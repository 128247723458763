import { Flex } from "@radix-ui/themes";
import { match } from "ts-pattern";
import { Spinner } from "~/components_next/Spinner/Spinner";
import { useView } from "../common/utils/useView";
import { KanbanView } from "../KanbanView";
import { TableView } from "../TableView";

const View = () => {
  const { data: viewData, status: viewStatus, error: viewError } = useView();

  if (viewStatus === "loading" || viewStatus === "idle") {
    return (
      <Flex height="100%" align="center" justify="center">
        <Spinner />
      </Flex>
    );
  }

  if (viewStatus === "error") {
    throw viewError;
  }

  return match(viewData.type)
    .with("table", () => <TableView />)
    .with("kanban", () => <KanbanView />)
    .exhaustive();
};

export { View };
