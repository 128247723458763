import { Executable } from "~/clientModel/executable";
import { Loadable } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import {
  ComputedFieldSyncScheduleClientModel,
  CreatingComputedFieldSyncScheduleClientModel,
} from "~/clientModel/tables/table/computedFieldSyncSchedule";
import { Drawer } from "~/components_next/Drawer";
import { CreateDrawerBodyAndFooter } from "./CreateDrawerBodyAndFooter";
import { EditDrawerBodyAndFooter } from "./EditDrawerBodyAndFooter";

type ComputedFieldsScheduledSyncSettingDrawerProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  computedFieldSyncScheduleLoadable: Loadable<ComputedFieldSyncScheduleClientModel | null>;
  createSyncScheduleExecutable: Executable<
    {
      syncSchedule: CreatingComputedFieldSyncScheduleClientModel;
    },
    unknown
  >;
  updateSyncScheduleExecutable: Executable<
    {
      syncSchedule: ComputedFieldSyncScheduleClientModel;
    },
    unknown
  >;
};

export const ComputedFieldsScheduledSyncSettingDrawer = (
  props: ComputedFieldsScheduledSyncSettingDrawerProps
) => {
  const {
    isOpen,
    onOpenChange,
    computedFieldSyncScheduleLoadable,
    createSyncScheduleExecutable,
    updateSyncScheduleExecutable,
  } = props;

  return (
    <Drawer.Root open={isOpen} onOpenChange={onOpenChange} size="xs">
      <Drawer.Content>
        <Drawer.Title>Sync Schedule Settings</Drawer.Title>
        <WithFallback loadables={[computedFieldSyncScheduleLoadable] as const}>
          {([syncSchedule]) => {
            if (syncSchedule) {
              // スケジューリングがある場合は編集できる
              return (
                <EditDrawerBodyAndFooter
                  syncSchedule={syncSchedule}
                  updateSyncScheduleExecutable={updateSyncScheduleExecutable}
                />
              );
            } else {
              // スケジューリングがない場合は作成できる
              return (
                <CreateDrawerBodyAndFooter
                  createSyncScheduleExecutable={createSyncScheduleExecutable}
                />
              );
            }
          }}
        </WithFallback>
      </Drawer.Content>
    </Drawer.Root>
  );
};
