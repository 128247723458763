import {
  RecordFilterCondition,
  RecordFilterConditionAnd,
  RecordFilterConditionOr,
  RecordFilterConditionUnit,
  SimpleField,
  zRecordFilterConditionAnd,
  zRecordFilterConditionOr,
  zRecordFilterConditionUnit,
} from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";
import { commonValidationFunctions } from "~/utils/commonValidationFunctions";
import { zodGuard } from "~/utils/zodGuard";

const validateFilterConditionUnit = (
  filterUnit: RecordFilterConditionUnit,
  fields: SimpleField[]
) => {
  const findSimpleField = fields.find((field) => field.name === filterUnit.key);
  if (findSimpleField) {
    const value = String(filterUnit.value);
    const isValid = match(findSimpleField.type)
      .with(P.union("shortText", "longText", "singleSelect"), () => {
        return String(value).length > 0;
      })
      .with(P.union("number", "decimal", "autoNumber", "autoBigNumber"), () => {
        return commonValidationFunctions.isNumber(value);
      })
      .with("date", () => {
        return commonValidationFunctions.isValidDate(value);
      })
      .with("datetime", () => {
        return commonValidationFunctions.isValidDateTime(value);
      })
      .with("email", () => {
        return commonValidationFunctions.isValidEmail(value);
      })
      .otherwise(() => {
        return true;
      });

    if (isValid) {
      return filterUnit;
    }
  }
  return undefined;
};

const validateAndFilter = (
  filterAnd: RecordFilterConditionAnd,
  fields: SimpleField[]
): RecordFilterConditionAnd => {
  return {
    and: filterAnd.and
      .map((filterObject) => {
        if (zodGuard(filterObject, zRecordFilterConditionUnit)) {
          return validateFilterConditionUnit(filterObject, fields);
        }

        if (zodGuard(filterObject, zRecordFilterConditionAnd)) {
          return validateAndFilter(filterObject, fields);
        }

        if (zodGuard(filterObject, zRecordFilterConditionOr)) {
          return validateOrFilter(filterObject, fields);
        }

        return undefined;
      })
      .filter((result) => result !== undefined) as (
      | RecordFilterConditionUnit
      | RecordFilterConditionAnd
      | RecordFilterConditionOr
    )[],
  };
};

const validateOrFilter = (
  filterOr: RecordFilterConditionOr,
  fields: SimpleField[]
): RecordFilterConditionOr => {
  return {
    or: filterOr.or
      .map((filterObject) => {
        if (zodGuard(filterObject, zRecordFilterConditionUnit)) {
          return validateFilterConditionUnit(filterObject, fields);
        }

        if (zodGuard(filterObject, zRecordFilterConditionAnd)) {
          return validateAndFilter(filterObject, fields);
        }

        if (zodGuard(filterObject, zRecordFilterConditionOr)) {
          return validateOrFilter(filterObject, fields);
        }

        return undefined;
      })
      .filter((result) => result !== undefined) as (
      | RecordFilterConditionUnit
      | RecordFilterConditionAnd
      | RecordFilterConditionOr
    )[],
  };
};

const validateFilterCondition = (
  filterCondition: RecordFilterCondition,
  fields: SimpleField[]
) => {
  if (zodGuard(filterCondition, zRecordFilterConditionAnd)) {
    return validateAndFilter(filterCondition, fields);
  }

  if (zodGuard(filterCondition, zRecordFilterConditionOr)) {
    return validateOrFilter(filterCondition, fields);
  }

  return undefined;
};

export { validateFilterCondition };
