import { useQueryClient } from "react-query";
import useApiSQL from "~/api/useApiSQL";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { sqlHistoriesKeys } from "../keys";

interface UseSaveGenerateSqlHistoryMutationParams {
  teamSlug: string;
  databaseId: string;
  tableSlug?: string;
  viewId?: string;
}

const useSaveGenerateSqlHistoryMutation = ({
  teamSlug,
  databaseId,
  tableSlug,
  viewId,
}: UseSaveGenerateSqlHistoryMutationParams) => {
  const { _saveGenerateSqlHistory } = useApiSQL();
  const client = useQueryClient();

  return {
    mutationFn: ({ message, sql }: { message: string; sql: string }) => {
      return _saveGenerateSqlHistory({
        teamSlug,
        databaseId,
        body: {
          tableSlug,
          viewId,
          message,
          sql,
        },
      });
    },
    onSuccess: () => {
      return invalidateAll(
        client,
        sqlHistoriesKeys.allGetGenerateSqlHistory({ teamSlug, databaseId })
      );
    },
  };
};

export { useSaveGenerateSqlHistoryMutation };
