import {
  ResourceGroupListResponse,
  ResourceGroupResponse,
  ResourceTableSchema,
  ResourceNotebookSchema,
  ResourcePermissionType,
  ResourceViewSchema,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

type DashboardGeneralResponse = {
  message: string;
};

export default function useApiResourceGroups() {
  const { executeRequest } = useApi();

  const _listResourceGroups = async ({
    teamSlug,
    resource,
    limit,
    skip,
  }: {
    teamSlug: string;
    resource: ResourceTableSchema | ResourceNotebookSchema | ResourceViewSchema;
    limit?: number;
    skip?: number;
  }): Promise<ResourceGroupListResponse> => {
    return executeRequest<ResourceGroupListResponse>(
      "POST",
      `/resource-Group/query`,
      undefined,
      { teamSlug },
      {
        resource,
        limit,
        skip,
      }
    );
  };

  const _createResourceGroup = async ({
    teamSlug,
    resource,
    permission,
    userId,
  }: {
    teamSlug: string;
    resource: ResourceTableSchema | ResourceNotebookSchema | ResourceViewSchema;
    permission: ResourcePermissionType
    userId: string;
  }): Promise<ResourceGroupResponse> => {
    return executeRequest<ResourceGroupResponse>(
      "POST",
      `/resource-Group/create`,
      undefined,
      { teamSlug },
      {
        resource,
        permission,
        userId,
      }
    );
  };

  const _updateResourceGroup = async ({
    teamSlug,
    resource,
    permission,
    userId,
  }: {
    teamSlug: string;
    resource: ResourceTableSchema | ResourceNotebookSchema | ResourceViewSchema;
    permission: ResourcePermissionType
    userId: string;
  }): Promise<ResourceGroupResponse> => {
    return executeRequest<ResourceGroupResponse>(
      "POST",
      `/resource-Group/update`,
      undefined,
      { teamSlug },
      {
        resource,
        permission,
        userId,
      }
    );
  };

  const _deleteResourceGroup = async ({
    teamSlug,
    resource,
    userId,
  }: {
    teamSlug: string;
    resource: ResourceTableSchema | ResourceNotebookSchema | ResourceViewSchema;
    userId: string;
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest<DashboardGeneralResponse>(
      "POST",
      `/resource-Group/delete`,
      undefined,
      { teamSlug },
      {
        resource,
        userId,
      }
    );
  };
  return {
    _listResourceGroups,
    _createResourceGroup,
    _updateResourceGroup,
    _deleteResourceGroup,
  };
}
