import { Box, Popover, Portal } from "@radix-ui/themes";
import {
  RecordSortConditionUnit,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { useCallback, useMemo } from "react";
import { Input } from "~/components_next/Input";
import { SortConditionForm } from "~/features/Fields/SortCondition/SortConditionForm";
import { isArrayWithAtLeastOneElement } from "~/utils/commonTypeGuards";

export const MultipleSortSelect = ({
  sort,
  fields,
  onChange,
}: {
  sort: RecordSortConditionUnit[] | undefined;
  fields: SimpleField[];
  onChange: (sort: RecordSortConditionUnit[] | undefined) => void;
}) => {
  const handleSelectOpen = useCallback(() => {
    if (sort) return;

    onChange([
      {
        key: fields[0].name,
        direction: "ascending",
      },
    ]);
  }, [fields, onChange, sort]);

  const inputValue = useMemo(() => {
    if (sort && sort.length) {
      return `${sort.length} Sorts`;
    } else {
      return "";
    }
  }, [sort]);

  // UI
  // const popoverBgColor = useColorModeValue("white", "gray.800");

  if (!isArrayWithAtLeastOneElement(fields)) {
    return null;
  }

  return (
    <>
      {/* <ScaleFade initialScale={0.9} in={true}> */}
      <Box>
        <Popover.Root>
          <Popover.Trigger>
            <Box width={"100%"} onClick={handleSelectOpen}>
              {/* <InputGroup size="sm"> */}
              <Input
                variant="primary"
                readOnly={true}
                style={{ borderRightWidth: 0 }}
                value={inputValue}
              />
              {/* <InputRightAddon
                      bgColor={"whiteAlpha.300"}
                      borderLeftWidth={0}
                    >
                      <ChevronDownIcon boxSize={5} />
                    </InputRightAddon>
                  </InputGroup> */}
            </Box>
          </Popover.Trigger>

          <Portal>
            <Popover.Content
              style={{
                minWidth: "500px",
                zIndex: "popover",
                // bgColor={popoverBgColor}
              }}
            >
              {/* <PopoverArrow bgColor={popoverBgColor} /> */}
              <Box style={{ minWidth: "500px" }}>
                {sort && sort.length === 1 && (
                  <SortConditionForm
                    simpleFields={fields}
                    sortCondition={sort[0] ?? null}
                    onChangeSortCondition={(sort) => {
                      onChange(sort ? [sort] : undefined);
                      !sort
                      // && onClose();
                    }}
                  />
                )}
              </Box>
            </Popover.Content>
          </Portal>
        </Popover.Root>
      </Box>
    </>
  );
};
