import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { templateAuthKeys } from "../keys";
import useApiTemplateAuth from '~/api/useApiTemplateAuth';

interface UseVerifyTemplateAuthMutationParams {
  templateId: string;
  teamSlug: string;
}

const useVerifyTemplateAuthMutation = ({
  templateId,
  teamSlug,
}: UseVerifyTemplateAuthMutationParams) => {
  const { _verifyTemplateAuth } = useApiTemplateAuth()
  const client = useQueryClient();

  return {
    mutationFn: ({
      templateAuthId,
    }: {
      templateAuthId: string;
    }) => {
      return _verifyTemplateAuth({
        templateId,
        templateAuthId,
        teamSlug,
      });
    },
    onSuccess: () => {
      const keys = templateAuthKeys.allListTemplateAuth({ templateId });
      return invalidateAll(client, keys);
    },
  };
};

export { useVerifyTemplateAuthMutation };
