import { UseExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { Loadable } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { RecordEntryClientModel } from "~/clientModel/records/record/recordEntry";
import { RecordValueInputWithFieldInfo } from "~/presenters/records";

type BulkEditRecordsByRowContentProps = {
  fieldsLoadable: Loadable<FieldsClientModel, unknown>;
  editingRecordEntryLoadable: Loadable<RecordEntryClientModel>;
  onEditingRecordEntryChange: (recordEntry: RecordEntryClientModel) => void;
  useUploadFileExecutable: UseExecutable<
    void,
    {
      file: File;
    },
    {
      data: string;
      url: string;
    },
    unknown
  >;
};

export const BulkEditRecordsByRowContent = (
  props: BulkEditRecordsByRowContentProps
) => {
  const {
    editingRecordEntryLoadable,
    onEditingRecordEntryChange,
    fieldsLoadable,
    useUploadFileExecutable,
  } = props;

  return (
    <WithFallback
      loadables={[fieldsLoadable, editingRecordEntryLoadable] as const}
    >
      {([fields, editingRecordEntry]) => (
        <RecordValueInputWithFieldInfo
          recordEntry={editingRecordEntry}
          onRecordEntryChange={onEditingRecordEntryChange}
          field={fields.getFieldByFieldNameOrThrow(editingRecordEntry.key)}
          useUploadFileExecutable={useUploadFileExecutable}
        />
      )}
    </WithFallback>
  );
};
