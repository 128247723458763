import { forwardRef } from "react";
import { BsX } from "react-icons/bs";
import { Button } from "~/components_next/Button";

type ClearAllFiltersAndSortsButtonProps = {
  onClick: () => void;
};

const ClearAllFiltersAndSortsButton = forwardRef<
  HTMLButtonElement,
  ClearAllFiltersAndSortsButtonProps
>((props, ref) => {
  const { onClick } = props;

  return (
    <Button ref={ref} variant="ghost" size="xs" onClick={onClick}>
      <BsX />
      Clear All Conditions
    </Button>
  );
});

ClearAllFiltersAndSortsButton.displayName = "ClearAllFiltersAndSortsButton";

export { ClearAllFiltersAndSortsButton };
