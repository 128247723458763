import { useMemo } from "react";
import { CanvasCreateJoinTableViewCellClientModel } from "~/clientModel/canvas/CanvasCreateCellClientModel";
import { Loadable } from "~/clientModel/loadable";
import { TablesClientModel } from "~/clientModel/tables";
import { JoinSources } from "../../controlPanel/joinControls/JoinSources";
import { useUseCreateCellExecutable } from "../../providers/PlaygroundCreateCellExecutableProvider";
import {
  useUseSourceFieldsLoadable,
  useUseViewFieldsLoadable,
} from "../../providers/PlaygroundUseSourceFIeldsLoadableProvider";
import { JoinSourceAndView } from "../../controlPanel/joinControls/JoinSourceAndView";
import { ControlledDropdown } from "~/components_next/DropdownMenu";
import { Box } from "~/components_next/Box";
import { Text } from "~/components_next/Text";

type JoinToViewDropdownProps = {
  createCellInstance: CanvasCreateJoinTableViewCellClientModel;
  sourcesLoadable: Loadable<TablesClientModel>;
  isCreatingCell: boolean;
};

const JoinToViewDropdown = (props: JoinToViewDropdownProps) => {
  const { createCellInstance, sourcesLoadable, isCreatingCell } = props;

  const { targetSource } = createCellInstance;

  const parentSource = useMemo(() => {
    if (createCellInstance?.parentSourceCell) {
      return sourcesLoadable.data?.allTables.find(
        (table) =>
          table.tableSlug === createCellInstance.parentSourceCell!.tableSlug
      );
    }
    return undefined;
  }, [createCellInstance?.parentSourceCell, sourcesLoadable.data?.allTables]);

  const parentView = useMemo(() => {
    return createCellInstance?.parentViewCell;
  }, [createCellInstance?.parentViewCell]);

  const useSourceFieldsLoadable = useUseSourceFieldsLoadable();
  const useViewFieldsLoadable = useUseViewFieldsLoadable();

  /**
   * override executable
   */
  const useCreateCellExecutable = useUseCreateCellExecutable();

  if (isCreatingCell) {
    <ControlledDropdown
      trigger={
        <Box
          css={{
            width: "30px",
            height: "2px",
            backgroundColor: "transparent",
          }}
        ></Box>
      }
      isOpen={createCellInstance !== null}
      onOpenChange={() => null}
    >
      <Text>Creating parent cell..</Text>
    </ControlledDropdown>;
  }

  if (parentView) {
    return (
      <ControlledDropdown
        trigger={
          <Box
            css={{
              width: "30px",
              height: "2px",
              backgroundColor: "transparent",
            }}
          ></Box>
        }
        isOpen={createCellInstance !== null}
        onOpenChange={() => null}
      >
        <JoinSourceAndView
          tableSlug={targetSource.tableSlug || ""}
          tableName={targetSource.displayName || ""}
          viewId={parentView.viewId || ""}
          viewName={parentView.cellNameForDisplay || ""}
          parentCells={createCellInstance.parentCells}
          useSourceFieldsLoadable={useSourceFieldsLoadable}
          useViewFieldsLoadable={useViewFieldsLoadable}
          useCreateCellExecutable={useCreateCellExecutable}
          isInverse
        />
      </ControlledDropdown>
    );
  }

  if (parentSource && createCellInstance?.parentSourceCell) {
    return (
      <ControlledDropdown
        trigger={
          <Box
            css={{
              width: "30px",
              height: "2px",
              backgroundColor: "transparent",
            }}
          ></Box>
        }
        isOpen={createCellInstance !== null}
        onOpenChange={() => null}
      >
        <JoinSources
          mainTableSlug={parentSource.tableSlug || ""} // nullなわけないんだけど..検討
          mainTableName={parentSource.displayName || ""}
          targetTableSlug={targetSource.tableSlug || ""}
          targetTableName={targetSource.displayName || ""}
          parentCells={createCellInstance.parentCells}
          useSourceFieldsLoadalbe={useSourceFieldsLoadable}
          useCreateCellExecutable={useCreateCellExecutable}
        />
      </ControlledDropdown>
    );
  }

  // TODO: ちゃんとしたエラーメッセージ
  return <></>;
};

export { JoinToViewDropdown };
