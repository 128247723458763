import { match } from "ts-pattern";
import {
  CanvasVariableDataType,
  CanvasVariableClientModelService,
} from "~/clientModel/canvas/CanvasVariableClientModel";
import { Checkbox } from "~/components_next/Checkbox";
import { Input, InputProps } from "~/components_next/Input";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { SimpleSelect } from "~/components_next/Select";
import { CanvasCellVariablesClientModel } from "~/clientModel/canvas/CanvasCellClientModel";
import { ViewResultSelect } from "./ViewResultSelect";
import { UseVariableOptionsViewResultLoadable } from "../../common/CanvasVariableOptionsLoadableProvider";

type CanvasVariableInputProps = {
  variable: CanvasVariableDataType;
  value?: unknown;
  onChange: (variable: unknown) => void;
  size?: InputProps["size"];
  label?: InputProps["label"];
  description?: InputProps["description"];
  useVariableOptionsLoadable: UseVariableOptionsViewResultLoadable;
  variableCellClientModel: CanvasCellVariablesClientModel;
};

const CanvasVariableInput = (props: CanvasVariableInputProps) => {
  const {
    variable,
    value,
    onChange,
    size,
    label,
    description,
    useVariableOptionsLoadable,
  } = props;

  return match(variable)
    .with({ type: "string" }, (stringVariable) => {
      return (
        <Input
          variant="primary"
          size={size}
          label={label}
          description={description}
          value={CanvasVariableClientModelService.getStringValue(value)}
          onChange={(e) => onChange(e.target.value)}
        />
      );
    })
    .with({ type: "number" }, (numberVariable) => {
      return (
        <Input
          variant="primary"
          type="number"
          size={size}
          label={label}
          description={description}
          value={CanvasVariableClientModelService.getNumberValue(value)}
          onChange={(e) => onChange(e.target.value)}
        />
      );
    })
    .with({ type: "boolean" }, (booleanVariable) => {
      return (
        <InputStack size={size} label={label} description={description}>
          <Checkbox
            value={
              CanvasVariableClientModelService.getBooleanValue(value) || false
            }
            onChange={onChange}
          />
        </InputStack>
      );
    })
    .with({ type: "date" }, (dateVariable) => {
      return (
        <Input
          variant="primary"
          type="date"
          size={size}
          label={label}
          description={description}
          value={CanvasVariableClientModelService.getDateValue(value)}
          onChange={(e) => onChange(e.target.value)}
        />
      );
    })
    .with({ type: "singleSelectString" }, (singleSelectStringVariable) => {
      return (
        <SimpleSelect<string | null>
          variant="primary"
          size={size}
          label={label}
          description={description}
          value={CanvasVariableClientModelService.getStringValue(value) || null}
          options={
            singleSelectStringVariable.options?.map((option) => ({
              label: option || "",
              value: option,
            })) || []
          }
          onChange={(value) => {
            if (value) onChange(value);
          }}
        />
      );
    })
    .with({ type: "singleSelectNumber" }, (singleSelectNumberVariable) => {
      return (
        <SimpleSelect<number | null>
          variant="primary"
          size={size}
          label={label}
          description={description}
          value={CanvasVariableClientModelService.getNumberValue(value) || null}
          getValueStringFromValue={(value: number | null) => String(value)}
          options={
            singleSelectNumberVariable.options?.map((option) => ({
              label: String(option),
              value: option,
            })) || []
          }
          onChange={(value) => {
            if (value) onChange(value);
          }}
        />
      );
    })
    .with(
      { type: "singleSelectSourceFieldOptions" },
      (singleSelectSourceFieldOptionsVariable) => {
        return (
          <SimpleSelect<string | null>
            variant="primary"
            size={size}
            label={label}
            description={description}
            value={
              CanvasVariableClientModelService.getStringValue(value) || null
            }
            options={
              singleSelectSourceFieldOptionsVariable.options?.map((option) => ({
                label: option as string,
                value: option as string,
              })) || []
            }
            onChange={(value) => {
              if (value) onChange(value);
            }}
          />
        );
      }
    )
    .with(
      { type: "singleSelectViewRecords" },
      (singleSelectViewRecordsVariable) => {
        return (
          <>
            <ViewResultSelect
              value={value}
              onChange={onChange}
              variableObject={singleSelectViewRecordsVariable}
              useVariableOptionsLoadable={useVariableOptionsLoadable}
              variableCellClientModel={props.variableCellClientModel}
            />
          </>
        );
      }
    )
    .otherwise(() => null);
};

export { CanvasVariableInput, type CanvasVariableInputProps };
