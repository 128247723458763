import "./multi-select.css";
import { MultiSelectRoot, MultiSelectRootProps } from "./MultiSelectRoot";
import {
  MultiSelectContent,
  MultiSelectContentProps,
} from "./MultiSelectContent";
import { MultiSelectItem, MultiSelectItemProps } from "./MultiSelectItem";
import {
  MultiSelectItemGroup,
  MultiSelectItemGroupProps,
} from "./MultiSelectItemGroup";
import { MultiSelectInput, MultiSelectInputProps } from "./MultiSelectInput";
import {
  MultiSelectEmptyFallback,
  MultiSelectEmptyFallbackProps,
} from "./MultiSelectEmptyFallback";
import { MultiSelectList, MultiSelectListProps } from "./MultiSelectList";
import {
  MultiSelectInputContainer,
  MultiSelectInputContainerProps,
} from "./MultiSelectInputContainer";
import { MultiSelectTag, MultiSelectTagProps } from "./MultiSelectTag";

export const MultiSelect = {
  Root: MultiSelectRoot,
  Content: MultiSelectContent,
  List: MultiSelectList,
  Group: MultiSelectItemGroup,
  Item: MultiSelectItem,
  Input: MultiSelectInput,
  EmptyFallback: MultiSelectEmptyFallback,
  InputContainer: MultiSelectInputContainer,
  Tag: MultiSelectTag,
};

export type {
  MultiSelectRootProps,
  MultiSelectContentProps,
  MultiSelectListProps,
  MultiSelectItemProps,
  MultiSelectInputProps,
  MultiSelectEmptyFallbackProps,
  MultiSelectInputContainerProps,
  MultiSelectTagProps,
  MultiSelectItemGroupProps,
};

// todo: キーボード操作(↑↓でフォーカス移動とEnterで選択)
