import { ChangeEvent, useRef } from "react";
import { useMutation } from "react-query";
import { usePostResourceMutation } from "~/serverStateStore/resources";
import { Button } from "~/components_next/Button";
import { Flex } from "~/components_next/Flex";
import { UserAvatar } from "~/components_next/User";
import { DashboardUserObject } from "@usemorph/morph-dashboard-types";
import { Box } from "~/components_next/Box";
import { Input } from "~/components_next/Input";

export const IconForm = ({
  teamSlug,
  // value,
  // username,
  user,
  onUpdate,
}: {
  teamSlug: string;
  // value?: string;
  // username?: string;
  user: DashboardUserObject;
  onUpdate: (value: string) => void;
}) => {
  const postResource = useMutation({ ...usePostResourceMutation({ teamSlug }) })

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const onFileInputButtonClick = () => {
    if (fileInputRef) {
      (fileInputRef.current as HTMLInputElement).click();
    }
  };

  const onFileChange = async (event: ChangeEvent) => {
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      const targetFile = files[0];
      const getUrlResponse = await postResource.mutateAsync({
        fileName: targetFile.name,
        isPublic: true,
      });

      const fetchResponse = await fetch(getUrlResponse.url, {
        method: "PUT",
        headers: {
          "Content-Type": targetFile.type,
        },
        body: targetFile,
      });

      if (fetchResponse && getUrlResponse.path) {
        onUpdate(getUrlResponse.path);
      }
    }
  };

  return (
    <Flex direction={"column"} align={"center"}>
      {/* <WrapItem cursor={"pointer"} mb={2}>
        {postResource.isLoading
          ? <SkeletonCircle size='128px' />
          : <>
            <Avatar
              size="2xl"
              name={username}
              src={value}
              bg={"gray.500"}
              onClick={onFileInputButtonClick}
            />{" "}
          </>
        }
      </WrapItem> */}
      <Box mb="2" onClick={onFileInputButtonClick}>
        <UserAvatar
          size="xl"
          user={user}
          showTooltipOnHover={false}
        ></UserAvatar>
      </Box>
      <Box>
        <Button
          variant='primary'
          onClick={onFileInputButtonClick}
          size="sm"
        >
          Upload a file
        </Button>
        <Box css={{ display: "none" }}>
          <Input
            variant="primary"
            type="file"
            ref={fileInputRef}
            // display="none"
            accept="image/png, image/gif, image/jpeg"
            onChange={onFileChange}
          />
        </Box>
      </Box>
    </Flex>
  );
};
