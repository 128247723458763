import { ReactNode, useCallback } from "react";
import { RecoilURLSync } from "recoil-sync";

type KanbanViewURLSyncStoreProps = {
  children: ReactNode;
};

const KanbanViewURLSyncStore = (props: KanbanViewURLSyncStoreProps) => {
  const { children } = props;

  const serialize = useCallback((value: unknown) => {
    return JSON.stringify(value);
  }, []);

  const deserialize = useCallback((value: string) => {
    return JSON.parse(value);
  }, []);

  return (
    <RecoilURLSync
      serialize={serialize}
      deserialize={deserialize}
      location={{ part: "queryParams" }}
    >
      {children}
    </RecoilURLSync>
  );
};

export { KanbanViewURLSyncStore };
