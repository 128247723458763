import { PaginationClientModel } from "./PaginationClientModel";

export class PaginationClientModelFactory {
  public static createInitialPagination<Limit extends number>({
    limit,
  }: {
    limit: Limit;
  }): PaginationClientModel<Limit> {
    return new PaginationClientModel({
      skip: 0,
      limit,
    });
  }
}
