import * as RadixAccordionPrimitive from "@radix-ui/react-accordion";
import { keyframes } from "@stitches/react";
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from "react";
import { BsChevronDown } from "react-icons/bs";
import { styled } from "~/stitches";

type AccordionRootProps = ComponentPropsWithoutRef<
  typeof RadixAccordionPrimitive.Root
>;
const StyledAccordionRoot = styled(RadixAccordionPrimitive.Root, {
  borderRadius: "6px",
  width: "100%",
  backgroundColor: "whtie",
  borderWidth: 1,
});
const _AccordionRoot = (
  props: AccordionRootProps,
  ref: ForwardedRef<HTMLDivElement>
) => <StyledAccordionRoot {...props} ref={ref}></StyledAccordionRoot>;
const AccordionRoot = forwardRef(_AccordionRoot);

type AccordionItemProps = ComponentPropsWithoutRef<
  typeof RadixAccordionPrimitive.Item
>;
const AccordionItem = styled(RadixAccordionPrimitive.Item, {
  overflow: "hidden",
  marginTop: "1px",
  borderBottomWidth: 1,
  // '&[data-state=open]': {
  //   borderBottomWidth: 0,
  // },
  "&:first-child": {
    marginTop: 0,
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  "&:last-child": {
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  "&:forcus-within": {
    position: "relative",
    // zIndex: 1,
    boxShadow: "0 0 0 2px var(--mauve-12)",
  },
});

type AccordionTriggerProps = ComponentPropsWithoutRef<
  typeof RadixAccordionPrimitive.Trigger
>;
const StyledAccordionTrigger = styled(RadixAccordionPrimitive.Trigger, {
  fontFamily: "inherit",
  backgroundColor: "$bg0",
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  lineHeight: 1,
  color: "$text",
  "&:hover": {
    backgroundColor: "$bg1",
  },
  ".AccordionChevron": {
    transition: "transform 300ms cubic-bezier(0.87, 0, 0.13, 1)",
  },
  "&[data-state=open]": {
    ".AccordionChevron": {
      transform: "rotate(180deg)",
    },
  },
  variants: {
    size: {
      xs: {
        padding: "0 4px",
        fontSize: "10px",
        height: "24px",
      },
      sm: {
        padding: "0 8px",
        fontSize: "12px",
        height: "32px",
      },
      md: {
        padding: "0 12px",
        fontSize: "14px",
        height: "40px",
      },
      lg: {
        padding: "0 16px",
        fontSize: "16px",
        height: "48px",
      },
    },
  },
});
const _AccordionTrigger = (
  props: AccordionTriggerProps & {
    size?: "xs" | "sm" | "md" | "lg";
  },
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const { size = "md" } = props;
  return (
    <RadixAccordionPrimitive.Header style={{ display: "flex" }}>
      <StyledAccordionTrigger {...props} ref={ref} size={size}>
        <>
          {props.children}
          <BsChevronDown className="AccordionChevron" />
        </>
      </StyledAccordionTrigger>
    </RadixAccordionPrimitive.Header>
  );
};
const AccordionTrigger = forwardRef(_AccordionTrigger);

type AccordionContentProps = ComponentPropsWithoutRef<
  typeof RadixAccordionPrimitive.Content
>;
const slideDown = keyframes({
  from: {
    height: 0,
  },
  to: {
    height: "var(--radix-accordion-content-height)",
  },
});

const slideUp = keyframes({
  from: {
    height: "var(--radix-accordion-content-height)",
  },
  to: {
    height: 0,
  },
});
const AccordionContent = styled(RadixAccordionPrimitive.Content, {
  overflow: "hidden",
  fontSize: "15px",
  color: "$text",
  backgroundColor: "$bg0",
  "&[data-state=open]": {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  "&[data-state=closed]": {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});

export {
  AccordionRoot,
  type AccordionRootProps,
  AccordionItem,
  type AccordionItemProps,
  AccordionTrigger,
  type AccordionTriggerProps,
  AccordionContent,
  type AccordionContentProps,
};
