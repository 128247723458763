import { RecordSortConditionUnit } from "@usemorph/morph-dashboard-types";
import { SortConditionUnitClientModel } from "./SortConditionUnitClientModel";

export class SortConditionUnitClientModelDefactory {
  public static toRecordSortConditionUnit(
    sortCondition: SortConditionUnitClientModel
  ): RecordSortConditionUnit {
    return {
      key: sortCondition.data.targetField.name,
      direction: sortCondition.data.direction,
    };
  }
}
