import { Card } from "@radix-ui/themes";
import { useEffect, useMemo } from "react";
import { BsPlus, BsThreeDotsVertical } from "react-icons/bs";
import {
  CanvasCellClientModelUnion,
  CanvasCellVariablesClientModel,
} from "~/clientModel/canvas/CanvasCellClientModel";
import {
  CanvasVariableClientModelService,
  CanvasVariablesClientModel,
} from "~/clientModel/canvas/CanvasVariableClientModel";
import { CanvasVariableTypeType } from "~/clientModel/canvas/CanvasVariableClientModel/CanvasVariableClientModel";
import { Executable } from "~/clientModel/executable";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import {
  SimpleDropdownMenu,
  DropdownMenu,
} from "~/components_next/DropdownMenu";
import { Flex } from "~/components_next/Flex";
import { IconButton } from "~/components_next/IconButton";
import { Input } from "~/components_next/Input";
import { SimpleSelect } from "~/components_next/Select";
import { Text } from "~/components_next/Text";
import { Tooltip } from "~/components_next/Tooltip";
import { VariablesNumberOptions } from "~/features/FlipNotebook/components/NotebookSidebar/Variables/VariableNumberOptions";
import { VariableSourceFieldOptions } from "~/features/FlipNotebook/components/NotebookSidebar/Variables/VariableSourceFieldOptions";
import { VariablesStringOptions } from "~/features/FlipNotebook/components/NotebookSidebar/Variables/VariableStringOptions";
import { VariableViewResultOptions } from "~/features/FlipNotebook/components/NotebookSidebar/Variables/VariableVewResultOptions";
import { useDebounceState } from "~/hooks/useDebounceState";
import { useReactiveState } from "~/hooks/useReactiveState";
import { CanvasVariableInput } from "~/presenters/canvas/cellContent/variable/CanvasVariableInput";
import { UseVariableOptionsViewResultLoadable } from "~/presenters/canvas/common/CanvasVariableOptionsLoadableProvider";

type PlaygroundSidebarVariablesProps = {
  cell: CanvasCellVariablesClientModel;
  updateCellExecutable: Executable<
    {
      cell: CanvasCellClientModelUnion;
      shouldGenerate?: boolean;
    },
    CanvasCellClientModelUnion
  >;
  useVariableOptionsLoadable: UseVariableOptionsViewResultLoadable;
};

const PlaygroundSidebarVariables = (props: PlaygroundSidebarVariablesProps) => {
  const { cell, updateCellExecutable, useVariableOptionsLoadable } = props;
  const { variables: propsVariables } = cell;

  const [variables, setVariables] =
    useReactiveState<CanvasVariablesClientModel>(propsVariables);
  const debouncedValue = useDebounceState(variables, 500);
  const hasChanged = useMemo(() => {
    return (
      JSON.stringify(variables.rawData) !==
      JSON.stringify(propsVariables.rawData)
    );
  }, [variables, propsVariables]);

  useEffect(() => {
    if (hasChanged) {
      executeUpdateCell();
    }
  }, [debouncedValue]);

  const executeUpdateCell = () => {
    const updatedCell = cell.updateVariables(variables);
    updateCellExecutable.execute({
      cell: updatedCell,
      shouldGenerate: false,
    });
  };

  /**
   * Event handlers
   */
  const handleDeleteVariable = (index: number) => {
    setVariables(() => variables.deleteVariableAt(index));
  };

  const handleCreateVariable = () => {
    setVariables(() => variables.createNewVariable());
  };

  const handleNameChange = (name: string, index: number) => {
    setVariables(() => variables.updateNameAt(name, index));
  };

  const handleTypeChange = (type: CanvasVariableTypeType, index: number) => {
    setVariables(() => variables.updateTypeAt(type, index));
  };

  const handleDefaultValueChange = (value: unknown, index: number) => {
    setVariables(() => variables.updateDefaultValueAt(value, index));
  };

  const handleStringOptionsChange = (options: string[], index: number) => {
    setVariables(() => variables.updateOptionsStringAt(options, index));
  };

  const handleNumberOptionsChange = (options: number[], index: number) => {
    setVariables(() => variables.updateOptionsNumberAt(options, index));
  };

  const handleSourceFieldOptionsChange = (
    value: { tableSlug: string; field: string },
    index: number
  ) => {
    setVariables(() => variables.updateOptionsSourceFieldAt(value, index));
  };

  const handleViewResultOptionsChange = (
    value: { viewId: string; labelField: string; valueField: string },
    index: number
  ) => {
    setVariables(() => variables.updateOptionsViewRecordsAt(value, index));
  };

  return (
    <>
      <Flex direction="column" gap="4">
        <Text variant="subheading">Variables</Text>
        {variables.data.map((variable, index) => {
          return (
            <Box
              key={index}
              css={{
                gridTemplateColumns: "1fr 24px",
                display: "grid",
                gap: "4px",
              }}
            >
              <Card variant="classic">
                <Flex direction="column" gap="3">
                  {/* Name */}
                  <Tooltip content="Variable name">
                    <Input
                      description="Variable name"
                      variant="primary"
                      size="xs"
                      value={variable.data.name}
                      onChange={(e) => handleNameChange(e.target.value, index)}
                    />
                  </Tooltip>

                  {/* Type */}
                  <Tooltip content="Type">
                    <SimpleSelect
                      description="Variable type"
                      size="xs"
                      variant="primary"
                      options={
                        CanvasVariableClientModelService.variableTypeOptions
                      }
                      value={variable.data.type}
                      onChange={(value) => {
                        if (value) handleTypeChange(value, index);
                      }}
                    />
                  </Tooltip>

                  {/* Option Setting */}
                  {(variable.data.type === "singleSelectString" ||
                    variable.data.type === "multiSelectString") && (
                    <Flex pl="2" pt="2" direction="column" gap="3">
                      <Text variant="tinyDescription">Options</Text>
                      <VariablesStringOptions
                        options={
                          (variable.data.options || []).filter(
                            (option) => option !== null
                          ) as string[]
                        }
                        onOptionsChange={(options) =>
                          handleStringOptionsChange(options, index)
                        }
                      />
                    </Flex>
                  )}
                  {(variable.data.type === "singleSelectNumber" ||
                    variable.data.type === "multiSelectNumber") && (
                    <Flex pl="2" pt="2" direction="column" gap="3">
                      <Text variant="tinyDescription">Options</Text>
                      <VariablesNumberOptions
                        options={
                          (variable.data.options || []).filter(
                            (option) => option !== null
                          ) as number[]
                        }
                        onOptionsChange={(options) =>
                          handleNumberOptionsChange(options, index)
                        }
                      />
                    </Flex>
                  )}
                  {/* Source: テーブルとフィールドを選択 */}
                  {(variable.data.type === "singleSelectSourceFieldOptions" ||
                    variable.data.type === "multiSelectSourceFieldOptions") && (
                    <Flex pl="2" pt="2" mb="2" direction="column" gap="3">
                      <VariableSourceFieldOptions
                        tableSlug={variable.data.tableSlug}
                        field={variable.data.field}
                        onChange={(value) =>
                          handleSourceFieldOptionsChange(value, index)
                        }
                      />
                    </Flex>
                  )}
                  {/* View Result */}
                  {(variable.data.type === "singleSelectViewRecords" ||
                    variable.data.type === "multiSelectViewRecords") && (
                    <Flex pl="2" pt="2" direction="column" gap="3">
                      <Text variant="tinyDescription">Options</Text>
                      <VariableViewResultOptions
                        viewId={variable.data.viewId}
                        labelField={variable.data.labelField}
                        valueField={variable.data.valueField}
                        onChange={(value) => {
                          handleViewResultOptionsChange(value, index);
                        }}
                      />
                    </Flex>
                  )}

                  {/* Default Value */}
                  <Tooltip content="Default Value">
                    <CanvasVariableInput
                      description="Default value"
                      size="xs"
                      variableCellClientModel={cell}
                      variable={variable.data}
                      value={variable.data.defaultValue}
                      onChange={(value) =>
                        handleDefaultValueChange(value, index)
                      }
                      useVariableOptionsLoadable={useVariableOptionsLoadable}
                    />
                  </Tooltip>
                </Flex>
              </Card>

              <SimpleDropdownMenu
                trigger={
                  <IconButton
                    icon={<BsThreeDotsVertical />}
                    tooltip="Settings"
                    size="xs"
                  />
                }
              >
                <DropdownMenu.Item onClick={() => handleDeleteVariable(index)}>
                  Delete variable
                </DropdownMenu.Item>
              </SimpleDropdownMenu>
            </Box>
          );
        })}
        <Button
          variant="tertiary"
          size="sm"
          onClick={() => handleCreateVariable()}
        >
          <BsPlus /> Add
        </Button>
      </Flex>
    </>
  );
};

export { PlaygroundSidebarVariables };
