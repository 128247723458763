// types
import { useQueryClient } from "react-query";
import useApiImport from "~/api/useApiImport";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { importsKeys } from "../keys";

interface UseDeleteImportIntegrationScheduleMutationParams {
  databaseId: string;
  teamSlug: string;
}

const useDeleteImportIntegrationScheduleMutation = ({
  teamSlug,
  databaseId,
}: UseDeleteImportIntegrationScheduleMutationParams) => {
  const { _deleteImportIntegrationSchedule } = useApiImport();

  const client = useQueryClient();

  return {
    mutationFn: ({
      importIntegrationScheduleId,
    }: {
      importIntegrationScheduleId: string;
    }) => {
      return _deleteImportIntegrationSchedule({
        teamSlug,
        databaseId,
        importIntegrationScheduleId,
      });
    },
    onSuccess: () => {
      return invalidateAll(client, importsKeys.all({ teamSlug, databaseId }));
    },
  };
};

export { useDeleteImportIntegrationScheduleMutation };
