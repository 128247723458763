import { SimpleSelect } from "~/components_next/Select/SimpleSelect/SimpleSelect";
import { RecordValueInputCommonProps } from "../../type";
import { ForwardedRef, forwardRef } from "react";

type SingleSelectInputProps = RecordValueInputCommonProps<"singleSelect"> & {
  members: string[];
};

const SingleSelectInput = forwardRef(function SingleSelectInput(
  props: SingleSelectInputProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const {
    value,
    members,
    onChange,
    errorMessages,
    isNullable,
    isReadOnly,
    size,
  } = props;

  return (
    <SimpleSelect
      variant="primary"
      size={size}
      options={members.map((member) => {
        return {
          label: member,
          value: member,
        };
      })}
      value={value ?? null}
      onChange={onChange}
      errorMessages={errorMessages}
      isClearable={isNullable && !isReadOnly}
      ref={ref}
    />
  );
});

export { SingleSelectInput };
