

import { useQuery } from "react-query";
import { useComposeLoadable } from "~/clientModel/loadable";
import { ListPlansClientModelFactory } from "~/clientModel/plan/ListPlansClientModelFactory";
import { useListPlansQuery } from "~/serverStateStore/plans"



export const useListPlansLoadable = (props: {
  teamSlug: string;
}) => {
  const { teamSlug } = props

  return useComposeLoadable(
    useQuery({
      ...useListPlansQuery({
        teamSlug,
      }),
      select: (data) => {
        return ListPlansClientModelFactory.fromListPlansResponse(data)
      }
    })
  )

}
