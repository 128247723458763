import { BsClockHistory } from "react-icons/bs";
import { IconButton } from "~/components_next/IconButton";

type PromptHistoryButtonProps = {
  onClick: () => void;
};

export const PromptHistoryButton = (props: PromptHistoryButtonProps) => {
  const { onClick } = props;
  return (
    <IconButton
      icon={<BsClockHistory />}
      onClick={onClick}
      tooltip="Prompt Histories"
      size="xs"
    />
  );
};
