import { DashboardImportIntegrationStatusResponse } from "@usemorph/morph-dashboard-types";



export class ImportIntegrationStatusClientModel {
  constructor(private readonly data: DashboardImportIntegrationStatusResponse) { }

  get status(): "failed" | "importing" | "imported" {
    return this.data.status;
  }
  get createdAt(): string {
    return this.data.createdAt;
  }

  get tableSlug(): string {
    return this.data.tableSlug;
  }

  get databaseId(): string {
    return this.data.databaseId;
  }

  get updatedAt(): string | null {
    return this.data.updatedAt;
  }

  get id(): string {
    return this.data.id;
  }

  get serviceType(): "airtable" | "notion" | "webflow" | "googleAnalytics" {
    return this.data.serviceType;
  }

  get errors(): Record<string, unknown> | null {
    return this.data.errors;
  }

  get templateAuthId(): string | undefined {
    return this.data.templateAuthId;
  }
}
