import { useQuery } from "react-query";
import { useListAllSimpleFieldsQuery } from "../queries/useListAllSimpleFieldsQuery";

interface useAllSimpleFieldsParams {
  teamSlug: string;
  databaseId: string;
}

const useAllSimpleFields = ({
  teamSlug,
  databaseId,
}: useAllSimpleFieldsParams) => {
  const options = useListAllSimpleFieldsQuery({ teamSlug, databaseId });
  return useQuery(options);
};

export { useAllSimpleFields };
