import useApiDatabase from "~/api/useApiDatabase";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { databaseKeys } from "../keys";

interface UseUpdateDatabaseMutationParams {
  teamSlug: string;
}

type UpdateDatabaseMutationProps = {
  databaseId: string;
  databaseName: string;
}

const useUpdateDatabaseMutation = ({
  teamSlug,
}: UseUpdateDatabaseMutationParams) => {
  const { _updateDatabase } = useApiDatabase();
  const client = useQueryClient();

  return {
    mutationFn: ({
      databaseId,
      databaseName,
    }: UpdateDatabaseMutationProps) => {
      return _updateDatabase({
        teamSlug,
        databaseId,
        requestBody: { databaseName },
      });
    },
    onSuccess: () => {
      return invalidateAll(client, databaseKeys.listDatabases({ teamSlug }));
    },
  };
};

export { useUpdateDatabaseMutation, type UpdateDatabaseMutationProps };
