import { type ForwardedRef, ComponentPropsWithoutRef, forwardRef } from "react";
import { MorphThemeSize } from "../commonProps.type";
import { Button as RadixThemeButton } from "@radix-ui/themes";
import { match } from "ts-pattern";
import { Spinner } from "../Spinner/Spinner";
import { styled } from "@stitches/react";
import { Box } from "../Box";

type RadixThemeButtonProps = ComponentPropsWithoutRef<typeof RadixThemeButton>;

type ButtonVariant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "actionText"
  | "negativeGhost"
  | "ghost"
  | "danger";

type ButtonProps = {
  variant: ButtonVariant;
  size?: MorphThemeSize;
  colorSchemeOverride?: RadixThemeButtonProps["color"];
  isDisabled?: boolean;
  isLoading?: boolean;
} & Omit<RadixThemeButtonProps, "variant" | "size" | "disabled">;

const convertVariant = (
  variant: ButtonVariant
): RadixThemeButtonProps["variant"] => {
  return match(variant)
    .with("primary", () => "solid" as RadixThemeButtonProps["variant"])
    .with("secondary", () => "outline" as RadixThemeButtonProps["variant"])
    .with("tertiary", () => "soft" as RadixThemeButtonProps["variant"])
    .with("actionText", () => "ghost" as RadixThemeButtonProps["variant"])
    .with("negativeGhost", () => "ghost" as RadixThemeButtonProps["variant"])
    .with("ghost", () => "ghost" as RadixThemeButtonProps["variant"])
    .with("danger", () => "solid" as RadixThemeButtonProps["variant"])
    .exhaustive();
};

const getColor = (
  variant: ButtonVariant,
  colorSchemeOverride: ButtonProps["colorSchemeOverride"]
): RadixThemeButtonProps["color"] => {
  if (colorSchemeOverride) {
    return colorSchemeOverride;
  }

  if (variant === "danger") {
    return "red";
  }

  return "gray";
};

const getSize = (size?: MorphThemeSize) => {
  return match(size)
    .with("xs", () => "1" as RadixThemeButtonProps["size"])
    .with("sm", () => "2" as RadixThemeButtonProps["size"])
    .with("md", () => "3" as RadixThemeButtonProps["size"])
    .with("lg", () => "4" as RadixThemeButtonProps["size"])
    .with("xl", () => "4" as RadixThemeButtonProps["size"])
    .otherwise(() => "3" as RadixThemeButtonProps["size"]);
};

const StyledButton = styled(RadixThemeButton, {
  cursor: "pointer",

  span: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
    flexGrow: 1,
  },
});

const _Button = (props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
  const {
    variant,
    colorSchemeOverride,
    size,
    isDisabled,
    isLoading,
    children,
    ...radixButtonProps
  } = props;

  const radixButtonVariant = convertVariant(variant);
  const radixButtonColor = getColor(variant, colorSchemeOverride);
  const radixButtonSize = getSize(size);

  const style = match(variant)
    .with("ghost", () => ({
      marginLeft: "unset",
      marginRight: "unset",
    }))
    .otherwise(() => ({}));

  return (
    <StyledButton
      ref={ref}
      variant={radixButtonVariant}
      color={radixButtonColor}
      {...radixButtonProps}
      highContrast
      size={radixButtonSize}
      disabled={isDisabled || isLoading}
      style={{ ...style, ...props.style }}
    >
      {isLoading ? (
        <Box css={{ position: "absolute" }}>
          <Spinner size={size} />
        </Box>
      ) : (
        <></>
      )}
      <span style={{ visibility: isLoading ? "hidden" : "visible" }}>
        {children}
      </span>
    </StyledButton>
  );
};

const Button = forwardRef(_Button);

export { Button, type ButtonProps };
