import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { SmartFunctionFieldTypeClientModel } from "../../../../SmartFunctionFieldTypeClientModel";

export type SmartFunctionInputClientModelBaseData = {
  key: string;
  label: string;
  description: string;
  required: boolean;
};

export abstract class SmartFunctionInputClientModelBase<ValueType> {
  public constructor(readonly data: SmartFunctionInputClientModelBaseData) {}

  public abstract readonly type: string;

  public get key(): string {
    return this.data.key;
  }

  public get label(): string {
    return this.data.label;
  }

  public get description(): string {
    return this.data.description;
  }

  public get isRequired(): boolean {
    return this.data.required;
  }

  public abstract getValue(
    smartFunctionFieldType: SmartFunctionFieldTypeClientModel
  ): ValueType;

  public abstract validateValue(
    smartFunctionFieldType: SmartFunctionFieldTypeClientModel
  ): ValidationResult;
}
