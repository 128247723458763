import { viewKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiView from "~/api/useApiView";

type UseFindViewQueryParams = {
  teamSlug: string;
  databaseId: string;
  viewId: string;
};

const useFindViewQuery = ({
  databaseId,
  teamSlug,
  viewId,
}: UseFindViewQueryParams) => {
  const { _findView } = useApiView();

  const client = useQueryClient();
  client.setQueryDefaults(
    viewKeys.allFindViews({ teamSlug, databaseId }),
    commonQueryOptions
  );

  return {
    queryKey: viewKeys.findView({
      databaseId,
      teamSlug,
      viewId,
    }),
    queryFn: () => {
      return _findView({
        teamSlug,
        viewId,
      });
    },
  };
};

export { useFindViewQuery };
