import { css } from "@stitches/react";
import { useCallback, useMemo, useState } from "react";
import { BsPlayFill } from "react-icons/bs";
import { Executable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { FormulaFieldTypeClientModel } from "~/clientModel/fields/field/fieldType";
import { RecordsClientModel } from "~/clientModel/records";
import { Button } from "~/components_next/Button";
import { Callout } from "~/components_next/Callout";
import { extractErrorDetails } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";
import { useDisclosure } from "~/hooks/useDisclosure";
import {
  basicFieldFilterFn,
  BasicTypeaheadPrompt,
  FieldSuggestionItem,
} from "~/presenters/prompt";
import { FormulaTestRunResultDrawer } from "./FormulaTestRunResultDrawer";

const promptRootStyle = css({
  overflowY: "auto !important",
  height: "200px !important",
});

const promptTheme = {
  root: `${promptRootStyle()}`,
};

type FormulaInputProps = {
  formulaType: FormulaFieldTypeClientModel;
  onFieldTypeChange: (field: FormulaFieldTypeClientModel) => void;
  fieldsForTypeahead: FieldsClientModel;
  testFormulaExecutable: Executable<
    { formula: string },
    { records: RecordsClientModel; fields: FieldsClientModel }
  >;
  isReadOnly?: boolean;
};

export const FormulaInput = (props: FormulaInputProps) => {
  const {
    formulaType,
    onFieldTypeChange,
    fieldsForTypeahead,
    testFormulaExecutable,
    isReadOnly,
  } = props;

  const [lastTestedFormula, setLastTestedFormula] = useState<string>(
    formulaType.formula
  );
  const [formulaInput, setFormulaInput] = useState<string>(formulaType.formula);
  const [testRunResult, setTestRunResult] = useState<{
    records: RecordsClientModel;
    fields: FieldsClientModel;
  } | null>(null);

  const {
    isOpen: isResultDrawerOpen,
    onOpen: onResultDrawerOpen,
    setIsOpen: onResultDrawerOpenChange,
  } = useDisclosure();

  const optionItems = useMemo(
    () => fieldsForTypeahead.allFields,
    [fieldsForTypeahead]
  );

  const convertFieldToDotNotatedFieldName = useCallback(
    (field: FieldClientModel) => `\${${field.name}}`,
    []
  );

  const renderHTMLTextContent = useCallback(
    (field: FieldClientModel) => field.label,
    []
  );

  const renderSuggestedItem = useCallback(
    (field: FieldClientModel) => <FieldSuggestionItem field={field} />,
    []
  );

  const handleTestRun = async () => {
    setLastTestedFormula(formulaInput);
    onFieldTypeChange(formulaType.updateFormula(""));
    const { records, fields } = await testFormulaExecutable.execute({
      formula: formulaInput,
    });
    onFieldTypeChange(formulaType.updateFormula(formulaInput));
    setTestRunResult({ records, fields });
    onResultDrawerOpen();
  };

  const handlePromptUpdate = (input: string) => {
    if (input === lastTestedFormula) {
      onFieldTypeChange(formulaType.updateFormula(lastTestedFormula));
    } else {
      onFieldTypeChange(formulaType.updateFormula(""));
    }
    setFormulaInput(input);
  };

  const showTestRunButton =
    (formulaInput && formulaInput !== lastTestedFormula) ||
    testFormulaExecutable.isExecuting;

  const showTestRunResult = lastTestedFormula === formulaInput;

  return (
    <>
      <Flex direction="column" gap="2">
        <InputStack label="Formula">
          <BasicTypeaheadPrompt<FieldClientModel>
            textContent={formulaInput}
            onUpdate={handlePromptUpdate}
            theme={promptTheme}
            optionItems={optionItems}
            filterFn={basicFieldFilterFn}
            convertOptionItemToText={convertFieldToDotNotatedFieldName}
            renderHTMLTextContent={renderHTMLTextContent}
            renderSuggestedItem={renderSuggestedItem}
            focusOnLoad
            isReadOnly={isReadOnly}
          />
        </InputStack>

        {/* Test Run Button */}
        {showTestRunButton && (
          <Flex mt="2" gap="1" align="center">
            {testFormulaExecutable.isExecuting ? (
              <Text variant="description">Testing your formula...</Text>
            ) : (
              <Text variant="description">Your need to test your formula.</Text>
            )}
            <Spacer />
            <Button
              variant="secondary"
              onClick={handleTestRun}
              isLoading={testFormulaExecutable.isExecuting}
              size="xs"
            >
              <BsPlayFill />
              Test Run
            </Button>
          </Flex>
        )}

        {/* Test Run Result(Error) */}
        {showTestRunResult && testFormulaExecutable.status === "error" && (
          <Callout
            type="alert"
            title="Invalid Formula"
            description={
              extractErrorDetails(testFormulaExecutable.error).description
            }
          />
        )}

        {/* Test Run Result(Success) */}
        {showTestRunResult && testFormulaExecutable.status === "success" && (
          <Callout
            type="success"
            description={
              <Flex align="center" style={{ flexGrow: 1 }}>
                <Text>The input formula is valid</Text>
                <Spacer />
                <Button
                  variant="tertiary"
                  size="xs"
                  onClick={onResultDrawerOpen}
                >
                  View Preview
                </Button>
              </Flex>
            }
          />
        )}
      </Flex>
      <FormulaTestRunResultDrawer
        isOpen={isResultDrawerOpen}
        onOpenChange={onResultDrawerOpenChange}
        records={testRunResult?.records}
        fields={testRunResult?.fields}
      />
    </>
  );
};
