import { z } from "zod";

export type ValidationSuccessResult = {
  isValid: true;
  errorMessages?: undefined;
};

export type ValidationFailureResult<T = string> = {
  isValid: false;
  errorMessages: T[];
};

export type ValidationResult<T = string> =
  | ValidationSuccessResult
  | ValidationFailureResult<T>;

export const zValidate = (
  schema: z.ZodType,
  value: unknown
): ValidationResult<string> => {
  const parsed = schema.safeParse(value);
  if (parsed.success) {
    return { isValid: true };
  } else {
    return {
      isValid: false,
      errorMessages: parsed.error.errors.map((e) => e.message),
    };
  }
};

export const mergeValidationResults = (
  ...results: ValidationResult[]
): ValidationResult => {
  const invalidResult = results.flatMap((result) =>
    result.isValid ? [] : [result]
  );
  if (invalidResult.length > 0) {
    return {
      isValid: false,
      errorMessages: invalidResult.flatMap((result) => result.errorMessages),
    };
  } else {
    return { isValid: true };
  }
};
