import { match, P } from "ts-pattern";
import { FieldClientModel } from "~/clientModel/fields/field";
import { commonValidator } from "~/clientModel/utils/commonValidator";
import { ValidationResult } from "~/clientModel/utils/validationResultType";

class ValueValidatorBase<ValueType> {
  protected value: ValueType;
  protected errorMessages: string[] = [];

  constructor(value: ValueType) {
    this.value = value;
  }

  public end(): ValidationResult {
    if (this.errorMessages.length > 0) {
      return {
        isValid: false,
        errorMessages: this.errorMessages,
      };
    }

    return { isValid: true };
  }

  protected addErrorMessages(errorMessages: string[] | string): this {
    this.errorMessages.push(...errorMessages);
    return this;
  }
}

export class StringValidator extends ValueValidatorBase<string> {
  public constructor(value: string) {
    super(value);
  }

  public shouldNotBeEmpty(): StringValidator {
    if (!this.value) {
      this.addErrorMessages(["value is required."]);
    }
    return this;
  }

  public shouldBeIntStringIfNumberType(
    targetField: FieldClientModel
  ): StringValidator {
    return match(targetField.type.type)
      .with(P.union("number", "autoNumber"), () => {
        if (!commonValidator.isIntString(this.value)) {
          this.addErrorMessages(["value should be integer."]);
        }
        return this;
      })
      .otherwise(() => this);
  }
}
