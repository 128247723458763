import { SimpleField } from "@usemorph/morph-dashboard-types";
import {
  RecordModelValueTypes,
  RecordModelValueWithSpecificType,
} from "~/features/RecordModel";
import z from "zod";

type FormatError = {
  detail: `${string}.`;
};

const requiredError = (field: SimpleField): FormatError => {
  return { detail: `${field.displayName ?? field.name} is required.` };
};

/**
 * Utility functions for validation
 */

class Validator<V> {
  private value: V;
  private field: SimpleField;
  private errors: FormatError[];
  private fieldLabel: string;

  public constructor(value: V, field: SimpleField) {
    this.value = value;
    this.field = field;
    this.errors = [];
    this.fieldLabel = `${this.field.displayName || this.field.name}`;
  }

  public end(): FormatError[] {
    return this.errors;
  }

  public shouldNotEmptyStringIfNotNullable(
    this: Validator<string>
  ): Validator<string> {
    if (!this.field.nullable && this.value === "") {
      this.addError({
        detail: `${this.fieldLabel} is required.`,
      });
    }
    return this;
  }

  public shouldNotBeNullIfNotNullable(this: Validator<V>): Validator<V> {
    if (!this.field.nullable && this.value === null) {
      this.addError({
        detail: `${this.fieldLabel} is required.`,
      });
    }
    return this;
  }

  public shouldBeIntString(this: Validator<string>): Validator<string> {
    const exp = /^-?\d+$/;
    if (!exp.test(this.value)) {
      this.addError({
        detail: `${this.fieldLabel} should be integer.`,
      });
    }
    return this;
  }

  public shouldBeDecimalString(this: Validator<string>): Validator<string> {
    const exp = /^-?\d+(?:\.\d+)?$/;
    if (!exp.test(this.value)) {
      this.addError({
        detail: `${this.fieldLabel} should be decimal.`,
      });
    }
    return this;
  }

  public shouldBeValidEmail(this: Validator<string>): Validator<string> {
    const schema = z.string().email();
    if (!schema.safeParse(this.value).success) {
      this.addError({ detail: `Invalid Email format.` });
    }
    return this;
  }

  public shouldBeValidUrl(this: Validator<string>): Validator<string> {
    const schema = z.string().url();
    if (!schema.safeParse(this.value).success) {
      this.addError({ detail: `Invalid URL format.` });
    }
    return this;
  }

  public shouldHaveAtLeastOneElementIfNotNullable<T extends unknown[]>(
    this: Validator<T>
  ): Validator<T> {
    if (!this.field.nullable && this.value.length === 0) {
      this.addError({
        detail: `${this.fieldLabel} is required.`,
      });
    }
    return this;
  }

  public shouldBeValidJSONString(this: Validator<string>): Validator<string> {
    try {
      JSON.parse(this.value);
      return this;
    } catch (e) {
      this.addError({ detail: "Invalid JSON format." });
      return this;
    }
  }

  private addError(error: FormatError) {
    this.errors = [...this.errors, error];
  }
}

const recordValueValueFormatValidators: {
  [T in SimpleField["type"]]: (
    value: RecordModelValueTypes[T],
    field: SimpleField
  ) => FormatError[];
} = {
  shortText: (value: string, field: SimpleField): FormatError[] => {
    return new Validator(value, field)
      .shouldNotEmptyStringIfNotNullable()
      .end();
  },

  longText: (value: string, field: SimpleField): FormatError[] => {
    return new Validator(value, field)
      .shouldNotEmptyStringIfNotNullable()
      .end();
  },

  richText: (value: string, field: SimpleField): FormatError[] => {
    return new Validator(value, field)
      .shouldNotEmptyStringIfNotNullable()
      .end();
  },

  number: (value: string, field: SimpleField): FormatError[] => {
    return new Validator(value, field).shouldBeIntString().end();
  },

  decimal: (value: string, field: SimpleField): FormatError[] => {
    return new Validator(value, field).shouldBeDecimalString().end();
  },

  bigNumber: (value: string, field: SimpleField): FormatError[] => {
    return new Validator(value, field).shouldBeIntString().end();
  },

  autoNumber: (): FormatError[] => {
    return [];
  },

  autoBigNumber: (): FormatError[] => {
    return [];
  },

  boolean: (): FormatError[] => {
    return [];
  },

  date: (value: string, field: SimpleField): FormatError[] => {
    return new Validator(value, field)
      .shouldNotEmptyStringIfNotNullable()
      .end();
  },

  datetime: (value: string, field: SimpleField): FormatError[] => {
    return new Validator(value, field)
      .shouldNotEmptyStringIfNotNullable()
      .end();
  },

  time: (value: string, field: SimpleField): FormatError[] => {
    return new Validator(value, field)
      .shouldNotEmptyStringIfNotNullable()
      .end();
  },

  email: (value: string, field: SimpleField): FormatError[] => {
    return new Validator(value, field).shouldBeValidEmail().end();
  },

  url: (value: string, field: SimpleField): FormatError[] => {
    return new Validator(value, field).shouldBeValidUrl().end();
  },

  phoneNumber: (value: string, field: SimpleField): FormatError[] => {
    return new Validator(value, field)
      .shouldNotEmptyStringIfNotNullable()
      .end();
  },

  image: (): FormatError[] => {
    return [];
  },

  attachment: (): FormatError[] => {
    return [];
  },

  formula: (): FormatError[] => {
    return [];
  },

  singleSelect: (value: string, field: SimpleField): FormatError[] => {
    return new Validator(value, field)
      .shouldNotEmptyStringIfNotNullable()
      .end();
  },

  multiSelect: (value: string[], field: SimpleField): FormatError[] => {
    return new Validator(value, field)
      .shouldHaveAtLeastOneElementIfNotNullable()
      .end();
  },

  json: (value: string, field: SimpleField): FormatError[] => {
    return new Validator(value, field).shouldBeValidJSONString().end();
  },

  array: (value: unknown[], field: SimpleField): FormatError[] => {
    return new Validator(value, field).shouldNotBeNullIfNotNullable().end();
  },

  lastEditedAt: (): FormatError[] => {
    return [];
  },

  lastEditedBy: (): FormatError[] => {
    return [];
  },

  createdAt: (): FormatError[] => {
    return [];
  },

  createdBy: (): FormatError[] => {
    return [];
  },

  reference: (): FormatError[] => {
    return [];
  },

  multiReference: (): FormatError[] => {
    return [];
  },

  html: (value: string, field: SimpleField): FormatError[] => {
    return new Validator(value, field)
      .shouldNotEmptyStringIfNotNullable()
      .end();
  },
  embeddings: (): FormatError[] => {
    return [];
  },
  python: (): FormatError[] => {
    return [];
  },
  rowInfo: (): FormatError[] => {
    return [];
  },
};

/**
 * 値がfield typeのフォーマットとして適当であるかを検査する。
 * 型の検査についてはtypeCheck.tsの責務
 * @return {string} - フォーマットエラーオブジェクトの一覧を返す
 */
const validateFieldValueFormat = <T extends SimpleField["type"]>(
  recordModelValue: RecordModelValueWithSpecificType<T>,
  field: SimpleField
): FormatError[] => {
  // skipするfield
  if (field.name.startsWith("morph_reserved_")) {
    return [];
  }

  // 以下チェック
  if (recordModelValue.value === undefined) {
    return [requiredError(field)];
  }

  const nullable = field.nullable === true;
  if (recordModelValue.value === null) {
    return nullable ? [] : [requiredError(field)];
  }

  return recordValueValueFormatValidators[recordModelValue.type](
    recordModelValue.value,
    field
  );
};

export { validateFieldValueFormat, type FormatError };
