import useApiTable from "~/api/useApiTable";
import { DashboardUpdateNormalizedTableScheduleRequestBody } from "@usemorph/morph-dashboard-types";
import { tablesKeys } from "../keys";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

type UseUpdateNormalizedTableScheduleMutationParams = {
  teamSlug: string;
  databaseId: string;
};

const useUpdateNormalizedTableScheduleMutation = ({
  teamSlug,
  databaseId,
}: UseUpdateNormalizedTableScheduleMutationParams) => {
  const { _updateNormalizedTableSchedule } = useApiTable();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      requestBody,
      normalizedTableScheduleId,
    }: {
      normalizedTableScheduleId: string;
      requestBody: DashboardUpdateNormalizedTableScheduleRequestBody;
    }) => {
      return _updateNormalizedTableSchedule({
        teamSlug,
        normalizedTableScheduleId,
        requestBody,
      });
    },
    onSuccess: () => {
      const keys = tablesKeys.listNormalizedTableSchedule({
        databaseId,
        teamSlug,
      });
      broadcast(keys);
      return invalidateAll(client, keys);
    },
  };
};

export { useUpdateNormalizedTableScheduleMutation };
