import {
  BigNumberFieldTypeClientModel,
  CalculationFieldTypeClientModel,
  DecimalFieldTypeClientModel,
  NumberFieldTypeClientModel,
} from "~/clientModel/fields/field/fieldType";
import {
  GenerateTextAndCalculationCommonSettingsInput,
  GenerateTextAndCalculationCommonSettingsInputProps,
} from "./GenerateTextAndCalculationCommonSettingsInput";

const syncValueTypeOptions = [
  new NumberFieldTypeClientModel(),
  new BigNumberFieldTypeClientModel(),
  new DecimalFieldTypeClientModel(),
] as const;

type CalculationSettingsInputProps = Omit<
  GenerateTextAndCalculationCommonSettingsInputProps<
    typeof syncValueTypeOptions
  >,
  "syncValueTypeOptions" | "onSyncValueTypeChange" | "fieldType"
> & {
  fieldType: CalculationFieldTypeClientModel;
};

export const CalculationSettingsInput = (
  props: CalculationSettingsInputProps
) => {
  const { field, onFieldChange, fieldType } = props;

  const handleSyncValueTypeChange = (
    syncedValueType:
      | NumberFieldTypeClientModel
      | BigNumberFieldTypeClientModel
      | DecimalFieldTypeClientModel
  ) => {
    onFieldChange(
      field.updateType(fieldType.updateSyncedValueType(syncedValueType))
    );
  };

  return (
    <GenerateTextAndCalculationCommonSettingsInput
      {...props}
      syncValueTypeOptions={syncValueTypeOptions}
      onSyncValueTypeChange={handleSyncValueTypeChange}
    />
  );
};
