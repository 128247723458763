import { Grid as RadixThemeGrid } from "@radix-ui/themes";
import { CSS, styled } from "@stitches/react"
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from "react"

type RadixThemeGridProps = ComponentPropsWithoutRef<
  typeof RadixThemeGrid
>;

type GridProps = {
  hoverable?: boolean;
  css?: CSS
} & Omit<RadixThemeGridProps, "css">

const StyledGrid = styled(RadixThemeGrid, {})
const HoverableStyle: CSS = {
  transitionProperty: "background-color",
  transitionDuration: "200ms",
  "&:hover": {
    backgroundColor: "$hoverColor"
  },
  "&:active": {
    backgroundColor: "$activeColor"
  }
}

const _Grid = (props: GridProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { hoverable = false, css } = props

  const radixGridProps = (({
    css,
    hoverable,
    ...rest
  }) => rest)(props);

  return (
    <StyledGrid
      ref={ref}
      css={hoverable ? { ...HoverableStyle, ...css } : css}
      {...radixGridProps}
    ></StyledGrid>
  )
}

const Grid = forwardRef<HTMLDivElement, GridProps>(_Grid)

export { Grid }
