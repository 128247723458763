import { BsLink45Deg } from "react-icons/bs";
import { useNodes } from "reactflow";
import { match, P } from "ts-pattern";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import {
  CanvasCellSourceClientModel,
  CanvasCellViewClientModel,
} from "~/clientModel/canvas/CanvasCellClientModel";
import { CanvasCreateCellClientModel } from "~/clientModel/canvas/CanvasCreateCellClientModel";
import { UseExecutable } from "~/clientModel/executable";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { JoinSourceAndView } from "./JoinSourceAndView";
import { JoinSources } from "./JoinSources";
import { JoinViews } from "./JoinViews";
import { FieldsClientModel } from "~/clientModel/fields";

type JoinControlProps = {
  cells: CanvasCellClientModelUnion[];
  useViewFieldsLoadable: UseLoadable<{ viewId: string }, FieldsClientModel>;
  useSourceFieldsLoadable: UseLoadable<
    { tableSlug: string },
    FieldsClientModel
  >;
  useCreateCellExecutable: UseExecutable<
    void,
    CanvasCreateCellClientModel,
    CanvasCellClientModelUnion,
    unknown
  >;
};

const JoinControl = (props: JoinControlProps) => {
  const {
    cells,
    useSourceFieldsLoadable,
    useViewFieldsLoadable,
    useCreateCellExecutable,
  } = props;

  /**
   * React Flow Selection
   */
  const allNodes = useNodes();
  const selectedNodes = allNodes.filter((node) => node.selected);
  const selectedNodeIds = selectedNodes.map((node) => node.id);
  const selectedCells = cells.filter((cell) => {
    return selectedNodeIds.includes(cell.cellId);
  });

  const selectedSourceCells = selectedCells.filter(
    (cell): cell is CanvasCellSourceClientModel => {
      return match(cell)
        .with(P.instanceOf(CanvasCellSourceClientModel), () => true)
        .otherwise(() => false);
    }
  );
  const selectedViewCells = selectedCells.filter(
    (cell): cell is CanvasCellViewClientModel => {
      return match(cell)
        .with(P.instanceOf(CanvasCellViewClientModel), () => true)
        .otherwise(() => false);
    }
  );

  if ([...selectedSourceCells, ...selectedViewCells].length !== 2) return null;

  return (
    <>
      <SimpleDropdownMenu
        trigger={
          <Box
            css={{
              boxShadow: "var(--shadow-4)",
            }}
          >
            <Button variant="primary" size="sm">
              <BsLink45Deg />
              Join
            </Button>
          </Box>
        }
      >
        {match([selectedSourceCells.length, selectedViewCells.length])
          .with([2, 0], () => {
            return (
              <JoinSources
                mainTableSlug={selectedSourceCells[0].tableSlug || ""} // nullなわけないんだけど..検討
                mainTableName={selectedSourceCells[0].tableSlug || ""}
                targetTableSlug={selectedSourceCells[1].tableSlug || ""}
                targetTableName={selectedSourceCells[1].tableSlug || ""}
                parentCells={selectedCells}
                useSourceFieldsLoadalbe={useSourceFieldsLoadable}
                useCreateCellExecutable={useCreateCellExecutable}
              />
            );
          })
          .with([0, 2], () => {
            return (
              <JoinViews
                mainViewId={selectedViewCells[0].viewId || ""}
                mainViewName={selectedViewCells[0].viewId || ""}
                targetViewId={selectedViewCells[1].viewId || ""}
                targetViewName={selectedViewCells[1].viewId || ""}
                parentCells={selectedCells}
                useViewFieldsLoadable={useViewFieldsLoadable}
                useCreateCellExecutable={useCreateCellExecutable}
              />
            );
          })
          .with([1, 1], () => {
            return (
              <JoinSourceAndView
                tableSlug={selectedSourceCells[0].tableSlug || ""}
                tableName={selectedSourceCells[0].tableSlug || ""}
                viewId={selectedViewCells[0].viewId || ""}
                viewName={selectedViewCells[0].viewId || ""}
                parentCells={selectedCells}
                useSourceFieldsLoadable={useSourceFieldsLoadable}
                useViewFieldsLoadable={useViewFieldsLoadable}
                useCreateCellExecutable={useCreateCellExecutable}
              />
            );
          })
          .otherwise(() => (
            <></>
          ))}
      </SimpleDropdownMenu>
    </>
  );
};

export { JoinControl };
