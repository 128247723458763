import {
  DashboardNotebookPublicPagesResponse,
  DashboardPageDetailObject,
  DashboardPageObject,
  DashboardPagePublicDetailResponse,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

export default function useApiPage() {
  const { executeRequest, executeRequestAxiosRaw } = useApi();

  const _createPage = async ({
    teamSlug,
    databaseId,
    notebookId,
    pageName,
    description,
    isPrivate,
  }: {
    teamSlug: string;
    databaseId: string;
    notebookId: string;
    pageName: string;
    description: string;
    isPrivate: boolean;
  }): Promise<DashboardPageObject> => {
    return executeRequest(
      "post",
      `/page`,
      undefined,
      {
        teamSlug,
      },
      {
        pageName,
        databaseId,
        description,
        isPrivate,
        notebookId,
      }
    );
  };

  const _updatePage = async ({
    pageId,
    teamSlug,
    pageName,
    description,
    isPrivate,
    isPublic,
    publicApiKeyType,
  }: {
    pageId: string;
    teamSlug: string;
    pageName: string;
    description?: string | null;
    isPrivate?: boolean;
    isPublic?: boolean;
    publicApiKeyType?: string;
  }): Promise<DashboardPageObject> => {
    return executeRequest(
      "put",
      `/page/${pageId}`,
      undefined,
      {
        teamSlug,
      },
      {
        pageName,
        description,
        isPrivate,
        isPublic,
      }
    );
  };

  const _addNotebookCellToPage = async ({
    teamSlug,
    pageId,
    notebookCellId,
    x,
    y,
    w,
    h,
  }: {
    teamSlug: string;
    pageId: string;
    notebookCellId: string;
    x: number;
    y: number;
    w: number;
    h: number;
  }): Promise<DashboardPageDetailObject> => {
    return executeRequest(
      "post",
      `/page/${pageId}/cell`,
      undefined,
      {
        teamSlug,
      },
      {
        notebookCellId,
        x,
        y,
        w,
        h,
      }
    );
  };

  const _findPageDetail = async ({
    teamSlug,
    pageId,
  }: {
    teamSlug: string;
    pageId: string;
  }): Promise<DashboardPageDetailObject> => {
    return executeRequest("get", `/page/${pageId}`, undefined, {
      teamSlug,
    });
  };

  const _updatePageCell = async ({
    teamSlug,
    pageId,
    cellId,
    notebookCellId,
    w,
    h,
    x,
    y,
  }: {
    teamSlug: string;
    pageId: string;
    cellId: string;
    notebookCellId: string;
    w: number;
    h: number;
    x: number;
    y: number;
  }): Promise<{ message: string }> => {
    return executeRequest(
      "put",
      `/page/${pageId}/cell/${cellId}`,
      undefined,
      {
        teamSlug,
      },
      {
        notebookCellId,
        w,
        h,
        x,
        y,
      }
    );
  };

  const _removePageCell = async ({
    cellId,
    pageId,
    teamSlug,
  }: {
    cellId: string;
    pageId: string;
    teamSlug: string;
  }): Promise<{ message: string }> => {
    return executeRequest(
      "delete",
      `/page/${pageId}/cell/${cellId}`,
      undefined,
      {
        teamSlug,
      }
    );
  };

  const _getPublishedNotebookPages = async ({
    teamSlug,
    apiKey,
  }: {
    teamSlug: string;
    apiKey: string;
  }): Promise<DashboardNotebookPublicPagesResponse> => {
    const { data } =
      await executeRequestAxiosRaw<DashboardNotebookPublicPagesResponse>(
        "GET",
        `/public/notebook/pages`,
        undefined,
        {
          teamSlug,
          "x-api-key": apiKey,
        }
      );
    return data;
  };

  const _findPublishedPageDetail = async ({
    teamSlug,
    apiKey,
    pageId,
  }: {
    teamSlug: string;
    apiKey: string;
    pageId: string;
  }): Promise<DashboardPagePublicDetailResponse> => {
    const { data } =
      await executeRequestAxiosRaw<DashboardPagePublicDetailResponse>(
        "GET",
        `/public/page/${pageId}`,
        undefined,
        {
          teamSlug,
          "x-api-key": apiKey,
        }
      );
    return data;
  };

  const _deletePage = async ({
    pageId,
    teamSlug,
  }: {
    pageId: string;
    teamSlug: string;
  }): Promise<{ message: string }> => {
    return executeRequest("delete", `/page/${pageId}`, undefined, {
      teamSlug,
    });
  };

  return {
    _createPage,
    _addNotebookCellToPage,
    _findPageDetail,
    _updatePageCell,
    _removePageCell,
    _updatePage,
    _getPublishedNotebookPages,
    _findPublishedPageDetail,
    _deletePage,
  };
}
