import { Input } from "~/components_next/Input";
import {
  ClearValueButton,
  ClearValueButtonWrapper,
} from "../../atoms/ClearValueButton";
import { RecordValueInputCommonProps } from "../../type";
import { forwardRef, ForwardedRef } from "react";

type BigNumberInputProps = RecordValueInputCommonProps<"bigNumber">;

const BigNumberInput = forwardRef(function BigNumberInput(
  props: BigNumberInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { value, onChange, errorMessages, isNullable, isReadOnly, size } =
    props;

  return (
    <ClearValueButtonWrapper>
      <Input
        size={size}
        variant="primary"
        value={value ?? ""}
        type="number"
        onChange={(event) =>
          event.target.value.length > 0
            ? onChange?.(event.target.value)
            : onChange?.(null)
        }
        errorMessage={errorMessages}
        readOnly={isReadOnly}
        ref={ref}
      />
      {isNullable && (
        <ClearValueButton
          size={size}
          isDisabled={isReadOnly}
          onClear={() => onChange?.(null)}
        />
      )}
    </ClearValueButtonWrapper>
  );
});

export { BigNumberInput };
