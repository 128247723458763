import {
  DashboardPaymentResponse,
  DashboardPlanResponse,
  PaymentCategoryType,
  DashboardTrialResponse,
} from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";
import { VersionClientModel } from "~/clientModel/version/VersionClientModel";

export type PlanStatusType =
  | "hide"
  | "currentTrial"
  | "currentPlan"
  | "selectButton"
  | "trialButton";

export class PaymentClientModel {
  constructor(private readonly data: DashboardPaymentResponse) { }

  /**
   * Getters
   */
  get isSubscribed(): boolean {
    return this.data.isSubscribed;
  }

  get seatNum(): number {
    return this.data.seatNum;
  }

  get plan(): DashboardPlanResponse | null {
    return this.data.plan;
  }

  get subscriptionEndedAt(): string | null {
    return this.data.subscriptionEndedAt;
  }

  get hasCardAlert(): boolean {
    return this.data.hasCardAlert;
  }

  get paymentType(): PaymentCategoryType | null {
    return this.data.paymentType;
  }

  get currentTrial(): DashboardTrialResponse | null {
    return this.data.currentTrial;
  }

  get trialHistories(): DashboardTrialResponse[] {
    return this.data.trialHistories;
  }

  get isLimitExceeded(): boolean {
    return this.data.isLimitExceeded;
  }

  get creditsUsage(): number {
    return this.data.usedUpdates;
  }

  get creditsLimit(): number {
    return this.data.availableMonthlyUpdates;
  }

  get creditsUsagePercent(): number {
    return Math.round(
      (this.data.usedUpdates / this.data.availableMonthlyUpdates) * 100
    );
  }

  get userLangage(): "ja" | "en" {
    const browserLanguage = window.navigator.language;
    const lang = match([this.data.plan?.currency, browserLanguage])
      .with(["yen", P._], () => "ja")
      .with(["dollar", P._], () => "en")
      .with([P._, "ja"], () => "ja")
      .with([P._, "ja-JP"], () => "ja")
      .otherwise(() => "en");

    return lang as "ja" | "en"
  }

  get currentPlanId(): string {
    return this.data.plan?.planId || "free";
  }

  public isTrialAvailable(versions: VersionClientModel[]): boolean {
    const avaliableVersions = versions.filter(
      (v) =>
        !this.trialHistories.some((th) => th.version.versionId === v.versionId)
    );
    return (
      !this.data.isSubscribed &&
      this.data?.currentTrial === null &&
      avaliableVersions.length > 0
    );
  }

  public getTrialVersionId(versions: VersionClientModel[]) {
    const avaliableVersions = versions.filter(
      (v) =>
        !this.trialHistories.some((th) => th.version.versionId === v.versionId)
    );
    if (avaliableVersions.length > 0) {
      return avaliableVersions[0].versionId;
    }
    return undefined;
  }
}
