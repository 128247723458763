import { useQueryClient } from "react-query";
import useApiNotebook from "~/api/useApiNotebook";
import { commonQueryOptions } from "~/serverStateStore/util/commonQueryOptions";
import { notebookKeys } from "../keys";

type UseGetPublicCellQueryProps = {
  teamSlug: string;
  apiKey: string;
};

const useGetPublicCellQuery = ({
  teamSlug,
  apiKey,
}: UseGetPublicCellQueryProps) => {
  const { _getPublicCell } = useApiNotebook();

  const client = useQueryClient();
  client.setQueryDefaults(
    notebookKeys.publicCell({
      teamSlug,
      apiKey,
    }),
    {
      ...commonQueryOptions,
      staleTime: 60 * 1000,
    }
  );

  return {
    queryKey: notebookKeys.publicCell({
      teamSlug,
      apiKey,
    }),
    queryFn: () => {
      return _getPublicCell({
        teamSlug,
        apiKey,
      });
    },
  };
};

export { useGetPublicCellQuery };
