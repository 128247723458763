import { useMutation } from "react-query";
import { useComposeExecutable } from "~/clientModel/executable";
import { useMarkAsReadNotificationMutation } from "~/serverStateStore/notification";


export const createUseMarkAsReadNotificationExecutable = (props: {
  teamSlug: string;
}) => {
  const { teamSlug } = props

  return () => useComposeExecutable(
    useMutation(
      useMarkAsReadNotificationMutation({
        teamSlug,
      })
    )
  )
}
