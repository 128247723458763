import { SimpleField } from "@usemorph/morph-dashboard-types";
import { FieldToggleListItem } from "./FieldToggleListItem";
import { DragEndEvent, DndContext, pointerWithin } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { styled } from "~/stitches";

const FieldToggleList = styled("ul", {
  listStyleType: "none",
  ml: 0,
  maxHeight: "50vh",
  overflowY: "auto",
  position: "relative",
});

type FieldSettingFormProps = {
  fields: SimpleField[];
  onIsHiddenUpdate: (args: { field: SimpleField; isHidden: boolean }) => void;
  onFieldOrderUpdate: (reorderedFields: SimpleField[]) => void;
  showTableSlug: boolean;
};

export const FieldSettingForm = (props: FieldSettingFormProps) => {
  const { fields, onFieldOrderUpdate, onIsHiddenUpdate, showTableSlug } = props;

  if (!fields) {
    return null;
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = fields.findIndex((field) => field.name === active.id);
      const newIndex = fields.findIndex((field) => field.name === over.id);
      const reordered = arrayMove([...fields], oldIndex, newIndex);
      onFieldOrderUpdate(reordered);
    }
  };

  return (
    <>
      <DndContext onDragEnd={handleDragEnd} collisionDetection={pointerWithin}>
        <SortableContext
          items={fields.map((field) => field.name)}
          strategy={verticalListSortingStrategy}
        >
          <FieldToggleList>
            {fields.map((field) => (
              <FieldToggleListItem
                key={field.name}
                field={field}
                isChecked={!field.isHidden}
                onChange={(checked) =>
                  onIsHiddenUpdate({
                    field,
                    isHidden: !checked,
                  })
                }
                showTableSlug={showTableSlug}
                draggable
              />
            ))}
          </FieldToggleList>
        </SortableContext>
      </DndContext>
    </>
  );
};
