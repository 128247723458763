import { Flex } from "@radix-ui/themes";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { UseExecutable } from "~/clientModel/executable";
import { MessageClientModel } from "~/clientModel/general";
import { NotificationClientModelFactory, ListNotificationClientModel } from "~/clientModel/notification"
import { Box } from "~/components_next/Box"
import { Text } from "~/components_next/Text";
import { UseMarkAsReadNotificationMutationProps } from "~/serverStateStore/notification/mutations/useMarkAsReadNotificationMutation";
import { NotificationListItem } from "./NotificationListItem";
import { SimpleTabs } from "~/components_next/Tabs";
import { NotificationControl } from "./NotificationControl";
import { useCallback, useState } from "react";
import { match } from "ts-pattern";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { UseListNotificationQueryProps } from "~/containers/notification/createUseListNotificationLoadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";

interface NotificationListProps {
  useListNotificationLoadable: UseLoadable<UseListNotificationQueryProps, ListNotificationClientModel>
  useMarkAsReadExecutable: UseExecutable<void, UseMarkAsReadNotificationMutationProps, MessageClientModel, unknown>
}

export const NotificationList = (props: NotificationListProps) => {
  const { useListNotificationLoadable, useMarkAsReadExecutable } = props

  // TODO: Infinite Loading
  const [listRequest, setListRequest] = useState<UseListNotificationQueryProps>({
    limit: 9999,
    skip: 0,
  })
  const listNotificationLoadable = useListNotificationLoadable(listRequest)
  const markAsReadExecutable = useMarkAsReadExecutable()

  const handleChangeTab = useCallback(
    (data: string) => {
      const _data = data as "Inbox" | "Archived"
      match(_data)
        .with("Inbox", () => setListRequest(e => {
          return { ...e, isArchived: false, }
        }))
        .with("Archived", () => setListRequest(e => {
          return { ...e, isArchived: true, }
        }))
        .exhaustive()
    },
    []
  )

  return (
    <>
      <SimpleTabs tabLabels={["Inbox", "Archived",]} onChange={handleChangeTab}>

        <Box>
          <WithFallback loadables={[listNotificationLoadable]}>
            {([listNotification]) => (
              <>
                <NotificationControl
                  markAsReadExecutable={markAsReadExecutable}
                />
                {
                  listNotification.allItems.length > 0
                    ? (
                      listNotification.allItems.map(notification => {
                        return (
                          <NotificationListItem
                            key={notification.notificationId}
                            notification={NotificationClientModelFactory.fromNotificationResponse(notification)}
                            markAsReadExecutable={markAsReadExecutable}
                          />
                        )
                      })
                    )
                    : (
                      <Flex mt="2" direction="column" align="center">
                        <AiOutlineCheckCircle color="gray" size="40" />
                        <Box my="2">
                          <Text variant="subheading">{`You're all caught up`}</Text>
                        </Box>
                      </Flex>
                    )
                }
              </>
            )}
          </WithFallback>
        </Box>

        <Box>
          <WithFallback loadables={[listNotificationLoadable]}>
            {([listNotification]) => (
              <>
                {/* <NotificationControl
                  markAsReadExecutable={markAsReadExecutable}
                /> */}
                {
                  listNotification.allItems.length > 0
                    ? (
                      listNotification.allItems.map(notification => {
                        return (
                          <NotificationListItem
                            key={notification.notificationId}
                            notification={NotificationClientModelFactory.fromNotificationResponse(notification)}
                            markAsReadExecutable={markAsReadExecutable}
                          />
                        )
                      })
                    )
                    : (
                      <Flex mt="2" direction="column" align="center">
                        <AiOutlineCheckCircle color="gray" size="40" />
                        <Box my="2">
                          <Text variant="subheading">{`No archived notifications.`}</Text>
                        </Box>
                      </Flex>
                    )
                }
              </>
            )}
          </WithFallback>
        </Box>

      </SimpleTabs>
    </>
  )
}
