import {
  BsCalendar,
  BsCaretDown,
  BsCheckSquare,
  BsClock,
  BsEnvelope,
  BsFile,
  BsFiletypeHtml,
  BsImage,
  BsLink,
  BsListCheck,
  BsPeople,
  BsPhone,
  BsSortNumericDown,
  BsTextLeft,
  BsType,
} from "react-icons/bs";
import {
  MdDataArray,
  MdDataObject,
  MdFunctions,
  MdOutlineSync,
} from "react-icons/md";
import { LuText, LuCalculator, LuSigma, LuSparkles } from "react-icons/lu";
import { match } from "ts-pattern";
import { FieldClientModel } from "~/clientModel/fields/field";
import {
  MorphIconBigInteger,
  MorphIconDateTime,
  MorphIconFloat,
  MorphIconInteger,
} from "../MorphIcon";
import { IconCommonProps } from "../type";
import { getSizePx, getColor } from "./constants";

/**
 * ClientModelを使った実装
 */

type FieldIconProps = {
  type: FieldClientModel["type"];
} & IconCommonProps;

const FieldIcon = (props: FieldIconProps) => {
  const { size, type } = props;
  const iconProps = { size: getSizePx(size), color: getColor("gray") };

  return match(type)
    .with({ type: "autoNumber" }, () => <BsSortNumericDown {...iconProps} />)
    .with({ type: "autoBigNumber" }, () => <BsSortNumericDown {...iconProps} />)
    .with({ type: "decimal" }, () => <MorphIconFloat {...iconProps} />)
    .with({ type: "number" }, () => <MorphIconInteger />)
    .with({ type: "bigNumber" }, () => <MorphIconBigInteger />)
    .with({ type: "shortText" }, () => <BsType {...iconProps} />)
    .with({ type: "longText" }, () => <BsTextLeft {...iconProps} />)
    .with({ type: "email" }, () => <BsEnvelope {...iconProps} />)
    .with({ type: "url" }, () => <BsLink {...iconProps} />)
    .with({ type: "phoneNumber" }, () => <BsPhone {...iconProps} />)
    .with({ type: "date" }, () => <BsCalendar {...iconProps} />)
    .with({ type: "datetime" }, () => <MorphIconDateTime {...iconProps} />)
    .with({ type: "createdAt" }, () => <BsClock {...iconProps} />)
    .with({ type: "lastEditedAt" }, () => <BsClock {...iconProps} />)
    .with({ type: "time" }, () => <BsClock {...iconProps} />)
    .with({ type: "boolean" }, () => <BsCheckSquare {...iconProps} />)
    .with({ type: "createdBy" }, () => <BsPeople {...iconProps} />)
    .with({ type: "lastEditedBy" }, () => <BsPeople {...iconProps} />)
    .with({ type: "singleSelect" }, () => <BsCaretDown {...iconProps} />)
    .with({ type: "multiSelect" }, () => <BsListCheck {...iconProps} />)
    .with({ type: "image" }, () => <BsImage {...iconProps} />)
    .with({ type: "attachment" }, () => <BsFile {...iconProps} />)
    .with({ type: "formula" }, () => <MdFunctions {...iconProps} />)
    .with({ type: "syncValue" }, () => <MdOutlineSync {...iconProps} />)
    .with({ type: "generateText" }, () => <LuText {...iconProps} />)
    .with({ type: "calculation" }, () => <LuCalculator {...iconProps} />)
    .with({ type: "aggregateValue" }, () => <LuSigma {...iconProps} />)
    .with({ type: "smartFunction" }, () => <LuSparkles {...iconProps} />)
    .with({ type: "array" }, () => <MdDataArray {...iconProps} />)
    .with({ type: "json" }, () => <MdDataObject {...iconProps} />)
    .with({ type: "html" }, () => <BsFiletypeHtml {...iconProps} />)
    .exhaustive();
};

export { FieldIcon };
