import { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useTeam } from "~/serverStateStore";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { AccessAuthoritySelect } from "./AccessAuthoritySelect";
import { AccessAuthority } from "./types";
import { convertAuthorityLabelToPermission } from "./permissionUtil";
import { useErrorToast } from "~/components_next/Error";
import { useCreateResourceMemberMutation } from "~/serverStateStore/resourceMember";
import {
  DashboardUserObject,
  ResourceGroupObject,
  ResourceNotebookSchema,
  ResourceTableSchema,
  ResourceViewSchema,
} from "@usemorph/morph-dashboard-types";
import { useListResourceGroups } from "~/serverStateStore/resourceGroup";
import { Button } from "~/components_next/Button";
import { Flex } from "~/components_next/Flex";
import { SimpleMultiSelect } from "~/components_next/Select";
import { UserMention } from "~/components_next/User";
import { Text } from "~/components_next/Text";

type InviteOption =
  | {
      type: "user";
      value: DashboardUserObject;
    }
  | {
      type: "resourceGroup";
      value: ResourceGroupObject;
    };

type InvitationInputProps = {
  resource: ResourceTableSchema | ResourceNotebookSchema | ResourceViewSchema;
};

const InvitationInput = (props: InvitationInputProps) => {
  const { resource } = props;

  const teamSlug = useTeamSlug();

  const { data: team } = useTeam({ teamSlug });
  const { data: resourceGroups } = useQuery({
    ...useListResourceGroups({
      teamSlug,
      resource,
      limit: 9999,
      skip: 0,
    }),
  });

  const { errorToast } = useErrorToast({});

  /**
   * Invite
   */
  const [authority, setAuthority] = useState<AccessAuthority>("Read & Write");

  const [inviteTargets, setInviteTargets] = useState<InviteOption[]>([]);
  const {
    mutateAsync: createResourceMember,
    isLoading: isCreatingResourceMember,
  } = useMutation({
    ...useCreateResourceMemberMutation({
      teamSlug,
      resource,
    }),
  });

  const handleInvite = async () => {
    const permission = convertAuthorityLabelToPermission(authority);
    if (!permission) return;

    const targetUserIds = inviteTargets.flatMap(({ type, value }) => {
      return type === "user" ? value.userId : [];
    });

    if (targetUserIds.length > 0) {
      try {
        await createResourceMember({
          userIds: targetUserIds,
          permission,
        });
        setInviteTargets([]);
      } catch (e) {
        errorToast(e);
      }
    }
    return;
  };

  /**
   * Options
   */
  const groupedOptions: {
    groupLabel: string;
    groupOptions: InviteOption[];
  }[] = useMemo(() => {
    const teamOptions: InviteOption[] =
      team?.user.map((user) => {
        return {
          type: "user",
          value: user,
        };
      }) || [];

    const resourceGroupOptions: InviteOption[] =
      resourceGroups?.items.map((group) => {
        return {
          type: "resourceGroup",
          value: group,
        };
      }) || [];

    return [
      { groupLabel: "user", groupOptions: teamOptions },
      {
        groupLabel: "Resource Group",
        groupOptions: resourceGroupOptions,
      },
    ];
  }, [team, resourceGroups]);

  const getValueFromOption = useCallback((option: InviteOption) => {
    return option.type === "user"
      ? option.value.userId
      : option.value.group.groupId;
  }, []);

  /**
   * UI
   */

  return (
    <Flex py="2" align="start" gap="2">
      <Flex style={{ width: "320px" }}>
        <SimpleMultiSelect
          variant="primary"
          size="sm"
          value={inviteTargets}
          options={groupedOptions}
          isGrouped={true}
          onChange={(value) => setInviteTargets(value ?? [])}
          getValueFromOption={getValueFromOption}
          renderBadge={(option) => {
            if (option.type === "user") {
              return <UserMention user={option.value} />;
            } else {
              return <Text>{option.value.group.groupName}</Text>;
            }
          }}
          renderListItem={(option) => {
            if (option.type === "user") {
              return <UserMention user={option.value} />;
            } else {
              return <Text>{option.value.group.groupName}</Text>;
            }
          }}
        />
      </Flex>

      <AccessAuthoritySelect
        authority={authority}
        onChange={setAuthority}
        options={["Read & Write", "Read Only"]}
      />
      <Button
        variant="primary"
        onClick={handleInvite}
        isLoading={isCreatingResourceMember}
        size="sm"
      >
        Invite
      </Button>
    </Flex>
  );
};

export { InvitationInput };
