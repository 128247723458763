import {
  RecordFilterConditionUnit,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { BsPlus } from "react-icons/bs";
import { match, P } from "ts-pattern";
import { Button } from "~/components_next/Button";
import { TableViewCellFilterConditionUnitForm } from "./TableViewCellFilterConditionUnitForm";
import { Box, Flex } from "@radix-ui/themes";
import { FlatFilterCondition } from "~/features/Fields/FilterCondition/flatFilterConditionTypes";
import { FilterConditionLogicalOperatorSelect } from "~/features/Fields/FilterCondition/FilterConditionLogicSelect";
import { ViewCellVariable } from "~/features/SourceAndViews/ViewCell/common/states/variables";

type FlatFilterConditionFormProps = {
  simpleFields: [SimpleField, ...SimpleField[]];
  variables: ViewCellVariable[];
  flatFilterCondition: FlatFilterCondition | null;
} & (
  | {
      isReadOnly?: false;
      onChangeFlatFilterCondition: (
        filterCondition: FlatFilterCondition | null
      ) => void;
    }
  | {
      isReadOnly: true;
    }
);

const FlatFilterConditionForm = (props: FlatFilterConditionFormProps) => {
  const { simpleFields, flatFilterCondition, isReadOnly, variables } = props;

  const { type, conditionUnits } = match(flatFilterCondition)
    .with({ and: P.select() }, (conditionUnits) => {
      return {
        type: "and",
        conditionUnits,
      } as const;
    })
    .with({ or: P.select() }, (conditionUnits) => {
      return {
        type: "or",
        conditionUnits,
      } as const;
    })
    .with(null, () => {
      return {
        type: "none",
        conditionUnits: [],
      } as const;
    })
    .exhaustive();

  const handleAddConditionUnit = () => {
    if (isReadOnly) return;

    const emptyConditionUnit: RecordFilterConditionUnit = {
      key: simpleFields[0].name,
      operator: "equal",
      value: null,
    };
    const newFilterCondition: FlatFilterCondition = match(type)
      .with("and", () => {
        return {
          and: [...conditionUnits, emptyConditionUnit],
        };
      })
      .with("or", () => {
        return {
          or: [...conditionUnits, emptyConditionUnit],
        };
      })
      .with("none", () => {
        return {
          and: [...conditionUnits, emptyConditionUnit],
        };
      })
      .exhaustive();
    props.onChangeFlatFilterCondition(newFilterCondition);
  };

  const handleRemoveConditionUnit = (index: number) => {
    if (isReadOnly) return;
    if (type === "none") return;

    const newFilterConditionUnits: RecordFilterConditionUnit[] = [
      ...conditionUnits,
    ].filter((_, i) => i !== index);

    if (newFilterConditionUnits.length === 0) {
      props.onChangeFlatFilterCondition(null);
    } else {
      props.onChangeFlatFilterCondition({
        [type]: newFilterConditionUnits,
      } as FlatFilterCondition); // type is "and" or "or", but TS doesn't infer it
    }
  };

  const handleChangeConditionUnit = (
    conditionUnit: RecordFilterConditionUnit,
    index: number
  ) => {
    if (type === "none") return;
    if (isReadOnly) return;

    const newFilterConditionUnits: RecordFilterConditionUnit[] = [
      ...conditionUnits,
    ].map((unit, i) => (i === index ? conditionUnit : unit));

    props.onChangeFlatFilterCondition({
      [type]: newFilterConditionUnits,
    } as FlatFilterCondition); // type is "and" or "or", but TS doesn't infer it
  };

  const handleLogicalOperatorChange = (logicalOperator: "and" | "or") => {
    if (isReadOnly) return;

    const newFilterCondition: FlatFilterCondition = match(logicalOperator)
      .with("and", () => {
        return {
          and: [...conditionUnits],
        };
      })
      .with("or", () => {
        return {
          or: [...conditionUnits],
        };
      })
      .exhaustive();
    props.onChangeFlatFilterCondition(newFilterCondition);
  };

  return (
    <Flex direction="column" width="100%" align="stretch">
      {type !== "none" && conditionUnits.length > 0 && (
        <Flex direction="column" gap="1" mb="2" width="100%" align="stretch">
          {conditionUnits.map((conditionUnit, i) => (
            <Flex key={i} direction="column" gap="1" align="stretch">
              <TableViewCellFilterConditionUnitForm
                simpleFields={simpleFields}
                variables={variables}
                filterConditionUnit={conditionUnit}
                onRemove={() => handleRemoveConditionUnit(i)}
                onChange={(unit) => handleChangeConditionUnit(unit, i)}
                isReadOnly={isReadOnly}
              />
              {/* FilterConditionUnitFormの間だけに出す */}
              {i !== conditionUnits.length - 1 && (
                <Box style={{ alignSelf: "start" }}>
                  <FilterConditionLogicalOperatorSelect
                    logicalOperator={type}
                    onLogicalOperatorChange={handleLogicalOperatorChange}
                    isReadOnly={isReadOnly}
                  />
                </Box>
              )}
            </Flex>
          ))}
        </Flex>
      )}
      {!isReadOnly && (
        <Box style={{ alignSelf: "start" }}>
          <Button
            onClick={() => handleAddConditionUnit()}
            variant="tertiary"
            size="xs"
          >
            <BsPlus />
            Add Filter
          </Button>
        </Box>
      )}
    </Flex>
  );
};

export { FlatFilterConditionForm as TableViewCellFlatFilterConditionForm };
