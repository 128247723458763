import { useMemo } from "react";
import { useOthers } from "../liveblocks.config";
import { BaseUserMeta, User } from "@liveblocks/client";
import { usePresenceBasics } from "../hooks/usePresenceBasics";
import { Presence } from "../types";
import { useGetUserColor } from "../context/RealtimeCollaborationUtilContext";
import { Text } from "~/components_next/Text";
import { UserAvatar } from "~/components_next/User";
import { Flex } from "~/components_next/Flex";
import { Grid } from "~/components_next/Grid";

const PresenceIcon = (props: { other: User<Presence, BaseUserMeta> }) => {
  const { other } = props;

  const getUserColor = useGetUserColor();

  const colorScheme = useMemo(() => {
    const color = getUserColor(other.presence.user.userId);
    return color;
  }, [getUserColor, other.presence.user.userId]);

  return <UserAvatar size="sm" user={other.presence.user} />;
};
type PresenceIconsProps = {
  maxAvatars?: number;
};

const PresenceIcons = (props: PresenceIconsProps) => {
  const { maxAvatars } = props;

  const others = useOthers();

  const { path, user } = usePresenceBasics();

  const othersOnThePath = useMemo(() => {
    const duplicatedOthersOnThePath = others.filter(
      (other) =>
        other.presence.path === path &&
        other.presence.user.userId !== user?.userId // 自分は弾く
    );

    // 重複削除
    return Array.from(
      new Map(
        duplicatedOthersOnThePath.map((other) => [
          other.presence.user.userId,
          other,
        ])
      ).values()
    );
  }, [others, path, user]);

  const othersToShow = useMemo(() => {
    if (typeof maxAvatars === "number") {
      return othersOnThePath.slice(0, maxAvatars);
    } else {
      return othersOnThePath;
    }
  }, [othersOnThePath, maxAvatars]);

  return (
    <Flex direction="row" align="center" gap="2">
      <Grid columns={othersToShow.length.toString()} mr="3">
        {othersToShow.map((other) => (
          <PresenceIcon other={other} key={other.presence.user.userId} />
        ))}
      </Grid>
      {typeof maxAvatars === "number" &&
        othersOnThePath.length > maxAvatars && (
          <Text variant="description">
            +{othersOnThePath.length - maxAvatars}
          </Text>
        )}
    </Flex>
  );
};

export { PresenceIcons };
