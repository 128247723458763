import { useCallback, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import {
  DatabaseBasePage,
  NextHomePage,
  DataApiPage,
  SignupPage,
  TeamSelect,
  SessionExpiredPage,
  Page404,
  PageMaintenance,
  DataCollectionPage,
} from "~/pages";

import { stringToBoolean } from "./utils/stringUtils";
import { useMe } from "~/serverStateStore/me";
import { ErrorResponse } from "@usemorph/morph-dashboard-types";
import { RecoilRoot } from "recoil";
import { View } from "./features/SourceAndViews/View";
import { DatabaseHome } from "./features/DatabaseHome/DatabaseHome";
import { getPath, routes } from "./routing";
import { WidgetDataApiPage } from "./pages/Database/WidgetDataApiPage";
import { PublishedView } from "./features/PublishedView/PublishedView";
import { CanvasPage } from "./pages/v1/CanvasPage";
import { SourcePage } from "./pages/v1/SourcePage";
import { PublishedCellContainer } from "./containers/publishedCell/PublishedCellContainer";
import { CanvasPublicDashboardContainer } from "./containers/canvas/publicDashboard/CanvasPublicDashboardContainer";

export const useRouteGuard = () => {
  const { data: me, error: meError } = useMe();

  const navigate = useNavigate();
  const { teamSlug } = useParams();

  const isMaintenance = stringToBoolean(import.meta.env.VITE_MAINTENANCE_MODE);

  const navigateWithLog = useCallback(
    ({ path, message }: { path: string; message: string }) => {
      console.log(message);
      return navigate(path);
    },
    [navigate]
  );

  useEffect(() => {
    if (isMaintenance) {
      return navigate(getPath("maintenance", {}));
    }

    if (meError) {
      // todo: castやめる
      if (
        (meError as ErrorResponse).error === "auth_error" &&
        (meError as ErrorResponse).subCode === 3 &&
        location.pathname !== "/auth/signup"
      ) {
        navigate("/auth/signup");
        return;
      }
    }

    if (me) {
      const teamSlugExits = !!teamSlug;

      if (teamSlugExits) {
        const teamSlugExistsInMyTeams =
          me.teams.findIndex((team) => team.teamSlug === teamSlug) >= 0;

        if (teamSlugExistsInMyTeams) {
          // 正常パターン
          return;
        } else {
          return navigateWithLog({
            path: "/",
            message: "Specified TeamSlug not found on your teams list.",
          });
        }
      } else {
        // TODO: ここ、最後に踏んだteamSlugにしたい
        const firstTeam = me.teams.length > 0 ? me.teams[0] : undefined;
        if (firstTeam) {
          return navigateWithLog({
            path: `/${firstTeam.teamSlug}`,
            message: "TeamSlug not provided. initialize moving onto firstTeam.",
          });
        } else {
          return navigateWithLog({
            path: "/",
            message: "Something else Error happened. moving onto root path.",
          });
        }
      }
    }

    if (!teamSlug) {
      return navigateWithLog({
        path: "/",
        message: "TeamSlug not provided. moving onto root path.",
      });
    }
  }, [me, meError, teamSlug, navigateWithLog, navigate, isMaintenance]);

  return;
};

const RedirectToLogin = () => {
  const { loginWithRedirect, handleRedirectCallback } = useAuth0();

  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  if (!code) {
    loginWithRedirect();
  } else {
    handleRedirectCallback();
  }
  return <>Processing ...</>;
};

function App() {
  const { isAuthenticated, isLoading } = useAuth0();

  return (
    <>
      <RecoilRoot>
        <BrowserRouter>
          {isAuthenticated ? (
            <Routes>
              {/* next */}
              <Route index element={<TeamSelect />}></Route>
              <Route path="/:teamSlug" element={<NextHomePage />}></Route>

              {/* old database base */}
              <Route path={routes.databaseHome} element={<DatabaseBasePage />}>
                <Route
                  path={routes.databaseHome}
                  element={<DatabaseHome />}
                ></Route>
                <Route path={routes.source} element={<SourcePage />}></Route>
                <Route path={routes.view} element={<View />}></Route>
                <Route path={routes.notebook} element={<CanvasPage />}></Route>
                <Route
                  path={`${routes.canvas_v1_1}`}
                  element={<CanvasPage />}
                ></Route>
                <Route
                  path={`${routes.source_v1_1}`}
                  element={<SourcePage />}
                ></Route>
                <Route path={routes.dataApi} element={<DataApiPage />}></Route>
                <Route
                  path={routes.widgetDataApi}
                  element={<WidgetDataApiPage />}
                ></Route>
                {/* <Route
                  path="/next/:teamSlug/db/:databaseId/widget/:widgetId"
                  element={<Widget />}
                ></Route> */}
                <Route path={routes.dataCollection} element={<DataCollectionPage />}></Route>
              </Route>

              <Route path="/auth/signup" element={<SignupPage />} />

              {/* old */}
              <Route path="/auth/signup" element={<SignupPage />} />

              {/* Published */}
              <Route path={routes.publishedView} element={<PublishedView />} />
              <Route
                path={routes.publishedDashboard}
                element={<CanvasPublicDashboardContainer />}
              />
              <Route
                path={routes.publishedCell}
                element={<PublishedCellContainer />}
              />

              <Route path="/404" element={<Page404 />} />
              <Route path={routes.expire} element={<SessionExpiredPage />} />
              <Route path={routes.maintenance} element={<PageMaintenance />} />
              <Route path="*" element={<Page404 />} />
            </Routes>
          ) : isLoading ? (
            <></>
          ) : location.pathname.startsWith("/_public") ? ( // もっといい方法ある？
            <>
              <Routes>
                <Route
                  path={routes.publishedView}
                  element={<PublishedView />}
                />
                <Route
                  path={routes.publishedDashboard}
                  element={<CanvasPublicDashboardContainer />}
                />
                <Route
                  path={routes.publishedCell}
                  element={<PublishedCellContainer />}
                />
              </Routes>
            </>
          ) : (
            <RedirectToLogin></RedirectToLogin>
          )}
        </BrowserRouter>
      </RecoilRoot>
    </>
  );
}

export default App;
