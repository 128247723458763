import { RecordHistoryClientModel } from "./recordHistory";

type RecordHistoriesClientModelData = {
  recordHistories: RecordHistoryClientModel[];
  count: number;
};

export class RecordHistoriesClientModel {
  constructor(private readonly data: RecordHistoriesClientModelData) {}

  public get allRecordHistories(): RecordHistoryClientModel[] {
    return this.data.recordHistories;
  }
}
