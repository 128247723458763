import { Separator } from "@radix-ui/themes";
import { DatabaseClientModel } from "~/clientModel/database/DatabaseClientModel";
import { Executable } from "~/clientModel/executable";
import { Box } from "~/components_next/Box";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { Flex } from "~/components_next/Flex";
import { IconButton } from "~/components_next/IconButton";
import { Text } from "~/components_next/Text";
import { ConnectToExistingDatabaseDialog } from "~/features/DatabaseHome/components/ConnectToExistingDatabaseDialog";
import { CreateDatabaseDialog } from "~/features/DatabaseHome/components/CreateDatabaseDialog";
import { UseDisclosureReturn } from "~/hooks/useDisclosure";
import { CreateDatabaseMutationProps } from "~/serverStateStore";

import { AiOutlineApi, AiOutlineDatabase } from "react-icons/ai";
import { BsPlusLg } from "react-icons/bs";

import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "react-query";
import { ListDatabaseClientModel } from "~/clientModel/database/listDatabaseClientModel";
import { PaymentClientModel } from "~/clientModel/payment/PaymentClientModel";

interface DatabaseAddPopoverProps {
  paymentClientModel: PaymentClientModel;
  createDatabaseExecutable: Executable<
    CreateDatabaseMutationProps,
    DatabaseClientModel
  >;
  createDatabaseModal: UseDisclosureReturn;
  connectDatabaseModal: UseDisclosureReturn;
  listDatabaseRefetcher: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<ListDatabaseClientModel, unknown>>;
}

export const DatabaseAddPopover = (props: DatabaseAddPopoverProps) => {
  const {
    paymentClientModel,
    createDatabaseExecutable,
    createDatabaseModal,
    connectDatabaseModal,
    listDatabaseRefetcher,
  } = props;

  return (
    <>
      <SimpleDropdownMenu
        trigger={
          <IconButton
            icon={<BsPlusLg color="gray" />}
            tooltip="Create New Database"
            size="sm"
          />
        }
      >
        <Box css={{ width: "300px" }} p="1">
          <Flex
            onClick={() => createDatabaseModal.onOpen()}
            width={"100%"}
            justify="start"
            align="center"
            p="2"
            hoverable
            css={{
              borderRadius: "md",
              fontWeight: "normal",
              fontSize: "sm",
              cursor: "pointer",
              zIndex: 2,
            }}
          >
            <AiOutlineDatabase size={18} color={"gray.500"} />
            <Text ml="2">Create New Database</Text>
          </Flex>

          {(paymentClientModel.isSubscribed || !!paymentClientModel.currentTrial) && (
            <>
              <Separator my="2" size="4" />
              <Box mb="1">
                <Text variant="description">Add from other souces</Text>
              </Box>
              <Flex
                onClick={() => connectDatabaseModal.onOpen()}
                width={"100%"}
                justify="start"
                align="center"
                p="2"
                hoverable
                css={{
                  borderRadius: "md",
                  fontWeight: "normal",
                  fontSize: "sm",
                  cursor: "pointer",
                  zIndex: 2,
                }}
              >
                <AiOutlineApi size={18} color={"gray.500"} />
                <Text ml="2">Connect to existing database</Text>
              </Flex>
            </>
          )}
        </Box>
      </SimpleDropdownMenu>

      <CreateDatabaseDialog
        isOpen={createDatabaseModal.isOpen}
        setIsOpen={createDatabaseModal.setIsOpen}
        onClose={createDatabaseModal.onClose}
        createDatabaseExecutable={createDatabaseExecutable}
        listDatabaseRefetcher={listDatabaseRefetcher}
      />

      <ConnectToExistingDatabaseDialog
        isOpen={connectDatabaseModal.isOpen}
        setIsOpen={connectDatabaseModal.setIsOpen}
        onClose={connectDatabaseModal.onClose}
        createDatabaseExecutable={createDatabaseExecutable}
        listDatabaseRefetcher={listDatabaseRefetcher}
      />
    </>
  );
};
