import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModel } from "..";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";

export class HtmlFieldTypeClientModel extends FieldTypeClientModelBase {
  public get type(): "html" {
    return "html";
  }

  public get fieldTypeLabel(): "HTML" {
    return "HTML";
  }

  public get isAutoGenerated(): false {
    return false;
  }

  public get isEmbeddable(): true {
    return true;
  }

  public get canBeTargetOfSyncingByComputedField(): true {
    return true;
  }

  public get _updatableFieldTypes(): FieldTypeClientModel["type"][] {
    return ["shortText", "longText"];
  }

  public validate(): ValidationResult {
    return { isValid: true };
  }
}
