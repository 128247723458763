import { SourceBucketClientModel } from "./SourceBucketClientModel";

export class SourceBucketClientModelFactory {
  static fromBucketResponse(bucketResponse: SourceBucketClientModel) {
    return new SourceBucketClientModel({
      bucketId: bucketResponse.bucketId,
      bucketName: bucketResponse.bucketName,
      readPermission: bucketResponse.readPermission,
      createPermission: bucketResponse.createPermission,
      updatePermission: bucketResponse.updatePermission,
    });
  }

  static getPlaceholderBucket({
    databaseId,
    tableSlug,
  }: {
    databaseId: string;
    tableSlug: string;
  }) {
    return new SourceBucketClientModel({
      bucketId: "",
      bucketName: SourceBucketClientModel.getBucketName({
        databaseId,
        tableSlug,
      }),
      readPermission: "forbidden",
      createPermission: "forbidden",
      updatePermission: "forbidden",
    });
  }
}
