import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useMe } from "~/serverStateStore/me";

export const usePresenceBasics = () => {
  const { pathname } = useLocation();
  const { data: meResponse } = useMe();

  const basiceValues = useMemo(() => {
    return {
      path: pathname,
      user: meResponse,
    };
  }, [pathname, meResponse]);

  return {
    ...basiceValues,
  };
};
