type AggregateValueFieldOperatorClientModelData = {
  value: string;
  label: string;
};

export class AggregateValueFieldOperatorClientModel {
  public constructor(
    readonly data: AggregateValueFieldOperatorClientModelData
  ) {}

  public get label(): string {
    return this.data.label;
  }

  public get value(): string {
    return this.data.value;
  }
}
