import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";

export class ImageFieldTypeClientModel extends FieldTypeClientModelBase {
  public get type(): "image" {
    return "image";
  }

  public get fieldTypeLabel(): "Image" {
    return "Image";
  }

  public get isAutoGenerated(): false {
    return false;
  }

  public get isEmbeddable(): false {
    return false;
  }

  public get canBeTargetOfSyncingByComputedField(): true {
    return true;
  }

  public get _updatableFieldTypes() {
    return [];
  }

  public validate(): ValidationResult {
    return { isValid: true };
  }
}
