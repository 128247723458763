import { DashboardNotebookPromptListResponse } from "@usemorph/morph-dashboard-types";
import { CanvasVisualizationPromptConfigsCientModel } from "./CanvasVisualizationPromptConfigsCientModel";
import { CanvasVisualizationPromptConfigCientModelFactory } from "./CanvasVisualizationPromptConfigCientModelFactory";

export class CanvasVisualizationPromptConfigsCientModelFactory {
  static fromPromptConfigsResponse(data: DashboardNotebookPromptListResponse) {
    return new CanvasVisualizationPromptConfigsCientModel({
      configs: data.prompts.map((prompt) =>
        CanvasVisualizationPromptConfigCientModelFactory.fromPromptConfigResponse(
          prompt
        )
      ),
    });
  }
}
