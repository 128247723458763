
import { SimpleField } from "@usemorph/morph-dashboard-types";
import { useMemo } from "react";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { Flex } from "~/components_next/Flex";
import { Text } from "~/components_next/Text";
import { FieldToggleListItem } from "~/features/Fields/FieldVisibility/FieldToggleListItem";
import {
  convertRecordToRecordModel,
  RecordModel,
} from "~/features/RecordModel";
import { useRecordModelFormatValidation } from "~/features/RecordModel/utils/useRecordModelValidation";
import { RecordForm } from "~/features/Records/RecordForm";

const convertValuesToRecordModel = (
  values: ValueUnit[],
  fields: SimpleField[]
): RecordModel => {
  const record = values.reduce((record, { key, value }) => {
    return { ...record, [key]: value };
  }, {} as Record<string, unknown>);

  return convertRecordToRecordModel(record, fields);
};

const convertRecordModelToValues = (recordModel: RecordModel): ValueUnit[] => {
  return Object.entries(recordModel).map(([key, { value }]) => {
    return { key, value } as ValueUnit; // todo: type safe
  });
};

export interface ValueUnit {
  key: string;
  value:
  | string
  | number
  | boolean
  | string[]
  | Record<string, unknown>
  | number[]
  | boolean[]
  | null;
}

type ValuesFormProps = {
  values: ValueUnit[];
  fields: SimpleField[];
  onChange: (values: ValueUnit[]) => void;
};

export const FixedValuesForm = ({ values, fields, onChange }: ValuesFormProps) => {
  /**
   * data and hooks
   */

  const fieldsInValues = useMemo(() => {
    return fields.flatMap((field) => {
      if (values.some(({ key }) => key === field.name)) {
        return [field];
      } else {
        return [];
      }
    });
  }, [fields, values]);

  const recordModel = useMemo(
    () => convertValuesToRecordModel(values, fieldsInValues),
    [values, fieldsInValues]
  );

  const selectedFields = useMemo(() => {
    const selectedFieldNames = values.map(({ key }) => key);
    return fields.flatMap((field) => {
      return selectedFieldNames.includes(field.name) ? [field] : [];
    });
  }, [fields, values]);

  const isSelected = (field: SimpleField): boolean => {
    return selectedFields.map(({ name }) => name).includes(field.name);
  };

  const { validationErrorsDict } = useRecordModelFormatValidation({
    recordModel,
    simpleFields: selectedFields,
  });

  /**
   * handlers
   */

  const onChangeRecordModel = (recordModel: RecordModel) => {
    const values = convertRecordModelToValues(recordModel);
    onChange(values);
  };

  const addField = (fieldName: string) => {
    // todo: nullable = falseの場合に、初手でtype corruptedになるのを修正する
    const updatedValues = [...values, { key: fieldName, value: null }];
    onChange(updatedValues);
  };

  const removeField = (fieldName: string) => {
    const updatedValues = values.flatMap((value) =>
      value.key === fieldName ? [] : [value]
    );
    onChange(updatedValues);
  };

  /**
   * for ui
   */

  const buttonLabel =
    values.length === 0
      ? "Select fields for fixed values"
      : `${values.length} fields selected for fixed values`;

  return (
    <>
      <Box mt="2">
        <Text fontWeight="medium">Fixed values</Text>
      </Box>
      <Flex direction="column" gap="4">
        <SimpleDropdownMenu
          trigger={
            <Button size="sm" style={{ width: "full" }} variant='secondary'>
              {buttonLabel}
            </Button>
          }
        >
          <Box
            css={{
              listStyleType: "none",
              maxHeight: "30vh",
              overflowY: "auto"
            }}
            ml="0"
            py="2"
          >
            {fields.map((field) => (
              <FieldToggleListItem
                key={field.name}
                field={field}
                isChecked={isSelected(field)}
                onChange={(checked) =>
                  checked ? addField(field.name) : removeField(field.name)
                }
                showTableSlug={false}
                draggable={false}
              />
            ))}
          </Box>
        </SimpleDropdownMenu>

        {values.length > 0 && (
          <Box
            p="4"
          // bg={bg1}
          >
            <RecordForm
              recordModel={recordModel}
              setRecordModel={onChangeRecordModel}
              validationErrorsDict={validationErrorsDict}
              simpleFields={selectedFields}
              displayedFieldCategories="all"
            />
          </Box>
        )}
      </Flex>
    </>
  );
};
