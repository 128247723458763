import { BsDatabase } from "react-icons/bs";
import { NodeProps, NodeToolbar, Position } from "reactflow";
import { CanvasCellSourceClientModel } from "~/clientModel/canvas/CanvasCellClientModel";
import { Badge } from "~/components_next/Badge";
import { Flex } from "~/components_next/Flex";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";
import { PlaygroundCellCard } from "../common/PlaygroundCellCard";
import { PlaygroundCellCreateCellButton } from "../common/CreateCellButton/PlaygroundCellCreateCellButton";
import { useUseSourceFieldsLoadable } from "../../providers/PlaygroundUseSourceFIeldsLoadableProvider";

type PlaygroundCellSourceProps = {
  cellClientModel: CanvasCellSourceClientModel;
};

const PlaygroundCellSource = (props: NodeProps<PlaygroundCellSourceProps>) => {
  const { data, selected, dragging, xPos, yPos } = props;
  const { cellClientModel } = data;

  const useSourceFieldsLoadable = useUseSourceFieldsLoadable();
  const sourceFieldsLoadable = useSourceFieldsLoadable({
    tableSlug: cellClientModel.tableSlug || "",
  });

  return (
    <PlaygroundCellCard
      cellClientModel={cellClientModel}
      isNodeDragging={dragging}
      isNodeSelected={selected}
      position={{ x: xPos, y: yPos }}
    >
      <Flex direction="column" gap="2">
        <Flex gap="2" align="center">
          <Spacer />
          <Badge variant="secondary">Source</Badge>
        </Flex>
        <Flex gap="2" align="center">
          <BsDatabase style={{ width: "20px", height: "20px" }} />
          <Text>{cellClientModel.tableSlug}</Text>
        </Flex>
      </Flex>

      <NodeToolbar position={Position.Bottom} isVisible={selected}>
        <PlaygroundCellCreateCellButton
          canvasCellClientModel={cellClientModel}
          sourceTableSlug={cellClientModel.tableSlug || undefined}
          fieldsLoadable={sourceFieldsLoadable}
        />
      </NodeToolbar>
    </PlaygroundCellCard>
  );
};

export { PlaygroundCellSource, type PlaygroundCellSourceProps };
