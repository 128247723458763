import dayjs from "dayjs";
import { P, match } from "ts-pattern";

type ImportStatusDataType = {
  id: string;
  status: "failed" | "schemaEdit" | "importing" | "imported";
  tableSlug: string;
  total: bigint | null;
  processed: bigint | null;
  importSource: string; // 'excel' とか 'airtable' とか。とりあえず自由に入れれるように。Factoryで形式担保してください。
  importActionType: "create" | "insert";
  sourceName: string | null;
  createdAt: string;
  updatedAt: string | null;
};

const camelCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export class ImportStatusClientModel {
  readonly #data: ImportStatusDataType;
  constructor(data: ImportStatusDataType) {
    this.#data = data;
  }

  get shouldShow(): boolean {
    // return true;
    return match(this.#data.status)
      .with(P.union("failed", "imported"), () => {
        return (
          dayjs().diff(
            dayjs(this.#data.updatedAt ?? this.#data.createdAt),
            "minute"
          ) < 5
        );
      })
      .with("importing", () => {
        if (
          this.#data.processed !== null &&
          this.#data.total !== null &&
          this.#data.total === this.#data.processed
        ) {
          return false;
        }
        return true;
      })
      .with("schemaEdit", () => {
        return false;
      })
      .exhaustive();
  }

  get isImporting(): boolean {
    return match(this.#data.status)
      .with("importing", () => {
        return true;
      })
      .otherwise(() => {
        return false;
      });
  }

  get title(): string {
    return match(this.#data.importSource)
      .with("excel", () => "Excel file import")
      .with("csv", () => "CSV file import")
      .otherwise(() => {
        return `${camelCaseToWords(this.#data.importSource)} import`;
      });
  }

  get importSourceLabel(): string {
    return match(this.#data.importSource)
      .with("excel", () => "Excel file")
      .with("csv", () => "CSV file")
      .otherwise(() => {
        return camelCaseToWords(this.#data.importSource);
      });
  }

  get statusLabel() {
    return match(this.#data.status)
      .with("failed", () => "failed" as const)
      .with("imported", () => "imported" as const)
      .with("importing", () => "in progress" as const)
      .with("schemaEdit", () => "in preparation" as const)
      .exhaustive();
  }

  get message(): string {
    return match(this.#data.importActionType)
      .with("create", () => {
        return `Creating new source from ${this.importSourceLabel} into ${
          this.#data.sourceName
        } is ${this.statusLabel}.`;
      })
      .with("insert", () => {
        return `Adding records from ${this.importSourceLabel} into ${
          this.#data.sourceName
        } is ${this.statusLabel}.`;
      })
      .exhaustive();
  }
}
