import { match } from "ts-pattern";
import { NotebookCellObjectWithMeta } from "../types/NotebookCellObjectWithMeta.type";
import Dagre from "@dagrejs/dagre";

const getCellInitialPosition = (
  cell: NotebookCellObjectWithMeta,
  index: number
): NotebookCellObjectWithMeta => {
  return match(cell.cellType)
    .with("prompt", () => {
      return {
        ...cell,
        settings: {
          x: 0,
          y: 300 * (index + 1),
          width: 600,
          height: 600,
        },
      };
    })
    .with("image", () => {
      return {
        ...cell,
        settings: {
          x: 0,
          y: 300 * (index + 1),
          width: 600,
          height: 600,
        },
      };
    })
    .with("richText", () => {
      return {
        ...cell,
        settings: {
          x: 0,
          y: 300 * (index + 1),
          width: 600,
          height: 300,
        },
      };
    })
    .with("source", () => {
      return {
        ...cell,
        settings: {
          x: 0,
          y: 300 * (index + 1),
          width: 300,
          height: 120,
        },
      };
    })
    .with("view", () => {
      return {
        ...cell,
        settings: {
          x: 0,
          y: 300 * (index + 1),
          width: 800,
          height: 600,
        },
      };
    })
    .with("variables", () => {
      return {
        ...cell,
        settings: {
          x: 0,
          y: 300 * (index + 1),
          width: 300,
          height: 200,
        },
      };
    })
    .otherwise(() => {
      return {
        ...cell,
        settings: {
          x: 0,
          y: 300 * (index + 1),
          width: 300,
          height: 200,
        },
      };
    });
};

const getLayoutedElements = (cells: readonly NotebookCellObjectWithMeta[]) => {
  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

  g.setGraph({ rankdir: "TB", ranksep: 100 });

  cells.forEach((cell, index) => {
    g.setNode(cell.cellId, {
      ...cell.settings,
    });

    cell.parentIds?.forEach((parentId) => {
      g.setEdge(parentId, cell.cellId);
    });
  });

  Dagre.layout(g);

  return cells.map((cell) => {
    const node = g.node(cell.cellId);
    return {
      ...cell,
      settings: {
        ...cell.settings,
        width: node.width,
        height: node.height,
        x: node.x - node.width / 2,
        y: node.y - node.height / 2,
      },
    };
  });
};

export const useNotebookPositionUtil = () => {
  const validateCells = (
    cells: NotebookCellObjectWithMeta[]
  ): {
    isAllValid: boolean;
    cells: NotebookCellObjectWithMeta[] | undefined;
  } => {
    let isAllValid = true;
    const fullfilled = cells.map((cell, index) => {
      if (
        !cell.settings ||
        cell.settings.width === undefined ||
        cell.settings.height === undefined
      ) {
        isAllValid = false;
        return getCellInitialPosition(cell, index);
      }
      return cell;
    });

    if (isAllValid) {
      return {
        isAllValid: true,
        cells: undefined,
      };
    }

    return {
      isAllValid: false,
      cells: getLayoutedElements(fullfilled),
    };
  };

  const recalcPositons = (
    cells: readonly NotebookCellObjectWithMeta[]
  ): NotebookCellObjectWithMeta[] => {
    return getLayoutedElements(cells);
  };

  return {
    validateCells,
    recalcPositons,
  };
};
