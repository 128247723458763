import { SmartFunctionInputClientModel } from "./smartFuntionInput";

type SmartFunctionInputFormClientModelData = {
  inputs: SmartFunctionInputClientModel[];
};

export class SmartFunctionInputFormClientModel {
  public constructor(readonly data: SmartFunctionInputFormClientModelData) {}

  public get allInputs(): SmartFunctionInputClientModel[] {
    return this.data.inputs;
  }
}
