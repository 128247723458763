import type * as CSS from "csstype";

const codeBg = "#f0f0f0";

const styles = {
  code: {
    backgroundColor: codeBg,
    padding: "0.1rem 0.4rem",
    borderRadius: "0.3rem",
    margin: "0 0.2rem",
  },
  codeBlock: {
    backgroundColor: codeBg,
    padding: "1rem 1rem",
    borderRadius: "0.4rem",
  },
  blockquote: {
    margin: "1em 40px",
    padding: "0",
    borderLeft: "4px solid #ccc",
    paddingLeft: "15px",
    fontStyle: "italic",
  },
} as const satisfies Record<string, CSS.Properties>;

const camelCaseToKebabCase = (camelCase: string): string => {
  return camelCase.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`);
};

const convertToStyleString = (css: CSS.Properties): string => {
  return Object.entries(css)
    .map(([key, value]) => `${camelCaseToKebabCase(key)}: ${value};`)
    .join(" ");
};

export { styles, convertToStyleString };
