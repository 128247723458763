import {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Flex } from "~/components_next/Flex";
import { Text } from "~/components_next/Text";
import { RadixTheme } from "~/RadixTheme";
import { styled } from "~/stitches";
import { SuggestionProps } from "@tiptap/suggestion";

type SuggestionMenuRef = {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean;
};

const MenuItemsContainer = styled(Flex, {
  backgroundColor: "$bg0",
  borderRadius: "var(--radius-3)",
  flexDirection: "column",
  boxShadow: "var(--shadow-4)",
  overflow: "auto",
  maxHeight: "200px",
  pointerEvents: "auto",
});

const MenuItem = styled("button", {
  background: "transparent",

  variants: {
    isSelected: {
      true: {
        backgroundColor: "$bg1",
      },
    },
  },
});

type SuggestionMenuProps<Item> = SuggestionProps<Item> & {
  renderItem: (item: Item) => ReactNode;
};

const _SuggestionMenu = <Item,>(
  props: SuggestionMenuProps<Item>,
  ref: ForwardedRef<SuggestionMenuRef>
) => {
  const { items, command, renderItem } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = items[index];

    if (item) {
      command(item);
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  const focusedRef = useCallback((element: HTMLButtonElement | null) => {
    element?.scrollIntoView({ behavior: "auto", block: "nearest" });
  }, []);

  return (
    <RadixTheme>
      <MenuItemsContainer>
        {props.items.length ? (
          items.map((item, index) => (
            <MenuItem
              key={index}
              ref={index === selectedIndex ? focusedRef : undefined}
              onClick={() => selectItem(index)}
              isSelected={index === selectedIndex}
              onMouseEnter={() => setSelectedIndex(index)}
              id={
                index === selectedIndex
                  ? "suggestion-menu-selected-item"
                  : undefined
              }
            >
              {renderItem(item)}
            </MenuItem>
          ))
        ) : (
          <Flex align="center" justify="center" px="5" py="3">
            <Text variant="description">No result</Text>
          </Flex>
        )}
      </MenuItemsContainer>
    </RadixTheme>
  );
};

const SuggestionMenu = forwardRef(_SuggestionMenu) as <Item>(
  props: SuggestionMenuProps<Item> & {
    ref?: ForwardedRef<SuggestionMenuRef>;
  }
) => JSX.Element;

export { SuggestionMenu, type SuggestionMenuRef, type SuggestionMenuProps };
