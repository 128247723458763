import { BsPlus } from "react-icons/bs";
import { Button } from "~/components_next/Button";

type FooterProps = {
  onCreate: () => void;
  isCreating: boolean;
  isCreateDisabled: boolean;
};

const Footer = (props: FooterProps) => {
  const { onCreate, isCreating, isCreateDisabled } = props;

  return (
    <Button
      isLoading={isCreating}
      isDisabled={isCreateDisabled}
      variant="primary"
      onClick={onCreate}
      size="sm"
      style={{ width: "100%" }}
    >
      <BsPlus />
      Create
    </Button>
  );
};

export { Footer };
