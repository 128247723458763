import { Flex } from "@radix-ui/themes";
import { CSSProperties } from "react";
import { BsPlusLg } from "react-icons/bs";
import {
  useRecordsTableBaseClearAllSelection,
  useRecordsTableBaseOnAddFieldClick,
} from "../context/ReactTableBaseContext";
import { useBorderStyle } from "../hooks/useBorderStyle";
import { useHeaderStyle } from "./useHeaderStyle";
import { IconButton } from "../../IconButton";

type RecordsTableAddFieldbuttonProps = {
  style: CSSProperties;
};

const RecordsTableAddFieldbutton = (props: RecordsTableAddFieldbuttonProps) => {
  const { style } = props;

  const onAddFieldClick = useRecordsTableBaseOnAddFieldClick();
  const clearSelection = useRecordsTableBaseClearAllSelection();

  const { borderAttributes } = useBorderStyle();
  const { headerAttributes } = useHeaderStyle();

  const handleAddFieldClick = () => {
    onAddFieldClick?.();
    clearSelection();
  };

  if (!onAddFieldClick) {
    return null;
  }

  return (
    <Flex
      align="center"
      justify="center"
      style={{
        width: "40px",
        ...borderAttributes,
        ...headerAttributes,
        ...style,
      }}
    >
      <IconButton
        size="md"
        onClick={handleAddFieldClick}
        icon={<BsPlusLg />}
        tooltip="Create Field"
      />
    </Flex>
  );
};

export { RecordsTableAddFieldbutton };
