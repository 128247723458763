import {
  CanvasVariableClientModel,
  CanvasVariableTypeType,
} from "./CanvasVariableClientModel";
import { CanvasVariableClientModelFactory } from "./CanvasVariableClientModelFactory";

export class CanvasVariablesClientModel {
  constructor(private readonly _data: CanvasVariableClientModel[]) {}

  get data() {
    return this._data;
  }

  get rawData() {
    return this._data.map((clientModel) => clientModel.data);
  }

  get allCanvasVariables() {
    return this._data;
  }

  deleteVariableAt(index: number): CanvasVariablesClientModel {
    const data = this.data.filter((_, i) => i !== index);
    return new CanvasVariablesClientModel(data);
  }

  createNewVariable(): CanvasVariablesClientModel {
    const data = [
      ...this.data,
      CanvasVariableClientModelFactory.fromVariableData(
        CanvasVariableClientModel.newVariableInitialValue
      ),
    ];
    return new CanvasVariablesClientModel(data);
  }

  updateNameAt(name: string, index: number) {
    const data = this.data.map((clientModel, i) => {
      if (i === index) {
        return clientModel.updateName(name);
      }
      return clientModel;
    });
    return new CanvasVariablesClientModel(data);
  }

  updateTypeAt(type: CanvasVariableTypeType, index: number) {
    const data = this.data.map((clientModel, i) => {
      if (i === index) {
        return clientModel.updateType(type);
      }
      return clientModel;
    });
    return new CanvasVariablesClientModel(data);
  }

  updateDefaultValueAt(defaultValue: unknown, index: number) {
    const data = this.data.map((clientModel, i) => {
      if (i === index) {
        return clientModel.updateDefaultValue(defaultValue);
      }
      return clientModel;
    });
    return new CanvasVariablesClientModel(data);
  }

  updateOptionsStringAt(options: string[], index: number) {
    const data = this.data.map((clientModel, i) => {
      if (i === index) {
        return clientModel.updateOptionsString(options);
      }
      return clientModel;
    });
    return new CanvasVariablesClientModel(data);
  }

  updateOptionsNumberAt(options: number[], index: number) {
    const data = this.data.map((clientModel, i) => {
      if (i === index) {
        return clientModel.updateOptionsNumber(options);
      }
      return clientModel;
    });
    return new CanvasVariablesClientModel(data);
  }

  updateOptionsSourceFieldAt(
    { tableSlug, field }: { tableSlug: string; field: string },
    index: number
  ) {
    const data = this.data.map((clientModel, i) => {
      if (i === index) {
        return clientModel.updateOptionsSourceField({ tableSlug, field });
      }
      return clientModel;
    });
    return new CanvasVariablesClientModel(data);
  }

  updateOptionsViewRecordsAt(
    {
      viewId,
      labelField,
      valueField,
    }: { viewId: string; labelField: string; valueField: string },
    index: number
  ) {
    const data = this.data.map((clientModel, i) => {
      if (i === index) {
        return clientModel.updateOptionsViewRecords({
          viewId,
          labelField,
          valueField,
        });
      }
      return clientModel;
    });
    return new CanvasVariablesClientModel(data);
  }
}
