import { forwardRef } from "react";
import { Button } from "~/components_next/Button";

type SQLButtonProps = {
  isActive: boolean;
};

const SQLButton = forwardRef<HTMLButtonElement, SQLButtonProps>(
  (props, ref) => {
    const { isActive, ...rest } = props;

    const buttonVariant = isActive ? "primary" : "tertiary";
    return (
      <Button ref={ref} variant={buttonVariant} size="xs" {...rest}>
        SQL
      </Button>
    );
  }
);

SQLButton.displayName = "SQLButton";

export { SQLButton };
