import { useQueryClient } from "react-query";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import useApiFields from "~/api/useApiField";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import { recordsKeys } from "~/serverStateStore/records";
import { removeTrailingUndefined } from "~/serverStateStore/util/removeTrailingUndefined";
import {
  QueryRecordWithFieldsResponse,
  SimpleField,
  SimpleFieldListResponse,
  zQueryRecordWithFieldsResponse,
  zSimpleFieldListResponse,
} from "@usemorph/morph-dashboard-types";
import { zodGuard } from "~/utils/zodGuard";
import { simpleFieldsKeys } from "../keys";

interface UseOptimisticUpdateSimpleFieldOrderMutationParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
}

const useOptimisticUpdateSimpleFieldOrderMutation = ({
  databaseId,
  tableSlug,
  teamSlug,
}: UseOptimisticUpdateSimpleFieldOrderMutationParams) => {
  const { _updateSimpleFieldOrder } = useApiFields();
  const client = useQueryClient();
  const broadcast = useBroadcastQueryInvalidate();

  const listSimpleFieldsQueryKey = simpleFieldsKeys.getSimpleField({
    databaseId,
    tableSlug,
    teamSlug,
  });

  const queryRecordsWithQueryKey = recordsKeys.getRecord({
    databaseId,
    tableSlug,
    teamSlug,
    select: ["*"],
  });

  return {
    mutationFn: ({ order }: { order: string[] }) => {
      return _updateSimpleFieldOrder({
        teamSlug,
        databaseId,
        tableSlug,
        order,
      });
    },
    onMutate: async ({ order }: { order: string[] }) => {
      const getUpdatedFields = (prevSimpleFields: SimpleField[]) =>
        [...prevSimpleFields].sort(
          (a, b) =>
            order.findIndex((name) => name === a.name) -
            order.findIndex((name) => name === b.name)
        );

      // list simple fieldsの更新
      client
        .getQueryCache()
        .findAll(removeTrailingUndefined(listSimpleFieldsQueryKey))
        .forEach(({ queryKey, state: { data } }) => {
          if (zodGuard(data, zSimpleFieldListResponse)) {
            client.setQueryData<SimpleFieldListResponse>(queryKey, {
              fields: getUpdatedFields(data.fields),
            });
          }
        });

      // query records with queryの更新
      client
        .getQueryCache()
        .findAll(removeTrailingUndefined(queryRecordsWithQueryKey))
        .forEach(({ queryKey, state: { data } }) => {
          if (zodGuard(data, zQueryRecordWithFieldsResponse)) {
            client.setQueryData<QueryRecordWithFieldsResponse>(queryKey, {
              ...data,
              fields: getUpdatedFields(data.fields),
            });
          }
        });
    },
    onSuccess: () => {
      broadcast(listSimpleFieldsQueryKey);
      return invalidateAll(
        client,
        listSimpleFieldsQueryKey,
        queryRecordsWithQueryKey
      );
    },
    onError: () => {
      return invalidateAll(
        client,
        listSimpleFieldsQueryKey,
        queryRecordsWithQueryKey
      );
    },
  };
};

export { useOptimisticUpdateSimpleFieldOrderMutation };
