import { useParams } from "react-router-dom";

const useTableSlug = () => {
  const { tableSlug } = useParams();

  if (!tableSlug) {
    throw new Error(`tableSlug not found.`);
  }

  return tableSlug;
};

export { useTableSlug };
