import { RecordFilterCondition } from "@usemorph/morph-dashboard-types";
import { RecordIdentifierClientModel } from "./RecordIdentifierClientModel";

export class RecordIdentifierClientModelDefactory {
  public static toRecord(
    recordIdentifier: RecordIdentifierClientModel
  ): Record<string, unknown> {
    return recordIdentifier.data.identityRecord;
  }

  public static toRecordFilterCondition(
    recordIdentifier: RecordIdentifierClientModel
  ): RecordFilterCondition {
    return {
      and: Object.entries(recordIdentifier.data.identityRecord).map(
        ([key, value]) => ({
          key,
          operator: "equal",
          value: String(value),
        })
      ),
    };
  }
}
