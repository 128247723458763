import { DashboardUserObject } from "@usemorph/morph-dashboard-types";
import { Box } from "~/components_next/Box";
import { Text } from "~/components_next/Text";
import { keyframes } from "~/stitches";
import {
  UserAvatar,
  UserAvatarProps,
} from "../../../../../components_next/User/UserAvatar/UserAvatar";

type OtherAvatarsProps = {
  userAvatarOptionProps: Omit<UserAvatarProps, "user">;
  others: DashboardUserObject[];
  showAll: boolean;
};

const OtherAvatars = (props: OtherAvatarsProps) => {
  const { others, userAvatarOptionProps, showAll } = props;

  const getTransformKeyframes = (index: number) =>
    keyframes({
      "0%": { transform: "translateY(-50%) translateX(0)" },
      "100%": { transform: `translateY(-50%) translateX(${index * 20}px)` },
    });

  const getZIndex = (index: number) => others.length - index;

  return (
    <Box style={{ position: "relative" }}>
      <Box>
        {others.map((user, i) => (
          <Box
            key={i}
            style={{
              position: "absolute",
              left: 0,
              top: "50%",
              zIndex: getZIndex(i),
              transform: "translateY(-50%)",
              animation: showAll
                ? `${getTransformKeyframes(i)} 150ms forwards`
                : undefined,
            }}
          >
            <UserAvatar user={user} {...userAvatarOptionProps} />
          </Box>
        ))}
      </Box>

      {!showAll && others.length > 1 && (
        <Text
          style={{
            position: "absolute",
            left: 26,
            top: "50%",
            transform: "translateY(-50%)",
            fontSize: "10px",
            pointerEvents: "none",
          }}
          variant="tinyDescription"
        >
          +{others.length - 1}
        </Text>
      )}
    </Box>
  );
};

export { OtherAvatars, type OtherAvatarsProps };
