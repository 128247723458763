import { RecordValueType } from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";
import {
  AllRecordModelValueUnion,
  RecordModel,
} from "../types/RecordModelValue";

type Values = {
  value: RecordValueType;
  key: string;
}[];

const throwIfValueIsUndefined = (
  fieldName: string,
  recordModelValue: AllRecordModelValueUnion
) => {
  if (recordModelValue.value === undefined) {
    // バリデーションで捌くべきなので、本来呼ばれてはいけない
    throw new Error(
      `Invalid record model(${recordModelValue.value} at ${fieldName} is invalid value for ${recordModelValue.type})`
    );
  }
};

// recordModelBeforeEditがある場合は、編集前と編集後の値が同じならAPIリクエストから除外する
const convertRecordModelToValuesForRequest = ({
  recordModelBeforeEdit,
  recordModelAfterEdit,
}: {
  recordModelBeforeEdit?: RecordModel;
  recordModelAfterEdit: RecordModel;
}): Values => {
  return Object.entries(recordModelAfterEdit).flatMap(
    ([fieldName, recordModelValueAfterEdit]) => {
      // 編集前と編集後の値が同じなら、APIリクエストに含めない
      if (
        recordModelBeforeEdit &&
        recordModelBeforeEdit[fieldName].value ===
          recordModelValueAfterEdit.value
      ) {
        return [];
      }

      // morph_reserved_から始まるフィールドは予約語なので、APIリクエストに含めない
      if (fieldName.startsWith("morph_reserved_")) return [];

      return (
        match(recordModelValueAfterEdit)
          // アセット系はpost時には、dataのみ送る
          .with(
            { type: P.union("image", "attachment"), value: P.select() },
            (value) => {
              throwIfValueIsUndefined(fieldName, recordModelValueAfterEdit);
              return [
                {
                  key: fieldName,
                  value: value?.data ?? null,
                },
              ];
            }
          )
          // multi selectはstringで送る
          .with({ type: "multiSelect", value: P.select() }, (value) => {
            throwIfValueIsUndefined(fieldName, recordModelValueAfterEdit);
            return [
              {
                key: fieldName,
                value: JSON.stringify(value),
              },
            ];
          })
          // 自動生成系とformulaはリクエストから抜く
          .with(
            {
              type: P.union(
                "autoNumber",
                "autoBigNumber",
                "formula",
                "createdAt",
                "createdBy",
                "lastEditedAt",
                "lastEditedBy",
                "embeddings"
              ),
            },
            () => {
              // 自動生成系はundefinedのチェックは不要
              return [];
            }
          )
          // その他は変換せずにそのまま送る
          .otherwise(({ value }) => {
            throwIfValueIsUndefined(fieldName, recordModelValueAfterEdit);
            return [{ key: fieldName, value: value as RecordValueType }]; // todo
          })
      );
    }
  );
};

export { convertRecordModelToValuesForRequest };
