import { Box } from "@radix-ui/themes";
import { Virtualizer } from "@tanstack/react-virtual";
import { useState } from "react";
import { match } from "ts-pattern";
import { RecordClientModel } from "~/clientModel/records/record";
import { TableCell } from "../Cell/TableCell";
import { useCoworkerSelectionUtil } from "../common/useCoworkerSelectionUtil";
import {
  useRecordsTableBaseColumnsMeta,
  useRecordsTableBaseFields,
  useRecordsTableBaseHandleRecordClick,
  useRecordsTableBaseHandleTableSelectionChange,
  useRecordsTableBaseTableSelection,
} from "../context/ReactTableBaseContext";
import { ContentRowOpenRecordButton } from "./ContentRowOpenRecordButton";
import { ContentRowSelectBox } from "./ContentRowSelectBox";

type ContentRowProps = {
  record: RecordClientModel;
  columnVirtualizer: Virtualizer<HTMLDivElement, Element>;
};

const ContentRow = (props: ContentRowProps) => {
  const { record, columnVirtualizer } = props;

  const [isContentRowHovered, setIsContentRowHovered] = useState(false);

  // data
  const fields = useRecordsTableBaseFields();
  const columnsMeta = useRecordsTableBaseColumnsMeta();
  const onTableSelectionChange =
    useRecordsTableBaseHandleTableSelectionChange();
  const tableSelection = useRecordsTableBaseTableSelection();

  const handleRecordClick = useRecordsTableBaseHandleRecordClick();

  const hasRightBorderForHeaderColumn = (index: number) => {
    const nextColumnMeta = columnsMeta[index + 1];

    // if next column is an item column, show border
    if (nextColumnMeta && nextColumnMeta.type === "item") {
      return true;
    } else {
      return false;
    }
  };

  const { isRecordLockedByCoworker } = useCoworkerSelectionUtil();

  const _isRecordLockedByCoworker = isRecordLockedByCoworker({
    recordIdentifier: record.recordIdentifier,
  });

  const showSelectBox = isContentRowHovered && !_isRecordLockedByCoworker;
  const showOpenRecordButton =
    isContentRowHovered && !_isRecordLockedByCoworker;

  return (
    <Box
      onMouseEnter={() => setIsContentRowHovered(true)}
      onMouseLeave={() => setIsContentRowHovered(false)}
    >
      {columnVirtualizer.getVirtualItems().map((virtualRowItem) => {
        const columnMeta = columnsMeta[virtualRowItem.index];
        return (
          <Box
            key={virtualRowItem.key}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              transform: `translateX(${virtualRowItem.start}px)`,
              width: `${virtualRowItem.size}px`,
              height: "100%",
            }}
          >
            {match(columnMeta)
              .with({ type: "selectCheckbox" }, () => (
                <ContentRowSelectBox
                  record={record}
                  showSelectBox={showSelectBox}
                  hasRightBorder={hasRightBorderForHeaderColumn(
                    virtualRowItem.index
                  )}
                />
              ))
              .with({ type: "openRecordButton" }, () => (
                <ContentRowOpenRecordButton
                  onOpenClick={() => handleRecordClick(record)}
                  showOpenRecordButton={showOpenRecordButton}
                  hasRightBorder={hasRightBorderForHeaderColumn(
                    virtualRowItem.index
                  )}
                />
              ))
              .with({ type: "item" }, ({ fieldName }) => {
                return (
                  <TableCell
                    record={record}
                    field={fields.getFieldByFieldNameOrThrow(fieldName)}
                  />
                );
              })
              .with({ type: "addFieldButton" }, () => null)
              .exhaustive()}
          </Box>
        );
      })}
    </Box>
  );
};

export { ContentRow };
