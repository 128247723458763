import { useMutation } from "react-query";
import { composeExecutable } from "~/clientModel/executable";
import { usePreviewColorsMutation } from "~/serverStateStore/canvasStyle";

export const createUsePreviewColorsExecutable = ({
  teamSlug,
}: {
  teamSlug: string;
}) => {
  return () => {
    return composeExecutable(
      useMutation(usePreviewColorsMutation({ teamSlug }))
    );
  };
};
