import { useCallback } from "react";
import { ClearPromptButton } from "../../../../../common/components/QueryMode/Buttons/ClearPromptButton";
import { useSetQueryMode } from "../../../states/queryMode";
import {
  useSetQueryingSql,
  useSetSqlInput,
  useSetPromptInput,
} from "../../../states/sqlConditions";

const TableViewClearPromptButton = () => {
  /**
   * global states from recoil
   */
  const setQueryMode = useSetQueryMode();
  const setSqlInput = useSetSqlInput();
  const setQueryingSql = useSetQueryingSql();
  const setPromptInput = useSetPromptInput();

  /**
   * handlers
   */

  const handleClickClearPromptButton = useCallback(() => {
    setQueryMode(null);
    setQueryingSql("");
    setSqlInput("");
    setPromptInput("");
  }, [setSqlInput, setQueryMode, setQueryingSql, setPromptInput]);

  return <ClearPromptButton onClick={handleClickClearPromptButton} />;
};

export { TableViewClearPromptButton };
