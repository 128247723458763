import { Footer } from "./Footer";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import {
  createGroupKeyFromViewMetaFormState,
  ViewMetaFormState,
} from "~/features/View";

import { Callout } from "~/components_next/Callout";
import { FieldsClientModel } from "~/clientModel/fields";
import { TableClientModel } from "~/clientModel/tables/table";
import { SourceQueryConditions } from "../types";
import { RightSidebarBase } from "../../common/components/sidebars/common/RightSidebarBase";
import { Loadable } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { useCreateViewMutation } from "~/serverStateStore/views";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useMutation } from "react-query";
import { getPath } from "~/routing";
import { useErrorToast } from "~/components_next/Error";
import { useTableSlug } from "~/utilHooks/useTableSlug";

type CreateViewWithSqlSidebarProps = {
  sourceFieldsLoadable: Loadable<FieldsClientModel>;
  tableLoadable: Loadable<TableClientModel>;
  queryConditions: SourceQueryConditions;
};

const CreateViewWithSqlSidebar = (props: CreateViewWithSqlSidebarProps) => {
  const { sourceFieldsLoadable, tableLoadable, queryConditions } = props;

  const { prompt, queryingSql } = queryConditions;

  /**
   * Local states
   */

  const [viewMetaFormState, setViewMetaFormState] = useState<ViewMetaFormState>(
    {
      type: "table",
      name: "",
      isPrivate: false,
    }
  );

  /**
   * handlers
   */

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const tableSlug = useTableSlug();

  const { errorToast } = useErrorToast({});

  const { mutateAsync: createView } = useMutation(
    useCreateViewMutation({ teamSlug, databaseId })
  );

  const navigate = useNavigate();

  const handleCreateView = useCallback(async () => {
    try {
      const { name, type, isPrivate } = viewMetaFormState;
      const { viewId } = await createView({
        name: name,
        type: type,
        isPrivate: isPrivate,
        condition: {
          select: ["*"],
          from: queryingSql,
          groupKey: createGroupKeyFromViewMetaFormState(
            viewMetaFormState,
            tableSlug
          ),
          reorderable:
            type === "kanban" ? viewMetaFormState.reorderable : undefined,
        },
        setting: { data: { prompt } },
      });
      navigate(getPath("view", { teamSlug, databaseId, viewId }));
    } catch (error) {
      errorToast(error);
    }
  }, [
    createView,
    databaseId,
    errorToast,
    navigate,
    prompt,
    queryingSql,
    tableSlug,
    teamSlug,
    viewMetaFormState,
  ]);

  const isDisable = false;

  const hasJoinClause = queryConditions.sql.match(/(join|JOIN)[\w\s]+(on|ON)/);

  return (
    <RightSidebarBase
      sidebarType="createViewWithSql"
      title="Create View"
      footer={
        <Footer
          onClickCreateButton={handleCreateView}
          isCreating={false}
          isDisabled={isDisable}
        />
      }
    >
      <WithFallback loadables={[sourceFieldsLoadable, tableLoadable] as const}>
        {([sourceFields, table]) => (
          <>
            {hasJoinClause && (
              <Callout
                size="sm"
                type="info"
                description="Creating a view with a ‘Join’ SQL query across multiple tables is currently not supported. We are actively working on this function, though, so stay tuned!"
              />
            )}
            {/* <ViewWithSQLForm
              value={{
                meta: viewMetaFormState,
                sql: queryingSql,
                setting: { data: { prompt } },
              }}
              onChange={(value) => setViewMetaFormState(value.meta)}
              viewFields={FieldsClientModelDefactory.toSimpleFields(
                sourceFields
              )}
              tables={[
                {
                  tableSlug: table.data.tableSlug,
                  displayName: table.data.displayName,
                } as DashboardTableObject,
              ]}
            /> */}
          </>
        )}
      </WithFallback>
    </RightSidebarBase>
  );
};

export { CreateViewWithSqlSidebar };
