import { Button } from "~/components_next/Button";
import { BsFillCaretRightFill } from "react-icons/bs";
import { Editor, useMonaco } from "@monaco-editor/react";
import { Range, languages } from "monaco-editor";
import { SimpleField } from "@usemorph/morph-dashboard-types";
import { useEffect } from "react";
import { styled } from "~/stitches";
import { Flex } from "~/components_next/Flex";
import { Spinner } from "~/components_next/Spinner";

const RunIcon = styled(BsFillCaretRightFill, {
  marginRight: "4px",
});

type SQLFormProps = {
  onRunSql: () => void;
  sqlInput: string;
  setSqlInput: (value: string) => void;
  fields: SimpleField[];
};

const SQLForm = (props: SQLFormProps) => {
  const { onRunSql, sqlInput, setSqlInput, fields } = props;

  const monaco = useMonaco();

  useEffect(() => {
    if (!monaco) return;

    const { dispose } = monaco.languages.registerCompletionItemProvider("sql", {
      triggerCharacters: [" ", ".", "("],
      provideCompletionItems: function (model, position) {
        const word = model.getWordAtPosition(position);

        let replaceRange: Range;

        if (word) {
          replaceRange = new monaco.Range(
            position.lineNumber,
            word.startColumn,
            position.lineNumber,
            word.endColumn
          );
        } else {
          replaceRange = new monaco.Range(
            position.lineNumber,
            position.column,
            position.lineNumber,
            position.column
          );
        }

        const fieldSuggestions: languages.CompletionItem[] = fields.map(
          ({ name, originalTableSlug }) => ({
            label: name,
            kind: monaco.languages.CompletionItemKind.Field,
            insertText: name,
            range: replaceRange,
            detail: `field in ${originalTableSlug}`,
          })
        );

        const originalTableSlugs = [
          ...new Set(
            fields.flatMap(({ originalTableSlug }) => {
              return originalTableSlug ?? [];
            })
          ),
        ];

        const tableSuggestions: languages.CompletionItem[] =
          originalTableSlugs.map((originalTableSlug) => ({
            label: originalTableSlug,
            kind: monaco.languages.CompletionItemKind.Field,
            insertText: originalTableSlug,
            range: replaceRange,
            detail: "table",
          }));

        return {
          suggestions: [...fieldSuggestions, ...tableSuggestions],
        };
      },
    });

    return dispose;
  }, [monaco, fields]);

  return (
    <Flex direction="column" gap="2" p="2">
      <Editor
        height="50vh"
        language="sql"
        value={sqlInput}
        loading={<Spinner />}
        onChange={(value) => {
          setSqlInput(value ?? "");
        }}
        theme={"vs-dark"}
        options={{
          padding: {
            top: 24,
            bottom: 24,
          },
          scrollBeyondLastLine: false,
          minimap: {
            enabled: false,
          },
          fontSize: 14,
          lineNumbers: "off",
        }}
      />
      <Button
        variant="primary"
        size="xs"
        onClick={onRunSql}
        isDisabled={!sqlInput}
      >
        <RunIcon />
        Run
      </Button>
    </Flex>
  );
};

export { SQLForm };
