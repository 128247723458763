import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { viewKeys } from "../keys";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import useApiView from "~/api/useApiView";

type UseDeleteViewMutationParams = {
  teamSlug: string;
  databaseId: string;
};

const useDeleteViewMutation = ({
  teamSlug,
  databaseId,
}: UseDeleteViewMutationParams) => {
  const { _deleteView } = useApiView();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({ viewId }: { viewId: string }) => {
      return _deleteView({
        teamSlug,
        viewId,
      });
    },
    onSuccess: () => {
      const keys = viewKeys.all({ teamSlug, databaseId });

      broadcast(keys);

      return invalidateAll(client, keys);
    },
  };
};

export { useDeleteViewMutation };
