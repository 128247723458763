import { Drawer } from "~/components_next/Drawer";
import { SimpleField } from "@usemorph/morph-dashboard-types";
import { DownloadDrawerTitle } from "./DownloadDrawerTitle";
import {
  DownloadConditions,
  DownloadRecordsPreviewWithSQL,
} from "./DownloadRecordsPreviewWithSQL";

type DownloadRecordsWithSQLDrawerProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onClickDownload: () => void;
  isDownloading: boolean;
  tableSlug: string;
  downloadConditions: DownloadConditions;
  onChangeCondition: (queryCondition: DownloadConditions) => void;
  allSimpleFields: SimpleField[];
};

const DownloadRecordsWithSQLDrawer = (
  props: DownloadRecordsWithSQLDrawerProps
) => {
  const {
    isOpen,
    onOpenChange,
    tableSlug,
    onClickDownload,
    isDownloading,
    downloadConditions,
    onChangeCondition,
    allSimpleFields,
  } = props;

  return (
    <Drawer.Root open={isOpen} onOpenChange={onOpenChange} size="xl">
      <Drawer.Content>
        <Drawer.Title>
          <DownloadDrawerTitle
            onClickDownload={onClickDownload}
            isDownloading={isDownloading}
            isDisabled={downloadConditions.selectedFields.length === 0}
          />
        </Drawer.Title>
        <Drawer.Body>
          <DownloadRecordsPreviewWithSQL
            tableSlug={tableSlug}
            onChangeCondition={onChangeCondition}
            downloadConditions={downloadConditions}
            allSimpleFields={allSimpleFields}
          />
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};

export { DownloadRecordsWithSQLDrawer };
