import { ViewType } from "@usemorph/morph-dashboard-types";
import { match } from "ts-pattern";
import { Badge } from "~/components_next/Badge";

type ViewTypeBadgeProps = {
  type: ViewType;
};

const ViewTypeBadge = (props: ViewTypeBadgeProps) => {
  const { type } = props;

  const label = match(type)
    .with("kanban", () => "Kanban")
    .with("table", () => "Table")
    .exhaustive();

  return (
    <Badge variant="secondary" size="xs">
      {label} View
    </Badge>
  );
};

export { ViewTypeBadge };
