import { FieldClientModel } from "../../../fields/field";

type CsvDownloadFieldClientModelData = {
  baseField: FieldClientModel;
  isMasked: boolean;
};

export class CsvDownloadFieldClientModel {
  constructor(readonly data: CsvDownloadFieldClientModelData) {}

  public get baseField(): FieldClientModel {
    return this.data.baseField;
  }

  public get isMasked(): boolean {
    return this.data.isMasked;
  }

  public maskField(): CsvDownloadFieldClientModel {
    return new CsvDownloadFieldClientModel({
      baseField: this.data.baseField,
      isMasked: true,
    });
  }

  public unmaskField(): CsvDownloadFieldClientModel {
    return new CsvDownloadFieldClientModel({
      baseField: this.data.baseField,
      isMasked: false,
    });
  }

  public showField(): CsvDownloadFieldClientModel {
    return new CsvDownloadFieldClientModel({
      baseField: this.data.baseField.showField(),
      isMasked: this.data.isMasked,
    });
  }

  public hideField(): CsvDownloadFieldClientModel {
    return new CsvDownloadFieldClientModel({
      baseField: this.data.baseField.hideField(),
      isMasked: this.data.isMasked,
    });
  }
}
