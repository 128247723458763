import { versionKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiVersion from "~/api/useApiVersion";

interface UseListVersionQueryParams {
  teamSlug: string;
}

const useListVersionQuery = ({ teamSlug }: UseListVersionQueryParams) => {
  const { _listVersions } = useApiVersion()

  const client = useQueryClient();
  client.setQueryDefaults(
    versionKeys.all({ teamSlug }),
    commonQueryOptions
  );

  return {
    queryKey: versionKeys.all({
      teamSlug,
    }),
    queryFn: () => {
      return _listVersions({
        teamSlug,
      });
    },
  };
};

export { useListVersionQuery };
