import { Button } from "~/components_next/Button";
import { Dialog } from "~/components_next/Dialog";
import { Executable } from '~/clientModel/executable';

export const DeleteAlertDialog = ({
  isOpen,
  setIsOpen,
  onDelete,
  deleteExecutable,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onDelete: () => void;
  deleteExecutable: Executable<void, unknown>
}) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Content>
        <Dialog.Title>Warning !</Dialog.Title>
        <Dialog.Body>Are you sure you want to delete?</Dialog.Body>
        <Dialog.Footer>
          <Button
            variant="danger"
            onClick={onDelete}
            isLoading={deleteExecutable.isExecuting}
          >
            Delete
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
};
