// types
import { useQueryClient } from "react-query";
import useApiImport from "~/api/useApiImport";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { importsKeys } from "../keys";

interface UseCreateImportDataShemaFromExcelCsvMutationParams {
  teamSlug: string;
  databaseId: string;
}

const useCreateImportDataSchemaFromExcelCsvMutation = ({
  teamSlug,
  databaseId,
}: UseCreateImportDataShemaFromExcelCsvMutationParams) => {
  const { _createImportDataFromExcelCsv } = useApiImport();

  const client = useQueryClient();

  return {
    mutationFn: ({
      tableSlug,
      sheet,
      storageBucketId,
      storageObjectId,
      fileType,
      hasHeader,
    }: {
      tableSlug?: string | undefined;
      sheet?: number | undefined;
      storageBucketId: string;
      storageObjectId: string;
      fileType: "csv" | "excel";
      hasHeader: boolean;
    }) => {
      return _createImportDataFromExcelCsv({
        teamSlug,
        databaseId,
        requestBody: {
          tableSlug,
          sheet,
          storageBucketId,
          storageObjectId,
          fileType,
          hasHeader,
        },
      });
    },
    onSuccess: () => {
      return invalidateAll(client, importsKeys.all({ teamSlug, databaseId }));
    },
  };
};

export { useCreateImportDataSchemaFromExcelCsvMutation };
