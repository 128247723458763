import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { Loadable } from "~/clientModel/loadable";
import { Table } from "~/components_next/Table";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";
import { useCallback } from "react";

type ConsumedUsageTableProps = {
  smartFunctionsLoadable: Loadable<SmartFunctionsClientModel>;
};

export const ConsumedUsageTable = (props: ConsumedUsageTableProps) => {
  const { smartFunctionsLoadable } = props;

  const getTableData = useCallback(
    (smartFunctions: SmartFunctionsClientModel) => {
      const computedFieldActions = [
        "Sync field synchronization",
        "Generate Text Field synchronization",
        "Calculation Text Field synchronization",
        "Aggregation Field synchronization",
      ];

      const computedFieldTableData = computedFieldActions.map((syncAction) => ({
        action: syncAction,
        consumedCredits: "1 credits / record",
      }));

      const smartFunctionTableData = smartFunctions.allSmartFunctions.map(
        ({ label, consumedCredits }) => ({
          action: `Updating AI Field with "${label}" method`,
          consumedCredits: `${consumedCredits} credits / record`,
        })
      );

      return [...computedFieldTableData, ...smartFunctionTableData];
    },
    []
  );

  return (
    <WithFallback loadables={[smartFunctionsLoadable] as const}>
      {([smartFunctions]) => (
        <Table.Root size="xs">
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>Action</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>Consumed credits</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {getTableData(smartFunctions).map(({ action, consumedCredits }) => (
              <Table.Row key={action}>
                <Table.RowHeaderCell>{action}</Table.RowHeaderCell>
                <Table.Cell>{consumedCredits}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      )}
    </WithFallback>
  );
};
