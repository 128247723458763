import { useState } from "react";
import { DeleteMemberDialog } from "./DeleteMemberDialog";
import { UpdateMemberDialog } from "./UpdateMemberDialog";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { Text } from "~/components_next/Text";
import { IconButton } from "~/components_next/IconButton";
import { Spacer } from "~/components_next/Spacer";
import { useDisclosure } from "~/hooks/useDisclosure";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
import { Button } from "~/components_next/Button";
import { BsThreeDotsVertical } from "react-icons/bs";

export type Member = {
	username: string;
	isAdmin: boolean;
	inviteStatus: "invited" | "joined";
	userId: string;
	firstName: string;
	lastName: string;
	email: string;
};

const MemberUserItem = ({
	member,
	isAdminOfSelectedTeam,
}: {
	member: Member;
	isAdminOfSelectedTeam: boolean;
}) => {
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

	const teamSlug = useTeamSlug()

	const updateMember = useDisclosure();
	const deleteMember = useDisclosure();

	return (
		<>
			<Box
				px="4"
				py="2"
			>
				<Flex width="100%" align='center'>
					<Box css={{
						width: "30%",
						overflow: "hidden",
						textOverflow: "ellipsis",
						whiteSpace: "nowrap",
					}}
					>
						<Text variant="description">{member.username}</Text>
					</Box>
					<Box
						css={{
							width: "35%",
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
						}}
						px="2"
					>
						<Text>{member.email}</Text>
					</Box>
					<Box
						css={{
							width: "15%",
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
						}}
						px="2"
					>
						<Text>{member.isAdmin ? "admin" : "general"}</Text>
					</Box>
					<Box
						css={{
							width: "15%",
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
						}}
						px="2"
					>
						<Text>{member.inviteStatus}</Text>
					</Box>
					<Spacer />

					{isAdminOfSelectedTeam && (

						<SimpleDropdownMenu
							trigger={
								<IconButton
									icon={<BsThreeDotsVertical />}
									tooltip={'Open member'}
								></IconButton>
							}
						>
							<Box p="1">
								<Box mb="2">
									<Button
										variant="actionText"
										size="sm"
										onClick={(e) => {
											e.preventDefault();
											updateMember.onOpen();
										}}
									>
										Change type
									</Button>
								</Box>
								<Box>
									<Button
										variant="actionText"
										size="sm"
										onClick={(e) => {
											e.preventDefault();
											deleteMember.onOpen();
										}}
									>
										Remove user
									</Button>
								</Box>
							</Box>
						</SimpleDropdownMenu>

						// <Menu
						// 	size="sm"
						// 	isOpen={isMenuOpen}
						// 	onClose={() => {
						// 		setIsMenuOpen(false);
						// 	}}
						// >
						// 	<MenuButton
						// 		as={IconButton}
						// 		aria-label="More"
						// 		size="xs"
						// 		isRound
						// 		icon={<BsThreeDotsVertical />}
						// 		mr={2}
						// 		variant="ghost"
						// 		color="gray.400"
						// 		onClick={(e) => {
						// 			e.preventDefault();
						// 			setIsMenuOpen((prev) => !prev);
						// 		}}
						// 	/>
						// 	<MenuList>
						// 		<MenuItem
						// 			fontSize="12px"
						// 			color="black.500"
						// 			onClick={(e) => {
						// 				e.preventDefault();
						// 				updateMember.onOpen();
						// 			}}
						// 		>
						// 			Change Status
						// 		</MenuItem>
						// 		<MenuItem
						// 			fontSize="12px"
						// 			color="red.500"
						// 			onClick={(e) => {
						// 				e.preventDefault();
						// 				deleteMember.onOpen();
						// 			}}
						// 		>
						// 			Remove User
						// 		</MenuItem>
						// 	</MenuList>
						// </Menu>
					)}
				</Flex>
			</Box>
			<UpdateMemberDialog
				isOpen={updateMember.isOpen}
				setIsOpen={updateMember.setIsOpen}
				onClose={updateMember.onClose}
				userId={member.userId}
				teamSlug={teamSlug}
				isAdmin={member.isAdmin}
			/>
			<DeleteMemberDialog
				isOpen={deleteMember.isOpen}
				setIsOpen={deleteMember.setIsOpen}
				onClose={deleteMember.onClose}
				userId={member.userId}
				username={member.username}
				teamSlug={teamSlug}
			/>
		</>
	);
};

export default MemberUserItem;
