import { ForwardedRef, forwardRef, useCallback, useMemo } from "react";
import { P, match } from "ts-pattern";

import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { TablesClientModel } from "~/clientModel/tables";
import { TableClientModel } from "~/clientModel/tables/table";
import {
  BasicTypeaheadPrompt,
  FieldSuggestionItem,
  TableSuggestionItem,
  VariableSuggestionItem,
  basicFieldFilterFn,
  basicTableFilterFn,
} from "~/presenters/prompt";
import { CanvasVariablesValue } from "../../common/CanvasVariablesProvider";
import { basicVariableValueFilterFn } from "~/presenters/prompt/utils/filterFnUtils";
import { css } from "@stitches/react";

const textAreaRootStyle = css({
  minHeight: "80px",
});

const textAreaTheme = {
  root: `${textAreaRootStyle()}`,
};

type SuggestOptionType =
  | FieldClientModel
  | TableClientModel
  | CanvasVariablesValue;

type PlaygroundPromptFormProps = {
  tablesForTypeahead?: TablesClientModel;
  fieldsForTypeahead?: FieldsClientModel;
  variablesForTypeahead?: CanvasVariablesValue[];
  value: string;
  onChange?: (value: string) => void;
  variant?: "input" | "textarea";
};

const PlaygroundPromptForm = forwardRef(
  (props: PlaygroundPromptFormProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      tablesForTypeahead,
      fieldsForTypeahead,
      variablesForTypeahead,
      value,
      onChange,
      variant = "input",
    } = props;

    const optionItems: SuggestOptionType[] = useMemo(() => {
      return [
        ...(tablesForTypeahead ? tablesForTypeahead.allTables : []),
        ...(variablesForTypeahead ? variablesForTypeahead : []),
        ...(fieldsForTypeahead ? fieldsForTypeahead.allFields : []),
      ];
    }, [fieldsForTypeahead, tablesForTypeahead, variablesForTypeahead]);

    const filterFn = useCallback((item: SuggestOptionType, query: string) => {
      return match(item)
        .with(P.instanceOf(TableClientModel), (table: TableClientModel) =>
          basicTableFilterFn(table, query)
        )
        .with(
          { cellId: P.string, name: P.string, value: P._ },
          (variable: CanvasVariablesValue) =>
            basicVariableValueFilterFn(variable, query)
        )
        .otherwise((field: FieldClientModel) =>
          basicFieldFilterFn(field, query)
        );
    }, []);

    const convertOptionItemToText = useCallback((item: SuggestOptionType) => {
      return match(item)
        .with(
          P.instanceOf(TableClientModel),
          (table: TableClientModel) => `${table.tableSlug}`
        )
        .with(
          { cellId: P.string, name: P.string, value: P._ },
          (variable: CanvasVariablesValue) =>
            `\${${variable.cellId}+${variable.name}}` // todo: cellIdどこから取ってくるか確認する
          // `\${${variable.cellId}+${variable.name}}`
        )
        .otherwise((field: FieldClientModel) => `\${${field.dotNotatedName}}`);
    }, []);

    const renderHTMLTextContent = useCallback((item: SuggestOptionType) => {
      return match(item)
        .with(
          P.instanceOf(TableClientModel),
          (table: TableClientModel) => table.label
        )
        .with(
          { cellId: P.string, name: P.string, value: P._ },
          (variable: CanvasVariablesValue) => variable.name
        )
        .otherwise((field: FieldClientModel) => field.label);
    }, []);

    const renderSuggestedItem = useCallback((item: SuggestOptionType) => {
      return match(item)
        .with(P.instanceOf(TableClientModel), (table: TableClientModel) => (
          <TableSuggestionItem table={table} />
        ))
        .with(
          { cellId: P.string, name: P.string, value: P._ },
          (variable: CanvasVariablesValue) => (
            <VariableSuggestionItem variable={variable} />
          )
        )
        .otherwise((field: FieldClientModel) => (
          <FieldSuggestionItem field={field} />
        ));
    }, []);

    return (
      <>
        <BasicTypeaheadPrompt<SuggestOptionType>
          ref={ref}
          theme={variant === "input" ? undefined : textAreaTheme}
          textContent={value}
          onUpdate={onChange}
          optionItems={optionItems}
          filterFn={filterFn}
          convertOptionItemToText={convertOptionItemToText}
          renderHTMLTextContent={renderHTMLTextContent}
          renderSuggestedItem={renderSuggestedItem}
          focusOnLoad
        />
      </>
    );
  }
);

PlaygroundPromptForm.displayName = "PlaygroundPromptForm";

export { PlaygroundPromptForm };
