import { useQuery } from "react-query";
import { useComposeLoadable } from "~/clientModel/loadable";
import { RssFeedItemsClientModelFactory } from "~/clientModel/rssFeed";
import { useListSampleRssFeedQuery } from "~/serverStateStore/rssFeed";

export type CreateUseListSampleRssFeedLoadableProps = {
  rssUrl: string;
  enabled: boolean
}

const useListSampleRssFeedLoadable = (props: {
  teamSlug: string;
  rssUrl: string;
  enabled: boolean
}) => {
  const { teamSlug, rssUrl, enabled } = props

  return useComposeLoadable(
    useQuery({
      ...useListSampleRssFeedQuery({
        teamSlug,
        rssUrl,
        enabled,
      }),
      select: (data) => {
        return RssFeedItemsClientModelFactory.fromRssFeedItemResponse(data)
      }
    })
  )
}


export const createUseListSampleRssFeedLoadable = (props: {
  teamSlug: string;
}) => {
  const { teamSlug } = props

  return ({ rssUrl, enabled }: CreateUseListSampleRssFeedLoadableProps) => useListSampleRssFeedLoadable({ teamSlug, rssUrl, enabled })
}
