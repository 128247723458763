type RecordIdentifierClientModelData = {
  identityRecord: Record<string, unknown>;
};

export class RecordIdentifierClientModel {
  public constructor(readonly data: RecordIdentifierClientModelData) {}

  public isSame(other: RecordIdentifierClientModel): boolean {
    return RecordIdentifierClientModel.isSame(this, other);
  }

  public static isSame(
    a: RecordIdentifierClientModel,
    b: RecordIdentifierClientModel
  ): boolean {
    const isSameKeyLength =
      Object.keys(a.data.identityRecord).length ===
      Object.keys(b.data.identityRecord).length;

    if (!isSameKeyLength) return false;

    return Object.entries(a.data.identityRecord).every(
      ([key, aVal]) => b.data.identityRecord[key] === aVal
    );
  }
}
