import {
  DashboardViewConditionObject,
  SimpleField,
} from "@usemorph/morph-dashboard-types";

type SqlDependencies = { teamSlug: string; databaseId: string };

type GenerateSqlDependencies = {
  message: string;
  tableSlug: string;
  condition?: DashboardViewConditionObject;
  extraTables?: {
    tableSlug: string;
    condition?: DashboardViewConditionObject;
  }[];
} & SqlDependencies;

type SuggestPromptsForGenerateSqlDependencies = {
  tableSlug?: string;
  viewId?: string;
  language: string;
  condition?: DashboardViewConditionObject;
} & SqlDependencies;

type DownloadAsCSVFromExecuteSQLDependencies = {
  sql: string;
  fields?: SimpleField[];
} & SqlDependencies;

const sqlKeys = {
  /**
   * all
   */
  all: ({ teamSlug, databaseId }: SqlDependencies) =>
    [teamSlug, databaseId, "sql"] as const,

  /**
   * generate sql
   */
  allGenerateSql: ({ teamSlug, databaseId }: SqlDependencies) =>
    [...sqlKeys.all({ teamSlug, databaseId }), "generateSql"] as const,

  generateSql: ({
    teamSlug,
    databaseId,
    message,
    tableSlug,
    condition,
    extraTables,
  }: GenerateSqlDependencies) =>
    [
      ...sqlKeys.allGenerateSql({
        teamSlug,
        databaseId,
      }),
      {
        message,
        tableSlug,
        condition,
        extraTables,
      },
    ] as const,

  /**
   * suggest prompts for generate sql
   */
  allSuggestPromptsForGenerateSql: ({
    teamSlug,
    databaseId,
  }: SqlDependencies) =>
    [
      ...sqlKeys.all({ teamSlug, databaseId }),
      "suggestPromptsForGenerateSql",
    ] as const,

  suggestPromptsForGenerateSql: ({
    teamSlug,
    viewId,
    databaseId,
    language,
    tableSlug,
    condition,
  }: SuggestPromptsForGenerateSqlDependencies) =>
    [
      ...sqlKeys.allSuggestPromptsForGenerateSql({ teamSlug, databaseId }),
      { language, tableSlug, viewId, condition },
    ] as const,

  /**
   * Download as CSV from execute sql
   */
  allDownloadAsCSVFromExecuteSQL: ({ teamSlug, databaseId }: SqlDependencies) =>
    [
      ...sqlKeys.all({ teamSlug, databaseId }),
      "downloadAsCSVFromExecuteSQL",
    ] as const,

  downloadAsCSVFromExecuteSQL: ({
    teamSlug,
    databaseId,
    sql,
    fields,
  }: DownloadAsCSVFromExecuteSQLDependencies) =>
    [
      ...sqlKeys.allDownloadAsCSVFromExecuteSQL({ teamSlug, databaseId }),
      { sql, fields },
    ] as const,
};

type SqlHistoriesDependencies = {
  teamSlug: string;
  databaseId: string;
};

type GetGenerateSqlHistoryDependencies = {
  tableSlug?: string;
  viewId?: string;
  limit?: number;
  skip?: number;
} & SqlDependencies;

const sqlHistoriesKeys = {
  all: ({ teamSlug, databaseId }: SqlHistoriesDependencies) =>
    [teamSlug, databaseId, "sqlHistories"] as const,

  /**
   * get generate sql history
   */
  allGetGenerateSqlHistory: ({
    teamSlug,
    databaseId,
  }: SqlHistoriesDependencies) =>
    [
      ...sqlHistoriesKeys.all({ teamSlug, databaseId }),
      "getGenerateSqlHistory",
    ] as const,
  getGeneratedSqlHistory: ({
    teamSlug,
    databaseId,
    tableSlug,
    viewId,
    limit,
    skip,
  }: GetGenerateSqlHistoryDependencies) =>
    [
      ...sqlHistoriesKeys.allGetGenerateSqlHistory({ teamSlug, databaseId }),
      {
        tableSlug,
        viewId,
        limit,
        skip,
      },
    ] as const,
};

export { sqlKeys, sqlHistoriesKeys };
