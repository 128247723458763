import { CellSelectionState, RecordsTableBaseRecord } from "../types";

export const getClipboardValueFromCellSelection = (
  cellSelections: CellSelectionState
) => {
  const selectionAllRecords = cellSelections.map(
    (cellSelection) => cellSelection.record
  );
  const selectionAllFileds = [
    ...new Set(
      cellSelections.flatMap((cellSelection) => cellSelection.fieldSlugs)
    ),
  ];

  // extract fields specified with selectionAllFileds from selectionAllRecords.
  const clipboardValue = selectionAllRecords.map((record) => {
    const values = Object.keys(record.values)
      .map((key) => {
        if (selectionAllFileds.includes(key)) {
          return `${record.values[key].value}`;
        }
        return undefined;
      })
      .filter((value) => value !== undefined) as string[];

    return values.join(`\t`);
  });

  return [...clipboardValue].join("\n") + "\n";
};

export const getClipboardValueFromRecordsSelection = (
  recordsSelection: RecordsTableBaseRecord[]
) => {
  const clipboardValue = recordsSelection.map((record) => {
    const values = Object.keys(record.values)
      .map((key) => {
        return `${record.values[key].value}`;
      })
      .filter((value) => value !== undefined) as string[];

    return values.join(`\t`);
  });

  return [...clipboardValue].join("\n") + "\n";
};
