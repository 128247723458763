import { DashboardCreateNormalizedTableScheduleRequestBody } from "@usemorph/morph-dashboard-types";
import { TimezoneClientModelDefactory } from "~/clientModel/timezone";
import { CreatingComputedFieldSyncScheduleClientModel } from "./ComputedFieldSyncScheduleClientModel";
import { ScheduledTimeClientModelDefactory } from "../../../scheduleCore/ScheduledTime";
import { TargetDaysClientModelDefactory } from "../../../scheduleCore/targetDays";

export class CreatingComputedFieldSyncScheduleClientModelDefactory {
  public static toDashboardCreateNormalizedTableScheduleRequestBody({
    creatingComputedFieldSyncSchedule,
    databaseId,
    tableSlug,
  }: {
    creatingComputedFieldSyncSchedule: CreatingComputedFieldSyncScheduleClientModel;
    databaseId: string;
    tableSlug: string;
  }): DashboardCreateNormalizedTableScheduleRequestBody {
    const { targetDays, scheduledTime, isEnabled, timezone } =
      creatingComputedFieldSyncSchedule.data;

    return {
      targetDays: TargetDaysClientModelDefactory.toTargetDaysArray(targetDays),
      scheduledMins:
        ScheduledTimeClientModelDefactory.toScheduledMins(scheduledTime),
      isEnabled,
      timezone: TimezoneClientModelDefactory.toTimezoneType(timezone),
      databaseId,
      tableSlug,
    };
  }
}
