import { useQueryClient } from "react-query";
import useApiRecord from "~/api/useApiRecord";
import { recordsKeys } from "../keys";

// types
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import { RecordValueType } from "@usemorph/morph-dashboard-types";

interface UseDeleteRecordsParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
}

const useCreateRecordMutation = ({
  databaseId,
  tableSlug,
  teamSlug,
}: UseDeleteRecordsParams) => {
  const { _createRecord } = useApiRecord();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      values,
    }: {
      values: { key: string; value: RecordValueType }[];
    }) => {
      return _createRecord({
        teamSlug,
        databaseId,
        tableSlug,
        body: {
          fixedValue: [],
          values,
        },
      });
    },
    onSuccess: () => {
      const getRecordKey = recordsKeys.allGetRecord({
        teamSlug,
        databaseId,
        tableSlug,
      });
      const getRecordWithSqlKey = recordsKeys.allGetRecordWithSql({
        teamSlug,
        databaseId,
        tableSlug,
      });
      broadcast(getRecordKey);
      broadcast(getRecordWithSqlKey);

      return invalidateAll(client, getRecordKey, getRecordWithSqlKey);
    },
  };
};

export { useCreateRecordMutation };
