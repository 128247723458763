import { useMutation } from "react-query";
import { useComposeExecutable } from "~/clientModel/executable";
import { useGetResourceMutation } from "~/serverStateStore/resources";

export const createUseFetchFileExecutable = (props: { teamSlug: string }) => {
  const { teamSlug } = props;

  return () =>
    useComposeExecutable(
      useMutation({
        ...useGetResourceMutation({
          teamSlug,
        }),
      })
    );
};
