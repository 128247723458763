import { pageKeys } from "../keys";
import useApiPage from "~/api/useApiPage";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "~/serverStateStore/util/commonQueryOptions";

const useGetPublishedNotebookPagesQuery = ({
  teamSlug,
  apiKey,
}: {
  teamSlug: string;
  apiKey: string;
}) => {
  const { _getPublishedNotebookPages } = useApiPage();

  const client = useQueryClient();
  client.setQueryDefaults(
    pageKeys.getPublicPages({
      teamSlug,
      apiKey,
    }),
    commonQueryOptions
  );

  return {
    queryKey: pageKeys.getPublicPages({
      teamSlug,
      apiKey,
    }),
    queryFn: () => {
      return _getPublishedNotebookPages({
        teamSlug,
        apiKey,
      });
    },
  };
};

export { useGetPublishedNotebookPagesQuery };
