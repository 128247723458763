import { FieldClientModel } from "~/clientModel/fields/field";
import { DateDisplaySettingClientModel } from "~/clientModel/fields/field/displaySetting/date";
import {
  ReadonlyRecordEntryClientModelBase,
  ReadonlyRecordEntryClientModelBaseConstructorParams,
} from "../RecordEntryClientModelBase";
import { zRequiredString, zValidate } from "../validatorsUtil";

type CreatedAtRecordEntryClientModelData =
  ReadonlyRecordEntryClientModelBaseConstructorParams;

export class CreatedAtRecordEntryClientModel extends ReadonlyRecordEntryClientModelBase<string> {
  constructor(data: CreatedAtRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "createdAt" {
    return "createdAt";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(zRequiredString(field), this.data.rawValue);
  }

  public getValidatedDisplayValue(field: FieldClientModel) {
    const validatedValue = this.getValidatedValue(field);

    if (
      !validatedValue.isValid ||
      !(field.displaySetting instanceof DateDisplaySettingClientModel)
    ) {
      return validatedValue;
    }

    const displayValue = field.displaySetting.getDisplayValue(
      validatedValue.value
    );

    return displayValue
      ? ({
          isValid: true,
          value: displayValue,
        } as const)
      : validatedValue;
  }
}
