import { ReactNode } from "react";
import { OutlinedWrapper } from "../../atomos/OutlinedWrapper";

type TypeConfigurationWrapperProps = {
  children: ReactNode;
};

export const TypeConfigurationWrapper = (
  props: TypeConfigurationWrapperProps
) => {
  const { children } = props;

  if (!children) {
    return null;
  }

  return (
    <OutlinedWrapper title="Type Configuration">{children}</OutlinedWrapper>
  );
};
