import {
  ValidationResult,
  zValidate,
} from "~/clientModel/utils/validationResultType";
import {
  FieldTypeClientModel,
  SyncValueFieldTypeClientModel,
} from "./fieldType";
import { z } from "zod";
import slugify from "slugify";
import { match, P } from "ts-pattern";
import { EmbeddingClientModel } from "./embedding";
import { MergeKeyClientModel, MergeKeyClientModelFactory } from "./mergeKey";
import {
  DisplaySettingClientModel,
  DisplaySettingClientModelFactory,
} from "./displaySetting";
import { SmartFunctionsClientModel } from "./fieldType/smartFunction/smartFunctions";
import { FieldsClientModel } from "../FieldsClientModel";

export type FieldClientModelData = {
  name: string;
  displayName: string | null;
  description: string | null;
  originalTableSlug?: string;
  type: FieldTypeClientModel;
  isHidden: boolean;
  isNullable: boolean;
  isPrimary: boolean;
  embedding: EmbeddingClientModel | null;
  mergeKey: MergeKeyClientModel | null;
  displaySetting: DisplaySettingClientModel | null;
  isUnique: boolean;
};

export class FieldClientModel {
  readonly #data: FieldClientModelData;

  constructor(data: FieldClientModelData) {
    this.#data = data;
  }

  public get data(): FieldClientModelData {
    return this.#data;
  }

  public get name(): string {
    return this.#data.name;
  }

  public get displayName(): string | null {
    return this.#data.displayName ?? null;
  }

  public get description(): string {
    return this.#data.description ?? "";
  }

  public get originalTableSlug(): string | null {
    return this.#data.originalTableSlug ?? null;
  }

  public get type(): FieldTypeClientModel {
    return this.#data.type;
  }

  public get isHidden(): boolean {
    return this.#data.isHidden;
  }

  public get isNullable(): boolean {
    return this.#data.isNullable;
  }

  public get isPrimary(): boolean {
    return this.#data.isPrimary;
  }

  public get isAutoGenerated(): boolean {
    return this.#data.type.isAutoGenerated;
  }

  public get isDeletable(): boolean {
    return !this.#data.isPrimary && isDeletableType(this.#data.type);
  }

  public get label(): string {
    // displayName が空文字か undefined の場合は name を表示する
    return this.#data.displayName || this.#data.name;
  }

  public get displaySetting(): DisplaySettingClientModel | null {
    return this.#data.displaySetting ?? null;
  }

  public get embedding(): EmbeddingClientModel | null {
    return this.#data.embedding ?? null;
  }

  public get isEmbedded(): boolean {
    return !!this.#data.embedding;
  }

  public get isEmbeddable(): boolean {
    return this.#data.type.isEmbeddable;
  }

  public get isReservedField(): boolean {
    return (
      this.#data.name.startsWith("morph_reserved_") ||
      this.#data.name === "health_check"
    );
  }

  public get dotNotatedName(): string {
    if (this.#data.originalTableSlug) {
      return `${this.#data.originalTableSlug}.${this.#data.name}`;
    }
    return this.#data.name;
  }

  public get isUnique(): boolean {
    return this.#data.isUnique;
  }

  public updateName(name: string): FieldClientModel {
    return new FieldClientModel({
      ...this.#data,
      name,
    });
  }

  public updateDisplayName(displayName: string): FieldClientModel {
    return new FieldClientModel({
      ...this.#data,
      displayName,
    });
  }

  public updateDisplayNameLinkingWithFieldName(
    displayName: string
  ): FieldClientModel {
    const getLinkedFieldName = (_displayName: string): string =>
      slugify(_displayName, {
        lower: true,
        replacement: "_",
      });

    const currentIsLinking =
      getLinkedFieldName(this.#data.displayName ?? "") === this.#data.name;

    return currentIsLinking
      ? new FieldClientModel({
          ...this.#data,
          displayName,
          name: getLinkedFieldName(displayName),
        })
      : new FieldClientModel({
          ...this.#data,
          displayName,
        });
  }

  public updateDescription(description: string): FieldClientModel {
    return new FieldClientModel({
      ...this.#data,
      description,
    });
  }

  public updateType(type: FieldTypeClientModel): FieldClientModel {
    const typeChanged = this.#data.type.type !== type.type;

    const numericFieldTypes = [
      "number",
      "bigNumber",
      "decimal",
      "autoNumber",
      "autoBigNumber",
      "aggregateValue",
      "calculation",
    ] as const;

    const dateFieldTypes = [
      "date",
      "datetime",
      "time",
      "createdAt",
      "lastEditedAt",
    ] as const;

    // フィールドタイプが変更された場合は、display settingを初期化する
    if (typeChanged) {
      const initialDisplaySetting = match(type)
        .with(
          {
            type: P.union(...numericFieldTypes),
          },
          () => DisplaySettingClientModelFactory.createEmpty("number")
        )
        .with(
          {
            type: P.union(...dateFieldTypes),
          },
          () => DisplaySettingClientModelFactory.createEmpty("date")
        )
        .otherwise(() => null);
      return new FieldClientModel({
        ...this.#data,
        type,
        displaySetting: initialDisplaySetting,
        isNullable: true,
      });
    }

    // field typeが更新されていなくて、syncValueのsyncTargetFieldTypeが更新された場合は、display settingを初期化する
    if (type.type === "syncValue") {
      const syncTargetFieldTypeChanged =
        type.syncTargetFieldType !==
        (this.#data.type as SyncValueFieldTypeClientModel).syncTargetFieldType;

      if (syncTargetFieldTypeChanged)
        if (
          type.syncTargetFieldType &&
          (numericFieldTypes as readonly string[]).includes(
            type.syncTargetFieldType
          )
        ) {
          return new FieldClientModel({
            ...this.#data,
            type,
            displaySetting:
              DisplaySettingClientModelFactory.createEmpty("number"),
          });
        } else if (
          type.syncTargetFieldType &&
          (dateFieldTypes as readonly string[]).includes(
            type.syncTargetFieldType
          )
        ) {
          return new FieldClientModel({
            ...this.#data,
            type,
            displaySetting:
              DisplaySettingClientModelFactory.createEmpty("date"),
          });
        } else {
          return new FieldClientModel({
            ...this.#data,
            type,
            displaySetting: null,
          });
        }
    }

    return new FieldClientModel({
      ...this.#data,
      type,
    });
  }

  public get canUpdateIsNullable(): boolean {
    return match(this.#data.type.type)
      .with(
        P.union(
          "autoNumber",
          "autoBigNumber",
          "formula",
          "createdAt",
          "createdBy",
          "lastEditedAt",
          "lastEditedBy",
          "syncValue",
          "generateText",
          "calculation",
          "aggregateValue",
          "smartFunction"
        ),
        () => false
      )
      .with(
        P.union(
          // text
          "shortText",
          "longText",
          "email",
          "phoneNumber",
          "url",
          "date",
          "datetime",
          "time",

          // number
          "number",
          "decimal",
          "bigNumber",

          // other primitives
          "boolean",

          // select
          "singleSelect",
          "multiSelect",

          // file
          "image",
          "attachment",

          // structured data
          "json",
          "array",
          "html"
        ),
        () => true
      )
      .exhaustive();
  }

  public updateIsNullable(isNullable: boolean): FieldClientModel {
    return new FieldClientModel({
      ...this.#data,
      isNullable,
    });
  }

  public updateIsPrimary(isPrimary: boolean): FieldClientModel {
    return new FieldClientModel({
      ...this.#data,
      isPrimary,
      isNullable: false,
    });
  }

  public showField(): FieldClientModel {
    return new FieldClientModel({
      ...this.#data,
      isHidden: false,
    });
  }

  public hideField(): FieldClientModel {
    return new FieldClientModel({
      ...this.#data,
      isHidden: true,
    });
  }

  public validateName(): ValidationResult {
    return zValidate(
      z
        .string()
        .min(1)
        .regex(
          /^[a-z0-9_]+$/,
          "Field name should be a combination of lowercase alphanumeric characters and underscores"
        ),
      this.#data.name
    );
  }

  public isAllValid({
    fields,
    smartFunctions,
  }: {
    fields: FieldsClientModel;
    smartFunctions: SmartFunctionsClientModel;
  }): boolean {
    return (
      this.validateName().isValid &&
      this.type.validate({ smartFunctions }).isValid &&
      match(this.#data.type.type)
        .with(
          P.union("syncValue", "generateText", "calculation", "aggregateValue"),
          () => !!fields.mergeKey && fields.mergeKey.hasTargets
        )
        .otherwise(() => true)
    );
  }

  public get mergeKey(): MergeKeyClientModel | null {
    return this.#data.mergeKey ?? null;
  }

  public get isMergeField(): boolean {
    return !!this.#data.mergeKey;
  }

  public initMergeKey(): FieldClientModel {
    return new FieldClientModel({
      ...this.#data,
      mergeKey: MergeKeyClientModelFactory.createEmpty(),
    });
  }

  public addMergeKeyTarget({
    targetTableSlug,
    targetFieldName,
  }: {
    targetTableSlug: string;
    targetFieldName: string;
  }): FieldClientModel {
    const mergeKey =
      this.#data.mergeKey ?? MergeKeyClientModelFactory.createEmpty();

    return new FieldClientModel({
      ...this.#data,
      mergeKey: mergeKey.addTarget({
        tableSlug: targetTableSlug,
        fieldName: targetFieldName,
      }),
    });
  }

  public removeMergeKey(): FieldClientModel {
    return new FieldClientModel({
      ...this.#data,
      mergeKey: null,
    });
  }

  public updateDisplaySetting(
    displaySetting: DisplaySettingClientModel
  ): FieldClientModel {
    return new FieldClientModel({
      ...this.#data,
      displaySetting,
    });
  }
}

const isDeletableType = (type: FieldTypeClientModel) =>
  match(type.type)
    .with(
      P.union("createdAt", "createdBy", "lastEditedAt", "lastEditedBy"),
      () => false
    )
    .with(
      P.union(
        "syncValue",
        "generateText",
        "calculation",
        "aggregateValue",
        "smartFunction",
        "formula",
        "autoNumber",
        "autoBigNumber",
        "shortText",
        "longText",
        "email",
        "phoneNumber",
        "url",
        "date",
        "datetime",
        "time",
        "number",
        "decimal",
        "bigNumber",
        "boolean",
        "singleSelect",
        "multiSelect",
        "image",
        "attachment",
        "json",
        "array",
        "html"
      ),
      () => true
    )
    .exhaustive();
