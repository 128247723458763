import { UserColorClientModel } from "./userColor";

type UserClientModelData = {
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  iconUrl: string | null;
  userId: string;
  color: UserColorClientModel;
};

export class FindUserClientModel {
  constructor(readonly data: UserClientModelData) {}
  /**
   * Getters
   */
  get email(): string {
    return this.data.email;
  }

  get username(): string {
    return this.data.username;
  }

  get firstName(): string {
    return this.data.firstName;
  }

  get lastName(): string {
    return this.data.lastName;
  }

  get iconUrl(): string | null {
    return this.data.iconUrl;
  }

  get userId(): string {
    return this.data.userId;
  }

  get color(): UserColorClientModel {
    return this.data.color;
  }
}
