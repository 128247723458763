import { useQueryClient } from "react-query";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import useApiRssFeed from "~/api/useApiRssFeed";
import { rssFeedKeys } from "../keys";
import { MessageClientModelFactory } from '../../../clientModel/general/MessageClientModelFactory';

type UseDeleteRssTableMappingMutationParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useDeleteRssTableMappingMutation = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseDeleteRssTableMappingMutationParams) => {
  const { _deleteRssTableMapping } = useApiRssFeed();
  const client = useQueryClient();

  return {
    mutationFn: async () => {
      const response = await _deleteRssTableMapping({
        teamSlug,
        databaseId,
        tableSlug,
      });
      return MessageClientModelFactory.fromMessageResponse(response)
    },
    onSuccess: () => {
      return invalidateAll(
        client,
        rssFeedKeys.all({
          teamSlug,
        })
      );
    },
  };
};

export { useDeleteRssTableMappingMutation };
