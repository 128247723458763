import useApiNotebook from "~/api/useApiNotebook";
import { useQueryClient } from "react-query";
import { DashboardNotebookCellVariableRequestObject } from "@usemorph/morph-dashboard-types";
import { notebookKeys } from "../keys";
import { commonQueryOptions } from "~/serverStateStore/util/commonQueryOptions";

interface UseExecuteNotebookCellQueryParams {
  teamSlug: string;
  notebookId: string;
  cellId: string;
  variables?: DashboardNotebookCellVariableRequestObject;
}

const useExecuteNotebookCellQuery = ({
  teamSlug,
  notebookId,
  cellId,
  variables,
}: UseExecuteNotebookCellQueryParams) => {
  const { _executeNotebookCell, _executeNotebookCellWithVariables } =
    useApiNotebook();

  const client = useQueryClient();

  client.setQueryDefaults(
    notebookKeys.executeCell({
      teamSlug,
      notebookId,
      cellId,
      variables,
    }),
    {
      ...commonQueryOptions,
      staleTime: 30 * 1000,
    }
  );

  return {
    queryKey: notebookKeys.executeCell({
      teamSlug,
      notebookId,
      cellId,
      variables,
    }),
    queryFn: () => {
      if (variables && variables.length > 0) {
        return _executeNotebookCellWithVariables({
          teamSlug,
          notebookId,
          cellId,
          variables,
        });
      }
      return _executeNotebookCell({
        teamSlug,
        notebookId,
        cellId,
      });
    },
  };
};

export { useExecuteNotebookCellQuery };
