import constate from "constate";
import { useState } from "react";
import { useDebounceState } from "~/hooks/useDebounceState";

type RenderAreaSize = {
  width: number;
  height: number;
};

const _usePlaygroundRenderAreaSize = () => {
  const [renderAreaSize, setRenderAreaSize] = useState<RenderAreaSize>({
    width: -1,
    height: -1,
  });

  const debouncedValue = useDebounceState(renderAreaSize, 100);

  return {
    renderAreaSize: debouncedValue,
    setRenderAreaSize,
  };
};

export const [
  PlaygroundRenderAreaSizeProvider,
  usePlaygroundRenderAreaSize,
  usePlaygroundSetRenderAreasize,
] = constate(
  _usePlaygroundRenderAreaSize,
  (value) => value.renderAreaSize,
  (value) => value.setRenderAreaSize
);
