import {
  CanvasCellClientModelBase,
  CanvasCellDataType,
  CanvasCellResizeParams,
} from "./CanvasCellClientModelBase";

export type CanvasCellRichTextDataType = CanvasCellDataType & {
  cellType: "richText";
};

export class CanvasCellRichTextClientModel extends CanvasCellClientModelBase<
  CanvasCellRichTextClientModel,
  CanvasCellRichTextDataType
> {
  constructor(data: CanvasCellRichTextDataType) {
    super(data);
  }

  /**
   *
   * Custom value getters
   *
   */

  get html(): string | null {
    return this.source.richText || null;
  }

  get isResizable(): boolean {
    return true;
  }

  get showSourceHandle(): boolean {
    return false;
  }

  get showTargetHandle(): boolean {
    return false;
  }

  updateRichText(richText: string): CanvasCellRichTextClientModel {
    return new CanvasCellRichTextClientModel({
      ...this.data,
      source: {
        ...this.data.source,
        richText,
      },
    });
  }

  updateSize(size: {
    width: number;
    height: number;
  }): CanvasCellRichTextClientModel {
    return new CanvasCellRichTextClientModel({
      ...this.data,
      settings: {
        ...this.data.settings,
        width: size.width,
        height: size.height,
      },
    });
  }

  updateCellName(cellName: string): CanvasCellRichTextClientModel {
    return new CanvasCellRichTextClientModel({
      ...this.data,
      cellName,
    });
  }

  onResize(resizePrams: CanvasCellResizeParams): CanvasCellRichTextClientModel {
    return new CanvasCellRichTextClientModel({
      ...this.data,
      settings: {
        ...this.data.settings,
        ...resizePrams,
      },
    });
  }
}
