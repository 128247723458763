import { useQuery } from "react-query";
import { useComposeLoadable } from "~/clientModel/loadable";
import {
  useGetMyColorsQuery,
  useGetPresetColorsQuery,
} from "~/serverStateStore/canvasStyle";

export const useCanvasMyColorsLoadable = ({
  teamSlug,
}: {
  teamSlug: string;
}) => {
  const getMyColursQueryOptions = useGetMyColorsQuery({ teamSlug });
  return useComposeLoadable(
    useQuery({
      ...getMyColursQueryOptions,
      select: (data) => {
        return {
          count: data.count,
          items: [...data.items],
        };
      },
    })
  );
};

export const createUseMyColorsLoadable = ({
  teamSlug,
}: {
  teamSlug: string;
}) => {
  return () => useCanvasMyColorsLoadable({ teamSlug });
};

export const useCanvasPresetColorsLoadable = ({
  teamSlug,
}: {
  teamSlug: string;
}) => {
  const getPresetColorsQueryOptions = useGetPresetColorsQuery({ teamSlug });
  return useComposeLoadable(
    useQuery({
      ...getPresetColorsQueryOptions,
      select: (data) => {
        return {
          count: data.count,
          items: [...data.items],
        };
      },
    })
  );
};
