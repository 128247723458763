import { useQuery } from "react-query";
import { useGetLoginUserQuery } from "../queries/useGetLoginUserQuery";

type UseSelectedMeTeamParams = {
  teamSlug: string;
};

export const useSelectedMeTeam = ({ teamSlug }: UseSelectedMeTeamParams) => {
  return useQuery({
    ...useGetLoginUserQuery(),
    select: (data) => {
      return data.teams.find(
        ({ teamSlug: _teamSlug }) => _teamSlug === teamSlug
      );
    },
  });
};
