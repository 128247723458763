import { useQueryClient } from "react-query";
import useApiWidgetData, {
  RegistDataApiReuqestParams,
} from "~/api/useApiWidgetData";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { widgetDataKeys } from "../keys";

type WidgetDataApiType =
  | "query"
  | "create"
  | "update"
  | "delete"
  | "upload"
  | "aggregate"
  | "download"
  | "chat";

type UseRegistWidgetDataMutationParams = {
  databaseId: string;
  teamSlug: string;
};

const useRegistWidgetDataMutation = ({
  teamSlug,
  databaseId,
}: UseRegistWidgetDataMutationParams) => {
  const { _registWidgetData } = useApiWidgetData();
  const client = useQueryClient();

  return {
    // todo: _registWidgetDataのフィンターフェースの独自定義やめる
    mutationFn: (
      params: Omit<RegistDataApiReuqestParams, "teamSlug" | "databaseId">
    ) => {
      return _registWidgetData({ teamSlug, databaseId, ...params });
    },
    onSuccess: () => {
      return invalidateAll(
        client,
        widgetDataKeys.all({
          teamSlug,
          databaseId,
        })
      );
    },
  };
};

export { useRegistWidgetDataMutation };
export type { WidgetDataApiType };
