import useApiRecord from "~/api/useApiRecord";
import { recordHistoriesKeys } from "../keys";

// types
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";

interface UseListRecordHistoriesQueryParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
  limit?: number;
  skip?: number;
  data: Record<string, unknown>;
}

const useListRecordHistoriesQuery = ({
  databaseId,
  tableSlug,
  teamSlug,
  data,
  limit,
  skip,
}: UseListRecordHistoriesQueryParams) => {
  const { _getRecordHistory } = useApiRecord();

  const client = useQueryClient();
  client.setQueryDefaults(
    recordHistoriesKeys.all({ databaseId, tableSlug, teamSlug }),
    commonQueryOptions
  );

  return {
    queryKey: recordHistoriesKeys.listRecordHistory({
      databaseId,
      tableSlug,
      teamSlug,
      limit,
      skip,
      data,
    }),
    queryFn: () => {
      return _getRecordHistory({
        databaseId,
        teamSlug,
        tableSlug,
        requestBody: {
          limit,
          skip,
          data,
        },
      });
    },
  };
};

export { useListRecordHistoriesQuery };
