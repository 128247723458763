import {
  DashboardCreateNotebookRequestBody,
  DashboardNotebookCellResponse,
  DashboardNotebookCellSourceObject,
  DashboardNotebookCellVariableRequestObject,
  DashboardNotebookDetailResponse,
  DashboardNotebookListResponse,
  DashboardNotebookPromptListResponse,
  DashboardNotebookResponse,
  DashboardNotebookVariableOptionsResponse,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";
type DashboardGeneralResponse = {
  message: string;
};

export default function useApiNotebook() {
  const { executeRequest, executeRequestAxiosRaw } = useApi();

  const _listNotebooks = async ({
    teamSlug,
    databaseId,
    tableSlug,
    limit,
    skip,
  }: {
    teamSlug: string;
    databaseId: string;
    tableSlug?: string;
    limit?: number;
    skip?: number;
  }): Promise<DashboardNotebookListResponse> => {
    return executeRequest(
      "get",
      `/notebook`,
      {
        databaseId,
        tableSlug,
        limit,
        skip,
      },
      {
        teamSlug,
      },
      undefined
    );
  };

  const _findNotebooks = async ({
    teamSlug,
    notebookId,
  }: {
    teamSlug: string;
    notebookId: string;
  }): Promise<DashboardNotebookDetailResponse> => {
    return executeRequest(
      "get",
      `/notebook/${notebookId}`,
      undefined,
      {
        teamSlug,
      },
      {}
    );
  };

  const _createNotebook = async ({
    teamSlug,
    body,
  }: {
    teamSlug: string;
    body: DashboardCreateNotebookRequestBody;
  }): Promise<DashboardNotebookResponse> => {
    return executeRequest(
      "post",
      `/notebook`,
      undefined,
      {
        teamSlug,
      },
      body
    );
  };

  const _deleteNotebook = async ({
    teamSlug,
    notebookId,
  }: {
    teamSlug: string;
    notebookId: string;
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest(
      "delete",
      `/notebook/${notebookId}`,
      undefined,
      {
        teamSlug,
      },
      {}
    );
  };

  const _updateNotebook = async ({
    teamSlug,
    notebookId,
    notebookName,
    description,
    isPrivate,
    isPagePublic,
    publicApiKeyType,
  }: {
    teamSlug: string;
    notebookId: string;
    notebookName: string;
    description?: string;
    isPrivate?: boolean;
    isPagePublic?: boolean;
    publicApiKeyType?: string;
  }): Promise<DashboardNotebookResponse> => {
    return executeRequest(
      "put",
      `/notebook/${notebookId}`,
      undefined,
      {
        teamSlug,
      },
      {
        notebookName,
        description,
        isPrivate,
        isPagePublic,
        publicApiKeyType,
      }
    );
  };

  /**
   * Cell
   */
  const _createNotebookCell = async ({
    teamSlug,
    notebookId,
    cellType,
    cellName,
    source,
    afterCellId,
    parentIds,
    requestId,
    settings,
  }: {
    teamSlug: string;
    notebookId: string;
    cellType: string;
    cellName: string;
    source: DashboardNotebookCellSourceObject;
    afterCellId?: string | null;
    parentIds?: string[] | null;
    requestId?: string;
    settings: any;
  }): Promise<DashboardNotebookCellResponse> => {
    return executeRequest(
      "post",
      `/notebook/${notebookId}/cell`,
      undefined,
      {
        teamSlug,
      },
      {
        cellType,
        cellName,
        source,
        afterCellId,
        requestId,
        parentIds,
        andRun: false,
        settings,
      }
    );
  };

  const _createCellFromOption = async ({
    teamSlug,
    notebookId,
    candidateId,
    settings,
    parentIds,
  }: {
    teamSlug: string;
    notebookId: string;
    candidateId: string;
    settings: any;
    parentIds?: string[];
  }) => {
    return executeRequest(
      "post",
      `/notebook/${notebookId}/cell/candidate`,
      undefined,
      {
        teamSlug,
      },
      {
        candidateId,
        settings,
        parentIds,
      }
    );
  };

  const _updateNotebookCell = async ({
    teamSlug,
    notebookId,
    cellType,
    cellId,
    isPublic,
    source,
    andRun = true,
    shouldGenerate = true,
  }: {
    teamSlug: string;
    notebookId: string;
    cellType: string;
    cellId: string;
    isPublic?: boolean;
    source: DashboardNotebookCellSourceObject;
    andRun?: boolean;
    shouldGenerate?: boolean;
  }): Promise<DashboardNotebookCellResponse> => {
    return executeRequest(
      "put",
      `/notebook/${notebookId}/cell/${cellId}`,
      undefined,
      {
        teamSlug,
      },
      {
        cellType,
        source,
        andRun,
        isPublic,
        shouldGenerate,
      }
    );
  };

  const _updateNotebookCellMeta = async ({
    teamSlug,
    notebookId,
    cellId,
    cellName,
    afterCellId,
    hidePrompt,
    settings,
    parentIds,
  }: {
    teamSlug: string;
    notebookId: string;
    cellId: string;
    cellName?: string;
    afterCellId?: string | null;
    hidePrompt: boolean;
    settings?: Record<string, unknown>;
    parentIds?: string[] | null;
  }): Promise<DashboardNotebookCellResponse> => {
    return executeRequest(
      "put",
      `/notebook/${notebookId}/cell/${cellId}/setting`,
      undefined,
      {
        teamSlug,
      },
      {
        afterCellId,
        hiddenPrompt: hidePrompt,
        settings,
        parentIds,
        cellName,
      }
    );
  };

  const _removeNotebookCell = async ({
    teamSlug,
    notebookId,
    cellId,
  }: {
    teamSlug: string;
    notebookId: string;
    cellId: string;
  }): Promise<DashboardNotebookCellResponse> => {
    return executeRequest(
      "delete",
      `/notebook/${notebookId}/cell/${cellId}`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  const _executeNotebookCell = async ({
    teamSlug,
    notebookId,
    cellId,
  }: {
    teamSlug: string;
    notebookId: string;
    cellId: string;
  }): Promise<DashboardNotebookCellResponse> => {
    return executeRequest(
      "post",
      `/notebook/${notebookId}/cell/${cellId}/run`,
      undefined,
      {
        teamSlug,
      }
    );
  };

  const _executePublicNotebookCell = async ({
    teamSlug,
    cellId,
    apiKey,
  }: {
    teamSlug: string;
    cellId: string;
    apiKey: string;
  }) => {
    const { data } =
      await executeRequestAxiosRaw<DashboardNotebookCellResponse>(
        "POST",
        `/public/notebook-cell/${cellId}/run`,
        undefined,
        {
          teamSlug,
          "x-api-key": apiKey,
        }
      );

    return data;
  };

  const _executeNotebookCellWithVariables = async ({
    teamSlug,
    notebookId,
    cellId,
    variables,
  }: {
    teamSlug: string;
    notebookId: string;
    cellId: string;
    variables?: DashboardNotebookCellVariableRequestObject;
  }): Promise<DashboardNotebookCellResponse> => {
    return executeRequest(
      "post",
      `/notebook/${notebookId}/cell/${cellId}/run-with-variables`,
      undefined,
      {
        teamSlug,
      },
      {
        variables,
      }
    );
  };

  const _executePublicNotebookCellWithVariables = async ({
    teamSlug,
    cellId,
    variables,
    apiKey,
  }: {
    teamSlug: string;
    cellId: string;
    variables?: DashboardNotebookCellVariableRequestObject;
    apiKey: string;
  }) => {
    const { data } =
      await executeRequestAxiosRaw<DashboardNotebookCellResponse>(
        "POST",
        `/public/notebook-cell/${cellId}/run-with-variables`,
        undefined,
        {
          teamSlug,
          "x-api-key": apiKey,
        },
        {
          variables,
        }
      );

    return data;
  };

  const _retryNotebookCell = async ({
    teamSlug,
    notebookId,
    cellId,
  }: {
    teamSlug: string;
    notebookId: string;
    cellId: string;
  }): Promise<DashboardNotebookCellResponse> => {
    return executeRequest(
      "post",
      `/notebook/${notebookId}/cell/${cellId}/retry`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  const _activateNotebook = async ({
    teamSlug,
    databaseId,
  }: {
    teamSlug: string;
    databaseId: string;
  }) => {
    return executeRequest(
      "post",
      `/notebook/activate`,
      undefined,
      {
        teamSlug,
      },
      {
        databaseId,
      }
    );
  };

  const _getNotebookUsage = async ({ teamSlug }: { teamSlug: string }) => {
    return executeRequest<{ usage: number; maxUsage: number }>(
      "get",
      "/notebook-usage",
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  const _getVariableViewResultOptions = async ({
    teamSlug,
    notebookId,
    cellId,
    name,
    skip,
    limit,
    variables,
  }: {
    teamSlug: string;
    notebookId: string;
    cellId: string;
    name: string;
    skip?: number;
    limit?: number;
    variables?: DashboardNotebookCellVariableRequestObject;
  }) => {
    return executeRequest<DashboardNotebookVariableOptionsResponse>(
      "post",
      `/notebook/${notebookId}/cell/${cellId}/variable-options`,
      undefined,
      {
        teamSlug,
      },
      {
        name,
        skip,
        limit,
        variables,
      }
    );
  };

  const _getPublicVariableViewResultOptions = async ({
    teamSlug,
    apiKey,
    cellId,
    name,
    skip,
    limit,
    variables,
  }: {
    teamSlug: string;
    apiKey: string;
    cellId: string;
    name: string;
    skip?: number;
    limit?: number;
    variables?: DashboardNotebookCellVariableRequestObject;
  }) => {
    const { data } =
      await executeRequestAxiosRaw<DashboardNotebookVariableOptionsResponse>(
        "post",
        `/public/notebook-cell/${cellId}/variable-options`,
        undefined,
        {
          teamSlug,
          "x-api-key": apiKey,
        },
        {
          name,
          skip,
          limit,
          variables,
        }
      );

    return data;
  };

  const _getPublicCell = async ({
    teamSlug,
    apiKey,
    variables,
  }: {
    teamSlug: string;
    apiKey: string;
    variables?: DashboardNotebookCellVariableRequestObject[];
  }) => {
    const { data } = await executeRequestAxiosRaw<{ html: string }>(
      "post",
      `/public/canvas/cell`,
      undefined,
      {
        teamSlug,
        "x-api-key": apiKey,
      },
      {
        variables,
      }
    );

    return data;
  };

  const _getVisualizationPromptConfig = async ({
    teamSlug,
  }: {
    teamSlug: string;
  }): Promise<DashboardNotebookPromptListResponse> => {
    return executeRequest("get", `/notebook-prompt`, undefined, {
      teamSlug,
    });
  };

  return {
    _listNotebooks,
    _findNotebooks,
    _createNotebook,
    _updateNotebook,
    _deleteNotebook,
    _createNotebookCell,
    _createCellFromOption,
    _updateNotebookCell,
    _updateNotebookCellMeta,
    _activateNotebook,
    _removeNotebookCell,
    _executeNotebookCell,
    _executeNotebookCellWithVariables,
    _retryNotebookCell,
    _getNotebookUsage,
    _getVariableViewResultOptions,
    _executePublicNotebookCell,
    _executePublicNotebookCellWithVariables,
    _getPublicVariableViewResultOptions,
    _getPublicCell,
    _getVisualizationPromptConfig,
  };
}
