import { useMutation } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import {
  FieldClientModel,
  FieldClientModelDefactory,
} from "~/clientModel/fields/field";
import { useErrorToast } from "~/components_next/Error";
import { useOptimisticUpdateSimpleFieldMutation } from "~/serverStateStore";

type UseUpdateFieldVisibilityExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useUpdateFieldVisibilityExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseUpdateFieldVisibilityExecutableParams): Executable<
  { field: FieldClientModel },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  const updateFieldOptions = useOptimisticUpdateSimpleFieldMutation({
    teamSlug,
    databaseId,
    tableSlug,
  });

  return useComposeExecutable(
    useMutation({
      ...updateFieldOptions,
      onMutate: ({ field }) => {
        const simpleField = FieldClientModelDefactory.toSimpleField(field);
        return updateFieldOptions.onMutate({ requestBody: simpleField });
      },
      mutationFn: ({ field }) => {
        const simpleField = FieldClientModelDefactory.toSimpleField(field);

        return updateFieldOptions.mutationFn({
          requestBody: simpleField,
        });
      },
      onError: (e) => {
        errorToast(e);
        return updateFieldOptions.onError();
      },
    })
  );
};

export const createUseUpdateFieldVisibilityExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseUpdateFieldVisibilityExecutableParams) => {
  return () =>
    useUpdateFieldVisibilityExecutable({ teamSlug, databaseId, tableSlug });
};
