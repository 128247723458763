// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NumberDisplaySettingClientModel {
  export type Precision = 0 | 1 | 2 | 3 | 4;
}

type Precision = NumberDisplaySettingClientModel.Precision;

type NumberDisplaySettingClientModelData = {
  precision: Precision;
  useGroupSeparator: boolean;
  units: string;
  displayUnitsAfterNumber: boolean;
};

export class NumberDisplaySettingClientModel {
  public constructor(readonly data: NumberDisplaySettingClientModelData) {}

  public get precision(): Precision {
    return this.data.precision;
  }

  public get precisionOptions(): readonly {
    value: Precision;
    label: string;
  }[] {
    return [
      { value: 0, label: "1" },
      { value: 1, label: "1.0" },
      { value: 2, label: "1.00" },
      { value: 3, label: "1.000" },
      { value: 4, label: "1.0000" },
    ];
  }

  public get useGroupSeparator(): boolean {
    return this.data.useGroupSeparator;
  }

  public get units(): string {
    return this.data.units;
  }

  public get displayUnitsAfterNumber(): boolean {
    return this.data.displayUnitsAfterNumber;
  }

  public get displayExample(): string {
    return this.getDisplayValue("12345.6789") ?? "";
  }

  public updatePrecision(
    precision: Precision
  ): NumberDisplaySettingClientModel {
    return new NumberDisplaySettingClientModel({
      ...this.data,
      precision,
    });
  }

  public updateUseGroupSeparator(
    useGroupSeparator: boolean
  ): NumberDisplaySettingClientModel {
    return new NumberDisplaySettingClientModel({
      ...this.data,
      useGroupSeparator,
    });
  }

  public updateUnits(unit: string): NumberDisplaySettingClientModel {
    return new NumberDisplaySettingClientModel({
      ...this.data,
      units: unit,
    });
  }

  public updateDisplayUnitsAfterNumber(
    displayUnitsAfterNumber: boolean
  ): NumberDisplaySettingClientModel {
    return new NumberDisplaySettingClientModel({
      ...this.data,
      displayUnitsAfterNumber,
    });
  }

  public getDisplayValue(value: string | null): string | null {
    if (value == null) {
      return null;
    }

    const number = parseFloat(String(value));

    if (isNaN(number)) {
      return String(value);
    }

    const numberPartString = number.toLocaleString("ja-JP", {
      useGrouping: this.useGroupSeparator,
      maximumFractionDigits: this.precision,
    });

    const unitsPartString =
      this.data.displayUnitsAfterNumber && this.units ? ` ${this.units}` : "";

    return `${numberPartString}${unitsPartString}`;
  }
}
