import { useParams } from "react-router-dom";

const useViewId = () => {
  const { viewId } = useParams();

  if (!viewId) {
    throw new Error(`viewId not found.`);
  }

  return viewId;
};

export { useViewId };
