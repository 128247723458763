import { forwardRef, ReactNode } from "react";
import { Box } from "../Box";
import { styled } from "~/stitches";

// todo
// - キーボードでのフォーカス操作、選択

/**
 * MenuRoot
 */

type MenuRootProps = {
  children: ReactNode;
};

const RootBox = styled(Box, {
  border: "1px solid $border",
  borderRadius: "var(--radius-3)",
  backgroundColor: "$bg0",
});

const MenuRoot = forwardRef<HTMLDivElement, MenuRootProps>((props, ref) => {
  const { children } = props;

  return <RootBox ref={ref}>{children}</RootBox>;
});

MenuRoot.displayName = "MenuRoot";

/**
 * MenuListItem
 */

type MenuListItemProps = {
  children: ReactNode;
  onSelect: () => void;
};

const ItemBox = styled(Box, {
  padding: "$1 $4",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "$bg2",
  },
  fontSize: "12px",
});

const MenuListItem = forwardRef<HTMLDivElement, MenuListItemProps>(
  (props, ref) => {
    const { children, onSelect } = props;

    return (
      <ItemBox ref={ref} onClick={onSelect}>
        {children}
      </ItemBox>
    );
  }
);

MenuListItem.displayName = "MenuListItem";

const Menu = {
  Root: MenuRoot,
  Item: MenuListItem,
};

export { Menu };
export type { MenuRootProps, MenuListItemProps };
