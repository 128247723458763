import useApiDatabase from "~/api/useApiDatabase";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { databaseKeys } from "../keys";

interface UseDeleteDatabaseMutationParams {
  teamSlug: string;
}

type DeleteDatabaseMutationProps = {
  databaseId: string
}

const useDeleteDatabaseMutation = ({
  teamSlug,
}: UseDeleteDatabaseMutationParams) => {
  const { _deleteDatabase } = useApiDatabase();
  const client = useQueryClient();

  return {
    mutationFn: ({ databaseId }: { databaseId: string }) => {
      return _deleteDatabase({
        teamSlug,
        databaseId,
      });
    },
    onSuccess: () => {
      return invalidateAll(client, databaseKeys.listDatabases({ teamSlug }));
    },
  };
};

export { useDeleteDatabaseMutation, type DeleteDatabaseMutationProps };
