import { useMemo } from "react";
import { BsStar, BsStarFill } from "react-icons/bs";
import { useMutation, useQuery } from "react-query";
import {
  useCreateTableFavoriteMutation,
  useDeleteTableFavoriteMutation,
  useListTableFavoritesQuery,
} from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTableSlug } from "~/utilHooks/useTableSlug";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { IconButton } from "~/components_next/IconButton";

const SourceFavoriteButton = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const tableSlug = useTableSlug();

  const { data: favoriteTables } = useQuery({
    ...useListTableFavoritesQuery({
      databaseId,
      teamSlug,
    }),
  });

  const isFavorited = useMemo(() => {
    if (!favoriteTables) {
      return false;
    }

    return favoriteTables.tables.some((table) => table.tableSlug === tableSlug);
  }, [favoriteTables, tableSlug]);

  const { mutateAsync: addToFavorite, isLoading: isAdding } = useMutation({
    ...useCreateTableFavoriteMutation({
      databaseId,
      teamSlug,
    }),
  });

  const { mutateAsync: removeFromFavorite, isLoading: isRemoving } =
    useMutation({
      ...useDeleteTableFavoriteMutation({
        databaseId,
        teamSlug,
      }),
    });

  const handleClick = () => {
    if (isFavorited) {
      removeFromFavorite({ tableSlug });
    } else {
      addToFavorite({ tableSlug });
    }
  };

  const icon = useMemo(() => {
    if (isFavorited) {
      return <BsStarFill />;
    }
    return <BsStar />;
  }, [isFavorited]);

  return (
    <>
      <IconButton
        icon={icon}
        tooltip="Add to favorite"
        onClick={handleClick}
        isLoading={isAdding || isRemoving}
      />
    </>
  );
};

export { SourceFavoriteButton };
