import useApiDatabase from "~/api/useApiDatabase";
import {
  DashboardCreateDatabaseRequestBody,
  DashboardDatabaseObject,
} from "@usemorph/morph-dashboard-types";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { databaseKeys } from "../keys";

interface UseCreateDatabaseMutationParams {
  teamSlug: string;
}

type CreateDatabaseMutationProps = DashboardCreateDatabaseRequestBody

const useCreateDatabaseMutation = ({
  teamSlug,
}: UseCreateDatabaseMutationParams) => {
  const { _createDatabase, _findDatabase } = useApiDatabase();
  const client = useQueryClient();

  return {
    mutationFn: (requestBody: DashboardCreateDatabaseRequestBody) => {
      const func = async (): Promise<DashboardDatabaseObject> => {
        const response = await _createDatabase({
          teamSlug,
          requestBody,
        });
        if (response.status === "created") {
          return response;
        }

        // DB作成後にDB画面に遷移するために
        // DBが作成されたかされていないかを判断する必要有り
        // 1秒ごとにfindDatabaseでポーリングする
        const sleep = (ms: number) =>
          new Promise((resolve) => setTimeout(resolve, ms));

        // eslint-disable-next-line no-constant-condition
        while (true) {
          await sleep(1000);
          const findResponse = await _findDatabase({
            teamSlug,
            databaseId: response.databaseId,
          });
          if (findResponse.status === "created") {
            return findResponse;
          }
        }
      };
      return func();
    },
    onSuccess: () => {
      return invalidateAll(client, databaseKeys.listDatabases({ teamSlug }));
    },
  };
};

export { useCreateDatabaseMutation, type CreateDatabaseMutationProps };
