const required = (value: string) => {
  if (value.length > 0) {
    return true;
  }
  return false;
};

const isNumber = (value: string) => {
  if (isNaN(Number(value))) {
    return false;
  }
  return true;
};

const isValidEmail = (email: string) => {
  // Regular expression pattern to match email format
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if the email matches the pattern
  if (emailPattern.test(email)) {
    return true;
  }

  return false;
};

const isValidDate = (dateString: string) => {
  // Regular expression for YYYY-MM-DD format
  const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

  if (!dateFormatRegex.test(dateString)) {
    // Invalid format
    return false;
  }

  // Split the date string into year, month, and day parts
  const [year, month, day] = dateString.split("-");

  // Create a Date object using the year, month, and day
  const date = new Date(
    parseInt(year, 10),
    parseInt(month, 10) - 1,
    parseInt(day, 10)
  );

  // Check if the Date object is valid
  // Note: The month value in the Date constructor is zero-based, hence subtracting 1 from the month value
  if (
    date.getFullYear() === parseInt(year, 10) &&
    date.getMonth() === parseInt(month, 10) - 1 &&
    date.getDate() === parseInt(day, 10)
  ) {
    return true;
  }

  return false;
};

const isValidDateTime = (dateTimeString: string): boolean => {
  // Regular expression for YYYY-MM-DD hh:mm format
  const dateTimeFormatRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;

  if (!dateTimeFormatRegex.test(dateTimeString)) {
    // Invalid format
    return false;
  }

  // Split the date-time string into date and time parts
  const [datePart, timePart] = dateTimeString.split(" ");

  // Validate the date part using the isValidDateFormat function from the previous example
  if (!isValidDate(datePart)) {
    return false;
  }

  // Extract the hour and minute from the time part
  const [hour, minute] = timePart.split(":");

  // Check if the hour and minute values are within valid range
  if (
    parseInt(hour, 10) >= 0 &&
    parseInt(hour, 10) <= 23 &&
    parseInt(minute, 10) >= 0 &&
    parseInt(minute, 10) <= 59
  ) {
    return true;
  }

  return false;
};

export const commonValidationFunctions = {
  required,
  isNumber,
  isValidEmail,
  isValidDate,
  isValidDateTime,
};
