import { Separator } from "@radix-ui/themes";
import { Executable } from "~/clientModel/executable";
import { FindPaymentClientModel } from "~/clientModel/payment/findPaymentClientModel";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { Container } from "~/components_next/Container";
import { Flex } from "~/components_next/Flex";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";
import { PreferenceTabType } from "~/features/GlobalNavigation/components/Preference/usePreferenceModal";
import { capitalize } from "~/utils/stringUtils";

interface BillingPresenterProps {
  paymentType?: FindPaymentClientModel["paymentType"];
  plan?: FindPaymentClientModel["plan"];
  isAdmin: boolean;
  onChangeTab: (tab: PreferenceTabType) => void;
  onClickCustomerDetail: () => void;
  createCustomerPortalSessionLoadable: Executable<void, { url: string }>;
}

export const BillingPresenter = (props: BillingPresenterProps) => {
  const {
    paymentType,
    plan,
    isAdmin,
    onChangeTab,
    onClickCustomerDetail,
    createCustomerPortalSessionLoadable,
  } = props;

  return (
    <>
      <Container css={{ maxWidth: "1080px" }} height="100%" p="4">
        <Box mb="4">
          <Text variant="heading">Billing</Text>
        </Box>

        {
          isAdmin && (
            <Box mb="4">
              <Separator size="4" />
              <Flex align={"center"} px="2" mt="2">
                <Box>
                  <Box>
                    <Text>Plan</Text>
                  </Box>
                  <Text variant="description">{plan?.name ?? "Free"}</Text>
                </Box>
                <Spacer />
                <Flex align={"center"}>
                  <Box mr="4"></Box>
                  <Button
                    variant="secondary"
                    onClick={() => onChangeTab("plans")}
                    size="sm"
                  >
                    Change plan
                  </Button>
                </Flex>
              </Flex>

              {/* <Separator size="4" /> */}
            </Box>
          )
        }

        <Box mb="4">
          <Separator size="4" />
          {paymentType && (
            <>
              <Flex align={"center"} px="2" my="2">
                <Box>
                  <Box>
                    <Text>Payment method</Text>
                  </Box>
                  <Text variant="description">
                    {paymentType ? capitalize(paymentType) : ""}
                  </Text>
                </Box>
                <Spacer />
                <Flex align={"center"}>
                  <Box mr="4"></Box>
                  {paymentType === "card" && (
                    <Button
                      variant="secondary"
                      onClick={onClickCustomerDetail}
                      isLoading={
                        createCustomerPortalSessionLoadable.isExecuting
                      }
                      size="sm"
                    >
                      Detail
                    </Button>
                  )}
                </Flex>
              </Flex>

              <Separator size="4" />
            </>
          )}
        </Box>
      </Container>
    </>
  );
};
