import { useCallback, useMemo } from "react";
import { CreatePromptCellField } from "~/clientModel/canvas/CanvasCreateCellClientModel";
import { CanvasVisualizationPromptConfigCientModel } from "~/clientModel/canvas/CanvasVisualizationPromptConfigClientModel";
import { FieldClientModel } from "~/clientModel/fields/field";
import { Flex } from "~/components_next/Flex";
import { Image } from "~/components_next/Image";
import { SimpleSelect } from "~/components_next/Select";
import { AxisMultiSelect } from "./AxisMultiSelect";
import { Box } from "~/components_next/Box";

type PromptCellXYInputProps = {
  xAxisInputType: "single" | "multiple" | "none";
  yAxisInputType: "single" | "multiple" | "none";
  xAxisFields?: CreatePromptCellField[] | null;
  yAxisFields?: CreatePromptCellField[] | null;
  onXAxisFieldsChange: (fields: CreatePromptCellField[] | null) => void;
  onYAxisFieldsChange: (fields: CreatePromptCellField[] | null) => void;
  fields: FieldClientModel[];
  chartTypeOptions?: CanvasVisualizationPromptConfigCientModel[];
};

const PromptCellXYInput = (props: PromptCellXYInputProps) => {
  const {
    xAxisInputType,
    yAxisInputType,
    xAxisFields,
    yAxisFields,
    onXAxisFieldsChange,
    onYAxisFieldsChange,
    fields,
    chartTypeOptions,
  } = props;

  const fieldsOptions = useMemo(() => {
    return (
      fields.map((field) => {
        return {
          label: field.displayName || field.name,
          value: field.name,
        };
      }) || []
    );
  }, [fields]);

  const handleXAxisSingleSelectChange = useCallback(
    (value: string | null) => {
      onXAxisFieldsChange(
        fields
          .filter((field) => field.name === value)
          .map((field) => {
            return {
              field,
            };
          })
      );
    },
    [fields, onXAxisFieldsChange]
  );

  const handleYAxisSingleSelectChange = useCallback(
    (value: string | null) => {
      onYAxisFieldsChange(
        fields
          .filter((field) => field.name === value)
          .map((field) => {
            return {
              field,
            };
          })
      );
    },
    [fields, onYAxisFieldsChange]
  );

  /**
   * Multi select callbacks
   */
  const handleSelectXAxisFieldFromMultiSelect = useCallback(
    (value: FieldClientModel) => {
      onXAxisFieldsChange([
        ...(xAxisFields?.filter((field) => field.field.name !== value.name) ||
          []),
        {
          field: value,
        },
      ]);
    },
    [onXAxisFieldsChange, xAxisFields]
  );

  const handleDeselectXAxisFieldFromMultiSelect = useCallback(
    (value: FieldClientModel) => {
      onXAxisFieldsChange(
        (xAxisFields || []).filter((field) => field.field.name !== value.name)
      );
    },
    [onXAxisFieldsChange, xAxisFields]
  );

  const handleSelectYAxisFieldFromMultiSelect = useCallback(
    (value: FieldClientModel) => {
      onYAxisFieldsChange([
        ...(yAxisFields?.filter((field) => field.field.name !== value.name) ||
          []),
        {
          field: value,
        },
      ]);
    },
    [onYAxisFieldsChange, yAxisFields]
  );

  const handleDeselectYAxisFieldFromMultiSelect = useCallback(
    (value: FieldClientModel) => {
      onYAxisFieldsChange(
        (yAxisFields || []).filter((field) => field.field.name !== value.name)
      );
    },
    [onYAxisFieldsChange, yAxisFields]
  );

  const handleSelectXAxisFieldAndConfigFromMultiSelect = useCallback(
    (
      field: FieldClientModel,
      config: CanvasVisualizationPromptConfigCientModel
    ) => {
      onXAxisFieldsChange([
        ...(xAxisFields?.filter((f) => f.field.name !== field.name) || []),
        {
          field,
          chartType: config.chartType,
        },
      ]);
    },
    [onXAxisFieldsChange, xAxisFields]
  );

  const handleSelectYAxisFieldAndConfigFromMultiSelect = useCallback(
    (
      field: FieldClientModel,
      config: CanvasVisualizationPromptConfigCientModel
    ) => {
      onYAxisFieldsChange([
        ...(yAxisFields?.filter((f) => f.field.name !== field.name) || []),
        {
          field,
          chartType: config.chartType,
        },
      ]);
    },
    [onYAxisFieldsChange, yAxisFields]
  );

  const getChartTypeIcon = useCallback(
    (field: CreatePromptCellField) => {
      const findConfig = chartTypeOptions?.find(
        (config) => config.chartType === field.chartType
      );
      if (!findConfig) return null;
      return <Image src={findConfig.icon} width={16} height={16} />;
    },
    [chartTypeOptions]
  );

  return (
    <Flex gap="4">
      <Box css={{ flex: 1 }}>
        {xAxisInputType === "single" && (
          <SimpleSelect
            label="X Axis"
            size="sm"
            variant="primary"
            options={fieldsOptions}
            value={xAxisFields?.[0]?.field.name || null}
            onChange={handleXAxisSingleSelectChange}
          />
        )}
        {xAxisInputType === "multiple" && (
          <AxisMultiSelect
            fields={fields}
            value={xAxisFields || null}
            badgeLabelSelector={(option) =>
              option.field.displayName || option.field.name
            }
            badgeIconSelector={getChartTypeIcon}
            label="X Axis"
            onSelectField={handleSelectXAxisFieldFromMultiSelect}
            onDeselectField={handleDeselectXAxisFieldFromMultiSelect}
            onSelectFieldAndConfig={
              handleSelectXAxisFieldAndConfigFromMultiSelect
            }
            getIsFieldOptionSelected={(field) =>
              Boolean(xAxisFields?.find((f) => f.field.name === field.name))
            }
            chartTypeOptions={chartTypeOptions}
          />
        )}
      </Box>
      <Box css={{ flex: 1 }}>
        {yAxisInputType === "single" && (
          <SimpleSelect
            label="Y Axis"
            size="sm"
            variant="primary"
            options={fieldsOptions}
            value={yAxisFields?.[0]?.field.name || null}
            onChange={handleYAxisSingleSelectChange}
          />
        )}
        {yAxisInputType === "multiple" && (
          <AxisMultiSelect
            fields={fields}
            value={yAxisFields || null}
            badgeLabelSelector={(option) =>
              option.field.displayName || option.field.name
            }
            badgeIconSelector={getChartTypeIcon}
            label="Y Axis"
            onSelectField={handleSelectYAxisFieldFromMultiSelect}
            onDeselectField={handleDeselectYAxisFieldFromMultiSelect}
            onSelectFieldAndConfig={
              handleSelectYAxisFieldAndConfigFromMultiSelect
            }
            getIsFieldOptionSelected={(field) =>
              Boolean(yAxisFields?.find((f) => f.field.name === field.name))
            }
            chartTypeOptions={chartTypeOptions}
          />
        )}
      </Box>
    </Flex>
  );
};

export { PromptCellXYInput };
