import { Flex } from "@radix-ui/themes";
import { Input } from "~/components_next/Input";
import { RecordValueReadOnlyInputCommonProps } from "../type";

type FormulaReadonlyInputProps =
  RecordValueReadOnlyInputCommonProps<"formula"> & {
    formula: string;
  };

const FormulaReadonlyInput = (props: FormulaReadonlyInputProps) => {
  const { formula, value } = props;

  const unescapedFormula = formula.replace(/\$\{(.*?)\}/g, (match, p1) => p1);

  return (
    <Flex direction="column" gap="1">
      <Input
        variant="supplementary"
        label="formula"
        defaultValue={unescapedFormula}
        readOnly={true}
      />
      <Input
        variant="primary"
        label="value"
        defaultValue={String(value)}
        readOnly={true}
      />
    </Flex>
  );
};

export { FormulaReadonlyInput };
