import { Separator } from "@radix-ui/themes";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { Flex } from "~/components_next/Flex";
import { SimpleTabs } from "~/components_next/Tabs";
import { Text } from "~/components_next/Text";
import MemberUserItem from "~/features/GlobalNavigation/components/Preference/members/MemberUserItem";
import { useDisclosure } from "~/hooks/useDisclosure";
import { useGetPaymentQuery } from "~/serverStateStore";
import { useMe, useSelectedMeTeam } from "~/serverStateStore/me";
import { useTeam } from "~/serverStateStore/teams";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { InviteMemberModal } from "./InviteMemberModal";

export const MembersAndGuests = () => {
  const teamSlug = useTeamSlug();

  const { data: me } = useMe();
  const { data: selectedTeam } = useSelectedMeTeam({ teamSlug });
  const { data: team } = useTeam({ teamSlug });

  const { data: payment } = useQuery({
    ...useGetPaymentQuery({ teamSlug }),
  });

  const isAdminOfSelectedTeam = useMemo(() => {
    if (me && selectedTeam) {
      const userSelectedTeam = me.teams.find(
        (team) => team.teamSlug === selectedTeam.teamSlug
      );
      return userSelectedTeam ? userSelectedTeam.isAdmin : false;
    }
    return false;
  }, [me, selectedTeam]);

  const inviteMemberModal = useDisclosure();

  const shouldShowInviteMemberButton = useMemo(() => {
    if (payment && payment.paymentType) {
      return true;
    }

    if (payment && payment.currentTrial) {
      return true;
    }

    return false;
  }, [payment]);

  return (
    <>
      {/* //members */}
      <Box mb="4">
        <Text variant="heading">Members</Text>
      </Box>

      <Box px="2" my="2">
        <SimpleTabs tabLabels={["Members"]}>
          <Box>
            <Box mt="4">
              <Text>Members</Text>
            </Box>
            <Box mb="4">
              <Text variant="description">Manage members here.</Text>
            </Box>

            {payment && shouldShowInviteMemberButton ? (
              <Button
                variant="primary"
                onClick={inviteMemberModal.onOpen}
                size="sm"
              >
                Invite Members
              </Button>
            ) : (
              <Box>
                <Text variant="errorMessage">
                  Upgrade plan to invite more members
                </Text>
              </Box>
            )}

            <Separator size="4" mt="4" />

            <Flex px="4" py="3">
              <Box style={{ width: "30%" }}>
                <Text variant="subheading">Name</Text>
              </Box>
              <Box style={{ width: "35%" }} px="2">
                <Text variant="subheading">Email</Text>
              </Box>
              <Box style={{ width: "15%" }} px="2">
                <Text variant="subheading">Type</Text>
              </Box>
              <Box style={{ width: "15%" }} px="2">
                <Text variant="subheading">Status</Text>
              </Box>
            </Flex>
            <Separator size="4" mb="1" />

            <Flex
              direction="column"
              // spacing={1}
              align="stretch"
            >
              {team?.user.map((member) => {
                return (
                  <MemberUserItem
                    key={member.userId}
                    member={member}
                    isAdminOfSelectedTeam={isAdminOfSelectedTeam}
                  />
                );
              })}
            </Flex>
            <Separator size="4" mt="1" />
          </Box>
          <></>
        </SimpleTabs>
      </Box>

      <InviteMemberModal
        isOpen={inviteMemberModal.isOpen}
        setIsOpen={inviteMemberModal.setIsOpen}
        onClose={inviteMemberModal.onClose}
      />
    </>
  );
};
