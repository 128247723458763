import { BsChevronDown } from "react-icons/bs";
import { Button } from "~/components_next/Button";
import {
  SimpleDropdownMenu,
  DropdownMenu,
} from "~/components_next/DropdownMenu";
import { Text } from "~/components_next/Text";
import { AccessAuthority } from "./types";

type AccessAuthoritySelectProps = {
  authority: AccessAuthority;
  onChange: (authority: AccessAuthority) => void;
  options?: AccessAuthority[];
  isLoading?: boolean;
};

export const AccessAuthoritySelect = (props: AccessAuthoritySelectProps) => {
  const {
    authority,
    onChange,
    options = ["Read & Write", "Read Only", "No Access"],
    isLoading,
  } = props;
  return (
    <SimpleDropdownMenu
      trigger={
        <Button variant="tertiary" isLoading={isLoading} size="sm">
          {authority}
          <BsChevronDown />
        </Button>
      }
    >
      {options.includes("Read & Write") && (
        <DropdownMenu.Item onClick={() => onChange("Read & Write")}>
          <Text>Read & Write</Text>
        </DropdownMenu.Item>
      )}
      {options.includes("Read Only") && (
        <DropdownMenu.Item onClick={() => onChange("Read Only")}>
          <Text>Read Only</Text>
        </DropdownMenu.Item>
      )}
      {options.includes("No Access") && (
        <DropdownMenu.Item onClick={() => onChange("No Access")}>
          <Text>No Access</Text>
        </DropdownMenu.Item>
      )}
    </SimpleDropdownMenu>
  );
};
