import { DashboardTableListResponse } from "@usemorph/morph-dashboard-types";
import { TableClientModelFactory } from "./table/TableClientModelFactory";
import { TablesClientModel } from "./TablesClientModel";

export class TablesClientModelFactory {
  public static createFromDashboardTableListResponse({
    tables,
  }: DashboardTableListResponse): TablesClientModel {
    return new TablesClientModel({
      tables: tables.map((table) =>
        TableClientModelFactory.createFromDashboardTableObject(table)
      ),
    });
  }
}
