import { Input } from "~/components_next/Input";
import { RecordValueInputCommonPropsWithValueType } from "../../../type";
import { forwardRef, ForwardedRef } from "react";

const DateTimeInputBase = forwardRef(function DateTimeInputBase(
  props: RecordValueInputCommonPropsWithValueType<string | null>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { value, errorMessages, isReadOnly, onChange, size } = props;

  return (
    <Input
      variant="primary"
      size={size}
      value={value ?? ""}
      errorMessage={errorMessages}
      readOnly={isReadOnly}
      type="datetime-local"
      onChange={(event) => onChange?.(event.target.value)}
      ref={ref}
    />
  );
});

export { DateTimeInputBase };
