import { FieldClientModel } from "~/clientModel/fields/field";
import {
  ReadonlyRecordEntryClientModelBase,
  UpdatableRecordEntryClientModelBaseConstructorParams,
} from "../RecordEntryClientModelBase";

const maskedValue = "********";
type MaskedValue = typeof maskedValue;

type MaskedValueRecordEntryClientModelData =
  UpdatableRecordEntryClientModelBaseConstructorParams;

export class MaskedValueRecordEntryClientModel extends ReadonlyRecordEntryClientModelBase<MaskedValue | null> {
  constructor(data: MaskedValueRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "maskedValue" {
    return "maskedValue";
  }

  public getValidatedValue(field: FieldClientModel) {
    return {
      value: maskedValue,
      isValid: true,
    } as const;
  }

  public getValidatedDisplayValue = this.getValidatedValue;

  public static isMaskedValue(value: unknown): value is MaskedValue {
    return value === maskedValue;
  }
}
