import {
  RecordSortConditionUnit,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { useCallback, useMemo } from "react";
import { Input } from "~/components_next/Input";
import { isArrayWithAtLeastOneElement } from "~/utils/commonTypeGuards";
import { MultipleSortConditionForm } from "./MultipleSortConditionForm";
import { Box } from "~/components_next/Box";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";

export const MultipleSortSelect = ({
  sort,
  fields,
  onChange,
}: {
  sort: RecordSortConditionUnit[] | undefined;
  fields: SimpleField[];
  onChange: (sort: RecordSortConditionUnit[] | undefined) => void;
}) => {
  const handleSelectOpen = useCallback(() => {
    if (sort) return;

    onChange([
      {
        key: fields[0].name,
        direction: "ascending",
      },
    ]);
  }, [fields, onChange, sort]);

  const inputValue = useMemo(() => {
    if (sort && sort.length) {
      return `${sort.length} Sorts`;
    } else {
      return "";
    }
  }, [sort]);

  // UI
  // const popoverBgColor = useColorModeValue("white", "gray.800");

  if (!isArrayWithAtLeastOneElement(fields)) {
    return null;
  }

  return (
    <>
      <SimpleDropdownMenu
        trigger={
          <Box width={"100%"} onClick={handleSelectOpen}>
            <Input
              variant="primary"
              style={{ borderRightWidth: 0 }}
              value={inputValue}
            />
          </Box>
        }
      >
        <Box style={{ minWidth: "500px" }} p="2">
          {sort && (
            <MultipleSortConditionForm
              simpleFields={fields}
              sortCondition={sort}
              onChangeSortCondition={(sort) => {
                onChange(sort ?? undefined);
              }}
            />
          )}
        </Box>
      </SimpleDropdownMenu>
    </>
  );
};
