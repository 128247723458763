import {
  DashboardNotebookCellObject,
  DashboardNotebookCellVariableRequestObject,
} from "@usemorph/morph-dashboard-types";
import { useMemo } from "react";
import { useFlipVariableValues } from "~/features/Flip/context/FlipVariablesContext";
import { useNotebookCells } from "../../context/NotebookCellContext";
import { getAncestorVariables } from "./getAncestorVariables";

export const useNotebookAncestorVariables = (
  targetCell: DashboardNotebookCellObject
) => {
  // この二つがpublishedの場合は動かない
  const allCells = useNotebookCells();
  const allVariables = useFlipVariableValues();

  const ancestorVariableValues: DashboardNotebookCellVariableRequestObject =
    useMemo(() => {
      const variables = getAncestorVariables(targetCell, allCells);

      return variables
        .map((variable) => {
          const variableValue = allVariables.find(
            (v) => v.name === variable.name
          );
          return variableValue;
        })
        .filter(
          (r) => r !== undefined
        ) as DashboardNotebookCellVariableRequestObject;
    }, [targetCell, allCells, allVariables]);

  return {
    ancestorVariableValues,
  };
};
