import { RecordFilterConditionUnit } from "@usemorph/morph-dashboard-types";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { FilterConditionUnitClientModel } from "./FilterConditionUnitClientModel";
import { EqualFilterOperationClientModel } from "./operations/EqualFilterOperationClientModel";
import { FilterOperationClientModelFactory } from "./operations/FilterOperationClientModelFactory";

export class FilterConditionUnitClientModelFactory {
  public static createInitialFilterConditionUnitClientModel(
    targetField: FieldClientModel
  ): FilterConditionUnitClientModel {
    // todo: targetFieldに応じて適切なoperationを返す
    return new FilterConditionUnitClientModel({
      targetField,
      operation: new EqualFilterOperationClientModel({ value: "" }),
    });
  }

  public static fromRecordConditionUnit(
    conditionUnit: RecordFilterConditionUnit,
    fields: FieldsClientModel
  ): FilterConditionUnitClientModel {
    const targetField = fields.allFields.find(
      (fieldInstance) => fieldInstance.name === conditionUnit.key
    );

    if (!targetField) {
      throw new Error("Failed to find target field");
    }

    return new FilterConditionUnitClientModel({
      targetField,
      operation: FilterOperationClientModelFactory.fromOperatorAndValue(
        conditionUnit.operator,
        conditionUnit.value
      ),
    });
  }
}
