import { SimpleField } from "@usemorph/morph-dashboard-types";
import {
  BsCalendar,
  BsCaretDown,
  BsCheckSquare,
  BsClock,
  BsEnvelope,
  BsFile,
  BsFileRichtext,
  BsImage,
  BsLink,
  BsPhone,
  BsSortNumericDown,
  BsType,
  BsPeople,
  BsTextLeft,
  BsListCheck,
  BsFiletypeHtml,
} from "react-icons/bs";
import { MdFunctions, MdDataArray, MdDataObject } from "react-icons/md";
import { match } from "ts-pattern";
import {
  MorphIconBigInteger,
  MorphIconDateTime,
  MorphIconFloat,
  MorphIconInteger,
} from "../MorphIcon";
import { IconCommonProps } from "../type";
import { getSizePx, getColor } from "./constants";

/**
 * ClientModel前の古い実装
 * ClientModelを使った実装はFieldIcon.tsx
 */

type FieldIconProps = {
  type: SimpleField["type"];
} & IconCommonProps;

const FieldIcon = (props: FieldIconProps) => {
  const { size, type } = props;
  const iconProps = { size: getSizePx(size), color: getColor("gray") };

  return match(type)
    .with("autoNumber", () => <BsSortNumericDown {...iconProps} />)
    .with("autoBigNumber", () => <BsSortNumericDown {...iconProps} />)
    .with("decimal", () => <MorphIconFloat {...iconProps} />)
    .with("number", () => <MorphIconInteger />)
    .with("bigNumber", () => <MorphIconBigInteger />)
    .with("shortText", () => <BsType {...iconProps} />)
    .with("longText", () => <BsTextLeft {...iconProps} />)

    .with("richText", () => <BsFileRichtext {...iconProps} />)
    .with("email", () => <BsEnvelope {...iconProps} />)
    .with("url", () => <BsLink {...iconProps} />)
    .with("phoneNumber", () => <BsPhone {...iconProps} />)
    .with("date", () => <BsCalendar {...iconProps} />)
    .with("datetime", () => <MorphIconDateTime {...iconProps} />)
    .with("createdAt", () => <BsClock {...iconProps} />)
    .with("lastEditedAt", () => <BsClock {...iconProps} />)
    .with("time", () => <BsClock {...iconProps} />)
    .with("boolean", () => <BsCheckSquare {...iconProps} />)
    .with("createdBy", () => <BsPeople {...iconProps} />)
    .with("lastEditedBy", () => <BsPeople {...iconProps} />)
    .with("singleSelect", () => <BsCaretDown {...iconProps} />)
    .with("multiSelect", () => <BsListCheck {...iconProps} />)
    .with("image", () => <BsImage {...iconProps} />)
    .with("attachment", () => <BsFile {...iconProps} />)
    .with("formula", () => <MdFunctions {...iconProps} />)
    .with("array", () => <MdDataArray {...iconProps} />)
    .with("json", () => <MdDataObject {...iconProps} />)
    .with("html", () => <BsFiletypeHtml {...iconProps} />)
    .with("reference", () => null)
    .with("multiReference", () => null)
    .with("embeddings", () => null)
    .with("python", () => null)
    .with("rowInfo", () => null)
    .exhaustive();
};

export { FieldIcon };
