import { useQueryClient } from "react-query";
import useApiRecord from "~/api/useApiRecord";
import { recordsKeys } from "../keys";

// types
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import { RecordValueType } from "@usemorph/morph-dashboard-types";

interface UseCreateRecordsParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
}

const useCreateRecordsMutation = ({
  databaseId,
  tableSlug,
  teamSlug,
}: UseCreateRecordsParams) => {
  const { _createRecord } = useApiRecord();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      valuesList,
    }: {
      valuesList: { key: string; value: RecordValueType }[][];
    }) => {
      return Promise.all(
        valuesList.map((values) =>
          _createRecord({
            teamSlug,
            databaseId,
            tableSlug,
            body: {
              values,
              fixedValue: [],
            },
          })
        )
      );
    },
    onSuccess: () => {
      const getRecordKey = recordsKeys.allGetRecord({
        teamSlug,
        databaseId,
        tableSlug,
      });
      const getRecordWithSqlKey = recordsKeys.allGetRecordWithSql({
        teamSlug,
        databaseId,
        tableSlug,
      });
      broadcast(getRecordKey);
      broadcast(getRecordWithSqlKey);

      return invalidateAll(client, getRecordKey, getRecordWithSqlKey);
    },
  };
};

export { useCreateRecordsMutation };
