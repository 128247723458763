import { IconButton } from "~/components_next/IconButton";
import {
  SimpleDropdownMenu,
  DropdownMenu,
} from "~/components_next/DropdownMenu";
import { BsPlusLg } from "react-icons/bs";
import { useMutation } from "react-query";
import { useCreateNotebookMutation } from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useNavigate } from "react-router-dom";
import { useErrorToast } from "~/components_next/Error";
import { getPath } from "~/routing";
import { LuUser, LuUsers } from "react-icons/lu";
import { Flex } from "~/components_next/Flex";

export const NotebookAddOption = () => {
  const databaseId = useDatabaseId();
  const teamSlug = useTeamSlug();
  const navigate = useNavigate();
  const { errorToast } = useErrorToast({});

  const { mutateAsync: createNotebook, isLoading: isCreatingNotebook } =
    useMutation({
      ...useCreateNotebookMutation({ teamSlug, databaseId }),
    });

  const handleCreateNotebookClicked = async ({
    isPrivate,
  }: {
    isPrivate: boolean;
  }) => {
    try {
      const notebook = await createNotebook({
        notebookName: "Untitled",
        description: null,
        isPrivate,
      });

      navigate(
        getPath("notebook", {
          teamSlug,
          databaseId,
          notebookId: notebook.notebookId,
        })
      );
    } catch (e: unknown) {
      errorToast(e);
    }
  };

  return (
    <>
      <SimpleDropdownMenu
        trigger={
          <IconButton
            icon={<BsPlusLg color="gray" />}
            tooltip="Create New Canvas"
            size="sm"
          />
        }
      >
        <DropdownMenu.Item
          onClick={() => handleCreateNotebookClicked({ isPrivate: false })}
        >
          <Flex align="center" gap="2">
            <LuUsers color="gray" />
          </Flex>
          Shared Canvas
        </DropdownMenu.Item>
        <DropdownMenu.Item
          onClick={() => handleCreateNotebookClicked({ isPrivate: true })}
        >
          <Flex align="center" gap="2">
            <LuUser color="gray" />
            Private Canvas
          </Flex>
        </DropdownMenu.Item>
      </SimpleDropdownMenu>
    </>
  );
};
