import { DashboardNotificationResponse, DashboardTeamUserObject } from "@usemorph/morph-dashboard-types";
import dayjs from "dayjs";

export class NotificationClientModel {
  constructor(private readonly data: DashboardNotificationResponse) { }

  /**
   * Getters
   */
  get category(): "csv" | "integration" | "normalization" {
    return this.data.category;
  }

  get createdAt(): string {
    return this.data.createdAt;
  }

  get createdBy(): DashboardTeamUserObject | null {
    return this.data.createdBy
  }

  get body(): Record<string, unknown> {
    return this.data.body;
  }

  get from(): "system" | "user" {
    return this.data.from;
  }

  get notificationId(): string {
    return this.data.notificationId;
  }

  get to(): "individual" | "all" {
    return this.data.to;
  }

  get readAt(): string | null {
    return this.data.readAt;
  }

  get subCategory(): "refreshTokenExpired" | "tokenRefreshFailed" | "tokenInvalid" | "unexpectedError" | "typeCastFailed" | "success" {
    return this.data.subCategory;
  }

  get formattedCreatedAt(): string | null {
    return dayjs(this.createdAt).format("YYYY-MM-DD HH:mm")
  }

  get isArchived(): boolean {
    return this.data.isArchived;
  }

  formatText(text: string) {
    return text.replace(/\\n/g, '\n');
  }
}
