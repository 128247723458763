import constate from "constate";
import { useCallback, useState } from "react";

export type SidebarType =
  | "createRecord"
  | "editRecord"
  | "bulkEditRecords"
  | "createField"
  | "editField"
  | "createView"
  | "createRecordWithFreeform"
  | "editSyncFieldSchedule"
  | "createViewWithSql";

const useSidebarContext = () => {
  const [openingSidebarType, setOpeningSidebarType] =
    useState<SidebarType | null>(null);

  const onClose = () => setOpeningSidebarType(null);

  return {
    openingSidebarType,
    setOpeningSidebarType,
    onClose,
  };
};

export const useSidebarDisclosure = () => {
  const openingSidebarType = useOpeningSidebarType();
  const setOpeningSidebarType = useSetOpeningSidebarType();

  const isOpen = useCallback(
    (sidebarType: SidebarType) => openingSidebarType === sidebarType,
    [openingSidebarType]
  );

  const onOpen = useCallback(
    (sidebarType: SidebarType) => setOpeningSidebarType(sidebarType),
    [setOpeningSidebarType]
  );

  const onClose = useCallback(
    (sidebarType: SidebarType) => setOpeningSidebarType(null),
    [setOpeningSidebarType]
  );

  return {
    isOpen,
    onOpen,
    onClose,
  };
};

export const [
  SidebarContextProvider,
  useOpeningSidebarType,
  useSetOpeningSidebarType,
  useSidebarOnClose,
] = constate(
  useSidebarContext,
  (value) => value.openingSidebarType,
  (value) => value.setOpeningSidebarType,
  (value) => value.onClose
);
