import {
  DashboardRegisterDataApiRequestBody,
} from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";
import { QueryDataApiPreview } from "./QueryDataApiPreview";

type DataApiPreviewProps = {
  body: DashboardRegisterDataApiRequestBody;
  tableSlug: string;
  apiKey: string;
};

const DataApiPreview = (props: DataApiPreviewProps) => {
  const { body, tableSlug, apiKey } = props;

  return match(body)
    .with({ apiType: "query", query: P.not(P.nullish) }, (_body) => {
      return (
        <QueryDataApiPreview
          body={_body.query}
          tableSlug={tableSlug}
          apiKey={apiKey}
        />
      );
    })
    .otherwise(() => <></>);
};

export { DataApiPreview };
