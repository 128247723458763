import { WidgetDataApi } from "~/features/WidgetDataApi/WidgetDataApi";

const WidgetDataApiPage = () => {
  return <WidgetDataApi />;
};

const WidgetDataApiPageWithKey = () => {
  return <WidgetDataApiPage />;
};

export { WidgetDataApiPageWithKey as WidgetDataApiPage };
