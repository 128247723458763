import { pageKeys } from "../keys";
import useApiPage from "~/api/useApiPage";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "~/serverStateStore/util/commonQueryOptions";

const useFindPublicPageDetailQuery = ({
  teamSlug,
  apiKey,
  pageId,
}: {
  teamSlug: string;
  apiKey: string;
  pageId: string;
}) => {
  const { _findPublishedPageDetail } = useApiPage();

  const client = useQueryClient();
  client.setQueryDefaults(
    pageKeys.findPublicPageDetail({
      teamSlug,
      apiKey,
      pageId,
    }),
    commonQueryOptions
  );

  return {
    queryKey: pageKeys.findPublicPageDetail({
      teamSlug,
      apiKey,
      pageId,
    }),
    queryFn: () => {
      return _findPublishedPageDetail({
        teamSlug,
        apiKey,
        pageId,
      });
    },
  };
};

export { useFindPublicPageDetailQuery };
