import { useState } from "react";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import { Box } from "~/components_next/Box";
import { Input } from "~/components_next/Input";
import { Text } from "~/components_next/Text";
import { usePlaygroundSetPseudoHoveredCells } from "../../providers/PlaygroundPseudoCellSelectionProvider";

type PlaygroundCellListItemProps = {
  cell: CanvasCellClientModelUnion;
  onUpdateLiveCell: (cell: CanvasCellClientModelUnion) => void;
};

const PlaygroundCellsListItem = (props: PlaygroundCellListItemProps) => {
  const { cell, onUpdateLiveCell } = props;

  const [focus, setFocus] = useState(false);
  const [mode, setMode] = useState<"display" | "edit">("display");

  const [cellName, setCellName] = useState(cell.cellNameForDisplay);

  /**
   * Events
   */
  const handleClick = () => {
    if (focus) {
      setMode("edit");
    } else {
      setFocus(true);
    }
  };

  const handleBlur = () => {
    if (mode === "edit") {
      onUpdateLiveCell(cell.updateCellName(cellName));
    }

    setFocus(false);
    setMode("display");
  };

  const setPseudoHoveredCells = usePlaygroundSetPseudoHoveredCells();

  const handleMouseEnter = () => {
    if (mode === "display") {
      setPseudoHoveredCells([cell.cellId]);
    }
  };

  const handleMouseLeave = () => {
    if (mode === "display") {
      setPseudoHoveredCells((prev) => prev.filter((c) => c !== cell.cellId));
    }
  };

  return (
    <>
      <Box
        key={cell.cellId}
        p="1"
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        css={{
          "&:hover": {
            backgroundColor: "$bg1",
          },
        }}
      >
        {mode === "display" ? (
          <Text>{cellName}</Text>
        ) : (
          <Input
            onBlur={handleBlur}
            variant="primary"
            size="xs"
            value={cellName}
            onChange={(e) => setCellName(e.target.value)}
            autoFocus
          />
        )}
      </Box>
    </>
  );
};

export { PlaygroundCellsListItem };
