import { useQuery } from "react-query";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { ToolbarWrapper } from "../components/atoms/ToolbarWrapper";
import { PresenceIcons } from "../../RealtimeCollaboration";
import { useViewId } from "~/utilHooks/useViewId";
import {
  useFindViewQuery,
  useGetViewFieldsQuery,
} from "~/serverStateStore/views";
import {
  ViewTypeBadge,
  getViewConditionType,
  ViewWithQueryConditionsForm,
  ViewWithQueryConditionsFormValueType,
  ViewWithSQLForm,
  ViewWithSQLFormValueType,
  parseViewSetting,
  ViewMetaFormState,
  ViewSettingsDropdown,
} from "../../View";
import { useCallback, useState } from "react";
import {
  useAllSimpleFields,
  useGetTableQuery,
  useListSimpleFieldsQuery,
} from "~/serverStateStore";
import { useErrorToast } from "~/components_next/Error";
import { SettingPopover } from "../components/atoms/SettingPopover";
import { BadgeAndTitle } from "../components/atoms/BadgeAndTitle";
import { useUpdateView } from "./ViewSetting/useUpdateView";
import { useDuplicateView } from "./ViewSetting/useDuplicateView";
import { Link, useNavigate } from "react-router-dom";
import { match } from "ts-pattern";
import { getTableSlugAndFieldNameFromSelectValue } from "~/features/View/utils/getTableSlugAndFieldNameFromSelectValue";
import { isFlatFilterCondition } from "~/features/Fields/FilterCondition/flatFilterConditionTypes";
import { getPath } from "~/routing";
import {
  DashboardViewUnionObject,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { ViewToolbarShare } from "./ViewToolbarShare";
import { BsDatabase } from "react-icons/bs";
import { CreateButton } from "./CreateButton";
import { useDisclosure } from "~/hooks/useDisclosure";
import { ViewToolbarPublish } from "./ViewToolbarPublish";
import { Button } from "~/components_next/Button";
import { SimpleTabs } from "~/components_next/Tabs";
import { Box, Flex } from "@radix-ui/themes";
import { Input } from "~/components_next/Input";
import { IconButton } from "~/components_next/IconButton";
import { Spacer } from "~/components_next/Spacer";
import { sortObjectUtils } from "~/utils/sortObjectUtils";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";

type FormValue =
  | {
      viewType: "sql";
      value: ViewWithSQLFormValueType;
    }
  | { viewType: "query"; value: ViewWithQueryConditionsFormValueType };

const ViewToolbar = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  /**
   * Form states
   */
  const [editViewFormValue, setEditViewFormValue] = useState<FormValue>();

  const [duplicateViewFormValue, setDuplicateViewFormValue] =
    useState<FormValue>();

  /**
   * Disclosure states
   */

  const settingDisclosure = useDisclosure();
  const duplicateDisclosure = useDisclosure();

  /**
   * Server states
   */
  const { data: viewData } = useQuery(
    useFindViewQuery({
      teamSlug,
      databaseId,
      viewId,
    })
  );

  const mainTableSlug = viewData?.tableSlug;

  const { data: mainTableSimpleFieldsData } = useQuery({
    ...useListSimpleFieldsQuery({
      teamSlug,
      databaseId,
      tableSlug: mainTableSlug as string,
    }),
    enabled: !!mainTableSlug,
  });

  const { data: mainTableData } = useQuery({
    ...useGetTableQuery({
      teamSlug,
      databaseId,
      tableSlug: mainTableSlug as string,
    }),
    enabled: !!mainTableSlug,
  });

  const { data: viewSimpleFieldsData } = useQuery({
    ...useGetViewFieldsQuery({
      teamSlug,
      databaseId,
      viewId,
    }),
  });

  const { data: allSimpleFieldsData } = useAllSimpleFields({
    teamSlug,
    databaseId,
  });

  /**
   * Utils for server states
   */

  const findSimpleFieldFromAllSimpleFields = useCallback(
    (tableSlug: string, fieldName: string): SimpleField => {
      const find = allSimpleFieldsData?.tables
        .find((table) => table.tableSlug === tableSlug)
        ?.fields.find((field) => field.name === fieldName);
      if (!find) {
        throw new Error("Field not found.(table slug:");
      }

      return find;
    },
    [allSimpleFieldsData]
  );

  /**
   * Handlers
   */

  // updateとduplicateで処理は共通なので、setterとdisclosureのみ変えている
  const handleOpenForm = (formType: "edit" | "duplicate") => {
    if (!viewData || !allSimpleFieldsData) return;
    const {
      name,
      type,
      condition,
      setting,
      tableSlug: mainTableSlug,
      isPrivate,
    } = viewData;
    const { select, join, filter, sort, from } = condition;

    const { setter, disclosure } = match(formType)
      .with("edit", () => ({
        setter: setEditViewFormValue,
        disclosure: settingDisclosure,
      }))
      .with("duplicate", () => ({
        setter: setDuplicateViewFormValue,
        disclosure: duplicateDisclosure,
      }))
      .exhaustive();

    // metaデータの作成
    const meta: ViewMetaFormState = match(type)
      .with("table", (type): ViewMetaFormState => ({ type, name, isPrivate }))
      .with("kanban", (type): ViewMetaFormState => {
        const { tableSlug, originalFieldName } =
          getTableSlugAndFieldNameFromSelectValue({
            selectValue: condition.groupKey ?? "",
            mainTableSlug: viewData.tableSlug,
          });
        const groupedByField = findSimpleFieldFromAllSimpleFields(
          tableSlug,
          originalFieldName
        );
        return {
          type,
          name,
          groupedByField,
          isPrivate,
          reorderable: viewData.condition.reorderable ?? false,
        };
      })
      .exhaustive();

    if (getViewConditionType(viewData) === "query") {
      // 一時的にembeddingsを無視
      const normalSorts = sortObjectUtils.getRecordSortConditionUnits(sort);
      setter({
        viewType: "query",
        value: {
          meta,
          selectedFields: select.flatMap((selectValue) => {
            const { tableSlug, originalFieldName } =
              getTableSlugAndFieldNameFromSelectValue({
                selectValue,
                mainTableSlug,
              });
            return findSimpleFieldFromAllSimpleFields(
              tableSlug,
              originalFieldName
            );
          }),
          join: join ?? [],
          filter: isFlatFilterCondition(filter) ? filter : null,
          sort: normalSorts && normalSorts.length > 0 ? normalSorts[0] : null,
        },
      });
    } else {
      setter({
        viewType: "sql",
        value: {
          meta,
          sql: from,
          setting: parseViewSetting(setting),
        },
      });
    }
    disclosure.onOpen();
  };

  const { errorToast } = useErrorToast({});

  const { updateView: saveView, isUpdating: isSaving } = useUpdateView({
    teamSlug,
    databaseId,
    viewId,
    view: viewData,
    viewFields: viewSimpleFieldsData?.fields,
    formValue: editViewFormValue,
  });

  const handleSave = async () => {
    try {
      await saveView();
      settingDisclosure.onClose();
    } catch (e) {
      errorToast(e);
    }
  };

  const { createView, isCreating } = useDuplicateView({
    teamSlug,
    databaseId,
    viewId,
    view: viewData,
    viewFields: viewSimpleFieldsData?.fields,
    formValue: duplicateViewFormValue,
  });

  const navigate = useNavigate();

  const handleDuplicate = async () => {
    try {
      const { viewId: newViewId } = await createView();
      duplicateDisclosure.onClose();
      navigate(
        getPath("view", {
          teamSlug,
          databaseId,
          viewId: newViewId,
        })
      );
    } catch (e) {
      errorToast(e);
    }
  };

  return (
    <ToolbarWrapper>
      {viewData &&
        mainTableSimpleFieldsData &&
        viewSimpleFieldsData &&
        allSimpleFieldsData &&
        mainTableSlug &&
        mainTableData && (
          <>
            {/* setting */}
            <SettingPopover
              title="View Settings"
              isOpen={settingDisclosure.isOpen}
              onClose={settingDisclosure.onClose}
              trigger={
                <BadgeAndTitle
                  badge={<ViewTypeBadge type={viewData.type} />}
                  title={viewData.name}
                />
              }
              footer={
                <Button
                  variant="primary"
                  onClick={handleSave}
                  isLoading={isSaving}
                  size="sm"
                  style={{ width: "100%" }}
                >
                  Save
                </Button>
              }
            >
              <Box style={{ overflowY: "auto", height: "calc(100vh - 200px)" }}>
                <Flex mb="4" align="end" gap="2">
                  <Input
                    variant="primary"
                    label="Source"
                    value={mainTableData?.displayName ?? ""}
                    readOnly
                  />
                  <Link
                    to={getPath("source", {
                      teamSlug,
                      databaseId,
                      tableSlug: viewData.tableSlug,
                    })}
                  >
                    <IconButton
                      icon={<BsDatabase />}
                      tooltip="Go to source"
                      size="sm"
                    />
                  </Link>
                </Flex>
                {editViewFormValue?.viewType === "query" && (
                  <ViewWithQueryConditionsForm
                    allFields={allSimpleFieldsData.tables}
                    mainTableSlug={mainTableSlug}
                    value={editViewFormValue.value}
                    onChange={({ formValue }) =>
                      setEditViewFormValue({
                        viewType: "query",
                        value: formValue,
                      })
                    }
                  />
                )}
                {editViewFormValue?.viewType === "sql" && (
                  <ViewWithSQLForm
                    value={editViewFormValue.value}
                    onChange={(value) =>
                      setEditViewFormValue({ viewType: "sql", value })
                    }
                    viewFields={viewSimpleFieldsData.fields}
                    tables={[mainTableData]}
                  />
                )}
              </Box>
            </SettingPopover>

            {/* duplicate */}
            <SettingPopover
              title="Duplicate View"
              isOpen={duplicateDisclosure.isOpen}
              onClose={duplicateDisclosure.onClose}
              footer={
                <Button
                  variant="primary"
                  onClick={handleDuplicate}
                  isLoading={isCreating}
                >
                  Duplicate
                </Button>
              }
            >
              <>
                {duplicateViewFormValue?.viewType === "query" && (
                  <ViewWithQueryConditionsForm
                    allFields={allSimpleFieldsData.tables}
                    mainTableSlug={mainTableSlug}
                    value={duplicateViewFormValue.value}
                    onChange={({ formValue }) =>
                      setDuplicateViewFormValue({
                        viewType: "query",
                        value: formValue,
                      })
                    }
                  />
                )}
                {duplicateViewFormValue?.viewType === "sql" && (
                  <ViewWithSQLForm
                    value={duplicateViewFormValue.value}
                    onChange={(value) =>
                      setDuplicateViewFormValue({ viewType: "sql", value })
                    }
                    viewFields={viewSimpleFieldsData.fields}
                    tables={[mainTableData]}
                  />
                )}
              </>
            </SettingPopover>
            {/* <Floater
              target={
                <BadgeAndTitle
                  badge={<ViewTypeBadge type={viewData.type} />}
                  title={viewData.name}
                />
              }
              placement="bottom-start"
              isOpen={true}
            >
            </Floater> */}
            <Flex>
              <ViewSettingsDropdown
                view={viewData as DashboardViewUnionObject}
                displayDelete
                displayDuplicate
                displaySetting
                onClickDuplicate={() => handleOpenForm("duplicate")}
                onClickSetting={() => handleOpenForm("edit")}
              />
            </Flex>
            <Spacer />
            <PresenceIcons maxAvatars={3} />
            <CreateButton />
            <SimpleDropdownMenu
              trigger={
                <Button variant="primary" size="sm">
                  Share
                </Button>
              }
            >
              <SimpleTabs tabLabels={["Share", "Publish"]}>
                <Box p="2">
                  <ViewToolbarShare />
                </Box>
                <Box p="2">
                  <ViewToolbarPublish />
                </Box>
              </SimpleTabs>
            </SimpleDropdownMenu>
          </>
        )}
    </ToolbarWrapper>
  );
};

const ViewToolbarWithKey = () => {
  const viewId = useViewId();
  return <ViewToolbar key={viewId} />;
};

export { ViewToolbarWithKey as ViewToolbar, type FormValue };
