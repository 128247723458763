type PaymentDependencies = { teamSlug: string };

type FindPaymentDependencies = {
  limit?: number;
  skip?: number;
} & PaymentDependencies;

const paymentKeys = {
  all: ({ teamSlug }: PaymentDependencies) => [teamSlug, "payment"] as const,

  /**
   * get payment
   */
  allFindPayment: ({ teamSlug }: PaymentDependencies) =>
    [...paymentKeys.all({ teamSlug }), "findPayment"] as const,
  findPayment: ({ teamSlug }: FindPaymentDependencies) =>
    [...paymentKeys.allFindPayment({ teamSlug })] as const,
};

export { paymentKeys };
