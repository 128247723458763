import { SimpleField } from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";
import { useListSimpleFieldsQuery } from "../queries/useListSimpleFieldsQuery";

type PickDataTypeFromQueryFn<QueryFn extends (...params: any[]) => any> =
  ReturnType<QueryFn> extends Promise<infer R> ? R : ReturnType<QueryFn>;

type UseListClientSimpleFieldsQueryParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

type DataType = PickDataTypeFromQueryFn<
  ReturnType<typeof useListSimpleFieldsQuery>["queryFn"]
>;

type ClientSimpleFieldType = Exclude<SimpleField["type"], "embeddings">;

type ClientSimpleField = Omit<SimpleField, "embeddings"> & {
  type: ClientSimpleFieldType;
  embeddingsField: SimpleField | null;
};

type ListClientSimpleFieldData = {
  fields: ClientSimpleField[];
};

const useListClientSimpleFieldsQuery = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseListClientSimpleFieldsQueryParams) => {
  return {
    ...useListSimpleFieldsQuery({ teamSlug, databaseId, tableSlug }),
    select: (data: DataType): ListClientSimpleFieldData => {
      const fields: ClientSimpleField[] = data.fields.flatMap((field) => {
        const embeddingsFields = data.fields.filter(
          ({ type }) => type === "embeddings"
        );
        const getEmbeddingsField = (field: SimpleField) => {
          const found = embeddingsFields.find(
            ({ embeddings }) => embeddings === field.name
          );
          return found || null;
        };
        return match(field)
          .with({ type: "embeddings" }, () => [])
          .with({ type: P.not("embeddings") }, (field) => {
            const { embeddings, ...rest } = field;
            return {
              ...rest,
              embeddingsField: getEmbeddingsField(field),
            };
          })
          .exhaustive();
      });
      return {
        fields,
      };
    },
  };
};

const clientSimpleFieldToSimpleField = (
  field: ClientSimpleField
): SimpleField => {
  const { embeddingsField, ...rest } = field;
  return {
    ...rest,
  };
};

export { useListClientSimpleFieldsQuery, clientSimpleFieldToSimpleField };
export type {
  ClientSimpleField,
  ClientSimpleFieldType,
  ListClientSimpleFieldData,
  UseListClientSimpleFieldsQueryParams,
};
