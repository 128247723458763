import { CsvDownloadFieldsClientModel } from "~/clientModel/csvDownload/csvDownloadFields";
import { CsvDownloadFieldClientModel } from "~/clientModel/csvDownload/csvDownloadFields/csvDownloadField";
import { Flex } from "~/components_next/Flex";
import { Tag } from "~/components_next/Tag";
import { Text } from "~/components_next/Text";

type MaskedFieldsProps = {
  csvDownloadFields: CsvDownloadFieldsClientModel;
  onCsvDownloadFieldsChange: (
    csvDownloadFields: CsvDownloadFieldsClientModel
  ) => void;
};

const MaskedFields = (props: MaskedFieldsProps) => {
  const { csvDownloadFields, onCsvDownloadFieldsChange } = props;

  const handleUnmaskField = (csvDownloadField: CsvDownloadFieldClientModel) => {
    onCsvDownloadFieldsChange(
      csvDownloadFields.updateCsvDownloadField(csvDownloadField.unmaskField())
    );
  };

  return (
    <Flex direction="row">
      <Text variant="subheading" style={{ width: "120px", flex: "none" }}>
        Masked Fields
      </Text>
      <Flex direction="row" gap="2" wrap="wrap">
        {csvDownloadFields.maskedCsvDownloadFields.allCsvDownloadFields.map(
          (csvDownloadField) => (
            <Tag
              variant="tertiary"
              key={csvDownloadField.baseField.name}
              size="sm"
              tooltip="Unmask"
              onClear={() => handleUnmaskField(csvDownloadField)}
            >
              {csvDownloadField.baseField.label}
            </Tag>
          )
        )}
      </Flex>
    </Flex>
  );
};

export { MaskedFields };
