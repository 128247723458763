import { useMutation } from "react-query";
import useApiSQL from "~/api/useApiSQL";
import { Executable, useComposeExecutable } from "~/clientModel/executable";

type useGenerateSqlForComputedFieldFromPromptExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useGenerateSqlForComputedFieldFromPromptExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: useGenerateSqlForComputedFieldFromPromptExecutableParams): Executable<
  {
    prompt: string;
    creatingFieldName: string;
    margeFieldName: string;
    mergeTargetTableSlug: string;
  },
  {
    sql: string;
  }
> => {
  const { _generateSQL } = useApiSQL();

  return useComposeExecutable(
    useMutation({
      mutationFn: async ({
        prompt,
        creatingFieldName,
        margeFieldName,
        mergeTargetTableSlug,
      }: {
        prompt: string;
        creatingFieldName: string;
        margeFieldName: string;
        mergeTargetTableSlug: string;
      }) => {
        const {
          result: { sql },
        } = await _generateSQL({
          teamSlug,
          databaseId,
          body: {
            message: prompt,
            tableSlug: mergeTargetTableSlug,
            type: "normalizedSource",
            normalizedSource: {
              fieldName: creatingFieldName,
              mergeKeyTableSlug: tableSlug,
              mergeKey: margeFieldName,
            },
          },
        });

        if (!sql) {
          throw new Error("Failed to generate SQL. Please try again.");
        }

        return { sql };
      },
    })
  );
};

export const createUseGenerateSqlForComputedFieldFromPromptExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: useGenerateSqlForComputedFieldFromPromptExecutableParams) => {
  return () =>
    useGenerateSqlForComputedFieldFromPromptExecutable({
      teamSlug,
      databaseId,
      tableSlug,
    });
};
