import { Box, Flex } from "@radix-ui/themes";
import { DashboardNotebookDetailObject } from "@usemorph/morph-dashboard-types";

import { styled } from "~/stitches";
import { FlipVariablesProvider } from "../Flip/context/FlipVariablesContext";
import { CanvasRoot } from "./components/CanvasRoot";
import { NotebookCellsProvider } from "./context/NotebookCellContext";
import { NotebookCreateCellProvider } from "./context/NotebookCreateCellContext";
import { NotebookDeleteCellProvider } from "./context/NotebookDeleteCellContext";
import { NotebookPseudoCellSelectionProvider } from "./context/NotebookPsuedoCellSelectionContext";
import { NotebookCellObjectWithMeta } from "./types/NotebookCellObjectWithMeta.type";

const NotebookWrapper = styled(Box, {
  height: "calc(100vh - 40px)",
  flex: 1,
});

const FlipCanvas = ({
  notebook,
}: {
  notebook: DashboardNotebookDetailObject;
}) => {
  return (
    <>
      <NotebookCellsProvider
        cells={notebook.cells as NotebookCellObjectWithMeta[]}
      >
        <NotebookCreateCellProvider>
          <NotebookDeleteCellProvider>
            <FlipVariablesProvider key={notebook.notebookId}>
              <NotebookPseudoCellSelectionProvider key={notebook.notebookId}>
                <Flex>
                  <NotebookWrapper>
                    <CanvasRoot />
                  </NotebookWrapper>
                </Flex>
              </NotebookPseudoCellSelectionProvider>
            </FlipVariablesProvider>
          </NotebookDeleteCellProvider>
        </NotebookCreateCellProvider>
      </NotebookCellsProvider>
    </>
  );
};

export { FlipCanvas };
