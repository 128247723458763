import { ScrollArea } from "@radix-ui/themes";
import { useState } from "react";
import { BsCaretDownFill } from "react-icons/bs";
import { useMutation } from "react-query";
import { Box } from "~/components_next/Box";
import { SimpleCollapsible } from "~/components_next/Collapsible";
import { useErrorToast } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { Input } from "~/components_next/Input";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";
import { useNotebookLiveObject } from "~/features/RealtimeCollaboration/Notebook/useNotebookLiveObject";
import { useUpdateNotebookCellMetaMutaiton } from "~/serverStateStore";
import { styled } from "~/stitches";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useNotebookId } from "~/utilHooks/useNotebookId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useNotebookCells } from "../../context/NotebookCellContext";
import { useNotebookSetPseudoHoveredCells } from "../../context/NotebookPsuedoCellSelectionContext";
import { NotebookCellObjectWithMeta } from "../../types/NotebookCellObjectWithMeta.type";
import { getDefaultCellName } from "../../util/getDefaultCellName";

const NotebookCellListItem = (props: { cell: NotebookCellObjectWithMeta }) => {
  const { cell } = props;

  const [focus, setFocus] = useState(false);
  const [mode, setMode] = useState<"display" | "edit">("display");

  const [cellName, setCellName] = useState(getDefaultCellName(cell.cellName));

  /**
   * Update Cell
   */
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const notebookId = useNotebookId();

  const { cells: liveCells = [], updateNotebookCells: updateLiveCell } =
    useNotebookLiveObject();

  const {
    mutateAsync: onUpdateNotebookCellSettings,
    isLoading: isUpdatingCellsSettings,
  } = useMutation(
    useUpdateNotebookCellMetaMutaiton({
      teamSlug,
      databaseId,
      notebookId,
    })
  );

  const { errorToast } = useErrorToast({});

  /**
   * Events
   */
  const handleClick = () => {
    if (focus) {
      setMode("edit");
    } else {
      setFocus(true);
    }
  };

  const handleBlur = () => {
    if (mode === "edit") {
      const cellIndex = liveCells?.findIndex(
        (_cell) => _cell.cellId === cell.cellId
      );

      if (cellIndex === undefined || cellIndex < 0) {
        errorToast(new Error("Cell not found"));
        return;
      }

      updateLiveCell({
        cell: {
          ...cell,
          cellName,
        },
        index: cellIndex,
      });

      // MARK: LIVEBLOCKS
      // onUpdateNotebookCellSettings({
      //   cellId: cell.cellId,
      //   hidePrompt: false,
      //   cellName,
      // });
    }

    setFocus(false);
    setMode("display");
  };

  const setPseudoHoveredCells = useNotebookSetPseudoHoveredCells();

  const handleMouseEnter = () => {
    if (mode === "display") {
      setPseudoHoveredCells([cell.cellId]);
    }
  };

  const handleMouseLeave = () => {
    if (mode === "display") {
      setPseudoHoveredCells((prev) => prev.filter((c) => c !== cell.cellId));
    }
  };

  return (
    <>
      <Box
        key={cell.cellId}
        p="1"
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        css={{
          "&:hover": {
            backgroundColor: "$bg1",
          },
        }}
      >
        {mode === "display" ? (
          <Text>{cellName}</Text>
        ) : (
          <Input
            onBlur={handleBlur}
            variant="primary"
            size="xs"
            value={cellName}
            onChange={(e) => setCellName(e.target.value)}
            autoFocus
          />
        )}
      </Box>
    </>
  );
};

const CollapseIcon = styled(BsCaretDownFill, {
  fontSize: "var(--font-size-1)",
  color: "$slate8",
});

const NotebookCellsList = () => {
  const cells = useNotebookCells();

  return (
    <>
      <Box
        css={{
          backgroundColor: "$whiteA10",
          borderRadius: "var(--radius-2)",
          boxShadow: "var(--shadow-4)",
        }}
        py="1"
      >
        <SimpleCollapsible
          variant="skelton"
          size="xs"
          trigger={
            <Flex
              css={{ minWidth: "160px", width: "100%" }}
              px="1"
              gap="1"
              align="center"
            >
              <Text variant="tinyDescription">Cells</Text>
              <Spacer />
              <CollapseIcon />
            </Flex>
          }
        >
          <ScrollArea style={{ maxHeight: "50vh" }}>
            {cells.map((cell) => {
              return <NotebookCellListItem cell={cell} key={cell.cellId} />;
            })}
          </ScrollArea>
        </SimpleCollapsible>
      </Box>
    </>
  );
};

export { NotebookCellsList };
