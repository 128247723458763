import { Flex } from "@radix-ui/themes";
import { DashboardGenerateSqlHistoryObject } from "@usemorph/morph-dashboard-types";
import { Fragment } from "react";
import { useQuery } from "react-query";
import { Drawer } from "~/components_next/Drawer";
import { ErrorFallback } from "~/components_next/Error";
import { Divider } from "~/components_next/Divider";
import { Spinner } from "~/components_next/Spinner";
import { Text } from "~/components_next/Text";
import { useListGenerateSqlHistoriesQuery } from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { PromptHistoryCell } from "./components/PromptHistoryCell";

type PromptHistoryDrawerProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onHistoryClick: (history: DashboardGenerateSqlHistoryObject) => void;
} & (
  | {
      type: "source";
      tableSlug: string;
    }
  | {
      type: "view";
      viewId: string;
    }
);

const PromptHistoryDrawer = (props: PromptHistoryDrawerProps) => {
  const { isOpen, onOpenChange, onHistoryClick } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const {
    data: sqlHistoryData,
    status: sqlHistoryStatus,
    error: sqlHistoryError,
  } = useQuery({
    ...useListGenerateSqlHistoriesQuery({
      teamSlug,
      databaseId,
      tableSlug: props.type === "source" ? props.tableSlug : undefined,
      viewId: props.type === "view" ? props.viewId : undefined,
    }),
  });

  return (
    <Drawer.Root size="xl" open={isOpen} onOpenChange={onOpenChange}>
      <Drawer.Content>
        <Drawer.Title>Prompt History</Drawer.Title>
        <Drawer.Body>
          {/* loading */}
          {sqlHistoryStatus === "loading" || sqlHistoryStatus === "idle" ? (
            <Flex style={{ height: "100%" }} align="center" justify="center">
              <Spinner />
            </Flex>
          ) : null}

          {/* error */}
          {sqlHistoryStatus === "error" && (
            <ErrorFallback error={sqlHistoryError} />
          )}

          {/* success */}
          {sqlHistoryStatus === "success" && (
            <>
              {sqlHistoryData.histories.length > 0 ? (
                <Flex align="stretch" direction="column">
                  {sqlHistoryData.histories.map((history, i) => {
                    return (
                      <Fragment key={i}>
                        <PromptHistoryCell
                          history={history}
                          onHistoryClick={() => onHistoryClick(history)}
                        />
                        {i !== sqlHistoryData.histories.length - 1 && (
                          <Divider />
                        )}
                      </Fragment>
                    );
                  })}
                </Flex>
              ) : (
                <Flex height="100%" align="center" justify="center">
                  <Text variant="description">No histories</Text>
                </Flex>
              )}
            </>
          )}
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};

export { PromptHistoryDrawer };
