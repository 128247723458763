import { SimpleFieldCategoryType } from "@usemorph/morph-dashboard-types";
import { useKey } from "react-use";
import { match, P } from "ts-pattern";
import {
  useRecordsTableBaseCellSelection,
  useRecordsTableBaseFields,
  useRecordsTableBaseOnCellSelectionChange,
  useRecordsTableBaseRecords,
  useRecordsTableBaseRecordSelection,
  useRecordsTableBaseEndCellEditing,
  useRecordsTableBaseHandleCellEvent,
  useRecordsTableBaseEditingCell,
} from "../context/ReactTableBaseContext";
import {
  getDownerRecord,
  getUpperRecord,
  getLeftField,
  getRightField,
  getFieldsRange,
} from "../util/cellSelectionUtil";
import {
  getClipboardValueFromCellSelection,
  getClipboardValueFromRecordsSelection,
} from "../util/clipboardUtil";
import {
  copyKeyPred,
  arrowDownEventPred,
  arrowLeftEventPred,
  arrowRightEventPred,
  arrowUpEventPred,
  shiftArrowDownEventPred,
  shiftArrowLeftEventPred,
  shiftArrowRightEventPred,
  shiftArrowUpEventPred,
  enterEventNotComposingPred,
  escapeEventPred,
} from "../util/keyboardCommandsUtil";

const useRecordsTableBaseCommands = () => {
  // data
  const records = useRecordsTableBaseRecords();
  const fields = useRecordsTableBaseFields();
  // state
  const cellSelections = useRecordsTableBaseCellSelection();
  const onCellSelectionsChange = useRecordsTableBaseOnCellSelectionChange();
  const recordsSelection = useRecordsTableBaseRecordSelection();
  const editingCell = useRecordsTableBaseEditingCell();
  const endCellEditing = useRecordsTableBaseEndCellEditing();
  const handleCellEvent = useRecordsTableBaseHandleCellEvent();

  // Copy event
  useKey(
    copyKeyPred,
    () => {
      if (cellSelections.length > 0) {
        const clipboardValue =
          getClipboardValueFromCellSelection(cellSelections);
        if (!navigator.clipboard) {
          // fallback
          return;
        }
        navigator.clipboard.writeText(clipboardValue);
        return;
      }

      if (recordsSelection && recordsSelection.length > 0) {
        const clipboardValue =
          getClipboardValueFromRecordsSelection(recordsSelection);
        if (!navigator.clipboard) {
          // fallback
          return;
        }
        navigator.clipboard.writeText(clipboardValue);
        return;
      }
    },
    {},
    [editingCell, cellSelections, records, fields]
  );

  // Arrow Events
  // Up
  useKey(
    arrowUpEventPred,
    () => {
      if (editingCell) {
        return;
      }
      return match(cellSelections.length)
        .with(1, () => {
          const selectingRecord = cellSelections[0].record;
          const upperRecord = getUpperRecord(records, selectingRecord);
          if (!upperRecord) {
            return;
          }
          onCellSelectionsChange?.([
            {
              record: upperRecord,
              fieldSlugs: cellSelections[0].fieldSlugs,
            },
          ]);
        })
        .otherwise(() => {
          return;
        });
    },
    {},
    [editingCell, cellSelections, records, fields]
  );
  // Down
  useKey(
    arrowDownEventPred,
    () => {
      if (editingCell) {
        return;
      }
      return match(cellSelections.length)
        .with(1, () => {
          const selectingRecord = cellSelections[0].record;
          const downerRecord = getDownerRecord(records, selectingRecord);
          if (!downerRecord) {
            return;
          }
          onCellSelectionsChange?.([
            {
              record: downerRecord,
              fieldSlugs: cellSelections[0].fieldSlugs,
            },
          ]);
        })
        .otherwise(() => {
          return;
        });
    },
    {},
    [editingCell, cellSelections, records, fields]
  );
  // Left
  useKey(
    arrowLeftEventPred,
    () => {
      return match([cellSelections.length, editingCell])
        .with([1, P.nullish], () => {
          const selectingFieldSlug = cellSelections[0].fieldSlugs[0];
          const leftField = getLeftField(fields, selectingFieldSlug);
          if (!leftField) {
            return;
          }
          onCellSelectionsChange?.([
            {
              record: cellSelections[0].record,
              fieldSlugs: [leftField.name],
            },
          ]);
        })
        .otherwise(() => {
          return;
        });
    },
    {},
    [editingCell, cellSelections, records, fields]
  );
  // Right
  useKey(
    arrowRightEventPred,
    () => {
      return match([cellSelections.length, editingCell])
        .with([1, P.nullish], () => {
          const selectingFieldSlug = cellSelections[0].fieldSlugs[0];
          const rightField = getRightField(fields, selectingFieldSlug);
          if (!rightField) {
            return;
          }
          onCellSelectionsChange?.([
            {
              record: cellSelections[0].record,
              fieldSlugs: [rightField.name],
            },
          ]);
        })
        .otherwise(() => {
          return;
        });
    },
    {},
    [editingCell, cellSelections, records, fields]
  );

  // Shift + Arrow Event
  // Up
  useKey(
    shiftArrowUpEventPred,
    () => {
      return match(cellSelections.length)
        .with(0, () => {
          return;
        })
        .otherwise(() => {
          const selectingRecord = cellSelections.sort(
            (s1, s2) =>
              s1.record._reservedRecordIndex - s2.record._reservedRecordIndex
          )[0].record;
          const upperRecord = getUpperRecord(records, selectingRecord);
          if (!upperRecord) {
            return;
          }

          onCellSelectionsChange?.([
            ...cellSelections,
            {
              record: upperRecord,
              fieldSlugs: cellSelections[0].fieldSlugs,
            },
          ]);
        });
    },
    {},
    [editingCell, cellSelections, records, fields]
  );

  useKey(
    shiftArrowDownEventPred,
    () => {
      return match(cellSelections.length)
        .with(0, () => {
          return;
        })
        .otherwise(() => {
          const selectingRecord = cellSelections.sort(
            (s1, s2) =>
              s2.record._reservedRecordIndex - s1.record._reservedRecordIndex
          )[0].record;
          const downerRecord = getDownerRecord(records, selectingRecord);
          if (!downerRecord) {
            return;
          }

          onCellSelectionsChange?.([
            ...cellSelections,
            {
              record: downerRecord,
              fieldSlugs: cellSelections[0].fieldSlugs,
            },
          ]);
        });
    },
    {},
    [editingCell, cellSelections, records, fields]
  );

  useKey(
    shiftArrowLeftEventPred,
    () => {
      return match(cellSelections.length)
        .with(0, () => {
          return;
        })
        .otherwise(() => {
          const firstCellSelection = cellSelections[0];
          const firstField = getFieldsRange(
            firstCellSelection.fieldSlugs,
            fields
          )[0];
          const leftField = getLeftField(fields, firstField.name);
          if (!leftField) {
            return;
          }

          const newCellSelections = cellSelections.map((selection) => {
            return {
              record: selection.record,
              fieldSlugs: [leftField.name, ...selection.fieldSlugs],
            };
          });

          onCellSelectionsChange?.(newCellSelections);
        });
    },
    {},
    [editingCell, cellSelections, records, fields]
  );

  useKey(
    shiftArrowRightEventPred,
    () => {
      return match(cellSelections.length)
        .with(0, () => {
          return;
        })
        .otherwise(() => {
          const firstCellSelection = cellSelections[0];
          const lastField = getFieldsRange(
            firstCellSelection.fieldSlugs,
            fields
          ).slice(-1)[0];
          const rightField = getRightField(fields, lastField.name);
          if (!rightField) {
            return;
          }

          const newCellSelections = cellSelections.map((selection) => {
            return {
              record: selection.record,
              fieldSlugs: [...selection.fieldSlugs, rightField.name],
            };
          });

          onCellSelectionsChange?.(newCellSelections);
        });
    },
    {},
    [editingCell, cellSelections, records, fields]
  );

  useKey(
    enterEventNotComposingPred,
    () => {
      match([editingCell, cellSelections.length])
        .with([P.not(P.nullish), P._], () => {
          // 編集中にEnterが押されたら、編集を終了する

          // ただし、array, json, htmlの場合は終了しない
          const targetField = fields.find(
            ({ name }) => name === editingCell?.fieldSlug
          );
          const ignoreFieldTypes: SimpleFieldCategoryType[] = [
            "array",
            "json",
            "html",
          ];
          if (targetField && ignoreFieldTypes.includes(targetField.type)) {
            return;
          }

          endCellEditing();
        })
        .with([P.nullish, 1], () => {
          if (cellSelections[0].fieldSlugs.length === 1) {
            handleCellEvent(
              "goEdit",
              cellSelections[0].record,
              cellSelections[0].fieldSlugs[0]
            );
          }
        })
        // .with([P.nullish, 0], () => {
        //   if (records.length > 0) {
        //     onCellSelectionsChange?.([
        //       {
        //         record: records[0],
        //         fieldSlugs: [fields[0].name],
        //       },
        //     ]);
        //   }
        // })
        .otherwise(() => {
          return;
        });
    },
    {},
    [editingCell, cellSelections, records, fields]
  );

  useKey(
    escapeEventPred,
    () => {
      match([editingCell, cellSelections.length])
        .with([P.not(P.nullish), P._], () => {
          endCellEditing();
        })
        .with([P.nullish, P.not(0)], () => {
          onCellSelectionsChange?.([]);
        })
        .otherwise(() => {
          return;
        });
    },
    {},
    [editingCell, cellSelections, records, fields]
  );

  return {};
};

export { useRecordsTableBaseCommands };
