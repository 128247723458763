import { CodePreview } from "../components/CodePreview";
import { RecordValueInputCommonProps } from "../../../type";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { useDisclosure } from "~/hooks/useDisclosure";
import { ArrayEditDialog } from "./ArrayEditDialog";

export const ArrayInput = (props: RecordValueInputCommonProps<"array">) => {
  const { value, onChange, errorMessages, isReadOnly, size, isNullable } =
    props;

  const { isOpen, onOpen, setIsOpen } = useDisclosure();

  const stringifiedValue = value ? JSON.stringify(value) : null;

  const handleChange = (stringifiedValue: string | null) => {
    if (stringifiedValue) {
      try {
        const parsed = JSON.parse(stringifiedValue);
        if (parsed instanceof Array) {
          onChange?.(parsed);
        }
      } catch (error) {
        return;
      }
    } else {
      onChange?.(null);
    }
  };

  return (
    <>
      <InputStack errorMessage={errorMessages}>
        <CodePreview
          value={stringifiedValue}
          isEditDisabled={isReadOnly}
          onEditClick={onOpen}
          size={size}
        />
      </InputStack>

      {/* modal */}
      <ArrayEditDialog
        value={stringifiedValue}
        onChange={handleChange}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        isReadonly={isReadOnly}
      />
    </>
  );
};
