import { Button } from "~/components_next/Button";
import { Input } from "~/components_next/Input";
import { useState, ChangeEvent } from "react";
import { Box, Flex } from "@radix-ui/themes";
import { Tag } from "~/components_next/Tag";

interface MembersInputProps {
  members: string[];
  setMembers: (members: string[]) => void;
  isReadOnly: boolean;
}

const MembersInput = ({
  members,
  setMembers,
  isReadOnly,
}: MembersInputProps) => {
  const [memberInput, setMemberInput] = useState<string>("");

  // handlers
  const onAddMember = () => {
    setMembers?.([...members, memberInput]);
    return setMemberInput("");
  };

  const onDeleteMember = (deletedMember: string) => {
    setMembers?.(members.filter((m) => m !== deletedMember));
  };

  // memberが重複しているか入力がないときボタンが無効
  const isDisabled = members.includes(memberInput) || !memberInput;

  return (
    <Box>
      <Flex mb="2" gap="2" style={{ flexWrap: "wrap" }}>
        {members.map((member) => {
          return (
            <Tag
              variant="secondary"
              key={member}
              onClear={isReadOnly ? undefined : () => onDeleteMember(member)}
            >
              {member}
            </Tag>
          );
        })}
      </Flex>
      {!isReadOnly && (
        <Flex gap="2" align="center">
          <Input
            variant="supplementary"
            size="sm"
            value={memberInput}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setMemberInput(e.target.value);
            }}
            placeholder="add option"
          />
          <Button
            variant="tertiary"
            size="sm"
            isDisabled={isDisabled}
            onClick={onAddMember}
          >
            Add
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export { MembersInput };
