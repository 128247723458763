import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModel } from "..";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";

export class DecimalFieldTypeClientModel extends FieldTypeClientModelBase {
  public get type(): "decimal" {
    return "decimal";
  }

  public get fieldTypeLabel(): "Decimal" {
    return "Decimal";
  }

  public get isAutoGenerated(): false {
    return false;
  }

  public get isEmbeddable(): false {
    return false;
  }

  public get canBeTargetOfSyncingByComputedField(): true {
    return true;
  }

  public get _updatableFieldTypes(): FieldTypeClientModel["type"][] {
    return [
      "number",
      "bigNumber",
      "shortText",
      "longText",
      "singleSelect",
      "multiSelect",
    ];
  }

  public validate(): ValidationResult {
    return { isValid: true };
  }
}
