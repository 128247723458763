import { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { FindCanvasClientModel } from "~/clientModel/canvas";
import { DashboardCellsClientModel } from "~/clientModel/canvas/DashboardCellClientModel";
import { FindDashboardPageClientModelFactory } from "~/clientModel/canvas/FindDashboardPageClientModel/FindDashboardPageClientModelFactory";
import {
  ListCanvasPageClientModel,
  ListCanvasPageClientModelFactory,
} from "~/clientModel/canvas/ListCanvasPageClientModel";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import { useComposeLoadable } from "~/clientModel/loadable";
import { CanvasDashboardEmptyPresenter } from "~/presenters/canvas/dashboard/CanvasDashboardEmptyPresenter";
import { CanvasDashboardPresenter } from "~/presenters/canvas/dashboard/CanvasDashboardPresenter";
import {
  useCreateNotebookPageMutaiton,
  useDeleteNotebookPageMutaiton,
  useFindPageDetailQuery,
  useRemovePageCellMutaiton,
  useUpdateNotebookPageMutaiton,
  useUpdatePageCellsMutaiton,
} from "~/serverStateStore/notebookPage";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useNotebookId } from "~/utilHooks/useNotebookId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useTableViewProps } from "./tableViewProps/useTableViewProps";
import { createUseVariableOptionsLoadable } from "../playground/createUseVariableOptionsLoadable";

const CanvasDashboardContent = (props: {
  pages: ListCanvasPageClientModel[];
  createPageExecutable: Executable<
    {
      pageName: string;
      description: string;
      isPrivate: boolean;
    },
    ListCanvasPageClientModel
  >;
  updatePageExecutable: Executable<
    {
      pageId: string;
      pageName: string;
      description: string;
    },
    ListCanvasPageClientModel
  >;
  deletePageExecutable: Executable<
    {
      pageId: string;
    },
    unknown
  >;
}) => {
  const { pages, createPageExecutable } = props;

  /**
   * Path parameters
   */
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const notebookId = useNotebookId();

  /**
   * Active page
   */
  const [activePageId, setActivePageId] = useState<string | null>(
    pages[0]?.pageId || null
  );

  /**
   * Server state
   */
  /**
   * Find page detail query
   * using activePageId
   */
  const findPageQueryOptions = useFindPageDetailQuery({
    teamSlug,
    databaseId,
    notebookId,
    pageId: activePageId || "UNSET",
  });
  const findPageQueryResult = useQuery({
    ...findPageQueryOptions,
    select: (response) => {
      return FindDashboardPageClientModelFactory.fromDashboardDetailResponse(
        response
      );
    },
    enabled: !!activePageId,
  });
  const findPageQueryLoadable = useComposeLoadable(findPageQueryResult);
  /**
   * Update layout
   */
  const updateLayoutMutationOptions = useUpdatePageCellsMutaiton({
    teamSlug,
    databaseId,
    notebookId,
    pageId: activePageId || "UNSET",
  });
  const updateLayoutMutationResult = useMutation({
    ...updateLayoutMutationOptions,
    mutationFn: async (cellsClientModel: DashboardCellsClientModel) => {
      if (!activePageId) {
        throw new Error("activePageId is not set");
      }
      const params = cellsClientModel.cells.map((cell) => ({
        cellId: cell.cellId,
        notebookCellId: cell.cell.cellId,
        x: cell.x,
        y: cell.y,
        w: cell.w,
        h: cell.h,
      }));
      return updateLayoutMutationOptions.mutationFn(params);
    },
  });
  const updateLayoutExecutable = useComposeExecutable(
    updateLayoutMutationResult
  );

  /**
   * Remove cell
   */
  const removeCellMutationOptions = useRemovePageCellMutaiton({
    teamSlug,
    databaseId,
    notebookId,
    pageId: activePageId || "UNSET",
  });
  const removeCellMutationResult = useMutation({
    ...removeCellMutationOptions,
    mutationFn: async (cellId: string) => {
      if (!activePageId) {
        throw new Error("activePageId is not set");
      }
      return removeCellMutationOptions.mutationFn({
        cellId,
      });
    },
  });
  const removeCellExecutable = useComposeExecutable(removeCellMutationResult);

  // variable用
  const useGetVariableOptionsLoadable = useMemo(() => {
    return createUseVariableOptionsLoadable({
      teamSlug,
      notebookId,
    });
  }, [teamSlug, notebookId]);

  // table view用
  const tableViewProps = useTableViewProps({
    teamSlug,
    databaseId,
  });

  return (
    <CanvasDashboardPresenter
      pages={pages}
      createPageExecutable={createPageExecutable}
      updatePageExecutable={props.updatePageExecutable}
      deletePageExecutable={props.deletePageExecutable}
      activePageLoadable={findPageQueryLoadable}
      updateLayoutExecutable={updateLayoutExecutable}
      setActivePageId={setActivePageId}
      tableViewProps={tableViewProps}
      useVariableOptionsLoadable={useGetVariableOptionsLoadable}
      removeCellExecutable={removeCellExecutable}
    />
  );
};

const CanvasDashboardContainer = (props: {
  findCanvasClientModel: FindCanvasClientModel;
}) => {
  const { findCanvasClientModel } = props;
  const pagesClientModel =
    ListCanvasPageClientModelFactory.fromNotebookResponseList(
      findCanvasClientModel.pages
    );

  /**
   * Path parameters
   */
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const notebookId = useNotebookId();

  /**
   * Server state
   */
  const createPageMutationOptions = useCreateNotebookPageMutaiton({
    teamSlug,
    databaseId,
    notebookId,
  });
  const createPageMutationResult = useMutation({
    ...createPageMutationOptions,
    mutationFn: async (params: {
      pageName: string;
      description: string;
      isPrivate: boolean;
    }) => {
      const rawResponse = await createPageMutationOptions.mutationFn(params);
      return ListCanvasPageClientModelFactory.fromNotebookResponse(rawResponse);
    },
  });
  const createPageExecutable = useComposeExecutable(createPageMutationResult);

  // update
  const updatePageMutationOptions = useUpdateNotebookPageMutaiton({
    teamSlug,
    databaseId,
    notebookId,
  });
  const updatePageMutationResult = useMutation({
    ...updatePageMutationOptions,
    mutationFn: async (params: {
      pageId: string;
      pageName: string;
      description: string;
    }) => {
      const rawResponse = await updatePageMutationOptions.mutationFn(params);
      return ListCanvasPageClientModelFactory.fromNotebookResponse(rawResponse);
    },
  });
  const updatePageExecutable = useComposeExecutable(updatePageMutationResult);

  // delete
  const deletePageMutationOptions = useDeleteNotebookPageMutaiton({
    teamSlug,
    databaseId,
    notebookId,
  });
  const deletePageMutationResult = useMutation({
    ...deletePageMutationOptions,
    mutationFn: async (params: { pageId: string }) => {
      const rawResponse = await deletePageMutationOptions.mutationFn(params);
      return rawResponse;
    },
  });
  const deletePageExecutable = useComposeExecutable(deletePageMutationResult);

  if (findCanvasClientModel.pages.length === 0) {
    return (
      <CanvasDashboardEmptyPresenter
        createPageExecutable={createPageExecutable}
      />
    );
  }

  return (
    <CanvasDashboardContent
      pages={pagesClientModel}
      createPageExecutable={createPageExecutable}
      updatePageExecutable={updatePageExecutable}
      deletePageExecutable={deletePageExecutable}
    />
  );
};

export { CanvasDashboardContainer };
