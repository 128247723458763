import { ReactNode } from "react";
import { BsX } from "react-icons/bs";
import { Flex } from "~/components_next/Flex";
import { IconButton } from "~/components_next/IconButton";
import { Spacer } from "~/components_next/Spacer";
import { styled } from "~/stitches";

type VariableOptionElementProps = {
  children: ReactNode;
  onRemove?: () => void;
};

const Wrapper = styled(Flex, {
  gap: "$2",
  backgroundColor: "$bg1",
});

const VariableOptionElement = (props: VariableOptionElementProps) => {
  const { children, onRemove } = props;

  return (
    <Wrapper align="center" py="1" px="2">
      {children}
      <Spacer />
      <IconButton
        size="xs"
        icon={<BsX />}
        tooltip="Delete"
        onClick={onRemove}
      />
    </Wrapper>
  );
};

export { VariableOptionElement };
