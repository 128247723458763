import { Box } from "~/components_next/Box";
import { styled } from "~/stitches";

const PlaygroundControlPanelBox = styled(Box, {
  backgroundColor: "$whiteA10",
  borderRadius: "var(--radius-2)",
  boxShadow: "var(--shadow-4)",
});

export { PlaygroundControlPanelBox };
