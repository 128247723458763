import { useState } from "react";
import { FallbackProps } from "react-error-boundary";
import { useLocation } from "react-router-dom";
import { extractErrorDetails } from "~/components_next/Error";
import { Button } from "~/components_next/Button";
import { Flex } from "~/components_next/Flex";
import { Text } from "~/components_next/Text";

const TableErrorFallback = ({ resetErrorBoundary, error }: FallbackProps) => {
  const { description, title } = extractErrorDetails(error);

  const location = useLocation();
  const pathname = location.pathname;

  const [prevPathname, setPrevPathname] = useState<string>();

  if (pathname !== prevPathname) {
    if (prevPathname) {
      setPrevPathname(pathname);
      resetErrorBoundary();
    } else {
      setPrevPathname(pathname);
    }
  }

  return (
    <Flex
      align="center"
      justify="center"
      direction="column"
      gap="8"
      height="100%"
    >
      <Flex direction="column" align="center" gap="2">
        <Text variant="heading">{title}</Text>
        <Text>{description}</Text>
      </Flex>
      <Button variant="secondary" onClick={resetErrorBoundary}>
        Reload
      </Button>
    </Flex>
  );
};

export { TableErrorFallback };
