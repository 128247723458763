import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { match } from "ts-pattern";
import { Box } from "~/components_next/Box";
import { routes } from "~/routing";
import { styled } from "~/stitches";
import { useGlobalNavigationUIState } from "../GlobalNavigation/states/globalNavigationUIState";
import { DataApiToolbar } from "./DataApiToolbar/DataApiToolbar";
import { NotebookToolbar } from "./NotebookToolbar/NotebookToolbar";
import { SourceToolbar } from "./SourceToolbar/SourceToolbar";
import { ViewToolbar } from "./ViewToolbar/ViewToolbar";
import { WidgetDataApiToolbar } from "./WidgetDataApiToolbar/WidgetDataApiToolbar";
import { DataCollectionToolbarContainer } from "~/containers/toolbars/DataCollectionToolbarContainer";

const ToolbarWrapper = styled(Box, {
  borderBottom: "1px solid $border",
  height: "40px",
});

const Toolbar = () => {
  const { pathname } = useLocation();
  const globalNavigationUIState = useGlobalNavigationUIState();

  const routeType:
    | "notebook"
    | "canvas_v1_1"
    | "dataApi"
    | "dataCollection"
    | "source"
    | "view"
    | "widgetDataApi"
    | "other" = useMemo(() => {
      const match = (
        [
          "notebook",
          "canvas_v1_1",
          "dataApi",
          "dataCollection",
          "source",
          "view",
          "widgetDataApi",
        ] as const
      ).find((type) => matchPath(routes[type], pathname));

      return match ?? "other";
    }, [pathname]);

  return (
    <ToolbarWrapper
      css={{
        paddingLeft: globalNavigationUIState.isLockSidebar ? "" : "38px",
      }}
    >
      {match(routeType)
        .with("notebook", () => <NotebookToolbar />)
        .with("canvas_v1_1", () => <NotebookToolbar />)
        .with("dataApi", () => <DataApiToolbar />)
        .with("dataCollection", () => <DataCollectionToolbarContainer />)
        .with("source", () => <SourceToolbar />)
        .with("view", () => <ViewToolbar />)
        .with("widgetDataApi", () => <WidgetDataApiToolbar />)
        .with("other", () => null)
        .exhaustive()}
    </ToolbarWrapper>
  );
};

export { Toolbar };
