import { FieldClientModel } from "~/clientModel/fields/field";
import {
  FilterOperationClientModelBase,
  FilterOperationWithValueInterface,
} from "./FilterOperationClientModelBase";
import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { StringValidator } from "./CommonValueValidator";

type LessThanFilterOperationClientModelData = {
  value: string;
};

export class LessThanFilterOperationClientModel
  extends FilterOperationClientModelBase
  implements
    FilterOperationWithValueInterface<
      string,
      LessThanFilterOperationClientModel
    >
{
  readonly #data: LessThanFilterOperationClientModelData;

  public constructor(data: LessThanFilterOperationClientModelData) {
    super();
    this.#data = data;
  }

  public get data() {
    return this.#data;
  }

  public get type(): "lessThan" {
    return "lessThan";
  }

  public get operatorValue(): "lessThan" {
    return "lessThan";
  }

  public get operatorLabel(): "Less than" {
    return "Less than";
  }

  public get operandValue(): string {
    return this.#data.value;
  }

  public updateValue(value: string): LessThanFilterOperationClientModel {
    return new LessThanFilterOperationClientModel({
      ...this.#data,
      value,
    });
  }

  public validate(targetField: FieldClientModel): ValidationResult {
    return new StringValidator(this.#data.value)
      .shouldNotBeEmpty()
      .shouldBeIntStringIfNumberType(targetField)
      .end();
  }
}
