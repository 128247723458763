import { useQuery } from "react-query";
import { Loadable, useComposeLoadable } from "~/clientModel/loadable";
import {
  PromptHistoriesClientModel,
  PromptHistoriesClientModelFactory,
} from "~/clientModel/promptHistories";

import { useListGenerateSqlHistoriesQuery } from "~/serverStateStore";

type usePromptHistoriesLoadableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

export const usePromptHistoriesLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: usePromptHistoriesLoadableParams): Loadable<
  PromptHistoriesClientModel,
  unknown
> => {
  return useComposeLoadable(
    useQuery({
      ...useListGenerateSqlHistoriesQuery({
        teamSlug,
        databaseId,
        tableSlug,
      }),
      select: (data) => {
        return PromptHistoriesClientModelFactory.createFromDashboardGenerateSqlHistoryListResponse(
          data
        );
      },
    })
  );
};
