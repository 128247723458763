import { DashboardDatabaseListResponse } from "@usemorph/morph-dashboard-types";
import { ListDatabaseClientModel } from ".";

export const ListDatabaseClientModelFactory = {
  // factory methods
  fromlistDatabaseResponse: (
    data: DashboardDatabaseListResponse
  ): ListDatabaseClientModel => {
    return new ListDatabaseClientModel(data);
  },
};
