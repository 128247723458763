import { TableClientModel } from "~/clientModel/tables/table";
import {
  CanvasCellClientModelUnion,
  CanvasCellSourceClientModel,
  CanvasCellViewClientModel,
} from "../../CanvasCellClientModel";
import { CanvasCellPosition } from "../../CanvasCellClientModel/CanvasCellClientModelBase";
import { CanvasCreateCellClientModel } from "../CanvasCreateCellClientModel";

const camelCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export type CanvasCreateJoinTableViewCellDataType = {
  parentCells: CanvasCellClientModelUnion[];
  cellName: string;
  settings?: CanvasCellPosition; // 外部から決めてもいいし、なければparentCellsから決める
  targetSource: TableClientModel;
};

export class CanvasCreateJoinTableViewCellClientModel extends CanvasCreateCellClientModel {
  readonly #data: CanvasCreateJoinTableViewCellDataType;
  constructor(data: CanvasCreateJoinTableViewCellDataType) {
    super({
      parentCells: data.parentCells,
      cellName: data.cellName,
      cellType: "view",
      source: {},
    });
    this.#data = data;
  }

  get cellName() {
    return this.#data.cellName;
  }

  get parentCells() {
    return this.#data.parentCells;
  }

  get parentSourceCells() {
    return this.#data.parentCells.filter(
      (cell): cell is CanvasCellSourceClientModel => cell.cellType === "source"
    );
  }

  get parentSourceCell() {
    if (this.parentSourceCells.length === 0) {
      return undefined;
    }
    return this.parentSourceCells[0];
  }

  get parentViewCells() {
    return this.#data.parentCells.filter(
      (cell): cell is CanvasCellViewClientModel => cell.cellType === "view"
    );
  }

  get parentViewCell() {
    if (this.parentViewCells.length === 0) {
      return undefined;
    }
    return this.parentViewCells[0];
  }

  get parentCell() {
    return this.parentSourceCell ?? this.parentViewCell;
  }

  get targetSource() {
    return this.#data.targetSource;
  }
}
