import { SmartFieldInputSetting } from "@usemorph/morph-dashboard-types";
import { SmartFunctionInputFormClientModel } from "./SmartFunctionInputFormClientModel";
import { SmartFunctionInputClientModelFactory } from "./smartFuntionInput";

export class SmartFunctionInputFormClientModelFactory {
  public static createFromSmartFieldInputSetting(
    smartFieldInputSetting: SmartFieldInputSetting
  ): SmartFunctionInputFormClientModel {
    return new SmartFunctionInputFormClientModel({
      inputs: smartFieldInputSetting.inputs.map((input) =>
        SmartFunctionInputClientModelFactory.createFromSmartFieldInputSettingItem(
          input
        )
      ),
    });
  }
}
