import { useMemo } from "react";
import { useRouteGuard } from "~/App";
import { usePreferenceModal } from "~/features/GlobalNavigation";
import { BillingPresenter } from "~/presenters/billing/BillingPresenter";
import { useFindPaymentObjectLoadable } from "../plan/useFindPaymentObjectLoadable";
import { useCreateCustomerPortalSession } from "./useCreateCustomerPortalSession";
import { useSelectedMeTeam } from "~/serverStateStore/me";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

export const BillingContainer = () => {
  useRouteGuard();

  const teamSlug = useTeamSlug();

  /**
   * Loadable
   */
  const paymentLoadable = useFindPaymentObjectLoadable({ teamSlug })
  const { createCustomerPortalSessionLoadable } = useCreateCustomerPortalSession()
  const { data: selectedTeam } = useSelectedMeTeam({ teamSlug });

  const paymentType = useMemo(() => {
    return paymentLoadable.data?.paymentType
  }, [paymentLoadable])

  const plan = useMemo(() => {
    return paymentLoadable.data?.plan
  }, [paymentLoadable])

  const isAdmin = useMemo(() => {
    return selectedTeam?.isAdmin ?? false
  }, [selectedTeam])

  /**
   * Handler
   */
  const { onChangeTab } = usePreferenceModal();

  const handleClickCustomerDetail = async () => {
    const response = await createCustomerPortalSessionLoadable.execute();
    window.open(response.url);
  };

  return (
    <>
      <BillingPresenter
        paymentType={paymentType}
        plan={plan}
        isAdmin={isAdmin}
        onChangeTab={onChangeTab}
        onClickCustomerDetail={handleClickCustomerDetail}
        createCustomerPortalSessionLoadable={
          createCustomerPortalSessionLoadable
        }
      />
    </>
  );
};
