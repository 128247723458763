import {
  DashboardViewConditionObject,
  ViewType,
} from "@usemorph/morph-dashboard-types";
import { viewKeys } from "../keys";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import useApiView from "~/api/useApiView";
import { ViewSetting } from "~/features/View";

type UseCreateViewMutationParams = {
  teamSlug: string;
  databaseId: string;
};

const useCreateViewMutation = ({
  teamSlug,
  databaseId,
}: UseCreateViewMutationParams) => {
  const { _createView } = useApiView();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      name,
      type,
      condition,
      setting,
      afterViewId,
      isPrivate,
    }: {
      name: string;
      type: ViewType;
      condition: DashboardViewConditionObject;
      setting: ViewSetting;
      afterViewId?: string;
      isPrivate: boolean;
    }) => {
      return _createView({
        teamSlug,
        body: {
          name,
          type,
          condition,
          setting,
          afterViewId,
          databaseId,
          isPrivate,
        },
      });
    },
    onSuccess: () => {
      const keys = viewKeys.all({ databaseId, teamSlug });
      broadcast(keys);
      return invalidateAll(client, keys);
    },
  };
};

export { useCreateViewMutation };
