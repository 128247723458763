import { SmartFieldNumberDisplaySetting } from "@usemorph/morph-dashboard-types";
import { NumberDisplaySettingClientModel } from "./NumberDisplaySettingClientModel";

export class NumberDisplaySettingClientModelDefactory {
  public static toSmartFieldNumberDisplaySetting(
    smartFieldNumberDisplaySetting: NumberDisplaySettingClientModel
  ): SmartFieldNumberDisplaySetting {
    return {
      precision: smartFieldNumberDisplaySetting.data.precision,
      useGroupSeparator: smartFieldNumberDisplaySetting.data.useGroupSeparator,
      units: smartFieldNumberDisplaySetting.data.units,
      displayUnitsAfterNumber:
        smartFieldNumberDisplaySetting.data.displayUnitsAfterNumber,
    };
  }

  public static createEmpty(): NumberDisplaySettingClientModel {
    return new NumberDisplaySettingClientModel({
      precision: 3,
      useGroupSeparator: false,
      units: "",
      displayUnitsAfterNumber: true,
    });
  }
}
