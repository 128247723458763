import { useCallback, useState } from "react";
import { BsMagic, BsPlus } from "react-icons/bs";
import { UseExecutable } from "~/clientModel/executable";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { Loadable, useLoadableState } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { CreatingRecordClientModel } from "~/clientModel/records/creatingRecord";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { Flex } from "~/components_next/Flex";
import { SimpleTabs } from "~/components_next/Tabs";
import { Text } from "~/components_next/Text";
import { CreateRecordForm } from "~/presenters/records";
import { FooterButtonBase } from "../../common/FooterButtonBase";
import { RightSidebarBase } from "../../common/RightSidebarBase";
import { PromptInput } from "./PromptInput";

type CreateRecordWithFreeformSidebarProps = {
  fieldsLoadable: Loadable<FieldsClientModel>;
  smartFunctionsLoadable: Loadable<SmartFunctionsClientModel>;
  useGenerateRecordsWithPromptExecutable: UseExecutable<
    void,
    {
      prompt: string;
      fields: FieldsClientModel;
      smartFunctions: SmartFunctionsClientModel;
    },
    { creatingRecordArray: CreatingRecordClientModel[] }
  >;
  useCreateRecordsExecutable: UseExecutable<
    void,
    {
      creatingRecordArray: CreatingRecordClientModel[];
      fields: FieldsClientModel;
    }
  >;
  useUploadFileExecutable: UseExecutable<
    void,
    { file: File },
    { url: string; data: string }
  >;
};

export const CreateRecordWithFreeformSidebar = (
  props: CreateRecordWithFreeformSidebarProps
) => {
  const {
    fieldsLoadable,
    smartFunctionsLoadable,
    useGenerateRecordsWithPromptExecutable,
    useCreateRecordsExecutable,
    useUploadFileExecutable,
  } = props;

  const [prompt, setPrompt] = useState("");

  const [
    generatedRecordArrayLoadable,
    setGeneratedRecordArray,
    resetGeneratedRecordArray,
  ] = useLoadableState<CreatingRecordClientModel[]>();

  const generateRecordWithFreeformExecutable =
    useGenerateRecordsWithPromptExecutable();

  const createRecordExecutable = useCreateRecordsExecutable();

  const handleGenerateRecordWithPrompt = useCallback(
    async ({
      prompt,
      fields,
      smartFunctions,
    }: {
      prompt: string;
      fields: FieldsClientModel;
      smartFunctions: SmartFunctionsClientModel;
    }) => {
      resetGeneratedRecordArray();
      const { creatingRecordArray } =
        await generateRecordWithFreeformExecutable.execute({
          prompt,
          fields,
          smartFunctions,
        });
      setGeneratedRecordArray(creatingRecordArray);
    },
    [
      generateRecordWithFreeformExecutable,
      setGeneratedRecordArray,
      resetGeneratedRecordArray,
    ]
  );

  const handleCreateRecords = useCallback(
    async (
      recordArray: CreatingRecordClientModel[],
      fields: FieldsClientModel
    ) => {
      await createRecordExecutable.execute({
        creatingRecordArray: recordArray.map((record) => record),
        fields,
      });
      resetGeneratedRecordArray();
      setPrompt("");
    },
    [createRecordExecutable, resetGeneratedRecordArray]
  );

  return (
    <RightSidebarBase
      sidebarType="createRecordWithFreeform"
      title="Create Record (Freeform)"
      footer={
        <WithFallback
          loadables={[fieldsLoadable, generatedRecordArrayLoadable] as const}
        >
          {([fields, recordArray]) => (
            <FooterButtonBase
              onClick={() => handleCreateRecords(recordArray, fields)}
              rightIcon={<BsPlus />}
              isLoading={createRecordExecutable.isExecuting}
              isDisabled={
                !recordArray.every((record) =>
                  record.isCreatingRecordValid(fields)
                )
              }
            >
              Create Records
            </FooterButtonBase>
          )}
        </WithFallback>
      }
    >
      <Flex direction="column" gap="8">
        <WithFallback
          loadables={[fieldsLoadable, smartFunctionsLoadable] as const}
        >
          {([fields, smartFunctions]) => (
            <Flex direction="column" align="stretch" gap="4">
              <Text variant="description">
                Enter text in natural language below. The content will be
                automatically converted to a format suitable for this table.
              </Text>

              <PromptInput
                prompt={prompt}
                onPromptChange={setPrompt}
                fieldsForTypeahead={fields}
              />
              <Button
                variant="secondary"
                onClick={() =>
                  handleGenerateRecordWithPrompt({
                    prompt,
                    fields,
                    smartFunctions,
                  })
                }
                isDisabled={!prompt}
                isLoading={generateRecordWithFreeformExecutable.isExecuting}
                size="sm"
              >
                <BsMagic />
                Detect
              </Button>
            </Flex>
          )}
        </WithFallback>
        <WithFallback
          loadables={[fieldsLoadable, generatedRecordArrayLoadable] as const}
        >
          {([fields, records]) => (
            <SimpleTabs tabLabels={records.map((_, i) => `Record ${i + 1}`)}>
              {records.map((creatingRecord, recordIndex) => {
                return (
                  <Box py="3" key={recordIndex}>
                    <CreateRecordForm
                      fields={fields}
                      creatingRecord={creatingRecord}
                      onCreatingRecordChange={(creatingRecord) => {
                        setGeneratedRecordArray(
                          records.map((r, i) =>
                            i === recordIndex ? creatingRecord : r
                          )
                        );
                      }}
                      useUploadFileExecutable={useUploadFileExecutable}
                    />
                  </Box>
                );
              })}
            </SimpleTabs>
          )}
        </WithFallback>
      </Flex>
    </RightSidebarBase>
  );
};
