import { ForwardedRef, forwardRef } from "react";
import { AggregateValueRecordEntryClientModel } from "~/clientModel/records/record/recordEntry";
import { Input } from "~/components_next/Input";
import { RecordValueInputCommonProps } from "../../type";

const AggregateValueReadonlyInput = forwardRef(
  function AggregateValueReadonlyInput(
    props: RecordValueInputCommonProps<AggregateValueRecordEntryClientModel>,
    ref: ForwardedRef<HTMLInputElement>
  ) {
    const { editingRecordEntry, size, field } = props;

    const validatedValue = editingRecordEntry.getValidatedValue(field);

    const value = validatedValue.isValid ? validatedValue.value : "";

    return (
      <Input
        size={size}
        variant="primary"
        value={String(value) ?? ""}
        readOnly={true}
        ref={ref}
      />
    );
  }
);

export { AggregateValueReadonlyInput };
