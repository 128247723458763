import { useEffect } from "react";
import { CanvasCellVariablesClientModel } from "~/clientModel/canvas/CanvasCellClientModel";
import { CanvasVariableClientModel } from "~/clientModel/canvas/CanvasVariableClientModel";
import { Text } from "~/components_next/Text";
import { useDebounceState } from "~/hooks/useDebounceState";
import { useReactiveState } from "~/hooks/useReactiveState";
import { CanvasVariableInput } from "~/presenters/canvas/cellContent/variable/CanvasVariableInput";
import {
  CanvasVariablesValue,
  useSetCanvasVariablesValues,
} from "~/presenters/canvas/common/CanvasVariablesProvider";
import { UseVariableOptionsViewResultLoadable } from "../../common/CanvasVariableOptionsLoadableProvider";

/**
 * Variable Cellの一行分のコンポーネント
 * * 必ずCanvasCellVariableProviderの子要素になるはず
 */

type CanvasCellVariableRowProps = {
  variable: CanvasVariableClientModel;
  variableCellClientModel: CanvasCellVariablesClientModel;
  useVariableOptionsLoadable: UseVariableOptionsViewResultLoadable;
};

const CanvasCellVariableRow = (props: CanvasCellVariableRowProps) => {
  const { variable, variableCellClientModel, useVariableOptionsLoadable } =
    props;
  const { cellId } = variableCellClientModel;

  const [variableValue, setVariableValue] = useReactiveState<unknown>(
    variable.defaultValue || null
  );
  // Varialbe名の入力、defaultValueの入力があるので、debounceする
  const debouncedValue = useDebounceState(variableValue, 500);

  // Provider以下に共有
  const setNotebookVariableValue = useSetCanvasVariablesValues();

  useEffect(() => {
    setNotebookVariableValue((prev) => {
      /**
       * すでにある場合は上書き、ない場合は追加
       */
      let found = false;
      const newVariableValues: CanvasVariablesValue[] = prev.map(
        (prevVariableValue) => {
          if (prevVariableValue.name === variable.name) {
            found = true;
            return {
              name: variable.name,
              cellId,
              value: debouncedValue,
            };
          }
          return prevVariableValue;
        }
      );

      if (!found) {
        newVariableValues.push({
          name: variable.name,
          value: debouncedValue,
          cellId,
        });
      }

      return newVariableValues;
    });
  }, [debouncedValue, setNotebookVariableValue, variable.name, cellId]);

  return (
    <>
      <Text>{variable.name}</Text>
      <CanvasVariableInput
        variable={variable.data}
        onChange={setVariableValue}
        value={variableValue}
        useVariableOptionsLoadable={useVariableOptionsLoadable}
        variableCellClientModel={variableCellClientModel}
        size="xs"
      />
    </>
  );
};

export { CanvasCellVariableRow };
