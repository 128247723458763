import constate from "constate";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";

export type UsePlaygroundUpdateLiveCellContextProps = {
  onUpdateLiveCell: (cell: CanvasCellClientModelUnion) => void;
};

const usePlaygroundUpdateLiveCellContext = (
  props: UsePlaygroundUpdateLiveCellContextProps
) => {
  const { onUpdateLiveCell } = props;

  return {
    onUpdateLiveCell,
  };
};

export const [PlaygroundUpdateLiveCellProvider, usePlaygroundOnUpdateLiveCell] =
  constate(
    usePlaygroundUpdateLiveCellContext,
    (value) => value.onUpdateLiveCell
  );
