import { useEditorOrThrow } from "../utils";

const useBulletList = () => {
  const editor = useEditorOrThrow();

  const handleToggle = () => {
    editor.chain().focus().toggleBulletList().run();
  };

  const isActive = editor.isActive("bulletList");

  const isDisabled = !editor.can().chain().focus().toggleBulletList().run();

  return {
    handleToggle,
    isActive,
    isDisabled,
  };
};

export { useBulletList };
