import { useMutation } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import {
  FieldClientModel,
  FieldClientModelDefactory,
} from "~/clientModel/fields/field";

import { useErrorToast } from "~/components_next/Error";
import { useDeleteSimpleFieldMutation } from "~/serverStateStore";

type UseDeleteFieldExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useDeleteFieldExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseDeleteFieldExecutableParams): Executable<
  { field: FieldClientModel },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  const deleteFieldOptions = useDeleteSimpleFieldMutation({
    teamSlug,
    databaseId,
    tableSlug,
  });

  return useComposeExecutable(
    useMutation({
      ...deleteFieldOptions,
      mutationFn: ({ field }: { field: FieldClientModel }) => {
        return deleteFieldOptions.mutationFn({
          fieldName: FieldClientModelDefactory.toFieldName(field),
        });
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseDeleteFieldExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseDeleteFieldExecutableParams) => {
  return () => useDeleteFieldExecutable({ teamSlug, databaseId, tableSlug });
};
