import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil";
import { urlSyncEffect } from "recoil-sync";
import { custom, literal, union } from "@recoiljs/refine";
import {
  RecordSortConditionUnit,
  zRecordSortConditionUnit,
} from "@usemorph/morph-dashboard-types";
import { zodGuard } from "~/utils/zodGuard";
import { createKanbanViewUrlSyncStoreEffectOptions } from "./KanbanViewUrlSyncStore/createKanbanViewUrlSyncStoreEffectOptions";

const isSortConditionUnit = (
  value: unknown
): value is RecordSortConditionUnit => {
  return zodGuard(value, zRecordSortConditionUnit);
};

const checker = union(
  literal(null),
  custom(
    (value) => (isSortConditionUnit(value) ? value : null),
    "value is not a valid value of FlatFilterCondition"
  )
);

const querySortConditionFamily = atomFamily<
  RecordSortConditionUnit | null,
  { viewId: string }
>({
  key: "kanbanViewSortCondition",
  default: null,
  effects: [
    urlSyncEffect(
      createKanbanViewUrlSyncStoreEffectOptions({
        checker,
        key: "kanbanViewSortCondition",
        defaultValue: null,
      })
    ),
  ],
});

const useSortCondition = (viewId: string) => {
  return useRecoilValue(querySortConditionFamily({ viewId }));
};

const useSetSortCondition = (viewId: string) => {
  return useSetRecoilState(querySortConditionFamily({ viewId }));
};

export { useSortCondition, useSetSortCondition };
