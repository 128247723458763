import {
  // core colors
  slate,
  slateDark,
  slateA,
  slateDarkA,
  bronze,
  bronzeDark,
  // semantic colors
  red,
  redDark,
  // collaborator colors
  teal,
  tealDark,
  crimson,
  crimsonDark,
  purple,
  purpleDark,
  blue,
  blueDark,
  green,
  greenDark,
  amber,
  amberDark,
  // utility colors
  whiteA,
  blackA,
} from "@radix-ui/colors";
import { createStitches } from "@stitches/react";

const {
  getCssText,
  keyframes,
  styled,
  createTheme,
  theme: light,
} = createStitches({
  theme: {
    colors: {
      ...slate,
      ...slateA,
      ...bronze,
      ...teal,
      ...crimson,
      ...purple,
      ...blue,
      ...green,
      ...amber,
      ...whiteA,
      text: slate.slate12,
      textDescription: slate.slate11,
      textSupplementary: slate.slate6,
      textError: red.red11,
      bg0: "white",
      bg1: slate.slate3,
      bg2: slate.slate4,
      bg3: slate.slate5,
      border: slate.slate5,
      border2: slate.slate7,
      commonBg0: whiteA.whiteA2,
      commonBg1: whiteA.whiteA3,
      hoverColor: slate.slate2,
      activeColor: slate.slate3,
    },
    radii: {
      1: "var(--radius-1)",
      2: "var(--radius-2)",
      3: "var(--radius-3)",
      4: "var(--radius-4)",
      5: "var(--radius-5)",
      6: "var(--radius-6)",
      full: "9999px",
    },
    shadows: {
      1: "var(--shadow-1)",
      2: "var(--shadow-2)",
      3: "var(--shadow-3)",
      4: "var(--shadow-4)",
      5: "var(--shadow-5)",
      6: "var(--shadow-6)",
    },
  },
});

const dark = createTheme({
  colors: {
    ...slateDark,
    ...slateDarkA,
    ...bronzeDark,
    ...tealDark,
    ...crimsonDark,
    ...purpleDark,
    ...blueDark,
    ...greenDark,
    ...amberDark,
    ...whiteA,
    text: slateDark.slate12,
    textDescription: slateDark.slate8,
    textSupplementary: slateDark.slate6,
    textError: redDark.red11,
    bg0: "black",
    bg1: slateDark.slate3,
    bg2: slateDark.slate4,
    bg3: slateDark.slate5,
    border: slateDark.slate5,
    border2: slateDark.slate7,
    commonBg0: blackA.blackA2,
    commonBg1: blackA.blackA3,
    hoverColor: slateDark.slate1,
    activeColor: slateDark.slate2,
  },
});

export { styled, light, dark, keyframes };
