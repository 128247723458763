import { FieldsClientModel } from "~/clientModel/fields";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { TableClientModel } from "~/clientModel/tables/table";
import { Flex } from "~/components_next/Flex";
import { Spacer } from "~/components_next/Spacer";
import { MergeKeyInputEqual } from "./atoms/MergeKeyInputEqual";
import { MergeKeyInputGrid } from "./atoms/MergeKeyInputGrid";
import { MergeKeyInputTableLabel } from "./atoms/MergeKeyInputTableLabel";
import { MergeKeyInputUnit } from "./atoms/MergeKeyInputUnit";

type MergeKeyInputProps = {
  table: TableClientModel;
  targetTable: TableClientModel;
  fields: FieldsClientModel;
  useFieldsLoadable: UseLoadable<{ tableSlug: string }, FieldsClientModel>;
  onFieldsChange?: (fields: FieldsClientModel) => void; // read only時不要
  onMergeKeyUpdated?: () => void;
  isReadOnly?: boolean;
};

export const MergeKeyInput = (props: MergeKeyInputProps) => {
  const {
    table,
    fields,
    targetTable,
    useFieldsLoadable,
    onFieldsChange,
    onMergeKeyUpdated,
    isReadOnly,
  } = props;

  const handleMergeKeyFieldChange = (fieldName: string) => {
    const updatedFields = fields.changeMergeKeyField({
      fieldName,
    });
    onFieldsChange?.(updatedFields);
    onMergeKeyUpdated?.();
  };

  const handleTargetFieldChange = (fieldName: string) => {
    const updatedFields = fields.addMergeKeyTarget({
      targetTableSlug: targetTable.tableSlug,
      targetFieldName: fieldName,
    });
    onFieldsChange?.(updatedFields);
    onMergeKeyUpdated?.();
  };

  const mergeKeyFieldName = fields.mergeKeyField?.name ?? null;
  const targetFieldName =
    fields.findMergeKeyTargetFieldName(targetTable.tableSlug) ?? null;

  return (
    <Flex direction="column" gap="2">
      <MergeKeyInputGrid>
        {/* 左側(現在のSource) */}
        <MergeKeyInputTableLabel table={table} />
        <MergeKeyInputUnit
          tableSlug={table.tableSlug}
          fieldName={mergeKeyFieldName}
          onChange={handleMergeKeyFieldChange}
          useFieldsLoadable={useFieldsLoadable}
          isReadOnly={!fields.canChangeMergeKeyField || isReadOnly}
        />

        {/* 真ん中のイコール */}
        <Spacer />
        <MergeKeyInputEqual />

        {/* 右側(Target) */}
        <MergeKeyInputTableLabel table={targetTable} />
        <MergeKeyInputUnit
          tableSlug={targetTable.tableSlug}
          fieldName={targetFieldName}
          onChange={handleTargetFieldChange}
          useFieldsLoadable={useFieldsLoadable}
          isReadOnly={!fields.canAddMergeKeyTarget || isReadOnly}
        />
      </MergeKeyInputGrid>
    </Flex>
  );
};
