import { useQuery } from "react-query";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { convertRecordToRecordModel } from "~/features/RecordModel";
import { useQueryMode } from "../states/queryMode";
import { useQueryingSql } from "../states/sqlConditions";
import { UseViewRecordsResult } from "./useViewRecords";
import { useLimit, useSkip } from "../states/pagination";
import { useTableView } from "./useProps";
import { useVariables } from "../../common/states/variables";
import { useQueryViewRecordsSqlQuery } from "~/serverStateStore/views/queries/useQueryViewRecordsSqlQuery";

const useViewRecordsWithSQL = (): UseViewRecordsResult => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const view = useTableView();

  const queryMode = useQueryMode();

  const skip = useSkip();
  const limit = useLimit();
  const queryingSql = useQueryingSql();
  const variables = useVariables(); // WIP: サーバーの変更必要

  const sqlResult = useQuery({
    ...useQueryViewRecordsSqlQuery({
      teamSlug,
      databaseId,
      viewId: view.viewId,
      sql: queryingSql,
      skip,
      limit,
      variables,
    }),
    select: (data) => {
      return {
        recordsTableBaseRecords: data.items.map((record, index) => {
          return {
            values: convertRecordToRecordModel(record, data.fields),
            _reservedRecordIndex: index,
          };
        }),
        fields: data.fields,
        count: data.count,
      };
    },
    enabled: !!queryingSql && (queryMode === "sql" || queryMode === "prompt"),
  });

  return sqlResult;
};

export { useViewRecordsWithSQL, type UseViewRecordsResult };
