import constate from "constate";
import { useMemo } from "react";
import { match, P } from "ts-pattern";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import { CanvasCellVariablesClientModel } from "~/clientModel/canvas/CanvasCellClientModel";
import { CanvasVariablesClientModel } from "~/clientModel/canvas/CanvasVariableClientModel";
import { CanvasVariablesValue } from "../../common/CanvasVariablesProvider";

/**
 * Table Viewの入力候補に渡す用に、すべてのvariablesを渡すContext
 */

type PlaygroundAllVariablesContextProps = {
  cells: CanvasCellClientModelUnion[];
};

const usePlaygroundAllVariablesContext = (
  props: PlaygroundAllVariablesContextProps
) => {
  const { cells } = props;

  const variablesCells = useMemo(
    () =>
      cells.filter((cell): cell is CanvasCellVariablesClientModel => {
        return match(cell)
          .with(P.instanceOf(CanvasCellVariablesClientModel), () => true)
          .otherwise(() => false);
      }),
    [cells]
  );

  const allVariables = useMemo(
    () =>
      new CanvasVariablesClientModel(
        variablesCells.flatMap((cell) => cell.variables.data)
      ),
    [variablesCells]
  );

  const allVariableValues: CanvasVariablesValue[] = useMemo(() => {
    return variablesCells.flatMap((cell) => {
      return cell.variables.data.map((variable) => {
        return {
          name: variable.name,
          value: variable.defaultValue,
          cellId: cell.cellId,
        };
      });
    });
  }, [variablesCells]);

  return {
    allVariables,
    allVariableValues,
  };
};

export const [
  PlaygroundAllVariablesProvider,
  usePlaygroundAllVariables,
  usePlaygroundAllVariableValues,
] = constate(
  usePlaygroundAllVariablesContext,
  (value) => value.allVariables,
  (value) => value.allVariableValues
);
