import { useQueryClient } from "react-query";
import { bucketKeys } from "../keys";

// types
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import useApiBucket from "~/api/useApiBucket";

interface UseCreateBucketMutationParams {
  teamSlug: string;
}

const useCreateBucketMutation = ({
  teamSlug,
}: UseCreateBucketMutationParams) => {
  const { _createBucket } = useApiBucket();
  const client = useQueryClient();

  return {
    mutationFn: ({
      bucketName,
      readPermission,
      createPermission,
      updatePermission,
    }: {
      bucketName: string;
      readPermission: "forbidden" | "public" | "limitedPublic";
      createPermission: "forbidden" | "public" | "limitedPublic";
      updatePermission: "forbidden" | "public" | "limitedPublic";
    }) => {
      return _createBucket({
        teamSlug,
        bucketName,
        readPermission,
        createPermission,
        updatePermission,
      });
    },
    onSuccess: () => {
      return invalidateAll(
        client,
        bucketKeys.all({
          teamSlug,
        })
      );
    },
  };
};

export { useCreateBucketMutation };
