import {
  IconButton as RadixThemeIconButton,
  Tooltip as RadixThemeTooltip,
} from "@radix-ui/themes";
import {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from "react";
import { match } from "ts-pattern";
import { styled } from "~/stitches";
import { Box } from "../Box";
import { MorphThemeSize } from "../commonProps.type";
import { Spinner } from "../Spinner";

type RadixThemeIconButtonProps = ComponentPropsWithoutRef<
  typeof RadixThemeIconButton
>;

type IconButtonProps = {
  icon: ReactNode;
  tooltip: string;
  size?: MorphThemeSize;
  spinnerSize?: MorphThemeSize;
  style?: RadixThemeIconButtonProps["style"];
  isDisabled?: boolean;
  isLoading?: boolean;
  variant?: "default" | "secondary" | "inverse";
  onClick?: RadixThemeIconButtonProps["onClick"];
};

const getSize = (size: MorphThemeSize): RadixThemeIconButtonProps["size"] => {
  return match(size)
    .with("xs", () => "1" as RadixThemeIconButtonProps["size"])
    .with("sm", () => "2" as RadixThemeIconButtonProps["size"])
    .with("md", () => "3" as RadixThemeIconButtonProps["size"])
    .with("lg", () => "4" as RadixThemeIconButtonProps["size"])
    .with("xl", () => "4" as RadixThemeIconButtonProps["size"])
    .otherwise(() => "4" as RadixThemeIconButtonProps["size"]);
};

const getFontSize = (size: MorphThemeSize): string => {
  return match(size)
    .with("xs", () => "1")
    .with("sm", () => "2")
    .with("md", () => "3")
    .with("lg", () => "4")
    .with("xl", () => "5")
    .otherwise(() => "3");
};

const getVariant = (
  variant: IconButtonProps["variant"]
): RadixThemeIconButtonProps["variant"] => {
  return match(variant)
    .with("default", () => "ghost" as RadixThemeIconButtonProps["variant"])
    .with("secondary", () => "soft" as RadixThemeIconButtonProps["variant"])
    .with("inverse", () => "ghost" as RadixThemeIconButtonProps["variant"])
    .otherwise(() => "ghost" as RadixThemeIconButtonProps["variant"]);
};

const StyledIconButton = styled(RadixThemeIconButton);

const _IconButton = (
  props: IconButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const {
    size = "sm",
    spinnerSize,
    icon,
    tooltip,
    variant = "default",
    isLoading,
    isDisabled = false,
    style,
    onClick,
    ...radixIconButtonProps
  } = props;

  const radixIconButtonVariant = getVariant(variant);
  const radixIconButtonSize = getSize(size);
  const radixIconButtonFontSize = getFontSize(size);

  return (
    <RadixThemeTooltip content={tooltip}>
      <StyledIconButton
        ref={ref}
        variant={radixIconButtonVariant}
        size={radixIconButtonSize}
        onClick={onClick}
        aria-label={tooltip}
        disabled={isDisabled}
        css={{
          cursor: isDisabled ? "not-allowed" : "pointer",
          fontSize: `var(--font-size-${radixIconButtonFontSize}) !important`,
          margin: "0px !important", // 一旦これで対応
          ...style,
        }}
        color="gray"
        highContrast
        {...radixIconButtonProps}
      >
        {isLoading ? (
          <Box css={{ position: "absolute" }}>
            <Spinner size={spinnerSize || size} />
          </Box>
        ) : (
          <>{icon}</>
        )}
      </StyledIconButton>
    </RadixThemeTooltip>
  );
};

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(_IconButton);

export { IconButton, type IconButtonProps };
