import { SimpleField } from "@usemorph/morph-dashboard-types";
import { FieldsClientModel } from "~/clientModel/fields";
import { RecordClientModelFactory } from "../record";
import { CreatingRecordClientModel } from "./CreatingRecordClientModel";

export class CreatingRecordClientModelFactory {
  public static createEmptyFromFieldsClientModel(
    fields: FieldsClientModel
  ): CreatingRecordClientModel {
    const emptyRecord =
      RecordClientModelFactory.createEmptyFromFieldsClientModel(fields);

    return new CreatingRecordClientModel({
      creatingRecord: emptyRecord,
    });
  }

  public static createWithInitialRecordValue(
    record: Record<string, unknown>,
    simpleFields: SimpleField[]
  ): CreatingRecordClientModel {
    return new CreatingRecordClientModel({
      creatingRecord: RecordClientModelFactory.createFromRecordAndSimpleFields(
        record,
        simpleFields
      ),
    });
  }
}
