import { useQuery } from "react-query";
import { useFindViewQuery } from "~/serverStateStore/views";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useViewId } from "~/utilHooks/useViewId";

const useView = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  return useQuery(useFindViewQuery({ teamSlug, databaseId, viewId }));
};

export { useView };
