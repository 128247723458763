import { ForwardedRef, forwardRef } from "react";
import { useComposedRefs } from "~/utilHooks/useComposeRefs";
import {
  useMultiSelectInputRef,
  useMultiSelectInputValue,
  useMultiSelectOnChangeInputValue,
  useMultiSelectPlaceholder,
  useMultiSelectSize,
} from "./MultiSelectContext";
import { Command as CommandPrimitive } from "cmdk";
import classNames from "classnames";
import { withBreakpoints } from "@radix-ui/themes";
import { convertSize } from "./util";

type MultiSelectInputProps = Record<string, never>;

const MultiSelectInput = forwardRef(
  (props: MultiSelectInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const inputRef = useMultiSelectInputRef();
    const composedRef = useComposedRefs(ref, inputRef);
    const placeholder = useMultiSelectPlaceholder();
    const size = useMultiSelectSize();
    const inputValue = useMultiSelectInputValue();
    const onChangeInputValue = useMultiSelectOnChangeInputValue();

    return (
      <CommandPrimitive.Input
        ref={composedRef}
        className={classNames(
          "rt-reset",
          "morph-MultiSelectInput",
          withBreakpoints(convertSize(size), "morph-r-size")
        )}
        placeholder={placeholder}
        value={inputValue}
        onValueChange={onChangeInputValue}
      />
    );
  }
);

MultiSelectInput.displayName = "MultiSelectInput";

export { MultiSelectInput, type MultiSelectInputProps };
