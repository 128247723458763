import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { viewKeys } from "../keys";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import useApiView from "~/api/useApiView";
import { DashboardViewConditionObject } from "@usemorph/morph-dashboard-types";
import { ViewSetting } from "~/features/View";

type UseUpdateViewMutationParams = {
  databaseId: string;
  teamSlug: string;
  viewId: string;
  isPublic?: boolean;
};

const useUpdateViewMutation = ({
  databaseId,
  teamSlug,
  viewId,
}: UseUpdateViewMutationParams) => {
  const { _updateView } = useApiView();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      type,
      name,
      condition,
      setting,
      afterViewId,
      isPrivate,
      isPublic,
    }: {
      type: "table" | "kanban";
      name: string;
      condition: DashboardViewConditionObject;
      setting: ViewSetting | Record<string, any> | null;
      afterViewId?: string;
      isPrivate?: boolean;
      isPublic?: boolean;
    }) => {
      return _updateView({
        teamSlug,
        viewId,
        body: {
          type,
          name,
          condition,
          setting,
          afterViewId,
          isPrivate,
        },
      });
    },
    onSuccess: () => {
      const keys = viewKeys.all({ databaseId, teamSlug });
      broadcast(keys);
      return invalidateAll(client, viewKeys.all({ databaseId, teamSlug }));
    },
  };
};

export { useUpdateViewMutation };
