import {
  DashboardImportIntegrationStatusListResponse,
  DashboardSimpleImportDataListResponse,
} from "@usemorph/morph-dashboard-types";
import { ImportStatusClientModel } from "./ImportStatusClientModel";

export class ImportStatusClientModelFactory {
  static fromCsvImportResponse(
    response: DashboardSimpleImportDataListResponse["items"][number]
  ) {
    return new ImportStatusClientModel({
      id: response.id,
      status: response.status,
      tableSlug: response.specifiedSchema.name,
      total: response.rowsTotal,
      processed: response.rowsProcessed,
      importSource: response.fileType,
      importActionType: response.action === "add" ? "insert" : "create",
      sourceName: null,
      createdAt: response.createdAt,
      updatedAt: response.updatedAt,
    });
  }

  static fromExternalImportResponse(
    response: DashboardImportIntegrationStatusListResponse["items"][number]
  ) {
    return new ImportStatusClientModel({
      id: response.id,
      status: response.status,
      tableSlug: response.tableSlug,
      total: null,
      processed: null,
      importSource: response.serviceType,
      importActionType: "create",
      sourceName: response.displayName,
      createdAt: response.createdAt,
      updatedAt: response.updatedAt,
    });
  }
}
