import { BsKanban, BsTable } from "react-icons/bs";
import { getColor, getSizePx } from "./constants";
import { match } from "ts-pattern";
import { IconCommonProps } from "../type";
import { LuLayoutDashboard } from "react-icons/lu";

type ViewTypeIconProps = { type: "table" | "kanban" } & IconCommonProps;

const ViewIcon = (props: ViewTypeIconProps) => {
  const { type, size } = props;
  const iconProps = { size: getSizePx(size), color: getColor("gray") };
  return match(type)
    .with("table", () => <BsTable {...iconProps} />)
    .with("kanban", () => <BsKanban {...iconProps} />)
    .exhaustive();
};

type NotebookIconProps = IconCommonProps;

const NotebookIcon = (props: NotebookIconProps) => {
  const { size } = props;
  return <LuLayoutDashboard color={getColor("gray")} size={getSizePx(size)} />;
};

export { ViewIcon, NotebookIcon };
