import { Flex } from "@radix-ui/themes";
import { ReactNode } from "react";

// const ToolbarWrapperElement = styled(Flex, {
//   height: '100%'
// })

const ToolbarWrapper = (props: { children?: ReactNode }) => {
  const { children } = props;
  return (
    <Flex height="100%" width="100%" px="2" align="center" gap="2">
      {children}
    </Flex>
  );
};

export { ToolbarWrapper };
