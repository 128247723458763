import { CSSProperties, useMemo } from "react";
import { match, P } from "ts-pattern";
import { CoworkerState } from "../types";

// coworkerSelection must be filtered to only include the field.
// passing non-empty coworkerSelection means that the header must be highlighted
export const useHeaderStyle = (
  isHoverable?: boolean,
  isSelected?: boolean,
  coworkerSelection?: CoworkerState
) => {
  const backgroundColor = useMemo(() => {
    return match([isHoverable, isSelected, coworkerSelection])
      .with([P._, P._, P.not(P.nullish)], () => {
        const colorScheme = coworkerSelection!.colorScheme;
        return {
          backgroundColor: `$${colorScheme}3`,
        };
      })
      .with([P._, true, P._], () => {
        return {
          backgroundColor: "$bg3",
        };
      })
      .with([true, P._, P._], () => {
        return {
          backgroundColor: "$bg1",
          _hover: {
            backgroundColor: "$bg2",
          },
        };
      })
      .otherwise(() => {
        return {
          backgroundColor: "$bg1",
        };
      });
  }, [coworkerSelection, isHoverable, isSelected]);

  return {
    headerAttributes: {
      ...backgroundColor,
      height: "40px",
    } as CSSProperties,
  };
};
