import { Button } from "~/components_next/Button";
import { FieldSettingForm } from "~/features/Fields/FieldVisibility/FieldSettingForm";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useCallback } from "react";
import { useViewId } from "~/utilHooks/useViewId";
import { SimpleField } from "@usemorph/morph-dashboard-types";
import { useMutation } from "react-query";
import {
  useOptimisticUpdateViewMutation,
  useViewFields,
} from "~/serverStateStore/views";
import { useErrorToast } from "~/components_next/Error";
import { useView } from "~/features/SourceAndViews/common/utils/useView";
import {
  createFieldsOrderFromViewFields,
  parseViewSetting,
} from "~/features/View";
import { match, P } from "ts-pattern";
import { FieldSelect } from "~/features/Fields/Form/FieldSelect";
import { Text } from "~/components_next/Text";
import { Flex } from "~/components_next/Flex";
import { Box } from "~/components_next/Box";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";

const FieldSettingButton = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  const { data: viewData } = useView();
  const { data: viewFieldsData } = useViewFields({
    teamSlug,
    databaseId,
    viewId,
    viewData,
  });

  const { mutateAsync: optimisticUpdateView } = useMutation(
    useOptimisticUpdateViewMutation({ teamSlug, databaseId, viewId })
  );

  const { errorToast } = useErrorToast({});

  const handleFieldOrderUpdate = useCallback(
    async (reorderedFields: SimpleField[]) => {
      try {
        if (!viewData || !viewFieldsData)
          throw new Error("Failed to update field order");
        const { setting, ...others } = viewData;
        await optimisticUpdateView({
          ...others,
          setting: {
            data: {
              ...parseViewSetting(setting).data,
              fieldsOrder: createFieldsOrderFromViewFields(reorderedFields),
            },
          },
        });
      } catch (e) {
        errorToast(e);
      }
    },
    [errorToast, viewData, optimisticUpdateView, viewFieldsData]
  );

  const handleIsHiddenUpdate = useCallback(
    async ({
      field: targetField,
      isHidden,
    }: {
      field: SimpleField;
      isHidden: boolean;
    }) => {
      try {
        if (!viewData || !viewFieldsData) {
          throw new Error("Failed to update field order");
        }
        const { type, name, condition, setting } = viewData;
        const parsedSetting = parseViewSetting(setting);
        const prevHiddenFields = parsedSetting.data.hiddenFields;
        const updateHiddenFields: {
          originalTableSlug?: string | undefined;
          name: string;
        }[] = match([prevHiddenFields, isHidden])
          .with([P.nullish, true], () => {
            return [
              {
                originalTableSlug: targetField.originalTableSlug,
                name: targetField.name,
              },
            ];
          })
          .with([P.nullish, false], () => {
            return [];
          })
          .with([P.not(P.nullish), true], ([prevHiddenFields]) => {
            return [
              ...prevHiddenFields,
              {
                originalTableSlug: targetField.originalTableSlug,
                name: targetField.name,
              },
            ];
          })
          .with([P.not(P.nullish), false], ([prevHiddenFields]) => {
            return prevHiddenFields.filter(
              ({ name, originalTableSlug }) =>
                name !== targetField.name ||
                originalTableSlug !== targetField.originalTableSlug
            );
          })
          .exhaustive();
        await optimisticUpdateView({
          type,
          name,
          condition,
          setting: {
            data: {
              ...parsedSetting.data,
              hiddenFields: updateHiddenFields,
            },
          },
        });
      } catch (e) {
        errorToast(e);
      }
    },
    [errorToast, viewData, optimisticUpdateView, viewFieldsData]
  );

  const handleTitleFieldChange = useCallback(
    async (newTitleField: SimpleField) => {
      try {
        if (!viewData || !viewFieldsData) {
          throw new Error("Failed to update field order");
        }
        const { setting, ...others } = viewData;

        const parsedSetting = parseViewSetting(setting);

        // title fieldをhidden fieldsから外す
        const updatedHiddenFields = parsedSetting.data.hiddenFields?.filter(
          ({ name, originalTableSlug }) =>
            !(
              name === newTitleField.name &&
              originalTableSlug === newTitleField.originalTableSlug
            )
        );

        await optimisticUpdateView({
          ...others,
          setting: {
            data: {
              ...parsedSetting.data,
              hiddenFields: updatedHiddenFields,
              titleField: {
                name: newTitleField.name,
                originalTableSlug: newTitleField.originalTableSlug,
              },
            },
          },
        });
      } catch (e) {
        errorToast(e);
      }
    },
    [errorToast, optimisticUpdateView, viewFieldsData, viewData]
  );

  if (!viewData || !viewFieldsData) {
    return null;
  }

  return (
    <SimpleDropdownMenu
      trigger={
        <Button variant="tertiary" size="xs">
          Fields
        </Button>
      }
      size="sm"
    >
      <Flex gap="3" direction="column">
        <Box>
          <Text variant="subheading">Title Field</Text>
          <FieldSelect
            value={viewFieldsData.titleField}
            fields={viewFieldsData.allPropertyFields}
            onChange={handleTitleFieldChange}
            size="sm"
          />
        </Box>
        <Box>
          <Text variant="subheading">Properties</Text>
          <FieldSettingForm
            fields={viewFieldsData.allPropertyFields}
            onFieldOrderUpdate={handleFieldOrderUpdate}
            onIsHiddenUpdate={handleIsHiddenUpdate}
            showTableSlug
          />
        </Box>
      </Flex>
    </SimpleDropdownMenu>
  );
};

export { FieldSettingButton };
