import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import {
  Background,
  BackgroundVariant,
  MiniMap,
  NodeChange,
  Panel,
  ReactFlow,
  ReactFlowProvider,
  SelectionMode,
} from "reactflow";
import {
  CanvasCellClientModelUnion,
  FindCanvasClientModel,
} from "~/clientModel/canvas";
import { UseExecutable } from "~/clientModel/executable";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
import { UseExecuteCellLoadableContextProvider } from "../common/UseExecuteCellLoadableContextProvider";
import {
  CanvasVariablesProvider,
  CanvasVariablesValue,
} from "../common/CanvasVariablesProvider";
import { PlaygroundAddCellControls } from "./controlPanel/addCell/PlaygroundAddCellControls";
import { PlaygroundCellsList } from "./controlPanel/cellsList/PlaygroundCellsList";
import { JoinControl } from "./controlPanel/joinControls/JoinControl";
import { PlaygroundTidyLayout } from "./controlPanel/tidyLayout/PlaygroundTidyLayout";
import {
  PlaygroundOnAddCellToPageExecutableProvider,
  UsePlaygroundAddCellToPageContextProps,
} from "./providers/PlaygroundAddCellToPageExecutableProvider";
import { PlaygroundPseudoCellSelectionProvider } from "./providers/PlaygroundPseudoCellSelectionProvider";
import {
  PlaygroundRenderAreaSizeProvider,
  usePlaygroundSetRenderAreasize,
} from "./providers/PlaygroundRenderAreaSizeProvider";
import {
  PlaygroundUpdateCellExecutableProvider,
  UsePlaygroundUpdateCellContextProps,
} from "./providers/PlaygroundUpdateCellExecutableProvider";
import { PlaygroundUpdateLiveCellProvider } from "./providers/PlaygroundUpdateLiveCellProvider";
import { PlaygroundSidebar } from "./sidebar/PlaygroundSidebar";
import { nodeTypes, useReactFlowNodes } from "./useReactFlowNodes";
import { UseFetchFileExecutableProvider } from "../cellContent/prompt/units/CanvasCellPromptUnitFile";
import {
  PlaygroundCellStyleProps,
  PlaygroundCreateCellExecutableProvider,
} from "./providers/PlaygroundCreateCellExecutableProvider";
import { CanvasCreateCellClientModel } from "~/clientModel/canvas/CanvasCreateCellClientModel";
import {
  PlaygroundTableViewPropsContextProps,
  PlaygroundTableViewPropsProvider,
} from "./providers/PlaygroundTableViewPropsProvider";
import { PlaygroundAllVariablesProvider } from "./providers/PlaygroundAllVariablesProvider";
import { PlaygroundUseSourceFieldsLoadableProvider } from "./providers/PlaygroundUseSourceFIeldsLoadableProvider";
import { FieldsClientModel } from "~/clientModel/fields";
import { PlaygroundAncestorUtilProvider } from "./providers/PlaygroundAncestorUtilProvider";
import {
  CanvasVariableOptionsLoadableProvider,
  UseVariableOptionsViewResultLoadable,
} from "../common/CanvasVariableOptionsLoadableProvider";
import {
  PlaygroundCoworkerStateProps,
  PlaygroundCoworkerStateProvider,
} from "./providers/PlaygroundCoworkerStateProvider";
import { CoworkerCursors } from "./coworker/CoworkerCursors";
import { MyPresenceEventHandler } from "./coworker/MyPresenceEventHandler";
import { CanvasVisualizationPromptConfigsCientModel } from "~/clientModel/canvas/CanvasVisualizationPromptConfigClientModel";
import {
  ReactFlowSelectedCellsProvider,
  useReactFlowIsNodeSelectionLocked,
} from "./providers/ReactFlowSelectedCellsProvider";
import { TablesClientModel } from "~/clientModel/tables";

type CanvasPlaygroundPresenterProps = {
  canvasClientModel: FindCanvasClientModel;
  cells: CanvasCellClientModelUnion[];
  // outputにファイルが来た時にダウンロードボタンを出す用
  useFetchFileExecutable: UseExecutable<
    void,
    {
      path: string;
    },
    {
      path: string | null;
      url: string;
    },
    unknown
  >;
  // prompt cellの再実行
  useExecuteCellLoadable: UseLoadable<
    { cellId: string; variables: CanvasVariablesValue[] },
    CanvasCellClientModelUnion
  >;
  // sourceの一覧表示
  useSourcesLoadables: UseLoadable<void, TablesClientModel>;
  // create cell
  useCreateCellExecutable: UseExecutable<
    void,
    CanvasCreateCellClientModel,
    CanvasCellClientModelUnion,
    unknown
  >;
  // create cellでvisualizationが選択された時の選択肢のconfig
  useVisualizationPromptConfigsLoadable: UseLoadable<
    void,
    CanvasVisualizationPromptConfigsCientModel,
    unknown
  >;
  // update cell
  useUpdateCellExecutable: UsePlaygroundUpdateCellContextProps["useUpdateCellExecutable"]; // Executable
  // update cell without run
  useUpdateCellWitouhRunExecutable: UsePlaygroundUpdateCellContextProps["useUpdateCellWitouhRunExecutable"]; // Executable
  // add live cell
  onAddLiveCell: (cell: CanvasCellClientModelUnion) => void;
  // remove lice cell
  onRemoveLiveCell: (cellId: string) => void;
  // update live cell: APIの実行がないので、ローカルステートの更新のように扱う
  onUpdateLiveCell: (cell: CanvasCellClientModelUnion) => void;
  // create new page
  useCreatePageExecutable: UsePlaygroundAddCellToPageContextProps["useCreatePageExecutable"];
  // add cell to page
  useAddCellToPageExecutable: UsePlaygroundAddCellToPageContextProps["useAddCellToPageExecutable"]; // Executable
  // delete cell executable
  useDeleteCellExecutable: UseExecutable<
    void,
    { cellId: string },
    unknown,
    unknown
  >;
  // イベントコールバック
  onPointerMove?: (position: { x: number; y: number }) => void;
  // sidebar
  sidebarWidth: number;
  onSidebarResize: (width: number) => void;

  // join関係
  useViewFieldsLoadable: UseLoadable<{ viewId: string }, FieldsClientModel>;
  useSourceFieldsLoadable: UseLoadable<
    { tableSlug: string },
    FieldsClientModel
  >;

  // variable用
  useVariableOptionsLoadable: UseVariableOptionsViewResultLoadable;

  // Canvas style関係
  cellStyleProps: PlaygroundCellStyleProps;

  // table view props
  tableViewProps: PlaygroundTableViewPropsContextProps;

  coworkerStateProps: PlaygroundCoworkerStateProps;
};

const ReactFlowCanvas = memo(
  ({ cells }: { cells: CanvasCellClientModelUnion[] }) => {
    /**
     * ReactFlowNodes
     */
    const { nodes, edges, onEdgesChange, onNodesChange } = useReactFlowNodes({
      cells,
    });

    const isNodeSelectionLocked = useReactFlowIsNodeSelectionLocked();

    const handleNodesChange = useCallback(
      (nodeChange: NodeChange[]) => {
        if (isNodeSelectionLocked) return;
        onNodesChange(nodeChange);
      },
      [isNodeSelectionLocked, onNodesChange]
    );

    return (
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={handleNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        // pan & scroll
        minZoom={0.1}
        panOnScroll
        // selection
        selectionKeyCode={"Shift"}
        selectionMode={SelectionMode.Partial}
        // onPaneClick
        // drag
        snapToGrid
        snapGrid={[10, 10]}
        // init view
        fitView
        onlyRenderVisibleElements
      >
        <MiniMap pannable />
        <Background
          color="#E4E4E9"
          size={3}
          style={{ backgroundColor: "#F2F2F5" }}
          variant={BackgroundVariant.Dots}
        />
        <CoworkerCursors />
      </ReactFlow>
    );
  }
);
ReactFlowCanvas.displayName = "ReactFlowCanvas";

const MemoizedReactFlowCanvas = memo(ReactFlowCanvas);

const CanvasPlaygroundPresenterContent = memo(
  (
    props: Pick<
      CanvasPlaygroundPresenterProps,
      | "cells"
      | "useCreateCellExecutable"
      | "useUpdateCellExecutable"
      | "useDeleteCellExecutable"
      | "sidebarWidth"
      | "onSidebarResize"
      | "onAddLiveCell"
      | "onUpdateLiveCell"
      | "onRemoveLiveCell"
      | "useSourceFieldsLoadable"
      | "useViewFieldsLoadable"
      | "useVariableOptionsLoadable"
    >
  ) => {
    const {
      cells,
      useCreateCellExecutable,
      useUpdateCellExecutable,
      useDeleteCellExecutable,
      sidebarWidth,
      onSidebarResize,
      onAddLiveCell,
      onUpdateLiveCell,
      onRemoveLiveCell,
      useSourceFieldsLoadable,
      useViewFieldsLoadable,
      useVariableOptionsLoadable,
    } = props;

    /**
     * RenderAreaSize
     * 座標計算のために必要
     */
    const renderAreaRef = useRef<HTMLDivElement>(null);
    const setRenderAreaSize = usePlaygroundSetRenderAreasize();
    useEffect(() => {
      if (!renderAreaRef.current) return;
      const resizeObserver = new ResizeObserver(() => {
        if (renderAreaRef.current) {
          setRenderAreaSize({
            width: renderAreaRef.current.clientWidth,
            height: renderAreaRef.current.clientHeight,
          });
        }
      });
      resizeObserver.observe(renderAreaRef.current);
      return () => resizeObserver.disconnect(); // clean up
    }, [renderAreaRef.current]);

    const offset = useMemo(() => {
      if (renderAreaRef.current) {
        const rect = renderAreaRef.current?.getBoundingClientRect();
        return {
          x: rect?.left || 0,
          y: rect?.top || 0,
        };
      }
      return {
        x: 0,
        y: 0,
      };
    }, [renderAreaRef.current]);

    return (
      <Box height="100%" width="100%" position="relative" ref={renderAreaRef}>
        <ReactFlowProvider>
          <ReactFlowSelectedCellsProvider cells={cells}>
            <MemoizedReactFlowCanvas cells={cells} />
            <MyPresenceEventHandler offset={offset} />
            <Panel position="top-left">
              <PlaygroundCellsList
                cells={cells}
                onUpdateLiveCell={onUpdateLiveCell}
              ></PlaygroundCellsList>
            </Panel>
            <Panel position="bottom-center">
              <Flex direction="column" align="center" gap="3">
                <Flex gap="2" align="center">
                  <PlaygroundTidyLayout
                    cells={cells}
                    onUpdateLiveCell={onUpdateLiveCell}
                  />
                  <JoinControl
                    cells={cells}
                    useViewFieldsLoadable={useViewFieldsLoadable}
                    useSourceFieldsLoadable={useSourceFieldsLoadable}
                    useCreateCellExecutable={useCreateCellExecutable}
                  />
                </Flex>
                <PlaygroundAddCellControls
                  cells={cells}
                  useCreateCellExecutable={useCreateCellExecutable}
                  useDeleteCellExecutable={useDeleteCellExecutable}
                  onAddLiveCell={onAddLiveCell}
                  onRemoveLiveCell={onRemoveLiveCell}
                />
              </Flex>
            </Panel>
            <PlaygroundSidebar
              cells={cells}
              useUpdateCellExecutable={useUpdateCellExecutable}
              useDeleteCellExecutable={useDeleteCellExecutable}
              width={sidebarWidth}
              onResize={onSidebarResize}
              useVariableOptionsLoadable={useVariableOptionsLoadable}
            />
          </ReactFlowSelectedCellsProvider>
        </ReactFlowProvider>
      </Box>
    );
  }
);
CanvasPlaygroundPresenterContent.displayName =
  "CanvasPlaygroundPresenterContent";

const CanvasPlaygroundPresenter = memo(
  (props: CanvasPlaygroundPresenterProps) => {
    const {
      canvasClientModel,
      useFetchFileExecutable,
      useExecuteCellLoadable,
      useCreateCellExecutable,
      useUpdateCellExecutable,
      useUpdateCellWitouhRunExecutable,
      onUpdateLiveCell,
      useCreatePageExecutable,
      useAddCellToPageExecutable,
      useSourceFieldsLoadable,
      useViewFieldsLoadable,
      useSourcesLoadables,
      cellStyleProps,
      useVisualizationPromptConfigsLoadable,
    } = props;

    return (
      <PlaygroundRenderAreaSizeProvider>
        <PlaygroundPseudoCellSelectionProvider>
          <PlaygroundCoworkerStateProvider {...props.coworkerStateProps}>
            <UseFetchFileExecutableProvider
              useFetchFileExecutable={useFetchFileExecutable}
            >
              <UseExecuteCellLoadableContextProvider
                useExecuteCellLoadable={useExecuteCellLoadable}
              >
                <PlaygroundCreateCellExecutableProvider
                  useCreateCellExecutable={useCreateCellExecutable}
                  useVisualizationPromptConfigsLoadable={
                    useVisualizationPromptConfigsLoadable
                  }
                  {...cellStyleProps}
                >
                  <PlaygroundUpdateCellExecutableProvider
                    useUpdateCellExecutable={useUpdateCellExecutable}
                    useUpdateCellWitouhRunExecutable={
                      useUpdateCellWitouhRunExecutable
                    }
                  >
                    <PlaygroundUpdateLiveCellProvider
                      onUpdateLiveCell={onUpdateLiveCell}
                    >
                      <PlaygroundOnAddCellToPageExecutableProvider
                        useCreatePageExecutable={useCreatePageExecutable}
                        useAddCellToPageExecutable={useAddCellToPageExecutable}
                        pages={canvasClientModel.pages}
                      >
                        <PlaygroundUseSourceFieldsLoadableProvider
                          useSourceFieldsLoadable={useSourceFieldsLoadable}
                          useViewFieldsLoadable={useViewFieldsLoadable}
                          useSourcesLoadables={useSourcesLoadables}
                        >
                          <PlaygroundAncestorUtilProvider cells={props.cells}>
                            <PlaygroundAllVariablesProvider cells={props.cells}>
                              <CanvasVariablesProvider>
                                <CanvasVariableOptionsLoadableProvider
                                  useVariableOptionsLoadable={
                                    props.useVariableOptionsLoadable
                                  }
                                >
                                  <PlaygroundTableViewPropsProvider
                                    {...props.tableViewProps}
                                  >
                                    <CanvasPlaygroundPresenterContent
                                      cells={props.cells}
                                      useCreateCellExecutable={
                                        useCreateCellExecutable
                                      }
                                      useUpdateCellExecutable={
                                        useUpdateCellExecutable
                                      }
                                      useDeleteCellExecutable={
                                        props.useDeleteCellExecutable
                                      }
                                      sidebarWidth={props.sidebarWidth}
                                      onSidebarResize={props.onSidebarResize}
                                      onAddLiveCell={props.onAddLiveCell}
                                      onUpdateLiveCell={onUpdateLiveCell}
                                      onRemoveLiveCell={props.onRemoveLiveCell}
                                      useSourceFieldsLoadable={
                                        useSourceFieldsLoadable
                                      }
                                      useViewFieldsLoadable={
                                        useViewFieldsLoadable
                                      }
                                      useVariableOptionsLoadable={
                                        props.useVariableOptionsLoadable
                                      }
                                    />
                                  </PlaygroundTableViewPropsProvider>
                                </CanvasVariableOptionsLoadableProvider>
                              </CanvasVariablesProvider>
                            </PlaygroundAllVariablesProvider>
                          </PlaygroundAncestorUtilProvider>
                        </PlaygroundUseSourceFieldsLoadableProvider>
                      </PlaygroundOnAddCellToPageExecutableProvider>
                    </PlaygroundUpdateLiveCellProvider>
                  </PlaygroundUpdateCellExecutableProvider>
                </PlaygroundCreateCellExecutableProvider>
              </UseExecuteCellLoadableContextProvider>
            </UseFetchFileExecutableProvider>
          </PlaygroundCoworkerStateProvider>
        </PlaygroundPseudoCellSelectionProvider>
      </PlaygroundRenderAreaSizeProvider>
    );
  }
);
CanvasPlaygroundPresenter.displayName = "CanvasPlaygroundPresenter";

export { CanvasPlaygroundPresenter, type CanvasPlaygroundPresenterProps };
