import { DashboardPageCellDetailObject } from "@usemorph/morph-dashboard-types";
import { CanvasCellClientModelFactory } from "../CanvasCellClientModel";
import { DashboardCellClientModel } from "./DashboardCellClientModel";

export class DashboardCellClientModelFactory {
  static fromDashboardPageCellResponse(
    dashboardPageCell: DashboardPageCellDetailObject
  ) {
    const cellClientModel =
      CanvasCellClientModelFactory.fromDashboardPageCellResponse(
        dashboardPageCell.notebookCell
      );

    return new DashboardCellClientModel({
      createdAt: dashboardPageCell.createdAt,
      cellId: dashboardPageCell.cellId,
      x: dashboardPageCell.x,
      y: dashboardPageCell.y,
      w: dashboardPageCell.w,
      h: dashboardPageCell.h,
      cell: cellClientModel,
    });
  }
}
