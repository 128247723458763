import {
  CanvasCellClientModelBase,
  CanvasCellDataType,
  CanvasCellResizeParams,
} from "./CanvasCellClientModelBase";

export type CanvasCellSourceDataType = CanvasCellDataType & {
  cellType: "source";
};

export class CanvasCellSourceClientModel extends CanvasCellClientModelBase<
  CanvasCellSourceClientModel,
  CanvasCellSourceDataType
> {
  constructor(data: CanvasCellSourceDataType) {
    super(data);
  }

  /**
   * Custom value getters
   */
  get tableSlug(): string | null {
    return this.source.tableSlug || null;
  }

  get isResizable(): boolean {
    return false;
  }

  get showSourceHandle(): boolean {
    return true;
  }

  get showTargetHandle(): boolean {
    return false;
  }

  updateCellName(cellName: string): CanvasCellSourceClientModel {
    return new CanvasCellSourceClientModel({
      ...this.data,
      cellName,
    });
  }

  onResize(resizePrams: CanvasCellResizeParams): CanvasCellSourceClientModel {
    return new CanvasCellSourceClientModel({
      ...this.data,
      settings: {
        ...this.data.settings,
        ...resizePrams,
      },
    });
  }
}
