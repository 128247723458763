import { useMutation, useQuery } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import {
  FieldsClientModel,
  FieldsClientModelDefactory,
} from "~/clientModel/fields";
import {
  FieldClientModel,
  FieldClientModelDefactory,
} from "~/clientModel/fields/field";
import { SmartFunctionsClientModelFactory } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";

import { useErrorToast } from "~/components_next/Error";
import {
  useCreateComputeFieldMutation,
  useListComputedFieldOptionsForSmartFunctionQuery,
} from "~/serverStateStore";

type UseCreateComputedFieldExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useCreateComputedFieldExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseCreateComputedFieldExecutableParams): Executable<
  {
    createdField: FieldClientModel;
    fields: FieldsClientModel;
  },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  const createComputeFieldOptions = useCreateComputeFieldMutation({
    teamSlug,
    databaseId,
    tableSlug,
  });

  // todo: v4移行後、queryClient.ensureQueryDataにする
  const { data: smartFunctions } = useQuery({
    ...useListComputedFieldOptionsForSmartFunctionQuery({ teamSlug }),
    select: (data) =>
      SmartFunctionsClientModelFactory.createFromSmartFieldFunctionListResponse(
        data
      ),
  });

  return useComposeExecutable(
    useMutation({
      ...createComputeFieldOptions,
      mutationFn: async ({
        createdField,
        fields,
      }: {
        createdField: FieldClientModel;
        fields: FieldsClientModel;
      }) => {
        if (!smartFunctions) {
          throw new Error("Something went wrong. Please try again.");
        }

        const createdSimpleField = FieldClientModelDefactory.toSimpleField(
          createdField,
          {
            smartFunctions,
          }
        );

        const mergeSimpleField = FieldsClientModelDefactory.toMergeSimpleField(
          fields,
          {
            smartFunctions,
          }
        );

        if (!mergeSimpleField) {
          throw new Error("Something went wrong. Please try again.");
        }

        return createComputeFieldOptions.mutationFn({
          normalizedField: createdSimpleField,
          mergeField: mergeSimpleField,
        });
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseCreateComputedFieldExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseCreateComputedFieldExecutableParams) => {
  return () =>
    useCreateComputedFieldExecutable({ teamSlug, databaseId, tableSlug });
};
