const tableViewUrlSyncStoreItemKeys = {
  tableViewFilterCondition: "filter",
  tableViewSortCondition: "sort",
  tableViewSkip: "skip",
  tableViewLimit: "limit",
  tableViewQueryMode: "queryMode",
  tableViewQueryingSql: "sql",
  tableViewPromptInput: "prompt",
} as const;

export { tableViewUrlSyncStoreItemKeys };
