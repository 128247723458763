import { useMutation } from "react-query";
import { useComposeExecutable } from "~/clientModel/executable";
import { useRunImportMutation } from "~/serverStateStore";

export const useRunImportExecutable = (props: {
  teamSlug: string;
  databaseId: string;
}) => {
  const { teamSlug, databaseId } = props;

  const runImportMutationOptions = useRunImportMutation({
    teamSlug,
    databaseId,
  });

  return useComposeExecutable(
    useMutation({
      ...runImportMutationOptions,
    })
  );
};
