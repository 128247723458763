
import { AlertDialog } from '@radix-ui/themes';
import { useEffect, useState, useRef } from 'react';
import { Box } from '~/components_next/Box';
import { Button } from '~/components_next/Button';
import { Flex } from '~/components_next/Flex';
import { useDisclosure } from '~/hooks/useDisclosure';

type IWindowProps = {
  url: string;
  title: string;
  width: number;
  height: number;
};

type IPopupProps = IWindowProps & {
  onClose: () => void;
  onCode: (code: string, params: URLSearchParams) => void;
  children: React.ReactNode;
};

const createPopup = ({
  url, title, height, width,
}: IWindowProps) => {
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  const externalPopup = window.open(
    url,
    title,
    `width=${width},height=${height},left=${left},top=${top}`,
  );
  return externalPopup;
};

const OauthPopup: React.FC<IPopupProps> = ({
  title = '',
  width = 500,
  height = 500,
  url,
  children,
  onCode,
  onClose,
}: IPopupProps) => {
  const [externalWindow, setExternalWindow] = useState<Window | null>();
  const intervalRef = useRef<number>();

  const clearTimer = () => {
    window.clearInterval(intervalRef.current);
  };

  const onContainerClick = () => {
    setExternalWindow(createPopup({
      url, title, width, height,
    }));
  };

  const alertDialogDisclosure = useDisclosure()

  useEffect(() => {
    if (externalWindow) {
      intervalRef.current = window.setInterval(() => {
        try {
          const currentUrl = externalWindow.location.href;
          const params = new URL(currentUrl).searchParams;
          const code = params.get('code');
          if (!code) {
            return;
          }
          onCode(code, params);
          clearTimer();
          externalWindow.close();
        } catch (error) {
          // 想定上はsnowflakeの場合のみエラーが出るはず
          // externalWindow.close();
          // alertDialogDisclosure.onOpen()
          // onClose()
          // clearTimer();
        } finally {
          if (!externalWindow || externalWindow.closed) {
            onClose();
            clearTimer();
          }
        }
      }, 700);
    }
    return () => {
      if (externalWindow) externalWindow.close();
      if (onClose) onClose();
    };
  }, [externalWindow]);

  return (
    <>
      <Box
        display="inline"
        onClick={() => {
          onContainerClick();
        }}
      >
        {children}
      </Box>

      {/* snowflake error */}
      <AlertDialog.Root open={alertDialogDisclosure.isOpen} onOpenChange={alertDialogDisclosure.setIsOpen}>
        <AlertDialog.Content style={{ maxWidth: 450 }}>
          <AlertDialog.Title>Access error</AlertDialog.Title>
          <Box mt="5" />
          <AlertDialog.Description size="2">
            The authentication information is different. Please check and try again.
          </AlertDialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <Button variant="secondary" onClick={alertDialogDisclosure.onClose}>
              Close
            </Button>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </>
  );
};

export default OauthPopup;
