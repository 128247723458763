import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { stringToBoolean } from "~/utils/stringUtils";

/**
 * useImportOtherServiceModal
 */

const OTHER_SERVICE_QUERY_PARAM_KEY = "otherService";
const INTEGRATION_ID_QUERY_PARAM_KEY = "integrationId";
const IS_PRIVATE_QUERY_PARAM_KEY = "isPrivate";

type OtherServiceType = {
  description: string;
  templateId: string;
  templateName: string;
  logoUrl: string;
  isPremium: boolean;
}

type ModalStateType = {
  isOpen: true;
  isPrivate: boolean;
  selected: {
    otherService: string | null;
    integrationId: string | null;
  }
}
  | {
    isOpen: false;
  };

type UseImportOtherServiceModalReturn = {
  onOpen: ({ otherService, integrationId }: {
    otherService: string | null;
    integrationId: string | null;
  }) => void;
  onClose: () => void;
  modalState: ModalStateType;
  setIsPrivate: (isPrivate: boolean) => void;
  setOtherService: (otherService: string) => void;
  setIntegrationId: (integrationId: string) => void;
  selectedTemplate: OtherServiceType | undefined;
  selectedIntegrationId: string | undefined;
};

// URLから状態を復帰できるように、モーダルの状態(開閉、選択されているタブ)をクエリパラメータに持たせている
const useImportOtherServiceModal = (): UseImportOtherServiceModalReturn => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setOtherService = useCallback(
    (otherService: string | null) => {
      setSearchParams((prev) => {
        prev.set(OTHER_SERVICE_QUERY_PARAM_KEY, otherService ?? 'null');
        return prev;
      });
    },
    [setSearchParams]
  );

  const setIntegrationId = useCallback(
    (integrationId: string | null) => {
      setSearchParams((prev) => {
        prev.set(INTEGRATION_ID_QUERY_PARAM_KEY, integrationId ?? 'null');
        return prev;
      });
    },
    [setSearchParams]
  );

  const setIsPrivate = useCallback(
    (isPrivate: boolean) => {
      setSearchParams((prev) => {
        prev.set(IS_PRIVATE_QUERY_PARAM_KEY, `${isPrivate}` ?? 'false');
        return prev;
      });
    },
    [setSearchParams]
  );

  const onOpen = useCallback(
    (props: {
      otherService: string | null;
      integrationId: string | null;
    }) => {
      console.log('onOpen')
      setOtherService(props.otherService);
      setIntegrationId(props.integrationId);
    },
    [setOtherService, setIntegrationId]
  );

  const onClose = useCallback(() => {
    setSearchParams((prev) => {
      prev.delete(OTHER_SERVICE_QUERY_PARAM_KEY);
      prev.delete(INTEGRATION_ID_QUERY_PARAM_KEY);
      return prev;
    });
  }, [setSearchParams]);

  const modalState = useMemo(() => {
    const selectedOtherServiceFromQueryParam = searchParams.get(OTHER_SERVICE_QUERY_PARAM_KEY);
    const selectedIntegrationIdFromQueryParam = searchParams.get(INTEGRATION_ID_QUERY_PARAM_KEY);
    const selectedIsPrivateFromQueryParam = stringToBoolean(searchParams.get(IS_PRIVATE_QUERY_PARAM_KEY))

    if (selectedOtherServiceFromQueryParam || selectedIntegrationIdFromQueryParam) {
      return {
        isOpen: true,
        isPrivate: selectedIsPrivateFromQueryParam,
        selected: {
          otherService: selectedOtherServiceFromQueryParam,
          integrationId: selectedIntegrationIdFromQueryParam
        },
      } as const;
    } else {
      return {
        isOpen: false,
      } as const;
    }
  }, [searchParams]);

  const selectedTemplate = useMemo(() => {
    if (modalState.isOpen && modalState.selected.otherService) {
      return JSON.parse(modalState.selected.otherService) as OtherServiceType;
    }
  }, [modalState])

  const selectedIntegrationId = useMemo(() => {
    if (modalState.isOpen && modalState.selected.integrationId) {
      return modalState.selected.integrationId;
    }
  }, [modalState]);

  return { onOpen, onClose, modalState, setOtherService, setIntegrationId, setIsPrivate, selectedTemplate, selectedIntegrationId };
};

export { useImportOtherServiceModal, type UseImportOtherServiceModalReturn, type OtherServiceType, type ModalStateType };
