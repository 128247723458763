import { useQuery } from "react-query";

import {
  SmartFunctionsClientModel,
  SmartFunctionsClientModelFactory,
} from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";
import { Loadable, useComposeLoadable } from "~/clientModel/loadable";

import { useListComputedFieldOptionsForSmartFunctionQuery } from "~/serverStateStore";

type useSmartFunctionsLoadableParams = {
  teamSlug: string;
};

export const useSmartFunctionsLoadable = ({
  teamSlug,
}: useSmartFunctionsLoadableParams): Loadable<
  SmartFunctionsClientModel,
  unknown
> => {
  return useComposeLoadable(
    useQuery({
      ...useListComputedFieldOptionsForSmartFunctionQuery({
        teamSlug,
      }),
      select: (data) => {
        return SmartFunctionsClientModelFactory.createFromSmartFieldFunctionListResponse(
          data
        );
      },
    })
  );
};
