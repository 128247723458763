import { DashboardUpdateRssTableMappingRequestBody } from "@usemorph/morph-dashboard-types";
import { RssTableMappingClientModel } from "./RssTableMappingClientModel";
import { RssFieldClientModelDefactory } from "./RssFieldClientModelDefactory";



export class RssTableMappingClientModelDefactory {
  public static toUpdateRequestBody(rss: RssTableMappingClientModel): DashboardUpdateRssTableMappingRequestBody {
    return {
      rssUrl: rss.rssUrl,
      fields: RssFieldClientModelDefactory.toRssField(rss.fields),
      isEnabled: rss.isEnabled,
    }
  }
}
