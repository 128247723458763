import { useEditorOrThrow } from "../utils";

const useHeading = ({ level }: { level: 1 | 2 | 3 | 4 | 5 | 6 }) => {
  const editor = useEditorOrThrow();

  const handleToggle = () => {
    editor.chain().focus().toggleHeading({ level }).run();
  };

  const isActive = editor.isActive("heading", { level });

  const isDisabled = !editor
    .can()
    .chain()
    .focus()
    .toggleHeading({ level })
    .run();

  return {
    handleToggle,
    isActive,
    isDisabled,
  };
};

export { useHeading };
