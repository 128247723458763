import useApiTable from "~/api/useApiTable";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { tablesKeys } from "../keys";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import { viewKeys } from "~/serverStateStore/views";

interface UseDeleteTableMutationParams {
  databaseId: string;
  teamSlug: string;
}

const useDeleteTableMutation = ({
  databaseId,
  teamSlug,
}: UseDeleteTableMutationParams) => {
  const { _deleteTable } = useApiTable();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({ tableSlug }: { tableSlug: string }) => {
      return _deleteTable({
        teamSlug,
        databaseId,
        tableSlug,
      });
    },
    onSuccess: () => {
      const allTableKeys = tablesKeys.all({ databaseId, teamSlug });
      const allViewKeys = viewKeys.all({ databaseId, teamSlug });

      broadcast(allTableKeys);
      broadcast(allViewKeys);

      return invalidateAll(client, allTableKeys, allViewKeys);
    },
  };
};

export { useDeleteTableMutation };
