import {
  DashboardAllSimpleFieldListResponse,
  RecordConditionRuleUnit,
  RecordSortConditionUnit,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import {
  FlatFilterCondition,
  isFlatFilterConditionAnd,
} from "~/features/Fields/FilterCondition/flatFilterConditionTypes";
import { FlatFilterConditionForm } from "~/features/Fields/FilterCondition/FlatFilterConditionForm";
import { SortConditionForm } from "~/features/Fields/SortCondition/SortConditionForm";
import { FormSection } from "./atoms/FormSection";
import { ViewConditionFieldSelect } from "./FieldSelect/ViewConditionFieldSelect";
import { ViewMetaForm, ViewMetaFormState } from "./ViewMetaForm";
import { Text } from "~/components_next/Text";
import { isArrayWithAtLeastOneElement } from "~/utils/commonTypeGuards";
import { useCallback } from "react";
import { match, P } from "ts-pattern";
import { Flex } from "~/components_next/Flex";

type ViewWithQueryConditionsFormValueType = {
  meta: ViewMetaFormState;
  join: RecordConditionRuleUnit[];
  selectedFields: SimpleField[];
  filter: FlatFilterCondition | null;
  sort: RecordSortConditionUnit | null;
};

// selectionの更新は、join先とmainTableで異なる扱いをすることがある
// 便利のために、formの値に加えてmainTableから選択されたフィールドを追加で渡す
// 更新がなければ、selectedFieldsFromMainTableはundefined
type ViewWithQueryConditionsUploadPayload = {
  formValue: ViewWithQueryConditionsFormValueType;
  additionalData: {
    selectedFieldsFromMainTable?: SimpleField[];
  };
};

type ViewWithQueryConditionsFormProps = {
  mainTableSlug: string;
  allFields: DashboardAllSimpleFieldListResponse["tables"];
  value: ViewWithQueryConditionsFormValueType;
  onChange: (value: ViewWithQueryConditionsUploadPayload) => void;
};

const ViewWithQueryConditionsForm = (
  props: ViewWithQueryConditionsFormProps
) => {
  const { mainTableSlug, allFields, value, onChange } = props;

  const handleFilterChange = useCallback(
    (filter: FlatFilterCondition | null) => {
      // filterに変更があった場合、selectに全てのkeyを必ず含める(なければ自動的に追加する)
      const filterKeys = !filter
        ? []
        : isFlatFilterConditionAnd(filter)
        ? filter.and.map(({ key }) => key)
        : filter.or.map(({ key }) => key);

      const selectFieldNames = value.selectedFields.map(({ name }) => name);

      const mainTableFields =
        allFields.find(({ tableSlug }) => tableSlug === mainTableSlug)
          ?.fields || [];

      const missingFields = mainTableFields.filter(
        ({ name }) =>
          filterKeys.includes(name) && !selectFieldNames.includes(name)
      );

      const updatedSelectedFields = [...value.selectedFields, ...missingFields];

      onChange({
        formValue: { ...value, filter, selectedFields: updatedSelectedFields },
        additionalData: {},
      });
    },
    [allFields, mainTableSlug, onChange, value]
  );

  const handleSortChange = useCallback(
    (sort: RecordSortConditionUnit | null) => {
      match([value, sort])
        .with([{ meta: { type: "kanban" } }, P.not(P.nullish)], ([value]) => {
          // Kanban Viewでsortが存在する場合はsortableをfalseにする
          onChange({
            formValue: {
              ...value,
              meta: {
                ...value.meta,
                reorderable: false,
              },
              sort,
            },
            additionalData: {},
          });
        })
        .otherwise(() => {
          onChange({
            formValue: { ...value, sort },
            additionalData: {},
          });
        });
    },
    [onChange, value]
  );

  if (!isArrayWithAtLeastOneElement(value.selectedFields)) {
    return (
      <Flex align="center" justify="center" py="5">
        <Text>One field is required at least.</Text>;
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap="8">
      <ViewMetaForm
        value={value.meta}
        onChange={(meta) =>
          onChange({ formValue: { ...value, meta }, additionalData: {} })
        }
        groupByFieldOptions={value.selectedFields}
      />
      <FormSection label="Fields">
        <ViewConditionFieldSelect
          mainTableSlug={mainTableSlug}
          allFields={allFields}
          join={value.join}
          selectedFields={value.selectedFields}
          filter={value.filter}
          onChange={({ join, selectedFields, selectedFieldsFromMainTable }) => {
            onChange({
              formValue: { ...value, selectedFields, join },
              additionalData: { selectedFieldsFromMainTable },
            });
          }}
          groupByField={
            "groupedByField" in value.meta
              ? value.meta.groupedByField
              : undefined
          }
        />
      </FormSection>
      <>
        <FormSection label="Filters">
          <FlatFilterConditionForm
            simpleFields={value.selectedFields}
            flatFilterCondition={value.filter}
            onChangeFlatFilterCondition={handleFilterChange}
          />
        </FormSection>
        <FormSection label="Sort">
          <SortConditionForm
            simpleFields={value.selectedFields}
            sortCondition={value.sort}
            onChangeSortCondition={handleSortChange}
          />
        </FormSection>
      </>
    </Flex>
  );
};

export {
  ViewWithQueryConditionsForm,
  type ViewWithQueryConditionsFormValueType,
  type ViewWithQueryConditionsUploadPayload,
};
