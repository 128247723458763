import { match } from "ts-pattern";
import { IconCommonProps } from "../type";

const iconStyles = {
  gray: "#718096",
  black: "#333132",
} as const;

export const getSizePx = (size: IconCommonProps["size"] = "md"): string => {
  return match(size)
    .with("xs", () => "10px")
    .with("sm", () => "12px")
    .with("md", () => "16px")
    .with("lg", () => "20px")
    .with("xl", () => "24px")
    .exhaustive();
};

export const getColor = (colorType: keyof typeof iconStyles = "gray") => {
  return iconStyles[colorType];
};
