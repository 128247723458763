import { RecordsTableBaseRecord } from "~/components_next/RecordsTableBase/types";
import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil";

const editingRecordFamily = atomFamily<
  RecordsTableBaseRecord | null,
  { viewId: string }
>({
  key: "table-view-editing-record",
  default: null,
});

const useEditingRecord = (viewId: string) => {
  return useRecoilValue(editingRecordFamily({ viewId }));
};

const useSetEditingRecord = (viewId: string) => {
  return useSetRecoilState(editingRecordFamily({ viewId }));
};

export { useEditingRecord, useSetEditingRecord };
