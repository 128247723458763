import { DashboardNotebookCellPromptObject } from "@usemorph/morph-dashboard-types";
import { CanvasCellClientModelUnion } from "../../CanvasCellClientModel";
import { CanvasCellPosition } from "../../CanvasCellClientModel/CanvasCellClientModelBase";
import { CanvasCreateCellClientModel } from "../CanvasCreateCellClientModel";
import { FieldClientModel } from "~/clientModel/fields/field";

export type CreatePromptCellField = {
  field: FieldClientModel;
  chartType?: string;
};

export type CanvasCreatePromptCellDataType = {
  parentCells: CanvasCellClientModelUnion[];
  cellType: "prompt";
  cellName: string;
  source: {
    prompt: DashboardNotebookCellPromptObject;
  };
  settings?: CanvasCellPosition; // 外部から決めてもいいし、なければparentCellsから決める
  xAxisFields?: CreatePromptCellField[];
  yAxisFields?: CreatePromptCellField[];
  xAxisLabel?: string;
  yAxisLabel?: string;
};

const camelCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export class CanvasCreatePromptCellClientModel extends CanvasCreateCellClientModel {
  readonly #data: CanvasCreatePromptCellDataType;

  constructor(data: CanvasCreatePromptCellDataType) {
    super(data);
    this.#data = data;
  }

  get chartType() {
    return this.#data.source.prompt.type;
  }

  get chartTypeLabel() {
    return camelCaseToWords(this.chartType);
  }

  get colorCodes() {
    return this.#data.source.prompt.colorCodes;
  }

  get xAxisFields() {
    return this.#data.xAxisFields;
  }

  get yAxisFields() {
    return this.#data.yAxisFields;
  }

  get xAxisLabel() {
    return this.#data.xAxisLabel;
  }

  get yAxisLabel() {
    return this.#data.yAxisLabel;
  }

  get title() {
    return this.#data.source.prompt.title;
  }

  get prompt() {
    return this.#data.source.prompt.prompt;
  }

  isSameColorCodes(colorCodes: string[]) {
    if (!this.colorCodes) return false;
    return this.colorCodes.join(",") === colorCodes.join(",");
  }

  updateColorCodes(colorCodes: string[]) {
    return new CanvasCreatePromptCellClientModel({
      ...this.#data,
      source: {
        ...this.#data.source,
        prompt: {
          ...this.#data.source.prompt,
          colorCodes,
        },
      },
    });
  }

  updateXAxisFields(fields: CreatePromptCellField[]) {
    return new CanvasCreatePromptCellClientModel({
      ...this.#data,
      xAxisFields: fields,
    });
  }

  updateYAxisFields(fields: CreatePromptCellField[]) {
    return new CanvasCreatePromptCellClientModel({
      ...this.#data,
      yAxisFields: fields,
    });
  }

  updateTitle(title: string) {
    return new CanvasCreatePromptCellClientModel({
      ...this.#data,
      source: {
        ...this.#data.source,
        prompt: {
          ...this.#data.source.prompt,
          title,
        },
      },
    });
  }

  updatePrompt(prompt: string) {
    return new CanvasCreatePromptCellClientModel({
      ...this.#data,
      source: {
        ...this.#data.source,
        prompt: {
          ...this.#data.source.prompt,
          prompt,
        },
      },
    });
  }

  updateXAxisLabel(xAxisLabel: string) {
    return new CanvasCreatePromptCellClientModel({
      ...this.#data,
      xAxisLabel,
    });
  }

  updateYAxisLabel(yAxisLabel: string) {
    return new CanvasCreatePromptCellClientModel({
      ...this.#data,
      yAxisLabel,
    });
  }

  override get requestBody() {
    const requestBody = super.requestBody;
    return {
      ...requestBody,
      source: {
        ...requestBody.source,
        prompt: {
          ...this.#data.source.prompt,
          xAxisObject: this.#data.xAxisFields?.map((f) => ({
            field: f.field.name,
            chartType: f.chartType as DashboardNotebookCellPromptObject["type"], // サーバー駆動にするために無理キャスト
          })),
          yAxisObject: this.#data.yAxisFields?.map((f) => ({
            field: f.field.name,
            chartType: f.chartType as DashboardNotebookCellPromptObject["type"], // サーバー駆動にするために無理キャスト
          })),
          xAxisLabel: this.#data.xAxisLabel,
          yAxisLabel: this.#data.yAxisLabel,
        },
      },
    };
  }
}
