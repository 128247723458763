type ExecuteFunc<PromiseParamType, PromiseReturnType> = (
  params: PromiseParamType
) => Promise<PromiseReturnType>;

interface ExecutableInterface<
  PromiseParamType,
  PromiseReturnType,
  ErrorType = unknown
> {
  status: "success" | "error" | "loading" | "standby";
  data?: PromiseReturnType;
  execute?: ExecuteFunc<PromiseParamType, PromiseReturnType>;
  isExecuting: boolean;
  error?: ErrorType;
}

export class SuccessExecutable<PromiseParamType, PromiseReturnType, ErrorType>
  implements
    ExecutableInterface<PromiseParamType, PromiseReturnType, ErrorType>
{
  status = "success" as const;
  private _data: PromiseReturnType;
  private _execute: ExecuteFunc<PromiseParamType, PromiseReturnType>;

  constructor(
    data: PromiseReturnType,
    execute: ExecuteFunc<PromiseParamType, PromiseReturnType>
  ) {
    this._data = data;
    this._execute = execute;
  }

  get execute(): ExecuteFunc<PromiseParamType, PromiseReturnType> {
    return this._execute;
  }

  get isExecuting(): boolean {
    return false;
  }

  get data(): PromiseReturnType {
    return this._data;
  }

  get error(): undefined {
    return undefined;
  }
}

export class ErrorExecutable<PromiseParamType, PromiseReturnType, ErrorType>
  implements
    ExecutableInterface<PromiseParamType, PromiseReturnType, ErrorType>
{
  status = "error" as const;
  private _error: ErrorType;
  private _execute: ExecuteFunc<PromiseParamType, PromiseReturnType>;

  constructor(
    error: ErrorType,
    execute: ExecuteFunc<PromiseParamType, PromiseReturnType>
  ) {
    this._error = error;
    this._execute = execute;
  }

  get execute(): ExecuteFunc<PromiseParamType, PromiseReturnType> {
    return this._execute;
  }

  get isExecuting(): boolean {
    return false;
  }

  get data(): undefined {
    return undefined;
  }

  get error(): ErrorType {
    return this._error;
  }
}

export class LoadingExecutable<PromiseParamType, PromiseReturnType, ErrorType>
  implements
    ExecutableInterface<PromiseParamType, PromiseReturnType, ErrorType>
{
  status = "loading" as const;
  private _execute: ExecuteFunc<PromiseParamType, PromiseReturnType>;

  constructor(execute: ExecuteFunc<PromiseParamType, PromiseReturnType>) {
    this._execute = execute;
  }

  get execute(): ExecuteFunc<PromiseParamType, PromiseReturnType> {
    return this._execute;
  }

  get isExecuting(): boolean {
    return true;
  }

  get data(): undefined {
    return undefined;
  }

  get error(): undefined {
    return undefined;
  }
}

export class StandbyExecutable<PromiseParamType, PromiseReturnType, ErrorType>
  implements
    ExecutableInterface<PromiseParamType, PromiseReturnType, ErrorType>
{
  status = "standby" as const;
  private _execute: ExecuteFunc<PromiseParamType, PromiseReturnType>;

  constructor(execute: ExecuteFunc<PromiseParamType, PromiseReturnType>) {
    this._execute = execute;
  }

  get execute(): ExecuteFunc<PromiseParamType, PromiseReturnType> {
    return this._execute;
  }

  get isExecuting(): boolean {
    return false;
  }

  get data(): undefined {
    return undefined;
  }

  get error(): undefined {
    return undefined;
  }
}

export type Executable<
  PromiseParamType,
  PromiseReturnType,
  ErrorType = unknown
> =
  | SuccessExecutable<PromiseParamType, PromiseReturnType, ErrorType>
  | ErrorExecutable<PromiseParamType, PromiseReturnType, ErrorType>
  | LoadingExecutable<PromiseParamType, PromiseReturnType, ErrorType>
  | StandbyExecutable<PromiseParamType, PromiseReturnType, ErrorType>;
