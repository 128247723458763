import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil";

const selectedGroupingValue = atomFamily<unknown, { viewId: string }>({
  key: "kanban-view-selected-grouping-value",
  default: null,
});

const useSelectedGroupingValue = (viewId: string) => {
  return useRecoilValue(selectedGroupingValue({ viewId }));
};

const useSetSelectedGroupingValue = (viewId: string) => {
  return useSetRecoilState(selectedGroupingValue({ viewId }));
};

export { useSelectedGroupingValue, useSetSelectedGroupingValue };
