import { CanvasVariableDataType } from "./CanvasVariableClientModel";
import { CanvasVariableClientModelFactory } from "./CanvasVariableClientModelFactory";
import { CanvasVariablesClientModel } from "./CanvasVariablesClientModel";

export class CanvasVariablesClientModelFactory {
  static fromVariableData(variableData: CanvasVariableDataType[]) {
    const canvasVariableClientModels = variableData.map((data) =>
      CanvasVariableClientModelFactory.fromVariableData(data)
    );
    return new CanvasVariablesClientModel(canvasVariableClientModels);
  }
}
