import { IntegrationInputForm } from "@usemorph/morph-dashboard-types";
import { IntegrationDynamicInput } from "./IntegrationDynamicInput";
import { BooleanInput, NumInput, TextInput, SqlInput, DateInput, DefaultInput } from "./IntegrationInput";

interface IntegrationInputsProps {
  templateId: string;
  integrationId: string;
  inputForm: IntegrationInputForm;
  templateAuthId?: string; // dynamic
  requestData: { [key: string]: unknown };
  setRequestData: (value: { [key: string]: unknown }) => void;
}

export const IntegrationInputs = (props: IntegrationInputsProps) => {
  const { templateId, integrationId, inputForm, templateAuthId, requestData, setRequestData } = props

  return (
    <>
      {inputForm.inputs.map((inp) => {
        if (inp.type === "boolean") {
          return (
            <BooleanInput
              key={inp.key}
              requestData={requestData}
              integrationKey={inp.key}
              type={inp.type}
              description={inp.description}
              label={inp.label}
              required={inp.required}
              descritpionUrlLabel={inp.descritpionUrlLabel}
              descritpionUrl={inp.descritpionUrl}
              onChangeValue={({
                integrationKey,
                value,
              }: {
                integrationKey: string;
                value: unknown;
              }) => {
                setRequestData({ ...requestData, [integrationKey]: value });
              }}
            />
          );
        } else if (inp.type === "number") {
          return (
            <NumInput
              key={inp.key}
              requestData={requestData}
              integrationKey={inp.key}
              type={inp.type}
              description={inp.description}
              label={inp.label}
              required={inp.required}
              descritpionUrlLabel={inp.descritpionUrlLabel}
              descritpionUrl={inp.descritpionUrl}
              onChangeValue={({
                integrationKey,
                value,
              }: {
                integrationKey: string;
                value: unknown;
              }) => {
                setRequestData({ ...requestData, [integrationKey]: value });
              }}
            />
          );
        } else if (inp.type === "date") {
          return (
            <DateInput
              key={inp.key}
              requestData={requestData}
              integrationKey={inp.key}
              description={inp.description}
              type={inp.type}
              label={inp.label}
              required={inp.required}
              descritpionUrlLabel={inp.descritpionUrlLabel}
              descritpionUrl={inp.descritpionUrl}
              onChangeValue={({
                integrationKey,
                value,
              }: {
                integrationKey: string;
                value: unknown;
              }) => {
                setRequestData({ ...requestData, [integrationKey]: value });
              }}
            />
          );
        } else if (inp.type === "textarea") {
          return (
            <TextInput
              key={inp.key}
              requestData={requestData}
              integrationKey={inp.key}
              type={inp.type}
              description={inp.description}
              label={inp.label}
              required={inp.required}
              descritpionUrlLabel={inp.descritpionUrlLabel}
              descritpionUrl={inp.descritpionUrl}
              onChangeValue={({
                integrationKey,
                value,
              }: {
                integrationKey: string;
                value: unknown;
              }) => {
                setRequestData({ ...requestData, [integrationKey]: value });
              }}
            />
          );
        } else if (inp.type === "sql") {
          return (
            <SqlInput
              key={inp.key}
              requestData={requestData}
              integrationKey={inp.key}
              type={inp.type}
              description={inp.description}
              label={inp.label}
              required={inp.required}
              descritpionUrlLabel={inp.descritpionUrlLabel}
              descritpionUrl={inp.descritpionUrl}
              onChangeValue={({
                integrationKey,
                value,
              }: {
                integrationKey: string;
                value: unknown;
              }) => {
                setRequestData({ ...requestData, [integrationKey]: value });
              }}
            />
          );
        } else if (inp.type === "dynamic") {
          return (
            <IntegrationDynamicInput
              key={inp.key}
              integrationKey={inp.key}
              templateAuthId={templateAuthId}
              integrationId={integrationId}
              templateId={templateId}
              requestData={requestData}
              dependsOn={inp.dependsOn}
              description={inp.description}
              label={inp.label}
              required={inp.required}
              descritpionUrlLabel={inp.descritpionUrlLabel}
              descritpionUrl={inp.descritpionUrl}
              onChangeValue={({
                integrationKey,
                value,
              }: {
                integrationKey: string;
                value: unknown;
              }) => {
                setRequestData({ ...requestData, [integrationKey]: value });
              }}
            />
          )
        } else {
          return (
            <DefaultInput
              key={inp.key}
              requestData={requestData}
              integrationKey={inp.key}
              type={inp.type}
              description={inp.description}
              label={inp.label}
              required={inp.required}
              descritpionUrlLabel={inp.descritpionUrlLabel}
              descritpionUrl={inp.descritpionUrl}
              onChangeValue={({
                integrationKey,
                value,
              }: {
                integrationKey: string;
                value: unknown;
              }) => {
                setRequestData({ ...requestData, [integrationKey]: value });
              }}
            />
          );
        }
      })}
    </>
  )
}
