import { useState } from "react";
import { LuSparkles } from "react-icons/lu";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { NativeDialog } from "~/components_next/Dialog";
import { Divider } from "~/components_next/Divider";
import { useErrorToast } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { Text } from "~/components_next/Text";
import { TextArea } from "~/components_next/TextArea";
import { styled } from "~/stitches";
import {
  useUseGenerateColorsExecutable,
  useUsePreviewColorsExecutable,
  useUseSaveNewColorCodeExecutable,
} from "../../providers/PlaygroundCreateCellExecutableProvider";
import { StyleChip } from "./StyleChip";
import { Grid } from "~/components_next/Grid";
import { CanvasCellPromptUnitHtml } from "~/presenters/canvas/cellContent/prompt/units/CanvasCellPromptUnitHtml";
import { ScrollArea } from "@radix-ui/themes";

const AIIcon = styled(LuSparkles, {
  color: "$purple10",
});

type GenerateMyColorsDialogProps = {
  isOpen: boolean;
  onIsOpenChange: (isOpen: boolean) => void;
};

const GenerateMyColorsNativeDialog = (props: GenerateMyColorsDialogProps) => {
  const { isOpen, onIsOpenChange } = props;

  const { errorToast } = useErrorToast({});

  const [prompt, setPrompt] = useState("Vivid cool colors");

  const useGenerateNewColorsExecutable = useUseGenerateColorsExecutable();
  const generateNewColorsExecutable = useGenerateNewColorsExecutable();

  const usePreviewColorsExecutable = useUsePreviewColorsExecutable();
  const previewColorsExecutable = usePreviewColorsExecutable();

  const handleGenerateClick = async () => {
    try {
      const colorsResponse = await generateNewColorsExecutable.execute({
        prompt,
      });
      previewColorsExecutable.execute({
        colorCodes: colorsResponse.colorCodes,
      });
    } catch (error) {
      errorToast(error);
    }
  };

  const useSaveNewColorsExecutable = useUseSaveNewColorCodeExecutable();
  const saveNewColorsExecutable = useSaveNewColorsExecutable();

  const handleSaveClick = async () => {
    if (!generateNewColorsExecutable.data) {
      errorToast(new Error("Style is not generated yet."));
      return;
    }

    try {
      await saveNewColorsExecutable.execute({
        colorCodes: generateNewColorsExecutable.data.colorCodes,
      });
      onIsOpenChange(false);
    } catch (error) {
      errorToast(error);
    }
  };

  return (
    <NativeDialog.Root open={isOpen} onOpenChange={onIsOpenChange}>
      <NativeDialog.Content>
        <ScrollArea style={{ maxHeight: "90vh" }}>
          <Flex direction="column" gap="4">
            <Flex gap="2" align="center">
              <AIIcon />
              <div>Generate Colors</div>
            </Flex>
            <Text variant="description">
              Generate new color sets with prompt.
            </Text>
            <TextArea
              variant="primary"
              description="Prompt"
              value={prompt}
              onChange={(event) => setPrompt(event.target.value)}
            />
            <Box>
              <Button
                size="sm"
                variant="secondary"
                onClick={handleGenerateClick}
                isLoading={generateNewColorsExecutable.isExecuting}
              >
                Generate
              </Button>
            </Box>

            <Flex direction="column" gap="4">
              {generateNewColorsExecutable.data && (
                <>
                  <Divider />

                  <Flex direction="column" gap="1">
                    <Text variant="description">Style</Text>
                    <Flex wrap="wrap">
                      <StyleChip
                        isSelected={false}
                        colorCodes={generateNewColorsExecutable.data.colorCodes}
                      />
                    </Flex>
                  </Flex>
                </>
              )}
              {previewColorsExecutable.data && (
                <>
                  <Flex direction="column" gap="1">
                    <Text variant="description">Preview</Text>
                    <Grid columns="3" gap="2">
                      {previewColorsExecutable.data.previews.map(
                        (preview, i) => (
                          <Box key={i} css={{ height: "300px" }}>
                            <CanvasCellPromptUnitHtml
                              html={preview.html}
                              isSelected={true}
                            />
                          </Box>
                        )
                      )}
                    </Grid>
                  </Flex>
                </>
              )}
            </Flex>

            <Flex gap="4">
              <Button
                variant="primary"
                size="sm"
                isDisabled={!generateNewColorsExecutable.data}
                onClick={handleSaveClick}
                isLoading={saveNewColorsExecutable.isExecuting}
              >
                Save
              </Button>
              <Button
                variant="tertiary"
                size="sm"
                onClick={() => onIsOpenChange(false)}
              >
                Cancel
              </Button>
            </Flex>
          </Flex>
        </ScrollArea>
      </NativeDialog.Content>
    </NativeDialog.Root>
  );
};

export { GenerateMyColorsNativeDialog };
