import { BsPlus } from "react-icons/bs";
import { Button } from "~/components_next/Button";
import {
  SimpleDropdownMenu,
  DropdownMenu,
} from "~/components_next/DropdownMenu";
import { Box } from "@radix-ui/themes";
import { match, P } from "ts-pattern";
import { LuSparkles, LuUpload } from "react-icons/lu";
import { Flex } from "~/components_next/Flex";

type CreateRecordButtonType = "createRecord" | "bulkInsert" | "freeform";

type CreateRecordButtonProps = {
  onClick: (eventType: CreateRecordButtonType) => void;
  buttonTypes?: CreateRecordButtonType[];
};

const CreateRecordButton = ({
  onClick,
  buttonTypes,
}: CreateRecordButtonProps) => {
  const showMenu = (type: CreateRecordButtonType) => {
    return match(buttonTypes)
      .with(P.nullish, () => true)
      .otherwise((menus) => menus.includes(type));
  };

  return (
    <SimpleDropdownMenu
      trigger={
        <Button variant="secondary" size="sm">
          <BsPlus />
          Create Record
        </Button>
      }
    >
      <Box>
        {showMenu("createRecord") && (
          <DropdownMenu.Item onClick={() => onClick("createRecord")}>
            <Flex align="center" gap="2">
              <BsPlus />
              Create a record
            </Flex>
          </DropdownMenu.Item>
        )}
        {showMenu("freeform") && (
          <DropdownMenu.Item onClick={() => onClick("freeform")}>
            <Flex align="center" gap="2">
              <LuSparkles />
              AI Form
            </Flex>
          </DropdownMenu.Item>
        )}
        {showMenu("bulkInsert") && (
          <DropdownMenu.Item onClick={() => onClick("bulkInsert")}>
            <Flex align="center" gap="2">
              <LuUpload />
              Upload CSV / Excel
            </Flex>
          </DropdownMenu.Item>
        )}
      </Box>
    </SimpleDropdownMenu>
  );
};

export { CreateRecordButton, type CreateRecordButtonType };
