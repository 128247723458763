import { useState } from "react";
import { BsArrowClockwise } from "react-icons/bs";
import { Executable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import {
  CalculationFieldTypeClientModel,
  FieldTypeClientModel,
  GenerateTextFieldTypeClientModel,
} from "~/clientModel/fields/field/fieldType";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { TablesClientModel } from "~/clientModel/tables";
import { TableClientModel } from "~/clientModel/tables/table";
import { Flex } from "~/components_next/Flex";
import { IconButton } from "~/components_next/IconButton";
import { Spinner } from "~/components_next/Spinner";
import { Text } from "~/components_next/Text";
import { FlatFieldTypeInput } from "../../../FlatFieldTypeInput";
import { MergeKeyInput } from "../common/mergeKeyInput/MergeKeyInput";
import { PromptInput } from "../common/PromptInput";
import { TargetTableInput } from "../common/TargetTableInput";

// synced value type以外はGenerate TextとCalculationで共通

export type GenerateTextAndCalculationCommonSettingsInputProps<
  T extends readonly FieldTypeClientModel[]
> = {
  table: TableClientModel;
  fields: FieldsClientModel;
  editingFieldsForUpdateMergeKey?: FieldsClientModel; // read only時不要
  field: FieldClientModel;
  fieldType: GenerateTextFieldTypeClientModel | CalculationFieldTypeClientModel;
  onFieldChange: (type: FieldClientModel) => void;
  onEditingFieldsForUpdateMergeKeyChange?: (fields: FieldsClientModel) => void; // read only時不要
  tables: TablesClientModel;
  useFieldsLoadable: UseLoadable<{ tableSlug: string }, FieldsClientModel>;
  generateSqlFromPromptExecutable?: Executable<
    {
      prompt: string;
      creatingFieldName: string;
      margeFieldName: string;
      mergeTargetTableSlug: string;
    },
    { sql: string }
  >; // read only時不要
  syncValueTypeOptions: T;
  onSyncValueTypeChange: (syncedValueType: T[number]) => void;
  isReadOnly?: boolean;
};

export const GenerateTextAndCalculationCommonSettingsInput = <
  T extends readonly FieldTypeClientModel[]
>(
  props: GenerateTextAndCalculationCommonSettingsInputProps<T>
) => {
  const {
    table,
    fields,
    editingFieldsForUpdateMergeKey,
    field,
    fieldType,
    onFieldChange,
    onEditingFieldsForUpdateMergeKeyChange,
    generateSqlFromPromptExecutable,
    tables,
    useFieldsLoadable,
    syncValueTypeOptions,
    onSyncValueTypeChange,
    isReadOnly,
  } = props;

  const [prompt, setPrompt] = useState(fieldType.sql ?? "");

  const handleTargetChange = (targetTableSlug: string) => {
    onFieldChange(
      field.updateType(fieldType.updateSyncTargetTableSlug(targetTableSlug))
    );
  };

  const updateSql = async () => {
    // sqlの生成に必要なものが全て入力された時に、SQLを生成する

    if (
      generateSqlFromPromptExecutable &&
      field.name &&
      prompt &&
      editingFieldsForUpdateMergeKey &&
      editingFieldsForUpdateMergeKey.mergeKeyField &&
      fieldType.syncTargetTableSlug
    ) {
      onFieldChange(field.updateType(fieldType.updateSql("")));
      const { sql } = await generateSqlFromPromptExecutable.execute({
        prompt,
        creatingFieldName: field.name,
        margeFieldName: editingFieldsForUpdateMergeKey.mergeKeyField.name,
        mergeTargetTableSlug: fieldType.syncTargetTableSlugOrThrow,
      });
      onFieldChange(field.updateType(fieldType.updateSql(sql)));
    }
  };

  const targetTable = fieldType.syncTargetTableSlug
    ? tables.findTableByTableSlug(fieldType.syncTargetTableSlug) ?? null
    : null;

  return (
    <Flex direction="column" gap="6">
      <TargetTableInput
        value={fieldType.syncTargetTableSlug}
        onChange={handleTargetChange}
        tables={tables}
        isReadOnly={isReadOnly}
      />

      {targetTable && (
        <Flex direction="column" gap="2">
          <PromptInput
            prompt={prompt}
            onPromptChange={setPrompt}
            useTypeaheadFieldsLoadable={() =>
              useFieldsLoadable({ tableSlug: targetTable.tableSlug })
            }
            onBlur={updateSql}
            isReadOnly={isReadOnly}
          />
          {generateSqlFromPromptExecutable?.isExecuting && (
            <Flex gap="2">
              <Spinner size="xs" />
              <Text variant="description">
                Generating SQL from your prompt...
              </Text>
            </Flex>
          )}
          {generateSqlFromPromptExecutable?.status === "error" && (
            <Flex gap="2" align="center">
              <IconButton
                onClick={updateSql}
                tooltip="Retry"
                icon={<BsArrowClockwise />}
              />

              <Text variant="errorMessage" style={{ fontSize: "14px" }}>
                Failed to generate SQL from your prompt. Please try again.
              </Text>
            </Flex>
          )}
        </Flex>
      )}

      {targetTable && (
        <FlatFieldTypeInput
          value={fieldType.syncedValueType}
          onChange={onSyncValueTypeChange}
          options={syncValueTypeOptions}
          label="Synced Value Type"
          size="sm"
          isReadOnly={isReadOnly}
        />
      )}

      {targetTable && (
        <MergeKeyInput
          table={table}
          fields={editingFieldsForUpdateMergeKey ?? fields}
          targetTable={targetTable}
          useFieldsLoadable={useFieldsLoadable}
          onFieldsChange={onEditingFieldsForUpdateMergeKeyChange}
          isReadOnly={isReadOnly}
          onMergeKeyUpdated={updateSql}
        />
      )}
    </Flex>
  );
};
