import { useMutation, useQueryClient } from "react-query";
import { P, match } from "ts-pattern";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import {
  CanvasCellClientModelFactory,
  CanvasCellViewClientModel,
} from "~/clientModel/canvas/CanvasCellClientModel";
import { useComposeExecutable } from "~/clientModel/executable";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import { useUpdateNotebookCellMutaiton } from "~/serverStateStore";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { viewKeys } from "~/serverStateStore/views";

const useUpdateCellExecutable = (props: {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
  onUpdateLiveCell: (cell: CanvasCellClientModelUnion) => void;
}) => {
  const { teamSlug, databaseId, notebookId, onUpdateLiveCell } = props;

  /**
   * Custom Invalidation
   */
  const client = useQueryClient();
  const broadcast = useBroadcastQueryInvalidate();

  const updateCellMutationOptions = useUpdateNotebookCellMutaiton({
    teamSlug,
    databaseId,
    notebookId,
  });

  return useComposeExecutable(
    useMutation({
      ...updateCellMutationOptions,
      mutationFn: async ({
        cell,
        shouldGenerate,
      }: {
        cell: CanvasCellClientModelUnion;
        shouldGenerate?: boolean;
      }) => {
        const rawResponse = await updateCellMutationOptions.mutationFn({
          cellId: cell.cellId,
          cellType: cell.cellType,
          source: cell.source,
          isPublic: cell.isPublic,
          shouldGenerate: shouldGenerate,
        });
        // この処理ではsettingsが返ってこないので埋める
        return CanvasCellClientModelFactory.fromNotebookCellResponse({
          ...rawResponse,
          settings: cell.settings,
        });
      },
      onSuccess: (updatedCell) => {
        const viewCellInvalidations = match(updatedCell)
          .with(
            P.instanceOf(CanvasCellViewClientModel),
            (cell: CanvasCellViewClientModel) =>
              [cell.viewId].filter((viewId): viewId is string => !!viewId)
          )
          .otherwise(() => []);

        return Promise.all([
          ...viewCellInvalidations.map((viewId) => {
            const viewCellKeys = viewKeys.allQueryViewRecords({
              teamSlug,
              databaseId,
              viewId,
            });
            const viewFieldKeys = viewKeys.getViewFields({
              teamSlug,
              databaseId,
              viewId,
            });

            return Promise.all([
              broadcast(viewCellKeys),
              broadcast(viewFieldKeys),
              invalidateAll(client, viewCellKeys),
              invalidateAll(client, viewFieldKeys),
            ]);
          }),
          onUpdateLiveCell(updatedCell),
          updateCellMutationOptions.onSuccess(),
        ]);
      },
    })
  );
};

export const createUseUpdateCellExecutable = (props: {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
  onUpdateLiveCell: (cell: CanvasCellClientModelUnion) => void;
}) => {
  const { teamSlug, databaseId, notebookId, onUpdateLiveCell } = props;

  return () =>
    useUpdateCellExecutable({
      teamSlug,
      databaseId,
      notebookId,
      onUpdateLiveCell,
    });
};
