import useApiTable from "~/api/useApiTable";
import { tablesKeys } from "../keys";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

type UseRunNormalizedTableMutationParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useRunNormalizedTableMutation = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseRunNormalizedTableMutationParams) => {
  const { _runNormalizedTable } = useApiTable();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: () => {
      return _runNormalizedTable({
        teamSlug,
        databaseId,
        tableSlug,
      });
    },
    onSuccess: () => {
      const keys = tablesKeys.listNormalizedTableSchedule({
        databaseId,
        teamSlug,
      });
      broadcast(keys);
      return invalidateAll(client, keys);
    },
  };
};

export { useRunNormalizedTableMutation };
