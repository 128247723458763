import { useCallback } from "react";
import { match } from "ts-pattern";
import { FieldsClientModel } from "~/clientModel/fields";
import { Loadable } from "~/clientModel/loadable";
import { FilterConditionsClientModel } from "~/clientModel/queryConditions/filterConditions";
import { PaginationClientModel } from "~/clientModel/queryConditions/pagination";
import { SortConditionsClientModel } from "~/clientModel/queryConditions/sortConditions";
import { RecordsClientModel } from "~/clientModel/records";
import { ClearAllFiltersAndSortsButton } from "~/features/SourceAndViews/common/components/QueryMode/Buttons/ClearAllFiltersAndSortsButton";
import { TablePaginationLimit } from "~/presenters/sourceAndViews/common/components/bottomItems/TablePagination";
import { FiltersAndSortsDropdown } from "~/presenters/sourceAndViews/common/components/queryToolbar/filtersAndSorts/FiltersAndSortsDropdown";
import { QueryToolbarLayout } from "~/presenters/sourceAndViews/common/components/queryToolbar/layout/QueryToolbarLayout";

export type PublicCanvasTableViewQueryConditions = {
  filterConditions: FilterConditionsClientModel;
  sortConditions: SortConditionsClientModel;
  pagination: PaginationClientModel<TablePaginationLimit>;
};

type PublicCanvasTableViewToolbarProps = {
  // loadable
  recordsLoadable: Loadable<RecordsClientModel>;
  fieldsLoadable: Loadable<FieldsClientModel>;

  // data
  queryConditions: PublicCanvasTableViewQueryConditions;
  onQueryConditionsChange: (
    queryConditions: PublicCanvasTableViewQueryConditions
  ) => void;
};

const PublicCanvasTableViewToolbar = (
  props: PublicCanvasTableViewToolbarProps
) => {
  const {
    recordsLoadable,
    fieldsLoadable,
    // data
    queryConditions,
    onQueryConditionsChange,
  } = props;

  const { filterConditions, sortConditions, pagination } = queryConditions;

  /**
   * Query condtions handlers
   */

  const handleFilterConditionsChange = useCallback(
    (filterConditions: FilterConditionsClientModel) => {
      match([
        filterConditions.hasFilterConditions,
        sortConditions.hasSortConditions,
      ])
        .with([false, false], () => {
          onQueryConditionsChange({
            ...queryConditions,
            filterConditions,
          });
        })
        .otherwise(() => {
          onQueryConditionsChange({
            ...queryConditions,
            filterConditions,
          });
        });
    },
    [onQueryConditionsChange, queryConditions, sortConditions.hasSortConditions]
  );

  const handleSortConditionsChange = useCallback(
    (sortConditions: SortConditionsClientModel) => {
      match([
        filterConditions.hasFilterConditions,
        sortConditions.hasSortConditions,
      ])
        .with([false, false], () => {
          onQueryConditionsChange({
            ...queryConditions,
            sortConditions,
          });
        })
        .otherwise(() => {
          onQueryConditionsChange({
            ...queryConditions,
            sortConditions,
          });
        });
    },
    [
      filterConditions.hasFilterConditions,
      onQueryConditionsChange,
      queryConditions,
    ]
  );

  return (
    <QueryToolbarLayout>
      <FiltersAndSortsDropdown
        fieldsLoadable={fieldsLoadable}
        filterConditions={filterConditions}
        sortConditions={sortConditions}
        onFilterConditionsChange={handleFilterConditionsChange}
        onSortConditionsChange={handleSortConditionsChange}
      />
      <ClearAllFiltersAndSortsButton
        onClick={() => {
          onQueryConditionsChange({
            ...queryConditions,
            filterConditions: filterConditions.clearAllFilterConditions(),
            sortConditions: sortConditions.clearAllSortConditions(),
          });
        }}
      />
    </QueryToolbarLayout>
  );
};

export { PublicCanvasTableViewToolbar };
