import constate from "constate";
import { useState } from "react";
import { useColorModeStorage, ColorMode } from "~/hooks/useStorage";

const _useColorMode = () => {
  const { getColorModeFromStorage, setColorModeToStorage } =
    useColorModeStorage();

  const recoveredFromStorage = getColorModeFromStorage();

  const getCurrentThemeIsDark = () =>
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  const [colorMode, _setColorMode] = useState<ColorMode>(
    recoveredFromStorage || (getCurrentThemeIsDark() ? "dark" : "light")
  );

  const setColorMode = (colorMode: ColorMode) => {
    _setColorMode(colorMode);
    setColorModeToStorage(colorMode);
  };

  return {
    colorMode,
    setColorMode,
  };
};

export const [ColorModeProvider, useColorMode, useSetColorMode] = constate(
  _useColorMode,
  (value) => value.colorMode,
  (value) => value.setColorMode
);
