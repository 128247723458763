import { SmartFieldFunctionObject } from "@usemorph/morph-dashboard-types";
import { SmartFunctionInputFormClientModel } from "./smartFunctionInputForm";

export type SmartFunctionValueType = Exclude<
  SmartFieldFunctionObject["fieldType"],
  | "richText"
  | "reference"
  | "multiReference"
  | "embeddings"
  | "python"
  | "rowInfo"
>;

type SmartFunctionClientModelData = {
  functionId: string;
  functionName: string;
  description: string;
  valueType: SmartFunctionValueType;
  inputForm: SmartFunctionInputFormClientModel;
  consumedCredits: number;
};

export class SmartFunctionClientModel {
  public constructor(readonly data: SmartFunctionClientModelData) {}

  public get functionId(): string {
    return this.data.functionId;
  }

  public get label(): string {
    return this.data.functionName;
  }

  public get description(): string {
    return this.data.description;
  }

  public get valueType(): SmartFunctionValueType {
    return this.data.valueType;
  }

  public get inputForm(): SmartFunctionInputFormClientModel {
    return this.data.inputForm;
  }

  public get consumedCredits(): number {
    return this.data.consumedCredits;
  }
}
