import { Flex } from "@radix-ui/themes";
import { BsArrowClockwise } from "react-icons/bs";
import { IconButton } from "~/components_next/IconButton";
import { styled } from "~/stitches";

type HTMLPreviewToolbarProps = {
  onReload: () => void;
};

const ToolbarContainer = styled(Flex, {
  backgroundColor: "$bg3",
});

const HTMLPreviewToolbar = (props: HTMLPreviewToolbarProps) => {
  const { onReload } = props;

  return (
    <ToolbarContainer p="1" gap="1">
      <IconButton
        tooltip="Reload"
        size="sm"
        onClick={onReload}
        icon={<BsArrowClockwise />}
      />
    </ToolbarContainer>
  );
};

export { HTMLPreviewToolbar };
