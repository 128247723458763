import { Switch as RadixThemeSwitch } from "@radix-ui/themes";
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from "react";
import { match } from "ts-pattern";
import { MorphThemeSize } from "../commonProps.type";

type RadixSwitchProps = ComponentPropsWithoutRef<typeof RadixThemeSwitch>;

type SwitchProps = {
  size?: MorphThemeSize;
  isChecked?: boolean;
  isDisabled?: boolean;
  onCheckedChange?: RadixSwitchProps["onCheckedChange"];
};

const getSize = (size?: MorphThemeSize) => {
  return match(size)
    .with("xs", () => "1" as RadixSwitchProps["size"])
    .with("sm", () => "2" as RadixSwitchProps["size"])
    .with("md", () => "3" as RadixSwitchProps["size"])
    .with("lg", () => "3" as RadixSwitchProps["size"])
    .with("xl", () => "3" as RadixSwitchProps["size"])
    .otherwise(() => "2" as RadixSwitchProps["size"]);
};

const _Switch = (props: SwitchProps, ref: ForwardedRef<HTMLButtonElement>) => {
  const { size = "md", isChecked, isDisabled, onCheckedChange } = props;

  const radixSwitchSize = getSize(size);

  return (
    <RadixThemeSwitch
      ref={ref}
      checked={isChecked}
      disabled={isDisabled}
      onCheckedChange={onCheckedChange}
      size={radixSwitchSize}
      color="gray"
      radius="full"
      highContrast
    />
  );
};

const Switch = forwardRef(_Switch);

export { Switch, type SwitchProps };
