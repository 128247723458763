import { match, P } from "ts-pattern";
import { Executable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { RecordsClientModel } from "~/clientModel/records";
import { TablesClientModel } from "~/clientModel/tables";
import { TableClientModel } from "~/clientModel/tables/table";
import { AggregateValueFieldOperatorsClientModel } from "~/clientModel/fields/field/fieldType/computed/aggregateValue/aggregateValueFieldOperators";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";
import {
  FieldTypeFormLayout,
  FieldTypeSelectRoot,
  TextFieldsTypeSubSelect,
  SelectFieldsTypeSubSelect,
  NumberFieldsTypeSubSelect,
  OtherPrimitiveFieldsTypeSubSelect,
  DateFieldsTypeSubSelect,
  FileFieldsTypeSubSelect,
  SmartFunctionFieldSubSelect,
  ComputedFieldsTypeSubSelect,
  TypeConfigurationWrapper,
  FormulaInput,
  SingleSelectMembersInput,
  MultiSelectMembersInput,
  SyncValueSettingsInput,
  SmartFunctionSettingsInput,
  StructuredDataFieldsTypeSubSelect,
  AggregateValueSettingsInput,
  CalculationSettingsInput,
  GenerateTextSettingsInput,
  SmartFunctionFieldCreditConsumption,
  ComputedFieldCreditConsumption,
} from "../../components";

type FieldTypeInputProps = {
  table: TableClientModel;
  field: FieldClientModel;
  fields: FieldsClientModel;
  editingFieldsForUpdateMergeKey: FieldsClientModel;
  tables: TablesClientModel;
  aggregateValueFieldOperators: AggregateValueFieldOperatorsClientModel;
  smartFunctions: SmartFunctionsClientModel;
  onFieldChange: (field: FieldClientModel) => void;
  onEditingFieldsForUpdateMergeKeyChange: (fields: FieldsClientModel) => void;
  testFormulaExecutable: Executable<
    { formula: string },
    { records: RecordsClientModel; fields: FieldsClientModel }
  >;
  isReadOnly?: boolean;
  useFieldsLoadable: UseLoadable<{ tableSlug: string }, FieldsClientModel>;
  generateSqlForComputedFieldFromPromptExecutable: Executable<
    {
      prompt: string;
      creatingFieldName: string;
      margeFieldName: string;
      mergeTargetTableSlug: string;
    },
    { sql: string }
  >;
};

export const FieldTypeInput = (props: FieldTypeInputProps) => {
  const {
    table,
    field,
    fields,
    editingFieldsForUpdateMergeKey,
    tables,
    aggregateValueFieldOperators,
    smartFunctions,
    onFieldChange,
    onEditingFieldsForUpdateMergeKeyChange,
    testFormulaExecutable,
    isReadOnly,
    useFieldsLoadable,
    generateSqlForComputedFieldFromPromptExecutable,
  } = props;

  const handleTypeChange = (type: FieldClientModel["type"]) => {
    onFieldChange(field.updateType(type));
  };

  return (
    <FieldTypeFormLayout>
      <FieldTypeSelectRoot
        size="sm"
        fieldType={field.type}
        isReadOnly={isReadOnly}
      >
        <TextFieldsTypeSubSelect onSelect={handleTypeChange} />
        <SelectFieldsTypeSubSelect onSelect={handleTypeChange} />
        <NumberFieldsTypeSubSelect onSelect={handleTypeChange} />
        <OtherPrimitiveFieldsTypeSubSelect onSelect={handleTypeChange} />
        <DateFieldsTypeSubSelect onSelect={handleTypeChange} />
        <FileFieldsTypeSubSelect onSelect={handleTypeChange} />
        <StructuredDataFieldsTypeSubSelect onSelect={handleTypeChange} />
        <SmartFunctionFieldSubSelect
          smartFunctions={smartFunctions}
          onSelect={handleTypeChange}
        />
        <ComputedFieldsTypeSubSelect
          aggregateValueFieldOperators={aggregateValueFieldOperators}
          onSelect={handleTypeChange}
        />
      </FieldTypeSelectRoot>

      <TypeConfigurationWrapper>
        {match(field.type)
          .with({ type: "formula" }, (formulaType) => (
            <FormulaInput
              formulaType={formulaType}
              onFieldTypeChange={(type) =>
                onFieldChange(field.updateType(type))
              }
              testFormulaExecutable={testFormulaExecutable}
              isReadOnly={isReadOnly}
              fieldsForTypeahead={fields}
            />
          ))
          .with({ type: "singleSelect" }, (singleSelectType) => (
            <SingleSelectMembersInput
              singleSelectType={singleSelectType}
              onFieldTypeChange={(type) =>
                onFieldChange(field.updateType(type))
              }
              isReadOnly={isReadOnly}
            />
          ))
          .with({ type: "multiSelect" }, (multiSelectType) => (
            <MultiSelectMembersInput
              multiSelectType={multiSelectType}
              onFieldTypeChange={(type) =>
                onFieldChange(field.updateType(type))
              }
              isReadOnly={isReadOnly}
            />
          ))
          .with({ type: "syncValue" }, (syncValueType) => (
            <SyncValueSettingsInput
              table={table}
              fields={fields}
              field={field}
              editingFieldsForUpdateMergeKey={editingFieldsForUpdateMergeKey}
              syncValueType={syncValueType}
              onFieldChange={onFieldChange}
              onEditingFieldsForUpdateMergeKeyChange={
                onEditingFieldsForUpdateMergeKeyChange
              }
              tables={tables}
              useFieldsLoadable={useFieldsLoadable}
            />
          ))
          .with({ type: "generateText" }, (syncValueType) => (
            <GenerateTextSettingsInput
              table={table}
              fields={fields}
              editingFieldsForUpdateMergeKey={editingFieldsForUpdateMergeKey}
              field={field}
              fieldType={syncValueType}
              onFieldChange={onFieldChange}
              onEditingFieldsForUpdateMergeKeyChange={
                onEditingFieldsForUpdateMergeKeyChange
              }
              generateSqlFromPromptExecutable={
                generateSqlForComputedFieldFromPromptExecutable
              }
              tables={tables}
              useFieldsLoadable={useFieldsLoadable}
            />
          ))
          .with({ type: "calculation" }, (syncValueType) => (
            <CalculationSettingsInput
              table={table}
              fields={fields}
              editingFieldsForUpdateMergeKey={editingFieldsForUpdateMergeKey}
              field={field}
              fieldType={syncValueType}
              onFieldChange={onFieldChange}
              onEditingFieldsForUpdateMergeKeyChange={
                onEditingFieldsForUpdateMergeKeyChange
              }
              generateSqlFromPromptExecutable={
                generateSqlForComputedFieldFromPromptExecutable
              }
              tables={tables}
              useFieldsLoadable={useFieldsLoadable}
            />
          ))
          .with({ type: "aggregateValue" }, (syncValueType) => (
            <AggregateValueSettingsInput
              table={table}
              fields={fields}
              editingFieldsForUpdateMergeKey={editingFieldsForUpdateMergeKey}
              field={field}
              aggregateValueFieldOperators={aggregateValueFieldOperators}
              aggregateValueType={syncValueType}
              onFieldChange={onFieldChange}
              onEditingFieldsForUpdateMergeKeyChange={
                onEditingFieldsForUpdateMergeKeyChange
              }
              tables={tables}
              useFieldsLoadable={useFieldsLoadable}
            />
          ))
          .with({ type: "smartFunction" }, (smartFunctionType) => (
            <SmartFunctionSettingsInput
              field={field}
              fields={fields}
              smartFunctionType={smartFunctionType}
              onFieldChange={onFieldChange}
              smartFunctions={smartFunctions}
            />
          ))
          .otherwise(() => null)}
      </TypeConfigurationWrapper>

      {match(field.type)
        .with(
          {
            type: P.union(
              "syncValue",
              "generateText",
              "calculation",
              "aggregateValue"
            ),
          },
          () => <ComputedFieldCreditConsumption />
        )
        .with({ type: "smartFunction" }, (smartFunctionType) => (
          <SmartFunctionFieldCreditConsumption
            smartFunctionType={smartFunctionType}
            smartFunctions={smartFunctions}
          />
        ))
        .otherwise(() => null)}
    </FieldTypeFormLayout>
  );
};
