import { useMutation } from "react-query";
import { useComposeExecutable } from "~/clientModel/executable";
import { useCheckTableSlugExistMutaiton } from "~/serverStateStore";

export const useCheckTableSlugExist = ({
  teamSlug,
  databaseId,
}: {
  teamSlug: string;
  databaseId: string;
}) => {
  const checkTableSlugExistMutaionOptions = useCheckTableSlugExistMutaiton({
    teamSlug,
    databaseId,
  });
  const checkTableSlugExistMutationResult = useMutation({
    ...checkTableSlugExistMutaionOptions,
    mutationFn: async (tableSlug: string) => {
      const result = await checkTableSlugExistMutaionOptions.mutationFn({
        tableSlug,
      });
      return result;
    },
  });
  const checkTableSlugExistExecutable = useComposeExecutable(
    checkTableSlugExistMutationResult
  );

  return {
    checkTableSlugExistExecutable,
  };
};
