
import { useEffect } from "react";
import { Box } from "~/components_next/Box";
import { Text } from "~/components_next/Text"
import { TableNameInputSet } from "~/features/Table";

interface OutputTableInputProps {
  slug: string;
  description?: string;
  onChange: (
    table: {
      slug: string;
      tableSlug?: string | undefined;
      displayName?: string | undefined;
    },
    tableSlugError?: string
  ) => void;
}

export const OutputTableInput = (props: OutputTableInputProps) => {
  const { slug, description, onChange } = props

  const {
    TableNameInput,
    TableSlugInput,
    tableName,
    tableSlug,
    tableSlugError,
    // onCreate,
  } = TableNameInputSet({
    oldTableName: "",
    oldTableSlug: "",
  });

  useEffect(() => {
    onChange({
      slug,
      tableSlug,
      displayName: tableName,
    }, tableSlugError)
  }, [tableName, tableSlug, tableSlugError])

  return (
    <Box>
      <Box mb="2">
        <Box><Text fontWeight="medium" mr="2">{slug}</Text></Box>
        <Box><Text variant="description">{description}</Text></Box>
      </Box>
      <Box grow="1" mb="2">{TableNameInput}</Box>
      <Box grow="1">{TableSlugInput}</Box>
    </Box>
  )
}
