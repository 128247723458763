import { Flex } from "@radix-ui/themes";
import { useState } from "react";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { Dialog } from "~/components_next/Dialog";
import { IconButton } from "~/components_next/IconButton";
import { Image } from "~/components_next/Image";
import { useDisclosure } from "~/hooks/useDisclosure";
import { styled } from "~/stitches";

type NotebookImageUnitProps = {
  url: string;
};

const ImageElement = styled(Image, {
  margin: "auto",
});

const ImageLargeElement = styled(Image, {
  maxHeight: "60vh",
  maxWidth: "100%",
  margin: "auto",
  objectFit: "contain",
});

const ImageOverlayFlex = styled(Flex, {
  position: "absolute",
  top: 0,
  right: 0,
  backgroundColor: "$slateA-10",
  p: 1,
  borderRadius: "2",
});

const CanvasCellPromptUnitImage = (props: NotebookImageUnitProps) => {
  const { url } = props;
  const { isOpen, setIsOpen, onOpen, onClose } = useDisclosure();
  const [isOnHover, setIsOnHover] = useState(false);
  return (
    <>
      <Box
        position="relative"
        width="100%"
        height="100%"
        onMouseEnter={() => setIsOnHover(true)}
        onMouseLeave={() => setIsOnHover(false)}
      >
        <ImageElement
          css={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
          src={props.url}
        />
        <Box display={isOnHover ? "block" : "none"}>
          <ImageOverlayFlex>
            <IconButton
              size="md"
              icon={<BsBoxArrowUpRight />}
              tooltip="Open image in modal"
              onClick={onOpen}
              variant="inverse"
            />
          </ImageOverlayFlex>
        </Box>
      </Box>

      <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
        <Dialog.Content>
          <Dialog.Body>
            <Flex
              p="4"
              display="flex"
              direction="column"
              align="center"
              gap="4"
            >
              <ImageLargeElement src={url} />
              <Button variant="actionText" onClick={onClose}>
                Close
              </Button>
            </Flex>
          </Dialog.Body>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};

export { CanvasCellPromptUnitImage };
