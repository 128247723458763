import { useMutation, useQuery } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import {
  FieldClientModel,
  FieldClientModelDefactory,
} from "~/clientModel/fields/field";
import { SmartFunctionsClientModelFactory } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";

import { useErrorToast } from "~/components_next/Error";
import {
  useCreateSimpleFieldMutation,
  useListComputedFieldOptionsForSmartFunctionQuery,
} from "~/serverStateStore";

type UseCreateFieldExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useCreateFieldExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseCreateFieldExecutableParams): Executable<
  { field: FieldClientModel },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  // todo: v4移行後、queryClient.ensureQueryDataにする
  const { data: smartFunctions } = useQuery({
    ...useListComputedFieldOptionsForSmartFunctionQuery({ teamSlug }),
    select: (data) =>
      SmartFunctionsClientModelFactory.createFromSmartFieldFunctionListResponse(
        data
      ),
  });

  const createFieldOptions = useCreateSimpleFieldMutation({
    teamSlug,
    databaseId,
    tableSlug,
  });

  return useComposeExecutable(
    useMutation({
      ...createFieldOptions,
      mutationFn: ({ field }: { field: FieldClientModel }) => {
        if (!smartFunctions) {
          throw new Error("Something went wrong. Please try again.");
        }

        const simpleField = FieldClientModelDefactory.toSimpleField(field, {
          smartFunctions,
        });

        return createFieldOptions.mutationFn({
          requestBody: simpleField,
        });
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseCreateFieldExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseCreateFieldExecutableParams) => {
  return () => useCreateFieldExecutable({ teamSlug, databaseId, tableSlug });
};
