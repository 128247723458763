import { SmartFunctionFieldTypeClientModel } from "~/clientModel/fields/field/fieldType";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";
import { SimpleSelect } from "~/components_next/Select";

type SmartFunctionSelectProps = {
  smartFunctionType: SmartFunctionFieldTypeClientModel;
  onSmartFunctionTypeChange: (type: SmartFunctionFieldTypeClientModel) => void;
  smartFunctions: SmartFunctionsClientModel;
  isReadOnly?: boolean;
};

export const SmartFunctionSelect = (props: SmartFunctionSelectProps) => {
  const {
    smartFunctionType,
    onSmartFunctionTypeChange,
    smartFunctions,
    isReadOnly,
  } = props;

  const options = smartFunctions.allSmartFunctions.map((smartFunction) => ({
    value: smartFunction.functionId,
    label: smartFunction.label,
  }));

  return (
    <SimpleSelect
      variant="primary"
      label="Method type"
      isReadOnly={isReadOnly}
      size="sm"
      value={smartFunctionType.smartFunctionId}
      options={options}
      onChange={(value) =>
        onSmartFunctionTypeChange(
          smartFunctionType.updateSmartFunctionId(value!)
        )
      }
    />
  );
};
