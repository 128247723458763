import { useMe } from "~/serverStateStore/me";

import { BiFile } from "react-icons/bi";
import {
  BsCardChecklist,
  BsGear,
  BsLink45Deg,
  BsPersonCircle,
  BsPersonLinesFill,
} from "react-icons/bs";
import { Text } from "~/components_next/Text";
import { MembersAndGuests } from "./members/MembersAndGuests";
import { MyAccount } from "./myAccount/MyAccount";
import { TeamSettings } from "./teamSettings/TeamSettings";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { UserAvatar } from "~/components_next/User";
import { usePreferenceModal } from "./usePreferenceModal";
import { Dialog } from "~/components_next/Dialog";
import { Flex } from "~/components_next/Flex";
import { Box } from "~/components_next/Box";
import { PlanContainer } from "~/containers/plan/PlanContainer";
import { BillingContainer } from "~/containers/billing/BillingContainer";

export const PreferenceModal = () => {
  const teamSlug = useTeamSlug();

  const { data: me } = useMe();

  const { onClose, modalState, onChangeTab } = usePreferenceModal();

  const devModeUrl = `${import.meta.env.VITE_DEV_MODE_BASEURL}/${teamSlug}`;

  return (
    <>
      <Dialog.Root
        open={modalState.isOpen}
        onOpenChange={(el) => {
          if (!el) {
            onClose();
          }
        }}
        size="xl"
      >
        <Dialog.Content>
          <Dialog.Body>
            {modalState.isOpen && (
              <Flex height="100%" width="100%" align="stretch">
                <Box
                  p="3"
                  css={{
                    minWidth: "fit-content",
                    overflowY: "auto",
                    borderRightWidth: 1,
                    alignSelf: 'stretch',
                  }}
                >
                  <>
                    {me && (
                      <Flex align="center" mb="2">
                        <UserAvatar user={{ ...me }} size="sm" />
                        <Box ml="2">
                          <Box>
                            <Text>{me.username}</Text>
                          </Box>
                          <Box mt="-1">
                            <Text variant="description">{me.email}</Text>
                          </Box>
                        </Box>
                      </Flex>
                    )}
                    <Flex
                      onClick={() => {
                        onChangeTab("myAccount");
                      }}
                      hoverable
                      p="2"
                      align="center"
                      css={{
                        borderRadius: "md",
                        backgroundColor:
                          modalState.tab === "myAccount" ? "$slate2" : "",
                      }}
                    >
                      <Box mr="2">
                        <BsPersonCircle color="gray" />
                      </Box>
                      <Text variant="description">My account</Text>
                    </Flex>
                  </>

                  <>
                    <Text variant="subheading">Team</Text>
                    <Flex
                      onClick={() => {
                        onChangeTab("members");
                      }}
                      hoverable
                      p="2"
                      mb="1"
                      align="center"
                      css={{
                        borderRadius: "md",
                        backgroundColor:
                          modalState.tab === "members" ? "$slate2" : "",
                      }}
                    >
                      <Box mr="2">
                        <BsPersonLinesFill color="gray" />
                      </Box>
                      <Text variant="description">Members</Text>
                    </Flex>

                    <Flex
                      onClick={() => {
                        onChangeTab("billing");
                      }}
                      hoverable
                      p="2"
                      mb="1"
                      align="center"
                      css={{
                        borderRadius: "md",
                        backgroundColor:
                          modalState.tab === "billing" ? "$slate2" : "",
                      }}
                    >
                      <Box mr="2">
                        <BiFile color="gray" />
                      </Box>
                      <Text variant="description">Billing</Text>
                    </Flex>

                    <Flex
                      onClick={() => {
                        onChangeTab("plans");
                      }}
                      hoverable
                      p="2"
                      mb="1"
                      align="center"
                      css={{
                        borderRadius: "md",
                        backgroundColor:
                          modalState.tab === "plans" ? "$slate2" : "",
                      }}
                    >
                      <Box mr="2">
                        <BsCardChecklist color="gray" />
                      </Box>
                      <Text variant="description">Plans</Text>
                    </Flex>

                    <Flex
                      onClick={() => {
                        onChangeTab("teamSettings");
                      }}
                      hoverable
                      p="2"
                      mb="1"
                      align="center"
                      css={{
                        borderRadius: "md",
                        backgroundColor:
                          modalState.tab === "teamSettings" ? "$slate2" : "",
                      }}
                    >
                      <Box mr="2">
                        <BsGear color="gray" />
                      </Box>
                      <Text variant="description">Setting</Text>
                    </Flex>

                    <Flex
                      onClick={() => window.open(devModeUrl)}
                      hoverable
                      p="2"
                      mb="1"
                      align="center"
                    >
                      <Box mr="2">
                        <BsLink45Deg color="gray" />
                      </Box>
                      <Text variant="description">Dev mode</Text>
                    </Flex>
                  </>
                </Box>

                <Box grow="1" p="3" style={{ overflow: "auto" }}>
                  {modalState.tab === "myAccount" && <MyAccount />}
                  {modalState.tab === "members" && <MembersAndGuests />}
                  {modalState.tab === "plans" && <PlanContainer />}
                  {modalState.tab === "billing" && <BillingContainer />}
                  {modalState.tab === "teamSettings" && <TeamSettings />}
                </Box>
              </Flex>
            )}
          </Dialog.Body>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};
