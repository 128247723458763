import { useCallback, useMemo, useState } from "react";
import {} from "~/clientModel/tables/tableMeta/tableCoworkersState";
import {
  TableSelectionClientModel,
  TableSelectionClientModelDefactory,
  TableSelectionClientModelFactory,
} from "~/clientModel/tables/tableMeta/TableSelection";
import { useMyPresence } from "~/features/RealtimeCollaboration/liveblocks.config";

export const useSourceTableSelection = () => {
  const [tableSelection, setTableSelection] = useState(
    TableSelectionClientModelFactory.createEmpty()
  );

  const [myPresence, updateMyPresence] = useMyPresence();

  const handleTableSelectionChange = useCallback(
    (tableSelection: TableSelectionClientModel) => {
      setTableSelection(tableSelection);
      updateMyPresence({
        ...myPresence,
        sourceState_1_1:
          TableSelectionClientModelDefactory.serialize(tableSelection),
      });
    },
    [myPresence, updateMyPresence]
  );

  return useMemo(
    () => [tableSelection, handleTableSelectionChange] as const,
    [handleTableSelectionChange, tableSelection]
  );
};
