import { match, P } from "ts-pattern";
import { Executable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { AggregateValueFieldOperatorsClientModel } from "~/clientModel/fields/field/fieldType/computed/aggregateValue/aggregateValueFieldOperators";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { RecordsClientModel } from "~/clientModel/records";
import { TablesClientModel } from "~/clientModel/tables";
import { TableClientModel } from "~/clientModel/tables/table";
import {
  FieldTypeFormLayout,
  FieldTypeSelectRoot,
  TextFieldsTypeSubSelect,
  SelectFieldsTypeSubSelect,
  NumberFieldsTypeSubSelect,
  OtherPrimitiveFieldsTypeSubSelect,
  DateFieldsTypeSubSelect,
  FileFieldsTypeSubSelect,
  TypeConfigurationWrapper,
  FormulaInput,
  SingleSelectMembersInput,
  MultiSelectMembersInput,
  SyncValueSettingsInput,
  SmartFunctionSettingsInput,
  StructuredDataFieldsTypeSubSelect,
  AggregateValueSettingsInput,
  CalculationSettingsInput,
  GenerateTextSettingsInput,
  ComputedFieldCreditConsumption,
  SmartFunctionFieldCreditConsumption,
} from "../../components";

type FieldTypeInputProps = {
  table: TableClientModel;
  field: FieldClientModel;
  fields: FieldsClientModel;
  tables: TablesClientModel;
  aggregateValueFieldOperators: AggregateValueFieldOperatorsClientModel;
  smartFunctions: SmartFunctionsClientModel;
  onFieldChange: (field: FieldClientModel) => void;
  useFieldsLoadable: UseLoadable<{ tableSlug: string }, FieldsClientModel>;
  testFormulaExecutable: Executable<
    { formula: string },
    { records: RecordsClientModel; fields: FieldsClientModel }
  >;
};

export const FieldTypeInput = (props: FieldTypeInputProps) => {
  const {
    table,
    field,
    fields,
    tables,
    aggregateValueFieldOperators,
    smartFunctions,
    onFieldChange,
    testFormulaExecutable,
    useFieldsLoadable,
  } = props;

  const handleTypeChange = (type: FieldClientModel["type"]) => {
    onFieldChange(field.updateType(type));
  };

  return (
    <FieldTypeFormLayout>
      <FieldTypeSelectRoot size="sm" fieldType={field.type}>
        <TextFieldsTypeSubSelect
          onSelect={handleTypeChange}
          options={{ includes: field.type.updatableFieldTypes }}
        />
        <SelectFieldsTypeSubSelect
          onSelect={handleTypeChange}
          options={{ includes: field.type.updatableFieldTypes }}
        />
        <NumberFieldsTypeSubSelect
          onSelect={handleTypeChange}
          options={{ includes: field.type.updatableFieldTypes }}
        />
        <OtherPrimitiveFieldsTypeSubSelect
          onSelect={handleTypeChange}
          options={{ includes: field.type.updatableFieldTypes }}
        />
        <DateFieldsTypeSubSelect
          onSelect={handleTypeChange}
          options={{ includes: field.type.updatableFieldTypes }}
        />
        <FileFieldsTypeSubSelect
          onSelect={handleTypeChange}
          options={{ includes: field.type.updatableFieldTypes }}
        />
        <StructuredDataFieldsTypeSubSelect
          onSelect={handleTypeChange}
          options={{ includes: field.type.updatableFieldTypes }}
        />
      </FieldTypeSelectRoot>
      <TypeConfigurationWrapper>
        {match(field.type)
          .with({ type: "formula" }, (formulaType) => (
            <FormulaInput
              formulaType={formulaType}
              onFieldTypeChange={(type) =>
                onFieldChange(field.updateType(type))
              }
              testFormulaExecutable={testFormulaExecutable}
              fieldsForTypeahead={fields}
            />
          ))
          .with({ type: "singleSelect" }, (singleSelectType) => (
            <SingleSelectMembersInput
              singleSelectType={singleSelectType}
              onFieldTypeChange={(type) =>
                onFieldChange(field.updateType(type))
              }
            />
          ))
          .with({ type: "multiSelect" }, (multiSelectType) => (
            <MultiSelectMembersInput
              multiSelectType={multiSelectType}
              onFieldTypeChange={(type) =>
                onFieldChange(field.updateType(type))
              }
            />
          ))
          .with({ type: "syncValue" }, (syncValueType) => (
            <SyncValueSettingsInput
              table={table}
              fields={fields}
              field={field}
              tables={tables}
              syncValueType={syncValueType}
              onFieldChange={onFieldChange}
              useFieldsLoadable={useFieldsLoadable}
              isReadOnly
            />
          ))
          .with({ type: "generateText" }, (syncValueType) => (
            <GenerateTextSettingsInput
              table={table}
              fields={fields}
              field={field}
              tables={tables}
              fieldType={syncValueType}
              onFieldChange={onFieldChange}
              useFieldsLoadable={useFieldsLoadable}
              isReadOnly
            />
          ))
          .with({ type: "calculation" }, (syncValueType) => (
            <CalculationSettingsInput
              table={table}
              fields={fields}
              field={field}
              tables={tables}
              fieldType={syncValueType}
              onFieldChange={onFieldChange}
              useFieldsLoadable={useFieldsLoadable}
              isReadOnly
            />
          ))
          .with({ type: "aggregateValue" }, (syncValueType) => (
            <AggregateValueSettingsInput
              table={table}
              fields={fields}
              field={field}
              aggregateValueFieldOperators={aggregateValueFieldOperators}
              aggregateValueType={syncValueType}
              onFieldChange={onFieldChange}
              tables={tables}
              useFieldsLoadable={useFieldsLoadable}
              isReadOnly
            />
          ))
          .with({ type: "smartFunction" }, (smartFunctionType) => (
            <SmartFunctionSettingsInput
              field={field}
              fields={fields}
              smartFunctionType={smartFunctionType}
              onFieldChange={onFieldChange}
              smartFunctions={smartFunctions}
              isReadOnly
            />
          ))
          .otherwise(() => null)}
      </TypeConfigurationWrapper>

      {match(field.type)
        .with(
          {
            type: P.union(
              "syncValue",
              "generateText",
              "calculation",
              "aggregateValue"
            ),
          },
          () => <ComputedFieldCreditConsumption />
        )
        .with({ type: "smartFunction" }, (smartFunctionType) => (
          <SmartFunctionFieldCreditConsumption
            smartFunctionType={smartFunctionType}
            smartFunctions={smartFunctions}
          />
        ))
        .otherwise(() => null)}
    </FieldTypeFormLayout>
  );
};
