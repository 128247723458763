import { ScrollArea } from "@radix-ui/themes";
import { useEffect, useMemo, useRef, useState } from "react";
import GridLayout, { Layout } from "react-grid-layout";
import { FindDashboardPageClientModel } from "~/clientModel/canvas/FindDashboardPageClientModel/FindDashboardPageClientModel";
import { DashboardCellCard } from "../../dashboard/cell/common/DashboardCellCard";
import { Box } from "~/components_next/Box";
import { useDebounceState } from "~/hooks/useDebounceState";
import { PublicDashboardPageCell } from "../cell/PublicDashboardPageCell";

type PublicDashboardPageProps = {
  page: FindDashboardPageClientModel;
};

const PublicDashboardPageContent = (
  props: PublicDashboardPageProps & { width: number }
) => {
  const { page, width } = props;

  const layout = useMemo((): Layout[] => page.cells.layouts, [page]);

  return (
    <ScrollArea style={{ height: "calc(100vh - 80px)", paddingRight: "10px" }}>
      <GridLayout
        className="layout"
        cols={12}
        rowHeight={60}
        width={width - 10}
        layout={layout}
        style={{
          marginBottom: "40px",
        }}
        isDraggable={false}
        isDroppable={false}
        isResizable={false}
        resizeHandles={[]}
      >
        {page.cells.cells.map((cell) => {
          return (
            <DashboardCellCard
              key={cell.cellId}
              id={cell.cellId}
              isSelected={false}
            >
              <PublicDashboardPageCell cellClientModel={cell} />
            </DashboardCellCard>
          );
        })}
      </GridLayout>
    </ScrollArea>
  );
};

const PublicDashboardPage = (props: PublicDashboardPageProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [wrapperWidth, setWrapperWidth] = useState<number>(
    window.innerWidth - 300
  );
  const debouncedWidth = useDebounceState(wrapperRef.current?.clientWidth, 250);

  useEffect(() => {
    calcWidth();
  }, [debouncedWidth]);

  const calcWidth = () => {
    if (wrapperRef.current && wrapperRef.current.clientWidth > 0) {
      setWrapperWidth(wrapperRef.current.clientWidth);
    }
  };

  return (
    <Box ref={wrapperRef}>
      <PublicDashboardPageContent
        key={`${props.page.pageId}-${wrapperWidth}`}
        width={wrapperWidth}
        {...props}
      />
    </Box>
  );
};

export { PublicDashboardPage };
