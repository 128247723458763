import { useMutation, useQuery } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import {
  useCreateObjectMutation,
  useFindSourceBucketQuery,
} from "~/serverStateStore";

type UseUploadFileExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useUploadFileExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseUploadFileExecutableParams): Executable<
  { file: File },
  { data: string; url: string }
> => {
  const { status: bucketStatus, data: bucketData } = useQuery(
    useFindSourceBucketQuery({ teamSlug, databaseId, tableSlug })
  );

  const { mutateAsync: createObject } = useMutation(
    useCreateObjectMutation({ teamSlug })
  );

  return useComposeExecutable(
    useMutation({
      mutationFn: async ({
        file,
      }: {
        file: File;
      }): Promise<{ data: string; url: string }> => {
        if (bucketStatus !== "success") {
          throw new Error("Bucket is not found.");
        }

        const bucketId = bucketData.bucketId;
        const bucketName = bucketData.bucketName;
        const timestamp = Date.now();
        const key = `${timestamp}-${file.name}`;

        const { objectName } = await createObject({
          bucketId,
          key,
          file,
        });

        const data = `morph:storage:::${bucketName}/${objectName}`;

        const url = URL.createObjectURL(file);
        return {
          data,
          url,
        };
      },
    })
  );
};

export const createUseUploadFileExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseUploadFileExecutableParams) => {
  return () =>
    useUploadFileExecutable({
      teamSlug,
      databaseId,
      tableSlug,
    });
};
