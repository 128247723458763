import {
  DashboardViewResponse,
  DashboardViewListResponse,
  DashboardCreateViewRequestBody,
  DashboardUpdateViewRequestBody,
  DashboardDuplicateViewRequestBody,
  QueryRecordListResponse,
  GroupQueryViewRecordRequestBody,
  SimpleFieldListResponse,
  CreateRecordRequestBody,
  UpdateViewRecordRequestBody,
  DeleteViewRecordRequestBody,
  GroupQueryRecordListResponse,
  ReorderViewRecordRequestBody,
  QueryViewRecordRequestBody,
  QueryViewRecordWithSqlRequestBody,
  QueryRecordWithFieldsResponse,
  DashboardCreateViewFieldRequestBody,
  DashboardUpdateViewFieldRequestBody,
  DashboardSimpleFieldResponse,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";
import { DashboardGeneralResponse } from "./useApiField";
import {
  transformNumberToStringForGroupQueryViewRecordsResponse,
  transformNumberToStringForQueryRecordListResponse,
  transformNumberToStringForQueryRecordWithFieldsResponse,
} from "./utils/transformers";

export default function useApiView() {
  const { executeRequest } = useApi();

  /**
   * Create(General View)
   */
  const _createView = async ({
    teamSlug,
    body,
  }: {
    teamSlug: string;
    body: DashboardCreateViewRequestBody;
  }): Promise<DashboardViewResponse> => {
    return executeRequest(
      "post",
      `/view`,
      undefined,
      {
        teamSlug,
      },
      body
    );
  };

  const _duplicateView = async ({
    teamSlug,
    viewId,
    body,
  }: {
    teamSlug: string;
    viewId: string;
    body: DashboardDuplicateViewRequestBody;
  }): Promise<DashboardViewResponse> => {
    return executeRequest(
      "post",
      `/view/${viewId}/duplicate`,
      undefined,
      {
        teamSlug,
      },
      body
    );
  };

  /**
   * Read(General View)
   */

  const _findView = async ({
    teamSlug,
    viewId,
  }: {
    teamSlug: string;
    viewId: string;
  }): Promise<DashboardViewResponse> => {
    return executeRequest("get", `/view/${viewId}`, undefined, {
      teamSlug,
    });
  };

  const _listViews = async ({
    teamSlug,
    databaseId,
  }: {
    teamSlug: string;
    databaseId: string;
  }): Promise<DashboardViewListResponse> => {
    return executeRequest(
      "get",
      `/view`,
      { databaseId },
      {
        teamSlug,
      }
    );
  };

  /**
   * Update(General View)
   */

  const _updateView = async ({
    teamSlug,
    viewId,
    body,
  }: {
    teamSlug: string;
    viewId: string;
    body: DashboardUpdateViewRequestBody;
  }): Promise<DashboardViewResponse> => {
    return executeRequest(
      "put",
      `/view/${viewId}`,
      undefined,
      {
        teamSlug,
      },
      body
    );
  };

  /**
   * Delete(General View)
   */

  const _deleteView = async ({
    teamSlug,
    viewId,
  }: {
    teamSlug: string;
    viewId: string;
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest("delete", `/view/${viewId}`, undefined, {
      teamSlug,
    });
  };

  /**
   * Create(View Record)
   */

  const _createViewRecord = async ({
    teamSlug,
    viewId,
    body,
  }: {
    teamSlug: string;
    viewId: string;
    body: CreateRecordRequestBody;
  }): Promise<QueryRecordListResponse> => {
    return executeRequest(
      "post",
      `/view/${viewId}/create-record`,
      undefined,
      { teamSlug },
      body
    );
  };

  /**
   * Read(View Record)
   */
  const _queryViewRecords = async ({
    teamSlug,
    viewId,
    body,
  }: {
    teamSlug: string;
    viewId: string;
    body: QueryViewRecordRequestBody;
  }): Promise<
    QueryRecordListResponse & { fields: DashboardSimpleFieldResponse[] } // typeが更新されたらいらない
  > => {
    return executeRequest(
      "post",
      `/view/${viewId}/query`,
      undefined,
      { teamSlug },
      body,
      {
        transformResponse: [transformNumberToStringForQueryRecordListResponse],
      }
    );
  };

  const _queryViewRecordsWithSQL = async ({
    teamSlug,
    body,
  }: {
    teamSlug: string;
    body: QueryViewRecordWithSqlRequestBody;
  }): Promise<QueryRecordWithFieldsResponse> => {
    return executeRequest(
      "post",
      `/view/query/sql`,
      undefined,
      { teamSlug },
      body,
      {
        transformResponse: [
          transformNumberToStringForQueryRecordWithFieldsResponse,
        ],
      }
    );
  };

  const _groupQueryViewRecords = async ({
    teamSlug,
    viewId,
    body,
  }: {
    teamSlug: string;
    viewId: string;
    body: GroupQueryViewRecordRequestBody;
  }): Promise<GroupQueryRecordListResponse> => {
    return executeRequest(
      "post",
      `/view/${viewId}/group-query`,
      undefined,
      { teamSlug },
      body,
      {
        transformResponse: [
          transformNumberToStringForGroupQueryViewRecordsResponse,
        ],
      }
    );
  };

  /**
   * Update(View Record)
   */

  const _updateViewRecord = async ({
    teamSlug,
    viewId,
    body,
  }: {
    teamSlug: string;
    viewId: string;
    body: UpdateViewRecordRequestBody;
  }): Promise<QueryRecordListResponse> => {
    return executeRequest(
      "post",
      `/view/${viewId}/update-record`,
      undefined,
      { teamSlug },
      body
    );
  };

  const _reorderViewRecord = async ({
    teamSlug,
    viewId,
    body,
  }: {
    teamSlug: string;
    viewId: string;
    body: ReorderViewRecordRequestBody;
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest(
      "post",
      `/view/${viewId}/reorder-record`,
      undefined,
      { teamSlug },
      body
    );
  };

  /**
   * Delete(View Record)
   */
  const _deleteViewRecord = async ({
    teamSlug,
    viewId,
    body,
  }: {
    teamSlug: string;
    viewId: string;
    body: DeleteViewRecordRequestBody;
  }): Promise<QueryRecordListResponse> => {
    return executeRequest(
      "post",
      `/view/${viewId}/delete-record`,
      undefined,
      { teamSlug },
      body
    );
  };

  /**
   * Create(View Fields)
   */
  const _createViewField = async ({
    teamSlug,
    viewId,
    body,
  }: {
    teamSlug: string;
    viewId: string;
    body: DashboardCreateViewFieldRequestBody;
  }): Promise<DashboardViewResponse> => {
    return executeRequest(
      "post",
      `/view/${viewId}/field`,
      undefined,
      {
        teamSlug,
      },
      body
    );
  };

  /**
   * Read(View Fields)
   */
  const _getViewFields = async ({
    teamSlug,
    viewId,
  }: {
    teamSlug: string;
    viewId: string;
  }): Promise<SimpleFieldListResponse> => {
    return executeRequest("get", `/view/${viewId}/field`, undefined, {
      teamSlug,
    });
  };

  /**
   * Update(View Fields)
   */
  const _updateViewField = async ({
    teamSlug,
    viewId,
    body,
  }: {
    teamSlug: string;
    viewId: string;
    body: DashboardUpdateViewFieldRequestBody;
  }): Promise<DashboardViewResponse> => {
    return executeRequest(
      "put",
      `/view/${viewId}/field`,
      undefined,
      {
        teamSlug,
      },
      body
    );
  };

  /**
   * Delete(View Fields)
   */
  const _deleteViewField = async ({
    teamSlug,
    viewId,
    fieldName,
  }: {
    teamSlug: string;
    viewId: string;
    fieldName: string;
  }): Promise<DashboardViewResponse> => {
    return executeRequest(
      "delete",
      `/view/${viewId}/field/${fieldName}`,
      undefined,
      {
        teamSlug,
      }
    );
  };

  // csv download
  const _downloadRecordsCSV = async ({
    teamSlug,
    viewId,
    body,
  }: {
    teamSlug: string;
    viewId: string;
    body: QueryViewRecordRequestBody;
  }): Promise<{ url: string }> => {
    return executeRequest(
      "post",
      `/view/${viewId}/query/csv`,
      undefined,
      { teamSlug },
      body
    );
  };

  return {
    // View
    _createView,
    _duplicateView,
    _findView,
    _listViews,
    _updateView,
    _deleteView,

    // View Record
    _createViewRecord,
    _queryViewRecords,
    _queryViewRecordsWithSQL,
    _groupQueryViewRecords,
    _updateViewRecord,
    _reorderViewRecord,
    _deleteViewRecord,

    // View Fields
    _createViewField,
    _getViewFields,
    _updateViewField,
    _deleteViewField,

    // csv download
    _downloadRecordsCSV,
  };
}
