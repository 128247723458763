import { BsDownload } from "react-icons/bs";
import { IconButton } from "~/components_next/IconButton/IconButton";

type FileDownloadButtonProps = {
  url: string;
  size?: "xs" | "sm" | "md";
};

export const FileDownloadButton = ({ url, size }: FileDownloadButtonProps) => {
  const handleDownload = () => {
    window.open(url, "_blank");
  };

  return (
    <IconButton
      size={size}
      tooltip="Download File"
      icon={<BsDownload />}
      onClick={handleDownload}
    />
  );
};
