import { useCallback, useState } from "react";
import { match, P } from "ts-pattern";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { useLoadableState } from "~/clientModel/loadable";
import { RecordClientModel } from "~/clientModel/records/record";
import { Flex } from "~/components_next/Flex";
import { Spacer } from "~/components_next/Spacer";
import { BottomItemsLayout } from "../common/components/bottomItems/BottomItemsLayout";
import { CreateRecordButton } from "../common/components/bottomItems/CreateRecordButton";
import { DownloadButton } from "../common/components/bottomItems/DownloadButton";
import { TablePagination } from "../common/components/bottomItems/TablePagination";
import { DeleteSelectedRecordsButton } from "../common/components/floatingItems/DeleteSelectedRecordsButton";
import { FloatingItemsLayout } from "../common/components/floatingItems/FloatingItemsLayout";
import { ClearAllFiltersAndSortsButton } from "../common/components/queryToolbar/buttons/ClearAllFiltersAndSortsButton";
import { ClearPromptButton } from "../common/components/queryToolbar/buttons/ClearPromptButton";
import { ClearSQLButton } from "../common/components/queryToolbar/buttons/ClearSQLButton";
import { FieldsSettingDropdown } from "../common/components/queryToolbar/filedsSetting/FieldsSettingDropdown";
import { FiltersAndSortsDropdown } from "../common/components/queryToolbar/filtersAndSorts/FiltersAndSortsDropdown";
import { QueryToolbarLayout } from "../common/components/queryToolbar/layout/QueryToolbarLayout";
import { PromptDropdown } from "../common/components/queryToolbar/prompt/PromptDropdown";
import { PromptHistoryButton } from "../common/components/queryToolbar/prompt/PromptHisoryButton";
import { SimilaritySearchDropdown } from "../common/components/queryToolbar/similaritySearch/SimilaritySearchDropdown";
import { SQLDropdown } from "../common/components/queryToolbar/sql/SQLDropdown";
import { RecordsTableWithFallback } from "../common/components/recordsTable/RecordsTableWithFallback";
import { CreateRecordSidebar } from "../common/components/sidebars/record/CreateRecordSidebar";
import { EditRecordSidebar } from "../common/components/sidebars/record/EditRecordSidebar";
import {
  SidebarContextProvider,
  useSidebarDisclosure,
} from "../common/components/sidebars/common/sidebarState/useSidebarDisclosure";
import { TableCompositeLayout } from "../common/components/TableCompositeLayout";
import { WithFallback } from "../../../clientModel/loadable/WithFallback";
import { CreateFieldSidebar } from "../common/components/sidebars/field/CreateFieldSidebar";
import {
  FieldClientModel,
  FieldClientModelFactory,
} from "~/clientModel/fields/field";
import { RecordsTableQueryModeHeaderDropdown } from "./RecordsTableQueryModeHeaderDropdown";
import { useDisclosure } from "~/hooks/useDisclosure";
import { DeleteFieldConfirmDialog } from "../common/components/recordsTable/DeleteFieldConfirmDialog";
import { EditFieldSidebar } from "../common/components/sidebars/field/EditFieldSidebar";
import { BulkEditRecordsSidebar } from "../common/components/sidebars/record/BulkEditRecordsSidebar";
import {
  RecordEntryClientModel,
  RecordEntryClientModelFactory,
} from "~/clientModel/records/record/recordEntry";
import { BulkEditRecordsByRowFooter } from "../common/components/sidebars/record/bulkEditByRow/BulkEditRecordsByRowFooter";
import { BulkEditRecordsByRowContent } from "../common/components/sidebars/record/bulkEditByRow/BulkEditRecordsByRowContent";
import { BulkEditRecordsByFormulaContent } from "../common/components/sidebars/record/bulkEditByFormula/BulkEditRecordsByFormulaContent";
import { BulkEditRecordsByFormulaFooter } from "../common/components/sidebars/record/bulkEditByFormula/BulkEditRecordsByFormulaFooter";
import { BulkEditRecordsByPromptContent } from "../common/components/sidebars/record/bulkEditByPrompt/BulkEditRecordsByPromptContent";
import { BulkEditRecordsByPromptFooter } from "../common/components/sidebars/record/bulkEditByPrompt/BulkEditRecordsByPromptFooter";
import { CreateRecordWithFreeformSidebar } from "../common/components/sidebars/record/createRecordWithFreeform/CreateRecordWithFreeFormSidebar";
import { PromptHistoriesDrawer } from "~/presenters/promptHistories";
import { PromptHistoryClientModel } from "~/clientModel/promptHistories/promptHistory";
import { EditSyncFieldScheduleSidebar } from "../common/components/sidebars/field/EditSyncFieldScheduleSidebar";
import { Callout } from "~/components_next/Callout";
import { CsvInsertPresenter } from "~/presenters/csvInsert/CsvInsertPresenter";
import {
  CsvDownloadFieldsClientModel,
  CsvDownloadFieldsClientModelFactory,
} from "~/clientModel/csvDownload/csvDownloadFields";
import {
  DownloadRecordsWithQueryDrawer,
  DownloadRecordsWithSqlDrawer,
} from "~/presenters/downloadRecords";
import { useSourceQueryConditionsHandlers } from "./useSourceQueryConditionsHandlers";
import { SourceProps } from "./types";
import { CreateViewWithSqlButton } from "./CreateView/CreateViewWithSQLButton";
import { CreateViewWithSqlSidebar } from "./CreateView/CreateViewWithSQLSidebar";
import {
  EditingRecordClientModel,
  EditingRecordClientModelFactory,
} from "~/clientModel/records/editingRecord";
import {
  CreatingRecordClientModel,
  CreatingRecordClientModelFactory,
} from "~/clientModel/records/creatingRecord";

export const SourceComponent = (props: SourceProps) => {
  const {
    // loadables
    recordsLoadable,
    fieldsLoadable,
    sourceFieldsLoadable,
    suggestedPromptsLoadable,
    promptHistoriesLoadable,
    tablesLoadable,
    tableLoadable,
    aggregateValueFieldOperatorsLoadable,
    syncStatusLoadable,
    smartFunctionsLoadable,
    useFieldsLoadable,
    useDownloadCsvWithQueryPreviewRecordsLoadable,
    useDownloadCsvWithSqlPreviewRecordsLoadable,
    useRecordHistoriesLoadable,

    // data
    queryConditions,
    tableSelection,
    tableColumnSizingLoadable,
    queryMode,
    coworkersState,

    // handlers
    onQueryConditionsChange,
    onQueryModeChange,
    onTableSelectionChange,
    onTableColumnSizingChange,

    // executables
    useCreateRecordExecutable,
    useGenerateRecordsWithPromptExecutable,
    useCreateRecordsExecutable,
    useUpdateRecordExecutable,
    useDeleteRecordsExecutable,
    useCreateFieldExecutable,
    useCreateComputedFieldExecutable,
    useUpdateFieldExecutable,
    useUpdateFieldVisibilityExecutable,
    useUpdateFieldsOrderExecutable,
    useMakeFieldsNullableExecutable,
    useDeleteFieldExecutable,
    useRunSmartFieldExecutable,
    useTestFormulaExecutable,
    useGenerateSqlFromPromptExecutable,
    useUploadFileExecutable,
    useActivateEmbeddingExecutable,
    useBulkEditRecordsByRowExecutable,
    useBulkEditRecordsByFormulaExecutable,
    usePreviewBulkEditRecordsByFormulaExecutable,
    useGenerateFormulaExecutable,
    usePreviewBulkEditRecordsByPromptExecutable,
    useBulkEditRecordsByPromptExecutable,
    useDownloadRecordsWithQueryConditionsExecutable,
    useDownloadRecordsWithSqlExecutable,
    useGenerateSqlForComputedFieldFromPromptExecutable,
    useStartSyncComputedFieldsExecutable,

    // csv insert
    csvInsertProps,
  } = props;

  const { onOpen, isOpen } = useSidebarDisclosure();

  const {
    filterConditions,
    sortConditions,
    embeddingSortCondition,
    pagination,
    prompt,
    sql,
  } = queryConditions;

  const {
    handleFilterConditionsChange,
    handleSortConditionsChange,
    handleEmbeddingSortConditionChange,
    handleAllQueryConditionsClear,
    handlePaginationChange,
    handlePromptChange,
    handlePromptClear,
    handleSqlChange,
    handleSqlRun,
    handleSqlClear,
    handleSqlGenerated,
    handlePromptHistorySelected,
  } = useSourceQueryConditionsHandlers({
    queryConditions,
    onQueryConditionsChange,
    queryMode,
    onQueryModeChange,
  });

  /**
   * Prompt History
   */

  const {
    isOpen: isPromptHistoryDrawerOpen,
    onOpen: onPromptHistoryDrawerOpen,
    onClose: onPromptHistoryDrawerClose,
    setIsOpen: onPromptHistoryDrawerOpenChange,
  } = useDisclosure();

  const generateSqlFromPromptExecutable = useGenerateSqlFromPromptExecutable();

  const handleHistoryClick = useCallback(
    (promptHistory: PromptHistoryClientModel) => {
      onPromptHistoryDrawerClose();
      handlePromptHistorySelected(promptHistory);
    },
    [onPromptHistoryDrawerClose, handlePromptHistorySelected]
  );

  /**
   * Create Field
   */

  const [creatingField, setCreatingField] = useState<FieldClientModel>(
    FieldClientModelFactory.createEmpty("shortText").updateIsNullable(true)
  );

  const [
    editingFieldsForUpdateMergeKeyLoadable,
    setEditingFieldsForUpdateMergeKey,
  ] = useLoadableState<FieldsClientModel>();

  const handleAddFieldClick = useCallback(
    (fields: FieldsClientModel) => {
      setEditingFieldsForUpdateMergeKey(fields);
      onOpen("createField");
    },
    [onOpen, setEditingFieldsForUpdateMergeKey]
  );

  /**
   * Edit Field
   */

  const [editingFieldLoadable, setEditingField] =
    useLoadableState<FieldClientModel>();

  const handleEditField = useCallback(
    (field: FieldClientModel) => {
      setEditingField(field);
      onOpen("editField");
    },
    [onOpen, setEditingField]
  );

  /**
   * Delete Field
   */

  const {
    isOpen: isOpenDeleteConfirmDialog,
    setIsOpen: setIsOpenDeleteConfirmDialog,
    onOpen: onDeleteFieldConfirmDialogOpen,
  } = useDisclosure();

  const [deleteTargetField, setDeleteTargetField] =
    useState<FieldClientModel | null>(null);

  const handleDeleteFieldConfirmDialogOpen = useCallback(
    (field: FieldClientModel) => {
      setDeleteTargetField(field);
      onDeleteFieldConfirmDialogOpen();
    },
    [onDeleteFieldConfirmDialogOpen]
  );

  /**
   * Create Record
   */

  const [creatingRecordLoadable, setCreatingRecord] =
    useLoadableState<CreatingRecordClientModel>();

  const handleCreateRecordClick = useCallback(
    (fields: FieldsClientModel) => {
      setCreatingRecord(
        CreatingRecordClientModelFactory.createEmptyFromFieldsClientModel(
          fields
        )
      );
      onOpen("createRecord");
    },
    [onOpen, setCreatingRecord]
  );

  const handleCreateRecordDropdownClick = useCallback(
    (
      type: "createRecord" | "bulkInsert" | "freeform",
      fields: FieldsClientModel
    ) => {
      match(type)
        .with("createRecord", () => handleCreateRecordClick(fields))
        .with("bulkInsert", () => {
          csvInsertProps.onIsOpenChange(true);
          return;
        })
        .with("freeform", () => {
          onOpen("createRecordWithFreeform");
        })
        .exhaustive();
    },
    [csvInsertProps, handleCreateRecordClick, onOpen]
  );

  /**
   * Edit Record
   */

  const [editingRecordLoadable, setEditingRecord] =
    useLoadableState<EditingRecordClientModel>();

  const handleRecordClick = useCallback(
    (record: RecordClientModel) => {
      setEditingRecord(
        EditingRecordClientModelFactory.createFromClientRecordModel(record)
      );
      onOpen("editRecord");
    },
    [onOpen, setEditingRecord]
  );

  /**
   * Bulk Edit Records by Row
   */

  const [bulkEditingRecordEntryLoadable, setBulkEditingRecordEntry] =
    useLoadableState<RecordEntryClientModel>();

  /**
   * Bulk Edit Records by Formula
   */
  const [formulaForBulkEdit, setFormulaForBulkEdit] = useState<string>("");

  /**
   * Bulk Edit Records by Prompt
   */
  const [promptForBulkEdit, setPromptForBulkEdit] = useState<string>("");

  /**
   * Bulk Edit(Common)
   */

  const [bulkEditTargetFieldLoadable, setBulkEditTargetField] =
    useLoadableState<FieldClientModel>();

  const handleBulkEditRecordsClick = useCallback(
    (field: FieldClientModel) => {
      setBulkEditingRecordEntry(
        RecordEntryClientModelFactory.createFromRawValueAndFieldClientModel(
          null,
          field
        )
      );
      setFormulaForBulkEdit("");
      setBulkEditTargetField(field);
      setPromptForBulkEdit("");
      onOpen("bulkEditRecords");
    },
    [
      onOpen,
      setBulkEditTargetField,
      setFormulaForBulkEdit,
      setBulkEditingRecordEntry,
    ]
  );

  /**
   * Download CSV
   */

  const {
    onOpen: onDownloadCsvWithQueryDrawerOpen,
    isOpen: isDownloadCsvWithQueryDrawerOpen,
    setIsOpen: downloadCsvWithQueryDrawerOpenChange,
  } = useDisclosure();

  const {
    onOpen: onDownloadCsvWithSqlDrawerOpen,
    isOpen: isDownloadCsvWithSqlDrawerOpen,
    setIsOpen: downloadCsvWithSqlDrawerOpenChange,
  } = useDisclosure();

  const [csvDownloadWithQueryFieldsLoadable, setCsvDownloadWithQueryFields] =
    useLoadableState<CsvDownloadFieldsClientModel>();

  const [csvDownloadWithSqlFieldsLoadable, setCsvDownloadWithSqlFields] =
    useLoadableState<CsvDownloadFieldsClientModel>();

  const handleDownloadCsvWithQueryDrawerOpen = useCallback(
    (fields: FieldsClientModel) => {
      if (queryMode === "default" || queryMode === "query") {
        onDownloadCsvWithQueryDrawerOpen();
        setCsvDownloadWithQueryFields(
          CsvDownloadFieldsClientModelFactory.fromFieldsClientModel(fields)
        );
      } else {
        onDownloadCsvWithSqlDrawerOpen();
        setCsvDownloadWithSqlFields(
          CsvDownloadFieldsClientModelFactory.fromFieldsClientModel(fields)
        );
      }
    },
    [
      onDownloadCsvWithQueryDrawerOpen,
      onDownloadCsvWithSqlDrawerOpen,
      queryMode,
      setCsvDownloadWithQueryFields,
      setCsvDownloadWithSqlFields,
    ]
  );

  /**
   * UI
   */

  const isDefaultMode = queryMode === "default";
  const isQueryMode = queryMode === "query";
  const isPromptMode = queryMode === "prompt";
  const isSQLMode = queryMode === "sql";

  return (
    <>
      <Flex
        direction="row"
        position="relative"
        style={{ height: "calc(100vh - 40px)" }}
      >
        <TableCompositeLayout style={{ flex: 1, overflow: "hidden" }}>
          <WithFallback loadables={[syncStatusLoadable] as const}>
            {([syncStatus]) =>
              syncStatus &&
              syncStatus.isImporting && (
                <Callout
                  type="info"
                  size="xs"
                  description="Synchronization of computed fields is in progress"
                />
              )
            }
          </WithFallback>
          <QueryToolbarLayout>
            {(isDefaultMode || isQueryMode) && (
              <SimilaritySearchDropdown
                fieldsLoadable={fieldsLoadable}
                embeddingSortCondition={embeddingSortCondition}
                onEmbeddingSortConditionChange={
                  handleEmbeddingSortConditionChange
                }
                useActivateEmbeddingExecutable={useActivateEmbeddingExecutable}
              />
            )}

            {(isDefaultMode || isQueryMode) && (
              <FiltersAndSortsDropdown
                fieldsLoadable={fieldsLoadable}
                filterConditions={filterConditions}
                sortConditions={sortConditions}
                onFilterConditionsChange={handleFilterConditionsChange}
                onSortConditionsChange={handleSortConditionsChange}
              />
            )}
            {isQueryMode && (
              <ClearAllFiltersAndSortsButton
                onClick={handleAllQueryConditionsClear}
              />
            )}

            {(isDefaultMode || isPromptMode) && (
              <PromptDropdown
                suggestedPromptsLoadable={suggestedPromptsLoadable}
                prompt={prompt}
                isActive={queryMode === "prompt"}
                onPromptChange={handlePromptChange}
                onSqlGenerated={handleSqlGenerated}
                fieldsForTypeaheadLoadable={fieldsLoadable}
                promptRightButtons={
                  <>
                    <PromptHistoryButton onClick={onPromptHistoryDrawerOpen} />
                  </>
                }
                generateSqlFromPromptExecutable={
                  generateSqlFromPromptExecutable
                }
              />
            )}
            {isPromptMode && <ClearPromptButton onClick={handlePromptClear} />}

            {(isDefaultMode || isSQLMode) && (
              <SQLDropdown
                sql={sql}
                onSqlRun={handleSqlRun}
                onSqlChange={handleSqlChange}
                isActive={queryMode === "sql"}
                isSqlRunning={
                  queryMode === "sql" && recordsLoadable.status === "loading"
                }
                suggestedFieldsLoadable={fieldsLoadable}
              />
            )}
            {isSQLMode && <ClearSQLButton onClick={handleSqlClear} />}

            <Spacer />
            <FieldsSettingDropdown
              fieldsLoadable={fieldsLoadable}
              useUpdateFieldVisibilityExecutable={
                useUpdateFieldVisibilityExecutable
              }
              useUpdateFieldsOrderExecutable={useUpdateFieldsOrderExecutable}
              getFieldLabel={(field) => field.label}
            />
          </QueryToolbarLayout>

          {/* todo: 【重要】RecordsTableWithFallbackにkeyを指定しないとなぜかhooksの呼び出し順でクラッシュする */}
          {/* 一旦応急処置でkeyをつけておく。原因朝調査 */}
          {match(queryMode)
            .with(P.union("default", "query"), () => (
              <RecordsTableWithFallback
                key={queryMode}
                recordsLoadable={recordsLoadable}
                fieldsLoadable={
                  isOpen("createField")
                    ? editingFieldsForUpdateMergeKeyLoadable
                    : fieldsLoadable
                }
                tableColumnSizingLoadable={tableColumnSizingLoadable}
                forceLoading={generateSqlFromPromptExecutable.isExecuting}
                isSelectable
                isOpenable
                tableSelection={tableSelection}
                onTableSelectionChange={onTableSelectionChange}
                useUpdateRecordExecutable={useUpdateRecordExecutable}
                onRecordClick={handleRecordClick}
                onAddFieldClick={(fields) => handleAddFieldClick(fields)}
                onColumnSizingChange={onTableColumnSizingChange}
                useUploadFileExecutable={useUploadFileExecutable}
                useRunSmartFieldExecutable={useRunSmartFieldExecutable}
                coworkersState={coworkersState}
                showMergeFieldIndicator={
                  isOpen("createField") || isOpen("editField")
                }
                headerDropdown={(field) => (
                  <RecordsTableQueryModeHeaderDropdown
                    field={field}
                    onBulkEditRecordsClick={() =>
                      handleBulkEditRecordsClick(field)
                    }
                    onEditFieldClick={() => handleEditField(field)}
                    useUpdateFieldVisibilityExecutable={
                      useUpdateFieldVisibilityExecutable
                    }
                    onDeleteFieldClick={() =>
                      handleDeleteFieldConfirmDialogOpen(field)
                    }
                  />
                )}
              />
            ))
            .with(P.union("sql", "prompt"), () => (
              <RecordsTableWithFallback
                key={queryMode}
                recordsLoadable={recordsLoadable}
                fieldsLoadable={fieldsLoadable}
                forceLoading={generateSqlFromPromptExecutable.isExecuting}
                isReadOnly
              />
            ))
            .exhaustive()}

          <FloatingItemsLayout>
            <WithFallback loadables={[recordsLoadable] as const}>
              {([records]) => (
                <>
                  <CreateViewWithSqlButton
                    queryMode={queryMode}
                    queryConditions={queryConditions}
                  />
                  <DeleteSelectedRecordsButton
                    records={records}
                    tableSelection={tableSelection}
                    onTableSelectionChange={onTableSelectionChange}
                    useDeleteRecordsExecutable={useDeleteRecordsExecutable}
                  />
                </>
              )}
            </WithFallback>
          </FloatingItemsLayout>
          <BottomItemsLayout>
            <WithFallback
              loadables={[recordsLoadable, fieldsLoadable] as const}
            >
              {([records, fields]) => (
                <>
                  <TablePagination
                    pagination={pagination}
                    recordsCount={records.totalCount}
                    onPaginationChange={handlePaginationChange}
                  />
                  <Spacer />
                  <DownloadButton
                    onClick={() => handleDownloadCsvWithQueryDrawerOpen(fields)}
                  />
                  <CreateRecordButton
                    onClick={(type) =>
                      handleCreateRecordDropdownClick(type, fields)
                    }
                    buttonTypes={["createRecord", "bulkInsert", "freeform"]}
                  />
                </>
              )}
            </WithFallback>
          </BottomItemsLayout>
        </TableCompositeLayout>
        {/* Sidebars */}
        <CreateRecordSidebar
          useCreateRecordExecutable={useCreateRecordExecutable}
          creatingRecordLoadable={creatingRecordLoadable}
          onCreatingRecordChange={setCreatingRecord}
          fieldsLoadable={fieldsLoadable}
          useUploadFileExecutable={useUploadFileExecutable}
        />
        <CreateRecordWithFreeformSidebar
          fieldsLoadable={fieldsLoadable}
          smartFunctionsLoadable={smartFunctionsLoadable}
          useGenerateRecordsWithPromptExecutable={
            useGenerateRecordsWithPromptExecutable
          }
          useCreateRecordsExecutable={useCreateRecordsExecutable}
          useUploadFileExecutable={useUploadFileExecutable}
        />
        <EditRecordSidebar
          editingRecordLoadable={editingRecordLoadable}
          onEditingRecordChange={setEditingRecord}
          fieldsLoadable={fieldsLoadable}
          useUpdateRecordExecutable={useUpdateRecordExecutable}
          useUploadFileExecutable={useUploadFileExecutable}
          useRecordHistoriesLoadable={useRecordHistoriesLoadable}
        />
        <CreateFieldSidebar
          tableLoadable={tableLoadable}
          creatingField={creatingField}
          fieldsLoadable={fieldsLoadable}
          editingFieldsForUpdateMergeKeyLoadable={
            editingFieldsForUpdateMergeKeyLoadable
          }
          tablesLoadable={tablesLoadable}
          aggregateValueFieldOperatorsLoadable={
            aggregateValueFieldOperatorsLoadable
          }
          smartFunctionsLoadable={smartFunctionsLoadable}
          useFieldsLoadable={useFieldsLoadable}
          onCreatingFieldChange={setCreatingField}
          onEditingFieldsForUpdateMergeKeyChange={
            setEditingFieldsForUpdateMergeKey
          }
          useCreateFieldExecutable={useCreateFieldExecutable}
          useCreateComputedFieldExecutable={useCreateComputedFieldExecutable}
          useGenerateSqlForComputedFieldFromPromptExecutable={
            useGenerateSqlForComputedFieldFromPromptExecutable
          }
          initialFieldType="shortText"
          useTestFormulaExecutable={useTestFormulaExecutable}
          useStartSyncComputedFieldsExecutable={
            useStartSyncComputedFieldsExecutable
          }
          useMakeFieldsNullableExecutable={useMakeFieldsNullableExecutable}
        />
        <EditFieldSidebar
          tableLoadable={tableLoadable}
          editingFieldLoadable={editingFieldLoadable}
          fieldsLoadable={fieldsLoadable}
          tablesLoadable={tablesLoadable}
          aggregateValueFieldOperatorsLoadable={
            aggregateValueFieldOperatorsLoadable
          }
          smartFunctionsLoadable={smartFunctionsLoadable}
          useFieldsLoadable={useFieldsLoadable}
          onEditingFieldChange={setEditingField}
          useUpdateFieldExecutable={useUpdateFieldExecutable}
          useTestFormulaExecutable={useTestFormulaExecutable}
        />
        <CreateViewWithSqlSidebar
          sourceFieldsLoadable={sourceFieldsLoadable}
          tableLoadable={tableLoadable}
          queryConditions={queryConditions}
        />
        <EditSyncFieldScheduleSidebar />
        <BulkEditRecordsSidebar
          tabs={["Row", "Formula", "Prompt"]}
          content={(tab) =>
            match(tab)
              .with("Row", () => (
                <BulkEditRecordsByRowContent
                  editingRecordEntryLoadable={bulkEditingRecordEntryLoadable}
                  fieldsLoadable={fieldsLoadable}
                  onEditingRecordEntryChange={setBulkEditingRecordEntry}
                  useUploadFileExecutable={useUploadFileExecutable}
                />
              ))
              .with("Formula", () => (
                <BulkEditRecordsByFormulaContent
                  formula={formulaForBulkEdit}
                  onFormulaChange={setFormulaForBulkEdit}
                  targetFieldLoadable={bulkEditTargetFieldLoadable}
                  fieldsLoadable={fieldsLoadable}
                  usePreviewBulkEditRecordsByFormulaExecutable={
                    usePreviewBulkEditRecordsByFormulaExecutable
                  }
                  useGenerateFormulaExecutable={useGenerateFormulaExecutable}
                />
              ))
              .with("Prompt", () => (
                <BulkEditRecordsByPromptContent
                  prompt={promptForBulkEdit}
                  onPromptChange={setPromptForBulkEdit}
                  targetFieldLoadable={bulkEditTargetFieldLoadable}
                  smartFunctionsLoadable={smartFunctionsLoadable}
                  fieldsLoadable={fieldsLoadable}
                  usePreviewBulkEditRecordsByPromptExecutable={
                    usePreviewBulkEditRecordsByPromptExecutable
                  }
                />
              ))
              .exhaustive()
          }
          footers={(tab) =>
            match(tab)
              .with("Row", () => (
                <BulkEditRecordsByRowFooter
                  editingRecordEntryLoadable={bulkEditingRecordEntryLoadable}
                  fieldsLoadable={fieldsLoadable}
                  useBulkEditRecordsByRowExecutable={
                    useBulkEditRecordsByRowExecutable
                  }
                />
              ))
              .with("Formula", () => (
                <BulkEditRecordsByFormulaFooter
                  formula={formulaForBulkEdit}
                  targetFieldLoadable={bulkEditTargetFieldLoadable}
                  useBulkEditRecordsByFormulaExecutable={
                    useBulkEditRecordsByFormulaExecutable
                  }
                />
              ))
              .with("Prompt", () => (
                <BulkEditRecordsByPromptFooter
                  prompt={promptForBulkEdit}
                  targetFieldLoadable={bulkEditTargetFieldLoadable}
                  useBulkEditRecordsByPromptExecutable={
                    useBulkEditRecordsByPromptExecutable
                  }
                />
              ))
              .exhaustive()
          }
        />
      </Flex>
      {/* Drawers, Dialogs */}
      {deleteTargetField && (
        <DeleteFieldConfirmDialog
          targetField={deleteTargetField}
          isOpen={isOpenDeleteConfirmDialog}
          onOpenChange={setIsOpenDeleteConfirmDialog}
          useDeleteFieldExecutable={useDeleteFieldExecutable}
        />
      )}
      <PromptHistoriesDrawer
        isOpen={isPromptHistoryDrawerOpen}
        onOpenChange={onPromptHistoryDrawerOpenChange}
        promptHistoriesLoadable={promptHistoriesLoadable}
        onHistoryClick={handleHistoryClick}
      />
      <CsvInsertPresenter {...csvInsertProps} />
      <WithFallback loadables={[csvDownloadWithQueryFieldsLoadable] as const}>
        {([csvDownloadWithQueryFields]) => (
          <DownloadRecordsWithQueryDrawer
            isOpen={isDownloadCsvWithQueryDrawerOpen}
            onOpenChange={downloadCsvWithQueryDrawerOpenChange}
            csvDownloadFields={csvDownloadWithQueryFields}
            onCsvDownloadFieldsChange={setCsvDownloadWithQueryFields}
            useDownloadExecutable={
              useDownloadRecordsWithQueryConditionsExecutable
            }
            usePreviewRecordsLoadable={
              useDownloadCsvWithQueryPreviewRecordsLoadable
            }
          />
        )}
      </WithFallback>
      <WithFallback
        loadables={
          [csvDownloadWithSqlFieldsLoadable, smartFunctionsLoadable] as const
        }
      >
        {([csvDownloadWithSqlFields, smartFunctions]) => (
          <DownloadRecordsWithSqlDrawer
            isOpen={isDownloadCsvWithSqlDrawerOpen}
            onOpenChange={downloadCsvWithSqlDrawerOpenChange}
            csvDownloadFields={csvDownloadWithSqlFields}
            smartFunctions={smartFunctions}
            usePreviewRecordsLoadable={
              useDownloadCsvWithSqlPreviewRecordsLoadable
            }
            useDownloadExecutable={useDownloadRecordsWithSqlExecutable}
          />
        )}
      </WithFallback>
    </>
  );
};

export const Source = (props: SourceProps) => {
  return (
    <SidebarContextProvider>
      <SourceComponent {...props} />
    </SidebarContextProvider>
  );
};
