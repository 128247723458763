import { useQuery } from "react-query";
import { useComposeLoadable } from "~/clientModel/loadable";
import { VersionClientModelFactory } from "~/clientModel/version/VersionClientModel/VersionClientModelFactory";
import { useListVersionQuery } from "~/serverStateStore/version"



export const useListVersionLoadable = (props: {
  teamSlug: string;
}) => {
  const { teamSlug } = props

  return useComposeLoadable(
    useQuery({
      ...useListVersionQuery({
        teamSlug,
      }),
      select: (data) => {
        return data.items.map(item => VersionClientModelFactory.fromVersionResponse(item))
      }
    })
  )

}
