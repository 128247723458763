import { FieldClientModel } from "~/clientModel/fields/field";
import {
  UpdatableRecordEntryClientModelBaseConstructorParams,
  UpdatableRecordEntryClientModelBase,
} from "../RecordEntryClientModelBase";
import { zValidate, zAllowNullIfNullable } from "../validatorsUtil";
import { z } from "zod";

type ArrayRecordEntryClientModelData =
  UpdatableRecordEntryClientModelBaseConstructorParams;

export class ArrayRecordEntryClientModel extends UpdatableRecordEntryClientModelBase<
  string | null
> {
  constructor(data: ArrayRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "array" {
    return "array";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([z.string(), zAllowNullIfNullable(field)]),
      this.data.rawValue
    );
  }

  public getValidatedDisplayValue = this.getValidatedValue;

  public updateValue(value: string | null): ArrayRecordEntryClientModel {
    return new ArrayRecordEntryClientModel({
      key: this.data.key,
      rawValue: value,
      meta: this.data.meta,
    });
  }
}
