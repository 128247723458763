import { useMutation } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import {
  CreatingRecordClientModel,
  CreatingRecordClientModelDefactory,
} from "~/clientModel/records/creatingRecord";

import { useErrorToast } from "~/components_next/Error";
import { useCreateRecordsMutation } from "~/serverStateStore";

type UseCreateRecordsExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useCreateRecordsExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseCreateRecordsExecutableParams): Executable<
  {
    creatingRecordArray: CreatingRecordClientModel[];
    fields: FieldsClientModel;
  },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  const createRecordsOptions = useCreateRecordsMutation({
    teamSlug,
    databaseId,
    tableSlug,
  });

  return useComposeExecutable(
    useMutation({
      ...createRecordsOptions,
      mutationFn: ({
        creatingRecordArray,
        fields,
      }: {
        creatingRecordArray: CreatingRecordClientModel[];
        fields: FieldsClientModel;
      }) => {
        const valuesList = creatingRecordArray.map((record) =>
          CreatingRecordClientModelDefactory.toRecordValues(record, fields)
        );

        return createRecordsOptions.mutationFn({
          valuesList,
        });
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseCreateRecordsExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseCreateRecordsExecutableParams) => {
  return () =>
    useCreateRecordsExecutable({
      teamSlug,
      databaseId,
      tableSlug,
    });
};
