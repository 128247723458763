import { useMutation } from "react-query";
import { useComposeExecutable } from "~/clientModel/executable";
import { useCreateCustomerPortalSessionMutation } from "~/serverStateStore";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";


export const useCreateCustomerPortalSession = () => {
  const teamSlug = useTeamSlug()

  const createCustomerPortalSessionMutationOptions = useCreateCustomerPortalSessionMutation({ teamSlug, redirectUrl: location.href, })
  const createCustomerPortalSessionMutationResult = useMutation({
    ...createCustomerPortalSessionMutationOptions,
    mutationFn: async () => {
      return await createCustomerPortalSessionMutationOptions.mutationFn()
    }
  });

  const createCustomerPortalSessionLoadable = useComposeExecutable(createCustomerPortalSessionMutationResult)

  return {
    createCustomerPortalSessionLoadable,
  }
}
