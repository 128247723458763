import { useQuery } from "react-query";
import { Loadable, useComposeLoadable } from "~/clientModel/loadable";
import {
  ComputedFieldSyncScheduleClientModel,
  ComputedFieldSyncScheduleClientModelFactory,
} from "~/clientModel/tables/table/computedFieldSyncSchedule";

import { useListNormalizedTableScheduleQuery } from "~/serverStateStore/tables";

type UseComputedFieldSyncScheduleLoadableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

export const useComputedFieldSyncScheduleLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseComputedFieldSyncScheduleLoadableParams): Loadable<ComputedFieldSyncScheduleClientModel | null> => {
  return useComposeLoadable(
    useQuery({
      ...useListNormalizedTableScheduleQuery({
        teamSlug,
        databaseId,
        tableSlug,
      }),
      select: (data) => {
        if (data.count === 0) {
          return null;
        }
        return ComputedFieldSyncScheduleClientModelFactory.createFromDashboardNormalizedTableScheduleResponse(
          data.items[0]
        );
      },
    })
  );
};
