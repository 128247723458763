import { BaseUserMeta, createClient, LsonObject } from "@liveblocks/client";
import { createRoomContext } from "@liveblocks/react";
import { BroadcastEventTypes, Presence } from "./types";

const client = createClient({
  publicApiKey: `${import.meta.env.VITE_LIVEBLOCK_KEY}`,
});

export const {
  RoomProvider,
  useRoom,
  useMyPresence,
  useUpdateMyPresence,
  useOthers,
  useBroadcastEvent,
  useEventListener,
} = createRoomContext<Presence, LsonObject, BaseUserMeta, BroadcastEventTypes>(
  client
);
