import { TargetDaysClientModel } from "~/clientModel/scheduleCore/targetDays";
import { Checkbox } from "~/components_next/Checkbox";
import { Flex } from "~/components_next/Flex";
import { Grid } from "~/components_next/Grid";
import { Text } from "~/components_next/Text";

type TargetDaysInputProps = {
  value: TargetDaysClientModel;
  onChange: (value: TargetDaysClientModel) => void;
};

export const TargetDaysInput = (props: TargetDaysInputProps) => {
  const { value, onChange } = props;

  return (
    <Grid rows="1" columns="7">
      {value.allDays.map((day) => (
        <Flex key={day.dayLabel} direction="column" align="center" gap="1">
          <Checkbox
            size="sm"
            value={day.isSelected}
            onChange={(checked) =>
              checked
                ? onChange(value.selectDay(day))
                : onChange(value.unselectDay(day))
            }
          />
          <Text variant="tinyDescription">{day.dayLabel}</Text>
        </Flex>
      ))}
    </Grid>
  );
};
