import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useCallback,
  useRef,
} from "react";
import { UseExecutable } from "~/clientModel/executable";
import { ImageRecordEntryClientModel } from "~/clientModel/records/record/recordEntry";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { ActionIcon } from "~/components_next/Icons";
import { InputStack } from "~/components_next/InputStack/InputStack";

import { RecordValueInputCommonProps } from "../../type";
import { ImagePreview } from "./ImagePreview";

type ImageInputProps =
  RecordValueInputCommonProps<ImageRecordEntryClientModel> & {
    useUploadFileExecutable?: UseExecutable<
      void,
      { file: File },
      {
        data: string;
        url: string;
      },
      unknown
    >;
  };

const ImageInput = forwardRef(function ImageInput(
  props: ImageInputProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const {
    editingRecordEntry,
    onChange,
    field,
    size,
    useUploadFileExecutable,
    isReadonly,
  } = props;

  const uploadFileExecutable = useUploadFileExecutable?.();

  const validatedValue = editingRecordEntry.getValidatedValue(field);

  const value = validatedValue.isValid ? validatedValue.value : null;

  const errorMessages = validatedValue.errorMessages;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFilterOpen = useCallback(() => {
    fileInputRef.current && fileInputRef.current.click();
  }, []);

  const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file || !uploadFileExecutable || !onChange) {
      return;
    }

    const { data, url } = await uploadFileExecutable.execute({ file });

    onChange(editingRecordEntry.updateValue({ data, url }));
  };

  const handleImageClear = () => {
    onChange?.(editingRecordEntry.updateValue(null));
  };

  return (
    <>
      {value ? (
        <Box style={{ maxWidth: "600px" }}>
          <ImagePreview
            url={value.url}
            onChangeClick={isReadonly ? undefined : handleFilterOpen}
            onRemove={isReadonly ? undefined : handleImageClear}
          />
        </Box>
      ) : (
        <Button
          ref={ref}
          variant="tertiary"
          size={size}
          style={{ width: "100%" }}
          onClick={handleFilterOpen}
          isDisabled={isReadonly}
          isLoading={uploadFileExecutable?.isExecuting}
        >
          <ActionIcon type="add" /> Upload
        </Button>
      )}
      <InputStack errorMessage={errorMessages}>
        <input
          ref={fileInputRef}
          style={{ visibility: "hidden", width: 0, height: 0 }}
          type="file"
          onChange={handleImageUpload}
          accept="image/*"
        />
      </InputStack>
    </>
  );
});

export { ImageInput };
