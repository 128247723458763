type DatabaseUsageDataType = {
  count: number;
};

export class DatabaseUsageClientModel {
  readonly #data: DatabaseUsageDataType;
  readonly limit = 1000;

  constructor(data: DatabaseUsageDataType) {
    this.#data = data;
  }

  get count(): number {
    return this.#data.count;
  }

  get isLimitExceeded(): boolean {
    return this.#data.count > 1000;
  }

  get usagePercentage(): number {
    return (this.#data.count / this.limit) * 100;
  }
}
