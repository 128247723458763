import { Form } from "@radix-ui/react-form";
import { Separator, Tabs } from "@radix-ui/themes";
import {
  DashboardRegisterDataApiRequestBody,
  DashboardRegisterDataApiResponse,
} from "@usemorph/morph-dashboard-types";
import { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { match, P } from "ts-pattern";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { useErrorToast } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { RightSidebar } from "~/components_next/RightSidebar";
import { Spinner } from "~/components_next/Spinner";
import {
  ApiType,
  useFindDataApiQuery,
  useRegistDataApiMutation,
  useSimpleFields,
} from "~/serverStateStore";
import { useDataApiKey } from "~/utilHooks/useDataApiKey";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTableSlug } from "~/utilHooks/useTableSlug";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { ApiKeyForm } from "./components/common/ApiKeyForm";
import { UrlForm } from "./components/common/UrlForm";
import { DataApiPreview } from "./components/DataApiPreview";
import { AggregateForm } from "./components/Form/AggregateForm";
import { CreateForm } from "./components/Form/CreateForm";
import { DownloadForm } from "./components/Form/DownloadForm";
import { QueryForm } from "./components/Form/QueryForm";
import { UpdateForm } from "./components/Form/UpdateForm";
import { SampleCode } from "./components/SampleCode";
import { convertDataApiObject } from "./util/convertDataApiObject";

enum DataApiPathnames {
  query = "/data-api/record/query",
  create = "/data-api/record/create",
  update = "/data-api/record/update",
  delete = "/data-api/record/delete",
  upload = "/data-api/file/upload",
  aggregate = "/data-api/record/aggregate",
  download = "/data-api/record/csv",
  chat = "/data-api/chat/reply",
}

type DataApiProps = {
  dataApi: DashboardRegisterDataApiResponse;
};

const DataApi = (props: DataApiProps) => {
  const { dataApi } = props;
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const tableSlug = useTableSlug();
  const dataApiKey = useDataApiKey();

  const { data: simpleFields } = useSimpleFields({
    teamSlug,
    databaseId,
    tableSlug,
  });

  const defaultRequestBody = useMemo(() => {
    const converted = convertDataApiObject(dataApi);
    if (!converted) {
      throw new Error("Something went wrong");
    }
    return converted;
  }, [dataApi]);

  const [editingValue, setEditingValue] =
    useState<DashboardRegisterDataApiRequestBody>(defaultRequestBody);

  const url = useMemo(() => {
    const pathname = match(editingValue?.apiType)
      .with("query", () => DataApiPathnames.query)
      .with("create", () => DataApiPathnames.create)
      .with("update", () => DataApiPathnames.update)
      .with("delete", () => DataApiPathnames.delete)
      .with("upload", () => DataApiPathnames.upload)
      .with("aggregate", () => DataApiPathnames.aggregate)
      .with("download", () => DataApiPathnames.download)
      .with("chat", () => DataApiPathnames.chat)
      .otherwise(() => "");
    return `${dataApi.url}${pathname}`;
  }, [dataApi, editingValue]);

  /**
   * Update
   */
  const { errorToast } = useErrorToast({});
  const { mutateAsync: updateDataApi, isLoading: isUpdating } = useMutation({
    ...useRegistDataApiMutation({
      databaseId,
      teamSlug,
      tableSlug,
    }),
  });

  const handleSaveClicked = async () => {
    if (!dataApi) return;
    if (!dataApi.apiType) return;

    try {
      await updateDataApi({
        requestBody: {
          ...editingValue,
          apiType: dataApi.apiType as ApiType,
          apiName: dataApi.apiName,
          apiKey: dataApi.apiKey,
        },
      });
    } catch (e) {
      errorToast(e);
    }
  };

  return (
    <Flex>
      <Box
        css={{
          height: "calc(100vh - 40px)",
          overflowY: "auto",
        }}
        grow="1"
        p="4"
      >
        <UrlForm url={url} />
        <Form>
          {match(editingValue)
            .with({ apiType: "query" }, (body) => {
              return (
                <QueryForm
                  fields={simpleFields?.fields ?? []}
                  defaultBody={body.query}
                  onChange={(value) => {
                    if (editingValue) {
                      setEditingValue({
                        ...editingValue,
                        query: value,
                      });
                    }
                  }}
                />
              );
            })
            .with({ apiType: "create" }, (body) => {
              return (
                <CreateForm
                  fields={simpleFields?.fields ?? []}
                  defaultBody={body.create}
                  onChange={(value) => {
                    if (editingValue) {
                      setEditingValue({
                        ...editingValue,
                        create: value,
                      });
                    }
                  }}
                />
              );
            })
            .with({ apiType: "update" }, (body) => {
              return (
                <UpdateForm
                  fields={simpleFields?.fields ?? []}
                  defaultBody={body.update}
                  onChange={(value) => {
                    if (editingValue) {
                      setEditingValue({
                        ...editingValue,
                        update: value,
                      });
                    }
                  }}
                />
              );
            })
            .with({ apiType: "download" }, (body) => {
              return (
                <DownloadForm
                  fields={simpleFields?.fields ?? []}
                  defaultBody={body.download}
                  onChange={(value) => {
                    if (editingValue) {
                      setEditingValue({
                        ...editingValue,
                        download: value,
                      });
                    }
                  }}
                />
              );
            })
            .with({ apiType: "aggregate" }, (body) => {
              return (
                <AggregateForm
                  fields={simpleFields?.fields ?? []}
                  defaultBody={body.aggregate}
                  onChange={(value) => {
                    if (editingValue) {
                      setEditingValue({
                        ...editingValue,
                        aggregate: value,
                      });
                    }
                  }}
                />
              );
            })
            .otherwise(() => (
              <Box height="5" />
            ))}
        </Form>
        <ApiKeyForm apiKey={dataApi?.apiKey || ""} />
        <Separator my="6" size="4" />
        <Button
          variant="primary"
          size="sm"
          isLoading={isUpdating}
          onClick={handleSaveClicked}
        >
          Save
        </Button>
      </Box>
      {/* Right */}
      <Tabs.Root defaultValue={editingValue.apiName}>
        <RightSidebar
          height={"100%"}
          width={400}
          title={
            <Tabs.List>
              {match(editingValue)
                .with({ apiType: P.union("query") }, () => (
                  // aggregateとdownloadも本当はやりたい
                  <Tabs.Trigger value={editingValue.apiName}>Data Example</Tabs.Trigger>
                ))
                .otherwise(() => (
                  <></>
                ))}
              <Tabs.Trigger value={"sampleCode"}>Sample Code</Tabs.Trigger>
            </Tabs.List>
          }
        >
          {match(editingValue)
            .with({ apiType: P.union("query") }, () => (
              <Tabs.Content value={editingValue.apiName}>
                {editingValue && (
                  <DataApiPreview
                    body={editingValue}
                    tableSlug={tableSlug}
                    apiKey={dataApiKey}
                  />
                )}
              </Tabs.Content>
            ))
            .otherwise(() => null)}
          <Tabs.Content value="sampleCode">
            {editingValue && <SampleCode url={url} body={editingValue} />}
          </Tabs.Content>
        </RightSidebar>
      </Tabs.Root>
    </Flex>
  );
};

const DataApiWrapper = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const tableSlug = useTableSlug();
  const dataApiKey = useDataApiKey();

  const { data, status, error } = useQuery(
    useFindDataApiQuery({
      databaseId,
      teamSlug,
      tableSlug,
      apiKey: dataApiKey,
    })
  );

  if (status === "loading" || status === "idle") {
    return (
      <Flex height="100%" align="center" justify="center">
        <Spinner size="24" />
      </Flex>
    );
  }

  if (status === "error") {
    throw error;
  }

  return <DataApi dataApi={data} key={dataApiKey} />;
};

export { DataApiWrapper as DataApi };
