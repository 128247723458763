import constate from "constate";
import { useMutation } from "react-query";
import { useNotebookLiveObject } from "~/features/RealtimeCollaboration/Notebook/useNotebookLiveObject";
import {
  useCreateNotebookCellMutaiton,
  CreateNotebookCellMutationProps,
} from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useNotebookId } from "~/utilHooks/useNotebookId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { NotebookCellObjectWithMeta } from "../types/NotebookCellObjectWithMeta.type";

const useNotebookCreateCellContext = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const notebookId = useNotebookId();

  const { addNoteookCell } = useNotebookLiveObject();

  const { mutateAsync: _onCreateCell, isLoading: isCreatingCell } = useMutation(
    {
      ...useCreateNotebookCellMutaiton({
        teamSlug,
        notebookId,
        databaseId,
      }),
    }
  );

  // セルの新規作成は必ずMorphサーバーへの書き込みを先にする
  // 成功したらLiveblocksに書き込む
  const onCreateCell = async (props: CreateNotebookCellMutationProps) => {
    const createResponse = await _onCreateCell(props);
    addNoteookCell(createResponse as NotebookCellObjectWithMeta);

    return createResponse;
  };

  return {
    onCreateCell,
    isCreatingCell,
  };
};

export const [
  NotebookCreateCellProvider,
  useNotebookOnCreateCell,
  useNotebookIsCreatingCell,
] = constate(
  useNotebookCreateCellContext,
  (value) => value.onCreateCell,
  (value) => value.isCreatingCell
);
