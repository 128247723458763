import dayjs from "dayjs";
import { FindUserClientModel } from "~/clientModel/user";

type PromptHistoryClientModelData = {
  message: string;
  sql: string;
  createdAt: string;
  createdBy?: FindUserClientModel;
};

export class PromptHistoryClientModel {
  readonly #data: PromptHistoryClientModelData;

  constructor(data: PromptHistoryClientModelData) {
    this.#data = data;
  }

  public get message(): string {
    return this.#data.message;
  }

  public get messageWithoutBrackets(): string {
    return this.#data.message.replaceAll(/(\$\{|\})/g, "");
  }

  public get sql(): string {
    return this.#data.sql;
  }

  public get createdAt(): string {
    return this.#data.createdAt;
  }

  public get fromNowLabel(): string {
    return dayjs(this.#data.createdAt).fromNow();
  }

  public get createdBy(): FindUserClientModel | null {
    return this.#data.createdBy ?? null;
  }
}
