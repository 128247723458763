import {
  DashboardTeamResponse,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";
type DashboardGeneralResponse = {
  message: string;
};
export type TableErrorType = {
  error: string;
  subCode: number;
  message: string;
  detail?: any;
};


export default function useApiTeam() {

  const { executeRequest } = useApi();

  const _createTeam = async ({
    teamName,
    teamSlug,
  }: {
    teamName: string;
    teamSlug: string;
  }): Promise<DashboardTeamResponse> => {
    return executeRequest<DashboardTeamResponse>(
      "POST",
      "/team",
      undefined,
      undefined,
      {
        teamName,
        teamSlug,
      }
    );
  };

  const _findTeam = async ({
    teamSlug,
  }: {
    teamSlug: string;
  }): Promise<DashboardTeamResponse> => {
    return executeRequest<DashboardTeamResponse>(
      "get",
      `/team/${teamSlug}`,
      undefined,
      undefined,
      undefined
    );
  };

  const _updateTeam = async ({
    teamSlug,
    teamName,
  }: {
    teamSlug: string;
    teamName: string;
  }): Promise<DashboardTeamResponse> => {
    return executeRequest<DashboardTeamResponse>(
      "PUT",
      `/team/${teamSlug}`,
      undefined,
      undefined,
      {
        teamName,
      }
    );
  };

  const _inviteUser = async ({
    teamSlug,
    emails,
  }: {
    teamSlug: string;
    emails: string[];
  }): Promise<DashboardTeamResponse> => {
    return executeRequest<DashboardTeamResponse>(
      "POST",
      `/team/${teamSlug}/user`,
      undefined,
      undefined,
      {
        emails,
      }
    );
  };

  const _joinUser = async ({
    teamSlug,
  }: {
    teamSlug?: string;
  }): Promise<DashboardTeamResponse | void> => {
    if (teamSlug) {
      return executeRequest<DashboardTeamResponse>(
        "POST",
        `/team/${teamSlug}/join`,
        undefined,
        undefined,
        undefined
      );
    } else {
      return console.log("teamSlug is not Provided");
    }
  };

  const _updateTeamUser = async ({
    userId,
    teamSlug,
    isAdmin,
  }: {
    userId: string;
    teamSlug: string;
    isAdmin: boolean;
  }): Promise<DashboardTeamResponse> => {
    return executeRequest(
      "PUT",
      `/team/${teamSlug}/user/${userId}`,
      undefined,
      undefined,
      {
        isAdmin,
      }
    );
  };

  const _deleteTeamUser = async ({
    userId,
    teamSlug,
  }: {
    userId: string;
    teamSlug: string;
  }): Promise<DashboardTeamResponse | void> => {
    return executeRequest(
      "DELETE",
      `/team/${teamSlug}/user/${userId}`,
      undefined,
      undefined,
      undefined
    );
  };

  const _checkTeamSlug = async ({
    teamSlug,
  }: {
    teamSlug: string;
  }): Promise<{ isAvailable: boolean }> => {
    return executeRequest(
      "POST",
      `/team/${teamSlug}`,
      undefined,
      undefined,
      undefined
    );
  };

  return {
    _findTeam,
    _createTeam,
    _updateTeam,
    _inviteUser,
    _joinUser,
    _updateTeamUser,
    _deleteTeamUser,
    _checkTeamSlug,
  };
}
