import { FieldTypeClientModelFactory } from "~/clientModel/fields/field/fieldType";
import { FieldTypeSubSelect } from "./common/FieldTypeSubSelectBase";

const selectFieldTypes = [
  FieldTypeClientModelFactory.createEmpty("singleSelect"),
  FieldTypeClientModelFactory.createEmpty("multiSelect"),
] as const;

type SelectFieldType = (typeof selectFieldTypes)[number];

type SelectFieldsTypeSubSelectProps = {
  onSelect: (fieldType: SelectFieldType) => void;
  options?:
    | {
        includes: SelectFieldType["type"][];
      }
    | {
        excludes: SelectFieldType["type"][];
      }; // 指定がなければ全部
};

export const SelectFieldsTypeSubSelect = (
  props: SelectFieldsTypeSubSelectProps
) => {
  const { onSelect, options } = props;

  return (
    <FieldTypeSubSelect
      triggerContent="Select"
      onSelect={onSelect}
      fieldTypeOptions={selectFieldTypes}
      options={options}
    />
  );
};
