import { useEffect } from "react";
import { useViewId } from "~/utilHooks/useViewId";
import { useFieldSelection } from "../../states/fieldSelection";
import {
  useSetOpeningSidebarType,
  useSidebarDisclosure,
} from "../../states/sidebar";

const useCloseEditFieldSidebar = () => {
  const viewId = useViewId();
  const fieldSelection = useFieldSelection(viewId);

  const { isOpen } = useSidebarDisclosure("editField");
  const setOpeningSidebarType = useSetOpeningSidebarType();
  useEffect(() => {
    if (isOpen && (!fieldSelection || fieldSelection.length !== 1)) {
      setOpeningSidebarType(null);
    }
  }, [isOpen, fieldSelection, setOpeningSidebarType]);
};

export { useCloseEditFieldSidebar };
