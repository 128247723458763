import { Executable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { Loadable } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { DropdownMenu } from "~/components_next/DropdownMenu";

type SyncScheduleDropdownItemsProps = {
  fieldsLoadable: Loadable<FieldsClientModel>;
  onSyncScheduleSettingsClick: () => void;
  startSyncExecutable: Executable<void, unknown>;
};

export const SyncScheduleDropdownItems = (
  props: SyncScheduleDropdownItemsProps
) => {
  const { onSyncScheduleSettingsClick, fieldsLoadable, startSyncExecutable } =
    props;

  return (
    <WithFallback loadables={[fieldsLoadable] as const}>
      {([fields]) =>
        fields.hasComputedField ? (
          <>
            <DropdownMenu.Item onClick={onSyncScheduleSettingsClick}>
              Sync Schedule
            </DropdownMenu.Item>
            <DropdownMenu.Item onClick={() => startSyncExecutable.execute()}>
              Start Sync
            </DropdownMenu.Item>
          </>
        ) : null
      }
    </WithFallback>
  );
};
