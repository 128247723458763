import { useCallback, useMemo, useState } from "react";
import { LuBarChart4, LuSparkles } from "react-icons/lu";
import {
  CanvasCellClientModelUnion,
  CanvasCellPromptClientModel,
} from "~/clientModel/canvas";
import { Executable } from "~/clientModel/executable";
import { Flex } from "~/components_next/Flex";
import { Input } from "~/components_next/Input";
import { Text } from "~/components_next/Text";
import { styled } from "~/stitches";
import { useGetAncestorVeiwOrSourceCells } from "../../providers/PlaygroundAncestorUtilProvider";

import { P, match } from "ts-pattern";
import { CanvasCellViewClientModel } from "~/clientModel/canvas/CanvasCellClientModel";
import { SidebarPromptXYSelectView } from "./SideberPromptXYSelect";
import { TextArea } from "~/components_next/TextArea";
import {
  useUseMyColorsLoadable,
  useUseDeleteMyColorsExecutable,
  useUseVisualizationPromptConfigsLoadable,
} from "../../providers/PlaygroundCreateCellExecutableProvider";
import { StyleChip } from "../../createCell/promptStyle/StyleChip";
import { useDisclosure } from "~/hooks/useDisclosure";
import { GenerateMyColorsNativeDialog } from "../../createCell/promptStyle/GenerateMyColorsDialog";
import { Box } from "@radix-ui/themes";
import { Button } from "~/components_next/Button";
import { BsPlus } from "react-icons/bs";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { Editor } from "@monaco-editor/react";
import { CustomPromptInput } from "./CustomPromptInput";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { fromLoadables } from "~/clientModel/loadable";
import { CanvasVisualizationPromptConfigsCientModel } from "~/clientModel/canvas/CanvasVisualizationPromptConfigClientModel";

type PlaygroundSidebarPromptProps = {
  cell: CanvasCellPromptClientModel;
  updateCellExecutable: Executable<
    {
      cell: CanvasCellClientModelUnion;
      shouldGenerate?: boolean;
    },
    CanvasCellClientModelUnion
  >;
};

const ChartIcon = styled(LuBarChart4, {
  color: "$purple10",
});

const AIIcon = styled(LuSparkles, {
  color: "$purple10",
});

const PlaygroundSidebarPrompt = (props: PlaygroundSidebarPromptProps) => {
  const { cell, updateCellExecutable } = props;

  const [editingCell, setEditingCell] =
    useState<CanvasCellPromptClientModel | null>(cell);

  const getAncestorViewOrSourceCells = useGetAncestorVeiwOrSourceCells();

  const ancestorViewOrSourceCells = useMemo(() => {
    return getAncestorViewOrSourceCells(cell);
  }, [cell, getAncestorViewOrSourceCells]);

  const ancestorViewOrSourceCell = useMemo(() => {
    if (ancestorViewOrSourceCells.length > 0) {
      return ancestorViewOrSourceCells[0];
    }
    return null;
  }, [ancestorViewOrSourceCells]);

  const useMyColorsLoadable = useUseMyColorsLoadable();
  const myColorsLoadable = useMyColorsLoadable();

  const useDeleteMyColorsExecutable = useUseDeleteMyColorsExecutable();
  const deleteMyColorsExecutable = useDeleteMyColorsExecutable();

  const handleDeleteMyColor = (colorCodeId: string) => {
    deleteMyColorsExecutable.execute({ colorCodeId });
  };

  const generateDialogDisclosure = useDisclosure();

  const handleUpdateCell = () => {
    if (!editingCell) {
      return;
    }

    updateCellExecutable.execute({
      cell: editingCell,
    });
  };

  /**
   * Prompt Input
   */
  const useVisualizationConfigsLoadable =
    useUseVisualizationPromptConfigsLoadable();
  const visualizationConfigsLoadable = useVisualizationConfigsLoadable();
  const findVisualizationConfig = useCallback(
    (visualizationConfigs: CanvasVisualizationPromptConfigsCientModel) => {
      return visualizationConfigs.configs.find(
        (config) => config.chartType === editingCell?.chartType
      );
    },
    [editingCell]
  );
  const visualizationConfigLoadable = useMemo(() => {
    return fromLoadables(
      [visualizationConfigsLoadable],
      findVisualizationConfig
    );
  }, [findVisualizationConfig, visualizationConfigsLoadable]);

  const chartTypeOptions = useMemo(() => {
    if (
      visualizationConfigLoadable.data &&
      visualizationConfigLoadable.data.chartType === "combinationChart"
    ) {
      return visualizationConfigsLoadable.data?.configs.filter(
        (config) =>
          config.chartType === "barChart" || config.chartType === "lineChart"
      );
    }
    return undefined;
  }, [visualizationConfigLoadable, visualizationConfigsLoadable.data?.configs]);

  return (
    <>
      {editingCell && (
        <Flex direction="column" gap="4" py="2">
          <Flex gap="1" align="center">
            <AIIcon />
            <ChartIcon />
            <Text fontWeight="medium">
              AI Visualization / {cell?.chartTypeLabel}
            </Text>
          </Flex>

          <Flex direction="column" gap="2">
            <Text variant="description">Lables</Text>
            <Input
              variant="primary"
              label="Title"
              value={editingCell.title}
              onChange={(e) =>
                setEditingCell(editingCell.updateTitle(e.target.value))
              }
            />
            <WithFallback loadables={[visualizationConfigLoadable]}>
              {([visualizationConfig]) => (
                <>
                  {visualizationConfig?.hasXYAxis && (
                    <Flex gap="4">
                      <Input
                        variant="primary"
                        label="X Axis Label"
                        value={editingCell?.xAxisFieldLabel}
                        onChange={(e) =>
                          setEditingCell(
                            editingCell.updateXAxisLabel(e.target.value)
                          )
                        }
                      />
                      <Input
                        variant="primary"
                        label="Y Axis Label"
                        value={editingCell?.yAxisFieldLabel}
                        onChange={(e) =>
                          setEditingCell(
                            editingCell.updateYAxisLabel(e.target.value)
                          )
                        }
                      />
                    </Flex>
                  )}
                </>
              )}
            </WithFallback>
          </Flex>

          {ancestorViewOrSourceCell && (
            <WithFallback loadables={[visualizationConfigLoadable]}>
              {([visualizationConfig]) => {
                if (!visualizationConfig?.hasXYAxis) {
                  return null;
                }

                return (
                  <Flex direction="column" gap="2">
                    <Text variant="description">Series</Text>
                    {match([ancestorViewOrSourceCell, visualizationConfig])
                      .with(
                        [
                          P.instanceOf(CanvasCellViewClientModel),
                          P.not(undefined),
                        ],
                        ([parentCell, config]) => (
                          <>
                            <SidebarPromptXYSelectView
                              parentCell={parentCell}
                              visualizationConfig={config}
                              xAxisObject={editingCell.xAxisFieldObject}
                              yAxisObject={editingCell.yAxisFieldObject}
                              onChange={({ xAxisObject, yAxisObject }) => {
                                setEditingCell(
                                  editingCell.updateXYAxis(
                                    xAxisObject || undefined,
                                    yAxisObject || undefined
                                  )
                                );
                              }}
                              chartTypeOptions={chartTypeOptions}
                            />
                          </>
                        )
                      ) // いったんSourceはありえない仕様 (v1.1.1)
                      .otherwise(() => (
                        <>
                          <p>xyselect</p>
                        </>
                      ))}
                  </Flex>
                );
              }}
            </WithFallback>
          )}

          <Flex direction="column" gap="2">
            <Text variant="description">Custom Prompt</Text>
            {ancestorViewOrSourceCell && (
              <>
                {match(ancestorViewOrSourceCell)
                  .with(
                    P.instanceOf(CanvasCellViewClientModel),
                    (parentCell: CanvasCellViewClientModel) => (
                      <CustomPromptInput
                        editingCell={editingCell}
                        parentCell={parentCell}
                        onUpdateValue={(value) =>
                          setEditingCell(editingCell.updatePrompt(value))
                        }
                      />
                    )
                  )
                  .otherwise(() => (
                    <>
                      <TextArea
                        variant="primary"
                        value={editingCell.prompt}
                        onChange={(e) =>
                          setEditingCell(
                            editingCell.updatePrompt(e.target.value)
                          )
                        }
                      />
                    </>
                  ))}
              </>
            )}
          </Flex>

          {editingCell.generatedSource && (
            <Box>
              <SimpleDropdownMenu
                trigger={
                  <Button variant="tertiary" size="xs">
                    Code
                  </Button>
                }
              >
                <Editor
                  width={"600px"}
                  height={"400px"}
                  language={"python"}
                  theme={"vs-dark"}
                  value={editingCell.generatedSource}
                  options={{
                    readOnly: true,
                    minimap: {
                      enabled: false,
                    },
                    padding: {
                      top: 24,
                      bottom: 60,
                    },
                  }}
                />
              </SimpleDropdownMenu>
            </Box>
          )}

          <Flex direction="column" gap="2">
            <Text variant="description">Style</Text>
            <Flex gap="2" wrap="wrap">
              {myColorsLoadable.data?.items.map((item, index) => {
                return (
                  <StyleChip
                    key={index}
                    isSelected={editingCell.isSameColorCodes(item.colorCodes)}
                    colorCodes={item.colorCodes}
                    onClick={() =>
                      setEditingCell(
                        editingCell.updateColorCodes(item.colorCodes)
                      )
                    }
                    onDelete={() => handleDeleteMyColor(item.colorCodeId)}
                  />
                );
              })}
              {/* {presetColorsLoadable.data?.items.map((item, index) => {
                return (
                  <StyleChip
                    key={index}
                    isSelected={selectedStyle?.colorCodeId === item.colorCodeId}
                    colorCodes={item.colorCodes}
                    onClick={() =>
                      setSelectedStyle({
                        colorCodeId: item.colorCodeId,
                      })
                    }
                  />
                );
              })} */}
            </Flex>
          </Flex>

          <Box px="2">
            <Button
              variant="actionText"
              size="sm"
              onClick={() => generateDialogDisclosure.onOpen()}
            >
              <BsPlus />
              Create New Style
            </Button>
          </Box>

          <Flex gap="4">
            <Button
              variant="primary"
              size="sm"
              onClick={handleUpdateCell}
              isLoading={updateCellExecutable.isExecuting}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      )}

      <GenerateMyColorsNativeDialog
        isOpen={generateDialogDisclosure.isOpen}
        onIsOpenChange={generateDialogDisclosure.setIsOpen}
      />
    </>
  );
};

export { PlaygroundSidebarPrompt };
