import { useQueryClient } from "react-query";
import { simpleFieldsKeys, allSimpleFieldsKeys } from "../keys";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import useApiFields from "~/api/useApiField";
import { recordsKeys } from "~/serverStateStore/records/keys";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseDeleteSimpleFieldMutationParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
}

const useDeleteSimpleFieldMutation = ({
  databaseId,
  tableSlug,
  teamSlug,
}: UseDeleteSimpleFieldMutationParams) => {
  const { _deleteSimpleField } = useApiFields();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({ fieldName }: { fieldName: string }) => {
      return _deleteSimpleField({
        teamSlug,
        databaseId,
        tableSlug,
        fieldName,
      });
    },
    onSuccess: () => {
      const getAllSimpleField = allSimpleFieldsKeys.getAllSimpleField({ teamSlug, databaseId })
      const getSimpleField = simpleFieldsKeys.getSimpleField({ teamSlug, databaseId, tableSlug })
      const allGetRecord = recordsKeys.allGetRecord({
        teamSlug,
        databaseId,
        tableSlug,
      })
      const allGetRecordWithSql = recordsKeys.allGetRecordWithSql({
        teamSlug,
        databaseId,
        tableSlug,
      })
      broadcast(getAllSimpleField);
      broadcast(getSimpleField);
      broadcast(allGetRecord);
      broadcast(allGetRecordWithSql);

      return invalidateAll(
        client,
        getAllSimpleField,
        getSimpleField,
        allGetRecord,
        allGetRecordWithSql,
      );
    },
  };
};

export { useDeleteSimpleFieldMutation };
