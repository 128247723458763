import { memo, ReactNode } from "react";
import {
  RecordsTableBaseProvider,
  useRecordsTableBaseHandleTableSelectionChange,
  useRecordsTableBaseOptions,
  useRecordsTableBaseTableSelection,
} from "./context/ReactTableBaseContext";
import SimpleBar from "simplebar-react";
import { RecordsClientModel } from "~/clientModel/records/RecordsClientModel";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { Rows } from "./Rows/Rows";
import { FieldClientModel } from "~/clientModel/fields/field";
import { TableSelectionClientModel } from "~/clientModel/tables/tableMeta/TableSelection";
import { TableColumnSizingClientModel } from "~/clientModel/tables/tableMeta/TableColumnSizing/TableColumnSizingClientModel";
import { useRecordsTableVirtualizers } from "./useRecordsTableVirtualizers";
import { EditingCell } from "./Cell/EditingCell";
import { RecordClientModel } from "~/clientModel/records/record";
import { useKeyActions } from "./KeyActions/useKeyActions";
import { UseExecutable } from "~/clientModel/executable";
import { TableCoworkersStateClientModel } from "~/clientModel/tables/tableMeta/tableCoworkersState";
import { useClickAway } from "react-use";
import { EditingRecordClientModel } from "~/clientModel/records/editingRecord";

type RecordsTableProps = {
  /**
   * Options
   */
  isSelectable?: boolean;
  isOpenable?: boolean;
  isReorderable?: boolean;
  isReadOnly?: boolean; // todo: 実装
  // editableFields?: string[] | "all" | "none";
  noPadding?: boolean;
  propagateEvent?: boolean;
  noFieldTypeIcon?: boolean;
  noStickyHeader?: boolean;
  showMergeFieldIndicator?: boolean;

  /**
   * Data
   */
  records: RecordsClientModel;
  fields: FieldsClientModel;
  tableSelection?: TableSelectionClientModel;
  onTableSelectionChange?: (tableSelection: TableSelectionClientModel) => void;

  // state
  columnSizing?: TableColumnSizingClientModel;
  coworkersState?: TableCoworkersStateClientModel;
  headerDropdown?: (field: FieldClientModel, index: number) => ReactNode;

  /**
   * Handlers
   */
  onRecordClick?: (record: RecordClientModel) => void;
  onAddFieldClick?: () => void;
  // onReorderFields?: (fieldsOrder: string[]) => void;
  onColumnSizingChange?: (columnSizing: TableColumnSizingClientModel) => void;
  useUpdateRecordExecutable?: UseExecutable<
    void,
    { editingRecord: EditingRecordClientModel }
  >;
  useRunSmartFieldExecutable?: UseExecutable<
    void,
    {
      records?: RecordClientModel[];
      fields?: FieldClientModel[];
      onlyError: boolean;
    }
  >;
  useUploadFileExecutable?: UseExecutable<
    void,
    { file: File },
    {
      data: string;
      url: string;
    }
  >;
};

const RecordsTableRoot = () => {
  const { propagateEvent } = useRecordsTableBaseOptions();

  const tableSelection = useRecordsTableBaseTableSelection();
  const onTableSelectionChange =
    useRecordsTableBaseHandleTableSelectionChange();
  /**
   * Virtual scroll
   */
  const { parentRef, rowVirtualizer, columnVirtualizer } =
    useRecordsTableVirtualizers();

  useKeyActions();

  useClickAway(parentRef, () => {
    onTableSelectionChange(tableSelection.clearAllCellSelection());
  });

  return (
    <SimpleBar
      scrollableNodeProps={{ ref: parentRef }}
      style={{
        height: "100%",
        width: "100%",
        overflow: "auto",
        position: "relative",
        border: "none",
        overscrollBehavior: "none",
      }}
      onClick={(e) => {
        if (!propagateEvent) {
          e.stopPropagation();
        }
      }}
    >
      <Rows
        rowVirtualizer={rowVirtualizer}
        columnVirtualizer={columnVirtualizer}
      />
      {tableSelection && onTableSelectionChange && (
        <EditingCell
          tableSelection={tableSelection}
          onTableSelectionChange={onTableSelectionChange}
        />
      )}
    </SimpleBar>
  );
};

const RecordsTable = memo(function RecordsTable(props: RecordsTableProps) {
  return (
    <RecordsTableBaseProvider {...props}>
      <RecordsTableRoot />
    </RecordsTableBaseProvider>
  );
});

export { RecordsTable, type RecordsTableProps };
