/**
 * filterFnのユーティリティ関数
 * BasicTypeaheadPromptのpropsに渡すfilterFnは、特別な事情がなければここにあるものを使ってください。
 */

import {
  DashboardTableObject,
  SimpleField,
} from "@usemorph/morph-dashboard-types";

const basicFieldFilterFn = (
  field: Pick<SimpleField, "name" | "displayName">,
  query: string
): boolean => {
  if (field.name.includes(query)) {
    return true;
  }

  if (field.displayName?.includes(query)) {
    return true;
  }

  return false;
};

const basicTableFilterFn = (
  table: Pick<DashboardTableObject, "tableSlug" | "displayName">,
  query: string
): boolean => {
  if (table.tableSlug.includes(query)) {
    return true;
  }

  if (table.displayName?.includes(query)) {
    return true;
  }

  return false;
};

const basicVariableFilterFn = (
  variable: { name: string },
  query: string
): boolean => {
  if (variable.name.includes(query)) {
    return true;
  }

  return false;
};

export { basicFieldFilterFn, basicTableFilterFn, basicVariableFilterFn };
