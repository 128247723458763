import { useMemo, useState, ChangeEvent } from "react";
import { DashboardCreateDatabaseRequestBody } from "@usemorph/morph-dashboard-types";
import { CreateDatabaseMutationProps } from "~/serverStateStore";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useErrorToast } from "~/components_next/Error";
import { Dialog } from "~/components_next/Dialog";
import useClipboard from "react-use-clipboard";
import { Text } from "~/components_next/Text";
import { Input } from "~/components_next/Input";
import { SimpleSelect } from "~/components_next/Select";
import { stringToBoolean } from "~/utils/stringUtils";
import { Code } from "@radix-ui/themes";
import { Checkbox } from "~/components_next/Checkbox";
import { IconButton } from "~/components_next/IconButton";
import { Button } from "~/components_next/Button";
import { TextArea } from "~/components_next/TextArea";
import { Flex } from "~/components_next/Flex";
import { Box } from "~/components_next/Box";
import { DatabaseClientModel } from "~/clientModel/database/DatabaseClientModel";
import { Executable } from "~/clientModel/executable";
import { useNavigate } from "react-router-dom";

import { AiOutlineCheck, AiOutlineCopy } from "react-icons/ai";
import { ListDatabaseClientModel } from "~/clientModel/database/listDatabaseClientModel";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";

export const ConnectToExistingDatabaseDialog = ({
  isOpen,
  setIsOpen,
  onClose,
  createDatabaseExecutable,
  listDatabaseRefetcher,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose: () => void;
  createDatabaseExecutable: Executable<
    CreateDatabaseMutationProps,
    DatabaseClientModel
  >;
  listDatabaseRefetcher: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<ListDatabaseClientModel, unknown>>;
}) => {
  const teamSlug = useTeamSlug();
  const navigate = useNavigate();
  const { errorToast } = useErrorToast({});

  const [isCopied, setCopied] = useClipboard("35.79.243.33", {
    successDuration: 1000,
  });

  const [requestBody, setRequestBody] =
    useState<DashboardCreateDatabaseRequestBody>({
      databaseName: "",
      protocol: "postgresql",
      host: "",
      port: 5432,
      database: "",
      username: "",
      password: "",
      isBuiltin: false,
    });

  const handleChangeRequestBody = ({
    id,
    value,
  }: {
    id: string;
    value: string;
  }) => {
    if (id === "isBuiltin") {
      setRequestBody({
        ...requestBody,
        isBuiltin: stringToBoolean(value),
      });
    } else if (id === "port") {
      setRequestBody({
        ...requestBody,
        port: Number(value),
      });
    } else if (id === "protocol") {
      setRequestBody({
        ...requestBody,
        [id]: value,
        port: value === "postgresql" ? 5432 : 3306,
      });
    } else {
      setRequestBody({
        ...requestBody,
        [id]: value,
      });
    }
  };

  ///

  const [isSshAuthentication, setIsSshAuthentication] = useState(false);
  const onChangeIsSshAuthentication = (isChecked: boolean) => {
    setRequestBody({
      ...requestBody,
      option: isChecked
        ? {
          sshPortForwardConfig: {
            username: "",
            host: "",
            // port: requestBody.protocol === 'postgresql' ? 5432 : 3306,
            port: 22,
            password: "",
            privateKey: "",
            passphrase: "",
          },
        }
        : undefined,
    });
    setIsSshAuthentication(isChecked);
  };

  const [sshAuthentication, setSshAuthentication] = useState<
    "sshKey" | "password"
  >("sshKey");

  const handleChangeOptionValue = (
    event: ChangeEvent,
    type: "text" | "number"
  ) => {
    if (
      requestBody &&
      requestBody.option &&
      requestBody.option.sshPortForwardConfig
    ) {
      const value =
        type === "number"
          ? Number((event.target as HTMLInputElement).value)
          : (event.target as HTMLInputElement).value;
      setRequestBody({
        ...requestBody,
        option: {
          sshPortForwardConfig: {
            ...requestBody.option.sshPortForwardConfig,
            [event.target.id]: value,
          },
        },
      });
    }
  };

  ///

  const [isCreatingNewDatabase, setIsCreatingNewDatabase] =
    useState<boolean>(false);

  const isNewDatabaseValueValid = useMemo((): boolean => {
    return requestBody.databaseName !== undefined;
  }, [requestBody]);

  const resetValues = () => {
    setRequestBody({
      databaseName: "",
      protocol: "postgresql",
      host: "",
      port: 22,
      database: "",
      username: "",
      password: "",
      isBuiltin: false,
    });
  };

  const isValid = useMemo(() => {
    if (
      !requestBody.databaseName ||
      !requestBody.host ||
      !requestBody.port ||
      !requestBody.database ||
      !requestBody.username ||
      !requestBody.password
    ) {
      return false;
    } else if (
      isSshAuthentication &&
      (!requestBody.option ||
        !requestBody.option.sshPortForwardConfig ||
        !requestBody.option.sshPortForwardConfig.host ||
        !requestBody.option.sshPortForwardConfig.port ||
        !requestBody.option.sshPortForwardConfig.username)
    ) {
      return false;
    } else if (
      isSshAuthentication &&
      sshAuthentication === "sshKey" &&
      !requestBody.option?.sshPortForwardConfig?.privateKey
    ) {
      return false;
    } else if (
      isSshAuthentication &&
      sshAuthentication === "password" &&
      !requestBody.option?.sshPortForwardConfig?.password
    ) {
      return false;
    } else {
      return true;
    }
  }, [requestBody, isSshAuthentication, sshAuthentication]);

  const handleClickCreate = async () => {
    setIsCreatingNewDatabase(true);
    if (
      isSshAuthentication &&
      sshAuthentication === "sshKey" &&
      requestBody.option &&
      requestBody.option.sshPortForwardConfig
    ) {
      requestBody.option.sshPortForwardConfig.password = undefined;
    } else if (
      isSshAuthentication &&
      sshAuthentication === "password" &&
      requestBody.option &&
      requestBody.option.sshPortForwardConfig
    ) {
      requestBody.option.sshPortForwardConfig.privateKey = undefined;
      requestBody.option.sshPortForwardConfig.passphrase = undefined;
    }
    try {
      await createDatabaseExecutable.execute(requestBody);
      await listDatabaseRefetcher();
      resetValues();
      onClose();
    } catch (error) {
      errorToast(error);
    } finally {
      setIsCreatingNewDatabase(false);
    }
  };

  return (
    <>
      <Dialog.Root open={isOpen} onOpenChange={setIsOpen} size="xs">
        <Dialog.Content>
          <Dialog.Title>Connect to existing database</Dialog.Title>
          <Dialog.Body>
            <Flex mt="5" width="100%">
              <Box grow="1">
                <Flex align={`center`} mb="3">
                  <Box style={{ width: "160px" }}>
                    <Text>Database Name</Text>
                  </Box>
                  <Box grow="1">
                    <Input
                      id={"databaseName"}
                      variant="primary"
                      value={requestBody.databaseName}
                      onChange={(e) =>
                        handleChangeRequestBody({
                          id: "databaseName",
                          value: e.target.value,
                        })
                      }
                    />
                  </Box>
                </Flex>

                <Flex align={`center`} mb="3">
                  <Box style={{ width: `160px` }}>
                    <Text>Protocol</Text>
                  </Box>
                  <Box grow="1">
                    <SimpleSelect
                      variant="primary"
                      value={requestBody.protocol}
                      onChange={(e) => {
                        if (e) {
                          handleChangeRequestBody({ id: "protocol", value: e });
                        }
                      }}
                      options={[
                        { label: "PostgreSQL", value: "postgresql" },
                        { label: "mysql", value: "MySQL" },
                      ]}
                    />
                  </Box>
                </Flex>

                <Flex align={`center`} mb="3">
                  <Box style={{ width: `160px` }}>
                    <Text>Host</Text>
                  </Box>
                  <Box grow="1">
                    <Input
                      id={"host"}
                      variant="primary"
                      value={requestBody.host}
                      onChange={(e) =>
                        handleChangeRequestBody({
                          id: "host",
                          value: e.target.value,
                        })
                      }
                    />
                  </Box>
                </Flex>

                <Flex align={`center`} mb="3">
                  <Box style={{ width: `160px` }}>
                    <Text>Username</Text>
                  </Box>
                  <Box grow="1">
                    <Input
                      id={"username"}
                      variant="primary"
                      value={requestBody.username}
                      onChange={(e) =>
                        handleChangeRequestBody({
                          id: "username",
                          value: e.target.value,
                        })
                      }
                    />
                  </Box>
                </Flex>

                <Flex align={`center`} mb="5">
                  <Box style={{ width: `160px` }}>
                    <Text>Password</Text>
                  </Box>
                  <Box grow="1">
                    <Input
                      id={"password"}
                      variant="primary"
                      value={requestBody.password}
                      onChange={(e) =>
                        handleChangeRequestBody({
                          id: "password",
                          value: e.target.value,
                        })
                      }
                      type={"password"}
                    />
                  </Box>
                </Flex>

                <Flex align={`center`} mb="3">
                  <Box style={{ width: `160px` }}>
                    <Text>Port</Text>
                  </Box>
                  <Box grow="1">
                    <Input
                      id={"port"}
                      variant="primary"
                      value={requestBody.port}
                      onChange={(e) =>
                        handleChangeRequestBody({
                          id: "port",
                          value: e.target.value,
                        })
                      }
                      type={"number"}
                    />
                  </Box>
                </Flex>

                <Flex align={`center`} mb="7">
                  <Box style={{ width: `160px` }}>
                    <Text>Database</Text>
                  </Box>
                  <Box grow="1">
                    <Input
                      id={"database"}
                      variant="primary"
                      value={requestBody.database}
                      onChange={(e) =>
                        handleChangeRequestBody({
                          id: "database",
                          value: e.target.value,
                        })
                      }
                    />
                  </Box>
                </Flex>

                <Flex align={`center`} mb="7">
                  <Box style={{ width: `160px` }}>
                    <Text>Enable SSH tunnel</Text>
                  </Box>
                  <Flex align={"center"} grow="1">
                    <Checkbox
                      value={isSshAuthentication}
                      onChange={(e) => onChangeIsSshAuthentication(e)}
                    />
                  </Flex>
                </Flex>

                {isSshAuthentication && (
                  <>
                    <Flex align={`center`} mb="3">
                      <Box style={{ width: `160px` }}>
                        <Text>SSH authentication</Text>
                      </Box>
                      <Flex align={"center"} grow="1">
                        <SimpleSelect
                          variant="primary"
                          value={sshAuthentication}
                          onChange={(e) =>
                            setSshAuthentication(e as "sshKey" | "password")
                          }
                          options={[
                            { label: "SSH key", value: "sshKey" },
                            { label: "password", value: "Password" },
                          ]}
                        />
                      </Flex>
                    </Flex>

                    <Flex align={`center`} mb="3">
                      <Box style={{ width: `160px` }}>
                        <Text>Host</Text>
                      </Box>
                      <Box grow="1">
                        <Input
                          id={"host"}
                          variant="primary"
                          value={requestBody.option?.sshPortForwardConfig?.host}
                          onChange={(el) => handleChangeOptionValue(el, "text")}
                        />
                      </Box>
                    </Flex>

                    <Flex align={`center`} mb="3">
                      <Box style={{ width: `160px` }}>
                        <Text>Username</Text>
                      </Box>
                      <Box grow="1">
                        <Input
                          id={"username"}
                          variant="primary"
                          value={
                            requestBody.option?.sshPortForwardConfig?.username
                          }
                          onChange={(el) => handleChangeOptionValue(el, "text")}
                        />
                      </Box>
                    </Flex>

                    {sshAuthentication === "password" && (
                      <Flex align={`center`} mb="5">
                        <Box style={{ width: `160px` }}>
                          <Text>Password</Text>
                        </Box>
                        <Box grow="1">
                          <Input
                            id={"password"}
                            variant="primary"
                            value={
                              requestBody.option?.sshPortForwardConfig
                                ?.password ?? undefined
                            }
                            onChange={(el) =>
                              handleChangeOptionValue(el, "text")
                            }
                            type={"password"}
                          />
                        </Box>
                      </Flex>
                    )}

                    <Flex align={`center`} mb="3">
                      <Box style={{ width: `160px` }}>
                        <Text>Port</Text>
                      </Box>
                      <Box grow="1">
                        <Input
                          id={"port"}
                          variant="primary"
                          value={requestBody.option?.sshPortForwardConfig?.port}
                          onChange={(el) =>
                            handleChangeOptionValue(el, "number")
                          }
                          type={"number"}
                        />
                      </Box>
                    </Flex>

                    {sshAuthentication === "sshKey" && (
                      <>
                        <Flex align={`center`} mb="3">
                          <Box style={{ width: `160px` }}>
                            <Text>Private key</Text>
                          </Box>
                          <Box grow="1">
                            <TextArea
                              id={"privateKey"}
                              variant="primary"
                              value={
                                requestBody.option?.sshPortForwardConfig
                                  ?.privateKey ?? undefined
                              }
                              onChange={(el) =>
                                handleChangeOptionValue(el, "text")
                              }
                            />
                          </Box>
                        </Flex>

                        <Flex align={`center`} mb="3">
                          <Box style={{ width: `160px` }}>
                            <Text>Passphrase</Text>
                          </Box>
                          <Box grow="1">
                            <Input
                              id={"passphrase"}
                              variant="primary"
                              value={
                                requestBody.option?.sshPortForwardConfig
                                  ?.passphrase ?? undefined
                              }
                              onChange={(el) =>
                                handleChangeOptionValue(el, "text")
                              }
                            />
                          </Box>
                        </Flex>
                      </>
                    )}
                  </>
                )}
              </Box>

              <Box px="2">
                <Box
                  style={{
                    // backgroundColor: ipCardBgColor
                    borderRadius: "md",
                    width: "180px",
                  }}
                  p="3"
                >
                  <Text variant="subheading">Allowlist IPs</Text>
                  <Text variant="description" mb="2">
                    Ensure your database has allow-listed the following IPs
                  </Text>
                  <Flex mt="2">
                    <Code color="gray">35.79.243.33</Code>
                    <IconButton
                      variant="default"
                      tooltip="Copy"
                      size="xs"
                      icon={isCopied ? <AiOutlineCheck /> : <AiOutlineCopy />}
                      onClick={() => {
                        setCopied();
                      }}
                    />
                    {/* </Tooltip> */}
                  </Flex>
                </Box>
              </Box>
            </Flex>
          </Dialog.Body>
          <Dialog.Footer>
            <Dialog.Close>
              <Button
                variant="tertiary"
                size="sm"
                mr="3"
                isDisabled={isCreatingNewDatabase}
              >
                Close
              </Button>
            </Dialog.Close>
            <Button
              variant="primary"
              size="sm"
              isDisabled={
                !isValid || !isNewDatabaseValueValid || isCreatingNewDatabase
              }
              isLoading={isCreatingNewDatabase}
              onClick={handleClickCreate}
            >
              Create
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};
