import { FieldClientModel } from "~/clientModel/fields/field";
import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { StringValidator } from "./CommonValueValidator";
import {
  FilterOperationClientModelBase,
  FilterOperationWithValueInterface,
} from "./FilterOperationClientModelBase";

type StartsWitFilterOperationClientModelData = {
  value: string;
};

export class StartsWitFilterOperationClientModel
  extends FilterOperationClientModelBase
  implements
    FilterOperationWithValueInterface<
      string,
      StartsWitFilterOperationClientModel
    >
{
  readonly #data: StartsWitFilterOperationClientModelData;

  public constructor(data: StartsWitFilterOperationClientModelData) {
    super();
    this.#data = data;
  }

  public get data() {
    return this.#data;
  }

  public get type(): "startsWith" {
    return "startsWith";
  }

  public get operatorValue(): "startsWith" {
    return "startsWith";
  }

  public get operatorLabel(): "Starts with" {
    return "Starts with";
  }

  public get operandValue(): string {
    return this.#data.value;
  }

  public updateValue(value: string): StartsWitFilterOperationClientModel {
    return new StartsWitFilterOperationClientModel({
      ...this.#data,
      value,
    });
  }

  public validate(targetField: FieldClientModel): ValidationResult {
    return new StringValidator(this.#data.value)
      .shouldNotBeEmpty()
      .shouldBeIntStringIfNumberType(targetField)
      .end();
  }
}
