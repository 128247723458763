import { ScheduledTimeClientModel } from "./ScheduledTimeClientModel";

type ScheduledTimeClientModelData = {
  scheduledMins: number;
};

export class ScheduledTimeClientModelDefactory {
  public static toScheduledMins(
    scheduledTime: ScheduledTimeClientModel
  ): number {
    return scheduledTime.data.scheduledMins;
  }
}
