import { RecordClientModel } from "../record";
import { EditingRecordClientModel } from "./EditingRecordClientModel";

export class EditingRecordClientModelFactory {
  public static createFromClientRecordModel(
    record: RecordClientModel
  ): EditingRecordClientModel {
    return new EditingRecordClientModel({
      recordBeforeEdit: record,
      editingRecord: record,
    });
  }
}
