import { MdDragIndicator } from "react-icons/md";
import { Box } from "~/components_next/Box";
import { IconButton } from "~/components_next/IconButton";
import { styled } from "~/stitches";
import { PlaygroundCellCardVariant } from "./PlaygroundCellCardElement";
import { memo } from "react";

const DragHandleElement = styled(Box, {
  position: "absolute",
  variants: {
    variant: {
      default: {
        top: "5px",
        left: "5px",
      },
      fixed: {
        top: "5px",
        left: "5px",
      },
      skelton: {
        top: "0px",
        left: "0px",
      },
    },
  },
});

const DragHandle = memo((props: { variant: PlaygroundCellCardVariant }) => {
  return (
    <DragHandleElement
      className="notebook-cell-drag-handle"
      variant={props.variant}
    >
      <IconButton icon={<MdDragIndicator />} tooltip="Move" size="lg" />
    </DragHandleElement>
  );
});
DragHandle.displayName = "DragHandle";

export { DragHandleElement, DragHandle };
