import { Box } from "@radix-ui/themes";
import {
  DashboardTableObject,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { useCallback, useMemo } from "react";
import { match } from "ts-pattern";
import {
  basicFieldFilterFn,
  basicTableFilterFn,
  BasicTypeaheadPrompt,
  basicVariableFilterFn,
  FieldSuggestionItem,
  TableSuggestionItem,
  VariableSuggestionItem,
} from "~/features/Prompt";
import { ViewCellVariable } from "~/features/SourceAndViews/ViewCell/common/states/variables";
import { useAllSimpleFields } from "~/serverStateStore";
import { styled } from "~/stitches";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import "./prompt.css";

type SuggestOptionType =
  | {
      type: "field";
      value: SimpleField;
    }
  | {
      type: "table";
      value: Pick<DashboardTableObject, "tableSlug" | "displayName">;
    }
  | {
      type: "variable";
      value: ViewCellVariable;
    };

const promptTheme = {
  root: "notebook-prompt-editor__prompt-editor-root",
};

type NotebookPromptEditorProps = {
  source: string;
  onChange?: (source: string) => void;
  isDisabled: boolean;
  focusOnLoad?: boolean;
  variablesForTypehead?: ViewCellVariable[];
};

const Wrapper = styled(Box, {
  backgroundColor: "$bg0",
});

const NotebookPromptEditor = (props: NotebookPromptEditorProps) => {
  const {
    source,
    onChange,
    isDisabled,
    variablesForTypehead = [],
    focusOnLoad,
  } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const { data: allSimpleFieldsData } = useAllSimpleFields({
    teamSlug,
    databaseId,
  });

  const { tables, fields } = useMemo(() => {
    if (!allSimpleFieldsData)
      return {
        tables: [],
        fields: [],
      };

    const tables = allSimpleFieldsData.tables.map(
      ({ fields, ...table }) => table
    );

    const fields = allSimpleFieldsData.tables.flatMap(({ fields }) => fields);

    return {
      tables,
      fields,
    };
  }, [allSimpleFieldsData]);

  // ノートブックのテーブルを優先的に表示する
  // todo: この辺の細かい調整
  // const sortOptionsCompareFunc = useCallback(
  //   (tableSlugA: string, tableSlugB: string): number => {
  //     if (tableSlugA === notebookTableSlug) {
  //       return -1;
  //     } else if (tableSlugB === notebookTableSlug) {
  //       return 1;
  //     }
  //     return 0;
  //   },
  //   [notebookTableSlug]
  // );

  const renderSuggestedItem = useCallback((item: SuggestOptionType) => {
    return match(item)
      .with({ type: "field" }, ({ value }) => (
        <FieldSuggestionItem field={value} />
      ))
      .with({ type: "table" }, ({ value }) => (
        <TableSuggestionItem table={value} />
      ))
      .with({ type: "variable" }, ({ value }) => (
        <VariableSuggestionItem variable={value} />
      ))
      .exhaustive();
  }, []);

  const renderHTMLTextContent = useCallback((item: SuggestOptionType) => {
    return match(item)
      .with({ type: "field" }, ({ value }) => {
        return value.displayName || value.name;
      })
      .with({ type: "table" }, ({ value }) => {
        return value.displayName || value.tableSlug;
      })
      .with({ type: "variable" }, ({ value }) => {
        return value.name;
      })
      .exhaustive();
  }, []);

  const optionItems: SuggestOptionType[] = useMemo(() => {
    const fieldItems: SuggestOptionType[] = fields.map((field) => ({
      type: "field" as const,
      value: field,
    }));

    const tableItems: SuggestOptionType[] = tables.map((table) => ({
      type: "table" as const,
      value: {
        tableSlug: table.tableSlug,
        displayName: table.displayName ?? null,
      },
    }));

    const variableItems = variablesForTypehead.map((variable) => ({
      type: "variable" as const,
      value: variable,
    }));

    return [...variableItems, ...fieldItems, ...tableItems];
  }, [fields, tables, variablesForTypehead]);

  const filterFn = useCallback((item: SuggestOptionType, query: string) => {
    return match(item)
      .with({ type: "field" }, ({ value }) => basicFieldFilterFn(value, query))
      .with({ type: "table" }, ({ value }) => basicTableFilterFn(value, query))
      .with({ type: "variable" }, ({ value }) =>
        basicVariableFilterFn(value, query)
      )
      .exhaustive();
  }, []);

  const convertOptionItemToText = useCallback((item: SuggestOptionType) => {
    return match(item)
      .with(
        { type: "table" },
        ({ value }) => value.displayName || value.tableSlug
      )
      .with({ type: "field" }, ({ value }) => {
        return value.originalTableSlug
          ? `\${${value.originalTableSlug}.${value.name}}`
          : `\${${value.name}}`;
      })
      .with({ type: "variable" }, ({ value }) => {
        return `\${${value.cellId}+${value.name}}`;
      })
      .exhaustive();
  }, []);

  return (
    <Wrapper>
      <BasicTypeaheadPrompt<SuggestOptionType>
        textContent={source}
        onUpdate={onChange}
        theme={promptTheme}
        isReadOnly={isDisabled}
        renderHTMLTextContent={renderHTMLTextContent}
        renderSuggestedItem={renderSuggestedItem}
        optionItems={optionItems}
        filterFn={filterFn}
        convertOptionItemToText={convertOptionItemToText}
        focusOnLoad
      />
    </Wrapper>
  );
};

export { NotebookPromptEditor };
