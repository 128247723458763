import {
  DashboardTableObject,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { ReactNode } from "react";
import { Badge } from "~/components_next/Badge";
import { Flex } from "~/components_next/Flex";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";

/**
 *
 * BasicTypeaheadPromptのpropsに渡すrenderSuggestedItemは、特別な事情がなければここにあるものを使ってください。
 */
const LeftTypeTag = ({ children }: { children: ReactNode }) => {
  return (
    // 幅を揃える
    <Flex style={{ width: "60px" }} justify="start">
      <Badge variant="tertiary" size="xs">
        {children}
      </Badge>
    </Flex>
  );
};

const SuggestionItemWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Flex align="center" gap="2" pl="2" pr="4" py="1">
      {children}
    </Flex>
  );
};

type FieldSuggestionItemProps = {
  field: Pick<SimpleField, "name" | "displayName" | "originalTableSlug">;
};

const FieldSuggestionItem = ({ field }: FieldSuggestionItemProps) => {
  return (
    <SuggestionItemWrapper>
      <LeftTypeTag>Field</LeftTypeTag>
      <Text
        style={{
          overflowX: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {field.displayName || field.name}
      </Text>
      <Spacer />
      <Text variant="description" style={{ fontSize: "14px" }}>
        {field.originalTableSlug}
      </Text>
    </SuggestionItemWrapper>
  );
};

type TableSuggestionItemProps = {
  table: Pick<DashboardTableObject, "tableSlug" | "displayName">;
};

const TableSuggestionItem = ({ table }: TableSuggestionItemProps) => {
  return (
    <SuggestionItemWrapper>
      <LeftTypeTag>Table</LeftTypeTag>
      <Text>{table.displayName || table.tableSlug}</Text>
    </SuggestionItemWrapper>
  );
};

type VariableSuggestionItemProps = {
  variable: { name: string };
};

const VariableSuggestionItem = ({ variable }: VariableSuggestionItemProps) => {
  return (
    <SuggestionItemWrapper>
      <LeftTypeTag>Variable</LeftTypeTag>
      <Text>{variable.name}</Text>
    </SuggestionItemWrapper>
  );
};

export { FieldSuggestionItem, TableSuggestionItem, VariableSuggestionItem };
