// /* eslint-disable react/display-name */
import {
  DashboardTemplateAuthObject,
  IntegrationAuthConfig,
  IntegrationInputForm,
  IntegrationOutputTables,
} from "@usemorph/morph-dashboard-types";
import { FormEvent, useEffect, useState } from "react";
import { Checkbox } from "~/components_next/Checkbox";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";
import { useQueryClient } from "react-query";
import {
  importsKeys,
  UseImportDataFromExternalServicesMutationProps,
} from "~/serverStateStore/imports";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { extractErrorDetails } from "~/components_next/Error";
import { useNavigate } from "react-router-dom";
import { getPath } from "~/routing";
import { OutputTableInput } from "../Input/Table/OutputTableInput";
import {
  convertToScheduledMins,
  IntegrationScheduleForm,
} from "./IntegrationScheduleForm";
import dayjs from "dayjs";
import { Button } from "~/components_next/Button";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
import {
  AccordionContent,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
} from "~/components_next/Accordion/Accordion";
import { IntegrationInputs } from "../Input/Trigger/IntegrationInputs";
import { IntegrationAuthInput } from "../Input/Account/IntegrationAuthInput";
import { IntegrationOauthInput } from "../Input/Account/IntegrationOauthInput";
import * as Form from "@radix-ui/react-form";
import { SnowflakeAuthInput } from "../Input/Account/SnowflakeAuthInput";
import { Executable } from "~/clientModel/executable";
import { ImportIntegrationStatusClientModel } from "~/clientModel/template";
import { useImportOtherServiceModal } from "../../useImportOtherServiceModal";

interface IntegrationFormProps {
  authConfig: IntegrationAuthConfig;
  description: string;
  triggerDescription?: string;
  integrationId: string;
  integrationName: string;
  templateId: string;
  templateName: string;
  logoUrl: string;
  inputForm: IntegrationInputForm;
  authInputForm?: IntegrationInputForm;
  outputTables: IntegrationOutputTables;
  loginButtonImage?: { light: string; dark: string };
  syncOptions: { sync: boolean; append: boolean };
  onSubmitted: () => void;
  importDataFromExternalServicesExecutable: Executable<UseImportDataFromExternalServicesMutationProps, ImportIntegrationStatusClientModel>
}

export interface ScheduleCoreInput {
  targetDays: number[];
  category: "sync" | "append";
  scheduledMins: number;
  isEnabled: boolean;
}

export const IntegrationForm = (props: IntegrationFormProps) => {
  const databaseId = useDatabaseId();
  const teamSlug = useTeamSlug();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { modalState } = useImportOtherServiceModal();

  const {
    authConfig,
    description,
    triggerDescription,
    integrationId,
    integrationName,
    templateId,
    templateName,
    logoUrl,
    inputForm,
    authInputForm,
    outputTables,
    loginButtonImage,
    syncOptions,
    onSubmitted,
    importDataFromExternalServicesExecutable,
  } = props;

  const [accordingValue, setAccordingValue] = useState<
    "account" | "trigger" | "table" | "schedule"
  >("account");

  const [requestTemplateAuth, setRequestTemplateAuth] =
    useState<DashboardTemplateAuthObject>();

  const [requestData, setRequestData] = useState<{ [key: string]: unknown }>(
    {}
  );

  const [requestTables, setRequestTables] = useState<
    {
      slug: string;
      tableSlug?: string | undefined;
      displayName?: string | undefined;
    }[]
  >([]);

  useEffect(() => {
    const initialRequestTables = outputTables.map(ot => {
      return {
        slug: ot.slug,
        tableSlug: '',
        displayName: '',
      }
    })
    setRequestTables(initialRequestTables)
  }, [outputTables])
  const [tableSlugError, setTableSlugError] = useState<string>();

  const checkRequestTableInvalid = () => {
    if (requestTables.some((rt) => !rt.displayName || !rt.tableSlug)) {
      return "displayName & tableSlug is required.";
    }
    return false;
  };

  const [isSchedule, setIsSchedule] = useState(true);
  const [scheduleBody, setScheduleBody] = useState<ScheduleCoreInput>({
    targetDays: [0, 0, 0, 0, 0, 0, 0],
    category: syncOptions.sync ? "sync" : "append",
    scheduledMins: convertToScheduledMins(dayjs().format("hh:00")),
    isEnabled: true,
  });

  const [errorMessage, setErrorMessage] = useState<string>()

  const handleSubmit = async (el: FormEvent<HTMLFormElement>) => {
    el.preventDefault();
    setErrorMessage(undefined)
    try {
      if (tableSlugError) {
        throw Error(tableSlugError);
      }
      const invalidTables = checkRequestTableInvalid();
      if (invalidTables) {
        throw Error(invalidTables);
      }
      if (
        authConfig.apiType === "oauth2" &&
        !requestTemplateAuth?.templateAuthId
      ) {
        throw Error("Authentication required.");
      }
      const response = await importDataFromExternalServicesExecutable.execute({
        integrationId,
        data: requestData,
        templateAuthId: requestTemplateAuth?.templateAuthId,
        tables: requestTables,
        isPrivate: modalState.isOpen ? modalState.isPrivate : false,
        schedule:
          isSchedule && (syncOptions.sync || syncOptions.append)
            ? scheduleBody
            : undefined,
      });
      queryClient.invalidateQueries({
        queryKey: importsKeys.all({
          teamSlug,
          databaseId,
        }),
      });
      if (response.errors) {
        const error = JSON.stringify(response.errors);
        throw error;
      } else {
        navigate(
          getPath("source", {
            teamSlug,
            databaseId,
            tableSlug: response.tableSlug,
          })
        );
        // resetTableNameInputs();
        onSubmitted();
      }
    } catch (error) {
      const { title, description } = extractErrorDetails(error);
      setErrorMessage(`${title}: ${description}`)
    }
  };

  return (
    <Box css={{ height: "100%" }}>
      {/* {JSON.stringify(requestTemplateAuth)} */}
      {/* {JSON.stringify(requestData)} */}
      {/* {JSON.stringify(syncOptions)} */}
      {/* <form style={{ height: "100%" }} onSubmit={handleSubmit}> */}
      <Form.Root style={{ height: "100%" }} onSubmit={handleSubmit}>
        {/* <Box> */}
        <AccordionRoot
          type="single"
          value={accordingValue}
          onValueChange={(el) =>
            setAccordingValue(el as "account" | "trigger" | "table")
          }
          collapsible
        >
          <AccordionItem value="account">
            <AccordionTrigger>Account</AccordionTrigger>
            <AccordionContent>
              <Box px="5" py="2">
                {authConfig.apiType === "oauth2" && (
                  <IntegrationOauthInput
                    authConfig={authConfig}
                    integrationId={integrationId}
                    templateId={templateId}
                    templateName={templateName}
                    logoUrl={logoUrl}
                    loginButtonImage={loginButtonImage}
                    selectedTemplateAuth={requestTemplateAuth}
                    setSelectedTemplateAuth={setRequestTemplateAuth}
                    onContinue={() => setAccordingValue("trigger")}
                  />
                )}

                {
                  (authConfig.apiType === "orm" || authConfig.apiType === "apiKey")
                  && authInputForm
                  && authInputForm.inputs.length > 0
                  && (
                    <IntegrationAuthInput
                      authConfig={authConfig}
                      templateId={templateId}
                      templateName={templateName}
                      logoUrl={logoUrl}
                      loginButtonImage={loginButtonImage}
                      integrationId={integrationId}
                      authInputForm={authInputForm}
                      setRequestData={setRequestData}
                      selectedTemplateAuth={requestTemplateAuth}
                      setSelectedTemplateAuth={setRequestTemplateAuth}
                      onContinue={() => setAccordingValue("trigger")}
                    />
                  )}

                {authConfig.apiType === "snowflake" && authInputForm && authInputForm.inputs.length > 0 && (
                  <SnowflakeAuthInput
                    authConfig={authConfig}
                    integrationId={integrationId}
                    templateId={templateId}
                    templateName={templateName}
                    authInputForm={authInputForm}
                    logoUrl={logoUrl}
                    loginButtonImage={loginButtonImage}
                    selectedTemplateAuth={requestTemplateAuth}
                    setSelectedTemplateAuth={setRequestTemplateAuth}
                    onContinue={() => setAccordingValue("trigger")}
                  />
                )}
              </Box>
            </AccordionContent>
          </AccordionItem>

          {requestTemplateAuth && (
            <>
              <AccordionItem value="trigger">
                <AccordionTrigger>Settings</AccordionTrigger>
                <AccordionContent>
                  <Box px="5">
                    <Box>
                      <Text variant="subheading">{integrationName}</Text>
                    </Box>
                    <Box mb="3">
                      <Text variant="description">{description}</Text>
                    </Box>

                    <IntegrationInputs
                      templateId={templateId}
                      templateAuthId={requestTemplateAuth.templateAuthId}
                      integrationId={integrationId}
                      inputForm={inputForm}
                      requestData={requestData}
                      setRequestData={setRequestData}
                    />

                    <Box mb="5">
                      <Button
                        onClick={() => setAccordingValue("table")}
                        variant="primary"
                        size="sm"
                        type="button"
                      >
                        Continue
                      </Button>
                    </Box>
                  </Box>
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="table">
                <AccordionTrigger>Tables</AccordionTrigger>
                <AccordionContent>
                  <Box css={{ alignItems: "stretch" }} px="5" mb="3">
                    {outputTables &&
                      outputTables.map((ot) => {
                        return (
                          <Box key={ot.slug} mb="4">
                            <OutputTableInput
                              slug={ot.slug}
                              description={ot.description}
                              onChange={(table, error) => {
                                setTableSlugError(error);
                                requestTables.length === outputTables.length
                                  ? setRequestTables(
                                    requestTables.map((rt) => {
                                      if (rt.slug === table.slug) {
                                        return table;
                                      }
                                      return rt;
                                    })
                                  )
                                  : setRequestTables([...requestTables, table]);
                              }}
                            />
                          </Box>
                        );
                      })}

                    {(syncOptions.sync || syncOptions.append)
                      ? (
                        <Box mb="5">
                          <Button
                            onClick={() => setAccordingValue("schedule")}
                            variant="primary"
                            size="sm"
                            type="button"
                          >
                            Continue
                          </Button>
                        </Box>
                      )
                      : (
                        <Flex mt="5">
                          <Spacer />
                          <Button
                            variant="primary"
                            type="submit"
                            size="md"
                            isLoading={importDataFromExternalServicesExecutable.isExecuting}
                          >
                            Import data from {templateName}
                          </Button>
                        </Flex>
                      )
                    }
                  </Box>
                </AccordionContent>
              </AccordionItem>
            </>
          )}

          {(syncOptions.sync || syncOptions.append) && (
            <AccordionItem value="schedule">
              <AccordionTrigger>Scheduled</AccordionTrigger>
              <AccordionContent>
                <Box css={{ alignItems: "stretch" }} px="5" mb="3">
                  {/* {JSON.stringify(scheduleBody)} */}
                  <Box my="4">
                    <Checkbox
                      value={isSchedule}
                      size="sm"
                      label="Scheduled import"
                      onChange={(event) => setIsSchedule(event)}
                    />
                  </Box>
                  {isSchedule && syncOptions.append && (
                    <Text variant="description">
                      {triggerDescription}
                    </Text>
                  )}

                  {isSchedule && syncOptions.sync && (
                    <IntegrationScheduleForm
                      scheduleBody={scheduleBody}
                      syncOptions={syncOptions}
                      setScheduleBody={setScheduleBody}
                    />
                  )}

                  <Flex mt="5">
                    <Spacer />
                    <Button
                      variant="primary"
                      type="submit"
                      size="md"
                      isLoading={importDataFromExternalServicesExecutable.isExecuting}
                    >
                      Import data from {templateName}
                    </Button>
                  </Flex>
                </Box>
              </AccordionContent>
            </AccordionItem>
          )}
        </AccordionRoot>

        {
          errorMessage && (
            <Box>
              <Text variant="errorMessage">{errorMessage}</Text>
            </Box>
          )
        }

        {/* </Box> */}
      </Form.Root>
      {/* </form> */}
    </Box>
  );
};
