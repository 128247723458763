import { useMutation } from "react-query";
import { useErrorToast } from "~/components_next/Error";
import { getPrimaryKeyFilterCondtionFromRecordModel } from "~/features/RecordModel";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import {
  useRecordSelection,
  useSetRecordSelection,
} from "../../states/recordSelection";
import { useViewId } from "~/utilHooks/useViewId";
import { useDeleteViewRecordMutation } from "~/serverStateStore/views";
import { useView } from "~/features/SourceAndViews/common/utils/useView";
import { useDisclosure } from "~/hooks/useDisclosure";

// 一旦隠す
const SourceDeleteSelectedRecordsButton = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  const recordSelections = useRecordSelection(viewId);
  const setRecordsSelections = useSetRecordSelection(viewId);

  const deleteConfirmModal = useDisclosure();

  const { data: viewData } = useView();

  const { mutateAsync: deleteRecords, isLoading: isDeleting } = useMutation({
    ...useDeleteViewRecordMutation({
      teamSlug,
      databaseId,
      viewId,
    }),
  });

  const { errorToast } = useErrorToast({});

  const handleDelete = async () => {
    if (!recordSelections) return;

    const filters = recordSelections.map((record) => {
      const primaryKeyFilters = getPrimaryKeyFilterCondtionFromRecordModel(
        record.values
      );
      return primaryKeyFilters;
    });

    try {
      // TODO: サーバー仕様確認(現在の仕様だとN+1になる)
      // await deleteRecords({
      //   filter: {
      //     or: [...filters],
      //   },
      // });
    } catch (error) {
      errorToast(error);
    }

    setRecordsSelections([]);

    deleteConfirmModal.onClose();
  };

  return (
    <>
      {/* {viewData &&
        viewData.rules.delete &&
        recordSelections &&
        recordSelections.length > 0 && (
          <DeleteSelectedRecordsButton
            onClick={(event) => {
              event.stopPropagation();
              deleteConfirmModal.onOpen();
            }}
            isDeleting={isDeleting}
            onDeleteConfirm={handleDelete}
            isOpen={deleteConfirmModal.isOpen}
            onClose={deleteConfirmModal.onClose}
          />
        )} */}
    </>
  );
};

export { SourceDeleteSelectedRecordsButton as DeleteSelectedRecordsButton };
