type NotificationBodyStatusBase = {
  status: 'success' | 'failed';
  errors: string | null;
};

export type NotificationCsvBody = NotificationBodyStatusBase & {
  resourceLabels: ['database', 'table', 'team', 'importDataSchema'];
  resources: { database: string; table: string; team: string; importDataSchema: string };
};
export type NotificationIntegrationBody = NotificationBodyStatusBase & {
  resourceLabels: ['database', 'table', 'team', 'importIntegrationStatus', 'importIntegrationSchedule'];
  resources: {
    database: string;
    table: string;
    team: string;
    importIntegrationStatus?: string;
    importIntegrationSchedule?: string;
  };
};
export type NotificationNormalizationBody = NotificationBodyStatusBase & {
  resourceLabels: ['database', 'table', 'team', 'normalizedTableStatus'];
  resources: { database: string; table: string; team: string; normalizedTableStatus: string };
};

export type NotificationResources = {
  database: string;
  table: string;
  team: string;
  importDataSchema?: string;
  importIntegrationStatus?: string;
  importIntegrationSchedule?: string;
  normalizedTableStatus?: string
}

export type NotificationBody =
  | NotificationCsvBody
  | NotificationIntegrationBody
  | NotificationNormalizationBody;


export class NotificationBodyClientModel {
  constructor(readonly data: NotificationBody) { }

  get status(): 'success' | 'failed' {
    return this.data.status
  }

  get errors(): string | null {
    return this.data.errors
  }

  get resourceLabels(): string[] {
    return this.data.resourceLabels
  }

  get resources(): NotificationResources {
    return this.data.resources;
  }

  get formattedErrors(): string | null {
    if (this.errors) {
      return this.errors.replace(/\\n/g, '\n');
    }
    return null
  }

  get database(): string {
    return this.resources.database
  }

  get team(): string {
    return this.resources.team;
  }

  get table(): string {
    return this.resources.table
  }

}
