import { Tabs as RadixThemeTabs } from "@radix-ui/themes";
import { ReactNode } from "react";

type TabsTriggerProps = {
  children: ReactNode;
  value: string;
};

const TabsTrigger = (props: TabsTriggerProps) => {
  const { children, value } = props;

  return (
    <RadixThemeTabs.Trigger value={value}>{children}</RadixThemeTabs.Trigger>
  );
};

export { TabsTrigger, type TabsTriggerProps };
