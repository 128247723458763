import { useMemo } from "react";
import { UseQueryResult } from "react-query";
import { composeLoadable } from "./composeLoadable";
import { Loadable } from "./Loadable";

export const useComposeLoadable = <DataType, ErrorType>(
  useQueryResult: UseQueryResult<DataType, ErrorType>
): Loadable<DataType, ErrorType> => {
  const loadable = useMemo(() => {
    return composeLoadable(useQueryResult);
  }, [useQueryResult.data, useQueryResult.error, useQueryResult.status]);

  return loadable;
};
