import { DashboardStorageBucketObject } from "@usemorph/morph-dashboard-types";
import { useMutation } from "react-query";
import {
  useBuckets,
  useCreateBucketMutation,
} from "~/serverStateStore/buckets";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

export const useTableBucket = () => {
  const teamSlug = useTeamSlug();

  const { data } = useBuckets({ teamSlug, limit: 1000, skip: 0 });

  const createBucketOptions = useCreateBucketMutation({ teamSlug });
  const { mutateAsync: createBucket } = useMutation(createBucketOptions);

  const preprocessCreateCommonTableBucket = async ({
    databaseId,
    ...rest
  }: {
    databaseId: string;
  } & (
    | {
        tableSlug: string;
      }
    | {
        viewId: string;
      }
  )): Promise<DashboardStorageBucketObject> => {
    const identifier = "tableSlug" in rest ? rest.tableSlug : rest.viewId;
    const existingBucket = data?.buckets.find(
      (bucket) => bucket.bucketName === `${databaseId}_${identifier}`
    );

    if (existingBucket) {
      return Promise.resolve(existingBucket);
    } else {
      const createBucketResponse = await createBucket({
        bucketName: `${databaseId}_${identifier}`,
        readPermission: "forbidden",
        createPermission: "forbidden",
        updatePermission: "forbidden",
      });
      return Promise.resolve(createBucketResponse);
    }
  };

  return {
    preprocessCreateCommonTableBucket,
  };
};
