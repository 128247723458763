import { DashboardUpdateNormalizedTableScheduleRequestBody } from "@usemorph/morph-dashboard-types";
import { TimezoneClientModelDefactory } from "~/clientModel/timezone";
import { ComputedFieldSyncScheduleClientModel } from "./ComputedFieldSyncScheduleClientModel";
import { ScheduledTimeClientModelDefactory } from "../../../scheduleCore/ScheduledTime";
import { TargetDaysClientModelDefactory } from "../../../scheduleCore/targetDays";

export class ComputedFieldSyncScheduleClientModelDefactory {
  public static toDashboardUpdateNormalizedTableScheduleRequestBody({
    computedFieldSyncSchedule,
  }: {
    computedFieldSyncSchedule: ComputedFieldSyncScheduleClientModel;
  }): DashboardUpdateNormalizedTableScheduleRequestBody {
    const { targetDays, scheduledTime, isEnabled, timezone } =
      computedFieldSyncSchedule.data;

    return {
      targetDays: TargetDaysClientModelDefactory.toTargetDaysArray(targetDays),
      scheduledMins:
        ScheduledTimeClientModelDefactory.toScheduledMins(scheduledTime),
      isEnabled,
      timezone: TimezoneClientModelDefactory.toTimezoneType(timezone),
    };
  }
}
