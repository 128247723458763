import useApiTeam from "~/api/useApiTeam";

const useCheckTeamSlugMutation = () => {
  const { _checkTeamSlug } = useApiTeam();

  return {
    mutationFn: ({
      teamSlug,
    }: {
      teamSlug: string;
    }) => {
      return _checkTeamSlug({
        teamSlug,
      });
    },
  };
};

export { useCheckTeamSlugMutation };
