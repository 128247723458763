import { planKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiPlan from "~/api/useApiPlan";

interface UseListPlansQueryParams {
  teamSlug: string;
}

const useListPlansQuery = ({ teamSlug }: UseListPlansQueryParams) => {
  const { _listPlan } = useApiPlan();

  const client = useQueryClient();
  client.setQueryDefaults(planKeys.listPlans({ teamSlug }), commonQueryOptions);

  return {
    queryKey: planKeys.listPlans({
      teamSlug,
    }),
    queryFn: () => {
      return _listPlan({
        teamSlug,
      });
    },
  };
};

export { useListPlansQuery };
