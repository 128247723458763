import { MouseEventHandler } from "react";
import { BsPlus } from "react-icons/bs";
import { Button } from "~/components_next/Button";

type FooterProps = {
  onClickCreateRecordButton: MouseEventHandler<HTMLButtonElement>;
  isCreatingRecord: boolean;
  isCreateRecordButtonDisabled: boolean;
};

const Footer = (props: FooterProps) => {
  const {
    onClickCreateRecordButton,
    isCreatingRecord,
    isCreateRecordButtonDisabled,
  } = props;

  return (
    <Button
      isLoading={isCreatingRecord}
      isDisabled={isCreateRecordButtonDisabled}
      variant="primary"
      onClick={onClickCreateRecordButton}
      size="sm"
      style={{ width: "100%" }}
    >
      <BsPlus />
      Create Record
    </Button>
  );
};

export { Footer };
