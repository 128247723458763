import { useCallback } from "react";
import { UseExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { AggregateValueFieldOperatorsClientModel } from "~/clientModel/fields/field/fieldType/computed/aggregateValue/aggregateValueFieldOperators";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";
import { Loadable } from "~/clientModel/loadable";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { RecordsClientModel } from "~/clientModel/records";
import { TablesClientModel } from "~/clientModel/tables";
import { TableClientModel } from "~/clientModel/tables/table";
import { SourceEditFieldForm } from "~/presenters/fields";

import { FooterButtonBase } from "../common/FooterButtonBase";
import { RightSidebarBase } from "../common/RightSidebarBase";

type EditFieldSidebarProps = {
  tableLoadable: Loadable<TableClientModel>;
  tablesLoadable: Loadable<TablesClientModel>;
  editingFieldLoadable: Loadable<FieldClientModel>;
  fieldsLoadable: Loadable<FieldsClientModel>;
  aggregateValueFieldOperatorsLoadable: Loadable<AggregateValueFieldOperatorsClientModel>;
  smartFunctionsLoadable: Loadable<SmartFunctionsClientModel>;
  useFieldsLoadable: UseLoadable<{ tableSlug: string }, FieldsClientModel>;
  onEditingFieldChange: (field: FieldClientModel) => void;
  useUpdateFieldExecutable: UseExecutable<void, { field: FieldClientModel }>;
  useTestFormulaExecutable: UseExecutable<
    void,
    { formula: string },
    {
      records: RecordsClientModel;
      fields: FieldsClientModel;
    }
  >;
};

export const EditFieldSidebar = (props: EditFieldSidebarProps) => {
  const {
    tableLoadable,
    editingFieldLoadable,
    fieldsLoadable,
    tablesLoadable,
    aggregateValueFieldOperatorsLoadable,
    smartFunctionsLoadable,
    useFieldsLoadable,
    onEditingFieldChange,
    useUpdateFieldExecutable,
    useTestFormulaExecutable,
  } = props;

  const testFormulaExecutable = useTestFormulaExecutable();
  const updateFieldExecutable = useUpdateFieldExecutable();

  const handleUpdateRecord = useCallback(
    async (field: FieldClientModel) => {
      await updateFieldExecutable.execute({ field });
    },
    [updateFieldExecutable]
  );

  return (
    <RightSidebarBase
      sidebarType="editField"
      title="Edit Field"
      footer={
        <WithFallback
          loadables={
            [
              editingFieldLoadable,
              fieldsLoadable,
              smartFunctionsLoadable,
            ] as const
          }
        >
          {([editingField, fields, smartFunctions]) => (
            <FooterButtonBase
              onClick={() => handleUpdateRecord(editingField)}
              isLoading={updateFieldExecutable.isExecuting}
              isDisabled={!editingField.isAllValid({ fields, smartFunctions })}
            >
              Save
            </FooterButtonBase>
          )}
        </WithFallback>
      }
    >
      <WithFallback
        loadables={
          [
            fieldsLoadable,
            editingFieldLoadable,
            tableLoadable,
            tablesLoadable,
            aggregateValueFieldOperatorsLoadable,
            smartFunctionsLoadable,
          ] as const
        }
      >
        {([
          fields,
          editingField,
          table,
          tables,
          aggregateValueFieldOperators,
          smartFunctions,
        ]) => (
          <SourceEditFieldForm
            table={table}
            field={editingField}
            fields={fields}
            tables={tables}
            smartFunctions={smartFunctions}
            aggregateValueFieldOperators={aggregateValueFieldOperators}
            onFieldChange={onEditingFieldChange}
            testFormulaExecutable={testFormulaExecutable}
            useFieldsLoadable={useFieldsLoadable}
          />
        )}
      </WithFallback>
    </RightSidebarBase>
  );
};
