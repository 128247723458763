import { SimpleField } from "@usemorph/morph-dashboard-types";
import { FieldTypeSelect } from "./FieldTypeSelect";

/**
 * 以下コンポーネントの実装
 */

// 新しいfield typeが追加されたら、ここにも追加する必要がある
const allTypes = [
  "array",
  "attachment",
  "autoBigNumber",
  "autoNumber",
  "bigNumber",
  "boolean",
  "createdAt",
  "createdBy",
  "date",
  "datetime",
  "decimal",
  "email",
  "formula",
  "image",
  "json",
  "lastEditedAt",
  "lastEditedBy",
  "longText",
  "multiSelect",
  "number",
  "phoneNumber",
  "richText",
  "shortText",
  "singleSelect",
  "time",
  "url",
  "createdBy",
  "createdAt",
  "lastEditedBy",
  "lastEditedAt",
  "reference",
  "multiReference",
  "html",
  "embeddings",
  "python",
  "rowInfo",
] as const;

// typeが追加されたときに、allTypesへの追加忘れを防ぐためにこの定義にする
// 追加を忘れると、SimpleFieldTypeがneverになりコンパイルに失敗する
type SimpleFieldType = SimpleField["type"] extends (typeof allTypes)[number]
  ? SimpleField["type"]
  : never;

const excludeTypes = <T extends readonly SimpleFieldType[]>(
  ...excludeTypes: T
): Exclude<SimpleFieldType, T[number]>[] => {
  return allTypes.filter((type) => !excludeTypes.includes(type)) as Exclude<
    SimpleFieldType,
    T[number]
  >[];
};

// どのField Typeからも遷移不可能なtypes
const nonTransitionableFieldTypes = [
  "createdBy",
  "createdAt",
  "lastEditedAt",
  "lastEditedBy",
  "autoNumber",
  "autoBigNumber",
  "richText",
  "formula",
  "multiSelect",
  "embeddings",
] as const satisfies readonly SimpleFieldType[];

const updatableFields: { [_ in SimpleFieldType]: Array<SimpleFieldType> } = {
  array: excludeTypes(...nonTransitionableFieldTypes),
  attachment: ["image"],
  autoBigNumber: [],
  autoNumber: [],
  bigNumber: excludeTypes(...nonTransitionableFieldTypes),
  boolean: excludeTypes(...nonTransitionableFieldTypes),
  createdAt: [],
  createdBy: [],
  date: excludeTypes(...nonTransitionableFieldTypes),
  datetime: excludeTypes(...nonTransitionableFieldTypes),
  decimal: excludeTypes(...nonTransitionableFieldTypes),
  email: excludeTypes(...nonTransitionableFieldTypes),
  formula: [],
  image: excludeTypes(...nonTransitionableFieldTypes),
  json: excludeTypes(...nonTransitionableFieldTypes),
  lastEditedAt: [],
  lastEditedBy: [],
  longText: excludeTypes(...nonTransitionableFieldTypes),
  multiSelect: [],
  number: excludeTypes(...nonTransitionableFieldTypes),
  phoneNumber: excludeTypes(...nonTransitionableFieldTypes),
  richText: [],
  shortText: excludeTypes(...nonTransitionableFieldTypes),
  singleSelect: excludeTypes(...nonTransitionableFieldTypes),
  time: excludeTypes(...nonTransitionableFieldTypes),
  url: excludeTypes(...nonTransitionableFieldTypes),
  reference: [],
  multiReference: [],
  html: excludeTypes(...nonTransitionableFieldTypes),
  embeddings: [],
  python: [],
  rowInfo: [],
};

/**
 * 以下コンポーネントの実装
 */

type UpdateFieldTypeSelectProps = {
  onChange: (value: SimpleFieldType) => void;
  currentValue: SimpleFieldType;
  value: SimpleFieldType;
  isReadOnly?: boolean;
};

const KeyedUpdateFieldTypeSelect = ({
  currentValue,
  value,
  isReadOnly = false,
  onChange,
}: UpdateFieldTypeSelectProps) => {
  const options = {
    includes: [...updatableFields[currentValue], currentValue],
  };

  return (
    <FieldTypeSelect
      value={value}
      onChange={onChange}
      isReadOnly={isReadOnly}
      options={options}
    />
  );
};

export const UpdateFieldTypeSelect = (props: UpdateFieldTypeSelectProps) => {
  const key = props.currentValue;
  return <KeyedUpdateFieldTypeSelect key={key} {...props} />;
};
