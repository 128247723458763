import { Flex } from "@radix-ui/themes";
import { useMemo } from "react";
import { SidebarTableLinkItem } from "../LinkItems/SidebarTableLinkItem";
import { useSortedTables } from "~/serverStateStore/tables";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { Spinner } from "~/components_next/Spinner/Spinner";

interface TableSelectProps {
  isPrivate: boolean;
}

const TableSelect = (props: TableSelectProps) => {
  const { isPrivate } = props;

  const databaseId = useDatabaseId();
  const teamSlug = useTeamSlug();

  const { queryResult } = useSortedTables({ teamSlug, databaseId });

  if (queryResult.status === "error") {
    throw new Error("tables not found");
  }

  const tables = useMemo(() => {
    if (queryResult.data) {
      if (isPrivate) {
        return queryResult.data?.tables.filter((t) => t.isPrivate);
      }
      return queryResult.data?.tables.filter((t) => !t.isPrivate);
    }
    return [];
  }, [queryResult]);

  return (
    <>
      {queryResult.status === "success" ? (
        // queryResult.data.tables.map((table) => (
        tables.map((table, i) => (
          <SidebarTableLinkItem
            key={table.tableSlug}
            databaseId={databaseId}
            table={table}
            indexNum={i}
          ></SidebarTableLinkItem>
        ))
      ) : (
        <Flex justify="center" align="center" height="100%">
          <Spinner size="20" />
        </Flex>
      )}
    </>
  );
};

export { TableSelect };

// <RowVirtualizerDynamic height="calc(100vh - 220px)" baseSize={30}>
//   {queryResult.data.tables.map((table) => (
//     <SidebarTableLinkItem
//       key={table.tableSlug}
//       databaseId={databaseId}
//       table={table}
//     ></SidebarTableLinkItem>
//   ))}
// </RowVirtualizerDynamic>
