import { useQuery } from "react-query";
import { CsvImportSchemaClientModelFactory } from "~/clientModel/csvImport";
import { useComposeLoadable } from "~/clientModel/loadable";
import { useFindImportDataSchemaQuery } from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

export const useFindImportSchema = (importDataSchemaId?: string) => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const findImportSchemaOptions = useFindImportDataSchemaQuery({
    teamSlug,
    databaseId,
    importDataSchemaId: importDataSchemaId || null,
  });
  const findImportSchemaQueryResult = useQuery({
    ...findImportSchemaOptions,
    select: (response) => {
      return CsvImportSchemaClientModelFactory.fromImportDataResponse(response);
    },
  });
  const findImportSchemaLoadable = useComposeLoadable(
    findImportSchemaQueryResult
  );

  return {
    findImportSchemaLoadable,
  };
};
