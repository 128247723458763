import { HTMLPreview } from "./Preview/HTMLPreview";
import { Button } from "~/components_next/Button";
import { HTMLEditModal } from "./HTMLEditModal";
import { RecordValueInputCommonProps } from "../../../type";
import { useDisclosure } from "~/hooks/useDisclosure";
import { InputStack } from "~/components_next/InputStack/InputStack";

export const HTMLInput = (props: RecordValueInputCommonProps<"html">) => {
  const { value, onChange, isReadOnly, errorMessages, size, isNullable } =
    props;
  const { isOpen, onOpen, onClose, setIsOpen } = useDisclosure();

  return (
    <>
      <InputStack errorMessage={errorMessages}>
        <HTMLPreview value={value} height="100%" />
        <Button
          size="xs"
          variant="tertiary"
          colorSchemeOverride="gray"
          onClick={onOpen}
        >
          View
        </Button>
      </InputStack>
      {/* modal */}
      <HTMLEditModal
        isOpen={isOpen}
        onChange={onChange}
        isReadOnly={isReadOnly}
        html={value}
        onOpenChange={setIsOpen}
      />
    </>
  );
};
