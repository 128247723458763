import { Flex } from "~/components_next/Flex"
import { Text } from "~/components_next/Text"

interface PlanTableContentProps {
  title: string;
  free: string;
  standard: string;
  team: string;
  enterprise: string;
}

export const PlanTableContent = (props: PlanTableContentProps) => {
  const {
    title,
    free,
    standard,
    team,
    enterprise,
  } = props

  return (
    <>
      <Flex css={{ borderBottomWidth: 1 }}>
        <Flex align="center" css={{ width: "160px" }}>
          <Text>{title}</Text>
        </Flex>

        <Flex
          justify="center"
          align="center"
          p="3"
          css={{ width: "150px", borderLeftWidth: 1 }}
        >
          <Text fontWeight="bold">{free}</Text>
        </Flex>

        <Flex
          justify="center"
          align="center"
          p="3"
          css={{ width: "150px", borderLeftWidth: 1 }}
        >
          <Text fontWeight="bold">
            {standard}
          </Text>
        </Flex>

        <Flex
          justify="center"
          align="center"
          p="3"
          css={{ width: "150px", borderLeftWidth: 1 }}
        >
          <Text fontWeight="bold">{team}</Text>
        </Flex>

        <Flex
          justify="center"
          align="center"
          p="3"
          css={{ width: "150px", borderLeftWidth: 1, borderRightWidth: 1 }}
        >
          <Text fontWeight="bold">
            {enterprise}
          </Text>
        </Flex>
      </Flex>
    </>
  )
}
