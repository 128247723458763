import constate from "constate";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import { CanvasCreateCellClientModel } from "~/clientModel/canvas/CanvasCreateCellClientModel";
import { CanvasVisualizationPromptConfigsCientModel } from "~/clientModel/canvas/CanvasVisualizationPromptConfigClientModel";
import { UseExecutable } from "~/clientModel/executable";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";

export type PlaygroundCellStyleProps = {
  useMyColorsLoadable: UseLoadable<
    void,
    {
      count: number;
      items: {
        colorCodes: string[];
        colorCodeId: string;
      }[];
    },
    unknown
  >;
  useGenerateColorsExecutable: UseExecutable<
    void,
    { prompt: string },
    { colorCodes: string[] },
    unknown
  >;
  useSaveNewColorCodeExecutable: UseExecutable<
    void,
    { colorCodes: string[] },
    { colorCodeId: string; colorCodes: string[] },
    unknown
  >;
  useDeleteMyColorsExecutable: UseExecutable<
    void,
    { colorCodeId: string },
    unknown,
    unknown
  >;
  usePreviewColorsExecutable: UseExecutable<
    void,
    { colorCodes: string[] },
    { previews: Array<{ html: string }> },
    unknown
  >;
};

export type UsePlaygroundCreateCellContextProps = {
  useCreateCellExecutable: UseExecutable<
    void,
    CanvasCreateCellClientModel,
    CanvasCellClientModelUnion,
    unknown
  >;
  useVisualizationPromptConfigsLoadable: UseLoadable<
    void,
    CanvasVisualizationPromptConfigsCientModel,
    unknown
  >;
} & PlaygroundCellStyleProps;

const usePlaygroundCreateCellContext = (
  props: UsePlaygroundCreateCellContextProps
) => {
  const {
    useCreateCellExecutable,
    useMyColorsLoadable,
    useGenerateColorsExecutable,
    useSaveNewColorCodeExecutable,
    useDeleteMyColorsExecutable,
    usePreviewColorsExecutable,
    useVisualizationPromptConfigsLoadable,
  } = props;

  return {
    useCreateCellExecutable,
    useMyColorsLoadable,
    useGenerateColorsExecutable,
    useSaveNewColorCodeExecutable,
    useDeleteMyColorsExecutable,
    usePreviewColorsExecutable,
    useVisualizationPromptConfigsLoadable,
  };
};

export const [
  PlaygroundCreateCellExecutableProvider,
  useUseCreateCellExecutable,
  useUseMyColorsLoadable,
  useUseGenerateColorsExecutable,
  useUseSaveNewColorCodeExecutable,
  useUseDeleteMyColorsExecutable,
  useUsePreviewColorsExecutable,
  useUseVisualizationPromptConfigsLoadable,
] = constate(
  usePlaygroundCreateCellContext,
  (value) => value.useCreateCellExecutable,
  (value) => value.useMyColorsLoadable,
  (value) => value.useGenerateColorsExecutable,
  (value) => value.useSaveNewColorCodeExecutable,
  (value) => value.useDeleteMyColorsExecutable,
  (value) => value.usePreviewColorsExecutable,
  (value) => value.useVisualizationPromptConfigsLoadable
);
