import {
  DashboardWidgetDataRegistApiRequestBody,
  DashboardWidgetDataRegistApiResponse,
  ChatReplyRequestObject,
  WidgetDataAggregateRecordRequestBody,
  WidgetDataDeleteRecordRequestBody,
  WidgetDataQueryRecordRequestBody,
  WidgetDataUpdateRecordRequestBody,
  WidgetDataUploadFileRequestBody,
  WidgetDataCreateRequestBodyObject,
} from "@usemorph/morph-dashboard-types";
import { match } from "ts-pattern";

export const convertWidgetDataApiObject = (
  dataApiResponse: DashboardWidgetDataRegistApiResponse
): DashboardWidgetDataRegistApiRequestBody | undefined => {
  return match(dataApiResponse)
    .with({ apiType: "query" }, (value) => {
      const queryObject: DashboardWidgetDataRegistApiRequestBody = {
        apiType: "query",
        apiKey: value.apiKey,
        apiName: value.apiName,
        acl: value.acl,
        isIndependent: true,
        isDynamicCondition: true,
        query: value.body as WidgetDataQueryRecordRequestBody,
      };

      return queryObject;
    })
    .with({ apiType: "create" }, (value) => {
      const createObject: DashboardWidgetDataRegistApiRequestBody = {
        apiType: "create",
        apiKey: value.apiKey,
        apiName: value.apiName,
        acl: value.acl,
        isIndependent: true,
        isDynamicCondition: true,
        create: value.body as WidgetDataCreateRequestBodyObject,
      };

      return createObject;
    })
    .with({ apiType: "update" }, (value) => {
      const updateObject: DashboardWidgetDataRegistApiRequestBody = {
        apiType: "update",
        apiKey: value.apiKey,
        apiName: value.apiName,
        acl: value.acl,
        isIndependent: true,
        isDynamicCondition: true,
        update: value.body as WidgetDataUpdateRecordRequestBody,
      };
      return updateObject;
    })
    .with({ apiType: "delete" }, (value) => {
      const deleteObject: DashboardWidgetDataRegistApiRequestBody = {
        apiType: "delete",
        apiKey: value.apiKey,
        apiName: value.apiName,
        acl: value.acl,
        isIndependent: true,
        isDynamicCondition: true,
        delete: value.body as WidgetDataDeleteRecordRequestBody,
      };
      return deleteObject;
    })
    .with({ apiType: "upload" }, (value) => {
      const uploadObject: DashboardWidgetDataRegistApiRequestBody = {
        apiType: "upload",
        apiKey: value.apiKey,
        apiName: value.apiName,
        acl: value.acl,
        isIndependent: true,
        isDynamicCondition: true,
        upload: value.body as WidgetDataUploadFileRequestBody,
      };
      return uploadObject;
    })
    .with({ apiType: "download" }, (value) => {
      const downloadObject: DashboardWidgetDataRegistApiRequestBody = {
        apiType: "download",
        apiKey: value.apiKey,
        apiName: value.apiName,
        acl: value.acl,
        isIndependent: true,
        isDynamicCondition: true,
        download: value.body as WidgetDataQueryRecordRequestBody,
      };
      return downloadObject;
    })
    .with({ apiType: "aggregate" }, (value) => {
      const aggregateObject: DashboardWidgetDataRegistApiRequestBody = {
        apiType: "aggregate",
        apiKey: value.apiKey,
        apiName: value.apiName,
        acl: value.acl,
        isIndependent: true,
        isDynamicCondition: true,
        aggregate: value.body as WidgetDataAggregateRecordRequestBody,
      };
      return aggregateObject;
    })
    .with({ apiType: "chat" }, (value) => {
      const chatObject: DashboardWidgetDataRegistApiRequestBody = {
        apiType: "chat",
        apiKey: value.apiKey,
        apiName: value.apiName,
        acl: value.acl,
        isIndependent: true,
        isDynamicCondition: true,
        chat: value.body as ChatReplyRequestObject,
      };
      return chatObject;
    })
    .otherwise(() => undefined);
};
