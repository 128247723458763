import slugify from "slugify";
const convertToSlug = (input: string) => {
  const valueUnderScoreToHyphen = input.replace(/_/g, "-");
  const slug = slugify(valueUnderScoreToHyphen, {
    lower: true,
    replacement: "_",
  });
  const safed = slug.replace(/-/g, "_");

  return safed;
};
export { convertToSlug };
