import { useQueryClient } from "react-query";
import useApiRecord from "~/api/useApiRecord";
import { recordsKeys } from "../../records/keys";

// types
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseDeleteRecordsParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
}

const useDeleteRecordsFromTrashMutation = ({
  databaseId,
  tableSlug,
  teamSlug,
}: UseDeleteRecordsParams) => {
  const { _deleteRecordFromTrash } = useApiRecord();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({ recordTrashIds }: { recordTrashIds: string[] }) => {
      return Promise.all(
        recordTrashIds.map((recordTrashId) => {
          return _deleteRecordFromTrash({
            databaseId,
            teamSlug,
            tableSlug,
            recordTrashId,
          });
        })
      );
    },
    onSuccess: () => {
      const allGetSoftDeletedRecord = recordsKeys.allGetSoftDeletedRecord({
        teamSlug,
        databaseId,
        tableSlug,
      })
      broadcast(allGetSoftDeletedRecord)

      return invalidateAll(
        client,
        allGetSoftDeletedRecord
      );
    },
  };
};

export { useDeleteRecordsFromTrashMutation };
