import { useQueryClient } from "react-query";
import useApiCanvasStyle from "~/api/useApiCanvasStyle";
import { commonQueryOptions } from "~/serverStateStore/util/commonQueryOptions";
import { canvasStyleKeys } from "../keys";

const useGetMyColorsQuery = ({ teamSlug }: { teamSlug: string }) => {
  const { _listMyColors } = useApiCanvasStyle();

  const client = useQueryClient();
  client.setQueryDefaults(canvasStyleKeys.myColors({ teamSlug }), {
    ...commonQueryOptions,
  });

  return {
    queryKey: canvasStyleKeys.myColors({ teamSlug }),
    queryFn: () => _listMyColors({ teamSlug }),
  };
};

export { useGetMyColorsQuery };
