import { QueryRecordWithFieldsResponse } from "@usemorph/morph-dashboard-types";
import { useMutation, useQuery } from "react-query";
import useApiRecord from "~/api/useApiRecord";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import {
  FieldsClientModel,
  FieldsClientModelDefactory,
  FieldsClientModelFactory,
} from "~/clientModel/fields";

import { Loadable } from "~/clientModel/loadable";
import {
  RecordsClientModel,
  RecordsClientModelFactory,
} from "~/clientModel/records";
import { useListComputedFieldOptionsForSmartFunctionQuery } from "~/serverStateStore";

type UseTestFormulaExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  fieldsLoadable: Loadable<FieldsClientModel>;
};

const useTestFormulaExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
  fieldsLoadable,
}: UseTestFormulaExecutableParams): Executable<
  { formula: string },
  {
    records: RecordsClientModel;
    fields: FieldsClientModel;
  }
> => {
  const { _getRecords } = useApiRecord();

  const smartFunctionsListResponse = useQuery(
    useListComputedFieldOptionsForSmartFunctionQuery({ teamSlug })
  );

  return useComposeExecutable(
    useMutation({
      mutationFn: async ({ formula }: { formula: string }) => {
        if (
          fieldsLoadable.status !== "success" ||
          smartFunctionsListResponse.status !== "success"
        ) {
          throw new Error("Something went wrong. Please try again.");
        }

        const result = await _getRecords({
          teamSlug,
          databaseId,
          tableSlug,
          requestBody: {
            select: [
              `[formula]${formula}`,
              ...FieldsClientModelDefactory.toFieldNames(fieldsLoadable.data),
            ],
            limit: 10,
            skip: 0,
          },
        });

        const resultWithTestField: QueryRecordWithFieldsResponse = {
          items: result.items,
          count: result.count,
          fields: [
            {
              type: "shortText",
              name: "morph_formula_result",
              displayName: "Formula Result",
            },
            ...result.fields,
          ],
        };

        const records =
          RecordsClientModelFactory.createFromQueryRecordListResponseWithFields(
            resultWithTestField
          );

        // todo: サーバーでfieldsのレスポンスに入れてもらうようにする
        const fields = FieldsClientModelFactory.createFromSimpleFields(
          resultWithTestField.fields
        );

        return { records, fields };
      },
    })
  );
};

export const createUseTestFormulaExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
  fieldsLoadable,
}: UseTestFormulaExecutableParams) => {
  return () =>
    useTestFormulaExecutable({
      teamSlug,
      databaseId,
      tableSlug,
      fieldsLoadable,
    });
};
