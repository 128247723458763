import {
  DashboardNotebookCellResponse,
  DashboardPageCellDetailObject,
} from "@usemorph/morph-dashboard-types";
import { match } from "ts-pattern";
import { NotebookCellObjectWithMeta } from "~/features/FlipNotebook/types/NotebookCellObjectWithMeta.type";
import { CanvasCellClientModelUnion } from ".";
import { CanvasCellPromptClientModel } from "./CanavsCellPromptClientModel";
import { CanvasCellImageClientModel } from "./CanvasCellImageClientModel";
import { CanvasCellRichTextClientModel } from "./CanvasCellRichTextClientModel";
import { CanvasCellSourceClientModel } from "./CanvasCellSourceClientModel";
import { CanvasCellStickyNoteClientModel } from "./CanvasCellStickyNoteClientModel";
import { CanvasCellVariablesClientModel } from "./CanvasCellVariablesClientModel";
import { CanvasCellViewClientModel } from "./CanvasCellViewClientModel";

export class CanvasCellClientModelFactory {
  static fromLiveblocksObject(
    liveBlocksObject: NotebookCellObjectWithMeta // TODO: 引数の型は移行時に整理する
  ): CanvasCellClientModelUnion | null {
    try {
      return match(liveBlocksObject)
        .with(
          { cellType: "source" },
          (sourceCellData): CanvasCellSourceClientModel => {
            return new CanvasCellSourceClientModel(sourceCellData);
          }
        )
        .with(
          { cellType: "prompt" },
          (promptCellData): CanvasCellPromptClientModel => {
            return new CanvasCellPromptClientModel(promptCellData);
          }
        )
        .with(
          { cellType: "view" },
          (viewCellData): CanvasCellViewClientModel => {
            return new CanvasCellViewClientModel(viewCellData);
          }
        )
        .with(
          { cellType: "variables" },
          (variablesCellData): CanvasCellVariablesClientModel => {
            return new CanvasCellVariablesClientModel(variablesCellData);
          }
        )
        .with(
          { cellType: "richText" },
          (richTextCellData): CanvasCellRichTextClientModel => {
            return new CanvasCellRichTextClientModel(richTextCellData);
          }
        )
        .with(
          { cellType: "stickyNote" },
          (stickyNoteCellData): CanvasCellStickyNoteClientModel => {
            return new CanvasCellStickyNoteClientModel(stickyNoteCellData);
          }
        )
        .with(
          { cellType: "image" },
          (imageCellData): CanvasCellImageClientModel => {
            return new CanvasCellImageClientModel(imageCellData);
          }
        )
        .exhaustive();
    } catch {
      return null;
    }
  }

  static fromNotebookCellResponse(
    notebookCellResponse: DashboardNotebookCellResponse
  ): CanvasCellClientModelUnion {
    return match(notebookCellResponse)
      .with(
        { cellType: "source" },
        (sourceCellData): CanvasCellSourceClientModel => {
          return new CanvasCellSourceClientModel(sourceCellData);
        }
      )
      .with(
        { cellType: "prompt" },
        (promptCellData): CanvasCellPromptClientModel => {
          return new CanvasCellPromptClientModel(promptCellData);
        }
      )
      .with({ cellType: "view" }, (viewCellData): CanvasCellViewClientModel => {
        return new CanvasCellViewClientModel(viewCellData);
      })
      .with(
        { cellType: "variables" },
        (variablesCellData): CanvasCellVariablesClientModel => {
          return new CanvasCellVariablesClientModel(variablesCellData);
        }
      )
      .with(
        { cellType: "richText" },
        (richTextCellData): CanvasCellRichTextClientModel => {
          return new CanvasCellRichTextClientModel(richTextCellData);
        }
      )
      .with(
        { cellType: "stickyNote" },
        (stickyNoteCellData): CanvasCellStickyNoteClientModel => {
          return new CanvasCellStickyNoteClientModel(stickyNoteCellData);
        }
      )
      .with(
        { cellType: "image" },
        (imageCellData): CanvasCellImageClientModel => {
          return new CanvasCellImageClientModel(imageCellData);
        }
      )
      .exhaustive();
  }

  static fromDashboardPageCellResponse(
    cell: DashboardPageCellDetailObject["notebookCell"]
  ): CanvasCellClientModelUnion {
    return match(cell)
      .with(
        { cellType: "source" },
        (sourceCellData): CanvasCellSourceClientModel => {
          return new CanvasCellSourceClientModel(sourceCellData);
        }
      )
      .with(
        { cellType: "prompt" },
        (promptCellData): CanvasCellPromptClientModel => {
          return new CanvasCellPromptClientModel(promptCellData);
        }
      )
      .with({ cellType: "view" }, (viewCellData): CanvasCellViewClientModel => {
        return new CanvasCellViewClientModel(viewCellData);
      })
      .with(
        { cellType: "variables" },
        (variablesCellData): CanvasCellVariablesClientModel => {
          return new CanvasCellVariablesClientModel(variablesCellData);
        }
      )
      .with(
        { cellType: "richText" },
        (richTextCellData): CanvasCellRichTextClientModel => {
          return new CanvasCellRichTextClientModel(richTextCellData);
        }
      )
      .with(
        { cellType: "stickyNote" },
        (stickyNoteCellData): CanvasCellStickyNoteClientModel => {
          return new CanvasCellStickyNoteClientModel(stickyNoteCellData);
        }
      )
      .with(
        { cellType: "image" },
        (imageCellData): CanvasCellImageClientModel => {
          return new CanvasCellImageClientModel(imageCellData);
        }
      )
      .exhaustive();
  }
}
