import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModel } from "..";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";

export class DateTimeFieldTypeClientModel extends FieldTypeClientModelBase {
  public get type(): "datetime" {
    return "datetime";
  }

  public get fieldTypeLabel(): "Date Time" {
    return "Date Time";
  }

  public get isAutoGenerated(): false {
    return false;
  }

  public get isEmbeddable(): false {
    return false;
  }

  public get canBeTargetOfSyncingByComputedField(): true {
    return true;
  }

  public get _updatableFieldTypes(): FieldTypeClientModel["type"][] {
    return [
      "shortText",
      "longText",
      "date",
      "time",
      "singleSelect",
      "multiSelect",
    ];
  }

  public validate(): ValidationResult {
    return { isValid: true };
  }
}
