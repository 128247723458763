import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil";
import { urlSyncEffect } from "recoil-sync";
import { union, literal } from "@recoiljs/refine";
import { createTableViewUrlSyncStoreEffectOptions } from "./TableViewUrlSyncStore/createTableViewUrlSyncStoreEffectOptions";
import { Limit } from "../../common/components/Pagination/TablePagination";

const limitChecker = union(literal(10), literal(30), literal(50), literal(100));

const limitFamily = atomFamily<Limit, { viewId: string }>({
  key: "tableViewLimit",
  default: 30,
  effects: [
    urlSyncEffect(
      createTableViewUrlSyncStoreEffectOptions({
        checker: limitChecker,
        key: "tableViewLimit",
        defaultValue: 30,
      })
    ),
  ],
});

const useLimit = (viewId: string) => {
  return useRecoilValue(limitFamily({ viewId }));
};

const useSetLimit = (viewId: string) => {
  return useSetRecoilState(limitFamily({ viewId }));
};

export { useLimit, useSetLimit };
