import { useMemo } from "react";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import { CanvasCreateCellClientModel } from "~/clientModel/canvas/CanvasCreateCellClientModel";
import { UseExecutable } from "~/clientModel/executable";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { JoinTwoForm } from "./JoinTwoForm";
import { FieldsClientModel } from "~/clientModel/fields";

type JoinSourcesProps = {
  mainTableSlug: string;
  mainTableName: string;
  targetTableSlug: string;
  targetTableName: string;
  parentCells: CanvasCellClientModelUnion[];
  useSourceFieldsLoadalbe: UseLoadable<
    { tableSlug: string },
    FieldsClientModel
  >;
  useCreateCellExecutable: UseExecutable<
    void,
    CanvasCreateCellClientModel,
    CanvasCellClientModelUnion,
    unknown
  >;
  onCompleteCreateCell?: () => void;
};

const JoinSources = (props: JoinSourcesProps) => {
  const {
    mainTableSlug,
    mainTableName,
    targetTableSlug,
    targetTableName,
    parentCells,
    useSourceFieldsLoadalbe,
    useCreateCellExecutable,
  } = props;

  const mainTableFieldsLoadable = useSourceFieldsLoadalbe({
    tableSlug: mainTableSlug,
  });
  const targetTableFieldsLoadable = useSourceFieldsLoadalbe({
    tableSlug: targetTableSlug,
  });

  const mainTableSlugFieldsOptions = useMemo(() => {
    return (
      mainTableFieldsLoadable.data?.allFields.map((field) => {
        return {
          label: field.displayName || field.name,
          value: field.name,
        };
      }) || []
    );
  }, [mainTableFieldsLoadable]);

  const targetTableSlugFieldsOptions = useMemo(() => {
    return (
      targetTableFieldsLoadable.data?.allFields.map((field) => {
        return {
          label: field.displayName || field.name,
          value: field.name,
        };
      }) || []
    );
  }, [targetTableFieldsLoadable]);

  return (
    <>
      <JoinTwoForm
        slug1={mainTableSlug}
        label1={mainTableName}
        options1={mainTableSlugFieldsOptions}
        slug2={targetTableSlug}
        label2={targetTableName}
        options2={targetTableSlugFieldsOptions}
        parentCells={parentCells}
        useCreateCellExecutable={useCreateCellExecutable}
        onCompleteCreateCell={props.onCompleteCreateCell}
      />
    </>
  );
};

export { JoinSources };
