import {
  BigNumberFieldTypeClientModel,
  DecimalFieldTypeClientModel,
  NumberFieldTypeClientModel,
} from "~/clientModel/fields/field/fieldType";
import { AggregateValueFieldOperatorsClientModel } from "~/clientModel/fields/field/fieldType/computed/aggregateValue/aggregateValueFieldOperators";
import { SimpleSelect } from "~/components_next/Select";

const fieldTypeOptions = [
  new NumberFieldTypeClientModel(),
  new BigNumberFieldTypeClientModel(),
  new DecimalFieldTypeClientModel(),
] as const;

type OperatorInputProps = {
  value: string | null;
  aggregateValueFieldOperators: AggregateValueFieldOperatorsClientModel;
  onChange: (value: string) => void;
  isReadOnly?: boolean;
};

export const OperatorInput = (props: OperatorInputProps) => {
  const { value, onChange, aggregateValueFieldOperators, isReadOnly } = props;

  const options = aggregateValueFieldOperators.operators.map((operator) => ({
    value: operator.value,
    label: operator.label,
  }));

  return (
    <SimpleSelect
      size="sm"
      variant="primary"
      label="Aggregate method"
      value={value}
      options={options}
      onChange={(value) => onChange(value!)}
      isReadOnly={isReadOnly}
    />
  );
};
