import {
  DashboardTemplateListResponse,
  DashboardTemplateDetailResponse,
  DashboardListDynamicValuesResponse
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

export default function useApiTemplate() {
  const { executeRequest } = useApi();

  const _listTemplate = async ({ teamSlug }: {
    teamSlug: string;
  }): Promise<DashboardTemplateListResponse> => {
    return executeRequest(
      "get",
      `/template`,
      undefined,
      { teamSlug },
      undefined,
    );
  };

  const _findTemplateDetail = async ({
    templateId,
    teamSlug,
  }: {
    templateId: string;
    teamSlug: string;
  }): Promise<DashboardTemplateDetailResponse> => {
    return executeRequest(
      "get",
      `/template/${templateId}`,
      undefined,
      { teamSlug },
      undefined,
    );
  };

  const _listDynamicValue = async ({
    integrationId,
    templateId,
    teamSlug,
    targetKey,
    dependentValues,
    templateAuthId,
  }: {
    integrationId: string;
    templateId: string;
    teamSlug: string;
    targetKey?: string;
    dependentValues?: { [key: string]: unknown };
    templateAuthId?: string;
  }): Promise<DashboardListDynamicValuesResponse> => {
    return executeRequest(
      "post",
      `/template/${templateId}/integration/${integrationId}/dynamic-values`,
      undefined,
      {
        teamSlug,
      },
      {
        targetKey,
        dependentValues,
        templateAuthId,
      },
    );
  };

  return {
    _listTemplate,
    _findTemplateDetail,
    _listDynamicValue,
  };
}
