import { Box, Flex } from "@radix-ui/themes";
import { useCurrentEditor } from "@tiptap/react";
import { SimpleSelect } from "~/components_next/Select";
import { styled } from "~/stitches";
import {
  useNodeOptions,
  NodeOptionValue,
} from "../../hooks/blockTypes/useNodeOptions";
import {
  BoldButton,
  ClearFormatButton,
  CodeButton,
  FontColorButton,
  HighlightButton,
  ItalicButton,
  StrikeButton,
  SubscriptButton,
  SuperscriptButton,
  UnderlineButton,
  RedoButton,
  UndoButton,
  TextAlignButton,
  LinkButton,
  ImageButton,
  HorizontalRuleButton,
} from "./TopMenuBarButtons";

const ButtonsOuterWrapper = styled(Flex, {
  backgroundColor: "$bg0",
  zIndex: 9000,
  flexWrap: "wrap",
  rowGap: "$1",
  columnGap: 36,
  borderBottom: "1px solid $border",
});

const TopMenuBar = () => {
  const { editor } = useCurrentEditor();

  const { handleSelect, currentBlock, options } = useNodeOptions();

  if (!editor) {
    return null;
  }

  return (
    <ButtonsOuterWrapper position="sticky" top="0" align="center" px="1" py="1">
      {/* Text Styles */}
      <Flex align="center" gap="1">
        <BoldButton />
        <ItalicButton />
        <UnderlineButton />
        <StrikeButton />
        <SuperscriptButton />
        <SubscriptButton />
        <HighlightButton />
        <FontColorButton />
        <CodeButton />
        <ClearFormatButton />
        <TextAlignButton align="left" />
        <TextAlignButton align="center" />
        <TextAlignButton align="right" />
        <LinkButton />
      </Flex>

      {/* Block Types */}
      <Flex align="center" gap="1">
        <ImageButton />
        <HorizontalRuleButton />
        <Box style={{ width: "176px" }}>
          <SimpleSelect
            variant="supplementary"
            options={options}
            size="sm"
            value={currentBlock}
            onChange={(value) => handleSelect(value as NodeOptionValue)}
          />
        </Box>
      </Flex>

      {/* History */}
      <Flex align="center" gap="1">
        <UndoButton />
        <RedoButton />
      </Flex>
    </ButtonsOuterWrapper>
  );
};

export { TopMenuBar };
