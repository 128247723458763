import { memo, useCallback } from "react";
import { NodeProps, ReactFlowState, useStore } from "reactflow";
import { CanvasCellStickyNoteClientModel } from "~/clientModel/canvas/CanvasCellClientModel";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
import { HTMLRichTextEditor } from "~/components_next/RichTextEditor";
import { styled } from "~/stitches";
import { usePlaygroundOnUpdateLiveCell } from "../../providers/PlaygroundUpdateLiveCellProvider";
import { PlaygroundCellCard } from "../common/PlaygroundCellCard";

const StickyNoteElement = styled(Box, {
  height: "100%",
  padding: "$3",
  boxShadow: "var(--shadow-4), var(--shadow-2)",
  variants: {
    color: {
      amber: {
        backgroundColor: "$amber5",
      },
      crimson: {
        backgroundColor: "$crimson5",
      },
      green: {
        backgroundColor: "$green5",
      },
      blue: {
        backgroundColor: "$blue5",
      },
    },
  },
});

type PlaygroundCellStickyNoteProps = {
  cellClientModel: CanvasCellStickyNoteClientModel;
};

const PlaygroundCellStickyNoteContent = memo(
  (
    props: {
      isDragging: boolean;
      isSelected: boolean;
      height: number;
      handleOnHtmlChange: (html: string, height: number) => void;
      xPos: number;
      yPos: number;
    } & PlaygroundCellStickyNoteProps
  ) => {
    const {
      cellClientModel,
      isDragging,
      isSelected,
      height,
      handleOnHtmlChange,
      xPos,
      yPos,
    } = props;

    return (
      <PlaygroundCellCard
        cellClientModel={cellClientModel}
        isNodeDragging={isDragging}
        isNodeSelected={isSelected}
        variant="skelton"
        position={{ x: xPos, y: yPos }}
      >
        <StickyNoteElement color={cellClientModel.color}>
          <HTMLRichTextEditor
            html={cellClientModel.html || ""}
            height={`${(height || 0) - 60}px`}
            onChange={handleOnHtmlChange}
            hideTopbar
            basicTextsOnly
            autoFocus={false}
            customEditorClass="sticky-note-html-editor"
          />
          <Flex align="center" css={{ height: "36px", opacity: 0.5 }}>
            {cellClientModel.createdBy?.username}
          </Flex>
        </StickyNoteElement>
      </PlaygroundCellCard>
    );
  }
);

PlaygroundCellStickyNoteContent.displayName = "PlaygroundCellStickyNoteContent";

const PlaygroundCellStickyNote = memo(
  (props: NodeProps<PlaygroundCellStickyNoteProps>) => {
    const { data, selected, dragging, id, xPos, yPos } = props;
    const { cellClientModel } = data;

    /**
     * ReactFlow
     */
    const sizeSelector = useCallback(
      (s: ReactFlowState) => {
        const node = s.nodeInternals.get(id);

        return {
          width: node?.width || undefined,
          height: node?.height || undefined,
        };
      },
      [id]
    );
    const size = useStore(
      useCallback(sizeSelector, [sizeSelector]),
      (prev, next) => prev.width === next.width && prev.height === next.height
    );

    /**
     * Event Handlers
     */
    const updateLiveCell = usePlaygroundOnUpdateLiveCell();
    const handleOnHtmlChange = useCallback(
      (html: string, height: number) => {
        const rounded = Math.ceil(height / 10) * 10 + 60;
        updateLiveCell(
          cellClientModel.updateRichText(html).updateSize({
            width: size.width || 0,
            height: rounded,
          })
        );
      },
      [size.width, cellClientModel]
    );

    return (
      <PlaygroundCellStickyNoteContent
        cellClientModel={cellClientModel}
        isDragging={dragging}
        isSelected={selected}
        height={size.height || 0}
        handleOnHtmlChange={handleOnHtmlChange}
        xPos={xPos}
        yPos={yPos}
      />
    );
  }
);

PlaygroundCellStickyNote.displayName = "PlaygroundCellStickyNote";

export { PlaygroundCellStickyNote, type PlaygroundCellStickyNoteProps };
