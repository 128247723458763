import { useCallback } from "react";
import { PresenceTableViewState } from "../types";

const usePresenceTableViewStateUtil = () => {
  const decodeTableViewState = useCallback((tableViewState?: string) => {
    if (!tableViewState) return undefined;

    const parsed = JSON.parse(tableViewState);
    // 本当は形式チェック入れたい
    return parsed as PresenceTableViewState;
  }, []);

  return {
    decodeTableViewState,
  };
};

export { usePresenceTableViewStateUtil };
