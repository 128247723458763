import { SimpleField } from "@usemorph/morph-dashboard-types";
import { EmbeddingClientModel } from "./EmbeddingClientModel";

export class EmbeddingClientModelFactory {
  public static createFromSimpleField(
    simpleField: SimpleField
  ): EmbeddingClientModel {
    return new EmbeddingClientModel({ fieldName: simpleField.name });
  }
}
