import { FieldClientModel } from "~/clientModel/fields/field";
import {
  UpdatableRecordEntryClientModelBaseConstructorParams,
  UpdatableRecordEntryClientModelBase,
} from "../RecordEntryClientModelBase";
import { zValidate, zIntString, zAllowNullIfNullable } from "../validatorsUtil";
import { z } from "zod";
import { NumberDisplaySettingClientModel } from "~/clientModel/fields/field/displaySetting/numberDisplaySetting";

type BigNumberRecordEntryClientModelData =
  UpdatableRecordEntryClientModelBaseConstructorParams;

export class BigNumberRecordEntryClientModel extends UpdatableRecordEntryClientModelBase<
  string | null
> {
  constructor(data: BigNumberRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "bigNumber" {
    return "bigNumber";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([zIntString, zAllowNullIfNullable(field)]),
      this.data.rawValue
    );
  }

  public getValidatedDisplayValue(field: FieldClientModel) {
    const validatedValue = this.getValidatedValue(field);

    return validatedValue.isValid &&
      field.displaySetting instanceof NumberDisplaySettingClientModel
      ? ({
          isValid: true,
          value: field.displaySetting.getDisplayValue(validatedValue.value),
        } as const)
      : validatedValue;
  }

  public updateValue(value: string | null): BigNumberRecordEntryClientModel {
    return new BigNumberRecordEntryClientModel({
      key: this.data.key,
      rawValue: value,
      meta: this.data.meta,
    });
  }
}
