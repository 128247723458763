import { RecordValueInputCommonProps } from "../../type";
import { ForwardedRef, forwardRef, useMemo } from "react";
import { match, P } from "ts-pattern";
import { MultiSelectFieldTypeClientModel } from "~/clientModel/fields/field/fieldType";
import { MultiSelectRecordEntryClientModel } from "~/clientModel/records/record/recordEntry";
import { SimpleMultiSelect } from "~/components_next/Select";

const MultiSelectInput = forwardRef(function MultiSelectInput(
  props: RecordValueInputCommonProps<MultiSelectRecordEntryClientModel>,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { editingRecordEntry, onChange, field, size, isReadonly } = props;

  const validatedValue = editingRecordEntry.getValidatedValue(field);

  const value = validatedValue.isValid ? validatedValue.value : null;

  const errorMessages = validatedValue.errorMessages;

  const handleChange = (value: string[] | null) => {
    onChange?.(editingRecordEntry.updateValue(value));
  };

  const options = useMemo(
    () =>
      match(field.type)
        .with(
          P.instanceOf(MultiSelectFieldTypeClientModel),
          (type) => type.members
        )
        .otherwise(() => {
          console.error("Invalid field type for MultiSelectInput");
          return [];
        }),
    [field.type]
  );

  return (
    <SimpleMultiSelect
      ref={ref}
      variant="primary"
      size={size}
      isReadOnly={isReadonly}
      value={value ?? null}
      onChange={handleChange}
      options={options}
      errorMessages={errorMessages}
      isClearable={field.isNullable && !isReadonly}
    />
  );
});

export { MultiSelectInput };
