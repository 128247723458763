import classNames from "classnames";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { Text } from "../../Text";
import {
  createContext,
  ElementRef,
  forwardRef,
  ReactNode,
  useContext,
} from "react";
import { MorphThemeSize } from "~/components_next/commonProps.type";
import { withBreakpoints } from "@radix-ui/themes";
import { match } from "ts-pattern";
import { styled } from "~/stitches";
import { Box } from "~/components_next/Box";
import "./drawer.css";
import { RadixTheme } from "~/RadixTheme";

/**
 * Context
 */
type DrawerContextType = Required<
  Pick<DrawerRootProps, "scrollBehavior" | "size">
>;

const DrawerContext = createContext({} as DrawerContextType);

const useDrawerContext = () => {
  return useContext(DrawerContext);
};

/**
 * DrawerRoot
 */
type DrawerRootProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  children?: ReactNode;
  size?: MorphThemeSize | "full";
  scrollBehavior?: "inside" | "outside";
};

const DrawerRoot = (props: DrawerRootProps) => {
  const { open, onOpenChange, children } = props;
  const contextValue = (({ size = "md", scrollBehavior = "inside" }) => ({
    size,
    scrollBehavior,
  }))(props);

  return (
    <DrawerContext.Provider value={contextValue}>
      <DialogPrimitive.Root open={open} onOpenChange={onOpenChange} modal>
        {children}
      </DialogPrimitive.Root>
    </DrawerContext.Provider>
  );
};
DrawerRoot.displayName = "DrawerRoot";

/**
 * DrawerContent
 */
type DrawerContentElement = React.ElementRef<typeof DialogPrimitive.Content>;
type DrawerContentProps = { children: ReactNode };

const convertSize = (size: DrawerContextType["size"]) => {
  return match(size)
    .with("xs", () => "1" as const)
    .with("sm", () => "2" as const)
    .with("md", () => "3" as const)
    .with("lg", () => "4" as const)
    .with("xl", () => "5" as const)
    .with("full", () => "full" as const)
    .exhaustive();
};

const DrawerContent = forwardRef<DrawerContentElement, DrawerContentProps>(
  (props, ref) => {
    const { size } = useDrawerContext();

    const { children } = props;

    return (
      <DialogPrimitive.Portal>
        <Box>
          <RadixTheme>
            <DialogPrimitive.Overlay className="morph-DrawerOverlay">
              <DialogPrimitive.Content
                ref={ref}
                className={classNames(
                  "morph-DrawerContent",
                  withBreakpoints(convertSize(size), "morph-r-size")
                )}
              >
                {children}
              </DialogPrimitive.Content>
            </DialogPrimitive.Overlay>
          </RadixTheme>
        </Box>
      </DialogPrimitive.Portal>
    );
  }
);
DrawerContent.displayName = "DrawerContent";

/**
 * DrawerTitle
 */
type DrawerTitleElement = React.ElementRef<typeof Text>;
type DrawerTitleProps = { children: ReactNode };

const DrawerTitle = forwardRef<DrawerTitleElement, DrawerTitleProps>(
  (props, ref) => {
    const { children } = props;
    return (
      <DialogPrimitive.Title asChild>
        <Text variant="heading" ref={ref} className="morph-DrawerTitle">
          {children}
        </Text>
      </DialogPrimitive.Title>
    );
  }
);
DrawerTitle.displayName = "DrawerTitle";

/**
 * DrawerDescription
 */
type DrawerDescriptionElement = HTMLParagraphElement;
type DrawerDescriptionProps = { children: ReactNode };

const DrawerDescription = forwardRef<
  DrawerDescriptionElement,
  DrawerDescriptionProps
>((props, ref) => {
  const { children } = props;
  return (
    <DialogPrimitive.Description asChild>
      <Text
        as="p"
        variant="description"
        ref={ref}
        className="morph-DrawerDescription"
      >
        {children}
      </Text>
    </DialogPrimitive.Description>
  );
});
DrawerDescription.displayName = "DrawerDescription";

/**
 * DrawerBody
 */
type DrawerBodyElement = ElementRef<typeof Box>;
type DrawerBodyProps = {
  children: ReactNode;
};

const BodyContainer = styled(Box, {
  variants: {
    scrollBehavior: {
      inside: {
        flex: 1,
        overflow: "auto",
      },
      outside: {},
    },
  },
});

const DrawerBody = forwardRef<DrawerBodyElement, DrawerBodyProps>(
  (props, ref) => {
    const { children } = props;

    const { scrollBehavior } = useDrawerContext();

    return (
      <BodyContainer
        ref={ref}
        scrollBehavior={scrollBehavior}
        className="morph-DrawerBody"
      >
        {children}
      </BodyContainer>
    );
  }
);
DrawerBody.displayName = "DrawerBody";

/**
 * DrawerClose
 */
type DrawerCloseElement = ElementRef<typeof DialogPrimitive.Close>;
type DrawerCloseProps = { children: ReactNode };

const DrawerClose = forwardRef<DrawerCloseElement, DrawerCloseProps>(
  (props, ref) => {
    const { children } = props;
    return (
      <DialogPrimitive.Close ref={ref} asChild>
        {children}
      </DialogPrimitive.Close>
    );
  }
);
DrawerClose.displayName = "DrawerClose";

/**
 * DrawerFooter
 */
type DrawerFooterElement = ElementRef<typeof Box>;
type DrawerFooterProps = { children: ReactNode };

const DrawerFooter = forwardRef<DrawerFooterElement, DrawerFooterProps>(
  (props, ref) => {
    const { children } = props;
    return (
      <Box ref={ref} className="morph-DrawerFooter">
        {children}
      </Box>
    );
  }
);
DrawerFooter.displayName = "DrawerFooter";

/**
 * Export
 */

export const Drawer = {
  Root: DrawerRoot,
  Content: DrawerContent,
  Title: DrawerTitle,
  Description: DrawerDescription,
  Body: DrawerBody,
  Close: DrawerClose,
  Footer: DrawerFooter,
};

export type {
  DrawerRootProps,
  DrawerContentProps,
  DrawerTitleProps,
  DrawerDescriptionProps,
  DrawerBodyProps,
  DrawerCloseProps,
  DrawerFooterProps,
};
