import { Dispatch, SetStateAction } from "react";
import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil";
import { PusherEvenyPayload } from "../types";

type NotebookRealtimeProgressState = PusherEvenyPayload[];

const notebookRealtimeProgressFamily = atomFamily<
  NotebookRealtimeProgressState,
  { notebookId: string }
>({
  key: "notebook-realtime-progress",
  default: [],
});

const useNotebookRealtimeProgress = (
  notebookId: string
): PusherEvenyPayload[] => {
  const notebookRealtimeProgress = useRecoilValue(
    notebookRealtimeProgressFamily({ notebookId })
  );
  return notebookRealtimeProgress;
};

const useSetNotebookRealtimeProgress = (
  notebookId: string
): Dispatch<SetStateAction<NotebookRealtimeProgressState>> => {
  const setNotebookRealtimeProgress = useSetRecoilState(
    notebookRealtimeProgressFamily({ notebookId })
  );
  return setNotebookRealtimeProgress;
};

export { useNotebookRealtimeProgress, useSetNotebookRealtimeProgress };
