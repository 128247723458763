import { DragEndEvent, DndContext, pointerWithin } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { styled } from "~/stitches";
import {
  FieldsClientModel,
  FieldsClientModelFactory,
} from "~/clientModel/fields";
import { FieldToggleListItem } from "./FieldToggleListItem";
import { UseExecutable } from "~/clientModel/executable";
import { FieldClientModel } from "~/clientModel/fields/field";

const FieldToggleList = styled("ul", {
  listStyleType: "none",
  ml: 0,
  maxHeight: "50vh",
  overflowY: "auto",
  position: "relative",
});

type FieldSettingFormProps = {
  fields: FieldsClientModel;
  getFieldLabel: (field: FieldClientModel) => string;
  useUpdateFieldVisibilityExecutable: UseExecutable<
    void,
    { field: FieldClientModel }
  >;
  useUpdateFieldsOrderExecutable: UseExecutable<
    void,
    { fields: FieldsClientModel }
  >;
};

export const FieldSettingForm = (props: FieldSettingFormProps) => {
  const {
    fields,
    useUpdateFieldVisibilityExecutable,
    useUpdateFieldsOrderExecutable,
    getFieldLabel,
  } = props;

  const updateFieldsOrderExecutable = useUpdateFieldsOrderExecutable();

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = fields.allFields.findIndex(
        (field) => field.name === active.id
      );
      const newIndex = fields.allFields.findIndex(
        (field) => field.name === over.id
      );
      const reorderedFields = FieldsClientModelFactory.createFromFields(
        arrayMove([...fields.allFields], oldIndex, newIndex)
      );
      updateFieldsOrderExecutable.execute({ fields: reorderedFields });
    }
  };

  return (
    <>
      <DndContext onDragEnd={handleDragEnd} collisionDetection={pointerWithin}>
        <SortableContext
          items={fields.allFields.map((field) => field.name)}
          strategy={verticalListSortingStrategy}
        >
          <FieldToggleList>
            {fields.allFields.map((field) => (
              <FieldToggleListItem
                key={field.name}
                field={field}
                useUpdateFieldVisibilityExecutable={
                  useUpdateFieldVisibilityExecutable
                }
                getFieldLabel={getFieldLabel}
              />
            ))}
          </FieldToggleList>
        </SortableContext>
      </DndContext>
    </>
  );
};
