import { TargetDayClientModel } from "./targetDay";
import { TargetDaysClientModel } from "./TargetDaysClientModel";

export class TargetDaysClientModelFactory {
  public static createFromTargetDaysArray(
    targetDayArray: number[]
  ): TargetDaysClientModel {
    const isSelected = (index: number): boolean => targetDayArray[index] === 1;

    const targetDays = [
      new TargetDayClientModel({
        isSelected: isSelected(0),
        dayType: "Sunday",
      }),
      new TargetDayClientModel({
        isSelected: isSelected(1),
        dayType: "Monday",
      }),
      new TargetDayClientModel({
        isSelected: isSelected(2),
        dayType: "Tuesday",
      }),
      new TargetDayClientModel({
        isSelected: isSelected(3),
        dayType: "Wednesday",
      }),
      new TargetDayClientModel({
        isSelected: isSelected(4),
        dayType: "Thursday",
      }),
      new TargetDayClientModel({
        isSelected: isSelected(5),
        dayType: "Friday",
      }),
      new TargetDayClientModel({
        isSelected: isSelected(6),
        dayType: "Saturday",
      }),
    ] as const;

    return new TargetDaysClientModel({
      targetDays,
    });
  }

  public static createInitialTargetDays(): TargetDaysClientModel {
    return new TargetDaysClientModel({
      targetDays: [
        new TargetDayClientModel({
          isSelected: false,
          dayType: "Sunday",
        }),
        new TargetDayClientModel({
          isSelected: false,
          dayType: "Monday",
        }),
        new TargetDayClientModel({
          isSelected: false,
          dayType: "Tuesday",
        }),
        new TargetDayClientModel({
          isSelected: false,
          dayType: "Wednesday",
        }),
        new TargetDayClientModel({
          isSelected: false,
          dayType: "Thursday",
        }),
        new TargetDayClientModel({
          isSelected: false,
          dayType: "Friday",
        }),
        new TargetDayClientModel({
          isSelected: false,
          dayType: "Saturday",
        }),
      ],
    });
  }
}
