import { BsTrash } from "react-icons/bs";
import { UseExecutable } from "~/clientModel/executable";
import { RecordsClientModel } from "~/clientModel/records";
import { RecordClientModel } from "~/clientModel/records/record";
import { TableSelectionClientModel } from "~/clientModel/tables/tableMeta/TableSelection";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { DangerDialog } from "~/components_next/Dialog";
import { useDisclosure } from "~/hooks/useDisclosure";
import { styled } from "~/stitches";

const BoxWithBg = styled(Box, {
  backgroundColor: "$bg0",
});

type DeleteSelectedRecordsButtonProps = {
  records: RecordsClientModel;
  tableSelection: TableSelectionClientModel;
  onTableSelectionChange: (tableSelection: TableSelectionClientModel) => void;
  useDeleteRecordsExecutable: UseExecutable<
    void,
    { recordArray: RecordClientModel[] }
  >;
};

const DeleteSelectedRecordsButton = (
  props: DeleteSelectedRecordsButtonProps
) => {
  const {
    records,
    tableSelection,
    useDeleteRecordsExecutable,
    onTableSelectionChange,
  } = props;

  const { isOpen, setIsOpen, onOpen, onClose } = useDisclosure();

  const deleteRecordsExecutable = useDeleteRecordsExecutable();

  const handleDeleteRecords = async () => {
    await deleteRecordsExecutable.execute({
      recordArray: tableSelection.selectedRecordsIdentifiers.flatMap(
        (identifier) => {
          const foundRecord = records.allRecords.find((record) =>
            record.recordIdentifier.isSame(identifier)
          );
          return foundRecord ? [foundRecord] : [];
        }
      ),
    });
    onTableSelectionChange(tableSelection.unselectAllRecords());
    onClose();
  };

  return (
    <>
      {tableSelection.isSomeRecordSelected && (
        <BoxWithBg>
          <Button
            size="sm"
            variant="secondary"
            colorSchemeOverride="red"
            onClick={onOpen}
            isLoading={deleteRecordsExecutable.isExecuting}
          >
            <BsTrash />
            Delete selected records
          </Button>
        </BoxWithBg>
      )}

      <DangerDialog
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        description="Are you sure you want to delete the selected records? You cannot undo this action."
        onConfirm={handleDeleteRecords}
        isConfirmLoading={deleteRecordsExecutable.isExecuting}
      />
    </>
  );
};

export { DeleteSelectedRecordsButton };
