import { useDataApiKey } from "~/utilHooks/useDataApiKey";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { ToolbarWrapper } from "../components/atoms/ToolbarWrapper";
import { useMutation, useQuery } from "react-query";
import {
  useDeleteDataApiMutation,
  useFindDataApiQuery,
  useRegistDataApiMutation,
} from "~/serverStateStore";
import { useTableSlug } from "~/utilHooks/useTableSlug";
import { BsThreeDotsVertical, BsTrash } from "react-icons/bs";
import { PresenceIcons } from "../../RealtimeCollaboration";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useErrorToast } from "~/components_next/Error";
import { convertDataApiObject } from "../../DataApi/util/convertDataApiObject";
import { getPath } from "~/routing";
import { Badge } from "~/components_next/Badge";
import { Text } from "~/components_next/Text";
import { Box, Flex } from "@radix-ui/themes";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { IconButton } from "~/components_next/IconButton";
import { Spacer } from "~/components_next/Spacer";
import { Input } from "~/components_next/Input";
import { Button } from "~/components_next/Button";
import { Divider } from "~/components_next/Divider";

const DataApiToolbar = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const tableSlug = useTableSlug();
  const dataApiKey = useDataApiKey();

  const { data: dataApi } = useQuery({
    ...useFindDataApiQuery({
      databaseId,
      teamSlug,
      tableSlug,
      apiKey: dataApiKey,
    }),
    onSuccess: (data) => {
      setDataApiMeta({
        apiName: data.apiName,
      });
    },
  });

  /**
   * Data API Meta
   */
  const [dataApiMeta, setDataApiMeta] = useState<{ apiName: string }>({
    apiName: "",
  });

  /**
   * API Requests
   */
  const { errorToast } = useErrorToast({});

  const { mutateAsync: updateDataApi, isLoading: isUpdatingDataApi } =
    useMutation({
      ...useRegistDataApiMutation({
        databaseId,
        teamSlug,
        tableSlug: dataApi?.tableSlug ?? "",
      }),
    });

  const handleSaveClicked = () => {
    if (!dataApi) return;

    const requestObject = convertDataApiObject(dataApi);

    if (!requestObject) return;

    try {
      updateDataApi({
        requestBody: {
          ...requestObject,
          apiName: dataApiMeta.apiName,
        },
      });
    } catch (e) {
      errorToast(e);
    }
  };

  // Delete
  const navigate = useNavigate();

  const { mutateAsync: deleteDataApi, isLoading: isDeleting } = useMutation({
    ...useDeleteDataApiMutation({
      databaseId,
      teamSlug,
    }),
  });

  const handleDeleteClicked = async () => {
    try {
      await deleteDataApi({
        apiKey: dataApi?.apiKey,
      });
      if (dataApi?.tableSlug) {
        navigate(
          getPath("source", {
            teamSlug,
            databaseId,
            tableSlug: dataApi?.tableSlug,
          })
        );
      } else {
        navigate(getPath("databaseHome", { teamSlug, databaseId }));
      }
    } catch (e) {
      errorToast(e);
    }
  };

  return (
    <ToolbarWrapper>
      {dataApi && (
        <>
          <Badge variant="tertiary">
            Data API - {dataApi.apiType.toUpperCase()}
          </Badge>
          <Box>
            <Text>{dataApi.apiName}</Text>
          </Box>
          <SimpleDropdownMenu
            trigger={
              <IconButton
                icon={<BsThreeDotsVertical />}
                tooltip="Data API Settings"
              />
            }
          >
            <Box p="2">
              <Input
                variant="primary"
                label="Name"
                value={dataApiMeta.apiName}
                onChange={(event) => {
                  const value = event.target.value as string;
                  setDataApiMeta((prev) => ({
                    ...prev,
                    apiName: value,
                  }));
                }}
              />
              <Flex mt="2">
                <Spacer />
                <Button
                  variant="primary"
                  onClick={handleSaveClicked}
                  isLoading={isUpdatingDataApi}
                >
                  Save
                </Button>
              </Flex>

              <Divider />
              <Flex>
                <Spacer />
                <Button
                  variant="actionText"
                  onClick={handleDeleteClicked}
                  isLoading={isDeleting}
                >
                  <BsTrash />
                  Delete API
                </Button>
              </Flex>
            </Box>
          </SimpleDropdownMenu>

          <Spacer />
          <PresenceIcons maxAvatars={3} />
        </>
      )}
    </ToolbarWrapper>
  );
};

export { DataApiToolbar };
