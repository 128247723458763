import { FieldClientModel } from "~/clientModel/fields/field";
import {
  UpdatableRecordEntryClientModelBaseConstructorParams,
  UpdatableRecordEntryClientModelBase,
} from "../RecordEntryClientModelBase";
import { zValidate, zAllowNullIfNullable } from "../validatorsUtil";
import { z } from "zod";

const attachmentValueSchema = z.object({ data: z.string(), url: z.string() });

type AttachmentRecordEntryClientModelData =
  UpdatableRecordEntryClientModelBaseConstructorParams;

type AttachmentValueType = z.infer<typeof attachmentValueSchema> | null;

export class AttachmentRecordEntryClientModel extends UpdatableRecordEntryClientModelBase<AttachmentValueType> {
  constructor(data: AttachmentRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "attachment" {
    return "attachment";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([attachmentValueSchema, zAllowNullIfNullable(field)]),
      this.data.rawValue
    );
  }

  public updateValue(
    value: AttachmentValueType | null
  ): AttachmentRecordEntryClientModel {
    return new AttachmentRecordEntryClientModel({
      key: this.data.key,
      rawValue: value,
      meta: this.data.meta,
    });
  }

  public getValidatedDisplayValue = this.getValidatedValue;
}
