import { useCallback, useMemo } from "react";
import { FindCanvasClientModel } from "~/clientModel/canvas";
import {
  CanvasCellClientModelFactory,
  CanvasCellClientModelUnion,
} from "~/clientModel/canvas/CanvasCellClientModel";
import { Box } from "~/components_next/Box";
import {
  useNotebookLayout,
  useSetNotebookLayout,
} from "~/features/FlipNotebook/state/notebookLayout";
import { useNotebookLiveObject } from "~/features/RealtimeCollaboration/Notebook/useNotebookLiveObject";
import { CanvasPlaygroundPresenter } from "~/presenters/canvas";

import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useNotebookId } from "~/utilHooks/useNotebookId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { createUseAddCellToPageExecutable } from "./createUseAddCellToPageExecutable";
import { createUseCreateCellExecutable } from "./createUseCreateCellExecutable";
import { createUseDeleteCellExecutable } from "./createUseDeleteCellExecutable";
import { createUseExecuteCellWithVariablesLoadable } from "./createUseExecuteCellLoadable";
import { createUseFetchFileExecutable } from "./createUseFetchFileExecutable";
import { createUseSourceFieldsLoadable } from "./createUseSourceFieldsLoadable";
import { createUseUpdateCellExecutable } from "./createUseUpdateCellExecutable";
import { createUseUpdateCellWithoutRunExecutable } from "./createUseUpdateCellWithoutRunExecutable";
import { createUseViewFieldsLoadable } from "./createUseViewFieldsLoadable";
import { useTableViewProps } from "./tableViewProps/useTableViewProps";

import { createUseSourcesLoadable } from "./useSourcesLoadable";
import { createUseVariableOptionsLoadable } from "./createUseVariableOptionsLoadable";
import { usePlaygroundCoworkerState } from "./coworkerState/usePlaygroundCowokerState";
import { createUseCreatePageExecutable } from "./createUseCreatePageExecutable";
import { useCellStyleProps } from "./cellStyleProps/useCellStyleProps";
import { createUseVisualizationPromptConfigsLoadable } from "./createUseVisualizationPromptConfigsLoadable";

/**
 * With Liveblocks value
 * Liveblocksの値を取得するhookを使いたいので、一つネストさせる必要がある.
 * 1個上にRoomProviderがある
 */
const CanvasPlaygroundContainer = (props: {
  findCanvasClientModel: FindCanvasClientModel;
}) => {
  const { findCanvasClientModel } = props;

  /**
   * Path parameters
   */
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const notebookId = useNotebookId();

  /**
   * Cells
   */
  const {
    cells: liveCells,
    updateNotebookCells,
    addNoteookCell,
    removeNotebookCell,
  } = useNotebookLiveObject();
  const cellClientModels = useMemo(() => {
    return (
      liveCells
        ?.map((cell) => {
          return CanvasCellClientModelFactory.fromLiveblocksObject(cell);
        })
        .filter(
          (cellInstance): cellInstance is CanvasCellClientModelUnion =>
            cellInstance !== null
        ) || []
    );
  }, [liveCells]);

  /**
   * Loadable  & Executable
   */
  // file download
  const useFetchFileExecutable = useMemo(() => {
    return createUseFetchFileExecutable({
      teamSlug,
    });
  }, [teamSlug]);

  // cell execution
  const useExecuteCellLoadable = useMemo(() => {
    return createUseExecuteCellWithVariablesLoadable({
      teamSlug,
      notebookId,
    });
  }, [teamSlug, notebookId]);

  // sources loadable
  const useSourcesLoadables = useMemo(() => {
    return createUseSourcesLoadable({
      teamSlug,
      databaseId,
    });
  }, [teamSlug, databaseId]);

  // add live cell
  const onAddLiveCell = useCallback(
    (cell: CanvasCellClientModelUnion) => {
      addNoteookCell(cell.rawData);
    },
    [addNoteookCell]
  );
  // remove live cell
  const onRemoveLiveCell = useCallback(
    (cellId: string) => {
      const cellIndex = liveCells?.findIndex(
        (liveCell) => liveCell.cellId === cellId
      );

      if (cellIndex === undefined || cellIndex < 0) {
        throw new Error("Cell not found");
      }
      removeNotebookCell(cellIndex);
    },
    [liveCells, removeNotebookCell]
  );

  // update live cell
  const onUpdateLiveCell = useCallback(
    (cell: CanvasCellClientModelUnion) => {
      const cellIndex = liveCells?.findIndex(
        (liveCell) => liveCell.cellId === cell.cellId
      );

      if (cellIndex === undefined || cellIndex < 0) {
        throw new Error("Cell not found");
      }

      updateNotebookCells({
        cell: cell.rawData,
        index: cellIndex,
      });
    },
    [updateNotebookCells, liveCells]
  );

  // create cell
  const useCreateCellExecutable = useMemo(() => {
    return createUseCreateCellExecutable({
      teamSlug,
      notebookId,
      databaseId,
      onAddLiveCell,
    });
  }, [teamSlug, notebookId, databaseId, onAddLiveCell]);

  // visualization prompt
  const useVisualizationPromptConfigsLoadable = useMemo(() => {
    return createUseVisualizationPromptConfigsLoadable({
      teamSlug,
    });
  }, [teamSlug]);

  // update cell
  const useUpdateCellExecutable = useMemo(() => {
    return createUseUpdateCellExecutable({
      teamSlug,
      notebookId,
      databaseId,
      onUpdateLiveCell,
    });
  }, [teamSlug, notebookId, databaseId, onUpdateLiveCell]);

  // update cell without run
  const useUpdateCellWithoutRunExecutable = useMemo(() => {
    return createUseUpdateCellWithoutRunExecutable({
      teamSlug,
      notebookId,
      databaseId,
      onUpdateLiveCell,
    });
  }, [teamSlug, notebookId, databaseId, onUpdateLiveCell]);

  // create new page
  const useCreatePageExecutable = useMemo(() => {
    return createUseCreatePageExecutable({
      teamSlug,
      notebookId,
      databaseId,
    });
  }, [teamSlug, notebookId, databaseId]);

  // add cell to page
  const useAddCellToPageExecutable = useMemo(() => {
    return createUseAddCellToPageExecutable({
      teamSlug,
      notebookId,
      databaseId,
    });
  }, [teamSlug, notebookId, databaseId]);

  // delete cell
  const useDeleteCellExecutable = useMemo(() => {
    return createUseDeleteCellExecutable({
      teamSlug,
      databaseId,
      notebookId,
    });
  }, [teamSlug, databaseId, notebookId]);

  // join関係のDI
  const useSourceFieldsLoadalbe = useMemo(() => {
    return createUseSourceFieldsLoadable({
      teamSlug,
      databaseId,
    });
  }, [teamSlug, databaseId]);

  const useViewFieldsLoadable = useMemo(() => {
    return createUseViewFieldsLoadable({
      teamSlug,
      databaseId,
    });
  }, [teamSlug, databaseId]);

  // variable関係
  const useGetVariableOptionsLoadable = useMemo(() => {
    return createUseVariableOptionsLoadable({
      teamSlug,
      notebookId,
    });
  }, [teamSlug, notebookId]);

  // Cell Style関係
  const cellStyleProps = useCellStyleProps({
    teamSlug,
  });

  // Table View Cell
  const tableViewProps = useTableViewProps({
    teamSlug,
    databaseId,
  });

  /**
   * For sub components
   */
  const notebookLayout = useNotebookLayout(notebookId);
  const setNotebookLayout = useSetNotebookLayout(notebookId);
  const handleSidebarResize = useCallback(
    (width: number) => {
      setNotebookLayout({
        ...notebookLayout,
        rightSidebarWidth: width,
      });
    },
    [notebookLayout, setNotebookLayout]
  );

  /**
   * Coworker
   */
  const _coworkerStateProps = usePlaygroundCoworkerState();
  const coworkerStateProps = useMemo(() => {
    return {
      coworkerStates: _coworkerStateProps.playgroundCoworkerStates,
      onUpdateState: _coworkerStateProps.updateMyNotebookState,
    };
  }, [_coworkerStateProps]);

  return (
    <Box
      css={{
        height: "calc(100vh - 40px)",
      }}
    >
      <CanvasPlaygroundPresenter
        canvasClientModel={findCanvasClientModel}
        cells={cellClientModels}
        useFetchFileExecutable={useFetchFileExecutable}
        useExecuteCellLoadable={useExecuteCellLoadable}
        useSourcesLoadables={useSourcesLoadables}
        useCreateCellExecutable={useCreateCellExecutable}
        useUpdateCellExecutable={useUpdateCellExecutable}
        useUpdateCellWitouhRunExecutable={useUpdateCellWithoutRunExecutable}
        useCreatePageExecutable={useCreatePageExecutable}
        useVisualizationPromptConfigsLoadable={
          useVisualizationPromptConfigsLoadable
        }
        useAddCellToPageExecutable={useAddCellToPageExecutable}
        useDeleteCellExecutable={useDeleteCellExecutable}
        onAddLiveCell={onAddLiveCell}
        onUpdateLiveCell={onUpdateLiveCell}
        onRemoveLiveCell={onRemoveLiveCell}
        sidebarWidth={notebookLayout?.rightSidebarWidth || 400}
        onSidebarResize={handleSidebarResize}
        useSourceFieldsLoadable={useSourceFieldsLoadalbe}
        useViewFieldsLoadable={useViewFieldsLoadable}
        useVariableOptionsLoadable={useGetVariableOptionsLoadable}
        cellStyleProps={cellStyleProps}
        tableViewProps={tableViewProps}
        coworkerStateProps={coworkerStateProps}
      />
    </Box>
  );
};

export { CanvasPlaygroundContainer };
