import { Flex } from "@radix-ui/themes";
import { ChangeEvent, useState } from "react";
import { Button } from "~/components_next/Button";
import { Input } from "~/components_next/Input";
import { styled } from "~/stitches";
import { LinkValueType } from "../../hooks/textStyles/useLink";

type LinkFormProps = {
  initialValue: LinkValueType;
  onSave: (value: LinkValueType) => void;
  onClear: () => void;
};

const FormContainer = styled(Flex, {
  backgroundColor: "$bg0",
  borderRadius: "$2",
  boxShadow: "$4",
  width: "320px",
});

const LinkForm = (props: LinkFormProps) => {
  const { initialValue, onSave, onClear } = props;

  const [value, setValue] = useState(initialValue);

  const handleHrefChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue({ ...value, href: event.target.value });
  };

  return (
    <form onSubmit={() => onSave(value)} onReset={onClear}>
      <FormContainer gap="2" direction="column">
        <Input
          size="sm"
          variant="primary"
          label="URL"
          type="url"
          value={value.href}
          onChange={handleHrefChange}
        />
        <Flex direction="row" gap="2" justify="end">
          <Button type="reset" size="xs" variant="secondary">
            Cancel
          </Button>
          <Button type="submit" size="xs" variant="primary">
            Save
          </Button>
        </Flex>
      </FormContainer>
    </form>
  );
};

export { LinkForm };
