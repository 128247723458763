import constate from "constate";
import { MouseEvent } from "react";
import { BsDownload } from "react-icons/bs";
import { UseExecutable } from "~/clientModel/executable";
import { Button } from "~/components_next/Button";
import { useErrorToast } from "~/components_next/Error";

/**
 * DI
 */
const useCanvasFetchFileExecutableContext = (props: {
  useFetchFileExecutable: UseExecutable<
    void,
    {
      path: string;
    },
    {
      path: string | null;
      url: string;
    },
    unknown
  >;
}) => {
  const { useFetchFileExecutable } = props;

  return {
    useFetchFileExecutable,
  };
};

const [UseFetchFileExecutableProvider, useUseFetchFileExecutable] = constate(
  useCanvasFetchFileExecutableContext,
  (value) => value.useFetchFileExecutable
);

/**
 * コンポーネント実装
 */
type CanvasCellPromptUnitFileProps = {
  path: string;
};

const CanvasCellPromptUnitFile = (props: CanvasCellPromptUnitFileProps) => {
  const { path } = props;

  const useFetchFileExecutable = useUseFetchFileExecutable();
  const fetchFileExecutable = useFetchFileExecutable();

  const { errorToast } = useErrorToast({});

  const handleDownloadClick = async (event: MouseEvent) => {
    event.stopPropagation();
    try {
      const response = await fetchFileExecutable.execute({ path });

      if (!response) {
        return;
      }

      window.open(response.url, "_blank");
    } catch (e) {
      errorToast(e);
    }
  };

  return (
    <Button
      variant="actionText"
      onClick={handleDownloadClick}
      isLoading={fetchFileExecutable.isExecuting}
    >
      <BsDownload />
      Download File
    </Button>
  );
};

export { CanvasCellPromptUnitFile, UseFetchFileExecutableProvider };
