import { useQuery } from "react-query";
import { Loadable, useComposeLoadable } from "~/clientModel/loadable";

import {
  RecordsClientModel,
  RecordsClientModelFactory,
} from "~/clientModel/records";
import { useListRecordsWithSQLQuery } from "~/serverStateStore";

type useDownloadCsvWithSqlPreviewRecordsLoadableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  sql: string;
};

const useDownloadCsvWithSqlPreviewRecordsLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
  sql,
}: useDownloadCsvWithSqlPreviewRecordsLoadableParams): Loadable<
  RecordsClientModel,
  unknown
> => {
  return useComposeLoadable(
    useQuery({
      ...useListRecordsWithSQLQuery({
        teamSlug,
        databaseId,
        tableSlug,
        sql,
      }),
      select: (data) => {
        return RecordsClientModelFactory.createFromQueryRecordListResponseWithFields(
          data
        );
      },
      enabled: !!sql,
    })
  );
};

export const createUseDownloadCsvWithSqlPreviewRecordsLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
  sql,
}: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  sql: string;
}) => {
  return () =>
    useDownloadCsvWithSqlPreviewRecordsLoadable({
      teamSlug,
      databaseId,
      tableSlug,
      sql,
    });
};
