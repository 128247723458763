import { CoworkerState, RecordsTableBaseRecord } from "../types";
import { isSameRecordWithPrimaryKey } from "./recordsTableRecordUtil";

export const isFieldSelectedByCoworker = (
  fieldSlug: string,
  coworkerStates: CoworkerState[]
) => {
  return coworkerStates.some((coworkerState) => {
    return coworkerState.fieldSelection.includes(fieldSlug);
  });
};

export const getCoworkerSelectionForField = (
  fieldSlug: string,
  coworkerStates: CoworkerState[]
) => {
  return coworkerStates.filter((coworkerState) => {
    return coworkerState.fieldSelection.includes(fieldSlug);
  });
};

export const isCellSelectedByCoworker = (
  fieldSlug: string,
  record: RecordsTableBaseRecord,
  coworkerStates: CoworkerState[]
) => {
  const isInCellSelection = coworkerStates.some((coworkerState) => {
    return coworkerState.cellSelection.some((cellSelection) => {
      return (
        cellSelection.fieldSlugs.includes(fieldSlug) &&
        isSameRecordWithPrimaryKey(cellSelection.record, record)
      );
    });
  });

  const isInFieldSelection = isFieldSelectedByCoworker(
    fieldSlug,
    coworkerStates
  );

  const isInEditingCell = coworkerStates.some((coworkerState) => {
    return (
      coworkerState.editingCell?.fieldSlug === fieldSlug &&
      isSameRecordWithPrimaryKey(coworkerState.editingCell?.record, record)
    );
  });

  const isInSelectedRecords = coworkerStates.some((coworkerState) => {
    return coworkerState.recordSelection.some((selectedRecord) => {
      return isSameRecordWithPrimaryKey(selectedRecord, record);
    });
  });

  return (
    isInCellSelection ||
    isInFieldSelection ||
    isInEditingCell ||
    isInSelectedRecords
  );
};

export const isInEditingByCoworker = (
  fieldSlug: string,
  record: RecordsTableBaseRecord,
  coworkerStates: CoworkerState[]
) => {
  const isInEditingCell = coworkerStates.some((coworkerState) => {
    return (
      coworkerState.editingCell?.fieldSlug === fieldSlug &&
      isSameRecordWithPrimaryKey(coworkerState.editingCell?.record, record)
    );
  });

  return isInEditingCell;
};

export const getCoworkerSelectionForCell = (
  fieldSlug: string,
  record: RecordsTableBaseRecord,
  coworkerStates: CoworkerState[]
): CoworkerState[] => {
  return coworkerStates.filter((coworkerState) => {
    return coworkerState.cellSelection.some((cellSelection) => {
      return (
        cellSelection.fieldSlugs.includes(fieldSlug) &&
        isSameRecordWithPrimaryKey(cellSelection.record, record)
      );
    });
  });
};

export const getCoworkerSelectionRecordSelection = (
  record: RecordsTableBaseRecord,
  coworkerStates: CoworkerState[]
): CoworkerState[] => {
  return coworkerStates.filter((coworkerState) => {
    return coworkerState.recordSelection.some((selectedRecord) => {
      return isSameRecordWithPrimaryKey(selectedRecord, record);
    });
  });
};

export const getCoworkerEditingCell = (
  fieldSlug: string,
  record: RecordsTableBaseRecord,
  coworkerStates: CoworkerState[]
): CoworkerState[] => {
  return coworkerStates.filter((coworkerState) => {
    return (
      coworkerState.editingCell?.fieldSlug === fieldSlug &&
      isSameRecordWithPrimaryKey(coworkerState.editingCell?.record, record)
    );
  });
};
