import { SimpleField } from "@usemorph/morph-dashboard-types";
import { RecordModel, AllRecordModelValueUnion } from "~/features/RecordModel";
import { RecordValueInput } from "~/components_next/RecordValue";
import { ValidationErrorsDict } from "~/features/RecordModel/utils/useRecordModelValidation";
import { FieldBadges } from "./FieldBadges";
import { isAutoGeneratedField } from "./utils/classifyFields";
import { Flex } from "@radix-ui/themes";

type FieldCategory = "nonAutoGenerated" | "autoGenerated";

type RecordFormProps = {
  recordModel: RecordModel;
  setRecordModel: (recordModel: RecordModel) => void;
  validationErrorsDict: ValidationErrorsDict;
  simpleFields: SimpleField[];
  displayedFieldCategories: FieldCategory[] | "all";
  isReadOnly?: boolean;
};

export const RecordForm = (props: RecordFormProps) => {
  const {
    simpleFields,
    recordModel,
    setRecordModel,
    validationErrorsDict,
    displayedFieldCategories,
    isReadOnly,
  } = props;

  const handleRecordModelChange = (
    field: SimpleField,
    recordModelValue: AllRecordModelValueUnion
  ) => {
    setRecordModel({
      ...recordModel,
      [field.name]: recordModelValue,
    });
  };

  const displayedFields = simpleFields.flatMap((field) => {
    if (displayedFieldCategories === "all") return [field];

    const category: FieldCategory = isAutoGeneratedField(field)
      ? "autoGenerated"
      : "nonAutoGenerated";

    return displayedFieldCategories.includes(category) ? [field] : [];
  });

  return (
    <Flex direction="column" gap="6">
      {displayedFields.map((field) => {
        return (
          <Flex direction="column" gap="1" key={field.name}>
            <FieldBadges field={field} />
            <RecordValueInput
              field={field}
              recordModelValue={recordModel[field.name]}
              errorMessages={
                `${field.name}` in validationErrorsDict
                  ? validationErrorsDict[field.name].map(({ detail }) => detail)
                  : []
              }
              onChange={(recordModelValue) =>
                handleRecordModelChange(field, recordModelValue)
              }
              isReadOnly={isReadOnly}
              size="sm"
            />
          </Flex>
        );
      })}
    </Flex>
  );
};
