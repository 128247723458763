import { useQuery } from "react-query";
import { Loadable, useComposeLoadable } from "~/clientModel/loadable";
import {
  SyncStatusClientModel,
  SyncStatusClientModelFactory,
} from "~/clientModel/syncStatus";

import { useListNormalizedTableStatusQuery } from "~/serverStateStore";

type useSyncStatusLoadableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

export const useSyncStatusLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: useSyncStatusLoadableParams): Loadable<
  SyncStatusClientModel | null,
  unknown
> => {
  return useComposeLoadable(
    useQuery({
      ...useListNormalizedTableStatusQuery({
        teamSlug,
        databaseId,
      }),
      select: (data) => {
        const latestStatusItem = data.items.find(
          (item) => item.tableSlug === tableSlug
        );
        return latestStatusItem
          ? SyncStatusClientModelFactory.createFromDashboardNormalizedTableStatusObject(
              latestStatusItem
            )
          : null;
      },
    })
  );
};
