import { useEditorOrThrow } from "../utils";

const useSubscript = () => {
  const editor = useEditorOrThrow();

  const handleToggle = () => {
    editor.chain().focus().toggleSubscript().run();
  };

  const isActive = editor.isActive("subscript");

  const isDisabled = !editor.can().chain().focus().toggleSubscript().run();

  return {
    handleToggle,
    isActive,
    isDisabled,
  };
};

export { useSubscript };
