import { MouseEventHandler } from "react";
import { BsPlus } from "react-icons/bs";
import { Button } from "~/components_next/Button";

type FooterProps = {
  onClickCreateButton: MouseEventHandler<HTMLButtonElement>;
  isCreating: boolean;
  isDisabled: boolean;
};

const Footer = (props: FooterProps) => {
  const { onClickCreateButton, isCreating, isDisabled } = props;

  return (
    <Button
      isLoading={isCreating}
      isDisabled={isDisabled}
      variant="primary"
      onClick={onClickCreateButton}
      size="sm"
      style={{ width: "100%" }}
    >
      <BsPlus />
      Create View
    </Button>
  );
};

export { Footer };
