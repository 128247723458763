import { RecordValueReadOnlyInputCommonProps } from "../../type";
import { InputTooltip } from "../../atoms/InputTooltip";
import { DateTimeInputBase } from "./common/DateTimeInputBase";

const LastEditedAtReadonlyInput = (
  props: RecordValueReadOnlyInputCommonProps<"lastEditedAt">
) => {
  const { value, errorMessages, isNullable, size } = props;

  return (
    <InputTooltip isAutoGenerated>
      <DateTimeInputBase
        value={value}
        size={size}
        errorMessages={errorMessages}
        isNullable={isNullable}
        isReadOnly={true}
      />
    </InputTooltip>
  );
};

export { LastEditedAtReadonlyInput };
