import { FieldClientModel } from "~/clientModel/fields/field";
import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { StringValidator } from "./CommonValueValidator";
import {
  FilterOperationClientModelBase,
  FilterOperationWithValueInterface,
} from "./FilterOperationClientModelBase";

type LikeFilterOperationClientModelData = {
  value: string;
};

export class LikeFilterOperationClientModel
  extends FilterOperationClientModelBase
  implements
    FilterOperationWithValueInterface<string, LikeFilterOperationClientModel>
{
  readonly #data: LikeFilterOperationClientModelData;

  public constructor(data: LikeFilterOperationClientModelData) {
    super();
    this.#data = data;
  }

  public get data() {
    return this.#data;
  }

  public get type(): "like" {
    return "like";
  }

  public get operatorValue(): "like" {
    return "like";
  }

  public get operatorLabel(): "Like" {
    return "Like";
  }

  public get operandValue(): string {
    return this.#data.value;
  }

  public updateValue(value: string): LikeFilterOperationClientModel {
    return new LikeFilterOperationClientModel({
      ...this.#data,
      value,
    });
  }

  public validate(targetField: FieldClientModel): ValidationResult {
    return new StringValidator(this.#data.value)
      .shouldNotBeEmpty()
      .shouldBeIntStringIfNumberType(targetField)
      .end();
  }
}
