import { useMutation } from "react-query";
import {
  CanvasCellClientModelFactory,
  CanvasCellClientModelUnion,
} from "~/clientModel/canvas/CanvasCellClientModel";
import { CanvasCreateCellClientModel } from "~/clientModel/canvas/CanvasCreateCellClientModel";
import { useComposeExecutable } from "~/clientModel/executable";
import { useCreateNotebookCellMutaiton } from "~/serverStateStore";

// 応急処置
const camelCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const useCreateCellExecutable = (props: {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
  onAddLiveCell: (cell: CanvasCellClientModelUnion) => void;
}) => {
  const { teamSlug, databaseId, notebookId, onAddLiveCell } = props;

  const createCellMutationOptions = useCreateNotebookCellMutaiton({
    teamSlug,
    databaseId,
    notebookId,
  });

  const mutationFn = async (
    canvasCellInstance: CanvasCreateCellClientModel
  ): Promise<CanvasCellClientModelUnion> => {
    const rawResponse = await createCellMutationOptions.mutationFn(
      canvasCellInstance.requestBody
    );
    const cellInstance =
      CanvasCellClientModelFactory.fromNotebookCellResponse(rawResponse);

    onAddLiveCell(cellInstance);

    return cellInstance;
  };

  return useComposeExecutable(
    useMutation({
      ...createCellMutationOptions,
      mutationFn,
    })
  );
};

export const createUseCreateCellExecutable = (props: {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
  onAddLiveCell: (cell: CanvasCellClientModelUnion) => void;
}) => {
  const { teamSlug, databaseId, notebookId, onAddLiveCell } = props;

  return () =>
    useCreateCellExecutable({
      teamSlug,
      databaseId,
      notebookId,
      onAddLiveCell,
    });
};
