import constate from "constate";
import { FieldsClientModel } from "~/clientModel/fields";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { TablesClientModel } from "~/clientModel/tables";

export type UsePlaygroundSourceFieldsLoadableContextProps = {
  useSourceFieldsLoadable: UseLoadable<
    { tableSlug: string },
    FieldsClientModel
  >;
  useViewFieldsLoadable: UseLoadable<{ viewId: string }, FieldsClientModel>;
  useSourcesLoadables: UseLoadable<void, TablesClientModel>;
};

const usePlaygroundSourceFieldsLoadableContext = (
  props: UsePlaygroundSourceFieldsLoadableContextProps
) => {
  const {
    useSourceFieldsLoadable,
    useViewFieldsLoadable,
    useSourcesLoadables,
  } = props;

  return {
    useSourceFieldsLoadable,
    useViewFieldsLoadable,
    useSourcesLoadables,
  };
};

export const [
  PlaygroundUseSourceFieldsLoadableProvider,
  useUseSourceFieldsLoadable,
  useUseViewFieldsLoadable,
  useUseSourcesLoadable,
] = constate(
  usePlaygroundSourceFieldsLoadableContext,
  (value) => value.useSourceFieldsLoadable,
  (value) => value.useViewFieldsLoadable,
  (value) => value.useSourcesLoadables
);
