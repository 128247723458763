import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { FilterConditionUnitClientModel } from "~/clientModel/queryConditions/filterConditions";
import { NativeSimpleSelect } from "~/components_next/Select/NativeSelect/NativeSimpleSelect";

type KeyInputProps = {
  filterConditionUnit: FilterConditionUnitClientModel;
  fields: FieldsClientModel;
  onFilterConditionUnitChange: (
    operation: FilterConditionUnitClientModel
  ) => void;
  isReadOnly: boolean;
};

export const KeyInput = (props: KeyInputProps) => {
  const {
    filterConditionUnit,
    fields,
    onFilterConditionUnitChange,
    isReadOnly,
  } = props;

  const handleKeyChange = (key: string) => {
    onFilterConditionUnitChange(
      filterConditionUnit.updateTargetField(
        fields.getFieldByFieldNameOrThrow(key)
      )
    );
  };

  const keyOptions = fields.allFields.map((field) => ({
    value: field.name,
    label: field.label,
  }));

  return (
    <NativeSimpleSelect
      variant="primary"
      value={filterConditionUnit.key}
      size="xs"
      onChange={(key) => handleKeyChange(key!)}
      isDisabled={isReadOnly}
      options={keyOptions}
    />
  );
};
