import { ForwardedRef, forwardRef } from "react";
import { TextArea } from "~/components_next/TextArea";
import { RecordValueInputCommonProps } from "../../type";

const LongTextInput = forwardRef(function LongTextInput(
  props: RecordValueInputCommonProps<"longText">,
  ref: ForwardedRef<HTMLTextAreaElement>
) {
  const { value, onChange, isNullable, isReadOnly, errorMessages, size } =
    props;

  return (
    <TextArea
      ref={ref}
      variant="primary"
      size={size}
      value={value ?? ""}
      readOnly={isReadOnly}
      onChange={(event) => onChange?.(event.target.value)}
      errorMessage={errorMessages}
    />
  );
});

export { LongTextInput };
