import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useNodes, useReactFlow } from "reactflow";
import { usePlaygroundOnUpdateState } from "../providers/PlaygroundCoworkerStateProvider";

type MyPresenceEventHandlerProps = {
  offset: { x: number; y: number };
};

const MyPresenceEventHandler = memo((props: MyPresenceEventHandlerProps) => {
  const { offset } = props;
  const onUpdate = usePlaygroundOnUpdateState();

  /**
   * Pointer Event
   */
  const [pointerPosition, setPointerPosition] = useState<{
    x: number;
    y: number;
  }>({ x: -1, y: -1 });

  const handlePointerMove = useCallback(
    (event: PointerEvent) => {
      setPointerPosition({
        x: event.clientX - offset.x,
        y: event.clientY - offset.y,
      });
    },
    [offset]
  );

  useEffect(() => {
    window.addEventListener("pointermove", handlePointerMove);

    return () => {
      window.removeEventListener("pointermove", handlePointerMove);
    };
  }, [handlePointerMove]);

  /**
   * React Flow Selection
   */
  const allNodes = useNodes();
  const selectedNodes = allNodes.filter((node) => node.selected);
  const selectedNodeIds = selectedNodes.map((node) => node.id);

  const selectedCellId = useMemo(() => {
    if (selectedNodeIds.length === 0) {
      return null;
    }

    if (selectedNodeIds.length === 1) {
      return selectedNodeIds[0];
    }

    return selectedNodeIds;
  }, [selectedNodeIds]);

  const reactFlow = useReactFlow();
  useEffect(() => {
    if (!pointerPosition) {
      onUpdate({
        pointer: undefined,
        selectingCellId: selectedCellId,
      });
      return;
    }
    const { x, y } = pointerPosition || { x: 0, y: 0 };
    const projected = reactFlow.project({ x, y });
    onUpdate({
      pointer: projected,
      selectingCellId: selectedCellId,
    });
  }, [pointerPosition, selectedCellId]);

  return null;
});
MyPresenceEventHandler.displayName = "MyPresenceEventHandler";

export { MyPresenceEventHandler };
