import { useMemo } from "react";
import {
  useRecordsTableBaseCellSelection,
  useRecordsTableBaseColumnSizing,
  useRecordsTableBaseCoworkerStates,
  useRecordsTableBaseEditingCell,
  useRecordsTableBaseFields,
  useRecordsTableBaseOptions,
} from "../context/ReactTableBaseContext";
import {
  CellSelectionRect,
  useCellSelectionUtil,
} from "./useCellSelectionUtil";
import { CoworkerState } from "../types";
import { styled } from "~/stitches";
import { Box } from "@radix-ui/themes";
import { UserAvatar } from "~/components_next/User";

const StyledBox = styled(Box, {});

const RecordsTableBaseCellSelectionRect = (props: {
  rect: CellSelectionRect;
}) => {
  const { rect } = props;
  return (
    <>
      <StyledBox
        css={{
          position: "absolute",
          left: `${rect.start.x}px`,
          top: `${rect.start.y}px`,
          width: `${rect.width}px`,
          height: `${rect.height}px`,
          border: `solid 2px $slate8`,
          borderRadius: "$2",
          pointerEvents: "none",
        }}
      ></StyledBox>
    </>
  );
};

const RecordsTableBaseCoworkerCellSelectionRect = (props: {
  rect: CellSelectionRect;
  coworkerState: CoworkerState;
}) => {
  const { rect, coworkerState } = props;

  return (
    <>
      <StyledBox
        css={{
          position: "absolute",
          left: `${rect.start.x}px`,
          top: `${rect.start.y}px`,
          width: `${rect.width}px`,
          height: `${rect.height}px`,
          border: `solid 2px $${coworkerState.colorScheme}8`,
          borderRadius: "sm",
          pointerEvents: "none",
        }}
      ></StyledBox>
      <StyledBox
        css={{
          position: "absolute",
          left: `${rect.start.x + rect.width + 10}px`,
          top: `${rect.start.y}px`,
          border: `solid 2px $${coworkerState.colorScheme}8`,
          boxShadow: "$2",
          borderRadius: "100%",
        }}
      >
        <UserAvatar user={coworkerState.user} size="sm" />
      </StyledBox>
    </>
  );
};

const RecordsTableBaseCoworkerCellSelectionRects = (props: {
  coworkerState: CoworkerState;
}) => {
  const { coworkerState } = props;

  const { isSelectable } = useRecordsTableBaseOptions();
  const fields = useRecordsTableBaseFields();
  const columnSizing = useRecordsTableBaseColumnSizing();

  const { rects: cellSelectionRects } = useCellSelectionUtil(
    columnSizing,
    coworkerState.cellSelection,
    null,
    fields,
    isSelectable
  );

  return (
    <>
      {cellSelectionRects.map((rect, index) => {
        return (
          <RecordsTableBaseCoworkerCellSelectionRect
            key={index}
            rect={rect}
            coworkerState={coworkerState}
          />
        );
      })}
    </>
  );
};

const RecordsTableBaseCellSelectionRects = () => {
  const fields = useRecordsTableBaseFields();
  const { isSelectable } = useRecordsTableBaseOptions();
  const columnSizing = useRecordsTableBaseColumnSizing();
  const cellSelection = useRecordsTableBaseCellSelection();
  const editingCell = useRecordsTableBaseEditingCell();
  const { rects } = useCellSelectionUtil(
    columnSizing,
    cellSelection,
    editingCell,
    fields,
    isSelectable
  );

  // coworker state rects
  const coworkerState = useRecordsTableBaseCoworkerStates();
  const coworkerCellSelections = useMemo(() => {
    return coworkerState?.filter((cs) => cs.cellSelection.length > 0) ?? [];
  }, [coworkerState]);
  const coworkerEditingCells = useMemo((): CoworkerState[] => {
    const editingCells = coworkerState?.filter((cs) => cs.editingCell) ?? [];
    return editingCells.map((cs) => {
      return {
        ...cs,
        cellSelection: [
          {
            record: cs.editingCell!.record,
            fieldSlugs: [cs.editingCell!.fieldSlug],
          },
        ],
      };
    });
  }, [coworkerState]);

  return (
    <>
      {rects.map((rect, index) => {
        return <RecordsTableBaseCellSelectionRect key={index} rect={rect} />;
      })}

      {[...coworkerCellSelections, ...coworkerEditingCells].map(
        (coworkerState, index) => {
          return (
            <RecordsTableBaseCoworkerCellSelectionRects
              key={index}
              coworkerState={coworkerState}
            />
          );
        }
      )}
    </>
  );
};

export { RecordsTableBaseCellSelectionRects };
