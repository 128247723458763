import constate from "constate";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import { UseExecutable } from "~/clientModel/executable";

export type UsePlaygroundUpdateCellContextProps = {
  useUpdateCellExecutable: UseExecutable<
    void,
    {
      cell: CanvasCellClientModelUnion;
      shouldGenerate?: boolean;
    },
    CanvasCellClientModelUnion,
    unknown
  >;
  useUpdateCellWitouhRunExecutable: UseExecutable<
    void,
    CanvasCellClientModelUnion,
    CanvasCellClientModelUnion,
    unknown
  >;
};

const usePlaygroundUpdateCellContext = (
  props: UsePlaygroundUpdateCellContextProps
) => {
  const { useUpdateCellExecutable, useUpdateCellWitouhRunExecutable } = props;

  return {
    useUpdateCellExecutable,
    useUpdateCellWitouhRunExecutable,
  };
};

export const [
  PlaygroundUpdateCellExecutableProvider,
  useUseUpdateCellExecutable,
  useUseUpdateCellWithoutRunExecutable,
] = constate(
  usePlaygroundUpdateCellContext,
  (value) => value.useUpdateCellExecutable,
  (value) => value.useUpdateCellWitouhRunExecutable
);
