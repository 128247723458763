import { createContext, ReactNode, useContext } from "react";

type LinkItemContextValue = {
  recalculateCoworkerIconsPositionKey: number;
};

const linkItemContext = createContext<LinkItemContextValue>(
  {} as LinkItemContextValue
);

export const LinkItemContextProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: LinkItemContextValue;
}) => {
  return (
    <linkItemContext.Provider value={value}>
      {children}
    </linkItemContext.Provider>
  );
};

export const useRecalculateCoworkerIconsPositionKey = () => {
  const { recalculateCoworkerIconsPositionKey } = useContext(linkItemContext);

  return recalculateCoworkerIconsPositionKey;
};
