import { useQuery } from "react-query";
import {
  AggregateValueFieldOperatorsClientModel,
  AggregateValueFieldOperatorsClientModelFactory,
} from "~/clientModel/fields/field/fieldType/computed/aggregateValue/aggregateValueFieldOperators";
import { Loadable, useComposeLoadable } from "~/clientModel/loadable";

import { useListComputedFieldOptionsForAggregationQuery } from "~/serverStateStore";

type useAggregateValueFieldOperatorsLoadableParams = {
  teamSlug: string;
};

export const useAggregateValueFieldOperatorsLoadable = ({
  teamSlug,
}: useAggregateValueFieldOperatorsLoadableParams): Loadable<
  AggregateValueFieldOperatorsClientModel,
  unknown
> => {
  return useComposeLoadable(
    useQuery({
      ...useListComputedFieldOptionsForAggregationQuery({
        teamSlug,
      }),
      select: (data) => {
        return AggregateValueFieldOperatorsClientModelFactory.createFromDashboardComputedFieldOptionListResponse(
          data
        );
      },
    })
  );
};
