import { Button } from "~/components_next/Button";
import { useUploadFile } from "./useUploadFile";
import { ChangeEvent, useRef } from "react";
import { useErrorToast } from "~/components_next/Error";
import {
  ClearValueButton,
  ClearValueButtonWrapper,
} from "../../atoms/ClearValueButton";
import { Flex } from "@radix-ui/themes";
import { RecordValueInputCommonProps } from "../../type";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { IconButton } from "~/components_next/IconButton";
import { BsUpload } from "react-icons/bs";
import { ActionIcon } from "~/components_next/Icons";
import { FileDownloadButton } from "./FileDownloadButton";
import { FileNameDisplay } from "./FileNameDisplay";
import { Spacer } from "~/components_next/Spacer";

export const FileInput = (props: RecordValueInputCommonProps<"attachment">) => {
  const { value, onChange, isNullable, errorMessages, size, isReadOnly } =
    props;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const { isUploading, uploadFile } = useUploadFile();

  const openFiler = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  const { errorToast } = useErrorToast({});
  const uploadImage = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const { files } = event.target;
      if (!files) {
        throw new Error("Something went wrong");
      }
      const newValue = await uploadFile(files[0]);
      onChange?.(newValue);
    } catch (e) {
      errorToast(e);
    }
  };

  return (
    <>
      {value ? (
        <ClearValueButtonWrapper>
          <FileNameDisplay data={value.data} />
          <Spacer />
          <FileDownloadButton size={size} url={value.url} />
          <IconButton
            tooltip="Upload File"
            size={size}
            icon={<BsUpload />}
            onClick={openFiler}
            isLoading={isUploading}
            isDisabled={isReadOnly}
          />
          <ClearValueButton
            isDisabled={!isNullable || isReadOnly}
            onClear={() => onChange?.(null)}
            size={size}
          />
        </ClearValueButtonWrapper>
      ) : (
        <Flex align="center">
          <Button
            variant="tertiary"
            onClick={openFiler}
            isLoading={isUploading}
            isDisabled={isReadOnly}
            size={size}
            style={{ flex: 1 }}
          >
            <ActionIcon type="add" />
            Upload
          </Button>
        </Flex>
      )}
      <InputStack errorMessage={errorMessages}>
        <input
          ref={fileInputRef}
          type="file"
          onChange={uploadImage}
          accept="*"
          style={{ visibility: "hidden", height: 0, width: 0 }}
        />
      </InputStack>
    </>
  );
};
