import { DatabaseUsageClientModel } from "~/clientModel/databaseUsage";
import { Loadable } from "~/clientModel/loadable";
import { FindPaymentClientModel } from "~/clientModel/payment/findPaymentClientModel";
import { Box } from "~/components_next/Box";
import { Progress } from "~/components_next/Progress";
import { Text } from "~/components_next/Text";
import { styled } from "~/stitches";

type PlanAppealPresenterProps = {
  paymentLoadable: Loadable<FindPaymentClientModel>;
  usageLoadable: Loadable<DatabaseUsageClientModel>;
  onClick: () => void;
};

const PlanAppealBox = styled(Box, {
  backgroundColor: "white",
  boxShadow: "var(--shadow-4)",
  p: "$3",
  width: "100%",
  border: "solid 1px $border1",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "$bg1",
  },
});

const PlanAppealPresenter = (props: PlanAppealPresenterProps) => {
  const { paymentLoadable, usageLoadable, onClick } = props;

  if (!paymentLoadable.data || !usageLoadable.data) {
    return null;
  }

  if (!paymentLoadable.data.isFreePlan) {
    return null;
  }

  return (
    <PlanAppealBox p="3" onClick={onClick}>
      <Box>
        <Text fontWeight="bold" mb="1">
          Upgrade
        </Text>
      </Box>
      <Box mb="2">
        <Text variant="tinyDescription" style={{ lineHeight: "10px" }}>
          Current usage : {usageLoadable.data.count} / 1000 records
        </Text>
      </Box>
      <Box>
        <Progress progress={usageLoadable.data.usagePercentage} size="xs" />
      </Box>
    </PlanAppealBox>
  );
};

export { PlanAppealPresenter };
