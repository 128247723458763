import { useQueryClient } from "react-query";
import { resourceMembersKeys } from "../keys";

// types
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { ResourceNotebookSchema, ResourceTableSchema, ResourceViewSchema } from "@usemorph/morph-dashboard-types";
import useApiResourceMembers from "~/api/useApiResourceMember";

interface UseDeleteResourceMemberParams {
  teamSlug: string;
  resource: ResourceTableSchema | ResourceNotebookSchema | ResourceViewSchema;
}

const useDeleteResourceMemberMutation = ({
  teamSlug,
  resource,
}: UseDeleteResourceMemberParams) => {
  const { _deleteResourceMember } = useApiResourceMembers();
  const client = useQueryClient();

  return {
    mutationFn: ({ userId }: { userId: string }) => {
      return _deleteResourceMember({
        teamSlug,
        resource,
        userId,
      });
    },
    onSuccess: () => {
      return invalidateAll(
        client,
        resourceMembersKeys.listResourceMembers({
          teamSlug,
          resource,
          resourceType: resource.resourceType,
        })
      );
    },
  };
};

export { useDeleteResourceMemberMutation };
