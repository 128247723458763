import { FilterOperationWithStringArrayValueClientModel } from "~/clientModel/queryConditions/filterConditions/FilterConditionUnit/operations";
import { BadgeInput } from "~/components_next/BadgeInput";

type OperandArrayInputProps = {
  operation: FilterOperationWithStringArrayValueClientModel;
  onOperationChange: (
    operation: FilterOperationWithStringArrayValueClientModel
  ) => void;
  isReadOnly: boolean;
  errorMessages?: readonly string[] | undefined;
};

export const OperandArrayInput = (props: OperandArrayInputProps) => {
  const { operation, onOperationChange, isReadOnly, errorMessages } = props;

  return (
    <BadgeInput
      variant="primary"
      isInvalid={errorMessages && errorMessages.length > 0}
      value={operation.operandValue}
      onChange={(value) => onOperationChange(operation.updateValue(value))}
      size="xs"
      readonly={isReadOnly}
      disabled={isReadOnly}
    />
  );
};
