import { useMemo } from "react";
import { SyncScheduleDropdownItems } from "~/presenters/toolbars/bridge";
import { createUseStartSyncExecutable } from "../hooks/createUseStartSyncExecutable";
import { useFieldsLoadable } from "../hooks/useFieldsLoadable";

type SyncScheduleDropdownItemsProps = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  onSyncScheduleSettingsClick: () => void;
};

export const SyncScheduleDropdownItemsContainer = (
  props: SyncScheduleDropdownItemsProps
) => {
  const { teamSlug, databaseId, tableSlug, onSyncScheduleSettingsClick } =
    props;

  const fieldsLoadable = useFieldsLoadable({
    teamSlug,
    databaseId,
    tableSlug,
  });

  const useStartSyncExecutable = useMemo(
    () =>
      createUseStartSyncExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const startSyncExecutable = useStartSyncExecutable();

  return (
    <SyncScheduleDropdownItems
      fieldsLoadable={fieldsLoadable}
      onSyncScheduleSettingsClick={onSyncScheduleSettingsClick}
      startSyncExecutable={startSyncExecutable}
    />
  );
};
