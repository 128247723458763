import { SimpleField } from "@usemorph/morph-dashboard-types";

export const isAutoGeneratedField = (field: SimpleField): boolean => {
  const autoGeneratedFieldTypes: SimpleField["type"][] = [
    "autoNumber",
    "autoBigNumber",
    "createdAt",
    "createdBy",
    "lastEditedAt",
    "lastEditedBy",
    "formula",
  ];

  return (
    autoGeneratedFieldTypes.includes(field.type) ||
    field.name === "morph_reserved_deleted_at"
  );
};
