import { Flex } from "@radix-ui/themes";
import { BsCheckSquareFill, BsSquare } from "react-icons/bs";
import { IconButton } from "~/components_next/IconButton";
import { useTableSizings } from "../context/useTableSizing";

type SelectBoxBaseProps = {
  isChecked?: boolean;
  onSelect?: () => void;
  tooltip: string;
  height: number;
};

const SelectBoxBase = (props: SelectBoxBaseProps) => {
  const { isChecked, onSelect, tooltip, height } = props;

  const { getHeaderColumnCheckBoxWidth, getHeaderRowHeight } =
    useTableSizings();

  return (
    <Flex
      style={{
        width: getHeaderColumnCheckBoxWidth(),
        height,
      }}
      align="center"
      justify="center"
    >
      <IconButton
        variant="default"
        size="sm"
        onClick={onSelect}
        isDisabled={!onSelect}
        icon={isChecked ? <BsCheckSquareFill /> : <BsSquare />}
        tooltip={tooltip}
      />
    </Flex>
  );
};

export { SelectBoxBase };
