/**
 * WARNING: 一般的なhookではなく、CSVインポートによるSource作成時に使うためのBucket用Hookです。
 * 固有のロジックを含むので、違う箇所で使いまわさないように注意してください。
 */

import { useQueryClient } from "react-query";
import useApiBucket from "~/api/useApiBucket";
import {
  CsvImportBucketClientModel,
  CsvImportBucketClientModelFactory,
} from "~/clientModel/storage/bucket";
import { commonQueryOptions } from "~/serverStateStore/util/commonQueryOptions";
import { bucketKeys } from "../keys";

type UseFindCsvImportBucketQueryProps = {
  teamSlug: string;
};

const useFindCsvImportBucketQuery = ({
  teamSlug,
}: UseFindCsvImportBucketQueryProps) => {
  const { _listBuckets, _createBucket } = useApiBucket();

  const client = useQueryClient();
  client.setQueryDefaults(
    bucketKeys.listBucket({ teamSlug }),
    commonQueryOptions
  );

  const bucketName = CsvImportBucketClientModel.csvImportFileBucketName;

  return {
    queryKey: bucketKeys.listBucket({
      teamSlug,
      bucketName,
    }),
    queryFn: async () => {
      // TODO: 見つからなかったら作成するようにする
      const findResponse = await _listBuckets({
        teamSlug,
        bucketName,
      });

      if (findResponse.count === 0) {
        // 定数管理のために嘘のIDで作ったインスタンスを経由する
        const csvImportFileBucketInstace =
          CsvImportBucketClientModelFactory.getPlaecholderBucket();
        const createResponse = await _createBucket({
          teamSlug,
          bucketName: csvImportFileBucketInstace.bucketName,
          readPermission: csvImportFileBucketInstace.readPermission,
          createPermission: csvImportFileBucketInstace.createPermission,
          updatePermission: csvImportFileBucketInstace.updatePermission,
        });

        return createResponse;
      }

      return findResponse.buckets[0];
    },
  };
};

export { useFindCsvImportBucketQuery };
