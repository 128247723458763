import { SimpleField } from "@usemorph/morph-dashboard-types";
import { ViewSettingFieldObject } from "./setting";

const createFieldsOrderFromSimpleFields = (
  simpleFields: SimpleField[],
  mainTableSlug: string
): ViewSettingFieldObject[] => {
  return simpleFields.map(({ name, originalTableSlug }) => {
    const viewFieldName =
      mainTableSlug === originalTableSlug
        ? name
        : `${originalTableSlug}_${name}`;
    return {
      name: viewFieldName,
      originalTableSlug,
    };
  });
};

const createFieldsOrderFromViewFields = (
  viewFields: SimpleField[]
): ViewSettingFieldObject[] => {
  return viewFields.map(({ name, originalTableSlug }) => ({
    name,
    originalTableSlug,
  }));
};

export { createFieldsOrderFromSimpleFields, createFieldsOrderFromViewFields };
