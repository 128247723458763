import { ImportStatusesClientModel } from "~/clientModel/importStatus";
import { Loadable } from "~/clientModel/loadable";
import { Box } from "~/components_next/Box";
import {
  AccordionContent,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
} from "~/components_next/Accordion";
import { Flex } from "~/components_next/Flex";
import { LuActivity } from "react-icons/lu";
import { ImportStatusItem } from "./ImportStatusItem";
import { ScrollArea } from "@radix-ui/themes";
import { Divider } from "~/components_next/Divider";

type ImportStatusPresenterProps = {
  importStatusLoadable: Loadable<ImportStatusesClientModel>;
};

export const ImportStatusPresenter = (props: ImportStatusPresenterProps) => {
  const { importStatusLoadable } = props;

  if (
    !importStatusLoadable.data ||
    importStatusLoadable.data.visibleItems.length === 0
  ) {
    return null;
  }

  return (
    <>
      <Box width="100%">
        <AccordionRoot type="single" collapsible>
          <AccordionItem value="import">
            <AccordionTrigger size="sm">
              <Flex align="center" gap="2">
                <LuActivity />
                Import logs
              </Flex>
            </AccordionTrigger>

            <AccordionContent>
              <ScrollArea style={{ maxHeight: "40vh" }}>
                <Flex direction="column" gap="1" px="2">
                  {importStatusLoadable.data.visibleItems.map((item, index) => (
                    <>
                      <ImportStatusItem key={index} importStatus={item} />
                      {importStatusLoadable.data.visibleItems.length - 1 !==
                        index && <Divider />}
                    </>
                  ))}
                </Flex>
              </ScrollArea>
            </AccordionContent>
          </AccordionItem>
        </AccordionRoot>
      </Box>
    </>
  );
};
