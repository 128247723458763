import { useQuery } from "react-query";
import { useListBucketsQuery } from "../queries/useListBucketsQuery";

interface UseListBucketsParams {
  teamSlug: string;
  limit?: number;
  skip?: number;
}

const useBuckets = ({ teamSlug, limit, skip }: UseListBucketsParams) => {
  const options = useListBucketsQuery({ teamSlug, limit, skip });
  return useQuery({ ...options });
};

export { useBuckets };
