import {
  mergeValidationResults,
  ValidationResult,
  zValidate,
} from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";
import { z } from "zod";
import { NumberFieldTypeClientModel } from "../number/NumberFieldTypeClientModel";
import { BigNumberFieldTypeClientModel } from "../number/BigNumberFieldTypeClientModel";
import { DecimalFieldTypeClientModel } from "../number/DecimalFieldTypeClientModel";

type SyncedValueType =
  | NumberFieldTypeClientModel
  | BigNumberFieldTypeClientModel
  | DecimalFieldTypeClientModel;

type CalculationFieldTypeClientModelData = {
  syncTargetTableSlug: string | null;
  sql: string | null;
  syncedValueType: SyncedValueType;
};

export class CalculationFieldTypeClientModel extends FieldTypeClientModelBase {
  readonly #data: CalculationFieldTypeClientModelData;

  public get type(): "calculation" {
    return "calculation";
  }

  public constructor(data: CalculationFieldTypeClientModelData) {
    super();
    this.#data = data;
  }

  public get data(): CalculationFieldTypeClientModelData {
    return this.#data;
  }

  public get fieldTypeLabel(): "Calculation" {
    return "Calculation";
  }

  public get isAutoGenerated(): true {
    return true;
  }

  public get isEmbeddable(): false {
    return false;
  }

  public get canBeTargetOfSyncingByComputedField(): false {
    return false;
  }

  public validate(): ValidationResult {
    return mergeValidationResults(
      zValidate(z.string(), this.#data.syncTargetTableSlug),
      zValidate(z.string().nonempty(), this.#data.sql)
    );
  }

  public get _updatableFieldTypes() {
    return [];
  }

  public get syncTargetTableSlug(): string | null {
    return this.#data.syncTargetTableSlug;
  }

  public get sql(): string | null {
    return this.#data.sql;
  }

  public get syncedValueType(): SyncedValueType {
    return this.#data.syncedValueType;
  }

  public get syncTargetTableSlugOrThrow(): string {
    if (!this.#data.syncTargetTableSlug) {
      throw new Error("syncTargetTable dees not exist");
    }
    return this.#data.syncTargetTableSlug;
  }

  public get sqlOrThrow(): string {
    if (!this.#data.sql) {
      throw new Error("sql dees not exist");
    }
    return this.#data.sql;
  }

  public updateSyncTargetTableSlug(
    tableSlug: string
  ): CalculationFieldTypeClientModel {
    return new CalculationFieldTypeClientModel({
      ...this.#data,
      syncTargetTableSlug: tableSlug,
    });
  }

  public updateSql(sql: string): CalculationFieldTypeClientModel {
    return new CalculationFieldTypeClientModel({
      ...this.#data,
      sql,
    });
  }

  public updateSyncedValueType(
    syncedValueType: SyncedValueType
  ): CalculationFieldTypeClientModel {
    return new CalculationFieldTypeClientModel({
      ...this.#data,
      syncedValueType,
    });
  }
}
