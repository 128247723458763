import { FieldClientModel } from "~/clientModel/fields/field";
import {
  ReadonlyRecordEntryClientModelBase,
  ReadonlyRecordEntryClientModelBaseConstructorParams,
} from "../RecordEntryClientModelBase";
import {
  zAllowNullIfNullable,
  zRequiredString,
  zValidate,
} from "../validatorsUtil";
import { z } from "zod";

type GenerateTextRecordEntryClientModelData =
  ReadonlyRecordEntryClientModelBaseConstructorParams;

export class GenerateTextRecordEntryClientModel extends ReadonlyRecordEntryClientModelBase<
  string | null
> {
  constructor(data: GenerateTextRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "generateText" {
    return "generateText";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([zRequiredString(field), zAllowNullIfNullable(field)]),
      this.data.rawValue
    );
  }

  public getValidatedDisplayValue = this.getValidatedValue;
}
