import { DashboardNotebookCellObject } from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";

export type CanvasVariableDataType = NonNullable<
  DashboardNotebookCellObject["source"]["variables"]
>[number];
export type CanvasVariableTypeType = CanvasVariableDataType["type"];

export class CanvasVariableClientModel {
  constructor(private readonly _data: CanvasVariableDataType) {}

  get data() {
    return this._data;
  }

  get name() {
    return this._data.name;
  }

  get type() {
    return this._data.type;
  }

  get defaultValue() {
    return this._data.defaultValue;
  }

  static get newVariableInitialValue() {
    return {
      name: "NewVariable",
      defaultValue: "",
      type: "string",
    } as const;
  }

  updateName(name: string) {
    const newVariableData = { ...this.data };
    newVariableData.name = name;
    return new CanvasVariableClientModel(newVariableData);
  }

  updateType(type: CanvasVariableTypeType) {
    const newVariableData = { ...this.data };
    newVariableData.type = type;

    match(type)
      .with(P.union("string", "date"), () => {
        newVariableData.defaultValue = "";
      })
      .otherwise(() => {
        newVariableData.defaultValue = undefined;
      });

    return new CanvasVariableClientModel(newVariableData);
  }

  updateDefaultValue(defaultValue: unknown) {
    const newVariableData = { ...this.data };

    match(newVariableData.type)
      .with(P.union("number", "singleSelectNumber"), () => {
        newVariableData.defaultValue = Number(defaultValue);
      })
      .otherwise(() => {
        newVariableData.defaultValue = defaultValue;
      });

    return new CanvasVariableClientModel(newVariableData);
  }

  // singleSelectString, multiSelectStringのoptionsの変更
  updateOptionsString(options: string[]) {
    // とりあえずは間違ったtypeがきたら何もしない
    const newVariableData = match(this.data)
      .with(
        P.union({ type: "singleSelectString" }, { type: "multiSelectString" }),
        (stringSelectVariableData) => {
          return {
            ...stringSelectVariableData,
            options: options,
          };
        }
      )
      .otherwise(() => this.data);
    return new CanvasVariableClientModel(newVariableData);
  }

  // singleSelectNumber, multiSelectNumberのoptionsの変更
  updateOptionsNumber(options: number[]) {
    // とりあえずは間違ったtypeがきたら何もしない
    const newVariableData = match(this.data)
      .with(
        P.union({ type: "singleSelectNumber" }, { type: "multiSelectNumber" }),
        (numberSelectVariableData) => {
          return {
            ...numberSelectVariableData,
            options: options,
          };
        }
      )
      .otherwise(() => this.data);
    return new CanvasVariableClientModel(newVariableData);
  }

  // singleSelectSourceFieldOptions, multiSelectSourceFieldOptionsのoptionsの変更
  updateOptionsSourceField({
    tableSlug,
    field,
  }: {
    tableSlug: string;
    field: string;
  }) {
    // とりあえずは間違ったtypeがきたら何もしない
    const newVariableData = match(this.data)
      .with(
        P.union(
          { type: "singleSelectSourceFieldOptions" },
          { type: "multiSelectSourceFieldOptions" }
        ),
        (sourceFieldOptionsSelectVariableData) => {
          return {
            ...sourceFieldOptionsSelectVariableData,
            tableSlug,
            field,
          };
        }
      )
      .otherwise(() => this.data);
    return new CanvasVariableClientModel(newVariableData);
  }

  // singleSelectViewRecords, multiSelectViewRecordsのoptionsの変更
  updateOptionsViewRecords({
    viewId,
    labelField,
    valueField,
  }: {
    viewId: string;
    labelField: string;
    valueField: string;
  }) {
    // とりあえずは間違ったtypeがきたら何もしない
    const newVariableData = match(this.data)
      .with(
        P.union(
          { type: "singleSelectViewRecords" },
          { type: "multiSelectViewRecords" }
        ),
        (viewRecordsSelectVariableData) => {
          return {
            ...viewRecordsSelectVariableData,
            viewId,
            labelField,
            valueField,
            defaultValue: undefined,
          };
        }
      )
      .otherwise(() => this.data);
    return new CanvasVariableClientModel(newVariableData);
  }
}
