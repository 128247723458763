import { ForwardedRef, forwardRef } from "react";
import { BooleanRecordEntryClientModel } from "~/clientModel/records/record/recordEntry";
import { SimpleSelect } from "~/components_next/Select";

import { RecordValueInputCommonProps } from "../../type";

const nonNullableOptions = [
  {
    value: true,
    label: "True",
  },
  {
    value: false,
    label: "False",
  },
] as const;

const nullableOptions = [
  ...nonNullableOptions,
  {
    value: null,
    label: "Null",
  },
] as const;

const BooleanInput = forwardRef(function BooleanInput(
  props: RecordValueInputCommonProps<BooleanRecordEntryClientModel>,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const { editingRecordEntry, onChange, field, size, isReadonly } = props;

  const validatedValue = editingRecordEntry.getValidatedValue(field);

  const value = validatedValue.isValid ? validatedValue.value : null;

  const errorMessages = validatedValue.errorMessages;

  const handleChange = (value: boolean | null) => {
    onChange?.(editingRecordEntry.updateValue(value));
  };

  const options = field.isNullable ? nullableOptions : nonNullableOptions;

  return (
    <SimpleSelect<boolean | null>
      variant="primary"
      size={size}
      options={options}
      getValueStringFromValue={(value: boolean) => String(value)}
      value={value}
      onChange={handleChange}
      errorMessages={errorMessages}
      isReadOnly={isReadonly}
      noPortal
      ref={ref}
    />
  );
});

export { BooleanInput };
