type DatabasesDependencies = { teamSlug: string };

type FindDatabaseDependencies = { databaseId: string } & DatabasesDependencies;

type ListDatabasesDependencies = {
  skip?: number;
  limit?: number;
  orderByCreatedAt?: "ASC" | "DESC";
  orderByLastEditedAt?: "ASC" | "DESC";
  orderByLastEditedAtByUser?: "ASC" | "DESC";
  orderByName?: "ASC" | "DESC";
} & DatabasesDependencies;

const databaseKeys = {
  all: ({ teamSlug }: DatabasesDependencies) =>
    [teamSlug, "databases"] as const,

  allFindDatabase: ({ teamSlug }: DatabasesDependencies) =>
    [...databaseKeys.all({ teamSlug }), "findDatabase"] as const,
  findDatabase: ({ teamSlug, databaseId }: FindDatabaseDependencies) =>
    [...databaseKeys.allFindDatabase({ teamSlug }), databaseId] as const,

  allListDatabases: ({ teamSlug }: DatabasesDependencies) =>
    [...databaseKeys.all({ teamSlug }), "listDatabases"] as const,
  listDatabases: ({
    teamSlug,
    orderByCreatedAt,
    orderByLastEditedAt,
    orderByLastEditedAtByUser,
    orderByName,
    skip,
    limit,
  }: ListDatabasesDependencies) =>
    [
      ...databaseKeys.allListDatabases({ teamSlug }),
      {
        orderByCreatedAt,
        orderByLastEditedAt,
        orderByLastEditedAtByUser,
        orderByName,
        skip,
        limit,
      },
    ] as const,
};

export { databaseKeys };
