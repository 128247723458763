import { useCurrentEditor } from "@tiptap/react";

export const useEditorOrThrow = () => {
  const { editor } = useCurrentEditor();
  if (!editor) {
    throw new Error("Editor  is not found");
  }

  return editor;
};

export const useFocusEditor = () => {
  const editor = useEditorOrThrow();
  const focusEditor = () => editor.chain().focus().run();

  return focusEditor;
};
