import { useCallback, useState } from "react";
import constate from "constate";
import { FlatFilterCondition } from "~/features/Fields/FilterCondition/flatFilterConditionTypes";
import { RecordSortConditionUnit } from "@usemorph/morph-dashboard-types";

type QueryMode = "filtersAndSorts" | "sql" | "prompt" | null;

const useQueryConditionsContext = ({
  initialFilter,
  initialSort,
}: {
  initialFilter: FlatFilterCondition | null;
  initialSort: RecordSortConditionUnit | null;
}) => {
  const [filter, setFilter] = useState<FlatFilterCondition | null>(
    initialFilter
  );
  const [sort, setSort] = useState<RecordSortConditionUnit | null>(initialSort);

  const setEmptyFilter = useCallback((key: string) => {
    setFilter({ and: [{ key, value: null, operator: "equal" }] });
  }, []);

  const setEmptySort = useCallback((key: string) => {
    setSort({ key, direction: "ascending" });
  }, []);

  return {
    filter,
    setFilter,
    setEmptyFilter,
    sort,
    setSort,
    setEmptySort,
  };
};

export const [
  QueryConditionsProvider,
  useFilter,
  useSetFilter,
  useSetEmptyFilter,
  useSort,
  useSetSort,
  useSetEmptySort,
] = constate(
  useQueryConditionsContext,
  (value) => value.filter,
  (value) => value.setFilter,
  (value) => value.setEmptyFilter,
  (value) => value.sort,
  (value) => value.setSort,
  (value) => value.setEmptySort
);

export type { QueryMode };
