import {
  DashboardTableHistoryObject,
  DashboardTableResponse,
} from "@usemorph/morph-dashboard-types";
import dayjs from "dayjs";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { match, P } from "ts-pattern";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
import { Text } from "~/components_next/Text";
import { Tooltip } from "~/components_next/Tooltip";
import { UserMention } from "~/components_next/User";
import { SourceBadge } from "~/features/Table/components/SourceBadge";
import { getTableHistoryOperationLabel } from "~/features/TableHistory";
import { getPath } from "~/routing";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

type RecentUpdatesTableHistoryCellProps = {
  history: DashboardTableHistoryObject;
  tables: DashboardTableResponse[];
};

const RecentUpdatesTableHistoryCell = (
  props: RecentUpdatesTableHistoryCellProps
) => {
  const { history, tables } = props;

  const targetTable = tables.find(
    (table) => table.tableSlug === history.tableSlug
  );

  return (
    <CellWrapper history={history}>
      <Flex direction="column" align="stretch">
        {history.createdBy && (
          <UserMention
            // size="xs"
            user={history.createdBy}
          />
        )}

        {targetTable && (
          <Flex align="center" mt="2">
            <Text fontWeight="bold">{targetTable.displayName}</Text>
            <Box ml="3">
              <SourceBadge />
            </Box>
          </Flex>
        )}

        <Box mt="1">
          <Text fontWeight="medium">
            {getTableHistoryOperationLabel({
              key: history.operation,
              username: history.createdBy?.username ?? "Guest User",
              isAdmin: history.createdBy?.isAdmin ?? false,
              isBulk: history.data?.isBulk === true ? true : false,
            })}
          </Text>
        </Box>

        <Box mt="2">
          <Tooltip content={history.operatedAt}>
            <Text variant="description">
              {dayjs(history.operatedAt).fromNow()}
            </Text>
          </Tooltip>
        </Box>
      </Flex>
    </CellWrapper>
  );
};

const CellWrapper = ({
  history,
  children,
}: {
  history: DashboardTableHistoryObject;
  children: ReactNode;
}) => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  // const { border } = useMorphColors();

  return match(history.operation)
    .with(
      P.union(
        "fieldCreate",
        "fieldDelete",
        "fieldUpdate",
        "recordBulkCreate",
        "recordBulkCreateAuto",
        "recordBulkCreateIntegration",
        "recordCreate",
        "recordDelete",
        "recordUpdate",
        "tableCreate",
        "tableIndexCreate",
        "tableIndexDelete",
        "tableUpdate",
        "tableCreate",
        "tableIndexCreate",
        "tableIndexDelete",
        "tableUpdate"
      ),
      () => {
        return (
          <Link
            to={getPath("source", {
              teamSlug,
              databaseId,
              tableSlug: history.tableSlug,
            })}
          >
            <Flex
              css={{
                borderWidth: "1px",
              }}
              px="3"
              py="5"
            >
              {children}
            </Flex>
          </Link>
        );
      }
    )
    .with(
      P.union("viewCreate", "viewUpdate", "tableDelete", "viewDelete"),
      () => (
        <Box
          css={{
            borderWidth: "1px",
          }}
          // borderColor={border}
          // rounded="md"
          px="3"
          py="5"
        >
          {children}
        </Box>
      )
    )
    .exhaustive();
};

export { RecentUpdatesTableHistoryCell };
