import { DashboardTableObject } from "@usemorph/morph-dashboard-types";
import { TableClientModel } from "./TableClientModel";

export class TableClientModelFactory {
  public static createFromDashboardTableObject(
    tableObject: DashboardTableObject
  ): TableClientModel {
    return new TableClientModel({
      tableSlug: tableObject.tableSlug,
      displayName: tableObject.displayName,
    });
  }
}
