import { ForwardedRef, forwardRef, ReactNode } from "react";
import { Command as CommandPrimitive } from "cmdk";
import classNames from "classnames";
import { withBreakpoints } from "@radix-ui/themes";
import { useMultiSelectSize } from "./MultiSelectContext";
import { convertSize } from "./util";

type MultiSelectItemGroupProps = {
  heading: string;
  children: ReactNode;
};

const MultiSelectItemGroup = forwardRef(
  (props: MultiSelectItemGroupProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, heading } = props;

    const size = useMultiSelectSize();

    return (
      <CommandPrimitive.Group
        ref={ref}
        className={classNames(
          "rt-reset",
          "morph-MultiSelectItemGroup",
          withBreakpoints(convertSize(size), "morph-r-size")
        )}
        heading={heading}
      >
        {children}
      </CommandPrimitive.Group>
    );
  }
);

MultiSelectItemGroup.displayName = "MultiSelectItemGroup";

export { MultiSelectItemGroup, type MultiSelectItemGroupProps };
