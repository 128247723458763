import { SimpleField } from "@usemorph/morph-dashboard-types";
import { CellSelectionState, RecordsTableBaseRecord } from "../types";

export const getFieldsRange = (fieldNames: string[], fields: SimpleField[]) => {
  const fieldIndexes = fieldNames.map((fieldName) =>
    fields.findIndex((field) => field.name === fieldName)
  );
  const start = Math.min(...fieldIndexes);
  const end = Math.max(...fieldIndexes);
  return fields.slice(start, end + 1);
};

export const getRecordsRange = (
  allRecords: RecordsTableBaseRecord[],
  rangeRecords: RecordsTableBaseRecord[]
) => {
  const rangeRecordsIndexes = rangeRecords.map(
    (rangeRecord) => rangeRecord._reservedRecordIndex
  );
  const start = Math.min(...rangeRecordsIndexes);
  const end = Math.max(...rangeRecordsIndexes);
  return allRecords.slice(start, end + 1);
};

export const splitSelectionsIntoSeries = (selections: CellSelectionState) => {
  const rowsInARow: Array<CellSelectionState> = [];
  const sorted = [...selections].sort(
    (s1, s2) => s1.record._reservedRecordIndex - s2.record._reservedRecordIndex
  );
  sorted.forEach((selection) => {
    if (rowsInARow.length === 0) {
      rowsInARow.push([selection]);
      return;
    }
    const lastRows = rowsInARow[rowsInARow.length - 1];
    const lastSelection = lastRows[lastRows.length - 1];
    if (
      lastSelection.record._reservedRecordIndex ===
      selection.record._reservedRecordIndex - 1
    ) {
      lastRows.push(selection);
      rowsInARow[rowsInARow.length - 1] = lastRows;
    } else {
      rowsInARow.push([selection]);
    }
  });

  return rowsInARow;
};

export const splitFieldsIntoSeries = (
  fieldSlugsWithIndex: { slug: string; index: number }[]
): Array<Array<string>> => {
  const fieldSlugsInARow: Array<Array<{ slug: string; index: number }>> = [];
  fieldSlugsWithIndex.forEach((fieldSlugWithIndex) => {
    if (fieldSlugsInARow.length === 0) {
      fieldSlugsInARow.push([fieldSlugWithIndex]);
      return;
    }

    const lastFieldSlugs = fieldSlugsInARow[fieldSlugsInARow.length - 1];
    const lastFieldSlug = lastFieldSlugs[lastFieldSlugs.length - 1];
    if (lastFieldSlug.index === fieldSlugWithIndex.index - 1) {
      lastFieldSlugs.push(fieldSlugWithIndex);
      fieldSlugsInARow[fieldSlugsInARow.length - 1] = lastFieldSlugs;
    } else {
      fieldSlugsInARow.push([fieldSlugWithIndex]);
    }
  });

  return fieldSlugsInARow.map((fieldSlugs) =>
    fieldSlugs.map((fieldSlug) => fieldSlug.slug)
  );
};

export const getUpperRecord = (
  allRecords: RecordsTableBaseRecord[],
  record: RecordsTableBaseRecord
) => {
  const recordIndex = record._reservedRecordIndex;

  if (recordIndex === 0) return undefined;

  const upperRecord = allRecords[recordIndex - 1];
  return upperRecord;
};

export const getDownerRecord = (
  allRecords: RecordsTableBaseRecord[],
  record: RecordsTableBaseRecord
) => {
  const recordIndex = record._reservedRecordIndex;

  if (recordIndex === allRecords.length - 1) return undefined;

  const downerRecord = allRecords[recordIndex + 1];
  return downerRecord;
};

export const getLeftField = (fields: SimpleField[], fieldSlug: string) => {
  const fieldIndex = fields.findIndex((field) => field.name === fieldSlug);

  if (fieldIndex === 0) return undefined;

  const leftField = fields[fieldIndex - 1];
  return leftField;
};

export const getRightField = (fields: SimpleField[], fieldSlug: string) => {
  const fieldIndex = fields.findIndex((field) => field.name === fieldSlug);

  if (fieldIndex === fields.length - 1) return undefined;

  const rightField = fields[fieldIndex + 1];
  return rightField;
};
