import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModel } from "..";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";

export class UrlFieldTypeClientModel extends FieldTypeClientModelBase {
  public get type(): "url" {
    return "url";
  }

  public get fieldTypeLabel(): "URL" {
    return "URL";
  }

  public get isAutoGenerated(): false {
    return false;
  }

  public get isEmbeddable(): true {
    return true;
  }

  public get canBeTargetOfSyncingByComputedField(): true {
    return true;
  }

  public get _updatableFieldTypes(): FieldTypeClientModel["type"][] {
    return ["number"];
  }

  public validate(): ValidationResult {
    return { isValid: true };
  }
}
