import { useState } from "react";
import { FilterConditionsClientModelFactory } from "~/clientModel/queryConditions/filterConditions";
import { PaginationClientModelFactory } from "~/clientModel/queryConditions/pagination/PaginationClientModelFactory";
import { SortConditionsClientModelFactory } from "~/clientModel/queryConditions/sortConditions";
import { SourceQueryConditions } from "~/presenters/sourceAndViews";

export const useSourceQueryConditionsState = () => {
  return useState<SourceQueryConditions>({
    filterConditions:
      FilterConditionsClientModelFactory.createEmptyFilterConditions(),
    sortConditions:
      SortConditionsClientModelFactory.createEmptySortConditions(),
    embeddingSortCondition: null,
    prompt: "",
    sql: "",
    queryingSql: "",
    pagination: PaginationClientModelFactory.createInitialPagination({
      limit: 30,
    }),
  });
};
