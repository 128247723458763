import { FieldClientModel } from "~/clientModel/fields/field";
import {
  ReadonlyRecordEntryClientModelBase,
  ReadonlyRecordEntryClientModelBaseConstructorParams,
} from "../RecordEntryClientModelBase";
import { zValidate } from "../validatorsUtil";
import { z } from "zod";

export type FormulaRecordEntryValueType =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | boolean[]
  | null;

type FormulaRecordEntryClientModelData =
  ReadonlyRecordEntryClientModelBaseConstructorParams;

export class FormulaRecordEntryClientModel extends ReadonlyRecordEntryClientModelBase<FormulaRecordEntryValueType> {
  constructor(data: FormulaRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "formula" {
    return "formula";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([
        z.string(field),
        z.number(),
        z.boolean(),
        z.array(z.string()),
        z.array(z.number()),
        z.array(z.boolean()),
        z.null(),
      ]),
      this.data.rawValue
    );
  }

  public getValidatedDisplayValue = this.getValidatedValue;
}
