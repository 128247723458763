import { useRouteGuard } from "~/App";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useMutation, useQuery } from "react-query";
import {
  useCreateDatabaseMutation,
  useJoinTeamMutation,
  useListDatabasesQuery,
} from "~/serverStateStore";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMe } from "~/serverStateStore/me";
import { Button } from "~/components_next/Button";
import { useErrorToast } from "~/components_next/Error";
import { getPath } from "~/routing";
import { Flex } from "~/components_next/Flex";

const NextHomePage = () => {
  useRouteGuard();

  const teamSlug = useTeamSlug();

  const navigate = useNavigate();

  const {
    data: listDatabase,
    refetch: refetchDatabases,
    isLoading: isFetchingDb,
  } = useQuery({
    ...useListDatabasesQuery({
      teamSlug,
      skip: 0,
      limit: 99999,
    }),
  });

  const { data: me } = useMe();

  const { mutateAsync: joinToTeam, isLoading: isJoining } = useMutation({
    ...useJoinTeamMutation({
      teamSlug,
    }),
  });

  const thisTeamMetaFromMe = useMemo(() => {
    return me?.teams.find((t) => t.teamSlug === teamSlug);
  }, [me, teamSlug]);

  const needToJoinTeam = useMemo(() => {
    return thisTeamMetaFromMe && thisTeamMetaFromMe.inviteStatus === "invited";
  }, [thisTeamMetaFromMe]);

  useEffect(() => {
    if (
      thisTeamMetaFromMe &&
      thisTeamMetaFromMe.inviteStatus === "joined" &&
      listDatabase &&
      listDatabase?.items.length > 0
    ) {
      navigate(
        getPath("databaseHome", {
          teamSlug,
          databaseId: listDatabase.items[0].databaseId,
        })
      );
    }
  }, [listDatabase, navigate, teamSlug, thisTeamMetaFromMe]);

  const handleJoinTeam = async () => {
    await joinToTeam();
    location.reload();
  };

  /**
   * Create Database
   */

  const isEmptyDbSomehow = useMemo(() => {
    if (
      thisTeamMetaFromMe &&
      thisTeamMetaFromMe.inviteStatus === "joined" &&
      listDatabase &&
      listDatabase.items.length === 0
    ) {
      return true;
    }
    return false;
  }, [thisTeamMetaFromMe, listDatabase]);

  const { mutateAsync: createDatabase, isLoading: isCreatingDatabase } =
    useMutation({
      ...useCreateDatabaseMutation({
        teamSlug,
      }),
    });

  const { errorToast } = useErrorToast({});

  const handleCreateDatabase = async () => {
    try {
      await createDatabase({
        databaseName: "My First Database",
        protocol: "postgresql",
        host: "",
        port: 5432,
        database: "",
        username: "",
        password: "",
        isBuiltin: true,
      });

      location.reload();
    } catch (e) {
      errorToast(e);
    }
  };

  return (
    <>
      {needToJoinTeam && (
        <>
          <Flex justify="center" align="center" mt="9">
            You need to join the team to see the contents.
          </Flex>
          <Flex justify="center" align="center" mt="5">
            <Button
              variant="primary"
              onClick={handleJoinTeam}
              isLoading={isJoining}
            >
              Join This Team
            </Button>
          </Flex>
        </>
      )}
      {isEmptyDbSomehow && (
        <>
          <Flex justify="center" align="center" mt="9">
            Create database and start using it!
          </Flex>
          <Flex justify="center" align="center" mt="5">
            <Button
              variant="primary"
              onClick={handleCreateDatabase}
              isLoading={isCreatingDatabase}
            >
              Create Database
            </Button>
          </Flex>
        </>
      )}
    </>
  );
};

const NextHomePageWithKey = () => {
  const teamSlug = useTeamSlug();
  return <NextHomePage key={teamSlug} />;
};

export { NextHomePageWithKey as NextHomePage };
