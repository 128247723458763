import { FieldClientModel } from "~/clientModel/fields/field";
import {
  UpdatableRecordEntryClientModelBaseConstructorParams,
  UpdatableRecordEntryClientModelBase,
} from "../RecordEntryClientModelBase";
import {
  zValidate,
  zAllowNullIfNullable,
  zNumericString,
} from "../validatorsUtil";
import { z } from "zod";
import { NumberDisplaySettingClientModel } from "~/clientModel/fields/field/displaySetting/numberDisplaySetting";

type DecimalRecordEntryClientModelData =
  UpdatableRecordEntryClientModelBaseConstructorParams;

export class DecimalRecordEntryClientModel extends UpdatableRecordEntryClientModelBase<
  string | null
> {
  constructor(data: DecimalRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "decimal" {
    return "decimal";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([zNumericString, zAllowNullIfNullable(field)]),
      this.data.rawValue
    );
  }

  public getValidatedDisplayValue(field: FieldClientModel) {
    const validatedValue = this.getValidatedValue(field);

    return validatedValue.isValid &&
      field.displaySetting instanceof NumberDisplaySettingClientModel
      ? ({
          isValid: true,
          value: field.displaySetting.getDisplayValue(validatedValue.value),
        } as const)
      : validatedValue;
  }

  public updateValue(value: string | null): DecimalRecordEntryClientModel {
    return new DecimalRecordEntryClientModel({
      key: this.data.key,
      rawValue: value,
      meta: this.data.meta,
    });
  }
}
