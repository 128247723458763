import { DashboardNormalizedTableStatusObject } from "@usemorph/morph-dashboard-types";
import { SyncStatusClientModel } from "./SyncStatusClientModel";

export class SyncStatusClientModelFactory {
  public static createFromDashboardNormalizedTableStatusObject(
    normalizedTableStatus: DashboardNormalizedTableStatusObject
  ): SyncStatusClientModel {
    return new SyncStatusClientModel({
      status: normalizedTableStatus.status,
    });
  }
}
