import { BsLink45Deg } from "react-icons/bs";
import {
  CanvasCellClientModelUnion,
  CanvasCellPromptClientModel,
} from "~/clientModel/canvas";
import { Executable } from "~/clientModel/executable";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import {
  ControlledDropdown,
  DropdownMenu,
} from "~/components_next/DropdownMenu";
import { useErrorToast } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { IconButton } from "~/components_next/IconButton";
import { Input } from "~/components_next/Input";
import { Spinner } from "~/components_next/Spinner";
import { Switch } from "~/components_next/Switch";
import { Text } from "~/components_next/Text";
import { useDisclosure } from "~/hooks/useDisclosure";
import { getPath } from "~/routing";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { usePlaygroundOnUpdateLiveCell } from "../../providers/PlaygroundUpdateLiveCellProvider";
import { PlaygroundCellActionButton } from "../common/PlaygroundCellActionButton";
import { TextArea } from "~/components_next/TextArea";
import { useMemo } from "react";

type PlaygroundCellPromptPublishProps = {
  cellClientModel: CanvasCellPromptClientModel;
  // focusが外れた後も状態を引き継ぐために、executable化したものを受け取る
  updateCellExecutable: Executable<
    CanvasCellClientModelUnion,
    CanvasCellClientModelUnion,
    unknown
  >;
};

const PlaygroundCellPromptPublish = (
  props: PlaygroundCellPromptPublishProps
) => {
  const { cellClientModel, updateCellExecutable } = props;

  const { errorToast } = useErrorToast({});

  const updateLiveCell = usePlaygroundOnUpdateLiveCell();

  const handleIsPublicChange = async (isPublic: boolean) => {
    const instance = cellClientModel.updateIsPublic(isPublic);
    try {
      const response = await updateCellExecutable.execute(instance);
      updateLiveCell(response);
    } catch (e) {
      errorToast(e);
    }
  };

  const teamSlug = useTeamSlug(); // 後で考える...
  const publishedUrl = useMemo(() => {
    return cellClientModel.publicApiKey
      ? `${location.origin}${getPath("publishedCell", {
          teamSlug,
          publicApiKey: cellClientModel.publicApiKey,
        })}`
      : "";
  }, [cellClientModel.publicApiKey, teamSlug]);

  const iframeCode = useMemo(() => {
    return `<iframe src="${publishedUrl}" width="100%" height="100%" frameborder="0"></iframe>`;
  }, [publishedUrl]);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(publishedUrl);
  };

  const { isOpen, setIsOpen } = useDisclosure();

  return (
    <PlaygroundCellActionButton>
      <ControlledDropdown
        trigger={
          <IconButton
            size="lg"
            icon={<BsLink45Deg />}
            tooltip="Share cell with URL."
          />
        }
        isOpen={isOpen || updateCellExecutable.isExecuting}
        onOpenChange={setIsOpen}
      >
        <DropdownMenu.Label>Share cell with URL</DropdownMenu.Label>
        <Box p="2" css={{ width: "280px" }}>
          <Flex gap="4">
            <Flex gap="2" css={{ flex: 1 }}>
              <Text>Make this cell public</Text>
              {updateCellExecutable.isExecuting && <Spinner />}
            </Flex>
            <Switch
              size="lg"
              isChecked={cellClientModel.isPublic}
              isDisabled={updateCellExecutable.isExecuting}
              onCheckedChange={handleIsPublicChange}
            />
          </Flex>
        </Box>
        <Flex direction="column" gap="2">
          {cellClientModel.isPublic && (
            <>
              <Input
                label="URL"
                variant="primary"
                readOnly
                value={publishedUrl}
                rightSlot={
                  <Button
                    size="xs"
                    variant="tertiary"
                    onClick={handleCopyToClipboard}
                  >
                    Copy
                  </Button>
                }
              ></Input>
              <TextArea
                size="xs"
                label="Embed code"
                variant="primary"
                readOnly
                value={iframeCode}
              />
            </>
          )}
        </Flex>
      </ControlledDropdown>
    </PlaygroundCellActionButton>
  );
};

export { PlaygroundCellPromptPublish };
