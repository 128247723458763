import { RecordValueInputCommonProps } from "../../type";
import { DateTimeInputBase } from "./common/DateTimeInputBase";
import { forwardRef, ForwardedRef } from "react";

export const DateTimeInput = forwardRef(function DateTimeInput(
  props: RecordValueInputCommonProps<"datetime">,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { value, onChange, errorMessages, isNullable, isReadOnly, size } =
    props;
  return (
    <DateTimeInputBase
      value={value}
      onChange={onChange}
      errorMessages={errorMessages}
      isNullable={isNullable}
      isReadOnly={isReadOnly}
      size={size}
      ref={ref}
    />
  );
});
