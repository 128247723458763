import { match } from "ts-pattern";
import { AccessAuthority } from "./types";

export const convertAuthorityLabelToPermission = (
  authority: AccessAuthority
): "readwrite" | "readonly" | undefined => {
  return match(authority)
    .with("Read & Write", () => "readwrite")
    .with("Read Only", () => "readonly")
    .otherwise(() => undefined) as "readwrite" | "readonly" | undefined;
};

export const convertPermissionToAuthorityLabel = (
  permission: "readwrite" | "readonly" | undefined
): AccessAuthority => {
  return match(permission)
    .with("readwrite", () => "Read & Write")
    .with("readonly", () => "Read Only")
    .otherwise(() => "No Access") as AccessAuthority;
};
