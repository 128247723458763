import { Separator as RadixSeparator } from "@radix-ui/themes";
import { ComponentPropsWithoutRef } from "react";

type RadixSeparatorProps = ComponentPropsWithoutRef<typeof RadixSeparator>;

type DividerProps = {
  orientation?: RadixSeparatorProps["orientation"];
};

const Divider = (props: DividerProps) => {
  const { orientation = "horizontal" } = props;

  return <RadixSeparator orientation={orientation} color="gray" size="4" />;
};

export { Divider, type DividerProps };
