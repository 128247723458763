import {
  DashboardCellClientModel,
  DashboardCellDataTypeLaoutProperties,
} from "./DashboardCellClientModel";

type Layout = DashboardCellDataTypeLaoutProperties & { i: string };

const filterLayoutDiffs = (
  currentLayout: Layout[],
  updatedLayout: Layout[]
): Layout[] => {
  return updatedLayout.filter((layout) => {
    const currentLayoutItem = currentLayout.find((item) => item.i === layout.i);
    if (!currentLayoutItem) return true;
    return (
      currentLayoutItem.x !== layout.x ||
      currentLayoutItem.y !== layout.y ||
      currentLayoutItem.w !== layout.w ||
      currentLayoutItem.h !== layout.h
    );
  });
};

export class DashboardCellsClientModel {
  constructor(private readonly _data: DashboardCellClientModel[]) {}

  get cells() {
    return this._data;
  }

  // react-grid-layout用の処理ではあるけど、そもそもエンティティ自体がそう設計されてるからあくまで汎用インターフェースの顔をしておく
  get layouts() {
    return this._data.map((cell) => ({
      i: cell.cellId,
      x: cell.x,
      y: cell.y,
      w: cell.w,
      h: cell.h,
    }));
  }

  /**
   * react-grid-layoutから上がってくる最新のLayoutと、今保持しているインスタンスのLayoutを比較して
   * 差分を変更した上で新しいインスタンスを返す
   */
  updateLayouts(layouts: Array<Layout>) {
    const currentLayouts = this.layouts;
    const diffedLayouts = filterLayoutDiffs(currentLayouts, layouts);
    const updatedCells = this.cells.map((cell) => {
      const updatedLayout = diffedLayouts.find(
        (layout) => layout.i === cell.cellId
      );
      if (!updatedLayout) return cell;
      return cell.updateLayout(updatedLayout);
    });
    return new DashboardCellsClientModel(updatedCells);
  }

  /**
   * react-grid-layoutから上がってくる最新のLayoutと、今保持しているインスタンスのLayoutを比較して
   * 差分がある部分のみを新しいインスタンスとして返却する
   */
  getLayoutsDiff(layouts: Array<Layout>) {
    const currentLayouts = this.layouts;
    const diffedLayouts = filterLayoutDiffs(currentLayouts, layouts);
    const updatedCells = this.cells.reduce((acc, cell) => {
      const updatedLayout = diffedLayouts.find(
        (layout) => layout.i === cell.cellId
      );
      if (!updatedLayout) return acc;
      return [...acc, cell.updateLayout(updatedLayout)];
    }, [] as DashboardCellClientModel[]);
    return new DashboardCellsClientModel(updatedCells);
  }
}
