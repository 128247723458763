import { useMemo } from "react";
import { useQuery } from "react-query";
import {
  FieldsClientModel,
  FieldsClientModelFactory,
} from "~/clientModel/fields";
import { useComposeLoadable, Loadable } from "~/clientModel/loadable";
import {
  EmbeddingSortConditionUnitClientModel,
  EmbeddingSortConditionUnitClientModelDefactory,
} from "~/clientModel/queryConditions/embeddingSortConditionUnit";
import {
  FilterConditionsClientModel,
  FilterConditionsClientModelDefactory,
} from "~/clientModel/queryConditions/filterConditions";
import {
  PaginationClientModel,
  PaginationClientModelDefactory,
} from "~/clientModel/queryConditions/pagination";
import {
  SortConditionsClientModel,
  SortConditionsClientModelDefactory,
} from "~/clientModel/queryConditions/sortConditions";
import {
  RecordsClientModel,
  RecordsClientModelFactory,
} from "~/clientModel/records";
import { TablePaginationLimit } from "~/presenters/sourceAndViews/common/components/bottomItems/TablePagination";
import {
  useListRecordsWithQueryQuery,
  useListSimpleFieldsQuery,
} from "~/serverStateStore";

type UseSourceRecordsAndFieldsWithQueryLoadableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  filterConditions: FilterConditionsClientModel;
  sortConditions: SortConditionsClientModel;
  embeddingSortCondition: EmbeddingSortConditionUnitClientModel | null;
  pagination: PaginationClientModel<TablePaginationLimit>;
};

export const useSourceRecordsAndFieldsWithQueryLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
  filterConditions,
  sortConditions,
  embeddingSortCondition,
  pagination,
}: UseSourceRecordsAndFieldsWithQueryLoadableParams): {
  recordsLoadable: Loadable<RecordsClientModel>;
  fieldsLoadable: Loadable<FieldsClientModel>;
} => {
  const recordsLoadable = useComposeLoadable(
    useQuery({
      ...useListRecordsWithQueryQuery(
        useMemo(
          () => ({
            teamSlug,
            databaseId,
            tableSlug,
            select: ["*"],
            filter:
              FilterConditionsClientModelDefactory.toRecordFilterCondition(
                filterConditions
              ),
            sort: [
              // embedding sort condition should be placed at the first
              ...(embeddingSortCondition
                ? [
                    EmbeddingSortConditionUnitClientModelDefactory.toRecordEmbeddingSortConditionUnit(
                      embeddingSortCondition
                    ),
                  ]
                : []),
              ...SortConditionsClientModelDefactory.toRecordSortConditionUnits(
                sortConditions
              ),
            ],
            limit: PaginationClientModelDefactory.toLimit(pagination),
            skip: PaginationClientModelDefactory.toSkip(pagination),
          }),
          [
            teamSlug,
            databaseId,
            tableSlug,
            filterConditions,
            sortConditions,
            embeddingSortCondition,
            pagination,
          ]
        )
      ),
      select: (response) =>
        RecordsClientModelFactory.createFromQueryRecordListResponseWithFields(
          response
        ),
    })
  );

  const fieldsLoadable = useComposeLoadable(
    useQuery({
      ...useListSimpleFieldsQuery({
        teamSlug,
        databaseId,
        tableSlug,
      }),
      select: (response) =>
        FieldsClientModelFactory.createFromSimpleFields(response.fields),
    })
  );

  return {
    recordsLoadable,
    fieldsLoadable,
  };
};
