import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { viewKeys } from "../keys";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import useApiView from "~/api/useApiView";

type UseReorderViewRecordMutationParams = {
  databaseId: string;
  teamSlug: string;
  viewId: string;
};

const useReorderViewRecordMutation = ({
  databaseId,
  teamSlug,
  viewId,
}: UseReorderViewRecordMutationParams) => {
  const { _reorderViewRecord } = useApiView();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      type,
      target,
      after,
    }: {
      type: "table" | "kanban";
      target: Record<string, unknown>;
      after: Record<string, unknown> | null;
    }) => {
      return _reorderViewRecord({
        teamSlug,
        viewId,
        body: {
          type,
          target,
          after,
        },
      });
    },
    onSuccess: () => {
      const keys = viewKeys.all({ databaseId, teamSlug });
      broadcast(keys);
      return invalidateAll(client, viewKeys.all({ databaseId, teamSlug }));
    },
  };
};

export { useReorderViewRecordMutation };
