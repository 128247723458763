import {
  NodeProps,
  NodeToolbar,
  Position,
  ReactFlowState,
  useStore,
} from "reactflow";
import { CanvasCellRichTextClientModel } from "~/clientModel/canvas/CanvasCellClientModel";
import { HTMLRichTextEditor } from "~/components_next/RichTextEditor";
import { usePlaygroundOnUpdateLiveCell } from "../../providers/PlaygroundUpdateLiveCellProvider";
import { PlaygroundCellAddToPageButton } from "../common/PlaygroundCellAddToPageButton";
import { PlaygroundCellCard } from "../common/PlaygroundCellCard";
import { memo, useCallback } from "react";

type PlaygroundCellRichTextProps = {
  cellClientModel: CanvasCellRichTextClientModel;
};

const PlaygroundCellRichText = memo(
  (props: NodeProps<PlaygroundCellRichTextProps>) => {
    const { data, selected, dragging, id, xPos, yPos } = props;
    const { cellClientModel } = data;
    const { cellId } = cellClientModel;

    /**
     * ReactFlow
     */
    const sizeSelector = useCallback(
      (s: ReactFlowState) => {
        const node = s.nodeInternals.get(id);

        return {
          width: node?.width || undefined,
          height: node?.height || undefined,
        };
      },
      [id]
    );
    const size = useStore(
      useCallback(sizeSelector, [sizeSelector]),
      (prev, next) => prev.width === next.width && prev.height === next.height
    );

    /**
     * Event Handlers
     */
    const updateLiveCell = usePlaygroundOnUpdateLiveCell();
    const handleOnHtmlChange = (html: string, height: number) => {
      const rounded = Math.ceil(height / 10) * 10;
      updateLiveCell(
        cellClientModel.updateRichText(html).updateSize({
          width: size.width || 0,
          height: rounded,
        })
      );
    };

    return (
      <>
        <PlaygroundCellCard
          cellClientModel={cellClientModel}
          isNodeDragging={dragging}
          isNodeSelected={selected}
          variant="skelton"
          position={{ x: xPos, y: yPos }}
        >
          <HTMLRichTextEditor
            html={cellClientModel.html || ""}
            height={`${size.height || 0}px`}
            onChange={handleOnHtmlChange}
            hideTopbar
            autoFocus={false}
          />
          <NodeToolbar position={Position.Left} isVisible={selected}>
            <PlaygroundCellAddToPageButton cellClientModel={cellClientModel} />
          </NodeToolbar>
        </PlaygroundCellCard>
      </>
    );
  }
);
PlaygroundCellRichText.displayName = "PlaygroundCellRichText";

export { PlaygroundCellRichText, type PlaygroundCellRichTextProps };
