import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil";
import {
  dynamicStorageEffect,
  dynamicStorageItem,
} from "~/clientStateStore/typedLocalStorage";
import { TypedStorage } from "~/clientStateStore/typedLocalStorage/storage";
import { RecordsTableColumnSizingState } from "~/components_next/RecordsTableBase/types";

const storage = new TypedStorage(localStorage);

const key = "table-view-cell-component-column-sizing";
const version = 1;

const deserializer = (value: string): RecordsTableColumnSizingState => {
  return JSON.parse(value);
};
const serializer = (value: RecordsTableColumnSizingState | null) => {
  return JSON.stringify(value);
};

const columnStorageItem = dynamicStorageItem<
  RecordsTableColumnSizingState | null,
  { viewId: string }
>({
  key,
  version,
  serializer,
  deserializer,
});

const columnSizingFamily = atomFamily<
  RecordsTableColumnSizingState | null,
  { viewId: string }
>({
  key: "table-view-column-sizing",
  default: null,
  effects: ({ viewId }) => [
    dynamicStorageEffect(storage, columnStorageItem, { viewId }, {}),
  ],
});

const useColumnSizing = (viewId: string) => {
  return useRecoilValue(columnSizingFamily({ viewId }));
};

const useSetColumnSizing = (viewId: string) => {
  return useSetRecoilState(columnSizingFamily({ viewId }));
};

export { useColumnSizing, useSetColumnSizing };
