import { TextArea as RadixThemeTextarea } from "@radix-ui/themes";
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from "react";
import { match, P } from "ts-pattern";
import { MorphThemeSize } from "../commonProps.type";
import {
  getInputStackProps,
  InputStack,
  InputStackProps,
} from "../InputStack/InputStack";

type RadixTextareaProps = ComponentPropsWithoutRef<typeof RadixThemeTextarea>;

type TextAreaProps = InputStackProps & {
  size?: MorphThemeSize;
  variant: "primary" | "secondary";
} & Omit<RadixTextareaProps, "size" | "variant">;

const getSize = (size?: MorphThemeSize) => {
  switch (size) {
    case "xs":
      return "1" as RadixTextareaProps["size"];
    case "sm":
      return "2" as RadixTextareaProps["size"];
    case "md":
      return "3" as RadixTextareaProps["size"];
    case "lg":
      return "4" as RadixTextareaProps["size"];
    case "xl":
      return "4" as RadixTextareaProps["size"];
    default:
      return "3" as RadixTextareaProps["size"];
  }
};

const getIsInvalid = ({
  errorMessage,
}: Pick<TextAreaProps, "errorMessage">): boolean => {
  return match(errorMessage)
    .with(P.string, (errorMessage) => errorMessage.length > 0)
    .with(P.array(P.string), (errorMessage) => errorMessage.length > 0)
    .with(P.nullish, () => false)
    .exhaustive();
};

const getVariant = ({
  variant,
  isInvalid,
}: Pick<TextAreaProps, "variant"> & {
  isInvalid: boolean;
}): RadixTextareaProps["variant"] => {
  return match([variant, isInvalid])
    .with(["primary", false], () => "surface" as const)
    .with(["secondary", false], () => "soft" as const)
    .with([P._, true], () => "soft" as const)
    .exhaustive();
};

const getColor = (isInvalid: boolean): RadixTextareaProps["color"] => {
  return isInvalid ? "red" : "gray";
};

const _TexArea = (
  props: TextAreaProps,
  ref: ForwardedRef<HTMLTextAreaElement>
) => {
  const { size = "md", variant, errorMessage, ...rest } = props;

  const isInvalid = getIsInvalid({ errorMessage });

  const radixTextareaSize = getSize(size);
  const radixTextareaVariant = getVariant({ variant, isInvalid });

  const inputStackProps = getInputStackProps(props);

  const color = getColor(isInvalid);

  return (
    <InputStack {...inputStackProps}>
      <RadixThemeTextarea
        {...rest}
        size={radixTextareaSize}
        variant={radixTextareaVariant}
        color={color}
      />
    </InputStack>
  );
};

const TextArea = forwardRef(_TexArea);

export { TextArea, type TextAreaProps };
