type SyncStatusClientModelData = {
  status: "importing" | "failed" | "imported";
};

export class SyncStatusClientModel {
  constructor(readonly data: SyncStatusClientModelData) {}

  public get isImporting() {
    return this.data.status === "importing";
  }
}
