import { match, P } from "ts-pattern";
import { useBroadcastEvent } from "../liveblocks.config";
import { BroadcastEventTypes } from "./keys";

const useBroadcastQueryInvalidate = () => {
  const broadcast = useBroadcastEvent();

  return (...invalidteKeys: Array<readonly unknown[]>) => {
    invalidteKeys;

    const braodcastValues = invalidteKeys.map((keys) => {
      const stringValues: string[] = keys
        .map((key) => {
          return match(key)
            .with(P.string, (stringValue) => stringValue)
            .otherwise(() => undefined);
        })
        .filter((r) => r !== undefined) as string[];

      return stringValues;
    });

    broadcast({
      type: BroadcastEventTypes.INVALIDATE_QUERY,
      value: braodcastValues,
    });
  };
};

export { useBroadcastQueryInvalidate };
