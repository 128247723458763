import {
  QueryRecordRequestBody,
  QueryRecordWithFieldsResponse,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

export default function useApiExecuteDataApi() {
  const { executeRequest } = useApi();

  const _executeQueryDataApi = async ({
    apiKey,
    requestBody,
    teamSlug,
    tableSlug,
    databaseId,
  }: {
    apiKey: string;
    teamSlug: string;
    databaseId: string;
    tableSlug: string;
    requestBody: QueryRecordRequestBody;
  }) => {
    return executeRequest<QueryRecordWithFieldsResponse>(
      "post",
      `/${teamSlug}/${databaseId}/${tableSlug}/widget-data/record/query`,
      {
        withFields: true,
      },
      {
        teamSlug,
      },
      { ...requestBody }
    );
  };

  return {
    _executeQueryDataApi,
  };
}
