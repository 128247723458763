import { TimezoneClientModel } from "~/clientModel/timezone";
import { ScheduledTimeClientModel } from "../../../scheduleCore/ScheduledTime";
import { TargetDaysClientModel } from "../../../scheduleCore/targetDays";

type ComputedFieldSyncScheduleClientModelData<ID extends string | null> = {
  id: ID;
  targetDays: TargetDaysClientModel;
  scheduledTime: ScheduledTimeClientModel;
  isEnabled: boolean;
  timezone: TimezoneClientModel;
};

class ComputedFieldSyncScheduleClientModelBase<ID extends string | null> {
  readonly #data: ComputedFieldSyncScheduleClientModelData<ID>;

  public constructor(data: ComputedFieldSyncScheduleClientModelData<ID>) {
    this.#data = data;
  }

  public get data(): ComputedFieldSyncScheduleClientModelData<ID> {
    return this.#data;
  }

  public get targetDays(): TargetDaysClientModel {
    return this.#data.targetDays;
  }

  public get scheduledTime(): ScheduledTimeClientModel {
    return this.#data.scheduledTime;
  }

  public get isEnabled(): boolean {
    return this.#data.isEnabled;
  }

  public updateTargetDays(
    targetDays: TargetDaysClientModel
  ): ComputedFieldSyncScheduleClientModelBase<ID> {
    return new ComputedFieldSyncScheduleClientModelBase({
      ...this.#data,
      targetDays,
    });
  }

  public updateScheduledTime(
    scheduledTime: ScheduledTimeClientModel
  ): ComputedFieldSyncScheduleClientModelBase<ID> {
    return new ComputedFieldSyncScheduleClientModelBase({
      ...this.#data,
      scheduledTime,
    });
  }

  public updateIsEnabled(
    isEnabled: boolean
  ): ComputedFieldSyncScheduleClientModelBase<ID> {
    return new ComputedFieldSyncScheduleClientModelBase({
      ...this.#data,
      isEnabled,
    });
  }
}

export class ComputedFieldSyncScheduleClientModel extends ComputedFieldSyncScheduleClientModelBase<string> {
  public constructor(data: ComputedFieldSyncScheduleClientModelData<string>) {
    super(data);
  }
}

export class CreatingComputedFieldSyncScheduleClientModel extends ComputedFieldSyncScheduleClientModelBase<null> {
  public constructor(data: ComputedFieldSyncScheduleClientModelData<null>) {
    super(data);
  }
}
