import { IconBase, IconBaseProps } from "react-icons";

const MorphIconFloat = (props: IconBaseProps) => {
  return (
    <IconBase {...props} viewBox="0 0 24 24">
      <path d="M7.51685 4.71718V19.0141H5.78833V6.97304L2.80005 8.30118V6.67032L7.25317 4.71718H7.51685Z" />
      <path d="M9.91675 18.057C9.91675 17.751 10.0111 17.4939 10.1999 17.2855C10.3953 17.0707 10.6752 16.9633 11.0398 16.9633C11.4044 16.9633 11.6811 17.0707 11.8699 17.2855C12.0652 17.4939 12.1628 17.751 12.1628 18.057C12.1628 18.3565 12.0652 18.6104 11.8699 18.8188C11.6811 19.0271 11.4044 19.1312 11.0398 19.1312C10.6752 19.1312 10.3953 19.0271 10.1999 18.8188C10.0111 18.6104 9.91675 18.3565 9.91675 18.057Z" />
      <path d="M21.1242 10.7719V12.9398C21.1242 14.1052 21.033 15.0883 20.8508 15.8891C20.675 16.6898 20.421 17.3344 20.089 17.8227C19.7571 18.3109 19.3567 18.6658 18.8879 18.8871C18.4257 19.102 17.9049 19.2094 17.3254 19.2094C16.8697 19.2094 16.4433 19.141 16.0461 19.0043C15.6555 18.8676 15.3039 18.6495 14.9914 18.35C14.6789 18.044 14.412 17.6469 14.1907 17.1586C13.9693 16.6703 13.8 16.0779 13.6829 15.3812C13.5657 14.6846 13.5071 13.8708 13.5071 12.9398V10.7719C13.5071 9.60653 13.5982 8.62995 13.7805 7.84219C13.9628 7.05443 14.22 6.42293 14.552 5.94767C14.8905 5.46589 15.2909 5.12085 15.7532 4.91251C16.2219 4.70417 16.7427 4.60001 17.3157 4.60001C17.7779 4.60001 18.2043 4.66837 18.595 4.80509C18.9921 4.93529 19.3437 5.14689 19.6496 5.43985C19.9621 5.72631 20.229 6.11043 20.4504 6.59219C20.6718 7.06745 20.8378 7.65015 20.9484 8.34025C21.0656 9.03035 21.1242 9.84089 21.1242 10.7719ZM19.4055 13.2328V10.4691C19.4055 9.83113 19.3762 9.27123 19.3176 8.78947C19.259 8.30119 19.1744 7.88451 19.0637 7.53947C18.953 7.19441 18.8098 6.91447 18.634 6.69961C18.4648 6.48477 18.2694 6.32853 18.0481 6.23087C17.8267 6.12671 17.5826 6.07461 17.3157 6.07461C16.9836 6.07461 16.6874 6.15599 16.427 6.31877C16.1731 6.47501 15.955 6.72567 15.7727 7.07071C15.5969 7.41577 15.4635 7.86823 15.3723 8.42813C15.2812 8.98803 15.2356 9.66837 15.2356 10.4691V13.2328C15.2356 13.8708 15.2649 14.434 15.3235 14.9223C15.3886 15.4105 15.4765 15.8337 15.5871 16.1918C15.7043 16.5434 15.8476 16.8331 16.0168 17.0609C16.1861 17.2888 16.3814 17.4581 16.6028 17.5688C16.8241 17.6729 17.065 17.725 17.3254 17.725C17.6705 17.725 17.97 17.6404 18.2239 17.4711C18.4843 17.3018 18.7024 17.0382 18.8782 16.6801C19.0539 16.3155 19.1842 15.85 19.2688 15.2836C19.3599 14.7107 19.4055 14.0271 19.4055 13.2328Z" />
    </IconBase>
  );
};

export { MorphIconFloat };
