import { DashboardViewConditionObject } from "@usemorph/morph-dashboard-types";
import { useMutation } from "react-query";
import useApiSQL from "~/api/useApiSQL";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import { useErrorToast } from "~/components_next/Error";
import { useSaveGenerateSqlHistoryMutation } from "~/serverStateStore";

type UseGenerateSqlFromPromptExecutableParams = {
  teamSlug: string;
  databaseId: string;
  condition: DashboardViewConditionObject;
};

const useGenerateSqlFromPromptExecutable = ({
  teamSlug,
  databaseId,
  condition,
}: UseGenerateSqlFromPromptExecutableParams): Executable<
  { prompt: string },
  { sql: string }
> => {
  const { errorToast } = useErrorToast({});

  const { _generateSQL } = useApiSQL();

  const { mutateAsync: saveGenerateSqlHistory } = useMutation(
    useSaveGenerateSqlHistoryMutation({ teamSlug, databaseId })
  );

  return useComposeExecutable(
    useMutation({
      mutationFn: async ({ prompt }: { prompt: string }) => {
        const {
          result: { sql, action },
        } = await _generateSQL({
          teamSlug,
          databaseId,
          body: {
            message: prompt,
            condition,
            extraTables: [], // todo
          },
        });

        if (action === "invalid") {
          throw new Error("Failed to generate SQL. Please try again.");
        }

        // サーバーで入れてもらう
        saveGenerateSqlHistory({
          message: prompt,
          sql,
        });

        return { sql };
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseGenerateSqlFromPromptExecutable = ({
  teamSlug,
  databaseId,
}: {
  teamSlug: string;
  databaseId: string;
}) => {
  return ({ condition }: { condition: DashboardViewConditionObject }) =>
    useGenerateSqlFromPromptExecutable({
      teamSlug,
      databaseId,
      condition,
    });
};
