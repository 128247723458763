import { SimpleField } from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";
import {
  AggregateValueFieldTypeClientModel,
  ArrayFieldTypeClientModel,
  AttachmentFieldTypeClientModel,
  AutoBigNumberFieldTypeClientModel,
  BigNumberFieldTypeClientModel,
  BooleanFieldTypeClientModel,
  CalculationFieldTypeClientModel,
  CreatedAtFieldTypeClientModel,
  CreatedByFieldTypeClientModel,
  DateFieldTypeClientModel,
  DateTimeFieldTypeClientModel,
  DecimalFieldTypeClientModel,
  EmailFieldTypeClientModel,
  FieldTypeClientModel,
  GenerateTextFieldTypeClientModel,
  HtmlFieldTypeClientModel,
  ImageFieldTypeClientModel,
  JsonFieldTypeClientModel,
  LastEditedAtFieldTypeClientModel,
  LastEditedByFieldTypeClientModel,
  LongTextFieldTypeClientModel,
  MultiSelectFieldTypeClientModel,
  PhoneNumberFieldTypeClientModel,
  SingleSelectFieldTypeClientModel,
  SmartFunctionFieldTypeClientModel,
  SyncValueFieldTypeClientModel,
  TimeFieldTypeClientModel,
  UrlFieldTypeClientModel,
} from ".";
import { AutoNumberFieldTypeClientModel } from "./number/AutoNumberFieldTypeClientModel";
import { FormulaFieldTypeClientModel } from "./computed/FormulaFieldTypeClientModel";
import { NumberFieldTypeClientModel } from "./number/NumberFieldTypeClientModel";
import { ShortTextFieldTypeClientModel } from "./text/ShortTextFieldTypeClientModel";
import { SyncFieldTargetFieldType } from "./computed/SyncValueFieldTypeClientModel";

export class FieldTypeClientModelFactory {
  public static createFromSimpleField(
    field: SimpleField
  ): FieldTypeClientModel {
    return (
      match(field)
        // Computed Fields
        .with(
          { normalizedSource: { type: "sync" } },
          ({ normalizedSource }) =>
            new SyncValueFieldTypeClientModel({
              syncTargetTableSlug: normalizedSource.tableSlug,
              syncTargetFieldName: normalizedSource.fieldName,
              syncTargetFieldType: field.type as SyncFieldTargetFieldType, // todo
            })
        )
        .with(
          {
            normalizedSource: { type: "calculation" },
            type: P.union("shortText", "longText"),
          },
          ({ normalizedSource, type }) =>
            new GenerateTextFieldTypeClientModel({
              syncTargetTableSlug: normalizedSource.tableSlug,
              sql: normalizedSource.sql,
              syncedValueType: FieldTypeClientModelFactory.createEmpty(type) as
                | ShortTextFieldTypeClientModel
                | LongTextFieldTypeClientModel,
            })
        )
        .with(
          {
            normalizedSource: { type: "calculation" },
            type: P.union("number", "bigNumber", "decimal"),
          },
          ({ normalizedSource, type }) =>
            new CalculationFieldTypeClientModel({
              syncTargetTableSlug: normalizedSource.tableSlug,
              sql: normalizedSource.sql,
              syncedValueType: FieldTypeClientModelFactory.createEmpty(type) as
                | NumberFieldTypeClientModel
                | BigNumberFieldTypeClientModel
                | DecimalFieldTypeClientModel,
            })
        )
        .with(
          { normalizedSource: { type: "aggregation" } },
          ({ normalizedSource }) =>
            new AggregateValueFieldTypeClientModel({
              syncTargetTableSlug: normalizedSource.tableSlug,
              syncTargetFieldName: normalizedSource.fieldName,
              operatorValue: normalizedSource.operator,
            })
        )

        // Smart Function Field
        .with(
          { smartFunction: P.when((sf) => !!sf) },
          ({ smartFunction: smartFunctionSchema }) => {
            return new SmartFunctionFieldTypeClientModel({
              smartFunctionId: smartFunctionSchema.functionId,
              settingData: smartFunctionSchema.data,
            });
          }
        )

        // text
        .with({ type: "shortText" }, () => new ShortTextFieldTypeClientModel())
        .with({ type: "longText" }, () => new LongTextFieldTypeClientModel())
        .with({ type: "email" }, () => new EmailFieldTypeClientModel())
        .with(
          { type: "phoneNumber" },
          () => new PhoneNumberFieldTypeClientModel()
        )
        .with({ type: "url" }, () => new UrlFieldTypeClientModel())
        .with({ type: "date" }, () => new DateFieldTypeClientModel())
        .with({ type: "datetime" }, () => new DateTimeFieldTypeClientModel())
        .with({ type: "time" }, () => new TimeFieldTypeClientModel())

        // number
        .with({ type: "number" }, () => new NumberFieldTypeClientModel())
        .with({ type: "bigNumber" }, () => new BigNumberFieldTypeClientModel())
        .with({ type: "decimal" }, () => new DecimalFieldTypeClientModel())
        .with(
          { type: "autoNumber" },
          () => new AutoNumberFieldTypeClientModel()
        )
        .with(
          { type: "autoBigNumber" },
          () => new AutoBigNumberFieldTypeClientModel()
        )

        // other primitives
        .with({ type: "boolean" }, () => new BooleanFieldTypeClientModel())

        // select
        .with(
          { type: "singleSelect" },
          () =>
            new SingleSelectFieldTypeClientModel({
              members: field.members ?? [],
            })
        )
        .with(
          { type: "multiSelect" },
          () =>
            new MultiSelectFieldTypeClientModel({
              members: field.members ?? [],
            })
        )

        // file
        .with({ type: "image" }, () => new ImageFieldTypeClientModel())
        .with(
          { type: "attachment" },
          () => new AttachmentFieldTypeClientModel()
        )

        // structured data
        .with({ type: "json" }, () => new JsonFieldTypeClientModel())
        .with({ type: "array" }, () => new ArrayFieldTypeClientModel())
        .with({ type: "html" }, () => new HtmlFieldTypeClientModel())

        // computed
        .with(
          { type: "formula" },
          () =>
            new FormulaFieldTypeClientModel({ formula: field.formula ?? "" })
        )

        // system
        .with({ type: "createdAt" }, () => new CreatedAtFieldTypeClientModel())
        .with(
          { type: "lastEditedAt" },
          () => new LastEditedAtFieldTypeClientModel()
        )
        .with({ type: "createdBy" }, () => new CreatedByFieldTypeClientModel())
        .with(
          { type: "lastEditedBy" },
          () => new LastEditedByFieldTypeClientModel()
        )
        .with(
          {
            type: P.union(
              "richText",
              "reference",
              "multiReference",
              "embeddings",
              "python",
              "rowInfo"
            ),
          },
          () => {
            throw new Error(`Unknown field type ${field.type}`);
          }
        )
        .exhaustive()
    );
  }

  public static createEmpty(
    type: FieldTypeClientModel["type"]
  ): FieldTypeClientModel {
    return (
      match(type)
        // text
        .with("shortText", () => new ShortTextFieldTypeClientModel())
        .with("longText", () => new LongTextFieldTypeClientModel())
        .with("email", () => new EmailFieldTypeClientModel())
        .with("phoneNumber", () => new PhoneNumberFieldTypeClientModel())
        .with("url", () => new UrlFieldTypeClientModel())
        .with("date", () => new DateFieldTypeClientModel())
        .with("datetime", () => new DateTimeFieldTypeClientModel())
        .with("time", () => new TimeFieldTypeClientModel())

        // number
        .with("number", () => new NumberFieldTypeClientModel())
        .with("bigNumber", () => new BigNumberFieldTypeClientModel())
        .with("decimal", () => new DecimalFieldTypeClientModel())
        .with("autoNumber", () => new AutoNumberFieldTypeClientModel())
        .with("autoBigNumber", () => new AutoBigNumberFieldTypeClientModel())

        // other primitives
        .with("boolean", () => new BooleanFieldTypeClientModel())

        // select
        .with(
          "singleSelect",
          () => new SingleSelectFieldTypeClientModel({ members: [] })
        )
        .with(
          "multiSelect",
          () => new MultiSelectFieldTypeClientModel({ members: [] })
        )

        // file
        .with("image", () => new ImageFieldTypeClientModel())
        .with("attachment", () => new AttachmentFieldTypeClientModel())

        // structured data
        .with("json", () => new JsonFieldTypeClientModel())
        .with("array", () => new ArrayFieldTypeClientModel())
        .with("html", () => new HtmlFieldTypeClientModel())

        // computed
        .with("formula", () => new FormulaFieldTypeClientModel({ formula: "" }))
        .with(
          "syncValue",
          () =>
            new SyncValueFieldTypeClientModel({
              syncTargetTableSlug: null,
              syncTargetFieldName: null,
              syncTargetFieldType: null,
            })
        )
        .with(
          "generateText",
          () =>
            new GenerateTextFieldTypeClientModel({
              syncTargetTableSlug: null,
              sql: null,
              syncedValueType: new ShortTextFieldTypeClientModel(),
            })
        )
        .with(
          "calculation",
          () =>
            new CalculationFieldTypeClientModel({
              syncTargetTableSlug: null,
              sql: null,
              syncedValueType: new NumberFieldTypeClientModel(),
            })
        )
        .with(
          "aggregateValue",
          () =>
            new AggregateValueFieldTypeClientModel({
              syncTargetTableSlug: null,
              syncTargetFieldName: null,
              operatorValue: null,
            })
        )
        .with(
          "smartFunction",
          () =>
            new SmartFunctionFieldTypeClientModel({
              smartFunctionId: null,
              settingData: {},
            })
        )

        // system
        .with("createdAt", () => new CreatedAtFieldTypeClientModel())
        .with("lastEditedAt", () => new CreatedAtFieldTypeClientModel())
        .with("createdBy", () => new CreatedAtFieldTypeClientModel())
        .with("lastEditedBy", () => new CreatedAtFieldTypeClientModel())
        .exhaustive()
    );
  }
}
