import { useParams } from "react-router-dom";

const useDataApiKey = () => {
  const { dataApiKey } = useParams();

  if (!dataApiKey) {
    throw new Error(`notebookId not found.`);
  }

  return dataApiKey;
};

export { useDataApiKey };
