import { useQueryClient } from "react-query";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import useApiRssFeed from "~/api/useApiRssFeed";
import { DashboardUpdateRssTableMappingRequestBody } from "@usemorph/morph-dashboard-types";
import { rssFeedKeys } from "../keys";
import { RssTableMappingClientModelFactory } from "~/clientModel/rssFeed";

type UseUpdateRssTableMappingMutationParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

type UseUpdateRssTableMappingMutationProps = DashboardUpdateRssTableMappingRequestBody

const useUpdateRssTableMappingMutation = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseUpdateRssTableMappingMutationParams) => {
  const { _updateRssTableMapping } = useApiRssFeed();
  const client = useQueryClient();

  return {
    mutationFn: async (props: UseUpdateRssTableMappingMutationProps) => {
      const response = await _updateRssTableMapping({
        teamSlug,
        databaseId,
        tableSlug,
        requestBody: props
      });
      return RssTableMappingClientModelFactory.fromRssTableMappingResponse(response)
    },
    onSuccess: () => {
      return invalidateAll(
        client,
        rssFeedKeys.all({
          teamSlug,
        })
      );
    },
  };
};

export { useUpdateRssTableMappingMutation, type UseUpdateRssTableMappingMutationProps };
