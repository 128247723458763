import { ForwardedRef, forwardRef, ReactNode } from "react";
import { Command as CommandPrimitive } from "cmdk";

type MultiSelectListProps = { children: ReactNode };

const MultiSelectList = forwardRef(
  (props: MultiSelectListProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children } = props;

    return (
      <CommandPrimitive.List ref={ref}>
        <CommandPrimitive.Group>{children}</CommandPrimitive.Group>
      </CommandPrimitive.List>
    );
  }
);

MultiSelectList.displayName = "MultiSelectList";

export { MultiSelectList, type MultiSelectListProps };
