import {
  RecordFilterConditionAnd,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { RecordModel } from "~/features/RecordModel";

/**
 * simple fieldとrecordから、一つのレコードを対象とするfilter conditionを生成する
 */
export const createFilterConditionForOneRecord = (
  recordModel: RecordModel
): RecordFilterConditionAnd => {
  return {
    and: Object.entries(recordModel).flatMap(([key, { value, isPrimary }]) => {
      if (!isPrimary) return [];
      return [
        {
          value: value as any, // todo: cast止める
          key,
          operator: "equal",
        },
      ];
    }),
  };
};

/**
 * simple fieldとrecordsから、複数のレコードを対象とするfilter conditionを生成する
 */
export const createFilterConditionForRecords = (
  recordModels: RecordModel[]
) => {
  return {
    or: recordModels.map(
      (recordModel): RecordFilterConditionAnd =>
        createFilterConditionForOneRecord(recordModel)
    ),
  };
};

/**
 * View Recordsの更新用のfilterオブジェクトを作成する
 * Primary keyとその値のオブジェクト
 */
export const createPrimaryKeyObjectForOneRecord = (
  fields: SimpleField[],
  recordModel: RecordModel
) =>
  fields.reduce((filterAcc, { name: fieldName, primary }) => {
    if (!primary) return filterAcc;

    return {
      ...filterAcc,
      [fieldName]: recordModel[fieldName].value,
    };
  }, {} as Record<string, unknown>);
