import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModel } from ".";
import { SmartFunctionsClientModel } from "./smartFunction/smartFunctions";

export abstract class FieldTypeClientModelBase {
  public abstract get type(): string;

  public abstract get fieldTypeLabel(): string;

  public abstract get isAutoGenerated(): boolean;

  public abstract get isEmbeddable(): boolean;

  public abstract get canBeTargetOfSyncingByComputedField(): boolean;

  protected abstract get _updatableFieldTypes(): FieldTypeClientModel["type"][];

  public get updatableFieldTypes(): FieldTypeClientModel["type"][] {
    // 自分自身は入れる
    return [
      this.type as FieldTypeClientModel["type"],
      ...this._updatableFieldTypes.flatMap((type) =>
        type === this.type ? [] : type
      ),
    ];
  }

  public get canUpdateFieldType(): boolean {
    return this.updatableFieldTypes.length > 1;
  }

  public abstract validate({
    smartFunctions,
  }: {
    smartFunctions: SmartFunctionsClientModel;
  }): ValidationResult;
}
