import {
  ComponentPropsWithoutRef,
  ForwardedRef,
  KeyboardEvent,
  ReactNode,
  forwardRef,
  useMemo,
  useState,
} from "react";
import {
  InputStack,
  InputStackProps,
  getInputStackProps,
} from "../InputStack/InputStack";
import { MorphThemeSize } from "../commonProps.type";
import { TextField as RadixThemeTextField } from "@radix-ui/themes";
import { P, match } from "ts-pattern";
import { Flex } from "../Flex";
import { styled } from "~/stitches";
import { Badge } from "../Badge/Badge";

type RadixThemeInputProps = ComponentPropsWithoutRef<
  typeof RadixThemeTextField.Input
>;

type BadgeInputProps = InputStackProps & {
  value?: string[];
  onChange?: (value: string[]) => void;
  variant: "primary" | "supplementary";
  isInvalid?: boolean;
  size?: MorphThemeSize;
  leftSlot?: ReactNode;
  rightSlot?: ReactNode;
  readonly?: boolean;
  disabled?: boolean;
};

const getSize = (size: MorphThemeSize): RadixThemeInputProps["size"] => {
  return match(size)
    .with("xs", () => "1" as const)
    .with("sm", () => "2" as const)
    .with("md", () => "3" as const)
    .with("lg", () => "3" as const)
    .with("xl", () => "3" as const)
    .otherwise(() => "3" as const);
};

const InputContainerElement = styled(Flex, {
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  boxShadow: "inset 0 0 0 1px var(--gray-a7)",
  cursor: "default",
  color: "var(--gray-12)",
  minHeight: "var(--multi-select-input-container-min-height)",
  backgroundColor: "var(--color-surface)",
  "&:hover": {
    "&:where(:hover)": {
      boxShadow: "inset 0 0 0 1px var(--gray-a8)",
    },
  },
});

const BadgeInput = forwardRef(
  (props: BadgeInputProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      variant,
      errorMessage,
      isInvalid = false,
      size = "sm",
      leftSlot,
      rightSlot,
      value = [],
      onChange,
      readonly,
      disabled,
    } = props;

    const inputStackProps = getInputStackProps(props);

    const _isInvalid = useMemo(() => {
      return match([isInvalid, errorMessage])
        .with([true, P._], () => true)
        .with([false, P.string], () => true)
        .with(
          [false, P.array(P.string)],
          ([_, errorMessages]) => errorMessages.length > 0
        )
        .otherwise(() => false);
    }, [errorMessage, isInvalid]);

    const radixInputVariant = useMemo((): RadixThemeInputProps["variant"] => {
      return match([_isInvalid, variant])
        .with([true, P._], () => "soft" as RadixThemeInputProps["variant"])
        .with(
          [false, "supplementary"],
          () => "soft" as RadixThemeInputProps["variant"]
        )
        .otherwise(() => "surface" as RadixThemeInputProps["variant"]);
    }, [_isInvalid, variant]);

    const radixInputSize = getSize(size);

    /**
     * Local state
     */
    const [inputValue, setInputValue] = useState("");

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" && event.nativeEvent.isComposing === false) {
        if (onChange) {
          onChange([...value, inputValue]);
        }
        setInputValue("");
      }
    };

    return (
      <InputStack {...inputStackProps}>
        <RadixThemeTextField.Root ref={ref} color={_isInvalid ? "red" : "gray"}>
          <RadixThemeTextField.Slot>
            {leftSlot}
            {value.map((v, i) => (
              <Badge key={i} variant="tertiary" size={size}>
                {v}
              </Badge>
            ))}
          </RadixThemeTextField.Slot>
          <RadixThemeTextField.Input
            variant={radixInputVariant}
            size={radixInputSize}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyPress}
            readOnly={readonly}
            disabled={disabled}
          />
          <RadixThemeTextField.Slot>{rightSlot}</RadixThemeTextField.Slot>
        </RadixThemeTextField.Root>
      </InputStack>
    );
  }
);

BadgeInput.displayName = "BadgeInput";

export { BadgeInput };
