
import { DashboardNotificationResponse } from "@usemorph/morph-dashboard-types";
import { NotificationClientModel } from "./NotificationClientModel";

export const NotificationClientModelFactory = {
  // factory methods
  fromNotificationResponse: (
    data: DashboardNotificationResponse
  ): NotificationClientModel => {
    return new NotificationClientModel(data);
  },
};
