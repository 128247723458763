import { useQuery } from "react-query";
import { CanvasVisualizationPromptConfigsCientModelFactory } from "~/clientModel/canvas/CanvasVisualizationPromptConfigClientModel";
import { useComposeLoadable } from "~/clientModel/loadable";
import { useListVisualizationPromptConfigsQuery } from "~/serverStateStore";

export const createUseVisualizationPromptConfigsLoadable = (props: {
  teamSlug: string;
}) => {
  const { teamSlug } = props;

  return () =>
    useComposeLoadable(
      useQuery({
        ...useListVisualizationPromptConfigsQuery({
          teamSlug,
        }),
        select: (response) => {
          return CanvasVisualizationPromptConfigsCientModelFactory.fromPromptConfigsResponse(
            response
          );
        },
      })
    );
};
