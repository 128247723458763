import { match, P } from "ts-pattern";
import { EqualFilterOperationClientModel } from "./EqualFilterOperationClientModel";
import { IsNullFilterOperationClientModel } from "./IsNullFilterOperationClientModel";
import { NotEqualFilterOperationClientModel } from "./NotEqualFilterOperationClientModel";
import { NotNullFilterOperationClientModel } from "./NotNullFilterOperationClientModel";
import { FilterOperationClientModel } from ".";
import { RecordFilterConditionUnit } from "@usemorph/morph-dashboard-types";

export class FilterOperationClientModelFactory {
  /**
   * 引数をoperatorとvalueの組み合わせのUnionにしてもいいが、
   * サーバーレスポンスでは組み合わせが保証されてないので、どうせどこかでパターンマッチすることになる。
   * とりあえずはFactoryにそれをやらせることにして、以下のように書く。
   * 検討の余地はある。
   */
  public static fromOperatorAndValue(
    operator: FilterOperationClientModel["operatorValue"], // 本当はこっち
    // operator:
    //   | "isNull"
    //   | "equal"
    //   | "notNull"
    //   | "notEqual"
    //   | "lessThan"
    //   | "lessThanOrEqual"
    //   | "greaterThan"
    //   | "greaterThanOrEqual"
    //   | "like"
    //   | "startsWith"
    //   | "endsWith"
    //   | "in"
    //   | "notIn"
    //   | "me",
    value?: RecordFilterConditionUnit["value"] // 本当はこっち
  ) {
    return match([operator, value])
      .with(
        ["equal", P.string],
        ([equalOperator, stringValue]) =>
          new EqualFilterOperationClientModel({
            value: stringValue,
          })
      )
      .with(["equal", P.not(P.string)], ([equalOperator, notStringValue]) => {
        console.error(
          "FilterOperationClientModelFactory: equal operator must have string value"
        );
        return new EqualFilterOperationClientModel({
          value: String(notStringValue),
        });
      })
      .with(
        ["notEqual", P.string],
        ([notEqualOperator, stringValue]) =>
          new NotEqualFilterOperationClientModel({
            value: stringValue,
          })
      )
      .with(
        ["notEqual", P.not(P.string)],
        ([notEqualOperator, notStringValue]) => {
          console.error(
            "FilterOperationClientModelFactory: notEqual operator must have string value"
          );
          return new NotEqualFilterOperationClientModel({
            value: String(notStringValue),
          });
        }
      )
      .with(["isNull", P._], () => new IsNullFilterOperationClientModel())
      .with(["notNull", P._], () => new NotNullFilterOperationClientModel())
      .otherwise(() => {
        // TODO: リリースまでに絶対無くす
        throw new Error(
          "FilterOperationClientModelFactory: invalid operator and value combination"
        );
      });
  }
}
