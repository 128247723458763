import { Document } from "@tiptap/extension-document";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Text } from "@tiptap/extension-text";
import { History } from "@tiptap/extension-history";
import { Placeholder } from "@tiptap/extension-placeholder";
import { composeMention } from "./suggestion/mention";
import { createSuggestion } from "./suggestion/createSuggestion";
import { ReactNode } from "react";

/**
 * 静的なextensions
 */

const staticExtensions = [
  Document,
  Paragraph,
  Text,
  History,
  Placeholder.configure({
    placeholder: "Press @ to refer an existing table or field name.",
  }),
];

/**
 * サジェスト
 */

type SuggestExtensionDependencies<OptionItem> = {
  filterFn?: (item: OptionItem, query: string) => boolean;
  sortFn?: (item: OptionItem) => number;
  renderSuggestedItem: (item: OptionItem) => ReactNode;
  renderHTMLTextContent: (item: OptionItem) => string;
  convertOptionItemToText: (item: OptionItem) => string;
};

const createSuggestExtension = <OptionItem>({
  filterFn,
  sortFn,
  renderSuggestedItem,
  renderHTMLTextContent,
  convertOptionItemToText,
}: SuggestExtensionDependencies<OptionItem>) => {
  const extension = composeMention<OptionItem>().configure({
    suggestion: createSuggestion<OptionItem>({
      triggerChar: "@",
      filterFn,
      sortFn,
      renderSuggestedItem,
    }),
    renderHTMLTextContent({ node }) {
      return renderHTMLTextContent(node.attrs.value);
    },
    convertNodeToText: ({ node }) => {
      return convertOptionItemToText(node.attrs.value);
    },
  });

  return extension;
};

export {
  staticExtensions,
  createSuggestExtension,
  type SuggestExtensionDependencies,
};
