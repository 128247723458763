import {
  DashboardPaymentResponse,
  DashboardPlanResponse,
  PaymentCategoryType,
  DashboardTrialResponse,
} from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";

export type PlanStatusType =
  | "hide"
  | "currentTrial"
  | "nextPlan"
  | "currentPlan"
  | "selectButton"
  | "trialButton";

export class FindPaymentClientModel {
  constructor(private readonly data: DashboardPaymentResponse) { }

  /**
   * Getters
   */
  get isSubscribed(): boolean {
    return this.data.isSubscribed;
  }

  get seatNum(): number {
    return this.data.seatNum;
  }

  get plan(): DashboardPlanResponse | null {
    return this.data.plan;
  }

  get subscriptionEndedAt(): string | null {
    return this.data.subscriptionEndedAt;
  }

  get hasCardAlert(): boolean {
    return this.data.hasCardAlert;
  }

  get paymentType(): PaymentCategoryType | null {
    return this.data.paymentType;
  }

  get currentTrial(): DashboardTrialResponse | null {
    return this.data.currentTrial;
  }

  get trialHistories(): DashboardTrialResponse[] {
    return this.data.trialHistories;
  }

  get isLimitExceeded(): boolean {
    return this.data.isLimitExceeded;
  }

  get userLangage(): string {
    const browserLanguage = window.navigator.language;
    return match([this.data.plan?.currency, browserLanguage])
      .with(["yen", P._], () => "ja")
      .with(["dollar", P._], () => "en")
      .with([P._, "ja"], () => "ja")
      .with([P._, "ja-JP"], () => "ja")
      .otherwise(() => "en");
  }

  get currentPlanId(): string {
    return this.data.plan?.planId || "free";
  }

  get isTrialAvailable(): boolean {
    return !this.data.isSubscribed && this.data?.currentTrial === null;
  }

  get isFreePlan(): boolean {
    if (this.data.plan === null) return true;
    return this.data.plan?.planId === "free";
  }
}
