import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { CanvasVariablesValue } from "./CanvasVariablesProvider";
import constate from "constate";

export type UseVariableOptionsViewResultLoadable = UseLoadable<
  {
    cellId: string;
    name: string;
    variables: CanvasVariablesValue[];
  },
  {
    count: number;
    options: Array<{ label: string; value?: unknown }>;
  }
>;

type VariableOptionsLoadableContextProps = {
  useVariableOptionsLoadable: UseVariableOptionsViewResultLoadable;
};

const useVariableOptionsLoadableContext = (
  props: VariableOptionsLoadableContextProps
) => {
  const { useVariableOptionsLoadable } = props;
  return {
    useVariableOptionsLoadable,
  };
};

export const [
  CanvasVariableOptionsLoadableProvider,
  useUseVariableOptionsLoadable,
] = constate(
  useVariableOptionsLoadableContext,
  (value) => value.useVariableOptionsLoadable
);
