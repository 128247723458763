import { Flex, LayoutProps } from "@radix-ui/themes";
import { ReactNode } from "react";
import { match, P } from "ts-pattern";
import { Text } from "~/components_next/Text";
import { MorphThemeSize } from "../commonProps.type";

type InputStackProps = {
  label?: string;
  description?: string;
  errorMessage?: string | string[];
  children?: ReactNode;
  width?: LayoutProps["width"];
  size?: MorphThemeSize;
};

const _InputStack = (props: InputStackProps) => {
  const {
    label,
    description,
    errorMessage,
    children,
    width = "100%",
    size = "md",
  } = props;

  const errorMessageComponents = match(errorMessage)
    .with(P.string, () => {
      return <Text variant="errorMessage">{errorMessage}</Text>;
    })
    .with(P.array(P.string), (errorMessages) => {
      return (
        <>
          {errorMessages.map((errorMessage, i) => {
            return (
              <Text key={i} variant="errorMessage">
                {errorMessage}
              </Text>
            );
          })}
        </>
      );
    })
    .otherwise(() => <></>);

  return (
    <Flex gap="1" direction="column" width={width} align="stretch">
      {label && <Text>{label}</Text>}
      {description && (
        <Text variant={size === "xs" ? "tinyDescription" : "description"}>
          {description}
        </Text>
      )}
      {children}
      {errorMessageComponents}
    </Flex>
  );
};

const getInputStackProps = (
  props: InputStackProps & unknown
): InputStackProps => {
  const { label, description, errorMessage, children, width, size } = props;

  return {
    label,
    description,
    errorMessage,
    children,
    width,
    size,
  };
};

export { _InputStack as InputStack, type InputStackProps, getInputStackProps };
