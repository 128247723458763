import {
  DashboardNotebookCellOutputObject,
  DashboardViewConditionObject,
  RecordFilterConditionAndType,
  RecordFilterConditionOrType,
  RecordSortConditionUnit,
} from "@usemorph/morph-dashboard-types";
import { memo, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { NodeToolbar, Position } from "reactflow";
import { Button } from "~/components_next/Button";
import { useErrorToast } from "~/components_next/Error";
import { useNotebookLiveObject } from "~/features/RealtimeCollaboration/Notebook/useNotebookLiveObject";
import { ViewCellComponent } from "~/features/SourceAndViews/ViewCell";
import { parseViewSetting, ViewSetting } from "~/features/View";
import { useCachedValue } from "~/hooks/useCachedValue/useCachedValue";
import { useDebounceState } from "~/hooks/useDebounceState";
import { useUpdateNotebookCellMutaiton } from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useNotebookId } from "~/utilHooks/useNotebookId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { sortObjectUtils } from "~/utils/sortObjectUtils";
import { useNotebookCells } from "../../context/NotebookCellContext";
import { NotebookCellObjectWithMeta } from "../../types/NotebookCellObjectWithMeta.type";
import { findViewIdFromViewCell } from "../../util/findViewIdFromViewCell";
import { useNotebookAncestorVariables } from "../Variables/useNotebookAncestorVariables";
import { NoteboolCellViewConditionLabels } from "./NotebookCellViewConditionLabels";

type NotebookCellViewContentProps = {
  cellObject: NotebookCellObjectWithMeta;
};

const findParentFrom = (
  targetCell: NotebookCellObjectWithMeta,
  cells: readonly NotebookCellObjectWithMeta[]
) => {
  /**
   * 前提: viewまたはsourceが複数parentIdsに入ることはないはず
   */

  const parentCells = cells.filter(
    (cell) => targetCell.parentIds?.includes(cell.cellId) ?? false
  );
  const parentViewCell = parentCells.find((cell) => cell.cellType === "view");
  const parentSourceCell = parentCells.find(
    (cell) => cell.cellType === "source"
  );

  /**
   * joinがある時は例外処理
   */

  if (targetCell.source.condition?.join) {
    // tableSlugかviewIdが来るはず
    const joinIds = targetCell.source.condition.join.map(
      (join) => join.targetTable
    );
    const viewIds = parentCells
      .filter((cell) => cell.cellType === "view")
      .map((cell) => {
        return findViewIdFromViewCell(cell);
      })
      .filter((value) => value !== undefined) as string[];
    const sourceTableSlugs = parentCells
      .filter((cell) => cell.cellType === "source")
      .map((cell) => {
        return cell.source.tableSlug;
      })
      .filter((value) => value !== undefined) as string[];

    const notFoundId = [...viewIds, ...sourceTableSlugs].find((id) => {
      return !joinIds.includes(id);
    });

    return notFoundId;
  }

  /**
   * 親から探す
   */

  if (parentViewCell) {
    const viewId = findViewIdFromViewCell(parentViewCell);
    if (viewId) {
      return viewId;
    }
  }

  if (parentSourceCell && parentSourceCell.source.tableSlug) {
    return parentSourceCell.source.tableSlug;
  }

  const moreParents = parentCells
    .flatMap((cell) => {
      return findParentFrom(cell, cells);
    })
    .filter((value) => value !== undefined) as string[];

  if (moreParents.length > 0) {
    return moreParents[0];
  }
  return undefined;
};

const NotebookCellViewContent = (props: NotebookCellViewContentProps) => {
  const { cellObject } = props;
  const { outputs, source } = cellObject;

  const viewId: string = useMemo(() => {
    const foundOutput = outputs.find(
      ({ contentType }) => contentType === "application/morph.view_id"
    ) as DashboardNotebookCellOutputObject & {
      contentType: "application/morph.view_id";
    };
    if (!foundOutput) {
      throw new Error("Something went wrong. View is not found.");
    }
    return foundOutput.value;
  }, [outputs]);

  const { ancestorVariableValues } = useNotebookAncestorVariables(cellObject);
  const cachedValue = useCachedValue(ancestorVariableValues, (value) =>
    JSON.stringify(value)
  );

  /**
   * View Condtion
   */
  const [updatedViewCondtion, setUpdatedViewCondtion] =
    useState<DashboardViewConditionObject | null>(null);
  const [updatedViewSetting, setUpdatedViewSetting] =
    useState<ViewSetting | null>(null);

  const parsedViewSetting = parseViewSetting(source.setting);

  const handleConditionChange = ({
    condition,
    settings,
  }: {
    condition?: DashboardViewConditionObject;
    settings?: ViewSetting;
  }) => {
    if (condition) {
      /**
       * ガチで応急処置
       * ViewCellComponentの中から、onConditionChangeが再レンダリングのたびに呼ばれていて、
       * upadte viewの処理をやっている最中に、ローカルステートを書き換えてしまう
       */
      const normalize = ({
        filter,
        sort,
        from,
      }: {
        filter:
          | RecordFilterConditionAndType
          | RecordFilterConditionOrType
          | null
          | undefined;
        sort: RecordSortConditionUnit[] | null | undefined;
        from: string | null | undefined;
      }) =>
        JSON.stringify({
          filter: filter ?? null,
          sort: sort && sort.length > 0 ? sort : null,
          from: from || null,
        });
      const payloadNormarized = normalize({
        filter: condition.filter,
        sort: sortObjectUtils.getRecordSortConditionUnits(condition.sort),
        from: condition.from,
      });
      const currentLocalCondtionNormarized = normalize({
        filter: source.condition?.filter,
        sort: sortObjectUtils.getRecordSortConditionUnits(
          source.condition?.sort
        ),
        from: source.condition?.from,
      });
      if (payloadNormarized === currentLocalCondtionNormarized) {
        setUpdatedViewCondtion(null);
      } else {
        setUpdatedViewCondtion(condition);
      }
    }
    if (settings) {
      setUpdatedViewSetting(settings);
    }
  };

  /**
   * Update Cell
   */
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const notebookId = useNotebookId();

  const { cells: liveCells = [], updateNotebookCells: updateLiveCell } =
    useNotebookLiveObject();

  const { mutateAsync: onUpdateNotebookCell, isLoading: isUpdatingCell } =
    useMutation(
      useUpdateNotebookCellMutaiton({
        teamSlug,
        databaseId,
        notebookId,
      })
    );

  const onUpdateLiveCell = (updatedCell: NotebookCellObjectWithMeta) => {
    const cellIndex = liveCells?.findIndex(
      (cell) => cell.cellId === updatedCell.cellId
    );

    if (cellIndex === undefined || cellIndex < 0) {
      errorToast(new Error("Cell not found"));
      return;
    }

    updateLiveCell({
      cell: updatedCell,
      index: cellIndex,
    });
  };

  const handleOnSave = async () => {
    setUpdatedViewCondtion(() => null);
    setUpdatedViewSetting(() => null);
    if (!updatedViewCondtion) {
      // MARK: LIVEBLOCKS
      await onUpdateNotebookCell({
        ...cellObject,
        source: {
          ...cellObject.source,
          setting: updatedViewSetting || cellObject.source.setting,
        },
      });

      onUpdateLiveCell({
        ...cellObject,
        source: {
          ...cellObject.source,
          setting: updatedViewSetting || cellObject.source.setting,
        },
      });
    } else {
      await onUpdateNotebookCell({
        ...cellObject,
        source: {
          ...cellObject.source,
          condition: updatedViewCondtion,
          setting: updatedViewSetting || cellObject.source.setting,
        },
      });
      onUpdateLiveCell({
        ...cellObject,
        source: {
          ...cellObject.source,
          condition: updatedViewCondtion,
          setting: updatedViewSetting || cellObject.source.setting,
        },
      });
    }
  };

  const allCells = useNotebookCells();
  const { errorToast } = useErrorToast({});

  const handleClearCondition = () => {
    const parentFrom = findParentFrom(cellObject, allCells);

    if (!parentFrom) {
      errorToast(
        "Initial Condition Not Found: Please remove the cell and re-create new cell."
      );
      return;
    }

    onUpdateNotebookCell({
      ...cellObject,
      source: {
        ...cellObject.source,
        condition: {
          from: parentFrom,
          select: ["*"],
        },
        setting: {},
      },
    });
    onUpdateLiveCell({
      ...cellObject,
      source: {
        ...cellObject.source,
        condition: {
          from: parentFrom,
          select: ["*"],
        },
        setting: {},
      },
    });
  };

  const renderKey = useDebounceState(JSON.stringify(source.condition), 500);

  if (!source.condition) {
    // throw "Something went wrong. View condition is not found.";
    return null;
  }

  return (
    <>
      {/* <>{String(updatedViewCondtion !== null)}</>
      <>{String(updatedViewSetting !== null)}</> */}
      <ViewCellComponent
        key={renderKey}
        viewId={viewId}
        variables={cachedValue}
        condition={source.condition}
        onConditionChange={handleConditionChange}
        viewSetting={updatedViewSetting || parsedViewSetting}
        height={cellObject.settings.height}
      />

      <NodeToolbar
        position={Position.Top}
        isVisible={updatedViewCondtion !== null || updatedViewSetting !== null}
      >
        <Button variant="primary" size="sm" onClick={handleOnSave}>
          Update View
        </Button>
      </NodeToolbar>

      <NoteboolCellViewConditionLabels
        source={source}
        viewId={viewId}
        onClearCondition={handleClearCondition}
        viewSetting={parsedViewSetting}
      />

      {/* <NotebookCellViewShare viewId={viewId} /> */}
    </>
  );
};

const memoizedNotebookCellViewContent = memo(NotebookCellViewContent);

export { memoizedNotebookCellViewContent as NotebookCellViewContent };
