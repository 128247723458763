import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useGetPublishedNotebookPagesQuery } from "~/serverStateStore/notebookPage/queries/useGetPublishedNotebookPagesQuery";
import { useFindPublicPageDetailQuery } from "~/serverStateStore/notebookPage";
import { useComposeLoadable } from "~/clientModel/loadable";
import { CanvasPublicDashboardPresenter } from "~/presenters/canvas/publicDashboard/CanvasPublicDashboardPresenter";
import { FindDashboardPageClientModelFactory } from "~/clientModel/canvas/FindDashboardPageClientModel/FindDashboardPageClientModelFactory";
import { ListCanvasPageClientModelFactory } from "~/clientModel/canvas/ListCanvasPageClientModel";
import { useTableViewProps } from "./useTableViewProps";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { usePublicApiKey } from "~/utilHooks/usePublicApiKey";
import { createUsePublicVariableOptionsLoadable } from "./createUsePublicVariableOptionsLoadable";

const CanvasPublicDashboardContainer = () => {
  const teamSlug = useTeamSlug();
  const publicApiKey = usePublicApiKey();

  const [activePage, setActivePage] = useState<string | null>(null);

  const notebookPagesQueryResult = useQuery({
    ...useGetPublishedNotebookPagesQuery({
      teamSlug: teamSlug,
      apiKey: publicApiKey,
    }),
    select: (response) => {
      return ListCanvasPageClientModelFactory.fromPublisedPagesResponse(
        response
      );
    },
    enabled: teamSlug !== undefined && publicApiKey !== undefined,
    onSuccess: (data) => {
      if (data.length > 0) setActivePage(data[0].pageId);
    },
  });

  const activePageQueryResult = useQuery({
    ...useFindPublicPageDetailQuery({
      pageId: activePage || "",
      teamSlug: teamSlug,
      apiKey: publicApiKey,
    }),
    select: (response) => {
      return FindDashboardPageClientModelFactory.fromPublicDashboardPageResponse(
        response
      );
    },
    enabled: !!activePage,
  });
  const activePageLoadable = useComposeLoadable(activePageQueryResult);

  // variable
  const useGetVariableOptionsLoadable = useMemo(() => {
    return createUsePublicVariableOptionsLoadable({
      teamSlug,
      apiKey: publicApiKey,
    });
  }, [teamSlug, publicApiKey]);

  // table view props
  const tableViewProps = useTableViewProps({
    teamSlug,
    apiKey: publicApiKey,
  });

  const handleRefresh = () => {
    notebookPagesQueryResult.refetch();
    activePageQueryResult.refetch();
  };

  // TODO: Spinner
  if (!notebookPagesQueryResult.data) return null;

  return (
    <CanvasPublicDashboardPresenter
      activePageLoadable={activePageLoadable}
      pages={notebookPagesQueryResult.data}
      setActivePageId={setActivePage}
      tableViewProps={tableViewProps}
      useVariableOptionsLoadable={useGetVariableOptionsLoadable}
      onRefresh={handleRefresh}
    />
  );
};

export { CanvasPublicDashboardContainer };
