import { notebookKeys } from "../keys";
import useApiNotebook from "~/api/useApiNotebook";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import { useQueryClient } from "react-query";
import { DashboardNotebookCellVariableRequestObject } from "@usemorph/morph-dashboard-types";

interface UseGetVariableViewResultOptionsQueryParams {
  teamSlug: string;
  notebookId: string;
  cellId: string;
  name: string;
  skip?: number;
  limit?: number;
  variables?: DashboardNotebookCellVariableRequestObject;
}

const useGetVariableViewResultOptionsQuery = ({
  teamSlug,
  notebookId,
  cellId,
  name,
  skip,
  limit,
  variables,
}: UseGetVariableViewResultOptionsQueryParams) => {
  const { _getVariableViewResultOptions } = useApiNotebook();

  const client = useQueryClient();
  client.setQueryDefaults(
    notebookKeys.usage({
      teamSlug,
    }),
    {
      ...commonQueryOptions,
      staleTime: 60 * 1000,
    }
  );

  return {
    queryKey: notebookKeys.variablesViewResultOptions({
      teamSlug,
      notebookId,
      cellId,
      name,
      skip,
      limit,
      variables,
    }),
    queryFn: () => {
      return _getVariableViewResultOptions({
        teamSlug,
        notebookId,
        cellId,
        name,
        skip,
        limit,
        variables,
      });
    },
  };
};

export { useGetVariableViewResultOptionsQuery };
