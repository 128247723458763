import { notebookKeys } from "../keys";
import useApiNotebook from "~/api/useApiNotebook";
import { useQueryClient } from "react-query";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import { viewKeys } from "~/serverStateStore/views";

interface UseDeleteNotebookMutationParams {
  teamSlug: string;
  databaseId: string;
}

const useDeleteNotebookMutaiton = ({
  databaseId,
  teamSlug,
}: UseDeleteNotebookMutationParams) => {
  const { _deleteNotebook } = useApiNotebook();

  const client = useQueryClient();
  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({ notebookId }: { notebookId: string }) => {
      return _deleteNotebook({
        teamSlug,
        notebookId,
      });
    },
    onSuccess: () => {
      const keys = notebookKeys.all({ databaseId, teamSlug });
      broadcast(keys);
      const listViewKeys = viewKeys.listViews({
        databaseId,
        teamSlug,
      });
      broadcast(listViewKeys);
      return invalidateAll(client, keys, listViewKeys);
    },
  };
};

export { useDeleteNotebookMutaiton };
