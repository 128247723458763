import { Flex } from "@radix-ui/themes";
import { CSSProperties } from "react";
import {
  BsCheckSquareFill,
  BsLayoutSidebarInsetReverse,
  BsSquare,
} from "react-icons/bs";
import { IconButton } from "~/components_next/IconButton";
import { styled } from "~/stitches";
import { useRecordsTableBaseOptions } from "../context/ReactTableBaseContext";
import { useBorderStyle } from "../hooks/useBorderStyle";
import { useHeaderStyle } from "./useHeaderStyle";

type RecordsTableSelectBoxProps = {
  isHeader?: boolean;
  isChecked?: boolean;
  isHilighted?: boolean;
  isActionsVisible?: boolean;
  onSelect?: () => void;
  onOpen?: () => void;
  style?: CSSProperties;
};

const StyledFlex = styled(Flex, {});

const RecordsTableSelectBox = (props: RecordsTableSelectBoxProps) => {
  const {
    isHeader,
    isChecked,
    isHilighted,
    isActionsVisible = true,
    onSelect,
    onOpen,
    style,
  } = props;

  // options
  const { isSelectable, isOpenable } = useRecordsTableBaseOptions();

  const { borderAttributes } = useBorderStyle();
  const { headerAttributes } = useHeaderStyle();

  if (!isSelectable && !isOpenable) {
    return null;
  }

  if (isHeader) {
    if (isOpenable) {
      return (
        <StyledFlex
          css={{
            width: "40px",
            height: "40px",
            ...borderAttributes,
            ...headerAttributes,
            ...style,
          }}
          align="center"
          justify="center"
        ></StyledFlex>
      );
    }
    return (
      <StyledFlex
        css={{
          width: "80px",
          height: "40px",
          ...borderAttributes,
          ...headerAttributes,
          ...style,
        }}
        align="center"
        justify="center"
      >
        <StyledFlex style={{ flex: 1 }} align="center" justify="center">
          <IconButton
            variant="default"
            size="sm"
            onClick={onSelect}
            icon={isChecked ? <BsCheckSquareFill /> : <BsSquare />}
            tooltip="Select All"
          />
        </StyledFlex>
        <StyledFlex style={{ flex: 1 }} align="center" justify="center" />
      </StyledFlex>
    );
  }

  if (isOpenable) {
    return (
      <StyledFlex
        css={{
          width: "40px",
          height: "40px",
          ...borderAttributes,
          ...style,
        }}
        align="center"
        justify="center"
      >
        {isActionsVisible && (
          <IconButton
            onClick={onOpen}
            size="sm"
            icon={<BsLayoutSidebarInsetReverse />}
            tooltip="Open this record"
          />
        )}
      </StyledFlex>
    );
  }

  return (
    <StyledFlex
      css={{
        width: "80px",
        height: "40px",
        ...borderAttributes,
        ...style,
        backgroundColor: isHilighted ? "$bg1" : undefined,
      }}
      align="center"
      justify="center"
    >
      <StyledFlex css={{ flex: 1 }} align="center" justify="center">
        {(isActionsVisible || isChecked) && (
          <IconButton
            size="sm"
            onClick={onSelect}
            icon={isChecked ? <BsCheckSquareFill /> : <BsSquare />}
            tooltip="Select this record"
          />
        )}
      </StyledFlex>
      <StyledFlex css={{ flex: 1 }} align="center" justify="center">
        {isActionsVisible && (
          <IconButton
            onClick={onOpen}
            size="sm"
            icon={<BsLayoutSidebarInsetReverse />}
            tooltip="Open this record"
          />
        )}
      </StyledFlex>
    </StyledFlex>
  );
};

export { RecordsTableSelectBox };
