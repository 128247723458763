import {
  ChatReplyRequestObject,
  DataApiAggregateRecordRequestBody,
  DataApiDeleteRecordRequestBody,
  DataApiQueryRecordRequestBody,
  DataApiUpdateRecordRequestBody,
  DataApiUploadFileRequestBody,
  DataApiCreateRequestBodyObject,
  DashboardRegisterDataApiResponse,
  DashboardRegisterDataApiRequestBody,
} from "@usemorph/morph-dashboard-types";
import { match } from "ts-pattern";

export const convertDataApiObject = (
  dataApiResponse: DashboardRegisterDataApiResponse
): DashboardRegisterDataApiRequestBody | undefined => {
  return match(dataApiResponse)
    .with({ apiType: "query" }, (value) => {
      const queryObject: DashboardRegisterDataApiRequestBody = {
        apiType: "query",
        apiKey: value.apiKey,
        apiName: value.apiName,
        query: value.body as DataApiQueryRecordRequestBody,
      };

      return queryObject;
    })
    .with({ apiType: "create" }, (value) => {
      const createObject: DashboardRegisterDataApiRequestBody = {
        apiType: "create",
        apiKey: value.apiKey,
        apiName: value.apiName,
        create: value.body as DataApiCreateRequestBodyObject,
      };

      return createObject;
    })
    .with({ apiType: "update" }, (value) => {
      const updateObject: DashboardRegisterDataApiRequestBody = {
        apiType: "update",
        apiKey: value.apiKey,
        apiName: value.apiName,
        update: value.body as DataApiUpdateRecordRequestBody,
      };
      return updateObject;
    })
    .with({ apiType: "delete" }, (value) => {
      const deleteObject: DashboardRegisterDataApiRequestBody = {
        apiType: "delete",
        apiKey: value.apiKey,
        apiName: value.apiName,
        delete: value.body as DataApiDeleteRecordRequestBody,
      };
      return deleteObject;
    })
    .with({ apiType: "upload" }, (value) => {
      const uploadObject: DashboardRegisterDataApiRequestBody = {
        apiType: "upload",
        apiKey: value.apiKey,
        apiName: value.apiName,
        upload: value.body as DataApiUploadFileRequestBody,
      };
      return uploadObject;
    })
    .with({ apiType: "download" }, (value) => {
      const downloadObject: DashboardRegisterDataApiRequestBody = {
        apiType: "download",
        apiKey: value.apiKey,
        apiName: value.apiName,
        download: value.body as DataApiQueryRecordRequestBody,
      };
      return downloadObject;
    })
    .with({ apiType: "aggregate" }, (value) => {
      const aggregateObject: DashboardRegisterDataApiRequestBody = {
        apiType: "aggregate",
        apiKey: value.apiKey,
        apiName: value.apiName,
        aggregate: value.body as DataApiAggregateRecordRequestBody,
      };
      return aggregateObject;
    })
    .with({ apiType: "chat" }, (value) => {
      const chatObject: DashboardRegisterDataApiRequestBody = {
        apiType: "chat",
        apiKey: value.apiKey,
        apiName: value.apiName,
        chat: value.body as ChatReplyRequestObject,
      };
      return chatObject;
    })
    .otherwise(() => undefined);
};
