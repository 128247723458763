import { DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Flex } from "@radix-ui/themes";
import {
  DashboardAllSimpleFieldListResponse,
  RecordConditionRuleUnit,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { useCallback } from "react";
import { BsPlus } from "react-icons/bs";
import { Button } from "~/components_next/Button";
import {
  SimpleDropdownMenu,
  DropdownMenu,
} from "~/components_next/DropdownMenu";
import {
  FlatFilterCondition,
  isFlatFilterConditionAnd,
} from "~/features/Fields/FilterCondition/flatFilterConditionTypes";
import { createId, isMatchIdAndSimpleField } from "./sortableUtils";
import { ViewConditionFieldSelectFieldCard } from "./ViewConditionFieldSelectFieldCard";

type ViewConditionFieldSelectPayload = {
  join: RecordConditionRuleUnit[];
  selectedFields: SimpleField[];
  selectedFieldsFromMainTable: SimpleField[];
};

type ViewConditionFieldSelectProps = {
  mainTableSlug: string;
  allFields: DashboardAllSimpleFieldListResponse["tables"];
  selectedFields: SimpleField[];
  join: RecordConditionRuleUnit[];
  filter: FlatFilterCondition | null;
  groupByField?: SimpleField | undefined;
  onChange: (payload: ViewConditionFieldSelectPayload) => void;
};

const ViewConditionFieldSelect = (props: ViewConditionFieldSelectProps) => {
  const {
    allFields,
    onChange,
    selectedFields,
    join,
    mainTableSlug,
    filter,
    groupByField,
  } = props;

  const handleAddMainField = (field: SimpleField) => {
    const newSelectedFields = [...selectedFields, field];
    onChange({
      join,
      selectedFields: newSelectedFields,
      selectedFieldsFromMainTable: newSelectedFields.filter(
        ({ originalTableSlug }) => originalTableSlug === mainTableSlug
      ),
    });
  };

  const handleAddJoin = (
    addedJoin: RecordConditionRuleUnit,
    fieldsToAdd: SimpleField[]
  ) => {
    const newSelectedFields = [...selectedFields, ...fieldsToAdd];

    onChange({
      join: [...(join || []), addedJoin],
      selectedFields: newSelectedFields,
      selectedFieldsFromMainTable: newSelectedFields.filter(
        ({ originalTableSlug }) => originalTableSlug === mainTableSlug
      ),
    });
  };

  const handleRemoveSelect = (field: SimpleField) => {
    const newSelectedFields = selectedFields.filter(
      ({ name, originalTableSlug }) => {
        if (field.primary === true) {
          // primary keyの場合はそのjoin先のフィールドを全て削除する
          return originalTableSlug !== field.originalTableSlug;
        } else {
          return (
            name !== field.name || originalTableSlug !== field.originalTableSlug
          );
        }
      }
    );

    // newSelectedFieldsにjoin先のtableのfieldが残っていない時は、そのjoinを削除する
    const isRemainedFieldFromTheJoinTable = newSelectedFields.some(
      ({ originalTableSlug }) => originalTableSlug === field.originalTableSlug
    );

    const newJoin = isRemainedFieldFromTheJoinTable
      ? join
      : join.filter((j) => j.targetTable !== field.originalTableSlug);

    onChange({
      join: newJoin,
      selectedFields: newSelectedFields,
      selectedFieldsFromMainTable: newSelectedFields.filter(
        ({ originalTableSlug }) => originalTableSlug === mainTableSlug
      ),
    });
  };

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        const oldIndex = selectedFields.findIndex((field) =>
          isMatchIdAndSimpleField(active.id, field)
        );
        const newIndex = selectedFields.findIndex((field) =>
          isMatchIdAndSimpleField(over.id, field)
        );
        const reorderedSelectedFields = arrayMove(
          [...selectedFields],
          oldIndex,
          newIndex
        );
        onChange({
          join,
          selectedFields: reorderedSelectedFields,
          selectedFieldsFromMainTable: reorderedSelectedFields.filter(
            ({ originalTableSlug }) => originalTableSlug === mainTableSlug
          ),
        });
      }
    },
    [join, mainTableSlug, onChange, selectedFields]
  );

  const mainTableFields =
    allFields.find(({ tableSlug }) => tableSlug === mainTableSlug)?.fields ??
    [];

  const unselectedMainTableFields = mainTableFields.filter(
    (field) =>
      !selectedFields.find(
        ({ name, originalTableSlug }) =>
          originalTableSlug === mainTableSlug && name === field.name
      )
  );

  const isUsedInFilter = useCallback(
    (field: SimpleField) => {
      if (!filter) return false;

      if (isFlatFilterConditionAnd(filter)) {
        return filter.and.some(({ key }) => key === field.name);
      } else {
        return filter.or.some(({ key }) => key === field.name);
      }
    },
    [filter]
  );

  const isUsedInGroupBy = useCallback(
    (field: SimpleField) => {
      return (
        groupByField?.name === field.name &&
        groupByField?.originalTableSlug === field.originalTableSlug
      );
    },
    [groupByField]
  );

  return (
    <>
      <Flex direction="column" mb="2" gap="2" align="start">
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext
            items={selectedFields.map((field) => ({
              id: createId(field),
              ...field,
            }))}
            strategy={verticalListSortingStrategy}
          >
            {/* <VStack spacing={0} gap={2.5} alignItems="start"> */}
            {selectedFields.flatMap((field) => {
              return [
                <ViewConditionFieldSelectFieldCard
                  key={`${field.originalTableSlug}.${field.name}`}
                  field={field}
                  allFields={allFields}
                  mainTableSlug={mainTableSlug}
                  currentJoin={join}
                  isUsedInFilter={isUsedInFilter(field)}
                  isUsedInGroupBy={isUsedInGroupBy(field)}
                  onAddJoin={handleAddJoin}
                  onRemove={() => handleRemoveSelect(field)}
                />,
              ];
            })}
          </SortableContext>
        </DndContext>
      </Flex>

      {unselectedMainTableFields.length > 0 && (
        <SimpleDropdownMenu
          trigger={
            <Button size="xs" variant="tertiary">
              <BsPlus />
              Select Field
            </Button>
          }
        >
          {unselectedMainTableFields.map((field) => (
            <DropdownMenu.Item
              key={field.name}
              onClick={() => handleAddMainField(field)}
            >
              {field.displayName || field.name}
            </DropdownMenu.Item>
          ))}
        </SimpleDropdownMenu>
      )}
    </>
  );
};

export { ViewConditionFieldSelect, type ViewConditionFieldSelectPayload };
