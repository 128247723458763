import { viewKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiView from "~/api/useApiView";

type UseListViewsQueryParams = {
  teamSlug: string;
  databaseId: string;
};

const useListViewsQuery = ({
  databaseId,
  teamSlug,
}: UseListViewsQueryParams) => {
  const { _listViews } = useApiView();

  const client = useQueryClient();
  client.setQueryDefaults(
    viewKeys.allListViews({ databaseId, teamSlug }),
    commonQueryOptions
  );

  return {
    queryKey: viewKeys.listViews({
      databaseId,
      teamSlug,
    }),
    queryFn: () => {
      return _listViews({
        databaseId,
        teamSlug,
      });
    },
  };
};

export { useListViewsQuery };
