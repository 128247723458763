import { type ForwardedRef, forwardRef, useMemo, useRef } from "react";
import { SelectContent } from "@radix-ui/themes";

import {
  SelectItem,
  SelectRoot,
  SelectRootProps,
  SelectTrigger,
} from "../Select/Select";
import { composeRefs } from "~/utilHooks/useComposeRefs";

type GetValueStringFromValue<ValueType> = (option: ValueType) => string;

// ValueTypeがstring | nullの場合は、getValueStringFromValueを省略できる
type GetValueStringFromValueProps<ValueType> = ValueType extends string
  ? { getValueStringFromValue?: GetValueStringFromValue<ValueType> }
  : { getValueStringFromValue: GetValueStringFromValue<ValueType> };

type OptionType<ValueType> = { label: string; value: ValueType };

type SimpleSelectProps<ValueType> = Pick<
  SelectRootProps,
  | "variant"
  | "size"
  | "description"
  | "label"
  | "errorMessages"
  | "isDisabled"
  | "isClearable"
  | "isReadOnly"
> & {
  value: ValueType | null;
  onChange?: (value: ValueType | null) => void;
  onOpenChange?: (open: boolean) => void;
  options: readonly OptionType<ValueType>[];
  noPortal?: boolean;
  placeholder?: string;
} & GetValueStringFromValueProps<ValueType>;

const _SimpleSelect = <ValueType,>(
  props: SimpleSelectProps<ValueType>,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const {
    variant,
    size,
    options,
    getValueStringFromValue,
    value,
    onChange,
    onOpenChange,
    label,
    description,
    placeholder,
    errorMessages,
    isDisabled = false,
    isReadOnly = false,
    isClearable = false,
    noPortal = false,
  } = props;

  const _getValueStringFromValue: (value: ValueType) => string = useMemo(() => {
    if (getValueStringFromValue) {
      return getValueStringFromValue;
    }
    return (value: ValueType) => value as string;
  }, [getValueStringFromValue]);

  const _getValueStringFromOption: (option: OptionType<ValueType>) => string =
    useMemo(() => {
      if (getValueStringFromValue) {
        return (option: OptionType<ValueType>) =>
          getValueStringFromValue(option.value);
      }
      return (option: OptionType<ValueType>) => option.value as string;
    }, [getValueStringFromValue]);

  const handleOnChange = (value: string | null) => {
    if (!onChange) return;

    if (value === null) {
      onChange(null);
      return;
    }

    const selectedOption = options.find(
      (option) => value === _getValueStringFromOption(option)
    ) as OptionType<ValueType>;
    onChange(selectedOption.value);
  };

  const localSelectRootRef = useRef<HTMLButtonElement>(null);
  const selectRootRef = composeRefs(ref, localSelectRootRef);

  return (
    <SelectRoot
      ref={selectRootRef}
      value={value != null ? _getValueStringFromValue(value) : null}
      label={label}
      description={description}
      errorMessages={errorMessages}
      variant={variant}
      size={size}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      onChange={handleOnChange}
      onOpenChange={onOpenChange}
    >
      <SelectTrigger placeholder={placeholder} />
      <SelectContent
        container={noPortal ? localSelectRootRef.current : undefined}
      >
        {options.map((option) => (
          <SelectItem
            key={_getValueStringFromOption(option)}
            value={_getValueStringFromOption(option)}
          >
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </SelectRoot>
  );
};

// ここのキャストは仕方がない
// ref
const SimpleSelect = forwardRef(_SimpleSelect) as <ValueType>(
  props: SimpleSelectProps<ValueType> & {
    ref?: ForwardedRef<HTMLButtonElement>;
  }
) => JSX.Element;

export { SimpleSelect, type SimpleSelectProps };
