import { useQueryClient } from "react-query";
import { resourceMembersKeys } from "../keys";

// types
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { ResourceNotebookSchema, ResourcePermissionType, ResourceTableSchema, ResourceViewSchema } from "@usemorph/morph-dashboard-types";
import useApiResourceMembers from "~/api/useApiResourceMember";

interface UseCreateResourceMemberParams {
  teamSlug: string;
  resource: ResourceTableSchema | ResourceNotebookSchema | ResourceViewSchema;
}

const useCreateResourceMemberMutation = ({
  teamSlug,
  resource,
}: UseCreateResourceMemberParams) => {
  const { _createResourceMember } = useApiResourceMembers();
  const client = useQueryClient();

  return {
    mutationFn: ({ permission, userIds }: { permission: ResourcePermissionType, userIds: string[] }) => {
      return _createResourceMember({
        teamSlug,
        resource,
        permission,
        userIds,
      });
    },
    onSuccess: () => {
      return invalidateAll(
        client,
        resourceMembersKeys.listResourceMembers({
          teamSlug,
          resource,
          resourceType: resource.resourceType,
        })
      );
    },
  };
};

export { useCreateResourceMemberMutation };
