import {
  DashboardPlanListResponse,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

export default function useApiPlan() {
  const { executeRequest } = useApi();

  const _listPlan = async ({
    teamSlug,
  }: {
    teamSlug: string;
  }): Promise<DashboardPlanListResponse> => {
    return executeRequest<DashboardPlanListResponse>(
      "get",
      `/plan`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  return {
    _listPlan,
  };
}
