import { useCallback, useMemo, useState } from "react";
import { RecordsTableBaseProps } from "../RecordsTableBase";
import constate from "constate";
import {
  CellSelectionState,
  EditingCellState,
  RecordsTableBaseRecord,
  RecordsTableColumnSizingState,
} from "../types";
import { useHandleCellClick } from "./useHandleCellClick";
import { useHandleFieldClick } from "./useHandleFieldClick";
import { getDefaultColumnSizings } from "../hooks/getDefaultColumnSizings";
import { emptySelectionState } from "../util/selectionStateUtil";
import { isCellSelectedByCoworker } from "../util/coworkerStateUtil";

type RecordsTableBaseContextProps = RecordsTableBaseProps;

const useRecordsTableBaseContext = (props: RecordsTableBaseContextProps) => {
  const {
    editableFields,
    fields,
    records,
    columnSizing,
    onColumnSizingChange,
    onUpdateRecord,
    selectionState,
    onSelectionChange,
    coworkerStates,
  } = props;

  /**
   * Fields
   */
  // const allFields = useMemo(() => {
  //   return _fields;
  // }, [_fields]);
  // const fields = useMemo(() => {
  //   return allFields.filter((field) => !field.isHidden);
  // }, [allFields]);

  /**
   * Column sizings
   * プレビュー用の便利のために、columnSizingとonColumnSizingChangeをoptionalにする代わりに
   * フォールバックをContextで吸収する
   */
  const [localColumnSizing, setLocalColumnSizing] =
    useState<RecordsTableColumnSizingState>({});
  const { defaultColumnSizings } = getDefaultColumnSizings(fields);
  const _columnSizing = useMemo(() => {
    if (columnSizing) {
      return {
        ...defaultColumnSizings,
        ...columnSizing,
      };
    }
    return {
      ...defaultColumnSizings,
      ...localColumnSizing,
    };
  }, [columnSizing, defaultColumnSizings, localColumnSizing]);

  const _onColumnSizingChange = useCallback(
    (columnSizing: RecordsTableColumnSizingState) => {
      onColumnSizingChange?.(columnSizing);
      setLocalColumnSizing(columnSizing);
    },
    [onColumnSizingChange]
  );

  /**
   *
   * Selection
   *
   */
  /**
   * Editing Cell
   */
  const editingCell = useMemo(() => {
    return selectionState?.editingCell || null;
  }, [selectionState?.editingCell]);

  // cell editing event
  const handleSetEditingCell = (cell: EditingCellState | null) => {
    if (selectionState && onSelectionChange && cell && coworkerStates) {
      // check if the field is editable
      const isEditableField =
        editableFields === "all" ||
        (editableFields && editableFields.includes(cell.fieldSlug));
      if (!isEditableField) return;

      // check if cell is contained in cowoker state
      const isLockedWithCoworker = isCellSelectedByCoworker(
        cell.fieldSlug,
        cell.record,
        coworkerStates
      );
      if (isLockedWithCoworker) return;
      onSelectionChange({
        ...selectionState,
        editingCell: cell,
      });
    }
  };

  const endCellEditing = useCallback(() => {
    if (selectionState && onSelectionChange) {
      onSelectionChange({
        ...selectionState,
        editingCell: null,
      });
    }
  }, [selectionState, onSelectionChange]);

  /**
   * Cell Selection
   */
  const handleCellSelectionChange = useCallback(
    (cellSelection: CellSelectionState) => {
      onSelectionChange?.({
        ...emptySelectionState,
        cellSelection,
      });
    },
    [onSelectionChange]
  );

  /**
   * Field Selection
   */
  const fieldSelections = useMemo(() => {
    return selectionState?.fieldSelection || [];
  }, [selectionState?.fieldSelection]);

  const onFieldSelectionChange = useCallback(
    (fieldSelection: string[]) => {
      onSelectionChange?.({
        ...emptySelectionState,
        fieldSelection,
      });
    },
    [onSelectionChange]
  );

  /**
   * Record Selection
   */
  const recordSelection = useMemo(() => {
    return selectionState?.recordSelection || [];
  }, [selectionState?.recordSelection]);

  const onRecordSelectionChange = useCallback(
    (records: RecordsTableBaseRecord[]) => {
      onSelectionChange?.({
        ...emptySelectionState,
        recordSelection: records,
      });
    },
    [onSelectionChange]
  );

  /**
   * Record Hilight
   */
  const recordHilights = useMemo(() => {
    return selectionState?.recordHilights || [];
  }, [selectionState?.recordHilights]);

  /**
   * Clear All Selection
   */
  const clearAllSelection = useCallback(() => {
    onSelectionChange?.({
      ...emptySelectionState,
    });
  }, [onSelectionChange]);

  /**
   *
   * Event Callback
   *
   */
  // turn cell click event into selection state
  const { handleCellClickEvent } = useHandleCellClick({
    cellSelection: selectionState?.cellSelection || [],
    handleCellSelectionChange,
    setEditingCell: handleSetEditingCell,
    fields,
    records,
  });

  // turn field click event into selection state
  const { handleFieldClickEvent } = useHandleFieldClick({
    fieldSelection: selectionState?.fieldSelection || [],
    onFieldSelectionChange,
    fields,
  });

  /**
   *
   * UI
   *
   */

  const contextValue = useMemo(() => {
    return {
      ...props,
      // col sizing
      columnSizing: _columnSizing,
      onColumnSizingChange: _onColumnSizingChange,
      // state
      clearAllSelection,
      // cell selection
      cellSelection: selectionState?.cellSelection || [],
      onCellSelectionChange: handleCellSelectionChange,
      // editing cell
      editingCell,
      endCellEditing,
      // field selection
      fieldSelections,
      onFieldSelectionChange,
      // record selection
      recordSelection,
      onRecordSelectionChange,
      // record hilight
      recordHilights,
      // event callback
      handleCellClickEvent,
      handleFieldClickEvent,
      onUpdateRecord,
    };
  }, [
    _columnSizing,
    _onColumnSizingChange,
    clearAllSelection,
    editingCell,
    endCellEditing,
    fieldSelections,
    handleCellClickEvent,
    handleCellSelectionChange,
    handleFieldClickEvent,
    onFieldSelectionChange,
    onRecordSelectionChange,
    onUpdateRecord,
    props,
    recordSelection,
    recordHilights,
    selectionState,
  ]);

  return { ...contextValue };
};

export const [
  RecordsTableBaseProvider,
  // options
  useRecordsTableBaseOptions,
  // data
  useRecordsTableBaseRecords,
  useRecordsTableBaseFields,
  // states
  useRecordsTableBaseClearAllSelection,
  //   column sizing
  useRecordsTableBaseColumnSizing,
  useRecordsTableBaseOnColumnSizingChange,
  //    cell selection
  useRecordsTableBaseCellSelection,
  useRecordsTableBaseOnCellSelectionChange,
  //    editing selection
  useRecordsTableBaseEditingCell,
  useRecordsTableBaseEndCellEditing,
  //    field selection
  useRecordsTableBaseFieldSelection,
  useRecordsTableBaseOnFieldSelectionChange,
  //    records selection
  useRecordsTableBaseRecordSelection,
  useRecordsTableBaseOnRecordSelectionChange,
  // recird hilight
  useRecordsTableBaseRecordHilights,
  //    coworker state
  useRecordsTableBaseCoworkerStates,
  // events
  useRecordsTableBaseOnRecordClick,
  useRecordsTableBaseOnAddFieldClick,
  useRecordsTableBaseOnReorderFields,
  useRecordsTableBaseHandleCellEvent,
  useRecordsTableBaseHandleFieldEvent,
  useRecordsTableBaseOnUpdateRecord,
  // ui
  useRecordsTableBaseHeaderDropdown,
  useRecordsTableBaseHeaderPopover,
] = constate(
  useRecordsTableBaseContext,
  // options
  (value) => ({
    isSelectable: value.isSelectable,
    isOpenable: value.isOpenable,
    isReorderable: value.isReorderable,
    isInfiniteScroll: value.isInfiniteScroll,
    editableFields: value.editableFields,
    hasPadding: value.hasPadding !== false,
    propagateEvent: value.propagateEvent || false,
  }),
  // data
  (value) => value.records,
  (value) => value.fields,
  // states
  (value) => value.clearAllSelection,
  //   column sizing
  (value) => value.columnSizing,
  (value) => value.onColumnSizingChange,
  //    cell selection
  (value) => value.cellSelection,
  (value) => value.onCellSelectionChange,
  //    editing selection
  (value) => value.editingCell,
  (value) => value.endCellEditing,
  //    field selection
  (value) => value.fieldSelections,
  (value) => value.onFieldSelectionChange,
  //    records selection
  (value) => value.recordSelection,
  (value) => value.onRecordSelectionChange,
  // recird hilight
  (value) => value.recordHilights,
  //    coworker state
  (value) => value.coworkerStates,
  // events
  (value) => value.onRecordClick,
  (value) => value.onAddFieldClick,
  (value) => value.onReorderFields,
  (value) => value.handleCellClickEvent,
  (value) => value.handleFieldClickEvent,
  (value) => value.onUpdateRecord,
  // ui
  (value) => value.headerDropdown,
  (value) => value.headerPopover
);
