import { useMemo } from "react";
import { useQuery } from "react-query";
import { Flex } from "~/components_next/Flex";
import { Spinner } from "~/components_next/Spinner";
import { useSortedTables } from "~/serverStateStore/tables";
import { useListViewsQuery } from "~/serverStateStore/views";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { SidebarViewLinkItem } from "../LinkItems/SidebarViewLinkItem";

interface ViewSelectProps {
  isPrivate: boolean;
}

const ViewSelect = (props: ViewSelectProps) => {
  const { isPrivate } = props;

  const databaseId = useDatabaseId();
  const teamSlug = useTeamSlug();

  const { queryResult } = useSortedTables({ teamSlug, databaseId });

  const { data: viewsData, status: viewsStatus } = useQuery({
    ...useListViewsQuery({
      databaseId,
      teamSlug,
    }),
  });

  if (viewsStatus === "error") {
    throw new Error("views & pages not found");
  }

  const views = useMemo(() => {
    // todo: viewの権限管理(サーバー対応次第)
    if (viewsData) {
      if (isPrivate) {
        return viewsData.items.filter((view) => view.isPrivate);
      } else {
        return viewsData.items.filter((view) => !view.isPrivate);
      }
    }
    return [];
  }, [viewsData, isPrivate]);

  return (
    <>
      {queryResult.status === "success" ? (
        // queryResult.data.tables.map((table) => (
        views.map((view) => (
          <SidebarViewLinkItem
            key={view.viewId}
            databaseId={databaseId}
            teamSlug={teamSlug}
            view={view}
          />
        ))
      ) : (
        <Flex height="100%" justify="center" align="center">
          <Spinner size="20" />
        </Flex>
      )}
    </>
  );
};

export { ViewSelect };
