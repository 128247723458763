import useApiTable from "~/api/useApiTable";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { tablesKeys } from "../keys";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseDuplicateTableMutationParams {
  databaseId: string;
  teamSlug: string;
}

const useDuplicateTableMutation = ({
  databaseId,
  teamSlug,
}: UseDuplicateTableMutationParams) => {
  const { _duplicateTable } = useApiTable();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      targetTableSlug,
      tableSlug,
      displayName,
      withRecords,
      isPrivate,
      comment,
    }: {
      targetTableSlug: string;
      tableSlug: string;
      displayName: string;
      withRecords: boolean;
      isPrivate?: boolean;
      comment?: string;
    }) => {
      return _duplicateTable({
        teamSlug,
        databaseId,
        targetTableSlug,
        tableSlug,
        displayName,
        withRecords,
        isPrivate,
        comment,
      });
    },
    onSuccess: () => {
      const keys = tablesKeys.all({ databaseId, teamSlug });
      broadcast(keys);
      return invalidateAll(client, keys);
    },
  };
};

export { useDuplicateTableMutation };
