import { match } from "ts-pattern";
import { ImportStatusClientModel } from "~/clientModel/importStatus";
import { Badge } from "~/components_next/Badge";
import { Flex } from "~/components_next/Flex";
import { Text } from "~/components_next/Text";

type ImportStatusItemProps = {
  importStatus: ImportStatusClientModel;
};

const StatusBadge = (props: {
  status: ImportStatusClientModel["statusLabel"];
}) => {
  const { status } = props;

  return match(status)
    .with("imported", () => (
      <Badge size="xs" variant="tertiary" color="green">
        Success
      </Badge>
    ))
    .with("failed", () => (
      <Badge size="xs" variant="tertiary" color="red">
        Failed
      </Badge>
    ))
    .with("in progress", () => (
      <Badge size="xs" variant="tertiary" color="yellow">
        In progress
      </Badge>
    ))
    .with("in preparation", () => (
      <Badge size="xs" variant="tertiary" color="blue">
        In preparation
      </Badge>
    ))
    .exhaustive();
};

const ImportStatusItem = (props: ImportStatusItemProps) => {
  const { importStatus } = props;

  return (
    <Flex direction="column" gap="1" p="1">
      <Flex align="center" gap="2">
        <Text variant="tinyHeading">{importStatus.title}</Text>
        <StatusBadge status={importStatus.statusLabel} />
      </Flex>
      <Text variant="tinyDescription">{importStatus.message}</Text>
    </Flex>
  );
};

export { ImportStatusItem, type ImportStatusItemProps };
