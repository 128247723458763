import { DashboardNotebookDetailObject } from "@usemorph/morph-dashboard-types";
import constate from "constate";

type NotebookMetaContextProps = {
  notebookId: DashboardNotebookDetailObject["notebookId"];
  notebookName: DashboardNotebookDetailObject["notebookName"];
  pages: DashboardNotebookDetailObject["pages"];
  isPrivate: DashboardNotebookDetailObject["isPrivate"];
};

const useNotebookMetaContext = (props: NotebookMetaContextProps) => {
  const { notebookId, notebookName, pages, isPrivate } = props;

  return {
    notebookId,
    notebookName,
    pages,
    isPrivate,
  };
};

export const [
  NotebookMetaProvider,
  useNotebookName,
  useNotebookPages,
  useNotebookIsPrivate,
] = constate(
  useNotebookMetaContext,
  (Value) => Value.notebookName,
  (value) => value.pages,
  (value) => value.isPrivate
);
