import { DashboardComputedFieldOptionListResponse } from "@usemorph/morph-dashboard-types";
import { AggregateValueFieldOperatorClientModelFactory } from "./aggregateValueFieldOperator/AggregateValueFieldOperatorClientModelFactory";
import { AggregateValueFieldOperatorsClientModel } from "./AggregateValueFieldOperatorsClientModel";

export class AggregateValueFieldOperatorsClientModelFactory {
  public static createFromDashboardComputedFieldOptionListResponse(
    computedFieldOptions: DashboardComputedFieldOptionListResponse
  ): AggregateValueFieldOperatorsClientModel {
    return new AggregateValueFieldOperatorsClientModel({
      operators: computedFieldOptions.items.map((item) =>
        AggregateValueFieldOperatorClientModelFactory.createFromDashboardComputedFieldOptionListResponseItem(
          item
        )
      ),
    });
  }
}
