import { keyframes } from "@stitches/react";
import { styled } from "~/stitches";
import { match } from "ts-pattern";
import { MorphThemeSize } from "../commonProps.type";
import { FaSpinner } from "react-icons/fa";
import { IconBaseProps } from "react-icons/lib";

type SpinnerProps = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  size?: MorphThemeSize | number | (string & {});
} & Omit<IconBaseProps, "size">;

const getSize = (size?: MorphThemeSize | number | string) => {
  return match(size)
    .with("xs", () => "18" as SpinnerProps["size"])
    .with("sm", () => "22" as SpinnerProps["size"])
    .with("md", () => "24" as SpinnerProps["size"])
    .with("lg", () => "26" as SpinnerProps["size"])
    .with("xl", () => "28" as SpinnerProps["size"])
    .otherwise(() => size);
  // .otherwise(() => "3" as RadixThemeButtonProps["size"]);
};

const animate = keyframes({
  "0%": {
    transform: "rotate(0deg)",
  },
  "100%": {
    transform: "rotate(720deg)",
  },
});

const StyledSpinner = styled(FaSpinner, {
  color: "$slate6",
  animation: `${animate} 2s infinite`,
});

const Spinner = (props: SpinnerProps) => {
  const spinnerSize = getSize(props.size);

  return <StyledSpinner {...props} size={spinnerSize} />;
};

export { Spinner };
