
import { BsPlusLg } from "react-icons/bs";

import { IconButton } from "~/components_next/IconButton";
import { Text } from "~/components_next/Text";
import { Image } from "~/components_next/Image";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
import { DropdownMenu, SimpleDropdownMenu } from "~/components_next/DropdownMenu";

export const TableAddPopover = ({
  isPrivate,
  onClickAddTable,
}: {
  isPrivate: boolean;
  onClickAddTable?: (type: "blank" | "csv" | "otherService", isPrivate: boolean) => void;
}) => {
  return (
    <>
      <SimpleDropdownMenu
        trigger={
          <IconButton
            icon={<BsPlusLg color="gray" />}
            tooltip="Create New Source"
            size="sm"
          />
        }
      >
        <Box css={{ width: "650px" }}>
          <DropdownMenu.Item style={{ height: "auto" }}>
            <Flex
              onClick={() => onClickAddTable && onClickAddTable("blank", isPrivate)}
              align="center"
              width={"100%"}
              p="4"
              css={{
                height: "80px",
                borderRadius: "md",
                fontWeight: "normal",
                fontSize: "sm",
                cursor: "pointer",
              }}
            >
              <Box>
                <Box>
                  <Text variant="default" fontWeight="bold">Create Blank Source</Text>
                </Box>
                <Box>
                  <Text variant="description">Create new source from scratch.</Text>
                </Box>
              </Box>
            </Flex>
          </DropdownMenu.Item>

          <DropdownMenu.Item style={{ height: "auto" }}>
            <Flex
              onClick={() => onClickAddTable && onClickAddTable("csv", isPrivate)}
              width={"100%"}
              p="4"
              justify="start"
              css={{
                height: "80px",
                borderRadius: "md",
                fontWeight: "normal",
                fontSize: "sm",
                cursor: "pointer",
              }}
            >
              <Flex justify="between" align="center" width="100%">
                <Box>
                  <Box>
                    <Text variant="default" fontWeight="bold">Import CSV / Excel file</Text>
                  </Box>
                  <Box>
                    <Text variant="description">
                      Create a source automatically from your CSV / EXcel file.
                    </Text>
                  </Box>
                </Box>
                <Flex >
                  <Box mr="4">
                    <Image height="38px" width="38px" src="/excel_logo.png" />
                  </Box>
                  <Image height="38px" width="38px" src="/google_sheet_logo.png" />
                </Flex>
              </Flex>
            </Flex>
          </DropdownMenu.Item>

          <DropdownMenu.Item style={{ height: "auto" }}>
            <Flex
              onClick={() => onClickAddTable && onClickAddTable("otherService", isPrivate)}
              width={"100%"}
              p="4"
              css={{
                height: "80px",
                borderRadius: "md",
                fontWeight: "normal",
                fontSize: "sm",
                cursor: "pointer",
              }}
            >
              <Flex justify="between" align="center" width="100%">
                <Box>
                  <Box>
                    <Text variant="default" fontWeight="bold">Import data from other service</Text>
                  </Box>
                  <Box>
                    <Text variant="description">
                      Create a source on Morph with a one-time data import.
                    </Text>
                  </Box>
                </Box>
                <Flex>
                  <Box mr="4">
                    <Image height="38px" width="38px" src="/notion-logo.png" />
                  </Box>
                  <Image height="38px" width="135px" src="/airtable_logo.png" />
                </Flex>
              </Flex>
            </Flex>
          </DropdownMenu.Item>

        </Box>
      </SimpleDropdownMenu>
    </>
  );
};
