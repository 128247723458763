import { useMutation } from "react-query";
import { CsvImportSchemaClientModelFactory } from "~/clientModel/csvImport";
import { useComposeExecutable } from "~/clientModel/executable";
import { CsvImportBucketClientModel } from "~/clientModel/storage/bucket";
import { StorageObjectClientModel } from "~/clientModel/storage/object";
import { useCreateImportDataSchemaFromExcelCsvMutation } from "~/serverStateStore";

export const useCreateImportSchema = ({
  storageObjectInstance,
  bucketInstance,
  teamSlug,
  databaseId,
  tableSlug,
}: {
  storageObjectInstance?: StorageObjectClientModel;
  bucketInstance?: CsvImportBucketClientModel;
  teamSlug: string;
  databaseId: string;
  tableSlug?: string;
}) => {
  const createImportSchemaOptions =
    useCreateImportDataSchemaFromExcelCsvMutation({ teamSlug, databaseId });

  const createImportSchemaResult = useMutation({
    ...createImportSchemaOptions,
    mutationFn: async ({ sheet }: { sheet?: number | undefined }) => {
      if (!bucketInstance) {
        throw new Error("Bucket is not loaded");
      }

      if (!storageObjectInstance) {
        throw new Error("Storage object is not loaded");
      }

      if (!storageObjectInstance.objectId) {
        throw new Error("Object id is not set");
      }

      const createResponse = await createImportSchemaOptions.mutationFn({
        sheet,
        storageBucketId: bucketInstance.bucketId,
        storageObjectId: storageObjectInstance.objectId,
        fileType: storageObjectInstance.isExel ? "excel" : "csv",
        hasHeader: true,
        tableSlug,
      });

      return CsvImportSchemaClientModelFactory.fromImportDataResponse(
        createResponse
      );
    },
  });

  const createImportSchemaExecutable = useComposeExecutable(
    createImportSchemaResult
  );

  return {
    createImportSchemaExecutable,
    reset: createImportSchemaResult.reset,
  };
};
