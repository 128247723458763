import { Link, useNavigate } from "react-router-dom";
import { Button } from "~/components_next/Button";
import { Flex } from "~/components_next/Flex";
import { Image } from "~/components_next/Image";
import { Text } from "~/components_next/Text";

export const SessionExpiredPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Flex justify="center" align="center" mb="4" mt="8">
        <Link to="/">
          <Image height="40px" src="/MorphLogo.png" />
        </Link>
      </Flex>
      <Flex justify="center" align="center">
        <Text fontWeight="bold" mb="4">
          Session Expired
        </Text>
      </Flex>
      <Flex justify="center" align="center">
        <Button variant="primary" onClick={() => navigate("/")}>Back to top</Button>
      </Flex>
    </>
  );
};
