import { useMutation } from "react-query";
import { match, P } from "ts-pattern";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import { FieldClientModel } from "~/clientModel/fields/field";
import {
  FilterConditionsClientModel,
  FilterConditionsClientModelDefactory,
} from "~/clientModel/queryConditions/filterConditions";
import { RecordIdentifierClientModelDefactory } from "~/clientModel/records/record/recordIdentifier";
import { TableSelectionClientModel } from "~/clientModel/tables/tableMeta/TableSelection";

import { useErrorToast } from "~/components_next/Error";
import { useBulkUpdateRecordByFormulaMutation } from "~/serverStateStore";

type UseBulkEditRecordsByFormulaExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  filterConditions: FilterConditionsClientModel;
  tableSelection: TableSelectionClientModel;
};

const useBulkEditRecordsByFormulaExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
  filterConditions,
  tableSelection,
}: UseBulkEditRecordsByFormulaExecutableParams): Executable<
  { formula: string; field: FieldClientModel },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  const bulkUpdateRecordsByFormulaOptions =
    useBulkUpdateRecordByFormulaMutation({
      teamSlug,
      databaseId,
      tableSlug,
    });

  return useComposeExecutable(
    useMutation({
      ...bulkUpdateRecordsByFormulaOptions,
      mutationFn: ({
        formula,
        field,
      }: {
        formula: string;
        field: FieldClientModel;
      }) => {
        const filter = match([
          filterConditions.hasValidFilterConditions,
          !!tableSelection.selectedRecordsIdentifiers.length,
        ])
          .with([false, false], () => ({ or: [] }))
          .with([true, false], () =>
            FilterConditionsClientModelDefactory.toRecordFilterCondition(
              filterConditions
            )
          )
          .with([P._, true], () => ({
            or: tableSelection.selectedRecordsIdentifiers.map((recordId) =>
              RecordIdentifierClientModelDefactory.toRecordFilterCondition(
                recordId
              )
            ),
          }))
          .exhaustive();

        return bulkUpdateRecordsByFormulaOptions.mutationFn({
          formula,
          filter,
          fieldName: field.data.name,
        });
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseBulkEditRecordsByFormulaExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
  filterConditions,
  tableSelection,
}: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  filterConditions: FilterConditionsClientModel;
  tableSelection: TableSelectionClientModel;
}) => {
  return () =>
    useBulkEditRecordsByFormulaExecutable({
      teamSlug,
      databaseId,
      tableSlug,
      filterConditions,
      tableSelection,
    });
};
