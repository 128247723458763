import { forwardRef } from "react";
import { BsX } from "react-icons/bs";
import { Button } from "~/components_next/Button";

type ClearSQLButtonProps = {
  onClick: () => void;
};

const ClearSQLButton = forwardRef<HTMLButtonElement, ClearSQLButtonProps>(
  (props, ref) => {
    const { onClick } = props;

    return (
      <Button ref={ref} variant="ghost" size="xs" onClick={onClick}>
        <BsX />
        Clear SQL
      </Button>
    );
  }
);

ClearSQLButton.displayName = "ClearSQLButton";

export { ClearSQLButton };
