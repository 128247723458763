import {
  DashboardViewResponse,
  QueryRecordListResponse,
  QueryRecordWithFieldsResponse,
  QueryViewRecordRequestBody,
  QueryViewRecordWithSqlRequestBody,
  SimpleFieldListResponse,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";
import { transformNumberToStringForQueryRecordListResponse } from "./utils/transformers";

export default function useApiPublicView() {
  const { executeRequestAxiosRaw } = useApi();

  const _getPublicView = async ({
    teamSlug,
    viewId,
    publicApiKey,
  }: {
    teamSlug: string;
    viewId: string;
    publicApiKey: string;
  }): Promise<DashboardViewResponse> => {
    const { data } = await executeRequestAxiosRaw<DashboardViewResponse>(
      "GET",
      `/public/view/${viewId}`,
      undefined,
      {
        teamSlug,
        "x-api-key": publicApiKey,
      }
    );
    return data;
  };

  const _getPublicViewFields = async ({
    teamSlug,
    viewId,
    publicApiKey,
  }: {
    teamSlug: string;
    viewId: string;
    publicApiKey: string;
  }): Promise<SimpleFieldListResponse> => {
    const { data } = await executeRequestAxiosRaw<SimpleFieldListResponse>(
      "GET",
      `/public/view/${viewId}/field`,
      undefined,
      {
        teamSlug,
        "x-api-key": publicApiKey,
      }
    );
    return data;
  };

  const _queryPublicViewRecords = async ({
    teamSlug,
    viewId,
    publicApiKey,
    body,
  }: {
    teamSlug: string;
    viewId: string;
    publicApiKey: string;
    body: QueryViewRecordRequestBody;
  }): Promise<Required<QueryRecordListResponse>> => {
    const { data } = await executeRequestAxiosRaw<
      Required<QueryRecordListResponse>
    >(
      "POST",
      `/public/view/${viewId}/query`,
      undefined,
      {
        teamSlug,
        "x-api-key": publicApiKey,
      },
      body,
      {
        transformResponse: [transformNumberToStringForQueryRecordListResponse],
      }
    );
    return data;
  };

  const _queryPublicViewRecordsWithSQL = async ({
    teamSlug,
    viewId,
    publicApiKey,
    body,
  }: {
    teamSlug: string;
    viewId: string;
    publicApiKey: string;
    body: QueryViewRecordWithSqlRequestBody;
  }): Promise<QueryRecordWithFieldsResponse> => {
    const { data } =
      await executeRequestAxiosRaw<QueryRecordWithFieldsResponse>(
        "POST",
        `/public/view/${viewId}/query/sql`,
        undefined,
        {
          teamSlug,
          "x-api-key": publicApiKey,
        },
        body
      );
    return data;
  };

  const _donwloadPublicViewRecords = async ({
    teamSlug,
    viewId,
    publicApiKey,
    body,
  }: {
    teamSlug: string;
    viewId: string;
    publicApiKey: string;
    body: QueryViewRecordRequestBody;
  }): Promise<{ url: string }> => {
    const { data } = await executeRequestAxiosRaw<{ url: string }>(
      "POST",
      `/public/view/${viewId}/query/csv`,
      undefined,
      { teamSlug, "x-api-key": publicApiKey },
      body
    );

    return data;
  };

  return {
    _getPublicView,
    _getPublicViewFields,
    _queryPublicViewRecords,
    _queryPublicViewRecordsWithSQL,
    _donwloadPublicViewRecords,
  };
}
