import { pageKeys } from "../keys";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import { useQueryClient } from "react-query";
import useApiPage from "~/api/useApiPage";

interface UseFindPageDetailQueryParams {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
  pageId: string;
}

const useFindPageDetailQuery = ({
  databaseId,
  teamSlug,
  notebookId,
  pageId,
}: UseFindPageDetailQueryParams) => {
  const { _findPageDetail } = useApiPage();

  const client = useQueryClient();
  client.setQueryDefaults(
    pageKeys.findPageDetail({
      teamSlug,
      databaseId,
      notebookId,
      pageId,
    }),
    commonQueryOptions
  );

  return {
    queryKey: pageKeys.findPageDetail({
      databaseId,
      teamSlug,
      notebookId,
      pageId,
    }),
    queryFn: () => {
      return _findPageDetail({
        teamSlug,
        pageId,
      });
    },
  };
};

export { useFindPageDetailQuery };
