import constate from "constate";
import { useCallback, useMemo, useState } from "react";

type SidebarType = "createRecord" | "editRecord";

const useKanbanViewSidebarContext = () => {
  const [openingSidebarType, setOpeningSidebarType] =
    useState<SidebarType | null>(null);

  const onClose = () => setOpeningSidebarType(null);

  return {
    openingSidebarType,
    setOpeningSidebarType,
    onClose,
  };
};

const useSidebarDisclosure = (SidebarType: SidebarType) => {
  const openingSidebarType = useOpeningSidebarType();
  const setOpeningSidebarType = useSetOpeningSidebarType();

  const isOpen = useMemo(
    () => openingSidebarType === SidebarType,
    [openingSidebarType, SidebarType]
  );

  const onOpen = useCallback(
    () => setOpeningSidebarType(SidebarType),
    [setOpeningSidebarType, SidebarType]
  );

  const onClose = useCallback(
    () => setOpeningSidebarType(null),
    [setOpeningSidebarType]
  );

  return {
    isOpen,
    onOpen,
    onClose,
  };
};

const [
  KanbanViewSidebarContextProvider,
  useOpeningSidebarType,
  useSetOpeningSidebarType,
  useSidebarOnClose,
] = constate(
  useKanbanViewSidebarContext,
  (value) => value.openingSidebarType,
  (value) => value.setOpeningSidebarType,
  (value) => value.onClose
);

export {
  // provider
  KanbanViewSidebarContextProvider,

  // sidebar
  useOpeningSidebarType,
  useSetOpeningSidebarType,
  useSidebarDisclosure,
  useSidebarOnClose,
};
