import { TableClientModel } from "~/clientModel/tables/table";
import { Text } from "~/components_next/Text";

type MergeKeyInputTableLabelProps = {
  table: TableClientModel;
};

export const MergeKeyInputTableLabel = (
  props: MergeKeyInputTableLabelProps
) => {
  const { table } = props;
  return <Text variant="tinyDescription">{table.label}</Text>;
};
