import { match, P } from "ts-pattern";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { Loadable } from "~/clientModel/loadable";
import { RecordsClientModel } from "~/clientModel/records";
import { SourceQueryConditions } from "~/presenters/sourceAndViews";
import { useSourceRecordsAndFieldsWithQueryLoadable } from "./useSourceRecordsAndFieldsWithQueryLoadable";
import { useSourceRecordsAndFieldsWithSQLLoadable } from "./useSourceRecordsAndFieldsWithSQLLoadable";

type UseSourceRecordsAndFieldsLoadableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  queryMode: "query" | "sql" | "default" | "prompt";
  queryConditions: SourceQueryConditions;
};

export const useSourceRecordsAndFieldsLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
  queryMode,
  queryConditions: {
    filterConditions,
    sortConditions,
    embeddingSortCondition,
    pagination,
    queryingSql,
  },
}: UseSourceRecordsAndFieldsLoadableParams): {
  recordsLoadable: Loadable<RecordsClientModel>;
  fieldsLoadable: Loadable<FieldsClientModel>;
} => {
  const withQuery = useSourceRecordsAndFieldsWithQueryLoadable({
    teamSlug,
    databaseId,
    tableSlug,
    filterConditions,
    sortConditions,
    embeddingSortCondition,
    pagination,
  });

  const withSQL = useSourceRecordsAndFieldsWithSQLLoadable({
    teamSlug,
    databaseId,
    tableSlug,
    queryingSql,
    pagination,
  });

  return match(queryMode)
    .with(P.union("query", "default"), () => withQuery)
    .with(P.union("prompt", "sql"), () => withSQL)
    .exhaustive();
};
