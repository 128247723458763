import { notebookKeys } from "../../notebook/keys";
import { useQueryClient } from "react-query";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import useApiPage from "~/api/useApiPage";

interface UseCreateNotebookPageMutationParams {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
}

const useCreateNotebookPageMutaiton = ({
  databaseId,
  teamSlug,
  notebookId,
}: UseCreateNotebookPageMutationParams) => {
  const { _createPage } = useApiPage();

  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      pageName,
      description,
      isPrivate,
    }: {
      pageName: string;
      description: string;
      isPrivate: boolean;
    }) => {
      return _createPage({
        teamSlug,
        notebookId,
        databaseId,
        pageName,
        description,
        isPrivate,
      });
    },
    onSuccess: () => {
      const keys = notebookKeys.findNotebook({
        databaseId,
        teamSlug,
        notebookId,
      });

      const usageKeys = notebookKeys.usage({
        teamSlug,
      });

      broadcast(keys);

      return invalidateAll(client, keys, usageKeys);
    },
  };
};

export { useCreateNotebookPageMutaiton };
