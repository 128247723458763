import { useMutation } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModelDefactory } from "~/clientModel/fields/field";

import { useErrorToast } from "~/components_next/Error";
import { useUpdateSimpleFieldMutation } from "~/serverStateStore";

type UseMakeFieldsNullableExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useMakeFieldsNullableExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseMakeFieldsNullableExecutableParams): Executable<
  { fields: FieldsClientModel },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  const updateFieldOptions = useUpdateSimpleFieldMutation({
    teamSlug,
    databaseId,
    tableSlug,
  });

  return useComposeExecutable(
    useMutation({
      ...updateFieldOptions,
      mutationFn: ({ fields }: { fields: FieldsClientModel }) => {
        const nullableSimpleFields = fields.allFields.flatMap((field) =>
          !field.isNullable && !field.isAutoGenerated
            ? [FieldClientModelDefactory.toSimpleField(field)]
            : []
        );

        return Promise.all(
          nullableSimpleFields.map(({ name, type }) =>
            updateFieldOptions.mutationFn({
              requestBody: { name, type, nullable: true },
            })
          )
        );
      },
      onSuccess: () => {
        return updateFieldOptions.onSuccess();
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseMakeFieldsNullableExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseMakeFieldsNullableExecutableParams) => {
  return () =>
    useMakeFieldsNullableExecutable({ teamSlug, databaseId, tableSlug });
};
