import { Code as RadixThemeCode } from "@radix-ui/themes";
import { ComponentPropsWithoutRef, forwardRef } from "react";

type RadixThemeCodeProps = ComponentPropsWithoutRef<typeof RadixThemeCode>;

type CodeProps = RadixThemeCodeProps;

const Code = forwardRef<HTMLDivElement, CodeProps>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <RadixThemeCode ref={ref} {...rest}>
      {children}
    </RadixThemeCode>
  );
});

Code.displayName = "Code";

export { Code };
