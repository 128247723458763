import { ReactRenderer } from "@tiptap/react";
import { SuggestionOptions } from "@tiptap/suggestion";
import {
  SuggestionMenu,
  SuggestionMenuProps,
  SuggestionMenuRef,
} from "./SuggestionMenu.js";
import tippy, { Tippy, GetReferenceClientRect } from "tippy.js";
import { ReactNode } from "react";
import { getOptionItems } from "./mention.js";

type CreateSuggestionParams<OptionItem> = {
  triggerChar: string;
  // optionItems: OptionItem[];
  filterFn?: (item: OptionItem, query: string) => boolean;
  sortFn?: (item: OptionItem) => number;
  renderSuggestedItem: (item: OptionItem) => ReactNode;
};

const createSuggestion = <OptionItem>({
  triggerChar,
  // optionItems,
  filterFn,
  sortFn,
  renderSuggestedItem,
}: CreateSuggestionParams<OptionItem>): Omit<
  SuggestionOptions<OptionItem>,
  "editor"
> => {
  return {
    char: triggerChar,
    allowedPrefixes: null,
    items: ({ query, editor }) => {
      return getOptionItems<OptionItem>(editor)
        .filter((item) => (filterFn ? filterFn(item, query) : true))
        .sort((item) => (sortFn ? sortFn(item) : 0));
    },

    render: () => {
      let component: ReactRenderer<
        SuggestionMenuRef,
        SuggestionMenuProps<OptionItem>
      >;
      let popup: ReturnType<Tippy>;

      return {
        onStart: (props) => {
          component = new ReactRenderer(SuggestionMenu<OptionItem>, {
            props: {
              items: props.items,
              command: props.command,
              renderItem: renderSuggestedItem,
            },
            editor: props.editor,
          });

          if (!props.clientRect) {
            return;
          }

          popup = tippy("body", {
            getReferenceClientRect: props.clientRect as GetReferenceClientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
          });
        },
        onUpdate(props) {
          component.updateProps({
            items: props.items,
            command: props.command,
            renderItem: renderSuggestedItem,
          });

          if (!props.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          });
        },
        onKeyDown(props) {
          if (props.event.key === "Escape") {
            popup[0].hide();
            return true;
          }

          return component.ref?.onKeyDown(props) as boolean;
        },
        onExit() {
          popup[0].destroy();
          component.destroy();
        },
      };
    },
  };
};

export { createSuggestion };
