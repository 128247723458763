import { DashboardRecordHistoryListResponse } from "@usemorph/morph-dashboard-types";
import { RecordHistoriesClientModel } from "./RecordHistoriesClientModel";
import { RecordHistoryClientModelFactory } from "./recordHistory";

export class RecordHistoriesClientModelFactory {
  public static createFromDashboardRecordHistoryListResponse({
    count,
    items,
  }: DashboardRecordHistoryListResponse): RecordHistoriesClientModel {
    return new RecordHistoriesClientModel({
      recordHistories: items.map((historyItem) =>
        RecordHistoryClientModelFactory.createFromDashboardRecordHistoryObject(
          historyItem
        )
      ),
      count,
    });
  }
}
