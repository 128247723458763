import { LeftBottomToastLayout } from "~/presenters/leftBottomToast/LeftBottomToastLayout";
import { ImportStatusContainer } from "../importStatus/ImportStatusContainer";
import { PlanAppealContainer } from "../planAppeal/PlanAppealContainer";

const LeftBottomToastContainer = () => {
  return (
    <LeftBottomToastLayout>
      <ImportStatusContainer />
      <PlanAppealContainer />
    </LeftBottomToastLayout>
  );
};

export { LeftBottomToastContainer };
