import { TablePagination } from "../../common/components/Pagination/TablePagination";
import { useViewRecords } from "./hooks/useViewRecords";
import { Box, Flex } from "@radix-ui/themes";
import {
  PaginationProvider,
  useLimit,
  useSetLimit,
  useSetSkip,
  useSkip,
} from "./states/pagination";
import { QueryConditionsProvider } from "./states/queryConditions";
import {
  TableViewCellComponentPropsProvider,
  useTableViewIsReadOnly,
} from "./hooks/useProps";
import {
  DashboardNotebookCellVariableRequestObject,
  DashboardViewResponse,
} from "@usemorph/morph-dashboard-types";
import { SqlConditionsProvider } from "./states/sqlConditions";
import { QueryModeProvider } from "./states/queryMode";
import { TableComponent } from "./components/TableComponent/TableComponent";
import { QueryModeToolbar } from "./components/QueryModeToolbar/QueryModeToolbar";
import { FlatFilterConditionAnd } from "~/features/Fields/FilterCondition/flatFilterConditionTypes";
import { useMemo } from "react";
import { VariablesProvider } from "../common/states/variables";
import { ReadOnlyTableComponent } from "./components/TableComponent/ReadOnlyTableComponent";
import { sortObjectUtils } from "~/utils/sortObjectUtils";
import { UpdateViewConditionHandler } from "./components/UpdateViewCondition/UpdateViewCondtionHandler";
import { UseViewConditionChangeProps } from "./components/UpdateViewCondition/useViewConditionChange";
import { ViewSettingProvider } from "./states/viewSetting";
import { parseViewSetting } from "~/features/View";

type TableViewCellComponentProps = {
  view: DashboardViewResponse;
  variables: DashboardNotebookCellVariableRequestObject;
  isReadOnly?: boolean;
  height?: number;
} & UseViewConditionChangeProps;

const TableViewCellComponent = (props: { height?: number }) => {
  const { height } = props;

  // handle click inside table
  // const setSelectionState = useSetSelectionState(viewId);
  const handleClickInsideTable = () => {
    // setSelectionState(undefined);
  };

  const skip = useSkip();
  const limit = useLimit();
  const setSkip = useSetSkip();
  const setLimit = useSetLimit();

  const { data: viewRecords } = useViewRecords();

  /**
   * isReadOnly
   */
  const isReadOnly = useTableViewIsReadOnly();

  return (
    <Flex
      direction="column"
      p="3"
      gap="2"
      height={"100%"}
      onClick={handleClickInsideTable}
    >
      <QueryModeToolbar />
      <Box
        position="relative"
        width="100%"
        style={{
          flexGrow: 1,
          overflow: "auto",
          height: height ? `${height - 120}px` : "100%",
        }}
      >
        {isReadOnly ? (
          <ReadOnlyTableComponent />
        ) : (
          <>
            <TableComponent />
          </>
        )}
      </Box>
      <Flex gap="4" direction="row" align="center">
        <TablePagination
          limit={limit}
          setLimit={setLimit}
          skip={skip}
          setSkip={setSkip}
          isDisabled={!viewRecords}
          count={viewRecords?.count ?? 0}
        />
      </Flex>
    </Flex>
  );
};

const TableViewCellComponentWrapper = (props: TableViewCellComponentProps) => {
  const {
    view: {
      condition: { filter, sort, from },
      setting,
    },
    variables,
    condition,
    onConditionChange,
    viewSetting,
    height,
  } = props;

  const parsedSetting = parseViewSetting(setting);

  const normalSorts = sortObjectUtils.getRecordSortConditionUnits(sort);

  const initialFilter = filter ? (filter as FlatFilterConditionAnd) : null;
  const initialSort =
    normalSorts && normalSorts.length > 0 ? normalSorts[0] : null;
  const initialSql = from.includes(" ") ? from : "";

  // todo: 型
  const initialPrompt =
    setting && "data" in setting && "prompt" in setting.data
      ? (setting.data.prompt as string)
      : "";

  const initialQueryMode = useMemo(() => {
    if (initialFilter || initialSort) {
      return "filtersAndSorts";
    }

    if (initialSql) {
      return initialPrompt ? "prompt" : "sql";
    }

    return null;
  }, [initialFilter, initialPrompt, initialSort, initialSql]);

  return (
    <TableViewCellComponentPropsProvider {...props}>
      <VariablesProvider variables={variables}>
        <ViewSettingProvider setting={viewSetting}>
          <QueryModeProvider initialQueryMode={null}>
            <PaginationProvider>
              <QueryConditionsProvider
                initialFilter={initialFilter}
                initialSort={initialSort}
              >
                <SqlConditionsProvider
                  initialSql={initialSql}
                  initialPrompt={initialPrompt}
                >
                  <TableViewCellComponent height={height} />
                  <UpdateViewConditionHandler
                    condition={condition}
                    onConditionChange={onConditionChange}
                    viewSetting={viewSetting}
                  />
                </SqlConditionsProvider>
              </QueryConditionsProvider>
            </PaginationProvider>
          </QueryModeProvider>
        </ViewSettingProvider>
      </VariablesProvider>
    </TableViewCellComponentPropsProvider>
  );
};

export {
  TableViewCellComponentWrapper as TableViewCellComponent,
  type TableViewCellComponentProps,
};
