import { BsFillCaretRightFill } from "react-icons/bs";
import {
  basicFieldFilterFn,
  basicTableFilterFn,
  BasicTypeaheadPrompt,
  basicVariableValueFilterFn,
  FieldSuggestionItem,
  TableSuggestionItem,
  VariableSuggestionItem,
} from "~/presenters/prompt";
import { Button } from "~/components_next/Button";
import { Box, Flex } from "@radix-ui/themes";
import { styled } from "~/stitches";
import { forwardRef, ReactNode, useCallback, useMemo } from "react";
import { match, P } from "ts-pattern";
import { css } from "@stitches/react";
import { FieldClientModel } from "~/clientModel/fields/field";
import { TablesClientModel } from "~/clientModel/tables";
import { TableClientModel } from "~/clientModel/tables/table";

import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { Executable } from "~/clientModel/executable";
import { CanvasVariablesValue } from "~/presenters/canvas/common/CanvasVariablesProvider";

const RightButtonWrapper = styled(Flex, {
  position: "absolute",
  right: 16,
  bottom: 8,
  gap: 12,
  height: "40px",
  flexDirection: "row",
  alignItems: "center",
});

const RunIcon = styled(BsFillCaretRightFill, {
  marginRight: "4px",
});

const promptRootStyle = css({
  overflowY: "auto !important",
  height: "100% !important",
  mihHeight: "120px !important",
  maxHeight: "50vh !important",
});

const promptRootClassName =
  "source-and-views-common-query-toolbar-prompt-prompt-form__prompt-root";

const promptTheme = {
  root: `${promptRootStyle()} ${promptRootClassName}`,
};

type SuggestOptionType =
  | FieldClientModel
  | TableClientModel
  | CanvasVariablesValue;

type PromptFormProps = {
  prompt: string;
  tablesForTypeahead?: TablesClientModel;
  fieldsForTypeahead?: FieldsClientModel;
  variablesForTypeahead?: CanvasVariablesValue[];
  onPromptChange: (prompt: string) => void;
  onSqlGenerated: (sql: string) => void;
  rightButtons?: ReactNode;
  generateSqlFromPromptExecutable: Executable<
    { prompt: string },
    { sql: string }
  >;
};

const PromptForm = forwardRef<HTMLDivElement, PromptFormProps>((props, ref) => {
  const {
    prompt,
    rightButtons,
    tablesForTypeahead,
    fieldsForTypeahead,
    variablesForTypeahead,
    onPromptChange,
    onSqlGenerated,
    generateSqlFromPromptExecutable,
  } = props;

  const optionItems: SuggestOptionType[] = useMemo(() => {
    return [
      ...(tablesForTypeahead ? tablesForTypeahead.allTables : []),
      ...(variablesForTypeahead ? variablesForTypeahead : []),
      ...(fieldsForTypeahead ? fieldsForTypeahead.allFields : []),
    ];
  }, [fieldsForTypeahead, tablesForTypeahead, variablesForTypeahead]);

  const filterFn = useCallback((item: SuggestOptionType, query: string) => {
    return match(item)
      .with(P.instanceOf(TableClientModel), (table: TableClientModel) =>
        basicTableFilterFn(table, query)
      )
      .with(
        { cellId: P.string, name: P.string, value: P._ },
        (variable: CanvasVariablesValue) =>
          basicVariableValueFilterFn(variable, query)
      )
      .otherwise((field: FieldClientModel) => basicFieldFilterFn(field, query));
  }, []);

  const convertOptionItemToText = useCallback((item: SuggestOptionType) => {
    return match(item)
      .with(
        P.instanceOf(TableClientModel),
        (table: TableClientModel) => `${table.tableSlug}`
      )
      .with(
        { cellId: P.string, name: P.string, value: P._ },
        (variable: CanvasVariablesValue) =>
          `\${${variable.cellId}+${variable.name}}`
        // `\${${variable.cellId}+${variable.name}}`
      )
      .otherwise((field: FieldClientModel) => `\${${field.dotNotatedName}}`);
  }, []);

  const renderHTMLTextContent = useCallback((item: SuggestOptionType) => {
    return match(item)
      .with(
        P.instanceOf(TableClientModel),
        (table: TableClientModel) => table.label
      )
      .with(
        { cellId: P.string, name: P.string, value: P._ },
        (variable: CanvasVariablesValue) => variable.name
      )
      .otherwise((field: FieldClientModel) => field.label);
  }, []);

  const renderSuggestedItem = useCallback((item: SuggestOptionType) => {
    return match(item)
      .with(P.instanceOf(TableClientModel), (table: TableClientModel) => (
        <TableSuggestionItem table={table} />
      ))
      .with(
        { cellId: P.string, name: P.string, value: P._ },
        (variable: CanvasVariablesValue) => (
          <VariableSuggestionItem variable={variable} />
        )
      )
      .otherwise((field: FieldClientModel) => (
        <FieldSuggestionItem field={field} />
      ));
  }, []);

  const rightButtonsContainerRef = (div: HTMLDivElement) => {
    const width = div?.clientWidth ?? 0;
    const editorRootElement =
      document.getElementsByClassName(promptRootClassName)[0];
    if (editorRootElement instanceof HTMLDivElement) {
      editorRootElement.style.paddingRight = `${width + 16}px`;
    }
  };

  return (
    <Box position="relative" p="2" style={{ maxWidth: "70vw" }}>
      <BasicTypeaheadPrompt<SuggestOptionType>
        ref={ref}
        textContent={prompt}
        onUpdate={onPromptChange}
        theme={promptTheme}
        optionItems={optionItems}
        filterFn={filterFn}
        convertOptionItemToText={convertOptionItemToText}
        renderHTMLTextContent={renderHTMLTextContent}
        renderSuggestedItem={renderSuggestedItem}
        focusOnLoad
      />
      <RightButtonWrapper ref={rightButtonsContainerRef}>
        {rightButtons}

        <Button
          variant="primary"
          size="xs"
          isLoading={generateSqlFromPromptExecutable.isExecuting}
          isDisabled={!prompt}
          onClick={async () => {
            const { sql } = await generateSqlFromPromptExecutable.execute({
              prompt,
            });
            onSqlGenerated(sql);
          }}
        >
          <RunIcon />
          Run
        </Button>
      </RightButtonWrapper>
    </Box>
  );
});

PromptForm.displayName = "PromptForm";

export { PromptForm };
