import {
  EditorContent,
  EditorEvents,
  JSONContent,
  useEditor,
} from "@tiptap/react";
import {
  createSuggestExtension,
  staticExtensions,
  SuggestExtensionDependencies,
} from "./extensions/extensions";
import { Editor } from "@tiptap/core";
import "./tiptap.css";
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react";
import {
  getOptionItems,
  MENTION_NODE_NAME,
  setOptionItems,
} from "./extensions/suggestion/mention";
import classNames from "classnames";

const getTextFromEditor = (editor: Editor): string => {
  return editor.getText();
};

const setTextContentToEditor = <OptionItem,>(
  editor: Editor,
  textContent: string,
  optionItems: OptionItem[],
  convertOptionItemToText: (optionItem: OptionItem) => string
) => {
  const paragraphs: JSONContent[] = textContent.split("\n").map((text) => {
    // textがなければ、空のparagraphを返す
    if (!text) {
      return {
        type: "paragraph",
      };
    }

    const parsedOptionItems = optionItems.map((optionItem) =>
      convertOptionItemToText(optionItem)
    );

    const escape = (string: string): string => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    };

    const regExp = new RegExp(
      `(${parsedOptionItems.map((item) => escape(item)).join("|")})`
    );

    const splitResult = text.split(regExp);
    const content: JSONContent[] = splitResult.flatMap((item) => {
      if (!item) return [];
      const foundOptionItem = optionItems.find(
        (optionItem) => convertOptionItemToText(optionItem) === item
      );
      return foundOptionItem
        ? {
            type: MENTION_NODE_NAME,
            attrs: {
              value: foundOptionItem,
            },
          }
        : {
            type: "text",
            text: item,
          };
    });

    return {
      type: "paragraph",
      content,
    };
  });

  editor.commands.setContent({ type: "doc", content: paragraphs });
};

type EditorThemeClasses = {
  root?: string;
};

type BasicTypeaheadPromptProps<OptionItem> = {
  textContent: string;
  onUpdate?: (textContent: string) => void;
  onHeightChange?: (height: number) => void;
  theme?: EditorThemeClasses;
  isReadOnly?: boolean;
  optionItems: OptionItem[];
  focusOnLoad?: boolean;
} & SuggestExtensionDependencies<OptionItem>;

/**
 * @template OptionItem
 * @param {string} props.textContent
 * @param {(textContent: string) => void} [props.onUpdate]
 * @param {(height: number) => void} [props.onHeightChange]
 * @param {EditorThemeClasses} [props.theme] エディターの各要素に当てるクラス名
 * @param {boolean} [props.isReadOnly=false]
 * @param {OptionItem[]} props.optionItems サジェストしたいアイテムの配列
 * @param {RenderHTMLTextContent<OptionItem>} props.renderHTMLTextContent エディター内にアイテムが表示される際の、バッジのUIを指定する関数
 * @param {ConvertOptionItemToText<OptionItem>} props.convertOptionItemToText アイテムをどのようにテキストに変換するかを指定する関数
 * @param {RenderSuggestedItem<OptionItem>} props.renderSuggestedItem Popover内でアイテムが表示される際のUIを指定する関数
 * @param {FilterFn<OptionItem>} props.filterFn props.optionItemsから実際にサジェストするアイテムを絞り込む関数
 * @param {SortFn<OptionItem>} props.sortFn props.filterFnの結果をソートする関数
 * @param {boolean} [props.focusOnLoad=false]
 */
const _BasicTypeaheadPrompt = <OptionItem,>(
  {
    textContent,
    onUpdate,
    onHeightChange,
    optionItems,
    renderHTMLTextContent,
    convertOptionItemToText,
    renderSuggestedItem,
    filterFn,
    sortFn,
    theme,
    isReadOnly = false,
    focusOnLoad = false,
  }: BasicTypeaheadPromptProps<OptionItem>,
  ref: ForwardedRef<HTMLElement>
) => {
  const extensions = useMemo(
    () => [
      ...staticExtensions,
      createSuggestExtension<OptionItem>({
        renderHTMLTextContent,
        convertOptionItemToText,
        renderSuggestedItem,
        filterFn,
        sortFn,
      }),
    ],
    // Editorインスタンスの初期設定でしか使わないので、依存配列なし
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleUpdate = useCallback(
    ({ editor }: EditorEvents["update"]) => {
      onUpdate?.(getTextFromEditor(editor));
      onHeightChange?.(editor.view.dom.clientHeight);
    },
    [onUpdate, onHeightChange]
  );

  const editor = useEditor({
    extensions,
    onUpdate: handleUpdate,
    editable: !isReadOnly,
    autofocus: focusOnLoad,
    editorProps: {
      attributes: {
        class: classNames("basic-typeahead-prompt", theme?.root),
      },
    },
  });

  // todo: Tiptapのバグフィックスがマージされてないので、一旦これで回避する
  // reference: https://github.com/ueberdosis/tiptap/issues/4470
  useImperativeHandle<HTMLElement | null, HTMLElement | null>(
    ref,
    () => {
      if (!editor) return null;
      return editor.view.dom;
    },
    [editor]
  );

  // textContentの同期
  useEffect(() => {
    if (!editor) {
      return;
    }
    const currentEditorTextContent = getTextFromEditor(editor);
    if (currentEditorTextContent !== textContent) {
      setTextContentToEditor(
        editor,
        textContent,
        optionItems,
        convertOptionItemToText
      );
    }
  }, [editor, textContent, optionItems, convertOptionItemToText]);

  // optionItemsの更新
  if (editor) {
    const currentOptionItems = getOptionItems(editor);
    if (currentOptionItems !== optionItems) {
      setOptionItems(optionItems, editor);
    }
  }

  return <EditorContent editor={editor} />;
};

// ここのキャストは仕方がない
const BasicTypeaheadPrompt = forwardRef(_BasicTypeaheadPrompt) as <OptionItem>(
  props: BasicTypeaheadPromptProps<OptionItem> & {
    ref?: ForwardedRef<HTMLDivElement>;
  }
) => JSX.Element;

export { BasicTypeaheadPrompt };
export type { BasicTypeaheadPromptProps };
