import { useState, ChangeEvent, ReactNode, useMemo } from "react";
import { Input } from "~/components_next/Input";
import { convertToTableSlug } from "./util/convertToTableSlug";
import { useSortedTables } from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { TextArea } from "~/components_next/TextArea";
// import { useErrorToast } from "../Error";
export type TableNameInputSetProps = {
  oldTableName?: string;
  oldTableSlug?: string;
  oldDescription?: string;
};

type TableNameInputReturn = {
  tableName: string;
  tableSlug: string;
  description: string;
  tableSlugError: string | undefined;
  TableNameInput: ReactNode;
  TableSlugInput: ReactNode;
  DescriptionInput: ReactNode;
  onCreate: () => void;
};
const TableNameInputSet = ({
  oldTableName,
  oldTableSlug,
  oldDescription,
}: TableNameInputSetProps): TableNameInputReturn => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const { isTableSlugExists } = useSortedTables({ teamSlug, databaseId });
  const [tableName, setTableName] = useState<string>(oldTableName || "");
  const [tableSlug, setTableSlug] = useState<string>(oldTableSlug || "");
  const [description, setDescription] = useState<string>(oldDescription || "");
  const [tableSlugError, setTableSlugError] = useState<string | undefined>(
    undefined
  );
  const onCreate = () => {
    setTableName("");
    setTableSlug("");
    setDescription("");
    setTableSlugError(undefined);
  };
  //validation func
  const alphabetRegex = /^[a-zA-Z_]/;
  const runTableSlugValidation = (inputString: string) => {
    if (inputString.length === 0) {
      return setTableSlugError("Source Slug is required.");
    }
    if (inputString === "health_check") {
      return setTableSlugError(
        '"health_check" is a reserved word. Use different name for the slug.'
      );
    }
    if (inputString.length > 0 && alphabetRegex.test(inputString) === false) {
      return setTableSlugError(
        "Source Slug should start with Alphabet or Underscore"
      );
    }
    if (isTableSlugExists(inputString) === false) {
      return setTableSlugError(`${inputString} is already used.`);
    }
    return setTableSlugError(undefined);
  };

  //func 1
  const onDisplayNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (convertToTableSlug(tableName) === tableSlug) {
      const autoGeneratedTableSlug = convertToTableSlug(e.target.value);
      setTableName(e.target.value);
      setTableSlug(autoGeneratedTableSlug);
      runTableSlugValidation(autoGeneratedTableSlug);
    } else {
      setTableName(e.target.value);
    }
  };
  //func 2
  const onTableSlugChangeAndValidate = (e: ChangeEvent<HTMLInputElement>) => {
    const newInputLetter =
      e.target.value.length > 0 ? e.target.value.slice(-1) : "";
    const isAccepted = (letter: string) => {
      const pattern = /^[0-9a-zA-Z_]+$/;
      return pattern.test(letter);
    };
    if (e.target.value.length > 0 && isAccepted(newInputLetter) === false) {
      setTableSlugError(
        "Only Lowercase Alphabet, Number, Underscore Permitted."
      );
      // errorToast({
      //   title: "Can't Use That Letter",
      //   description: "Only Lowercase Alphabet, Number, Underscore Permitted.",
      //   status: "info",
      //   duration: 3000,
      //   isClosable: true,
      //   position: "top",
      // });
    } else if (
      e.target.value.length > 0 &&
      isAccepted(newInputLetter) === true
    ) {
      setTableSlugError(undefined);
    }
    const slugifiedInput = convertToTableSlug(e.target.value);
    setTableSlug(slugifiedInput);
    runTableSlugValidation(slugifiedInput);
  };

  //components
  const TableNameInput = (
    <>
      <Input
        variant="primary"
        value={tableName}
        label="Source Name"
        onChange={onDisplayNameChange}
      />
    </>
  );
  type ErrorType = {
    message: string | undefined;
    isInvalid: boolean;
  };
  const ErrorAndValidation = useMemo<ErrorType>(() => {
    if (tableSlugError && tableSlugError.length > 0) {
      return {
        message: tableSlugError,
        isInvalid: true,
      };
    } else {
      return {
        message: undefined,
        isInvalid: false,
      };
    }
  }, [tableSlugError]);
  const TableSlugInput = (
    <>
      <Input
        label="Source Slug"
        variant="supplementary"
        value={tableSlug}
        errorMessage={ErrorAndValidation.message}
        isInvalid={ErrorAndValidation.isInvalid}
        onChange={onTableSlugChangeAndValidate}
      />
    </>
  );

  const DescriptionInput = (
    <>
      <TextArea
        label="Description"
        variant="primary"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
    </>
  );
  return {
    tableName,
    tableSlug,
    description,
    tableSlugError,
    TableNameInput,
    TableSlugInput,
    DescriptionInput,
    onCreate,
  };
};
export { TableNameInputSet };
