import { useCallback } from "react";
import { ClearPromptButton } from "../../../../common/components/QueryMode/Buttons/ClearPromptButton";
import { useSetPromptInput } from "../../../states/promptInput";
import { useSetQueryMode } from "../../../states/queryMode";
import { useSetQueryingSql, useSetSqlInput } from "../../../states/sql";
import { useViewId } from "~/utilHooks/useViewId";

const TableViewClearPromptButton = () => {
  /**
   * global states from recoil
   */
  const viewId = useViewId();
  const setQueryMode = useSetQueryMode(viewId);
  const setSqlInput = useSetSqlInput(viewId);
  const setQueryingSql = useSetQueryingSql(viewId);
  const setPromptInput = useSetPromptInput(viewId);

  /**
   * handlers
   */

  const handleClickClearPromptButton = useCallback(() => {
    setQueryMode(null);
    setQueryingSql("");
    setSqlInput("");
    setPromptInput("");
  }, [setSqlInput, setQueryMode, setQueryingSql, setPromptInput]);

  return <ClearPromptButton onClick={handleClickClearPromptButton} />;
};

export { TableViewClearPromptButton };
