import { useCallback, useEffect, useRef } from "react";
import {
  useRecordsTableBaseColumnSizing,
  useRecordsTableBaseColumnsMeta,
  useRecordsTableBaseOptions,
  useRecordsTableBaseRecords,
} from "./context/ReactTableBaseContext";
import {
  defaultRangeExtractor,
  Range,
  useVirtualizer,
} from "@tanstack/react-virtual";
import { match } from "ts-pattern";
import { useTableSizings } from "./context/useTableSizing";

const useRecordsTableVirtualizers = () => {
  const {
    getHeaderRowHeight,
    getBodyRowHeight,
    getHeaderColumnCheckBoxWidth,
    getHeaderColumnOpenRecordButtonWidth,
    getRecordColumnWidth,
    getAddFieldButtonWidth,
  } = useTableSizings();

  const columnsMeta = useRecordsTableBaseColumnsMeta();
  const columnSizing = useRecordsTableBaseColumnSizing();

  const { noPadding, noStickyHeader } = useRecordsTableBaseOptions();
  const records = useRecordsTableBaseRecords();

  /**
   * Virtual Row scroll
   */
  const parentRef = useRef<HTMLDivElement>(null);

  const rowCount =
    1 + // header
    records.allRecords.length; // records

  const estimateRowSize = (i: number) =>
    i === 0 ? getHeaderRowHeight() : getBodyRowHeight();

  const rowVirtualizer = useVirtualizer({
    count: rowCount,
    getScrollElement: () => parentRef.current,
    estimateSize: estimateRowSize,
    overscan: 5,
    paddingEnd: noPadding ? 0 : 400,
    rangeExtractor: useCallback(
      (range: Range) => {
        if (noStickyHeader) {
          return defaultRangeExtractor(range);
        }

        const next = new Set([0, ...defaultRangeExtractor(range)]);

        return [...next].sort((a, b) => a - b);
      },
      [noStickyHeader]
    ),
  });

  /**
   * Virtual Column scroll
   */

  const estimateColumnSize = (i: number) => {
    return match(columnsMeta[i])
      .with({ type: "selectCheckbox" }, () => getHeaderColumnCheckBoxWidth())
      .with({ type: "openRecordButton" }, () =>
        getHeaderColumnOpenRecordButtonWidth()
      )
      .with({ type: "item" }, ({ fieldName }) =>
        getRecordColumnWidth(fieldName)
      )
      .with({ type: "addFieldButton" }, () => getAddFieldButtonWidth())
      .exhaustive();
  };

  const columnVirtualizer = useVirtualizer({
    horizontal: true,
    count: columnsMeta.length,
    getScrollElement: () => parentRef.current,
    estimateSize: estimateColumnSize,
    overscan: 5,
    paddingEnd: noPadding ? 0 : 400,
  });

  useEffect(() => {
    columnVirtualizer.measure();
  }, [columnSizing, columnVirtualizer]);

  return {
    rowVirtualizer,
    columnVirtualizer,
    parentRef,
  };
};

export { useRecordsTableVirtualizers };
