import { DefaultSerializerUsableType } from "./serializers";
import { Serializer, Deserializer, Serializable } from "./types";

/**
 * ストレージに保存する値ごとに作られるオブジェクト
 */
type StorageItem<ValueType> = {
  key: string;
  version: number;
  serializer?: Serializer<ValueType>;
  deserializer?: Deserializer<ValueType>;
} & (ValueType extends DefaultSerializerUsableType
  ? {
      serializer?: Serializer<ValueType>;
    }
  : {
      serializer: Serializer<ValueType>;
    }) & {
    deserializer: Deserializer<ValueType>;
  };

const getStorageKey = (
  key: string,
  dependencies: { [key: string]: Serializable }
): string => {
  // keyとdependenciesを連結する文字列
  const dependenciesString = Object.entries(dependencies)
    .map(([depKey, depValue]) => {
      return `${depKey}=${JSON.stringify(depValue)}`;
    })
    .join("&");
  return `${key}?${dependenciesString}`;
};

type DynamicStorageItem<
  ValueType,
  Dependencies extends { [key: string]: Serializable }
> = (dependencies: Dependencies) => StorageItem<ValueType>;

const dynamicStorageItem = <
  ValueType,
  Dependencies extends { [key: string]: Serializable }
>(
  storageItem: StorageItem<ValueType>
): DynamicStorageItem<ValueType, Dependencies> => {
  return (dependencies: Dependencies) => {
    const storageKey = getStorageKey(storageItem.key, dependencies);
    return { ...storageItem, key: storageKey };
  };
};

const storageItem = <ValueType>(
  storageItem: StorageItem<ValueType>
): StorageItem<ValueType> => {
  return storageItem;
};

export { storageItem, dynamicStorageItem };
export type { StorageItem, DynamicStorageItem };
