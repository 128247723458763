import { useEffect, useMemo } from "react";
import { CoworkerState } from "~/components_next/RecordsTableBase/types";
import {
  useGetUserColor,
  useOthersDecoded,
  useUpdateMyPresenceTableViewState,
} from "~/features/RealtimeCollaboration";
import { useViewId } from "~/utilHooks/useViewId";
import { useCellSelection } from "../states/cellSelection";
import { useFieldSelection } from "../states/fieldSelection";
import { useRecordSelection } from "../states/recordSelection";

const useTableViewCollaboration = () => {
  /**
   * Others
   */
  const { samePageOthers } = useOthersDecoded();
  const getUserColor = useGetUserColor();

  const tableViewCoworkerState: CoworkerState[] = useMemo(() => {
    return samePageOthers.flatMap((other) => {
      if (!other.presence.tableState) return [];

      const userColor = getUserColor(other.presence.user.userId) || "amber";

      const coworkerState: CoworkerState = {
        ...other.presence.tableState,
        user: other.presence.user,
        colorScheme: userColor,
      };

      return [coworkerState];
    });
  }, [getUserColor, samePageOthers]);

  /**
   * My Presence
   */
  const viewId = useViewId();
  const cellSelection = useCellSelection(viewId);
  const recordSelection = useRecordSelection(viewId);
  const fieldSelection = useFieldSelection(viewId);

  const updateMyPresenceTableViewState = useUpdateMyPresenceTableViewState();

  useEffect(() => {
    updateMyPresenceTableViewState({
      cellSelection: cellSelection || [],
      recordSelection: recordSelection || [], // recordSelectionバグってる
      fieldSelection: fieldSelection || [],
      editingCell: null,
    });
  }, [
    cellSelection,
    fieldSelection,
    recordSelection,
    updateMyPresenceTableViewState,
  ]);

  return {
    tableViewCoworkerState,
  };
};

export { useTableViewCollaboration };
