type PageDependencies = {
  teamSlug: string;
  databaseId: string;
};

type FindNotebookDependencies = {
  notebookId: string;
  pageId: string;
} & PageDependencies;

const pageKeys = {
  getPublicPages: ({
    teamSlug,
    apiKey,
  }: {
    teamSlug: string;
    apiKey: string;
  }) => [teamSlug, apiKey, "getPublicNotebookPages"],
  findPublicPageDetail: ({
    teamSlug,
    apiKey,
    pageId,
  }: {
    teamSlug: string;
    apiKey: string;
    pageId: string;
  }) => [teamSlug, apiKey, pageId, "findPublicPageDetail"],
  // `all` が実運用上あり得ない
  findPageDetail: ({
    teamSlug,
    databaseId,
    notebookId,
    pageId,
  }: FindNotebookDependencies) =>
    [
      teamSlug,
      databaseId,
      notebookId,
      "findNotebookPageDetail",
      pageId,
    ] as const,

  allFindPageDetailUnderNotebook: ({
    teamSlug,
    databaseId,
    notebookId,
  }: FindNotebookDependencies) =>
    [teamSlug, databaseId, notebookId, "findNotebookPageDetail"] as const,
};

export { pageKeys };
