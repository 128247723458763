import { TimezoneType } from "@usemorph/morph-dashboard-types";

// 数多すぎるので一旦サーバーの型使う
export type Timezone = TimezoneType;

type TimezoneClientModelData = {
  timezone: Timezone;
};

export class TimezoneClientModel {
  readonly #data: TimezoneClientModelData;

  public constructor(data: TimezoneClientModelData) {
    this.#data = data;
  }

  public get data(): TimezoneClientModelData {
    return this.#data;
  }

  public get timezoneLabel(): string {
    return this.#data.timezone;
  }
}
