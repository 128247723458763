import { useCallback, useState } from "react";
import { FilterConditionsClientModelFactory } from "~/clientModel/queryConditions/filterConditions";
import { PaginationClientModelFactory } from "~/clientModel/queryConditions/pagination/PaginationClientModelFactory";
import { SortConditionsClientModelFactory } from "~/clientModel/queryConditions/sortConditions";
import { CanvasTableViewQueryConditions } from "~/presenters/canvas/cellContent/view/CanvasTableViewToolbar";

export const useDashboardTableViewQueryConditions = () => {
  const [queryConditions, setQueryConditions] =
    useState<CanvasTableViewQueryConditions>({
      filterConditions:
        FilterConditionsClientModelFactory.createEmptyFilterConditions(),
      sortConditions:
        SortConditionsClientModelFactory.createEmptySortConditions(),
      prompt: "",
      sql: "",
      queryingSql: "",
      pagination: PaginationClientModelFactory.createInitialPagination({
        limit: 30,
      }),
    });

  const [queryMode, setQueryMode] = useState<
    "default" | "query" | "prompt" | "sql"
  >("default");

  const resetAllConditions = useCallback(() => {
    setQueryConditions({
      filterConditions:
        FilterConditionsClientModelFactory.createEmptyFilterConditions(),
      sortConditions:
        SortConditionsClientModelFactory.createEmptySortConditions(),
      prompt: "",
      sql: "",
      queryingSql: "",
      pagination: PaginationClientModelFactory.createInitialPagination({
        limit: 30,
      }),
    });

    setQueryMode("default");
  }, []);

  return {
    queryMode,
    onQueryModeChange: setQueryMode,
    queryConditions,
    onQueryConditionsChange: setQueryConditions,
    resetAllConditions,
  };
};
