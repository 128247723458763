import { useQuery } from "react-query";
import {
  useFindViewQuery,
  useGroupQueryViewRecordsQuery,
  useGetViewFieldsQuery,
  viewKeys,
} from "~/serverStateStore/views";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useViewId } from "~/utilHooks/useViewId";
import { convertRecordToRecordModel } from "~/features/RecordModel";
import { useFilterCondition } from "../states/filterCondition";
import { useSortCondition } from "../states/sortCondition";
import { KanbanValues } from "~/components_next/RecordsKanbanBase/RecordsKanbanBase";
import { useMemo } from "react";
import {
  GroupQueryRecordListResponse,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import {
  getTableSlugAndFieldNameFromGroupKey,
  parseViewSetting,
} from "~/features/View";

type GroupingViewRecordsData = {
  records: KanbanValues[];
  groupingField: SimpleField;
};

type UseGroupingViewRecordsResult =
  | {
      status: "success";
      data: GroupingViewRecordsData;
      error: unknown;
    }
  | {
      status: "error";
      data: undefined;
      error: unknown;
    }
  | {
      status: "loading" | "idle";
      data: undefined;
      error: undefined;
    };

const useGroupingViewRecords = (): UseGroupingViewRecordsResult => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  const filterCondition = useFilterCondition(viewId);
  const sortCondition = useSortCondition(viewId);

  const {
    data: viewData,
    status: viewStatus,
    error: viewError,
  } = useQuery(
    useFindViewQuery({
      teamSlug,
      databaseId,
      viewId,
    })
  );

  const {
    data: viewFieldsData,
    status: viewFieldsStatus,
    error: viewFieldsError,
  } = useQuery(
    useGetViewFieldsQuery({
      teamSlug,
      databaseId,
      viewId,
    })
  );

  const groupingField = useMemo(() => {
    if (!viewData || !viewFieldsData) return undefined;

    return viewFieldsData.fields.find(({ name, originalTableSlug }) => {
      const { viewFieldName, tableSlug } = getTableSlugAndFieldNameFromGroupKey(
        {
          groupKey: viewData.condition.groupKey ?? "",
          mainTableSlug: viewData.tableSlug,
        }
      );
      return name === viewFieldName && originalTableSlug === tableSlug;
    });
  }, [viewData, viewFieldsData]);

  const {
    data: groupQueryViewRecordsData,
    status: groupQueryViewRecordsStatus,
    error: groupQueryViewRecordsError,
  } = useQuery<
    GroupQueryRecordListResponse,
    unknown,
    GroupingViewRecordsData,
    ReturnType<typeof viewKeys.groupQueryViewRecords>
  >({
    ...useGroupQueryViewRecordsQuery({
      teamSlug,
      databaseId,
      viewId,
      select: ["*"],
      filter: filterCondition ?? undefined,
      sort: sortCondition ? [sortCondition] : undefined,
    }),
    select: (data: GroupQueryRecordListResponse): GroupingViewRecordsData => {
      const parsedSetting = parseViewSetting(viewData!.setting);
      const hiddenColumns = parsedSetting.data.hiddenColumns ?? [];
      const columnsOrder = parsedSetting.data.columnsOrder ?? [];

      const displayedGroupingRecords = Object.entries(data).flatMap(
        ([groupingFieldValue, { items }]) => {
          // hidden columnsに指定されているgrouping valueのカラムは除外
          if (hiddenColumns.includes(groupingFieldValue)) return [];

          return [
            {
              groupingFieldName: groupingField!.name,
              groupingFieldValue,
              records: items.map((record) =>
                convertRecordToRecordModel(record, viewFieldsData!.fields)
              ),
            },
          ];
        }
      );

      const orderedRecords = [...displayedGroupingRecords].sort((a, b) => {
        // columnsOrderの順に並べる
        // columnOrderに含まれていないものは最後
        return (
          columnsOrder.indexOf(a.groupingFieldValue) -
          columnsOrder.indexOf(b.groupingFieldValue)
        );
      });

      return {
        records: orderedRecords,
        groupingField: groupingField!,
      };
    },
    enabled: !!viewData && !!groupingField && !!viewFieldsData,
  });

  if (
    (viewData && !viewData.condition.groupKey) ||
    (viewData && viewFieldsData && !groupingField)
  ) {
    return {
      status: "error",
      data: undefined,
      error: new Error("Invalid group key in view condition."),
    };
  }

  if (
    groupQueryViewRecordsStatus === "loading" ||
    viewStatus === "loading" ||
    viewFieldsStatus === "loading"
  ) {
    return {
      status: "loading",
      data: undefined,
      error: undefined,
    };
  }

  if (
    groupQueryViewRecordsStatus === "idle" ||
    viewStatus === "idle" ||
    viewFieldsStatus === "idle"
  ) {
    return {
      status: "idle",
      data: undefined,
      error: undefined,
    };
  }

  if (
    groupQueryViewRecordsStatus === "error" ||
    viewStatus === "error" ||
    viewFieldsStatus === "error"
  ) {
    return {
      status: "error",
      data: undefined,
      error: groupQueryViewRecordsError ?? viewError ?? viewFieldsError,
    };
  }

  return {
    status: "success",
    data: groupQueryViewRecordsData,
    error: undefined,
  };
};

export { useGroupingViewRecords };
