import { BsPlus } from "react-icons/bs";
import { Button } from "~/components_next/Button";
import { Box } from "@radix-ui/themes";

type AddSortButtonProps = {
  onClick: () => void;
};

const AddSortButton = (props: AddSortButtonProps) => {
  const { onClick } = props;

  return (
    <Box style={{ alignSelf: "start" }}>
      <Button onClick={onClick} variant="tertiary" size="xs">
        <BsPlus />
        Add
      </Button>
    </Box>
  );
};

export { AddSortButton };
