type TableFavoriteDependencies = {
  teamSlug: string;
  databaseId: string;
};

const tableFavoriteKeys = {
  all: ({ teamSlug, databaseId }: TableFavoriteDependencies) =>
    ["tableGroups", teamSlug, databaseId] as const,

  /**
   * list plans
   */
  allListTableFavorites: ({
    teamSlug,
    databaseId,
  }: TableFavoriteDependencies) =>
    [...tableFavoriteKeys.all({ teamSlug, databaseId }), "list"] as const,
};

export { tableFavoriteKeys };
