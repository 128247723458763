import { RecordClientModel } from "./record";
import { RecordIdentifierClientModel } from "./record/recordIdentifier";

type RecordsClientModelData = {
  records: RecordClientModel[];
  totalCount: number;
};

export class RecordsClientModel {
  readonly #data: RecordsClientModelData;

  constructor(data: RecordsClientModelData) {
    this.#data = data;
  }

  public get allRecords(): RecordClientModel[] {
    return this.#data.records;
  }

  // 取得したレコードの数
  public get count(): number {
    return this.#data.records.length;
  }

  // テーブル上の全てのレコードの総数
  public get totalCount(): number {
    return this.#data.totalCount;
  }

  public getRecordByIndex(index: number): RecordClientModel {
    return this.#data.records[index];
  }

  public findRecordByRecordIdentifier(
    recordIdentifier: RecordIdentifierClientModel
  ): RecordClientModel | null {
    return (
      this.#data.records.find((r) =>
        r.recordIdentifier.isSame(recordIdentifier)
      ) ?? null
    );
  }

  public getUpperRecord(
    recordIdentifier: RecordIdentifierClientModel
  ): RecordClientModel | null {
    const recordIndex = this.getRecordIndex(recordIdentifier);

    if (recordIndex === 0) return null;

    const upperRecord = this.#data.records[recordIndex - 1];
    return upperRecord;
  }

  public getDownerRecord(
    recordIdentifier: RecordIdentifierClientModel
  ): RecordClientModel | null {
    const recordIndex = this.getRecordIndex(recordIdentifier);

    if (recordIndex === this.#data.records.length - 1) return null;

    const downerRecord = this.#data.records[recordIndex + 1];
    return downerRecord;
  }

  // recordIdentifiersの中で最も上にあるものを返す
  public getTheTopRecordIdentifier(
    recordIdentifiers: RecordIdentifierClientModel[]
  ): RecordIdentifierClientModel {
    const theUpperRecordIdentifier = recordIdentifiers.reduce(
      (currentTheUpperRecordIdentifier, recordIdentifier) => {
        const currentTheUpperRecordIdentifierIndex = this.getRecordIndex(
          currentTheUpperRecordIdentifier
        );
        const recordIndex = this.getRecordIndex(recordIdentifier);

        if (recordIndex < currentTheUpperRecordIdentifierIndex) {
          return recordIdentifier;
        }

        return currentTheUpperRecordIdentifier;
      },
      recordIdentifiers[0]
    );

    return theUpperRecordIdentifier;
  }

  // recordIdentifiersの中で最も下にあるものを返す
  public getTheBottomRecordIdentifier(
    recordIdentifiers: RecordIdentifierClientModel[]
  ): RecordIdentifierClientModel {
    const theDownerRecordIdentifier = recordIdentifiers.reduce(
      (currentTheDownerRecordIdentifier, recordIdentifier) => {
        const currentTheDownerRecordIdentifierIndex = this.getRecordIndex(
          currentTheDownerRecordIdentifier
        );
        const recordIndex = this.getRecordIndex(recordIdentifier);

        if (recordIndex > currentTheDownerRecordIdentifierIndex) {
          return recordIdentifier;
        }

        return currentTheDownerRecordIdentifier;
      },
      recordIdentifiers[0]
    );

    return theDownerRecordIdentifier;
  }

  private getRecordIndex(
    recordIdentifier: RecordIdentifierClientModel
  ): number {
    return this.#data.records.findIndex((r) =>
      r.recordIdentifier.isSame(recordIdentifier)
    );
  }
}
