import { tableFavoriteKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiTableFavorite from "~/api/useApiTableFavorite";

interface UseListTableFavoritesQueryParams {
  teamSlug: string;
  databaseId: string;
}

const useListTableFavoritesQuery = ({
  teamSlug,
  databaseId,
}: UseListTableFavoritesQueryParams) => {
  const { _listTableFavorites } = useApiTableFavorite();

  const client = useQueryClient();
  client.setQueryDefaults(
    tableFavoriteKeys.allListTableFavorites({ teamSlug, databaseId }),
    commonQueryOptions
  );

  return {
    queryKey: tableFavoriteKeys.allListTableFavorites({
      teamSlug,
      databaseId,
    }),
    queryFn: () => {
      return _listTableFavorites({
        teamSlug,
        databaseId,
      });
    },
  };
};

export { useListTableFavoritesQuery };
