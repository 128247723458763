import {
  SimpleField,
  DashboardTableObject,
} from "@usemorph/morph-dashboard-types";
import { BasicTypeaheadPrompt, FieldSuggestionItem } from "~/features/Prompt";

import "./prompt.css";

type PromptViewerProps = {
  prompt: string;
  fields: SimpleField[];
  tables: DashboardTableObject[];
};

const PromptViewer = (props: PromptViewerProps) => {
  const { prompt, fields, tables } = props;

  return (
    <BasicTypeaheadPrompt<SimpleField>
      textContent={prompt}
      isReadOnly
      optionItems={fields}
      theme={{ root: "prompt-viewer__prompt-editor-root" }}
      renderSuggestedItem={(item) => <FieldSuggestionItem field={item} />}
      renderHTMLTextContent={(item) => item.displayName || item.name}
      convertOptionItemToText={(item) => `\${${item.name}}`}
    />
  );
};

export { PromptViewer };
