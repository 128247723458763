import { DashboardNotebookCellSourceObject } from "@usemorph/morph-dashboard-types";
import {
  CanvasCellClientModelUnion,
  CanvasCellSourceClientModel,
  CanvasCellViewClientModel,
} from "../../CanvasCellClientModel";
import { CanvasCellPosition } from "../../CanvasCellClientModel/CanvasCellClientModelBase";
import { CanvasCreateCellClientModel } from "../CanvasCreateCellClientModel";
import { FieldClientModel } from "~/clientModel/fields/field";

const camelCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export type CanvasCreateTableViewCellDataType = {
  parentCells: CanvasCellClientModelUnion[];
  cellType: "view";
  cellName: string;
  source: {
    promptSql: NonNullable<DashboardNotebookCellSourceObject["promptSql"]>;
  };
  settings?: CanvasCellPosition; // 外部から決めてもいいし、なければparentCellsから決める
  timeSeriesTargetField?: FieldClientModel;
  groupingTargetFields?: FieldClientModel[];
};

export class CanvasCreateTableViewCellClientModel extends CanvasCreateCellClientModel {
  readonly #data: CanvasCreateTableViewCellDataType;
  constructor(data: CanvasCreateTableViewCellDataType) {
    super(data);
    this.#data = data;
  }

  get tableViewType() {
    return this.#data.source.promptSql.type;
  }

  get typeLabel() {
    return camelCaseToWords(this.#data.source.promptSql.type);
  }

  get operatorLabel() {
    return camelCaseToWords(this.#data.source.promptSql.operator);
  }

  get timeSeriesTargetField() {
    return this.#data.timeSeriesTargetField;
  }

  get groupingTargetFields() {
    return this.#data.groupingTargetFields;
  }

  get prompt() {
    return this.#data.source.promptSql.prompt;
  }

  get parentSourceCells() {
    return this.#data.parentCells.filter(
      (cell): cell is CanvasCellSourceClientModel => cell.cellType === "source"
    );
  }

  get parentSourceCell() {
    if (this.parentSourceCells.length === 0) {
      return undefined;
    }
    return this.parentSourceCells[0];
  }

  get parentViewCells() {
    return this.#data.parentCells.filter(
      (cell): cell is CanvasCellViewClientModel => cell.cellType === "view"
    );
  }

  get parentViewCell() {
    if (this.parentViewCells.length === 0) {
      return undefined;
    }
    return this.parentViewCells[0];
  }

  updateTimeSeriesTargetField(field: FieldClientModel | undefined) {
    return new CanvasCreateTableViewCellClientModel({
      ...this.#data,
      source: {
        ...this.#data.source,
        promptSql: {
          ...this.#data.source.promptSql,
        },
      },
      timeSeriesTargetField: field,
    });
  }

  updateGroupingTargetFieldAt(index: number, field: FieldClientModel) {
    return new CanvasCreateTableViewCellClientModel({
      ...this.#data,
      source: {
        ...this.#data.source,
        promptSql: {
          ...this.#data.source.promptSql,
        },
      },
      groupingTargetFields: this.#data.groupingTargetFields?.map((f, i) =>
        i === index ? field : f
      ),
    });
  }

  updateGroupingTargetFields(fields: FieldClientModel[] | undefined) {
    return new CanvasCreateTableViewCellClientModel({
      ...this.#data,
      source: {
        ...this.#data.source,
        promptSql: {
          ...this.#data.source.promptSql,
        },
      },
      groupingTargetFields: fields,
    });
  }

  updatePrompt(prompt: string) {
    return new CanvasCreateTableViewCellClientModel({
      ...this.#data,
      source: {
        ...this.#data.source,
        promptSql: {
          ...this.#data.source.promptSql,
          prompt,
        },
      },
    });
  }

  override get requestBody() {
    const requestBody = super.requestBody;
    return {
      ...requestBody,
      source: {
        ...requestBody.source,
        promptSql: {
          ...this.#data.source.promptSql,
          timeseriesField: this.timeSeriesTargetField?.name,
          groupFields: this.groupingTargetFields
            ? this.groupingTargetFields.map((field) => field.name)
            : [],
        },
      },
    };
  }
}
