import { ScrollArea } from "@radix-ui/themes";
import { BsCaretDownFill } from "react-icons/bs";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import { SimpleCollapsible } from "~/components_next/Collapsible";
import { Flex } from "~/components_next/Flex";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";
import { styled } from "~/stitches";
import { PlaygroundControlPanelBox } from "../PlaygroundControlPanelBox";
import { PlaygroundCellsListItem } from "./PlaygroundCellsListItem";

const CollapseIcon = styled(BsCaretDownFill, {
  fontSize: "var(--font-size-1)",
  color: "$slate8",
});

type PlaygroundCellsListProps = {
  cells: CanvasCellClientModelUnion[];
  onUpdateLiveCell: (cell: CanvasCellClientModelUnion) => void;
};

const PlaygroundCellsList = (props: PlaygroundCellsListProps) => {
  const { cells, onUpdateLiveCell } = props;

  return (
    <>
      <PlaygroundControlPanelBox py="1">
        <SimpleCollapsible
          variant="skelton"
          size="xs"
          trigger={
            <Flex
              css={{ minWidth: "160px", width: "100%" }}
              px="1"
              gap="1"
              align="center"
            >
              <Text variant="tinyDescription">Cells</Text>
              <Spacer />
              <CollapseIcon />
            </Flex>
          }
        >
          <ScrollArea style={{ maxHeight: "50vh" }}>
            {cells.map((cell) => {
              return (
                <PlaygroundCellsListItem
                  cell={cell}
                  key={cell.cellId}
                  onUpdateLiveCell={onUpdateLiveCell}
                />
              );
            })}
          </ScrollArea>
        </SimpleCollapsible>
      </PlaygroundControlPanelBox>
    </>
  );
};

export { PlaygroundCellsList, type PlaygroundCellsListProps };
