import { FieldsClientModel } from "../fields";
import { FieldClientModel } from "../fields/field";
import { RecordsClientModel } from "../records";
import { RecordClientModel } from "../records/record";
import {
  RecordEntryClientModel,
  RecordEntryClientModelFactory,
} from "../records/record/recordEntry";
import { CsvImportSchemaClientModel } from "./CsvImportSchemaClientModel";

export class CsvInsertSchemaEditClientModel {
  readonly #importSchemaInstance: CsvImportSchemaClientModel; // サーバーからの最初のレスポンス (推定結果)
  readonly #records: RecordClientModel[]; // プレビュー用のレコード。ローカルの書き換えをもとにkey名を変更したりするのでプロパティとして保持
  readonly #mappedFields: Array<{
    colNum: number;
    fileHeaderKey: string;
    field: FieldClientModel;
  }>; // colNumがkeyになる

  constructor(
    importSchemaInstance: CsvImportSchemaClientModel,
    records: RecordClientModel[] = [],
    mappedFields: Array<{
      colNum: number;
      fileHeaderKey: string;
      field: FieldClientModel;
    }> = []
  ) {
    this.#importSchemaInstance = importSchemaInstance;
    this.#records = records;
    this.#mappedFields = mappedFields;
  }

  get mappedFields() {
    return this.#mappedFields;
  }

  get id() {
    return this.#importSchemaInstance.id;
  }

  get hasHeader() {
    return this.#importSchemaInstance.hasHeader;
  }

  get previewRecords() {
    return new RecordsClientModel({
      records: this.#records,
      totalCount: this.#records.length,
    });
  }

  get previewFields() {
    return new FieldsClientModel({
      fields: this.#mappedFields.map((mappedField) => {
        return mappedField.field;
      }),
    });
  }

  get estimatedDataSource() {
    return this.#importSchemaInstance.estimatedDataSource;
  }

  get estimatedName() {
    return this.#importSchemaInstance.estimatedName;
  }

  get estimatedFields() {
    return this.#importSchemaInstance.estimatedFields;
  }

  /**
   *
   * @param colNum: Estimated schemaの通し番号
   * @param field: 選択されたフィールド
   * @param originalKey: CSVファイルのヘッダー名
   */
  mapField({
    colNum,
    field,
    originalKey,
  }: {
    colNum: number;
    field: FieldClientModel;
    originalKey: string;
  }) {
    const mappedFields = [
      ...this.#mappedFields.filter((mappedField) => {
        return mappedField.colNum !== colNum;
      }),
      { colNum, field, fileHeaderKey: originalKey },
    ];

    const records = this.#importSchemaInstance.previewRecords.allRecords.map(
      (recordInstance) => {
        return new RecordClientModel({
          ...recordInstance.data,
          entries: recordInstance.allEntries.reduce((result, current) => {
            // 今選択されたマッピングのRecord作成
            if (current.key === originalKey) {
              result.push(
                RecordEntryClientModelFactory.createFromRawValueAndFieldClientModel(
                  current.data.rawValue,
                  field
                )
              );
            } else {
              // 過去分の引継ぎ
              const findFromExsitingMappedFields = this.#mappedFields.find(
                (mappedField) => {
                  return mappedField.fileHeaderKey === current.key;
                }
              );

              if (findFromExsitingMappedFields) {
                result.push(
                  RecordEntryClientModelFactory.createFromRawValueAndFieldClientModel(
                    current.data.rawValue,
                    findFromExsitingMappedFields.field
                  )
                );
              }
            }

            return result;
          }, [] as RecordEntryClientModel[]),
        });
      }
    );

    return new CsvInsertSchemaEditClientModel(
      this.#importSchemaInstance,
      records,
      mappedFields
    );
  }

  removeMappedField(originalKey: string) {
    const mappedFields = this.#mappedFields.filter((mappedField) => {
      return mappedField.fileHeaderKey !== originalKey;
    });

    console.log(mappedFields);

    const records = this.#importSchemaInstance.previewRecords.allRecords.map(
      (recordInstance) => {
        return new RecordClientModel({
          ...recordInstance.data,
          entries: recordInstance.allEntries.reduce((result, current) => {
            const findFromExsitingMappedFields = this.mappedFields.find(
              (mappedField) => {
                return mappedField.fileHeaderKey === current.key;
              }
            );

            if (
              findFromExsitingMappedFields &&
              findFromExsitingMappedFields.fileHeaderKey !== originalKey
            ) {
              result.push(
                RecordEntryClientModelFactory.createFromRawValueAndFieldClientModel(
                  current.data.rawValue,
                  findFromExsitingMappedFields.field
                )
              );
            }

            return result;
          }, [] as RecordEntryClientModel[]),
        });
      }
    );

    return new CsvInsertSchemaEditClientModel(
      this.#importSchemaInstance,
      records,
      mappedFields
    );
  }
}
