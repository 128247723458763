import { useEffect } from "react";

import { CsvImportDialogPresenter } from "~/presenters/csvImport/CsvImportDialogPresenter";
import { useCheckTableSlugExist } from "./useCheckTableSlugExist";
import { useCreateImportSchema } from "./useCreateImportSchema";
import { useCreateStorageObject } from "./useCreateStorageObject";
import { useCsvImportBucket } from "./useCsvImportBucket";
import { useFindImportSchema } from "./useFindImportSchema";
import { useUpdateImportSchema } from "./useUpdateImportSchema";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useRunImportExecutable } from "./useRunImportExecutable";

type CsvImportContainerProps = {
  isOpen: boolean;
  onIsOpenChange: (isOpen: boolean) => void;
};

const CsvImportContainer = (props: CsvImportContainerProps) => {
  const { isOpen, onIsOpenChange } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  /**
   * Bucket & Storage Object
   */
  const { bucketLoadable } = useCsvImportBucket({ teamSlug });

  const { createObjectExecutable, reset: resetStorageObject } =
    useCreateStorageObject({
      bucketLoadable,
      teamSlug,
    });

  const { createImportSchemaExecutable, reset: resetImportSchema } =
    useCreateImportSchema({
      storageObjectInstance: createObjectExecutable.data,
      bucketInstance: bucketLoadable.data,
      databaseId,
      teamSlug,
    });

  const { updateImportSchemaExecutable, reset: resetUpdateImportSchema } =
    useUpdateImportSchema({
      teamSlug,
      databaseId,
    });

  const runImportExecutable = useRunImportExecutable({
    teamSlug,
    databaseId,
  });

  const { findImportSchemaLoadable } = useFindImportSchema(
    createImportSchemaExecutable.data?.id
  );

  const { checkTableSlugExistExecutable } = useCheckTableSlugExist({
    teamSlug,
    databaseId,
  });

  useEffect(() => {
    if (
      createObjectExecutable.data &&
      !createObjectExecutable.data.shouldSelectSheet
    ) {
      // sheetを選択する必要がない場合は、スキーマ作成へ
      createImportSchemaExecutable.execute({});
    }
  }, [createObjectExecutable.data]);

  const handleIsOpenChange = (isOpen: boolean) => {
    onIsOpenChange(isOpen);
    if (!isOpen) {
      resetStorageObject();
      resetImportSchema();
      resetUpdateImportSchema();
    }
  };

  return (
    <>
      <CsvImportDialogPresenter
        isOpen={isOpen}
        onIsOpenChange={handleIsOpenChange}
        createObjectExecutable={createObjectExecutable}
        createImportSchemaExecutable={createImportSchemaExecutable}
        checkTableSlugExistExecutable={checkTableSlugExistExecutable}
        updateImportSchemaExecutable={updateImportSchemaExecutable}
        runImportExecutable={runImportExecutable}
      />
    </>
  );
};

export { CsvImportContainer };
