import { DashboardNormalizedTableScheduleResponse } from "@usemorph/morph-dashboard-types";
import { TimezoneClientModelFactory } from "~/clientModel/timezone";
import { ComputedFieldSyncScheduleClientModel } from "./ComputedFieldSyncScheduleClientModel";
import { ScheduledTimeClientModelFactory } from "../../../scheduleCore/ScheduledTime";
import { TargetDaysClientModelFactory } from "../../../scheduleCore/targetDays";

export class ComputedFieldSyncScheduleClientModelFactory {
  public static createFromDashboardNormalizedTableScheduleResponse(
    normalizedTableSchedule: DashboardNormalizedTableScheduleResponse
  ): ComputedFieldSyncScheduleClientModel {
    const { id, targetDays, scheduledMins, isEnabled, timezone } =
      normalizedTableSchedule;

    return new ComputedFieldSyncScheduleClientModel({
      id,
      targetDays:
        TargetDaysClientModelFactory.createFromTargetDaysArray(targetDays),
      scheduledTime:
        ScheduledTimeClientModelFactory.fromScheduledMins(scheduledMins),
      isEnabled,
      timezone: TimezoneClientModelFactory.fromTimezoneType(timezone),
    });
  }
}
