import { Box, Flex } from "@radix-ui/themes";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { PaginationClientModel } from "~/clientModel/queryConditions/pagination";
import { Button } from "~/components_next/Button";
import { SimpleSelect } from "~/components_next/Select";
import { Text } from "~/components_next/Text";

const limits = [10, 30, 50, 100] as const;

type TablePaginationLimit = (typeof limits)[number];

const limitOptions = limits.map((limit) => ({
  value: limit,
  label: limit.toString(),
}));

type TablePaginationProps = {
  pagination: PaginationClientModel<TablePaginationLimit>;
  recordsCount: number;
  onPaginationChange: (
    pagination: PaginationClientModel<TablePaginationLimit>
  ) => void;
};

const TablePagination = (props: TablePaginationProps) => {
  const { pagination, onPaginationChange, recordsCount } = props;

  const handleLimitChange = (limit: TablePaginationLimit | null) => {
    limit && onPaginationChange(pagination.updateLimit(limit));
  };

  const handlePrevClick = () => {
    onPaginationChange(pagination.prev());
  };

  const handleNextClick = () => {
    onPaginationChange(pagination.next());
  };

  const showingItemsLabel = `${pagination.startAt(
    recordsCount
  )} - ${pagination.endAt(recordsCount)} of ${recordsCount}`;

  return (
    <Flex align="center" gap="2">
      <Box>
        <SimpleSelect
          variant="supplementary"
          value={pagination.limit}
          size="xs"
          onChange={handleLimitChange}
          options={limitOptions}
          getValueStringFromValue={(value: TablePaginationLimit) =>
            value.toString()
          }
        />
      </Box>

      <Button
        size="xs"
        variant="tertiary"
        isDisabled={pagination.isPrevDisabled()}
        onClick={handlePrevClick}
      >
        <BsChevronLeft />
      </Button>
      <Button
        size="xs"
        variant="tertiary"
        isDisabled={pagination.isNextDisabled(recordsCount)}
        onClick={handleNextClick}
      >
        <BsChevronRight />
      </Button>
      <Text variant="description" style={{ whiteSpace: "nowrap" }}>
        {showingItemsLabel}
      </Text>
    </Flex>
  );
};

export { TablePagination, type TablePaginationLimit };
