import { useMemo } from "react";
import { LuMoveRight } from "react-icons/lu";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { Loadable } from "~/clientModel/loadable";
import { Badge } from "~/components_next/Badge";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
import { NativeSimpleSelect } from "~/components_next/Select/NativeSelect/NativeSimpleSelect";
import { Text } from "~/components_next/Text";

type FieldMappingRowProps = {
  field: FieldClientModel;
  fieldsLoadable: Loadable<FieldsClientModel>;
  onSelectMappingField: (field: FieldClientModel) => void;
  onRemoveMappingField: () => void;
  mappedField: FieldClientModel | null;
};

const FieldMappingRow = (props: FieldMappingRowProps) => {
  const {
    field,
    fieldsLoadable,
    onSelectMappingField,
    onRemoveMappingField,
    mappedField,
  } = props;

  const fieldOptions = useMemo(() => {
    const options =
      fieldsLoadable.data?.allFields.map((field) => {
        return {
          value: field.name,
          label: field.displayName || field.name,
        };
      }) || [];

    return [{ value: "DONT", label: "Don't Import" }, ...options];
  }, [fieldsLoadable]);

  const handleSelectField = (fieldName: string | null) => {
    if (!fieldName || fieldName === "DONT") {
      // remove処理
      onRemoveMappingField();
      return;
    }

    const field = fieldsLoadable.data?.allFields.find(
      (field) => field.name === fieldName
    );

    if (field) {
      onSelectMappingField(field);
    }
  };

  return (
    <Box
      css={{
        display: "grid",
        gridTemplateColumns: "240px 20px 160px",
        gap: "$2",
        alignItems: "center",
        padding: "$2",
        "&:hover": {
          backgroundColor: "$bg1",
        },
      }}
    >
      <Flex align="center" gap="2">
        <Text>{field.displayName}</Text>
        <Badge variant="tertiary">{field.type.fieldTypeLabel}</Badge>
      </Flex>

      <LuMoveRight />

      <NativeSimpleSelect
        size="sm"
        variant="primary"
        options={fieldOptions}
        value={mappedField?.name || null}
        onChange={handleSelectField}
      />
    </Box>
  );
};

export { FieldMappingRow };
