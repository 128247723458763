import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
/**
 * Tab types
 */
const preferenceTabs = [
  "myAccount",
  "teamSettings",
  "members",
  "plans",
  "billing",
] as const;

type PreferenceTabType = (typeof preferenceTabs)[number];

const isPreferenceTabType = (value: unknown): value is PreferenceTabType => {
  return (
    typeof value === "string" &&
    (preferenceTabs as readonly string[]).includes(value)
  );
};

/**
 * usePreferenceModal
 */

const QUERY_PARAM_KEY = "preference";

type UsePreferenceModalReturn = {
  onOpen: (initialSelectedTab: PreferenceTabType) => void;
  onClose: () => void;
  modalState:
  | {
    isOpen: true;
    tab: PreferenceTabType;
  }
  | {
    isOpen: false;
  };
  onChangeTab: (tab: PreferenceTabType) => void;
};

// URLから状態を復帰できるように、モーダルの状態(開閉、選択されているタブ)をクエリパラメータに持たせている
const usePreferenceModal = (): UsePreferenceModalReturn => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setTab = useCallback(
    (tab: PreferenceTabType) => {
      setSearchParams((prev) => {
        prev.set(QUERY_PARAM_KEY, tab);
        return prev;
      });
    },
    [setSearchParams]
  );

  const onOpen = useCallback(
    (initialSelectedTab: PreferenceTabType) => {
      setTab(initialSelectedTab);
    },
    [setTab]
  );

  const onClose = useCallback(() => {
    setSearchParams((prev) => {
      prev.delete(QUERY_PARAM_KEY);
      return prev;
    });
  }, [setSearchParams]);

  const modalState = useMemo(() => {
    const selectedTabFromQueryParam = searchParams.get(QUERY_PARAM_KEY);

    if (isPreferenceTabType(selectedTabFromQueryParam)) {
      return {
        isOpen: true,
        tab: selectedTabFromQueryParam,
      } as const;
    } else {
      return {
        isOpen: false,
      } as const;
    }
  }, [searchParams]);

  return { onOpen, onClose, modalState, onChangeTab: setTab };
};

export { usePreferenceModal, type PreferenceTabType };
