import useApiRecord from "~/api/useApiRecord";
import { recordsKeys } from "../keys";

// types
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";

interface UseListRecordsWithSQLParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
  sql: string;
  limit?: number | undefined;
  skip?: number | undefined;
}

const useListRecordsWithSQLQuery = ({
  databaseId,
  tableSlug,
  teamSlug,
  sql,
  limit,
  skip,
}: UseListRecordsWithSQLParams) => {
  const { _getRecordsWithSql } = useApiRecord();

  const client = useQueryClient();
  client.setQueryDefaults(
    recordsKeys.all({ databaseId, tableSlug, teamSlug }),
    commonQueryOptions
  );

  return {
    queryKey: recordsKeys.getRecordWithSql({
      databaseId,
      tableSlug,
      teamSlug,
      sql,
      limit,
      skip,
    }),
    queryFn: () => {
      return _getRecordsWithSql({
        databaseId,
        teamSlug,
        tableSlug,
        sql,
        limit,
        skip,
      });
    },
  };
};

export { useListRecordsWithSQLQuery };
