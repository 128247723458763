type TableClientModelData = {
  tableSlug: string;
  displayName: string | null;
};

export class TableClientModel {
  readonly #data: TableClientModelData;
  constructor(data: TableClientModelData) {
    this.#data = data;
  }

  public get data(): TableClientModelData {
    return this.#data;
  }

  public get tableSlug(): string {
    return this.#data.tableSlug;
  }

  public get displayName(): string | null {
    return this.#data.displayName;
  }

  public get label(): string {
    return this.#data.displayName || this.#data.tableSlug;
  }
}
