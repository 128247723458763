type PlansDependencies = { teamSlug: string };

type ListPlansDependencies = {
  limit?: number;
  skip?: number;
} & PlansDependencies;

const planKeys = {
  all: ({ teamSlug }: PlansDependencies) => [teamSlug, "plan"] as const,

  /**
   * list plans
   */
  allListPlans: ({ teamSlug }: PlansDependencies) =>
    [...planKeys.all({ teamSlug }), "listPlans"] as const,
  listPlans: ({ teamSlug }: ListPlansDependencies) =>
    [...planKeys.allListPlans({ teamSlug })] as const,
};

export { planKeys };
