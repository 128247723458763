import { Handle, Position } from "reactflow";

type EdgeHandleElementProps = {
  type: "source" | "target";
};

const EdgeHandle = (props: EdgeHandleElementProps) => {
  const transform =
    props.type === "source" ? "translate(-8px, 4px)" : "translate(-8px, -4px)";

  const position = props.type === "source" ? Position.Bottom : Position.Top;

  return (
    <Handle
      type={props.type}
      position={position}
      isConnectable
      style={{
        width: "16px",
        height: "16px",
        borderRadius: "var(--radius-4)",
        backgroundColor: "var(--colors-slate4)",
        transform: transform,
      }}
    />
  );
};

export { EdgeHandle };
