import axios, {
  Method,
  AxiosRequestHeaders,
  AxiosResponse,
  AxiosRequestConfig,
} from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useErrorBoundary } from "react-error-boundary";
import { getPath } from "~/routing";
import { useToast } from "~/components_next/Toast";

export default function useApi() {
  const { getIdTokenClaims } = useAuth0();
  const toast = useToast();
  const { showBoundary } = useErrorBoundary();

  const client = axios.create();
  client.interceptors.response.use(
    async (response: AxiosResponse) => {
      const { data, status } = response;
      if (
        "isUnderMaintenance" in data &&
        data.isUnderMaintenance &&
        "message" in data &&
        data.message ===
          "Sorry! We are in maintenance mode and will be back shortly."
      ) {
        localStorage.setItem("isMaintenance", "true");
        if (
          location.href !==
          `${window.location.origin}${getPath("maintenance", {})}`
        ) {
          location.href = `${window.location.origin}${getPath(
            "maintenance",
            {}
          )}`;
        }
        throw response;
      } else {
        localStorage.setItem("isMaintenance", "false");
      }

      if (status !== 200) {
        throw response;
      }

      if (data.error) {
        if (
          data.error === "auth_error" &&
          data.subCode !== 3 &&
          location.pathname !== getPath("expire", {})
        ) {
          location.href = `${window.location.origin}${getPath("expire", {})}`;
        }
        if (
          data.error === "auth_error" &&
          data.subCode === 3 &&
          location.pathname !== "/auth/signup"
        ) {
          location.href = `${window.location.origin}/auth/signup`;
        }
        if (data.error === "database_error") {
          toast({
            title: "Database Error",
            description: data.message,
            type: "error",
          });
          if (
            data.message === "Database does not exist." &&
            location.pathname !== "/404"
          ) {
            location.href = `${window.location.origin}/404`;
          }
        }
        throw response;
      }

      return response;
    },
    async (error) => {
      if (axios.isAxiosError(error) && error.response && error.response.data) {
        throw error.response.data;
      }
      throw error;
    }
  );
  // for raw version
  const clientRaw = axios.create();
  clientRaw.interceptors.response.use(
    async (response: AxiosResponse) => {
      const { data, status } = response;
      if (status !== 200) {
        throw response;
      }
      if (data.error) {
        if (data.error === "payment_error") {
          showBoundary(data);
        }
        console.error("Returned axios data has some error");
      }
      return response;
    },
    async (error) => {
      throw error;
    }
  );

  // common
  const getGeneralHeaders = async (): Promise<AxiosRequestHeaders> => {
    const token = await getIdTokenClaims();
    if (token) {
      return {
        "client-type": "widget",
        Authorization: `Bearer ${token.__raw}`,
        // 'x-api-key': import.meta.env.VITE_API_KEY,
      };
    }
    return {
      // 'x-api-key': import.meta.env.VITE_API_KEY,
    };
  };

  const executeRequest = async <T>(
    method: Method,
    path: string,
    params?: any,
    header?: any,
    body?: any,
    additionalOptions?: Omit<
      AxiosRequestConfig,
      "method" | "url" | "params" | "headers" | "data"
    >
  ) => {
    let headers = await getGeneralHeaders();
    headers = { ...headers, ...header };
    const response = await client({
      method,
      url: `${import.meta.env.VITE_API_URL}${path}`,
      params,
      headers,
      data: body,
      ...additionalOptions,
    });
    const { data } = response;
    return data as T;
  };

  const executeRequestAxiosRaw = async <T>(
    method: Method,
    path: string,
    params?: any,
    header?: AxiosRequestHeaders | undefined,
    body?: any,
    additionalOptions?: Omit<
      AxiosRequestConfig,
      "method" | "url" | "params" | "headers" | "data"
    >
  ) => {
    let headers = await getGeneralHeaders();
    headers = { ...headers, ...header };
    const response = await clientRaw({
      method,
      url: `${import.meta.env.VITE_API_URL}${path}`,
      params,
      headers,
      data: body,
      ...additionalOptions,
    });
    return response as AxiosResponse<T>;
  };

  // ===========================================
  // ===========================================
  // ===========================================
  // ===========================================
  // ==========================================

  const makeSWR = <P, R>(key: string, params: P, func: (p: P) => R) => {
    return func(params);
  };

  return {
    makeSWR,
    executeRequest,
    executeRequestAxiosRaw,
  };
}
