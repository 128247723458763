import { useMutation } from "react-query";
import { match, P } from "ts-pattern";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import { FieldClientModel } from "~/clientModel/fields/field";
import {
  FilterConditionsClientModel,
  FilterConditionsClientModelDefactory,
} from "~/clientModel/queryConditions/filterConditions";
import { RecordIdentifierClientModelDefactory } from "~/clientModel/records/record/recordIdentifier";
import { TableSelectionClientModel } from "~/clientModel/tables/tableMeta/TableSelection";

import { useErrorToast } from "~/components_next/Error";
import { useStartBulkUpdateRecordByPromptMutation } from "~/serverStateStore";

type UseBulkEditRecordsByPromptExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  filterConditions: FilterConditionsClientModel;
  tableSelection: TableSelectionClientModel;
};

const useBulkEditRecordsByPromptExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
  filterConditions,
  tableSelection,
}: UseBulkEditRecordsByPromptExecutableParams): Executable<
  { prompt: string; field: FieldClientModel },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  const bulkUpdateRecordsByPromptOptions =
    useStartBulkUpdateRecordByPromptMutation({
      teamSlug,
      databaseId,
      tableSlug,
    });

  return useComposeExecutable(
    useMutation({
      ...bulkUpdateRecordsByPromptOptions,
      mutationFn: ({
        prompt,
        field,
      }: {
        prompt: string;
        field: FieldClientModel;
      }) => {
        const filter = match([
          filterConditions.hasValidFilterConditions,
          !!tableSelection.selectedRecordsIdentifiers.length,
        ])
          .with([false, false], () => ({ or: [] }))
          .with([true, false], () =>
            FilterConditionsClientModelDefactory.toRecordFilterCondition(
              filterConditions
            )
          )
          .with([P._, true], () => ({
            or: tableSelection.selectedRecordsIdentifiers.map((recordId) =>
              RecordIdentifierClientModelDefactory.toRecordFilterCondition(
                recordId
              )
            ),
          }))
          .exhaustive();

        return bulkUpdateRecordsByPromptOptions.mutationFn({
          prompt,
          filter,
          fieldName: field.data.name,
        });
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseBulkEditRecordsByPromptExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
  filterConditions,
  tableSelection,
}: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  filterConditions: FilterConditionsClientModel;
  tableSelection: TableSelectionClientModel;
}) => {
  return () =>
    useBulkEditRecordsByPromptExecutable({
      teamSlug,
      databaseId,
      tableSlug,
      filterConditions,
      tableSelection,
    });
};
