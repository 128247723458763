import { Separator } from "@radix-ui/themes";
import { BsThreeDotsVertical, BsTrash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { DatabaseClientModel } from "~/clientModel/database/DatabaseClientModel";
import { ListDatabaseClientModel } from "~/clientModel/database/listDatabaseClientModel";
import { Executable } from "~/clientModel/executable";
import { MessageClientModel } from "~/clientModel/general";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { DangerDialog } from "~/components_next/Dialog/DangerDialog";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { useErrorToast } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { IconButton } from "~/components_next/IconButton";
import { Input } from "~/components_next/Input";
import { Spacer } from "~/components_next/Spacer";
import { useDisclosure } from "~/hooks/useDisclosure";
import { useReactiveState } from "~/hooks/useReactiveState";
import { getPath } from "~/routing";
import {
  DeleteDatabaseMutationProps,
  UpdateDatabaseMutationProps,
} from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

interface DatabaseOptionPopoverProps {
  listDatabaseClientModel: ListDatabaseClientModel;
  updateDatabaseExecutable: Executable<UpdateDatabaseMutationProps, DatabaseClientModel>
  deleteDatabaseExecutable: Executable<DeleteDatabaseMutationProps, MessageClientModel>
}

const DatabaseOptionPopover = (props: DatabaseOptionPopoverProps) => {

  const {
    listDatabaseClientModel,
    updateDatabaseExecutable,
    deleteDatabaseExecutable,
  } = props

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const [databaseName, setDatabaseName] = useReactiveState(listDatabaseClientModel.findDatabase(databaseId)?.databaseName || "")

  const deleteDatabaseModal = useDisclosure();
  const { errorToast } = useErrorToast({});
  const navigate = useNavigate();

  // const { data: listDatabase } = useQuery({
  //   ...useListDatabasesQuery({
  //     teamSlug,
  //   }),
  // });

  // const database = useMemo(() => {
  //   return listDatabase?.items.find((l) => l.databaseId === databaseId);
  // }, [databaseId, listDatabase?.items]);

  // useEffect(() => {
  //   if (database) {
  //     setDatabaseName(database.databaseName);
  //   }
  // }, [database]);

  /**
   * Mutations
   */
  // const { mutateAsync: updateDatabase, isLoading: isUpdating } = useMutation({
  //   ...useUpdateDatabaseMutation({
  //     teamSlug,
  //   }),
  // });

  // const { mutateAsync: deleteDatabase, isLoading: isDeleting } = useMutation({
  //   ...useDeleteDatabaseMutation({
  //     teamSlug,
  //   }),
  // });

  const handleSaveClicked = () => {
    updateDatabaseExecutable.execute({
      databaseId,
      databaseName,
    });
  };

  const handleDeleteClicked = () => deleteDatabaseModal.onOpen();

  const executeDeleteDatabase = async () => {
    try {
      await deleteDatabaseExecutable.execute({ databaseId });
      deleteDatabaseModal.onClose();

      if (listDatabaseClientModel && listDatabaseClientModel.items.length > 0) {
        const activeDatabases = listDatabaseClientModel.items.filter(
          (database) => database.databaseId !== databaseId
        );
        navigate(
          getPath("databaseHome", {
            teamSlug,
            databaseId: activeDatabases[0].databaseId,
          })
        );
        return;
      } else {
        navigate(`/${teamSlug}`);
      }
    } catch (e) {
      errorToast(e);
    }
  };

  return (
    <>
      <SimpleDropdownMenu
        key={databaseId}
        trigger={
          <IconButton
            icon={<BsThreeDotsVertical />}
            tooltip="Database settings"
            isLoading={updateDatabaseExecutable.isExecuting || deleteDatabaseExecutable.isExecuting}
            size="sm"
          />
        }
      >
        <Box p="3" css={{ minWidth: "300px" }}>
          <Input
            variant="primary"
            label="Name"
            value={databaseName}
            onChange={(event) => {
              setDatabaseName(event.target.value);
            }}
          />
          <Flex mt="3">
            <Spacer />
            <Button
              variant="primary"
              onClick={handleSaveClicked}
              isLoading={updateDatabaseExecutable.isExecuting}
              size="sm"
            >
              Save
            </Button>
          </Flex>

          <Separator my="3" size="4" />
          <Flex>
            <Spacer />
            <Button
              variant="actionText"
              size="sm"
              onClick={handleDeleteClicked}
              isLoading={deleteDatabaseExecutable.isExecuting}
            >
              <BsTrash />
              Delete Database
            </Button>
          </Flex>
        </Box>
      </SimpleDropdownMenu>
      <DangerDialog
        isOpen={deleteDatabaseModal.isOpen}
        onOpenChange={deleteDatabaseModal.setIsOpen}
        description={`Are you sure you want to delete this database? You Can't undo this action.`}
        onConfirm={executeDeleteDatabase}
      />
    </>
  );
};

export { DatabaseOptionPopover };
