import { useEditorOrThrow } from "../utils";

const useRedo = () => {
  const editor = useEditorOrThrow();

  const handleRedo = () => {
    editor.chain().focus().redo().run();
  };

  const isDisabled = !editor.can().chain().focus().redo().run();

  return {
    handleRedo,
    isDisabled,
  };
};

export { useRedo };
