import { FallbackProps } from "react-error-boundary";
import { extractErrorDetails } from "~/components_next/Error";
import { Button } from "~/components_next/Button";
import { Flex } from "~/components_next/Flex";
import { Image } from "~/components_next/Image";
import { Text } from "~/components_next/Text";

const RootErrorBoundaryFallback = ({
  error,
  resetErrorBoundary,
}: FallbackProps) => {
  const { title, description } = extractErrorDetails(error);

  // それ以外のフォールバック
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      position="fixed"
      inset="0"
      gap="8"
    >
      <Image style={{ height: "40px" }} src="/MorphLogo.png" />
      <Flex direction="column" align="center" gap="2">
        <Text variant="heading">{title}</Text>
        <Text variant="default">{description}</Text>
      </Flex>
      <Flex direction="row" gap="4">
        {/* todo: main.tsxとApp.tsxを整理する */}
        {/* <Link to="/"> */}
        <Button variant="secondary">Back to top</Button>
        {/* </Link> */}
        <Button variant="primary" onClick={resetErrorBoundary}>
          Refresh
        </Button>
      </Flex>
    </Flex>
  );
};

export { RootErrorBoundaryFallback };
