import { DashboardMeObject, TimezoneType, TimezoneWithOffset } from "@usemorph/morph-dashboard-types";
import { ChangeEvent, useState } from "react";
import { useMutation } from "react-query";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { useErrorToast } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { Input } from "~/components_next/Input";
import { SimpleSelect } from "~/components_next/Select";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";
import { useToast } from "~/components_next/Toast";
import { IconForm } from "~/features/Common/IconForm";
import {
  useMe,
  useSelectedMeTeam,
  useUpdateLoginUserMutation,
} from "~/serverStateStore/me";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

export const MyAccount = () => {
  const teamSlug = useTeamSlug();
  const toast = useToast();
  const { errorToast } = useErrorToast({});

  const timezoneOptions = TimezoneWithOffset

  const { data: me } = useMe();
  const { data: selectedTeam } = useSelectedMeTeam({ teamSlug });

  const [localUser, setLocalUser] = useState<DashboardMeObject>({
    username: me?.username ?? "",
    firstName: me?.firstName ?? "",
    lastName: me?.lastName ?? "",
    userId: me?.userId ?? "",
    email: me?.email ?? "",
    iconUrl: me?.iconUrl ?? null,
    teams: me?.teams ?? [],
    language: me?.language ?? "en",
    timezone: me?.timezone ?? "UTC",
  });

  const onIconUrlChange = (url: string) => {
    setLocalUser({ ...localUser, iconUrl: url });
  };

  const onUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalUser({ ...localUser, username: event.target.value });
  };

  const onFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalUser({ ...localUser, firstName: event.target.value });
  };

  const onLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalUser({ ...localUser, lastName: event.target.value });
  };

  const onTimezoneChange = (timezone: TimezoneType) => {
    setLocalUser({ ...localUser, timezone })
  }

  const { mutateAsync: updateLoginUser, isLoading: isUpdatingLoginUser } =
    useMutation(useUpdateLoginUserMutation());

  const updateMe = async () => {
    if (localUser) {
      try {
        await updateLoginUser({
          requestBody: {
            username: localUser.username,
            lastName: localUser.lastName,
            firstName: localUser.firstName,
            iconUrl: localUser.iconUrl !== null ? localUser.iconUrl : undefined,
            timezone: localUser.timezone ?? "UTC",
          }
        });
        toast({
          title: "Account Updated.",
          description: "Your account was successfully updated.",
          type: "success",
          duration: 5000,
        });
      } catch (e) {
        errorToast(e);
      }
    }
  };

  return (
    <>
      <Flex align={"center"} mb="5">

        <Box mr="4">
          {selectedTeam?.teamSlug && localUser && (
            <IconForm
              teamSlug={selectedTeam.teamSlug}
              user={{ ...localUser }}
              onUpdate={onIconUrlChange}
            ></IconForm>
          )}
        </Box>

        <Box grow="1">
          <Box my="4">
            <Text>User name</Text>
            <Input
              variant="primary"
              value={localUser?.username}
              onChange={onUsernameChange}
            />
          </Box>

          <Box my="4">
            <Text>First name</Text>
            <Input
              variant="primary"
              value={localUser?.firstName}
              onChange={onFirstNameChange}
            />
          </Box>

          <Box my="4">
            <Text>Last name</Text>
            <Input
              variant="primary"
              value={localUser?.lastName}
              onChange={onLastNameChange}
            />
          </Box>

          <Box mt="4">
            <SimpleSelect
              label="Timezone"
              variant="primary"
              value={localUser.timezone}
              onChange={(el) => {
                if (el) {
                  onTimezoneChange(el as TimezoneType)
                }
              }}
              size="sm"
              options={
                timezoneOptions.map(opt => {
                  return (
                    { label: opt.label, value: opt.value }
                  )
                })
              }
            />
          </Box>
        </Box>
      </Flex>

      <Flex>
        <Spacer />
        <Button
          onClick={updateMe}
          variant="primary"
          size="sm"
          isLoading={isUpdatingLoginUser}
        >
          Save
        </Button>
      </Flex>
    </>
  );
};
