import {
  DashboardSimpleImportDataListResponse,
  DashboardImportDataRequestBody,
  DashboardRunImportIntegrationDataRequestBody,
  DashboardSimpleUpdateImportDataSchemaRequestBody,
  DashboardImportIntegrationStatusResponse,
  DashboardImportIntegrationStatusListResponse,
  DashboardImportIntegrationScheduleListResponse,
  DashboardImportIntegrationScheduleResponse,
  DashboardUpdateImportIntegrationScheduleRequestBody,
  DashboardCreateImportIntegrationScheduleRequestBody,
  DashboardSimpleImportDataResponse,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";
type DashboardGeneralResponse = {
  message: string;
};

export default function useApiImport() {
  const { executeRequest } = useApi();

  const _listImportData = async ({
    databaseId,
    branchSlug,
    limit,
    skip,
    teamSlug,
    action,
    from,
  }: {
    databaseId: string;
    branchSlug?: string;
    teamSlug: string;
    limit?: number;
    skip?: number;
    action?: "create" | "add";
    from?: string;
  }): Promise<DashboardSimpleImportDataListResponse> => {
    return executeRequest<DashboardSimpleImportDataListResponse>(
      "GET",
      `/${databaseId}/simple-import`,
      {
        branchSlug,
        limit,
        skip,
        action,
        from,
      },
      { teamSlug },
      undefined
    );
  };

  const _createImportDataFromExcelCsv = async ({
    databaseId,
    requestBody,
    teamSlug,
  }: {
    databaseId: string;
    teamSlug: string;
    requestBody: DashboardImportDataRequestBody;
  }): Promise<DashboardSimpleImportDataResponse> => {
    return executeRequest<DashboardSimpleImportDataResponse>(
      "POST",
      `/${databaseId}/simple-import`,
      {},
      { teamSlug },
      {
        ...requestBody,
      }
    );
  };

  const _updateImportData = async ({
    databaseId,
    importDataSchemaId,
    requestBody,
    teamSlug,
  }: {
    databaseId: string;
    importDataSchemaId: string;
    requestBody: DashboardSimpleUpdateImportDataSchemaRequestBody;
    teamSlug: string;
  }): Promise<DashboardSimpleImportDataResponse> => {
    return executeRequest<DashboardSimpleImportDataResponse>(
      "PUT",
      `/${databaseId}/simple-import/${importDataSchemaId}`,
      undefined,
      { teamSlug },
      {
        ...requestBody,
      }
    );
  };

  const _findImportDataSchema = async ({
    databaseId,
    importDataSchemaId,
    teamSlug,
  }: {
    databaseId: string;
    importDataSchemaId: string;
    teamSlug: string;
  }): Promise<DashboardSimpleImportDataResponse> => {
    return executeRequest<DashboardSimpleImportDataResponse>(
      "GET",
      `/${databaseId}/simple-import/${importDataSchemaId}`,
      undefined,
      { teamSlug },
      undefined
    );
  };

  const _runImport = async ({
    databaseId,
    importDataSchemaId,
    teamSlug,
  }: {
    databaseId: string;
    importDataSchemaId: string;
    teamSlug: string;
  }): Promise<DashboardGeneralResponse> => {
    // Promise<DashboardGeneralResponse | ErrorResponse>
    return executeRequest<DashboardGeneralResponse>(
      "POST",
      `/${databaseId}/import/${importDataSchemaId}`,
      undefined,
      { teamSlug },
      undefined
    );
  };

  const _importFormExternal = async ({
    databaseId,
    teamSlug,
    body,
  }: {
    databaseId: string;
    teamSlug: string;
    body: DashboardRunImportIntegrationDataRequestBody;
  }) => {
    return executeRequest<DashboardImportIntegrationStatusResponse>(
      "POST",
      `/${databaseId}/import-integration`,
      undefined,
      { teamSlug },
      body
    );
  };

  type listImportStatusFromExternalRequestBody = {
    limit?: number;
    skip?: number;
    tableSlug?: string;
  };
  const _listImportStatusFromExternal = async ({
    databaseId,
    teamSlug,
    body,
  }: {
    databaseId: string;
    teamSlug: string;
    body?: listImportStatusFromExternalRequestBody;
  }) => {
    return executeRequest<DashboardImportIntegrationStatusListResponse>(
      "GET",
      `/${databaseId}/import-integration`,
      undefined,
      { teamSlug },
      body
    );
  };

  const _listImportIntegrationHistories = async ({
    databaseId,
    tableSlug,
    teamSlug,
    limit,
    skip,
  }: {
    databaseId: string;
    tableSlug?: string;
    teamSlug: string;
    limit?: number;
    skip?: number;
  }) => {
    return executeRequest<DashboardImportIntegrationScheduleListResponse>(
      "GET",
      `/${databaseId}/import-integration-history`,
      {
        tableSlug,
        limit,
        skip,
      },
      { teamSlug },
      undefined
    );
  };

  const _listImportIntegrationSchedule = async ({
    databaseId,
    teamSlug,
    tableSlug,
    limit,
    skip,
  }: {
    databaseId: string;
    teamSlug: string;
    tableSlug?: string;
    limit?: number;
    skip?: number;
  }) => {
    return executeRequest<DashboardImportIntegrationScheduleListResponse>(
      "GET",
      `/${databaseId}/import-integration-schedule`,
      {
        tableSlug,
        limit,
        skip,
      },
      { teamSlug },
      undefined
    );
  };

  const _findImportIntegrationSchedule = async ({
    databaseId,
    importIntegrationScheduleId,
    teamSlug,
  }: {
    databaseId: string;
    importIntegrationScheduleId?: string;
    teamSlug: string;
  }) => {
    return executeRequest<DashboardImportIntegrationScheduleResponse>(
      "GET",
      `/${databaseId}/import-integration-schedule/${importIntegrationScheduleId}`,
      undefined,
      { teamSlug },
      undefined
    );
  };

  const _createImportIntegrationSchedule = async ({
    databaseId,
    teamSlug,
    body,
  }: {
    databaseId: string;
    teamSlug: string;
    body: DashboardCreateImportIntegrationScheduleRequestBody;
  }) => {
    return executeRequest<DashboardImportIntegrationScheduleResponse>(
      "POST",
      `/${databaseId}/import-integration-schedule`,
      undefined,
      { teamSlug },
      {
        ...body,
      }
    );
  };

  const _updateImportIntegrationSchedule = async ({
    databaseId,
    importIntegrationScheduleId,
    teamSlug,
    body,
  }: {
    databaseId: string;
    importIntegrationScheduleId?: string;
    teamSlug: string;
    body: DashboardUpdateImportIntegrationScheduleRequestBody;
  }) => {
    return executeRequest<DashboardImportIntegrationScheduleResponse>(
      "PUT",
      `/${databaseId}/import-integration-schedule/${importIntegrationScheduleId}`,
      undefined,
      { teamSlug },
      {
        ...body,
      }
    );
  };

  const _deleteImportIntegrationSchedule = async ({
    databaseId,
    importIntegrationScheduleId,
    teamSlug,
  }: {
    databaseId: string;
    importIntegrationScheduleId?: string;
    teamSlug: string;
  }) => {
    return executeRequest<DashboardGeneralResponse>(
      "DELETE",
      `/${databaseId}/import-integration-schedule/${importIntegrationScheduleId}`,
      undefined,
      { teamSlug },
      undefined
    );
  };

  return {
    _listImportData,
    _createImportDataFromExcelCsv,
    _updateImportData,
    _findImportDataSchema,
    _runImport,
    _importFormExternal,
    _listImportStatusFromExternal,
    _listImportIntegrationHistories,
    _listImportIntegrationSchedule,
    _findImportIntegrationSchedule,
    _createImportIntegrationSchedule,
    _updateImportIntegrationSchedule,
    _deleteImportIntegrationSchedule,
  };
}
