import { useQuery } from "react-query";
import {
  useFindViewQuery,
  useViewFields,
  useQueryViewRecordsQuery,
} from "~/serverStateStore/views";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useViewId } from "~/utilHooks/useViewId";
import { convertRecordToRecordModel } from "~/features/RecordModel";
import { useFilterCondition } from "../states/filterCondition";
import { useSortCondition } from "../states/sortCondition";
import { useSkip } from "../states/skip";
import { useLimit } from "../states/limit";
import { useQueryMode } from "../states/queryMode";
import { UseViewRecordsResult } from "./useViewRecords";

const useViewRecordsWithQuery = (): UseViewRecordsResult => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  const queryMode = useQueryMode(viewId);

  // with filter and sort
  const filterCondition = useFilterCondition(viewId);
  const sortCondition = useSortCondition(viewId);
  const skip = useSkip(viewId);
  const limit = useLimit(viewId);

  const {
    data: viewRecordsWithQueryData,
    status: viewRecordsWithQueryStatus,
    error: viewRecordsWithQueryError,
  } = useQuery({
    ...useQueryViewRecordsQuery({
      teamSlug,
      databaseId,
      viewId,
      select: ["*"],
      filter: filterCondition ?? undefined,
      sort: sortCondition ? [sortCondition] : undefined,
      skip,
      limit,
    }),
    enabled: !queryMode || queryMode === "filtersAndSorts",
  });

  const {
    data: viewData,
    status: viewStatus,
    error: viewError,
  } = useQuery(useFindViewQuery({ teamSlug, databaseId, viewId }));

  const {
    data: fieldsData,
    status: fieldsStatus,
    error: fieldsError,
  } = useViewFields({
    teamSlug,
    databaseId,
    viewId,
    viewData,
  });

  if (
    viewRecordsWithQueryStatus === "error" ||
    fieldsStatus === "error" ||
    viewStatus === "error"
  ) {
    return {
      status: "error",
      data: undefined,
      error: viewRecordsWithQueryError ?? fieldsError ?? viewError,
    };
  }

  if (
    viewRecordsWithQueryStatus === "loading" ||
    fieldsStatus === "loading" ||
    viewStatus === "loading"
  ) {
    return {
      status: "loading",
      data: undefined,
      error: undefined,
    };
  }

  if (
    viewRecordsWithQueryStatus === "idle" ||
    fieldsStatus === "idle" ||
    viewStatus === "idle"
  ) {
    return {
      status: "idle",
      data: undefined,
      error: undefined,
    };
  }

  return {
    status: "success",
    data: {
      recordsTableBaseRecords: viewRecordsWithQueryData.items.map(
        (record, index) => {
          return {
            values: convertRecordToRecordModel(record, fieldsData.fields),
            _reservedRecordIndex: index,
          };
        }
      ),
      count: viewRecordsWithQueryData.count,
      fields: fieldsData.fields,
    },
    error: undefined,
  };
};

export { useViewRecordsWithQuery };
