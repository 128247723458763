import { FieldClientModel } from "~/clientModel/fields/field";
import {
  ValidationResult,
  zValidate,
} from "~/clientModel/utils/validationResultType";
import {
  FilterOperationClientModelBase,
  FilterOperationWithValueInterface,
} from "./FilterOperationClientModelBase";
import { zStringArrayValidation } from "./zValidations";

type InFilterOperationClientModelData = {
  value: string[];
};

export class InFilterOperationClientModel
  extends FilterOperationClientModelBase
  implements
    FilterOperationWithValueInterface<string[], InFilterOperationClientModel>
{
  readonly #data: InFilterOperationClientModelData;

  public constructor(data: InFilterOperationClientModelData) {
    super();
    this.#data = data;
  }

  public get data() {
    return this.#data;
  }

  public get type(): "in" {
    return "in";
  }

  public get operatorValue(): "in" {
    return "in";
  }

  public get operatorLabel(): "In" {
    return "In";
  }

  public get operandValue(): string[] {
    return this.#data.value;
  }

  public updateValue(value: string[]): InFilterOperationClientModel {
    return new InFilterOperationClientModel({
      ...this.#data,
      value,
    });
  }

  public validate(targetField: FieldClientModel): ValidationResult {
    return zValidate(zStringArrayValidation(targetField), this.#data.value);
  }
}
