import { FieldClientModel } from "~/clientModel/fields/field";
import {
  ReadonlyRecordEntryClientModelBase,
  ReadonlyRecordEntryClientModelBaseConstructorParams,
} from "../RecordEntryClientModelBase";
import {
  zValidate,
  zRequiredString,
  zAllowNullIfNullable,
} from "../validatorsUtil";
import { z } from "zod";
import { DateDisplaySettingClientModel } from "~/clientModel/fields/field/displaySetting/date";

type LastEditedAtRecordEntryClientModelData =
  ReadonlyRecordEntryClientModelBaseConstructorParams;

export class LastEditedAtRecordEntryClientModel extends ReadonlyRecordEntryClientModelBase<
  string | null
> {
  constructor(data: LastEditedAtRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "lastEditedAt" {
    return "lastEditedAt";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([zRequiredString(field), zAllowNullIfNullable(field)]),
      this.data.rawValue
    );
  }

  public getValidatedDisplayValue(field: FieldClientModel) {
    const validatedValue = this.getValidatedValue(field);

    return validatedValue.isValid &&
      field.displaySetting instanceof DateDisplaySettingClientModel
      ? ({
          isValid: true,
          value: field.displaySetting.getDisplayValue(validatedValue.value),
        } as const)
      : validatedValue;
  }
}
