import { useMutation } from "react-query";
import { DatabaseClientModelFactory } from "~/clientModel/database/DatabaseClientModel";
import { useComposeExecutable } from "~/clientModel/executable";
import { UpdateDatabaseMutationProps, useUpdateDatabaseMutation } from "~/serverStateStore";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

export const useUpdateDatabase = () => {
  const teamSlug = useTeamSlug();

  const updateDatabaseMutationOptions = useUpdateDatabaseMutation({ teamSlug })
  const updateDatabaseMutationResult = useMutation({
    ...updateDatabaseMutationOptions,
    mutationFn: async (params: UpdateDatabaseMutationProps) => {
      const response = await updateDatabaseMutationOptions.mutationFn(params)
      return DatabaseClientModelFactory.fromfindDatabaseResponse(response)
    }
  })

  const updateDatabaseExecutable = useComposeExecutable(updateDatabaseMutationResult)

  return {
    updateDatabaseExecutable,
  }
}
