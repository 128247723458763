import { DashboardTemplateDetailResponse } from "@usemorph/morph-dashboard-types";

export type AuthConfigType = {
  apiType: "apiKey" | "orm" | "oauth2" | "snowflake";
  authorizeUrl?: string | undefined;
  loginButtonImage?: {
    light: string;
    dark: string;
  } | undefined;
}

export type IntegrationInputType = {
  type: "string" | "number" | "boolean" | "select" | "password" | "date" | "sql" | "textarea" | "dynamic";
  key: string;
  description: string;
  label: string;
  required: boolean;
  members?: string[] | undefined;
  descritpionUrlLabel?: string | undefined;
  descritpionUrl?: string | undefined;
  dependsOn?: string[] | undefined;
}

export type InputFormType = {
  inputs: IntegrationInputType[];
}

export type IntegrationType = {
  description: string;
  integrationId: string;
  integrationName: string;
  inputForm: {
    inputs: IntegrationInputType[];
  };
  outputTables: OutputTableType[];
  syncOptions: {
    sync: boolean;
    append: boolean;
  };
  triggerDescription?: string;
}

export type OutputTableType = {
  slug: string;
  isMain: boolean;
  description: string;
  defaultName: string;
}

export class FindTemplateDetailClientModel {
  constructor(private readonly data: DashboardTemplateDetailResponse) { }

  /**
   * Getters
   */
  get templateId(): string {
    return this.data.templateId;
  }

  get templateName(): string {
    return this.data.templateName;
  }

  get description(): string {
    return this.data.description;
  }

  get logoUrl(): string {
    return this.data.logoUrl;
  }

  get authConfig(): AuthConfigType {
    return this.data.authConfig;
  }

  get authInputForm(): InputFormType {
    return this.data.authInputForm;
  }

  get integrations(): IntegrationType[] {
    return this.data.integrations;
  }

  get isPremium(): boolean {
    return this.data.isPremium;
  }

}
