import { SingleSelectFieldTypeClientModel } from "~/clientModel/fields/field/fieldType";
import { MembersInput } from "./MembersInput";

type SingleSelectMembersInputProps = {
  singleSelectType: SingleSelectFieldTypeClientModel;
  onFieldTypeChange: (field: SingleSelectFieldTypeClientModel) => void;
  isReadOnly?: boolean;
};

export const SingleSelectMembersInput = (
  props: SingleSelectMembersInputProps
) => {
  const { singleSelectType, onFieldTypeChange, isReadOnly } = props;

  return (
    <>
      <MembersInput
        members={singleSelectType.members}
        onAddMember={(member) =>
          onFieldTypeChange(singleSelectType.addMember(member))
        }
        onDeleteMember={(member) =>
          onFieldTypeChange(singleSelectType.deleteMember(member))
        }
        isReadOnly={isReadOnly}
      />
    </>
  );
};
