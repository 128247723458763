import { Flex } from "@radix-ui/themes";
import { ErrorFallback } from "~/components_next/Error";
import { Divider } from "~/components_next/Divider";
import { Spinner } from "~/components_next/Spinner";
import { PromptHistoryCell } from "./PromptHistoryCell";
import { PromptHistoriesClientModel } from "~/clientModel/promptHistories";
import { Loadable } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { PromptHistoryClientModel } from "~/clientModel/promptHistories/promptHistory";

type PromptHistoriesProps = {
  promptHistoriesLoadable: Loadable<PromptHistoriesClientModel>;
  onHistoryClick: (history: PromptHistoryClientModel) => void;
};

const PromptHistories = (props: PromptHistoriesProps) => {
  const { promptHistoriesLoadable, onHistoryClick } = props;

  return (
    <WithFallback
      loadables={[promptHistoriesLoadable] as const}
      loadingFallback={<LoadingFallback />}
      errorFallback={(error) => <ErrorFallback error={error} />}
    >
      {([promptHistories]) =>
        promptHistories.count > 0 ? (
          <Flex align="stretch" direction="column">
            {promptHistories.allHistories.map((history, i) => (
              <HistoryCellWithDivider
                key={i}
                history={history}
                onHistoryClick={() => onHistoryClick(history)}
                isLast={i === promptHistories.count - 1}
              />
            ))}
          </Flex>
        ) : (
          <NoHistoriesFallback />
        )
      }
    </WithFallback>
  );
};

export { PromptHistories };

const LoadingFallback = () => {
  return (
    <Flex style={{ height: "100%" }} align="center" justify="center">
      <Spinner />
    </Flex>
  );
};

type HistoryCellWithDividerProps = {
  history: PromptHistoryClientModel;
  isLast: boolean;
  onHistoryClick: () => void;
};

const HistoryCellWithDivider = (props: HistoryCellWithDividerProps) => {
  const { history, isLast, onHistoryClick } = props;

  return (
    <>
      <PromptHistoryCell history={history} onHistoryClick={onHistoryClick} />
      {!isLast && <Divider />}
    </>
  );
};

const NoHistoriesFallback = () => {
  return (
    <Flex height="100%" align="center" justify="center">
      No histories
    </Flex>
  );
};
