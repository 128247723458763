import { memo, useMemo } from "react";
import { useNodes } from "reactflow";
import { match, P } from "ts-pattern";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import {
  CanvasCellPromptClientModel,
  CanvasCellVariablesClientModel,
} from "~/clientModel/canvas/CanvasCellClientModel";
import { UseExecutable } from "~/clientModel/executable";
import { Box } from "~/components_next/Box";

import { useErrorToast } from "~/components_next/Error";
import { RightSidebar } from "~/components_next/RightSidebar";
import { styled } from "~/stitches";
import { PlaygroundSidebarVariables } from "./variables/PlaygroundSidebarVariables";
import { PlaygroundSidebarPrompt } from "./prompt/PlaygroundSidebarPrompt";
import { UseVariableOptionsViewResultLoadable } from "../../common/CanvasVariableOptionsLoadableProvider";

const SidebarWrapper = styled(Box, {
  position: "fixed",
  top: "40px",
  right: 0,
  bottom: 0,
  backgroundColor: "$bg0",
});

type PlaygroundSidebarProps = {
  cells: CanvasCellClientModelUnion[];
  useUpdateCellExecutable: UseExecutable<
    void,
    {
      cell: CanvasCellClientModelUnion;
      shouldGenerate?: boolean;
    },
    CanvasCellClientModelUnion,
    unknown
  >;
  useDeleteCellExecutable: UseExecutable<
    void,
    { cellId: string },
    unknown,
    unknown
  >;
  useVariableOptionsLoadable: UseVariableOptionsViewResultLoadable;
  width: number;
  onResize: (width: number) => void;
};

const PlaygroundSidebar = (props: PlaygroundSidebarProps) => {
  const {
    cells,
    useDeleteCellExecutable,
    useUpdateCellExecutable,
    useVariableOptionsLoadable,
    width,
    onResize,
  } = props;

  const updateCellExecutable = useUpdateCellExecutable();

  /**
   * React Flow Selection
   */
  const allNodes = useNodes();
  const selectedNodes = allNodes.filter((node) => node.selected);
  const selectedNodeIds = selectedNodes.map((node) => node.id);

  const selectedCells = cells.filter((cell) => {
    return selectedNodeIds.includes(cell.cellId);
  });
  const selectedCell = useMemo(() => {
    // console.log(`Sidebar: selectedCells: ${selectedCells.length}`);
    if (selectedCells.length === 1) return selectedCells[0];
    return undefined;
  }, [selectedCells]);

  /**
   * Event handlers
   */
  const { errorToast } = useErrorToast({});
  const deleteCellExecutable = useDeleteCellExecutable();
  const handleRemoveCell = () => {
    if (!selectedCell) {
      errorToast("No cell selected");
      return;
    }
    deleteCellExecutable.execute({
      cellId: selectedCell.cellId,
    });
  };

  if (!selectedCell) return null;

  if (
    selectedCell.cellType !== "prompt" &&
    selectedCell.cellType !== "variables"
  )
    return null;

  return (
    <SidebarWrapper>
      <RightSidebar height={"100%"} width={width} onResizeDone={onResize}>
        {/* <Flex mb="2">
          <Spacer />
          <SimpleDropdownMenu
            trigger={
              <IconButton icon={<BsThreeDotsVertical />} tooltip="Options" />
            }
          >
            <Box py="1">
              <DropdownMenu.Item onClick={handleRemoveCell}>
                <Text>Delete this cell</Text>
              </DropdownMenu.Item>
            </Box>
          </SimpleDropdownMenu>
        </Flex> */}
        {match(selectedCell)
          .with(
            P.instanceOf(CanvasCellPromptClientModel),
            (_promptCell: CanvasCellPromptClientModel) => {
              return (
                <PlaygroundSidebarPrompt
                  key={selectedCell.cellId}
                  cell={_promptCell}
                  updateCellExecutable={updateCellExecutable}
                />
              );
            }
          )
          .with(
            P.instanceOf(CanvasCellVariablesClientModel),
            (_variableCell: CanvasCellVariablesClientModel) => {
              return (
                <PlaygroundSidebarVariables
                  key={selectedCell.cellId}
                  cell={_variableCell}
                  updateCellExecutable={updateCellExecutable}
                  useVariableOptionsLoadable={useVariableOptionsLoadable}
                />
              );
            }
          )
          .otherwise(() => (
            <>Other</>
          ))}
      </RightSidebar>
    </SidebarWrapper>
  );
};

const memoized = memo(PlaygroundSidebar);

export { memoized as PlaygroundSidebar };
