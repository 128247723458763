import { DashboardStorageBucketObject } from "@usemorph/morph-dashboard-types";
import { CsvImportBucketClientModel } from "./CsvImportBucketClientModel";

export class CsvImportBucketClientModelFactory {
  static fromBucketResponse(bucketResponse: DashboardStorageBucketObject) {
    return new CsvImportBucketClientModel({
      bucketId: bucketResponse.bucketId,
      bucketName: bucketResponse.bucketName,
      readPermission: bucketResponse.readPermission,
      createPermission: bucketResponse.createPermission,
      updatePermission: bucketResponse.updatePermission,
    });
  }

  static getPlaecholderBucket() {
    return new CsvImportBucketClientModel({
      bucketId: "",
      bucketName: CsvImportBucketClientModel.csvImportFileBucketName,
      readPermission: "forbidden",
      createPermission: "forbidden",
      updatePermission: "forbidden",
    });
  }
}
