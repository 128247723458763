import { useMutation } from "react-query";
import { useComposeExecutable } from "~/clientModel/executable";
import { Loadable } from "~/clientModel/loadable";
import { CsvImportBucketClientModel } from "~/clientModel/storage/bucket";
import { StorageObjectClientModelFactory } from "~/clientModel/storage/object/StorageObjectClientModelFactory";
import { useCreateObjectMutation } from "~/serverStateStore";

export const useCreateStorageObject = ({
  bucketLoadable,
  teamSlug,
}: {
  bucketLoadable: Loadable<CsvImportBucketClientModel>;
  teamSlug: string;
}) => {
  const createObjectMutationOptions = useCreateObjectMutation({ teamSlug });

  // TODO: Error Toast出す
  const createObjectMutationResult = useMutation({
    ...createObjectMutationOptions,
    mutationFn: async (data: {
      key: string;
      contentType: string;
      file: File;
    }) => {
      if (!bucketLoadable.data) {
        throw new Error("Bucket is not loaded");
      }
      const createResponse = await createObjectMutationOptions.mutationFn({
        bucketId: bucketLoadable.data?.bucketId,
        ...data,
      });

      return StorageObjectClientModelFactory.fromStorageObject(createResponse);
    },
  });
  const createObjectExecutable = useComposeExecutable(
    createObjectMutationResult
  );

  return {
    createObjectExecutable,
    reset: createObjectMutationResult.reset,
  };
};
