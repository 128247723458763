import { LuSigma } from "react-icons/lu";
import { DropdownMenu } from "~/components_next/DropdownMenu";
import { Flex } from "~/components_next/Flex";

type TableViewDropdownItemProps = {
  onSelectTableViewMeta: (
    tableViewType: "timeSeries" | "grouping",
    operator: "sum" | "avg" | "min" | "max" | "count"
  ) => void;
  tableViewType: "timeSeries" | "grouping";
};

const TableViewDropdownSubcontent = (props: TableViewDropdownItemProps) => {
  const { onSelectTableViewMeta, tableViewType } = props;

  return (
    <DropdownMenu.SubContent>
      <DropdownMenu.Item
        onClick={() => onSelectTableViewMeta(tableViewType, "count")}
      >
        <Flex gap="2" align="center">
          <LuSigma />
          Count
        </Flex>
      </DropdownMenu.Item>
      <DropdownMenu.Item
        onClick={() => onSelectTableViewMeta(tableViewType, "sum")}
      >
        <Flex gap="2" align="center">
          <LuSigma />
          Sum
        </Flex>
      </DropdownMenu.Item>
      <DropdownMenu.Item
        onClick={() => onSelectTableViewMeta(tableViewType, "avg")}
      >
        <Flex gap="2" align="center">
          <LuSigma />
          Average
        </Flex>
      </DropdownMenu.Item>
      <DropdownMenu.Item
        onClick={() => onSelectTableViewMeta(tableViewType, "min")}
      >
        <Flex gap="2" align="center">
          <LuSigma />
          Min
        </Flex>
      </DropdownMenu.Item>
      <DropdownMenu.Item
        onClick={() => onSelectTableViewMeta(tableViewType, "max")}
      >
        <Flex gap="2" align="center">
          <LuSigma />
          Max
        </Flex>
      </DropdownMenu.Item>
    </DropdownMenu.SubContent>
  );
};

export { TableViewDropdownSubcontent };
