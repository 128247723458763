import { createRoot } from "react-dom/client";
import App from "~/App";

import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "simplebar-react/dist/simplebar.min.css";

//intercom hooks
import { IntercomProvider } from "react-use-intercom";
const INTERCOM_APP_ID = "epx3biyg";
// common dayjs settings
import { extend } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ToastProvider } from "./components_next/Toast/Toast";
import { RadixTheme } from "./RadixTheme";
extend(relativeTime);

// i18n
import "./i18n/config";

const auth0Domain = import.meta.env.VITE_AUTH0_DOMAIN;
const auth0ClientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
import {
  RootErrorBoundaryFallback,
  SpecifiableErrorBoundary,
  SpecifiableErrorBoundaryProvider,
} from "./features/Common/ErrorBoundary";
import "@radix-ui/themes/styles.css";
import "./overwrite.css";
import { setGlobalStyles } from "./stitches/globalStyle";
import { setupSentry } from "./sentry";

const queryClient = new QueryClient();

setGlobalStyles();

setupSentry();

createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>

  <RadixTheme>
    <ToastProvider>
      <Auth0Provider
        domain={`${auth0Domain}`}
        clientId={`${auth0ClientId}`}
        redirectUri={window.location.origin}
      >
        <SpecifiableErrorBoundaryProvider>
          <SpecifiableErrorBoundary
            errorBoundaryKey="root"
            FallbackComponent={RootErrorBoundaryFallback}
          >
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools />
              <IntercomProvider appId={INTERCOM_APP_ID}>
                <App />
              </IntercomProvider>
            </QueryClientProvider>
          </SpecifiableErrorBoundary>
        </SpecifiableErrorBoundaryProvider>
      </Auth0Provider>
    </ToastProvider>
  </RadixTheme>

  // </React.StrictMode>
);

// notebook css
// import "./features/Notebook/notebook-html-element.css";
