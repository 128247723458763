import { Flex } from "~/components_next/Flex";
import { styled } from "~/stitches";

export const DashboardPageTab = styled(Flex, {
  fontSize: "12px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  minWidth: "100px",
  variants: {
    isActive: {
      true: {
        backgroundColor: "$bg0",
      },
      false: {
        "&:hover": {
          backgroundColor: "$bg2",
          textDecoration: "underline",
        },
      },
    },
  },
});
