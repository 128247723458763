import { Flex } from "@radix-ui/themes";
import { Text } from "~/components_next/Text";
import { Button } from "~/components_next/Button";
import { Box } from "~/components_next/Box";
import { FieldClientModel } from "~/clientModel/fields/field";
import { UseExecutable } from "~/clientModel/executable";

type FieldItemProps = {
  field: FieldClientModel;
  useActivateEmbeddingExecutable: UseExecutable<
    void,
    { targetField: FieldClientModel },
    unknown,
    unknown
  >;
};

const FieldItem = (props: FieldItemProps) => {
  const { field, useActivateEmbeddingExecutable } = props;

  const activateEmbeddingExecutable = useActivateEmbeddingExecutable();

  return (
    <Flex align="center" gap="4">
      <Box style={{ width: "70px" }}>
        {field.isEmbedded ? (
          <Button variant="primary" size="xs" isDisabled>
            Activated
          </Button>
        ) : (
          <Button
            variant="secondary"
            size="xs"
            onClick={() =>
              activateEmbeddingExecutable.execute({ targetField: field })
            }
            isLoading={activateEmbeddingExecutable.isExecuting}
          >
            Activate
          </Button>
        )}
      </Box>

      <Text variant="default">{field.displayName || field.name}</Text>
    </Flex>
  );
};

export { FieldItem };
