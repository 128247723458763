import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { TbGridDots } from "react-icons/tb";
import { Text } from "~/components_next/Text";
import { styled } from "~/stitches";
import { Switch } from "~/components_next/Switch";
import { Spacer } from "~/components_next/Spacer";
import { Box } from "~/components_next/Box";
import { FieldClientModel } from "~/clientModel/fields/field";
import { UseExecutable } from "~/clientModel/executable";
import { FieldIcon } from "~/components_next/Icons/components/FieldIcon";

const ListItem = styled("li", {
  display: "flex",
  flexDirection: "row",
  gap: 20,
  alignItems: "center",
  padding: "4px 8px",
});

type FieldToggleListItemProps = {
  field: FieldClientModel;
  useUpdateFieldVisibilityExecutable: UseExecutable<
    void,
    { field: FieldClientModel }
  >;
  getFieldLabel: (field: FieldClientModel) => string;
};

export const FieldToggleListItem = ({
  field,
  useUpdateFieldVisibilityExecutable,
  getFieldLabel,
}: FieldToggleListItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: field.name });

  const style = {
    transform: CSS.Transform.toString({
      x: 0,
      y: transform?.y || 0,
      scaleX: 1,
      scaleY: 1,
    }),
    transition,
  };

  const listItemProps = {
    ref: setNodeRef,
    style,
    ...attributes,
    cursor: "default",
  };

  const updateFieldVisibilityExecutable = useUpdateFieldVisibilityExecutable();

  const handleCheckedChange = (isChecked: boolean) => {
    if (isChecked) {
      updateFieldVisibilityExecutable.execute({ field: field.showField() });
    } else {
      updateFieldVisibilityExecutable.execute({ field: field.hideField() });
    }
  };

  return (
    <ListItem key={field.name} {...listItemProps}>
      <Box {...listeners} style={{ cursor: "grab" }}>
        <TbGridDots size={12} />
      </Box>

      <Switch
        size="xs"
        isChecked={!field.isHidden}
        onCheckedChange={handleCheckedChange}
      />

      <Text size="2" variant={field.isHidden ? "description" : "default"}>
        {getFieldLabel(field)}
      </Text>

      <Spacer />
      <Box style={{ height: "90%" }}>
        <FieldIcon type={field.type} />
      </Box>
    </ListItem>
  );
};
