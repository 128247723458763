import { RecordIdentifierClientModel } from "~/clientModel/records/record/recordIdentifier";
import {
  CellSelectionStateClientModel,
  CellSelectionStateClientModelFactory,
} from "./cellSelection";
import {
  EditingCellStateClientModel,
  EditingCellStateClientModelFactory,
} from "./editingCell";
import {
  RecordSelectionStateClientModel,
  RecordSelectionStateClientModelFactory,
} from "./recordSelection";
import { TableSelectionClientModel } from "./TableSelectionClientModel";
import { RecordIdentifierSnapshot, TableSelectionSnapshotJSON } from "./type";

export class TableSelectionClientModelFactory {
  public static createEmpty(): TableSelectionClientModel {
    return new TableSelectionClientModel({
      editingCellState:
        EditingCellStateClientModelFactory.createInitialEditingCellState(),
      cellSelectionState:
        CellSelectionStateClientModelFactory.createInitialCellSelectionState(),
      recordSelectionState:
        RecordSelectionStateClientModelFactory.createInitialCellSelectionState(),
    });
  }

  public static deserialize(serialized: string): TableSelectionClientModel {
    const data = JSON.parse(serialized) as TableSelectionSnapshotJSON;

    const cellSelectionState = new CellSelectionStateClientModel({
      fieldNames: data.cellSelection.fieldNames,
      recordIdentifiers:
        TableSelectionClientModelFactory.deserializeRecordIdentifiers(
          data.cellSelection.recordIdentifiers
        ),
    });

    const editingCellState = new EditingCellStateClientModel({
      editingRecord: null,
    });

    const recordSelectionState = new RecordSelectionStateClientModel({
      recordIdentifiers:
        TableSelectionClientModelFactory.deserializeRecordIdentifiers(
          data.recordSelection.recordIdentifiers
        ),
    });

    return new TableSelectionClientModel({
      cellSelectionState,
      editingCellState,
      recordSelectionState,
    });
  }

  private static deserializeRecordIdentifier(
    recordIdentifier: RecordIdentifierSnapshot
  ): RecordIdentifierClientModel {
    return new RecordIdentifierClientModel(recordIdentifier);
  }

  public static deserializeRecordIdentifiers(
    recordIdentifiers: RecordIdentifierSnapshot[]
  ): RecordIdentifierClientModel[] {
    return recordIdentifiers.map(this.deserializeRecordIdentifier);
  }
}
