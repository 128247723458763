/* eslint-disable react/display-name */
import {
  DashboardTemplateAuthObject,
  IntegrationAuthConfig,
  IntegrationInputForm,
} from "@usemorph/morph-dashboard-types";
import { memo, useMemo, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useErrorToast } from "~/components_next/Error";
import {
  useCreateNewTemplateAuthMutation,
  useVerifyTemplateAuthMutation,
} from "~/serverStateStore/templateAuth";
import { useListTemplateAuthQuery } from "~/serverStateStore/templateAuth/queries/useListTemplateAuthQuery";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useColorMode } from "~/contexts/ColorModeContext";
import { Card } from "@radix-ui/themes";
import { Text } from "~/components_next/Text";
import { Spacer } from "~/components_next/Spacer";
import OauthPopup from "./OauthPopup";
import { Button } from "~/components_next/Button";
import { Box } from "~/components_next/Box";
import { Image } from "~/components_next/Image";
import { Flex } from "~/components_next/Flex";
import { AuthChangeDropdown } from "./AuthChangeDropdown";
import { AuthSettingDropdown } from "./AuthSettingDropdown";
import { Spinner } from "~/components_next/Spinner";
import { useToast } from "~/components_next/Toast";
import { IntegrationInputs } from "../Trigger/IntegrationInputs";

interface SnowflakeAuthInput {
  authConfig: IntegrationAuthConfig;
  integrationId: string;
  templateId: string;
  templateName: string;
  authInputForm: IntegrationInputForm;
  // setRequestData: (value: { [key: string]: unknown }) => void;
  logoUrl: string;
  loginButtonImage?: { light: string; dark: string };
  selectedTemplateAuth?: DashboardTemplateAuthObject;
  setSelectedTemplateAuth: (templateAuth?: DashboardTemplateAuthObject) => void;
  onContinue: () => void;
}

export const SnowflakeAuthInput = memo((props: SnowflakeAuthInput) => {
  const {
    authConfig,
    integrationId,
    loginButtonImage,
    templateId,
    templateName,
    authInputForm,
    // setRequestData,
    logoUrl,
    selectedTemplateAuth,
    setSelectedTemplateAuth,
    onContinue,
  } = props;
  const teamSlug = useTeamSlug();
  const toast = useToast();
  const { errorToast } = useErrorToast({});
  const signInButton = useRef<HTMLButtonElement>(null);

  // state
  const [requestAuthInfo, setRequestAuthInfo] = useState<{
    [key: string]: unknown;
  }>({});
  const [verifyError, setVerifyError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const isContinue = useMemo(() => {
    if (selectedTemplateAuth && !verifyError) {
      return true;
    }
    return false;
  }, [selectedTemplateAuth, verifyError]);
  const [isFormVisible, setIsFormVisible] = useState(false)

  const snowflakeAuthUrl = useMemo(() => {
    if (
      authConfig.authorizeUrl
      && "server" in requestAuthInfo
      && requestAuthInfo.server
      && "clientId" in requestAuthInfo
      && requestAuthInfo.clientId
      && "clientSecret" in requestAuthInfo
      && requestAuthInfo.clientSecret
    ) {
      const server = requestAuthInfo.server as string
      const clientId = encodeURIComponent(requestAuthInfo.clientId as string)
      const url = authConfig.authorizeUrl.replace("__server__", server).replace("__clientId__", clientId)
      return url
    }
    return undefined
  }, [authConfig, requestAuthInfo])

  // functions
  const { data: listTemplateAuth } = useQuery({
    ...useListTemplateAuthQuery({
      templateId,
      limit: 99999,
      skip: 0,
      teamSlug,
    }),
    onSuccess: async (data) => {
      // 初期値を入れる
      if (data.items.length > 0 && !selectedTemplateAuth) {
        handleSelectTemplateAuth(data.items[0]);
      } else if (data.items.length === 0) {
        setSelectedTemplateAuth(undefined);
      }
    },
  });
  const { mutateAsync: createTemplateAuth } = useMutation({
    ...useCreateNewTemplateAuthMutation({ templateId, teamSlug }),
  });
  const { mutateAsync: verifyTemplateAuth } = useMutation({
    ...useVerifyTemplateAuthMutation({ templateId, teamSlug }),
  });

  const handleSelectTemplateAuth = (
    templateAuth: DashboardTemplateAuthObject
  ) => {
    verifyAuth(templateAuth.templateAuthId);
    setSelectedTemplateAuth(templateAuth);
    // onCreated(templateAuth.templateAuthId)
  };

  const handleCreateNewTemplateAuth = async (code: string) => {
    try {
      const response = await createTemplateAuth({
        code,
        authInfo: requestAuthInfo,
      });
      handleSelectTemplateAuth(response);
    } catch (error) {
      errorToast(error);
    }
  };

  const verifyAuth = async (templateAuthId: string) => {
    try {
      setIsLoading(true);
      setVerifyError(undefined);
      const response = await verifyTemplateAuth({ templateAuthId });
      if (response.success) {
        setVerifyError(undefined);
        setIsLoading(false);
        return true;
      } else {
        const errorMessage = response.error || "An authentication error has occurred."
        setVerifyError(errorMessage);
        setIsLoading(false);
        return false;
      }
    } catch (e) {
      setVerifyError("An authentication error has occurred.");
      setIsLoading(false);
      return false;
    }
  };

  const colorMode = useColorMode();

  return (
    <>
      {/* {JSON.stringify(requestAuthInfo)} */}
      {/* {JSON.stringify(snowflakeAuthUrl)} */}

      {listTemplateAuth &&
        (selectedTemplateAuth ? (
          <>
            <Card>
              <Flex align="center">
                {isLoading ? (
                  <Spinner width="28px" height="28px" />
                ) : (
                  <Image
                    src={logoUrl}
                    width="28px"
                    height="28px"
                    style={{ objectFit: "contain" }}
                  />
                )}
                <Box ml="4">
                  <Box>
                    <Text fontWeight="bold">{selectedTemplateAuth.title}</Text>
                  </Box>
                  <Box>
                    <Text variant="description">
                      {selectedTemplateAuth.label}
                    </Text>
                  </Box>
                </Box>
                <Spacer />
                {verifyError && (
                  <Box css={{ color: "red" }} mr="2">
                    <Text variant="default">{verifyError}</Text>
                  </Box>
                )}
                <Box mr="3">
                  <Button
                    onClick={() => setIsFormVisible(e => !e)}
                    variant="secondary"
                    size="sm"
                  >
                    {
                      isFormVisible
                        ? <>Close</>
                        : <>Create</>
                    }
                  </Button>
                </Box>
                <AuthChangeDropdown
                  listTemplateAuth={listTemplateAuth.items}
                  selectedTemplateAuth={selectedTemplateAuth}
                  authConfig={authConfig}
                  authorizeUrl={snowflakeAuthUrl ?? ""}
                  templateName={templateName}
                  loginButtonImage={loginButtonImage}
                  isOauthSignUp={true}
                  isButtonDisabled={!snowflakeAuthUrl}
                  onSelect={handleSelectTemplateAuth}
                  onCreate={handleCreateNewTemplateAuth}
                />
                <Box mr="3" />
                <AuthSettingDropdown
                  selectedTemplateAuth={selectedTemplateAuth}
                  setSelectedTemplateAuth={setSelectedTemplateAuth}
                  templateId={templateId}
                />
                <Box mr="2" />
              </Flex>
              {/* {JSON.stringify(selectedAuth)} */}
            </Card>
            {
              isFormVisible && (
                <>
                  <Box mt="2" />
                  <IntegrationInputs
                    templateId={templateId}
                    integrationId={integrationId}
                    inputForm={authInputForm}
                    requestData={requestAuthInfo}
                    setRequestData={(el) => {
                      setRequestAuthInfo(el);
                      // setRequestData(el);
                    }}
                  />
                </>
              )
            }
          </>
        ) : (
          <>
            <IntegrationInputs
              templateId={templateId}
              integrationId={integrationId}
              inputForm={authInputForm}
              requestData={requestAuthInfo}
              setRequestData={(el) => {
                setRequestAuthInfo(el);
                // setRequestData(el);
              }}
            />
            <Card>
              <Flex align="center">
                <Image
                  src={logoUrl}
                  width="28px"
                  height="28px"
                  style={{ objectFit: "contain" }}
                />
                <Box ml="4">
                  <Box>
                    <Text variant="heading">Connect {templateName}</Text>
                  </Box>
                </Box>
                <Spacer />
                <OauthPopup
                  url={snowflakeAuthUrl ?? ""}
                  title={templateName}
                  width={800}
                  height={800}
                  onClose={() => console.log}
                  onCode={(code) => handleCreateNewTemplateAuth(code)}
                >
                  <Box mr="1">
                    {loginButtonImage === undefined ? (
                      <Button
                        variant="secondary"
                        size="sm"
                        ref={signInButton}
                        isDisabled={!snowflakeAuthUrl}
                        type="button"
                      >
                        Sign in {templateName}
                      </Button>
                    ) : (
                      <Box display="inline">
                        <Button
                          ref={signInButton}
                          variant="actionText"
                          isDisabled={!snowflakeAuthUrl}
                          type="button"
                        >
                          {colorMode === "light" ? (
                            <Image src={loginButtonImage.light} />
                          ) : (
                            <Image src={loginButtonImage.dark} />
                          )}
                        </Button>
                      </Box>
                    )}
                  </Box>
                </OauthPopup>
              </Flex>
            </Card>
          </>
        ))}
      <Box m="4" />
      <Box mb="3">
        <Button
          onClick={onContinue}
          variant="primary"
          size="sm"
          isDisabled={!isContinue}
          isLoading={isLoading}
          type="button"
        >
          Continue
        </Button>
      </Box>
    </>
  );
});
