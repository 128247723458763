import { meKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiMe from "~/api/useApiMe";

const useGetLoginUserQuery = () => {
  const { _getMe } = useApiMe();

  const client = useQueryClient();
  client.setQueryDefaults(meKeys.getLoginUser(), commonQueryOptions);

  return {
    queryKey: meKeys.getLoginUser(),
    queryFn: () => {
      return _getMe();
    },
    retry: 0,
  };
};

export { useGetLoginUserQuery };
