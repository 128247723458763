import { Flex } from "@radix-ui/themes";
import {
  RecordFilterConditionUnit,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { ChangeEvent } from "react";
import { BsX } from "react-icons/bs";
import { match, P } from "ts-pattern";
import { IconButton } from "~/components_next/IconButton";
import { Input } from "~/components_next/Input";
import { SimpleSelect } from "~/components_next/Select";
import { Spacer } from "~/components_next/Spacer";

// todo: simple fieldのtypeに応じて、valueの入力を変える
// 例えば、booleanの場合は、valueをtrue / false / nullのselectにする

type Operator = RecordFilterConditionUnit["operator"];

// get operators for a field type
const getOperatorsWithFieldType = (
  fieldType: SimpleField["type"]
): Operator[] => {
  return match(fieldType)
    .with(
      P.union(
        "shortText",
        "longText",
        "richText",
        "singleSelect",
        "multiSelect",
        "phoneNumber",
        "datetime",
        "time",
        "date"
      ),
      (): Operator[] => [
        "equal",
        "notEqual",
        "isNull",
        "notNull",
        "like",
        "startsWith",
        "endsWith",
      ]
    )
    .with(P.union("lastEditedAt", "createdAt"), (): Operator[] => [
      "equal",
      "notEqual",
      "isNull",
      "notNull",
    ])
    .with("boolean", (): Operator[] => [
      "equal",
      "notEqual",
      "isNull",
      "notNull",
    ])
    .with(
      P.union(
        "number",
        "autoNumber",
        "bigNumber",
        "autoBigNumber",
        "formula",
        "decimal"
      ),
      (): Operator[] => [
        "equal",
        "notEqual",
        "lessThan",
        "lessThanOrEqual",
        "greaterThan",
        "greaterThanOrEqual",
        "isNull",
        "notNull",
      ]
    )
    .with(P.union("url", "email"), (): Operator[] => [
      "equal",
      "notEqual",
      "isNull",
      "notNull",
    ])
    .with(P.union("attachment", "image"), (): Operator[] => [
      "isNull",
      "notNull",
    ])
    .with(P.union("array", "json"), (): Operator[] => ["notNull", "isNull"])
    .with(P.union("createdBy", "lastEditedBy"), (): Operator[] => [
      "isNull",
      "notNull",
    ])
    .otherwise(() => []);
};

type FilterConditionUnitFormProps = {
  simpleFields: SimpleField[];
  filterConditionUnit: RecordFilterConditionUnit;
} & (
  | {
      isReadOnly?: false;
      onChange: (value: RecordFilterConditionUnit) => void;
      onRemove: () => void;
    }
  | {
      isReadOnly: true;
    }
);

const FilterConditionUnitForm = (props: FilterConditionUnitFormProps) => {
  const {
    simpleFields,
    filterConditionUnit: { key, value, operator },
    isReadOnly,
  } = props;

  const simpleFieldOptions = simpleFields.flatMap((simpleField) =>
    match(simpleField).otherwise(() => [simpleField])
  );

  const hasValue = (op: Operator) =>
    match(op)
      .with(P.union("isNull", "notNull"), () => false)
      .otherwise(() => true);

  const handleChangeKeySelect = (key: string) => {
    if (isReadOnly) return;

    props.onChange({
      key,
      value,
      operator,
    });
  };

  const handleChangeOperatorSelect = (operator: Operator) => {
    if (isReadOnly) return;

    props.onChange({
      key,
      operator,
      value: hasValue(operator) ? value : null,
    });
  };

  const handleChangeValueInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (isReadOnly) return;

    props.onChange({
      key,
      operator,
      value: event.target.value,
    });
  };

  const currentField = simpleFields.find(({ name }) => name === key);

  if (!currentField) {
    throw new Error("Field not found");
  }

  const operators = getOperatorsWithFieldType(currentField.type);

  const operatorOptions = operators.map((operator) => ({
    value: operator,
    label: operator,
  }));

  const keyOptions = simpleFieldOptions.map(({ name, displayName }) => ({
    value: name,
    label: displayName || name,
  }));

  return (
    <Flex direction="row" align="center" gap="1">
      <SimpleSelect
        variant="primary"
        value={key}
        size="xs"
        onChange={(value) => handleChangeKeySelect(value as string)}
        isDisabled={isReadOnly}
        options={keyOptions}
      />
      <SimpleSelect
        variant="primary"
        value={operator}
        size="xs"
        onChange={(value) => handleChangeOperatorSelect(value as Operator)}
        isDisabled={isReadOnly}
        options={operatorOptions}
      />
      {hasValue(operator) && (
        <Input
          variant="primary"
          value={value ? String(value) : ""}
          onChange={handleChangeValueInput}
          size="xs"
          readOnly={isReadOnly}
          disabled={isReadOnly}
        />
      )}

      <Spacer />
      {!isReadOnly && (
        <IconButton onClick={props.onRemove} icon={<BsX />} tooltip="Remove" />
      )}
    </Flex>
  );
};

export { FilterConditionUnitForm };
