import { useCallback } from "react";
import { PromptHistoryClientModel } from "~/clientModel/promptHistories/promptHistory";
import { EmbeddingSortConditionUnitClientModel } from "~/clientModel/queryConditions/embeddingSortConditionUnit";
import { FilterConditionsClientModel } from "~/clientModel/queryConditions/filterConditions";
import { PaginationClientModel } from "~/clientModel/queryConditions/pagination";
import { SortConditionsClientModel } from "~/clientModel/queryConditions/sortConditions";
import { TablePaginationLimit } from "~/presenters/sourceAndViews/common/components/bottomItems/TablePagination";
import { SourceQueryConditions, SourceQueryMode } from "./types";

const hasValidQueryConditions = (
  filterConditions: FilterConditionsClientModel,
  sortConditions: SortConditionsClientModel,
  embeddingSortCondition: EmbeddingSortConditionUnitClientModel | null
): boolean => {
  return (
    filterConditions.hasFilterConditions ||
    sortConditions.hasSortConditions ||
    (!!embeddingSortCondition && embeddingSortCondition.validate().isValid)
  );
};

export const useSourceQueryConditionsHandlers = ({
  queryMode,
  onQueryModeChange,
  queryConditions,
  onQueryConditionsChange,
}: {
  queryConditions: SourceQueryConditions;
  onQueryConditionsChange: (queryConditions: SourceQueryConditions) => void;
  queryMode: SourceQueryMode;
  onQueryModeChange: (queryMode: SourceQueryMode) => void;
}) => {
  const { filterConditions, sortConditions, embeddingSortCondition, sql } =
    queryConditions;

  const handleFilterConditionsChange = useCallback(
    (filterConditions: FilterConditionsClientModel) => {
      if (
        hasValidQueryConditions(
          filterConditions,
          sortConditions,
          embeddingSortCondition
        )
      ) {
        onQueryModeChange("query");
      } else {
        onQueryModeChange("default");
      }
      onQueryConditionsChange({ ...queryConditions, filterConditions });
    },
    [
      sortConditions,
      embeddingSortCondition,
      onQueryConditionsChange,
      queryConditions,
      onQueryModeChange,
    ]
  );

  const handleSortConditionsChange = useCallback(
    (sortConditions: SortConditionsClientModel) => {
      if (
        hasValidQueryConditions(
          filterConditions,
          sortConditions,
          embeddingSortCondition
        )
      ) {
        onQueryModeChange("query");
      } else {
        onQueryModeChange("default");
      }
      onQueryConditionsChange({ ...queryConditions, sortConditions });
    },
    [
      filterConditions,
      embeddingSortCondition,
      onQueryConditionsChange,
      queryConditions,
      onQueryModeChange,
    ]
  );

  const handleEmbeddingSortConditionChange = useCallback(
    (embeddingSortCondition: EmbeddingSortConditionUnitClientModel | null) => {
      if (
        hasValidQueryConditions(
          filterConditions,
          sortConditions,
          embeddingSortCondition
        )
      ) {
        onQueryModeChange("query");
      } else {
        onQueryModeChange("default");
      }
      onQueryConditionsChange({ ...queryConditions, embeddingSortCondition });
    },
    [
      filterConditions,
      onQueryConditionsChange,
      queryConditions,
      sortConditions,
      onQueryModeChange,
    ]
  );

  const handleAllQueryConditionsClear = useCallback(() => {
    onQueryModeChange("default");
    onQueryConditionsChange({
      ...queryConditions,
      filterConditions: filterConditions.clearAllFilterConditions(),
      sortConditions: sortConditions.clearAllSortConditions(),
      embeddingSortCondition: null,
    });
  }, [
    onQueryModeChange,
    onQueryConditionsChange,
    queryConditions,
    filterConditions,
    sortConditions,
  ]);

  const handlePromptChange = useCallback(
    (prompt: string) => {
      onQueryConditionsChange({ ...queryConditions, prompt });
    },
    [onQueryConditionsChange, queryConditions]
  );

  const handlePromptClear = useCallback(() => {
    onQueryModeChange("default");
    onQueryConditionsChange({
      ...queryConditions,
      prompt: "",
      sql: "",
      queryingSql: "",
    });
  }, [onQueryConditionsChange, queryConditions, onQueryModeChange]);

  const handleSqlGenerated = useCallback(
    (generatedSql: string) => {
      onQueryModeChange("prompt");
      onQueryConditionsChange({
        ...queryConditions,
        sql: generatedSql,
        queryingSql: generatedSql,
      });
    },
    [onQueryConditionsChange, queryConditions, onQueryModeChange]
  );

  const handleSqlChange = useCallback(
    (sql: string) => {
      onQueryConditionsChange({ ...queryConditions, sql });
    },
    [onQueryConditionsChange, queryConditions]
  );

  const handleSqlRun = useCallback(() => {
    onQueryModeChange("sql");
    onQueryConditionsChange({ ...queryConditions, queryingSql: sql });
  }, [onQueryConditionsChange, queryConditions, sql, onQueryModeChange]);

  const handleSqlClear = useCallback(() => {
    onQueryModeChange("default");
    onQueryConditionsChange({
      ...queryConditions,
      prompt: "",
      sql: "",
      queryingSql: "",
    });
  }, [onQueryConditionsChange, queryConditions, onQueryModeChange]);

  const handlePaginationChange = useCallback(
    (pagination: PaginationClientModel<TablePaginationLimit>) => {
      onQueryConditionsChange({ ...queryConditions, pagination });
    },
    [onQueryConditionsChange, queryConditions]
  );

  const handlePromptHistorySelected = useCallback(
    (promptHistory: PromptHistoryClientModel) => {
      onQueryConditionsChange({
        ...queryConditions,
        prompt: promptHistory.message,
        sql: promptHistory.sql,
        queryingSql: promptHistory.sql,
      });
      onQueryModeChange("prompt");
    },
    [onQueryConditionsChange, onQueryModeChange, queryConditions]
  );

  return {
    queryMode,
    handleFilterConditionsChange,
    handleSortConditionsChange,
    handleEmbeddingSortConditionChange,
    handleAllQueryConditionsClear,
    handlePromptChange,
    handlePromptClear,
    handleSqlChange,
    handleSqlGenerated,
    handleSqlRun,
    handleSqlClear,
    handlePaginationChange,
    handlePromptHistorySelected,
  };
};
