import { match } from "ts-pattern";
import { FieldClientModel } from "~/clientModel/fields/field";

type SortConditionUnitDirection = "ascending" | "descending";

type SortConditionUnitClientModelData = {
  targetField: FieldClientModel;
  direction: SortConditionUnitDirection;
};

export class SortConditionUnitClientModel {
  readonly #data: SortConditionUnitClientModelData;

  constructor(data: SortConditionUnitClientModelData) {
    this.#data = data;
  }

  public get data(): SortConditionUnitClientModelData {
    return this.#data;
  }

  public get keyLabel() {
    return this.#data.targetField.label;
  }

  public get key() {
    return this.#data.targetField.name;
  }

  public get direction() {
    return this.#data.direction;
  }

  public get directionLabel() {
    return match(this.#data.direction)
      .with("ascending", () => "Ascending")
      .with("descending", () => "Descending")
      .exhaustive();
  }

  public updateTargetField(targetField: FieldClientModel) {
    return new SortConditionUnitClientModel({
      ...this.#data,
      targetField,
    });
  }

  public updateDirection(direction: SortConditionUnitDirection) {
    return new SortConditionUnitClientModel({
      ...this.#data,
      direction,
    });
  }
}
