import { DownloadDrawerTitle } from "./DownloadDrawerTitle";
import { Drawer } from "~/components_next/Drawer";
import { UseExecutable } from "~/clientModel/executable";
import { CsvDownloadFieldsClientModel } from "~/clientModel/csvDownload/csvDownloadFields";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { RecordsClientModel } from "~/clientModel/records";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { Flex } from "~/components_next/Flex";
import { RecordsTable } from "~/components_next/RecordsTable";
import { Spinner } from "~/components_next/Spinner";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";

type DownloadRecordsWithSqlDrawerProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  csvDownloadFields: CsvDownloadFieldsClientModel;
  smartFunctions: SmartFunctionsClientModel;
  useDownloadExecutable: UseExecutable<
    void,
    {
      csvDownloadFields: CsvDownloadFieldsClientModel;
      smartFunctions: SmartFunctionsClientModel;
    },
    { url: string }
  >;
  usePreviewRecordsLoadable: UseLoadable<void, RecordsClientModel>;
};

const DownloadRecordsWithSqlDrawer = (
  props: DownloadRecordsWithSqlDrawerProps
) => {
  const {
    isOpen,
    onOpenChange,
    csvDownloadFields,
    smartFunctions,
    usePreviewRecordsLoadable,
    useDownloadExecutable,
  } = props;

  const downloadExecutable = useDownloadExecutable();

  const previewLoadable = usePreviewRecordsLoadable();

  const handleDownload = async () => {
    const { url } = await downloadExecutable.execute({
      csvDownloadFields,
      smartFunctions,
    });
    window.open(url, "_blank");
  };

  return (
    <Drawer.Root open={isOpen} onOpenChange={onOpenChange} size="xl">
      <Drawer.Content>
        <Drawer.Title>
          <DownloadDrawerTitle
            onClickDownload={handleDownload}
            isDownloading={downloadExecutable.isExecuting}
            isDisabled={
              !csvDownloadFields.validate().isValid ||
              previewLoadable.status !== "success"
            }
          />
        </Drawer.Title>
        <Drawer.Body>
          <WithFallback
            loadables={[previewLoadable] as const}
            loadingFallback={<LoadingFallback />}
          >
            {([previewRecords]) => (
              <RecordsTable
                records={previewRecords}
                fields={csvDownloadFields.baseFields}
                isReadOnly
                noPadding
              />
            )}
          </WithFallback>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};

const LoadingFallback = () => {
  return (
    <Flex width="100%" height="100%" align="center" justify="center">
      <Spinner size="lg" />
    </Flex>
  );
};

export { DownloadRecordsWithSqlDrawer };
