import { Flex as RadixThemeFlex } from "@radix-ui/themes";
import { CSS, styled } from "@stitches/react";
import {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  Fragment,
  ReactNode,
} from "react";

type RadixThemeFlexProps = ComponentPropsWithoutRef<typeof RadixThemeFlex>;

type FlexProps = {
  hoverable?: boolean;
  css?: CSS;
  divider?: ReactNode;
} & Omit<RadixThemeFlexProps, "css">;

const StyledFlex = styled(RadixThemeFlex, {});
const HoverableStyle: CSS = {
  cursor: "pointer",
  transitionProperty: "background-color",
  transitionDuration: "200ms",
  "&:hover": {
    backgroundColor: "$hoverColor",
  },
  "&:active": {
    backgroundColor: "$activeColor",
  },
};

const _Flex = (props: FlexProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { hoverable, css, divider, children } = props;

  const radixFlexProps = (({ css, hoverable, divider, children, ...rest }) =>
    rest)(props);

  const _css = hoverable ? { ...HoverableStyle, ...css } : css;

  return (
    <StyledFlex ref={ref} css={_css} {...radixFlexProps}>
      {Array.isArray(children) && divider
        ? children.map((item, index) =>
            index !== children.length - 1 ? (
              <Fragment key={item.key ?? index}>
                {item}
                {divider}
              </Fragment>
            ) : (
              <Fragment key={item.key ?? index}>{item}</Fragment>
            )
          )
        : children}
    </StyledFlex>
  );
};

const Flex = forwardRef<HTMLDivElement, FlexProps>(_Flex);

export { Flex };
