import { UseMutationResult } from "react-query";
import { match } from "ts-pattern";
import {
  SuccessExecutable,
  ErrorExecutable,
  LoadingExecutable,
  StandbyExecutable,
  Executable,
} from "./Executable";

export const composeExecutable = <
  PromiseParamType,
  PromiseReturnType,
  ErrorType
>(
  useMutationResult: UseMutationResult<
    PromiseReturnType,
    ErrorType,
    PromiseParamType
  >
): Executable<PromiseParamType, PromiseReturnType, ErrorType> => {
  return match(useMutationResult)
    .with(
      { status: "idle" },
      (idleResult) =>
        new StandbyExecutable<PromiseParamType, PromiseReturnType, ErrorType>(
          idleResult.mutateAsync
        )
    )
    .with(
      { status: "loading" },
      (loadingResult) =>
        new LoadingExecutable<PromiseParamType, PromiseReturnType, ErrorType>(
          loadingResult.mutateAsync
        )
    )
    .with(
      { status: "success" },
      (successResult) =>
        new SuccessExecutable<PromiseParamType, PromiseReturnType, ErrorType>(
          successResult.data,
          successResult.mutateAsync
        )
    )
    .with(
      { status: "error" },
      (errorResult) =>
        new ErrorExecutable<PromiseParamType, PromiseReturnType, ErrorType>(
          errorResult.error,
          errorResult.mutateAsync
        )
    )
    .exhaustive();
};
