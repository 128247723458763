import { useCallback } from "react";
import { useQuery } from "react-query";
import { useListTablesQuery } from "../queries/useListTablesQuery";

interface UseTablesParams {
  teamSlug: string;
  databaseId: string;
}

const useSortedTables = (props: UseTablesParams) => {
  const { teamSlug, databaseId } = props;

  const queryResult = useQuery({
    ...useListTablesQuery({ teamSlug, databaseId }),
    select: (data) => {
      const sortedTables = [...data.tables].sort((t1, t2) => {
        const name1 = (t1.displayName || t1.tableSlug).toLocaleUpperCase();
        const name2 = (t2.displayName || t2.tableSlug).toLocaleLowerCase();
        return name1.localeCompare(name2);
      });

      return {
        tables: sortedTables,
      };
    },
  });

  const isTableSlugExists = useCallback(
    (tableSlug: string): boolean => {
      if (!queryResult.data) return false;
      const find = queryResult.data.tables.find(
        (_t) => _t.tableSlug === tableSlug
      );
      return !find;
    },
    [queryResult.data]
  );

  const getTableDisplayNameWithSlug = useCallback(
    (tableSlug: string): string => {
      if (!queryResult.data) return tableSlug;

      const find = queryResult.data.tables.find(
        (_t) => _t.tableSlug === tableSlug
      );
      if (find) {
        return find.displayName || find.tableSlug;
      }
      return tableSlug;
    },
    [queryResult.data]
  );

  return {
    queryResult,
    isTableSlugExists,
    getTableDisplayNameWithSlug,
  };
};

export { useSortedTables };
