import {
  DashboardNotebookCellPromptObject,
  DashboardNotebookCellSourceObject,
} from "@usemorph/morph-dashboard-types";
import { useEffect, useMemo, useState } from "react";

import { BsBarChart, BsPlus, BsTable } from "react-icons/bs";
import {
  LuCalendarClock,
  LuMerge,
  LuSlidersHorizontal,
  LuStretchHorizontal,
} from "react-icons/lu";
import { match } from "ts-pattern";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import {
  CanvasCreateCellClientModelFactory,
  CanvasCreateJoinTableViewCellClientModel,
  CanvasCreatePromptCellClientModel,
  CanvasCreateTableViewCellClientModel,
} from "~/clientModel/canvas/CanvasCreateCellClientModel";
import {
  ControlledDropdown,
  DropdownMenu,
} from "~/components_next/DropdownMenu";
import { useErrorToast } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { IconButton } from "~/components_next/IconButton";
import {
  useUseCreateCellExecutable,
  useUseVisualizationPromptConfigsLoadable,
} from "../../../providers/PlaygroundCreateCellExecutableProvider";
import { PlaygroundCellActionButton } from "../PlaygroundCellActionButton";
import { CreatePromptCellDropdown } from "../../../createCell/prompt/CreatePromptCellDropdown";
import { Loadable } from "~/clientModel/loadable";
import { FieldsClientModel } from "~/clientModel/fields";
import { TableViewDropdownSubcontent } from "../../../createCell/tableView/TableViewDropdownItem";
import { CreateTableViewCellDropdown } from "../../../createCell/tableView/CreateTableViewCellDropdown";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { VisualizationConfigDropdownItem } from "../../../createCell/prompt/VisualizationConfigDropdownItem";
import { CanvasVisualizationPromptConfigCientModel } from "~/clientModel/canvas/CanvasVisualizationPromptConfigClientModel";
import { useReactFlowSetIsNodeSelectionLocked } from "../../../providers/ReactFlowSelectedCellsProvider";
import { useUseSourcesLoadable } from "../../../providers/PlaygroundUseSourceFIeldsLoadableProvider";
import { JoinToViewDropdown } from "../../../createCell/tableView/JoinToViewDropdown";
import { TableClientModel } from "~/clientModel/tables/table";
import { JoinTargetsDropdownItems } from "../../../createCell/tableView/JoinTargetsDropdownItems";

type PlaygroundCellCreateCellButtonProps = {
  canvasCellClientModel: CanvasCellClientModelUnion;
  sourceTableSlug?: string;
  fieldsLoadable?: Loadable<FieldsClientModel>;
};

const PlaygroundCellCreateCellButton = (
  props: PlaygroundCellCreateCellButtonProps
) => {
  const { canvasCellClientModel, sourceTableSlug, fieldsLoadable } = props;

  /**
   * UI State
   */
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { errorToast } = useErrorToast({});

  /**
   * Create Cell
   */
  const useCreateCellExecutable = useUseCreateCellExecutable();
  const createCellExecutable = useCreateCellExecutable();

  // table view
  const [createTableViewCellInstance, setCreateTableViewCellInstance] =
    useState<CanvasCreateTableViewCellClientModel | null>(null);

  const handleTableViewOpen = (
    tableViewType: "timeSeries" | "grouping",
    operator: "sum" | "avg" | "min" | "max" | "count"
  ) => {
    const _source: DashboardNotebookCellSourceObject = match(
      canvasCellClientModel.cellType
    )
      .with("source", () => {
        return {
          condition: {
            select: ["*"],
            from: sourceTableSlug,
          },
        };
      })
      .otherwise(() => ({}));

    setCreateTableViewCellInstance(
      new CanvasCreateTableViewCellClientModel({
        cellType: "view",
        cellName: "Table View",
        parentCells: [canvasCellClientModel],
        source: {
          ..._source,
          promptSql: {
            type: tableViewType,
            prompt: "",
            operator,
          },
        },
      })
    );
  };

  const handleCreateTableViewCell = async () => {
    try {
      if (!createTableViewCellInstance) {
        throw new Error("createTableViewCellInstance is null");
      }
      const cell = await createCellExecutable.execute(
        createTableViewCellInstance
      );
      setCreateTableViewCellInstance(null);
      setIsDropdownOpen(false);
    } catch (e) {
      errorToast(e);
    }
  };

  const handleOnCreateBasicTableView = async () => {
    try {
      // memo: 親がSourceの時だけ、クライアントからsourceを渡してる。調整する
      const _source: DashboardNotebookCellSourceObject = match(
        canvasCellClientModel.cellType
      )
        .with("source", () => {
          return {
            condition: {
              select: ["*"],
              from: sourceTableSlug,
            },
          };
        })
        .otherwise(() => ({}));
      const canvasCellInstance = CanvasCreateCellClientModelFactory.create({
        cellType: "view",
        cellName: "Table View",
        source: _source,
        parentCells: [canvasCellClientModel],
      });
      await createCellExecutable.execute(canvasCellInstance);
      setIsDropdownOpen(false);
    } catch (e) {
      errorToast(e);
    }
  };

  // variables
  const handleOnCreateVariable = async () => {
    try {
      const createCellInstace = CanvasCreateCellClientModelFactory.create({
        cellType: "variables",
        cellName: "Variables",
        source: {
          variables: [
            {
              name: "NewVariable",
              defaultValue: "",
              type: "string",
            },
          ],
        },
        parentCells: [canvasCellClientModel],
      });

      await createCellExecutable.execute(createCellInstace);
      setIsDropdownOpen(false);
    } catch (e) {
      errorToast(e);
    }
  };

  // prompt (visualization)
  const useVisualizationConfigsLoadable =
    useUseVisualizationPromptConfigsLoadable();
  const visualizationConfigsLoadable = useVisualizationConfigsLoadable();

  const [selectedVisualizationConfig, setSelectedVisualizationConfig] =
    useState<CanvasVisualizationPromptConfigCientModel | null>(null);
  const [createPromptCellInstance, setCreatePromptInstanceCell] =
    useState<CanvasCreatePromptCellClientModel | null>(null);
  const handlePromptOpen = (
    config: CanvasVisualizationPromptConfigCientModel
  ) => {
    if (!fieldsLoadable) {
      errorToast(new Error("fieldsLoadable is null"));
      return;
    }
    setSelectedVisualizationConfig(config);
    setCreatePromptInstanceCell(
      new CanvasCreatePromptCellClientModel({
        parentCells: [canvasCellClientModel],
        cellType: "prompt",
        cellName: "Prompt",
        source: {
          prompt: {
            type: config.chartType as DashboardNotebookCellPromptObject["type"], // サーバー駆動にするために無理キャスト
            title: "",
            prompt: "",
          },
        },
      })
    );
  };
  const handleOnCreatePrompt = async () => {
    try {
      if (!createPromptCellInstance) {
        throw new Error("createPromptCellInstance is null");
      }
      const cell = await createCellExecutable.execute(createPromptCellInstance);
      setCreatePromptInstanceCell(null);
      setSelectedVisualizationConfig(null);
      setIsDropdownOpen(false);
    } catch (e) {
      errorToast(e);
    }
  };
  const chartTypeOptions = useMemo(() => {
    if (
      selectedVisualizationConfig &&
      selectedVisualizationConfig.chartType === "combinationChart"
    ) {
      return visualizationConfigsLoadable.data?.configs.filter(
        (config) =>
          config.chartType === "barChart" || config.chartType === "lineChart"
      );
    }
    return undefined;
  }, [selectedVisualizationConfig, visualizationConfigsLoadable.data?.configs]);

  /**
   * Dropdownの挙動制御
   */
  const setIsNodeChangeLocked = useReactFlowSetIsNodeSelectionLocked();
  const handleOpenChange = (isOpen: boolean) => {
    setIsDropdownOpen(isOpen);
    if (isOpen) {
      setIsNodeChangeLocked(isOpen);
    }
  };

  useEffect(() => {
    if (isDropdownOpen === false && createPromptCellInstance === null) {
      setIsNodeChangeLocked(false);
    }
  }, [isDropdownOpen, createPromptCellInstance, setIsNodeChangeLocked]);

  /**
   * Join
   */
  const useSourcesLoadable = useUseSourcesLoadable();
  const sourcesLoadable = useSourcesLoadable();
  const [createJoinTableViewInstance, setCreateJoinTableViewInstance] =
    useState<CanvasCreateJoinTableViewCellClientModel | null>(null);
  const handleSelectJoinTarget = (tableClientModel: TableClientModel) => {
    const instance = new CanvasCreateJoinTableViewCellClientModel({
      parentCells: [canvasCellClientModel],
      cellName: "Joined table view",
      targetSource: tableClientModel,
    });
    setCreateJoinTableViewInstance(instance);
    createJoinParentSource(instance);
  };

  const createJoinParentSource = async (
    instance: CanvasCreateJoinTableViewCellClientModel
  ) => {
    const parentPosition = instance?.parentCell?.settings;
    const createCellInstace = CanvasCreateCellClientModelFactory.create({
      cellType: "source",
      cellName: instance.targetSource.displayName || "Source",
      source: {
        tableSlug: instance.targetSource.tableSlug || "",
      },
      parentCells: [],
      settings: {
        x:
          parentPosition && parentPosition.x
            ? parentPosition.x + (parentPosition.width || 300) + 100
            : 0,
        y: parentPosition?.y ?? 0,
        width: 300,
        height: 120,
      },
    });

    const sourceResponse = await createCellExecutable.execute(
      createCellInstace
    );
    setCreateJoinTableViewInstance(
      new CanvasCreateJoinTableViewCellClientModel({
        cellName: instance.cellName || "Joined table view",
        targetSource: instance.targetSource,
        parentCells: [...instance.parentCells, sourceResponse],
      })
    );
  };

  return (
    <>
      <Flex direction="column">
        <PlaygroundCellActionButton>
          <ControlledDropdown
            isOpen={isDropdownOpen}
            onOpenChange={handleOpenChange}
            trigger={
              <IconButton
                icon={<BsPlus />}
                isLoading={createCellExecutable.isExecuting}
                tooltip="Add cell"
                size="lg"
              />
            }
          >
            <DropdownMenu.Item onClick={handleOnCreateVariable}>
              <Flex gap="2" align="center">
                <LuSlidersHorizontal />
                Variable
              </Flex>
            </DropdownMenu.Item>
            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger>
                <Flex gap="2" align="center">
                  <BsTable />
                  Table View
                </Flex>
              </DropdownMenu.SubTrigger>
              <DropdownMenu.SubContent>
                <DropdownMenu.Sub>
                  <DropdownMenu.SubTrigger>
                    <Flex gap="2" align="center">
                      <LuStretchHorizontal />
                      Grouping
                    </Flex>
                  </DropdownMenu.SubTrigger>
                  <TableViewDropdownSubcontent
                    onSelectTableViewMeta={handleTableViewOpen}
                    tableViewType="grouping"
                  />
                </DropdownMenu.Sub>
                <DropdownMenu.Sub>
                  <DropdownMenu.SubTrigger>
                    <Flex gap="2" align="center">
                      <LuCalendarClock />
                      Time series
                    </Flex>
                  </DropdownMenu.SubTrigger>
                  <TableViewDropdownSubcontent
                    onSelectTableViewMeta={handleTableViewOpen}
                    tableViewType="timeSeries"
                  />
                </DropdownMenu.Sub>
                <DropdownMenu.Sub>
                  <DropdownMenu.SubTrigger>
                    <Flex gap="2" align="center">
                      <LuMerge style={{ transform: "rotate(90deg)" }} />
                      Join Sources
                    </Flex>
                  </DropdownMenu.SubTrigger>
                  <DropdownMenu.SubContent>
                    <JoinTargetsDropdownItems
                      sourcesLoadable={sourcesLoadable}
                      onSelectTarget={handleSelectJoinTarget}
                    />
                  </DropdownMenu.SubContent>
                </DropdownMenu.Sub>
                <DropdownMenu.Item onClick={handleOnCreateBasicTableView}>
                  <Flex gap="2" align="center">
                    <BsTable />
                    Basic
                  </Flex>
                </DropdownMenu.Item>
              </DropdownMenu.SubContent>
            </DropdownMenu.Sub>

            {/* Visualization */}
            {canvasCellClientModel.cellType === "view" ? (
              <WithFallback loadables={[visualizationConfigsLoadable]}>
                {([visualizationConfigs]) => {
                  return (
                    <DropdownMenu.Sub>
                      <DropdownMenu.SubTrigger>
                        <Flex gap="2" align="center">
                          <BsBarChart />
                          AI Visualization
                        </Flex>
                      </DropdownMenu.SubTrigger>
                      <DropdownMenu.SubContent>
                        {visualizationConfigs.configs.map((config, i) => (
                          <VisualizationConfigDropdownItem
                            key={i}
                            config={config}
                            onSelect={handlePromptOpen}
                          />
                        ))}
                      </DropdownMenu.SubContent>
                    </DropdownMenu.Sub>
                  );
                }}
              </WithFallback>
            ) : (
              <DropdownMenu.Label>
                Visualizations must be created <br /> from a Table View
              </DropdownMenu.Label>
            )}
          </ControlledDropdown>
        </PlaygroundCellActionButton>

        {fieldsLoadable && (
          <CreatePromptCellDropdown
            visualizationConfig={selectedVisualizationConfig}
            createCellInstance={createPromptCellInstance}
            onCreateCellInstanceChange={setCreatePromptInstanceCell}
            isCreatingCell={createCellExecutable.isExecuting}
            onCreatePromptCell={handleOnCreatePrompt}
            fieldsLoadable={fieldsLoadable}
            chartTypeOptions={chartTypeOptions}
          />
        )}

        {fieldsLoadable && (
          <CreateTableViewCellDropdown
            createCellInstance={createTableViewCellInstance}
            onCreateCellInstanceChange={setCreateTableViewCellInstance}
            isCreatingCell={createCellExecutable.isExecuting}
            onCreateTableViewCell={handleCreateTableViewCell}
            fieldsLoadable={fieldsLoadable}
          />
        )}

        {sourcesLoadable && createJoinTableViewInstance && (
          <JoinToViewDropdown
            createCellInstance={createJoinTableViewInstance}
            sourcesLoadable={sourcesLoadable}
            isCreatingCell={createCellExecutable.isExecuting}
          />
        )}
      </Flex>
    </>
  );
};

export { PlaygroundCellCreateCellButton };
