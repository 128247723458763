import { useQuery } from "react-query";
import { useComposeLoadable } from "~/clientModel/loadable";
import { FindTemplateDetailClientModelFactory } from "~/clientModel/template";
import { useFindTemplateDetailQuery } from "~/serverStateStore/template";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";


export const useFindTemplateDetail = ({ templateId, enabled }: { templateId: string, enabled: boolean }) => {
  const teamSlug = useTeamSlug();

  const findTemplateDetailQueryOptions = useFindTemplateDetailQuery({ teamSlug, templateId })
  const findTemplateDetailQueryResult = useQuery({
    ...findTemplateDetailQueryOptions,
    select: (data) => {
      return FindTemplateDetailClientModelFactory.fromTemplateDetailResponse(data)
    },
    enabled,
  });

  const findTemplateDetailLoadable = useComposeLoadable(findTemplateDetailQueryResult)

  return {
    findTemplateDetailLoadable,
  }
}
