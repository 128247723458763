import { useMutation } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import {
  CreatingRecordClientModel,
  CreatingRecordClientModelDefactory,
} from "~/clientModel/records/creatingRecord";
import { useErrorToast } from "~/components_next/Error";
import { useCreateRecordMutation } from "~/serverStateStore";

type UseCreateRecordExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useCreateRecordExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseCreateRecordExecutableParams): Executable<
  { creatingRecord: CreatingRecordClientModel; fields: FieldsClientModel },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  const createRecordOptions = useCreateRecordMutation({
    teamSlug,
    databaseId,
    tableSlug,
  });

  return useComposeExecutable(
    useMutation({
      ...createRecordOptions,
      mutationFn: ({
        creatingRecord,
        fields,
      }: {
        creatingRecord: CreatingRecordClientModel;
        fields: FieldsClientModel;
      }) => {
        const values = CreatingRecordClientModelDefactory.toRecordValues(
          creatingRecord,
          fields
        );

        return createRecordOptions.mutationFn({
          values,
        });
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseCreateRecordExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseCreateRecordExecutableParams) => {
  return () =>
    useCreateRecordExecutable({
      teamSlug,
      databaseId,
      tableSlug,
    });
};
