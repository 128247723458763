import { ResourceCategoryType, ResourceNotebookSchema, ResourceTableSchema, ResourceViewSchema } from "@usemorph/morph-dashboard-types";

type resourceGroupsDependencies = { teamSlug: string; resourceType: ResourceCategoryType };

type ListResourceGroupDependencies = {
  teamSlug: string;
  resource: ResourceTableSchema | ResourceNotebookSchema | ResourceViewSchema;
  limit?: number;
  skip?: number;
} & resourceGroupsDependencies;

const resourceGroupsKeys = {
  all: ({ teamSlug, resourceType }: resourceGroupsDependencies) =>
    ["resourceGroup", teamSlug, resourceType] as const,

  /**
   * list plans
   */
  allListResourceGroups: ({ teamSlug, resourceType }: resourceGroupsDependencies) =>
    [...resourceGroupsKeys.all({ teamSlug, resourceType }), "list"] as const,
  listResourceGroups: ({ teamSlug, resource }: ListResourceGroupDependencies) =>
    [...resourceGroupsKeys.allListResourceGroups({ teamSlug, resourceType: resource.resourceType, })] as const,
};

export { resourceGroupsKeys };
