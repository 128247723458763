import { DropdownMenu as RadixThemeDropdownMenu } from "@radix-ui/themes";
import { ComponentPropsWithRef, forwardRef } from "react";
import { LuChevronDown } from "react-icons/lu";
import { styled } from "~/stitches";
import { Button } from "../Button";
import { Spacer } from "../Spacer";
import { RadixComponentObjToPropsObj } from "../typeUtils";

type RadixThemeDropdownMenuPropsObj = RadixComponentObjToPropsObj<
  typeof RadixThemeDropdownMenu
>;

/**
 * Content
 */

type ContentProps = RadixThemeDropdownMenuPropsObj["Content"];

const Content = forwardRef<HTMLDivElement, ContentProps>((props, ref) => {
  return (
    <RadixThemeDropdownMenu.Content
      ref={ref}
      variant="soft"
      color="gray"
      {...props}
    />
  );
});

Content.displayName = "Content";

/**
 * SelectLikeButton
 */

// todo: important使わない方法を考える;
const StyledSelectLinkButton = styled(Button, {
  boxShadow: "inset 0 0 0 1px var(--gray-a7) !important",
  color: "var(--gray-12)  !important",
  fontWeight: "400  !important",
  border: "none  !important",
  outline: "none  !important",
  "&:hover": {
    boxShadow: "inset 0 0 0 1px var(--gray-a8) !important",
  },
});

type SelectLikeButtonProps = Omit<
  ComponentPropsWithRef<typeof Button>,
  "variant"
>;

const SelectLikeButton = forwardRef<HTMLButtonElement, SelectLikeButtonProps>(
  (props: SelectLikeButtonProps, ref) => {
    const { children, ...rest } = props;
    return (
      <StyledSelectLinkButton ref={ref} variant="secondary" {...rest}>
        <>
          {children}
          <Spacer />
          <LuChevronDown />
        </>
      </StyledSelectLinkButton>
    );
  }
);

SelectLikeButton.displayName = "SelectLikeButton";

/**
 * Export
 */

const DropdownMenu = { ...RadixThemeDropdownMenu, Content, SelectLikeButton };

export { DropdownMenu };
