import { FieldClientModel } from "~/clientModel/fields/field";
import { TextArea } from "~/components_next/TextArea";

type DescriptionInputProps = {
  field: FieldClientModel;
  onFieldChange: (field: FieldClientModel) => void;
  isReadOnly?: boolean;
};

export const DescriptionInput = (props: DescriptionInputProps) => {
  const { field, onFieldChange, isReadOnly } = props;

  return (
    <TextArea
      label="Description"
      variant="primary"
      value={field.description}
      onChange={(e) => onFieldChange(field.updateDescription(e.target.value))}
      readOnly={isReadOnly}
      style={{ height: "160px" }}
    />
  );
};
