import { BaseUserMeta, createClient } from "@liveblocks/client";
import { createRoomContext } from "@liveblocks/react";
import { DashboardUserObject } from "@usemorph/morph-dashboard-types";
import { NotebookStorage } from "./Notebook/notebookStorage";

const client = createClient({
  publicApiKey: `${import.meta.env.VITE_LIVEBLOCK_KEY}`,
});

export type NotebookPresence = {
  cursor?: { x: number; y: number };
  user: DashboardUserObject;
};

export const {
  RoomProvider: NotebookRoomProvider,
  useRoom: useNotebookRoom,
  useMyPresence: useMyNotebookPresence,
  useUpdateMyPresence: useUpdateMyNotebookPresence,
  useOthers: useOthersOnNotebook,
  // storage
  useStorage: useNotebookStorage,
  useMutation: useNotebookMutation,
  useCanRedo: useNotebookCanRedo,
  useCanUndo: useNotebookCanUndo,
  useRedo: useNotebookRedo,
  useUndo: useNotebookUndo,
} = createRoomContext<NotebookPresence, NotebookStorage, BaseUserMeta>(client);
