import { templateAuthKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiTemplateAuth from "~/api/useApiTemplateAuth";

interface UseListTemplateAuthQueryParams {
  templateId: string
  limit: number;
  skip: number;
  teamSlug: string;
}

const useListTemplateAuthQuery = ({ templateId, limit, skip, teamSlug }: UseListTemplateAuthQueryParams) => {
  const { _getTemplateAuthList } = useApiTemplateAuth();

  const client = useQueryClient();
  client.setQueryDefaults(
    templateAuthKeys.allListTemplateAuth({ templateId }),
    commonQueryOptions
  );

  return {
    queryKey: templateAuthKeys.listTemplateAuth({ templateId, limit, skip, teamSlug }),
    queryFn: () => {
      return _getTemplateAuthList({ templateId, limit, skip, teamSlug })
    },
  };
};

export { useListTemplateAuthQuery };
