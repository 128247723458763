import useApi from "./useApi";
type DatabaseRecordCountResponse = { count: number };
export default function useApiDatabaseeRecordCount() {
  const BASE_PATH = "/database-record-count";

  const { executeRequest } = useApi();

  /**
   * GET ALL
   */
  const _getDatabaseRecordCount = async ({
    teamSlug,
  }: {
    teamSlug: string;
  }): Promise<DatabaseRecordCountResponse> => {
    return executeRequest(
      "get",
      BASE_PATH,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };
  /**
   * FIND
   */
  const _findDatabaseRecordCount = async ({
    teamSlug,
    databaseId,
  }: {
    teamSlug: string;
    databaseId: string;
  }): Promise<DatabaseRecordCountResponse> => {
    return executeRequest(
      "get",
      BASE_PATH + databaseId,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  return {
    _getDatabaseRecordCount,
    _findDatabaseRecordCount,
  };
}
