import {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import {
  ControlledDropdown,
  DropdownMenu,
} from "~/components_next/DropdownMenu";
import {
  getInputStackProps,
  InputStack,
  InputStackProps,
} from "~/components_next/InputStack/InputStack";
import { ScrollArea, TextField as RadixThemeTextField } from "@radix-ui/themes";
import { Badge } from "~/components_next/Badge";
import { match, P } from "ts-pattern";
import { Flex } from "~/components_next/Flex";
import { FieldClientModel } from "~/clientModel/fields/field";
import { useDisclosure } from "~/hooks/useDisclosure";
import { LuCheck, LuChevronDown } from "react-icons/lu";
import { CanvasVisualizationPromptConfigCientModel } from "~/clientModel/canvas/CanvasVisualizationPromptConfigClientModel";
import { VisualizationConfigDropdownItem } from "./VisualizationConfigDropdownItem";

type AxisBadgeInputProps<ValueType> = {
  value: ValueType[];
  badgeLabelSelector: (option: ValueType) => string;
  badgeIconSelector?: (option: ValueType) => ReactNode;
  isInvalid?: boolean;
  onClick?: () => void;
} & InputStackProps;

const _AxisBadgeInput = <ValueType,>(
  props: AxisBadgeInputProps<ValueType>,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const {
    value,
    badgeLabelSelector,
    badgeIconSelector,
    errorMessage,
    isInvalid,
    onClick,
  } = props;
  const inputStackProps = getInputStackProps(props);

  const _isInvalid = useMemo(() => {
    return match([isInvalid, errorMessage])
      .with([true, P._], () => true)
      .with([false, P.string], () => true)
      .with(
        [false, P.array(P.string)],
        ([_, errorMessages]) => errorMessages.length > 0
      )
      .otherwise(() => false);
  }, [errorMessage, isInvalid]);

  return (
    <InputStack {...inputStackProps}>
      <RadixThemeTextField.Root
        ref={ref}
        color={_isInvalid ? "red" : "gray"}
        onClick={onClick}
      >
        <RadixThemeTextField.Slot>
          <Flex css={{ maxWidth: "240px" }} wrap="wrap" gap="1">
            {value.map((v, i) => (
              <Badge key={i} variant="tertiary">
                <Flex gap="1" align="center">
                  {badgeIconSelector?.(v)}
                  {badgeLabelSelector(v)}
                </Flex>
              </Badge>
            ))}
          </Flex>
        </RadixThemeTextField.Slot>
        <RadixThemeTextField.Input onBlur={(e) => e.stopPropagation()} />
        <RadixThemeTextField.Slot>
          <LuChevronDown />
        </RadixThemeTextField.Slot>
      </RadixThemeTextField.Root>
    </InputStack>
  );
};

const AxisBadgeInput = forwardRef(_AxisBadgeInput) as <ValueType>(
  props: AxisBadgeInputProps<ValueType> & React.RefAttributes<HTMLDivElement>
) => JSX.Element;

type AxisMultiSelectProps<ValueType> = {
  value: ValueType[] | null;
  badgeLabelSelector: (option: ValueType) => string;
  badgeIconSelector?: (option: ValueType) => ReactNode;
  onSelectField: (value: FieldClientModel) => void;
  onDeselectField: (value: FieldClientModel) => void;
  onSelectFieldAndConfig: (
    value: FieldClientModel,
    config: CanvasVisualizationPromptConfigCientModel
  ) => void;
  fields: FieldClientModel[];
  getIsFieldOptionSelected: (field: FieldClientModel) => boolean;
  label?: string;
  chartTypeOptions?: CanvasVisualizationPromptConfigCientModel[];
};

const AxisMultiSelect = <OptionType,>(
  props: AxisMultiSelectProps<OptionType>,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const {
    value,
    badgeIconSelector,
    badgeLabelSelector,
    onSelectField,
    onDeselectField,
    onSelectFieldAndConfig,
    fields,
    getIsFieldOptionSelected,
    label,
    chartTypeOptions,
  } = props;

  const dropdownDisclosure = useDisclosure();

  const handleSelectField = useCallback(
    (field: FieldClientModel) => {
      if (getIsFieldOptionSelected(field)) {
        onDeselectField?.(field);
      } else {
        onSelectField?.(field);
      }
    },
    [getIsFieldOptionSelected, onDeselectField, onSelectField]
  );

  const handleSelectFieldAndConfig = useCallback(
    (
      field: FieldClientModel,
      config: CanvasVisualizationPromptConfigCientModel
    ) => {
      if (getIsFieldOptionSelected(field)) {
        onDeselectField?.(field);
      } else {
        onSelectFieldAndConfig(field, config);
      }
    },
    [getIsFieldOptionSelected, onDeselectField, onSelectFieldAndConfig]
  );

  return (
    <>
      <ControlledDropdown
        trigger={
          <AxisBadgeInput<OptionType>
            value={value || []}
            badgeIconSelector={badgeIconSelector}
            badgeLabelSelector={badgeLabelSelector}
            label={label}
            onClick={() => dropdownDisclosure.setIsOpen(true)}
          />
        }
        isOpen={dropdownDisclosure.isOpen}
        onOpenChange={dropdownDisclosure.setIsOpen}
      >
        <>
          <ScrollArea style={{ maxHeight: "30vh" }}>
            {chartTypeOptions && (
              <>
                {fields.map((field) => {
                  return (
                    <DropdownMenu.Sub key={field.name}>
                      <DropdownMenu.SubTrigger>
                        <Flex align="center" gap="2">
                          {field.displayName || field.name}
                          {getIsFieldOptionSelected(field) && <LuCheck />}
                        </Flex>
                      </DropdownMenu.SubTrigger>
                      <DropdownMenu.SubContent>
                        {chartTypeOptions.map((config, i) => (
                          <VisualizationConfigDropdownItem
                            key={i}
                            config={config}
                            onSelect={(config) =>
                              handleSelectFieldAndConfig(field, config)
                            }
                          />
                        ))}
                      </DropdownMenu.SubContent>
                    </DropdownMenu.Sub>
                  );
                })}
              </>
            )}
            {!chartTypeOptions && (
              <>
                {fields.map((field) => {
                  return (
                    <DropdownMenu.Item
                      key={field.name}
                      onClick={() => handleSelectField(field)}
                    >
                      <Flex align="center" gap="2">
                        {field.displayName || field.name}
                        {getIsFieldOptionSelected(field) && <LuCheck />}
                      </Flex>
                    </DropdownMenu.Item>
                  );
                })}
              </>
            )}
          </ScrollArea>
        </>
      </ControlledDropdown>
    </>
  );
};

export { AxisMultiSelect };
