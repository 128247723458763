import { templateKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiTemplate from "~/api/useApiTemplate";

interface UseListTemplateQueryProps {
  teamSlug: string
}

const useListTemplateQuery = ({ teamSlug }: UseListTemplateQueryProps) => {
  const { _listTemplate } = useApiTemplate();

  const client = useQueryClient();
  client.setQueryDefaults(
    templateKeys.listTemplate({ teamSlug }),
    commonQueryOptions
  );

  return {
    queryKey: templateKeys.listTemplate({ teamSlug }),
    queryFn: () => {
      return _listTemplate({ teamSlug })
    },
  };
};

export { useListTemplateQuery };
