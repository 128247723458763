import { DashboardTableObject } from "@usemorph/morph-dashboard-types";
import { ReactNode } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { DangerDialog } from "~/components_next/Dialog";
import {
  DropdownMenu,
  SimpleDropdownMenu,
} from "~/components_next/DropdownMenu";
import { useErrorToast } from "~/components_next/Error";
import { IconButton } from "~/components_next/IconButton";
import { Text } from "~/components_next/Text";
import { useDisclosure } from "~/hooks/useDisclosure";
import { getPath } from "~/routing";
import { useDeleteTableMutation } from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useDuplicateTable } from "./useDuplicateTable";

type TableSettingsDropdownProps = {
  prependChildren?: ReactNode;
  table: DashboardTableObject;
  onDeleted?: () => void;
};

const TableSettingsDropdown = (props: TableSettingsDropdownProps) => {
  const { table, onDeleted, prependChildren } = props;

  const { errorToast } = useErrorToast({});

  /**
   * Duplicate
   */
  const { duplicateTable, isDuplicating } = useDuplicateTable();
  const handleDuplicateTable = async (withRecords: boolean) => {
    try {
      await duplicateTable({
        targetTableSlug: table.tableSlug,
        targetDisplayName: table.displayName ?? table.tableSlug,
        withRecords,
      });
    } catch (e) {
      errorToast(e);
    }
  };

  /**
   * Delete
   */
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const { mutateAsync: deleteTable, isLoading: isDeleting } = useMutation({
    ...useDeleteTableMutation({
      teamSlug,
      databaseId,
    }),
  });

  const deleteTableDialog = useDisclosure();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleDeleteTable = async () => {
    const sourcePagePath = getPath("source", {
      teamSlug,
      databaseId,
      tableSlug: table.tableSlug,
    });

    try {
      await deleteTable({
        tableSlug: table.tableSlug,
      });
      onDeleted?.();

      if (!onDeleted && pathname === sourcePagePath) {
        navigate(
          getPath("databaseHome", {
            teamSlug,
            databaseId,
          })
        );
      }
    } catch (e) {
      errorToast(e);
    }
    deleteTableDialog.onClose();
  };

  return (
    <>
      <SimpleDropdownMenu
        trigger={
          <IconButton
            icon={<BsThreeDotsVertical />}
            tooltip="Source settings"
            isLoading={isDuplicating || isDeleting}
            size="sm"
          />
        }
      >
        {prependChildren}
        <DropdownMenu.Item onClick={() => handleDuplicateTable(true)}>
          Duplicate
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={() => handleDuplicateTable(false)}>
          Duplicate (without records)
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={deleteTableDialog.onOpen}>
          <Text variant="errorMessage">Delete Source</Text>
        </DropdownMenu.Item>
      </SimpleDropdownMenu>

      <DangerDialog
        description={`Are you sure to delete ${table.displayName ?? table.tableSlug
          } source permanently?`}
        isOpen={deleteTableDialog.isOpen}
        onOpenChange={deleteTableDialog.setIsOpen}
        onConfirm={handleDeleteTable}
      />
    </>
  );
};

export { TableSettingsDropdown };
