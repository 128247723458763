import { Button } from "~/components_next/Button";
import { Input } from "~/components_next/Input";
import { useState, ChangeEvent } from "react";
import { Flex } from "@radix-ui/themes";
import { Tag } from "~/components_next/Tag";

interface MembersInputProps {
  members: string[];
  onAddMember: (member: string) => void;
  onDeleteMember: (member: string) => void;
  isReadOnly?: boolean;
}

const MembersInput = ({
  members,
  onAddMember,
  onDeleteMember,
  isReadOnly,
}: MembersInputProps) => {
  const [memberInput, setMemberInput] = useState<string>("");

  // memberが重複しているか入力がないときボタンが無効
  const isDisabled = members.includes(memberInput) || !memberInput;

  const handleAddMember = () => {
    onAddMember(memberInput);
    setMemberInput("");
  };

  return (
    <Flex direction="column" gap="1">
      <Flex mb="2" gap="2" style={{ flexWrap: "wrap" }}>
        {members.map((member) => {
          return (
            <Tag
              variant="secondary"
              key={member}
              onClear={isReadOnly ? undefined : () => onDeleteMember(member)}
            >
              {member}
            </Tag>
          );
        })}
      </Flex>
      {!isReadOnly && (
        <Flex gap="2" align="center">
          <Input
            variant="supplementary"
            size="sm"
            value={memberInput}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setMemberInput(e.target.value);
            }}
            placeholder="add option"
          />
          <Button
            variant="tertiary"
            size="sm"
            isDisabled={isDisabled}
            onClick={handleAddMember}
          >
            Add
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export { MembersInput };
