import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";

export class AutoNumberFieldTypeClientModel extends FieldTypeClientModelBase {
  public get type(): "autoNumber" {
    return "autoNumber";
  }

  public get fieldTypeLabel(): "Auto Number" {
    return "Auto Number";
  }

  public get isAutoGenerated(): true {
    return true;
  }

  public get isEmbeddable(): false {
    return false;
  }

  public get canBeTargetOfSyncingByComputedField(): true {
    return true;
  }

  public get _updatableFieldTypes() {
    return [];
  }

  public validate(): ValidationResult {
    return { isValid: true };
  }
}
