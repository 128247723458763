import { useCallback } from "react";
import { FieldClientModel } from "~/clientModel/fields/field";
import { RecordClientModel } from "~/clientModel/records/record";
import { TableSelectionClientModel } from "~/clientModel/tables/tableMeta/TableSelection";
import {
  useRecordsTableBaseFields,
  useRecordsTableBaseRecords,
} from "../context/ReactTableBaseContext";

export const useTableSelectionUtil = () => {
  const records = useRecordsTableBaseRecords();
  const fields = useRecordsTableBaseFields();

  const isUpperCellSelected = useCallback(
    ({
      tableSelection,
      record,
      field,
    }: {
      tableSelection: TableSelectionClientModel;
      record: RecordClientModel;
      field: FieldClientModel;
    }): boolean => {
      const upperRecord = records.getUpperRecord(record.recordIdentifier);
      return (
        !!upperRecord &&
        tableSelection.isCellSelected({
          recordIdentifier: upperRecord.recordIdentifier,
          fieldName: field.name,
        })
      );
    },
    [records]
  );

  const isDownerCellSelected = useCallback(
    ({
      tableSelection,
      record,
      field,
    }: {
      tableSelection: TableSelectionClientModel;
      record: RecordClientModel;
      field: FieldClientModel;
    }): boolean => {
      const downerRecord = records.getDownerRecord(record.recordIdentifier);

      return (
        !!downerRecord &&
        tableSelection.isCellSelected({
          recordIdentifier: downerRecord.recordIdentifier,
          fieldName: field.name,
        })
      );
    },
    [records]
  );

  const isLeftCellSelected = useCallback(
    ({
      tableSelection,
      record,
      field,
    }: {
      tableSelection: TableSelectionClientModel;
      record: RecordClientModel;
      field: FieldClientModel;
    }): boolean => {
      const leftVisibleField = fields.getPreviousVisibleField(field.name);
      return (
        !!leftVisibleField &&
        tableSelection.isCellSelected({
          recordIdentifier: record.recordIdentifier,
          fieldName: leftVisibleField.name,
        })
      );
    },
    [fields]
  );

  const isRightCellSelected = useCallback(
    ({
      tableSelection,
      record,
      field,
    }: {
      tableSelection: TableSelectionClientModel;
      record: RecordClientModel;
      field: FieldClientModel;
    }): boolean => {
      const rightVisibleField = fields.getNextVisibleField(field.name);
      return (
        !!rightVisibleField &&
        tableSelection.isCellSelected({
          recordIdentifier: record.recordIdentifier,
          fieldName: rightVisibleField.name,
        })
      );
    },
    [fields]
  );

  return {
    isUpperCellSelected,
    isDownerCellSelected,
    isLeftCellSelected,
    isRightCellSelected,
  };
};
