import { SmartFieldFunctionObject } from "@usemorph/morph-dashboard-types";
import { SmartFunctionClientModel } from "./SmartFunctionClientModel";
import { SmartFunctionInputFormClientModelFactory } from "./smartFunctionInputForm";

export class SmartFunctionClientModelFactory {
  public static createFromSmartFieldFunctionObject(
    smartFieldFunctionObject: SmartFieldFunctionObject
  ): SmartFunctionClientModel {
    if (
      smartFieldFunctionObject.fieldType === "richText" ||
      smartFieldFunctionObject.fieldType === "reference" ||
      smartFieldFunctionObject.fieldType === "multiReference" ||
      smartFieldFunctionObject.fieldType === "embeddings" ||
      smartFieldFunctionObject.fieldType === "python" ||
      smartFieldFunctionObject.fieldType === "rowInfo"
    ) {
      throw new Error(
        "Rich text is not supported to be used as a smart function value type."
      );
    }

    return new SmartFunctionClientModel({
      functionId: smartFieldFunctionObject.functionId,
      functionName: smartFieldFunctionObject.functionName,
      description: smartFieldFunctionObject.description,
      valueType: smartFieldFunctionObject.fieldType,
      inputForm:
        SmartFunctionInputFormClientModelFactory.createFromSmartFieldInputSetting(
          smartFieldFunctionObject.inputForm
        ),
      consumedCredits: smartFieldFunctionObject.useUpdates,
    });
  }
}
