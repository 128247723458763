import { FieldTypeClientModelFactory } from "~/clientModel/fields/field/fieldType";
import { FieldTypeSubSelect } from "./common/FieldTypeSubSelectBase";

const otherPrimitiveFieldTypes = [
  FieldTypeClientModelFactory.createEmpty("boolean"),
] as const;

type OtherPrimitiveFieldType = (typeof otherPrimitiveFieldTypes)[number];

type OtherPrimitiveFieldsTypeSubSelectProps = {
  onSelect: (fieldType: OtherPrimitiveFieldType) => void;
  options?:
    | {
        includes: OtherPrimitiveFieldType["type"][];
      }
    | {
        excludes: OtherPrimitiveFieldType["type"][];
      }; // 指定がなければ全部
};

export const OtherPrimitiveFieldsTypeSubSelect = (
  props: OtherPrimitiveFieldsTypeSubSelectProps
) => {
  const { onSelect, options } = props;

  return (
    <FieldTypeSubSelect
      triggerContent="Other Primitives"
      onSelect={onSelect}
      fieldTypeOptions={otherPrimitiveFieldTypes}
      options={options}
    />
  );
};
