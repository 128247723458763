import { SimpleField } from "@usemorph/morph-dashboard-types";
import { FieldClientModelDefactory } from "./field";
import { SmartFunctionsClientModel } from "./field/fieldType/smartFunction/smartFunctions";
import { FieldsClientModel } from "./FieldsClientModel";

export class FieldsClientModelDefactory {
  public static toFieldNames(fields: FieldsClientModel): string[] {
    return fields.data.fields.map((field) =>
      FieldClientModelDefactory.toFieldName(field)
    );
  }

  public static toSimpleFields(
    fields: FieldsClientModel,
    {
      smartFunctions,
    }: {
      smartFunctions: SmartFunctionsClientModel;
    }
  ): SimpleField[] {
    return fields.data.fields.map((field) =>
      FieldClientModelDefactory.toSimpleField(field, { smartFunctions })
    );
  }

  public static toSimpleFieldsWithoutSmartFields(
    fields: FieldsClientModel
  ): SimpleField[] {
    return fields.data.fields.map((field) =>
      FieldClientModelDefactory.toSimpleField(field)
    );
  }

  public static toMergeSimpleField(
    fields: FieldsClientModel,
    {
      smartFunctions,
    }: {
      smartFunctions: SmartFunctionsClientModel;
    }
  ): SimpleField | null {
    const mergeField = fields.mergeKeyField;

    if (!mergeField) {
      return null;
    }

    return FieldClientModelDefactory.toSimpleField(fields.mergeKeyField, {
      smartFunctions,
    });
  }
}
