import { Badge } from "~/components_next/Badge";

const SourceBadge = () => {
  return (
    <Badge variant="secondary" size="xs">
      Source
    </Badge>
  );
};

export { SourceBadge };
