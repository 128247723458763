import { Flex } from "@radix-ui/themes";
import { Callout } from "~/components_next/Callout";
import { styled } from "~/stitches";

type CanvasCellPromptUnitWarningProps = {
  title: string;
  content: string;
};

// TODO: 仕上がり見てCalloutを検討する
const Wrapper = styled(Flex, {
  alignItems: "center",
  justifyContent: "center",
  minHeight: "60px",
  backgroundColor: "$bg1",
  color: "$textSupplementary",
  borderRadius: "$2",
  p: "$4",
});

const CanvasCellPromptUnitWarning = (
  props: CanvasCellPromptUnitWarningProps
) => {
  const { title, content } = props;

  return <Callout type="alert" title={title} description={content} />;
};

export { CanvasCellPromptUnitWarning };
