import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactNode, useState } from "react";
import {
  useRecordsTableBaseHandleColumnSizingChange,
  useRecordsTableBaseHeaderDropdown,
  useRecordsTableBaseOptions,
} from "../context/ReactTableBaseContext";
import { ElementClassNamesForOnboarding } from "~/constants/IntercomClassConst";
import { Box, Flex } from "@radix-ui/themes";
import { ResizableBox } from "~/components_next/ResizableBox";
import { Text } from "~/components_next/Text";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { FieldClientModel } from "~/clientModel/fields/field";
import { useTableSizings } from "../context/useTableSizing";
import { FieldIcon } from "~/components_next/Icons/components/FieldIcon";
import { TableColumnSizingClientModel } from "~/clientModel/tables/tableMeta/TableColumnSizing";
import { Badge } from "~/components_next/Badge";
import { styled } from "~/stitches";
import { mergeFieldIndicatorStyles } from "../mergeFieldIndicator/mergeFieldIndicatorStyles";

const HeaderBox = styled(ResizableBox, {
  textTransform: "none",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  position: "absolute",
  userSelect: "none",
  top: 0,
  left: 0,
  minWidth: TableColumnSizingClientModel.minColumnWidth,
  borderRight: "solid 1px $border",
  borderBottom: "solid 1px $border",
  backgroundColor: "$bg1",

  variants: {
    showMergeFieldIndicator: {
      true: {
        "&::after": {
          content: "",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          borderTop: mergeFieldIndicatorStyles.border,
          borderLeft: mergeFieldIndicatorStyles.border,
          borderRight: mergeFieldIndicatorStyles.border,
        },
      },
    },
  },
});

const WithHeaderDropdown = (props: {
  children: ReactNode;
  headerDropdown:
    | ((field: FieldClientModel, index: number) => ReactNode)
    | undefined;
  field: FieldClientModel;
  index: number;
}) => {
  const { children, field, headerDropdown, index } = props;

  if (headerDropdown && headerDropdown(field, index)) {
    return (
      <SimpleDropdownMenu trigger={children}>
        {headerDropdown(field, index)}
      </SimpleDropdownMenu>
    );
  } else {
    return <>{children}</>;
  }
};

const MergeFieldIndicatorContainer = styled(Flex, {
  position: "absolute",
  top: 4,
  right: 4,
  zIndex: 1,
  backgroundColor: "$bg0",
  borderRadius: "9999px",
  border: "solid 1px $border2",
});

type FieldHeaderProps = {
  isIntercomTarget: boolean;
  field: FieldClientModel;
  index: number;
};

const FieldHeader = (props: FieldHeaderProps) => {
  const { isIntercomTarget, field, index } = props;

  const { getHeaderRowHeight } = useTableSizings();

  const {
    isReorderable,
    noFieldTypeIcon,
    showMergeFieldIndicator: showMergeFieldIndicatorOption,
  } = useRecordsTableBaseOptions();

  // reorder
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: field.name });

  const sortableStyle = {
    transform: CSS.Transform.toString({
      x: transform?.x || 0,
      y: 0,
      scaleX: 1,
      scaleY: 1,
    }),
    transition,
  };

  const { getRecordColumnWidth } = useTableSizings();
  const handleColumnSizingChange =
    useRecordsTableBaseHandleColumnSizingChange();
  const columnWidth = getRecordColumnWidth(field.name);
  const [isResizing, setIsResizing] = useState(false);

  const handleResizeStart = () => {
    setIsResizing(true);
  };

  const handleResizeDone = (width: number) => {
    setIsResizing(false);
    handleColumnSizingChange({ fieldName: field.name, width });
  };

  /**
   * UI
   */

  const headerDropdown = useRecordsTableBaseHeaderDropdown();

  const showMergeFieldIndicator =
    showMergeFieldIndicatorOption && field.isMergeField;
  return (
    <HeaderBox
      direction="right"
      width={columnWidth}
      height={getHeaderRowHeight()}
      ref={setNodeRef}
      style={{
        zIndex: isResizing ? 1 : "auto",
        cursor:
          headerDropdown && headerDropdown(field, index)
            ? "pointer"
            : "default",
        ...sortableStyle,
        ...(isReorderable ? attributes : {}),
      }}
      onResizeDone={handleResizeDone}
      onResizeStart={handleResizeStart}
      showMergeFieldIndicator={showMergeFieldIndicator}
    >
      <WithHeaderDropdown
        field={field}
        headerDropdown={headerDropdown}
        index={index}
      >
        <Flex
          style={{
            height: "100%",
            width: "100%",
            position: "relative",
            overflow: "hidden",
          }}
          align="center"
          gap="1"
          p="2"
          className={
            isIntercomTarget
              ? ElementClassNamesForOnboarding.TableEdit.selectHeader
                  .classNameText
              : undefined
          }
        >
          <Text
            variant="subheading"
            style={{
              flexGrow: 1,
            }}
          >
            {field.label}
          </Text>
          {!noFieldTypeIcon && (
            <Box>
              <FieldIcon type={field.type} />
            </Box>
          )}
        </Flex>
      </WithHeaderDropdown>
      {showMergeFieldIndicator && (
        <MergeFieldIndicatorContainer>
          <Badge variant="tertiary" color="bronze" size="xs">
            Merge Field
          </Badge>
        </MergeFieldIndicatorContainer>
      )}
    </HeaderBox>
  );
};

export { FieldHeader };
