import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { SmartFunctionFieldTypeClientModel } from "~/clientModel/fields/field/fieldType";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";
import { Flex } from "~/components_next/Flex";
import { SmartFunctionInputForm } from "./inputForm/SmartFunctionInputForm";
import { SmartFunctionSelect } from "./SmartFunctionSelect";

type SmartFunctionSettingsInputProps = {
  field: FieldClientModel;
  fields: FieldsClientModel;
  smartFunctionType: SmartFunctionFieldTypeClientModel;
  smartFunctions: SmartFunctionsClientModel;
  onFieldChange: (type: FieldClientModel) => void;
  isReadOnly?: boolean;
};

export const SmartFunctionSettingsInput = (
  props: SmartFunctionSettingsInputProps
) => {
  const {
    field,
    fields,
    smartFunctionType,
    smartFunctions,
    onFieldChange,
    isReadOnly,
  } = props;

  const handleSmartFunctionTypeChange = (
    type: SmartFunctionFieldTypeClientModel
  ) => {
    onFieldChange(field.updateType(type));
  };

  return (
    <Flex direction="column" gap="4">
      <SmartFunctionSelect
        smartFunctionType={smartFunctionType}
        smartFunctions={smartFunctions}
        onSmartFunctionTypeChange={handleSmartFunctionTypeChange}
        isReadOnly={isReadOnly}
      />
      <SmartFunctionInputForm
        smartFunctionType={smartFunctionType}
        smartFunctions={smartFunctions}
        onSmartFunctionTypeChange={handleSmartFunctionTypeChange}
        fields={fields}
        isReadOnly={isReadOnly}
      />
    </Flex>
  );
};
