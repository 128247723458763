import {
  ReactNode,
  createContext,
  useContext,
  ForwardedRef,
  forwardRef,
} from "react";
import { MorphThemeSize } from "../../commonProps.type";
import { InputStack } from "../../InputStack/InputStack";
import {
  ErrorMessages,
  getContentColor,
  getContentVariant,
  getIsInvalid,
  getRadixSize,
  getTriggerColor,
  getTriggerVariant,
  SelectVariant,
} from "./converters";
import { Select as RadixThemeSelect } from "@radix-ui/themes";
import { styled } from "~/stitches";

/**
 * Select Root
 */

type SelectRootProps = {
  variant: SelectVariant;
  size?: MorphThemeSize;
  value: string | null;
  label?: string;
  description?: string;
  onChange?: (value: string | null) => void;
  onOpenChange?: (open: boolean) => void;
  errorMessages?: ErrorMessages;
  isDisabled?: boolean; // default false
  isReadOnly?: boolean; // default false
  isClearable?: boolean; // default false
};

type SelectContextValue = SelectRootProps & {
  triggerRef: ForwardedRef<HTMLButtonElement>;
};

const selectContext = createContext<SelectContextValue>(
  {} as SelectContextValue
);

const useSelectContext = () => {
  return useContext(selectContext);
};

const _SelectRoot = (
  { children, ...props }: SelectRootProps & { children: ReactNode },
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const {
    value,
    label,
    description,
    errorMessages,
    onChange,
    onOpenChange,
    isDisabled,
    isReadOnly,
    size,
  } = props;

  const radixSize = getRadixSize(size);

  return (
    <selectContext.Provider value={{ ...props, triggerRef: ref }}>
      <InputStack
        errorMessage={errorMessages}
        label={label}
        description={description}
        size={size}
      >
        <RadixThemeSelect.Root
          size={radixSize}
          value={value ?? undefined}
          onValueChange={onChange}
          onOpenChange={onOpenChange}
          disabled={isDisabled || isReadOnly}
        >
          {children}
        </RadixThemeSelect.Root>
      </InputStack>
    </selectContext.Provider>
  );
};

const SelectRoot = forwardRef(_SelectRoot);

type SelectTriggerProps = {
  placeholder?: string;
};

const SelectTrigger = (props: SelectTriggerProps) => {
  const { placeholder } = props;
  const { variant, errorMessages, triggerRef } = useSelectContext();

  const isInvalid = getIsInvalid(errorMessages);
  const radixVariant = getTriggerVariant(variant, isInvalid);
  const radixColor = getTriggerColor(isInvalid);

  return (
    <RadixThemeSelect.Trigger
      ref={triggerRef}
      variant={radixVariant}
      color={radixColor}
    />
  );
};

const SelectContent = ({ children }: { children: ReactNode }) => {
  const { variant } = useSelectContext();

  const radixVariant = getContentVariant(variant);
  const radixColor = getContentColor();

  return (
    <RadixThemeSelect.Content variant={radixVariant} color={radixColor}>
      {children}
    </RadixThemeSelect.Content>
  );
};

const StyledSelectItem = styled(RadixThemeSelect.Item, {
  cursor: "pointer",
  marginBottom: "4px",
  "&:hover": {
    backgroundColor: "$bg1",
  },
});

const SelectItem = ({
  value,
  children,
}: {
  value: string;
  children: ReactNode;
}) => {
  return <StyledSelectItem value={value}>{children}</StyledSelectItem>;
};

export { SelectRoot, SelectTrigger, SelectContent, SelectItem };
export type { SelectRootProps };
