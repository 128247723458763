import { useQueryClient } from "react-query";
import { useImportOtherServiceModal } from "~/features/ImportOtherService/useImportOtherServiceModal";
import { IntegrationDialogPresenter } from "~/presenters/integration/integrationDialogPresenter"
import { templateAuthKeys } from "~/serverStateStore/templateAuth";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useListTemplate } from "./useListTemplate";
import { useFindTemplateDetail } from "./useFindTemplateDetail";
import { useImportDataFromExternalServices } from "./useImportDataFromExternalServices";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useFindPaymentObjectLoadable } from "../plan/useFindPaymentObjectLoadable";
import { usePreferenceModal } from "~/features/GlobalNavigation";

export const IntegrationDialogContainer = () => {
  const teamSlug = useTeamSlug()
  const { onClose, modalState, setOtherService, setIntegrationId, selectedTemplate, selectedIntegrationId } =
    useImportOtherServiceModal();
  const client = useQueryClient();

  const paymentLoadable = useFindPaymentObjectLoadable({ teamSlug })

  const { listTemplateLoadable } = useListTemplate()
  const { findTemplateDetailLoadable } = useFindTemplateDetail({
    templateId: selectedTemplate?.templateId || "",
    enabled: modalState.isOpen,
  })
  const { importDataFromExternalServicesExecutable } = useImportDataFromExternalServices()
  const { onOpen: onOpenPreferenceModal } = usePreferenceModal();

  const handleSelectIntegration = (integrationId: string) => {
    setIntegrationId(String(integrationId));
    const keys = templateAuthKeys.all({
      templateId: selectedTemplate?.templateId || "",
    });
    invalidateAll(client, keys);
  };

  if (!listTemplateLoadable.data || !findTemplateDetailLoadable.data) {
    return <></>
  }

  return (
    <>
      <IntegrationDialogPresenter
        paymentLoadable={paymentLoadable}
        listTemplateClientModel={listTemplateLoadable.data}
        findTemplateDetailClientModel={findTemplateDetailLoadable.data}
        importDataFromExternalServicesExecutable={importDataFromExternalServicesExecutable}
        modalState={modalState}
        selectedIntegrationId={selectedIntegrationId}
        selectedTemplate={selectedTemplate}
        onOpenPreferenceModal={onOpenPreferenceModal}
        handleSelectIntegration={handleSelectIntegration}
        setOtherService={setOtherService}
        onClose={onClose}
      />
    </>
  );
};
