import { useState } from "react";
import { Executable } from "~/clientModel/executable";
import { ComputedFieldSyncScheduleClientModel } from "~/clientModel/tables/table/computedFieldSyncSchedule";
import { Button } from "~/components_next/Button";
import { Drawer } from "~/components_next/Drawer";
import { EditComputedFieldsScheduledSyncSettingForm } from "./EditComputedFieldsScheduledSyncSettingForm";

type EditDrawerBodyAndFooterProps = {
  syncSchedule: ComputedFieldSyncScheduleClientModel;
  updateSyncScheduleExecutable: Executable<
    {
      syncSchedule: ComputedFieldSyncScheduleClientModel;
    },
    unknown
  >;
};

export const EditDrawerBodyAndFooter = (
  props: EditDrawerBodyAndFooterProps
) => {
  const { updateSyncScheduleExecutable, syncSchedule } = props;

  const [editingSyncSchedule, setEditingSyncSchedule] = useState(syncSchedule);

  const handleCreateClick = () => {
    updateSyncScheduleExecutable.execute({
      syncSchedule: editingSyncSchedule,
    });
  };

  return (
    <>
      <Drawer.Body>
        <EditComputedFieldsScheduledSyncSettingForm
          value={editingSyncSchedule}
          onChange={setEditingSyncSchedule}
        />
      </Drawer.Body>
      <Drawer.Footer>
        <Button
          onClick={handleCreateClick}
          isLoading={updateSyncScheduleExecutable.isExecuting}
          variant="primary"
        >
          Save
        </Button>
      </Drawer.Footer>
    </>
  );
};
