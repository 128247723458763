import constate from "constate";
import { useMutation } from "react-query";
import { match, P } from "ts-pattern";
import { NotebookCellObjectWithMeta } from "~/features/FlipNotebook/types/NotebookCellObjectWithMeta.type";
import { useNotebookLiveObject } from "~/features/RealtimeCollaboration/Notebook/useNotebookLiveObject";
import {
  useUpdateNotebookCellMutaiton,
  useUpdateNotebookCellsMetaMutaiton,
  useUpdateNotebookCellsMutaiton,
} from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useNotebookId } from "~/utilHooks/useNotebookId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

const detectCellMetaChange = (
  prevCell: NotebookCellObjectWithMeta,
  currentCell: NotebookCellObjectWithMeta
) => {
  const prevSettings = prevCell.settings;
  const currentSettings = currentCell.settings;
  return JSON.stringify(prevSettings) !== JSON.stringify(currentSettings);
};

const detectSourceChange = (
  prevCell: NotebookCellObjectWithMeta,
  currentCell: NotebookCellObjectWithMeta
) => {
  return JSON.stringify(prevCell.source) !== JSON.stringify(currentCell.source);
};

const getSourceUpdatedCells = (
  prevCells: NotebookCellObjectWithMeta[],
  currentCells: NotebookCellObjectWithMeta[]
) => {
  return currentCells.filter((currentCell) => {
    const findCell = prevCells.find(
      (prevCell) => prevCell.cellId === currentCell.cellId
    );
    if (!findCell) return false; // これありえないはずなので弾いておく

    return detectSourceChange(findCell, currentCell);
  });
};

const getMetaUpdatedCells = (
  prevCells: NotebookCellObjectWithMeta[],
  currentCells: NotebookCellObjectWithMeta[]
) => {
  return currentCells.filter((currentCell) => {
    const findCell = prevCells.find(
      (prevCell) => prevCell.cellId === currentCell.cellId
    );
    if (!findCell) return false; // これありえないはずなので弾いておく

    // 基本的にメタ情報以外の更新は下位コンポーネントで行われているはず。
    // メタ情報の変更だけチェックする
    return detectCellMetaChange(findCell, currentCell);
  });
};

const findViewIdOutput = (cell: NotebookCellObjectWithMeta) => {
  const findCell = cell.outputs.find(
    (output) => output.contentType === "application/morph.view_id"
  );
  if (!findCell) return undefined;

  return match(findCell)
    .with({ value: P.string }, ({ value }) => value)
    .otherwise(() => undefined);
};

const useNotebookCellsContext = (props: {
  cells: NotebookCellObjectWithMeta[];
}) => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const notebookId = useNotebookId();

  const { cells } = props;

  const { cells: liveCells, updateNotebookCells } = useNotebookLiveObject();

  const onUpdateLocalCell = (cellObject: NotebookCellObjectWithMeta) => {
    const cellIndex = liveCells?.findIndex(
      (cell) => cell.cellId === cellObject.cellId
    );

    if (cellIndex === undefined || cellIndex < 0) return;

    updateNotebookCells({
      cell: cellObject,
      index: cellIndex,
    });

    const sourceUpdatedCells = getSourceUpdatedCells(cells, [cellObject]);
    onUpdateNotebookCells(
      sourceUpdatedCells.map((cell) => {
        if (cell.cellType !== "view")
          return {
            cellId: cell.cellId,
            cellType: cell.cellType,
            source: cell.source,
          };

        const viewId = findViewIdOutput(cell);

        return {
          cellId: cell.cellId,
          cellType: cell.cellType,
          source: cell.source,
          viewIdToInvalidate: viewId,
        };
      })
    );

    const metaUpdatedCells = getMetaUpdatedCells(cells, [cellObject]);
    onUpdateNotebookCellsMeta(
      metaUpdatedCells.map((cell) => ({
        cellId: cell.cellId,
        hidePrompt: cell.hiddenPrompt,
        settings: cell.settings,
        parentIds: cell.parentIds,
      }))
    );
  };

  const onUpdateSingleCellServeFirst = async (
    cellObject: NotebookCellObjectWithMeta
  ) => {
    const cellIndex = liveCells?.findIndex(
      (cell) => cell.cellId === cellObject.cellId
    );

    if (cellIndex === undefined || cellIndex < 0) return;

    const updateResponse = await onUdpateServerNotebookCell({
      cellId: cellObject.cellId,
      cellType: cellObject.cellType,
      source: cellObject.source,
      isPublic: false,
    });

    updateNotebookCells({
      cell: updateResponse as NotebookCellObjectWithMeta,
      index: cellIndex,
    });
  };

  const onUpdateAllLocalCell = (_cells: NotebookCellObjectWithMeta[]) => {
    // setLocalCells(cells);
    _cells.forEach((cell) => {
      const cellIndex = liveCells?.findIndex(
        (liveCell) => cell.cellId === liveCell.cellId
      );

      if (cellIndex === undefined || cellIndex < 0) return;

      updateNotebookCells({
        cell: cell,
        index: cellIndex,
      });
    });

    const sourceUpdatedCells = getSourceUpdatedCells(cells, _cells);
    onUpdateNotebookCells(
      sourceUpdatedCells.map((cell) => {
        if (cell.cellType !== "view")
          return {
            cellId: cell.cellId,
            cellType: cell.cellType,
            source: cell.source,
          };

        const viewId = findViewIdOutput(cell);

        return {
          cellId: cell.cellId,
          cellType: cell.cellType,
          source: cell.source,
          viewIdToInvalidate: viewId,
        };
      })
    );

    const metaUpdatedCells = getMetaUpdatedCells(cells, _cells);
    onUpdateNotebookCellsMeta(
      metaUpdatedCells.map((cell) => ({
        cellId: cell.cellId,
        hidePrompt: cell.hiddenPrompt,
        settings: cell.settings,
        parentIds: cell.parentIds,
      }))
    );
  };

  /**
   */
  const {
    mutateAsync: onUpdateNotebookCellsMeta,
    isLoading: isUpdatingCellsMeta,
  } = useMutation(
    useUpdateNotebookCellsMetaMutaiton({
      teamSlug,
      databaseId,
      notebookId,
    })
  );

  const { mutateAsync: onUpdateNotebookCells, isLoading: isUpdatingCells } =
    useMutation(
      useUpdateNotebookCellsMutaiton({
        teamSlug,
        databaseId,
        notebookId,
      })
    );

  const { mutateAsync: onUdpateServerNotebookCell } = useMutation(
    useUpdateNotebookCellMutaiton({
      teamSlug,
      databaseId,
      notebookId,
    })
  );

  return {
    localCells: liveCells ?? [],
    onUpdateLocalCell,
    onUpdateAllLocalCell,
    onUpdateSingleCellServeFirst,
    isUpdatingCellsMeta,
  };
};

export const [
  NotebookCellsProvider,
  useNotebookCells,
  useNotebookUpdateCell,
  useNotebookUpdateAllCells,
  useNotebookUpdateSingleCellServeFirst,
  useNotebookIsUpdatingCell,
] = constate(
  useNotebookCellsContext,
  (value) => value.localCells,
  (value) => value.onUpdateLocalCell,
  (value) => value.onUpdateAllLocalCell,
  (value) => value.onUpdateSingleCellServeFirst,
  (value) => value.isUpdatingCellsMeta
);

/**
 * Cellの更新はやるべき処理が場合によって違うので、処置をまとめずに個別に実装する
 */
