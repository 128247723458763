import { bucketKeys } from "../keys";

// types
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiBucket from "~/api/useApiBucket";

interface UseListBucketsQueryParams {
  teamSlug: string;
  bucketName?: string;
  limit?: number;
  skip?: number;
}

const useListBucketsQuery = ({
  teamSlug,
  bucketName,
  limit,
  skip,
}: UseListBucketsQueryParams) => {
  const { _listBuckets } = useApiBucket();

  const client = useQueryClient();
  client.setQueryDefaults(
    bucketKeys.listBucket({ teamSlug, bucketName, limit, skip }),
    commonQueryOptions
  );

  return {
    queryKey: bucketKeys.listBucket({
      teamSlug,
      bucketName,
      limit,
      skip,
    }),
    queryFn: () => {
      return _listBuckets({
        teamSlug,
        bucketName,
        limit,
        skip,
      });
    },
  };
};

export { useListBucketsQuery };
