import { CsvInsertSchemaEditClientModel } from "~/clientModel/csvImport";
import { FieldsClientModel } from "~/clientModel/fields";
import { Loadable } from "~/clientModel/loadable";
import { Divider } from "~/components_next/Divider";
import { Flex } from "~/components_next/Flex";
import { FieldMappingRow } from "./FieldMappingRow";
import { FieldClientModel } from "~/clientModel/fields/field";
import { useErrorToast } from "~/components_next/Error";
import { Text } from "~/components_next/Text";
import { RecordsTable } from "~/components_next/RecordsTable";
import { Button } from "~/components_next/Button";
import { LuSparkles } from "react-icons/lu";
import { useAutoDecetMappingFields } from "./useAutoDecetMappingFields";
import { useState } from "react";
import { Callout } from "~/components_next/Callout";

type CsvInserttSchemaEditorProps = {
  importSchema: CsvInsertSchemaEditClientModel | null;
  onImportSchemaChange: (importSchema: CsvInsertSchemaEditClientModel) => void;
  fieldsLoadable: Loadable<FieldsClientModel>;
};

const CsvInsertSchemaEditor = (props: CsvInserttSchemaEditorProps) => {
  const { importSchema, onImportSchemaChange, fieldsLoadable } = props;

  const { errorToast } = useErrorToast({});

  const handleSelectMappingField = ({
    field,
    colNum,
    originalKey,
  }: {
    field: FieldClientModel;
    colNum: number;
    originalKey: string;
  }) => {
    try {
      if (!importSchema) {
        throw new Error("There is no schema to edit.");
      }

      onImportSchemaChange(
        importSchema.mapField({
          field,
          colNum,
          originalKey,
        })
      );
    } catch (e) {
      errorToast(e);
    }
  };

  const handleRemoveMappingField = (originalKey: string) => {
    try {
      if (!importSchema) {
        throw new Error("There is no schema to edit.");
      }

      onImportSchemaChange(importSchema.removeMappedField(originalKey));
    } catch (e) {
      errorToast(e);
    }
  };

  /**
   * Field mapping
   */
  const getMappgedField = (colNum: number) => {
    if (!importSchema) {
      return null;
    }

    return importSchema.mappedFields.find((f) => f.colNum === colNum) || null;
  };

  const [autoDetectFailed, setAutoDetectFailed] = useState(false);
  const { runAutoDetect } = useAutoDecetMappingFields();

  const handleAutoDetect = async () => {
    if (!importSchema?.estimatedFields || !fieldsLoadable.data) {
      return;
    }

    setAutoDetectFailed(false);

    const result = runAutoDetect(
      importSchema.estimatedFields,
      fieldsLoadable.data.allFields
    );

    if (result.length === 0) {
      setAutoDetectFailed(true);
    }

    const mappedInstance = result.reduce((acc, cur) => {
      return acc.mapField(cur);
    }, importSchema);

    onImportSchemaChange(mappedInstance);

    // if (result) {
    //   onImportSchemaChange(result);
    // }
  };

  return (
    <>
      <Flex direction="column" gap="4" py="2">
        <Flex direction="column" gap="2">
          <Flex align="center" gap="4">
            <Text variant="description">Configuration</Text>
            <Button variant="secondary" size="xs" onClick={handleAutoDetect}>
              <LuSparkles />
              Auto detect
            </Button>
          </Flex>
          {autoDetectFailed && (
            <Callout
              type="alert"
              title="Auto detect faild."
              description="No field detected as same field. Please map your import fields manually."
            />
          )}
          {importSchema?.estimatedFields.map((estimatedField, i) => {
            return (
              <FieldMappingRow
                key={i}
                field={estimatedField.field}
                fieldsLoadable={fieldsLoadable}
                onSelectMappingField={(field) => {
                  handleSelectMappingField({
                    field,
                    colNum: i,
                    originalKey: estimatedField.field.name,
                  });
                }}
                onRemoveMappingField={() => {
                  handleRemoveMappingField(estimatedField.field.name);
                }}
                mappedField={getMappgedField(i)?.field || null}
              />
            );
          })}
        </Flex>
        <Divider />
        {importSchema && (
          <Flex direction="column" gap="2">
            <Text variant="description">Preview</Text>
            <RecordsTable
              key={importSchema.previewFields.allFields
                .map((f) => f.name)
                .join(",")}
              records={importSchema.previewRecords}
              fields={importSchema.previewFields}
              isReadOnly
              noPadding
            />
          </Flex>
        )}
      </Flex>
    </>
  );
};

export { CsvInsertSchemaEditor, type CsvInserttSchemaEditorProps };
