import { FieldClientModel } from "~/clientModel/fields/field";
import { EmbeddingSortConditionUnitClientModel } from "./EmbeddingSortConditionUnitClientModel";

export class EmbeddingSortConditionUnitClientModelFactory {
  public static createEmptyEmbeddingSortConditions(
    targetField: FieldClientModel
  ): EmbeddingSortConditionUnitClientModel {
    return new EmbeddingSortConditionUnitClientModel({
      targetField,
      operator: "<=>",
      inputValue: "",
    });
  }
}
