import { sqlHistoriesKeys } from "../keys";

// types
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiSQL from "~/api/useApiSQL";

interface UseListGenerateSqlHistoriesQueryParams {
  teamSlug: string;
  databaseId: string;
  tableSlug?: string;
  viewId?: string;
  limit?: number;
  skip?: number;
}

const useListGenerateSqlHistoriesQuery = ({
  databaseId,
  tableSlug,
  teamSlug,
  viewId,
  limit,
  skip,
}: UseListGenerateSqlHistoriesQueryParams) => {
  const { _getGeneratedSqlHistory } = useApiSQL();

  const client = useQueryClient();
  client.setQueryDefaults(
    sqlHistoriesKeys.getGeneratedSqlHistory({
      databaseId,
      teamSlug,
      viewId,
      limit,
      skip,
    }),
    commonQueryOptions
  );

  return {
    queryKey: sqlHistoriesKeys.getGeneratedSqlHistory({
      databaseId,
      tableSlug,
      teamSlug,
      viewId,
      limit,
      skip,
    }),
    queryFn: () => {
      return _getGeneratedSqlHistory({
        teamSlug,
        databaseId,
        tableSlug,
        viewId,
        limit,
        skip,
      });
    },
  };
};

export { useListGenerateSqlHistoriesQuery };
