import {
  BucketClientModelBase,
  BucketPermissions,
} from "./BucketClientModelBase";

export class SourceBucketClientModel extends BucketClientModelBase {
  constructor({
    bucketId,
    bucketName,
    readPermission,
    createPermission,
    updatePermission,
  }: {
    bucketId: string;
    bucketName: string;
    readPermission: BucketPermissions;
    createPermission: BucketPermissions;
    updatePermission: BucketPermissions;
  }) {
    super({
      bucketId,
      bucketName,
      readPermission,
      createPermission,
      updatePermission,
    });
  }

  public static getBucketName({
    databaseId,
    tableSlug,
  }: {
    databaseId: string;
    tableSlug: string;
  }) {
    return `${databaseId}_${tableSlug}`;
  }
}
