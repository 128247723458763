import { RecordSortConditionUnit } from "@usemorph/morph-dashboard-types";
import { SortConditionUnitClientModelDefactory } from "./SortConditionUnit/SortConditionUnitClientModelDefactory";
import { SortConditionsClientModel } from "./SortConditionsClientModel";

export class SortConditionsClientModelDefactory {
  public static toRecordSortConditionUnits(
    sortConditions: SortConditionsClientModel
  ): RecordSortConditionUnit[] {
    return sortConditions.data.sortConditionUnits.map((sortConditionUnit) =>
      SortConditionUnitClientModelDefactory.toRecordSortConditionUnit(
        sortConditionUnit
      )
    );
  }
}
