import { TypedStorage } from "../storage";
import { AtomEffect } from "recoil";
import { Serializable } from "../types";
import { DynamicStorageItem } from "../storageItem";

export const dynamicStorageEffect = <
  ValueType,
  Param extends Readonly<{ [key: string]: Serializable }>
>(
  storage: TypedStorage,
  dynamicStorageItem: DynamicStorageItem<ValueType, Param>,
  param: Param,
  {
    duration,
  }: {
    duration?: number;
  }
): AtomEffect<ValueType> => {
  return ({ setSelf, onSet }) => {
    const storageValue = storage.get(dynamicStorageItem(param));
    if (storageValue != null) {
      setSelf(storageValue);
    }

    onSet((newValue) =>
      storage.set(
        dynamicStorageItem(param),
        newValue,
        duration ? Date.now() + duration : undefined
      )
    );
  };
};
