import { ForwardedRef, forwardRef } from "react";
import { TimeRecordEntryClientModel } from "~/clientModel/records/record/recordEntry";
import { Input } from "~/components_next/Input";
import { RecordValueInputCommonProps } from "../../type";

const TimeInput = forwardRef(function TimeInput(
  props: RecordValueInputCommonProps<TimeRecordEntryClientModel>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { editingRecordEntry, onChange, field, size, isReadonly } = props;

  const validatedValue = editingRecordEntry.getValidatedValue(field);

  const value = validatedValue.isValid ? validatedValue.value : "";

  const errorMessages = validatedValue.errorMessages;

  const handleChange = (value: string) => {
    onChange?.(editingRecordEntry.updateValue(value));
  };

  return (
    <Input
      variant="primary"
      size={size}
      value={value ?? ""}
      onChange={(e) => handleChange(e.target.value)}
      readOnly={isReadonly}
      errorMessage={errorMessages}
      type="time"
      ref={ref}
    />
  );
});

export { TimeInput };
