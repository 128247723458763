import { SimpleDropdownMenu } from "~/components_next/DropdownMenu"
import { Box } from "~/components_next/Box";
import { NotificationList } from "./NotificationList";
import { UseExecutable } from "~/clientModel/executable";
import { UseMarkAsReadNotificationMutationProps } from "~/serverStateStore/notification/mutations/useMarkAsReadNotificationMutation";
import { MessageClientModel } from "~/clientModel/general";
import { UseListNotificationQueryProps } from "~/containers/notification/createUseListNotificationLoadable";
import { NotificationDropdownTrigger } from "./NotificationDropdownTrigger";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { ListNotificationClientModel } from "~/clientModel/notification";

interface NotificationDropdownProps {
  useListNotificationLoadable: UseLoadable<UseListNotificationQueryProps, ListNotificationClientModel>
  useMarkAsReadExecutable: UseExecutable<void, UseMarkAsReadNotificationMutationProps, MessageClientModel, unknown>
}

export const NotificationDropdown = (props: NotificationDropdownProps) => {
  const { useListNotificationLoadable, useMarkAsReadExecutable } = props

  return (
    <>
      <SimpleDropdownMenu
        trigger={
          <Box
            hoverable
            css={{
              "&:hover": {
                backgroundColor: "$bg2",
              },
              "&:active": {
                backgroundColor: "$bg3",
              },
            }}
            px="2"
          >
            <NotificationDropdownTrigger
              useListNotificationLoadable={useListNotificationLoadable}
            />
          </Box>
        }
      >
        <Box p="1" css={{ width: "400px" }}>
          <NotificationList
            useListNotificationLoadable={useListNotificationLoadable}
            useMarkAsReadExecutable={useMarkAsReadExecutable}
          />
        </Box>
      </SimpleDropdownMenu>
    </>
  )
}
