import { RecordEntryClientModel } from "./recordEntry";
import { RecordIdentifierClientModel } from "./recordIdentifier";

type RecordClientModelData = {
  entries: RecordEntryClientModel[];
  recordIdentifier: RecordIdentifierClientModel;
};

export class RecordClientModel {
  readonly #data: RecordClientModelData;

  constructor(data: RecordClientModelData) {
    this.#data = data;
  }

  public get data(): RecordClientModelData {
    return this.#data;
  }

  public get allEntries(): RecordEntryClientModel[] {
    return this.#data.entries;
  }

  public get recordIdentifier(): RecordIdentifierClientModel {
    return this.#data.recordIdentifier;
  }

  public get isSame(): (other: RecordClientModel) => boolean {
    return (other) =>
      this.#data.recordIdentifier.isSame(other.recordIdentifier);
  }

  public selectEntries(keys: string[]): RecordClientModel {
    return new RecordClientModel({
      ...this.#data,
      entries: this.#data.entries.filter((entry) => keys.includes(entry.key)),
    });
  }

  public selectEntry(key: string): RecordEntryClientModel | null {
    const entry = this.#data.entries.find((entry) => entry.key === key);

    return entry ?? null;
  }

  public selectEntryOrThrow(key: string): RecordEntryClientModel {
    const entry = this.#data.entries.find((entry) => entry.key === key);
    if (!entry) {
      throw new Error(`Entry with key ${key} not found`);
    }
    return entry;
  }
}
