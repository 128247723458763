import { Tooltip as RadixThemeTooltip } from "@radix-ui/themes";
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from "react";
import { Flex } from "../Flex";

type RadixThemeTooltipProps = ComponentPropsWithoutRef<
  typeof RadixThemeTooltip
>;

type TooltipProps = Pick<RadixThemeTooltipProps, "content" | "children"> & {
  isDisabled?: boolean;
};

const _Tooltip = (props: TooltipProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { content, isDisabled = false, children } = props;

  if (isDisabled) {
    return <Flex ref={ref}>{children}</Flex>;
  }

  return (
    <RadixThemeTooltip ref={ref} content={content}>
      <Flex>{children}</Flex>
    </RadixThemeTooltip>
  );
};

const Tooltip = forwardRef(_Tooltip);

export { Tooltip };
