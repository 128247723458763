import { importsKeys } from "../keys";

// types
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiImport from "~/api/useApiImport";

interface UselistImportIntegrationHistoriesQueryPrams {
  databaseId: string;
  tableSlug?: string;
  teamSlug: string;
  limit?: number;
  skip?: number;
}

const uselistImportIntegrationHistoriesQuery = ({
  databaseId,
  tableSlug,
  teamSlug,
  limit,
  skip,
}: UselistImportIntegrationHistoriesQueryPrams) => {
  const { _listImportIntegrationHistories } = useApiImport();
  const client = useQueryClient();

  client.setQueryDefaults(
    importsKeys.listImportStatusFromOtherServices({ teamSlug, databaseId }),
    commonQueryOptions
  );

  return {
    queryKey: importsKeys.listImportIntegrationHistories({
      teamSlug,
      databaseId,
      limit,
      skip,
      tableSlug,
    }),
    queryFn: () => {
      return _listImportIntegrationHistories({
        teamSlug,
        databaseId,
        limit,
        skip,
        tableSlug,
      });
    },
  };
};

export { uselistImportIntegrationHistoriesQuery };
