import { Editor, EditorProps } from "@monaco-editor/react";
import { Box, Flex } from "@radix-ui/themes";
import { useCallback, useState } from "react";
import { FormatCode } from "./Toolbar/FormatCode";
import {
  IncreaseFontSize,
  DecreaseFontSize,
  ChangeFontSizeOptions,
} from "./Toolbar/ChangeFontSize";
import { DownloadCode, DownloadCodeOptions } from "./Toolbar/DownloadCode";
import { styled } from "~/stitches";

const CodeEditorToolbarWrapper = styled(Flex, {
  position: "absolute",
  top: 0,
  right: 0,
  backgroundColor: "$bg3",
});

type ToolbarItemOptionsDict = {
  fontSize: ChangeFontSizeOptions;
  format: Record<string, never>;
  download: DownloadCodeOptions;
};

type ToolbarItemOptions = {
  [K in keyof ToolbarItemOptionsDict]?: ToolbarItemOptionsDict[K];
};

type CodeEditorProps = {
  value: string;
  onChange?: (value: string) => void;
  isReadOnly?: boolean;
  language?: string;
  overwriteOptions?: EditorProps["options"];
  toolBarItemOptions?: ToolbarItemOptions;
  height: EditorProps["height"];
};

type IStandaloneCodeEditor = Parameters<
  Exclude<EditorProps["onMount"], undefined>
>[0];

const CodeEditor = (props: CodeEditorProps) => {
  const {
    value,
    onChange,
    language,
    isReadOnly = false,
    overwriteOptions,
    toolBarItemOptions,
    height,
  } = props;

  const [fontSize, setFontSize] = useState(16);

  const [editor, setEditor] = useState<IStandaloneCodeEditor>();

  const handleMount = useCallback((editor: IStandaloneCodeEditor) => {
    setEditor(editor);
  }, []);

  const handleFormat = useCallback(() => {
    editor?.getAction("editor.action.formatDocument")?.run();
  }, [editor]);

  return (
    <Box position="relative" width="100%">
      <Editor
        height={height}
        language={language}
        value={value ?? ""}
        onChange={(value) => {
          if (onChange) {
            value ? onChange(value) : onChange("");
          }
        }}
        theme={"vs-dark"}
        options={{
          padding: {
            top: 24,
            bottom: 60,
          },
          scrollBeyondLastLine: false,
          minimap: {
            enabled: false,
          },
          fontSize: fontSize,
          readOnly: isReadOnly,
          ...overwriteOptions,
        }}
        onMount={handleMount}
      />
      {/* ツールバー */}
      {toolBarItemOptions && (
        <CodeEditorToolbarWrapper>
          {toolBarItemOptions.fontSize && (
            <>
              <DecreaseFontSize
                onChangeFontSize={setFontSize}
                step={toolBarItemOptions.fontSize.step}
              />
              <IncreaseFontSize
                onChangeFontSize={setFontSize}
                step={toolBarItemOptions.fontSize.step}
              />
            </>
          )}
          {toolBarItemOptions.format && (
            <FormatCode onFormatCode={handleFormat} />
          )}
          {toolBarItemOptions.download && value && (
            <DownloadCode
              value={value}
              baseName={toolBarItemOptions.download.baseName}
              extension={toolBarItemOptions.download.extension}
              mimeSubType={toolBarItemOptions.download.mimeSubType}
            />
          )}
        </CodeEditorToolbarWrapper>
      )}
    </Box>
  );
};

export { CodeEditor };
