import { DndContext, DragEndEvent, pointerWithin } from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import { Flex } from "@radix-ui/themes";
import { Fragment } from "react";
import { styled } from "~/stitches";
import { useRecordsKanbanOnUpdateColumnValues } from "../context/RecordsKanbanBaseContext";
import { KanbanValues } from "../RecordsKanbanBase";
import { RecordsKanbanHeader } from "./RecordsKanbanHeader";

const StyledFlex = styled(Flex, {});

type RecordsKanbanHeadersProps = {
  width: number;
  localValues: KanbanValues[];
};

const RecordsKanbanHeaders = (props: RecordsKanbanHeadersProps) => {
  const { localValues, width } = props;

  const onUpdateColumnValues = useRecordsKanbanOnUpdateColumnValues();

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    const columnValues = localValues.map((value) =>
      String(value.groupingFieldValue)
    );

    if (active && over && active.id !== over.id) {
      const oldIndex = columnValues.findIndex(
        (value) => value === (active.id as string)
      );
      const newIndex = columnValues.findIndex(
        (value) => value === (over.id as string)
      );
      const reordered = arrayMove([...columnValues], oldIndex, newIndex);
      onUpdateColumnValues?.(reordered);
    }
  };

  const totalWidth = localValues.length * (width + 20);

  return (
    <DndContext onDragEnd={handleDragEnd} collisionDetection={pointerWithin}>
      <SortableContext
        items={localValues.map(({ groupingFieldValue }) =>
          String(groupingFieldValue)
        )}
        strategy={horizontalListSortingStrategy}
      >
        <StyledFlex gap="4" mb="2" css={{ width: `${totalWidth}px` }}>
          {localValues.map((value) => {
            return (
              <Fragment key={String(value.groupingFieldValue)}>
                <RecordsKanbanHeader
                  width={width}
                  value={String(value.groupingFieldValue)}
                />
              </Fragment>
            );
          })}
        </StyledFlex>
      </SortableContext>
    </DndContext>
  );
};

export { RecordsKanbanHeaders };
