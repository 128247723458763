import {
  RecordsTableBase,
  getDefaultColumnSizings,
} from "~/components_next/RecordsTableBase";
import { useViewRecords } from "../../hooks/useViewRecords";
import { useColumnSizing, useSetColumnSizing } from "../../states/columnSizing";
import { extractErrorDetails } from "~/components_next/Error";
import { RecordsTableBaseRecord } from "~/components_next/RecordsTableBase/types";
import { useMemo, useState } from "react";
import { RecordModel } from "~/features/RecordModel";
import { useQueryMode } from "../../states/queryMode";
import { match, P } from "ts-pattern";
import { Callout } from "~/components_next/Callout";
import { Flex } from "@radix-ui/themes";
import { Spinner } from "~/components_next/Spinner";
import { useTableView } from "../../hooks/useProps";
import { useDisclosure } from "~/hooks/useDisclosure";
import { TableComponentRecordDrawer } from "./TableComponentRecordDrawer";

const ReadOnlyTableComponent = () => {
  /**
   * Basic params
   */
  const view = useTableView();
  const { viewId } = view;

  /**
   * Server states
   */

  const {
    data: viewRecordsData,
    status: viewRecordsStatus,
    error: viewRecordsError,
  } = useViewRecords();

  const queryMode = useQueryMode();

  const [displayedSimpleFields, displayedRecordsTableBaseRecords] =
    useMemo(() => {
      if (!viewRecordsData) return [undefined, undefined];

      // 以下を除く
      // - morph_reserved_deleted_atのもの
      // - [queryModeがfiltersAndSorts or nullのとき]isHiddenがtrueのもの
      const displayedSimpleFields = viewRecordsData.fields.filter(
        ({ isHidden, name }) => {
          const removed =
            name === "morph_reserved_deleted_at" ||
            ((!queryMode || queryMode === "filtersAndSorts") &&
              isHidden === true);
          return !removed;
        }
      );

      const displayedTableBaseRecords: RecordsTableBaseRecord[] =
        viewRecordsData.recordsTableBaseRecords.map(
          ({ values, _reservedRecordIndex }) => {
            return {
              _reservedRecordIndex,
              values: displayedSimpleFields.reduce((_values, { name }) => {
                return { ..._values, [name]: values[name] };
              }, {} as RecordModel),
            };
          }
        );

      return [displayedSimpleFields, displayedTableBaseRecords];
    }, [queryMode, viewRecordsData]);

  /**
   * Client states
   */

  const columnSizing =
    useColumnSizing(viewId) ??
    getDefaultColumnSizings(viewRecordsData?.fields ?? []).defaultColumnSizings;
  const setColumnSizing = useSetColumnSizing(viewId);

  /**
   * Drawer
   */
  const { isOpen, setIsOpen } = useDisclosure();
  const [selectedRecord, setSelectedRecord] = useState<RecordModel | null>(
    null
  );

  /**
   * handlers
   */
  /**
   * UI
   */

  if (viewRecordsStatus === "error") {
    const { title, description } = extractErrorDetails(viewRecordsError);
    return (
      <Callout size="sm" type="alert" title={title} description={description} />
    );
  }

  if (
    viewRecordsStatus === "loading" ||
    viewRecordsStatus === "idle" ||
    !displayedSimpleFields
  ) {
    return (
      <Flex height="100%" align="center" justify="center">
        <Spinner />
      </Flex>
    );
  }

  return (
    <>
      {match(queryMode)
        .with(P.union(P.nullish, "filtersAndSorts"), () => (
          <>
            <RecordsTableBase
              editableFields={"none"}
              fields={displayedSimpleFields}
              records={displayedRecordsTableBaseRecords}
              isSelectable={false}
              isOpenable={true}
              columnSizing={columnSizing}
              onColumnSizingChange={setColumnSizing}
              propagateEvent
              onRecordClick={(record) => setSelectedRecord(record.values)}
            />
            <TableComponentRecordDrawer
              record={selectedRecord}
              onClose={() => setSelectedRecord(null)}
              fields={displayedSimpleFields}
              updateRules={view.rules.update}
              deleteRule={view.rules.delete}
              viewId={viewId}
            />
          </>
        ))
        .with(P.union("prompt", "sql"), () => (
          <RecordsTableBase
            editableFields="none"
            fields={displayedSimpleFields}
            records={displayedRecordsTableBaseRecords}
            isSelectable={false}
            propagateEvent
          />
        ))
        .exhaustive()}
    </>
  );
};

export { ReadOnlyTableComponent };
