import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { templateAuthKeys } from "../keys";
import useApiTemplateAuth from '~/api/useApiTemplateAuth';

interface UseCreateNewTemplateAuthMutationParams {
  templateId: string;
  teamSlug: string;
}

const useCreateNewTemplateAuthMutation = ({
  templateId,
  teamSlug,
}: UseCreateNewTemplateAuthMutationParams) => {
  const { _createNewTemplateAuth } = useApiTemplateAuth()
  const client = useQueryClient();

  return {
    mutationFn: ({
      code,
      authInfo,
    }: {
      code?: string;
      authInfo?: { [key: string]: unknown };
    }) => {
      return _createNewTemplateAuth({
        templateId,
        teamSlug,
        code,
        authInfo,
      });
    },
    onSuccess: () => {
      const keys = templateAuthKeys.allListTemplateAuth({ templateId });
      return invalidateAll(client, keys);
    },
  };
};

export { useCreateNewTemplateAuthMutation };
