import {
  mergeValidationResults,
  ValidationResult,
  zValidate,
} from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModelBase } from "../../FieldTypeClientModelBase";
import { z } from "zod";
import { AggregateValueFieldOperatorClientModel } from "./aggregateValueFieldOperators/aggregateValueFieldOperator";
import { ShortTextFieldTypeClientModel } from "../../text/ShortTextFieldTypeClientModel";

type AggregateValueFieldTypeClientModelData = {
  syncTargetTableSlug: string | null;
  syncTargetFieldName: string | null;
  operatorValue: AggregateValueFieldOperatorClientModel["value"] | null;
};

export class AggregateValueFieldTypeClientModel extends FieldTypeClientModelBase {
  readonly #data: AggregateValueFieldTypeClientModelData;

  public get type(): "aggregateValue" {
    return "aggregateValue";
  }

  public get syncedValueType(): ShortTextFieldTypeClientModel {
    return new ShortTextFieldTypeClientModel();
  }

  public constructor(data: AggregateValueFieldTypeClientModelData) {
    super();
    this.#data = data;
  }

  public get data(): AggregateValueFieldTypeClientModelData {
    return this.#data;
  }

  public get fieldTypeLabel(): "Aggregate Value" {
    return "Aggregate Value";
  }

  public get isAutoGenerated(): true {
    return true;
  }

  public get isEmbeddable(): false {
    return false;
  }

  public get canBeTargetOfSyncingByComputedField(): false {
    return false;
  }

  public validate(): ValidationResult {
    return mergeValidationResults(
      zValidate(z.string(), this.#data.syncTargetTableSlug),
      zValidate(z.string(), this.#data.syncTargetFieldName),
      zValidate(z.string(), this.#data.operatorValue)
    );
  }

  public get _updatableFieldTypes() {
    return [];
  }

  public get syncTargetTableSlug(): string | null {
    return this.#data.syncTargetTableSlug;
  }

  public get syncTargetFieldName(): string | null {
    return this.#data.syncTargetFieldName;
  }

  public get operatorValue(): string | null {
    return this.#data.operatorValue;
  }

  public get syncTargetTableSlugOrThrow(): string {
    if (!this.#data.syncTargetTableSlug) {
      throw new Error("syncTargetTable dees not exist");
    }
    return this.#data.syncTargetTableSlug;
  }

  public get syncTargetFieldNameOrThrow(): string {
    if (!this.#data.syncTargetFieldName) {
      throw new Error("syncTargetFieldName dees not exist");
    }
    return this.#data.syncTargetFieldName;
  }

  public get operatorValueOrThrow(): string {
    if (!this.#data.operatorValue) {
      throw new Error("operator dees not exist");
    }
    return this.#data.operatorValue;
  }

  public updateSyncTargetTableSlug(
    tableSlug: string
  ): AggregateValueFieldTypeClientModel {
    return new AggregateValueFieldTypeClientModel({
      ...this.#data,
      syncTargetTableSlug: tableSlug,
    });
  }

  public updateSyncTargetFieldName(
    fieldName: string
  ): AggregateValueFieldTypeClientModel {
    return new AggregateValueFieldTypeClientModel({
      ...this.#data,
      syncTargetFieldName: fieldName,
    });
  }

  public updateOperatorValue(
    operatorValue: AggregateValueFieldOperatorClientModel["value"]
  ): AggregateValueFieldTypeClientModel {
    return new AggregateValueFieldTypeClientModel({
      ...this.#data,
      operatorValue,
    });
  }
}
