import { FieldClientModel } from "~/clientModel/fields/field";
import {
  UpdatableRecordEntryClientModelBaseConstructorParams,
  UpdatableRecordEntryClientModelBase,
} from "../RecordEntryClientModelBase";
import {
  zAllowNullIfNullable,
  zRequiredString,
  zValidate,
} from "../validatorsUtil";
import { z } from "zod";
import { DateDisplaySettingClientModel } from "~/clientModel/fields/field/displaySetting/date";

type DateTimeRecordEntryClientModelData =
  UpdatableRecordEntryClientModelBaseConstructorParams;

export class DateTimeRecordEntryClientModel extends UpdatableRecordEntryClientModelBase<
  string | null
> {
  constructor(data: DateTimeRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "datetime" {
    return "datetime";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([zRequiredString(field), zAllowNullIfNullable(field)]),
      this.data.rawValue
    );
  }

  public getValidatedDisplayValue(field: FieldClientModel) {
    const validatedValue = this.getValidatedValue(field);

    return validatedValue.isValid &&
      field.displaySetting instanceof DateDisplaySettingClientModel
      ? ({
          isValid: true,
          value: field.displaySetting.getDisplayValue(validatedValue.value),
        } as const)
      : validatedValue;
  }

  public updateValue(value: string | null): DateTimeRecordEntryClientModel {
    return new DateTimeRecordEntryClientModel({
      key: this.data.key,
      rawValue: value,
      meta: this.data.meta,
    });
  }
}
