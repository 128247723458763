import { DashboardImportIntegrationStatusListResponse } from "@usemorph/morph-dashboard-types";
import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { ImportStatusesClientModelFactory } from "~/clientModel/importStatus";
import { composeLoadable } from "~/clientModel/loadable";
import { ImportStatusPresenter } from "~/presenters/importStatus/ImportStatusPresenter";
import { useListImportStatusFromOtherServicesQuery } from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

const ImportStatusContainer = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  /**
   * インポートステータスの管理を新エンドポイントに移行中
   */

  // const listCsvImportResults = useQuery(
  //   useListSimpleImportDataSchemaQuery({
  //     teamSlug,
  //     databaseId,
  //   })
  // );

  const listIntegrationImportResults = useQuery({
    ...useListImportStatusFromOtherServicesQuery({
      teamSlug,
      databaseId,
      skip: 0,
      limit: 100,
    }),
    select: useCallback(
      (data: DashboardImportIntegrationStatusListResponse) => {
        return ImportStatusesClientModelFactory.fromExternalImportResponse(
          data.items
        );
      },
      []
    ),
  });
  const integrationStatusLoadable = useMemo(() => {
    return composeLoadable(listIntegrationImportResults);
  }, [listIntegrationImportResults]);

  // const importStatusesLoadable = useMemo(() => {
  //   return match([
  //     listCsvImportResults.status,
  //     listIntegrationImportResults.status,
  //   ])
  //     .with(["success", "success"], () => {
  //       return new ValueLoadable(
  //         ImportStatusesClientModelFactory.fromImportResponses(
  //           listCsvImportResults.data?.items,
  //           listIntegrationImportResults.data?.items
  //         )
  //       );
  //     })
  //     .with(P.union(["loading", P._], [P._, "loading"]), () => {
  //       return new LoadingLoadable();
  //     })
  //     .with(P.union(["error", P._], [P._, "error"]), () => {
  //       return new ErrorLoadable(
  //         listCsvImportResults.error ||
  //           listIntegrationImportResults.error ||
  //           new Error("Unknown error")
  //       );
  //     })
  //     .with(P.union(["idle", P._], [P._, "idle"]), () => {
  //       return new IdleLadable(() => {
  //         listCsvImportResults.refetch();
  //         listIntegrationImportResults.refetch();
  //       });
  //     })
  //     .exhaustive();
  // }, [listCsvImportResults.status, listIntegrationImportResults.status]);

  /**
   * とりあえず現行実装のまま。サーバーと相談中
   */
  // useInterval(() => {
  //   if (
  //     integrationStatusLoadable.status === "success" &&
  //     integrationStatusLoadable.data.importingItems.length > 0
  //   ) {
  //     listIntegrationImportResults.refetch();
  //   }
  //   // console.log(listImports);
  //   // console.log(listImportStatusFromExternalServices);
  //   // console.log(numberOfImportsInProgress);
  //   return;
  // }, 4 * 1000);

  return (
    <>
      <ImportStatusPresenter importStatusLoadable={integrationStatusLoadable} />
    </>
  );
};

export { ImportStatusContainer };
