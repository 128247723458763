import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { SimpleSelect } from "~/components_next/Select";
import { useNotebookCells } from "~/features/FlipNotebook/context/NotebookCellContext";
import { findViewIdFromViewCell } from "~/features/FlipNotebook/util/findViewIdFromViewCell";
import { getDefaultCellName } from "~/features/FlipNotebook/util/getDefaultCellName";
import { useGetViewFieldsQuery } from "~/serverStateStore/views";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

const VariableViewFieldOptions = (props: {
  viewId: string;
  label: string;
  onChange: (value: string | null) => void;
  value: string | null;
}) => {
  const { viewId, onChange, value, label } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const { data: simpleFields } = useQuery({
    ...useGetViewFieldsQuery({
      teamSlug,
      databaseId,
      viewId,
    }),
  });

  const fieldsOptions = useMemo(() => {
    return (
      simpleFields?.fields
        // .filter((f) => f.type === "singleSelect" || f.type === "multiSelect")
        .map((field) => ({
          label: field.displayName || field.name,
          value: field.name,
        })) || []
    );
  }, [simpleFields]);

  return (
    <>
      <SimpleSelect
        description={label}
        variant="primary"
        options={fieldsOptions}
        onChange={(selected) => onChange(selected)}
        value={value}
        size="xs"
      />
    </>
  );
};

type VariablesViewResultOptionsProps = {
  viewId?: string | null;
  labelField?: string | null;
  valueField?: string | null;
  onChange: ({
    viewId,
    labelField,
    valueField,
  }: {
    viewId: string;
    labelField: string;
    valueField: string;
  }) => void;
};

const VariableViewResultOptions = (props: VariablesViewResultOptionsProps) => {
  const { onChange, labelField, valueField, viewId } = props;

  const cells = useNotebookCells();
  const viewOptions = cells
    .filter((cell) => cell.cellType === "view")
    .map((cell) => {
      return {
        value: findViewIdFromViewCell(cell),
        label: getDefaultCellName(cell.cellName),
      };
    });

  const [_viewId, _setViewId] = useState<string | null>(viewId || null);
  const [_labelField, _setLabelField] = useState<string | null>(
    labelField || null
  );
  const [_valueField, _setValueField] = useState<string | null>(
    valueField || null
  );

  useEffect(() => {
    if (!_viewId || !_labelField || !_valueField) {
      return;
    }

    onChange({
      viewId: _viewId,
      labelField: _labelField,
      valueField: _valueField,
    });
  }, [_viewId, _labelField, _valueField]);

  return (
    <>
      <SimpleSelect
        description="View"
        variant="primary"
        options={viewOptions}
        onChange={(selected) => _setViewId(selected)}
        value={_viewId}
        size="xs"
      />

      {_viewId && (
        <VariableViewFieldOptions
          label="Label Field"
          viewId={_viewId}
          onChange={(value) => _setLabelField(value)}
          value={_labelField}
        />
      )}

      {_viewId && (
        <VariableViewFieldOptions
          label="Value Field"
          viewId={_viewId}
          onChange={(value) => _setValueField(value)}
          value={_valueField}
        />
      )}
    </>
  );
};

export { VariableViewResultOptions };
