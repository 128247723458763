import { Flex } from "@radix-ui/themes";
import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { match, P } from "ts-pattern";
import { useTeam } from "~/serverStateStore";
import {
  useFindViewQuery,
  useUpdateViewMutation,
} from "~/serverStateStore/views";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useViewId } from "~/utilHooks/useViewId";
import { BelongedResourceMembers } from "../components/Share/BelongedResourceMembers";
import { GeneralAccessAuthorityCard } from "../components/Share/GeneralAccessAuthorityCard";
import { InvitationInput } from "../components/Share/InvitationInput";
import { AccessAuthority } from "../components/Share/types";

/**
 * Root & General Access
 */
const ViewToolbarShare = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  const { data: viewData } = useQuery(
    useFindViewQuery({
      teamSlug,
      databaseId,
      viewId,
    })
  );

  const { data: team } = useTeam({ teamSlug });

  /**
   * General Access
   */
  const { mutateAsync: updateView } = useMutation({
    ...useUpdateViewMutation({
      databaseId,
      teamSlug,
      viewId,
    }),
  });

  const generalAccess = useMemo((): AccessAuthority => {
    if (!viewData) return "No Access";
    if (viewData.isPrivate) return "No Access";
    return "Read & Write";
  }, [viewData]);
  const handleGeneralAccessChange = (authority: AccessAuthority) => {
    if (!viewData) return;

    match([authority, generalAccess])
      .with(["Read & Write", P.not("Read & Write")], () => {
        updateView({
          isPrivate: false,
          type: viewData.type,
          name: viewData.name,
          condition: viewData.condition,
          setting: viewData.setting,
        });
      })
      .with(["No Access", P.not("No Access")], () => {
        updateView({
          isPrivate: true,
          type: viewData.type,
          name: viewData.name,
          condition: viewData.condition,
          setting: viewData.setting,
        });
      })
      .otherwise(() => {
        return;
      });
  };

  return (
    <>
      <Flex direction="column" gap="4" align="stretch">
        {generalAccess !== "Read & Write" && (
          <>
            <InvitationInput
              resource={{
                viewId,
                resourceType: "view",
              }}
            />
          </>
        )}
        {/* General */}
        <GeneralAccessAuthorityCard
          team={team}
          generalAccess={generalAccess}
          onGeneralAccessChange={handleGeneralAccessChange}
        />
        {/* Members */}
        {viewData?.isPrivate && (
          <BelongedResourceMembers
            resource={{
              viewId,
              resourceType: "view",
            }}
          />
        )}
      </Flex>
    </>
  );
};

export { ViewToolbarShare };
