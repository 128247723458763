import { CodeEditor } from "~/components_next/CodeEditor";

type HTMLEditorProps = {
  html: string | null;
  isReadOnly: boolean;
  onChange?: (html: string | null) => void;
  height: string;
};

const HTMLEditor = (props: HTMLEditorProps) => {
  const { html, isReadOnly, height } = props;
  return (
    <CodeEditor
      value={html ?? ""}
      onChange={(value) => props.onChange?.(value || null)}
      isReadOnly={isReadOnly}
      language="html"
      height={height}
      toolBarItemOptions={{
        download: {
          extension: "html",
          baseName: "morph-download",
          mimeSubType: "html",
        },
        fontSize: {},
        format: {},
      }}
    />
  );
};

export { HTMLEditor };
