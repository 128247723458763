import { ForwardedRef, forwardRef } from "react";
import {
  AutoBigNumberRecordEntryClientModel,
  AutoNumberRecordEntryClientModel,
} from "~/clientModel/records/record/recordEntry";
import { Input } from "~/components_next/Input";
import { RecordValueInputCommonProps } from "../../type";

const AutoNumberReadonlyInput = forwardRef(function AutoNumberReadonlyInput(
  props: RecordValueInputCommonProps<
    AutoNumberRecordEntryClientModel | AutoBigNumberRecordEntryClientModel
  >,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { editingRecordEntry, size, field } = props;

  const validatedValue = editingRecordEntry.getValidatedValue(field);

  const value = validatedValue.isValid ? validatedValue.value : "";

  return (
    <Input
      size={size}
      variant="primary"
      value={value ?? ""}
      type="number"
      readOnly={true}
      ref={ref}
    />
  );
});

export { AutoNumberReadonlyInput };
