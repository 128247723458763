import { FieldClientModel } from "~/clientModel/fields/field";
import {
  ValidationSuccessResult,
  ValidationFailureResult,
} from "~/clientModel/utils/validationResultType";
import { RecordEntryMetaClientModel } from "./recordEntryMeta";

type ValidationSuccessResultWithValue<ValueType> = ValidationSuccessResult & {
  value: ValueType;
};

type TypeValidationFailureResultWithValue = ValidationFailureResult & {
  errorType: "type";
  value: unknown;
};

type FormatValidationFailureResultWithValue<ValueType> =
  ValidationFailureResult & {
    errorType: "format";
    value: ValueType;
  };

export type ValidationResultWithValue<ValueType> =
  | ValidationSuccessResultWithValue<ValueType>
  | TypeValidationFailureResultWithValue
  | FormatValidationFailureResultWithValue<ValueType>;

type RecordEntryClientModelBaseData = {
  key: string;
  rawValue: unknown;
  meta: RecordEntryMetaClientModel | null;
};

abstract class RecordEntryClientModelBase<
  ValueType,
  DisplayValueType = ValueType
> {
  readonly #data: RecordEntryClientModelBaseData;

  public abstract get type(): string;

  constructor(data: RecordEntryClientModelBaseData) {
    this.#data = data;
  }

  public get data(): RecordEntryClientModelBaseData {
    return this.#data;
  }

  public get key(): string {
    return this.#data.key;
  }

  public get rawValue(): unknown {
    return this.#data.rawValue;
  }
  public get meta(): RecordEntryMetaClientModel | null {
    return this.#data.meta;
  }

  public abstract getValidatedValue(
    field: FieldClientModel
  ): ValidationResultWithValue<ValueType>;

  public abstract getValidatedDisplayValue(
    field: FieldClientModel
  ): ValidationResultWithValue<DisplayValueType>;
}

export abstract class UpdatableRecordEntryClientModelBase<
  ValueType
> extends RecordEntryClientModelBase<ValueType> {
  public abstract updateValue(value: ValueType): void;
}

export type UpdatableRecordEntryClientModelBaseConstructorParams =
  ConstructorParameters<typeof UpdatableRecordEntryClientModelBase>[0];

export abstract class ReadonlyRecordEntryClientModelBase<
  ValueType
> extends RecordEntryClientModelBase<ValueType> {}

export type ReadonlyRecordEntryClientModelBaseConstructorParams =
  ConstructorParameters<typeof ReadonlyRecordEntryClientModelBase>[0];
