import { match, P } from "ts-pattern";
import { FieldClientModel } from "~/clientModel/fields/field";
import { FilterOperationClientModel } from "./operations";
import { EqualFilterOperationClientModel } from "./operations/EqualFilterOperationClientModel";
import { IsNullFilterOperationClientModel } from "./operations/IsNullFilterOperationClientModel";
import { NotEqualFilterOperationClientModel } from "./operations/NotEqualFilterOperationClientModel";
import { NotNullFilterOperationClientModel } from "./operations/NotNullFilterOperationClientModel";
import { LessThanFilterOperationClientModel } from "./operations/LessThanFilterOperationClientModel";
import { LessThanOrEqualFilterOperationClientModel } from "./operations/LessThanOrEqualFilterOperationClientModel";
import { GreaterThanFilterOperationClientModel } from "./operations/GreaterThanFilterOperationClientModel";
import { GreaterThanOrEqualFilterOperationClientModel } from "./operations/GreaterThanOrEqualFilterOperationClientModel";
import { LikeFilterOperationClientModel } from "./operations/LikeFilterOperationClientModel";
import { StartsWitFilterOperationClientModel } from "./operations/StartsWithFilterOperationClientModel";
import { EndsWithFilterOperationClientModel } from "./operations/EndsWithFilterOperationClientModel";
import { InFilterOperationClientModel } from "./operations/InFilterOperationClientModel";
import { NotInFilterOperationClientModel } from "./operations/NotInFilterOperationClientModel";
import {
  FieldTypeClientModel,
  SmartFunctionFieldTypeClientModel,
} from "~/clientModel/fields/field/fieldType";

export type FilterConditionUnitClientModelData = {
  targetField: FieldClientModel;
  operation: FilterOperationClientModel;
};

export class FilterConditionUnitClientModel {
  readonly #data: FilterConditionUnitClientModelData;

  constructor(data: FilterConditionUnitClientModelData) {
    this.#data = data;
  }

  public get data(): FilterConditionUnitClientModelData {
    return this.#data;
  }

  public get key(): string {
    return this.#data.targetField.name;
  }

  public get keyLabel(): string {
    return this.#data.targetField.label;
  }

  public get operation(): FilterOperationClientModel {
    return this.#data.operation;
  }

  public get operationValue(): FilterOperationClientModel["operatorValue"] {
    return this.#data.operation.operatorValue;
  }

  public validate() {
    return this.#data.operation.validate(this.#data.targetField);
  }

  public get selectableOperations(): readonly FilterOperationClientModel[] {
    /**
     * 変換時、現在の入力値を変換後にも維持する
     */
    const currentOperationStringValue = match(this.#data.operation)
      .with(
        {
          type: P.union(
            "equal",
            "notEqual",
            "lessThan",
            "lessThanOrEqual",
            "greaterThan",
            "greaterThanOrEqual",
            "like",
            "startsWith",
            "endsWith"
          ),
        },
        (operation) => operation.operandValue
      )
      .with(
        { type: P.union("isNull", "notNull", "me", "in", "notIn") },
        () => null
      )
      .exhaustive();

    const currentOperationStringArrayValue = match(this.#data.operation)
      .with(
        {
          type: P.union("in", "notIn"),
        },
        (operation) => operation.operandValue
      )
      .with(
        {
          type: P.union(
            "isNull",
            "notNull",
            "me",
            "equal",
            "notEqual",
            "lessThan",
            "lessThanOrEqual",
            "greaterThan",
            "greaterThanOrEqual",
            "like",
            "startsWith",
            "endsWith"
          ),
        },
        () => null
      )
      .exhaustive();

    const equal = new EqualFilterOperationClientModel({
      value: currentOperationStringValue ?? "",
    });
    const notEqual = new NotEqualFilterOperationClientModel({
      value: currentOperationStringValue ?? "",
    });
    const lessThan = new LessThanFilterOperationClientModel({
      value: currentOperationStringValue ?? "",
    });
    const lessThanOrEqual = new LessThanOrEqualFilterOperationClientModel({
      value: currentOperationStringValue ?? "",
    });
    const greaterThan = new GreaterThanFilterOperationClientModel({
      value: currentOperationStringValue ?? "",
    });
    const greaterThanOrEqual = new GreaterThanOrEqualFilterOperationClientModel(
      {
        value: currentOperationStringValue ?? "",
      }
    );
    const like = new LikeFilterOperationClientModel({
      value: currentOperationStringValue ?? "",
    });
    const startsWith = new StartsWitFilterOperationClientModel({
      value: currentOperationStringValue ?? "",
    });
    const endsWith = new EndsWithFilterOperationClientModel({
      value: currentOperationStringValue ?? "",
    });
    const inFilter = new InFilterOperationClientModel({
      value: currentOperationStringArrayValue ?? [],
    });
    const notIn = new NotInFilterOperationClientModel({
      value: currentOperationStringArrayValue ?? [],
    });

    const isNull = new IsNullFilterOperationClientModel();
    const notNull = new NotNullFilterOperationClientModel();

    const equalOperations = [equal, notEqual] as const;
    const numberOperations = [
      lessThan,
      lessThanOrEqual,
      greaterThan,
      greaterThanOrEqual,
    ] as const;
    const stringOperations = [like, startsWith, endsWith] as const;
    const arrayOperations = [inFilter, notIn] as const;
    const nullOperations = [isNull, notNull] as const;

    const stringFieldOperations = [
      ...equalOperations,
      ...stringOperations,
      ...arrayOperations,
      ...nullOperations,
    ] as const;
    const numberFieldOperations = [
      ...equalOperations,
      ...numberOperations,
      ...arrayOperations,
      ...nullOperations,
    ] as const;

    // Smart Functionの場合はtypeがメタ情報として保存されているので再起的にOperatorを取得する
    const getOperationsWithTypeWithoutSmartFunction = (
      type: Exclude<
        FieldTypeClientModel,
        SmartFunctionFieldTypeClientModel
      >["type"]
    ): readonly FilterOperationClientModel[] => {
      return (
        match(type)
          // text
          .with("shortText", () => stringFieldOperations)
          .with("longText", () => stringFieldOperations)
          .with("email", () => stringFieldOperations)
          .with("phoneNumber", () => stringFieldOperations)
          .with("url", () => stringFieldOperations)
          .with("date", () => stringFieldOperations)
          .with("datetime", () => stringFieldOperations)
          .with("time", () => stringFieldOperations)

          // number
          .with("number", () => numberFieldOperations)
          .with("bigNumber", () => numberFieldOperations)
          .with("decimal", () => numberFieldOperations)
          .with("autoNumber", () => equalOperations)
          .with("autoBigNumber", () => equalOperations)

          // other primitives
          .with(
            "boolean",
            () => [...equalOperations, ...nullOperations] as const
          )

          // select
          .with(
            "singleSelect",
            () => [...equalOperations, ...nullOperations] as const
          )
          .with(
            "multiSelect",
            () => [...equalOperations, ...nullOperations] as const
          )

          // file
          .with("attachment", () => nullOperations)
          .with("image", () => nullOperations)

          // structured data
          .with("json", () => [...equalOperations, ...nullOperations] as const)
          .with("array", () => [...equalOperations, ...nullOperations] as const)
          .with("html", () => [...equalOperations, ...nullOperations] as const)

          // computed
          .with(
            "formula",
            () => [...equalOperations, ...nullOperations] as const
          )
          .with(
            "syncValue",
            () => [...equalOperations, ...nullOperations] as const
          )
          .with("generateText", () => stringFieldOperations)
          .with("calculation", () => numberFieldOperations)
          .with("aggregateValue", () => numberFieldOperations)

          // system
          .with("createdBy", () => equalOperations)
          .with(
            "lastEditedBy",
            () => [...equalOperations, ...nullOperations] as const
          )
          .with("createdAt", () => equalOperations)
          .with(
            "lastEditedAt",
            () => [...equalOperations, ...nullOperations] as const
          )
          .exhaustive()
      );
    };

    return match(this.#data.targetField.type)
      .with({ type: "smartFunction" }, (type) => {
        // if (type.smartFunction?.valueType) {
        //   return getOperationsWithTypeWithoutSmartFunction(
        //     type.smartFunction.valueType
        //   );
        // }
        // todo
        return [];
      })
      .otherwise((type) =>
        getOperationsWithTypeWithoutSmartFunction(type.type)
      );
  }

  public updateTargetField(
    targetField: FieldClientModel
  ): FilterConditionUnitClientModel {
    return new FilterConditionUnitClientModel({
      ...this.#data,
      targetField,
    });
  }

  public updateOperation(
    operation: FilterOperationClientModel
  ): FilterConditionUnitClientModel {
    return new FilterConditionUnitClientModel({
      ...this.#data,
      operation,
    });
  }
}
