import { useCallback } from "react";
import { match, P } from "ts-pattern";
import { CanvasCellPromptClientModel } from "~/clientModel/canvas";
import { Flex } from "~/components_next/Flex";
import { CanvasCellPromptUnitFile } from "./units/CanvasCellPromptUnitFile";
import { CanvasCellPromptUnitHtml } from "./units/CanvasCellPromptUnitHtml";
import { CanvasCellPromptUnitImage } from "./units/CanvasCellPromptUnitImage";
import { CanvasCellPromptUnitNotFound } from "./units/CanvasCellPromptUnitNotFound";
import { CanvasCellPromptUnitWarning } from "./units/CanvasCellPromptUnitWarning";

type CanvasCellPromptUnitsProps = {
  promptCellClientModel: CanvasCellPromptClientModel;
  onSelect?: () => void;
  isSelected?: boolean;
};

const CanvasCellPromptUnitsContent = (props: CanvasCellPromptUnitsProps) => {
  const { promptCellClientModel, onSelect, isSelected = false } = props;

  const handleIframeClick = useCallback(() => {
    onSelect?.();
  }, []);

  if (promptCellClientModel.isOutputEmpty) {
    return <CanvasCellPromptUnitNotFound />;
  }

  // if (promptCellClientModel.isSystemMessagesOnly) {
  //   return (
  //     <>
  //       {promptCellClientModel.systemMessageOutputs.map((output, i) => {
  //         return <Text key={i}>{output.value}</Text>;
  //       })}
  //     </>
  //   );
  // }

  return (
    <>
      {promptCellClientModel.outputs.map((output, i) => {
        return match(output)
          .with(
            {
              contentType: P.union("image/png", "image/jpeg", "image/svg+xml"),
              src: P.string,
            },
            (output) => {
              return <CanvasCellPromptUnitImage key={i} url={output.src} />;
            }
          )
          .with(
            {
              contentType: "text/html",
            },
            (output) => {
              return (
                <CanvasCellPromptUnitHtml
                  key={i}
                  html={output.value}
                  onSelect={handleIframeClick}
                  isSelected={isSelected}
                />
              );
            }
          )
          .with(
            {
              contentType: "application/morph.file",
            },
            (value) => {
              return <CanvasCellPromptUnitFile key={i} path={value.src} />;
            }
          )
          .with(
            {
              contentType: "application/morph.error",
            },
            (value) => {
              return (
                <CanvasCellPromptUnitWarning
                  key={i}
                  title="Generation error."
                  content={value.value}
                />
              );
            }
          )
          .with(
            {
              contentType: "application/morph.python_error",
            },
            (value) => {
              return (
                <CanvasCellPromptUnitWarning
                  key={i}
                  title="Runtime error."
                  content={value.value}
                />
              );
            }
          )
          .otherwise(() => <></>);
      })}
    </>
  );
};

const CanvasCellPromptUnits = (props: CanvasCellPromptUnitsProps) => {
  return (
    <Flex direction="column" width="100%" height="100%">
      <CanvasCellPromptUnitsContent {...props} />
    </Flex>
  );
};

export { CanvasCellPromptUnits };
