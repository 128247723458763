import { DashboardImportIntegrationScheduleObject, DashboardTableHistoryObject, SimpleField } from "@usemorph/morph-dashboard-types"
import { isImportIntegrationScheduleObject } from "../util/isImportIntegrationScheduleObject";
import { isTableHistoryObject } from "../util/isTableHistoryObject";
import { TableHistoryCell } from "./TableHistoryCell";


interface TableHistoryCellWrapperProps {
  items: (DashboardTableHistoryObject | DashboardImportIntegrationScheduleObject)[]
  fields: SimpleField[];
}

export const TableHistoryCellWrapper = (props: TableHistoryCellWrapperProps) => {
  const { items, fields } = props

  return (
    <>
      {
        items.map((item, i) => {
          if (isTableHistoryObject(item)) {
            return (<TableHistoryCell
              key={i}
              history={item}
              fields={fields}
            />)
          } else if (isImportIntegrationScheduleObject(item)) {
            return (
              <></>
              // <IntegrationHistoryCell
              //   key={i}
              //   integration={item as DashboardImportIntegrationScheduleObject}
              // />
            )
          } else {
            return (<></>)
          }
        })
      }
    </>
  )
}
