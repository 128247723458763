import { Box } from "~/components_next/Box";
import { coworkerBorderVariants } from "~/components_next/hooks/coworkerColors";
import { styled } from "~/stitches";

type PlaygroundCellCardVariant = "default" | "skelton" | "fixed";

const PlaygroundCellCardElement = styled(Box, {
  variants: {
    variant: {
      default: {
        borderRadius: "var(--radius-4)",
        padding: "10px 10px 10px 10px",
        backgroundColor: "$bg0",
        boxShadow: "var(--shadow-2)",
      },
      fixed: {
        borderRadius: "var(--radius-4)",
        padding: "10px 10px 10px 10px",
        backgroundColor: "$bg0",
        boxShadow: "var(--shadow-2)",
      },
      skelton: {
        borderStyle: "none",
        "&:hover": {
          outline: "solid 1px $slate10",
        },
      },
    },
    coworkerColor: {
      ...coworkerBorderVariants,
    },
    state: {
      selected: {
        outlineStyle: "solid",
        outlineColor: "$blue8",
        outlineWidth: "4px",
        "&:hover": {
          outlineStyle: "solid",
          outlineColor: "$blue8",
          outlineWidth: "4px",
        },
      },
      hovered: {
        outlineStyle: "solid",
        outlineColor: "$blue6",
        outlineWidth: "4px",
      },
    },
  },
});

export { PlaygroundCellCardElement, type PlaygroundCellCardVariant };
