import { BsLayoutSidebarInsetReverse } from "react-icons/bs";
import { Flex } from "~/components_next/Flex";
import { IconButton } from "~/components_next/IconButton";
import { BorderedBox } from "../common/BorderedBox";
import { useTableSizings } from "../context/useTableSizing";

type ContentRowOpenRecordButtonProps = {
  onOpenClick: () => void;
  showOpenRecordButton: boolean;
  hasRightBorder: boolean;
};

const ContentRowOpenRecordButton = (props: ContentRowOpenRecordButtonProps) => {
  const { onOpenClick, showOpenRecordButton, hasRightBorder } = props;
  const { getHeaderColumnOpenRecordButtonWidth, getBodyRowHeight } =
    useTableSizings();

  return (
    <BorderedBox bottom right={hasRightBorder}>
      <Flex
        style={{
          height: getBodyRowHeight(),
          width: getHeaderColumnOpenRecordButtonWidth(),
        }}
        align="center"
        justify="center"
      >
        {showOpenRecordButton && (
          <IconButton
            onClick={onOpenClick}
            size="sm"
            icon={<BsLayoutSidebarInsetReverse />}
            tooltip="Open this record"
          />
        )}
      </Flex>
    </BorderedBox>
  );
};

export { ContentRowOpenRecordButton };
