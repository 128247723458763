import { DashboardRssFeedItemsResponse } from "@usemorph/morph-dashboard-types";


export class RssFeedItemsClientModel {
  constructor(readonly data: DashboardRssFeedItemsResponse) { }

  get stringify(): string {
    return JSON.stringify(this.data)
  }

  get titleOptions(): string[] {
    return this.data.map(item => item.title)
  }

  get descriptionOptions(): string[] {
    return this.data.map(item => item.description)
  }

  get imagesOptions(): string[] {
    return this.data.map(item => item.image)
  }

  get linksOptions(): string[] {
    return this.data.map(item => item.link)
  }

  get pubDateOptions(): string[] {
    return this.data.map(item => item.pubDate)
  }
}
