import { Fragment, useCallback } from "react";
import { UploadFileProvider } from "~/components_next/RecordValue/RecordValueInput/Inputs/ImageAndAttachment/UploadFileContext";
import { useViewId } from "~/utilHooks/useViewId";
import { TablePagination } from "../common/components/Pagination/TablePagination";
import { useUploadFile } from "../common/utils/useUploadFile";
import { EditRecordSidebar } from "./components/EditRecord/EditRecordSidebar";
import { CreateRecordButton } from "./components/CreateRecord/CreateRecordButton";
import { CreateRecordSidebar } from "./components/CreateRecord/CreateRecordSidebar";
import { DeleteSelectedRecordsButton } from "./components/DeleteSelectedRecords/DeleteSelectedRecordsButton";
import { QueryModeToolbar } from "./components/QueryModeToolbar/QueryModeToolbar";
import { TableComponent } from "./components/TableComponent/TableComponent";
import { useViewRecords } from "./hooks/useViewRecords";
import { useLimit, useSetLimit } from "./states/limit";
import { useSetSelectionState } from "./states/selectionState";
import { TableViewSidebarContextProvider } from "./states/sidebar";
import { useSkip, useSetSkip } from "./states/skip";
import { TableViewURLSyncStore } from "./states/TableViewUrlSyncStore/TableVIewURLSyncStore";
import { DownloadRecordsButton } from "./components/DownloadRecords/DownloadRecordsButton";
import { Box, Flex } from "@radix-ui/themes";
import { Spacer } from "~/components_next/Spacer";
import { CreateFieldSidebar } from "./components/CreateField/CreateFieldSidebar";
import { EditFieldSidebar } from "./components/EditField/EditFieldSidebar";

const TableViewComponent = () => {
  const viewId = useViewId();
  const { uploadFile } = useUploadFile();

  // handle click inside table
  const setSelectionState = useSetSelectionState(viewId);
  const handleClickInsideTable = () => {
    setSelectionState(undefined);
  };

  const limit = useLimit(viewId);
  const setLimit = useSetLimit(viewId);
  const skip = useSkip(viewId);
  const setSkip = useSetSkip(viewId);

  const { data: viewRecords } = useViewRecords();

  const handleUploadFile = useCallback(
    async (file: File) => {
      return await uploadFile(file, { viewId });
    },
    [uploadFile, viewId]
  );

  return (
    <UploadFileProvider value={{ onUploadFile: handleUploadFile }}>
      <Fragment key={viewId}>
        <TableViewSidebarContextProvider>
          <Flex
            style={{ height: "calc(100vh - 40px)" }}
            direction="row"
            position="relative"
          >
            <Flex
              direction="column"
              px="3"
              py="3"
              gap="2"
              style={{ flex: 1, overflow: "hidden" }}
              onClick={handleClickInsideTable}
            >
              <QueryModeToolbar />
              <Box width="100%" style={{ flexGrow: 1, overflow: "auto" }}>
                <TableComponent />
              </Box>
              <Flex gap="4" direction="row" align="center">
                <TablePagination
                  limit={limit}
                  setLimit={setLimit}
                  skip={skip}
                  setSkip={setSkip}
                  isDisabled={!viewRecords}
                  count={viewRecords?.count as number}
                />
                <Spacer />
                <DownloadRecordsButton />
                <CreateRecordButton
                  onOpenBulkInsertModal={() => {
                    return;
                  }}
                />
              </Flex>
            </Flex>
            {/* 右下のボタン類 */}
            {/* 条件に応じて可能なアクションのボタンを出し分ける */}
            <Flex position="absolute" gap="4" style={{ right: 32, bottom: 64 }}>
              <DeleteSelectedRecordsButton />
            </Flex>

            {/* Sidebars */}
            <CreateRecordSidebar />
            <EditRecordSidebar />
            <CreateFieldSidebar />
            <EditFieldSidebar />
          </Flex>
        </TableViewSidebarContextProvider>
      </Fragment>
    </UploadFileProvider>
  );
};

const TableView = () => {
  return (
    <TableViewURLSyncStore>
      <TableViewComponent />
    </TableViewURLSyncStore>
  );
};

export { TableView };
