import { useEffect, useMemo, useState } from "react";
import { BsArrowLeftRight, BsLink45Deg } from "react-icons/bs";
import { Badge } from "~/components_next/Badge";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { Grid } from "~/components_next/Grid";
import { NativeSimpleSelect } from "~/components_next/Select/NativeSelect/NativeSimpleSelect";
import { Text } from "~/components_next/Text";
import { styled } from "~/stitches";
import { useNotebookOnCreateCell } from "../../context/NotebookCreateCellContext";
import { NotebookCellObjectWithMeta } from "../../types/NotebookCellObjectWithMeta.type";
import { useNewCellPosition } from "./useNewCellPosition";

const LinkIcon = styled(BsLink45Deg);

type FieldOption = {
  label: string;
  value: string;
};

const JoinTwoForm = (props: {
  slug1: string;
  label1: string;
  options1: FieldOption[];
  slug2: string;
  label2: string;
  options2: FieldOption[];
  parentCells: NotebookCellObjectWithMeta[];
}) => {
  const { slug1, label1, options1, slug2, label2, options2, parentCells } =
    props;

  const [fieldSlug1, setFieldSlug1] = useState<string | null>(null);
  const [fieldSlug2, setFieldSlug2] = useState<string | null>(null);

  useEffect(() => {
    if (fieldSlug1 === null && options1.length > 0) {
      setFieldSlug1(options1[0].value);
    }
  }, [options1]);

  useEffect(() => {
    if (fieldSlug2 === null && options2.length > 0) {
      setFieldSlug2(options2[0].value);
    }
  }, [options2]);

  /**
   * Switch main and target
   */
  const [isInverse, setIsInverse] = useState<boolean>(false);
  const bindValue = useMemo(() => {
    const _slug1 = isInverse ? slug2 : slug1;
    const _label1 = isInverse ? label2 : label1;
    const _options1 = isInverse ? options2 : options1;
    const _slug2 = isInverse ? slug1 : slug2;
    const _label2 = isInverse ? label1 : label2;
    const _options2 = isInverse ? options1 : options2;
    const _fieldSlug1 = isInverse ? fieldSlug2 : fieldSlug1;
    const _fieldSlug2 = isInverse ? fieldSlug1 : fieldSlug2;

    const _setFieldSlug1 = isInverse ? setFieldSlug2 : setFieldSlug1;
    const _setFieldSlug2 = isInverse ? setFieldSlug1 : setFieldSlug2;

    return {
      slug1: _slug1,
      label1: _label1,
      options1: _options1,
      slug2: _slug2,
      label2: _label2,
      options2: _options2,
      fieldSlug1: _fieldSlug1,
      fieldSlug2: _fieldSlug2,
      setFieldSlug1: _setFieldSlug1,
      setFieldSlug2: _setFieldSlug2,
    };
  }, [isInverse, props, fieldSlug1, fieldSlug2]);

  /**
   * Create View
   */
  const isCreateValid = useMemo(() => {
    return fieldSlug1 !== null && fieldSlug2 !== null;
  }, [fieldSlug1, fieldSlug2]);

  const onCreateCell = useNotebookOnCreateCell();

  const parentCellIds = parentCells.map((cell) => cell.cellId);
  const { averageX, maxY } = useNewCellPosition(parentCells);

  const handleCreateJoinTableView = () => {
    if (!bindValue.fieldSlug1 || !bindValue.fieldSlug2) return;

    onCreateCell({
      cellType: "view",
      cellName: `Join: ${bindValue.label1} + ${bindValue.label2}`,
      source: {
        condition: {
          select: ["*"],
          from: bindValue.slug1,
          join: [
            {
              targetTable: bindValue.slug2,
              rules: [
                {
                  tableKey: bindValue.fieldSlug1,
                  targetTableKey: bindValue.fieldSlug2,
                },
              ],
            },
          ],
        },
      },
      parentIds: parentCellIds,
      settings: {
        width: 600,
        height: 600,
        x: averageX || 0,
        y: maxY + 100,
      },
    });
  };

  return (
    <>
      <Grid columns={`200px 30px 200px`} gap="2" align="center" mb="4">
        {/* Headings */}
        <Box px="1">
          <Badge variant="secondary" size="xs">
            Main
          </Badge>
          <Box
            css={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <Text variant="description">{bindValue.label1}</Text>
          </Box>
        </Box>
        <Box>
          <Button
            variant="tertiary"
            size="xs"
            onClick={() => setIsInverse((prev) => !prev)}
          >
            <BsArrowLeftRight />
          </Button>
        </Box>
        <Box px="1">
          <Badge variant="secondary" size="xs">
            Target
          </Badge>
          <Box
            css={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <Text variant="description">{bindValue.label2}</Text>
          </Box>
        </Box>

        {/* Selects */}
        <NativeSimpleSelect
          variant="primary"
          options={bindValue.options1}
          value={bindValue.fieldSlug1}
          onChange={bindValue.setFieldSlug1}
          size="xs"
        />
        <LinkIcon css={{ width: "100%", height: "60%" }} />
        {/* <SimpleSelect
          variant="primary"
          options={bindValue.options2}
          value={bindValue.fieldSlug2}
          onChange={bindValue.setFieldSlug2}
          size="xs"
        /> */}
        <NativeSimpleSelect
          variant="primary"
          options={bindValue.options2}
          value={bindValue.fieldSlug2}
          onChange={bindValue.setFieldSlug2}
          size="xs"
        />
      </Grid>
      <Button
        variant="secondary"
        size="xs"
        isDisabled={!isCreateValid}
        onClick={handleCreateJoinTableView}
      >
        Create
      </Button>
    </>
  );
};

export { JoinTwoForm };
