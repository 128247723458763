import { styled } from "~/stitches";
import { BorderedBox } from "../common/BorderedBox";
import { useTableSizings } from "../context/useTableSizing";

const BorderedBoxWithBg = styled(BorderedBox, {
  backgroundColor: "$bg1",
});

type HeaderOpenRecordButtonBoxProps = {
  hasRightBorder: boolean;
};

const HeaderOpenRecordButtonBox = (props: HeaderOpenRecordButtonBoxProps) => {
  const { hasRightBorder } = props;

  const { getHeaderColumnOpenRecordButtonWidth, getHeaderRowHeight } =
    useTableSizings();

  return (
    <BorderedBoxWithBg
      bottom
      right={hasRightBorder}
      style={{
        width: getHeaderColumnOpenRecordButtonWidth(),
        height: getHeaderRowHeight(),
      }}
    />
  );
};

export { HeaderOpenRecordButtonBox };
