import { FieldClientModel } from "~/clientModel/fields/field";
import { FindUserClientModel } from "~/clientModel/user";
import {
  ReadonlyRecordEntryClientModelBase,
  ReadonlyRecordEntryClientModelBaseConstructorParams,
} from "../RecordEntryClientModelBase";
import { zValidate } from "../validatorsUtil";
import { z } from "zod";

type CreatedByRecordEntryClientModelData =
  ReadonlyRecordEntryClientModelBaseConstructorParams;

export class CreatedByRecordEntryClientModel extends ReadonlyRecordEntryClientModelBase<FindUserClientModel | null> {
  constructor(data: CreatedByRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "createdBy" {
    return "createdBy";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([z.instanceof(FindUserClientModel), z.null()]),
      this.data.rawValue
    );
  }

  public getValidatedDisplayValue = this.getValidatedValue;
}
