import { useMutation } from "react-query";
import { useComposeExecutable } from "~/clientModel/executable";
import { useUpdateRssTableMappingMutation } from "~/serverStateStore/rssFeed/mutations/useUpdateRssTableMappingMutation";


const useUpdateRssTableMappingExecutable = (props: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
}) => {
  const { teamSlug, databaseId, tableSlug } = props

  const useUpdateRssTableMappingMutationOptions = useUpdateRssTableMappingMutation({
    teamSlug,
    databaseId,
    tableSlug,
  })

  return useComposeExecutable(
    useMutation({
      ...useUpdateRssTableMappingMutationOptions,
      // mutationFn: async (props: UseUpdateRssTableMappingMutationProps) => {
      //   return await useUpdateRssTableMappingMutationOptions.mutationFn(props)
      // }
    })
  )
}

export const createUseUpdateRssTableMappingExecutable = (props: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
}) => {

  return () => useUpdateRssTableMappingExecutable(props)
}
