import { useMutation, useQuery } from "react-query";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import {
  useListTablesQuery,
  useDuplicateTableMutation,
} from "~/serverStateStore/tables";

export const useDuplicateTable = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const { data: tablesResponse } = useQuery({
    ...useListTablesQuery({ teamSlug, databaseId, limit: 99999, skip: 0 }),
  });

  const {
    mutateAsync: duplicate,
    isLoading: isDuplicating,
    error: duplicateError,
  } = useMutation({
    ...useDuplicateTableMutation({ databaseId, teamSlug }),
  });

  const duplicateTable = async ({
    targetTableSlug,
    targetDisplayName,
    withRecords,
    isPrivate,
    comment,
  }: {
    targetTableSlug: string;
    targetDisplayName: string;
    withRecords: boolean;
    isPrivate?: boolean;
    comment?: string;
  }) => {
    // ↓↓同じ名前のテーブルの数↓↓
    const num =
      tablesResponse?.tables.filter((t) =>
        t.displayName?.indexOf(targetDisplayName)
      ).length || 0;
    const tableDisplayName = `${targetDisplayName}_copy${
      num && num > 0 ? num + 1 : ""
    }`;
    const tableSlug = `${targetTableSlug}_copy${num && num > 0 ? num + 1 : ""}`;
    return duplicate({
      targetTableSlug,
      tableSlug,
      displayName: tableDisplayName,
      withRecords,
      isPrivate,
      comment,
    });
  };

  return {
    duplicateTable,
    isDuplicating,
    duplicateError,
  };
};
