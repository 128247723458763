import { extractErrorDetails, useErrorToast } from "~/components_next/Error";
import { useSetOpeningSidebarType } from "../../states/sidebar";
import { useSetEditingRecordModel } from "../../states/editingRecordModel";
import { useViewId } from "~/utilHooks/useViewId";
import { useView } from "~/features/SourceAndViews/common/utils/useView";
import { useGroupingViewRecords } from "../../hooks/useGroupingViewRecords";
import { RecordsKanbanBase } from "~/components_next/RecordsKanbanBase/RecordsKanbanBase";
import { useCallback, useMemo } from "react";
import { RecordModel } from "~/features/RecordModel";
import { useSetSelectedGroupingValue } from "../../states/selectedGroupingValue";
import {
  useOptimisticUpdateViewMutation,
  useOptimisticUpdateViewRecordsMutation,
  useReorderViewRecordMutation,
  useViewFields,
} from "~/serverStateStore/views";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useMutation } from "react-query";
import { parseViewSetting } from "~/features/View";
import { convertRecordModelToValuesForRequest } from "~/features/RecordModel/utils/convertRecordModelToValuesForRequest";
import { createPrimaryKeyObjectForOneRecord } from "~/features/Records";
import { useFilterCondition } from "../../states/filterCondition";
import { useSortCondition } from "../../states/sortCondition";
import { Flex } from "@radix-ui/themes";
import { Spinner } from "~/components_next/Spinner";
import { Callout } from "~/components_next/Callout";

const KanbanComponent = () => {
  /**
   * Basic params
   */
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  /**
   * Server states
   */

  const {
    data: groupingViewRecordsData,
    status: groupingViewRecordsStatus,
    error: groupingViewRecordsError,
  } = useGroupingViewRecords();

  const { data: viewData, status: viewStatus, error: viewError } = useView();

  const {
    data: viewFieldsData,
    status: viewFieldsStatus,
    error: viewFieldsError,
  } = useViewFields({
    teamSlug,
    databaseId,
    viewId,
    viewData,
  });

  /**
   * Client states
   */
  const setEditingRecordModel = useSetEditingRecordModel(viewId);
  const setOpeningSidebarType = useSetOpeningSidebarType();
  const setSelectedGroupingValue = useSetSelectedGroupingValue(viewId);

  /**
   * handlers
   */

  const handleRecordClick = useCallback(
    (recordModel: RecordModel) => {
      setEditingRecordModel(recordModel);
      setOpeningSidebarType("editRecord");
    },
    [setEditingRecordModel, setOpeningSidebarType]
  );

  const handleCreateClick = useMemo(() => {
    if (!viewData?.rules.update) return undefined;

    return (groupingValue: unknown) => {
      setSelectedGroupingValue(groupingValue);
      setOpeningSidebarType("createRecord");
    };
  }, [setOpeningSidebarType, setSelectedGroupingValue, viewData?.rules.update]);

  const { mutateAsync: optimisticUpdateView } = useMutation(
    useOptimisticUpdateViewMutation({
      teamSlug,
      databaseId,
      viewId,
    })
  );

  const { errorToast } = useErrorToast({});

  const handleUpdateColumnValues = useCallback(
    async (updatedColumnsValues: string[]) => {
      try {
        if (!viewData) {
          throw new Error("View data is not found");
        }
        await optimisticUpdateView({
          ...viewData,
          setting: {
            data: {
              ...parseViewSetting(viewData.setting).data,
              columnsOrder: updatedColumnsValues,
            },
          },
        });
      } catch (error) {
        errorToast(error);
      }
    },
    [errorToast, optimisticUpdateView, viewData]
  );

  const { mutateAsync: optimisticUpdateViewRecords } = useMutation(
    useOptimisticUpdateViewRecordsMutation({
      teamSlug,
      databaseId,
      viewId,
    })
  );

  const { mutateAsync: reorderViewRecord } = useMutation(
    useReorderViewRecordMutation({
      teamSlug,
      databaseId,
      viewId,
    })
  );

  const filterCondition = useFilterCondition(viewId);
  const sortCondition = useSortCondition(viewId);

  const handleUpdateRecord = useMemo(() => {
    if (
      !viewData ||
      !groupingViewRecordsData ||
      viewData.rules.update[groupingViewRecordsData.groupingField.name] !==
        true ||
      viewData.condition.reorderable !== true
    ) {
      return undefined;
    }

    return async (
      record: RecordModel,
      beforeCell?: RecordModel | null | undefined
    ) => {
      try {
        if (!viewFieldsData) {
          throw new Error("Something went wrong");
        }
        await optimisticUpdateViewRecords({
          values: convertRecordModelToValuesForRequest({
            recordModelAfterEdit: record,
          }),
          filterForRequest: createPrimaryKeyObjectForOneRecord(
            viewFieldsData.fields,
            record
          ),
          currentKeyParams: {
            select: ["*"],
            filter: filterCondition ?? undefined,
            sort: sortCondition ? [sortCondition] : undefined,
          },
        });
        await reorderViewRecord({
          type: viewData.type,
          target: createPrimaryKeyObjectForOneRecord(
            viewFieldsData.fields,
            record
          ),
          after: beforeCell
            ? createPrimaryKeyObjectForOneRecord(
                viewFieldsData.fields,
                beforeCell
              )
            : null,
        });
      } catch (error) {
        errorToast(error);
      }
    };
  }, [
    errorToast,
    filterCondition,
    groupingViewRecordsData,
    optimisticUpdateViewRecords,
    reorderViewRecord,
    sortCondition,
    viewData,
    viewFieldsData,
  ]);

  // todo
  const handleReorderRecord = useMemo(() => {
    if (
      !viewData ||
      (viewData.condition.sort && viewData.condition.sort.length > 0)
    ) {
      return undefined;
    }

    return async (
      record: RecordModel,
      beforeCell?: RecordModel | null | undefined
    ) => {
      try {
        //
      } catch (error) {
        errorToast(error);
      }
    };
  }, [errorToast, viewData]);

  /**
   * UI
   */

  if (
    groupingViewRecordsStatus === "error" ||
    viewStatus === "error" ||
    viewFieldsStatus === "error" ||
    (viewData && !viewData.condition.groupKey)
  ) {
    const { title, description } = extractErrorDetails(
      groupingViewRecordsError ??
        viewError ??
        viewFieldsError ??
        new Error("Something went wrong")
    );
    return (
      <Callout type="alert" size="sm" title={title} description={description} />
    );
  }

  if (
    groupingViewRecordsStatus === "loading" ||
    groupingViewRecordsStatus === "idle" ||
    viewStatus === "loading" ||
    viewStatus === "idle" ||
    viewFieldsStatus === "loading" ||
    viewFieldsStatus === "idle"
  ) {
    return (
      <Flex height="100%" align="center" justify="center">
        <Spinner />
      </Flex>
    );
  }

  const dragFallbackMessage =
    viewData.condition.reorderable === true
      ? "dragFallbackMessageDrag-and-drop is not available because the grouping field is not editable."
      : "Allow records reordering from view settings to enable drag-and-drop.";

  return (
    <RecordsKanbanBase
      values={groupingViewRecordsData!.records}
      titleFieldName={viewFieldsData.titleField.name}
      propertyFieldNames={viewFieldsData.displayedPropertyFields.map(
        ({ name }) => name
      )}
      groupingField={groupingViewRecordsData!.groupingField}
      onClickRecord={handleRecordClick}
      onCreateClicked={handleCreateClick}
      onUpdateColumnValues={handleUpdateColumnValues}
      onUpdateRecord={handleUpdateRecord}
      onReorderRecord={handleReorderRecord}
      dragFallbackMessage={dragFallbackMessage}
    />
  );
};

export { KanbanComponent };
