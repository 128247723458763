import {
  ResourceMemberListResponse,
  ResourceMemberResponse,
  ResourceTableSchema,
  ResourceNotebookSchema,
  ResourcePermissionType,
  ResourceViewSchema,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

type DashboardGeneralResponse = {
  message: string;
};

export default function useApiResourceMembers() {
  const { executeRequest } = useApi();

  const _listResourceMembers = async ({
    teamSlug,
    resource,
    limit,
    skip,
  }: {
    teamSlug: string;
    resource: ResourceTableSchema | ResourceNotebookSchema | ResourceViewSchema;
    limit?: number;
    skip?: number;
  }): Promise<ResourceMemberListResponse> => {
    return executeRequest<ResourceMemberListResponse>(
      "POST",
      `/resource-member/query`,
      undefined,
      { teamSlug },
      {
        resource,
        limit,
        skip,
      }
    );
  };

  const _createResourceMember = async ({
    teamSlug,
    resource,
    permission,
    userIds,
  }: {
    teamSlug: string;
    resource: ResourceTableSchema | ResourceNotebookSchema | ResourceViewSchema;
    permission: ResourcePermissionType
    userIds: string[];
  }): Promise<ResourceMemberResponse> => {
    return executeRequest<ResourceMemberResponse>(
      "POST",
      `/resource-member/create`,
      undefined,
      { teamSlug },
      {
        resource,
        permission,
        userIds,
      }
    );
  };

  const _updateResourceMember = async ({
    teamSlug,
    resource,
    permission,
    userId,
  }: {
    teamSlug: string;
    resource: ResourceTableSchema | ResourceNotebookSchema | ResourceViewSchema;
    permission: ResourcePermissionType
    userId: string;
  }): Promise<ResourceMemberResponse> => {
    return executeRequest<ResourceMemberResponse>(
      "POST",
      `/resource-member/update`,
      undefined,
      { teamSlug },
      {
        resource,
        permission,
        userId,
      }
    );
  };

  const _deleteResourceMember = async ({
    teamSlug,
    resource,
    userId,
  }: {
    teamSlug: string;
    resource: ResourceTableSchema | ResourceNotebookSchema | ResourceViewSchema;
    userId: string;
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest<DashboardGeneralResponse>(
      "POST",
      `/resource-member/delete`,
      undefined,
      { teamSlug },
      {
        resource,
        userId,
      }
    );
  };
  return {
    _listResourceMembers,
    _createResourceMember,
    _updateResourceMember,
    _deleteResourceMember,
  };
}
