import useApiPayment from "~/api/useApiPayment";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { paymentKeys } from "../keys";

interface UseCreateCustomerPortalSessionMutationParams {
  teamSlug: string;
  redirectUrl: string;
}

const useCreateCustomerPortalSessionMutation = ({
  teamSlug,
  redirectUrl,
}: UseCreateCustomerPortalSessionMutationParams) => {
  const { _createCustomerPortalSession } = useApiPayment();
  const client = useQueryClient();

  return {
    mutationFn: () => {
      return _createCustomerPortalSession({
        teamSlug,
        redirectUrl,
      });
    },
    onSuccess: () => {
      return invalidateAll(client, paymentKeys.all({ teamSlug }));
    },
  };
};

export { useCreateCustomerPortalSessionMutation };
