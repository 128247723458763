import { useQueryClient } from "react-query";
import useApiNotification from "~/api/useApiNotification";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { notificationKeys } from "..";
import { MessageClientModelFactory } from "~/clientModel/general";

type UseMarkAsReadNotificationMutationParams = {
  teamSlug: string;
};

type UseMarkAsReadNotificationMutationProps = {
  notificationId?: string;
  operation: "read" | "archive";
};

const useMarkAsReadNotificationMutation = ({
  teamSlug,
}: UseMarkAsReadNotificationMutationParams) => {
  const { _markAsReadNotifications } = useApiNotification();
  const client = useQueryClient();

  return {
    mutationFn: async ({
      notificationId,
      operation,
    }: UseMarkAsReadNotificationMutationProps) => {
      const response = await _markAsReadNotifications({
        teamSlug,
        notificationId,
        operation,
      });
      return MessageClientModelFactory.fromMessageResponse(response)
    },
    onSuccess: () => {
      return invalidateAll(
        client,
        notificationKeys.all({
          teamSlug,
        })
      );
    },
  };
};

export { useMarkAsReadNotificationMutation, type UseMarkAsReadNotificationMutationProps };
