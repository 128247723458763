import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModel } from "..";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";

export class LongTextFieldTypeClientModel extends FieldTypeClientModelBase {
  public get type(): "longText" {
    return "longText";
  }

  public get fieldTypeLabel(): "Long Text" {
    return "Long Text";
  }

  public get isAutoGenerated(): false {
    return false;
  }

  public get isEmbeddable(): true {
    return true;
  }

  public get canBeTargetOfSyncingByComputedField(): true {
    return true;
  }

  public get _updatableFieldTypes(): FieldTypeClientModel["type"][] {
    return [
      "shortText",
      "date",
      "datetime",
      "time",
      "email",
      "phoneNumber",
      "url",
      "singleSelect",
      "multiSelect",
    ];
  }

  public validate(): ValidationResult {
    return { isValid: true };
  }
}
