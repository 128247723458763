import { useCallback } from "react";
import { RecordIdentifierClientModel } from "~/clientModel/records/record/recordIdentifier";
import {
  useRecordsTableBaseCoworkersState,
  useRecordsTableBaseFields,
} from "../context/ReactTableBaseContext";

export const useCoworkerSelectionUtil = () => {
  const fields = useRecordsTableBaseFields();
  const coworkersState = useRecordsTableBaseCoworkersState();

  const isRecordSelectedByCoworker = useCallback(
    ({
      recordIdentifier,
    }: {
      recordIdentifier: RecordIdentifierClientModel;
    }): boolean => {
      if (!coworkersState) {
        return false;
      }

      return coworkersState.coworkersState.some(({ tableSelection }) => {
        return tableSelection.isRecordSelected({
          recordIdentifier,
        });
      });
    },
    [coworkersState]
  );

  const getRecordColorSchemeLockedByCoworker = useCallback(
    ({
      recordIdentifier,
    }: {
      recordIdentifier: RecordIdentifierClientModel;
    }): string | null => {
      if (!coworkersState) {
        return null;
      }

      const lockingCoworkerState = coworkersState.coworkersState.find(
        ({ tableSelection }) => {
          return tableSelection.isRecordSelected({
            recordIdentifier,
          });
        }
      );

      return lockingCoworkerState
        ? lockingCoworkerState.user.color.colorScheme
        : null;
    },
    [coworkersState]
  );

  const isCellSelectedByCoworker = useCallback(
    ({
      recordIdentifier,
      fieldName,
    }: {
      recordIdentifier: RecordIdentifierClientModel;
      fieldName: string;
    }): boolean => {
      if (!coworkersState) {
        return false;
      }

      return coworkersState.coworkersState.some(({ tableSelection }) => {
        return tableSelection.isCellSelected({
          recordIdentifier,
          fieldName,
        });
      });
    },
    [coworkersState]
  );

  const isCellLockedByCoworker = useCallback(
    ({
      recordIdentifier,
      fieldName,
    }: {
      recordIdentifier: RecordIdentifierClientModel;
      fieldName: string;
    }): boolean => {
      return (
        isCellSelectedByCoworker({ recordIdentifier, fieldName }) ||
        isRecordSelectedByCoworker({ recordIdentifier })
      );
    },
    [isCellSelectedByCoworker, isRecordSelectedByCoworker]
  );

  const isRecordLockedByCoworker = useCallback(
    ({
      recordIdentifier,
    }: {
      recordIdentifier: RecordIdentifierClientModel;
    }): boolean => {
      return (
        isRecordSelectedByCoworker({ recordIdentifier }) ||
        fields.visibleFields.allFields.some((field) =>
          isCellSelectedByCoworker({
            recordIdentifier,
            fieldName: field.name,
          })
        )
      );
    },
    [
      fields.visibleFields.allFields,
      isCellSelectedByCoworker,
      isRecordSelectedByCoworker,
    ]
  );

  return {
    isCellLockedByCoworker,
    isRecordLockedByCoworker,
    getRecordColorSchemeLockedByCoworker,
  };
};
