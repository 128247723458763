import { Box } from "~/components_next/Box";
import { styled } from "~/stitches";

export const BorderedBox = styled(Box, {
  width: "100%",
  height: "100%",
  variants: {
    bottom: {
      true: {
        borderBottom: "solid 1px $border",
      },
    },
    right: {
      true: {
        borderRight: "solid 1px $border",
      },
    },
  },
});
