import {
  RecordFormatCondition,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { FieldsClientModelDefactory } from "~/clientModel/fields";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";
import { CsvDownloadFieldsClientModel } from "./CsvDownloadFieldsClientModel";

export class CsvDownloadFieldsClientModelDefactory {
  public static toRecordFormatConditions(
    csvDownloadFields: CsvDownloadFieldsClientModel
  ): RecordFormatCondition[] {
    return csvDownloadFields.maskedCsvDownloadFields.allCsvDownloadFields.map(
      ({ baseField }) => ({
        action: "mask",
        column: baseField.name,
      })
    );
  }

  public static toQueryRecordRequestBodyObjectSelect(
    csvDownloadFields: CsvDownloadFieldsClientModel
  ): string[] {
    return csvDownloadFields.baseFields.visibleFields.allFields.map(
      ({ name }) => name
    );
  }

  public static toSimpleFields(
    csvDownloadFields: CsvDownloadFieldsClientModel,
    {
      smartFunctions,
    }: {
      smartFunctions: SmartFunctionsClientModel;
    }
  ): SimpleField[] {
    return FieldsClientModelDefactory.toSimpleFields(
      csvDownloadFields.baseFields,
      { smartFunctions }
    );
  }
}
