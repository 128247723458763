import {
  AuthPrivateChannelResponse,
  DashboardNotificationListResponse,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

export type DashboardGeneralResponse = {
  message: string;
};

export default function useApiNotification() {
  const { executeRequest } = useApi();

  const _mqttAuthForPrivateCh = async ({
    socketId,
    teamSlug,
    notebookId,
  }: {
    socketId: string;
    notebookId?: string;
    teamSlug: string;
  }): Promise<AuthPrivateChannelResponse> => {
    return executeRequest<AuthPrivateChannelResponse>(
      "POST",
      "/private-channel/auth",
      undefined,
      {
        teamSlug,
      },
      {
        socketId,
        notebookId,
      }
    );
  };

  const _mqttPublishToPrivateCh = async ({
    event,
    data,
    teamSlug,
  }: {
    event: string;
    data: object;
    teamSlug: string;
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest<DashboardGeneralResponse>(
      "POST",
      "/private-channel/trigger",
      undefined,
      {
        teamSlug,
      },
      {
        event,
        data,
      }
    );
  };

  const _listNotifications = async ({
    teamSlug,
    limit,
    skip,
    isArchived,
    isRead,
  }: {
    teamSlug: string;
    limit?: number;
    skip?: number;
    isArchived?: boolean;
    isRead?: boolean;
  }): Promise<DashboardNotificationListResponse> => {
    return executeRequest<DashboardNotificationListResponse>(
      "GET",
      "/notification",
      {
        limit,
        skip,
        isArchived,
        isRead,
      },
      {
        teamSlug,
      },
      undefined,
    );
  }

  const _markAsReadNotifications = async ({
    teamSlug,
    notificationId,
    operation,
  }: {
    teamSlug: string;
    notificationId?: string;
    operation: "read" | "archive";
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest<DashboardGeneralResponse>(
      "PUT",
      "/notification",
      undefined,
      {
        teamSlug,
      },
      {
        notificationId,
        operation,
      }
    );
  }

  return {
    _mqttAuthForPrivateCh,
    _mqttPublishToPrivateCh,
    _listNotifications,
    _markAsReadNotifications,
  };
}
