import { useCallback } from "react";
import { BsPlus } from "react-icons/bs";
import { UseExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { Loadable } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import {
  CreatingRecordClientModel,
  CreatingRecordClientModelFactory,
} from "~/clientModel/records/creatingRecord";
import { CreateRecordForm } from "~/presenters/records";
import { FooterButtonBase } from "../common/FooterButtonBase";
import { RightSidebarBase } from "../common/RightSidebarBase";

type CreateRecordSidebarProps = {
  creatingRecordLoadable: Loadable<CreatingRecordClientModel>;
  onCreatingRecordChange: (record: CreatingRecordClientModel) => void;
  fieldsLoadable: Loadable<FieldsClientModel>;
  useCreateRecordExecutable: UseExecutable<
    void,
    { creatingRecord: CreatingRecordClientModel; fields: FieldsClientModel }
  >;
  useUploadFileExecutable: UseExecutable<
    void,
    { file: File },
    {
      data: string;
      url: string;
    }
  >;
};

export const CreateRecordSidebar = (props: CreateRecordSidebarProps) => {
  const {
    creatingRecordLoadable,
    onCreatingRecordChange,
    fieldsLoadable,
    useCreateRecordExecutable,
    useUploadFileExecutable,
  } = props;

  const createRecordExecutable = useCreateRecordExecutable();

  const handleCreateRecord = useCallback(
    async (
      creatingRecord: CreatingRecordClientModel,
      fields: FieldsClientModel
    ) => {
      await createRecordExecutable.execute({
        creatingRecord,
        fields: fields,
      });
      // todo: ここの処理後で確認する
      onCreatingRecordChange(
        CreatingRecordClientModelFactory.createEmptyFromFieldsClientModel(
          fields
        )
      );
    },
    [createRecordExecutable, onCreatingRecordChange]
  );

  return (
    <RightSidebarBase
      sidebarType="createRecord"
      title="Create Record"
      footer={
        <WithFallback
          loadables={[fieldsLoadable, creatingRecordLoadable] as const}
        >
          {([fields, creatingRecord]) => (
            <FooterButtonBase
              onClick={() => handleCreateRecord(creatingRecord, fields)}
              rightIcon={<BsPlus />}
              isLoading={createRecordExecutable.isExecuting}
              isDisabled={!creatingRecord.isCreatingRecordValid(fields)}
            >
              Create
            </FooterButtonBase>
          )}
        </WithFallback>
      }
    >
      <WithFallback
        loadables={[fieldsLoadable, creatingRecordLoadable] as const}
      >
        {([fields, creatingRecord]) => (
          <CreateRecordForm
            creatingRecord={creatingRecord}
            fields={fields}
            onCreatingRecordChange={onCreatingRecordChange}
            useUploadFileExecutable={useUploadFileExecutable}
          />
        )}
      </WithFallback>
    </RightSidebarBase>
  );
};
