import { Dialog as RadixThemeDialog } from "@radix-ui/themes";
import {
  ComponentPropsWithoutRef,
  createContext,
  forwardRef,
  ReactNode,
  useContext,
} from "react";
import { match, P } from "ts-pattern";
import { styled } from "~/stitches";
import { Box } from "../Box";
import { MorphThemeSize } from "../commonProps.type";
import { Flex } from "../Flex";

/**
 * Dialog Root
 */

type DialogRootProps = {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  children: ReactNode;
  size?: MorphThemeSize | "full";
  scrollBehavior?: "inside" | "outside";
};

type DialogContextType = Required<
  Pick<DialogRootProps, "scrollBehavior" | "size">
>;

const DialogContext = createContext({} as DialogContextType);

const useDialogContext = () => {
  return useContext(DialogContext);
};

const DialogRoot = (props: DialogRootProps) => {
  const {
    open,
    onOpenChange,
    children,
    size = "md",
    scrollBehavior = "inside",
  } = props;

  const contextValue = {
    scrollBehavior,
    size,
  };

  return (
    <DialogContext.Provider value={contextValue}>
      <RadixThemeDialog.Root open={open} onOpenChange={onOpenChange}>
        {children}
      </RadixThemeDialog.Root>
    </DialogContext.Provider>
  );
};

/**
 * Dialog Content
 */

type DialogContentProps = {
  children: ReactNode;
};

const StyledDialogContent = styled(RadixThemeDialog.Content, {
  display: "flex",
  flexDirection: "column",
  padding: "$6 $5 $3 $5",
});

const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(
  (props, ref) => {
    const { children } = props;

    const { size, scrollBehavior } = useDialogContext();

    const width = match(size)
      .with("xs", () => "50vw")
      .with("sm", () => "60vw")
      .with("md", () => "70vw")
      .with("lg", () => "80vw")
      .with("xl", () => "90vw")
      .with("full", () => "100vw")
      .exhaustive();

    const height = match([size, scrollBehavior])
      .with(
        [P.union("xs", "sm", "md", "lg", "xl"), "inside"],
        () => "content-fit"
      )
      .with(["full", P._], () => "100vh")
      .with([P._, "outside"], () => "content-fit")
      .exhaustive();

    const maxWidth = match(size)
      .with(P.union("xs", "sm", "md", "lg", "xl"), () => "1200px")
      .with("full", () => "none")
      .exhaustive();

    return (
      <StyledDialogContent
        ref={ref}
        style={{
          width,
          height,
          maxWidth,
          minWidth: "400px",
        }}
      >
        {children}
      </StyledDialogContent>
    );
  }
);

DialogContent.displayName = "DialogContent";

/**
 * DialogBody
 */

type DialogBodyProps = {
  children: ReactNode;
} & Pick<ComponentPropsWithoutRef<typeof Flex>, "style">;

const BodyContainer = styled(Box, {
  width: "100%",

  variants: {
    scrollBehavior: {
      inside: {
        flex: 1,
        overflow: "auto",
      },
      outside: {},
    },
  },
});

const DialogBody = (props: DialogBodyProps) => {
  const { children, style } = props;

  const { scrollBehavior } = useDialogContext();

  return (
    <BodyContainer style={style} scrollBehavior={scrollBehavior}>
      {children}
    </BodyContainer>
  );
};

/**
 * Dialog Title
 */

type DialogTitleProps = {
  children: ReactNode;
};

const DialogTitle = (props: DialogTitleProps) => {
  const { children } = props;

  return <RadixThemeDialog.Title>{children} </RadixThemeDialog.Title>;
};

/**
 * Dialog Description
 */

type DialogDescriptionProps = {
  children: ReactNode;
};

const DialogDescription = (props: DialogDescriptionProps) => {
  const { children } = props;

  return (
    <RadixThemeDialog.Description mb="3">
      {children}
    </RadixThemeDialog.Description>
  );
};

/**
 * Dialog Footer
 */

type DialogFooterProps = {
  children: ReactNode;
} & Pick<ComponentPropsWithoutRef<typeof Box>, "style">;

const FooterContainer = styled(Box, {
  paddingTop: "$2",
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  gap: "$3",
});

const DialogFooter = (props: DialogFooterProps) => {
  const { children, ...rest } = props;

  return <FooterContainer {...rest}>{children} </FooterContainer>;
};

/**
 * Dialog Close
 */

const DialogClose = RadixThemeDialog.Close;

const Dialog = {
  Root: DialogRoot,
  Content: DialogContent,
  Body: DialogBody,
  Title: DialogTitle,
  Description: DialogDescription,
  Footer: DialogFooter,
  Close: DialogClose,
};

export { Dialog, type DialogRootProps };
