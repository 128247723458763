import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { SimpleField } from "@usemorph/morph-dashboard-types";
import { RecordsTableBaseProps } from "../RecordsTableBase";
import { FieldIcon } from "~/components_next/Icons/";
import { MouseEvent, useMemo, useState } from "react";
import { useBorderStyle } from "../hooks/useBorderStyle";
import { useHeaderStyle } from "./useHeaderStyle";
import {
  useRecordsTableBaseColumnSizing,
  useRecordsTableBaseCoworkerStates,
  useRecordsTableBaseFieldSelection,
  useRecordsTableBaseHandleFieldEvent,
  useRecordsTableBaseHeaderDropdown,
  useRecordsTableBaseOnColumnSizingChange,
  useRecordsTableBaseOnReorderFields,
  useRecordsTableBaseOptions,
} from "../context/ReactTableBaseContext";
import { match } from "ts-pattern";
import { getCoworkerSelectionForField } from "../util/coworkerStateUtil";
//Intercom Class setup : index===2で、 HardCoding している
import { ElementClassNamesForOnboarding } from "~/constants/IntercomClassConst";
import { Box, Flex } from "@radix-ui/themes";
import { ResizableBox } from "~/components_next/ResizableBox";
import { Text } from "~/components_next/Text";
import { Spacer } from "~/components_next/Spacer";
import { styled } from "~/stitches";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";

type RecordsTableHeaderProps = {
  index: number;
  field: SimpleField;
  translateX: number;
  translateY: number;
};

const StyledBox = styled(Box, {});

// Join Tableの件はあとで

const Wrapper = (props: {
  children: React.ReactNode;
  index: RecordsTableHeaderProps["index"];
  headerDropdown: RecordsTableBaseProps["headerDropdown"];
  field: RecordsTableHeaderProps["field"];
  forceHide?: boolean;
}) => {
  const { children, headerDropdown, field, index, forceHide = false } = props;

  if (headerDropdown) {
    return (
      <SimpleDropdownMenu trigger={children}>
        {forceHide ? null : headerDropdown(field, index)}
      </SimpleDropdownMenu>
    );
  } else {
    return <>{children}</>;
  }
};

const RecordsTableHeader = (props: RecordsTableHeaderProps) => {
  const { index, field, translateX, translateY } = props;

  const { isReorderable } = useRecordsTableBaseOptions();
  // states
  const columnSizing = useRecordsTableBaseColumnSizing();
  const onColumnSizingChange = useRecordsTableBaseOnColumnSizingChange();
  const fieldSelections = useRecordsTableBaseFieldSelection();
  const coworkerStates = useRecordsTableBaseCoworkerStates();
  // event
  const onReorderFields = useRecordsTableBaseOnReorderFields();
  const handleFieldClickEvent = useRecordsTableBaseHandleFieldEvent();
  // ui
  const headerDropdown = useRecordsTableBaseHeaderDropdown();

  const isSelected = useMemo(() => {
    return fieldSelections?.includes(field.name);
  }, [field, fieldSelections]);

  const coworkerSelection = useMemo(() => {
    if (!coworkerStates) {
      return undefined;
    }
    const selections = getCoworkerSelectionForField(field.name, coworkerStates);
    return selections.length > 0 ? selections[0] : undefined;
  }, [field, coworkerStates]);

  const { borderAttributes } = useBorderStyle();
  const { headerAttributes } = useHeaderStyle(
    headerDropdown !== undefined,
    isSelected,
    coworkerSelection
  );

  // reorder
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: field.name });

  const _style = {
    transform: CSS.Transform.toString({
      x: (transform?.x || 0) + translateX,
      y: translateY,
      scaleX: 1,
      scaleY: 1,
    }),
    transition,
  };

  // sizing
  const columnWidth = useMemo(() => {
    return columnSizing?.[field.name] || 200;
  }, [columnSizing, field]);

  const [isResizing, setIsResizing] = useState(false);

  const onResize = (width: number) => {
    setIsResizing(false);
    const newColumnSizing = {
      ...columnSizing,
      [field.name]: width,
    };

    if (onColumnSizingChange) {
      onColumnSizingChange(newColumnSizing);
    }
  };

  // user
  const handleFieldClick = (event: MouseEvent<HTMLDivElement>) => {
    if (coworkerSelection) {
      event.preventDefault();
      return;
    }
    match([event.shiftKey])
      .with([true], () => {
        handleFieldClickEvent("shiftClick", field.name);
        event.preventDefault();
      })
      .with([false], () => {
        handleFieldClickEvent("click", field.name);
      })
      .run();
  };

  return (
    // アイコンは惜しい感じだった
    // {coworkerSelection && (
    //   <Avatar
    //     src={coworkerSelection.user.iconUrl || undefined}
    //     name={coworkerSelection.user.username}
    //     size="sm"
    //     borderWidth="2px"
    //     borderColor={coworkerHighlight}
    //     shadow="md"
    //     translateX={-translateX}
    //   />
    // )}
    <Box onClick={(e) => e.preventDefault()}>
      <Wrapper
        index={index}
        field={field}
        headerDropdown={headerDropdown}
        // forceHide={isResizing}
      >
        <ResizableBox
          direction="right"
          width={columnWidth}
          height="100%"
          // reorder
          ref={setNodeRef}
          style={{
            textTransform: "none",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            position: "absolute",
            userSelect: "none",
            top: 0,
            left: 0,
            zIndex: isResizing ? 1 : "auto",
            ...borderAttributes,
            ...headerAttributes,
            ..._style,
            ...(isReorderable ? attributes : {}),
          }}
          onClick={handleFieldClick}
          onResizeDone={onResize}
          onResizeStart={() => setIsResizing(true)}
        >
          <Flex
            style={{
              height: "100%",
              width: "100%",
              position: "relative",
              overflow: "hidden",
            }}
            align="center"
            gap="1"
            pl="2"
            className={
              index ===
              ElementClassNamesForOnboarding.TableEdit.selectHeader.indexCulumn
                ? ElementClassNamesForOnboarding.TableEdit.selectHeader
                    .classNameText
                : undefined
            }
          >
            {/* 一時的に無効 */}
            {/* {isReorderable && onReorderFields && (
                <Box
                  {...listeners}
                  color={textSupplementary}
                  fontSize="sm"
                  cursor="grab"
                >
                  <Icon
                    color={coworkerColorText}
                    boxSize="14px"
                    mt={1}
                    as={TbGridDots}
                  />
                </Box>
              )} */}
            <Text
              variant="subheading"
              colorOverride={coworkerSelection?.colorScheme}
            >
              {field.displayName || field.name}
            </Text>

            <Spacer />

            <StyledBox
              css={{
                color: coworkerSelection
                  ? `$${coworkerSelection?.colorScheme}8`
                  : "$textSupplementary",
              }}
              mr="1"
            >
              <FieldIcon type={field.type} />
            </StyledBox>
          </Flex>
        </ResizableBox>
      </Wrapper>
    </Box>
  );
};

const Keyed = (props: RecordsTableHeaderProps) => {
  return (
    <RecordsTableHeader key={`${props.index}-${props.field.name}`} {...props} />
  );
};

export { Keyed as RecordsTableHeader };
