import { memo } from "react";
import { BsDownload } from "react-icons/bs";
import { FieldsClientModel } from "~/clientModel/fields";
import { Loadable } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { PaginationClientModel } from "~/clientModel/queryConditions/pagination";
import { RecordsClientModel } from "~/clientModel/records";
import { TableColumnSizingClientModel } from "~/clientModel/tables/tableMeta/TableColumnSizing";
import { Button } from "~/components_next/Button";
import { Spacer } from "~/components_next/Spacer";
import { BottomItemsLayout } from "~/presenters/sourceAndViews/common/components/bottomItems/BottomItemsLayout";
import {
  TablePagination,
  TablePaginationLimit,
} from "~/presenters/sourceAndViews/common/components/bottomItems/TablePagination";
import { RecordsTableWithFallback } from "~/presenters/sourceAndViews/common/components/recordsTable/RecordsTableWithFallback";
import { TableCompositeLayout } from "~/presenters/sourceAndViews/common/components/TableCompositeLayout";

type CanvasTableViewProps = {
  // loadables
  recordsLoadable: Loadable<RecordsClientModel>;
  fieldsLoadable: Loadable<FieldsClientModel>;
  tableColumnSizingLoadable?: Loadable<TableColumnSizingClientModel>;

  // pagination
  pagination: PaginationClientModel<TablePaginationLimit>;
  onPaginationChange: (
    pagination: PaginationClientModel<TablePaginationLimit>
  ) => void;

  // csv download
  onDownloadClick?: () => void;

  // records table config
  isOpenable: boolean;
  height: number;
};

const CanvasTableView = memo((props: CanvasTableViewProps) => {
  const {
    recordsLoadable,
    fieldsLoadable,
    tableColumnSizingLoadable,
    height,
    isOpenable,
    pagination,
    onPaginationChange,
    onDownloadClick,
  } = props;

  return (
    <TableCompositeLayout style={{ height }}>
      <RecordsTableWithFallback
        key={fieldsLoadable.data?.allFields.map((f) => f.name).join(",")} // TODO: いったん無理やりやってる
        recordsLoadable={recordsLoadable}
        fieldsLoadable={fieldsLoadable}
        isReadOnly
        isOpenable={isOpenable}
        propagateEvent
        noPadding
      />
      <BottomItemsLayout>
        <WithFallback loadables={[recordsLoadable]}>
          {([records]) => (
            <>
              <TablePagination
                pagination={pagination}
                recordsCount={records.totalCount}
                onPaginationChange={onPaginationChange}
              />
              <Spacer />
              {onDownloadClick && (
                <Button
                  variant="tertiary"
                  size="xs"
                  onClick={() => onDownloadClick()}
                >
                  <BsDownload />
                  Export
                </Button>
              )}
            </>
          )}
        </WithFallback>
      </BottomItemsLayout>
    </TableCompositeLayout>
  );
});

CanvasTableView.displayName = "CanvasTableView";

export { CanvasTableView };
