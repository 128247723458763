import {
  PropsWithChildren,
  PropsWithRef,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps,
} from "react-error-boundary";
import { createCtx } from "~/utils/createCtx";
import { ErrorBoundaryKeyType } from "./errorBoundaryKeys";

type ErrorsContextValueType = { [key in ErrorBoundaryKeyType]+?: unknown };

/**
 * contexts
 */
const { Provider: ErrorsProvider, useCtx: useErrorCtx } =
  createCtx<ErrorsContextValueType>();

const { Provider: ErrorSetterProvider, useCtx: useErrorSetterCtx } = createCtx<{
  setError: (errorBoundaryKey: ErrorBoundaryKeyType, error: unknown) => void;
  initError: (errorBoundaryKey: ErrorBoundaryKeyType) => void;
}>();

/**
 * hooks
 */
const useShowSpecificErrorBoundary = () => {
  return { showSpecificErrorBoundary: useErrorSetterCtx().setError };
};

/**
 * 全てのSpecifiableErrorBoundaryを配下に含むように配置する
 */
const SpecifiableErrorBoundaryProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [errors, setErrors] = useState<ErrorsContextValueType>({});

  const setError = useCallback(
    (errorBoundaryKey: ErrorBoundaryKeyType, error: unknown) => {
      if (!(errorBoundaryKey in errors)) {
        throw new Error(
          `Specified key ${errorBoundaryKey} is not registered as error boundary key.`
        );
      }
      setErrors((cur) => {
        return { ...cur, ...{ [errorBoundaryKey]: error } };
      });
    },
    [errors]
  );

  const initError = useCallback((errorBoundaryKey: ErrorBoundaryKeyType) => {
    setErrors((cur) => {
      return { ...cur, ...{ [errorBoundaryKey]: null } };
    });
  }, []);

  return (
    <ErrorsProvider value={errors}>
      <ErrorSetterProvider value={{ setError, initError }}>
        {children}
      </ErrorSetterProvider>
    </ErrorsProvider>
  );
};

interface ErrorThrowerProps {
  errorBoundaryKey: ErrorBoundaryKeyType;
  children: ReactNode;
}

/**
 * errorBoundaryKeyのerrorが存在すれば同期的にerrorをthrowする
 */
const ErrorThrower = ({ errorBoundaryKey, children }: ErrorThrowerProps) => {
  const errors = useErrorCtx();
  const { initError } = useErrorSetterCtx();

  useEffect(() => {
    initError(errorBoundaryKey);
  }, [errorBoundaryKey, initError]);

  if (errors[errorBoundaryKey]) {
    throw errors[errorBoundaryKey];
  }

  return <>{children}</>;
};

type SpecifiableErrorBoundaryProps = {
  errorBoundaryKey: ErrorBoundaryKeyType;
} & PropsWithRef<PropsWithChildren<ErrorBoundaryProps>>;

/**
 * errorBoundaryKeyによって対象のErrorBoundaryのフォールバックを直接トリガーできるラッパーコンポーネント
 */
const SpecifiableErrorBoundary = ({
  errorBoundaryKey,
  children,
  ...props
}: SpecifiableErrorBoundaryProps) => {
  const initError = useErrorSetterCtx().initError;

  useEffect(() => {
    initError(errorBoundaryKey);
  }, [errorBoundaryKey, initError]);

  return (
    <ReactErrorBoundary {...props}>
      <ErrorThrower errorBoundaryKey={errorBoundaryKey}>
        {children}
      </ErrorThrower>
    </ReactErrorBoundary>
  );
};

export {
  SpecifiableErrorBoundaryProvider,
  SpecifiableErrorBoundary,
  useShowSpecificErrorBoundary,
};
