import { botKeys } from "../keys";

// types
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiBot from "~/api/useApiBot";

interface UseListBotsQueryParams {
  teamSlug: string;
  databaseId: string;
  limit?: number;
  skip?: number;
}

const useListBotsQuery = ({
  teamSlug,
  databaseId,
  limit,
  skip,
}: UseListBotsQueryParams) => {
  const { _listBots } = useApiBot();

  const client = useQueryClient();
  client.setQueryDefaults(
    botKeys.getBotList({ teamSlug, databaseId, limit, skip }),
    commonQueryOptions
  );

  return {
    queryKey: botKeys.getBotList({
      teamSlug,
      databaseId,
      limit,
      skip,
    }),
    queryFn: () => {
      return _listBots({
        teamSlug,
        databaseId,
        limit,
        skip,
      });
    },
  };
};

export { useListBotsQuery };
