// queryは、fromにtableSlugを直接指定したもの(filter, sortなど)

import { DashboardViewResponse } from "@usemorph/morph-dashboard-types";

// sqlは、fromにsqlを指定したもの
export type ViewConditionType = "query" | "sql";

export const getViewConditionType = (
  view: DashboardViewResponse
): ViewConditionType => {
  return view.tableSlug === view.condition.from ? "query" : "sql";
};
