import { match } from "ts-pattern";

type DayType =
  | "Sunday"
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday";

type TargetDayClientModelData = {
  isSelected: boolean;
  dayType: DayType;
};

export class TargetDayClientModel {
  readonly #data: TargetDayClientModelData;

  public constructor(data: TargetDayClientModelData) {
    this.#data = data;
  }

  public get data(): TargetDayClientModelData {
    return this.#data;
  }

  public get dayType(): DayType {
    return this.#data.dayType;
  }

  public get isSelected(): boolean {
    return this.#data.isSelected;
  }

  public select(): TargetDayClientModel {
    return new TargetDayClientModel({
      ...this.#data,
      isSelected: true,
    });
  }

  public unselect(): TargetDayClientModel {
    return new TargetDayClientModel({
      ...this.#data,
      isSelected: false,
    });
  }

  public get dayLabel(): string {
    return match(this.#data.dayType)
      .with("Sunday", () => "Sunday")
      .with("Monday", () => "Monday")
      .with("Tuesday", () => "Tuesday")
      .with("Wednesday", () => "Wednesday")
      .with("Thursday", () => "Thursday")
      .with("Friday", () => "Friday")
      .with("Saturday", () => "Saturday")
      .exhaustive();
  }
}
