import { notebookKeys } from "../keys";
import useApiNotebook from "~/api/useApiNotebook";
import { useQueryClient } from "react-query";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import {
  DashboardNotebookCellResponse,
  DashboardNotebookCellSourceObject,
} from "@usemorph/morph-dashboard-types";
import { viewKeys } from "~/serverStateStore/views";

interface UseUpdateNotebookCellMutationParams {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
}

const useUpdateNotebookCellsMutaiton = ({
  databaseId,
  teamSlug,
  notebookId,
}: UseUpdateNotebookCellMutationParams) => {
  const { _updateNotebookCell } = useApiNotebook();

  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: (
      cells: {
        cellId: string;
        cellType: string;
        source: DashboardNotebookCellSourceObject;
        viewIdToInvalidate?: string;
      }[]
    ) => {
      return Promise.all(
        cells.map((cell) =>
          _updateNotebookCell({
            teamSlug,
            notebookId,
            ...cell,
          })
        )
      );
    },
    onSuccess: (
      responses: DashboardNotebookCellResponse[],
      props: {
        cellId: string;
        cellType: string;
        source: DashboardNotebookCellSourceObject;
        viewIdToInvalidate?: string;
      }[]
    ) => {
      const keys = notebookKeys.findNotebook({
        databaseId,
        teamSlug,
        notebookId,
      });
      broadcast(keys);

      const _viewKeys = (
        props
          .map((cell) => cell.viewIdToInvalidate)
          .filter((viewId) => viewId !== undefined) as string[]
      ).map((viewId) => viewKeys.findView({ databaseId, teamSlug, viewId }));

      if (_viewKeys.length > 0) {
        broadcast(_viewKeys);
        invalidateAll(client, _viewKeys);
      }

      return invalidateAll(client, keys);
    },
  };
};

export { useUpdateNotebookCellsMutaiton };
