import { Box } from "~/components_next/Box";
import {
  forwardRef,
  memo,
  MouseEvent,
  ReactNode,
  TouchEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import { usePlaygroundPseudoHoveredCells } from "../../../providers/PlaygroundPseudoCellSelectionProvider";
import {
  NodeResizer,
  NodeToolbar,
  OnResizeEnd,
  Position,
  ReactFlowState,
  useStore,
} from "reactflow";
import { ScrollArea } from "@radix-ui/themes";
import { Flex } from "~/components_next/Flex";
import { Text } from "~/components_next/Text";
import {
  PlaygroundCellCardElement,
  PlaygroundCellCardVariant,
} from "./PlaygroundCellCardElement";
import { DragHandle } from "./DragHandleElement";
import { EdgeHandle } from "./EdgeHandleElement";
import { usePlaygroundOnUpdateLiveCell } from "../../../providers/PlaygroundUpdateLiveCellProvider";
import { useErrorToast } from "~/components_next/Error";
import { usePlaygroundCoworkerState } from "../../../providers/PlaygroundCoworkerStateProvider";
import { UserAvatar } from "~/components_next/User";
import {
  useReactFlowAddSelectedNodes,
  useReactFlowSelectedNodes,
} from "../../../providers/ReactFlowSelectedCellsProvider";

/**
 * PlaygroundCellCardの仕事
 * 1. 共通スタイルの適応
 * 2. 共通のイベントハンドラの適応: クリックとリサイズ
 * 3. 共通のコンポーネントの適応: ドラッグハンドルとノードツールバー
 */

type PlaygroundCellCardProps = {
  children?: ReactNode;
  cellClientModel: CanvasCellClientModelUnion;
  isNodeSelected: boolean;
  isNodeDragging: boolean;
  variant?: PlaygroundCellCardVariant;
  position: {
    x: number;
    y: number;
  };
};

/**
 * Sore Selectors
 */

const getNodeSizeSelector = (cellId: string) => {
  return (s: ReactFlowState) => {
    const node = s.nodeInternals.get(cellId);

    return {
      width: node?.width || undefined,
      height: node?.height || undefined,
    };
  };
};

const PlaygroundCellCard = (
  props: PlaygroundCellCardProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const {
    children,
    cellClientModel,
    isNodeDragging,
    isNodeSelected,
    variant = "default",
    position,
  } = props;

  const { cellId, cellName } = cellClientModel;

  /**
   * Cell Size
   */
  const nodeSizeSelector = useMemo(() => getNodeSizeSelector(cellId), [cellId]);
  const nodeSize = useStore(
    useCallback(nodeSizeSelector, [nodeSizeSelector]),
    (prev, next) => prev.width === next.width && prev.height === next.height
  );
  const size = useMemo(() => {
    if (
      cellClientModel.settings &&
      cellClientModel.settings.height &&
      cellClientModel.settings.width
    ) {
      return cellClientModel.settings;
    }

    return nodeSize;
  }, [nodeSize, cellClientModel.settings]);

  /**
   * Click Event Handler
   */
  // 複数選択のために、すでに選択済みのノードを取得する
  const addSelectedNodes = useReactFlowAddSelectedNodes();
  const selectedNodes = useReactFlowSelectedNodes();

  const handleCardClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      const isMulti = event.shiftKey;
      if (isMulti) {
        addSelectedNodes([...selectedNodes.map((node) => node.id), cellId]);
        return;
      }
      addSelectedNodes([cellId]);
    },
    [addSelectedNodes, cellId, selectedNodes]
  );

  /**
   * Resize, Drag Event Handler
   */
  const updateLiveCell = usePlaygroundOnUpdateLiveCell();
  // Resize
  const handleResizeEnd: OnResizeEnd = useCallback(
    (event, params) => {
      updateLiveCell(cellClientModel.onResize(params));
    },
    [cellClientModel, updateLiveCell]
  );

  const { errorToast } = useErrorToast({});

  const handleDragEnd = useCallback(() => {
    // react flow側のプロパティを参照
    if (!position.x || !position.y || !size.width || !size.height) {
      errorToast(new Error("Position is not set or unrecognized."));
      return;
    }
    updateLiveCell(
      cellClientModel.onResize({
        width: size.width,
        height: size.height,
        x: position.x,
        y: position.y,
      })
    );
  }, [
    cellClientModel,
    errorToast,
    position.x,
    position.y,
    size.height,
    size.width,
    updateLiveCell,
  ]);

  // Drag
  const [isDragging, setIsDragging] = useState(false);
  useEffect(() => {
    if (isNodeSelected) {
      if (isDragging && !isNodeDragging) {
        handleDragEnd();
      }
      setIsDragging(() => isNodeDragging);
    }
  }, [isNodeSelected, isNodeDragging]);

  /**
   * スクロールイベントの制御
   */
  const cardRef = useRef<HTMLDivElement>(null);
  const handleTouchMove = useCallback((event: TouchEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);
  const handleWheel = useCallback((event: WheelEvent) => {
    if (event.ctrlKey) {
      event.preventDefault();
    }
  }, []);
  useEffect(() => {
    const currentCardRef = cardRef.current;
    currentCardRef?.addEventListener("wheel", handleWheel, {
      passive: false,
    });

    return () => {
      currentCardRef?.removeEventListener("wheel", handleWheel);
    };
  }, [cardRef]);

  /**
   * 外部からのホバー・セレクト状態の制御
   */
  const pseudoHoveredCells = usePlaygroundPseudoHoveredCells();
  const isPseudoHovered = pseudoHoveredCells.includes(cellId);
  const stateVariant = useMemo(() => {
    if (isNodeDragging || isPseudoHovered) {
      return "hovered";
    }
    if (isNodeSelected) {
      return "selected";
    }
    return undefined;
  }, [isNodeDragging, isNodeSelected, isPseudoHovered]);

  /**
   * Cardの中のスクローラブルなエリアのスタイル
   */
  const scrollAreaStyle = useMemo(() => {
    if (variant === "default") {
      return {
        width: `${(size.width || 0) - 20}px`,
        height: `${(size.height || 0) - 20}px`,
      };
    }

    return {
      width: `${size.width}px`,
      height: `${size.height}px`,
    };
  }, [size, variant]);

  /**
   * Coworker
   */
  const coworkerStates = usePlaygroundCoworkerState();
  const coworkersOnCell = coworkerStates.filter((coworker) => {
    return coworker.selectingCellId?.includes(cellId);
  });
  const coworkerCellColor = useMemo(() => {
    if (coworkersOnCell.length === 0) {
      return undefined;
    }
    return coworkersOnCell[0].colorScheme;
  }, [coworkersOnCell]);

  return (
    <Box ref={ref} className={`cellId::${cellId}`}>
      <PlaygroundCellCardElement
        ref={cardRef}
        // variant, state
        variant={variant}
        state={stateVariant}
        className={isNodeSelected ? "nowheel nodrag" : ""} // Node内のマウスイベントの制御。ReactFlow由来: https://reactflow.dev/api-reference/types/node-props#notes
        // position, size
        position="relative"
        css={{ width: `${size.width}px`, height: `${size.height}px` }}
        // event handlers
        onTouchMove={handleTouchMove}
        onClick={handleCardClick}
        // coworker
        coworkerColor={coworkerCellColor}
      >
        {/* Edge Handle: Traget */}
        {cellClientModel.showTargetHandle && <EdgeHandle type="target" />}
        {/* Children */}
        {variant === "fixed" ? (
          <>{children}</>
        ) : (
          <ScrollArea style={scrollAreaStyle}>{children}</ScrollArea>
        )}
        {/* Edge Handle: Source */}
        {cellClientModel.showSourceHandle && <EdgeHandle type="source" />}
      </PlaygroundCellCardElement>

      {cellClientModel.isResizable && (
        <NodeResizer
          minWidth={100}
          minHeight={100}
          handleStyle={{
            width: "20px",
            height: "20px",
          }}
          isVisible={isNodeSelected}
          onResizeEnd={handleResizeEnd}
        />
      )}
      <NodeToolbar
        position={Position.Right}
        // isVisible={coworkersOnCell.length > 0}
        align="start"
      >
        <Flex direction="column">
          {coworkersOnCell.map((coworker) => {
            return (
              <UserAvatar
                key={coworker.user.userId}
                user={coworker.user}
                size="xs"
              />
            );
          })}
        </Flex>
      </NodeToolbar>
      <Flex gap="4" css={{ position: "absolute", top: "-28px", left: 0 }}>
        <Text variant="description">{cellName}</Text>
      </Flex>
      <Box css={{ position: "absolute", top: "-4px", left: "-48px" }}>
        {/* DragHandle */}
        <DragHandle variant={variant} />
      </Box>
    </Box>
  );
};

const forwarded = forwardRef(PlaygroundCellCard);

const memoized = memo(forwarded);

export { memoized as PlaygroundCellCard, type PlaygroundCellCardProps };
