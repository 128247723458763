import useApiRecord from "~/api/useApiRecord";
import { recordsKeys } from "../keys";

// types
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import {
  RecordFilterCondition,
  RecordFormatCondition,
  RecordConditionRuleUnit,
  RecordSortConditionUnit,
} from "@usemorph/morph-dashboard-types";

interface UseDownloadRecordsWithQueryQueryParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
  select: string[];
  filter?: RecordFilterCondition;
  format?: RecordFormatCondition[];
  sort?: RecordSortConditionUnit[];
  join?: RecordConditionRuleUnit[];
  limit?: number;
  skip?: number;
  additionalFilter?: RecordFilterCondition;
  additionalSort?: RecordSortConditionUnit[];
}

const useDownloadRecordsWithQueryQuery = ({
  databaseId,
  tableSlug,
  teamSlug,
  select,
  filter,
  format,
  sort,
  join,
  limit,
  skip,
  additionalFilter,
  additionalSort,
}: UseDownloadRecordsWithQueryQueryParams) => {
  const { _downloadRecordsCSV } = useApiRecord();

  const client = useQueryClient();
  client.setQueryDefaults(
    recordsKeys.all({ databaseId, tableSlug, teamSlug }),
    commonQueryOptions
  );

  return {
    queryKey: recordsKeys.downloadRecordAsCSV({
      databaseId,
      tableSlug,
      teamSlug,
      select,
      format,
      sort,
      filter,
      join,
      limit,
      skip,
      additionalFilter,
      additionalSort,
    }),
    queryFn: () => {
      return _downloadRecordsCSV({
        databaseId,
        teamSlug,
        tableSlug,
        requestBody: {
          select: select,
          filter,
          sort,
          join,
          format,
          limit,
          skip,
          additionalSort,
          addtionalFilter: additionalFilter,
        },
      });
    },
  };
};

export { useDownloadRecordsWithQueryQuery };
