import { useMemo } from "react";
import { UseMutationResult } from "react-query";
import { composeExecutable } from "./composeExecutable";
import { Executable } from "./Executable";

export const useComposeExecutable = <
  PromiseParamType,
  PromiseReturnType,
  ErrorType
>(
  useMutationResult: UseMutationResult<
    PromiseReturnType,
    ErrorType,
    PromiseParamType
  >
): Executable<PromiseParamType, PromiseReturnType, ErrorType> => {
  const loadable = useMemo(() => {
    return composeExecutable(useMutationResult);
  }, [useMutationResult]);

  return loadable;
};
