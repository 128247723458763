import { TableCoworkerStateClientModel } from "./tableCoworkerState/TableCoworkerStateClientModel";

type TableCoworkersStateClientModelData = {
  coworkersState: TableCoworkerStateClientModel[];
};

export class TableCoworkersStateClientModel {
  constructor(readonly data: TableCoworkersStateClientModelData) {}

  public get coworkersState(): TableCoworkerStateClientModel[] {
    return this.data.coworkersState;
  }
}
