// types
import { SimpleTableSchema } from "@usemorph/morph-dashboard-types";
import { useQueryClient } from "react-query";
import useApiImport from "~/api/useApiImport";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { importsKeys } from "../keys";

interface UseCreateImportDataSchemaFromExcelCsvMutationParams {
  teamSlug: string;
  databaseId: string;
}

const useUpdateSimpleImportDataSchemaMutation = ({
  teamSlug,
  databaseId,
}: UseCreateImportDataSchemaFromExcelCsvMutationParams) => {
  const { _updateImportData } = useApiImport();

  const client = useQueryClient();

  return {
    mutationFn: ({
      importDataSchemaId,
      hasHeader,
      specifiedSchema,
    }: {
      importDataSchemaId: string;
      hasHeader: boolean;
      specifiedSchema: SimpleTableSchema;
    }) => {
      return _updateImportData({
        teamSlug,
        databaseId,
        importDataSchemaId,
        requestBody: {
          hasHeader,
          specifiedSchema,
        },
      });
    },
    onSuccess: () => {
      return invalidateAll(client, importsKeys.all({ teamSlug, databaseId }));
    },
  };
};

export { useUpdateSimpleImportDataSchemaMutation };
