import { useMutation } from "react-query";
import { RightSidebar } from "~/components_next/RightSidebar";
import { convertRecordModelToValuesForRequest } from "~/features/RecordModel/utils/convertRecordModelToValuesForRequest";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { RecordModel } from "~/features/RecordModel";
import { useErrorToast } from "~/components_next/Error";
import { useRecordModelFormatValidation } from "~/features/RecordModel/utils/useRecordModelValidation";
import { createPrimaryKeyObjectForOneRecord } from "~/features/Records/utils/createFilterConditionForRequest";
import { DeleteAndSaveSidebarFooter } from "../../../common/components/atoms/DeleteAndSaveSidebarFooter";
import {
  useEditingRecord,
  useSetEditingRecord,
} from "../../states/editingRecord";
import { useSidebarDisclosure } from "../../states/sidebar";
import { useCloseEditRecordSidebar } from "./useCloseEditRecordSidebar";
import { useSetRecordHighlights } from "../../states/recordHighlight";
import { useViewId } from "~/utilHooks/useViewId";
import {
  useDeleteViewRecordMutation,
  useUpdateViewRecordMutation,
  useViewFields,
} from "~/serverStateStore/views";
import { useView } from "~/features/SourceAndViews/common/utils/useView";
import { ViewRecordForm } from "~/features/Records/ViewRecordForm";
import { SimpleField } from "@usemorph/morph-dashboard-types";

type EditRecordSidebarPresenterProps = {
  simpleViewFields: SimpleField[];
  editingRecordModel: RecordModel;
  setEditingRecordModel: (recordModel: RecordModel) => void;
  onClose: () => void;
};

const EditRecordSidebarPresenter = (props: EditRecordSidebarPresenterProps) => {
  const {
    simpleViewFields,
    onClose,
    editingRecordModel,
    setEditingRecordModel,
  } = props;
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  const { data: viewData } = useView();

  /**
   * Client states
   */
  const { isAllValid, validationErrorsDict } = useRecordModelFormatValidation({
    recordModel: editingRecordModel,
    simpleFields: simpleViewFields,
  });

  /**
   * handlers
   */

  const { errorToast } = useErrorToast({});

  const { mutateAsync: updateRecord, isLoading: isSaving } = useMutation(
    useUpdateViewRecordMutation({ teamSlug, databaseId, viewId })
  );

  const handleSaveRecord = async () => {
    if (isAllValid) {
      try {
        await updateRecord({
          values: convertRecordModelToValuesForRequest({
            recordModelAfterEdit: editingRecordModel,
          }),
          filterForRequest: createPrimaryKeyObjectForOneRecord(
            simpleViewFields,
            editingRecordModel
          ),
        });
        onClose();
      } catch (e) {
        errorToast(e);
      }
    }
  };

  const { mutateAsync: deleteRecord, isLoading: isDeleting } = useMutation(
    useDeleteViewRecordMutation({ teamSlug, databaseId, viewId })
  );

  const handleDelete = async () => {
    try {
      await deleteRecord({
        filterForRequest: createPrimaryKeyObjectForOneRecord(
          simpleViewFields,
          editingRecordModel
        ),
      });
      onClose();
    } catch (e) {
      errorToast(e);
    }
  };

  const canUpdate =
    !!viewData?.rules.update &&
    Object.entries(viewData.rules.update).some(([_, value]) => value);

  return (
    <RightSidebar
      width={400}
      minWidth={300}
      footer={
        <DeleteAndSaveSidebarFooter
          isSaveDisabled={!isAllValid}
          onClickSaveRecordButton={handleSaveRecord}
          onClickDeleteRecordButton={handleDelete}
          isSaving={isSaving}
          isDeleting={isDeleting}
          canDelete={!!viewData?.rules.delete}
          canUpdate={canUpdate}
        />
      }
      title="Edit record"
      onClose={onClose}
      height="calc(100vh - 40px)"
    >
      {viewData && (
        <ViewRecordForm
          simpleViewFields={simpleViewFields}
          recordModel={editingRecordModel}
          setRecordModel={setEditingRecordModel}
          validationErrorsDict={validationErrorsDict}
          displayedFieldCategories={"all"}
          updateRules={viewData.rules.update}
        />
      )}
    </RightSidebar>
  );
};

const EditRecordSidebar = () => {
  const { isOpen, onClose } = useSidebarDisclosure("editRecord");
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  const { data: viewData } = useView();

  const { data: viewFieldsData } = useViewFields({
    teamSlug,
    databaseId,
    viewId,
    viewData,
  });

  const editingRecord = useEditingRecord(viewId);
  const setEditingRecord = useSetEditingRecord(viewId);

  useCloseEditRecordSidebar();

  const setRecordHighlights = useSetRecordHighlights(viewId);
  const handleOnClose = () => {
    setRecordHighlights([]);
    onClose();
  };

  if (!viewFieldsData || !editingRecord) return null;

  if (!isOpen) {
    return null;
  }

  const editingRecordModel = editingRecord.values;

  const setEditingRecordModel = (editingRecordModel: RecordModel) => {
    setEditingRecord((prev) => {
      if (!prev) return null;

      return {
        ...prev,
        values: editingRecordModel,
      };
    });
  };

  return (
    <EditRecordSidebarPresenter
      onClose={handleOnClose}
      simpleViewFields={viewFieldsData.fields}
      editingRecordModel={editingRecordModel}
      setEditingRecordModel={setEditingRecordModel}
    />
  );
};

export { EditRecordSidebar };
