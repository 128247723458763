import { NodeProps, NodeToolbar, Position, useStore } from "reactflow";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
import { styled } from "~/stitches";
import { NotebookCellCard } from "../NotebookCellCommon";
import { NotebookActionButtonCreate } from "../NotebookCellCommon/ActionButton/NotebookActionButtonCreate";
import { NotebookActionButtonExecute } from "../NotebookCellCommon/ActionButton/NotebookActionButtonExecute";
import { NotebookActionButtonHtml } from "../NotebookCellCommon/ActionButton/NotebookActionButtonHtml";
import { useCellProgress } from "../NotebookCellCommon/useCellProgress";
import { NotebookCellObjectWithMeta } from "../../types/NotebookCellObjectWithMeta.type";
import { useCallback } from "react";
import { NotebookCellPromptUnits } from "./NotebookCellPromptUnits";
import { NotebookActionButtonAddToPage } from "../NotebookCellCommon/ActionButton/NotebookActionButtonAddToPage";
import { useNotebookAncestorVariables } from "../Variables/useNotebookAncestorVariables";
import { useQuery } from "react-query";
import { useExecuteNotebookCellQuery } from "~/serverStateStore";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useNotebookId } from "~/utilHooks/useNotebookId";
import { Spinner } from "~/components_next/Spinner";
import { useCachedValue } from "~/hooks/useCachedValue/useCachedValue";

type NotebookCellPromptProps = {
  cellObject: NotebookCellObjectWithMeta;
};

const PromptElement = styled(Box, {
  borderRadius: "$2",
  backgroundColor: "$bg2",
});

const NotebookCellPrompt = (props: NodeProps<NotebookCellPromptProps>) => {
  const { data, selected } = props;
  const { cellObject } = data;
  const { cellId, cellType, settings } = cellObject;

  const { isProcessing, lastMessage } = useCellProgress(cellId);

  const addSelectedNodes = useStore((s) => s.addSelectedNodes);
  // const resetSelectedNodes = useStore((s) => s.resetSelectedElements);

  const handleOnSelectCell = useCallback(() => {
    addSelectedNodes([cellId]);
  }, []);

  const { ancestorVariableValues } = useNotebookAncestorVariables(cellObject);
  const cachedValue = useCachedValue(ancestorVariableValues, (value) =>
    JSON.stringify(value)
  );

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const notebookId = useNotebookId();

  const { data: cellWithVariables, isLoading: isExecuting } = useQuery({
    ...useExecuteNotebookCellQuery({
      teamSlug,
      notebookId,
      cellId: cellObject.cellId,
      variables: cachedValue,
    }),
    enabled: cachedValue.length > 0,
  });

  return (
    <NotebookCellCard
      cellObject={cellObject}
      isNodeDragging={props.dragging}
      isNodeSelected={props.selected}
      variant="fixed"
    >
      <Box height={"100%"} width="100%" className="PromptContent">
        {isExecuting ? (
          <Flex height="100%" width="100%" align="center" justify="center">
            <Spinner />
          </Flex>
        ) : (
          <NotebookCellPromptUnits
            units={
              cellWithVariables ? cellWithVariables.outputs : cellObject.outputs
            }
            onSelect={handleOnSelectCell}
            isSelected={selected}
          />
        )}
      </Box>

      <NodeToolbar position={Position.Left} isVisible={selected}>
        <Flex direction="column" align="start" justify="start" gap="2">
          <NotebookActionButtonAddToPage cellObject={cellObject} />
        </Flex>
      </NodeToolbar>

      {/* Variablesの件で一旦生成中のログを隠す */}
      {/* <NodeToolbar
        position={Position.Right}
        isVisible={selected || isProcessing}
      >
        <Flex direction="column" align="start" justify="start" gap="2">
          {selected && (
            <>
              <NotebookActionButtonExecute cellObject={cellObject} />
              <NotebookActionButtonHtml cellObject={cellObject} />
            </>
          )}
          {isProcessing && (
            <Code variant="solid" color="gray" highContrast size="1">
              {lastMessage}
            </Code>
          )}
        </Flex>
      </NodeToolbar> */}

      <NodeToolbar position={Position.Right} isVisible={selected}>
        <Flex direction="column" align="start" justify="start" gap="2">
          <NotebookActionButtonExecute cellObject={cellObject} />
          <NotebookActionButtonHtml cellObject={cellObject} />
        </Flex>
      </NodeToolbar>

      <NodeToolbar position={Position.Bottom} isVisible={selected}>
        <NotebookActionButtonCreate
          cellId={cellId}
          cellType={cellType}
          cellSettings={settings}
        />
      </NodeToolbar>
    </NotebookCellCard>
  );
};

export { NotebookCellPrompt, type NotebookCellPromptProps };
