import { Flex, Text } from "@radix-ui/themes";
import { styled } from "@stitches/react";
import { DashboardUserObject } from "@usemorph/morph-dashboard-types";
import { UserAvatar } from "../UserAvatar/UserAvatar";

type UserMentionProps = {
  user: DashboardUserObject;
};

const UserName = styled(Text, {
  fontWeight: "regular",
  fontSize: "$2",
  lineHeight: "$2",
  color: "$textDescription",
});

export const UserMention = (props: UserMentionProps) => {
  const { user } = props;
  return (
    <Flex align="center" gap="2">
      <UserAvatar
        user={user}
        size="xs"
        showCoworkerBorder
        showTooltipOnHover={false}
      />
      <UserName>{user.username}</UserName>
    </Flex>
  );
};
