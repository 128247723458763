import * as Form from '@radix-ui/react-form';
import {
  SimpleField,
  WidgetDataUpdateRecordRequestBody,
} from "@usemorph/morph-dashboard-types";
import { useState } from "react";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { FixedValuesForm } from "../common/FiexedValuesForm";
import { ValuesForm } from '../common/ValuesForm';

export const UpdateForm = ({
  defaultBody,
  fields,
  onChange,
}: {
  defaultBody?: WidgetDataUpdateRecordRequestBody;
  fields: SimpleField[];
  onChange: (body: WidgetDataUpdateRecordRequestBody) => void;
}) => {
  const [body, setBody] = useState<WidgetDataUpdateRecordRequestBody>(
    defaultBody ?? {
      filter: {
        and: [
          {
            key: fields[0].name,
            operator: "equal",
            value: null,
          },
        ],
      },
      values: [],
      fixedValue: [],
    }
  );

  return (
    <Form.Field name="update" style={{ width: "100%" }}>
      <InputStack>
        <ValuesForm
          values={defaultBody ? defaultBody.values : []}
          fields={fields}
          onChange={(e) => {
            setBody({ ...body, values: e });
            onChange({ ...body, values: e });
          }}
        />

        <FixedValuesForm
          values={defaultBody ? defaultBody.fixedValue : []}
          fields={fields}
          onChange={(e) => {
            setBody({ ...body, fixedValue: e });
            onChange({ ...body, fixedValue: e });
          }}
        />
      </InputStack>
    </Form.Field>
  );
};
