/* eslint-disable react/display-name */
import { memo } from "react";
import {
  SimpleField,
  TableHistoryDataConditionAnd,
} from "@usemorph/morph-dashboard-types";
import {
  isAndCondition,
  isOrCondition,
} from "~/features/TableHistory/util/tableHistoryConditionUtils";
import { TableHistoryNormalCondition } from "./TableHistoryNormalCondition";
import { TableHistoryOrCondition } from "./TableHistoryOrCondition";

export const TableHistoryAndCondition = memo(
  ({
    condition,
    fields,
  }: {
    condition: TableHistoryDataConditionAnd;
    fields: SimpleField[];
  }) => {
    return (
      <>
        {condition.and.map((cond, index) => {
          if (!isAndCondition(cond) && !isOrCondition(cond)) {
            return (
              <TableHistoryNormalCondition
                key={index}
                condition={cond}
                fields={fields}
              />
            );
          } else if (isAndCondition(cond)) {
            return (
              <TableHistoryAndCondition
                key={index}
                condition={condition}
                fields={fields}
              />
            );
          } else if (isOrCondition(cond)) {
            return (
              <TableHistoryOrCondition
                key={index}
                condition={cond}
                fields={fields}
              />
            );
          }
        })}
      </>
    );
  }
);
