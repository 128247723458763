import { Badge as RadixThemeBadge } from "@radix-ui/themes";
import {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from "react";
import { match } from "ts-pattern";
import { MorphThemeSize } from "../commonProps.type";

type RadixBadgeProps = ComponentPropsWithoutRef<typeof RadixThemeBadge>;

type BadgeProps = {
  size?: MorphThemeSize;
  variant: "primary" | "secondary" | "tertiary";
  color?: RadixBadgeProps["color"];
  radius?: "small" | "full" | "none" | "medium" | "large";
  children?: ReactNode;
  onClick?: () => void;
};

const getSize = (size?: MorphThemeSize) => {
  return match(size)
    .with("xs", () => "1" as const)
    .with("sm", () => "1" as const)
    .with("md", () => "1" as const)
    .with("lg", () => "2" as const)
    .with("xl", () => "2" as const)
    .otherwise(() => "2" as const);
};

const getVariant = (variant: BadgeProps["variant"]) => {
  return match(variant)
    .with("primary", () => "solid" as const)
    .with("secondary", () => "outline" as const)
    .with("tertiary", () => "soft" as const)
    .exhaustive();
};

const getAdditionalStyle = (size: MorphThemeSize) => {
  return match(size)
    .with("xs", () => ({
      fontSize: "10px",
      padding: "0 4px",
    }))
    .otherwise(() => ({}));
};

const _Badge = (props: BadgeProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { size = "md", variant, onClick, color, radius, children } = props;

  const radixBadgeSize = getSize(size);
  const radixBadgeVariant = getVariant(variant);

  return (
    <RadixThemeBadge
      color={color ?? "gray"}
      highContrast
      size={radixBadgeSize}
      variant={radixBadgeVariant}
      radius={radius ?? "full"}
      onClick={onClick}
      style={{
        height: "fit-content",
        width: "fit-content",
        ...getAdditionalStyle(size),
      }}
    >
      {children}
    </RadixThemeBadge>
  );
};

const Badge = forwardRef(_Badge);

export { Badge, type BadgeProps };
