import { DashboardWidgetDataRegistApiRequestBody } from "@usemorph/morph-dashboard-types";
import { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import SimpleBar from "simplebar-react";
import { match, P } from "ts-pattern";
import { Button } from "~/components_next/Button";
import { Text } from "~/components_next/Text";
import { useErrorToast } from "~/components_next/Error";
import { RightSidebar } from "~/components_next/RightSidebar";
import {
  useFindWidgetDataApiQuery,
  useRegistWidgetDataMutation,
  useSimpleFields,
} from "~/serverStateStore";
import { useDataApiKey } from "~/utilHooks/useDataApiKey";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTableSlug } from "~/utilHooks/useTableSlug";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { ApiKeyForm } from "./components/common/ApiKeyForm";
import { UrlForm } from "./components/common/UrlForm";
import { DataApiPreview } from "./components/WidgetDataApiPreview";
import { AggregateForm } from "./components/Form/AggregateForm";
import { CreateForm } from "./components/Form/CreateForm";
import { DownloadForm } from "./components/Form/DownloadForm";
import { QueryForm } from "./components/Form/QueryForm";
import { UpdateForm } from "./components/Form/UpdateForm";
import { SampleCode } from "./components/SampleCode";
import { convertWidgetDataApiObject } from "./util/convertWidgetDataApiObject";
import { Box, Flex, Separator, Tabs } from "@radix-ui/themes";
import { Form } from "@radix-ui/react-form";

enum DataApiPathnames {
  query = "/widget-data/record/query",
  create = "/widget-data/record/create",
  update = "/widget-data/record/update",
  delete = "/widget-data/record/delete",
  upload = "/widget-data/file/upload",
  aggregate = "/widget-data/record/aggregate",
  download = "/widget-data/record/csv",
  chat = "/widget-data/chat/reply",
}

const WidgetDataApi = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const tableSlug = useTableSlug();
  const dataApiKey = useDataApiKey();

  const { data: simpleFields } = useSimpleFields({
    teamSlug,
    databaseId,
    tableSlug,
  });

  const { data: dataApi } = useQuery({
    ...useFindWidgetDataApiQuery({
      databaseId,
      teamSlug,
      tableSlug,
      apiKey: dataApiKey,
    }),
    onSuccess: (data) => {
      const converted = convertWidgetDataApiObject(data);
      if (converted) {
        setEditingValue(converted);
      }
    },
  });

  const [editingValue, setEditingValue] =
    useState<DashboardWidgetDataRegistApiRequestBody | null>(null);

  const pathname = useMemo(() => {
    return match(editingValue?.apiType)
      .with("query", () => DataApiPathnames.query)
      .with("create", () => DataApiPathnames.create)
      .with("update", () => DataApiPathnames.update)
      .with("delete", () => DataApiPathnames.delete)
      .with("upload", () => DataApiPathnames.upload)
      .with("aggregate", () => DataApiPathnames.aggregate)
      .with("download", () => DataApiPathnames.download)
      .with("chat", () => DataApiPathnames.chat)
      .otherwise(() => "");
  }, [editingValue]);

  const url = useMemo(() => {
    return `${dataApi?.url}${pathname}`;
  }, [dataApi, pathname]);

  /**
   * Update
   */
  const { errorToast } = useErrorToast({});
  const { mutateAsync: updateDataApi, isLoading: isUpdating } = useMutation({
    ...useRegistWidgetDataMutation({
      databaseId,
      teamSlug,
    }),
  });

  const handleSaveClicked = async () => {
    if (!dataApi) return;
    if (!dataApi.apiType) return;

    try {
      await updateDataApi({
        ...editingValue,
        tableSlug: dataApi.tableSlug,
        apiType: dataApi.apiType,
        apiName: dataApi.apiName,
        apiKey: dataApi.apiKey,
        isDynamicCondition: true,
      });
    } catch (e) {
      errorToast(e);
    }
  };

  /**
   * UI
   */
  const windowWidth = window.innerWidth;
  const [sidebarWidth, setSidebarWidth] = useState((windowWidth - 300) / 2);

  // const { primaryBg1 } = useMorphColors();

  return (
    <Flex>
      <Box
        style={{
          height: "calc(100vh - 40px)",
          overflowY: "auto",
        }}
        grow="1"
        p="4"
      >
        <Flex
          direction="column"
          // bg={primaryBg1}
          // rounded="md"
          mb="4"
          align="start"
          gap="1"
          p="4"
        >
          <Text variant="subheading">Notice</Text>
          <Text>
            This feature will be deprecated soon. Use similar features in our
            new Data API. Migration guide coming soon. Thank you.
          </Text>
        </Flex>
        <UrlForm url={url} />
        <Form>
          {match(editingValue)
            .with({ apiType: "query" }, (body) => {
              return (
                <QueryForm
                  fields={simpleFields?.fields ?? []}
                  defaultBody={body.query}
                  onChange={(value) => {
                    if (editingValue) {
                      setEditingValue({
                        ...editingValue,
                        query: value,
                      });
                    }
                  }}
                />
              );
            })
            .with({ apiType: "create" }, (body) => {
              return (
                <CreateForm
                  fields={simpleFields?.fields ?? []}
                  defaultBody={body.create}
                  onChange={(value) => {
                    if (editingValue) {
                      setEditingValue({
                        ...editingValue,
                        create: value,
                      });
                    }
                  }}
                />
              );
            })
            .with({ apiType: "update" }, (body) => {
              return (
                <UpdateForm
                  fields={simpleFields?.fields ?? []}
                  defaultBody={body.update}
                  onChange={(value) => {
                    if (editingValue) {
                      setEditingValue({
                        ...editingValue,
                        update: value,
                      });
                    }
                  }}
                />
              );
            })
            .with({ apiType: "download" }, (body) => {
              return (
                <DownloadForm
                  fields={simpleFields?.fields ?? []}
                  defaultBody={body.download}
                  onChange={(value) => {
                    if (editingValue) {
                      setEditingValue({
                        ...editingValue,
                        download: value,
                      });
                    }
                  }}
                />
              );
            })
            .with({ apiType: "aggregate" }, (body) => {
              return (
                <AggregateForm
                  fields={simpleFields?.fields ?? []}
                  defaultBody={body.aggregate}
                  onChange={(value) => {
                    if (editingValue) {
                      setEditingValue({
                        ...editingValue,
                        aggregate: value,
                      });
                    }
                  }}
                />
              );
            })
            .otherwise(() => (
              <Box height="5" />
            ))}
        </Form>
        <ApiKeyForm apiKey={dataApi?.apiKey || ""} />
        <Separator my="6" size="4" />
        <Button
          variant="primary"
          size="sm"
          isLoading={isUpdating}
          onClick={handleSaveClicked}
        >
          Save
        </Button>
        {/* <Text wordBreak="break-all">{JSON.stringify(editingValue)}</Text> */}
      </Box>
      {/* Right */}
      <RightSidebar
        onResizeDone={(value) => setSidebarWidth(value)}
        width={sidebarWidth}
        height="100%"
      >
        {editingValue && (
          <Tabs.Root defaultValue={editingValue.apiName}>
            <Tabs.List>
              {match(editingValue)
                .with({ apiType: P.union("query") }, () => (
                  // aggregateとdownloadも本当はやりたい
                  <Tabs.Trigger value={editingValue?.apiName ?? ""}>
                    Data Example
                  </Tabs.Trigger>
                ))
                .otherwise(() => (
                  <></>
                ))}
              <Tabs.Trigger value={"sampleCode"}>Sample Code</Tabs.Trigger>
            </Tabs.List>

            {match(editingValue)
              .with({ apiType: P.union("query") }, () => (
                <Tabs.Content value={editingValue?.apiName ?? ""}>
                  <SimpleBar
                    style={{
                      height: "calc(100vh - 140px)",
                      padding: "5px 10px",
                    }}
                  >
                    {editingValue && (
                      <DataApiPreview
                        body={editingValue}
                        tableSlug={tableSlug}
                        apiKey={dataApiKey}
                      />
                    )}
                  </SimpleBar>
                </Tabs.Content>
              ))
              .otherwise(() => (
                <></>
              ))}

            <Tabs.Content value="sampleCode">
              <SimpleBar
                style={{
                  height: "calc(100vh - 140px)",
                  padding: "5px 10px",
                }}
              >
                {editingValue && <SampleCode url={url} body={editingValue} />}
              </SimpleBar>
            </Tabs.Content>
          </Tabs.Root>
        )}
      </RightSidebar>
    </Flex>
  );
};

export { WidgetDataApi };
