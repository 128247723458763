import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModel } from "..";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";

export class NumberFieldTypeClientModel extends FieldTypeClientModelBase {
  public get type(): "number" {
    return "number";
  }

  public get fieldTypeLabel(): "Number" {
    return "Number";
  }

  public get isAutoGenerated(): false {
    return false;
  }

  public get isEmbeddable(): false {
    return false;
  }

  public get canBeTargetOfSyncingByComputedField(): true {
    return true;
  }

  public get _updatableFieldTypes(): FieldTypeClientModel["type"][] {
    return [
      "bigNumber",
      "decimal",
      "shortText",
      "longText",
      "singleSelect",
      "multiSelect",
    ];
  }

  public validate(): ValidationResult {
    return { isValid: true };
  }
}
