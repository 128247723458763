import { MorphThemeSize } from "../../commonProps.type";
import { Select as RadixThemeSelect } from "@radix-ui/themes";
import { match, P } from "ts-pattern";
import { RadixComponentObjToPropsObj } from "../../typeUtils";

type RadixThemeSelectProps = RadixComponentObjToPropsObj<
  typeof RadixThemeSelect
>;

/**
 * Common Types
 */
export type SelectVariant = "primary" | "supplementary";

export type ErrorMessages = string[];

/**
 * Common Functions for internal use
 */

export const getIsInvalid = (errorMessages?: ErrorMessages): boolean => {
  return errorMessages && errorMessages.length > 0 ? true : false;
};

export const getTriggerVariant = (
  variant: SelectVariant,
  isInvalid: boolean
): RadixThemeSelectProps["Trigger"]["variant"] => {
  return match([variant, isInvalid])
    .with(["primary", false], () => "surface" as const)
    .with(["primary", true], () => "soft" as const)
    .with(["supplementary", P._], () => "soft" as const)
    .exhaustive();
};

export const getContentVariant = (
  variant: SelectVariant
): RadixThemeSelectProps["Content"]["variant"] => {
  return match(variant)
    .with("primary", () => "soft" as const)
    .with("supplementary", () => "soft" as const)
    .exhaustive();
};

export const getTriggerColor = (
  isInvalid: boolean
): RadixThemeSelectProps["Trigger"]["color"] => {
  return isInvalid ? "red" : "gray";
};

export const getContentColor =
  (): RadixThemeSelectProps["Content"]["color"] => {
    return "gray";
  };

export const getRadixSize = (
  size?: MorphThemeSize
): RadixThemeSelectProps["Root"]["size"] => {
  return match(size)
    .with("xs", () => "1" as const)
    .with("sm", () => "2" as const)
    .with("md", () => "3" as const)
    .with("lg", () => "3" as const)
    .with("xl", () => "3" as const)
    .with(undefined, () => "3" as const)
    .exhaustive();
};
