import { DashboardVersionObject } from "@usemorph/morph-dashboard-types";

export class VersionClientModel {
  readonly #data: DashboardVersionObject

  constructor(data: DashboardVersionObject) {
    this.#data = data
  }

  /**
   * Getters
   */
  public get versionId(): string {
    return this.#data.versionId;
  }

  public get isAvailable(): boolean {
    return this.#data.isAvailable;
  }

  public get versionName(): string {
    return this.#data.versionName;
  }

}
