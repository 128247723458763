import { Virtualizer } from "@tanstack/react-virtual";
import { MouseEvent, useCallback, useMemo, useState } from "react";
import { match, P } from "ts-pattern";
import {
  useRecordsTableBaseCellSelection,
  useRecordsTableBaseClearAllSelection,
  useRecordsTableBaseCoworkerStates,
  useRecordsTableBaseEditingCell,
  useRecordsTableBaseFields,
  useRecordsTableBaseOnRecordClick,
  useRecordsTableBaseOnRecordSelectionChange,
  useRecordsTableBaseRecordHilights,
  useRecordsTableBaseRecordSelection,
} from "../context/ReactTableBaseContext";
import { RecordsTableCell } from "../RecordsTableCell/RecordsTableCell";
import { RecordsTableSelectBox } from "../RecordsTableHeader/RecordsTableSelectBox";
import { RecordsTableBaseRecord } from "../types";
import { getCoworkerSelectionRecordSelection } from "../util/coworkerStateUtil";
import { isSameRecord } from "../util/recordsTableRecordUtil";
//Intercom Class setup
import { ElementClassNamesForOnboarding } from "~/constants/IntercomClassConst";
import { Box } from "@radix-ui/themes";

type RecordsTableRowProps = {
  record: RecordsTableBaseRecord;
  index: number;
  columnVirtualizer: Virtualizer<HTMLDivElement, Element>;
  translateY: number;
  rowWidth: number;
};

const RecordsTableRow = (props: RecordsTableRowProps) => {
  const { record, columnVirtualizer, translateY, rowWidth, index } = props;

  // data
  const fields = useRecordsTableBaseFields();

  // states
  const recordSelection = useRecordsTableBaseRecordSelection();
  const recordHilights = useRecordsTableBaseRecordHilights();
  const onRecordSelectionChange = useRecordsTableBaseOnRecordSelectionChange();
  const cellSelection = useRecordsTableBaseCellSelection();
  const editingCell = useRecordsTableBaseEditingCell();
  const coworkerStates = useRecordsTableBaseCoworkerStates();
  // event
  const onRecordClick = useRecordsTableBaseOnRecordClick();

  const [isHovered, setIsHovered] = useState(false);

  /**
   * Select record event
   */
  const handleSelectRecord = (record: RecordsTableBaseRecord) => {
    if (isSelected) {
      // remove
      onRecordSelectionChange([
        ...(recordSelection || []).filter(({ _reservedRecordIndex }) => {
          return _reservedRecordIndex !== record._reservedRecordIndex;
        }),
      ]);
    } else {
      // add
      onRecordSelectionChange([...(recordSelection || []), record]);
    }
  };

  const isSelected = useMemo(() => {
    if (recordSelection.length === 0) return false;

    const findRecord = recordSelection.find((r) => {
      return isSameRecord(r, record);
    });
    return findRecord !== undefined;
  }, [recordSelection, record]);

  const isRecordHilighted = useMemo(() => {
    if (recordHilights.length === 0) return false;

    const findRecord = recordHilights.find((r) => {
      return isSameRecord(r, record);
    });
    return findRecord !== undefined;
  }, [record, recordHilights]);

  const coworkerSelection = useMemo(() => {
    if (!coworkerStates) {
      return undefined;
    }

    const recordSelection = getCoworkerSelectionRecordSelection(
      record,
      coworkerStates
    );
    return recordSelection.length > 0 ? recordSelection[0] : undefined;
  }, [coworkerStates, record]);

  /**
   * Row coloring
   */

  const backgroundColor = useMemo(() => {
    if (coworkerSelection) {
      return `$${coworkerSelection.colorScheme}2`;
    }

    return match([
      cellSelection.length,
      recordSelection.length,
      editingCell,
      isHovered,
    ])
      .with([0, 0, P.nullish, true], () => "$bg1")
      .otherwise(() => undefined);
  }, [
    cellSelection.length,
    recordSelection.length,
    editingCell,
    isHovered,
    coworkerSelection,
  ]);

  /**
   * Margin Click
   */
  const clearAllSelection = useRecordsTableBaseClearAllSelection();

  const handleMarginClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      if (event.target === event.currentTarget) {
        clearAllSelection();
      }
    },
    [clearAllSelection]
  );

  /**
   * Virtual Scroll
   */
  /**
   * [ [checkbox], [field], [field], ..., [addFieldButton], [margin] ]
   */
  const CHECKBOX_INDEX = 0;
  const ADDFIELD_BUTTON_INDEX = 1 + (fields.length - 1) + 1;
  const MARGIN_COLUMN_INDEX = ADDFIELD_BUTTON_INDEX + 1;

  return (
    <Box
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: `${rowWidth}px`,
        backgroundColor: `${backgroundColor}`,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {columnVirtualizer.getVirtualItems().map((virtualItem) => {
        const transform = `translateX(${virtualItem.start}px) translateY(${translateY}px)`;

        return match(virtualItem.index)
          .with(CHECKBOX_INDEX, () => (
            <RecordsTableSelectBox
              key={virtualItem.key}
              isActionsVisible={coworkerSelection ? false : isHovered}
              onOpen={() => {
                onRecordClick?.(record);
              }}
              onSelect={() => handleSelectRecord(record)}
              isChecked={isSelected}
              isHilighted={isRecordHilighted}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                transform,
              }}
            />
          ))
          .with(ADDFIELD_BUTTON_INDEX, () => (
            <Box
              key={virtualItem.key}
              style={{
                width: "40px",
                height: "40px",
                position: "absolute",
                top: 0,
                left: 0,
                transform,
              }}
              onClick={handleMarginClick}
            />
          ))
          .with(MARGIN_COLUMN_INDEX, () => (
            <Box
              key={virtualItem.key}
              style={{
                width: "400px",
                height: "40px",
                position: "absolute",
                top: 0,
                left: 0,
                transform,
              }}
              onClick={handleMarginClick}
            />
          ))
          .otherwise(() => (
            <RecordsTableCell
              key={virtualItem.index}
              field={fields[virtualItem.index - 1]}
              record={record}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                transform,
              }}
              className={
                index ===
                  ElementClassNamesForOnboarding.TableEdit.selectCell
                    .indexRow &&
                virtualItem.index ===
                  ElementClassNamesForOnboarding.TableEdit.selectCell
                    .indexCulumn
                  ? ElementClassNamesForOnboarding.TableEdit.selectCell
                      .classNameText
                  : undefined
              }
            />
          ));
      })}
    </Box>
  );
};

export { RecordsTableRow };
