import { SelectContent, SelectItem, SelectRoot, SelectTrigger } from "~/components_next/Select"
import { Input } from "~/components_next/Input"
import { Flex } from "~/components_next/Flex"
import { MdArrowRightAlt } from "react-icons/md"
import { RssFieldClientModel } from "~/clientModel/rssFeed/RssFieldClientModel"
import { Box } from "~/components_next/Box"
import { FieldsClientModel } from "~/clientModel/fields"

interface FieldMappingSelectProps {
  fields: FieldsClientModel;
  rssField: RssFieldClientModel;
  onChangeField: (field: RssFieldClientModel) => void;
  field: "title" | "description" | "image" | "link" | "pubDate";
  options: string[]
}

export const FieldMappingSelect = (props: FieldMappingSelectProps) => {
  const {
    fields,
    rssField,
    onChangeField,
    field,
    options,
  } = props

  const onChange = async (value: string | null) => {
    if (value) {
      const val = rssField.update({ key: field, value })
      onChangeField(val)
    }
  }

  return (
    <>
      <Flex align="center" gap="4">
        <Box css={{ minWidth: "30%" }}>
          <Input value={field} variant="supplementary" disabled={true} />
        </Box>
        <MdArrowRightAlt size="40" />
        <SelectRoot
          value={rssField[field] ?? null}
          variant="primary"
          size="sm"
          onChange={onChange}
          isClearable
        >
          <SelectTrigger />
          <SelectContent>
            {fields.allFields.map((field, index) => (
              <SelectItem key={index} value={field.displayName ?? field.name}>
                <Flex gap="4" align="center" pl="3">
                  {field.displayName ?? field.name}
                </Flex>
              </SelectItem>
            ))}
          </SelectContent>
        </SelectRoot>
      </Flex>
    </>
  )
}
