import { DashboardViewConditionObject } from "@usemorph/morph-dashboard-types";
import constate from "constate";
import { useMemo } from "react";
import { CanvasVariablesClientModel } from "~/clientModel/canvas/CanvasVariableClientModel";
import { CsvDownloadFieldsClientModel } from "~/clientModel/csvDownload/csvDownloadFields";
import { UseExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { PromptHistoriesClientModel } from "~/clientModel/promptHistories";
import { PaginationClientModel } from "~/clientModel/queryConditions/pagination";
import { RecordsClientModel } from "~/clientModel/records";
import { TablePaginationLimit } from "~/presenters/sourceAndViews/common/components/bottomItems/TablePagination";
import { CanvasVariablesValue } from "../../common/CanvasVariablesProvider";

/**
 * 実装が追いついてないところは一旦全部オプショナル
 */
export type PlaygroundTableViewPropsContextProps = {
  // loadable
  useViewRecordsLoadable: UseLoadable<
    {
      viewId: string;
      pagination: PaginationClientModel<TablePaginationLimit>;
      variables?: CanvasVariablesValue[];
    },
    RecordsClientModel
  >;

  useViewFieldsLoadable: UseLoadable<{ viewId: string }, FieldsClientModel>;

  // input suggestions loadables
  useVariablesForTypeaheadLoadable?: UseLoadable<
    { viewId: string },
    CanvasVariablesClientModel
  >;
  useFieldsForTypeaheadLoadable?: UseLoadable<
    { viewId: string },
    FieldsClientModel
  >;
  useSuggestedPromptsLoadable: UseLoadable<
    { condition: DashboardViewConditionObject; viewId: string },
    { messages: string[] },
    unknown
  >;
  usePromptHistoriesLoadable: UseLoadable<
    { viewId: string },
    PromptHistoriesClientModel,
    unknown
  >;

  // executables
  useGenerateSqlFromPromptExecutable: UseExecutable<
    {
      condition: DashboardViewConditionObject;
    },
    { prompt: string },
    { sql: string }
  >;
  useUpdateFieldsOrderExecutable?: UseExecutable<
    { viewId: string },
    { fields: FieldsClientModel }
  >;
  useUpdateFieldVisibilityExecutable?: UseExecutable<
    { viewId: string },
    { field: FieldClientModel }
  >;

  // csv download
  useDownloadCsvPreviewRecordsLoadable: UseLoadable<
    { csvDownloadFields: CsvDownloadFieldsClientModel; viewId: string },
    RecordsClientModel
  >;
  useDownloadCsvExecutable: UseExecutable<
    { viewId: string },
    { csvDownloadFields: CsvDownloadFieldsClientModel },
    { url: string }
  >;
};

const usePlaygroundTableViewPropsContext = (
  props: PlaygroundTableViewPropsContextProps
) => {
  const {
    useViewRecordsLoadable,
    useViewFieldsLoadable,

    useVariablesForTypeaheadLoadable,
    useFieldsForTypeaheadLoadable,
    useSuggestedPromptsLoadable,
    useGenerateSqlFromPromptExecutable,
    useUpdateFieldsOrderExecutable,
    useUpdateFieldVisibilityExecutable,
    usePromptHistoriesLoadable,

    // csv download
    useDownloadCsvPreviewRecordsLoadable,
    useDownloadCsvExecutable,
  } = props;

  const contextValue = useMemo(() => {
    return {
      useViewRecordsLoadable,
      useViewFieldsLoadable,
      useVariablesForTypeaheadLoadable,
      useFieldsForTypeaheadLoadable,
      useSuggestedPromptsLoadable,
      useGenerateSqlFromPromptExecutable,
      useUpdateFieldsOrderExecutable,
      useUpdateFieldVisibilityExecutable,
      usePromptHistoriesLoadable,
      useDownloadCsvPreviewRecordsLoadable,
      useDownloadCsvExecutable,
    };
  }, [
    useViewRecordsLoadable,
    useViewFieldsLoadable,

    useVariablesForTypeaheadLoadable,
    useFieldsForTypeaheadLoadable,
    useSuggestedPromptsLoadable,
    useGenerateSqlFromPromptExecutable,
    useUpdateFieldsOrderExecutable,
    useUpdateFieldVisibilityExecutable,
    usePromptHistoriesLoadable,

    useDownloadCsvPreviewRecordsLoadable,
    useDownloadCsvExecutable,
  ]);

  return contextValue;
};

export const [
  PlaygroundTableViewPropsProvider,
  useUseViewRecordsLoadable,
  useUseViewFieldsLoadable,

  useUseVariablesForTypeaheadLoadable,
  useUseFieldsForTypeaheadLoadable,
  useUseSuggestedPromptsLoadable,
  useUseGenerateSqlFromPromptExecutable,
  useUseUpdateFieldsOrderExecutable,
  useUseUpdateFieldVisibilityExecutable,
  useUsePromptHistoriesLoadable,

  useUseDownloadCsvPreviewRecordsLoadable,
  useUseDownloadCsvExecutable,
] = constate(
  usePlaygroundTableViewPropsContext,
  (value) => value.useViewRecordsLoadable,
  (value) => value.useViewFieldsLoadable,

  (value) => value.useVariablesForTypeaheadLoadable,
  (value) => value.useFieldsForTypeaheadLoadable,
  (value) => value.useSuggestedPromptsLoadable,
  (value) => value.useGenerateSqlFromPromptExecutable,
  (value) => value.useUpdateFieldsOrderExecutable,
  (value) => value.useUpdateFieldVisibilityExecutable,
  (value) => value.usePromptHistoriesLoadable,

  (value) => value.useDownloadCsvPreviewRecordsLoadable,
  (value) => value.useDownloadCsvExecutable
);
