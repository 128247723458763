export const ElementClassNamesForOnboarding = {
  sourceToolbar: {
    //TableSelect.tsx でmapを回している
    //SidebarTableLinkItem.tsx で、上記のArray.map のindexNum=0 のところで入れている
    createSourceButton: {
      classNameText: "source-toolbar_create-source-button",
      indexRow: 0,
    },
  },
  TableEdit: {
    //RecordsTableRows.tsx > RecordsTableRow.tsxにて設定
    //index === 2 && virtualItem.index === 3 (左から３、上から4つめ)
    selectCell: {
      classNameText: "table-edit_select-cell",
      indexRow: 2,
      indexCulumn: 3,
    },

    //RecordsTableHeader.tsx で index===3 でハードコーディングしている
    selectHeader: {
      classNameText: "table-edit_select-header",
      indexRow: 0,
      indexCulumn: 3,
    },
  },
  //SourceToolbar.tsxに設置
  databaseBaseTopBar: {
    createNoteBookButton: {
      classNameText: "database-base-topBar_create-notebook-button",
    },
  },

  notebookToolBar: {
    //NotebookSelect.tsxで index===0 でハードコーディングしている

    initialNotebook: {
      classNameText: "notebook-toolbar_initial-notebook",
      indexRow: 0,
    },
  },
};
