import { FieldTypeClientModelFactory } from "~/clientModel/fields/field/fieldType";
import { FieldTypeSubSelect } from "./common/FieldTypeSubSelectBase";

const dateFieldTypes = [
  FieldTypeClientModelFactory.createEmpty("date"),
  FieldTypeClientModelFactory.createEmpty("datetime"),
  FieldTypeClientModelFactory.createEmpty("time"),
] as const;

type DateFieldType = (typeof dateFieldTypes)[number];

type DateFieldsTypeSubSelectProps = {
  onSelect: (fieldType: DateFieldType) => void;
  options?:
    | {
        includes: DateFieldType["type"][];
      }
    | {
        excludes: DateFieldType["type"][];
      }; // 指定がなければ全部
};

export const DateFieldsTypeSubSelect = (
  props: DateFieldsTypeSubSelectProps
) => {
  const { onSelect, options } = props;

  return (
    <FieldTypeSubSelect
      triggerContent="Date"
      onSelect={onSelect}
      fieldTypeOptions={dateFieldTypes}
      options={options}
    />
  );
};
