import { useQuery } from "react-query";
import { useComposeLoadable } from "~/clientModel/loadable";
import { ListTemplateClientModelFactory } from "~/clientModel/template/ListTemplateClientModelFactory";
import { useListTemplateQuery } from "~/serverStateStore/template";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";


export const useListTemplate = () => {
  const teamSlug = useTeamSlug();

  const listTemplateQueryOptions = useListTemplateQuery({ teamSlug })
  const listTemplateQueryResult = useQuery({
    ...listTemplateQueryOptions,
    select: (data) => {
      return ListTemplateClientModelFactory.fromListTemplateResponse(data)
    }
  });

  const listTemplateLoadable = useComposeLoadable(listTemplateQueryResult)

  return {
    listTemplateLoadable,
  }
}
