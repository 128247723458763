
import { DashboardTemplateObject, DashboardTemplateListResponse } from "@usemorph/morph-dashboard-types";


export class ListTemplateClientModel {
  constructor(private readonly data: DashboardTemplateListResponse) { }

  /**
   * Getters
   */
  get count(): number {
    return this.data.count;
  }

  get templates(): DashboardTemplateObject[] {
    return this.data.templates;
  }

}
