import { TimezoneClientModel } from "~/clientModel/timezone";
import { CreatingComputedFieldSyncScheduleClientModel } from "./ComputedFieldSyncScheduleClientModel";
import { ScheduledTimeClientModelFactory } from "../../../scheduleCore/ScheduledTime";
import { TargetDaysClientModelFactory } from "../../../scheduleCore/targetDays";

export class CreatingComputedFieldSyncScheduleClientModelFactory {
  public static createInitialCreatingComputedFieldSyncSchedule({
    userTimezone,
  }: {
    userTimezone: TimezoneClientModel;
  }): CreatingComputedFieldSyncScheduleClientModel {
    return new CreatingComputedFieldSyncScheduleClientModel({
      id: null,
      targetDays: TargetDaysClientModelFactory.createInitialTargetDays(),
      scheduledTime: ScheduledTimeClientModelFactory.createNow(),
      isEnabled: true,
      timezone: userTimezone,
    });
  }
}
