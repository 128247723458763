import { useParams } from "react-router-dom";

const useNotebookId = () => {
  const { notebookId } = useParams();

  if (!notebookId) {
    throw new Error(`notebookId not found.`);
  }

  return notebookId;
};

export { useNotebookId };
