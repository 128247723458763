import { useQuery } from "react-query";
import { CsvDownloadFieldsClientModel } from "~/clientModel/csvDownload/csvDownloadFields";
import { CsvDownloadFieldsClientModelDefactory } from "~/clientModel/csvDownload/csvDownloadFields/CsvDownloadFieldsClientModelDefactory";
import { useComposeLoadable } from "~/clientModel/loadable";
import { RecordsClientModelFactory } from "~/clientModel/records";
import { useQueryViewRecordsQuery } from "~/serverStateStore/views";

const useDownloadCsvPreviewRecordsLoadable = ({
  teamSlug,
  databaseId,
  viewId,
  csvDownloadFields,
}: {
  teamSlug: string;
  databaseId: string;
  viewId: string;
  csvDownloadFields: CsvDownloadFieldsClientModel;
}) => {
  return useComposeLoadable(
    useQuery({
      ...useQueryViewRecordsQuery({
        teamSlug,
        databaseId,
        viewId,
        select: ["*"],
        format:
          CsvDownloadFieldsClientModelDefactory.toRecordFormatConditions(
            csvDownloadFields
          ),
      }),
      select: (data) => {
        return RecordsClientModelFactory.createFromQueryRecordListResponseWithFields(
          data
        );
      },
    })
  );
};

export const createuseDownloadCsvPreviewRecordsLoadable = ({
  teamSlug,
  databaseId,
}: {
  teamSlug: string;
  databaseId: string;
}) => {
  return ({
    viewId,
    csvDownloadFields,
  }: {
    viewId: string;
    csvDownloadFields: CsvDownloadFieldsClientModel;
  }) => {
    return useDownloadCsvPreviewRecordsLoadable({
      teamSlug,
      databaseId,
      viewId,
      csvDownloadFields,
    });
  };
};
