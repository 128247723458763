import { useQuery } from "react-query";
import { Loadable, useComposeLoadable } from "~/clientModel/loadable";

import {
  RecordHistoriesClientModel,
  RecordHistoriesClientModelFactory,
} from "~/clientModel/recordHistories";
import {
  RecordIdentifierClientModel,
  RecordIdentifierClientModelDefactory,
} from "~/clientModel/records/record/recordIdentifier";

import { useListRecordHistoriesQuery } from "~/serverStateStore/recordHistories/queries/useListRecordHistoriesQuery";

type useRecordHistoriesLoadableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  recordIdentifier: RecordIdentifierClientModel;
};

const useRecordHistoriesLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
  recordIdentifier,
}: useRecordHistoriesLoadableParams): Loadable<
  RecordHistoriesClientModel,
  unknown
> => {
  return useComposeLoadable(
    useQuery({
      ...useListRecordHistoriesQuery({
        teamSlug,
        databaseId,
        tableSlug,
        data: RecordIdentifierClientModelDefactory.toRecord(recordIdentifier),
      }),
      select: (date) => {
        return RecordHistoriesClientModelFactory.createFromDashboardRecordHistoryListResponse(
          date
        );
      },
    })
  );
};

export const createUseRecordHistoriesLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
}) => {
  return ({
    recordIdentifier,
  }: {
    recordIdentifier: RecordIdentifierClientModel;
  }) =>
    useRecordHistoriesLoadable({
      teamSlug,
      databaseId,
      tableSlug,
      recordIdentifier,
    });
};
