import { FieldClientModel } from "~/clientModel/fields/field";
import { Badge } from "~/components_next/Badge";
import { Flex } from "~/components_next/Flex";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";

type FieldInfoProps = {
  field: FieldClientModel;
};

const FieldInfo = (props: FieldInfoProps) => {
  const { field } = props;

  return (
    <Flex direction="row" gap="2">
      <Text>{field.label}</Text>
      <Spacer />
      {field.isPrimary && (
        <Badge variant="primary" size="xs">
          Primary
        </Badge>
      )}
      {!field.isNullable && (
        <Badge size="xs" variant="primary">
          Required
        </Badge>
      )}
      <Badge size="xs" variant="tertiary">
        {field.type.fieldTypeLabel}
      </Badge>
    </Flex>
  );
};

export { FieldInfo };
