import { viewKeys } from "../keys";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import useApiView from "~/api/useApiView";

type UseDeleteViewFieldMutationParams = {
  teamSlug: string;
  databaseId: string;
  viewId: string;
};

// create view fieldで作成したtype = formulaのみ更新可能
const useDeleteViewFieldMutation = ({
  teamSlug,
  databaseId,
  viewId,
}: UseDeleteViewFieldMutationParams) => {
  const { _deleteViewField } = useApiView();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({ fieldName }: { fieldName: string }) => {
      return _deleteViewField({
        teamSlug,
        viewId,
        fieldName,
      });
    },
    onSuccess: () => {
      const keys = viewKeys.all({ databaseId, teamSlug });
      broadcast(keys);
      return invalidateAll(client, keys);
    },
  };
};

export { useDeleteViewFieldMutation };
