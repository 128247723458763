import { dataApiKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiDataApi from "~/api/useApiDataApi";

type UseGetDataApiApisQueryParams = {
  databaseId: string;
  teamSlug: string;
  tableSlug?: string | undefined;
  limit?: number | undefined;
  skip?: number | undefined;
};

const useGetDataApisQuery = ({
  teamSlug,
  databaseId,
  tableSlug,
  limit,
  skip,
}: UseGetDataApiApisQueryParams) => {
  const { _listDataApi } = useApiDataApi();

  const client = useQueryClient();
  client.setQueryDefaults(
    dataApiKeys.getDataApis({
      teamSlug,
      databaseId,
      tableSlug,
    }),
    commonQueryOptions
  );

  return {
    queryKey: dataApiKeys.getDataApis({
      teamSlug,
      databaseId,
      tableSlug,
      limit,
      skip,
    }),
    queryFn: () => {
      return _listDataApi({
        teamSlug,
        databaseId,
        tableSlug,
        limit,
        skip,
      });
    },
  };
};

export { useGetDataApisQuery };
