import { useState } from "react";
import { LuEye } from "react-icons/lu";
import { CsvImportSchemaEditClientModel } from "~/clientModel/csvImport/CsvImportSchemaEditClientModel";
import { FieldClientModel } from "~/clientModel/fields/field";
import { useLoadableState } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { TableNameClientModel } from "~/clientModel/tables/tableName";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { Dialog } from "~/components_next/Dialog";
import { Divider } from "~/components_next/Divider";
import { DropdownMenu } from "~/components_next/DropdownMenu";
import { Flex } from "~/components_next/Flex";
import { RecordsTable } from "~/components_next/RecordsTable";
import { Spacer } from "~/components_next/Spacer";
import {
  TableNameInput,
  TableNameInputProps,
} from "~/components_next/TableNameInput/TableNameInput";
import { Text } from "~/components_next/Text";
import { ImportSchemaEditFieldForm } from "~/presenters/fields/form/usecases/importSchema/ImportSchemaEditFieldForm";

type CsvImportSchemaEditorProps = {
  importSchema: CsvImportSchemaEditClientModel | null;
  checkTableSlugExecutable: TableNameInputProps["checkTableSlugExecutable"];
  onImportSchemaChange: (importSchema: CsvImportSchemaEditClientModel) => void;
  tableName: TableNameClientModel;
  onTableNameChange: (tableName: TableNameClientModel) => void;
};

const CsvImportSchemaEditor = (props: CsvImportSchemaEditorProps) => {
  const {
    importSchema,
    checkTableSlugExecutable,
    onImportSchemaChange,
    tableName,
    onTableNameChange,
  } = props;

  /**
   * Local state
   */
  const [editingFieldLoadable, setEditingField] =
    useLoadableState<FieldClientModel | null>(null);

  const [editingFieldIndex, setEditingFieldIndex] = useState<number | null>(
    null
  );

  const handleEditFieldDialogClose = () => {
    if (
      importSchema &&
      editingFieldLoadable.data &&
      editingFieldIndex !== null
    ) {
      const updatedSchema = importSchema.updateFieldWithIndex(
        editingFieldLoadable.data,
        editingFieldIndex
      );
      onImportSchemaChange(updatedSchema);
    }

    setEditingField(null);
    setEditingFieldIndex(null);
  };

  const handleRemoveFiled = (field: FieldClientModel) => {
    if (importSchema) {
      onImportSchemaChange(importSchema.removeField(field));
    }
  };

  const handleRevertField = (field: FieldClientModel) => {
    if (importSchema) {
      onImportSchemaChange(importSchema.revertField(field));
    }
  };

  return (
    <>
      <Box p="4">
        <Box
          css={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "1fr 1fr",
            ":nth-child(1)": {
              gridArea: `1 / 1 / 2 / 2`,
            },
            ":nth-child(2)": {
              gridArea: `1 / 2 / 2 / 3`,
            },
            ":nth-child(3)": {
              gridArea: `2 / 1 / 3 / 3`,
            },
            gap: "12px",
          }}
          mb="4"
        >
          <TableNameInput
            tableNameInstance={tableName}
            onUpdateTableNameInstance={onTableNameChange}
            checkTableSlugExecutable={checkTableSlugExecutable}
          />
        </Box>
        {importSchema && (
          <Flex direction="column" gap="4">
            {importSchema.removedFields.length > 0 && (
              <Flex direction="column" gap="2">
                <Text variant="subheading">Excluded Fields</Text>
                <Flex align="center" gap="4" wrap="wrap">
                  {importSchema.removedFields.map((field) => {
                    return (
                      <Button
                        key={field.name}
                        variant="tertiary"
                        size="xs"
                        onClick={() => {
                          handleRevertField(field);
                        }}
                      >
                        <Flex gap="3" align="center">
                          <Text>{field.displayName}</Text>
                          <LuEye />
                        </Flex>
                      </Button>
                    );
                  })}
                </Flex>
              </Flex>
            )}
            <RecordsTable
              key={importSchema.fields.allFields.map((f) => f.name).join(",")}
              records={importSchema.records}
              fields={importSchema.fields}
              noPadding
              isReadOnly
              headerDropdown={(field, index) => {
                return (
                  <>
                    <Flex
                      direction="column"
                      gap="2"
                      css={{ width: "240px" }}
                      p="1"
                    >
                      <Flex align="center" gap="4" px="1">
                        <Text variant="description">Display Name</Text>
                        <Spacer />
                        <Text fontWeight="medium">{field.displayName}</Text>
                      </Flex>
                      <Flex align="center" gap="4" px="1">
                        <Text variant="description">Field Slug</Text>
                        <Spacer />
                        <Text fontWeight="medium">{field.name}</Text>
                      </Flex>
                      <Flex align="center" gap="4" px="1">
                        <Text variant="description">Type</Text>
                        <Spacer />
                        <Text fontWeight="medium">
                          {field.type.fieldTypeLabel}
                        </Text>
                      </Flex>
                      <Divider />
                    </Flex>
                    <DropdownMenu.Item
                      onClick={() => {
                        setEditingField(field);
                        setEditingFieldIndex(index);
                        console.log(index);
                      }}
                    >
                      Edit field
                    </DropdownMenu.Item>
                    <DropdownMenu.Item onClick={() => handleRemoveFiled(field)}>
                      Remove field
                    </DropdownMenu.Item>
                  </>
                );
              }}
            />
          </Flex>
        )}
      </Box>

      {/* Edit Field */}
      <Dialog.Root
        open={!!editingFieldLoadable.data}
        onOpenChange={() => handleEditFieldDialogClose()}
        size="sm"
      >
        <Dialog.Content>
          <WithFallback loadables={[editingFieldLoadable]}>
            {([editingField]) => {
              if (!editingField) {
                return null;
              }

              return (
                <ImportSchemaEditFieldForm
                  field={editingField}
                  onFieldChange={(field) => setEditingField(field)}
                />
              );
            }}
          </WithFallback>
        </Dialog.Content>
      </Dialog.Root>
      {/* <NativeDialog.Root
        open={!!editingFieldLoadable.data}
        onOpenChange={() => handleEditFieldDialogClose()}
        size="sm"
      >
        <NativeDialog.Content>
          <WithFallback loadables={[editingFieldLoadable]}>
            {([editingField]) => {
              if (!editingField) {
                return null;
              }

              return (
                <ImportSchemaEditFieldForm
                  field={editingField}
                  onFieldChange={(field) => setEditingField(field)}
                />
              );
            }}
          </WithFallback>
        </NativeDialog.Content>
      </NativeDialog.Root> */}
    </>
  );
};

export { CsvImportSchemaEditor, type CsvImportSchemaEditorProps };
