import {
  CanvasCellPromptClientModel,
  CanvasCellViewClientModel,
} from "~/clientModel/canvas/CanvasCellClientModel";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { CanvasVariablesValue } from "~/presenters/canvas/common/CanvasVariablesProvider";
import { useUseViewFieldsLoadable } from "../../providers/PlaygroundUseSourceFIeldsLoadableProvider";
import { PlaygroundPromptForm } from "../../textEditor/PlaygroundPromptForm";

type CutomPromptInputProps = {
  editingCell: CanvasCellPromptClientModel;
  parentCell: CanvasCellViewClientModel;
  variables?: CanvasVariablesValue[];
  onUpdateValue: (value: string) => void;
};

const CustomPromptInput = (props: CutomPromptInputProps) => {
  const { editingCell, parentCell, variables, onUpdateValue } = props;

  const useViewFieldsLoadable = useUseViewFieldsLoadable();
  const viewFieldsLoadable = useViewFieldsLoadable({
    viewId: parentCell.viewId || "",
  });

  const handleUpdateValue = (value: string) => {
    onUpdateValue(value);
  };

  return (
    <WithFallback loadables={[viewFieldsLoadable]}>
      {([viewFields]) => {
        return (
          <PlaygroundPromptForm
            variant="textarea"
            value={editingCell.prompt}
            onChange={handleUpdateValue}
            variablesForTypeahead={variables}
            fieldsForTypeahead={viewFields}
          />
        );
      }}
    </WithFallback>
  );
};

export { CustomPromptInput };
