import { FieldClientModel } from "~/clientModel/fields/field";
import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { StringValidator } from "./CommonValueValidator";
import {
  FilterOperationClientModelBase,
  FilterOperationWithValueInterface,
} from "./FilterOperationClientModelBase";

type NotEqualFilterOperationClientModelData = {
  value: string;
};

export class NotEqualFilterOperationClientModel
  extends FilterOperationClientModelBase
  implements
    FilterOperationWithValueInterface<
      string,
      NotEqualFilterOperationClientModel
    >
{
  readonly #data: NotEqualFilterOperationClientModelData;

  public constructor(data: NotEqualFilterOperationClientModelData) {
    super();
    this.#data = data;
  }

  public get data() {
    return this.#data;
  }

  public get type(): "notEqual" {
    return "notEqual";
  }

  public get operatorValue(): "notEqual" {
    return "notEqual";
  }

  public get operatorLabel(): "Not equals" {
    return "Not equals";
  }

  public get operandValue(): string {
    return this.#data.value;
  }

  public updateValue(value: string): NotEqualFilterOperationClientModel {
    return new NotEqualFilterOperationClientModel({
      ...this.#data,
      value,
    });
  }

  public validate(targetField: FieldClientModel): ValidationResult {
    return new StringValidator(this.#data.value)
      .shouldNotBeEmpty()
      .shouldBeIntStringIfNumberType(targetField)
      .end();
  }
}
