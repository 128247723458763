import { Code } from "~/components_next/Code";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { Text } from "~/components_next/Text";
import useClipboard from "react-use-clipboard";
import { Input } from "~/components_next/Input";
import { Flex } from "~/components_next/Flex";
import { AiOutlineCheck, AiOutlineCopy } from "react-icons/ai";
import { IconButton } from "~/components_next/IconButton";
import { Box } from "~/components_next/Box";

export const UrlForm = ({ url }: { url: string }) => {
  const [isCopied, setCopied] = useClipboard(url);

  return (
    <>
      <InputStack>
        <Text fontWeight="medium">URL</Text>
        <Flex align="center">
          <Code color={"blue"} mr="3">
            POST
          </Code>
          <Input variant="primary" readOnly={true} value={`${url}`} />
          <Box ml="1">
            <IconButton
              variant="secondary"
              tooltip="Copy"
              size="sm"
              icon={isCopied ? <AiOutlineCheck /> : <AiOutlineCopy />}
              onClick={() => {
                setCopied();
              }}
            />
          </Box>
        </Flex>
      </InputStack>
    </>
  );
};
