import { SmartFieldDateDisplaySetting } from "@usemorph/morph-dashboard-types";
import { DateDisplaySettingClientModel } from "./DateDisplaySettingClientModel";

export class DateDisplaySettingClientModelFactory {
  public static createFromSmartFieldDateDisplaySetting(
    smartFieldDateDisplaySetting: SmartFieldDateDisplaySetting
  ): DateDisplaySettingClientModel {
    return new DateDisplaySettingClientModel({
      parts: smartFieldDateDisplaySetting.parts,
      dateFormat: smartFieldDateDisplaySetting.dateFormat,
      timeFormat: smartFieldDateDisplaySetting.timeFormat,
    });
  }

  public static createEmpty(): DateDisplaySettingClientModel {
    return new DateDisplaySettingClientModel({
      parts: "dateTime",
      dateFormat: "medium",
      timeFormat: "withSeconds",
    });
  }
}
