import { useMemo } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Text } from "~/components_next/Text";
import { useGetPublicCellQuery } from "~/serverStateStore";

const PublishedCellContainer = () => {
  const { teamSlug, publicApiKey } = useParams();

  const { data, isLoading } = useQuery(
    useGetPublicCellQuery({
      teamSlug: teamSlug || "",
      apiKey: publicApiKey || "",
    })
  );

  const sourceUrl = useMemo(() => {
    const blob = new Blob(
      [
        `<html>
          <head>
            <link
              rel="stylesheet"
              href="https://cdn.jsdelivr.net/npm/destyle.css@1.0.15/destyle.css"
            />
          </head>
          <body>${data?.html}</body>
        </html>`,
      ],
      { type: "text/html" }
    );

    return URL.createObjectURL(blob);
  }, [data?.html]); // isSelectedがガンガン変わるのでmemo化

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (!data) {
    return <Text>Cell not found</Text>;
  }

  return <iframe width="100%" height="100%" src={sourceUrl} />;
};

export { PublishedCellContainer };
