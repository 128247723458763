import {
  DashboardViewConditionObject,
  RecordFilterConditionAndType,
  RecordFilterConditionOrType,
  RecordSortConditionUnit,
} from "@usemorph/morph-dashboard-types";
import { useEffect } from "react";
import { match, P } from "ts-pattern";
import { ViewSetting } from "~/features/View";
import { sortObjectUtils } from "~/utils/sortObjectUtils";
import { useTableView } from "../../hooks/useProps";
import { useFilter, useSort } from "../../states/queryConditions";
import { useQueryMode } from "../../states/queryMode";
import { useQueryingSql, usePromptInput } from "../../states/sqlConditions";
import {
  useViewSetting,
  useViewSettingChanged,
} from "../../states/viewSetting";

export type UseViewConditionChangeProps = {
  condition: DashboardViewConditionObject;
  viewSetting: ViewSetting;
  onConditionChange: (payload: {
    condition?: DashboardViewConditionObject;
    settings?: ViewSetting;
  }) => void;
};

export const useViewConditionChange = (props: UseViewConditionChangeProps) => {
  const { condition, onConditionChange } = props;

  const filter = useFilter();
  const sort = useSort();
  const queryingSql = useQueryingSql();
  const queryMode = useQueryMode();
  const promptInput = usePromptInput();

  const view = useTableView();
  const { condition: currentViewCondition, viewId } = view;

  const viewSetting = useViewSetting();
  const isViewSettingChanged = useViewSettingChanged();

  const handleUpdateViewCondition = async () => {
    const prompt =
      queryMode === "prompt" ? { data: { prompt: promptInput } } : {};

    const setting: ViewSetting | undefined =
      isViewSettingChanged || queryMode === "prompt"
        ? {
            ...viewSetting,
            ...prompt,
          }
        : undefined;

    if (!queryMode) {
      onConditionChange({
        condition: undefined,
        settings: setting,
      });
      return;
    }

    const updatedCondition: DashboardViewConditionObject = match(queryMode)
      .with("filtersAndSorts", () => ({
        ...currentViewCondition,
        filter: filter ?? undefined,
        sort: sort ? [sort] : undefined,
      }))
      .with(P.union("sql", "prompt"), () => ({
        ...currentViewCondition,
        join: undefined, // generate sqlでcondition送ってるので、SQLで必ずjoin句が保管されているはず。重複するので消す。
        from: queryingSql,
      }))
      .exhaustive();

    onConditionChange({
      condition: updatedCondition,
      settings: setting,
    });
  };

  useEffect(() => {
    const normalize = ({
      filter,
      sort,
      from,
    }: {
      filter:
        | RecordFilterConditionAndType
        | RecordFilterConditionOrType
        | null
        | undefined;
      sort: RecordSortConditionUnit[] | null | undefined;
      from: string | null | undefined;
    }) =>
      JSON.stringify({
        filter: filter ?? null,
        sort: sort && sort.length > 0 ? sort : null,
        from: from || null,
      });

    // todo: プロンプトの比較
    const currentQueryCondition = normalize({
      filter,
      sort: sort ? [sort] : null,
      from: queryingSql || currentViewCondition.from,
    });

    const comparedViewCondition = normalize({
      filter: currentViewCondition.filter,
      sort: sortObjectUtils.getRecordSortConditionUnits(
        currentViewCondition.sort
      ), // 一時的にembeddingsを無視
      from: currentViewCondition.from,
    });

    const isSame = currentQueryCondition === comparedViewCondition;

    if (!isSame || isViewSettingChanged) {
      handleUpdateViewCondition();
    }
  }, [filter, sort, queryingSql, currentViewCondition, viewSetting]);
};
