import { notebookKeys } from "../keys";
import useApiNotebook from "~/api/useApiNotebook";
import { useQueryClient } from "react-query";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import { DashboardNotebookCellSourceObject } from "@usemorph/morph-dashboard-types";

interface UseUpdateNotebookCellMutationParams {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
}

const useUpdateNotebookCellMutaiton = ({
  databaseId,
  teamSlug,
  notebookId,
}: UseUpdateNotebookCellMutationParams) => {
  const { _updateNotebookCell } = useApiNotebook();

  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      cellId,
      cellType,
      source,
      isPublic,
      andRun,
      shouldGenerate = true,
    }: {
      cellId: string;
      cellType: string;
      source: DashboardNotebookCellSourceObject;
      isPublic: boolean;
      andRun?: boolean;
      shouldGenerate?: boolean;
    }) => {
      return _updateNotebookCell({
        teamSlug,
        notebookId,
        cellId,
        cellType,
        source,
        isPublic,
        andRun,
        shouldGenerate,
      });
    },
    onSuccess: () => {
      const findNotebookKeys = notebookKeys.findNotebook({
        databaseId,
        teamSlug,
        notebookId,
      });
      broadcast(findNotebookKeys);
      return invalidateAll(client, findNotebookKeys);
    },
  };
};

export { useUpdateNotebookCellMutaiton };
