import { useEditorOrThrow } from "../utils";

const useClearFormat = () => {
  const editor = useEditorOrThrow();

  const handleClear = () => {
    editor.chain().focus().unsetAllMarks().unsetColor().run();
  };

  const isDisabled = !editor
    .can()
    .chain()
    .focus()
    .unsetColor()
    .unsetAllMarks()
    .run();

  return {
    handleClear,
    isDisabled,
  };
};

export { useClearFormat };
