import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { match, P } from "ts-pattern";
import { Flex } from "~/components_next/Flex";
import {
  useTeam,
  useFindNotebookQuery,
  useUpdateNotebookMutaiton,
} from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useNotebookId } from "~/utilHooks/useNotebookId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { BelongedResourceMembers } from "../components/Share/BelongedResourceMembers";
import { GeneralAccessAuthorityCard } from "../components/Share/GeneralAccessAuthorityCard";
import { InvitationInput } from "../components/Share/InvitationInput";
import { AccessAuthority } from "../components/Share/types";

/**
 * Root & General Access
 */
const NotebookToolbarShare = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const notebookId = useNotebookId();

  const { data: notebook } = useQuery(
    useFindNotebookQuery({
      teamSlug,
      databaseId,
      notebookId,
    })
  );

  const { data: team } = useTeam({ teamSlug });

  /**
   * General Access
   */
  const {
    mutateAsync: updateNotebook,
    // isLoading: isUpdatingNotebook,
  } = useMutation({
    ...useUpdateNotebookMutaiton({
      teamSlug,
      databaseId,
      notebookId,
    }),
  });

  const generalAccess = useMemo((): AccessAuthority => {
    if (!notebook) return "No Access";
    if (notebook.isPrivate) return "No Access";
    return "Read & Write";
  }, [notebook]);
  const handleGeneralAccessChange = (authority: AccessAuthority) => {
    if (!notebook) return;

    match([authority, generalAccess])
      .with(["Read & Write", P.not("Read & Write")], () => {
        updateNotebook({
          isPrivate: false,
          notebookName: notebook.notebookName || "",
          description: notebook.description ?? undefined,
        });
      })
      .with(["No Access", P.not("No Access")], () => {
        updateNotebook({
          isPrivate: true,
          notebookName: notebook.notebookName || "",
          description: notebook.description ?? undefined,
        });
      })
      .otherwise(() => {
        return;
      });
  };

  return (
    <>
      <Flex direction="column" align="stretch">
        {generalAccess !== "Read & Write" && (
          <>
            <InvitationInput
              resource={{
                notebookId,
                resourceType: "notebook",
              }}
            />
          </>
        )}
        {/* General */}
        <GeneralAccessAuthorityCard
          team={team}
          generalAccess={generalAccess}
          onGeneralAccessChange={handleGeneralAccessChange}
        />
        {/* Members */}
        {notebook?.isPrivate && (
          <BelongedResourceMembers
            resource={{
              notebookId,
              resourceType: "notebook",
            }}
          />
        )}
      </Flex>
    </>
  );
};

export { NotebookToolbarShare };
