import { match, P } from "ts-pattern";
import { UserMention } from "../User";
import { Text } from "~/components_next/Text";
import { Badge } from "~/components_next/Badge";
import { Image } from "~/components_next/Image";
import { Checkbox } from "~/components_next/Checkbox";
import { RecordEntryClientModel } from "~/clientModel/records/record/recordEntry";
import { FieldClientModel } from "~/clientModel/fields/field";
import { FormulaRecordEntryValueType } from "~/clientModel/records/record/recordEntry/computed/FormulaRecordEntryClientModel";
import { FindUserClientModel } from "~/clientModel/user";
import { SyncValueRecordEntryValueType } from "~/clientModel/records/record/recordEntry/computed/SyncValueRecordEntryClientModel";
import { SmartFunctionRecordEntryValueType } from "~/clientModel/records/record/recordEntry/computed/SmartFunctionRecordEntryClientModel";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { Link } from "@radix-ui/themes";
import { Spinner } from "../Spinner";
import { Code } from "../Code";
import { Popover } from "../Popover";
import { IconButton } from "../IconButton";
import { LuInfo } from "react-icons/lu";
import { Table } from "../Table";
import { BsArrowClockwise } from "react-icons/bs";

const LoadingContent = () => {
  return (
    <Flex align="center" justify="center">
      <Spinner size="xs" />
    </Flex>
  );
};

const EmptyContent = () => {
  return <span></span>;
};

const TypeConversionErrorContent = (props: {
  errorMessage: string;
  previousValue: unknown;
}) => {
  const { errorMessage, previousValue } = props;

  return (
    <Flex direction="row" align="center" gap="1">
      <Popover
        trigger={
          <IconButton
            icon={<LuInfo color="gray" size="14" />}
            tooltip="Error detail"
            onClick={(e) => e.stopPropagation()}
          />
        }
      >
        <Table.Root size="xs">
          <Table.Body>
            <Table.Row>
              <Table.RowHeaderCell>
                <Text variant="description"> Previous Value: </Text>
              </Table.RowHeaderCell>
              <Table.Cell>
                <Code size="2">{String(previousValue)}</Code>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table.Root>
      </Popover>
      <Text
        variant="errorMessage"
        style={{
          textTransform: "none",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {errorMessage}
      </Text>
    </Flex>
  );
};

const CreditShortageErrorContent = () => {
  return (
    <Text
      variant="errorMessage"
      style={{
        textTransform: "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      Failed to update because of credits shortage
    </Text>
  );
};

const SmartFieldExecutionErrorContent = ({
  onRetry,
}: {
  onRetry?: () => void;
}) => {
  return (
    <Flex direction="row" align="center" gap="2">
      {onRetry && (
        <IconButton
          tooltip="Retry"
          icon={<BsArrowClockwise color="gray" size={12} />}
          onClick={(e) => {
            e.stopPropagation();
            onRetry();
          }}
        />
      )}

      <Text
        variant="errorMessage"
        style={{
          textTransform: "none",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        Failed to update
      </Text>
    </Flex>
  );
};

const InvalidTypeContent = (props: { value: unknown }) => {
  const { value } = props;
  return (
    <Text
      variant="errorMessage"
      style={{
        textTransform: "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      {String(value)}
    </Text>
  );
};

const TextContent = (props: { value: string | null | undefined }) => {
  const { value } = props;

  if (typeof value !== "string") {
    return <EmptyContent />;
  }

  return (
    <Text
      style={{
        textTransform: "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      {value}
    </Text>
  );
};

const NumberContent = (props: { value: string | null | undefined }) => {
  const { value } = props;

  if (typeof value !== "string") {
    return <EmptyContent />;
  }

  return (
    <Text
      style={{
        textTransform: "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      {value}
    </Text>
  );
};

const SingleSelectContent = (props: { value: string | null | undefined }) => {
  const { value } = props;

  if (typeof value !== "string") {
    return <EmptyContent />;
  }

  return (
    <Box
      style={{
        overflow: "hidden",
      }}
    >
      <Badge size="md" variant="tertiary" key={value}>
        {value}
      </Badge>
    </Box>
  );
};

const MultiSelectContent = (props: { value: string[] | null | undefined }) => {
  const { value } = props;

  if (!Array.isArray(value)) {
    return <EmptyContent />;
  }

  return (
    <Flex
      gap="1"
      style={{
        overflow: "hidden",
      }}
    >
      {value.map((value) => (
        <Badge size="md" variant="tertiary" key={value}>
          {value}
        </Badge>
      ))}
    </Flex>
  );
};

const BooleanContent = (props: { value: boolean | null | undefined }) => {
  const { value } = props;

  if (typeof value !== "boolean") {
    return <EmptyContent />;
  }

  return (
    <Flex direction="column" justify="center" align="center">
      <Checkbox value={value} size="lg" isReadOnly />
    </Flex>
  );
};

const ImageContent = (props: {
  value: { data: string; url: string } | null | undefined;
}) => {
  const { value } = props;

  if (!value) {
    return <EmptyContent />;
  }

  return (
    <Image height="30px" src={value.url} style={{ objectFit: "contain" }} />
  );
};

const AttachmentContent = (props: {
  value: { data: string; url: string } | null | undefined;
}) => {
  const { value } = props;

  if (!value) {
    return <EmptyContent />;
  }

  const attachmentName = value.url.split("/").pop();

  return (
    <Box
      style={{
        textTransform: "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      <Badge variant="secondary">{attachmentName}</Badge>
    </Box>
  );
};

const UserContent = (props: {
  value: FindUserClientModel | null | undefined;
}) => {
  const { value } = props;

  if (!value) {
    return <EmptyContent />;
  }

  return <UserMention user={value} />;
};

const HTMLContent = (props: { value: string | null | undefined }) => {
  const { value } = props;

  if (typeof value !== "string") {
    return <EmptyContent />;
  }

  return (
    <Text
      variant="description"
      style={{
        fontFamily: "monospace",
        textTransform: "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      {value}
    </Text>
  );
};

const JSONContent = (props: { value: string | null | undefined }) => {
  const { value } = props;

  if (typeof value !== "string") {
    return <EmptyContent />;
  }

  return (
    <Text
      style={{
        fontFamily: "monospace",
        textTransform: "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      {value}
    </Text>
  );
};

const ArrayContent = (props: { value: string | null | undefined }) => {
  const { value } = props;

  if (!value) {
    return <EmptyContent />;
  }

  return (
    <Text
      style={{
        fontFamily: "monospace",
        textTransform: "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      {value}
    </Text>
  );
};

const UrlContent = (props: { value: string | null | undefined }) => {
  const { value } = props;

  if (typeof value !== "string") {
    return <EmptyContent />;
  }

  return (
    <Link
      href={value}
      target="_blank"
      style={{
        textTransform: "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      {value}
    </Link>
  );
};

const FormulaContent = (props: { value: FormulaRecordEntryValueType }) => {
  const { value } = props;

  if (value == null) {
    return <EmptyContent />;
  }

  return (
    <Text
      style={{
        textTransform: "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      {String(value)}
    </Text>
  );
};

const SmartFunctionContent = (props: {
  value: SmartFunctionRecordEntryValueType;
}) => {
  const { value } = props;

  if (value == null) {
    return <EmptyContent />;
  }

  return (
    <Text
      style={{
        textTransform: "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      {String(value)}
    </Text>
  );
};

const SyncValueContent = (props: { value: SyncValueRecordEntryValueType }) => {
  const { value } = props;

  if (value == null) {
    return <EmptyContent />;
  }

  return (
    <Text
      style={{
        textTransform: "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    >
      {String(value)}
    </Text>
  );
};

type RecordValueDisplayProps = {
  recordEntry: RecordEntryClientModel;
  field: FieldClientModel;
  onRetrySmartField?: () => void;
  isLoading?: boolean;
};

const RecordValueDisplay = (props: RecordValueDisplayProps) => {
  const { recordEntry, field, isLoading, onRetrySmartField } = props;

  const recordEntryMeta = recordEntry.meta;

  if (isLoading) {
    return (
      <Flex direction="column" justify="center" width="100%" height="100%">
        <LoadingContent />
      </Flex>
    );
  }

  if (
    recordEntryMeta?.typeCastFailure.hasError &&
    recordEntry.rawValue === null
  ) {
    return (
      <Flex direction="column" justify="center" width="100%" height="100%">
        <TypeConversionErrorContent
          errorMessage={recordEntryMeta.typeCastFailure.errorMessage}
          previousValue={recordEntryMeta.typeCastFailure.originalValue}
        />
      </Flex>
    );
  }

  return (
    <Flex direction="column" justify="center" width="100%" height="100%">
      {match(recordEntry)
        .with(
          {
            type: P.union(
              "shortText",
              "longText",
              "email",
              "phoneNumber",
              "date",
              "datetime",
              "time",
              "lastEditedAt",
              "createdAt",
              "maskedValue"
            ),
          },

          (recordEntry) => {
            const validatedValue = recordEntry.getValidatedDisplayValue(field);
            return validatedValue.isValid ? (
              <TextContent value={validatedValue.value} />
            ) : (
              <InvalidTypeContent value={validatedValue.value} />
            );
          }
        )
        .with(
          {
            type: "url",
          },

          (recordEntry) => {
            const validatedValue = recordEntry.getValidatedDisplayValue(field);
            return validatedValue.isValid ? (
              <UrlContent value={validatedValue.value} />
            ) : (
              <InvalidTypeContent value={validatedValue.value} />
            );
          }
        )
        .with(
          {
            type: P.union(
              "number",
              "bigNumber",
              "autoNumber",
              "autoBigNumber",
              "decimal"
            ),
          },

          (recordEntry) => {
            const validatedValue = recordEntry.getValidatedDisplayValue(field);
            return validatedValue.isValid ? (
              <NumberContent value={validatedValue.value} />
            ) : (
              <InvalidTypeContent value={validatedValue.value} />
            );
          }
        )
        .with(
          { type: "boolean" },

          (recordEntry) => {
            const validatedValue = recordEntry.getValidatedDisplayValue(field);
            return validatedValue.isValid ? (
              <BooleanContent value={validatedValue.value} />
            ) : (
              <InvalidTypeContent value={validatedValue.value} />
            );
          }
        )
        .with(
          { type: "singleSelect" },

          (recordEntry) => {
            const validatedValue = recordEntry.getValidatedDisplayValue(field);
            return validatedValue.isValid ? (
              <SingleSelectContent value={validatedValue.value} />
            ) : (
              <InvalidTypeContent value={validatedValue.value} />
            );
          }
        )
        .with(
          { type: "multiSelect" },

          (recordEntry) => {
            const validatedValue = recordEntry.getValidatedDisplayValue(field);
            return validatedValue.isValid ? (
              <MultiSelectContent value={validatedValue.value} />
            ) : (
              <InvalidTypeContent value={validatedValue.value} />
            );
          }
        )
        .with(
          { type: "image" },

          (recordEntry) => {
            const validatedValue = recordEntry.getValidatedDisplayValue(field);
            return validatedValue.isValid ? (
              <ImageContent value={validatedValue.value} />
            ) : (
              <InvalidTypeContent value={validatedValue.value} />
            );
          }
        )
        .with(
          { type: "attachment" },

          (recordEntry) => {
            const validatedValue = recordEntry.getValidatedDisplayValue(field);
            return validatedValue.isValid ? (
              <AttachmentContent value={validatedValue.value} />
            ) : (
              <InvalidTypeContent value={validatedValue.value} />
            );
          }
        )
        .with(
          { type: "json" },

          (recordEntry) => {
            const validatedValue = recordEntry.getValidatedDisplayValue(field);
            return validatedValue.isValid ? (
              <JSONContent value={validatedValue.value} />
            ) : (
              <InvalidTypeContent value={validatedValue.value} />
            );
          }
        )
        .with(
          { type: "array" },

          (recordEntry) => {
            const validatedValue = recordEntry.getValidatedDisplayValue(field);
            return validatedValue.isValid ? (
              <ArrayContent value={validatedValue.value} />
            ) : (
              <InvalidTypeContent value={validatedValue.value} />
            );
          }
        )
        .with(
          { type: "html" },

          (recordEntry) => {
            const validatedValue = recordEntry.getValidatedDisplayValue(field);
            return validatedValue.isValid ? (
              <HTMLContent value={validatedValue.value} />
            ) : (
              <InvalidTypeContent value={validatedValue.value} />
            );
          }
        )
        .with(
          { type: "formula" },

          (recordEntry) => {
            const validatedValue = recordEntry.getValidatedDisplayValue(field);
            return validatedValue.isValid ? (
              <FormulaContent value={validatedValue.value} />
            ) : (
              <InvalidTypeContent value={validatedValue.value} />
            );
          }
        )
        .with(
          { type: "syncValue" },

          (recordEntry) => {
            const validatedValue = recordEntry.getValidatedDisplayValue(field);
            return validatedValue.isValid ? (
              <SyncValueContent value={validatedValue.value} />
            ) : (
              <InvalidTypeContent value={validatedValue.value} />
            );
          }
        )
        .with(
          { type: "generateText" },

          (recordEntry) => {
            const validatedValue = recordEntry.getValidatedDisplayValue(field);
            return validatedValue.isValid ? (
              <TextContent value={validatedValue.value} />
            ) : (
              <InvalidTypeContent value={validatedValue.value} />
            );
          }
        )
        .with(
          { type: "calculation" },

          (recordEntry) => {
            const validatedValue = recordEntry.getValidatedDisplayValue(field);
            return validatedValue.isValid ? (
              <NumberContent value={validatedValue.value} />
            ) : (
              <InvalidTypeContent value={validatedValue.value} />
            );
          }
        )
        .with(
          { type: "aggregateValue" },

          (recordEntry) => {
            const validatedValue = recordEntry.getValidatedDisplayValue(field);
            return validatedValue.isValid ? (
              <NumberContent value={validatedValue.value} />
            ) : (
              <InvalidTypeContent value={validatedValue.value} />
            );
          }
        )
        .with(
          { type: "smartFunction" },

          (recordEntry) => {
            if (recordEntryMeta?.isSmartFieldUpdating) {
              return <LoadingContent />;
            }

            if (recordEntryMeta?.smartFunctionCreditShortageError.hasError) {
              return <CreditShortageErrorContent />;
            }

            if (recordEntryMeta?.smartFunctionExecutionError.hasError) {
              return (
                <SmartFieldExecutionErrorContent onRetry={onRetrySmartField} />
              );
            }

            const validatedValue = recordEntry.getValidatedDisplayValue(field);
            return validatedValue.isValid ? (
              <SmartFunctionContent value={validatedValue.value} />
            ) : (
              <InvalidTypeContent value={validatedValue.value} />
            );
          }
        )
        .with(
          { type: P.union("createdBy", "lastEditedBy") },

          (recordEntry) => {
            const validatedValue = recordEntry.getValidatedDisplayValue(field);
            return validatedValue.isValid ? (
              <UserContent value={validatedValue.value} />
            ) : (
              <InvalidTypeContent value={validatedValue.value} />
            );
          }
        )
        .exhaustive()}
    </Flex>
  );
};

export { RecordValueDisplay };
