import { ReactNode } from "react";
import { Flex } from "~/components_next/Flex";
import { styled } from "~/stitches";

type NotebookActionButtonPros = {
  children?: ReactNode;
};

const Wrapper = styled(Flex, {
  padding: 0,
  borderRadius: "100%",
  backgroundColor: "$bg0",
  width: "32px",
  height: "32px",
  overflow: "hidden",
  boxShadow: "var(--shadow-2)",
  position: "relative",
});

const NotebookActionButton = (props: NotebookActionButtonPros) => {
  const { children } = props;
  return (
    <Wrapper align="center" justify="center">
      {children}
    </Wrapper>
  );
};

export { NotebookActionButton };
