import { Button } from "~/components_next/Button";
import { FieldSettingForm } from "~/features/Fields/FieldVisibility/FieldSettingForm";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useCallback } from "react";
import { SimpleField } from "@usemorph/morph-dashboard-types";
import { useMutation } from "react-query";
import { useOptimisticUpdateViewMutation } from "~/serverStateStore/views";
import { useErrorToast } from "~/components_next/Error";
import { createFieldsOrderFromViewFields } from "~/features/View";
import { match, P } from "ts-pattern";
import { useViewFields } from "~/serverStateStore/views/data/useViewFields";
import { useQueryMode } from "../../../states/queryMode";
import { Popover } from "~/components_next/Popover";
import { useTableView } from "../../../hooks/useProps";
import { useSetViewSetting, useViewSetting } from "../../../states/viewSetting";

const FieldSettingButton = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const queryMode = useQueryMode();

  const viewSetting = useViewSetting();
  const onUpdateViewSetting = useSetViewSetting();

  const view = useTableView();
  const { data: viewFieldsData } = useViewFields({
    teamSlug,
    databaseId,
    viewId: view.viewId,
    viewData: {
      ...view,
      setting: viewSetting,
    },
  });

  const { mutateAsync: optimisticUpdateView } = useMutation(
    useOptimisticUpdateViewMutation({
      teamSlug,
      databaseId,
      viewId: view.viewId,
    })
  );

  const { errorToast } = useErrorToast({});

  const handleFieldOrderUpdate = useCallback(
    async (reorderedFields: SimpleField[]) => {
      try {
        if (!viewFieldsData) {
          throw new Error("Failed to update field order");
        }

        onUpdateViewSetting({
          data: {
            ...viewSetting.data,
            fieldsOrder: createFieldsOrderFromViewFields(reorderedFields),
          },
        });
      } catch (e) {
        errorToast(e);
      }
    },
    [errorToast, view, viewFieldsData, viewSetting]
  );

  const handleIsHiddenUpdate = useCallback(
    async ({
      field: targetField,
      isHidden,
    }: {
      field: SimpleField;
      isHidden: boolean;
    }) => {
      try {
        if (!viewFieldsData) {
          throw new Error("Failed to update field order");
        }

        const prevHiddenFields = viewSetting.data.hiddenFields;
        const updateHiddenFields: {
          originalTableSlug?: string;
          name: string;
        }[] = match([prevHiddenFields, isHidden])
          .with([P.nullish, true], () => {
            return [
              {
                name: targetField.name,
                originalTableSlug: targetField.originalTableSlug,
              },
            ];
          })
          .with([P.nullish, false], () => {
            return [];
          })
          .with([P.not(P.nullish), true], ([prevHiddenFields]) => {
            return [
              ...prevHiddenFields,
              {
                name: targetField.name,
                originalTableSlug: targetField.originalTableSlug,
              },
            ];
          })
          .with([P.not(P.nullish), false], ([prevHiddenFields]) => {
            return prevHiddenFields.filter(
              ({ name, originalTableSlug }) =>
                name !== targetField.name ||
                originalTableSlug !== targetField.originalTableSlug
            );
          })
          .exhaustive();
        // await optimisticUpdateView({
        //   type,
        //   name,
        //   condition,
        //   setting: {
        //     data: {
        //       ...parsedSetting.data,
        //       hiddenFields: updateHiddenFields,
        //     },
        //   },
        // });
        onUpdateViewSetting({
          data: {
            ...viewSetting.data,
            hiddenFields: updateHiddenFields,
          },
        });
      } catch (e) {
        errorToast(e);
      }
    },
    [errorToast, view, viewFieldsData, viewSetting]
  );

  if (queryMode === "prompt" || queryMode === "sql" || !viewFieldsData) {
    return null;
  }

  return (
    <Popover
      trigger={
        <Button variant="actionText" size="xs">
          Fields
        </Button>
      }
    >
      <FieldSettingForm
        fields={viewFieldsData.fields}
        onFieldOrderUpdate={handleFieldOrderUpdate}
        onIsHiddenUpdate={handleIsHiddenUpdate}
        showTableSlug
      />
    </Popover>
  );
};

export { FieldSettingButton };
