import { useMutation } from "react-query";
import useApiSQL from "~/api/useApiSQL";
import { CsvDownloadFieldsClientModel } from "~/clientModel/csvDownload/csvDownloadFields";
import { CsvDownloadFieldsClientModelDefactory } from "~/clientModel/csvDownload/csvDownloadFields/CsvDownloadFieldsClientModelDefactory";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";

import { useErrorToast } from "~/components_next/Error";

type UseDownloadCsvWithSqlExecutableParams = {
  teamSlug: string;
  databaseId: string;
  sql: string;
};

const useDownloadCsvWithSqlExecutable = ({
  teamSlug,
  databaseId,
  sql,
}: UseDownloadCsvWithSqlExecutableParams): Executable<
  {
    csvDownloadFields: CsvDownloadFieldsClientModel;
    smartFunctions: SmartFunctionsClientModel;
  },
  { url: string }
> => {
  const { errorToast } = useErrorToast({});

  const { _downloadCSVFromExecuteSQL } = useApiSQL();

  return useComposeExecutable(
    useMutation({
      mutationFn: async ({
        csvDownloadFields,
        smartFunctions,
      }: {
        csvDownloadFields: CsvDownloadFieldsClientModel;
        smartFunctions: SmartFunctionsClientModel;
      }) => {
        const { url } = await _downloadCSVFromExecuteSQL({
          teamSlug,
          databaseId,
          sql,
          fields: CsvDownloadFieldsClientModelDefactory.toSimpleFields(
            csvDownloadFields,
            {
              smartFunctions,
            }
          ),
        });

        return { url };
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseDownloadCsvWithSqlExecutable = ({
  teamSlug,
  databaseId,
  sql,
}: {
  teamSlug: string;
  databaseId: string;
  sql: string;
}) => {
  return () =>
    useDownloadCsvWithSqlExecutable({
      teamSlug,
      databaseId,
      sql,
    });
};
