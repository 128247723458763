import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { ToolbarWrapper } from "../components/atoms/ToolbarWrapper";


import { useTableSlug } from "~/utilHooks/useTableSlug";
import { Badge } from "~/components_next/Badge";
import { Text } from "~/components_next/Text";
import { Box } from "@radix-ui/themes";

const DataCollectionToolbar = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const tableSlug = useTableSlug();

  return (
    <ToolbarWrapper>
      <>
        <Badge variant="tertiary">
          Data Collection
        </Badge>
        <Box>
          <Text>{tableSlug} RSS Feed Collection</Text>
        </Box>
      </>
    </ToolbarWrapper>
  );
};

export { DataCollectionToolbar };
