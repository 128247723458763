import { forwardRef } from "react";
import { BsX } from "react-icons/bs";
import { Button } from "~/components_next/Button";

type ClearSQLButtonProps = {
  onClick: () => void;
};

const ClearSQLButton = forwardRef<HTMLButtonElement, ClearSQLButtonProps>(
  (props, ref) => {
    const { onClick, ...rest } = props;
    return (
      <Button
        ref={ref}
        variant="actionText"
        size="xs"
        onClick={onClick}
        {...rest}
      >
        <BsX />
        Clear SQL
      </Button>
    );
  }
);

ClearSQLButton.displayName = "ClearSQLButton";

export { ClearSQLButton };
