import { DashboardViewConditionObject } from "@usemorph/morph-dashboard-types";
import constate from "constate";
import { CanvasVariablesClientModel } from "~/clientModel/canvas/CanvasVariableClientModel";
import { CsvDownloadFieldsClientModel } from "~/clientModel/csvDownload/csvDownloadFields";
import { UseExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { PromptHistoriesClientModel } from "~/clientModel/promptHistories";
import { FilterConditionsClientModel } from "~/clientModel/queryConditions/filterConditions";
import { PaginationClientModel } from "~/clientModel/queryConditions/pagination";
import { SortConditionsClientModel } from "~/clientModel/queryConditions/sortConditions";
import { RecordsClientModel } from "~/clientModel/records";
import { TablePaginationLimit } from "~/presenters/sourceAndViews/common/components/bottomItems/TablePagination";

export type DashboardTableViewPropsContextProps = {
  // loadable
  useRecordsWithConditionLoadable: UseLoadable<
    {
      viewId: string;
      filterConditions: FilterConditionsClientModel;
      sortConditions: SortConditionsClientModel;
      pagination: PaginationClientModel<TablePaginationLimit>;
    },
    RecordsClientModel
  >;
  useRecordsWithSqlLoadable: UseLoadable<
    {
      viewId: string;
      queryingSql: string;
      pagination: PaginationClientModel<TablePaginationLimit>;
    },
    RecordsClientModel
  >;

  useFieldsWithConditionLoadable: UseLoadable<
    { viewId: string },
    FieldsClientModel
  >;
  useFieldsWithSqlLoadable: UseLoadable<
    { viewId: string; queryingSql: string },
    FieldsClientModel
  >;

  // input suggestions loadables
  useVariablesForTypeaheadLoadable?: UseLoadable<
    { viewId: string },
    CanvasVariablesClientModel
  >;
  useFieldsForTypeaheadLoadable?: UseLoadable<
    { viewId: string },
    FieldsClientModel
  >;
  useSuggestedPromptsLoadable: UseLoadable<
    { condition: DashboardViewConditionObject; viewId: string },
    { messages: string[] },
    unknown
  >;

  useGenerateSqlFromPromptExecutable: UseExecutable<
    {
      condition: DashboardViewConditionObject;
    },
    { prompt: string },
    { sql: string }
  >;

  usePromptHistoriesLoadable: UseLoadable<
    { viewId: string },
    PromptHistoriesClientModel,
    unknown
  >;

  // csv download
  useDownloadCsvPreviewRecordsLoadable: UseLoadable<
    { csvDownloadFields: CsvDownloadFieldsClientModel; viewId: string },
    RecordsClientModel
  >;
  useDownloadCsvExecutable: UseExecutable<
    { viewId: string },
    { csvDownloadFields: CsvDownloadFieldsClientModel },
    { url: string }
  >;
};

const useDashboardTableViewPropsContext = (
  props: DashboardTableViewPropsContextProps
) => {
  const {
    useRecordsWithConditionLoadable,
    useRecordsWithSqlLoadable,
    useFieldsWithConditionLoadable,
    useFieldsWithSqlLoadable,

    useVariablesForTypeaheadLoadable,
    useFieldsForTypeaheadLoadable,
    useSuggestedPromptsLoadable,

    useGenerateSqlFromPromptExecutable,
    usePromptHistoriesLoadable,

    useDownloadCsvPreviewRecordsLoadable,
    useDownloadCsvExecutable,
  } = props;

  return {
    useRecordsWithConditionLoadable,
    useRecordsWithSqlLoadable,
    useFieldsWithConditionLoadable,
    useFieldsWithSqlLoadable,
    useVariablesForTypeaheadLoadable,
    useSuggestedPromptsLoadable,
    useFieldsForTypeaheadLoadable,
    useGenerateSqlFromPromptExecutable,
    usePromptHistoriesLoadable,
    useDownloadCsvPreviewRecordsLoadable,
    useDownloadCsvExecutable,
  };
};

export const [
  DashboardTableViewPropsProvider,
  useUseRecordsWithConditionLoadable,
  useUseRecordsWithSqlLoadable,
  useUseFieldsWithConditionLoadable,
  useUseFieldsWithSqlLoadable,

  useUseSuggestedPromptsLoadable,
  useUseVariablesForTypeaheadLoadable,
  useUseFieldsForTypeaheadLoadable,

  useUseGenerateSqlFromPromptExecutable,
  useUsePromptHistoriesLoadable,

  useUseDownloadCsvPreviewRecordsLoadable,
  useUseDownloadCsvExecutable,
] = constate(
  useDashboardTableViewPropsContext,
  (value) => value.useRecordsWithConditionLoadable,
  (value) => value.useRecordsWithSqlLoadable,
  (value) => value.useFieldsWithConditionLoadable,
  (value) => value.useFieldsWithSqlLoadable,

  (value) => value.useSuggestedPromptsLoadable,
  (value) => value.useVariablesForTypeaheadLoadable,
  (value) => value.useFieldsForTypeaheadLoadable,

  (value) => value.useGenerateSqlFromPromptExecutable,
  (value) => value.usePromptHistoriesLoadable,

  (value) => value.useDownloadCsvPreviewRecordsLoadable,
  (value) => value.useDownloadCsvExecutable
);
