import { DataCollectionToolbar } from "~/features/Toolbar/DataCollectionToolbar/DataCollectionToolbar"


export const DataCollectionToolbarContainer = () => {

  return (
    <>
      <DataCollectionToolbar />
    </>
  )
}
