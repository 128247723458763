type PaginationClientModelData<Limit extends number> = {
  skip: number;
  limit: Limit;
};

export class PaginationClientModel<Limit extends number> {
  readonly #data: PaginationClientModelData<Limit>;

  constructor(data: PaginationClientModelData<Limit>) {
    this.#data = data;
  }

  public get data(): PaginationClientModelData<Limit> {
    return this.#data;
  }

  public get limit(): Limit {
    return this.#data.limit;
  }

  public isPrevDisabled(): boolean {
    return this.#data.skip === 0;
  }

  public isNextDisabled(count: number): boolean {
    return this.#data.skip + this.#data.limit >= count;
  }

  public startAt(count: number): number {
    if (count === 0) {
      return 0;
    }

    return this.#data.skip + 1;
  }

  public endAt(count: number): number {
    return Math.min(this.#data.skip + this.#data.limit, count);
  }

  public next(): PaginationClientModel<Limit> {
    return new PaginationClientModel<Limit>({
      skip: this.#data.skip + this.#data.limit,
      limit: this.#data.limit,
    });
  }

  public prev(): PaginationClientModel<Limit> {
    return new PaginationClientModel<Limit>({
      skip: this.#data.skip - this.#data.limit,
      limit: this.limit,
    });
  }

  public updateLimit(limit: Limit): PaginationClientModel<Limit> {
    return new PaginationClientModel<Limit>({
      skip: 0,
      limit,
    });
  }

  public jumpToBeginning(): PaginationClientModel<Limit> {
    return new PaginationClientModel<Limit>({
      skip: 0,
      limit: this.#data.limit,
    });
  }

  public jumpToEnd(count: number): PaginationClientModel<Limit> {
    return new PaginationClientModel<Limit>({
      skip: Math.floor(count / this.#data.limit) * this.#data.limit,
      limit: this.#data.limit,
    });
  }
}
