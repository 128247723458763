import { Flex } from "@radix-ui/themes";
import { SimpleField } from "@usemorph/morph-dashboard-types";
import { useCallback } from "react";
import { BsArrow90DegUp } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { HintPopover } from "~/components_next/Hint";
import { Divider } from "~/components_next/Divider";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";
import { getPath } from "~/routing";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useTableView } from "../../hooks/useProps";
import { DropdownMenu } from "~/components_next/DropdownMenu";

type FieldDropdownProps = {
  field: SimpleField;
  onClickHideField: (simpleField: SimpleField) => void;
};

const FieldDropdown = (props: FieldDropdownProps) => {
  const { field, onClickHideField } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const view = useTableView();

  const navigate = useNavigate();

  const handleSourceClick = useCallback(() => {
    if (field.originalTableSlug) {
      navigate(
        getPath("source", {
          teamSlug,
          databaseId,
          tableSlug: field.originalTableSlug,
        })
      );
    }
  }, [field, navigate, teamSlug, databaseId]);

  const isUpdatable = view.rules.update[field.name];

  return (
    <>
      <DropdownMenu.Item onClick={() => onClickHideField(field)}>
        Hide Field
      </DropdownMenu.Item>
      {!!field.originalTableSlug && (
        <>
          <Divider />
          <DropdownMenu.Item
            onClick={handleSourceClick}
            style={{
              height: "100%",
              paddingTop: 12,
              paddingBottom: 12,
            }}
          >
            <Flex direction="column" gap="1">
              <Text variant="description">Go to referring source</Text>
              <Flex align="center" gap="2">
                <BsArrow90DegUp />
                <Text>
                  {field.originalTableSlug}.{field.name}
                </Text>
              </Flex>
            </Flex>
          </DropdownMenu.Item>
        </>
      )}
      {isUpdatable !== true && (
        <>
          <Divider />
          <Flex px="3" pt="2" align="center">
            <Text>This fiel is readonly.</Text>
            <Spacer />
            <HintPopover title="A field is readonly if">
              <ul>
                <li>
                  <Text variant="description">
                    Field in the source is primary key.
                  </Text>
                </li>
                <li>
                  <Text variant="description">
                    Field on the View does not have a one-to-one correspondence
                    with a field in the Source.(e.g., when using an aggregate
                    function)
                  </Text>
                </li>
                <li>
                  <Text variant="description">
                    Field values are automatically generated(e.g., when field
                    type is formula)
                  </Text>
                </li>
              </ul>
            </HintPopover>
          </Flex>
        </>
      )}
    </>
  );
};

export { FieldDropdown };
