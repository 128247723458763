import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil";
import { urlSyncEffect } from "recoil-sync";
import { string } from "@recoiljs/refine";
import { createTableViewUrlSyncStoreEffectOptions } from "./TableViewUrlSyncStore/createTableViewUrlSyncStoreEffectOptions";

const promptInputFamily = atomFamily<string, { tableSlug: string }>({
  key: "tableViewPromptInput",
  default: "",
  effects: [
    urlSyncEffect({
      ...createTableViewUrlSyncStoreEffectOptions({
        checker: string(),
        key: "tableViewPromptInput",
        defaultValue: "",
      }),
      history: "replace", // 入力するごとに履歴を残さないようにする
    }),
  ],
});

const usePromptInput = (tableSlug: string) => {
  return useRecoilValue(promptInputFamily({ tableSlug }));
};

const useSetPromptInput = (tableSlug: string) => {
  return useSetRecoilState(promptInputFamily({ tableSlug }));
};

export { usePromptInput, useSetPromptInput };
