import { useQueryClient } from "react-query";
import useApiPage from "~/api/useApiPage";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { pageKeys } from "../keys";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseUpdatePageCellMutationParams {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
  pageId: string;
}

type MutateProps = {
  cellId: string;
  notebookCellId: string;
  x: number;
  y: number;
  w: number;
  h: number;
};

const useUpdatePageCellsMutaiton = ({
  teamSlug,
  databaseId,
  notebookId,
  pageId,
}: UseUpdatePageCellMutationParams) => {
  const { _updatePageCell } = useApiPage();

  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: (props: MutateProps[]) => {
      return Promise.all(
        props.map(({ cellId, notebookCellId, x, y, w, h }) =>
          _updatePageCell({
            pageId,
            teamSlug,
            cellId,
            notebookCellId,
            x,
            y,
            w,
            h,
          })
        )
      );
    },
    onSuccess: () => {
      const keys = pageKeys.findPageDetail({
        teamSlug,
        databaseId,
        notebookId,
        pageId,
      });
      broadcast(keys);
      return invalidateAll(client, keys);
    },
  };
};

export {
  useUpdatePageCellsMutaiton,
  type MutateProps as UpdatePageCellsMutateProps,
};
