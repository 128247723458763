import { useQuery } from "react-query";
import { useListSimpleFieldsQuery } from "../queries/useListSimpleFieldsQuery";

interface useSimpleFieldsParams {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
}

const useSimpleFields = ({
  teamSlug,
  databaseId,
  tableSlug,
}: useSimpleFieldsParams) => {
  const options = useListSimpleFieldsQuery({ teamSlug, databaseId, tableSlug });
  return useQuery(options);
};

export { useSimpleFields };
