import {
  RecordSortConditionUnit,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { BsPlus, BsX } from "react-icons/bs";
import { Button } from "~/components_next/Button";
import { SimpleSelect } from "~/components_next/Select";
import { IconButton } from "~/components_next/IconButton";
import { Box, Flex } from "@radix-ui/themes";
import { Spacer } from "~/components_next/Spacer";

type SortConditionFormProps = {
  simpleFields: [SimpleField, ...SimpleField[]];
  sortCondition: RecordSortConditionUnit | null;
} & (
    | {
      isReadOnly?: false;
      onChangeSortCondition: (
        sortCondition: RecordSortConditionUnit | null
      ) => void;
    }
    | {
      isReadOnly: true;
    }
  );

const SortConditionForm = (props: SortConditionFormProps) => {
  const { simpleFields, sortCondition, isReadOnly } = props;

  const handleAddSortCondition = () => {
    if (isReadOnly) return;

    props.onChangeSortCondition({
      key: simpleFields[0].name,
      direction: "ascending",
    });
  };

  const handleRemoveSortCondition = () => {
    if (isReadOnly) return;

    props.onChangeSortCondition(null);
  };

  const handleChangeDirectionSelect = (
    direction: "ascending" | "descending"
  ) => {
    if (!sortCondition) return;
    if (isReadOnly) return;

    props.onChangeSortCondition({
      key: sortCondition.key,
      direction,
    });
  };

  const handleChangeKeySelect = (key: string) => {
    if (!sortCondition) return;
    if (isReadOnly) return;

    props.onChangeSortCondition({
      key: key,
      direction: sortCondition.direction,
    });
  };

  const keyOptions = simpleFields.map(({ name, displayName }) => ({
    value: name,
    label: displayName || name,
  }));

  const sortDirectionOptions = [
    { value: "ascending", label: "Ascending" },
    { value: "descending", label: "Descending" },
  ];

  return (
    <Flex direction="column" gap="2">
      {sortCondition && (
        <Flex direction="row" align="center" gap="1">
          <SimpleSelect
            variant="primary"
            value={sortCondition.key}
            onChange={(value) => handleChangeKeySelect(value as string)}
            size="xs"
            isDisabled={isReadOnly}
            options={keyOptions}
          />
          <SimpleSelect
            variant="primary"
            value={sortCondition.direction}
            onChange={(value) =>
              handleChangeDirectionSelect(value as "ascending" | "descending")
            }
            size="xs"
            isDisabled={isReadOnly}
            options={sortDirectionOptions}
          />
          <Spacer />
          {!isReadOnly && (
            <IconButton
              onClick={handleRemoveSortCondition}
              icon={<BsX />}
              tooltip="Remove"
            />
          )}
        </Flex>
      )}

      {!sortCondition && (
        <Box style={{ alignSelf: "start" }}>
          <Button
            onClick={() => handleAddSortCondition()}
            variant="tertiary"
            size="xs"
            isDisabled={isReadOnly}
          >
            <BsPlus />
            Add Sort
          </Button>
        </Box>
      )}
    </Flex>
  );
};

export { SortConditionForm };
