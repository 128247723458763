import { useQuery } from "react-query";
import { Loadable, useComposeLoadable } from "~/clientModel/loadable";
import { RssTableMappingClientModel, RssTableMappingClientModelFactory } from "~/clientModel/rssFeed";
import { useFindRssLinkedToTableQuery } from "~/serverStateStore/rssFeed";


const useFindRssLinkedToTableLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
  enabled,
}: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  enabled?: boolean;
}): Loadable<RssTableMappingClientModel, unknown> => {
  return useComposeLoadable(
    useQuery({
      ...useFindRssLinkedToTableQuery({
        teamSlug,
        databaseId,
        tableSlug,
      }),
      enabled,
      select: (data) => {
        return RssTableMappingClientModelFactory.fromRssTableMappingResponse(data)
      }
    })
  )
}

export const createUseFindRssLinkedToTableLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
  enabled,
}: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  enabled?: boolean;
}) => {
  return () => useFindRssLinkedToTableLoadable({
    teamSlug,
    databaseId,
    tableSlug,
    enabled,
  })
}
