import { sqlKeys } from "../keys";

// types
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiSQL from "~/api/useApiSQL";
import { DashboardViewConditionObject } from "@usemorph/morph-dashboard-types";

interface UseGenerateSqlQueryParams {
  message: string;
  tableSlug: string;
  teamSlug: string;
  databaseId: string;
  condition?: DashboardViewConditionObject;
  extraTables?: {
    tableSlug: string;
    condition?: DashboardViewConditionObject;
  }[];
}

const useGenerateSqlQuery = ({
  databaseId,
  message,
  teamSlug,
  tableSlug,
  condition,
  extraTables,
}: UseGenerateSqlQueryParams) => {
  const { _generateSQL } = useApiSQL();

  const client = useQueryClient();
  client.setQueryDefaults(
    sqlKeys.generateSql({
      databaseId,
      message,
      teamSlug,
      tableSlug,
      extraTables,
    }),
    commonQueryOptions
  );

  return {
    queryKey: sqlKeys.generateSql({
      databaseId,
      tableSlug,
      teamSlug,
      message,
      condition,
      extraTables,
    }),
    queryFn: () => {
      return _generateSQL({
        databaseId,
        teamSlug,
        body: {
          message,
          tableSlug,
          condition,
          extraTables,
        },
      });
    },
  };
};

export { useGenerateSqlQuery };
