import { QueryRecordListResponse } from "@usemorph/morph-dashboard-types";
import { RecordClientModelFactory } from "./record";

import { RecordsClientModel } from "./RecordsClientModel";

export class RecordsClientModelFactory {
  public static createFromQueryRecordListResponseWithFields(
    recordListResponse: Required<QueryRecordListResponse>
  ): RecordsClientModel {
    const { items, fields, count } = recordListResponse;

    return new RecordsClientModel({
      records: items.map((record) =>
        RecordClientModelFactory.createFromRecordAndSimpleFields(record, fields)
      ),
      totalCount: count,
    });
  }
}
