import { SimpleFieldCategoryType } from "@usemorph/morph-dashboard-types";
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
} from "~/components_next/Select";
import { getSimpleFieldTypeLabel } from "~/features/Fields/utils/getSimpleFieldTypeLabel";

const fieldsDict: { [_ in SimpleFieldCategoryType]: { show: boolean } } = {
  shortText: { show: true },
  longText: { show: true },
  singleSelect: { show: true },
  multiSelect: { show: true },
  number: { show: true },
  decimal: { show: true },
  bigNumber: { show: true },
  boolean: { show: true },
  date: { show: true },
  datetime: { show: true },
  time: { show: true },
  url: { show: true },
  phoneNumber: { show: true },
  email: { show: true },
  image: { show: true },
  attachment: { show: true },
  formula: { show: true },
  createdAt: { show: true },
  createdBy: { show: true },
  lastEditedAt: { show: true },
  lastEditedBy: { show: true },
  autoBigNumber: { show: true },
  autoNumber: { show: true },
  json: { show: true },
  array: { show: true },
  richText: { show: false },
  reference: { show: false },
  multiReference: { show: false },
  html: { show: true },
  embeddings: { show: false },
  python: { show: false },
  rowInfo: { show: false },
};

interface FieldTypeSelectProps {
  onChange?: (value: SimpleFieldCategoryType) => void;
  value?: SimpleFieldCategoryType;
  isReadOnly?: boolean;
  options?:
    | { includes: SimpleFieldCategoryType[] }
    | { excludes: SimpleFieldCategoryType[] }; // 指定がなければ、optionsDictから{show: true}のものを全て表示する。
}

export const FieldTypeSelect = ({
  value,
  options,
  isReadOnly = false,
  onChange,
}: FieldTypeSelectProps) => {
  const shownOptionTypes: SimpleFieldCategoryType[] = (
    Object.keys(fieldsDict) as SimpleFieldCategoryType[]
  ).flatMap((type: SimpleFieldCategoryType) => {
    if (!fieldsDict[type].show) return [];

    if (!options) {
      return type;
    }

    if ("includes" in options) {
      return options.includes.includes(type) ? [type] : [];
    } else {
      return options.excludes.includes(type) ? [] : [type];
    }
  });

  return (
    <SelectRoot
      variant="primary"
      value={value ?? null}
      onChange={(value) => onChange?.(value as SimpleFieldCategoryType)}
      isReadOnly={isReadOnly}
      isDisabled={isReadOnly}
      size="sm"
    >
      <SelectTrigger />
      <SelectContent>
        {shownOptionTypes.map((type) => (
          <SelectItem value={type} key={type}>
            {getSimpleFieldTypeLabel(type)}
          </SelectItem>
        ))}
      </SelectContent>
    </SelectRoot>
  );
};
