import { Flex } from "~/components_next/Flex";
import { Spinner } from "~/components_next/Spinner";

export const RecordsTableLoadingFallback = () => {
  return (
    <Flex width="100%" height="100%" align="center" justify="center">
      <Spinner size="lg" />
    </Flex>
  );
};
