import { match, P } from "ts-pattern";

import { NumberInput } from "./inputs/number/NumberInput";
import { ShortTextInput } from "./inputs/text/ShortTextInput";
import { ForwardedRef, forwardRef } from "react";
import { RecordEntryClientModel } from "~/clientModel/records/record/recordEntry";
import { FieldClientModel } from "~/clientModel/fields/field";
import { AutoNumberReadonlyInput } from "./inputs/number/AutoNumberReadonlyInput";
import { FormulaReadonlyInput } from "./inputs/computed/FormulaReadonlyInput";
import { LastEditedAtReadonlyInput } from "./inputs/system/LastEditedAtReadonlyInput";
import { LastEditedByReadonlyInput } from "./inputs/system/LastEditedByReadonlyInput";
import { CreatedByReadonlyInput } from "./inputs/system/CreatedByReadonlyInput";
import { CreatedAtReadonlyInput } from "./inputs/system/CreatedAtReadonlyInput";
import { LongTextInput } from "./inputs/text/LongTextInput";
import { EmailInput } from "./inputs/text/EmailInput";
import { PhoneNumberInput } from "./inputs/text/PhoneNumberInput";
import { UrlInput } from "./inputs/text/UrlInput";
import { DateInput } from "./inputs/text/DateInput";
import { DateTimeInput } from "./inputs/text/DateTimeInput";
import { TimeInput } from "./inputs/text/TimeInput";
import { BooleanInput } from "./inputs/otherPrimitives/BooleanInput";
import { SingleSelectInput } from "./inputs/select/SingleSelectInput";
import { MultiSelectInput } from "./inputs/select/MultiSelectInput";
import { ImageInput } from "./inputs/file/ImageInput";
import { AttachmentInput } from "./inputs/file/AttachmentInput";
import { UseExecutable } from "~/clientModel/executable";
import { SyncValueReadonlyInput } from "./inputs/computed/SyncValueReadonlyInput";
import { GenerateTextReadonlyInput } from "./inputs/computed/GenerateTextReadonlyInput";
import { CalculationReadonlyInput } from "./inputs/computed/CalculationReadonlyInput";
import { AggregateValueReadonlyInput } from "./inputs/computed/AggregateValueReadonlyInput";
import { SmartFunctionReadonlyInput } from "./inputs/computed/SmartFunctionReadonlyInput";
import { JSONInput } from "./inputs/structuredData/JSON/JSONInput";
import { ArrayInput } from "./inputs/structuredData/Array/ArrayInput";
import { HTMLInput } from "./inputs/structuredData/HTML/HTMLInput";

type RecordValueInputProps = {
  field: FieldClientModel;
  editingRecordEntry: RecordEntryClientModel;
  onChange?: (editingRecordEntry: RecordEntryClientModel) => void;
  useUploadFileExecutable?: UseExecutable<
    void,
    { file: File },
    {
      data: string;
      url: string;
    },
    unknown
  >;
  size?: "xs" | "sm" | "md";
  isReadonly?: boolean;
};

type RecordValueInputRefType =
  | HTMLInputElement
  | HTMLTextAreaElement
  | HTMLButtonElement;

const RecordValueInput = forwardRef<
  RecordValueInputRefType,
  RecordValueInputProps
>((props, ref) => {
  const {
    field,
    editingRecordEntry,
    onChange,
    size,
    isReadonly = false,
    useUploadFileExecutable,
  } = props;

  return (
    match(editingRecordEntry)
      // text
      .with({ type: "shortText" }, (editingRecordEntry) => (
        <ShortTextInput
          editingRecordEntry={editingRecordEntry}
          field={field}
          onChange={onChange}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "longText" }, (editingRecordEntry) => (
        <LongTextInput
          editingRecordEntry={editingRecordEntry}
          field={field}
          onChange={onChange}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLTextAreaElement>}
        />
      ))
      .with({ type: "email" }, (editingRecordEntry) => (
        <EmailInput
          editingRecordEntry={editingRecordEntry}
          field={field}
          onChange={onChange}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "phoneNumber" }, (editingRecordEntry) => (
        <PhoneNumberInput
          editingRecordEntry={editingRecordEntry}
          field={field}
          onChange={onChange}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "url" }, (editingRecordEntry) => (
        <UrlInput
          editingRecordEntry={editingRecordEntry}
          field={field}
          onChange={onChange}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "date" }, (editingRecordEntry) => (
        <DateInput
          editingRecordEntry={editingRecordEntry}
          field={field}
          onChange={onChange}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "datetime" }, (editingRecordEntry) => (
        <DateTimeInput
          editingRecordEntry={editingRecordEntry}
          field={field}
          onChange={onChange}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "time" }, (editingRecordEntry) => (
        <TimeInput
          editingRecordEntry={editingRecordEntry}
          field={field}
          onChange={onChange}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))

      // number
      .with(
        { type: P.union("number", "bigNumber", "decimal") },
        (editingRecordEntry) => (
          <NumberInput
            editingRecordEntry={editingRecordEntry}
            field={field}
            onChange={onChange}
            size={size}
            isReadonly={isReadonly}
            ref={ref as ForwardedRef<HTMLInputElement>}
          />
        )
      )
      .with(
        { type: P.union("autoNumber", "autoBigNumber") },
        (editingRecordEntry) => (
          <AutoNumberReadonlyInput
            editingRecordEntry={editingRecordEntry}
            field={field}
            size={size}
            isReadonly={isReadonly}
            ref={ref as ForwardedRef<HTMLInputElement>}
          />
        )
      )

      // other primitive
      .with({ type: "boolean" }, (editingRecordEntry) => (
        <BooleanInput
          editingRecordEntry={editingRecordEntry}
          onChange={onChange}
          field={field}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLButtonElement>}
        />
      ))

      // select
      .with({ type: "singleSelect" }, (editingRecordEntry) => (
        <SingleSelectInput
          editingRecordEntry={editingRecordEntry}
          onChange={onChange}
          field={field}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLButtonElement>}
        />
      ))
      .with({ type: "multiSelect" }, (editingRecordEntry) => (
        <MultiSelectInput
          editingRecordEntry={editingRecordEntry}
          onChange={onChange}
          field={field}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLDivElement>}
        />
      ))

      // file
      .with({ type: "image" }, (editingRecordEntry) => (
        <ImageInput
          editingRecordEntry={editingRecordEntry}
          onChange={onChange}
          field={field}
          size={size}
          isReadonly={isReadonly}
          useUploadFileExecutable={useUploadFileExecutable}
          ref={ref as ForwardedRef<HTMLButtonElement>}
        />
      ))
      .with({ type: "attachment" }, (editingRecordEntry) => (
        <AttachmentInput
          editingRecordEntry={editingRecordEntry}
          onChange={onChange}
          field={field}
          size={size}
          isReadonly={isReadonly}
          useUploadFileExecutable={useUploadFileExecutable}
          ref={ref as ForwardedRef<HTMLButtonElement>}
        />
      ))

      // structured data
      .with({ type: "json" }, (editingRecordEntry) => (
        <JSONInput
          editingRecordEntry={editingRecordEntry}
          onChange={onChange}
          field={field}
          size={size}
          isReadonly={isReadonly}
        />
      ))
      .with({ type: "array" }, (editingRecordEntry) => (
        <ArrayInput
          editingRecordEntry={editingRecordEntry}
          onChange={onChange}
          field={field}
          size={size}
          isReadonly={isReadonly}
        />
      ))
      .with({ type: "html" }, (editingRecordEntry) => (
        <HTMLInput
          editingRecordEntry={editingRecordEntry}
          onChange={onChange}
          field={field}
          size={size}
          isReadonly={isReadonly}
        />
      ))

      // computed
      .with({ type: "formula" }, (editingRecordEntry) => (
        <FormulaReadonlyInput
          editingRecordEntry={editingRecordEntry}
          field={field}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "syncValue" }, (editingRecordEntry) => (
        <SyncValueReadonlyInput
          editingRecordEntry={editingRecordEntry}
          field={field}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "generateText" }, (editingRecordEntry) => (
        <GenerateTextReadonlyInput
          editingRecordEntry={editingRecordEntry}
          field={field}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "calculation" }, (editingRecordEntry) => (
        <CalculationReadonlyInput
          editingRecordEntry={editingRecordEntry}
          field={field}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "aggregateValue" }, (editingRecordEntry) => (
        <AggregateValueReadonlyInput
          editingRecordEntry={editingRecordEntry}
          field={field}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "smartFunction" }, (editingRecordEntry) => (
        <SmartFunctionReadonlyInput
          editingRecordEntry={editingRecordEntry}
          field={field}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))

      // system
      .with({ type: "lastEditedAt" }, (editingRecordEntry) => (
        <LastEditedAtReadonlyInput
          editingRecordEntry={editingRecordEntry}
          field={field}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "lastEditedBy" }, (editingRecordEntry) => (
        <LastEditedByReadonlyInput
          editingRecordEntry={editingRecordEntry}
          field={field}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "createdAt" }, (editingRecordEntry) => (
        <CreatedAtReadonlyInput
          editingRecordEntry={editingRecordEntry}
          field={field}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "createdBy" }, (editingRecordEntry) => (
        <CreatedByReadonlyInput
          editingRecordEntry={editingRecordEntry}
          field={field}
          size={size}
          isReadonly={isReadonly}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "maskedValue" }, () => null)
      .exhaustive()
  );
});

RecordValueInput.displayName = "RecordValueInput";

export { RecordValueInput, type RecordValueInputRefType };
