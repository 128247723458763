import { useQuery } from "react-query";
import { CsvDownloadFieldsClientModel } from "~/clientModel/csvDownload/csvDownloadFields";
import { CsvDownloadFieldsClientModelDefactory } from "~/clientModel/csvDownload/csvDownloadFields/CsvDownloadFieldsClientModelDefactory";
import { Loadable, useComposeLoadable } from "~/clientModel/loadable";
import {
  FilterConditionsClientModel,
  FilterConditionsClientModelDefactory,
} from "~/clientModel/queryConditions/filterConditions";
import {
  SortConditionsClientModel,
  SortConditionsClientModelDefactory,
} from "~/clientModel/queryConditions/sortConditions";

import {
  RecordsClientModel,
  RecordsClientModelFactory,
} from "~/clientModel/records";
import { useListRecordsWithQueryQuery } from "~/serverStateStore";

type useDownloadCsvWithQueryPreviewRecordsLoadableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  csvDownloadFields: CsvDownloadFieldsClientModel;
  filterConditions: FilterConditionsClientModel;
  sortConditions: SortConditionsClientModel;
};

const useDownloadCsvWithQueryPreviewRecordsLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
  csvDownloadFields,
  filterConditions,
  sortConditions,
}: useDownloadCsvWithQueryPreviewRecordsLoadableParams): Loadable<
  RecordsClientModel,
  unknown
> => {
  return useComposeLoadable(
    useQuery({
      ...useListRecordsWithQueryQuery({
        teamSlug,
        databaseId,
        tableSlug,
        select: ["*"],
        format:
          CsvDownloadFieldsClientModelDefactory.toRecordFormatConditions(
            csvDownloadFields
          ),
        filter:
          FilterConditionsClientModelDefactory.toRecordFilterCondition(
            filterConditions
          ),
        sort: SortConditionsClientModelDefactory.toRecordSortConditionUnits(
          sortConditions
        ),
      }),
      select: (data) => {
        return RecordsClientModelFactory.createFromQueryRecordListResponseWithFields(
          data
        );
      },
    })
  );
};

export const createUseDownloadCsvWithQueryPreviewRecordsLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
  filterConditions,
  sortConditions,
}: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  filterConditions: FilterConditionsClientModel;
  sortConditions: SortConditionsClientModel;
}) => {
  return ({
    csvDownloadFields,
  }: {
    csvDownloadFields: CsvDownloadFieldsClientModel;
  }) =>
    useDownloadCsvWithQueryPreviewRecordsLoadable({
      teamSlug,
      databaseId,
      tableSlug,
      filterConditions,
      sortConditions,
      csvDownloadFields,
    });
};
