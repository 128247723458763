import { UseExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";
import { Loadable } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { RecordsClientModel } from "~/clientModel/records";
import { Badge } from "~/components_next/Badge";
import { Flex } from "~/components_next/Flex";
import { PromptInput } from "./PromptInput";

type BulkEditRecordsByPromptContentProps = {
  prompt: string;
  onPromptChange: (prompt: string) => void;
  targetFieldLoadable: Loadable<FieldClientModel, unknown>;
  fieldsLoadable: Loadable<FieldsClientModel, unknown>;
  smartFunctionsLoadable: Loadable<SmartFunctionsClientModel>;
  usePreviewBulkEditRecordsByPromptExecutable: UseExecutable<
    void,
    {
      prompt: string;
      field: FieldClientModel;
      smartFunctions: SmartFunctionsClientModel;
    },
    { records: RecordsClientModel; fields: FieldsClientModel },
    unknown
  >;
};

export const BulkEditRecordsByPromptContent = (
  props: BulkEditRecordsByPromptContentProps
) => {
  const {
    prompt,
    onPromptChange,
    targetFieldLoadable,
    smartFunctionsLoadable,
    fieldsLoadable,
    usePreviewBulkEditRecordsByPromptExecutable,
  } = props;

  return (
    <WithFallback
      loadables={
        [targetFieldLoadable, fieldsLoadable, smartFunctionsLoadable] as const
      }
    >
      {([targetField, fields, smartFunctions]) => (
        <Flex gap="7" direction="column">
          <Flex direction="column" gap="2">
            <Badge variant="tertiary">Prompt</Badge>
            <PromptInput
              prompt={prompt}
              onPromptChange={onPromptChange}
              fieldsForTypeahead={fields}
              smartFunctions={smartFunctions}
              usePreviewBulkEditRecordsByPromptExecutable={
                usePreviewBulkEditRecordsByPromptExecutable
              }
              targetField={targetField}
            />
          </Flex>
        </Flex>
      )}
    </WithFallback>
  );
};
