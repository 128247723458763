import { LuCode } from "react-icons/lu";
import { IconButton } from "~/components_next/IconButton/IconButton";

type FormatCodeProps = {
  onFormatCode: () => void;
};

const FormatCode = (props: FormatCodeProps) => {
  const { onFormatCode } = props;

  return (
    <IconButton
      tooltip="Format"
      size="sm"
      onClick={onFormatCode}
      icon={<LuCode />}
    />
  );
};

export { FormatCode };
