import { DashboardNotebookCellVariableRequestObject } from "@usemorph/morph-dashboard-types";
import { useQuery } from "react-query";
import { match } from "ts-pattern";
import { Callout } from "~/components_next/Callout";
import { extractErrorDetails } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { Spinner } from "~/components_next/Spinner";
import { useFindViewQuery } from "~/serverStateStore/views";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { UseViewConditionChangeProps } from "./TableViewCellComponent/components/UpdateViewCondition/useViewConditionChange";
import { TableViewCellComponent } from "./TableViewCellComponent/TableViewCellComponent";

type ViewCellComponentProps = {
  viewId: string;
  variables: DashboardNotebookCellVariableRequestObject;
  isReadOnly?: boolean;
  height?: number;
} & UseViewConditionChangeProps;

const ViewCellComponent = (props: ViewCellComponentProps) => {
  const {
    viewId,
    variables,
    isReadOnly = false,
    condition,
    onConditionChange,
    viewSetting,
    height,
  } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const {
    data: viewData,
    status: viewStatus,
    error: viewError,
  } = useQuery(useFindViewQuery({ teamSlug, databaseId, viewId }));

  if (viewStatus === "loading" || viewStatus === "idle") {
    return (
      <Flex height="100%" width="100%" align="center" justify="center">
        <Spinner />
      </Flex>
    );
  }

  if (viewStatus === "error") {
    const { title, description } = extractErrorDetails(viewError);
    return <Callout type="alert" title={title} description={description} />;
  }

  return (
    match(viewData.type)
      .with("table", () => (
        <TableViewCellComponent
          view={viewData}
          variables={variables}
          isReadOnly={isReadOnly}
          condition={condition}
          onConditionChange={onConditionChange}
          viewSetting={viewSetting}
          height={height}
        />
      ))
      // Kanban ViewはTODO
      .with("kanban", () => null)
      .exhaustive()
  );
};

export { ViewCellComponent, type ViewCellComponentProps };
