import { Schema, z } from "zod";

const createViewSettingSchema = <S extends Schema>(dataSchema: S) =>
  z
    .object({
      data: dataSchema,
    })
    .catch({ data: dataSchema.parse({}) });

const parseViewSetting = (rowViewSetting: unknown) => {
  const viewSettingDataSchema = z
    .object({
      prompt: z.string().optional().catch(undefined),
      hiddenFields: z.array(fieldObjectSchema).optional().catch(undefined),
      fieldsOrder: z.array(fieldObjectSchema).optional().catch(undefined),
      titleField: fieldObjectSchema.optional().catch(undefined),
      columnsOrder: z.array(z.string()).optional().catch(undefined),
      hiddenColumns: z.array(z.string()).optional().catch(undefined),
    })
    .catch({ hiddenFields: [] });

  return createViewSettingSchema(viewSettingDataSchema).parse(rowViewSetting);
};

const fieldObjectSchema = z.object({
  name: z.string(),
  originalTableSlug: z.string().optional(),
});

type ViewSettingFieldObject = z.infer<typeof fieldObjectSchema>;

type ViewSetting = ReturnType<typeof parseViewSetting>;

export { parseViewSetting, type ViewSetting, type ViewSettingFieldObject };
