import { useState } from "react";
import { BsUpload, BsXLg } from "react-icons/bs";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { Dialog } from "~/components_next/Dialog";
import { Flex } from "~/components_next/Flex";
import { IconButton } from "~/components_next/IconButton/IconButton";
import { Image } from "~/components_next/Image";
import { useDisclosure } from "~/hooks/useDisclosure";
import { styled } from "~/stitches";

type ImagePreviewProps = {
  url: string;
  onChangeClick?: () => void;
  onRemove?: () => void;
};

const ClickableImagePreview = styled(Image, {
  width: "100%",
  cursor: "pointer",
  borderRadius: "$2",
  boxShadow: "$2",
});

const ActionButtonsContainer = styled(Flex, {
  position: "absolute",
  top: "8px",
  right: "8px",
  backgroundColor: "$bg1",
  borderRadius: "$2",
});

export const ImagePreview = (props: ImagePreviewProps) => {
  const { url, onRemove, onChangeClick } = props;
  const { isOpen, setIsOpen, onOpen } = useDisclosure();
  const [isHover, setIsHover] = useState(false);

  return (
    <>
      <Box
        position="relative"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <ClickableImagePreview src={url} onClick={onOpen} />
        {isHover && (
          <ActionButtonsContainer gap="1" p="1">
            <IconButton
              size="sm"
              icon={<BsUpload />}
              tooltip="Change"
              onClick={onChangeClick}
              variant="secondary"
            />
            <IconButton
              size="sm"
              icon={<BsXLg />}
              tooltip="Clear"
              onClick={onRemove}
              variant="secondary"
            />
          </ActionButtonsContainer>
        )}
      </Box>
      <Dialog.Root open={isOpen} onOpenChange={setIsOpen} size="xl">
        <Dialog.Content>
          <Dialog.Body>
            <Image
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              src={url}
            />
          </Dialog.Body>
          <Dialog.Footer style={{ justifyContent: "center", marginTop: "4px" }}>
            <Dialog.Close>
              <Button variant="actionText" size="md">
                Close
              </Button>
            </Dialog.Close>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};
