import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil";
import { urlSyncEffect } from "recoil-sync";
import { custom, union, literal } from "@recoiljs/refine";
import {
  isFlatFilterCondition,
  FlatFilterCondition,
} from "~/features/Fields/FilterCondition/flatFilterConditionTypes";
import { createTableViewUrlSyncStoreEffectOptions } from "./TableViewUrlSyncStore/createTableViewUrlSyncStoreEffectOptions";
import { useCallback } from "react";

const checker = union(
  literal(null),
  custom(
    (value) => (isFlatFilterCondition(value) ? value : null),
    "value is not a valid value of FlatFilterCondition"
  )
);

const queryFilterConditionFamily = atomFamily<
  FlatFilterCondition | null,
  { viewId: string }
>({
  key: "tableViewFilterCondition",
  default: null,
  effects: [
    urlSyncEffect(
      createTableViewUrlSyncStoreEffectOptions({
        checker,
        key: "tableViewFilterCondition",
        defaultValue: null,
      })
    ),
  ],
});

const useFilterCondition = (viewId: string) => {
  return useRecoilValue(queryFilterConditionFamily({ viewId }));
};

const useSetFilterCondition = (viewId: string) => {
  return useSetRecoilState(queryFilterConditionFamily({ viewId }));
};

const useSetEmptyFilterCondition = (viewId: string) => {
  const setFilterCondition = useSetFilterCondition(viewId);
  return useCallback(
    (key: string) => {
      setFilterCondition({ and: [{ key, value: null, operator: "equal" }] });
    },
    [setFilterCondition]
  );
};

export {
  useFilterCondition,
  useSetFilterCondition,
  useSetEmptyFilterCondition,
};
