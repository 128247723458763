import {
  RowInfoSchemaDataSmartFieldStatus,
  RowInfoSchemaDataTypeCastFailure,
} from "@usemorph/morph-dashboard-types";

type RecordEntryMetaClientModelData = {
  meta: (
    | RowInfoSchemaDataSmartFieldStatus
    | RowInfoSchemaDataTypeCastFailure
  )[];
};

export class RecordEntryMetaClientModel {
  public constructor(readonly data: RecordEntryMetaClientModelData) {}

  public get typeCastFailure():
    | {
        hasError: true;
        errorMessage: string;
        originalValue: unknown;
        originalType: string;
      }
    | {
        hasError: false;
      } {
    const typeCastFailure = this.data.meta.find(
      (meta) => meta.type === "typeCastFailure"
    ) as RowInfoSchemaDataTypeCastFailure | undefined;

    if (typeCastFailure) {
      return {
        hasError: true,
        errorMessage: typeCastFailure.payload.error,
        originalValue: typeCastFailure.payload.originalValue,
        originalType: typeCastFailure.payload.originalType,
      };
    }

    return {
      hasError: false,
    };
  }

  public get isSmartFieldUpdating(): boolean {
    return this.data.meta.some(
      (meta) =>
        meta.type === "smartFieldStatus" && meta.payload.status === "inProgress"
    );
  }

  public get smartFunctionCreditShortageError():
    | {
        hasError: true;
      }
    | {
        hasError: false;
      } {
    const smartFunctionCreditShortageError = this.data.meta.find(
      (meta) =>
        meta.type === "smartFieldStatus" &&
        meta.payload.status === "updatesLimitExceeded"
    );

    if (smartFunctionCreditShortageError) {
      return {
        hasError: true,
      };
    }

    return {
      hasError: false,
    };
  }

  public get smartFunctionExecutionError():
    | {
        hasError: true;
      }
    | {
        hasError: false;
      } {
    const smartFunctionExecutionError = this.data.meta.find(
      (meta) =>
        meta.type === "smartFieldStatus" &&
        meta.payload.status === "executionError"
    );

    if (smartFunctionExecutionError) {
      return {
        hasError: true,
      };
    }

    return {
      hasError: false,
    };
  }
}
