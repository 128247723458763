import { BsPlus } from "react-icons/bs";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { FilterConditionsClientModel } from "~/clientModel/queryConditions/filterConditions";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import {
  SimpleDropdownMenu,
  DropdownMenu,
} from "~/components_next/DropdownMenu";
import { NestLevel } from "./FilterConditionsForm";

type AddFilterDropdownProps = {
  filterConditions: FilterConditionsClientModel;
  onFilterConditionsChange: (
    filterConditions: FilterConditionsClientModel
  ) => void;
  fields: FieldsClientModel;
  nestLevel: NestLevel;
};

export const AddFilterDropdown = (props: AddFilterDropdownProps) => {
  const { filterConditions, fields, nestLevel, onFilterConditionsChange } =
    props;

  const handleAddFilter = () => {
    onFilterConditionsChange(
      filterConditions.addInitialFilterConditionUnit(fields.allFields[0])
    );
  };

  const handleAddNestedFilter = () => {
    onFilterConditionsChange(
      filterConditions.addNestedInitialFilterConditions(fields.allFields[0])
    );
  };

  if (!filterConditions.hasFilterConditions || nestLevel === 1) {
    return (
      <Box style={{ alignSelf: "start" }}>
        <Button variant="tertiary" size="xs" onClick={handleAddFilter}>
          <BsPlus />
          Add
        </Button>
      </Box>
    );
  }

  return (
    <Box style={{ alignSelf: "start" }}>
      <SimpleDropdownMenu
        trigger={
          <Button variant="tertiary" size="xs">
            <BsPlus />
            Add
          </Button>
        }
      >
        <DropdownMenu.Item onClick={handleAddFilter}>
          Add filter rule
        </DropdownMenu.Item>
        <DropdownMenu.Item onClick={handleAddNestedFilter}>
          Add nested filter rule
        </DropdownMenu.Item>
      </SimpleDropdownMenu>
    </Box>
  );
};
