const routes = {
  // db
  databaseHome: "/:teamSlug/db/:databaseId",
  source: "/:teamSlug/db/:databaseId/source/:tableSlug",
  view: "/:teamSlug/db/:databaseId/view/:viewId",
  notebook: "/:teamSlug/db/:databaseId/notebook/:notebookId",
  dataApi: "/:teamSlug/db/:databaseId/data-api/:tableSlug/:dataApiKey",
  widgetDataApi:
    "/:teamSlug/db/:databaseId/widget-data-api/:tableSlug/:dataApiKey",
  dataCollection: "/:teamSlug/db/:databaseId/data-collection/:tableSlug",

  // others
  maintenance: "/maintenance",
  expire: "/expire",

  // public
  publishedView: "/_public/view/:teamSlug/:databaseId/:viewId/:publicApiKey",
  publishedDashboard: "/_public/dashboard/:teamSlug/:databaseId/:publicApiKey",
  publishedCell: "/_public/cell/:teamSlug/:publicApiKey",

  // v1.1
  canvas_v1_1: "/:teamSlug/db/:databaseId/notebook_v1.1/:notebookId",
  source_v1_1: "/:teamSlug/db/:databaseId/source_v1.1/:tableSlug",
} as const;

export { routes };
