import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil";
import { urlSyncEffect } from "recoil-sync";
import { union, literal } from "@recoiljs/refine";
import { createTableViewUrlSyncStoreEffectOptions } from "./TableViewUrlSyncStore/createTableViewUrlSyncStoreEffectOptions";

type QueryMode = "filtersAndSorts" | "sql" | "prompt" | null;

const checker = union(
  literal("filtersAndSorts"),
  literal("sql"),
  literal("prompt"),
  literal(null)
);

const queryModeFamily = atomFamily<QueryMode, { tableSlug: string }>({
  key: "tableViewQueryMode",
  default: null,
  effects: [
    urlSyncEffect(
      createTableViewUrlSyncStoreEffectOptions({
        checker,
        key: "tableViewQueryMode",
        defaultValue: null,
      })
    ),
  ],
});

const useQueryMode = (tableSlug: string) => {
  return useRecoilValue(queryModeFamily({ tableSlug }));
};

const useSetQueryMode = (tableSlug: string) => {
  return useSetRecoilState(queryModeFamily({ tableSlug }));
};

export { useQueryMode, useSetQueryMode, type QueryMode };
