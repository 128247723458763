import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";

export class LastEditedAtFieldTypeClientModel extends FieldTypeClientModelBase {
  public get type(): "lastEditedAt" {
    return "lastEditedAt";
  }

  public get fieldTypeLabel(): "Last Edited At" {
    return "Last Edited At";
  }

  public get isAutoGenerated(): true {
    return true;
  }

  public get isEmbeddable(): false {
    return false;
  }

  public get canBeTargetOfSyncingByComputedField(): true {
    return true;
  }

  public get _updatableFieldTypes() {
    return [];
  }

  public validate(): ValidationResult {
    return { isValid: true };
  }
}
