import { DashboardNotebookCellVariableRequestObject } from "@usemorph/morph-dashboard-types";
import { useQuery } from "react-query";
import { CanvasCellClientModelFactory } from "~/clientModel/canvas/CanvasCellClientModel";
import { useComposeLoadable } from "~/clientModel/loadable";
import { CanvasVariablesValue } from "~/presenters/canvas/common/CanvasVariablesProvider";
import { useExecuteNotebookCellQuery } from "~/serverStateStore";

export const createUseExecuteCellWithVariablesLoadable = (props: {
  teamSlug: string;
  notebookId: string;
}) => {
  const { teamSlug, notebookId } = props;

  return ({
    cellId,
    variables,
  }: {
    cellId: string;
    variables: CanvasVariablesValue[];
  }) =>
    useComposeLoadable(
      useQuery({
        ...useExecuteNotebookCellQuery({
          teamSlug,
          notebookId,
          cellId,
          variables: variables as DashboardNotebookCellVariableRequestObject, // 型パズルエグすぎて一旦キャスト
        }),
        select: (response) => {
          return CanvasCellClientModelFactory.fromNotebookCellResponse(
            response
          );
        },
        enabled: variables.length > 0,
      })
    );
};
