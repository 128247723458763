import { useParams } from "react-router-dom";

const useTeamSlug = () => {
  const { teamSlug } = useParams();

  if (!teamSlug) {
    throw new Error(`teamSlug not found.`);
  }

  return teamSlug;
};

export { useTeamSlug };
