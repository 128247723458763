import { useMutation } from "react-query";
import { useComposeExecutable } from "~/clientModel/executable";
import { MessageClientModelFactory } from "~/clientModel/general";
import { DeleteDatabaseMutationProps, useDeleteDatabaseMutation } from "~/serverStateStore";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

export const useDeleteDatabase = () => {
  const teamSlug = useTeamSlug();

  const deleteDatabaseMutationOptions = useDeleteDatabaseMutation({ teamSlug })
  const deleteDatabaseMutationResult = useMutation({
    ...deleteDatabaseMutationOptions,
    mutationFn: async (params: DeleteDatabaseMutationProps) => {
      const response = await deleteDatabaseMutationOptions.mutationFn(params)
      return MessageClientModelFactory.fromMessageResponse(response)
    }
  })

  const deleteDatabaseExecutable = useComposeExecutable(deleteDatabaseMutationResult)

  return {
    deleteDatabaseExecutable,
  }
}
