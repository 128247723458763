import { useEditorOrThrow } from "../utils";

const useOrderedList = () => {
  const editor = useEditorOrThrow();

  const handleToggle = () => {
    editor.chain().focus().toggleOrderedList().run();
  };

  const isActive = editor.isActive("orderedList");

  const isDisabled = !editor.can().chain().focus().toggleOrderedList().run();

  return {
    handleToggle,
    isActive,
    isDisabled,
  };
};

export { useOrderedList };
