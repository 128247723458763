const meKeys = {
  all: () => ["me"] as const,

  /**
   * get team
   */
  allGetLoginUser: () => [...meKeys.all(), "getLoginUser"] as const,
  getLoginUser: () => [...meKeys.allGetLoginUser()] as const,
};

export { meKeys };
