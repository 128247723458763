import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import { publicViewKeys } from "../keys";
import useApiPublicView from "~/api/useApiPublicView";

type UseGetViewFieldsQueryParams = {
  teamSlug: string;
  databaseId: string;
  viewId: string;
  publicApiKey: string;
};

const useGetPublicViewFieldsQuery = ({
  databaseId,
  teamSlug,
  viewId,
  publicApiKey,
}: UseGetViewFieldsQueryParams) => {
  const { _getPublicViewFields } = useApiPublicView();

  const client = useQueryClient();
  client.setQueryDefaults(
    publicViewKeys.getViewFields({ databaseId, teamSlug, viewId }),
    commonQueryOptions
  );

  return {
    queryKey: publicViewKeys.getViewFields({
      databaseId,
      teamSlug,
      viewId,
    }),
    queryFn: () => {
      return _getPublicViewFields({
        teamSlug,
        viewId,
        publicApiKey,
      });
    },
  };
};

export { useGetPublicViewFieldsQuery };
