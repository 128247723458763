import { forwardRef } from "react";
import { BsX } from "react-icons/bs";
import { Button } from "~/components_next/Button";

type ClearPromptButtonProps = {
  onClick: () => void;
};

const ClearPromptButton = forwardRef<HTMLButtonElement, ClearPromptButtonProps>(
  (props, ref) => {
    const { onClick } = props;

    return (
      <Button ref={ref} variant="ghost" size="xs" onClick={onClick}>
        <BsX />
        Clear Prompt
      </Button>
    );
  }
);

ClearPromptButton.displayName = "ClearPromptButton";

export { ClearPromptButton };
