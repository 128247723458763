import constate from "constate";
import { useMemo } from "react";
import { CollaboratorColorSchemes } from "~/components_next/hooks/coworkerColors";
import { useOthers } from "../liveblocks.config";

const useRealtimeCollaborationUtilContext = () => {
  const others = useOthers();

  const userColorMappings = useMemo(() => {
    return others.map((other, i) => {
      const userId = other.presence.user?.userId;
      const color =
        CollaboratorColorSchemes[i % CollaboratorColorSchemes.length];
      return {
        userId,
        color,
      };
    });
  }, [others]);

  const getUserColor = (userId: string) => {
    const user = userColorMappings.find((user) => user?.userId === userId);
    return user?.color;
  };

  return {
    getUserColor,
  };
};

export const [RealtimeCollaborationUtilContextProvider, useGetUserColor] =
  constate(useRealtimeCollaborationUtilContext, (value) => value.getUserColor);
