import { notebookKeys } from "../keys";
import useApiNotebook from "~/api/useApiNotebook";
import { useQueryClient } from "react-query";

const useListVisualizationPromptConfigsQuery = ({
  teamSlug,
}: {
  teamSlug: string;
}) => {
  const { _getVisualizationPromptConfig } = useApiNotebook();

  const client = useQueryClient();
  client.setQueryDefaults(
    notebookKeys.listVisualizationPromptConfigs({
      teamSlug,
    }),
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
      retry: 1,
    }
  );

  return {
    queryKey: notebookKeys.listVisualizationPromptConfigs({
      teamSlug,
    }),
    queryFn: () => {
      return _getVisualizationPromptConfig({
        teamSlug,
      });
    },
  };
};

export { useListVisualizationPromptConfigsQuery };
