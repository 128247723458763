import { forwardRef } from "react";
import { Button } from "~/components_next/Button";

type PromptButtonProps = {
  isActive?: boolean;
  isDisabled?: boolean;
};

const PromptButton = forwardRef<HTMLButtonElement, PromptButtonProps>(
  (props, ref) => {
    const { isActive, isDisabled, ...rest } = props;

    const buttonVariant = isActive ? "primary" : "tertiary";

    return (
      <Button
        ref={ref}
        variant={buttonVariant}
        size="xs"
        isDisabled={isDisabled}
        {...rest}
      >
        Prompt
      </Button>
    );
  }
);

PromptButton.displayName = "PromptButton";

export { PromptButton };
