import { useState } from "react";
import constate from "constate";

type QueryMode = "filtersAndSorts" | "sql" | "prompt" | null;

const useQueryModeContext = ({
  initialQueryMode,
}: {
  initialQueryMode: QueryMode;
}) => {
  const [queryMode, setQueryMode] = useState<QueryMode | null>(
    initialQueryMode
  );

  return {
    queryMode,
    setQueryMode,
  };
};

export const [QueryModeProvider, useQueryMode, useSetQueryMode] = constate(
  useQueryModeContext,
  (value) => value.queryMode,
  (value) => value.setQueryMode
);

export type { QueryMode };
