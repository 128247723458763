import { SimpleFieldListResponse } from "@usemorph/morph-dashboard-types";
import { useCallback, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import useApiPublicView from "~/api/useApiPublicView";
import { CsvDownloadFieldsClientModel } from "~/clientModel/csvDownload/csvDownloadFields";
import { CsvDownloadFieldsClientModelDefactory } from "~/clientModel/csvDownload/csvDownloadFields/CsvDownloadFieldsClientModelDefactory";
import { useComposeExecutable } from "~/clientModel/executable";
import {
  FieldsClientModel,
  FieldsClientModelFactory,
} from "~/clientModel/fields";
import { useComposeLoadable } from "~/clientModel/loadable";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import {
  FilterConditionsClientModel,
  FilterConditionsClientModelDefactory,
} from "~/clientModel/queryConditions/filterConditions";
import {
  PaginationClientModel,
  PaginationClientModelDefactory,
} from "~/clientModel/queryConditions/pagination";
import {
  SortConditionsClientModel,
  SortConditionsClientModelDefactory,
} from "~/clientModel/queryConditions/sortConditions";
import {
  RecordsClientModel,
  RecordsClientModelFactory,
} from "~/clientModel/records";
import { useErrorToast } from "~/components_next/Error";
import { TablePaginationLimit } from "~/presenters/sourceAndViews/common/components/bottomItems/TablePagination";
import {
  useGetPublicViewFieldsQuery,
  useQueryPublicViewRecordsQuery,
} from "~/serverStateStore/publicViews";

const createUseRecordsWithConditionLoadable = (props: {
  teamSlug: string;
  apiKey: string;
}): UseLoadable<
  {
    viewId: string;
    filterConditions: FilterConditionsClientModel;
    sortConditions: SortConditionsClientModel;
    pagination: PaginationClientModel<TablePaginationLimit>;
  },
  RecordsClientModel
> => {
  const { teamSlug, apiKey } = props;

  return ({
    viewId,
    filterConditions,
    sortConditions,
    pagination,
  }: {
    viewId: string;
    filterConditions: FilterConditionsClientModel;
    sortConditions: SortConditionsClientModel;
    pagination: PaginationClientModel<TablePaginationLimit>;
  }) => {
    return useComposeLoadable(
      useQuery({
        ...useQueryPublicViewRecordsQuery({
          teamSlug,
          databaseId: "public",
          viewId,
          select: ["*"],
          publicApiKey: apiKey,
          filter:
            FilterConditionsClientModelDefactory.toRecordFilterCondition(
              filterConditions
            ),
          sort: [
            ...SortConditionsClientModelDefactory.toRecordSortConditionUnits(
              sortConditions
            ),
          ],
          limit: PaginationClientModelDefactory.toLimit(pagination),
          skip: PaginationClientModelDefactory.toSkip(pagination),
        }),
        select: (response) =>
          RecordsClientModelFactory.createFromQueryRecordListResponseWithFields(
            response
          ),
      })
    );
  };
};

const createUseFieldsWithConditionLoadable = (props: {
  teamSlug: string;
  apiKey: string;
}): UseLoadable<{ viewId: string }, FieldsClientModel> => {
  const { teamSlug, apiKey } = props;

  return ({ viewId }: { viewId: string }) =>
    useComposeLoadable(
      useQuery({
        ...useGetPublicViewFieldsQuery({
          teamSlug,
          databaseId: "",
          viewId,
          publicApiKey: apiKey,
        }),
        select: useCallback(
          (data: SimpleFieldListResponse) =>
            FieldsClientModelFactory.createFromSimpleFields(data.fields),
          []
        ),
      })
    );
};

const useDownloadCsvExecutable = ({
  teamSlug,
  apiKey,
  viewId,
}: {
  teamSlug: string;
  apiKey: string;
  viewId: string;
}) => {
  const { errorToast } = useErrorToast({});

  const { _donwloadPublicViewRecords } = useApiPublicView();

  return useComposeExecutable(
    useMutation({
      mutationFn: async ({
        csvDownloadFields,
      }: {
        csvDownloadFields: CsvDownloadFieldsClientModel;
      }) => {
        const select =
          CsvDownloadFieldsClientModelDefactory.toQueryRecordRequestBodyObjectSelect(
            csvDownloadFields
          );
        const format =
          CsvDownloadFieldsClientModelDefactory.toRecordFormatConditions(
            csvDownloadFields
          );

        const { url } = await _donwloadPublicViewRecords({
          teamSlug,
          publicApiKey: apiKey,
          viewId,
          body: {
            select,
            format,
          },
        });

        return { url };
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

const createUseDownloadCsvExecutable = (props: {
  teamSlug: string;
  apiKey: string;
}) => {
  return ({ viewId }: { viewId: string }) => {
    return useDownloadCsvExecutable({
      teamSlug: props.teamSlug,
      apiKey: props.apiKey,
      viewId,
    });
  };
};

const createUseDownloadCsvPreviewRecordsLoadable = (props: {
  teamSlug: string;
  apiKey: string;
}) => {
  const { teamSlug, apiKey } = props;

  return ({
    viewId,
    csvDownloadFields,
  }: {
    viewId: string;
    csvDownloadFields: CsvDownloadFieldsClientModel;
  }) => {
    return useComposeLoadable(
      useQuery({
        ...useQueryPublicViewRecordsQuery({
          teamSlug,
          databaseId: "public",
          viewId,
          publicApiKey: apiKey,
          select: ["*"],
          format:
            CsvDownloadFieldsClientModelDefactory.toRecordFormatConditions(
              csvDownloadFields
            ),
        }),
        select: (data) => {
          return RecordsClientModelFactory.createFromQueryRecordListResponseWithFields(
            data
          );
        },
      })
    );
  };
};

export const useTableViewProps = ({
  teamSlug,
  apiKey,
}: {
  teamSlug: string;
  apiKey: string;
}) => {
  const useRecordsWithConditionLoadable = useMemo(() => {
    return createUseRecordsWithConditionLoadable({
      teamSlug,
      apiKey,
    });
  }, [teamSlug, apiKey]);

  const useFieldsWithConditionLoadable = useMemo(() => {
    return createUseFieldsWithConditionLoadable({
      teamSlug,
      apiKey,
    });
  }, [teamSlug, apiKey]);

  const useDownloadCsvExecutable = useMemo(() => {
    return createUseDownloadCsvExecutable({
      teamSlug,
      apiKey,
    });
  }, [teamSlug, apiKey]);

  const useDownloadCsvPreviewRecordsLoadable = useMemo(() => {
    return createUseDownloadCsvPreviewRecordsLoadable({
      teamSlug,
      apiKey,
    });
  }, [teamSlug, apiKey]);

  return {
    useRecordsWithConditionLoadable,
    useFieldsWithConditionLoadable,
    useDownloadCsvExecutable,
    useDownloadCsvPreviewRecordsLoadable,
  };
};
