import {
  RecordEmbeddingSortConditionUnit,
  RecordSortConditionUnit,
} from "@usemorph/morph-dashboard-types";

const getRecordSortConditionUnits = (
  sorts:
    | Array<RecordSortConditionUnit | RecordEmbeddingSortConditionUnit>
    | undefined
) => {
  return (
    sorts?.filter((sort): sort is RecordSortConditionUnit => {
      if ("direction" in sort) return true;
      return false;
    }) ?? []
  );
};

const getRecordEmbbedingsSortConditionUnits = (
  sorts:
    | Array<RecordSortConditionUnit | RecordEmbeddingSortConditionUnit>
    | undefined
) => {
  return (
    sorts?.filter((sort): sort is RecordEmbeddingSortConditionUnit => {
      if ("value" in sort) return true;
      return false;
    }) ?? []
  );
};

const sortObjectUtils = {
  getRecordSortConditionUnits,
  getRecordEmbbedingsSortConditionUnits,
};

export { sortObjectUtils };
