import { Table as RadixThemeTable } from "@radix-ui/themes";

import { match } from "ts-pattern";
import { MorphThemeSize } from "../commonProps.type";
import { RadixComponentObjToPropsObj } from "../typeUtils";

type RadixTablePropsDict = RadixComponentObjToPropsObj<typeof RadixThemeTable>;

/**
 * Table Root
 */

type TableRootProps = {
  size?: MorphThemeSize;
} & Omit<RadixTablePropsDict["Root"], "size" | "variant">;

const TableRoot = (props: TableRootProps) => {
  const { size = "md", ...radixProps } = props;

  const radixSize = match(size)
    .with("xs", () => "1" as const)
    .with("sm", () => "1" as const)
    .with("md", () => "2" as const)
    .with("lg", () => "3" as const)
    .with("xl", () => "3" as const)
    .exhaustive();

  return (
    <RadixThemeTable.Root size={radixSize} variant="ghost" {...radixProps} />
  );
};

/**
 * Table Header
 */

type TableHeaderProps = RadixTablePropsDict["Header"];

const TableHeader = (props: TableHeaderProps) => {
  return <RadixThemeTable.Header {...props} />;
};

/**
 * Table Body
 */

type TableBodyProps = RadixTablePropsDict["Body"];

const TableBody = (props: TableBodyProps) => {
  return <RadixThemeTable.Body {...props} />;
};

/**
 * Table Row
 */

type TableRowProps = RadixTablePropsDict["Row"];

const TableRow = (props: TableRowProps) => {
  return <RadixThemeTable.Row {...props} />;
};

/**
 * Table Cell
 */

type TableCellProps = RadixTablePropsDict["Cell"];

const TableCell = (props: TableCellProps) => {
  return <RadixThemeTable.Cell {...props} />;
};

/**
 * Column Header Cell
 */

type ColumnHeaderCellProps = RadixTablePropsDict["ColumnHeaderCell"];

const ColumnHeaderCell = (props: ColumnHeaderCellProps) => {
  return <RadixThemeTable.ColumnHeaderCell {...props} />;
};

/**
 * Column Row Header Cell
 */

type RowHeaderCellProps = RadixTablePropsDict["RowHeaderCell"];

const RowHeaderCell = (props: RowHeaderCellProps) => {
  return <RadixThemeTable.RowHeaderCell {...props} />;
};

const Table = {
  Root: TableRoot,
  Header: TableHeader,
  Body: TableBody,
  Row: TableRow,
  Cell: TableCell,
  ColumnHeaderCell: ColumnHeaderCell,
  RowHeaderCell: RowHeaderCell,
};

export { Table };
