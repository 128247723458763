import useApiCanvasStyle from "~/api/useApiCanvasStyle";

const usePreviewColorsMutation = ({ teamSlug }: { teamSlug: string }) => {
  const { _previewColors } = useApiCanvasStyle();

  // なにもinvalidateしない
  return {
    mutationFn: ({ colorCodes }: { colorCodes: string[] }) => {
      return _previewColors({
        colorCodes,
        teamSlug,
      });
    },
  };
};

export { usePreviewColorsMutation };
