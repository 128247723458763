import { match } from "ts-pattern";

export type RssField = {
  title?: string;
  link?: string;
  description?: string;
  pubDate?: string;
  image?: string;
};

export class RssFieldClientModel {
  readonly #data: RssField;

  constructor(data: RssField) {
    this.#data = data
  }

  public get title(): string | undefined {
    return this.#data.title
  }

  public get link(): string | undefined {
    return this.#data.link
  }

  public get description(): string | undefined {
    return this.#data.description
  }

  public get pubDate(): string | undefined {
    return this.#data.pubDate
  }

  public get image(): string | undefined {
    return this.#data.image
  }

  public update({ key, value }: {
    key: "title" | "description" | "image" | "link" | "pubDate",
    value: string
  }): RssFieldClientModel {
    return match(key)
      .with('title', () => new RssFieldClientModel({
        title: value,
        description: this.description,
        link: this.link,
        image: this.image,
        pubDate: this.pubDate,
      }))
      .with('description', () => new RssFieldClientModel({
        title: this.title,
        description: value,
        link: this.link,
        image: this.image,
        pubDate: this.pubDate,
      }))
      .with('image', () => new RssFieldClientModel({
        title: this.title,
        description: this.description,
        link: this.link,
        image: value,
        pubDate: this.pubDate,
      }))
      .with('link', () => new RssFieldClientModel({
        title: this.title,
        description: this.description,
        link: value,
        image: this.image,
        pubDate: this.pubDate,
      }))
      .with('pubDate', () => new RssFieldClientModel({
        title: this.title,
        description: this.description,
        link: this.link,
        image: this.image,
        pubDate: value,
      }))
      .exhaustive()
  }

  public updateTitle(title: string): RssFieldClientModel {
    return new RssFieldClientModel({
      title,
      description: this.description,
      link: this.link,
      image: this.image,
      pubDate: this.pubDate,
    })
  }

  public updateLink(link: string): RssFieldClientModel {
    return new RssFieldClientModel({
      title: this.title,
      description: this.description,
      link,
      image: this.image,
      pubDate: this.pubDate,
    })
  }

  public updateDescription(description: string): RssFieldClientModel {
    return new RssFieldClientModel({
      title: this.title,
      description,
      link: this.link,
      image: this.image,
      pubDate: this.pubDate,
    })
  }

  public updatePubDate(pubDate: string): RssFieldClientModel {
    return new RssFieldClientModel({
      title: this.title,
      description: this.description,
      link: this.link,
      image: this.image,
      pubDate,
    })
  }

  public updateImage(image: string): RssFieldClientModel {
    return new RssFieldClientModel({
      title: this.title,
      description: this.description,
      link: this.link,
      image,
      pubDate: this.pubDate,
    })
  }
}
