import { FieldClientModel } from "~/clientModel/fields/field";
import {
  UpdatableRecordEntryClientModelBaseConstructorParams,
  UpdatableRecordEntryClientModelBase,
} from "../RecordEntryClientModelBase";
import { zValidate, zAllowNullIfNullable } from "../validatorsUtil";
import { z } from "zod";

type JsonRecordEntryClientModelData =
  UpdatableRecordEntryClientModelBaseConstructorParams;

export class JsonRecordEntryClientModel extends UpdatableRecordEntryClientModelBase<
  string | null
> {
  constructor(data: JsonRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "json" {
    return "json";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([z.string(), zAllowNullIfNullable(field)]),
      this.data.rawValue
    );
  }

  public getValidatedDisplayValue = this.getValidatedValue;

  public updateValue(value: string | null): JsonRecordEntryClientModel {
    return new JsonRecordEntryClientModel({
      key: this.data.key,
      rawValue: value,
      meta: this.data.meta,
    });
  }
}
