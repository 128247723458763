import { SelectRoot, SelectTrigger, SelectContent, SelectItem } from "@radix-ui/themes";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
import { Spinner } from "~/components_next/Spinner";
import { Text } from "~/components_next/Text";
import { useListDynamicValueQuery } from "~/serverStateStore/template/queries/useListDynamicValue";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import * as Form from "@radix-ui/react-form";

interface IntegrationDynamicInputProps {
  integrationKey: string;
  templateAuthId?: string;
  integrationId: string;
  templateId: string;
  requestData: { [key: string]: unknown };
  dependsOn?: string[];
  description: string;
  label: string;
  required: boolean;
  descritpionUrlLabel?: string | undefined;
  descritpionUrl?: string | undefined;
  onChangeValue: ({ integrationKey, value }: { integrationKey: string, value: unknown }) => void;
}

export const IntegrationDynamicInput = (props: IntegrationDynamicInputProps) => {
  const {
    integrationKey,
    templateAuthId,
    integrationId,
    templateId,
    requestData,
    dependsOn,
    description,
    label,
    required,
    descritpionUrlLabel,
    descritpionUrl,
    onChangeValue,
  } = props
  const teamSlug = useTeamSlug()

  const isDependsOnInRequestData = useMemo(() => {
    if (dependsOn) {
      return dependsOn.every(key => key in requestData)
    } else {
      return true
    }
  }, [dependsOn, requestData])

  const dependentValues = useMemo(() => {
    if (dependsOn && isDependsOnInRequestData) {
      const filteredData: { [key: string]: unknown } = {};
      dependsOn.forEach(key => {
        if (requestData[key] !== undefined) {
          filteredData[key] = requestData[key];
        }
      });
      return filteredData;
    } else {
      return {}
    }
  }, [isDependsOnInRequestData, requestData, dependsOn])

  const { data: listDynamicValue, isLoading: isLoadingDynamicValue } = useQuery({
    ...useListDynamicValueQuery({
      integrationId,
      templateId,
      teamSlug,
      targetKey: integrationKey,
      dependentValues,
      templateAuthId,
    }),
    enabled: isDependsOnInRequestData && !!dependentValues,
  })

  return (
    <Form.Field name={integrationKey}>
      <Box mb="3">
        <Flex justify="between">
          <Flex align="center">
            <Text variant="default" fontWeight="light" mb="1" mr="1">{label}</Text>
            {
              isLoadingDynamicValue && (
                <Spinner size="14" />
              )
            }
          </Flex>
          <Form.Message
            style={{
              fontSize: "13px",
              color: "red",
              opacity: 0.8,
            }}
            match="valueMissing"
          >
            Please enter {label}
          </Form.Message>
        </Flex>

        <Form.Control asChild>
          <SelectRoot
            required={required}
            disabled={!isDependsOnInRequestData}
            value={integrationKey in requestData ? String(requestData[integrationKey]) : undefined}
            onValueChange={(el) => {
              onChangeValue({ integrationKey, value: el })
            }}
          >
            <SelectTrigger style={{ minWidth: '200px' }} />
            <SelectContent>
              {listDynamicValue?.items.map((val, ind) => (
                <SelectItem key={ind} value={String(val.value)}>
                  <Flex gap="4" align="center" pl="3">
                    {val.label}
                  </Flex>
                </SelectItem>
              ))}
            </SelectContent>
          </SelectRoot>
        </Form.Control>

        <Box>
          <Text variant='description'>{description}</Text>
        </Box>
        <Flex>
          <Box>
            <Text variant='default'>{descritpionUrlLabel}</Text>
          </Box>
          <Box>
            <Text variant='description' ml="2">{descritpionUrl}</Text>
          </Box>
        </Flex>
      </Box>
    </Form.Field>
  )
}
