import { Callout as RadixThemeCallout } from "@radix-ui/themes";
import { ForwardedRef, forwardRef, ReactNode } from "react";
import { BsExclamationTriangle, BsInfoCircle } from "react-icons/bs";
import { match } from "ts-pattern";
import { styled } from "~/stitches";
import { MorphThemeSize } from "../commonProps.type";
import { Text } from "~/components_next/Text";
import { RadixComponentObjToPropsObj } from "../typeUtils";

const Title = styled(Text, {
  whiteSpace: "nowrap",
});

type RadixCalloutProps = RadixComponentObjToPropsObj<typeof RadixThemeCallout>;

type CalloutProps = {
  type: "info" | "success" | "warning" | "alert";
  size?: MorphThemeSize;
  title?: string;
  description: ReactNode;
};

const getSize = (size?: MorphThemeSize): RadixCalloutProps["Root"]["size"] => {
  return match(size)
    .with("xs", () => "1" as const)
    .with("sm", () => "1" as const)
    .with("md", () => "2" as const)
    .with("lg", () => "3" as const)
    .with("xl", () => "3" as const)
    .otherwise(() => "2" as const);
};

const getColor = (
  type: CalloutProps["type"]
): RadixCalloutProps["Root"]["color"] => {
  return match(type)
    .with("success", () => "blue" as const)
    .with("info", () => "blue" as const)
    .with("warning", () => "yellow" as const)
    .with("alert", () => "red" as const)
    .exhaustive();
};

const getRole = (
  type: CalloutProps["type"]
): RadixCalloutProps["Root"]["role"] => {
  return match(type)
    .with("alert", () => "alert" as const)
    .otherwise(() => undefined);
};

const getIcon = (
  type: CalloutProps["type"]
): RadixCalloutProps["Icon"]["children"] => {
  return match(type)
    .with("success", () => <BsInfoCircle />)
    .with("info", () => <BsInfoCircle />)
    .with("warning", () => <BsInfoCircle />)
    .with("alert", () => <BsExclamationTriangle />)
    .exhaustive();
};

const _Callout = (props: CalloutProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { type, size = "md", title, description } = props;

  const radixCalloutSize = getSize(size);

  const color = getColor(type);
  const role = getRole(type);

  const icon = getIcon(type);

  return (
    <RadixThemeCallout.Root
      ref={ref}
      size={radixCalloutSize}
      color={color}
      role={role}
      highContrast
      style={{ display: "flex", alignItems: "center" }}
    >
      <RadixThemeCallout.Icon>{icon}</RadixThemeCallout.Icon>
      {title && <Title fontWeight="bold">{title}</Title>}

      {typeof description === "string" ? (
        <RadixThemeCallout.Text>{description}</RadixThemeCallout.Text>
      ) : (
        description
      )}
    </RadixThemeCallout.Root>
  );
};

const Callout = forwardRef(_Callout);

export { Callout, type CalloutProps };
