import { Code } from "@radix-ui/themes";
import { Button } from "~/components_next/Button";
import { Flex } from "~/components_next/Flex";
import { Text } from "~/components_next/Text";

type CodePreviewProps = {
  value: string | null | undefined;
  onEditClick: () => void;
  isEditDisabled: boolean;
  size?: "xs" | "sm" | "md";
};

const CodePreview = (props: CodePreviewProps) => {
  const { value, onEditClick, isEditDisabled, size } = props;

  return (
    <Flex direction="column" gap="2">
      <pre>
        <Code
          style={{
            width: "100%",
            display: "block",
            padding: "12px",
            minHeight: "30px",
          }}
        >
          {value ?? (
            <Text variant="description" colorOverride="gray">
              Empty
            </Text>
          )}
        </Code>
      </pre>
      <Button
        size="xs"
        variant="tertiary"
        isDisabled={isEditDisabled}
        onClick={onEditClick}
      >
        Edit
      </Button>
    </Flex>
  );
};

export { CodePreview };
