import { UseExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { Loadable } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { RecordEntryClientModel } from "~/clientModel/records/record/recordEntry";
import { FooterButtonBase } from "../../common/FooterButtonBase";

type BulkEditRecordsByRowFooterProps = {
  editingRecordEntryLoadable: Loadable<RecordEntryClientModel>;
  fieldsLoadable: Loadable<FieldsClientModel, unknown>;
  useBulkEditRecordsByRowExecutable: UseExecutable<
    void,
    {
      recordEntry: RecordEntryClientModel;
      field: FieldClientModel;
    },
    unknown,
    unknown
  >;
};

export const BulkEditRecordsByRowFooter = (
  props: BulkEditRecordsByRowFooterProps
) => {
  const {
    editingRecordEntryLoadable,
    fieldsLoadable,
    useBulkEditRecordsByRowExecutable,
  } = props;

  const bulkEditRecordsByRowExecutable = useBulkEditRecordsByRowExecutable();

  const getIsDisabled = ({
    fields,
    editingRecordEntry,
  }: {
    fields: FieldsClientModel;
    editingRecordEntry: RecordEntryClientModel;
  }) => {
    const field = fields.getFieldByFieldName(editingRecordEntry.key);

    if (!field) {
      return true;
    }

    return !editingRecordEntry.getValidatedValue(field).isValid;
  };

  return (
    <WithFallback
      loadables={[fieldsLoadable, editingRecordEntryLoadable] as const}
    >
      {([fields, editingRecordEntry]) => (
        <FooterButtonBase
          isDisabled={getIsDisabled({
            fields,
            editingRecordEntry,
          })}
          isLoading={bulkEditRecordsByRowExecutable.isExecuting}
          onClick={() =>
            bulkEditRecordsByRowExecutable.execute({
              recordEntry: editingRecordEntry,
              field: fields.getFieldByFieldNameOrThrow(editingRecordEntry.key),
            })
          }
        >
          Update All
        </FooterButtonBase>
      )}
    </WithFallback>
  );
};
