import { useQuery } from "react-query";
import {
  FieldsClientModel,
  FieldsClientModelFactory,
} from "~/clientModel/fields";
import { Loadable, useComposeLoadable } from "~/clientModel/loadable";
import { useListSimpleFieldsQuery } from "~/serverStateStore";

type useFieldsLoadableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useFieldsLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: useFieldsLoadableParams): Loadable<FieldsClientModel> => {
  return useComposeLoadable(
    useQuery({
      ...useListSimpleFieldsQuery({
        teamSlug,
        databaseId,
        tableSlug,
      }),
      select: (response) =>
        FieldsClientModelFactory.createFromSimpleFields(response.fields),
    })
  );
};

export const createUseFieldsLoadable = ({
  teamSlug,
  databaseId,
}: {
  teamSlug: string;
  databaseId: string;
}) => {
  return ({ tableSlug }: { tableSlug: string }) =>
    useFieldsLoadable({ teamSlug, databaseId, tableSlug });
};
