import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { Loadable } from "~/clientModel/loadable";
import { Box } from "~/components_next/Box";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { SQLButton } from "../buttons/SQLButton";
import { SQLForm } from "./SQLForm";

type SQLDropdownProps = {
  sql: string;
  isActive: boolean;
  isSqlRunning: boolean;
  onSqlRun: () => void;
  onSqlChange: (sql: string) => void;
  suggestedFieldsLoadable: Loadable<FieldsClientModel>;
};

export const SQLDropdown = (props: SQLDropdownProps) => {
  const {
    sql,
    isActive,
    onSqlRun,
    onSqlChange,
    isSqlRunning,
    suggestedFieldsLoadable,
  } = props;

  return (
    <SimpleDropdownMenu trigger={<SQLButton isActive={isActive} />}>
      <Box css={{ minWidth: 500, overflow: "auto" }}>
        <SQLForm
          sql={sql}
          onSqlRun={onSqlRun}
          onSqlChange={onSqlChange}
          // 補完はなくてもいいので、fallbackはなし
          suggestedFields={suggestedFieldsLoadable.data}
          isSqlRunning={isSqlRunning}
        />
      </Box>
    </SimpleDropdownMenu>
  );
};
