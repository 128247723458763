import { FC, useMemo } from "react";
import {
  SelectRoot,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "~/components_next/Select";
import { Image } from "~/components_next/Image";
import { Flex } from "~/components_next/Flex";
import { OtherServiceType, useImportOtherServiceModal } from "../useImportOtherServiceModal";
import { Text } from "~/components_next/Text";
import { Grid } from "~/components_next/Grid";
import { Badge } from "@radix-ui/themes";

export type TemplateSelectProps = {
  templates: OtherServiceType[];
  selected: OtherServiceType | undefined;
  setTemplate: (template: OtherServiceType | null) => void;
};

export const TemplateSelect: FC<TemplateSelectProps> = ({
  templates,
  setTemplate,
  selected,
}: TemplateSelectProps) => {
  const { setOtherService } = useImportOtherServiceModal();

  const value = useMemo(() => selected?.templateId ?? null, [selected]);

  const onChange = (templateId: string | null) => {
    const selectedTemplate = templates.find(
      (tmp) => tmp.templateId === templateId
    );
    if (selectedTemplate) {
      setTemplate(selectedTemplate);
      setOtherService(JSON.stringify(selectedTemplate));
    }
  };

  return (
    <>
      <SelectRoot
        value={value}
        variant="primary"
        size="md"
        onChange={onChange}
        isClearable
      >
        <SelectTrigger />
        <SelectContent>
          {templates.map((temp) => (
            <SelectItem key={temp.templateId} value={temp.templateId}>
              <Flex gap="4" align="center" pl="3">
                <Image
                  src={temp.logoUrl}
                  alt={temp.templateName}
                  width="24px"
                  height="24px"
                  style={{ objectFit: "contain" }}
                  referrerPolicy="no-referrer"
                />
                {temp.templateName}
                {
                  temp.isPremium && (
                    <Badge>Premium</Badge>
                  )
                }
              </Flex>
            </SelectItem>
          ))}
        </SelectContent>
      </SelectRoot>

      {!selected && (
        <Grid columns="5" gap="3" mt="5">
          {templates.map((temp) => {
            return (
              <Flex
                key={temp.templateId}
                direction="column"
                justify="center"
                align="center"
                height="100%"
                onClick={() => onChange(temp.templateId)}
                hoverable
                p="3"
                css={{
                  width: "100%",
                  borderRadius: 5,
                }}
              >
                <Image
                  src={temp.logoUrl}
                  alt={temp.templateName}
                  width="60%"
                  style={{ objectFit: "contain" }}
                  referrerPolicy="no-referrer"
                />
                <Flex justify="center" mt="3">
                  <Text fontWeight="medium" align="center">
                    {temp.templateName}
                  </Text>
                </Flex>
                {
                  temp.isPremium && (
                    <Badge>Premium</Badge>
                  )
                }
              </Flex>
            );
          })}
        </Grid>
      )}
    </>
  );
};
