import { SimpleField } from "@usemorph/morph-dashboard-types";
import { AllRecordModelValueUnion, RecordModel } from "~/features/RecordModel";
import { RecordsKanbanBaseContextProvider } from "./context/RecordsKanbanBaseContext";
import { RecordsKanbanColumns } from "./RecordsKanbanColumn/RecordsKanbanColumns";

type GroupingValue = AllRecordModelValueUnion["value"];

type KanbanValues = {
  groupingFieldName: string;
  groupingFieldValue: GroupingValue;
  records: RecordModel[];
};

type RecordsKanbanBaseProps = {
  values: KanbanValues[];
  titleFieldName: string;
  propertyFieldNames: string[];
  groupingField: SimpleField; // callbackで型を戻すのに必要
  columnValues?: string[]; // なければ全て表示
  onUpdateRecord?: (
    record: RecordModel,
    beforeCell?: RecordModel | null
  ) => void; // nullの場合は先頭
  onReorderRecord?: (
    record: RecordModel,
    beforeCell: RecordModel | null
  ) => void; // nullの場合は先頭
  onCreateClicked?: (groupingValue: GroupingValue) => void;
  onClickRecord?: (record: RecordModel) => void;
  onUpdateColumnValues?: (columnValues: string[]) => void;
  dragFallbackMessage?: string;
};

const RecordsKanbanBase = (props: RecordsKanbanBaseProps) => {
  return (
    <>
      <RecordsKanbanBaseContextProvider {...props}>
        <RecordsKanbanColumns />
      </RecordsKanbanBaseContextProvider>
    </>
  );
};

export { RecordsKanbanBase, type KanbanValues, type RecordsKanbanBaseProps };
