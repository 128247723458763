import {
  DashboardViewResponse,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { useCallback } from "react";
import { useMutation } from "react-query";
import {
  createFieldsOrderFromViewFields,
  createGroupKeyFromViewMetaFormState,
  getViewConditionType,
} from "~/features/View";
import { convertSimpleFieldsToSelect } from "~/features/View/utils/convertSimpleFieldsToSelect";
import { useCreateViewMutation } from "~/serverStateStore/views";
import { FormValue } from "../ViewToolbar";

type UseDuplicateViewParams = {
  teamSlug: string;
  databaseId: string;
  viewId: string;
  formValue: FormValue | undefined;
  view: DashboardViewResponse | undefined;
  viewFields: SimpleField[] | undefined;
};

const useDuplicateView = ({
  teamSlug,
  databaseId,
  formValue,
  view,
  viewFields,
}: UseDuplicateViewParams) => {
  const { mutateAsync: _createView, isLoading: isCreating } = useMutation(
    useCreateViewMutation({
      teamSlug,
      databaseId,
    })
  );

  const createView = useCallback(async () => {
    if (!view || !viewFields || !formValue) {
      throw new Error("Failed to create view");
    }

    const viewConditionType = getViewConditionType(view);

    if (viewConditionType !== formValue.viewType) {
      throw new Error("Failed to create view");
    }

    if (formValue.viewType === "query") {
      const {
        value: { meta, join, filter, selectedFields, sort },
      } = formValue;
      const { name, type, isPrivate } = meta;

      const select = convertSimpleFieldsToSelect(
        selectedFields,
        view.tableSlug
      );

      return await _createView({
        name,
        type,
        isPrivate,
        condition: {
          from: view.condition.from,
          select,
          join,
          filter: filter ?? undefined,
          sort: sort ? [sort] : undefined,
          groupKey: createGroupKeyFromViewMetaFormState(meta, view.tableSlug),
          reorderable: meta.type === "kanban" ? meta.reorderable : undefined,
        },
        setting: {
          data: {
            hiddenFields: [],
            fieldsOrder: createFieldsOrderFromViewFields(selectedFields),
          },
        },
      });
    } else {
      const {
        value: { meta, sql, setting },
      } = formValue;
      const { name, type, isPrivate } = meta;
      return await _createView({
        name,
        type,
        isPrivate,
        condition: {
          from: sql,
          select: view.condition.select,
          groupKey: createGroupKeyFromViewMetaFormState(meta, view.tableSlug),
        },
        setting,
      });
    }
  }, [_createView, view, viewFields, formValue]);

  return {
    createView,
    isCreating,
  };
};

export {};

export { useDuplicateView };
