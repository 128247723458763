import {
  DashboardFieldListResponse,
  DashboardQueryRecordHistoryRequestBody,
  DashboardRecordHistoryListResponse,
  QueryRecordListResponse,
  QueryRecordRequestBody,
  QueryRecordWithFieldsResponse,
  RecordFilterCondition,
  QueryRecordRequestBodyObject,
  DeleteRecordRequestBody,
  DashboardRecordTrashListResponse,
  CreateRecordWithPromptResponse,
  CreateRecordRequestBody,
  UpdateRecordRequestBody,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";
import {
  transformNumberToStringForCreateRecordWithPromptResponse,
  transformNumberToStringForQueryRecordListResponse,
  transformNumberToStringForRecordTrashListResponse,
} from "./utils/transformers";

type DashboardGeneralResponse = {
  message: string;
};
type DashboardCsvUrlResponse = {
  url: string;
};

export default function useApiRecord() {
  const { executeRequest } = useApi();

  const _countRecords = async ({
    databaseId,
    tableSlug,
    branchSlug,
    teamSlug,
  }: {
    databaseId: string;
    tableSlug: string;
    branchSlug?: string;
    teamSlug: string;
  }): Promise<DashboardFieldListResponse> => {
    return executeRequest(
      "GET",
      `/${databaseId}/record/${tableSlug}/count`,
      { branchSlug },
      {
        teamSlug,
      },
      undefined
    );
  };

  const _createRecord = async ({
    databaseId,
    tableSlug,
    branchSlug,
    teamSlug,
    body,
  }: {
    databaseId: string;
    tableSlug: string;
    branchSlug?: string;
    teamSlug: string;
    body: CreateRecordRequestBody;
  }): Promise<QueryRecordListResponse> => {
    return executeRequest(
      "POST",
      `/${databaseId}/record/${tableSlug}`,
      { branchSlug },
      {
        teamSlug,
      },
      body
    );
  };

  const _createRecordObjectWithPrompt = async ({
    teamSlug,
    databaseId,
    tableSlug,
    prompt,
  }: {
    teamSlug: string;
    databaseId: string;
    tableSlug: string;
    prompt: string;
  }) => {
    return executeRequest<CreateRecordWithPromptResponse>(
      "POST",
      `/${databaseId}/record/${tableSlug}/prompt`,
      undefined,
      {
        teamSlug,
      },
      {
        prompt,
      },
      {
        transformResponse: [
          transformNumberToStringForCreateRecordWithPromptResponse,
        ],
      }
    );
  };

  const _getRecords = async ({
    databaseId,
    tableSlug,
    branchSlug,
    teamSlug,
    requestBody,
  }: {
    databaseId: string;
    tableSlug: string;
    branchSlug?: string;
    teamSlug: string;
    requestBody: QueryRecordRequestBody;
  }): Promise<QueryRecordWithFieldsResponse> => {
    return executeRequest(
      "POST",
      `/${databaseId}/record/${tableSlug}/query`,
      { branchSlug, withFields: true },
      {
        teamSlug,
      },
      {
        ...requestBody,
      },
      {
        transformResponse: [transformNumberToStringForQueryRecordListResponse],
      }
    );
  };

  const _getRecordsWithSql = async ({
    databaseId,
    tableSlug,
    branchSlug,
    teamSlug,
    sql,
    limit,
    skip,
  }: {
    databaseId: string;
    tableSlug: string;
    branchSlug?: string;
    teamSlug: string;
    sql: string;
    limit?: number | undefined;
    skip?: number | undefined;
  }): Promise<QueryRecordWithFieldsResponse> => {
    return executeRequest<QueryRecordWithFieldsResponse>(
      "POST",
      `/${databaseId}/record/${tableSlug}/query/sql`,
      { branchSlug },
      {
        teamSlug,
      },
      {
        sql,
        limit,
        skip,
      },
      { transformResponse: [transformNumberToStringForQueryRecordListResponse] }
    );
  };

  const _updateRecord = async ({
    databaseId,
    tableSlug,
    branchSlug,
    teamSlug,
    body,
  }: {
    databaseId: string;
    tableSlug: string;
    branchSlug?: string;
    teamSlug: string;
    body: UpdateRecordRequestBody;
  }): Promise<QueryRecordListResponse> => {
    return executeRequest(
      "PUT",
      `/${databaseId}/record/${tableSlug}`,
      { branchSlug },
      {
        teamSlug,
      },
      body
    );
  };

  const _startBulkUpdateRecordByPrompt = async ({
    databaseId,
    tableSlug,
    teamSlug,
    prompt,
    fieldName,
    filter,
  }: {
    databaseId: string;
    tableSlug: string;
    teamSlug?: string;
    fieldName: string;
    prompt: string;
    filter: RecordFilterCondition;
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest(
      "PUT",
      `/${databaseId}/record/${tableSlug}/prompt`,
      {},
      {
        teamSlug,
      },
      {
        fieldName,
        prompt,
        filter: filter,
      }
    );
  };

  const _previewBulkUpdateRecordByPrompt = async ({
    databaseId,
    tableSlug,
    teamSlug,
    prompt,
    fieldName,
    filter,
  }: {
    databaseId: string;
    tableSlug: string;
    teamSlug?: string;
    fieldName: string;
    prompt: string;
    filter: RecordFilterCondition;
  }): Promise<QueryRecordWithFieldsResponse> => {
    return executeRequest(
      "PUT",
      `/${databaseId}/record/${tableSlug}/prompt/preview`,
      {
        withFields: true,
      },
      {
        teamSlug,
      },
      {
        fieldName,
        prompt,
        filter,
      },
      {
        transformResponse: [transformNumberToStringForQueryRecordListResponse],
      }
    );
  };

  const _bulkUpdateRecordByFormula = async ({
    databaseId,
    tableSlug,
    teamSlug,
    formula,
    fieldName,
    filter,
  }: {
    databaseId: string;
    tableSlug: string;
    teamSlug?: string;
    fieldName: string;
    formula: string;
    filter: RecordFilterCondition;
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest(
      "PUT",
      `/${databaseId}/record/${tableSlug}`,
      {},
      {
        teamSlug,
      },
      {
        fixedValue: [],
        values: [{ key: fieldName, value: `[formula]${formula}` }],
        filter,
      }
    );
  };

  const _previewBulkUpdateRecordByFormula = async ({
    databaseId,
    tableSlug,
    teamSlug,
    formula,
    fieldName,
    filter,
  }: {
    databaseId: string;
    tableSlug: string;
    teamSlug?: string;
    fieldName: string;
    formula: string;
    filter: RecordFilterCondition;
  }): Promise<QueryRecordWithFieldsResponse> => {
    return await executeRequest(
      "POST",
      `/${databaseId}/record/${tableSlug}/query`,
      {
        withFields: true,
      },
      {
        teamSlug,
      },
      {
        select: [`[formula]${formula}`, "*"],
        limit: 5,
        skip: 0,
        filter,
      },
      {
        transformResponse: [transformNumberToStringForQueryRecordListResponse],
      }
    );
  };

  const _deleteRecord = async ({
    databaseId,
    tableSlug,
    teamSlug,
    body,
    isHardDelete,
  }: {
    databaseId: string;
    tableSlug: string;
    teamSlug: string;
    body: DeleteRecordRequestBody;
    isHardDelete: boolean;
  }): Promise<QueryRecordListResponse> => {
    return executeRequest(
      "POST",
      `/${databaseId}/record/${tableSlug}/delete`,
      {
        isHardDelete,
      },
      {
        teamSlug,
      },
      body
    );
  };

  const _downloadRecordsCSV = async ({
    databaseId,
    tableSlug,
    branchSlug,
    teamSlug,
    requestBody,
  }: {
    databaseId: string;
    tableSlug: string;
    branchSlug?: string;
    teamSlug: string;
    requestBody: QueryRecordRequestBody;
  }): Promise<DashboardCsvUrlResponse> => {
    return executeRequest(
      "POST",
      `/${databaseId}/record/${tableSlug}/query/csv`,
      { branchSlug },
      {
        teamSlug,
      },
      {
        ...requestBody,
      }
    );
  };

  const _getRecordHistory = ({
    databaseId,
    teamSlug,
    tableSlug,
    requestBody,
  }: {
    databaseId: string;
    teamSlug: string;
    tableSlug: string;
    requestBody: DashboardQueryRecordHistoryRequestBody;
  }) => {
    return executeRequest<DashboardRecordHistoryListResponse>(
      "post",
      `/${databaseId}/record-history/${tableSlug}`,
      undefined,
      {
        teamSlug,
      },
      {
        ...requestBody,
      }
    );
  };

  const _getSoftDeletedRecord = ({
    databaseId,
    teamSlug,
    tableSlug,
    withFields,
    requestBody,
  }: {
    databaseId: string;
    teamSlug: string;
    tableSlug: string;
    withFields: boolean;
    requestBody: QueryRecordRequestBodyObject;
  }) => {
    return executeRequest<DashboardRecordTrashListResponse>(
      "post",
      `/${databaseId}/record/${tableSlug}/trash`,
      {
        withFields,
      },
      {
        teamSlug,
      },
      {
        ...requestBody,
      },
      {
        transformResponse: [transformNumberToStringForRecordTrashListResponse],
      }
    );
  };

  const _deleteRecordFromTrash = ({
    databaseId,
    teamSlug,
    tableSlug,
    recordTrashId,
  }: {
    databaseId: string;
    teamSlug: string;
    tableSlug: string;
    recordTrashId: string;
  }) => {
    return executeRequest<DashboardRecordTrashListResponse>(
      "delete",
      `/${databaseId}/record/${tableSlug}/trash/${recordTrashId}`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  const _restoreRecordFromTrash = ({
    databaseId,
    teamSlug,
    tableSlug,
    recordTrashId,
  }: {
    databaseId: string;
    teamSlug: string;
    tableSlug: string;
    recordTrashId: string;
  }) => {
    return executeRequest<DashboardRecordTrashListResponse>(
      "post",
      `/${databaseId}/record/${tableSlug}/trash/${recordTrashId}`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  const _emptyTrash = ({
    databaseId,
    teamSlug,
    tableSlug,
  }: {
    databaseId: string;
    teamSlug: string;
    tableSlug: string;
  }) => {
    return executeRequest<DashboardGeneralResponse>(
      "delete",
      `/${databaseId}/record/${tableSlug}/trash`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  return {
    _countRecords,
    _createRecord,
    _createRecordObjectWithPrompt,
    _getRecords,
    _getRecordsWithSql,
    _updateRecord,
    _deleteRecord,
    _downloadRecordsCSV,
    _getRecordHistory,
    _previewBulkUpdateRecordByPrompt,
    _startBulkUpdateRecordByPrompt,
    _bulkUpdateRecordByFormula,
    _previewBulkUpdateRecordByFormula,
    _getSoftDeletedRecord,
    _deleteRecordFromTrash,
    _restoreRecordFromTrash,
    _emptyTrash,
  };
}
