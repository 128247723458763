import { generatePath, ParamParseKey } from "react-router-dom";
import { routes } from "./routes";

type Route = typeof routes;

type Args<K extends keyof Route> = {
  [key in ParamParseKey<Route[K]>]: string;
};

const getPath = <K extends keyof Route>(route: K, args: Required<Args<K>>) => {
  return generatePath(routes[route], args);
};

export { getPath };
