import { RecordValueType } from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { CreatingRecordClientModel } from "./CreatingRecordClientModel";
import { RecordEntryClientModelDefactory } from "../record/recordEntry";

export class CreatingRecordClientModelDefactory {
  public static toRecordValues(
    editingRecord: CreatingRecordClientModel,
    fields: FieldsClientModel
  ): { key: string; value: RecordValueType }[] {
    const values: { key: string; value: RecordValueType }[] =
      fields.data.fields.flatMap((field) => {
        const recordEntry = editingRecord.data.creatingRecord.data.entries.find(
          (entry) => entry.key === field.name
        );

        if (!recordEntry) {
          return [];
        }

        return match(field.type)
          .with(
            // 自動生成系はリクエストから抜く
            {
              type: P.union(
                "formula",
                "autoNumber",
                "autoBigNumber",
                "createdAt",
                "createdBy",
                "lastEditedAt",
                "lastEditedBy",
                "syncValue",
                "generateText",
                "calculation",
                "aggregateValue",
                "smartFunction"
              ),
            },
            () => []
          )
          .with(
            {
              type: P.union(
                "shortText",
                "longText",
                "email",
                "phoneNumber",
                "date",
                "datetime",
                "time",
                "url",
                "number",
                "bigNumber",
                "decimal",
                "boolean",
                "singleSelect",
                "multiSelect",
                "attachment",
                "image",
                "html",
                "json",
                "array"
              ),
            },
            () => ({
              key: field.name,
              value: RecordEntryClientModelDefactory.toValueForRecordRequest(
                recordEntry,
                field
              ),
            })
          )
          .exhaustive();
      });

    return values;
  }
}
