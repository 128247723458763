import { FieldClientModel } from "~/clientModel/fields/field";
import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { StringValidator } from "./CommonValueValidator";
import {
  FilterOperationClientModelBase,
  FilterOperationWithValueInterface,
} from "./FilterOperationClientModelBase";

type GreaterThanOrEqualFilterOperationClientModelData = {
  value: string;
};

export class GreaterThanOrEqualFilterOperationClientModel
  extends FilterOperationClientModelBase
  implements
    FilterOperationWithValueInterface<
      string,
      GreaterThanOrEqualFilterOperationClientModel
    >
{
  readonly #data: GreaterThanOrEqualFilterOperationClientModelData;

  public constructor(data: GreaterThanOrEqualFilterOperationClientModelData) {
    super();
    this.#data = data;
  }

  public get data() {
    return this.#data;
  }

  public get type(): "greaterThanOrEqual" {
    return "greaterThanOrEqual";
  }

  public get operatorValue(): "greaterThanOrEqual" {
    return "greaterThanOrEqual";
  }

  public get operatorLabel(): "Greater than or equal" {
    return "Greater than or equal";
  }

  public get operandValue(): string {
    return this.#data.value;
  }

  public updateValue(
    value: string
  ): GreaterThanOrEqualFilterOperationClientModel {
    return new GreaterThanOrEqualFilterOperationClientModel({
      ...this.#data,
      value,
    });
  }

  public validate(targetField: FieldClientModel): ValidationResult {
    return new StringValidator(this.#data.value)
      .shouldNotBeEmpty()
      .shouldBeIntStringIfNumberType(targetField)
      .end();
  }
}
