import { UseExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import {
  FieldClientModel,
  FieldClientModelFactory,
} from "~/clientModel/fields/field";
import { AggregateValueFieldOperatorsClientModel } from "~/clientModel/fields/field/fieldType/computed/aggregateValue/aggregateValueFieldOperators";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";
import { Loadable } from "~/clientModel/loadable";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { RecordsClientModel } from "~/clientModel/records";
import { TablesClientModel } from "~/clientModel/tables";
import { TableClientModel } from "~/clientModel/tables/table";
import { useDisclosure } from "~/hooks/useDisclosure";

import { SourceCreateFieldForm } from "~/presenters/fields";
import { RightSidebarBase } from "../common/RightSidebarBase";
import { useSidebarDisclosure } from "../common/sidebarState/useSidebarDisclosure";
import { CreateFieldSidebarCreateComputedFieldDialog } from "./CreateFieldSidebarCreateComputedFieldDialog";
import { CreateFieldSidebarFooter } from "./CreateFieldSidebarFooter";
import { CreateFieldSidebarMakeAllFieldsNullableDialog } from "./CreateFieldSidebarMakeAllFieldsNullableDialog";

type CreateFieldSidebarProps = {
  tableLoadable: Loadable<TableClientModel>;
  creatingField: FieldClientModel;
  fieldsLoadable: Loadable<FieldsClientModel>;
  editingFieldsForUpdateMergeKeyLoadable: Loadable<FieldsClientModel>;
  smartFunctionsLoadable: Loadable<SmartFunctionsClientModel>;
  onCreatingFieldChange: (field: FieldClientModel) => void;
  onEditingFieldsForUpdateMergeKeyChange: (fields: FieldsClientModel) => void;
  tablesLoadable: Loadable<TablesClientModel>;
  aggregateValueFieldOperatorsLoadable: Loadable<AggregateValueFieldOperatorsClientModel>;
  useFieldsLoadable: UseLoadable<{ tableSlug: string }, FieldsClientModel>;
  useCreateFieldExecutable: UseExecutable<void, { field: FieldClientModel }>;
  useCreateComputedFieldExecutable: UseExecutable<
    void,
    {
      createdField: FieldClientModel;
      fields: FieldsClientModel;
    }
  >;
  useTestFormulaExecutable: UseExecutable<
    void,
    { formula: string },
    { records: RecordsClientModel; fields: FieldsClientModel }
  >;
  initialFieldType: FieldClientModel["type"]["type"];
  useGenerateSqlForComputedFieldFromPromptExecutable: UseExecutable<
    void,
    {
      prompt: string;
      creatingFieldName: string;
      margeFieldName: string;
      mergeTargetTableSlug: string;
    },
    { sql: string }
  >;
  useStartSyncComputedFieldsExecutable: UseExecutable<void, void>;
  useMakeFieldsNullableExecutable: UseExecutable<
    void,
    { fields: FieldsClientModel }
  >;
};

export const CreateFieldSidebar = (props: CreateFieldSidebarProps) => {
  const {
    tableLoadable,
    creatingField,
    fieldsLoadable,
    editingFieldsForUpdateMergeKeyLoadable,
    aggregateValueFieldOperatorsLoadable,
    smartFunctionsLoadable,
    onCreatingFieldChange,
    onEditingFieldsForUpdateMergeKeyChange,
    useCreateFieldExecutable,
    useCreateComputedFieldExecutable,
    useTestFormulaExecutable,
    tablesLoadable,
    useFieldsLoadable,
    initialFieldType,
    useGenerateSqlForComputedFieldFromPromptExecutable,
    useStartSyncComputedFieldsExecutable,
    useMakeFieldsNullableExecutable,
  } = props;

  const { onClose } = useSidebarDisclosure();

  const testFormulaExecutable = useTestFormulaExecutable();

  const createComputedFieldDialogDisclosure = useDisclosure();

  const makeAllFieldsNullableDialogDisclosure = useDisclosure();

  const generateSqlForComputedFieldFromPromptExecutable =
    useGenerateSqlForComputedFieldFromPromptExecutable();

  const handleFieldCreated = () => {
    onClose("createField");
    onCreatingFieldChange(
      FieldClientModelFactory.createEmpty(initialFieldType).updateIsNullable(
        true
      )
    );
  };

  return (
    <>
      <RightSidebarBase
        sidebarType="createField"
        title="Create Field"
        footer={
          <WithFallback
            loadables={
              [
                editingFieldsForUpdateMergeKeyLoadable,
                smartFunctionsLoadable,
              ] as const
            }
          >
            {([editingFieldsForUpdateMergeKey, smartFunctions]) => (
              <CreateFieldSidebarFooter
                creatingField={creatingField}
                smartFunctions={smartFunctions}
                editingFieldsForUpdateMergeKey={editingFieldsForUpdateMergeKey}
                useCreateFieldExecutable={useCreateFieldExecutable}
                onCreateComputedFieldDialogOpen={
                  createComputedFieldDialogDisclosure.onOpen
                }
                onMakeAllFieldsNullableDialogOpen={
                  makeAllFieldsNullableDialogDisclosure.onOpen
                }
                onFieldCreated={handleFieldCreated}
              />
            )}
          </WithFallback>
        }
      >
        <WithFallback
          loadables={
            [
              fieldsLoadable,
              editingFieldsForUpdateMergeKeyLoadable,
              tableLoadable,
              tablesLoadable,
              aggregateValueFieldOperatorsLoadable,
              smartFunctionsLoadable,
            ] as const
          }
        >
          {([
            fields,
            editingFieldsForUpdateMergeKey,
            table,
            tables,
            aggregateValueFieldOperators,
            smartFunctions,
          ]) => (
            <SourceCreateFieldForm
              table={table}
              field={creatingField}
              editingFieldsForUpdateMergeKey={editingFieldsForUpdateMergeKey}
              fields={fields}
              tables={tables}
              smartFunctions={smartFunctions}
              aggregateValueFieldOperators={aggregateValueFieldOperators}
              useFieldsLoadable={useFieldsLoadable}
              onFieldChange={onCreatingFieldChange}
              onEditingFieldsForUpdateMergeKeyChange={
                onEditingFieldsForUpdateMergeKeyChange
              }
              testFormulaExecutable={testFormulaExecutable}
              generateSqlForComputedFieldFromPromptExecutable={
                generateSqlForComputedFieldFromPromptExecutable
              }
            />
          )}
        </WithFallback>
      </RightSidebarBase>
      <WithFallback
        loadables={[editingFieldsForUpdateMergeKeyLoadable] as const}
      >
        {([editingFieldsForUpdateMergeKey]) => (
          <CreateFieldSidebarCreateComputedFieldDialog
            isOpen={createComputedFieldDialogDisclosure.isOpen}
            onOpenChange={createComputedFieldDialogDisclosure.setIsOpen}
            creatingField={creatingField}
            editingFieldsForUpdateMergeKey={editingFieldsForUpdateMergeKey}
            useCreateComputedFieldExecutable={useCreateComputedFieldExecutable}
            useStartSyncComputedFieldsExecutable={
              useStartSyncComputedFieldsExecutable
            }
            onFieldCreated={handleFieldCreated}
          />
        )}
      </WithFallback>

      <WithFallback
        loadables={[editingFieldsForUpdateMergeKeyLoadable] as const}
      >
        {([editingFieldsForUpdateMergeKey]) => (
          <CreateFieldSidebarMakeAllFieldsNullableDialog
            editingFieldsForUpdateMergeKey={editingFieldsForUpdateMergeKey}
            isOpen={makeAllFieldsNullableDialogDisclosure.isOpen}
            onOpenChange={makeAllFieldsNullableDialogDisclosure.setIsOpen}
            useMakeFieldsNullableExecutable={useMakeFieldsNullableExecutable}
            onCreateComputedFieldDialogOpen={
              createComputedFieldDialogDisclosure.onOpen
            }
          />
        )}
      </WithFallback>
    </>
  );
};
