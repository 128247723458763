import { useEditorOrThrow } from "../utils";

const useCode = () => {
  const editor = useEditorOrThrow();

  const handleToggle = () => {
    editor.chain().focus().toggleCode().run();
  };

  const isActive = editor.isActive("code");

  const isDisabled = !editor.can().chain().focus().toggleCode().run();

  return {
    handleToggle,
    isActive,
    isDisabled,
  };
};

export { useCode };
