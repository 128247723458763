import { SimpleField } from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";

import { NumberInput } from "./Inputs/Number/NumberInput";
import { AutoNumberReadonlyInput } from "./Inputs/Number/AutoNumberReadonlyInput";
import { BigNumberInput } from "./Inputs/Number/BigNumberInput";
import { BooleanInput } from "./Inputs/OtherPrimitives/BooleanInput";
import { DecimalInput } from "./Inputs/Number/DecimalInput";
import { FormulaReadonlyInput } from "./Inputs/FormulaReadonlyInput";
import { MultiSelectInput } from "./Inputs/Select/MultiSelectInput";
import { SingleSelectInput } from "./Inputs/Select/SingleSelectInput";
import { AllRecordModelValueUnion } from "~/features/RecordModel/types/RecordModelValue";
import { CreatedByReadonlyInput } from "./Inputs/User/CreatedByReadonlyInput";
import { LastEditedByReadonlyInput } from "./Inputs/User/LastEditedByReadonlyInput";
import { EmailInput } from "./Inputs/Text/EmailInput";
import { PhoneNumberInput } from "./Inputs/Text/PhoneNumberInput";
import { UrlInput } from "./Inputs/Text/UrlInput";
import { ShortTextInput } from "./Inputs/Text/ShortTextInput";
import { LongTextInput } from "./Inputs/Text/LongTextInput";
import { CreatedAtReadonlyInput } from "./Inputs/Date/CreatedAtReadonlyInput";
import { LastEditedAtReadonlyInput } from "./Inputs/Date/LastEditedAtReadonlyInput";
import { DateInput } from "./Inputs/Date/DateInput";
import { DateTimeInput } from "./Inputs/Date/DateTimeInput";
import { TimeInput } from "./Inputs/Date/TimeInput";
import { AutoBigNumberReadonlyInput } from "./Inputs/Number/AutoBigNumberReadonlyInput";
import { ImageInput } from "./Inputs/ImageAndAttachment/ImageInput";
import { FileInput } from "./Inputs/ImageAndAttachment/FileInput";
import { JSONInput } from "./Inputs/Code/JSON/JSONInput";
import { ArrayInput } from "./Inputs/Code/Array/ArrayInput";
import { HTMLInput } from "./Inputs/Code/HTML/HTMLInput";
import { ForwardedRef, forwardRef } from "react";

type RecordValueInputProps = {
  field: SimpleField;
  recordModelValue: AllRecordModelValueUnion;
  isReadOnly?: boolean;
  errorMessages: string[];
  onChange?: (value: AllRecordModelValueUnion) => void;
  size?: "xs" | "sm" | "md";
};

type RecordValueInputRefType =
  | HTMLInputElement
  | HTMLTextAreaElement
  | HTMLButtonElement;

const RecordValueInput = forwardRef<
  RecordValueInputRefType,
  RecordValueInputProps
>((props, ref) => {
  const {
    field,
    recordModelValue,
    isReadOnly = false,
    errorMessages,
    onChange,
    size,
  } = props;

  const isNullable = field.nullable === true;

  const handleOnChange = (value: unknown) => {
    if (onChange) {
      onChange({
        ...recordModelValue,
        value,
      } as AllRecordModelValueUnion);
    }
  };

  return (
    match(recordModelValue)
      // text系
      .with({ type: P.union("shortText") }, (recordModelValue) => (
        <ShortTextInput
          value={recordModelValue.value}
          isReadOnly={isReadOnly}
          onChange={handleOnChange}
          errorMessages={errorMessages}
          size={size}
          isNullable={isNullable}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "longText" }, (recordModelValue) => (
        <LongTextInput
          value={recordModelValue.value}
          isReadOnly={isReadOnly}
          onChange={handleOnChange}
          errorMessages={errorMessages}
          size={size}
          isNullable={isNullable}
          ref={ref as ForwardedRef<HTMLTextAreaElement>}
        />
      ))
      .with({ type: "email" }, (recordModelValue) => (
        <EmailInput
          value={recordModelValue.value}
          isReadOnly={isReadOnly}
          onChange={handleOnChange}
          errorMessages={errorMessages}
          size={size}
          isNullable={isNullable}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "phoneNumber" }, (recordModelValue) => (
        <PhoneNumberInput
          value={recordModelValue.value}
          isReadOnly={isReadOnly}
          onChange={handleOnChange}
          errorMessages={errorMessages}
          size={size}
          isNullable={isNullable}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "url" }, (recordModelValue) => (
        <UrlInput
          value={recordModelValue.value}
          isReadOnly={isReadOnly}
          onChange={handleOnChange}
          errorMessages={errorMessages}
          size={size}
          isNullable={isNullable}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      // seelct系
      .with({ type: "singleSelect" }, (recordModelValue) => (
        <SingleSelectInput
          value={recordModelValue.value}
          members={field.members || []}
          isReadOnly={isReadOnly}
          isNullable={isNullable}
          onChange={handleOnChange}
          errorMessages={errorMessages}
          size={size}
          ref={ref as ForwardedRef<HTMLButtonElement>}
        />
      ))
      .with({ type: "multiSelect" }, (recordModelValue) => (
        <MultiSelectInput
          value={recordModelValue.value}
          members={field.members || []}
          isReadOnly={isReadOnly}
          isNullable={isNullable}
          onChange={handleOnChange}
          errorMessages={errorMessages}
          size={size}
          ref={ref as ForwardedRef<HTMLDivElement>}
        />
      ))
      // number系
      .with({ type: P.union("number") }, (recordModelValue) => (
        <NumberInput
          value={recordModelValue.value}
          isReadOnly={isReadOnly}
          isNullable={isNullable}
          onChange={handleOnChange}
          errorMessages={errorMessages}
          size={size}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: P.union("decimal") }, (recordModelValue) => (
        <DecimalInput
          value={recordModelValue.value}
          isReadOnly={isReadOnly}
          isNullable={isNullable}
          onChange={handleOnChange}
          errorMessages={errorMessages}
          size={size}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "bigNumber" }, (recordModelValue) => (
        <BigNumberInput
          value={recordModelValue.value}
          isReadOnly={isReadOnly}
          isNullable={isNullable}
          onChange={handleOnChange}
          errorMessages={errorMessages}
          size={size}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "autoNumber" }, (recordModelValue) => (
        <AutoNumberReadonlyInput
          value={recordModelValue.value}
          errorMessages={errorMessages}
          size={size}
          isNullable={false}
        />
      ))
      .with({ type: "autoBigNumber" }, (recordModelValue) => (
        <AutoBigNumberReadonlyInput
          value={recordModelValue.value}
          errorMessages={errorMessages}
          size={size}
          isNullable={false}
        />
      ))
      // その他プリミティブ系
      .with({ type: "boolean" }, (recordModelValue) => (
        <BooleanInput
          value={recordModelValue.value}
          isReadOnly={isReadOnly}
          isNullable={isNullable}
          onChange={handleOnChange}
          errorMessages={errorMessages}
          size={size}
          ref={ref as ForwardedRef<HTMLButtonElement>}
        />
      ))
      // date系
      .with({ type: "date" }, (recordModelValue) => (
        <DateInput
          value={recordModelValue.value}
          isReadOnly={isReadOnly}
          isNullable={isNullable}
          onChange={handleOnChange}
          errorMessages={errorMessages}
          size={size}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "datetime" }, (recordModelValue) => (
        <DateTimeInput
          value={recordModelValue.value}
          isReadOnly={isReadOnly}
          isNullable={isNullable}
          onChange={handleOnChange}
          errorMessages={errorMessages}
          size={size}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "time" }, (recordModelValue) => (
        <TimeInput
          value={recordModelValue.value}
          isReadOnly={isReadOnly}
          isNullable={isNullable}
          onChange={handleOnChange}
          errorMessages={errorMessages}
          size={size}
          ref={ref as ForwardedRef<HTMLInputElement>}
        />
      ))
      .with({ type: "formula" }, (recordModelValue) => (
        <FormulaReadonlyInput
          value={recordModelValue.value}
          formula={field.formula || ""}
          errorMessages={errorMessages}
          size={size}
          isNullable={isNullable}
        />
      ))
      // Asset系
      .with({ type: "image" }, (recordModelValue) => (
        <ImageInput
          value={recordModelValue.value}
          isReadOnly={isReadOnly}
          isNullable={isNullable}
          errorMessages={errorMessages}
          onChange={handleOnChange}
          size={size}
        />
      ))
      .with({ type: "attachment" }, (recordModelValue) => (
        <FileInput
          value={recordModelValue.value}
          isReadOnly={isReadOnly}
          isNullable={isNullable}
          errorMessages={errorMessages}
          onChange={handleOnChange}
          size={size}
        />
      ))
      // // その他形式
      .with({ type: "json" }, (recordModelValue) => (
        <JSONInput
          value={recordModelValue.value}
          isReadOnly={isReadOnly}
          errorMessages={errorMessages}
          onChange={handleOnChange}
          isNullable={isNullable}
          size={size}
        />
      ))
      .with({ type: "array" }, (recordModelValue) => (
        <ArrayInput
          value={recordModelValue.value}
          isReadOnly={isReadOnly}
          errorMessages={errorMessages}
          onChange={handleOnChange}
          isNullable={isNullable}
          size={size}
        />
      ))
      .with({ type: "html" }, (recordModelValue) => (
        <HTMLInput
          value={recordModelValue.value}
          isReadOnly={isReadOnly}
          errorMessages={errorMessages}
          onChange={handleOnChange}
          isNullable={isNullable}
          size={size}
        />
      ))
      // システム系
      .with(
        {
          type: "createdBy",
        },
        (recordModelValue) => (
          <CreatedByReadonlyInput
            value={recordModelValue.value}
            errorMessages={errorMessages}
            size={size}
            isNullable={true}
          />
        )
      )
      .with(
        {
          type: "lastEditedBy",
        },
        (recordModelValue) => (
          <LastEditedByReadonlyInput
            value={recordModelValue.value}
            errorMessages={errorMessages}
            size={size}
            isNullable={true}
          />
        )
      )
      .with(
        {
          type: "createdAt",
        },
        (recordModelValue) => (
          <CreatedAtReadonlyInput
            value={recordModelValue.value}
            errorMessages={errorMessages}
            size={size}
            isNullable={true}
          />
        )
      )
      .with(
        {
          type: "lastEditedAt",
        },
        (recordModelValue) => (
          <LastEditedAtReadonlyInput
            value={recordModelValue.value}
            errorMessages={errorMessages}
            size={size}
            isNullable={true}
          />
        )
      )

      // todo: コンパイル通すためにとりあえず追加
      .with(
        {
          type: P.union(
            "richText",
            "reference",
            "multiReference",
            "embeddings",
            "python",
            "rowInfo"
          ),
        },
        () => {
          return null;
        }
      )
      .exhaustive()
  );
});

RecordValueInput.displayName = "RecordValueInput";

export { RecordValueInput, type RecordValueInputRefType };
