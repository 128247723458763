import { useQuery } from "react-query";
import { Loadable, useComposeLoadable } from "~/clientModel/loadable";
import {
  TableClientModel,
  TableClientModelFactory,
} from "~/clientModel/tables/table";

import { useGetTableQuery } from "~/serverStateStore";

type useTableLoadableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

export const useTableLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: useTableLoadableParams): Loadable<TableClientModel> => {
  return useComposeLoadable(
    useQuery({
      ...useGetTableQuery({
        teamSlug,
        databaseId,
        tableSlug,
      }),
      select: (data) => {
        return TableClientModelFactory.createFromDashboardTableObject(data);
      },
    })
  );
};
