import { FieldClientModel } from "~/clientModel/fields/field";
import { RecordClientModel } from "~/clientModel/records/record";
import { UserAvatar } from "~/presenters/user";
import { useTableSelectionUtil } from "../common/useTableSelectionUtil";
import {
  useRecordsTableBaseCoworkersState,
  useRecordsTableBaseFields,
  useRecordsTableBaseRecords,
} from "../context/ReactTableBaseContext";

type CoworkerAvatarProps = {
  record: RecordClientModel;
  field: FieldClientModel;
};

export const CoworkerAvatar = (props: CoworkerAvatarProps) => {
  const { record, field } = props;

  const records = useRecordsTableBaseRecords();
  const fields = useRecordsTableBaseFields();
  const coworkersState = useRecordsTableBaseCoworkersState();
  const { isUpperCellSelected, isRightCellSelected } = useTableSelectionUtil();

  // 選択セルの右上(「セルが選択されている」かつ「上のセルが選択されていない」かつ「左のセルが選択されていない」)
  // のときに、右上のユーザーアイコンを表示する
  const user = coworkersState?.coworkersState.find(({ tableSelection }) => {
    const isCellSelected = tableSelection.isCellSelected({
      recordIdentifier: record.recordIdentifier,
      fieldName: field.name,
    });

    const _isUpperCellSelected = isUpperCellSelected({
      tableSelection,
      field,
      record,
    });

    const _isRightCellSelected = isRightCellSelected({
      tableSelection,
      field,
      record,
    });

    return isCellSelected && !_isUpperCellSelected && !_isRightCellSelected;
  })?.user;

  if (!user) {
    return null;
  }

  return (
    <UserAvatar key={user.userId} user={user} size="xs" showCoworkerBorder />
  );
};
