import {
  DashboardNotebookCellObject,
  DashboardNotebookCellSourceVariableObject,
} from "@usemorph/morph-dashboard-types";

export const getAncestorVariables = (
  targetCell: DashboardNotebookCellObject,
  cells: readonly DashboardNotebookCellObject[]
): DashboardNotebookCellSourceVariableObject[] => {
  const variables: DashboardNotebookCellSourceVariableObject[] = [];
  if (targetCell.cellType === "variables") {
    if (targetCell.source.variables) {
      variables.push(...targetCell.source.variables);
    }
  }

  const parentIds = targetCell.parentIds?.filter(
    (cellId) => targetCell.cellId !== cellId
  );
  if (parentIds && parentIds.length > 0) {
    const parentCells = cells.filter((cell) => parentIds.includes(cell.cellId));
    parentCells.forEach((parentCell) => {
      variables.push(...getAncestorVariables(parentCell, cells));
    });
  }

  return variables;
};
