import { DashboardGenerateSqlHistoryObject } from "@usemorph/morph-dashboard-types";
import { FindUserClientModelFactory } from "~/clientModel/user";
import { PromptHistoryClientModel } from "./PromptHistoryClientModel";

export class PromptHistoryClientModelFactory {
  public static createFromDashboardGenerateSqlHistoryObject(
    historyObject: DashboardGenerateSqlHistoryObject
  ): PromptHistoryClientModel {
    const { message, sql, createdAt, createdBy } = historyObject;
    return new PromptHistoryClientModel({
      message,
      sql,
      createdAt,
      createdBy: createdBy
        ? FindUserClientModelFactory.fromUserResponse(createdBy)
        : undefined,
    });
  }
}
