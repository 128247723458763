import { forwardRef, ReactNode, useCallback } from "react";
import { MorphThemeSize } from "../../commonProps.type";
import { Popover as RadixThemePopover } from "@radix-ui/themes";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { MultiSelectVariant } from "./type";
import { Command as CommandPrimitive } from "cmdk";
import {
  MultiSelectContextProvider,
  useMultiSelectFilterFn,
  useMultiSelectInputRef,
  useMultiSelectOnChange,
  useMultiSelectOnOpenChange,
  useMultiSelectOpen,
  useMultiSelectValue,
} from "./MultiSelectContext";

type MultiSelectRootProps = {
  variant: MultiSelectVariant;
  size?: MorphThemeSize;
  value: string[];
  label?: string;
  onChange?: (value: string[]) => void;
  filterFn?: (value: string, search: string) => number;
  errorMessages?: string[];
  placeholder?: string;
  isDisabled?: boolean; // default false
  isReadOnly?: boolean; // default false
  isClearable?: boolean; // default false
  children: ReactNode;
};

const MultiSelectRoot = forwardRef<HTMLDivElement, MultiSelectRootProps>(
  (props, ref) => {
    const { children, errorMessages, label } = props;

    return (
      <MultiSelectContextProvider {...props}>
        <InputStack label={label} errorMessage={errorMessages}>
          <PopoverRoot>
            <Command ref={ref}>{children}</Command>
          </PopoverRoot>
        </InputStack>
      </MultiSelectContextProvider>
    );
  }
);

MultiSelectRoot.displayName = "MultiSelectRoot";

const PopoverRoot = ({ children }: { children: ReactNode }) => {
  const open = useMultiSelectOpen();
  const onOpenChange = useMultiSelectOnOpenChange();
  return (
    <RadixThemePopover.Root open={open} onOpenChange={onOpenChange}>
      {children}
    </RadixThemePopover.Root>
  );
};

type CommandProps = {
  children: ReactNode;
};

const Command = forwardRef<HTMLDivElement, CommandProps>((props, ref) => {
  const { children } = props;
  const value = useMultiSelectValue();
  const onChange = useMultiSelectOnChange();
  const inputRef = useMultiSelectInputRef();
  const filterFn = useMultiSelectFilterFn();

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current;

      if (!input || !onChange) return;

      if (input) {
        // UX悪いかも
        // if (e.key === "Delete" || e.key === "Backspace") {
        //   if (input.value === "") {
        //     // Remove the last item from the selected items
        //     const updatedValue = value.slice(0, value.length - 1);
        //     onChange(updatedValue);
        //   }
        // }

        // Blur the input when the user presses escape
        if (e.key === "Escape") {
          input.blur();
        }
      }
    },
    [value, onChange, inputRef]
  );

  return (
    <CommandPrimitive ref={ref} filter={filterFn} onKeyDown={handleKeyDown}>
      {children}
    </CommandPrimitive>
  );
});

Command.displayName = "Command";

export { MultiSelectRoot, type MultiSelectRootProps };
