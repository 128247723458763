import { useCallback } from "react";
import { ClearSQLButton } from "../../../../common/components/QueryMode/Buttons/ClearSQLButton";
import { useSetQueryMode } from "../../../states/queryMode";
import { useSetQueryingSql, useSetSqlInput } from "../../../states/sql";
import { useViewId } from "~/utilHooks/useViewId";

const TableViewClearSQLButton = () => {
  /**
   * global states from recoil
   */
  const viewId = useViewId();
  const setQueryMode = useSetQueryMode(viewId);
  const setSqlInput = useSetSqlInput(viewId);
  const setQueryingSql = useSetQueryingSql(viewId);

  /**
   * handlers
   */

  const handleClickClearSQLButton = useCallback(() => {
    setQueryMode(null);
    setQueryingSql("");
    setSqlInput("");
  }, [setQueryMode, setQueryingSql, setSqlInput]);

  return <ClearSQLButton onClick={handleClickClearSQLButton} />;
};

export { TableViewClearSQLButton };
