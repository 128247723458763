import { Popover as RadixThemePopover } from "@radix-ui/themes";
import { ComponentPropsWithoutRef, ReactNode } from "react";

type PopoverContentProps = ComponentPropsWithoutRef<
  typeof RadixThemePopover.Content
>;

type PopoverProps = {
  trigger?: ReactNode;
  children: ReactNode;
  contentStyle?: PopoverContentProps["style"];
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
};

const Popover = (props: PopoverProps) => {
  const { trigger, children, contentStyle, isOpen, onOpenChange } = props;
  return (
    <RadixThemePopover.Root open={isOpen} onOpenChange={onOpenChange}>
      {trigger && (
        <RadixThemePopover.Trigger>{trigger}</RadixThemePopover.Trigger>
      )}
      <RadixThemePopover.Content style={contentStyle}>
        {children}
      </RadixThemePopover.Content>
    </RadixThemePopover.Root>
  );
};

const PopoverClose = RadixThemePopover.Close;

export { Popover, PopoverClose, type PopoverProps };
