import { Flex } from "~/components_next/Flex";
import { styled } from "~/stitches";

const PlaygroundControlPanelFlex = styled(Flex, {
  backgroundColor: "$whiteA10",
  borderRadius: "var(--radius-4)",
  boxShadow: "var(--shadow-4)",
});

export { PlaygroundControlPanelFlex };
