/**
 * WARNING: 一般的なhookではなく、CSVインポートによるSource作成時に使うためのBucket用Hookです。
 * 固有のロジックを含むので、違う箇所で使いまわさないように注意してください。
 */

import { useMutation, useQueryClient } from "react-query";
import useApiBucket from "~/api/useApiBucket";
import {
  SourceBucketClientModel,
  SourceBucketClientModelFactory,
} from "~/clientModel/storage/bucket";
import { commonQueryOptions } from "~/serverStateStore/util/commonQueryOptions";
import { bucketKeys } from "../keys";
import { useCreateBucketMutation } from "../mutations/useCreateBucketMutation";

type UseFindSourceBucketQueryProps = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useFindSourceBucketQuery = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseFindSourceBucketQueryProps) => {
  const { _listBuckets } = useApiBucket();

  const client = useQueryClient();
  client.setQueryDefaults(
    bucketKeys.listBucket({ teamSlug }),
    commonQueryOptions
  );

  const { mutateAsync: createBucket } = useMutation(
    useCreateBucketMutation({
      teamSlug,
    })
  );

  const bucketName = SourceBucketClientModel.getBucketName({
    databaseId,
    tableSlug,
  });

  return {
    queryKey: bucketKeys.listBucket({
      teamSlug,
      bucketName,
    }),
    queryFn: async () => {
      const findResponse = await _listBuckets({
        teamSlug,
        bucketName,
      });

      if (findResponse.count === 0) {
        // todo: serverStateStoreがClientModelに依存しているので、設計考える
        // 定数管理のために嘘のIDで作ったインスタンスを経由する
        const placeholderBucket =
          SourceBucketClientModelFactory.getPlaceholderBucket({
            databaseId,
            tableSlug,
          });
        const createResponse = await createBucket({
          bucketName: placeholderBucket.bucketName,
          readPermission: placeholderBucket.readPermission,
          createPermission: placeholderBucket.createPermission,
          updatePermission: placeholderBucket.updatePermission,
        });

        return createResponse;
      }

      return findResponse.buckets[0];
    },
  };
};

export { useFindSourceBucketQuery };
