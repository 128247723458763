import { WithFallback } from "~/clientModel/loadable/WithFallback";

import { FooterButtonBase } from "../common/FooterButtonBase";
import { RightSidebarBase } from "../common/RightSidebarBase";

type EditSyncFieldScheduleSidebarProps = {};

export const EditSyncFieldScheduleSidebar = (
  props: EditSyncFieldScheduleSidebarProps
) => {
  return (
    <RightSidebarBase
      sidebarType="editSyncFieldSchedule"
      title="Edit Sync Schedule"
      footer={
        <WithFallback loadables={[] as const}>
          {() => <FooterButtonBase>Save</FooterButtonBase>}
        </WithFallback>
      }
    >
      content
    </RightSidebarBase>
  );
};
