import { importsKeys } from "../keys";

// types
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiImport from "~/api/useApiImport";

interface uselistImportStatusFromOtherServicesQueryPrams {
  teamSlug: string;
  databaseId: string;
  limit?: number;
  skip?: number;
  tableSlug?: string;
}

const useListImportStatusFromOtherServicesQuery = ({
  teamSlug,
  databaseId,
  limit,
  skip,
  tableSlug,
}: uselistImportStatusFromOtherServicesQueryPrams) => {
  const { _listImportStatusFromExternal } = useApiImport();
  const client = useQueryClient();

  client.setQueryDefaults(
    importsKeys.listImportStatusFromOtherServices({ teamSlug, databaseId }),
    commonQueryOptions
  );

  return {
    queryKey: importsKeys.listImportStatusFromOtherServices({
      teamSlug,
      databaseId,
      limit,
      skip,
      tableSlug,
    }),
    queryFn: () => {
      return _listImportStatusFromExternal({
        teamSlug,
        databaseId,
        body: { limit, skip, tableSlug },
      });
    },
  };
};

export { useListImportStatusFromOtherServicesQuery };
