import { useQuery } from "react-query";
import { useGetPaymentQuery } from '~/serverStateStore';
import { PaymentClientModelFactory } from "~/clientModel/payment/PaymentClientModel";
import { useComposeLoadable } from "~/clientModel/loadable";

export const useFindPaymentObjectLoadable = (props: { teamSlug: string }) => {
  const { teamSlug } = props

  const findPaymentQuery = useQuery({
    ...useGetPaymentQuery({ teamSlug }),
    select: (data) => {
      return PaymentClientModelFactory.fromPaymentResponse(data)
    },
  });
  return useComposeLoadable(findPaymentQuery)
}
