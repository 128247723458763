/* eslint-disable react/display-name */
import { DashboardTemplateAuthObject, IntegrationAuthConfig } from "@usemorph/morph-dashboard-types";
import { Button } from "~/components_next/Button";
import { SimpleDropdownMenu, DropdownMenu } from "~/components_next/DropdownMenu";
import { Box } from "~/components_next/Box";
import { Card } from "@radix-ui/themes";
import { Flex } from "~/components_next/Flex";
import { Text } from "~/components_next/Text";
import { SimpleRadio } from "~/components_next/Radio";
import { styled } from "@stitches/react";
import OauthPopup from "./OauthPopup";
import { Image } from "~/components_next/Image";
import { useColorMode } from "~/contexts/ColorModeContext";
import { memo } from "react";

interface AuthChangeDropdownProps {
  listTemplateAuth: DashboardTemplateAuthObject[];
  selectedTemplateAuth: DashboardTemplateAuthObject;
  authConfig: IntegrationAuthConfig;
  authorizeUrl: string;
  templateName: string;
  loginButtonImage?: { light: string; dark: string };
  isOauthSignUp: boolean;
  isButtonDisabled: boolean;
  onSelect: (templateAuth: DashboardTemplateAuthObject) => void;
  onCreate?: (code: string) => void;
}

export const AuthChangeDropdown = memo(
  (props: AuthChangeDropdownProps) => {
    const {
      listTemplateAuth,
      selectedTemplateAuth,
      authConfig,
      authorizeUrl,
      templateName,
      loginButtonImage,
      isOauthSignUp,
      isButtonDisabled,
      onSelect,
      onCreate,
    } = props
    // const signInButton = useRef<HTMLButtonElement>(null)

    const StyledCard = styled(Card, {
      minWidth: '300px',
      cursor: 'pointer',
      opacity: 1,
      '&:hover': {
        opacity: 0.5
      },
    })

    const colorMode = useColorMode()

    return (
      <SimpleDropdownMenu
        trigger={
          <Button
            variant="secondary"
            size="sm"
          >
            Change
          </Button>
        }
      >
        {/* {JSON.stringify(listTemplateAuth)} */}
        <Box py="3">
          {
            listTemplateAuth.map(tmpAuth => {
              return (
                <Box key={tmpAuth.templateAuthId} my="7">
                  <DropdownMenu.Item>
                    <StyledCard onClick={() => onSelect(tmpAuth)}>
                      <Flex align="center">
                        <SimpleRadio
                          value={selectedTemplateAuth.templateAuthId}
                          options={[
                            { label: '', value: tmpAuth.templateAuthId }
                          ]}
                        />
                        <Box ml="4">
                          <Box><Text fontWeight="bold">{tmpAuth.title}</Text></Box>
                          <Box><Text variant="description">{tmpAuth.label}</Text></Box>
                        </Box>
                      </Flex>
                    </StyledCard>
                  </DropdownMenu.Item>
                </Box>
              )
            })
          }

          {
            isOauthSignUp && (
              <OauthPopup
                url={authorizeUrl}
                title={templateName}
                width={800}
                height={800}
                onClose={() => console.log}
                onCode={(code) => {
                  if (onCreate) {
                    onCreate(code)
                  }
                }}
              >
                <Flex justify="end" mr="1">
                  {
                    loginButtonImage === undefined
                      ? <Box>
                        <Button
                          variant="secondary"
                          size="sm"
                          isDisabled={isButtonDisabled}
                          onClick={() => console.log()}
                        >
                          Sign in {templateName}
                        </Button>
                      </Box>

                      : <Box>
                        <Button
                          variant="actionText"
                          isDisabled={isButtonDisabled}
                          onClick={() => console.log()}
                        >
                          {
                            colorMode === "light"
                              ? <Image src={loginButtonImage.light} />
                              : <Image src={loginButtonImage.dark} />
                          }
                        </Button>
                      </Box>
                  }
                </Flex>
              </OauthPopup>
            )
          }
        </Box>
      </SimpleDropdownMenu>
    )
  }
)
