import { useEditorOrThrow } from "../utils";

const useTextAlign = ({ align }: { align: "left" | "center" | "right" }) => {
  const editor = useEditorOrThrow();

  const isActive = editor.isActive({ textAlign: align });

  const handleToggle = () => {
    if (isActive) {
      editor.chain().focus().unsetTextAlign().run();
    } else {
      editor.chain().focus().setTextAlign(align).run();
    }
  };

  const isDisabled = isActive
    ? !editor.can().chain().focus().setTextAlign(align).run()
    : !editor.can().chain().focus().unsetTextAlign().run();

  return {
    handleToggle,
    isActive,
    isDisabled,
  };
};

export { useTextAlign };
