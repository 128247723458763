import {
  CanvasVariableClientModel,
  CanvasVariableDataType,
} from "./CanvasVariableClientModel";

export class CanvasVariableClientModelFactory {
  static fromVariableData(variableData: CanvasVariableDataType) {
    return new CanvasVariableClientModel(variableData);
  }
}
