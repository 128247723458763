import * as RadixToast from "@radix-ui/react-toast";
import { Flex } from "@radix-ui/themes";
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiErrorCircle, BiInfoCircle } from "react-icons/bi";
import { match } from "ts-pattern";
import { styled } from "~/stitches";
import { Box } from "../Box";
import { Button } from "../Button";
import { Text } from "../Text";

const genRandomId = () => Math.random().toString(32).substring(2);

export type ToastItem = {
  id: string;
  type: "success" | "error" | "info";
  title: ReactNode;
  description?: ReactNode;
  duration?: number;
  isOpen: boolean;
};

type ToastParams = Omit<ToastItem, "id" | "isOpen">;

const ToastContext = createContext<(params: ToastParams) => void>(() => null);

export function useToast() {
  return useContext(ToastContext);
}

const ToastRoot = styled(RadixToast.Root, {
  backgroundColor: "white",
  borderRadius: "6px",
  boxShadow: "$2",
  padding: "15px",
  display: "grid",
  gridTemplateAreas: `'title action' 'description action'`,
  gridTemplateColumns: "auto max-content",
  columnGap: "15px",
  alignItems: "center",

  "&[data-state=open]": {
    animation: "slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1)",
  },
  "&[data-state=closed]": {
    animation: "hide 100ms ease-in",
  },
  "&[data-swipe=move]": {
    transform: "translateX(var(--radix-toast-swipe-move-x))",
  },
  "&[data-swipe=cancel]": {
    transform: "translateX(0)",
    transition: "transform 200ms ease-out",
  },
  "&[data-swipe=end]": {
    animation: "swipeOut 100ms ease-out",
  },
});

const ToastDescription = styled(RadixToast.Description, {
  gridArea: "description",
  margin: 0,
  color: "$textDescription",
  fontSize: "13px",
  lineHeight: 1.3,
});

type ToastProps = {
  value: ToastItem;
  onClose: (id: string) => void;
};

const Toast = (props: ToastProps) => {
  const { value, onClose } = props;

  const leftIcon = useMemo(() => {
    return match(value.type)
      .with("error", () => <BiErrorCircle color="red" size={24} />)
      .with("success", () => (
        <AiOutlineCheckCircle
          style={{ gridArea: "icon" }}
          color="green"
          size={24}
        />
      ))
      .with("info", () => <BiInfoCircle color="#3366FF" size={24} />)
      .exhaustive();
  }, [value.type]);

  return (
    <ToastRoot
      open={value.isOpen}
      onOpenChange={(isOpen) => !isOpen && onClose(value.id)}
      duration={value.duration}
    >
      <Flex gap="4" align="center">
        <Box style={{ width: 24, height: 24 }}>{leftIcon}</Box>
        <Flex direction="column" gap="1">
          <Text variant="subheading">{value.title}</Text>
          {value.description && (
            <ToastDescription>{value.description}</ToastDescription>
          )}
        </Flex>
      </Flex>

      <RadixToast.Close style={{ gridArea: "action" }} asChild>
        <Button variant="actionText" size="sm">
          Close
        </Button>
      </RadixToast.Close>
    </ToastRoot>
  );
};

const ToastViewPort = styled(RadixToast.Viewport, {
  position: "fixed",
  bottom: 15,
  right: 15,
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  width: "390px",
  maxWidth: "100vw",
  margin: 0,
  listStyle: "none",
  zIndex: 2147483647,
  outline: "none",
});

export const ToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastItem[]>([]);

  const toast = useCallback((params: ToastParams) => {
    const id = genRandomId();
    setToasts((prev) => [...prev, { id, isOpen: true, ...params }]);
  }, []);

  const closeToast = useCallback((id: string) => {
    setToasts((prev) =>
      prev.map((value) =>
        value.id === id ? { ...value, isOpen: false } : value
      )
    );

    setTimeout(() => {
      setToasts((prev) => prev.filter((value) => value.id !== id));
    }, 200);
  }, []);

  return (
    <ToastContext.Provider value={toast}>
      <RadixToast.Provider duration={5000}>
        {children}
        {toasts.map((value) => (
          <Toast key={value.id} value={value} onClose={closeToast} />
        ))}
        <ToastViewPort />
      </RadixToast.Provider>
    </ToastContext.Provider>
  );
};
