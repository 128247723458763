import { Box } from "@radix-ui/themes";
import { ReactNode } from "react";
import { Text } from "~/components_next/Text";

const FormSection = ({
  label,
  children,
}: {
  label: string;
  children: ReactNode;
}) => {
  return (
    <section>
      <Box mb="1">
        <Text variant="subheading">{label}</Text>
      </Box>
      {children}
    </section>
  );
};

export { FormSection };
