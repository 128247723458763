import { RecordFilterConditionUnit } from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";
import { FilterConditionUnitClientModel } from "./FilterConditionUnitClientModel";

export class FilterConditionUnitClientModelDefactory {
  public static toRecordFilterConditionUnit(
    filterCondition: FilterConditionUnitClientModel
  ): RecordFilterConditionUnit {
    const key = filterCondition.data.targetField.name;
    return match(filterCondition.data.operation)
      .with(
        {
          type: P.union(
            "equal",
            "notEqual",
            "lessThan",
            "lessThanOrEqual",
            "greaterThan",
            "greaterThanOrEqual",
            "like",
            "startsWith",
            "endsWith",
            "in",
            "notIn"
          ),
        },
        (operation) => ({
          key,
          operator: operation.operatorValue,
          value: operation.operandValue,
        })
      )
      .with({ type: P.union("isNull", "notNull", "me") }, (operation) => ({
        key,
        operator: operation.operatorValue,
      }))
      .exhaustive();
  }
}
