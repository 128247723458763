import useApiPayment from "~/api/useApiPayment";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { paymentKeys } from "../keys";

interface UseCreatePaymentSessionMutationParams {
  teamSlug: string;
}

type CreatePaymentSessionMutationProps = {
  planId: string;
  redirectUrl: string;
}

const useCreatePaymentSessionMutation = ({
  teamSlug,
}: UseCreatePaymentSessionMutationParams) => {
  const { _createSubscriptionSession } = useApiPayment();
  const client = useQueryClient();

  return {
    mutationFn: ({ planId, redirectUrl, }: CreatePaymentSessionMutationProps) => {
      return _createSubscriptionSession({
        teamSlug,
        planId,
        redirectUrl,
      });
    },
    onSuccess: () => {
      return invalidateAll(client, paymentKeys.all({ teamSlug }));
    },
  };
};

export { useCreatePaymentSessionMutation, type CreatePaymentSessionMutationProps };
