import { Flex } from "@radix-ui/themes";
import { BsPlusLg } from "react-icons/bs";
import {
  useRecordsTableBaseHandleTableSelectionChange,
  useRecordsTableBaseOnAddFieldClick,
  useRecordsTableBaseTableSelection,
} from "../context/ReactTableBaseContext";
import { IconButton } from "../../IconButton";

const AddFieldButton = () => {
  const onAddFieldClick = useRecordsTableBaseOnAddFieldClick();
  const tableSelection = useRecordsTableBaseTableSelection();
  const onTableSelectionChange =
    useRecordsTableBaseHandleTableSelectionChange();

  const handleAddFieldClick = () => {
    onAddFieldClick?.();
    onTableSelectionChange(tableSelection.clearAllCellSelection());
  };

  if (!onAddFieldClick) {
    return null;
  }

  return (
    <Flex width="100%" height="100%" align="center" justify="center">
      <IconButton
        size="md"
        onClick={handleAddFieldClick}
        icon={<BsPlusLg />}
        tooltip="Create Field"
      />
    </Flex>
  );
};

export { AddFieldButton };
