import { SimpleField } from "@usemorph/morph-dashboard-types";
import { Text } from "~/components_next/Text";
import { FieldTypeForm } from "./FieldType/FieldTypeForm";
import { Input } from "~/components_next/Input";
import { TextArea } from "~/components_next/TextArea";
import { Box, Flex } from "@radix-ui/themes";
import { Spacer } from "~/components_next/Spacer";
import { Checkbox } from "~/components_next/Checkbox";
import { useMemo } from "react";

// 全てのfield作成に共通するロジックとUIのみ載せる
// それ以外はpropsでカスタマイズできるようにする
// todo: FieldFormを使っている箇所をこっちに書き換える

const isEditableField = (f: SimpleField): boolean => {
  const uneditableFields: SimpleField["type"][] = [
    "formula",
    "createdAt",
    "createdBy",
    "lastEditedAt",
    "lastEditedBy",
  ];

  return !uneditableFields.includes(f.type);
};

type EditFieldFormProps = {
  field: SimpleField;
  fieldBeforeEdit: SimpleField;
  simpleFields: SimpleField[];
  onChange: (field: SimpleField) => void;
  onTestRunStart: (formula: string) => void;
  isTestRunning: boolean;
  fieldTypes?: SimpleField["type"][];
  convertFieldToText: (field: SimpleField) => string;
};

const EditFieldForm = (props: EditFieldFormProps) => {
  const {
    field,
    fieldBeforeEdit,
    simpleFields,
    onChange,
    onTestRunStart,
    isTestRunning,
    fieldTypes,
    convertFieldToText,
  } = props;

  const isNullableDisabled = !isEditableField(field) || field.primary === true;

  const embeddingTarget = useMemo(() => {
    return simpleFields.find((f) => f.name === field.embeddings) ?? null;
  }, [simpleFields, field.embeddings]);

  return (
    <>
      <Flex direction="column" gap="6">
        {/* Field Name */}
        <Input
          label="Field Name"
          variant="primary"
          value={field.displayName ?? ""}
          onChange={(e) =>
            onChange({
              ...field,
              displayName: e.target.value,
            })
          }
        />
        {/* Field Slug */}
        <Input
          label="Field Slug"
          variant="supplementary"
          value={field.name}
          readOnly
        />

        <Box>
          <Text mb="1">Field Type</Text>
          <FieldTypeForm
            value={{
              previousType: fieldBeforeEdit.type,
              type: field.type,
              members: field.members,
              formula: field.formula,
              embeddingTarget,
            }}
            isReadOnly={false}
            onChange={({ type, members, formula, embeddingTarget }) => {
              onChange({
                ...field,
                type,
                members,
                formula,
                embeddings: embeddingTarget?.name,
              });
            }}
            onTestRunStart={onTestRunStart}
            isTestRunning={isTestRunning}
            simpleFields={simpleFields}
            options={fieldTypes ? { includes: fieldTypes } : undefined}
            convertFieldToText={convertFieldToText}
          />
        </Box>

        {/* Nullable */}
        <Flex>
          <Text>Nullable</Text>
          <Spacer />
          <Checkbox
            size="lg"
            isDisabled={isNullableDisabled}
            isReadOnly={isNullableDisabled}
            value={field.nullable ?? false}
            onChange={(value) =>
              onChange({
                ...field,
                nullable: value,
              })
            }
          />
          <Spacer />
        </Flex>

        {/* Comment */}
        <TextArea
          label="Description"
          variant="primary"
          value={field.comment ?? ""}
          onChange={(e) =>
            onChange({
              ...field,
              comment: e.target.value,
            })
          }
        />
      </Flex>
    </>
  );
};

export { EditFieldForm };
