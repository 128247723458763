import { useQuery } from "react-query";
import { FieldsClientModelFactory } from "~/clientModel/fields";
import { useComposeLoadable } from "~/clientModel/loadable";
import { useListSimpleFieldsQuery } from "~/serverStateStore";

type UseSourceFieldsLoadableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useSourceFieldsLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseSourceFieldsLoadableParams) => {
  return useComposeLoadable(
    useQuery({
      ...useListSimpleFieldsQuery({ teamSlug, databaseId, tableSlug }),
      select: (response) =>
        FieldsClientModelFactory.createFromSimpleFields(response.fields),
    })
  );
};

export const createUseSourceFieldsLoadable = (props: {
  teamSlug: string;
  databaseId: string;
}) => {
  const { teamSlug, databaseId } = props;

  return ({ tableSlug }: { tableSlug: string }) =>
    useSourceFieldsLoadable({ teamSlug, databaseId, tableSlug });
};
