import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModel } from "..";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";

export class JsonFieldTypeClientModel extends FieldTypeClientModelBase {
  public get type(): "json" {
    return "json";
  }

  public get fieldTypeLabel(): "JSON" {
    return "JSON";
  }

  public get isAutoGenerated(): false {
    return false;
  }

  public get isEmbeddable(): true {
    return true;
  }

  public get canBeTargetOfSyncingByComputedField(): true {
    return true;
  }

  public get _updatableFieldTypes(): FieldTypeClientModel["type"][] {
    return ["array"];
  }

  public validate(): ValidationResult {
    return { isValid: true };
  }
}
