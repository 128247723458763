import { useCallback } from "react";
import { Text } from "~/components_next/Text";
import { FlatFilterConditionForm } from "~/features/Fields/FilterCondition/FlatFilterConditionForm";
import { FlatFilterCondition } from "~/features/Fields/FilterCondition/flatFilterConditionTypes";
import { SortConditionForm } from "~/features/Fields/SortCondition/SortConditionForm";
import {
  useFilterCondition,
  useSetFilterCondition,
} from "../../../states/filterCondition";
import {
  useSortCondition,
  useSetSortCondition,
} from "../../../states/sortCondition";
import { validateFilterCondition } from "~/features/Fields/FilterCondition/validateFilterCondition";
import { useViewId } from "~/utilHooks/useViewId";
import { useQuery } from "react-query";
import { useGetViewFieldsQuery } from "~/serverStateStore/views";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { isArrayWithAtLeastOneElement } from "~/utils/commonTypeGuards";
import { RecordSortConditionUnit } from "@usemorph/morph-dashboard-types";
import { useSetQueryMode } from "../../../states/queryMode";
import { match, P } from "ts-pattern";
import { Box, Flex } from "@radix-ui/themes";
import { Spinner } from "~/components_next/Spinner";

type TableViewFilterAndSortFormProps = {
  onClose: () => void;
};

const TableViewFilterAndSortForm = (props: TableViewFilterAndSortFormProps) => {
  const { onClose } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  const filterCondition = useFilterCondition(viewId);
  const setFilterCondition = useSetFilterCondition(viewId);

  const sortCondition = useSortCondition(viewId);
  const setSortCondition = useSetSortCondition(viewId);

  const setQueryMode = useSetQueryMode(viewId);

  const { data: viewFieldsData } = useQuery(
    useGetViewFieldsQuery({
      teamSlug,
      databaseId,
      viewId,
    })
  );

  const handleFilterChange = useCallback(
    (updatedFilterCondition: FlatFilterCondition | null) => {
      match([updatedFilterCondition, sortCondition])
        .with([P.nullish, P.nullish], () => {
          setFilterCondition(null);
          setQueryMode(null);
          onClose();
        })
        .with([P.not(P.nullish), P._], ([updatedFilterCondition]) => {
          if (
            viewFieldsData &&
            validateFilterCondition(
              updatedFilterCondition,
              viewFieldsData.fields
            )
          ) {
            setFilterCondition(updatedFilterCondition);
            setQueryMode("filtersAndSorts");
          }
        })
        .with([P.nullish, P.not(P.nullish)], () => {
          setFilterCondition(null);
        })
        .exhaustive();
    },
    [viewFieldsData, setFilterCondition, sortCondition, onClose, setQueryMode]
  );

  const handleSortChange = useCallback(
    (updatedSortCondition: RecordSortConditionUnit | null) => {
      match([filterCondition, updatedSortCondition])
        .with([P.nullish, P.nullish], () => {
          setSortCondition(null);
          setQueryMode(null);
          onClose();
        })
        .otherwise(() => {
          setQueryMode("filtersAndSorts");
          setSortCondition(updatedSortCondition);
        });
    },
    [setSortCondition, filterCondition, onClose, setQueryMode]
  );

  if (!viewFieldsData || !isArrayWithAtLeastOneElement(viewFieldsData.fields)) {
    return (
      <Flex py="9" align="center" justify="center">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Flex direction="column" p="4" align="start" gap="6">
      <Box>
        <Text variant="subheading" mb="2">
          Filters
        </Text>
        <FlatFilterConditionForm
          simpleFields={viewFieldsData.fields}
          flatFilterCondition={filterCondition}
          onChangeFlatFilterCondition={handleFilterChange}
        />
      </Box>
      <Box>
        <Text variant="subheading" mb="2">
          Sorts
        </Text>
        <SortConditionForm
          simpleFields={viewFieldsData.fields}
          sortCondition={sortCondition}
          onChangeSortCondition={handleSortChange}
        />
      </Box>
    </Flex>
  );
};

export { TableViewFilterAndSortForm };
