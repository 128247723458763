import { SimpleField } from "@usemorph/morph-dashboard-types";
import { SimpleMultiSelect } from "~/components_next/Select";

export const SelectForm = ({
  defaultValue,
  fields,
  onChange,
}: {
  defaultValue?: string[];
  fields: SimpleField[];
  onChange: (format: string[]) => void;
}) => {
  const getDefaultValue = (defaultValue: string[]) => {
    return defaultValue.reduce(
      (acc: { label: string; value: string }[], val: string) => {
        const field = fields.find((field) => field.name === val);
        if (field) {
          return [
            ...acc,
            {
              label: field.displayName ?? field.name,
              value: field.name,
            },
          ];
        } else {
          return acc;
        }
      },
      []
    );
  };

  const getLabel = (option: string) => {
    const field = fields.find((field) => field.name === option);
    if (field) {
      return field.displayName || field.name;
    } else {
      return option;
    }
  };

  return (
    <SimpleMultiSelect
      variant="primary"
      value={defaultValue ?? null}
      options={fields.map((field) => field.name)}
      onChange={(v) => onChange(v ?? [])}
      renderBadge={(option) => getLabel(option)}
      renderListItem={(option) => getLabel(option)}
    />
  );
};
