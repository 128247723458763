import {
  DashboardUserResponse,
  RowInfoSchema,
} from "@usemorph/morph-dashboard-types";
import { match } from "ts-pattern";
import { FieldClientModel } from "~/clientModel/fields/field";
import { FindUserClientModelFactory } from "~/clientModel/user";
import {
  AggregateValueRecordEntryClientModel,
  ArrayRecordEntryClientModel,
  AttachmentRecordEntryClientModel,
  AutoBigNumberRecordEntryClientModel,
  AutoNumberRecordEntryClientModel,
  BigNumberRecordEntryClientModel,
  BooleanRecordEntryClientModel,
  CalculationRecordEntryClientModel,
  CreatedAtRecordEntryClientModel,
  DateRecordEntryClientModel,
  DateTimeRecordEntryClientModel,
  DecimalRecordEntryClientModel,
  EmailRecordEntryClientModel,
  FormulaRecordEntryClientModel,
  GenerateTextRecordEntryClientModel,
  HtmlRecordEntryClientModel,
  ImageRecordEntryClientModel,
  JsonRecordEntryClientModel,
  LastEditedAtRecordEntryClientModel,
  LongTextRecordEntryClientModel,
  MaskedValueRecordEntryClientModel,
  MultiSelectRecordEntryClientModel,
  NumberRecordEntryClientModel,
  PhoneNumberRecordEntryClientModel,
  RecordEntryClientModel,
  ShortTextRecordEntryClientModel,
  SingleSelectRecordEntryClientModel,
  SmartFunctionRecordEntryClientModel,
  SyncValueRecordEntryClientModel,
  TimeRecordEntryClientModel,
  UrlRecordEntryClientModel,
} from ".";
import {
  RecordEntryMetaClientModel,
  RecordEntryMetaClientModelFactory,
} from "./recordEntryMeta";
import { CreatedByRecordEntryClientModel } from "./system/CreatedByRecordEntryClientModel";
import { LastEditedByRecordEntryClientModel } from "./system/LastEditedByRecordEntryClientModel";

// todo
// multiSelect型は、現状ではstringifyされた状態で返ってくる
// 将来的には、string[]で返してもらいたい(/featuresの旧実装を全て移行したら、バックエンドと合わせて更新する)
const convertToMultiSelectRawValue = (value: unknown): unknown => {
  if (typeof value === "string") {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }
  return value;
};

export class RecordEntryClientModelFactory {
  public static createFromRawValueAndFieldClientModel(
    rawValue: unknown,
    field: FieldClientModel,
    supplements?: { rowInfo?: RowInfoSchema }
  ): RecordEntryClientModel {
    const { name } = field;

    const meta: RecordEntryMetaClientModel | null = supplements?.rowInfo
      ? RecordEntryMetaClientModelFactory.fromRowInfoSchemaData(
          supplements.rowInfo.fieldInfo.flatMap(({ field: fieldName, data }) =>
            field.name === fieldName ? [data] : []
          )
        )
      : null;

    if (MaskedValueRecordEntryClientModel.isMaskedValue(rawValue)) {
      return new MaskedValueRecordEntryClientModel({
        key: name,
        rawValue,
        meta,
      });
    }

    return (
      match(field.type)
        // text
        .with(
          { type: "shortText" },
          () =>
            new ShortTextRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "longText" },
          () =>
            new LongTextRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "email" },
          () =>
            new EmailRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "phoneNumber" },
          () =>
            new PhoneNumberRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "url" },
          () =>
            new UrlRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "date" },
          () =>
            new DateRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "datetime" },
          () =>
            new DateTimeRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "time" },
          () =>
            new TimeRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )

        // number
        .with(
          { type: "number" },
          () =>
            new NumberRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "bigNumber" },
          () =>
            new BigNumberRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "decimal" },
          () =>
            new DecimalRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "autoNumber" },
          () =>
            new AutoNumberRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "autoBigNumber" },
          () =>
            new AutoBigNumberRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )

        // other primitives
        .with(
          { type: "boolean" },
          () =>
            new BooleanRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )

        // select
        .with(
          { type: "singleSelect" },
          () =>
            new SingleSelectRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "multiSelect" },
          () =>
            new MultiSelectRecordEntryClientModel({
              key: name,
              rawValue: convertToMultiSelectRawValue(rawValue),
              meta,
            })
        )

        // file
        .with(
          { type: "image" },
          () =>
            new ImageRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "attachment" },
          () =>
            new AttachmentRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )

        // structured data
        .with(
          { type: "json" },
          () =>
            new JsonRecordEntryClientModel({
              key: name,
              rawValue: JSON.stringify(rawValue),
              meta,
            })
        )
        .with(
          { type: "array" },
          () =>
            new ArrayRecordEntryClientModel({
              key: name,
              rawValue: JSON.stringify(rawValue),
              meta,
            })
        )
        .with(
          { type: "html" },
          () =>
            new HtmlRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )

        // computed
        .with(
          { type: "formula" },
          () =>
            new FormulaRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "syncValue" },
          () =>
            new SyncValueRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "generateText" },
          () =>
            new GenerateTextRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "calculation" },
          () =>
            new CalculationRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "aggregateValue" },
          () =>
            new AggregateValueRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "smartFunction" },
          () =>
            new SmartFunctionRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )

        // system
        .with(
          { type: "createdAt" },
          () =>
            new CreatedAtRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "createdBy" },
          () =>
            new CreatedByRecordEntryClientModel({
              key: name,
              // todo: この変換をどこで捌くか検討(このファイル内に同様の処理が他3箇所あり)
              // todo: 型
              rawValue: rawValue
                ? FindUserClientModelFactory.fromUserResponse(
                    rawValue as DashboardUserResponse
                  )
                : null,
              meta,
            })
        )
        .with(
          { type: "lastEditedAt" },
          () =>
            new LastEditedAtRecordEntryClientModel({
              key: name,
              rawValue,
              meta,
            })
        )
        .with(
          { type: "lastEditedBy" },
          () =>
            new LastEditedByRecordEntryClientModel({
              key: name,
              rawValue: rawValue
                ? FindUserClientModelFactory.fromUserResponse(
                    rawValue as DashboardUserResponse
                  )
                : null,
              meta,
            })
        )
        .exhaustive()
    );
  }
}
