import { SimpleField } from "@usemorph/morph-dashboard-types";
import {
  AllRecordModelValueUnion,
  RecordModel,
} from "../types/RecordModelValue";
import {
  RecordModelNullableValueTypes,
  RecordModelValueTypes,
} from "../types/RecordModelValueTypes";

/**
 * 各filed typeの空の値
 */
const nullableValueTypes: {
  [Key in SimpleField["type"]]: RecordModelNullableValueTypes[Key] | undefined;
} = {
  shortText: null,
  longText: null,
  richText: null,
  formula: null,
  date: null,
  datetime: null,
  time: null,
  email: null,
  url: null,
  phoneNumber: null,
  singleSelect: null,
  multiSelect: [],
  number: null,
  bigNumber: null,
  decimal: null,
  autoNumber: null,
  autoBigNumber: null,
  boolean: null,
  image: null,
  attachment: null,
  array: null,
  json: null,
  createdAt: null,
  createdBy: null,
  lastEditedAt: null,
  lastEditedBy: null,
  reference: null,
  multiReference: null,
  html: null,
  embeddings: null,
  python: null,
  rowInfo: null,
};

const nonNullableValueTypes: {
  [Key in SimpleField["type"]]: RecordModelValueTypes[Key] | undefined;
} = {
  shortText: "",
  longText: "",
  richText: "",
  formula: undefined,
  date: undefined,
  datetime: undefined,
  time: undefined,
  email: "",
  url: "",
  phoneNumber: "",
  singleSelect: undefined,
  multiSelect: [],
  number: undefined,
  bigNumber: undefined,
  decimal: undefined,
  autoNumber: undefined,
  autoBigNumber: undefined,
  boolean: undefined,
  image: undefined,
  attachment: undefined,
  array: undefined,
  json: undefined,
  createdAt: undefined,
  createdBy: undefined,
  lastEditedAt: undefined,
  lastEditedBy: undefined,
  reference: undefined,
  multiReference: undefined,
  html: "",
  embeddings: undefined,
  python: undefined,
  rowInfo: undefined,
};

const createRecordModelWithEmptyValues = (
  fields: SimpleField[]
): RecordModel => {
  return fields.reduce((recordModel, field): RecordModel => {
    const nullable = field.nullable === true;
    const type = field.type;
    const isPrimary = field.primary === true;

    const newValue: AllRecordModelValueUnion = {
      isValid: true,
      isPrimary,
      nullable,
      type,
      value: nullable ? nullableValueTypes[type] : nonNullableValueTypes[type],
    } as AllRecordModelValueUnion; // todo: キャストやめる

    return {
      ...recordModel,
      [field.name]: newValue,
    };
  }, {} as RecordModel);
};

export {
  createRecordModelWithEmptyValues,
  nullableValueTypes,
  nonNullableValueTypes,
};
