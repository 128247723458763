import * as Sentry from "@sentry/browser";

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const SENTRY_VERSION_CODE = import.meta.env.VITE_SENTRY_VERSION_CODE;
const SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT;

export const setupSentry = () => {
  if (SENTRY_ENVIRONMENT !== "dev-local") {
    Sentry.init({
      dsn: SENTRY_DSN,
      release: SENTRY_VERSION_CODE,
      environment: SENTRY_ENVIRONMENT,
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        }),
        new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
};
