import { useMemo } from "react";
import {
  CsvImportSchemaClientModel,
  CsvInsertSchemaEditClientModel,
} from "~/clientModel/csvImport";
import { Executable } from "~/clientModel/executable";
import { StorageObjectClientModel } from "~/clientModel/storage/object";
import { Dialog } from "~/components_next/Dialog";
import { CsvImportFileUploadDialogContent } from "../csvImport/dialogContent/CsvImportFileUploadDialogContent";
import { CsvImportSheetSelectDialogContent } from "../csvImport/dialogContent/CsvImportSheetSelectDialogContent";
import { CsvInsertSchemaEditorDialogContent } from "./schemaEditor/CsvInsertSchemaEdtiorDialogContent";
import { Loadable } from "~/clientModel/loadable";
import { FieldsClientModel } from "~/clientModel/fields";

type CsvInsertPresenterProps = {
  isOpen: boolean;
  onIsOpenChange: (isOpen: boolean) => void;
  createObjectExecutable: Executable<
    { key: string; contentType: string; file: File },
    StorageObjectClientModel
  >;
  createImportSchemaExecutable: Executable<
    {
      sheet?: number | undefined;
    },
    CsvImportSchemaClientModel,
    unknown
  >;
  updateImportSchemaExecutable: Executable<
    {
      csvImportSchemaInstance: CsvInsertSchemaEditClientModel;
    },
    CsvImportSchemaClientModel,
    unknown
  >;
  runImportExecutable: Executable<
    { importDataSchemaId: string },
    { message: string },
    unknown
  >;
  fieldsLoadable: Loadable<FieldsClientModel>;
};

const CsvInsertPresenter = (props: CsvInsertPresenterProps) => {
  const {
    isOpen,
    onIsOpenChange,
    createObjectExecutable,
    createImportSchemaExecutable,
    updateImportSchemaExecutable,
    runImportExecutable,
    fieldsLoadable,
  } = props;

  /**
   * UI state
   */
  const step = useMemo(() => {
    if (createImportSchemaExecutable.data) {
      return "editSchema";
    }
    if (
      createObjectExecutable.data &&
      createObjectExecutable.data?.shouldSelectSheet
    ) {
      return "selectSheet";
    }
    return "upload";
  }, [createObjectExecutable.data, createImportSchemaExecutable.data]);

  return (
    <>
      <Dialog.Root open={isOpen} onOpenChange={onIsOpenChange}>
        <Dialog.Content>
          {/* アップロード前 */}
          {step === "upload" && (
            <CsvImportFileUploadDialogContent
              createObjectExecutable={createObjectExecutable}
              createImportSchemaExecutable={createImportSchemaExecutable}
            />
          )}

          {/* アップロード後 */}
          {/* シート選択 */}
          {step === "selectSheet" && (
            <CsvImportSheetSelectDialogContent
              sheets={createObjectExecutable.data!.metaData?.sheets || []}
              createImportSchemaExecutable={createImportSchemaExecutable}
            />
          )}

          {/* スキーマ編集 */}
          {step === "editSchema" && (
            <CsvInsertSchemaEditorDialogContent
              importSchema={createImportSchemaExecutable.data!}
              onClose={() => onIsOpenChange(false)}
              updateImportSchemaExecutable={updateImportSchemaExecutable}
              runImportExecutable={runImportExecutable}
              fieldsLoadable={fieldsLoadable}
            />
          )}
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};

export { CsvInsertPresenter, type CsvInsertPresenterProps };
