import { SimpleField } from "@usemorph/morph-dashboard-types";

/**
 * 普通のSimpleField → ドット区切りのSimpleField
 */

const convertSimpleFieldToDotNotatedSimpleField = (
  field: SimpleField,
  mainTableSlug?: string
): SimpleField => {
  if (!mainTableSlug) {
    return {
      ...field,
      name: `${field.originalTableSlug}.${field.name}`,
    };
  }

  const dotNotatedFieldName =
    field.originalTableSlug === mainTableSlug || !field.originalTableSlug
      ? field.name
      : `${field.originalTableSlug}.${field.name}`;

  return {
    ...field,
    name: dotNotatedFieldName,
  };
};

const convertSimpleFieldsToDotNotatedSimpleFields = (
  fields: SimpleField[],
  mainTableSlug?: string
): SimpleField[] => {
  return fields.map((field) =>
    convertSimpleFieldToDotNotatedSimpleField(field, mainTableSlug)
  );
};

/**
 *  ドット区切りのSimpleField → 普通のSimpleField
 */

const convertDotNotatedSimpleFieldToSimpleField = (
  field: SimpleField
): SimpleField => {
  const matchResult = field.name.match(/^(\w+)\.(\w+)$/);
  return {
    ...field,
    name: matchResult ? matchResult[2] : field.name,
  };
};

const convertDotNotatedSimpleFieldsToSimpleFields = (
  fields: SimpleField[]
): SimpleField[] => {
  return fields.map((field) =>
    convertDotNotatedSimpleFieldToSimpleField(field)
  );
};

/**
 * アンスコ区切りのSimpleField → 普通のSimpleField
 */

const convertUnderscoreNotatedSimpleFieldToSimpleField = (
  field: SimpleField,
  mainTableSlug: string
): SimpleField => {
  if (field.originalTableSlug === mainTableSlug) {
    // main table由来のfieldの場合
    return field;
  } else if (field.originalTableSlug) {
    // join table由来のfieldの場合
    const regExp = new RegExp(`^${field.originalTableSlug}_(\\w+)$`);
    const fieldName = field.name.match(regExp)?.[1];
    if (!fieldName) {
      throw new Error(
        `invalid field name: ${field.name} in table: ${field.originalTableSlug}`
      );
    }
    return {
      ...field,
      name: fieldName,
    };
  } else {
    // どのtable由来のfieldでもない場合(集合関数など)
    return field;
  }
};

const convertUnderscoreNotatedSimpleFieldsToSimpleFields = (
  fields: SimpleField[],
  mainTableSlug: string
): SimpleField[] => {
  return fields.map((field) =>
    convertUnderscoreNotatedSimpleFieldToSimpleField(field, mainTableSlug)
  );
};

export {
  convertSimpleFieldToDotNotatedSimpleField,
  convertSimpleFieldsToDotNotatedSimpleFields,
  convertDotNotatedSimpleFieldToSimpleField,
  convertDotNotatedSimpleFieldsToSimpleFields,
  convertUnderscoreNotatedSimpleFieldToSimpleField,
  convertUnderscoreNotatedSimpleFieldsToSimpleFields,
};
