import { FieldClientModel } from "~/clientModel/fields/field";
import { Checkbox } from "~/components_next/Checkbox";

type NullableInputProps = {
  field: FieldClientModel;
  onFieldChange: (field: FieldClientModel) => void;
  isReadOnly?: boolean;
};

export const NullableInput = (props: NullableInputProps) => {
  const { field, onFieldChange, isReadOnly } = props;

  return (
    <Checkbox
      label="Nullable"
      size="lg"
      isDisabled={!field.canUpdateIsNullable}
      isReadOnly={!field.canUpdateIsNullable || isReadOnly}
      value={field.isNullable}
      onChange={(isNullable) =>
        onFieldChange(field.updateIsNullable(isNullable))
      }
    />
  );
};
