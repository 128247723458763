import { notebookKeys } from "../keys";
import useApiNotebook from "~/api/useApiNotebook";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import { useQueryClient } from "react-query";

interface UseFindNotebookQueryParams {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
}

const useFindNotebookQuery = ({
  databaseId,
  teamSlug,
  notebookId,
}: UseFindNotebookQueryParams) => {
  const { _findNotebooks } = useApiNotebook();

  const client = useQueryClient();
  client.setQueryDefaults(
    notebookKeys.findNotebook({
      databaseId,
      teamSlug,
      notebookId,
    }),
    commonQueryOptions
  );

  return {
    queryKey: notebookKeys.findNotebook({
      databaseId,
      teamSlug,
      notebookId,
    }),
    queryFn: () => {
      return _findNotebooks({
        teamSlug,
        notebookId,
      });
    },
  };
};

export { useFindNotebookQuery };
