// 他のviewでも使いそうだったらcommonに移動する

import constate from "constate";
import { useMemo } from "react";
import { ViewSetting } from "~/features/View";
import { useReactiveState } from "~/hooks/useReactiveState";

const useViewSettingsContext = (props: { setting: ViewSetting }) => {
  const { setting } = props;
  const [viewSetting, setViewSetting] = useReactiveState<ViewSetting>(setting);

  const hasViewSettingChanged = useMemo(() => {
    return JSON.stringify(setting) !== JSON.stringify(viewSetting);
  }, [viewSetting, setting]);

  return {
    viewSetting,
    setViewSetting,
    hasViewSettingChanged,
  };
};

export const [
  ViewSettingProvider,
  useViewSetting,
  useSetViewSetting,
  useViewSettingChanged,
] = constate(
  useViewSettingsContext,
  (value) => value.viewSetting,
  (value) => value.setViewSetting,
  (value) => value.hasViewSettingChanged
);
