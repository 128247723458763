import {
  CanvasCreateCellClientModel,
  CanvasCreateCellDataType,
} from "./CanvasCreateCellClientModel";

export class CanvasCreateCellClientModelFactory {
  static create(data: CanvasCreateCellDataType) {
    return new CanvasCreateCellClientModel(data);
  }
}
