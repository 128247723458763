import { DashboardPageDetailObject } from "@usemorph/morph-dashboard-types";
import {
  DashboardCellsClientModel,
  DashboardCellsClientModelFactory,
} from "../DashboardCellClientModel/";

export type FindDashboardPageDataType = DashboardPageDetailObject;

export class FindDashboardPageClientModel {
  private data: FindDashboardPageDataType;
  private _cells: DashboardCellsClientModel;

  constructor(data: FindDashboardPageDataType) {
    this.data = data;
    this._cells =
      DashboardCellsClientModelFactory.fromDashboardPageCellResponse(
        data.cells
      );
  }

  get pageId() {
    return this.data.pageId;
  }

  get pageName() {
    return this.data.pageName;
  }

  get description() {
    return this.data.description;
  }

  get isPublic() {
    return this.data.isPublic;
  }

  get isPrivate() {
    return this.data.isPrivate;
  }

  get createdAt() {
    return this.data.createdAt;
  }

  get cells() {
    return this._cells;
  }

  get databaseId() {
    return this.data.databaseId;
  }

  get notebookId() {
    return this.data.notebookId;
  }
}
