import {
  DashboardBotListResponse,
  DashboardBotResponse,
  QueryRecordRequestBodyObject,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

export default function useApiBot() {
  const { executeRequest } = useApi();

  // list bot
  const _listBots = async ({
    databaseId,
    teamSlug,
    limit,
    skip,
  }: {
    databaseId: string;
    teamSlug: string;
    limit?: number;
    skip?: number;
  }): Promise<DashboardBotListResponse> => {
    return executeRequest(
      "GET",
      `/${databaseId}/bot`,
      {
        limit,
        skip,
      },
      { teamSlug },
      undefined
    );
  };

  // find bot
  const _findBot = async ({
    teamSlug,
    databaseId,
    botId,
  }: {
    teamSlug: string;
    databaseId: string;
    botId: string;
  }): Promise<DashboardBotResponse> => {
    return executeRequest(
      "GET",
      `/${databaseId}/bot/${botId}`,
      {},
      { teamSlug },
      undefined
    );
  };

  // create bot
  const _createBot = async ({
    databaseId,
    teamSlug,
    tableSlug,
    botName,
    description,
    query,
  }: {
    teamSlug: string;
    databaseId: string;
    tableSlug: string;
    botName: string;
    description?: string;
    query: QueryRecordRequestBodyObject;
  }) => {
    return executeRequest<DashboardBotResponse>(
      "POST",
      `/${databaseId}/bot`,
      {},
      { teamSlug },
      {
        tableSlug,
        botName,
        description,
        query,
      }
    );
  };

  // update bot
  const _updateBot = async ({
    databaseId,
    teamSlug,
    botId,
    tableSlug,
    query,
  }: {
    teamSlug: string;
    databaseId: string;
    botId: string;
    tableSlug: string;
    query: QueryRecordRequestBodyObject;
  }) => {
    return executeRequest<DashboardBotResponse>(
      "PUT",
      `/${databaseId}/bot/${botId}`,
      {},
      { teamSlug },
      {
        tableSlug,
        query,
      }
    );
  };

  // delete bot

  const _deleteBot = async ({
    databaseId,
    teamSlug,
    botId,
  }: {
    teamSlug: string;
    databaseId: string;
    botId: string;
  }) => {
    return executeRequest<DashboardBotResponse>(
      "DELETE",
      `/${databaseId}/bot/${botId}`,
      {},
      { teamSlug },
      {}
    );
  };

  return {
    _listBots,
    _findBot,
    _createBot,
    _updateBot,
    _deleteBot,
  };
}
