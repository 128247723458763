import { useQueryClient } from "react-query";
import useApiWidgetData from "~/api/useApiWidgetData";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { widgetDataKeys } from "../keys";

type UseDeleteWidgetDataMutationParams = {
  databaseId: string;
  teamSlug: string;
};

const useDeleteWidgetDataMutation = ({
  teamSlug,
  databaseId,
}: UseDeleteWidgetDataMutationParams) => {
  const { _deleteWidgetData } = useApiWidgetData();
  const client = useQueryClient();

  return {
    mutationFn: ({
      tableSlug,
      apiKey,
    }: {
      apiKey?: string | undefined;
      tableSlug?: string | undefined;
    }) => {
      return _deleteWidgetData({
        teamSlug,
        databaseId,
        tableSlug,
        apiKey,
      });
    },
    onSuccess: () => {
      return invalidateAll(
        client,
        widgetDataKeys.all({
          teamSlug,
          databaseId,
        })
      );
    },
  };
};

export { useDeleteWidgetDataMutation };
