import {
  ForwardedRef,
  forwardRef,
  ReactNode,
  TouchEvent,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Box } from "~/components_next/Box";
import { coworkerBorderVariants } from "~/components_next/hooks/coworkerColors";
import { styled } from "~/stitches";
import { useCoworkersOnCell } from "./useCoworkersOnCell";
import { Handle, NodeResizer, NodeToolbar, Position } from "reactflow";

import { NotebookCellObjectWithMeta } from "../../types/NotebookCellObjectWithMeta.type";
import { useNotebookCellCommonLogic } from "./useNotebookCellCommonLogic";
import { ScrollArea } from "@radix-ui/themes";
import { useNotebookPseudoHoveredCells } from "../../context/NotebookPsuedoCellSelectionContext";
import { Text } from "~/components_next/Text";
import { Flex } from "~/components_next/Flex";
import { UserAvatar } from "~/components_next/User";
import { IconButton } from "~/components_next/IconButton";
import { MdDragIndicator } from "react-icons/md";

type NotebookCellCardVariant = "default" | "skelton" | "fixed";

const NotebookCellCardElement = styled(Box, {
  variants: {
    variant: {
      default: {
        borderRadius: "var(--radius-4)",
        padding: "20px 10px 10px 10px",
        backgroundColor: "$bg0",
        boxShadow: "var(--shadow-2)",
      },
      fixed: {
        borderRadius: "var(--radius-4)",
        padding: "20px 10px 10px 10px",
        backgroundColor: "$bg0",
        boxShadow: "var(--shadow-2)",
      },
      skelton: {
        borderStyle: "none",
        "&:hover": {
          outline: "solid 1px $slate10",
        },
      },
    },
    coworkerColor: {
      ...coworkerBorderVariants,
    },
    state: {
      selected: {
        outlineStyle: "solid",
        outlineColor: "$blue8",
        outlineWidth: "4px",
        "&:hover": {
          outlineStyle: "solid",
          outlineColor: "$blue8",
          outlineWidth: "4px",
        },
      },
      hovered: {
        outlineStyle: "solid",
        outlineColor: "$blue6",
        outlineWidth: "4px",
      },
    },
  },
});

const DragHandleElement = styled(Box, {
  position: "absolute",
  variants: {
    variant: {
      default: {
        top: "5px",
        left: "5px",
      },
      fixed: {
        top: "5px",
        left: "5px",
      },
      skelton: {
        top: "-40px",
        left: "0px",
      },
    },
  },
});

const handleBaseStyle = {
  width: "48px",
  height: "16px",
  borderRadius: "var(--radius-4)",
  backgroundColor: "var(--colors-slate4)",
};

type NotebookCellCardProps = {
  children?: ReactNode;
  cellObject: NotebookCellObjectWithMeta;
  isNodeSelected: boolean;
  isNodeDragging: boolean;
  variant?: NotebookCellCardVariant;
};

const _NotebookCellCard = (
  props: NotebookCellCardProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const {
    children,
    cellObject,
    isNodeDragging,
    isNodeSelected,
    variant = "default",
  } = props;
  const { cellId, cellType, parentIds, cellName } = cellObject;

  const {
    size,
    handleCardClick,
    showTargetHandle,
    showSourceHandle,
    handleResizeEnd,
  } = useNotebookCellCommonLogic(props);

  const scrollAreaStyle = useMemo(() => {
    if (variant === "default") {
      return {
        width: `${(size.width || 0) - 20}px`,
        height: `${(size.height || 0) - 20}px`,
      };
    }

    return {
      width: `${size.width}px`,
      height: `${size.height}px`,
    };
  }, [size, variant]);

  /**
   * Coworker
   */
  const { coworkerCellColor, coworkersOnCell } = useCoworkersOnCell(cellId);

  /**
   * Scroll events
   */
  const cardRef = useRef<HTMLDivElement>(null);
  const handleTouchMove = (event: TouchEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleWheel = (event: WheelEvent) => {
    if (event.ctrlKey) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const currentCardRef = cardRef.current;
    currentCardRef?.addEventListener("wheel", handleWheel, {
      passive: false,
    });

    return () => {
      currentCardRef?.removeEventListener("wheel", handleWheel);
    };
  }, [cardRef]);

  /**
   * Visual style
   */
  const pseudoHoveredCells = useNotebookPseudoHoveredCells();
  const isPseudoHovered = pseudoHoveredCells.includes(cellId);

  const stateVariant = useMemo(() => {
    if (isNodeDragging || isPseudoHovered) {
      return "hovered";
    }
    if (isNodeSelected) {
      return "selected";
    }
    return undefined;
  }, [isNodeDragging, isNodeSelected, isPseudoHovered]);

  return (
    <Box ref={ref} className={`cellId::${cellId}`}>
      <NotebookCellCardElement
        ref={cardRef}
        state={stateVariant}
        coworkerColor={coworkerCellColor}
        css={{ width: `${size.width}px`, height: `${size.height}px` }}
        position="relative"
        onClick={handleCardClick}
        variant={variant}
        onTouchMove={handleTouchMove}
        className={isNodeSelected ? "nowheel nodrag" : ""}
      >
        {/* ここのやり方考えないといけない */}
        {showTargetHandle && (
          <Handle
            type="target"
            position={Position.Top}
            isConnectable
            style={{
              ...handleBaseStyle,
              transform: "translate(-24px, -4px)",
            }}
          />
        )}
        {variant === "fixed" ? (
          <> {children} </>
        ) : (
          <ScrollArea style={scrollAreaStyle}>{children}</ScrollArea>
        )}
        {showSourceHandle && (
          <Handle
            type="source"
            position={Position.Bottom}
            isConnectable
            style={{
              ...handleBaseStyle,
              transform: "translate(-24px, 4px)",
            }}
          />
        )}
        <DragHandleElement
          className="notebook-cell-drag-handle"
          variant={variant}
        >
          <IconButton icon={<MdDragIndicator />} tooltip="Move" size="lg" />
        </DragHandleElement>
      </NotebookCellCardElement>
      {cellType !== "stickyNote" && (
        <NodeResizer
          minWidth={100}
          minHeight={100}
          handleStyle={{
            width: "10px",
            height: "10px",
          }}
          isVisible={isNodeSelected}
          onResizeEnd={handleResizeEnd}
        />
      )}
      <NodeToolbar
        position={Position.Right}
        isVisible={coworkersOnCell.length > 0}
        align="start"
      >
        <Flex direction="column">
          {coworkersOnCell.map((coworker) => {
            return (
              <UserAvatar
                key={coworker.user.userId}
                user={coworker.user}
                size="xs"
              />
            );
          })}
        </Flex>
      </NodeToolbar>
      <Box css={{ position: "absolute", top: "-24px", left: 0 }}>
        <Text variant="tinyDescription">{cellName}</Text>
      </Box>
    </Box>
  );
};

const NotebookCellCard = forwardRef(_NotebookCellCard);

export { NotebookCellCard, type NotebookCellCardProps };
