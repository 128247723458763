import { Dialog as RadixThemeDialog, Flex } from "@radix-ui/themes";
import { Button } from "../Button";

type DangerDialogProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  description: string;
  onConfirm: () => void;
  isConfirmLoading?: boolean;
};

const DangerDialog = (props: DangerDialogProps) => {
  const { isOpen, onOpenChange, description, onConfirm, isConfirmLoading } =
    props;

  return (
    <RadixThemeDialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <RadixThemeDialog.Content>
        <RadixThemeDialog.Title>Danger Zone</RadixThemeDialog.Title>
        <RadixThemeDialog.Description>
          {description}
        </RadixThemeDialog.Description>
        <Flex gap="2" align="center" justify="end" mt="5">
          <RadixThemeDialog.Close>
            <Button variant="tertiary" isDisabled={isConfirmLoading}>
              Cancel
            </Button>
          </RadixThemeDialog.Close>
          <Button
            variant="danger"
            onClick={onConfirm}
            isLoading={isConfirmLoading}
          >
            Confirm
          </Button>
        </Flex>
      </RadixThemeDialog.Content>
    </RadixThemeDialog.Root>
  );
};

export { DangerDialog, type DangerDialogProps };
