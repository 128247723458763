/* eslint-disable react/display-name */
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import { DashboardTableObject } from "@usemorph/morph-dashboard-types";
import { memo, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useGetDataApisQuery, useListBotsQuery } from "~/serverStateStore";
import { IconButton } from "~/components_next/IconButton";
import { TableSettingsDropdown } from "~/features/Table";
import { LinkItemWrapper } from "./common/LinkItemWrapper";
import { LinkItemText } from "./common/LinkItemText";
import { SidebarDataApiLinkItem } from "./SidebarDataApiLinkItem";
import { useLocation } from "react-router-dom";
import { getPath } from "~/routing";
import { ElementClassNamesForOnboarding } from "~/constants/IntercomClassConst";
import { useGetWidgetDataApisQuery } from "~/serverStateStore/widgetData";
import { SidebarWidgetDataApiLinkItem } from "./SidebarWidgetDataApiLinkItem";
import { useDisclosure } from "~/hooks/useDisclosure";
import { Image } from "~/components_next/Image";
import { Spinner } from "~/components_next/Spinner/Spinner";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
import { createUseFindRssLinkedToTableLoadable } from "~/containers/rssFeed/createUseFindRssLinkedToTableLoadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { SidebarRssLinkItem } from "./SidebarRssLinkItem";

const SidebarTableLinkItem = memo(
  ({
    databaseId,
    table,
    indexNum,
  }: {
    databaseId: string;
    table: DashboardTableObject;
    indexNum?: number;
  }) => {
    const {
      isOpen: isWidgetListOpen,
      onToggle: onWidgetListToggle,
      onOpen: onWidgetListOpen,
    } = useDisclosure();

    const teamSlug = useTeamSlug();

    const to = useMemo(() => {
      return getPath("source", {
        teamSlug,
        databaseId,
        tableSlug: table.tableSlug,
      });
    }, [databaseId, table, teamSlug]);

    const { data: dataApisData, status: dataApisStatus } = useQuery({
      ...useGetDataApisQuery({
        databaseId,
        teamSlug,
        tableSlug: table.tableSlug,
      }),
      enabled: isWidgetListOpen,
    });

    const { data: widgetDataApisData, status: widgetDataApisStatus } = useQuery(
      {
        ...useGetWidgetDataApisQuery({
          databaseId,
          teamSlug,
          tableSlug: table.tableSlug,
        }),
        enabled: isWidgetListOpen,
      }
    );

    const useFindRssLinkedToTable = useMemo(() => {
      return createUseFindRssLinkedToTableLoadable({
        teamSlug,
        databaseId,
        tableSlug: table.tableSlug,
        enabled: isWidgetListOpen,
      });
    }, [teamSlug, databaseId, table, isWidgetListOpen]);
    const findRssLinkedToTable = useFindRssLinkedToTable();

    const { data: botsData } = useQuery(
      useListBotsQuery({ databaseId, teamSlug })
    );
    const botsInTable = botsData?.bots.filter(
      (bot) => bot.tableSlug === table.tableSlug
    );

    /**
     * Watch path
     */
    const { pathname } = useLocation();
    useEffect(() => {
      if (pathname.indexOf(table.tableSlug) >= 0) {
        onWidgetListOpen();
      }
    }, [pathname]);

    return (
      <>
        <Box
          className={
            indexNum ===
              ElementClassNamesForOnboarding.sourceToolbar.createSourceButton
                .indexRow
              ? ElementClassNamesForOnboarding.sourceToolbar.createSourceButton
                .classNameText
              : undefined
          }
        >
          <LinkItemWrapper to={to}>
            <IconButton
              onClick={onWidgetListToggle}
              icon={
                isWidgetListOpen ? (
                  <BsChevronDown width="14px" height="14px" />
                ) : (
                  <BsChevronRight width="14px" height="14px" />
                )
              }
              tooltip="Open children"
              size="xs"
            />
            <Flex
              justify="between"
              align="center"
              pl="1"
              css={{
                overflow: "hidden",
                flex: 1,
              }}
            >
              {table.template && (
                <Box mr="2">
                  <Image
                    src={table.template.logoUrl}
                    width="16px"
                    height="16px"
                  // display="inline-block"
                  />
                </Box>
              )}
              <LinkItemText onClick={onWidgetListToggle}>
                {table.displayName || table.tableSlug}
              </LinkItemText>
            </Flex>
            <TableSettingsDropdown table={table} />
          </LinkItemWrapper>
          {/* Children */}
          {isWidgetListOpen && (
            <Box>
              <Box pl="3">
                <WithFallback loadables={[findRssLinkedToTable] as const}>
                  {([rss]) => (
                    <>
                      {/* {JSON.stringify(rss)} */}
                      {
                        rss.rssUrl && (
                          <SidebarRssLinkItem
                            rss={rss}
                            teamSlug={teamSlug}
                            databaseId={databaseId}
                            tableSlug={table.tableSlug}
                          />
                        )
                      }
                    </>
                  )}
                </WithFallback>

                {dataApisStatus === "success" ? (
                  dataApisData.dataApis
                    // .filter((api) => api.isIndependent)
                    .map((dataApi) => (
                      <SidebarDataApiLinkItem
                        key={dataApi.apiKey}
                        dataApi={dataApi}
                        databaseId={databaseId}
                        teamSlug={teamSlug}
                        tableSlug={table.tableSlug}
                      />
                    ))
                ) : dataApisStatus === "loading" ||
                  dataApisStatus === "idle" ? (
                  <Flex align="center" justify="center" py="2">
                    <Spinner size="16" />
                  </Flex>
                ) : (
                  <></>
                )}

                {widgetDataApisStatus === "success" ? (
                  widgetDataApisData?.widgetApis
                    // .filter((api) => api.isIndependent)
                    .map((widgetDataApi) => (
                      <SidebarWidgetDataApiLinkItem
                        key={widgetDataApi.apiKey}
                        dataApi={widgetDataApi}
                        databaseId={databaseId}
                        teamSlug={teamSlug}
                        tableSlug={table.tableSlug}
                      />
                    ))
                ) : widgetDataApisStatus === "loading" ||
                  widgetDataApisStatus === "idle" ? (
                  <Flex align="center" justify="center" py="2">
                    {/* <Spinner size="sm" /> */}
                  </Flex>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </>
    );
  }
);

export { SidebarTableLinkItem };
