import * as RadixPrimitiveAvatar from "@radix-ui/react-avatar";
import { styled } from "~/stitches";
import { useMemo } from "react";
import { Tooltip } from "~/components_next/Tooltip";
import { FindUserClientModel } from "~/clientModel/user";
import { MorphThemeSize } from "~/components_next/commonProps.type";

type UserAvatarProps = {
  size?: MorphThemeSize; // default md
  user: FindUserClientModel;
  showTooltipOnHover?: boolean; // default true
  showCoworkerBorder?: boolean; // default false
};

const sizeMap = {
  xs: 24,
  sm: 32,
  md: 40,
  lg: 48,
  xl: 60,
} as const;

const borderMap = {
  xs: 2,
  sm: 3,
  md: 3.5,
  lg: 4,
  xl: 5,
} as const;

const AvatarRoot = styled(RadixPrimitiveAvatar.Root, {
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  verticalAlign: "middle",
  overflow: "hidden",
  userSelect: "none",
  borderRadius: "100%",
  borderStyle: "solid",
  boxSizing: "border-box",

  variants: {
    size: {
      xs: {
        width: sizeMap.xs,
        height: sizeMap.xs,
      },
      sm: {
        width: sizeMap.sm,
        height: sizeMap.sm,
      },
      md: {
        width: sizeMap.md,
        height: sizeMap.md,
      },
      lg: {
        width: sizeMap.lg,
        height: sizeMap.lg,
      },
      xl: {
        width: sizeMap.xl,
        height: sizeMap.xl,
      },
    },
    type: {
      basic: {
        borderWidth: 1,
      },
      coworker: {},
    },
  },

  compoundVariants: [
    {
      size: "xs",
      type: "coworker",
      css: {
        borderWidth: borderMap.xs,
      },
    },
    {
      size: "sm",
      type: "coworker",
      css: {
        borderWidth: borderMap.sm,
      },
    },
    {
      size: "md",
      type: "coworker",
      css: {
        borderWidth: borderMap.md,
      },
    },
    {
      size: "lg",
      type: "coworker",
      css: {
        borderWidth: borderMap.lg,
      },
    },
    {
      size: "xl",
      type: "coworker",
      css: {
        borderWidth: borderMap.xl,
      },
    },
  ],
});

const AvatarImage = styled(RadixPrimitiveAvatar.Image, {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "inherit",
});

const AvatarFallback = styled(RadixPrimitiveAvatar.Fallback, {
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  lineHeight: 1,
  fontWeight: 500,
  backgroundColor: "$bg0",

  variants: {
    size: {
      xs: {
        fontWeight: 700,
        fontSize: 11,
      },
      sm: {
        fontWeight: 600,
        fontSize: 14,
      },
      md: {
        fontSize: 18,
      },
      lg: {
        fontSize: 22,
      },
      xl: {
        fontSize: 28,
      },
    },
  },
});

const UserAvatar = (props: UserAvatarProps) => {
  const {
    user,
    size = "md",
    showTooltipOnHover = true,
    showCoworkerBorder = false,
  } = props;

  const fallbackLabel = useMemo(() => {
    return user.username.length > 0 ? user.username[0] : "";
  }, [user]);

  const borderColor = showCoworkerBorder
    ? `$${user.color.colorScheme}7`
    : "$border";

  const Avatar = (
    <AvatarRoot
      size={size}
      type={showCoworkerBorder ? "coworker" : "basic"}
      css={{ borderColor }}
    >
      <AvatarImage src={user.iconUrl ?? undefined} alt={user.username} />
      <AvatarFallback delayMs={600} size={size}>
        {fallbackLabel}
      </AvatarFallback>
    </AvatarRoot>
  );

  return showTooltipOnHover ? (
    <Tooltip content={user.username}>{Avatar}</Tooltip>
  ) : (
    Avatar
  );
};

export { UserAvatar, type UserAvatarProps };
