import { Box, Code, Flex } from "@radix-ui/themes";

import { PromptHistoryClientModel } from "~/clientModel/promptHistories/promptHistory";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";
import { Tooltip } from "~/components_next/Tooltip";
import { UserMention } from "~/components_next/User";

type PromptHistoryCellProps = {
  history: PromptHistoryClientModel;
  onHistoryClick: () => void;
};

const PromptHistoryCell = (props: PromptHistoryCellProps) => {
  const { history, onHistoryClick } = props;

  return (
    <Box
      px="2"
      py="3"
      onClick={onHistoryClick}
      style={{ cursor: "pointer" }}
      role="button"
    >
      <Flex direction="column" align="stretch" gap="3">
        <Flex align="center">
          {history.createdBy && <UserMention user={history.createdBy} />}
          <Spacer />
          <Tooltip content={history.createdAt}>
            <Text variant="description">{history.fromNowLabel}</Text>
          </Tooltip>
        </Flex>
        <pre style={{ whiteSpace: "pre-wrap", font: "unset" }}>
          <Text fontWeight="bold">{history.messageWithoutBrackets}</Text>
        </pre>
        <Code>
          <pre style={{ padding: 20, overflowY: "auto" }}>{history.sql}</pre>
        </Code>
      </Flex>
    </Box>
  );
};

export { PromptHistoryCell };
