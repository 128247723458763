import { FieldClientModel } from "~/clientModel/fields/field";
import {
  ValidationResult,
  zValidate,
} from "~/clientModel/utils/validationResultType";
import { z } from "zod";

type EmbeddingSortConditionUnitClientModelData = {
  targetField: FieldClientModel;
  operator: "<=>" | "<->" | "<#>";
  inputValue: string;
};

export class EmbeddingSortConditionUnitClientModel {
  readonly #data: EmbeddingSortConditionUnitClientModelData;

  constructor(data: EmbeddingSortConditionUnitClientModelData) {
    this.#data = data;
  }

  public get data(): EmbeddingSortConditionUnitClientModelData {
    return this.#data;
  }

  public get targetField(): FieldClientModel {
    return this.#data.targetField;
  }

  public get targetFieldLabel(): string {
    return this.#data.targetField.label;
  }

  public get key(): string {
    return this.#data.targetField.name;
  }

  public get inputValue(): string {
    return this.#data.inputValue;
  }

  public updateTargetField(
    targetField: FieldClientModel
  ): EmbeddingSortConditionUnitClientModel {
    return new EmbeddingSortConditionUnitClientModel({
      ...this.#data,
      targetField,
    });
  }

  public updateInputValue(
    inputValue: string
  ): EmbeddingSortConditionUnitClientModel {
    return new EmbeddingSortConditionUnitClientModel({
      ...this.#data,
      inputValue,
    });
  }

  public validate(): ValidationResult {
    return zValidate(z.string().min(1), this.#data.inputValue);
  }
}
