import { NumberDisplaySettingClientModel } from "~/clientModel/fields/field/displaySetting/numberDisplaySetting";
import { Checkbox } from "~/components_next/Checkbox";
import { Flex } from "~/components_next/Flex";
import { Input } from "~/components_next/Input";
import { SimpleSelect } from "~/components_next/Select";
import { DisplaySample } from "./DisplaySample";

type NumberDisplaySettingInputProps = {
  value: NumberDisplaySettingClientModel;
  onChange: (value: NumberDisplaySettingClientModel) => void;
  isReadOnly?: boolean;
};

export const NumberDisplaySettingInput = (
  props: NumberDisplaySettingInputProps
) => {
  const { value, onChange, isReadOnly } = props;

  const handleUpdatePrecision = (
    precision: NumberDisplaySettingClientModel.Precision
  ) => {
    onChange(value.updatePrecision(precision));
  };

  const handleUpdateUnits = (unit: string) => {
    onChange(value.updateUnits(unit));
  };

  const handleUpdateDisplayUnitsAfterNumber = (
    displayUnitsAfterNumber: boolean
  ) => {
    onChange(value.updateDisplayUnitsAfterNumber(displayUnitsAfterNumber));
  };

  const handleUpdateUseGroupSeparator = (useGroupSeparator: boolean) => {
    onChange(value.updateUseGroupSeparator(useGroupSeparator));
  };

  return (
    <Flex direction="column" gap="4">
      <SimpleSelect
        size="sm"
        variant="primary"
        label="Precision"
        value={value.precision}
        getValueStringFromValue={(
          precision: NumberDisplaySettingClientModel.Precision
        ) => precision.toString()}
        options={value.precisionOptions}
        isReadOnly={isReadOnly}
        onChange={(precision) => handleUpdatePrecision(precision!)}
      />

      <Input
        variant="primary"
        label="units"
        value={value.units}
        onChange={(e) => handleUpdateUnits(e.target.value)}
        readOnly={isReadOnly}
      />

      <Checkbox
        value={value.displayUnitsAfterNumber}
        size="xs"
        onChange={handleUpdateDisplayUnitsAfterNumber}
        label="Display units after number"
        isReadOnly={isReadOnly}
      />

      <Checkbox
        value={value.useGroupSeparator}
        size="xs"
        onChange={handleUpdateUseGroupSeparator}
        label="Use group separator"
        isReadOnly={isReadOnly}
      />

      <DisplaySample>{value.displayExample}</DisplaySample>
    </Flex>
  );
};
