import { SimpleField } from "@usemorph/morph-dashboard-types";
import {
  RecordModel,
  RecordModelValueWithSpecificType,
} from "../types/RecordModelValue";

import { nullableValueTypes, nonNullableValueTypes } from "./emptyValues";
import {
  validateNullableRecordModelValue,
  validateNonNullableRecordModelValue,
} from "./typeCheck";

const createRecordModelValue = <T extends SimpleField["type"]>(
  rowValue: unknown,
  type: T,
  simpleField: SimpleField
): RecordModelValueWithSpecificType<T> => {
  const isPrimary = simpleField.primary === true;
  const nullable = simpleField.nullable === true;

  // ts-patternが使えないので、let-switchで書いている。
  let parsedValue: unknown;
  switch (type) {
    case "multiSelect": {
      try {
        parsedValue = JSON.parse(rowValue as string);
      } catch (_) {
        parsedValue = rowValue;
      }
      break;
    }
    // todo: バックエンド使用確認
    case "json": {
      try {
        parsedValue =
          typeof rowValue != null ? JSON.stringify(rowValue) : rowValue;
      } catch (_) {
        parsedValue = rowValue;
      }
      break;
    }
    default: {
      parsedValue = rowValue;
    }
  }

  if (nullable) {
    if (validateNullableRecordModelValue(parsedValue, type)) {
      return {
        isPrimary,
        type,
        nullable,
        isValid: true,
        value: parsedValue,
      };
    } else {
      return {
        isPrimary,
        type,
        nullable,
        isValid: false,
        rowValue,
        value: nullableValueTypes[type],
      };
    }
  } else {
    if (validateNonNullableRecordModelValue(parsedValue, type)) {
      return {
        isPrimary,
        type,
        nullable,
        isValid: true,
        value: parsedValue,
      };
    } else {
      return {
        isPrimary,
        type,
        nullable,
        isValid: false,
        rowValue,
        value: nonNullableValueTypes[type],
      };
    }
  }
};

const convertRecordToRecordModel = (
  record: Record<string, unknown>,
  simpleFields: SimpleField[]
): RecordModel => {
  return simpleFields.reduce((recordModel, field): RecordModel => {
    const { name, type } = field;

    return {
      ...recordModel,
      [name]: createRecordModelValue(record[name], type, field),
    } as RecordModel; // todo: キャストなくす
  }, {} as RecordModel);
};

export { convertRecordToRecordModel, createRecordModelValue };
