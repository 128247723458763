import { FieldsClientModel } from "../../fields";
import { ValidationResult } from "../../utils/validationResultType";
import { CsvDownloadFieldClientModel } from "./csvDownloadField/CsvDownloadFieldClientModel";

type CsvDownloadFieldsClientModelData = {
  csvDownloadFields: CsvDownloadFieldClientModel[];
};

export class CsvDownloadFieldsClientModel {
  constructor(readonly data: CsvDownloadFieldsClientModelData) {}

  public get baseFields(): FieldsClientModel {
    return new FieldsClientModel({
      fields: this.data.csvDownloadFields.map(
        (csvDownloadField) => csvDownloadField.baseField
      ),
    });
  }

  public get allCsvDownloadFields(): CsvDownloadFieldClientModel[] {
    return this.data.csvDownloadFields;
  }

  public get hiddenCsvDownloadFields(): CsvDownloadFieldsClientModel {
    return new CsvDownloadFieldsClientModel({
      csvDownloadFields: this.data.csvDownloadFields.filter(
        (csvDownloadField) => csvDownloadField.baseField.isHidden
      ),
    });
  }

  public get maskedCsvDownloadFields(): CsvDownloadFieldsClientModel {
    return new CsvDownloadFieldsClientModel({
      csvDownloadFields: this.data.csvDownloadFields.filter(
        (csvDownloadField) => csvDownloadField.isMasked
      ),
    });
  }

  public updateCsvDownloadField(
    csvDownloadField: CsvDownloadFieldClientModel
  ): CsvDownloadFieldsClientModel {
    return new CsvDownloadFieldsClientModel({
      csvDownloadFields: this.data.csvDownloadFields.map(
        (currentCsvDownloadField) =>
          currentCsvDownloadField.baseField.name ===
          csvDownloadField.baseField.name
            ? csvDownloadField
            : currentCsvDownloadField
      ),
    });
  }

  public validate(): ValidationResult {
    if (this.baseFields.visibleFields.count === 0) {
      return {
        isValid: false,
        errorMessages: ["At least one field must be selected."],
      };
    }
    return { isValid: true };
  }
}
