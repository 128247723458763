import { useSetOpeningSidebarType } from "../../states/sidebar";
import { useSetSelectionState } from "../../states/selectionState";
import { useViewRecords } from "../../hooks/useViewRecords";
import {
  CreateRecordButton,
  CreateRecordButtonClickType,
} from "../../../common/components/atoms/CreateRecordButton";
import { useViewId } from "~/utilHooks/useViewId";
import { useView } from "~/features/SourceAndViews/common/utils/useView";
import { match } from "ts-pattern";

type CreateRecordButtonProps = {
  onOpenBulkInsertModal: () => void;
};

const SourceCreateRecordButton = (props: CreateRecordButtonProps) => {
  const viewId = useViewId();

  const { status } = useViewRecords();
  const { data: viewData } = useView();

  const setOpeningSidebarType = useSetOpeningSidebarType();

  const setSelectionState = useSetSelectionState(viewId);

  const { onOpenBulkInsertModal } = props;

  const onCreateClick = (type: CreateRecordButtonClickType) => {
    match(type)
      .with("createRecord", () => {
        setSelectionState(undefined);
        setOpeningSidebarType("createRecord");
      })
      .with("bulkInsert", () => {
        setSelectionState(undefined);
        onOpenBulkInsertModal();
      })
      .with("freeform", () => {
        setSelectionState(undefined);
        setOpeningSidebarType("createRecordFreeform");
      })
      .exhaustive();
  };

  const isDisabled = status !== "success";

  const isDisplayed = viewData && viewData.rules.create;

  return isDisplayed ? (
    <CreateRecordButton
      isDisabled={isDisabled}
      onClick={onCreateClick}
      forbiddens={["bulkInsert", "freeform"]}
    />
  ) : null;
};

export { SourceCreateRecordButton as CreateRecordButton };
