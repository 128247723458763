import { RecordValueType } from "@usemorph/morph-dashboard-types";
import { viewKeys } from "../keys";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import useApiView from "~/api/useApiView";

type UseCreateViewRecordMutationParams = {
  teamSlug: string;
  databaseId: string;
  viewId: string;
};

const useCreateViewRecordMutation = ({
  teamSlug,
  databaseId,
  viewId,
}: UseCreateViewRecordMutationParams) => {
  const { _createViewRecord } = useApiView();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      values,
    }: {
      values: { key: string; value: RecordValueType }[];
    }) => {
      return _createViewRecord({
        teamSlug,
        viewId,
        body: {
          values,
          fixedValue: [],
        },
      });
    },
    onSuccess: () => {
      const keys = viewKeys.all({ databaseId, teamSlug });
      broadcast(keys);
      return invalidateAll(client, keys);
    },
  };
};

export { useCreateViewRecordMutation };
