import { useMemo } from "react";
import { createUseGenerateSqlFromPromptExecutable } from "./createUseGenerateSqlFromPromptExecutable";
import { createUseSuggestedPromptsLoadable } from "./createUseSuggestedPromptsLoadable";
import { createUseFieldsWithConditionLoadable } from "./createUseFieldsWithConditionLoadable";
import { createUsePromptHistoryLoadable } from "./createUsePromptHistoryLoadable";
import { createUseViewRecordsLoadable } from "./createUseViewRecordsLoadable";
import { createUseDownloadCsvExecutable } from "./createUseDownloadCsvExecutable";
import { createuseDownloadCsvPreviewRecordsLoadable } from "./createUseDownloadCsvPreviewRecordsLoadable";

export const useTableViewProps = ({
  teamSlug,
  databaseId,
}: {
  teamSlug: string;
  databaseId: string;
}) => {
  const useViewRecordsLoadable = useMemo(() => {
    return createUseViewRecordsLoadable({
      teamSlug,
      databaseId,
    });
  }, [teamSlug, databaseId]);

  const useViewFieldsLoadable = useMemo(() => {
    return createUseFieldsWithConditionLoadable({
      teamSlug,
      databaseId,
    });
  }, [teamSlug, databaseId]);

  const useSuggestedPromptsLoadable = useMemo(() => {
    return createUseSuggestedPromptsLoadable({
      teamSlug,
      databaseId,
    });
  }, [teamSlug, databaseId]);

  const useGenerateSqlFromPromptExecutable = useMemo(() => {
    return createUseGenerateSqlFromPromptExecutable({
      teamSlug,
      databaseId,
    });
  }, [teamSlug, databaseId]);

  const usePromptHistoriesLoadable = useMemo(() => {
    return createUsePromptHistoryLoadable({
      teamSlug,
      databaseId,
    });
  }, [teamSlug, databaseId]);

  // csv download
  const useDownloadCsvExecutable = useMemo(() => {
    return createUseDownloadCsvExecutable({
      teamSlug,
    });
  }, [teamSlug]);

  const useDownloadCsvPreviewRecordsLoadable = useMemo(() => {
    return createuseDownloadCsvPreviewRecordsLoadable({
      teamSlug,
      databaseId,
    });
  }, [teamSlug, databaseId]);

  const memoizedValue = useMemo(() => {
    return {
      useViewRecordsLoadable,
      useViewFieldsLoadable,
      useSuggestedPromptsLoadable,
      useGenerateSqlFromPromptExecutable,
      usePromptHistoriesLoadable,
      useDownloadCsvExecutable,
      useDownloadCsvPreviewRecordsLoadable,
    };
  }, [
    useViewRecordsLoadable,
    useViewFieldsLoadable,
    useSuggestedPromptsLoadable,
    useGenerateSqlFromPromptExecutable,
    usePromptHistoriesLoadable,
    useDownloadCsvExecutable,
    useDownloadCsvPreviewRecordsLoadable,
  ]);

  return memoizedValue;
};
