type ListCanvasPageDataType = {
  createdAt: string;
  description: string | null;
  databaseId: string;
  isPrivate: boolean;
  notebookId: string;
  pageId: string;
  pageName: string;
  cells: {
    createdAt: string;
    cellId: string;
    notebookCellId: string;
    x: number;
    y: number;
    w: number;
    h: number;
  }[];
  isPublic: boolean;
};

export class ListCanvasPageClientModel {
  constructor(private data: ListCanvasPageDataType) {}

  get createdAt() {
    return this.data.createdAt;
  }

  get description() {
    return this.data.description;
  }

  get databaseId() {
    return this.data.databaseId;
  }

  get isPrivate() {
    return this.data.isPrivate;
  }

  get notebookId() {
    return this.data.notebookId;
  }

  get pageId() {
    return this.data.pageId;
  }

  get pageName() {
    return this.data.pageName;
  }

  get cells() {
    return this.data.cells;
  }

  get isPublic() {
    return this.data.isPublic;
  }

  updatePageName(pageName: string) {
    return new ListCanvasPageClientModel({
      ...this.data,
      pageName,
    });
  }

  updateDescription(description: string) {
    return new ListCanvasPageClientModel({
      ...this.data,
      description,
    });
  }
}
