import { Flex } from "@radix-ui/themes";
import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { match, P } from "ts-pattern";
import {
  useGetTableQuery,
  useTeam,
  useUpdateTableMutation,
} from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTableSlug } from "~/utilHooks/useTableSlug";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { BelongedResourceMembers } from "../components/Share/BelongedResourceMembers";
import { GeneralAccessAuthorityCard } from "../components/Share/GeneralAccessAuthorityCard";
import { InvitationInput } from "../components/Share/InvitationInput";
import { AccessAuthority } from "../components/Share/types";

/**
 * Root & General Access
 */
const SourceToolbarShare = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const tableSlug = useTableSlug();

  const { data: table } = useQuery(
    useGetTableQuery({
      teamSlug,
      databaseId,
      tableSlug,
    })
  );

  const { data: team } = useTeam({ teamSlug });

  /**
   * General Access
   */
  const { mutateAsync: updateTable } = useMutation({
    ...useUpdateTableMutation({
      teamSlug,
      databaseId,
      tableSlug,
    }),
  });

  const generalAccess = useMemo((): AccessAuthority => {
    if (!table) return "No Access";
    if (table.isPrivate) return "No Access";
    return "Read & Write";
  }, [table]);
  const handleGeneralAccessChange = (authority: AccessAuthority) => {
    if (!table) return;

    match([authority, generalAccess])
      .with(["Read & Write", P.not("Read & Write")], () => {
        updateTable({
          isPrivate: false,
          displayName: table.displayName || "",
          tableType: table.tableType,
          comment: table.comment || "",
        });
      })
      .with(["No Access", P.not("No Access")], () => {
        updateTable({
          isPrivate: true,
          displayName: table.displayName || "",
          tableType: table.tableType,
          comment: table.comment || "",
        });
      })
      .otherwise(() => {
        return;
      });
  };

  return (
    <>
      <Flex direction="column" align="stretch">
        {generalAccess !== "Read & Write" && (
          <>
            <InvitationInput
              resource={{
                tableSlug,
                databaseId,
                resourceType: "table",
              }}
            />
          </>
        )}
        {/* General */}
        <GeneralAccessAuthorityCard
          team={team}
          generalAccess={generalAccess}
          onGeneralAccessChange={handleGeneralAccessChange}
        />
        {/* Members */}
        {table?.isPrivate && (
          <BelongedResourceMembers
            resource={{
              tableSlug,
              databaseId,
              resourceType: "table",
            }}
          />
        )}
      </Flex>
    </>
  );
};

export { SourceToolbarShare };
