import { HTMLPreview } from "./Preview/HTMLPreview";
import { Button } from "~/components_next/Button";
import { HTMLEditModal } from "./HTMLEditModal";
import { RecordValueInputCommonProps } from "../../../type";
import { useDisclosure } from "~/hooks/useDisclosure";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { HtmlRecordEntryClientModel } from "~/clientModel/records/record/recordEntry";

export const HTMLInput = (
  props: RecordValueInputCommonProps<HtmlRecordEntryClientModel>
) => {
  const { editingRecordEntry, onChange, field, size, isReadonly } = props;

  const { isOpen, onOpen, setIsOpen } = useDisclosure();

  const validatedValue = editingRecordEntry.getValidatedValue(field);

  const value = validatedValue.isValid ? validatedValue.value : null;

  const errorMessages = validatedValue.errorMessages;

  const handleChange = (value: string | null) => {
    onChange?.(editingRecordEntry.updateValue(value));
  };

  return (
    <>
      <InputStack errorMessage={errorMessages}>
        <HTMLPreview value={value} height="100%" />
        <Button
          size={size}
          variant="tertiary"
          colorSchemeOverride="gray"
          onClick={onOpen}
        >
          View and Edit
        </Button>
      </InputStack>
      {/* modal */}
      <HTMLEditModal
        isOpen={isOpen}
        onChange={handleChange}
        isReadOnly={isReadonly}
        html={value}
        onOpenChange={setIsOpen}
      />
    </>
  );
};
