import { BsThreeDotsVertical } from "react-icons/bs";
import { DashboardCellClientModel } from "~/clientModel/canvas/DashboardCellClientModel";
import { Executable } from "~/clientModel/executable";
import { DangerDialog } from "~/components_next/Dialog";
import {
  DropdownMenu,
  SimpleDropdownMenu,
} from "~/components_next/DropdownMenu";
import { Flex } from "~/components_next/Flex";
import { IconButton } from "~/components_next/IconButton";
import { useDisclosure } from "~/hooks/useDisclosure";
import { styled } from "~/stitches";

type DashboardPageCellOptionsProps = {
  cellClientModel: DashboardCellClientModel;
  removeCellExecutable: Executable<string, unknown>;
  isVisible: boolean;
};

const Wrapper = styled(Flex, {
  position: "absolute",
  top: "-6px",
  right: "-7px",
  backgroundColor: "var(--slate-a5)",
  borderRadius: "var(--radius-2)",
});

const DashboardPageCellOptions = (props: DashboardPageCellOptionsProps) => {
  const { cellClientModel, isVisible, removeCellExecutable } = props;

  const DeleteConirm = useDisclosure();

  if (!isVisible) return null;

  return (
    <>
      <Wrapper>
        <SimpleDropdownMenu
          trigger={
            <IconButton
              icon={<BsThreeDotsVertical />}
              size="sm"
              tooltip="Options"
            />
          }
        >
          <DropdownMenu.Item onClick={DeleteConirm.onOpen}>
            Delete
          </DropdownMenu.Item>
        </SimpleDropdownMenu>
      </Wrapper>
      <DangerDialog
        description="Are you sure to remove this cell from this page?"
        isOpen={DeleteConirm.isOpen}
        onOpenChange={DeleteConirm.setIsOpen}
        onConfirm={async () => {
          await removeCellExecutable.execute(cellClientModel.cellId);
          DeleteConirm.setIsOpen(false);
        }}
        isConfirmLoading={removeCellExecutable.isExecuting}
      />
    </>
  );
};

export { DashboardPageCellOptions };
