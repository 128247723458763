import useApiMe from "~/api/useApiMe";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { meKeys } from "../keys";
import { DashboardUpdateMeRequestBody } from "@usemorph/morph-dashboard-types";

const useUpdateLoginUserMutation = () => {
  const { _updateMe } = useApiMe();
  const client = useQueryClient();

  return {
    mutationFn: ({
      requestBody,
    }: {
      requestBody: DashboardUpdateMeRequestBody
    }) => {
      return _updateMe({
        requestBody,
      });
    },
    onSuccess: () => {
      return invalidateAll(client, meKeys.getLoginUser());
    },
  };
};

export { useUpdateLoginUserMutation };
