import {
  RecordsTableBase,
  getDefaultColumnSizings,
} from "~/components_next/RecordsTableBase";
import { useViewRecords } from "../../hooks/useViewRecords";
import { useColumnSizing, useSetColumnSizing } from "../../states/columnSizing";
import { extractErrorDetails, useErrorToast } from "~/components_next/Error";
import { RecordsTableBaseRecord } from "~/components_next/RecordsTableBase/types";
import { SimpleField } from "@usemorph/morph-dashboard-types";
import { useCallback, useMemo } from "react";
import { FieldDropdown } from "./FieldDropdown";
import { RecordModel } from "~/features/RecordModel";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useOptimisticUpdateViewMutation } from "~/serverStateStore/views";
import { useMutation } from "react-query";
import { parseViewSetting } from "~/features/View";
import { useQueryMode } from "../../states/queryMode";
import { match, P } from "ts-pattern";
import { Callout } from "~/components_next/Callout";
import { Flex } from "@radix-ui/themes";
import { Spinner } from "~/components_next/Spinner";
import { useTableView } from "../../hooks/useProps";
import { useSetViewSetting } from "../../states/viewSetting";

const TableComponent = () => {
  /**
   * Basic params
   */
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const view = useTableView();
  const { viewId } = view;

  /**
   * Server states
   */

  const {
    data: viewRecordsData,
    status: viewRecordsStatus,
    error: viewRecordsError,
  } = useViewRecords();

  const queryMode = useQueryMode();

  const [displayedSimpleFields, displayedRecordsTableBaseRecords] =
    useMemo(() => {
      if (!viewRecordsData) return [undefined, undefined];

      // 以下を除く
      // - morph_reserved_deleted_atのもの
      // - [queryModeがfiltersAndSorts or nullのとき]isHiddenがtrueのもの
      const displayedSimpleFields = viewRecordsData.fields.filter(
        ({ isHidden, name }) => {
          const removed =
            name === "morph_reserved_deleted_at" ||
            ((!queryMode || queryMode === "filtersAndSorts") &&
              isHidden === true);
          return !removed;
        }
      );

      const displayedTableBaseRecords: RecordsTableBaseRecord[] =
        viewRecordsData.recordsTableBaseRecords.map(
          ({ values, _reservedRecordIndex }) => {
            return {
              _reservedRecordIndex,
              values: displayedSimpleFields.reduce((_values, { name }) => {
                return { ..._values, [name]: values[name] };
              }, {} as RecordModel),
            };
          }
        );

      return [displayedSimpleFields, displayedTableBaseRecords];
    }, [queryMode, viewRecordsData]);

  /**
   * Client states
   */

  const columnSizing =
    useColumnSizing(viewId) ??
    getDefaultColumnSizings(viewRecordsData?.fields ?? []).defaultColumnSizings;
  const setColumnSizing = useSetColumnSizing(viewId);

  /**
   * handlers
   */
  const { errorToast } = useErrorToast({});

  const { mutateAsync: optimisticUpdateView } = useMutation(
    useOptimisticUpdateViewMutation({ teamSlug, databaseId, viewId })
  );

  const onUpdateViewSetting = useSetViewSetting();

  const handleClickHideField = useCallback(
    async (targetField: SimpleField) => {
      try {
        if (!viewRecordsData) {
          throw new Error("Failed to update field order");
        }
        const { type, name, condition, setting } = view;
        const parsedSetting = parseViewSetting(setting);
        onUpdateViewSetting({
          data: {
            ...parsedSetting.data,
            hiddenFields: [
              ...(parsedSetting.data.hiddenFields ?? []),
              {
                name: targetField.name,
                originalTableSlug: targetField.originalTableSlug,
              },
            ],
          },
        });
        // await optimisticUpdateView({
        //   type,
        //   name,
        //   condition,
        //   setting: {
        //     data: {
        //       ...parsedSetting.data,
        //       hiddenFields: [
        //         ...(parsedSetting.data.hiddenFields ?? []),
        //         {
        //           name: targetField.name,
        //           originalTableSlug: targetField.originalTableSlug,
        //         },
        //       ],
        //     },
        //   },
        // });
      } catch (e) {
        errorToast(e);
      }
    },
    [errorToast, view, optimisticUpdateView, viewRecordsData]
  );

  /**
   * UI
   */

  if (viewRecordsStatus === "error") {
    const { title, description } = extractErrorDetails(viewRecordsError);
    return (
      <Callout size="sm" type="alert" title={title} description={description} />
    );
  }

  if (
    viewRecordsStatus === "loading" ||
    viewRecordsStatus === "idle" ||
    !displayedSimpleFields
  ) {
    return (
      <Flex height="100%" align="center" justify="center">
        <Spinner />
      </Flex>
    );
  }

  return match(queryMode)
    .with(P.union(P.nullish, "filtersAndSorts"), () => (
      <RecordsTableBase
        editableFields={"none"}
        fields={displayedSimpleFields}
        records={displayedRecordsTableBaseRecords}
        isSelectable={false}
        columnSizing={columnSizing}
        onColumnSizingChange={setColumnSizing}
        headerDropdown={(field) => (
          <FieldDropdown
            field={field}
            onClickHideField={handleClickHideField}
          />
        )}
        hasPadding={false}
        propagateEvent
      />
    ))
    .with(P.union("prompt", "sql"), () => (
      <RecordsTableBase
        editableFields="none"
        fields={displayedSimpleFields}
        records={displayedRecordsTableBaseRecords}
        isSelectable={false}
        hasPadding={false}
        propagateEvent
      />
    ))
    .exhaustive();
};

export { TableComponent };
