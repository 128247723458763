import { globalCss } from "@stitches/react";
import { slate } from "@radix-ui/colors";

export const setGlobalStyles = globalCss({
  "*": {
    borderColor: slate.slate5,
    borderStyle: "solid",
    borderWidth: 0,
    boxSizing: "border-box",
    margin: 0,
    padding: 0,
  },
  a: {
    color: "inherit",
    textDecoration: "inherit",
  },
  body: {
    margin: 0,
  },
});
