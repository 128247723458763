import { forwardRef } from "react";
import { Button } from "~/components_next/Button";

type PromptButtonProps = {
  status: "default" | "active";
};

const PromptButton = forwardRef<HTMLButtonElement, PromptButtonProps>(
  (props, ref) => {
    const { status, ...rest } = props;

    const buttonVariant = status === "active" ? "primary" : "tertiary";

    return (
      <Button ref={ref} variant={buttonVariant} size="xs" {...rest}>
        Prompt
      </Button>
    );
  }
);

PromptButton.displayName = "PromptButton";

export { PromptButton };
