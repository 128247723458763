import { Box, Flex } from "@radix-ui/themes";
import {
  DashboardNotebookCellObject,
  DashboardTableObject,
} from "@usemorph/morph-dashboard-types";
import { useMemo } from "react";
// eslint-disable-next-line import/no-restricted-paths
import { BsThreeDotsVertical } from "react-icons/bs";
import { match } from "ts-pattern";
import {
  SimpleDropdownMenu,
  DropdownMenu,
} from "~/components_next/DropdownMenu";
import { IconButton } from "~/components_next/IconButton";
import { RightSidebar } from "~/components_next/RightSidebar";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";
import { styled } from "~/stitches";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useNotebookId } from "~/utilHooks/useNotebookId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useNotebookOnDeleteCell } from "../../context/NotebookDeleteCellContext";

import {
  useNotebookLayout,
  useSetNotebookLayout,
} from "../../state/notebookLayout";
import { useNotebookSelectedNodes } from "../../util/useNotebookSelectedNodes";
import { NotebookSidebarPrompt } from "./NotebookSidebarPrompt";
import { NotebookSidebarVariables } from "./Variables/NotebookSidebarVariables";

type NotebookDiagramSidebarProps = {
  table?: DashboardTableObject;
};

const SidebarWrapper = styled(Box, {
  position: "fixed",
  top: "40px",
  right: 0,
  bottom: 0,
  backgroundColor: "$bg0",
});

const DisplayNameText = styled(Text, {
  "&:hover": {
    opacity: 0.5,
  },
});

const NotebookDiagramSidebar = () => {
  const databaseId = useDatabaseId();
  const teamSlug = useTeamSlug();
  const notebookId = useNotebookId();

  const { selectedCells } = useNotebookSelectedNodes();

  const selectedCell = useMemo(() => {
    // console.log(`Sidebar: selectedCells: ${selectedCells.length}`);
    if (selectedCells.length > 0) return selectedCells[0];
    return undefined;
  }, [selectedCells]);

  const onDeleteCell = useNotebookOnDeleteCell();

  const handleRemoveCell = () => {
    if (!selectedCell) return;
    onDeleteCell(selectedCell.cellId);
  };

  const notebookLayout = useNotebookLayout(notebookId);
  const setNotebookLayout = useSetNotebookLayout(notebookId);

  if (selectedCells.length > 1 || !selectedCell) return null;

  if (
    selectedCell.cellType !== "prompt" &&
    selectedCell.cellType !== "variables"
  )
    return null;

  return (
    <SidebarWrapper>
      <RightSidebar
        height={"100%"}
        width={notebookLayout?.rightSidebarWidth || 400}
        onResizeDone={(value) => {
          setNotebookLayout({
            ...notebookLayout,
            rightSidebarWidth: value,
          });
        }}
      >
        <Flex mb="2">
          <Spacer />
          <SimpleDropdownMenu
            trigger={
              <IconButton icon={<BsThreeDotsVertical />} tooltip="Options" />
            }
          >
            <Box py="1">
              <DropdownMenu.Item onClick={handleRemoveCell}>
                <Text>Delete this cell</Text>
              </DropdownMenu.Item>
            </Box>
          </SimpleDropdownMenu>
        </Flex>
        {match(selectedCell.cellType)
          .with("prompt", () => {
            return (
              <NotebookSidebarPrompt
                key={selectedCell.cellId}
                cell={selectedCell as DashboardNotebookCellObject}
              />
            );
          })
          .with("variables", () => {
            return (
              <NotebookSidebarVariables
                key={selectedCell.cellId}
                cell={selectedCell as DashboardNotebookCellObject}
              />
            );
          })
          // .with("view", () => {
          //   return (
          //     <NotebookSidebarView
          //       key={selectedCell.cellId}
          //       cell={selectedCell as DashboardNotebookCellObject}
          //     />
          //   );
          // })
          .otherwise(() => (
            <>Other</>
          ))}
      </RightSidebar>
    </SidebarWrapper>
  );
};

export { NotebookDiagramSidebar };
