import { DownloadDrawerTitle } from "./DownloadDrawerTitle";
import { Drawer } from "~/components_next/Drawer";
import { UseExecutable } from "~/clientModel/executable";
import { CsvDownloadFieldsClientModel } from "~/clientModel/csvDownload/csvDownloadFields";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { RecordsClientModel } from "~/clientModel/records";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { Flex } from "~/components_next/Flex";
import { HiddenFields } from "./HiddenFields";
import { MaskedFields } from "./MaskedFields";
import { RecordsTable } from "~/components_next/RecordsTable";
import { Spinner } from "~/components_next/Spinner";
import { DropdownMenu } from "~/components_next/DropdownMenu";
import { CsvDownloadFieldClientModel } from "~/clientModel/csvDownload/csvDownloadFields/csvDownloadField";
import { FieldClientModel } from "~/clientModel/fields/field";

type DownloadRecordsWithQueryDrawerProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  csvDownloadFields: CsvDownloadFieldsClientModel;
  onCsvDownloadFieldsChange: (
    csvDownloadFields: CsvDownloadFieldsClientModel
  ) => void;
  useDownloadExecutable: UseExecutable<
    void,
    { csvDownloadFields: CsvDownloadFieldsClientModel },
    { url: string }
  >;
  usePreviewRecordsLoadable: UseLoadable<
    { csvDownloadFields: CsvDownloadFieldsClientModel },
    RecordsClientModel
  >;
};

const DownloadRecordsWithQueryDrawer = (
  props: DownloadRecordsWithQueryDrawerProps
) => {
  const {
    isOpen,
    onOpenChange,
    csvDownloadFields,
    onCsvDownloadFieldsChange,
    usePreviewRecordsLoadable,
    useDownloadExecutable,
  } = props;

  const downloadExecutable = useDownloadExecutable();

  const previewLoadable = usePreviewRecordsLoadable({
    csvDownloadFields,
  });

  const getCsvDownloadFieldFromField = (
    field: FieldClientModel
  ): CsvDownloadFieldClientModel => {
    // 絶対ある
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return csvDownloadFields.allCsvDownloadFields.find(
      ({ baseField }) => baseField.name === field.name
    )!;
  };

  const handleFieldHide = (csvDownloadField: CsvDownloadFieldClientModel) => {
    onCsvDownloadFieldsChange(
      csvDownloadFields.updateCsvDownloadField(csvDownloadField.hideField())
    );
  };

  const handleFieldMask = (csvDownloadField: CsvDownloadFieldClientModel) => {
    onCsvDownloadFieldsChange(
      csvDownloadFields.updateCsvDownloadField(csvDownloadField.maskField())
    );
  };

  const handleFieldUnmask = (csvDownloadField: CsvDownloadFieldClientModel) => {
    onCsvDownloadFieldsChange(
      csvDownloadFields.updateCsvDownloadField(csvDownloadField.unmaskField())
    );
  };

  const handleDownload = async () => {
    const { url } = await downloadExecutable.execute({ csvDownloadFields });
    window.open(url, "_blank");
  };

  return (
    <Drawer.Root open={isOpen} onOpenChange={onOpenChange} size="xl">
      <Drawer.Content>
        <Drawer.Title>
          <DownloadDrawerTitle
            onClickDownload={handleDownload}
            isDownloading={downloadExecutable.isExecuting}
            isDisabled={
              !csvDownloadFields.validate().isValid ||
              previewLoadable.status !== "success"
            }
          />
        </Drawer.Title>
        <Drawer.Body>
          <WithFallback
            loadables={[previewLoadable] as const}
            loadingFallback={<LoadingFallback />}
          >
            {([previewRecords]) => (
              <Flex direction="column" gap="3" pt="3">
                <HiddenFields
                  csvDownloadFields={csvDownloadFields}
                  onCsvDownloadFieldsChange={onCsvDownloadFieldsChange}
                />
                <MaskedFields
                  csvDownloadFields={csvDownloadFields}
                  onCsvDownloadFieldsChange={onCsvDownloadFieldsChange}
                />
                <RecordsTable
                  records={previewRecords}
                  fields={csvDownloadFields.baseFields}
                  isReadOnly
                  noPadding
                  headerDropdown={(field) => {
                    const csvDownloadField =
                      getCsvDownloadFieldFromField(field);
                    return (
                      <HeaderDropdown
                        isMasked={csvDownloadField.isMasked}
                        onFieldHide={() => handleFieldHide(csvDownloadField)}
                        onFieldMask={() => handleFieldMask(csvDownloadField)}
                        onFieldUnmask={() =>
                          handleFieldUnmask(csvDownloadField)
                        }
                      />
                    );
                  }}
                />
              </Flex>
            )}
          </WithFallback>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};

const HeaderDropdown = (props: {
  isMasked: boolean;
  onFieldHide: () => void;
  onFieldMask: () => void;
  onFieldUnmask: () => void;
}) => {
  const { isMasked, onFieldHide, onFieldMask, onFieldUnmask } = props;
  return (
    <>
      <DropdownMenu.Item onClick={onFieldHide}>Hide Field</DropdownMenu.Item>
      {isMasked ? (
        <DropdownMenu.Item onClick={onFieldUnmask}>
          Unmask Field
        </DropdownMenu.Item>
      ) : (
        <DropdownMenu.Item onClick={onFieldMask}>Mask Field</DropdownMenu.Item>
      )}
    </>
  );
};

const LoadingFallback = () => {
  return (
    <Flex width="100%" height="100%" align="center" justify="center">
      <Spinner size="lg" />
    </Flex>
  );
};

export { DownloadRecordsWithQueryDrawer };
