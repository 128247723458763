import { useCallback, useMemo, useState } from "react";
import { RecordsTableProps } from "../RecordsTable";
import constate from "constate";
import {
  TableSelectionClientModel,
  TableSelectionClientModelFactory,
} from "~/clientModel/tables/tableMeta/TableSelection";
import { TableColumnSizingClientModel } from "~/clientModel/tables/tableMeta/TableColumnSizing/TableColumnSizingClientModel";
import { useHandleCellClickEvent } from "./useHandleCellClickEvent";
import { RecordClientModel } from "~/clientModel/records/record";
import { TableColumnSizingClientModelFactory } from "~/clientModel/tables/tableMeta/TableColumnSizing";
import { RecordIdentifierClientModel } from "~/clientModel/records/record/recordIdentifier";

type RecordsTableBaseContextProps = RecordsTableProps;

type ColumnMeta =
  | { type: "selectCheckbox" }
  | { type: "openRecordButton" }
  | { type: "item"; fieldName: string }
  | { type: "addFieldButton" };

const getColumnsMeta = ({
  isSelectable,
  isOpenable,
  noPadding,
  fields,
}: Required<
  Pick<
    RecordsTableBaseContextProps,
    "isSelectable" | "isOpenable" | "noPadding" | "fields"
  >
>): ColumnMeta[] => {
  const selectCheckboxMeta: ColumnMeta = {
    type: "selectCheckbox",
  };

  const openRecordButtonMeta: ColumnMeta = {
    type: "openRecordButton",
  };

  const itemsMetaArray: ColumnMeta[] = fields.visibleFields.allFields.map(
    (field) => ({
      type: "item",
      fieldName: field.name,
    })
  );

  const addFieldButtonMeta: ColumnMeta = {
    type: "addFieldButton",
  };

  const columnsMeta: ColumnMeta[] = [];

  if (isSelectable) {
    columnsMeta.push(selectCheckboxMeta);
  }

  if (isOpenable) {
    columnsMeta.push(openRecordButtonMeta);
  }

  columnsMeta.push(...itemsMetaArray, addFieldButtonMeta);

  return columnsMeta;
};

const useRecordsTableBaseContext = (props: RecordsTableBaseContextProps) => {
  const {
    isSelectable = false,
    isOpenable = false,
    isReorderable = false,
    noPadding = false,
    noFieldTypeIcon = false,
    propagateEvent = false,
    noStickyHeader = false,
    showMergeFieldIndicator = false,
    fields,
    records,
    tableSelection,
    coworkersState,
    columnSizing: propsColumnSizing,
    onColumnSizingChange: propsOnColumnSizingChange,
    onTableSelectionChange,
    headerDropdown,
    onAddFieldClick,
    onRecordClick,
    useUpdateRecordExecutable,
    useUploadFileExecutable,
    useRunSmartFieldExecutable,
  } = props;

  /**
   * Data
   */

  const columnsMeta = useMemo(
    () => getColumnsMeta({ isSelectable, isOpenable, noPadding, fields }),
    [isSelectable, isOpenable, noPadding, fields]
  );

  // table sizings
  const [localColumnSizing, setLocalColumnSizing] =
    useState<TableColumnSizingClientModel>(
      TableColumnSizingClientModelFactory.create({
        columnSizingDict: null,
        fields,
      })
    );

  const usePropsColumnSizing =
    !!propsColumnSizing && !!propsOnColumnSizingChange;

  const columnSizing = useMemo(
    () => (usePropsColumnSizing ? propsColumnSizing : localColumnSizing),
    [usePropsColumnSizing, propsColumnSizing, localColumnSizing]
  );

  const tableSelectionWithFallback = useMemo(
    () => tableSelection ?? TableSelectionClientModelFactory.createEmpty(),
    [tableSelection]
  );

  /**
   * Handlers
   */

  const handleCellClickEvent = useHandleCellClickEvent({
    tableSelection,
    onTableSelectionChange,
  });

  const updateRecordExecutable = useUpdateRecordExecutable?.();

  /**
   * handleCellEditingEnd
   */

  const [updatingCellState, setUpdatingCellState] = useState<{
    recordIdentifier: RecordIdentifierClientModel;
    fieldName: string;
  } | null>(null);

  const handleCellEditingEnd = useCallback(async () => {
    if (!tableSelection || !tableSelection.taggedEditingRecord.isEditing) {
      // あるはず
      return;
    }

    try {
      setUpdatingCellState({
        recordIdentifier:
          tableSelection.taggedEditingRecord.editingRecord
            .recordIdentifierBeforeEdit,
        fieldName: tableSelection.taggedEditingRecord.editingRecordEntry.key,
      });
      onTableSelectionChange?.(tableSelection.endCellEditing());

      await updateRecordExecutable?.execute({
        editingRecord: tableSelection.taggedEditingRecord.editingRecord,
      });
    } finally {
      setUpdatingCellState(null);
    }
  }, [updateRecordExecutable, tableSelection, onTableSelectionChange]);

  const isCellUpdating = useCallback(
    ({
      recordIdentifier,
      fieldName,
    }: {
      recordIdentifier: RecordIdentifierClientModel;
      fieldName: string;
    }): boolean => {
      if (!updatingCellState) {
        return false;
      }

      return (
        updatingCellState.recordIdentifier.isSame(recordIdentifier) &&
        updatingCellState.fieldName === fieldName
      );
    },
    [updatingCellState]
  );

  /**
   * handleRecordClick
   */

  const handleRecordClick = useCallback(
    (record: RecordClientModel) => {
      onRecordClick?.(record);
    },
    [onRecordClick]
  );

  /**
   * handleTableSelectionChange
   */

  const handleTableSelectionChange = useCallback(
    (tableSelection: TableSelectionClientModel) => {
      onTableSelectionChange?.(tableSelection);
    },
    [onTableSelectionChange]
  );

  /**
   * handleColumnSizingChange
   */

  const handleColumnSizingChange = useCallback(
    ({ fieldName, width }: { fieldName: string; width: number }) => {
      const updatedColumnSizing = columnSizing.updateColumnWidth(
        fieldName,
        width
      );
      if (usePropsColumnSizing) {
        propsOnColumnSizingChange(updatedColumnSizing);
      } else {
        setLocalColumnSizing(updatedColumnSizing);
      }
    },
    [columnSizing, usePropsColumnSizing, propsOnColumnSizingChange]
  );

  return {
    // options
    options: {
      isSelectable,
      isOpenable,
      isReorderable,
      noPadding,
      propagateEvent,
      noFieldTypeIcon,
      noStickyHeader,
      showMergeFieldIndicator,
    },

    // data
    records,
    fields,
    tableSelection: tableSelectionWithFallback,
    coworkersState,

    // ui
    headerDropdown,
    columnsMeta,
    columnSizing,

    // handlers
    onAddFieldClick,
    handleTableSelectionChange,
    handleColumnSizingChange,
    handleCellClickEvent,
    handleCellEditingEnd,
    isCellUpdating,
    handleRecordClick,

    // executables
    useUploadFileExecutable,
    useRunSmartFieldExecutable,
  };
};

export const [
  RecordsTableBaseProvider,
  // options
  useRecordsTableBaseOptions,

  // data
  useRecordsTableBaseRecords,
  useRecordsTableBaseFields,
  useRecordsTableBaseTableSelection,
  useRecordsTableBaseCoworkersState,

  // ui
  useRecordsTableBaseHeaderDropdown,
  useRecordsTableBaseColumnsMeta,
  useRecordsTableBaseColumnSizing,

  // handlers
  useRecordsTableBaseOnAddFieldClick,
  useRecordsTableBaseHandleTableSelectionChange,
  useRecordsTableBaseHandleColumnSizingChange,
  useRecordsTableBaseHandleCellClickEvent,
  useRecordsTableBaseHandleCellEditingEnd,
  useRecordsTableBaseHandleRecordClick,

  // executables
  useRecordsTableBaseUseUploadFileExecutable,
  useRecordsTableBaseUseRunSmartFieldExecutable,
] = constate(
  useRecordsTableBaseContext,
  // options
  (value) => value.options,

  // data
  (value) => value.records,
  (value) => value.fields,
  (value) => value.tableSelection,
  (value) => value.coworkersState,

  // ui
  (value) => value.headerDropdown,
  (value) => value.columnsMeta,
  (value) => value.columnSizing,

  // handlers
  (value) => value.onAddFieldClick,
  (value) => value.handleTableSelectionChange,
  (value) => value.handleColumnSizingChange,
  (value) => value.handleCellClickEvent,
  (value) => ({
    handleCellEditingEnd: value.handleCellEditingEnd,
    isCellUpdating: value.isCellUpdating,
  }),
  (value) => value.handleRecordClick,

  // executables
  (value) => value.useUploadFileExecutable,
  (value) => value.useRunSmartFieldExecutable
);
