import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useRouteGuard } from "~/App";
import { useMe } from "~/serverStateStore/me";
import { Button } from "~/components_next/Button";
import { Image } from "~/components_next/Image";
import { Text } from "~/components_next/Text";
import { Spinner } from "~/components_next/Spinner";
import { Flex } from "~/components_next/Flex";
import { Box } from "~/components_next/Box";
const TeamBar = ({
  teamName,
  teamSlug,
}: {
  teamName: string;
  teamSlug: string;
}) => {
  return (
    <Flex justify="center" align="center">
      <Link to={`/${teamSlug}`}>
        <Flex style={{ width: "500px" }} my="5" align="center" justify="center">
          <Button variant="primary" style={{ width: "100%" }}>{teamName}</Button>
        </Flex>
      </Link>
    </Flex>
  );
};

export const TeamSelect = () => {
  useRouteGuard();
  const { data: me } = useMe();
  const teams = useMemo(() => {
    if (me && me.teams.length > 0) {
      return me.teams;
    }
    return [];
  }, [me]);
  return (
    <>
      <Flex justify="center" align="center" mb="2" pt="5">
        <Image height="40px" src="/MorphLogo.png" />
      </Flex>
      <Flex justify="center" align="center" mb="2">
        <Text variant="heading">Retrieving Teams ...</Text>
      </Flex>
      <Box>
        {teams.length > 0 ? (
          teams.map((team) => (
            <TeamBar
              key={team.teamSlug}
              teamName={team.teamName}
              teamSlug={team.teamSlug}
            />
          ))
        ) : (
          <Flex justify="center" align="center">
            <Spinner size="lg" />
          </Flex>
        )}
      </Box>
    </>
  );
};
