import { useQueryClient } from "react-query";
import useApiRecord from "~/api/useApiRecord";
import { recordsKeys } from "../keys";

// types
import { RecordFilterCondition } from "@usemorph/morph-dashboard-types";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseBulkUpdateRecordByFormulaMutationParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
}

const useBulkUpdateRecordByFormulaMutation = ({
  databaseId,
  tableSlug,
  teamSlug,
}: UseBulkUpdateRecordByFormulaMutationParams) => {
  const { _bulkUpdateRecordByFormula } = useApiRecord();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      formula,
      filter,
      fieldName,
    }: {
      formula: string;
      filter: RecordFilterCondition;
      fieldName: string;
    }) => {
      return _bulkUpdateRecordByFormula({
        teamSlug,
        databaseId,
        tableSlug,
        formula,
        fieldName,
        filter,
      });
    },
    onSuccess: () => {
      const getRecordKey = recordsKeys.allGetRecord({
        teamSlug,
        databaseId,
        tableSlug,
      })
      const getRecordWithSqlKey = recordsKeys.allGetRecordWithSql({
        teamSlug,
        databaseId,
        tableSlug,
      })
      broadcast(getRecordKey);
      broadcast(getRecordWithSqlKey);

      return invalidateAll(client, getRecordKey, getRecordWithSqlKey);
    },
  };
};

export { useBulkUpdateRecordByFormulaMutation };
