import { Flex } from "@radix-ui/themes";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import {
  SortConditionsClientModel,
  SortConditionUnitClientModel,
} from "~/clientModel/queryConditions/sortConditions";
import { AddSortButton } from "./AddSortButton";
import { SortConditionUnitForm } from "./SortConditionUnitForm/SortConditionUnitForm";

type SortConditionsFormProps = {
  fields: FieldsClientModel;
  sortConditions: SortConditionsClientModel;
  onSortConditionChanges?: (sortCondition: SortConditionsClientModel) => void;
  isReadOnly?: boolean;
};

const SortConditionsForm = (props: SortConditionsFormProps) => {
  const {
    fields,
    sortConditions,
    onSortConditionChanges = () =>
      console.error("onSortConditionChanges is not defined"),
    isReadOnly = false,
  } = props;

  const handleSortConditionUnitAdd = () => {
    onSortConditionChanges(
      sortConditions.addInitialSortConditionUnit(fields.allFields[0])
    );
  };

  const handleSortConditionUnitRemove = (index: number) => {
    onSortConditionChanges(sortConditions.removeSortConditionByIndex(index));
  };

  const handleSortConditionUnitChange = (
    sortConditionUnit: SortConditionUnitClientModel,
    index: number
  ) => {
    onSortConditionChanges(
      sortConditions.replaceSortConditionItemByIndex(sortConditionUnit, index)
    );
  };

  return (
    <Flex direction="column" gap="2">
      {sortConditions.allSortConditionUnits.map((sortConditionUnit, index) => (
        <SortConditionUnitForm
          key={index}
          fields={fields}
          sortConditionUnit={sortConditionUnit}
          onSortConditionUnitChanges={(unit) =>
            handleSortConditionUnitChange(unit, index)
          }
          onSortConditionUnitRemove={() => handleSortConditionUnitRemove(index)}
          isReadOnly={isReadOnly}
        />
      ))}

      {!isReadOnly && <AddSortButton onClick={handleSortConditionUnitAdd} />}
    </Flex>
  );
};

export { SortConditionsForm };
