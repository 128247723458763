import { Checker } from "@recoiljs/refine";
import { URLSyncEffectOptions } from "recoil-sync";
import { kanbanViewUrlSyncStoreItemKeys } from "./kanbanViewUrlSyncStoreItemKeys";

const createKanbanViewUrlSyncStoreEffectOptions = <T>({
  checker,
  key,
  defaultValue,
}: {
  checker: Checker<T>;
  key: keyof typeof kanbanViewUrlSyncStoreItemKeys;
  defaultValue: T;
}): URLSyncEffectOptions<T> => {
  return {
    refine: checker,
    history: "push",
    itemKey: kanbanViewUrlSyncStoreItemKeys[key],
    read: ({ read }) => {
      // 型が違うときはデフォルト値を返す
      // クエリパラメータの型が違ってもクラッシュを防ぐ
      const value = read(kanbanViewUrlSyncStoreItemKeys[key]);

      if (checker(value).type === "success") {
        return value;
      } else {
        return defaultValue;
      }
    },
    write: ({ write, reset }, newValue) => {
      // デフォルト値と同じときはクエリパラメータを削除する
      if (newValue === defaultValue) {
        reset(kanbanViewUrlSyncStoreItemKeys[key]);
      } else {
        write(kanbanViewUrlSyncStoreItemKeys[key], newValue);
      }
    },
  };
};

export { createKanbanViewUrlSyncStoreEffectOptions };
