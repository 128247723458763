import { useMutation } from "react-query";
import { useComposeExecutable } from "~/clientModel/executable";
import { CreatePaymentSessionMutationProps, useCreatePaymentSessionMutation } from "~/serverStateStore";

type UseCreatePaymentSessionExecutableParams = {
  teamSlug: string;
}

const useCreatePaymentSessionExecutable = (props: UseCreatePaymentSessionExecutableParams) => {
  const { teamSlug } = props
  const createPaymentSessionMutationOptions = useCreatePaymentSessionMutation({ teamSlug })

  return useComposeExecutable(
    useMutation({
      ...createPaymentSessionMutationOptions,
      mutationFn: async (params: CreatePaymentSessionMutationProps) => {
        return await createPaymentSessionMutationOptions.mutationFn(params)
      }
    })
  )
}

export const createUseCreatePaymentSessionExecutable = (props: UseCreatePaymentSessionExecutableParams) => {
  return () => useCreatePaymentSessionExecutable({ ...props })
}
