import { Input } from "~/components_next/Input";
import { RecordValueReadOnlyInputCommonProps } from "../../type";

type AutoBigNumberReadonlyInputProps =
  RecordValueReadOnlyInputCommonProps<"autoBigNumber">;

const AutoBigNumberReadonlyInput = (props: AutoBigNumberReadonlyInputProps) => {
  const { value, errorMessages, size } = props;

  return (
    <Input
      size={size}
      variant="primary"
      value={value ?? ""}
      type="number"
      errorMessage={errorMessages}
      readOnly={true}
    />
  );
};

export { AutoBigNumberReadonlyInput };
