import { FieldClientModel } from "~/clientModel/fields/field";
import {
  FieldFormLayout,
  DisplayNameInput,
  FieldNameInput,
  NullableInput,
  DescriptionInput,
} from "../../components";
import { IsPrimaryInput } from "../../components/IsPrimaryInput";
import { ImportSchemaFieldTypeInput } from "./ImportSchemaFieldTypeInput";

type ImportSchemaitFieldFormProps = {
  field: FieldClientModel;
  onFieldChange: (field: FieldClientModel) => void;
};

export const ImportSchemaEditFieldForm = (
  props: ImportSchemaitFieldFormProps
) => {
  const { field, onFieldChange } = props;

  return (
    <FieldFormLayout>
      <DisplayNameInput
        field={field}
        onFieldChange={onFieldChange}
        linkedWithFieldName={false}
      />
      <FieldNameInput field={field} onFieldChange={onFieldChange} />
      <ImportSchemaFieldTypeInput field={field} onFieldChange={onFieldChange} />
      <NullableInput field={field} onFieldChange={onFieldChange} />
      <IsPrimaryInput field={field} onFieldChange={onFieldChange} />
      <DescriptionInput field={field} onFieldChange={onFieldChange} />
    </FieldFormLayout>
  );
};
