import { Box } from "@radix-ui/themes";
import {
  DashboardTableHistoryConditionUnit,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { Badge } from "~/components_next/Badge";
import {
  isAndCondition,
  isOrCondition,
} from "~/features/TableHistory/util/tableHistoryConditionUtils";

export const TableHistoryNormalCondition = ({
  condition,
  fields,
}: {
  condition: DashboardTableHistoryConditionUnit;
  fields: SimpleField[];
}) => {
  const getDisplayName = (name: string) => {
    const targetField = fields.find((field) => field.name === name);
    if (targetField) {
      return targetField.displayName;
    }
    return undefined;
  };

  return (
    <>
      {condition && !isAndCondition(condition) && !isOrCondition(condition) && (
        <Box>
          <Badge variant="secondary">
            {`${getDisplayName(condition.key) ?? condition.key} ${
              condition.operator
            } ${condition.value}`}
          </Badge>
        </Box>
      )}
    </>
  );
};
