import { FieldClientModel } from "~/clientModel/fields/field";
import {
  FilterOperationClientModelBase,
  FilterOperationWithValueInterface,
} from "./FilterOperationClientModelBase";
import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { StringValidator } from "./CommonValueValidator";

type LessThanOrEqualFilterOperationClientModelData = {
  value: string;
};

export class LessThanOrEqualFilterOperationClientModel
  extends FilterOperationClientModelBase
  implements
    FilterOperationWithValueInterface<
      string,
      LessThanOrEqualFilterOperationClientModel
    >
{
  readonly #data: LessThanOrEqualFilterOperationClientModelData;

  public constructor(data: LessThanOrEqualFilterOperationClientModelData) {
    super();
    this.#data = data;
  }

  public get data() {
    return this.#data;
  }

  public get type(): "lessThanOrEqual" {
    return "lessThanOrEqual";
  }

  public get operatorValue(): "lessThanOrEqual" {
    return "lessThanOrEqual";
  }

  public get operatorLabel(): "Less than or equal" {
    return "Less than or equal";
  }

  public get operandValue(): string {
    return this.#data.value;
  }

  public updateValue(value: string): LessThanOrEqualFilterOperationClientModel {
    return new LessThanOrEqualFilterOperationClientModel({
      ...this.#data,
      value,
    });
  }

  public validate(targetField: FieldClientModel): ValidationResult {
    return new StringValidator(this.#data.value)
      .shouldNotBeEmpty()
      .shouldBeIntStringIfNumberType(targetField)
      .end();
  }
}
