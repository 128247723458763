import useApiTeam from "~/api/useApiTeam";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { meKeys } from "../../me/keys";

const useCreateTeamMutation = () => {
  const { _createTeam } = useApiTeam();
  const client = useQueryClient();

  return {
    mutationFn: ({
      teamSlug,
      teamName,
    }: {
      teamSlug: string;
      teamName: string;
    }) => {
      return _createTeam({
        teamSlug,
        teamName,
      });
    },
    onSuccess: () => {
      return invalidateAll(client, meKeys.getLoginUser());
    },
  };
};

export { useCreateTeamMutation };
