import { UseExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { EditingRecordClientModel } from "~/clientModel/records/editingRecord";
import { Flex } from "~/components_next/Flex";
import { RecordValueInputWithFieldInfo } from "./RecordValueInputWithFieldInfo";

type EditRecordFormProps = {
  editingRecord: EditingRecordClientModel;
  fields: FieldsClientModel;
  onEditingRecordChange: (editingRecord: EditingRecordClientModel) => void;
  useUploadFileExecutable: UseExecutable<
    void,
    { file: File },
    {
      data: string;
      url: string;
    },
    unknown
  >;
  isReadonly?: boolean;
};

export const EditRecordForm = (props: EditRecordFormProps) => {
  const {
    editingRecord,
    fields,
    onEditingRecordChange,
    useUploadFileExecutable,
    isReadonly = false,
  } = props;

  return (
    <Flex direction="column" gap="7">
      {fields.allFields.map((field) => {
        const recordEntry = editingRecord.selectEditingRecordEntry(field.name);

        if (!recordEntry) {
          return null;
        }
        return (
          <RecordValueInputWithFieldInfo
            key={field.name}
            recordEntry={recordEntry}
            onRecordEntryChange={(entry) => {
              onEditingRecordChange(editingRecord.updateRecordEntry(entry));
            }}
            field={field}
            useUploadFileExecutable={useUploadFileExecutable}
            isReadonly={isReadonly}
          />
        );
      })}
    </Flex>
  );
};
