import { RssFeedPresenter } from "~/presenters/rssFeed/RssFeedPresenter"
import { useTeamSlug } from "~/utilHooks/useTeamSlug"
import { createUseListSampleRssFeedLoadable } from "./createUseListSampleRssFeedLoadable"
import { useMemo } from "react"
import { createUseImportRssIntoTableExecutable } from "./createUseImportRssIntoTableExecutable"
import { useTableSlug } from "~/utilHooks/useTableSlug"
import { useDatabaseId } from "~/utilHooks/useDatabaseId"
import { createUseFieldsLoadable } from "../source/hooks/fields/createUseFieldsLoadable"
import { createUseFindRssLinkedToTableLoadable } from "./createUseFindRssLinkedToTableLoadable"
import { createUseUpdateRssTableMappingExecutable } from "./createUseUpdateRssTableMappingExecutable"
import { createUseDeleteRssTableMappingExecutable } from "./createUseDeleteRssTableMappingExecutable"
import { useDisclosure } from "~/hooks/useDisclosure"


export const RssFeedContainer = () => {
  const teamSlug = useTeamSlug()
  const databaseId = useDatabaseId()
  const tableSlug = useTableSlug()

  const useFindRssLinkedToTableLoadable = useMemo(() => {
    return createUseFindRssLinkedToTableLoadable({ teamSlug, databaseId, tableSlug, })
  }, [teamSlug, databaseId, tableSlug])

  const useListSampleRssFeedLoadable = useMemo(() => {
    return createUseListSampleRssFeedLoadable({ teamSlug })
  }, [teamSlug])

  const useImportRssIntoTableExecutable = useMemo(() => {
    return createUseImportRssIntoTableExecutable({ teamSlug, databaseId, tableSlug })
  }, [teamSlug, databaseId, tableSlug])

  const useFieldsLoadable = useMemo(() => {
    return createUseFieldsLoadable({ teamSlug, databaseId })
  }, [teamSlug, databaseId])

  const useUpdateRssTableMappingExecutable = useMemo(() => {
    return createUseUpdateRssTableMappingExecutable({ teamSlug, databaseId, tableSlug })
  }, [teamSlug, databaseId, tableSlug])

  const useDeleteRssTableMappingExecutable = useMemo(() => {
    return createUseDeleteRssTableMappingExecutable({ teamSlug, databaseId, tableSlug })
  }, [teamSlug, databaseId, tableSlug])


  const deleteDialogDisclosure = useDisclosure();


  return (
    <>
      <RssFeedPresenter
        useFindRssLinkedToTableLoadable={useFindRssLinkedToTableLoadable}
        useListSampleRssFeedLoadable={useListSampleRssFeedLoadable}
        useImportRssIntoTableExecutable={useImportRssIntoTableExecutable}
        useFieldsLoadable={useFieldsLoadable}
        useUpdateRssTableMappingExecutable={useUpdateRssTableMappingExecutable}
        useDeleteRssTableMappingExecutable={useDeleteRssTableMappingExecutable}
        deleteDialogDisclosure={deleteDialogDisclosure}
      />
    </>
  )
}
