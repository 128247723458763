import {
  Popover,
  Box,
  Flex,
  Portal,
  Separator,
} from "@radix-ui/themes";
import {
  AggregationOperatorType,
  RecordAggregationCondition,
  RecordFilterConditionUnit,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { useEffect, useMemo, useState } from "react";
import { Button } from "~/components_next/Button";
import { Input } from "~/components_next/Input"
import { SimpleSelect } from "~/components_next/Select";
import { Text } from "~/components_next/Text";
import {
  EmptyAggregationFilterUnit,
  EmptyAggregationUnit,
} from "~/utils/aggregateUtils";
type operator = RecordFilterConditionUnit["operator"];

export const AggregationForm = ({
  defaultValue,
  fields,
  onChange,
}: {
  defaultValue?: RecordAggregationCondition;
  fields: SimpleField[];
  onChange?: (filter: RecordAggregationCondition) => void;
}) => {
  // const popoverBgColor = useColorModeValue("white", "gray.800");

  const [recordAggregation, setRecordAggregation] =
    useState<RecordAggregationCondition>();

  useEffect(() => {
    if (defaultValue) {
      setRecordAggregation(defaultValue);
    }
  }, []);

  useEffect(() => {
    if (onChange && recordAggregation) {
      onChange(recordAggregation);
    }
  }, [recordAggregation]);

  const inputValue = useMemo(() => {
    if (recordAggregation && recordAggregation.filter) {
      return `${recordAggregation.key}, ${recordAggregation.operator}, 1 Filter`;
    } else if (recordAggregation) {
      return `${recordAggregation.key}, ${recordAggregation.operator}`;
    } else {
      return "";
    }
  }, [recordAggregation]);

  return (
    <>
      {/* <ScaleFade initialScale={0.9} in={true}> */}
      <Box>
        <Popover.Root>
          <>
            <Popover.Trigger>
              <Box
                width={"100%"}
                onClick={() => {
                  if (recordAggregation === undefined) {
                    setRecordAggregation(
                      EmptyAggregationUnit({
                        key: fields[0].name,
                      })
                    );
                  }
                }}
              >
                {/* <InputGroup size="sm"> */}
                <Input
                  variant="primary"
                  readOnly={true}
                  style={{ borderRightWidth: 0 }}
                  value={inputValue}
                />
                {/* <InputRightAddon
                    bgColor={"whiteAlpha.300"}
                    borderLeftWidth={0}
                  >
                    <BsChevronDown size={25} />
                  </InputRightAddon> */}
                {/* </InputGroup> */}
              </Box>
            </Popover.Trigger>

            <Portal>
              <Popover.Content
                style={{ minWidth: "500px", zIndex: "popover" }}
              >
                {/* <PopoverArrow bgColor={popoverBgColor} /> */}
                <Popover.Content style={{ minWidth: "500px" }}>
                  {recordAggregation ? (
                    <>
                      <_AggregationInputs
                        aggregation={recordAggregation}
                        fields={fields}
                        onChange={(aggregate) =>
                          setRecordAggregation(aggregate)
                        }
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Popover.Content>
              </Popover.Content>
            </Portal>
          </>
        </Popover.Root>
      </Box>
    </>
  );
};

const _AggregationInputs = ({
  aggregation,
  fields,
  onChange,
}: {
  aggregation: RecordAggregationCondition;
  fields: SimpleField[];
  onChange: (aggregation: RecordAggregationCondition) => void;
}) => {
  const [localAggregation, setLocalAggregation] =
    useState<RecordAggregationCondition>(aggregation);
  useEffect(() => {
    onChange(localAggregation);
  }, [localAggregation]);

  const aggregationOperatorOpt: AggregationOperatorType[] = [
    "sum",
    "count",
    "mean",
    "median",
  ];

  const operatorArray: operator[] = [
    "equal",
    "notEqual",
    "lessThan",
    "lessThanOrEqual",
    "greaterThan",
    "greaterThanOrEqual",
    "isNull",
    "notNull",
    "like",
    "startsWith",
    "endsWith",
    "in",
    "notIn",
  ];

  const humanReadableOperator = (operator: operator): string => {
    switch (operator) {
      case "equal":
        return "Equal";
      case "notEqual":
        return "Not Equal";
      case "lessThan":
        return "Less than";
      case "lessThanOrEqual":
        return "Less than or equal";
      case "greaterThan":
        return "Greater than";
      case "greaterThanOrEqual":
        return "Greater than or equal";
      case "isNull":
        return "Null";
      case "notNull":
        return "Not null";
      case "like":
        return "Like";
      case "startsWith":
        return "Starts with";
      case "endsWith":
        return "End with";
      case "in":
        return "In";
      case "notIn":
        return "Not in";
      default:
        return "";
    }
  };

  return (
    <>
      <Flex gap="2" mb="3">
        <SimpleSelect
          variant="primary"
          value={localAggregation.key}
          size={"xs"}
          onChange={(e) => {
            if (e) {
              setLocalAggregation({ ...localAggregation, key: e });
            }
          }}
          options={fields.map((field) => {
            return (
              { label: field.displayName || field.name, value: field.name }
            );
          })}
        />

        <SimpleSelect
          variant="primary"
          value={localAggregation.operator}
          size={"xs"}
          onChange={(e) => {
            if (e) {
              setLocalAggregation({
                ...localAggregation,
                operator: e as "sum" | "count" | "mean" | "median",
              });
            }
          }}
          options={aggregationOperatorOpt.map(op => {
            return ({ label: op, value: op })
          })}
        />
      </Flex>

      {localAggregation.filter && (
        <Box mx="1">
          <Text variant="description">
            Filter
          </Text>
          <Flex align={"center"} gap="2">
            <Text variant="description">Value</Text>
            {/* <Input size={"xs"} m={"2px"} mt={1} /> */}
            <SimpleSelect
              variant="primary"
              value={localAggregation.operator}
              size={"xs"}
              onChange={(e) => {
                if (
                  localAggregation.filter &&
                  (e === "isNull" || e === "notNull")
                ) {
                  setLocalAggregation({
                    ...localAggregation,
                    filter: {
                      ...localAggregation.filter,
                      operator: e,
                    },
                  });
                  // handleChangeValue(undefined, e.target.value);
                } else if (localAggregation.filter) {
                  setLocalAggregation({
                    ...localAggregation,
                    filter: {
                      ...localAggregation.filter,
                      operator: e as
                        | "equal"
                        | "notEqual"
                        | "lessThan"
                        | "lessThanOrEqual"
                        | "greaterThan"
                        | "greaterThanOrEqual"
                        | "isNull"
                        | "notNull"
                        | "like"
                        | "startsWith"
                        | "endsWith"
                        | "in"
                        | "notIn",
                    },
                  });
                }
              }}
              options={operatorArray.map(operator => {
                return { label: operator, value: operator }
              })}
            />
          </Flex>
        </Box>
      )}

      <Separator my="1" />
      {!localAggregation.filter && (
        <Button
          onClick={() => {
            setLocalAggregation({
              ...localAggregation,
              filter: EmptyAggregationFilterUnit({ value: undefined }),
            });
          }}
          variant="tertiary"
          size={"xs"}
          color={"gray"}
        // p="3"
        >
          Add Filter
        </Button>
      )}
      {localAggregation.filter && (
        <Button
          onClick={() => {
            setLocalAggregation({
              ...localAggregation,
              filter: undefined,
            });
          }}
          variant="tertiary"
          size={"xs"}
          color={"gray"}
        // p={3}
        >
          Delete Filter
        </Button>
      )}
    </>
  );
};
