import useApiPayment from "~/api/useApiPayment";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { paymentKeys } from "../keys";

interface UseUpdatePaymentMutationParams {
  teamSlug: string;
}

type UpdatePaymentMutationProps = {
  planId: string;
}

const useUpdatePlanMutation = ({
  teamSlug,
}: UseUpdatePaymentMutationParams) => {
  const { _upgradePlan } = useApiPayment();
  const client = useQueryClient();

  return {
    mutationFn: ({ planId }: UpdatePaymentMutationProps) => {
      return _upgradePlan({
        teamSlug,
        planId,
      });
    },
    onSuccess: () => {
      return invalidateAll(client, paymentKeys.all({ teamSlug }));
    },
  };
};

export { useUpdatePlanMutation, type UpdatePaymentMutationProps };
