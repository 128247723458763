import { useQueryClient } from "react-query";
import useApiRssFeed from "~/api/useApiRssFeed";
import { rssFeedKeys } from "../keys";
import { commonQueryOptions } from "../../util/commonQueryOptions";

type UseListSampleRssFeedQueryParams = {
  teamSlug: string;
  rssUrl: string;
  enabled: boolean;
};

const useListSampleRssFeedQuery = ({
  teamSlug,
  rssUrl,
  enabled,
}: UseListSampleRssFeedQueryParams) => {
  const { _listSampleRssFeed } = useApiRssFeed();
  const client = useQueryClient();

  client.setQueryDefaults(
    rssFeedKeys.allListRssFeedSample({
      teamSlug,
      rssUrl,
    }),
    commonQueryOptions
  );

  return {
    queryKey: rssFeedKeys.allListRssFeedSample({
      teamSlug,
      rssUrl,
    }),
    queryFn: () => {
      return _listSampleRssFeed({
        teamSlug,
        rssUrl,
      });
    },
    enabled,
  };
};

export { useListSampleRssFeedQuery, type UseListSampleRssFeedQueryParams };


// type UseListSampleRssFeedMutationParams = {
//   teamSlug: string;
// };

// export type UseListSampleRssFeedMutationProps = {
//   rssUrl: string;
// };

// const useListSampleRssFeedMutation = ({
//   teamSlug,
// }: UseListSampleRssFeedMutationParams) => {
//   const { _listSampleRssFeed } = useApiRssFeed();
//   const client = useQueryClient();

//   return {
//     mutationFn: async ({
//       rssUrl,
//     }: UseListSampleRssFeedMutationProps) => {
//       const response = await _listSampleRssFeed({
//         teamSlug,
//         rssUrl,
//       })

//       return RssFeedItemsClientModelFactory.fromRssFeedItemResponse(response)

//     },
//     onSuccess: () => {
//       return invalidateAll(
//         client,
//         rssFeedKeys.all({ teamSlug })
//       )
//     }
//   };
// };

// export { useListSampleRssFeedMutation };
