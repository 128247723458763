import { useQueryClient } from "react-query";
import useApiRecord from "~/api/useApiRecord";
import { recordsKeys } from "../keys";

// types
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import {
  QueryRecordListResponse,
  RecordFilterCondition,
} from "@usemorph/morph-dashboard-types";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseDeleteRecordsParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
}

const useDeleteRecordMutation = ({
  databaseId,
  tableSlug,
  teamSlug,
}: UseDeleteRecordsParams) => {
  const { _deleteRecord } = useApiRecord();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      filter,
      isHardDelete,
    }: {
      filter: RecordFilterCondition;
      isHardDelete: boolean;
    }): Promise<QueryRecordListResponse> => {
      return _deleteRecord({
        teamSlug,
        databaseId,
        tableSlug,
        body: { filter },
        isHardDelete,
      });
    },
    onSuccess: () => {
      const getRecordKey = recordsKeys.allGetRecord({
        teamSlug,
        databaseId,
        tableSlug,
      });
      const getRecordWithSqlKey = recordsKeys.allGetRecordWithSql({
        teamSlug,
        databaseId,
        tableSlug,
      });
      const getSoftDeletedRecordKey = recordsKeys.allGetSoftDeletedRecord({
        teamSlug,
        databaseId,
        tableSlug,
      });
      broadcast(getRecordKey);
      broadcast(getRecordWithSqlKey);
      broadcast(getSoftDeletedRecordKey);

      return invalidateAll(
        client,
        getRecordKey,
        getRecordWithSqlKey,
        getSoftDeletedRecordKey
      );
    },
  };
};

export { useDeleteRecordMutation };
