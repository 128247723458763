// types
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiSQL from "~/api/useApiSQL";
import { SimpleField } from "@usemorph/morph-dashboard-types";
import { sqlKeys } from "../keys";

interface UseDownloadAsCSVFromExecuteSQLQueryParams {
  databaseId: string;
  teamSlug: string;
  sql: string;
  fields: SimpleField[];
}

const useDownloadAsCSVFromExecuteSQLQuery = ({
  databaseId,
  teamSlug,
  sql,
  fields,
}: UseDownloadAsCSVFromExecuteSQLQueryParams) => {
  const { _downloadCSVFromExecuteSQL } = useApiSQL();

  const client = useQueryClient();
  client.setQueryDefaults(
    sqlKeys.all({ databaseId, teamSlug }),
    commonQueryOptions
  );

  return {
    queryKey: sqlKeys.downloadAsCSVFromExecuteSQL({
      databaseId,
      teamSlug,
      sql,
      fields,
    }),
    queryFn: () => {
      return _downloadCSVFromExecuteSQL({
        databaseId,
        teamSlug,
        fields,
        sql,
      });
    },
  };
};

export { useDownloadAsCSVFromExecuteSQLQuery };
