import { ReactNode, useMemo } from "react";
import { Text } from "~/components_next/Text";
import { ResizableBox, ResizableBoxProps } from "../ResizableBox";
import { BsXLg } from "react-icons/bs"; // eslint-disable-line import/no-restricted-paths
import { Box, Flex, ScrollArea } from "@radix-ui/themes";
import { styled } from "~/stitches";
import { IconButton } from "../IconButton";
import { Spacer } from "../Spacer";

type RightSidebarProps = Omit<ResizableBoxProps, "direction" | "title"> & {
  title?: ReactNode;
  footer?: ReactNode;
  onClose?: () => void;
};

const RighjtSidebarResizableBox = styled(ResizableBox, {
  height: "100%",
  overflow: "hidden",
  borderLeft: "solid 1px $border",
});

const RightSidebarContentElement = styled(Flex, {
  height: "calc(100vh - 40px)",
});

const RightSidebarTitleWrapperFlex = styled(Flex, {
  height: "40px",
  alignItems: "center",
  padding: "8px 16px",
});

const RightSidebarBodyWrapper = styled(ScrollArea, {
  flex: 1,
  padding: "8px 16px",
});

const RightSidebarFooterWrapper = styled(Box, {
  minHeight: "40px",
  padding: "8px 16px",
});

const RightSidebar = (props: RightSidebarProps) => {
  const { title, footer, children, onClose } = props;

  const boxProps: Omit<ResizableBoxProps, "direction"> = (({
    title,
    children,
    footer,
    onClose,
    minWidth,
    maxWidth,
    ...rest
  }) => rest)(props);

  const bodyHeight = useMemo(() => {
    if (footer && title) return "calc(100vh - 150px)";
    if (!footer && !title) return "calc(100vh - 80px)";
    return "calc(100vh - 120px)";
  }, [footer, title]);

  return (
    <RighjtSidebarResizableBox
      {...boxProps}
      style={{ height: "100%", overflow: "hidden" }}
      direction={"left"}
    >
      <RightSidebarContentElement direction="column">
        {/* Title */}
        {title && (
          <RightSidebarTitleWrapperFlex align="center">
            <Box>
              {typeof title === "string" ? (
                <Text variant="heading">{title}</Text>
              ) : (
                title
              )}
            </Box>
            <Spacer />
            {onClose && (
              <IconButton icon={<BsXLg />} tooltip="Close" onClick={onClose} />
            )}
          </RightSidebarTitleWrapperFlex>
        )}

        {/* Body */}
        <RightSidebarBodyWrapper>{children}</RightSidebarBodyWrapper>

        {/* Footer */}
        {footer && (
          <RightSidebarFooterWrapper>{footer}</RightSidebarFooterWrapper>
        )}
      </RightSidebarContentElement>
    </RighjtSidebarResizableBox>
  );
};

export { RightSidebar };
