import { DashboardUserObject } from "@usemorph/morph-dashboard-types";
import { memo } from "react";
import { match, P } from "ts-pattern";
import { UserMention } from "../../User";
import { AllRecordModelValueUnion } from "~/features/RecordModel";
import { Text } from "~/components_next/Text";
import { Badge } from "~/components_next/Badge";
import { Image } from "~/components_next/Image";
import { Checkbox } from "~/components_next/Checkbox";
import { Tag } from "~/components_next/Tag";
import { Flex, Link } from "@radix-ui/themes";

type RecordsTableCellDisplayProps = {
  recordModelValue: AllRecordModelValueUnion;
};

const InvalidTypeContent = (props: { children: unknown }) => {
  const { children } = props;

  return <Text variant="errorMessage">{String(children)}</Text>;
};

const SingleSelectContent = (props: { value: string | null | undefined }) => {
  const { value } = props;

  if (!value) {
    return null;
  }

  return (
    <Tag size="sm" variant="tertiary" key={value}>
      {value}
    </Tag>
  );
};

const MultiSelectContent = (props: { value: string[] | null | undefined }) => {
  const { value } = props;

  if (!value) {
    return null;
  }

  return (
    <Flex gap="1">
      {value.map((value) => (
        <Tag size="sm" variant="tertiary" key={value}>
          {value}
        </Tag>
      ))}
    </Flex>
  );
};

const BooleanContent = (props: { value: boolean | null | undefined }) => {
  const { value } = props;

  if (value == null) {
    return null;
  }

  return <Checkbox value={value} isReadOnly />;
};

const ImageContent = (props: {
  value: { data: string; url: string } | null | undefined;
}) => {
  const { value } = props;

  if (!value) {
    return null;
  }

  return <Image height="30px" src={value.url} />;
};

const AttachmentContent = (props: {
  value: { data: string; url: string } | null | undefined;
}) => {
  const { value } = props;

  if (!value) {
    return null;
  }

  const attachmentName = value.url.split("/").pop();

  return <Badge variant="secondary">{attachmentName}</Badge>;
};

const UserContent = (props: {
  value: DashboardUserObject | null | undefined;
}) => {
  const { value } = props;
  return value ? <UserMention user={value} /> : null;
};

const HTMLContent = (props: { value: string | null | undefined }) => {
  const { value } = props;

  if (!value) {
    return null;
  }

  return (
    <Text variant="description" style={{ fontFamily: "monospace" }}>
      {value}
    </Text>
  );
};

const JSONContent = (props: { value: string | null | undefined }) => {
  const { value } = props;

  if (!value) {
    return null;
  }

  return <Text style={{ fontFamily: "monospace" }}>{value}</Text>;
};

const ArrayContent = (props: { value: unknown[] | null | undefined }) => {
  const { value } = props;

  if (!value) {
    return null;
  }

  return (
    <Text style={{ fontFamily: "monospace" }}>{JSON.stringify(value)}</Text>
  );
};

const LinkContent = (props: { value: string | null | undefined }) => {
  const { value } = props;

  if (!value) {
    return null;
  }

  return (
    <Link href={value} target="_blank">
      {value}
    </Link>
  );
};

const RecordValueDisplay = (props: RecordsTableCellDisplayProps) => {
  const { recordModelValue } = props;

  return (
    <>
      {match(recordModelValue)
        .with(
          {
            type: P.union(
              "shortText",
              "longText",
              "email",
              "phoneNumber",
              "number",
              "bigNumber",
              "decimal",
              "autoBigNumber",
              "autoNumber",
              "embeddings"
            ),
          },
          (recordModelValue) =>
            recordModelValue.isValid ? (
              <Text>{recordModelValue.value}</Text>
            ) : (
              <InvalidTypeContent>
                {recordModelValue.rowValue}
              </InvalidTypeContent>
            )
        )
        .with({ type: "url" }, (recordModelValue) =>
          recordModelValue.isValid ? (
            <LinkContent value={recordModelValue.value} />
          ) : (
            <InvalidTypeContent>{recordModelValue.rowValue}</InvalidTypeContent>
          )
        )
        .with({ type: "singleSelect" }, (recordModelValue) =>
          recordModelValue.isValid ? (
            <SingleSelectContent
              value={recordModelValue.value}
            ></SingleSelectContent>
          ) : (
            <InvalidTypeContent>{recordModelValue.rowValue}</InvalidTypeContent>
          )
        )
        .with({ type: "multiSelect" }, (recordModelValue) =>
          recordModelValue.isValid ? (
            <MultiSelectContent value={recordModelValue.value} />
          ) : (
            <InvalidTypeContent>{recordModelValue.rowValue}</InvalidTypeContent>
          )
        )
        .with({ type: "formula" }, (recordModelValue) => (
          <Text>{String(recordModelValue.value)}</Text>
        ))
        .with(
          {
            type: P.union(
              "date",
              "datetime",
              "time",
              "createdAt",
              "lastEditedAt"
            ),
          },
          (recordModelValue) =>
            recordModelValue.isValid ? (
              <Text style={{ fontFamily: "monospace" }}>
                {recordModelValue.value}
              </Text>
            ) : (
              <InvalidTypeContent>
                {recordModelValue.rowValue}
              </InvalidTypeContent>
            )
        )
        .with({ type: "boolean" }, (recordModelValue) =>
          recordModelValue.isValid ? (
            <BooleanContent value={recordModelValue.value} />
          ) : (
            <InvalidTypeContent>{recordModelValue.rowValue}</InvalidTypeContent>
          )
        )
        .with(
          {
            type: "image",
          },
          (recordModelValue) =>
            recordModelValue.isValid ? (
              <ImageContent value={recordModelValue.value} />
            ) : (
              <InvalidTypeContent>
                {recordModelValue.rowValue}
              </InvalidTypeContent>
            )
        )
        .with(
          {
            type: "attachment",
          },
          (recordModelValue) =>
            recordModelValue.isValid ? (
              <AttachmentContent value={recordModelValue.value} />
            ) : (
              <InvalidTypeContent>
                {recordModelValue.rowValue}
              </InvalidTypeContent>
            )
        )
        .with(
          {
            type: P.union("createdBy", "lastEditedBy"),
          },
          (recordModelValue) =>
            recordModelValue.isValid ? (
              <UserContent value={recordModelValue.value} />
            ) : (
              <InvalidTypeContent>
                {recordModelValue.rowValue}
              </InvalidTypeContent>
            )
        )
        .with(
          {
            type: P.union("richText"),
          },
          () => null
        )
        .with(
          {
            type: P.union("array"),
          },
          (recordModelValue) =>
            recordModelValue.isValid ? (
              <ArrayContent value={recordModelValue.value} />
            ) : (
              <InvalidTypeContent>
                {recordModelValue.rowValue}
              </InvalidTypeContent>
            )
        )
        .with(
          {
            type: P.union("json"),
          },
          (recordModelValue) =>
            recordModelValue.isValid ? (
              <JSONContent value={recordModelValue.value} />
            ) : (
              <InvalidTypeContent>
                {recordModelValue.rowValue}
              </InvalidTypeContent>
            )
        )
        .with(
          {
            type: "html",
          },
          (recordModelValue) =>
            recordModelValue.isValid ? (
              <HTMLContent value={recordModelValue.value} />
            ) : (
              <InvalidTypeContent>
                {recordModelValue.rowValue}
              </InvalidTypeContent>
            )
        )
        .with(
          {
            type: P.union("reference", "multiReference", "python", "rowInfo"),
          },
          () => null
        )
        .exhaustive()}
    </>
  );
};

const MemoizedRecordsValueDisplay = memo(RecordValueDisplay);

export { MemoizedRecordsValueDisplay as RecordValueDisplay };
