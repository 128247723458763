import { match, P } from "ts-pattern";
import { FieldClientModel } from "~/clientModel/fields/field";
import { DisplaySettingClientModel } from "~/clientModel/fields/field/displaySetting";
import { DateDisplaySettingClientModel } from "~/clientModel/fields/field/displaySetting/date";
import { NumberDisplaySettingClientModel } from "~/clientModel/fields/field/displaySetting/numberDisplaySetting";
import { OutlinedWrapper } from "../atomos/OutlinedWrapper";
import { DateDisplaySettingInput } from "./DateDisplaySettingInput";
import { NumberDisplaySettingInput } from "./NumberDisplaySettingInput";

type DisplaySettingInputProps = {
  field: FieldClientModel;
  onFieldChange: (field: FieldClientModel) => void;
  isReadOnly?: boolean;
};

export const DisplaySettingInput = (props: DisplaySettingInputProps) => {
  const { field, onFieldChange, isReadOnly } = props;

  const handleDisplaySettingChange = (
    displaySetting: DisplaySettingClientModel
  ) => {
    onFieldChange(field.updateDisplaySetting(displaySetting));
  };

  if (!field.displaySetting) {
    return null;
  }

  return (
    <OutlinedWrapper title="Display">
      {match(field.displaySetting)
        .with(
          P.instanceOf(NumberDisplaySettingClientModel),
          (displaySetting) => (
            <NumberDisplaySettingInput
              value={displaySetting}
              onChange={handleDisplaySettingChange}
              isReadOnly={isReadOnly}
            />
          )
        )
        .with(P.instanceOf(DateDisplaySettingClientModel), (displaySetting) => (
          <DateDisplaySettingInput
            value={displaySetting}
            onChange={handleDisplaySettingChange}
            isReadOnly={isReadOnly}
          />
        ))
        .exhaustive()}
    </OutlinedWrapper>
  );
};
