import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Flex } from "~/components_next/Flex";
import { Image } from "~/components_next/Image";
import { Text } from "~/components_next/Text";
import { useColorMode } from "~/contexts/ColorModeContext";
import { useMe } from "~/serverStateStore/me";
import { stringToBoolean } from "~/utils/stringUtils";

export const PageMaintenance = () => {
  const navigate = useNavigate();

  const colorMode = useColorMode()
  const isMaintenance = stringToBoolean(import.meta.env.VITE_MAINTENANCE_MODE) || stringToBoolean(localStorage.getItem('isMaintenance'))
  const maintenanceMessage = import.meta.env.VITE_MAINTENANCE_MESSAGE

  const { data: me } = useMe();

  useEffect(() => {
    if (!isMaintenance && me) {
      navigate("/");
    }
  }, [me, isMaintenance])

  return (
    <>
      <Flex justify={"center"} align="center" css={{ marginBottom: "80px", marginTop: "100px" }}>
        <Link to="/">
          {
            colorMode === 'light'
              ? <Image height="40px" src="/MorphLogo.png" />
              : <Image height="40px" src="/MorphLogoWhite.png" />
          }
        </Link>
      </Flex>
      <Flex justify={"center"} align="center">
        <Text variant="heading">
          Morph under maintenance {isMaintenance}
        </Text>
      </Flex>
      {
        maintenanceMessage
          ? <Flex justify={"center"} align="center">
            <Text>{maintenanceMessage}</Text>
          </Flex>
          : <></>
      }
    </>
  );
};
