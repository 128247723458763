import { SimpleField } from "@usemorph/morph-dashboard-types";

export const SelectForm = ({
  defaultValue,
  fields,
  onChange,
}: {
  defaultValue?: string[];
  fields: SimpleField[];
  onChange: (format: string[]) => void;
}) => {
  const getDefaultValue = (defaultValue: string[]) => {
    return defaultValue.reduce(
      (acc: { label: string; value: string }[], val: string) => {
        const field = fields.find((field) => field.name === val);
        if (field) {
          return [
            ...acc,
            {
              label: field.displayName ?? field.name,
              value: field.name,
            },
          ];
        } else {
          return acc;
        }
      },
      []
    );
  };

  // const chakraStyles: ChakraStylesConfig = {
  //   dropdownIndicator: (provided, state) => ({
  //     ...provided,
  //     background: state.isFocused ? 'blue.100' : provided.background,
  //     p: 0,
  //     w: '40px',
  //   }),
  //   placeholder: (provided, state) => ({
  //     ...provided,
  //     color: 'gray.500',
  //   }),
  //   option: (provided, state) => ({
  //     ...provided,
  //     _hover: {
  //       color: 'blue.500',
  //       bg: !state.isSelected && 'gray.100',
  //     },
  //   }),
  // };

  return (
    <>
      {/* <MultiSelect
        variant="primary"
        value={
          // defaultValue ? getDefaultValue(defaultValue) : undefined
          defaultValue ?? []
        }
        options={fields.map((field) => {
          return {
            label: field.displayName || field.name,
            value: field.name,
          };
        })}
        onChange={(e) => {
          onChange(e.map((_e) => _e));
        }}
      ></MultiSelect> */}
      {/* <Select
        size="sm"
        isMulti={true}
        name={"select"}
        options={fields.map((field) => {
          return {
            label: field.displayName ?? field.name,
            value: field.name,
          };
        })}
        // chakraStyles={chakraStyles}
        defaultValue={defaultValue ? getDefaultValue(defaultValue) : undefined}
        placeholder={""}
        onChange={(e) => {
          onChange(e.map((_e) => _e.value));
        }}
        closeMenuOnSelect={false}
        selectedOptionStyle="check"
        hideSelectedOptions={false}
      /> */}
    </>
  );
};
