import { ReactNode } from "react";
import { Grid } from "~/components_next/Grid";
import { InputStack } from "~/components_next/InputStack/InputStack";

type MergeKeyInputGridProps = {
  children: ReactNode;
};

export const MergeKeyInputGrid = (props: MergeKeyInputGridProps) => {
  const { children } = props;
  return (
    <InputStack label="When">
      <Grid
        rows="2"
        columns="3"
        gapX="3"
        gapY="1"
        style={{
          gridTemplateColumns: "1fr auto 1fr",
          gridTemplateRows: "auto auto",
          gridAutoFlow: "column",
        }}
      >
        {children}
      </Grid>
    </InputStack>
  );
};
