import { ReactNode, useState } from "react";
import { Box } from "~/components_next/Box";
import { Tabs } from "~/components_next/Tabs";

import { RightSidebarBase } from "../common/RightSidebarBase";

type Tab = "Row" | "Formula" | "Prompt";

type BulkEditRecordsSidebarProps<T> = {
  tabs: readonly T[];
  footers: (tab: T) => ReactNode;
  content: (tab: T) => ReactNode;
};

export const BulkEditRecordsSidebar = <T extends Tab>(
  props: BulkEditRecordsSidebarProps<T>
) => {
  const { tabs, footers, content } = props;

  const [selectedTab, setSelectedTab] = useState<T>(tabs[0]);

  return (
    <RightSidebarBase
      sidebarType="bulkEditRecords"
      title="Bulk Edit Records"
      footer={footers(selectedTab)}
    >
      <Tabs.Root
        value={selectedTab}
        onChange={(tab) => setSelectedTab(tab as T)}
        size="md"
      >
        <Tabs.List>
          {tabs.map((tab) => (
            <Tabs.Trigger key={tab} value={tab}>
              {tab}
            </Tabs.Trigger>
          ))}
        </Tabs.List>
        {tabs.map((tab) => (
          <Tabs.Content key={tab} value={tab}>
            <Box py="3">{content(tab)}</Box>
          </Tabs.Content>
        ))}
      </Tabs.Root>
    </RightSidebarBase>
  );
};
