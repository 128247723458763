import { SimpleField } from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";
import { AllRecordModelValueUnion } from "~/features/RecordModel";
import { createRecordModelValue } from "~/features/RecordModel/utils/convertRecordToRecordModel";

// Kanbanの都合で、uniqueIdentifierはstringになる
// string化されたものを元に戻す処理
// DashboardUserObjectをどうするかが決まってない
const decodeValue = (uniqueIdentifier: string, field: SimpleField) => {
  const parsedValue = match(field.type)
    .with("boolean", (): boolean | null => {
      if (uniqueIdentifier === "true") {
        return true;
      }
      if (uniqueIdentifier === "false") {
        return false;
      }
      if (field.nullable) {
        return null;
      }
      return false;
    })
    .with("multiSelect", () => {
      try {
        const parsed = JSON.parse(uniqueIdentifier);
        if (Array.isArray(parsed)) {
          return parsed;
        }
        if (field.nullable) {
          return null;
        }
        return [];
      } catch (e) {
        if (field.nullable) {
          return null;
        }
        return [];
      }
    })
    .with("array", () => {
      try {
        const parsed = JSON.parse(uniqueIdentifier);
        if (Array.isArray(parsed)) {
          return parsed;
        }
        if (field.nullable) {
          return null;
        }
        return [];
      } catch (e) {
        if (field.nullable) {
          return null;
        }
        return [];
      }
    })
    .with(P.union("number", "autoNumber"), (): number | null => {
      try {
        return parseInt(uniqueIdentifier);
      } catch (e) {
        if (field.nullable) {
          return null;
        }
        return 0;
      }
    })
    .with("decimal", (): number | null => {
      try {
        return parseFloat(uniqueIdentifier);
      } catch (e) {
        if (field.nullable) {
          return null;
        }
        return 0;
      }
    })
    .otherwise(() => uniqueIdentifier);

  return parsedValue;
};

export const getRecordModelValueFromIdentifier = (
  uniqueIdentifier: string,
  field: SimpleField
): AllRecordModelValueUnion => {
  const decodedValue = decodeValue(uniqueIdentifier, field);

  return createRecordModelValue(
    decodedValue,
    field.type,
    field
  ) as AllRecordModelValueUnion;
};
