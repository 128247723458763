


import {
  DashboardRegisterRssTableMappingRequestBody,
  DashboardRssTableMappingResponse,
  DashboardRssFeedItemsResponse,
  DashboardUpdateRssTableMappingRequestBody,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

export type DashboardGeneralResponse = {
  message: string;
};

export default function useApiRssFeed() {
  const { executeRequest } = useApi();

  /**
   * List
   */
  const _listSampleRssFeed = async ({
    teamSlug,
    rssUrl,
  }: {
    teamSlug: string;
    rssUrl: string;
  }): Promise<DashboardRssFeedItemsResponse> => {
    return executeRequest(
      "get",
      `/rss/sample`,
      {
        rssUrl,
      },
      {
        teamSlug,
      },
      undefined
    );
  };

  const _findRssLinkedToTable = async ({
    teamSlug,
    databaseId,
    tableSlug,
  }: {
    teamSlug: string;
    databaseId: string;
    tableSlug: string;
  }): Promise<DashboardRssTableMappingResponse> => {
    return executeRequest<DashboardRssTableMappingResponse>(
      "get",
      `/${databaseId}/rss-table/${tableSlug}`,
      undefined,
      {
        teamSlug,
      },
      {
        databaseId,
        tableSlug,
      }
    );
  };

  const _importRssIntoTable = async ({
    teamSlug,
    databaseId,
    tableSlug,
    requestBody,
  }: {
    teamSlug: string;
    databaseId: string;
    tableSlug: string;
    requestBody: DashboardRegisterRssTableMappingRequestBody
  }): Promise<DashboardRssTableMappingResponse> => {
    return executeRequest<DashboardRssTableMappingResponse>(
      "post",
      `/${databaseId}/rss-table/${tableSlug}`,
      undefined,
      {
        teamSlug,
      },
      {
        ...requestBody,
      },
    );
  };

  const _updateRssTableMapping = async ({
    teamSlug,
    databaseId,
    tableSlug,
    requestBody,
  }: {
    teamSlug: string;
    databaseId: string;
    tableSlug: string;
    requestBody: DashboardUpdateRssTableMappingRequestBody
  }) => {
    return executeRequest<DashboardRssTableMappingResponse>(
      "put",
      `/${databaseId}/rss-table/${tableSlug}`,
      undefined,
      {
        teamSlug,
      },
      {
        ...requestBody,
      },
    );
  }

  const _deleteRssTableMapping = async ({
    teamSlug,
    databaseId,
    tableSlug,
  }: {
    teamSlug: string;
    databaseId: string;
    tableSlug: string;
  }) => {
    return executeRequest<DashboardGeneralResponse>(
      "delete",
      `/${databaseId}/rss-table/${tableSlug}`,
      undefined,
      {
        teamSlug,
      },
      undefined,
    );
  }

  return {
    _listSampleRssFeed,
    _findRssLinkedToTable,
    _importRssIntoTable,
    _updateRssTableMapping,
    _deleteRssTableMapping,
  };
}
