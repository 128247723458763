import { Dispatch, SetStateAction, useState } from "react";

import constate from "constate";

interface UseSqlFromChatAssistantReturn {
  sql: string | null;
  setSql: Dispatch<SetStateAction<string | null>>;
}

const _useSqlFromChatAssistant = (): UseSqlFromChatAssistantReturn => {
  const [sql, setSql] = useState<string | null>(null);

  return { sql, setSql };
};

export const [SqlFromChatAssistantProvider, useSqlFromChatAssistant] = constate(
  _useSqlFromChatAssistant
);
