import { Dispatch, SetStateAction, useCallback } from "react";
import { LuPlus, LuMinus } from "react-icons/lu";
import { IconButton } from "~/components_next/IconButton/IconButton";

type ChangeFontSizeOptions = {
  step?: number; // default: 2
};

type IncreaseFontSizeProps = {
  onChangeFontSize: Dispatch<SetStateAction<number>>;
} & ChangeFontSizeOptions;

const IncreaseFontSize = (props: IncreaseFontSizeProps) => {
  const { onChangeFontSize, step = 2 } = props;

  const handleIncrement = useCallback(() => {
    onChangeFontSize((prev) => prev + step);
  }, [onChangeFontSize, step]);

  return (
    <IconButton
      tooltip="Increase Font Size"
      onClick={handleIncrement}
      size="sm"
      icon={<LuPlus />}
    />
  );
};

type DecreaseFontSizeProps = {
  onChangeFontSize: Dispatch<SetStateAction<number>>;
} & ChangeFontSizeOptions;

const DecreaseFontSize = (props: DecreaseFontSizeProps) => {
  const { onChangeFontSize, step = 2 } = props;

  const handleDecrement = useCallback(() => {
    onChangeFontSize((prev) => Math.max(prev - step, step));
  }, [onChangeFontSize, step]);

  return (
    <IconButton
      tooltip="Decrease font size"
      onClick={handleDecrement}
      size="sm"
      icon={<LuMinus />}
    />
  );
};

export { IncreaseFontSize, DecreaseFontSize };
export type { ChangeFontSizeOptions };
