import useApiPayment from "~/api/useApiPayment";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { paymentKeys } from "../keys";

interface UseDeletePaymentMutationParams {
  teamSlug: string;
}

const useDeletePaymentMutation = ({
  teamSlug,
}: UseDeletePaymentMutationParams) => {
  const { _deletePayment } = useApiPayment();
  const client = useQueryClient();

  return {
    mutationFn: () => {
      return _deletePayment({
        teamSlug,
      });
    },
    onSuccess: () => {
      return invalidateAll(client, paymentKeys.all({ teamSlug }));
    },
  };
};

export { useDeletePaymentMutation };
