import { FieldClientModel } from "~/clientModel/fields/field";
import {
  UpdatableRecordEntryClientModelBaseConstructorParams,
  UpdatableRecordEntryClientModelBase,
} from "../RecordEntryClientModelBase";
import { zValidate, zAllowNullIfNullable } from "../validatorsUtil";
import { z } from "zod";

const imageValueSchema = z.object({ data: z.string(), url: z.string() });

type ImageRecordEntryClientModelData =
  UpdatableRecordEntryClientModelBaseConstructorParams;

type ImageValueType = z.infer<typeof imageValueSchema> | null;

export class ImageRecordEntryClientModel extends UpdatableRecordEntryClientModelBase<ImageValueType> {
  constructor(data: ImageRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "image" {
    return "image";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([imageValueSchema, zAllowNullIfNullable(field)]),
      this.data.rawValue
    );
  }

  public updateValue(
    value: ImageValueType | null
  ): ImageRecordEntryClientModel {
    return new ImageRecordEntryClientModel({
      key: this.data.key,
      rawValue: value,
      meta: this.data.meta,
    });
  }

  public getValidatedDisplayValue = this.getValidatedValue;
}
