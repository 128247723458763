import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { RecordClientModel } from "../record";
import { RecordEntryClientModel } from "../record/recordEntry";
import { RecordIdentifierClientModel } from "../record/recordIdentifier";

type EditingRecordClientModelData = {
  recordBeforeEdit: RecordClientModel;
  editingRecord: RecordClientModel;
};

export class EditingRecordClientModel {
  public constructor(readonly data: EditingRecordClientModelData) {}

  public get editingRecord(): RecordClientModel {
    return this.data.editingRecord;
  }

  public get recordIdentifierBeforeEdit(): RecordIdentifierClientModel {
    return this.data.recordBeforeEdit?.recordIdentifier;
  }

  public selectEditingRecordEntry(key: string): RecordEntryClientModel | null {
    return this.data.editingRecord.selectEntry(key);
  }

  public selectEditingRecordEntryOrThrow(key: string): RecordEntryClientModel {
    return this.data.editingRecord.selectEntryOrThrow(key);
  }

  public updateRecordEntry(
    entry: RecordEntryClientModel
  ): EditingRecordClientModel {
    return new EditingRecordClientModel({
      recordBeforeEdit: this.data.recordBeforeEdit,
      editingRecord: new RecordClientModel({
        entries: this.data.editingRecord.data.entries.map((e) =>
          e.key === entry.key ? entry : e
        ),
        recordIdentifier: this.data.editingRecord.data.recordIdentifier,
      }),
    });
  }

  public isEditingRecordValid(fields: FieldsClientModel): boolean {
    return this.data.editingRecord.data.entries.every((entry) => {
      const field = fields.getFieldByFieldNameOrThrow(entry.key);

      if (field.isAutoGenerated) {
        return true;
      }

      return entry.getValidatedValue(field).isValid;
    });
  }
}
