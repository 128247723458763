import { ReactNode, useMemo } from "react";
import { Tooltip } from "~/components_next/Tooltip";

type InputTooltipProps = {
  isAutoGenerated: boolean;
  children: ReactNode;
};

const InputTooltip = (props: InputTooltipProps) => {
  const { isAutoGenerated, children } = props;
  const content = useMemo(() => {
    if (isAutoGenerated) {
      return "The value is automatically generated";
    }
    return null;
  }, [isAutoGenerated]);

  if (!content) {
    return <>{children}</>;
  }

  return <Tooltip content={content}>{children}</Tooltip>;
};

export { InputTooltip };
