import { Document } from "@tiptap/extension-document";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Text } from "@tiptap/extension-text";
import { Bold } from "@tiptap/extension-bold";
import { Italic } from "@tiptap/extension-italic";
import { Heading } from "@tiptap/extension-heading";
import { Blockquote } from "@tiptap/extension-blockquote";
import { Code } from "@tiptap/extension-code";
import { ListItem } from "@tiptap/extension-list-item";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { Strike } from "@tiptap/extension-strike";
import { CodeBlock } from "@tiptap/extension-code-block";
import { BulletList } from "@tiptap/extension-bullet-list";
import { Highlight } from "@tiptap/extension-highlight";
import { Underline } from "@tiptap/extension-underline";
import { Superscript } from "@tiptap/extension-superscript";
import { Subscript } from "@tiptap/extension-subscript";
import { TextAlign } from "@tiptap/extension-text-align";
import { Dropcursor } from "@tiptap/extension-dropcursor";
import { History } from "@tiptap/extension-history";
import { HorizontalRule } from "@tiptap/extension-horizontal-rule";
import { Color } from "@tiptap/extension-color";
import { TextStyle } from "@tiptap/extension-text-style";
import { Image } from "@tiptap/extension-image";
import { Link } from "@tiptap/extension-link";
import { Typography } from "@tiptap/extension-typography";

import { convertToStyleString, styles } from "../styles";

/**
 * Basic
 */

const CustomDocument = Document;

const CustomHistory = History;

const CustomDropcursor = Dropcursor.configure({
  color: "rgba(59,121,204,0.7)",
  width: 3,
});

/**
 * Nodes
 */

const CustomParagraph = Paragraph.extend({});

const CustomHeading = Heading.extend({});

const CustomListItem = ListItem.extend({});

const CustomOrderedList = OrderedList.extend({
  keepMarks: true,
  keepAttributes: true,
});

const CustomBulletList = BulletList.extend({
  keepMarks: true,
  keepAttributes: true,
});

const CustomHorizontalRule = HorizontalRule.extend({});

const CustomBlockquote = Blockquote.extend({
  addAttributes() {
    return {
      style: {
        renderHTML: () => {
          return {
            ...this.parent?.(),
            style: convertToStyleString(styles.blockquote),
          };
        },
      },
    };
  },
});

const CustomImage = Image.configure({ allowBase64: true, inline: false });

const CustomCodeBlock = CodeBlock.extend({
  addAttributes() {
    return {
      style: {
        renderHTML: () => {
          return {
            ...this.parent?.(),
            style: convertToStyleString(styles.codeBlock),
          };
        },
      },
    };
  },
});

/**
 * Text format
 */

const CustomText = Text;

const CustomBold = Bold;

const CustomItalic = Italic;

const CustomStrike = Strike;

const CustomHighlight = Highlight;

const CustomUnderline = Underline;

const CustomSuperscript = Superscript;

const CustomSubscript = Subscript;

const CustomTextAlign = TextAlign.configure({
  types: ["heading", "paragraph"],
  alignments: ["left", "center", "right"],
  defaultAlignment: "left",
});

const CustomCode = Code.extend({
  addAttributes() {
    return {
      style: {
        renderHTML: () => {
          return {
            ...this.parent?.(),
            style: convertToStyleString(styles.code),
          };
        },
      },
    };
  },
});

const CustomTextStyle = TextStyle;

const CustomColor = Color.extend({ types: ["textStyle"] });

const CustomLink = Link.extend({
  autolink: true,
  linkOnPaste: true,
  inclusive: false,
  openOnClick: false,
});

/**
 * Functionalities
 */

const CustomTypography = Typography;

export const extensions = [
  // basic
  CustomDocument,
  CustomHistory,
  CustomDropcursor,

  // nodes
  CustomParagraph,
  CustomHeading,
  CustomListItem,
  CustomOrderedList,
  CustomBulletList,
  CustomCodeBlock,
  CustomBlockquote,
  CustomHorizontalRule,
  CustomImage,

  // text format
  CustomText,
  CustomTextStyle,
  CustomBold,
  CustomItalic,
  CustomStrike,
  CustomHighlight,
  CustomCode,
  CustomUnderline,
  CustomSuperscript,
  CustomSubscript,
  CustomTextAlign,
  CustomColor,
  CustomLink,

  // functionalities
  CustomTypography,
];

// For Sticky Notes and comments
export const extensionsBasicTexts = [
  // basic
  CustomDocument,
  CustomHistory,
  CustomDropcursor,

  // nodes: No Headings, Codes, Blockquotes, Horizontal Rules, Images
  CustomParagraph,
  CustomListItem,
  CustomOrderedList,
  CustomBulletList,

  // text format
  CustomText,
  CustomTextStyle,
  CustomBold,
  CustomItalic,
  CustomStrike,
  CustomHighlight,
  CustomCode,
  CustomUnderline,
  CustomColor,
  CustomLink,

  // functionalities
  CustomTypography,
];
