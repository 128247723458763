import { FilterConditionUnitClientModel } from "~/clientModel/queryConditions/filterConditions";
import { FilterOperationClientModel } from "~/clientModel/queryConditions/filterConditions/FilterConditionUnit/operations";
import { NativeSimpleSelect } from "~/components_next/Select/NativeSelect/NativeSimpleSelect";

type OperatorInputProps = {
  filterConditionUnit: FilterConditionUnitClientModel;
  onOperationChange: (operation: FilterOperationClientModel) => void;
  isReadOnly: boolean;
};

export const OperatorInput = (props: OperatorInputProps) => {
  const { filterConditionUnit, onOperationChange, isReadOnly } = props;

  const operatorOptions = filterConditionUnit.selectableOperations.map(
    (operation) => {
      return {
        value: operation,
        label: operation.operatorLabel,
      } as const;
    }
  );

  return (
    <NativeSimpleSelect
      variant="primary"
      value={filterConditionUnit.operation}
      getValueStringFromValue={(operation: FilterOperationClientModel) =>
        operation.operatorValue
      }
      size="xs"
      onChange={(value) => onOperationChange(value!)}
      isDisabled={isReadOnly}
      options={operatorOptions}
    />
  );
};
