import { Flex } from "@radix-ui/themes";
import { ForwardedRef, forwardRef, ReactNode } from "react";
import { Text } from "~/components_next/Text";

type BadgeAndTitleProps = {
  title: string;
  badge: ReactNode;
};

const BadgeAndTitle = forwardRef(function BadgeAndTitle(
  props: BadgeAndTitleProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { title, badge } = props;
  return (
    <Flex ref={ref} align="center" height="100%" gap="2">
      {badge}
      <Text>{title}</Text>
    </Flex>
  );
});

export { BadgeAndTitle };
