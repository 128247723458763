import { useCallback, useMemo } from "react";
import { FieldClientModel } from "~/clientModel/fields/field";
import {
  useRecordsTableBaseCoworkersState,
  useRecordsTableBaseOptions,
  useRecordsTableBaseRecords,
  useRecordsTableBaseTableSelection,
} from "../context/ReactTableBaseContext";
import { RecordClientModel } from "~/clientModel/records/record";
import { TableSelectionClientModel } from "~/clientModel/tables/tableMeta/TableSelection";
import { useTableSelectionUtil } from "../common/useTableSelectionUtil";
import { mergeFieldIndicatorStyles } from "../mergeFieldIndicator/mergeFieldIndicatorStyles";

const gridBorderStyles = {
  borderBottom: "1px solid $border",
  borderRight: "1px solid $border",
} as const;

const useTableCellBorderStyles = ({
  record,
  field,
}: {
  record: RecordClientModel;
  field: FieldClientModel;
}) => {
  const tableSelection = useRecordsTableBaseTableSelection();
  const coworkersState = useRecordsTableBaseCoworkersState();

  const { showMergeFieldIndicator: showMergeFieldIndicatorOption } =
    useRecordsTableBaseOptions();

  const records = useRecordsTableBaseRecords();

  const {
    isUpperCellSelected,
    isDownerCellSelected,
    isLeftCellSelected,
    isRightCellSelected,
  } = useTableSelectionUtil();

  const getSelectionBorderStyles = useCallback(
    (tableSelection: TableSelectionClientModel, borderColor: string) => {
      const isCellSelected = tableSelection.isCellSelected({
        recordIdentifier: record.recordIdentifier,
        fieldName: field.name,
      });

      if (!isCellSelected) {
        return undefined;
      }

      const selectionBorderStyle = `2px solid ${borderColor}`;

      const _isUpperRecordSelected = isUpperCellSelected({
        tableSelection,
        record,
        field,
      });

      const _isDownerRecordSelected = isDownerCellSelected({
        tableSelection,
        record,
        field,
      });

      const _isLeftFieldSelected = isLeftCellSelected({
        tableSelection,
        record,
        field,
      });

      const _isRightFieldSelected = isRightCellSelected({
        tableSelection,
        record,
        field,
      });

      const borderTop = _isUpperRecordSelected
        ? undefined
        : selectionBorderStyle;

      const borderBottom = _isDownerRecordSelected
        ? undefined
        : selectionBorderStyle;

      const borderLeft = _isLeftFieldSelected
        ? undefined
        : selectionBorderStyle;

      const borderRight = _isRightFieldSelected
        ? undefined
        : selectionBorderStyle;

      return {
        "&::after": {
          content: "",
          position: "absolute",
          pointerEvents: "none",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          borderTop,
          borderBottom,
          borderLeft,
          borderRight,
        },
      };
    },
    [
      field,
      isDownerCellSelected,
      isLeftCellSelected,
      isRightCellSelected,
      isUpperCellSelected,
      record,
    ]
  );

  const getMergeFieldIndicatorStyles = useCallback(() => {
    const showMergeFieldIndicator =
      showMergeFieldIndicatorOption && field.isMergeField;

    if (!showMergeFieldIndicator) {
      return undefined;
    }

    const isLastRecord = !records.getDownerRecord(record.recordIdentifier);

    return {
      "&::after": {
        content: "",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        borderLeft: mergeFieldIndicatorStyles.border,
        borderRight: mergeFieldIndicatorStyles.border,
        borderBottom: isLastRecord
          ? mergeFieldIndicatorStyles.border
          : undefined,
      },
    };
  }, [
    showMergeFieldIndicatorOption,
    field.isMergeField,
    records,
    record.recordIdentifier,
  ]);

  const borderStyles = useMemo(() => {
    const mergeFieldIndicatorStyles = getMergeFieldIndicatorStyles();

    const selfSelectionBorderStyles = getSelectionBorderStyles(
      tableSelection,
      "$slate8"
    );

    const coworkerSelectionBorderStylesArray =
      coworkersState?.coworkersState.map((coworkerState) =>
        getSelectionBorderStyles(
          coworkerState.tableSelection,
          `$${coworkerState.user.color.colorScheme}7`
        )
      );

    const coworkerSelectionBorderStyles =
      coworkerSelectionBorderStylesArray?.reduce((styles, currentStyles) => {
        return {
          ...currentStyles,
          ...styles,
        };
      }, {} as Record<string, unknown>);

    return {
      ...gridBorderStyles,
      ...mergeFieldIndicatorStyles,
      ...selfSelectionBorderStyles,
      ...coworkerSelectionBorderStyles,
    };
  }, [
    getMergeFieldIndicatorStyles,
    getSelectionBorderStyles,
    tableSelection,
    coworkersState?.coworkersState,
  ]);

  return borderStyles;
};

export { useTableCellBorderStyles };
