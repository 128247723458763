import { UseExecutable } from "~/clientModel/executable";
import { FieldClientModel } from "~/clientModel/fields/field";
import { Loadable } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { FooterButtonBase } from "../../common/FooterButtonBase";

type BulkEditRecordsByFormulaFooterProps = {
  formula: string;
  targetFieldLoadable: Loadable<FieldClientModel, unknown>;
  useBulkEditRecordsByFormulaExecutable: UseExecutable<
    void,
    {
      formula: string;
      field: FieldClientModel;
    },
    unknown,
    unknown
  >;
};

export const BulkEditRecordsByFormulaFooter = (
  props: BulkEditRecordsByFormulaFooterProps
) => {
  const {
    formula,
    useBulkEditRecordsByFormulaExecutable,
    targetFieldLoadable,
  } = props;

  const bulkEditRecordsByFormulaExecutable =
    useBulkEditRecordsByFormulaExecutable();

  return (
    <WithFallback loadables={[targetFieldLoadable] as const}>
      {([targetField]) => (
        <FooterButtonBase
          isDisabled={!formula}
          isLoading={bulkEditRecordsByFormulaExecutable.isExecuting}
          onClick={() =>
            bulkEditRecordsByFormulaExecutable.execute({
              formula,
              field: targetField,
            })
          }
        >
          Update All
        </FooterButtonBase>
      )}
    </WithFallback>
  );
};
