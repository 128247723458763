import { FieldClientModel } from "~/clientModel/fields/field";
import { Checkbox } from "~/components_next/Checkbox";

type IsPrimaryInputProps = {
  field: FieldClientModel;
  onFieldChange: (field: FieldClientModel) => void;
  isReadOnly?: boolean;
};

export const IsPrimaryInput = (props: IsPrimaryInputProps) => {
  const { field, onFieldChange, isReadOnly } = props;

  return (
    <Checkbox
      label="Primary key"
      size="lg"
      isReadOnly={isReadOnly}
      value={field.isPrimary}
      onChange={(isPrimary) => onFieldChange(field.updateIsPrimary(isPrimary))}
    />
  );
};
