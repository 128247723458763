import { Box, Flex } from "@radix-ui/themes";
import { SimpleField, ViewType } from "@usemorph/morph-dashboard-types";
import { match } from "ts-pattern";
import { HintPopover } from "~/components_next/Hint";
import { Input } from "~/components_next/Input";
import { Text } from "~/components_next/Text";
import { Checkbox } from "~/components_next/Checkbox";
import { FieldSelect } from "~/features/Fields/Form/FieldSelect";
import { ViewTypeSelect } from "./ViewTypeSelect/ViewTypeSelect";

/**
 * Formの状態の型
 */
type CommonViewMetaFormState = {
  name: string;
  isPrivate: boolean;
};

type TableViewMetaFormState = CommonViewMetaFormState & {
  type: "table";
};

type KanbanViewMetaFormState = CommonViewMetaFormState & {
  type: "kanban";
  groupedByField: SimpleField;
  reorderable: boolean;
};

type ViewMetaFormState = TableViewMetaFormState | KanbanViewMetaFormState;

type ViewMetaFormProps = {
  onChange: (value: ViewMetaFormState) => void;
  value: ViewMetaFormState;
  groupByFieldOptions: [SimpleField, ...SimpleField[]];
};
const ViewMetaForm = (props: ViewMetaFormProps) => {
  const { value, onChange, groupByFieldOptions } = props;

  const handleViewTypeChange = (type: ViewType) => {
    match(type)
      .with("table", (type) => onChange({ ...value, type }))
      .with("kanban", (type) =>
        onChange({
          ...value,
          type,
          groupedByField: groupByFieldOptions[0],
          reorderable: false,
        })
      )
      .exhaustive();
  };

  return (
    <Flex direction="column" gap="2">
      <Input
        variant="primary"
        label="Name"
        value={value.name}
        onChange={(event) => onChange({ ...value, name: event.target.value })}
      />
      <ViewTypeSelect value={value.type} onChange={handleViewTypeChange} />
      {value.type === "kanban" && (
        <>
          <Box>
            <Text mb="1">Grouped By</Text>
            <FieldSelect
              value={value.groupedByField}
              onChange={(groupedByField) =>
                onChange({ ...value, groupedByField })
              }
              fields={groupByFieldOptions}
              size="sm"
            />
          </Box>
          <Flex align="center" gap="4">
            <Checkbox
              value={value.reorderable}
              onChange={(reorderable) => onChange({ ...value, reorderable })}
              label="Allow Reorder Records"
            />

            <HintPopover title="Record Reordering">
              Reordering is not possible when a sort condition exists or
              grouping field is not updatable.If reordering is enabled, sorting
              from the View is not possible.
            </HintPopover>
          </Flex>
        </>
      )}
      <Checkbox
        value={value.isPrivate}
        onChange={(isPrivate) => onChange({ ...value, isPrivate })}
        label="Private"
      />
    </Flex>
  );
};

export { ViewMetaForm, type ViewMetaFormState, type TableViewMetaFormState };
