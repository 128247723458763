import { dataApiKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiDataApi from "~/api/useApiDataApi";

type UseFindDataApiQueryParams = {
  databaseId: string;
  teamSlug: string;
  tableSlug: string;
  apiKey: string;
};

const useFindDataApiQuery = ({
  teamSlug,
  databaseId,
  tableSlug,
  apiKey,
}: UseFindDataApiQueryParams) => {
  const { _findDataApi } = useApiDataApi();

  const client = useQueryClient();
  client.setQueryDefaults(
    dataApiKeys.findDataApi({
      teamSlug,
      databaseId,
      tableSlug,
      apiKey,
    }),
    commonQueryOptions
  );

  return {
    queryKey: dataApiKeys.findDataApi({
      teamSlug,
      databaseId,
      tableSlug,
      apiKey,
    }),
    queryFn: () => {
      return _findDataApi({
        teamSlug,
        databaseId,
        tableSlug,
        apiKey,
      });
    },
  };
};

export { useFindDataApiQuery };
