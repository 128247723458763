import { CodePreview } from "../components/CodePreview";
import { RecordValueInputCommonProps } from "../../../type";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { useDisclosure } from "~/hooks/useDisclosure";
import { JSONEditDialog } from "./JSONEditDialog";

export const JSONInput = (props: RecordValueInputCommonProps<"json">) => {
  const { value, onChange, errorMessages, isReadOnly, size, isNullable } =
    props;

  const { isOpen, onOpen, setIsOpen } = useDisclosure();

  return (
    <>
      <InputStack errorMessage={errorMessages}>
        <CodePreview
          value={value}
          isEditDisabled={isReadOnly}
          onEditClick={onOpen}
          size={size}
        />
      </InputStack>

      {/* modal */}
      <JSONEditDialog
        value={value || null}
        onChange={onChange}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        isReadonly={isReadOnly}
      />
    </>
  );
};
