import { simpleFieldsKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiFields from "~/api/useApiField";

type UseListComputedFieldOptionsForSmartFunctionQueryParams = {
  teamSlug: string;
};

const useListComputedFieldOptionsForSmartFunctionQuery = ({
  teamSlug,
}: UseListComputedFieldOptionsForSmartFunctionQueryParams) => {
  const { _listComputedFieldOptionsForSmartFunction } = useApiFields();

  const client = useQueryClient();

  client.setQueryDefaults(
    simpleFieldsKeys.listComputedFieldOptionsForSmartFunction(),
    commonQueryOptions
  );

  return {
    // 常にキャッシュする
    queryKey: simpleFieldsKeys.listComputedFieldOptionsForSmartFunction(),
    queryFn: () => {
      return _listComputedFieldOptionsForSmartFunction({
        teamSlug,
      });
    },
  };
};

export { useListComputedFieldOptionsForSmartFunctionQuery };
