import { match, P } from "ts-pattern";
import {
  CanvasCellVariablesClientModel,
  CanvasCellPromptClientModel,
  CanvasCellRichTextClientModel,
  CanvasCellViewClientModel,
} from "~/clientModel/canvas/CanvasCellClientModel";
import { DashboardCellClientModel } from "~/clientModel/canvas/DashboardCellClientModel";
import { Flex } from "~/components_next/Flex";
import { HTMLRichTextEditor } from "~/components_next/RichTextEditor";
import { CanvasCellPromptUnits } from "../../cellContent/prompt/CanvasCellPromptUnits";
import { CanvasCellVariables } from "../../cellContent/variable/CanvasCellVariables";
import { DashboardPageCellDragHandle } from "./DashboardPageCellDragHandle";
import { DashboardPageCellOptions } from "./DashboardPageCellOptions";
import { DashboardPageViewCell } from "./view/DashboardPageViewCell";
import { useRef } from "react";
import { useUseVariableOptionsLoadable } from "../../common/CanvasVariableOptionsLoadableProvider";
import { Executable } from "~/clientModel/executable";

type DashboardPageCellProps = {
  cellClientModel: DashboardCellClientModel;
  removeCellExecutable: Executable<string, unknown>;
  isPublic?: boolean;
  isSelected?: boolean;
};

const DashboardPageCell = (props: DashboardPageCellProps) => {
  const {
    cellClientModel,
    isPublic = false,
    isSelected = false,
    removeCellExecutable,
  } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleIframeClick = () => {
    wrapperRef.current?.click();
  };

  const useVariableOptionsLoadable = useUseVariableOptionsLoadable();

  return (
    <Flex
      direction="column"
      gap="2"
      height="100%"
      css={{ position: "relative" }}
      ref={wrapperRef}
    >
      {match(cellClientModel.cell)
        .with(
          P.instanceOf(CanvasCellPromptClientModel),
          (promptCell: CanvasCellPromptClientModel) => {
            return (
              <CanvasCellPromptUnits
                promptCellClientModel={promptCell}
                isSelected={true}
                onSelect={handleIframeClick}
              />
            );
          }
        )
        .with(
          P.instanceOf(CanvasCellVariablesClientModel),
          (variableCell: CanvasCellVariablesClientModel) => {
            return (
              <CanvasCellVariables
                variableCellClientModel={variableCell}
                useVariableOptionsLoadable={useVariableOptionsLoadable}
              />
            );
          }
        )
        .with(
          P.instanceOf(CanvasCellRichTextClientModel),
          (richTextCell: CanvasCellRichTextClientModel) => {
            return (
              <HTMLRichTextEditor
                html={richTextCell.html || ""}
                isReadOnly
                height="100%"
                hideTopbar
              />
            );
          }
        )
        .with(
          P.instanceOf(CanvasCellViewClientModel),
          (viewCell: CanvasCellViewClientModel) => {
            return <DashboardPageViewCell viewCellInstance={viewCell} />;
          }
        )
        .otherwise(() => (
          <></>
        ))}

      <DashboardPageCellDragHandle isVisible={!isPublic && isSelected} />
      <DashboardPageCellOptions
        cellClientModel={cellClientModel}
        removeCellExecutable={removeCellExecutable}
        isVisible={!isPublic && isSelected}
      />
    </Flex>
  );
};

export { DashboardPageCell };
