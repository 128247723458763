type GetResourceDependencies = {
  teamSlug: string;
  path?: string;
};

const resourcesKeys = {
  all: () => ["resources"] as const,

  /**
   * get resource
   */
  allGetResource: () => ["getResource"] as const,
  getResource: ({ teamSlug, path }: GetResourceDependencies) =>
    [
      ...resourcesKeys.allGetResource(),
      {
        teamSlug,
        path,
      },
    ] as const,
};

export { resourcesKeys };
