import { RecordIdentifierClientModel } from "~/clientModel/records/record/recordIdentifier";
import { TableSelectionClientModel } from "./TableSelectionClientModel";
import { RecordIdentifierSnapshot, TableSelectionSnapshotJSON } from "./type";

export class TableSelectionClientModelDefactory {
  public static serialize(tableSelection: TableSelectionClientModel): string {
    const { cellSelectionState, editingCellState, recordSelectionState } =
      tableSelection.data;
    const data: TableSelectionSnapshotJSON = {
      cellSelection: {
        recordIdentifiers:
          TableSelectionClientModelDefactory.serializeRecordIdentifiers(
            cellSelectionState.data.recordIdentifiers
          ),
        fieldNames: cellSelectionState.data.fieldNames,
      },
      recordSelection: {
        recordIdentifiers:
          TableSelectionClientModelDefactory.serializeRecordIdentifiers(
            recordSelectionState.data.recordIdentifiers
          ),
      },
    };

    return JSON.stringify(data);
  }

  private static serializeRecordIdentifier(
    recordIdentifier: RecordIdentifierClientModel
  ): RecordIdentifierSnapshot {
    return recordIdentifier.data;
  }

  private static serializeRecordIdentifiers(
    recordIdentifiers: RecordIdentifierClientModel[]
  ): RecordIdentifierSnapshot[] {
    return recordIdentifiers.map(this.serializeRecordIdentifier);
  }
}
