import useApiRecord from "~/api/useApiRecord";

// types
import { RecordFilterCondition } from "@usemorph/morph-dashboard-types";

interface UseStartBulkUpdateRecordByPromptMutationParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
}

const useStartBulkUpdateRecordByPromptMutation = ({
  databaseId,
  tableSlug,
  teamSlug,
}: UseStartBulkUpdateRecordByPromptMutationParams) => {
  const { _startBulkUpdateRecordByPrompt } = useApiRecord();

  return {
    mutationFn: ({
      prompt,
      filter,
      fieldName,
    }: {
      prompt: string;
      filter: RecordFilterCondition;
      fieldName: string;
    }) => {
      return _startBulkUpdateRecordByPrompt({
        teamSlug,
        databaseId,
        tableSlug,
        prompt,
        fieldName,
        filter,
      });
    },
    onSuccess: () => {
      // todo: 更新は非同期に行われる。ユーザーフィードバックについて考える。
    },
  };
};

export { useStartBulkUpdateRecordByPromptMutation };
