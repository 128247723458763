import { type ForwardedRef, forwardRef, useMemo } from "react";

import { SelectRootProps } from "../Select/Select";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { styled } from "~/stitches";

type GetValueStringFromValue<ValueType> = (option: ValueType) => string;

// ValueTypeがstring | nullの場合は、getValueStringFromValueを省略できる
type GetValueStringFromValueProps<ValueType> = ValueType extends string
  ? { getValueStringFromValue?: GetValueStringFromValue<ValueType> }
  : { getValueStringFromValue: GetValueStringFromValue<ValueType> };

type OptionType<ValueType> = { label: string; value: ValueType };

type SimpleSelectProps<ValueType> = Pick<
  SelectRootProps,
  | "variant"
  | "size"
  | "description"
  | "label"
  | "errorMessages"
  | "isDisabled"
  | "isClearable"
  | "isReadOnly"
> & {
  value: ValueType | null;
  onChange?: (value: ValueType | null) => void;
  onOpenChange?: (open: boolean) => void;
  options: readonly OptionType<ValueType>[];
} & GetValueStringFromValueProps<ValueType>;

const NativeSimpleSelectElement = styled("select", {
  variants: {
    size: {
      xs: {
        fontSize: "var(--font-size-1)",
        lineHeight: "var(--line-height-1)",
        padding: "var(--space-1)",
        borderRadius: "var(--radius-2)",
      },
      sm: {
        fontSize: "var(--font-size-2)",
        lineHeight: "var(--line-height-2)",
        padding: "6px",
        borderRadius: "var(--radius-3)",
      },
      md: {
        fontSize: "var(--font-size-3)",
        lineHeight: "var(--line-height-3)",
        padding: "var(--space-3)",
        borderRadius: "var(--radius-4)",
      },
      lg: {
        fontSize: "var(--font-size-4)",
        lineHeight: "var(--line-height-4)",
        padding: "var(--space-4)",
        borderRadius: "var(--radius-4)",
      },
      xl: {
        fontSize: "var(--font-size-5)",
        lineHeight: "var(--line-height-5)",
        padding: "var(--space-5)",
        borderRadius: "var(--radius-5)",
      },
    },
    variant: {
      primary: {
        border: "1px solid $border2",
      },
      supplementary: {
        backgroundColor: "$bg2",
      },
    },
  },
});

const _NativeSimpleSelect = <ValueType,>(
  props: SimpleSelectProps<ValueType>,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const {
    variant,
    size,
    options,
    getValueStringFromValue,
    value,
    onChange,
    onOpenChange,
    label,
    description,
    errorMessages,
    isDisabled = false,
    isReadOnly = false,
    isClearable = false,
  } = props;

  const _getValueStringFromValue: (value: ValueType) => string = useMemo(() => {
    if (getValueStringFromValue) {
      return getValueStringFromValue;
    }
    return (value: ValueType) => value as string;
  }, [getValueStringFromValue]);

  const _getValueStringFromOption: (option: OptionType<ValueType>) => string =
    useMemo(() => {
      if (getValueStringFromValue) {
        return (option: OptionType<ValueType>) =>
          getValueStringFromValue(option.value);
      }
      return (option: OptionType<ValueType>) => option.value as string;
    }, [getValueStringFromValue]);

  const handleOnChange = (value: string | null) => {
    if (!onChange) return;

    if (value === null) {
      onChange(null);
      return;
    }

    const selectedOption = options.find(
      (option) => value === _getValueStringFromOption(option)
    ) as OptionType<ValueType>;
    onChange(selectedOption.value);
  };

  return (
    <InputStack
      errorMessage={errorMessages}
      label={label}
      description={description}
      size={size}
    >
      <NativeSimpleSelectElement
        disabled={isDisabled}
        value={value ? _getValueStringFromValue(value) : undefined}
        onChange={(e) => handleOnChange(e.target.value)}
        size={size}
        variant={variant}
      >
        {options.map((option) => {
          return (
            <option
              key={_getValueStringFromOption(option)}
              value={_getValueStringFromOption(option)}
              selected={option.value === value}
            >
              {option.label}
            </option>
          );
        })}
      </NativeSimpleSelectElement>
    </InputStack>
  );
};

// ここのキャストは仕方がない
// ref
const NativeSimpleSelect = forwardRef(_NativeSimpleSelect) as <ValueType>(
  props: SimpleSelectProps<ValueType> & {
    ref?: ForwardedRef<HTMLButtonElement>;
  }
) => JSX.Element;

export { NativeSimpleSelect, type SimpleSelectProps };
