import { CanvasContainer } from "~/containers/canvas/CanvasContainer";
import { useNotebookId } from "~/utilHooks/useNotebookId";

const CanvasPage = () => {
  return (
    <>
      <CanvasContainer />
    </>
  );
};

const CanvasPageWithKey = () => {
  const notebookId = useNotebookId();
  return <CanvasPage key={notebookId} />;
};

export { CanvasPageWithKey as CanvasPage };
