import { tableHistoriesKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiTable from "~/api/useApiTable";

interface UseListTableHistoryQueryParams {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  limit?: number;
  skip?: number;
}

const useListTableHistoryQuery = ({
  databaseId,
  teamSlug,
  tableSlug,
  limit,
  skip,
}: UseListTableHistoryQueryParams) => {
  const { _getTableHistory } = useApiTable();

  const client = useQueryClient();
  client.setQueryDefaults(
    tableHistoriesKeys.listTableHistory({
      databaseId,
      teamSlug,
      tableSlug,
      skip,
      limit,
    }),
    commonQueryOptions
  );

  return {
    queryKey: tableHistoriesKeys.listTableHistory({
      databaseId,
      teamSlug,
      tableSlug,
      limit,
      skip,
    }),
    queryFn: () => {
      return _getTableHistory({
        databaseId,
        teamSlug,
        tableSlug,
        limit,
        skip,
      });
    },
  };
};

export { useListTableHistoryQuery };
