import { QueryClient } from "react-query";
import { removeTrailingUndefined } from "./removeTrailingUndefined";

/**
 * 複数のkeyをPromise.allでinvalidateする
 */
const invalidateAll = (
  client: QueryClient,
  ...keys: Array<readonly unknown[]>
) => {
  return Promise.all(
    keys.map((key) => {
      client.invalidateQueries({
        queryKey: removeTrailingUndefined(key),
      });
    })
  );
};

export { invalidateAll };
