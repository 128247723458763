import constate from "constate";
import { useState } from "react";

/**
 * Variablesは、Providerをどこに置くかで値の共有スコープを変えられるので、共有パーツとしてここに置く
 */

export type CanvasVariablesValue = {
  name: string;
  value: unknown;
  cellId: string;
};

const useCanvasVariablesContext = () => {
  const [variableValues, setVariableValues] = useState<CanvasVariablesValue[]>(
    []
  );

  return {
    variableValues,
    setVariableValues,
  };
};

export const [
  CanvasVariablesProvider,
  useCanvasVariablesValues,
  useSetCanvasVariablesValues,
] = constate(
  useCanvasVariablesContext,
  (value) => value.variableValues,
  (value) => value.setVariableValues
);
