import { useMutation } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModelDefactory } from "~/clientModel/fields/field";

import { useErrorToast } from "~/components_next/Error";
import { useUpdateSimpleFieldMutation } from "~/serverStateStore";

type UseUpdateMergeKeyExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useUpdateMergeKeyExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseUpdateMergeKeyExecutableParams): Executable<
  {
    fields: FieldsClientModel;
    prevFields: FieldsClientModel;
  },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  const updateFieldOptions = useUpdateSimpleFieldMutation({
    teamSlug,
    databaseId,
    tableSlug,
  });

  const { mutateAsync: updateField } = useMutation(
    useUpdateSimpleFieldMutation({
      teamSlug,
      databaseId,
      tableSlug,
    })
  );

  return useComposeExecutable(
    useMutation({
      ...updateFieldOptions,
      mutationFn: async ({
        fields,
        prevFields,
      }: {
        fields: FieldsClientModel;
        prevFields: FieldsClientModel;
      }) => {
        if (!fields.mergeKeyField) {
          throw new Error("Merge key not found");
        }

        const prevMergeKeyField = prevFields.mergeKeyField;
        if (
          prevMergeKeyField &&
          prevMergeKeyField.name !== fields.mergeKeyField.name
        ) {
          await updateField({
            requestBody: {
              ...FieldClientModelDefactory.toSimpleField(
                prevFields.mergeKeyField
              ),
              mergeSource: undefined,
            },
          });
        }

        return updateFieldOptions.mutationFn({
          requestBody: FieldClientModelDefactory.toSimpleField(
            fields.mergeKeyField
          ),
        });
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseUpdateMergeKeyExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseUpdateMergeKeyExecutableParams) => {
  return () => useUpdateMergeKeyExecutable({ teamSlug, databaseId, tableSlug });
};
