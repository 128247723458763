import { useCallback } from "react";
import { useSetFilterCondition } from "../../../states/filterCondition";
import { useSetSortCondition } from "../../../states/sortCondition";
import { ClearAllFiltersAndSortsButton } from "../../../../common/components/QueryMode/Buttons/ClearAllFiltersAndSortsButton";
import { useViewId } from "~/utilHooks/useViewId";
import { useSetQueryMode } from "../../../states/queryMode";

const TableViewClearFiltersAndSortsButton = () => {
  /**
   * global states from recoil
   */
  const viewId = useViewId();
  const setFilterCondition = useSetFilterCondition(viewId);
  const setSortCondition = useSetSortCondition(viewId);
  const setQueryMode = useSetQueryMode(viewId);

  /**
   * handlers
   */

  const handleClickClearAllFiltersAndSortsButton = useCallback(() => {
    setFilterCondition(null);
    setSortCondition(null);
    setQueryMode(null);
  }, [setFilterCondition, setSortCondition, setQueryMode]);

  return (
    <ClearAllFiltersAndSortsButton
      onClick={handleClickClearAllFiltersAndSortsButton}
    />
  );
};

export { TableViewClearFiltersAndSortsButton };
