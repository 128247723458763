import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Button } from "~/components_next/Button";
import { Flex } from "~/components_next/Flex";
import { Input } from "~/components_next/Input/Input";
import { Spacer } from "~/components_next/Spacer";
import { Switch } from "~/components_next/Switch";
import { Text } from "~/components_next/Text";
import { getPath } from "~/routing";
import {
  useFindViewQuery,
  useUpdateViewMutation,
} from "~/serverStateStore/views";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useViewId } from "~/utilHooks/useViewId";

type ViewToolbarPublishProps = {
  isPublished: boolean;
  onIsPublishedChange: (isPublished: boolean) => void;
  publicApiKey?: string | null;
};

const ViewToolbarPublishWrapper = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  const { data: viewData } = useQuery(
    useFindViewQuery({
      teamSlug,
      databaseId,
      viewId,
    })
  );

  // 手続的な更新リクエスト用 = isPublicの更新
  const { mutateAsync: updateView } = useMutation({
    ...useUpdateViewMutation({
      databaseId,
      teamSlug,
      viewId,
    }),
  });

  const handleIsPublishedChange = (isPublished: boolean) => {
    if (!viewData) return;
    //
    updateView({
      ...viewData,
      isPublic: isPublished,
    });
    return;
  };

  if (!viewData) return null;

  return (
    <ViewToolbarPublish
      // isPublished={viewData?.isPrivate}
      isPublished={false}
      onIsPublishedChange={handleIsPublishedChange}
      // publicApiKey={viewData?.publicApiKey}
    />
  );
};

const ViewToolbarPublish = (props: ViewToolbarPublishProps) => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  const { isPublished, onIsPublishedChange, publicApiKey } = props;

  const [isPublishedLocal, setIsPublishedLocal] = useState(isPublished);

  const handleIsPublishedChange = () => {
    setIsPublishedLocal((prev) => !prev);
    onIsPublishedChange(!isPublishedLocal);
  };

  const publishedUrl = publicApiKey
    ? `${location.origin}${getPath("publishedView", {
        teamSlug,
        databaseId,
        viewId,
        publicApiKey,
      })}`
    : "";

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(publishedUrl);
  };

  return (
    <>
      <Flex direction="column" width="100%" p="2" gap="2">
        <Flex align="center" width="100%" gap="5">
          <Flex direction="column">
            <Text fontWeight="medium">Publish</Text>
            <Text variant="description">
              You can share this view via URL with publishing it.
            </Text>
          </Flex>
          <Spacer />
          <Switch
            size="lg"
            isChecked={isPublishedLocal}
            onCheckedChange={() => handleIsPublishedChange()}
          />
        </Flex>
        {isPublished && publicApiKey && (
          <Input
            variant="primary"
            readOnly
            value={publishedUrl}
            rightSlot={
              <Button
                size="xs"
                variant="tertiary"
                onClick={handleCopyToClipboard}
              >
                Copy
              </Button>
            }
          ></Input>
        )}
      </Flex>
    </>
  );
};

export { ViewToolbarPublishWrapper as ViewToolbarPublish };
