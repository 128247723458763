import {
  DashboardPaymentCustomerPortalSessionResponse,
  DashboardPaymentResponse, DashboardPaymentSessionResponse,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

export default function useApiPayment() {
  const { executeRequest } = useApi();

  const _findPayment = async ({
    teamSlug,
  }: {
    teamSlug: string;
  }): Promise<DashboardPaymentResponse> => {
    return executeRequest<DashboardPaymentResponse>(
      "get",
      `/payment`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  // unsubscribe
  const _deletePayment = async ({
    teamSlug,
  }: {
    teamSlug: string;
  }): Promise<DashboardPaymentResponse> => {
    return executeRequest<DashboardPaymentResponse>(
      "delete",
      `/payment`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  const _createSubscriptionSession = async ({
    teamSlug,
    planId,
    redirectUrl,
  }: {
    teamSlug: string;
    planId: string;
    redirectUrl: string;
  }): Promise<DashboardPaymentSessionResponse> => {
    return executeRequest<DashboardPaymentSessionResponse>(
      "post",
      `/payment/session`,
      undefined,
      {
        teamSlug,
      },
      {
        planId,
        redirectUrl,
      }
    );
  };

  const _createCustomerPortalSession = async ({
    teamSlug,
    redirectUrl,
  }: {
    teamSlug: string;
    redirectUrl: string;
  }): Promise<DashboardPaymentCustomerPortalSessionResponse> => {
    return executeRequest<DashboardPaymentCustomerPortalSessionResponse>(
      "post",
      `/payment/customer-session`,
      undefined,
      {
        teamSlug,
      },
      {
        redirectUrl,
      },
    );
  };

  const _upgradePlan = async ({
    teamSlug,
    planId,
  }: {
    teamSlug: string;
    planId: string;
  }): Promise<DashboardPaymentResponse> => {
    return executeRequest<DashboardPaymentResponse>(
      "put",
      `/payment/plan`,
      undefined,
      {
        teamSlug,
      },
      {
        planId,
      }
    );
  };

  const _registerTrial = async ({
    teamSlug,
    versionId,
  }: {
    teamSlug: string;
    versionId: string;
  }): Promise<DashboardPaymentResponse> => {
    return executeRequest<DashboardPaymentResponse>(
      "post",
      `/payment/trial`,
      undefined,
      {
        teamSlug,
      },
      {
        versionId,
      }
    );
  };

  return {
    _findPayment,
    _deletePayment,
    _createSubscriptionSession,
    _createCustomerPortalSession,
    _upgradePlan,
    _registerTrial,
  };
}
