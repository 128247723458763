import { DashboardViewConditionObject } from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";
import { FieldsClientModel } from "~/clientModel/fields";
import { SortConditionsClientModel } from "./SortConditionsClientModel";
import { SortConditionUnitClientModelFactory } from "./SortConditionUnit/SortConditionUnitClientModelFactry";

export class SortConditionsClientModelFactory {
  public static createEmptySortConditions(): SortConditionsClientModel {
    return new SortConditionsClientModel({
      sortConditionUnits: [],
    });
  }

  public static fromRecordSortCondition(
    sortCondition: DashboardViewConditionObject["sort"],
    fields: FieldsClientModel
  ): SortConditionsClientModel {
    if (!sortCondition) {
      return SortConditionsClientModelFactory.createEmptySortConditions();
    }
    const plainSortCondition = sortCondition?.filter(
      (
        sortConditionUnit
      ): sortConditionUnit is {
        key: string;
        direction: "ascending" | "descending";
      } => {
        return match(sortConditionUnit)
          .with(
            { key: P.string, direction: P.union("ascending", "descending") },
            () => true
          )
          .otherwise(() => false);
      }
    );

    return new SortConditionsClientModel({
      sortConditionUnits: plainSortCondition.map((sortConditionUnit) =>
        SortConditionUnitClientModelFactory.fromSortConditionUnit(
          sortConditionUnit,
          fields
        )
      ),
    });
  }
}
