import {
  DashboardPageDetailResponse,
  DashboardPagePublicDetailResponse,
} from "@usemorph/morph-dashboard-types";
import { FindDashboardPageClientModel } from "./FindDashboardPageClientModel";

export class FindDashboardPageClientModelFactory {
  static fromDashboardDetailResponse(
    dashboardDetailResponse: DashboardPageDetailResponse
  ) {
    return new FindDashboardPageClientModel(dashboardDetailResponse);
  }

  static fromPublicDashboardPageResponse(
    publicDashboardPageResponse: DashboardPagePublicDetailResponse
  ) {
    return new FindDashboardPageClientModel({
      ...publicDashboardPageResponse,
      isPrivate: false,
      isPublic: true,
      notebookId: "unknown",
    });
  }
}
