import { TablesClientModel } from "~/clientModel/tables";
import { SimpleSelect } from "~/components_next/Select";

type TargetTableInputProps = {
  value: string | null;
  onChange: (targetTableSlug: string) => void;
  tables: TablesClientModel;
  isReadOnly?: boolean;
};

export const TargetTableInput = (props: TargetTableInputProps) => {
  const { value, onChange, tables, isReadOnly } = props;

  const options = tables.allTables.map((table) => ({
    value: table,
    label: table.label,
  }));

  return (
    <SimpleSelect
      variant="primary"
      size="sm"
      label="Get value from"
      value={value ? tables.findTableByTableSlug(value) ?? null : null}
      getValueStringFromValue={(table) => table.tableSlug}
      onChange={(table) => onChange(table!.tableSlug)}
      options={options}
      isReadOnly={isReadOnly}
    />
  );
};
