import { useState } from "react";
import { useUploadFileCtx } from "./UploadFileContext";

export const useUploadFile = () => {
  const [isUploading, setIsUploading] = useState(false);

  const { onUploadFile } = useUploadFileCtx();

  const uploadFile = async (
    file: File
  ): Promise<{ data: string; url: string }> => {
    setIsUploading(true);
    try {
      const { data, url } = await onUploadFile(file);
      return {
        data,
        url,
      };
    } finally {
      setIsUploading(false);
    }
  };

  return {
    uploadFile,
    isUploading,
  };
};
