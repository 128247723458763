import { useCallback } from "react";
import { PresenceSourceState } from "../types";

const usePresenceSourceStateUtil = () => {
  const decodeSourceState = useCallback((sourceState?: string) => {
    if (!sourceState) return undefined;

    const parsed = JSON.parse(sourceState);
    // 本当は形式チェック入れたい
    return parsed as PresenceSourceState;
  }, []);

  return {
    decodeSourceState,
  };
};

export { usePresenceSourceStateUtil };
