import { SimpleFieldCategoryType } from "@usemorph/morph-dashboard-types";
import { match } from "ts-pattern";

export const getSimpleFieldTypeLabel = (
  fieldType: SimpleFieldCategoryType
): string => {
  return match(fieldType)
    .with("json", () => "JSON")
    .with("url", () => "URL")
    .with("html", () => "HTML")
    .otherwise(() => {
      // その他
      // キャメルケースを先頭大文字、スペース区切りの文字列に変換する
      const splitBySpace = fieldType.replace(/([a-z0-9])([A-Z])/g, "$1 $2");

      return `${splitBySpace.charAt(0).toUpperCase()}${splitBySpace.slice(1)}`;
    });
};
