import { FindUserClientModel } from "~/clientModel/user";
import { TableSelectionClientModel } from "../../TableSelection";

type TableCoworkerStateClientModelData = {
  user: FindUserClientModel;
  tableSelection: TableSelectionClientModel;
};

export class TableCoworkerStateClientModel {
  constructor(readonly data: TableCoworkerStateClientModelData) {}

  public get user(): FindUserClientModel {
    return this.data.user;
  }

  public get tableSelection(): TableSelectionClientModel {
    return this.data.tableSelection;
  }
}
