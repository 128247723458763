import { notebookKeys } from "../keys";
import useApiNotebook from "~/api/useApiNotebook";
import { useQueryClient } from "react-query";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseRemoveNotebookCellMetaMutationParams {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
}

const useRemoveNotebookCellMetaMutaiton = ({
  databaseId,
  teamSlug,
  notebookId,
}: UseRemoveNotebookCellMetaMutationParams) => {
  const { _removeNotebookCell } = useApiNotebook();

  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({ cellId }: { cellId: string }) => {
      return _removeNotebookCell({
        teamSlug,
        notebookId,
        cellId,
      });
    },
    onSuccess: () => {
      const keys = notebookKeys.findNotebook({
        databaseId,
        teamSlug,
        notebookId,
      });
      broadcast(keys);
      return invalidateAll(client, keys);
    },
  };
};

export { useRemoveNotebookCellMetaMutaiton };
