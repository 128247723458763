import { ForwardedRef, forwardRef } from "react";
import { FormulaRecordEntryClientModel } from "~/clientModel/records/record/recordEntry";
import { Input } from "~/components_next/Input";
import { RecordValueInputCommonProps } from "../../type";

const FormulaReadonlyInput = forwardRef(function FormulaReadonlyInput(
  props: RecordValueInputCommonProps<FormulaRecordEntryClientModel>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { editingRecordEntry, size, field } = props;

  const validatedValue = editingRecordEntry.getValidatedValue(field);

  const value = validatedValue.isValid ? validatedValue.value : "";

  return (
    <Input
      size={size}
      variant="primary"
      value={String(value) ?? ""}
      readOnly={true}
      ref={ref}
    />
  );
});

export { FormulaReadonlyInput };
