import { match, P } from "ts-pattern";
import { Serializer } from "./types";

/**
 * string
 */
const stringSerializer: Serializer<string> = (value) => {
  return value;
};

/**
 * number
 */
const numberSerializer: Serializer<number> = (value) => {
  return value.toString();
};

/**
 * boolean
 */
const booleanSerializer: Serializer<boolean> = (value) => {
  return value.toString();
};

/**
 * Object
 */
const objectSerializer = <T>(value: T): string => {
  return JSON.stringify(value);
};

type DefaultSerializerUsableType = string | number | boolean | object;

const defaultSerializer = (value: DefaultSerializerUsableType): string => {
  return match(value)
    .with(P.number, (value) => numberSerializer(value))
    .with(P.string, (value) => stringSerializer(value))
    .with(P.boolean, (value) => booleanSerializer(value))
    .otherwise((value) => objectSerializer(value));
};

export { defaultSerializer };
export type { DefaultSerializerUsableType };
