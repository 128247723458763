/**
 * filterFnのユーティリティ関数
 * BasicTypeaheadPromptのpropsに渡すfilterFnは、特別な事情がなければここにあるものを使ってください。
 */

import { FieldClientModel } from "~/clientModel/fields/field";
import { TableClientModel } from "~/clientModel/tables/table";
import { CanvasVariablesValue } from "~/presenters/canvas/common/CanvasVariablesProvider";

const basicFieldFilterFn = (
  field: FieldClientModel,
  query: string
): boolean => {
  if (field.name.includes(query)) {
    return true;
  }

  if (field.displayName?.includes(query)) {
    return true;
  }

  return false;
};

const basicTableFilterFn = (
  table: TableClientModel,
  query: string
): boolean => {
  if (table.tableSlug.includes(query)) {
    return true;
  }

  if (table.displayName?.includes(query)) {
    return true;
  }

  return false;
};

const basicVariableValueFilterFn = (
  variable: CanvasVariablesValue,
  query: string
): boolean => {
  if (variable.name.includes(query)) {
    return true;
  }

  return false;
};

export { basicFieldFilterFn, basicTableFilterFn, basicVariableValueFilterFn };
