import { useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useMe, useSelectedMeTeam } from "~/serverStateStore/me";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { usePreferenceModal } from "../Preference/usePreferenceModal";
import { helpCenterLinks } from "~/constants/helpLinks";

import { DropdownMenuItem, Separator } from "@radix-ui/themes";

import { Text } from "~/components_next/Text";
import { Image } from "~/components_next/Image";
import { DropdownMenu } from "~/components_next/DropdownMenu";

import { PreferenceModal } from "../Preference/PreferenceModal";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
import { CreateTeamDialog } from "../Preference/teamSettings/CreateTeamDialog";
import { useDisclosure } from "~/hooks/useDisclosure";

import {
  BsCardChecklist,
  BsChevronDown,
  BsPersonLinesFill,
} from "react-icons/bs";
import {
  MdGroup,
  MdLogout,
  MdOutlineHelpOutline,
  MdOutlineSettings,
  MdOutlineSwitchRight,
} from "react-icons/md";
import { CurrentUsageSubItem } from "./CurrentUsageSubItem";

const TeamSelect = () => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const { shutdown, hardShutdown } = useIntercom();
  const teamSlug = useTeamSlug();

  const { data: me } = useMe();
  const { data: selectedTeam } = useSelectedMeTeam({ teamSlug });

  const teams = useMemo(() => {
    if (me) {
      return me.teams;
    }
    return [];
  }, [me]);

  const onClickTeam = (teamSlug: string) => {
    navigate(`/${teamSlug}`);
  };

  const onClickSignOut = () => {
    shutdown();
    logout({ returnTo: window.location.origin });
  };

  const { onOpen: onOpenPreferenceModal } = usePreferenceModal();

  const notSelectedTeams = useMemo(
    () =>
      teams.flatMap((team) =>
        selectedTeam?.teamSlug === team.teamSlug ? [] : [team]
      ),
    [teams, selectedTeam]
  );

  const createTeamDialog = useDisclosure();

  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <Box hoverable px="1" height="100%" css={{ cursor: "pointer" }}>
            <Flex align="center" height="100%" gap="2">
              <Image
                src="/favicon.ico"
                style={{ width: "25px", height: "25px" }}
              />
              <Text variant="default" fontWeight="bold">
                {selectedTeam?.teamName}
              </Text>
              <BsChevronDown />
            </Flex>
          </Box>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content size="2" color="gray" highContrast>
          <Flex
            direction="column"
            align="stretch"
            gap="2"
            border-radius="medium"
          >
            <DropdownMenu.Label>
              {selectedTeam?.payment?.plan?.name || "Free"} Plan
            </DropdownMenu.Label>
            <DropdownMenu.Item
              onClick={() => {
                onOpenPreferenceModal("myAccount");
              }}
              style={{ cursor: "pointer" }}
            >
              <Flex align="center">
                <MdOutlineSettings color="gray" />
                <Text ml="2">Preference</Text>
              </Flex>
            </DropdownMenu.Item>
            <DropdownMenu.Item
              onClick={() => window.open(helpCenterLinks.top, "_blank")}
              style={{ cursor: "pointer" }}
            >
              <Flex align="center">
                <MdOutlineHelpOutline color="gray" />
                <Text ml="2">Help</Text>
              </Flex>
            </DropdownMenu.Item>

            <Separator size="4" />

            <DropdownMenu.Item
              onClick={() => {
                onOpenPreferenceModal("members");
              }}
              style={{ cursor: "pointer" }}
            >
              <Flex align="center">
                <BsPersonLinesFill color="gray" />
                <Text ml="2">Invite members</Text>
              </Flex>
            </DropdownMenu.Item>

            <DropdownMenu.Item
              onClick={() => {
                createTeamDialog.onOpen();
              }}
              style={{ cursor: "pointer" }}
            >
              <Flex align="center">
                <MdGroup color="gray" />
                <Text ml="2">Create new team</Text>
              </Flex>
            </DropdownMenu.Item>

            {teams.length > 0 && (
              <DropdownMenu.Sub>
                <DropdownMenu.SubTrigger>
                  <Flex align="center">
                    <MdOutlineSwitchRight color="gray" />
                    <Text ml="2">Switch team</Text>
                  </Flex>
                </DropdownMenu.SubTrigger>
                <DropdownMenu.SubContent>
                  {notSelectedTeams.map((team) => (
                    <DropdownMenuItem
                      key={team.teamSlug}
                      onClick={() => onClickTeam(team.teamSlug)}
                      style={{ cursor: "pointer" }}
                      shortcut={(team.payment?.plan?.name || "Free") + " Plan"}
                    >
                      <Text>{team.teamName}</Text>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenu.SubContent>
              </DropdownMenu.Sub>
            )}

            <Separator size="4" />

            <DropdownMenu.Item
              onClick={() => {
                onOpenPreferenceModal("plans");
              }}
              style={{ cursor: "pointer" }}
            >
              <Flex align="center">
                <BsCardChecklist color="gray" />
                <Text ml="2">Upgrade plan</Text>
              </Flex>
            </DropdownMenu.Item>

            <CurrentUsageSubItem />

            <Separator size="4" />

            <DropdownMenu.Item onClick={onClickSignOut}>
              <Flex align="center">
                <MdLogout color="gray" />
                <Text ml="2">Sign out</Text>
              </Flex>
            </DropdownMenu.Item>
          </Flex>
        </DropdownMenu.Content>
      </DropdownMenu.Root>

      <PreferenceModal />

      <CreateTeamDialog
        isOpen={createTeamDialog.isOpen}
        setIsOpen={createTeamDialog.setIsOpen}
        onClose={createTeamDialog.onClose}
      />
    </>
  );
};

export { TeamSelect };
