import { FieldClientModel } from "~/clientModel/fields/field";
import {
  ReadonlyRecordEntryClientModelBase,
  ReadonlyRecordEntryClientModelBaseConstructorParams,
} from "../RecordEntryClientModelBase";
import {
  zAllowNullIfNullable,
  zRequiredString,
  zValidate,
} from "../validatorsUtil";
import { z } from "zod";
import { NumberDisplaySettingClientModel } from "~/clientModel/fields/field/displaySetting/numberDisplaySetting";

type AggregateValueRecordEntryClientModelData =
  ReadonlyRecordEntryClientModelBaseConstructorParams;

export class AggregateValueRecordEntryClientModel extends ReadonlyRecordEntryClientModelBase<
  string | null
> {
  constructor(data: AggregateValueRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "aggregateValue" {
    return "aggregateValue";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([zRequiredString(field), zAllowNullIfNullable(field)]),
      this.data.rawValue
    );
  }

  public getValidatedDisplayValue(field: FieldClientModel) {
    const validatedValue = this.getValidatedValue(field);

    return validatedValue.isValid &&
      field.displaySetting instanceof NumberDisplaySettingClientModel
      ? ({
          isValid: true,
          value: field.displaySetting.getDisplayValue(validatedValue.value),
        } as const)
      : validatedValue;
  }
}
