import { DndContext, DragEndEvent, pointerWithin } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { Box } from "@radix-ui/themes";
import { Virtualizer } from "@tanstack/react-virtual";
import { MouseEvent, useMemo } from "react";
import { match } from "ts-pattern";
import {
  useRecordsTableBaseClearAllSelection,
  useRecordsTableBaseFields,
  useRecordsTableBaseOnRecordSelectionChange,
  useRecordsTableBaseOnReorderFields,
  useRecordsTableBaseOptions,
  useRecordsTableBaseRecords,
  useRecordsTableBaseRecordSelection,
} from "../context/ReactTableBaseContext";
import { RecordsTableAddFieldbutton } from "./RecordsTableAddFieldbutton";
import { RecordsTableHeader } from "./RecordsTableHeader";
import { RecordsTableSelectBox } from "./RecordsTableSelectBox";

type RecordsTableHeadersProps = {
  translateY: number;
  columnVirtualizer: Virtualizer<HTMLDivElement, Element>;
};

const RecordsTableHeaders = (props: RecordsTableHeadersProps) => {
  const { columnVirtualizer, translateY } = props;

  // options
  const { isReorderable } = useRecordsTableBaseOptions();
  // data
  const fields = useRecordsTableBaseFields();
  const records = useRecordsTableBaseRecords();
  // state
  const recordsSelection = useRecordsTableBaseRecordSelection();
  const onRecordsSelectionChange = useRecordsTableBaseOnRecordSelectionChange();
  // events
  const onReorderFields = useRecordsTableBaseOnReorderFields();

  const fieldsOrder = useMemo(() => {
    return fields.map((field) => field.name as string);
  }, [fields]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = fieldsOrder.findIndex(
        (name) => name === (active.id as string)
      );
      const newIndex = fieldsOrder.findIndex(
        (name) => name === (over.id as string)
      );
      const reordered = arrayMove([...fieldsOrder], oldIndex, newIndex);
      onReorderFields?.(reordered);
    }
  };

  const isAllChecked = useMemo(() => {
    return (
      recordsSelection &&
      recordsSelection.length > 0 &&
      recordsSelection.length === records.length
    );
  }, [recordsSelection, records]);

  const clearAllSelection = useRecordsTableBaseClearAllSelection();

  const handleRowClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      clearAllSelection();
    }
  };

  const handleSelectAllClick = () => {
    if (isAllChecked) {
      onRecordsSelectionChange([]);
    } else {
      onRecordsSelectionChange(records);
    }
  };

  /**
   * Virtual Scroll
   */
  /**
   * [ [checkbox], [field], [field], ..., [addFieldButton], [margin] ]
   */
  const CHECKBOX_INDEX = 0;
  const ADDFIELD_BUTTON_INDEX = 1 + (fields.length - 1) + 1;
  const MARGIN_COLUMN_INDEX = ADDFIELD_BUTTON_INDEX + 1;

  const _children = (
    <>
      {columnVirtualizer.getVirtualItems().map((virtualItem) => {
        const transform = `translateX(${virtualItem.start}px) translateY(${translateY}px)`;
        return match(virtualItem.index)
          .with(CHECKBOX_INDEX, () => (
            <RecordsTableSelectBox
              key={virtualItem.key}
              onSelect={handleSelectAllClick}
              isChecked={isAllChecked}
              isHeader
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                transform,
              }}
            />
          ))
          .with(ADDFIELD_BUTTON_INDEX, () => (
            <RecordsTableAddFieldbutton
              key={virtualItem.key}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                transform,
              }}
            />
          ))
          .with(MARGIN_COLUMN_INDEX, () => (
            <Box
              key={virtualItem.key}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                transform,
                width: "400px",
                height: "40px",
              }}
              onClick={handleRowClick}
            />
          ))
          .otherwise(() => (
            <RecordsTableHeader
              key={virtualItem.key}
              index={virtualItem.index}
              field={fields[virtualItem.index - 1]}
              translateX={virtualItem.start}
              translateY={translateY}
            />
          ));
      })}
    </>
  );

  if (isReorderable && onReorderFields) {
    return (
      <DndContext onDragEnd={handleDragEnd} collisionDetection={pointerWithin}>
        <SortableContext items={fieldsOrder} strategy={rectSortingStrategy}>
          {_children}
        </SortableContext>
      </DndContext>
    );
  }

  return <>{_children}</>;
};

export { RecordsTableHeaders };
