// react

import { createContext, useContext } from "react";

function createCtx<ContextValueType extends object | null>(
  defaultContextValue?: ContextValueType
) {
  const Context = createContext<ContextValueType | undefined>(
    defaultContextValue
  );

  function Provider({
    value,
    children,
  }: {
    value: ContextValueType;
    children: React.ReactNode;
  }) {
    return <Context.Provider value={value}>{children}</Context.Provider>;
  }

  function useCtx() {
    const context = useContext(Context);
    if (context) return context;
    if (defaultContextValue !== undefined) return defaultContextValue;
    throw new Error(`useCtx() must be used within a Provider with a value`);
  }

  return { Provider, useCtx } as const;
}

export { createCtx };
