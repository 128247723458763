import { LiveList, LiveObject } from "@liveblocks/client";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { match } from "ts-pattern";
import { findCanvasClientModelService } from "~/clientModel/canvas";
import {
  CanvasCellClientModelFactory,
  CanvasCellClientModelUnion,
} from "~/clientModel/canvas/CanvasCellClientModel";

import { useNotebookTabState } from "~/features/FlipNotebook/state/notebookTabState";
import { NotebookCellObjectWithMeta } from "~/features/FlipNotebook/types/NotebookCellObjectWithMeta.type";
import { usePresenceBasics } from "~/features/RealtimeCollaboration";
import { NotebookRoomProvider } from "~/features/RealtimeCollaboration/notebook.liveblocks.config";
import { CanvasTabLayout } from "~/presenters/canvas";
import { useFindNotebookQuery } from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useNotebookId } from "~/utilHooks/useNotebookId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { CanvasDashboardContainer } from "./dashboard/CanvasDashboardContainer";
import { CanvasPlaygroundContainer } from "./playground/CanvasPlaygroundContainer";

/**
 * !! 設計
 * 1. Notebookのresponseに含まれるpages
 * 2. Liveblocksの値
 * が共有なので、この二つはここで取り扱う
 */

const CanvasContainer = () => {
  /**
   * Path parameters
   */
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const notebookId = useNotebookId();

  /**
   * UI state
   */
  const tabState = useNotebookTabState(notebookId);
  const tabIndex = useMemo(() => {
    return match(tabState)
      .with({ tab: "canvas" }, () => 0)
      .with({ tab: "dashboard" }, () => 1)
      .otherwise(() => 0);
  }, [tabState]);

  /**
   * Server state
   */
  // notebook detail
  const { data: notebook } = useQuery({
    ...useFindNotebookQuery({
      teamSlug,
      databaseId,
      notebookId,
    }),
  });
  // Liveblocks presence
  const { user } = usePresenceBasics();

  /**
   * Client model
   */
  const findCanvasClientModel = useMemo(() => {
    if (!notebook) {
      return null;
    }
    return findCanvasClientModelService.fromNotebookDetailResponse(notebook);
  }, [notebook]);

  const canvasCellClientModels = useMemo(() => {
    if (!notebook) {
      return [];
    }
    return notebook.cells
      .map((cell) => {
        return CanvasCellClientModelFactory.fromLiveblocksObject(
          cell as NotebookCellObjectWithMeta
        );
      })
      .filter(
        (cellInstance): cellInstance is CanvasCellClientModelUnion =>
          cellInstance !== null
      );
  }, [notebook]);

  if (!findCanvasClientModel || !user) {
    return null; // TODO: Loading
  }

  return (
    <NotebookRoomProvider
      id={`${teamSlug}/notebook/${notebookId}`}
      initialPresence={{
        user,
      }}
      initialStorage={{
        cells: new LiveList(
          canvasCellClientModels.map(
            (cellClientModel) => new LiveObject(cellClientModel.rawData)
          )
        ),
      }}
    >
      <CanvasTabLayout tabValue={tabIndex}>
        <CanvasPlaygroundContainer
          findCanvasClientModel={findCanvasClientModel}
        />
        <CanvasDashboardContainer
          findCanvasClientModel={findCanvasClientModel}
        />
      </CanvasTabLayout>
    </NotebookRoomProvider>
  );
};

export { CanvasContainer };
