import { useMemo } from "react";
import { useQuery } from "react-query";
import { composeLoadable } from "~/clientModel/loadable";
import { useGetDatabaseRecordCount } from "~/serverStateStore/databaseRecordCount";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useGetPaymentQuery } from "~/serverStateStore/payments";
import { PlanAppealPresenter } from "~/presenters/planAppeal/PlanAppealPresenter";
import { findPaymentClientModelService } from "~/clientModel/payment/findPaymentClientModel";
import { DatabaseUsageClientModelFactory } from "~/clientModel/databaseUsage";
import { usePreferenceModal } from "~/features/GlobalNavigation";

const PlanAppealContainer = () => {
  const teamSlug = useTeamSlug();

  const usageRsult = useQuery({
    ...useGetDatabaseRecordCount({
      teamSlug,
    }),
    select: (response) => {
      return DatabaseUsageClientModelFactory.fromRecordCountResponse(response);
    },
  });
  const usageLoadable = useMemo(() => {
    return composeLoadable(usageRsult);
  }, [usageRsult]);

  const paymentResult = useQuery({
    ...useGetPaymentQuery({ teamSlug }),
    select: (response) => {
      return findPaymentClientModelService.fromPaymentResponse(response);
    },
  });
  const paymentLoadable = useMemo(() => {
    return composeLoadable(paymentResult);
  }, [paymentResult]);

  const { onOpen } = usePreferenceModal();

  const showPlanInfo = () => {
    onOpen("plans");
  };

  return (
    <PlanAppealPresenter
      paymentLoadable={paymentLoadable}
      usageLoadable={usageLoadable}
      onClick={() => showPlanInfo()}
    />
  );
};

export { PlanAppealContainer };
