import {
  DashboardNotebookDetailObject,
  DashboardNotebookPublicPagesResponse,
} from "@usemorph/morph-dashboard-types";
import { ListCanvasPageClientModel } from "./ListCanvasPageClientModel";

export class ListCanvasPageClientModelFactory {
  static fromNotebookResponse(
    notebookPage: DashboardNotebookDetailObject["pages"][number]
  ) {
    return new ListCanvasPageClientModel(notebookPage);
  }

  static fromNotebookResponseList(
    notebookPages: DashboardNotebookDetailObject["pages"]
  ) {
    return notebookPages.map((notebookPage) =>
      ListCanvasPageClientModelFactory.fromNotebookResponse(notebookPage)
    );
  }

  static fromPublisedPagesResponse(
    publishedPages: DashboardNotebookPublicPagesResponse
  ) {
    return publishedPages.pages.map((publishedPage) =>
      ListCanvasPageClientModelFactory.fromNotebookResponse({
        ...publishedPage,
        databaseId: "unknown",
        isPrivate: false,
        isPublic: true,
        notebookId: "unknown",
      })
    );
  }
}
