import { useMemo } from "react";
import { TableCoworkersStateClientModel } from "~/clientModel/tables/tableMeta/tableCoworkersState";
import { TableCoworkerStateClientModel } from "~/clientModel/tables/tableMeta/tableCoworkersState/tableCoworkerState";
import { TableSelectionClientModelFactory } from "~/clientModel/tables/tableMeta/TableSelection";
import { FindUserClientModelFactory } from "~/clientModel/user";

import { useOthersDecoded } from "~/features/RealtimeCollaboration";

export const useSourceCoworkersState = () => {
  const { samePageOthers } = useOthersDecoded();

  const sourceCoworkerState: TableCoworkersStateClientModel = useMemo(() => {
    const coworkersState: TableCoworkerStateClientModel[] =
      samePageOthers.flatMap((other) => {
        if (!other.presence.sourceState_1_1) {
          return [];
        }

        const tableSelection = TableSelectionClientModelFactory.deserialize(
          other.presence.sourceState_1_1
        );

        const user = FindUserClientModelFactory.fromUserResponse(
          other.presence.user
        );

        return new TableCoworkerStateClientModel({
          user,
          tableSelection,
        });
      });
    return new TableCoworkersStateClientModel({ coworkersState });
  }, [samePageOthers]);

  return sourceCoworkerState;
};
