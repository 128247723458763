import { BsX } from "react-icons/bs";
import { IconButton } from "~/components_next/IconButton";

type RemoveButtonProps = {
  onClick: () => void;
};

export const RemoveButton = (props: RemoveButtonProps) => {
  const { onClick } = props;

  return <IconButton onClick={onClick} icon={<BsX />} tooltip="Remove" />;
};
