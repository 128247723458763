import { useEditorOrThrow } from "../utils";

const useStrike = () => {
  const editor = useEditorOrThrow();

  const handleToggle = () => {
    editor.chain().focus().toggleStrike().run();
  };

  const isActive = editor.isActive("strike");

  const isDisabled = !editor.can().chain().focus().toggleStrike().run();

  return {
    handleToggle,
    isActive,
    isDisabled,
  };
};

export { useStrike };
