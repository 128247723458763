import { useMutation } from "react-query";
import { useComposeExecutable } from "~/clientModel/executable";
import { PaymentClientModelFactory } from "~/clientModel/payment/PaymentClientModel";
import { UpdatePaymentMutationProps, useUpdatePlanMutation } from "~/serverStateStore";

const useUpdatePaymentExecutable = (props: { teamSlug: string }) => {
  const { teamSlug } = props

  const updatePlanMutationOptions = useUpdatePlanMutation({ teamSlug })
  const updatePlanMutationResult = useMutation({
    ...updatePlanMutationOptions,
    mutationFn: async (params: UpdatePaymentMutationProps) => {
      const response = await updatePlanMutationOptions.mutationFn(params)
      return PaymentClientModelFactory.fromPaymentResponse(response)
    }
  })

  return useComposeExecutable(updatePlanMutationResult)

}

export const createUseUpdatePaymentExecutable = ({ teamSlug }: { teamSlug: string }) => {
  return () => useUpdatePaymentExecutable({ teamSlug })
}
