import {
  CanvasCellClientModelBase,
  CanvasCellDataType,
  CanvasCellResizeParams,
} from "./CanvasCellClientModelBase";

export type CanvasCellImageDataType = CanvasCellDataType & {
  cellType: "image";
};

export class CanvasCellImageClientModel extends CanvasCellClientModelBase<
  CanvasCellImageClientModel,
  CanvasCellImageDataType
> {
  constructor(data: CanvasCellImageDataType) {
    super(data);
  }

  /**
   *
   * Custom value getters
   *
   */

  get src(): string | null {
    return this.source.image?.src || null;
  }

  get objectFit(): string {
    return this.source.image?.objectFit || "contain";
  }

  get isResizable(): boolean {
    return true;
  }

  get showTargetHandle(): boolean {
    return false;
  }

  get showSourceHandle(): boolean {
    return false;
  }

  updateCellName(cellName: string): CanvasCellImageClientModel {
    return new CanvasCellImageClientModel({
      ...this.data,
      cellName,
    });
  }

  onResize(resizePrams: CanvasCellResizeParams): CanvasCellImageClientModel {
    return new CanvasCellImageClientModel({
      ...this.data,
      settings: {
        ...this.data.settings,
        ...resizePrams,
      },
    });
  }
}
