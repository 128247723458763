import { useMemo } from "react";
import { LuChevronRight } from "react-icons/lu";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { ValueLoadable } from "~/clientModel/loadable";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { TablesClientModel } from "~/clientModel/tables";
import { TableClientModel } from "~/clientModel/tables/table";
import { Flex } from "~/components_next/Flex";
import { Text } from "~/components_next/Text";
import { TableAndFieldDropdown } from "~/presenters/fields";

type TriggerProps = Pick<TargetTableAndFieldInputProps, "useFieldsLoadable"> & {
  targetTable: TableClientModel;
  targetFieldName: string;
};

const Trigger = ({
  targetFieldName,
  targetTable,
  useFieldsLoadable,
}: TriggerProps) => {
  const fieldsLoadable = useFieldsLoadable({
    tableSlug: targetTable.tableSlug,
  });

  return (
    <Flex gap="2" align="center">
      <Text>{targetTable.label}</Text>
      <LuChevronRight />
      <WithFallback loadables={[fieldsLoadable] as const}>
        {([fields]) => (
          <Text>{fields.getFieldByFieldName(targetFieldName)?.label}</Text>
        )}
      </WithFallback>
    </Flex>
  );
};

const renderTableItem = (table: TableClientModel) => {
  return <Text>{table.label}</Text>;
};

const renderFieldItem = (field: FieldClientModel) => {
  return <Text>{field.label}</Text>;
};

type TargetTableAndFieldInputProps = {
  value: {
    targetTableSlug: string;
    targetFieldName: string;
  } | null;
  onChange: ({
    targetTable,
    targetField,
  }: {
    targetTable: TableClientModel;
    targetField: FieldClientModel;
  }) => void;
  tables: TablesClientModel;
  useFieldsLoadable: UseLoadable<{ tableSlug: string }, FieldsClientModel>;
  isReadOnly?: boolean;
};

export const TargetTableAndFieldInput = (
  props: TargetTableAndFieldInputProps
) => {
  const { value, tables, useFieldsLoadable, onChange, isReadOnly } = props;

  const targetTable = value
    ? tables.findTableByTableSlug(value.targetTableSlug) ?? null
    : null;

  const targetFieldName = value?.targetFieldName;

  // TableAndFieldDropdownのインターフェース上必要なので
  // todo: これ系検討する
  const tablesLoadable = useMemo(() => new ValueLoadable(tables), [tables]);

  return (
    <TableAndFieldDropdown
      trigger={
        targetTable &&
        targetFieldName && (
          <Trigger
            targetTable={targetTable}
            targetFieldName={targetFieldName}
            useFieldsLoadable={useFieldsLoadable}
          />
        )
      }
      onSelect={({ table, field }) =>
        onChange({ targetTable: table, targetField: field })
      }
      tablesLoadable={tablesLoadable}
      useFieldsLoadable={useFieldsLoadable}
      label="Get value from"
      renderTableItem={renderTableItem}
      renderFieldItem={renderFieldItem}
      isReadOnly={isReadOnly}
    />
  );
};
