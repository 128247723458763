import { useQuery } from "react-query";
import { useComposeLoadable } from "~/clientModel/loadable";
import { SourceBucketClientModel } from "~/clientModel/storage/bucket";
import { useFindSourceBucketQuery } from "~/serverStateStore";

export const useCsvInsertBucket = ({
  teamSlug,
  databaseId,
  tableSlug,
}: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
}) => {
  const findCsvInsertFileBucketResult = useQuery({
    ...useFindSourceBucketQuery({
      teamSlug,
      databaseId,
      tableSlug,
    }),
    select: (data) => {
      return new SourceBucketClientModel({
        ...data,
      });
    },
  });
  const bucketLoadable = useComposeLoadable(findCsvInsertFileBucketResult);

  return {
    bucketLoadable,
  };
};
