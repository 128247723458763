import { Flex } from "@radix-ui/themes";
import { styled } from "~/stitches";

type NotebookWarningUnitProps = {
  content: string;
};

// TODO: 仕上がり見てCalloutを検討する
const Wrapper = styled(Flex, {
  alignItems: "center",
  justifyContent: "center",
  minHeight: "60px",
  backgroundColor: "$bg1",
  color: "$textSupplementary",
  borderRadius: "$2",
  p: "$4",
});

const NotebookWarningUnit = (props: NotebookWarningUnitProps) => {
  const { content } = props;

  return <Wrapper>{content}</Wrapper>;
};

export { NotebookWarningUnit };
