import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { templateAuthKeys } from "../keys";
import useApiTemplateAuth from '~/api/useApiTemplateAuth';

interface UseDeleteTemplateAuthMutationParams {
  templateId: string;
  teamSlug: string;
}

const useDeleteTemplateAuthMutation = ({
  templateId,
  teamSlug,
}: UseDeleteTemplateAuthMutationParams) => {
  const { _deleteTemplateAuth } = useApiTemplateAuth()
  const client = useQueryClient();

  return {
    mutationFn: ({
      templateAuthId,
    }: {
      templateAuthId: string;
    }) => {
      return _deleteTemplateAuth({
        templateId,
        templateAuthId,
        teamSlug,
      });
    },
    onSuccess: () => {
      const keys = templateAuthKeys.allListTemplateAuth({ templateId });
      return invalidateAll(client, keys);
    },
  };
};

export { useDeleteTemplateAuthMutation };
