import constate from "constate";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { CanvasVariablesValue } from "./CanvasVariablesProvider";

type UseExecuteCellLoadableContext = {
  useExecuteCellLoadable: UseLoadable<
    { cellId: string; variables: CanvasVariablesValue[] },
    CanvasCellClientModelUnion
  >;
};

const useExecuteCellLoadableContext = (
  options: UseExecuteCellLoadableContext
) => {
  const { useExecuteCellLoadable } = options;

  return {
    useExecuteCellLoadable,
  };
};

export const [
  UseExecuteCellLoadableContextProvider,
  useUseExecuteCellLoadable,
] = constate(
  useExecuteCellLoadableContext,
  (value) => value.useExecuteCellLoadable
);
