import {
  DashboardVersionListResponse
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

export default function useApiVersion() {
  const { executeRequest } = useApi();

  const _listVersions = async ({
    teamSlug,
  }: {
    teamSlug: string;
  }): Promise<DashboardVersionListResponse> => {
    return executeRequest<DashboardVersionListResponse>(
      "get",
      `/version`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  return {
    _listVersions,
  };
}
