import { forwardRef } from "react";
import { Box } from "../Box";
import { styled } from "~/stitches";
import { MorphThemeSize } from "../commonProps.type";
import { keyframes } from "@stitches/react";

type SkeltonTextProps = {
  size?: MorphThemeSize;
  lines?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
};

const referenceHeight = {
  xs: 8,
  sm: 12,
  md: 16,
  lg: 20,
  xl: 24,
} as const;

const gapRatio = 0.6;

const Container = styled(Box, {
  display: "flex",
  flexDirection: "column",

  variants: {
    size: {
      xs: {
        gap: referenceHeight.xs * gapRatio,
      },
      sm: {
        gap: referenceHeight.sm * gapRatio,
      },
      md: {
        gap: referenceHeight.md * gapRatio,
      },
      lg: {
        gap: referenceHeight.lg * gapRatio,
      },
      xl: {
        gap: referenceHeight.xl * gapRatio,
      },
    },
  },
});

const heightRatio = 0.6;

const skeltonAnimation = keyframes({
  "0%": {
    backgroundColor: "$bg1",
  },
  "50%": {
    backgroundColor: "$bg3",
  },
  "100%": {
    backgroundColor: "$bg1",
  },
});

const Line = styled(Box, {
  backgroundColor: "$bg2",
  borderRadius: "$2",
  width: "100%",

  variants: {
    size: {
      xs: {
        height: referenceHeight.xs * heightRatio,
      },
      sm: {
        height: referenceHeight.sm * heightRatio,
      },
      md: {
        height: referenceHeight.md * heightRatio,
      },
      lg: {
        height: referenceHeight.lg * heightRatio,
      },
      xl: {
        height: referenceHeight.xl * heightRatio,
      },
    },
  },

  animation: `${skeltonAnimation} 1.5s infinite`,
});

const SkeltonText = forwardRef<HTMLDivElement, SkeltonTextProps>(
  (props, ref) => {
    const { size = "md", lines = 1 } = props;

    return (
      <Container ref={ref} size={size}>
        {[...Array(lines)].map((_, i) => (
          <Line key={i} size={size} />
        ))}
      </Container>
    );
  }
);

SkeltonText.displayName = "SkeltonText";

export { SkeltonText, type SkeltonTextProps };
