import { Flex } from "~/components_next/Flex";
import { CanvasTableView } from "~/presenters/canvas/cellContent/view/CanvasTableView";
import { CanvasTableViewToolbar } from "~/presenters/canvas/cellContent/view/CanvasTableViewToolbar";
import {
  useUseFieldsWithConditionLoadable,
  useUseFieldsWithSqlLoadable,
  useUseRecordsWithConditionLoadable,
  useUseRecordsWithSqlLoadable,
  useUseGenerateSqlFromPromptExecutable,
  useUseSuggestedPromptsLoadable,
  useUseDownloadCsvPreviewRecordsLoadable,
  useUseDownloadCsvExecutable,
} from "../../providers/DashboardTableViewPropsProvider";
import { useDashboardTableViewQueryConditions } from "./useDashboardTableViewQueryConditions";
import { CanvasCellViewClientModel } from "~/clientModel/canvas/CanvasCellClientModel";
import { useCallback, useMemo } from "react";
import { DashboardViewConditionObject } from "@usemorph/morph-dashboard-types";
import { ScrollArea } from "@radix-ui/themes";
import { useDisclosure } from "~/hooks/useDisclosure";
import { PromptHistoryClientModel } from "~/clientModel/promptHistories/promptHistory";
import { DashboardCellViewPromptHistoryDrawer } from "./DashboardCellViewPromptHitoryDrawer";
import {
  CsvDownloadFieldsClientModel,
  CsvDownloadFieldsClientModelFactory,
} from "~/clientModel/csvDownload/csvDownloadFields";
import { useLoadableState } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { DownloadRecordsWithQueryDrawer } from "~/presenters/downloadRecords";

type DashboardPageViewCellProps = {
  viewCellInstance: CanvasCellViewClientModel;
};

const DashboardPageViewCellContent = (
  props: DashboardPageViewCellProps & {
    viewId: string;
    condition: DashboardViewConditionObject;
  }
) => {
  const { viewCellInstance, viewId, condition } = props;

  const {
    queryConditions,
    queryMode,
    onQueryConditionsChange,
    onQueryModeChange,
    resetAllConditions,
  } = useDashboardTableViewQueryConditions();

  // loadables
  const useRecordsWithConditionLoadable = useUseRecordsWithConditionLoadable();
  const recordsWithConditionsLoadable = useRecordsWithConditionLoadable({
    filterConditions: queryConditions.filterConditions,
    sortConditions: queryConditions.sortConditions,
    pagination: queryConditions.pagination,
    viewId,
  });

  const useRecordsWithSqlLoadable = useUseRecordsWithSqlLoadable();
  const recordsWithSqlLoadable = useRecordsWithSqlLoadable({
    queryingSql: queryConditions.queryingSql,
    pagination: queryConditions.pagination,
    viewId,
  });

  const recordsLoadable = useMemo(() => {
    if (queryMode === "sql" || queryMode === "prompt") {
      return recordsWithSqlLoadable;
    } else {
      return recordsWithConditionsLoadable;
    }
  }, [queryMode, recordsWithConditionsLoadable, recordsWithSqlLoadable]);

  const useFieldsWithConditionLoadable = useUseFieldsWithConditionLoadable();
  const fieldsWithConditionLoadable = useFieldsWithConditionLoadable({
    viewId,
  });

  const useFieldsWithSqlLoadable = useUseFieldsWithSqlLoadable();
  const fieldsWithSqlLoadable = useFieldsWithSqlLoadable({
    queryingSql: queryConditions.queryingSql,
    viewId,
  });

  const fieldsLoadable = useMemo(() => {
    if (queryMode === "sql" || queryMode === "prompt") {
      return fieldsWithSqlLoadable;
    } else {
      return fieldsWithConditionLoadable;
    }
  }, [queryMode, fieldsWithConditionLoadable, fieldsWithSqlLoadable]);

  const useSuggestedPromptsLoadable = useUseSuggestedPromptsLoadable();

  const _useGenerateSqlFromPromptExecutable =
    useUseGenerateSqlFromPromptExecutable();
  const useGenerateSqlFromPromptExecutable = () => {
    return _useGenerateSqlFromPromptExecutable({
      condition,
    });
  };

  /**
   * History
   */
  const {
    isOpen: isPromptHistoryDrawerOpen,
    onOpen: onPromptHistoryDrawerOpen,
    onClose: onPromptHistoryDrawerClose,
    setIsOpen: onPromptHistoryDrawerOpenChange,
  } = useDisclosure();

  const handlePromptHistorySelected = (
    promptHistory: PromptHistoryClientModel
  ) => {
    onPromptHistoryDrawerClose();
    onQueryConditionsChange({
      ...queryConditions,
      prompt: promptHistory.message,
      sql: promptHistory.sql,
      queryingSql: promptHistory.sql,
    });
    onQueryModeChange("prompt");
  };

  /**
   * CSV download
   */
  const _useDownloadCsvPreviewRecordsLoadable =
    useUseDownloadCsvPreviewRecordsLoadable();
  const useDownloadCsvPreviewRecordsLoadable = useCallback(
    (props: { csvDownloadFields: CsvDownloadFieldsClientModel }) => {
      const { csvDownloadFields } = props;
      return _useDownloadCsvPreviewRecordsLoadable({
        viewId,
        csvDownloadFields,
      });
    },
    [viewId, _useDownloadCsvPreviewRecordsLoadable]
  );
  const _useDownloadCsvExecutable = useUseDownloadCsvExecutable();
  const useDownloadCsvExecutable = useCallback(() => {
    return _useDownloadCsvExecutable({
      viewId,
    });
  }, [viewId, _useDownloadCsvExecutable]);

  const [csvDownloadWithQueryFieldsLoadable, setCsvDownloadWithQueryFields] =
    useLoadableState<CsvDownloadFieldsClientModel>();

  const {
    onOpen: onDownloadCsvWithQueryDrawerOpen,
    isOpen: isDownloadCsvWithQueryDrawerOpen,
    setIsOpen: downloadCsvWithQueryDrawerOpenChange,
  } = useDisclosure();

  const onDownloadClick = useCallback(() => {
    onDownloadCsvWithQueryDrawerOpen();
    if (fieldsLoadable.data) {
      setCsvDownloadWithQueryFields(
        CsvDownloadFieldsClientModelFactory.fromFieldsClientModel(
          fieldsLoadable.data
        )
      );
    }
  }, [
    fieldsLoadable.data,
    onDownloadCsvWithQueryDrawerOpen,
    setCsvDownloadWithQueryFields,
  ]);

  return (
    <>
      <Flex direction="column" gap="2">
        <CanvasTableViewToolbar
          recordsLoadable={recordsLoadable}
          fieldsLoadable={fieldsLoadable}
          queryConditions={queryConditions}
          onQueryConditionsChange={onQueryConditionsChange}
          queryMode={queryMode}
          onQueryModeChange={onQueryModeChange}
          useSuggestedPromptsLoadable={useSuggestedPromptsLoadable}
          viewId={viewId}
          viewCondition={condition}
          useGenerateSqlFromPromptExecutable={
            useGenerateSqlFromPromptExecutable
          }
          onHistoryClick={() => onPromptHistoryDrawerOpen()}
          onClearAllConditions={() => resetAllConditions()}
        />
        <CanvasTableView
          recordsLoadable={recordsLoadable}
          fieldsLoadable={fieldsLoadable}
          pagination={queryConditions.pagination}
          onPaginationChange={(pagination) =>
            onQueryConditionsChange({
              ...queryConditions,
              pagination,
            })
          }
          isOpenable={false}
          height={600} // todo: 600を実際の高さにする
          onDownloadClick={onDownloadClick}
        />
      </Flex>

      <DashboardCellViewPromptHistoryDrawer
        viewId={viewId}
        isOpen={isPromptHistoryDrawerOpen}
        onOpenChange={onPromptHistoryDrawerOpenChange}
        onHistoryClick={handlePromptHistorySelected}
      />

      <WithFallback loadables={[csvDownloadWithQueryFieldsLoadable] as const}>
        {([csvDownloadWithQueryFields]) => (
          <DownloadRecordsWithQueryDrawer
            isOpen={isDownloadCsvWithQueryDrawerOpen}
            onOpenChange={downloadCsvWithQueryDrawerOpenChange}
            csvDownloadFields={csvDownloadWithQueryFields}
            onCsvDownloadFieldsChange={setCsvDownloadWithQueryFields}
            useDownloadExecutable={useDownloadCsvExecutable}
            usePreviewRecordsLoadable={useDownloadCsvPreviewRecordsLoadable}
          />
        )}
      </WithFallback>
    </>
  );
};

const DashboardPageViewCell = (props: DashboardPageViewCellProps) => {
  const { viewCellInstance } = props;

  // TODO: Fallback
  if (!viewCellInstance.viewId || !viewCellInstance.viewCondition) {
    return <></>;
  }

  return (
    <ScrollArea style={{ height: "100%" }}>
      <DashboardPageViewCellContent
        {...props}
        viewId={viewCellInstance.viewId}
        condition={viewCellInstance.viewCondition}
      />
    </ScrollArea>
  );
};

export { DashboardPageViewCell };
