import { useState, ChangeEvent, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import { useMutation } from "react-query";
import {
  useCheckTeamSlugMutation,
  useCreateTeamMutation,
} from "~/serverStateStore/teams";

import { AiOutlineWarning } from "react-icons/ai";
import { useErrorToast } from "~/components_next/Error";
import { Text } from "~/components_next/Text";
import { Input } from "~/components_next/Input";
import { Button } from "~/components_next/Button";
import { Dialog } from "~/components_next/Dialog";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";

export const CreateTeamDialog = ({
  isOpen,
  setIsOpen,
  onClose,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose?: () => void;
}) => {
  const navigate = useNavigate();

  const { mutateAsync: createTeam, isLoading: isCreatingTeam } = useMutation(
    useCreateTeamMutation()
  );
  const { mutateAsync: checkTeamSlug, isLoading: isLoadingCheckTeamSlug, status: checkTeamSlugStatus } =
    useMutation(useCheckTeamSlugMutation());

  const [isAvailableTeamSlug, setIsAvailableTeamSlug] = useState(true);

  const [teamName, setTeamName] = useState("");
  const onTeamNameChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.endsWith("-")) {
      setTeamName(value);
    } else {
      setTeamName(
        slugify(value, {
          lower: true,
          replacement: "-",
        })
      );
    }
  };

  const checkIsAvailableTeamSlug = async () => {
    const response = await checkTeamSlug({ teamSlug: teamName });
    setIsAvailableTeamSlug(response.isAvailable);
  };

  const teamNameValid = useMemo(() => {
    const exp = /^[0-9a-z-]*$/;
    return !exp.test(teamName);
  }, [teamName]);

  const { errorToast } = useErrorToast({});
  const handleCreateTeam = async () => {
    try {
      const response = await createTeam({
        teamName,
        teamSlug: teamName,
      });
      setTimeout(() => {
        const newTeamSlug = response.teamSlug;
        navigate(`/${newTeamSlug}`);
      }, 1000);
    } catch (e) {
      errorToast(e);
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
        <Dialog.Content>
          <Dialog.Title>Create New Team</Dialog.Title>
          <Dialog.Body>
            <Box mt="5">
              <Flex direction="column" gap="4" align="stretch">
                <Flex align="start">
                  <Box mt="1" mr="3">
                    <Text>Team Name</Text>
                  </Box>
                  <Box grow="1">
                    <Flex gap="2">
                      <Input
                        variant="primary"
                        value={teamName}
                        onChange={onTeamNameChange}
                        isInvalid={teamNameValid && !isAvailableTeamSlug}
                        onBlur={checkIsAvailableTeamSlug}
                      />
                      <Button
                        variant="secondary"
                        isLoading={isLoadingCheckTeamSlug}
                        size="sm"
                      >
                        Check
                      </Button>
                    </Flex>
                    {teamNameValid ? (
                      <Text variant="description" colorOverride="red" mt="2">
                        <AiOutlineWarning color="red.700" size={4} />{" "}
                        {`Accepts only lower-case alphabets, numbers and '-'.`}
                      </Text>
                    ) : (
                      <Box>
                        <Text>
                          {`Accepts only lower-case alphabets, numbers and '-'.`}
                        </Text>
                      </Box>
                    )}
                    {!isAvailableTeamSlug && (
                      <Box mt="2" css={{ color: "red" }}>
                        <Text>
                          <AiOutlineWarning color="red.700" size={4} />{" "}
                          {`This team name is already in use.`}
                        </Text>
                      </Box>
                    )}
                    {
                      checkTeamSlugStatus === "success" && isAvailableTeamSlug && (
                        <Box mt="2" css={{ color: "DarkCyan" }}>
                          <Text>
                            {`You may use this team name.`}
                          </Text>
                        </Box>
                      )
                    }
                  </Box>
                </Flex>
              </Flex>
            </Box>
            <Flex justify="end" mt="5">
              <Button variant="secondary" size="sm" onClick={onClose}>
                Close
              </Button>
              <Box mr="3"></Box>
              <Button
                variant="primary"
                size="sm"
                onClick={handleCreateTeam}
                isDisabled={
                  teamName === "" || teamNameValid || !isAvailableTeamSlug
                }
                isLoading={isCreatingTeam}
              >
                Create
              </Button>
            </Flex>
          </Dialog.Body>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};
