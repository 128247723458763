import { Virtualizer } from "@tanstack/react-virtual";
import { match } from "ts-pattern";
import { Box } from "~/components_next/Box";
import { styled } from "~/stitches";
import {
  useRecordsTableBaseColumnsMeta,
  useRecordsTableBaseFields,
} from "../context/ReactTableBaseContext";
import { AddFieldButton } from "./AddFieldButton";
import { FieldHeader } from "./FieldHeader";
import { HeaderOpenRecordButtonBox } from "./HeaderOpenRecordButtonBox";
import { HeaderSelectBox } from "./HeaderSelectBox";

const BoxWithBg = styled(Box, {
  backgroundColor: "$bg1",
});

type HeaderProps = {
  columnVirtualizer: Virtualizer<HTMLDivElement, Element>;
};

const Header = (props: HeaderProps) => {
  const { columnVirtualizer } = props;

  const fields = useRecordsTableBaseFields();
  const columnsMeta = useRecordsTableBaseColumnsMeta();

  const hasRightBorderForHeaderColumn = (index: number) => {
    const nextColumnMeta = columnsMeta[index + 1];

    // if next column is an item column, show border
    if (nextColumnMeta && nextColumnMeta.type === "item") {
      return true;
    } else {
      return false;
    }
  };

  const _children = (
    <>
      {columnVirtualizer.getVirtualItems().map((virtualRowItem) => {
        const columnMeta = columnsMeta[virtualRowItem.index];
        return (
          <div
            key={virtualRowItem.key}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              transform: `translateX(${virtualRowItem.start}px)`,
              width: `${virtualRowItem.size}px`,
              height: "100%",
            }}
          >
            {match(columnMeta)
              .with({ type: "selectCheckbox" }, () => (
                <HeaderSelectBox
                  hasRightBorder={hasRightBorderForHeaderColumn(
                    virtualRowItem.index
                  )}
                />
              ))
              .with({ type: "openRecordButton" }, () => (
                <HeaderOpenRecordButtonBox
                  hasRightBorder={hasRightBorderForHeaderColumn(
                    virtualRowItem.index
                  )}
                />
              ))
              .with({ type: "item" }, (columnMeta) => (
                <BoxWithBg width="100%" height="100%">
                  <FieldHeader
                    field={fields.getFieldByFieldNameOrThrow(
                      columnMeta.fieldName
                    )}
                    // プロダクトツアー用:  indexが2(最初のカラム)をIntercomのターゲットにする
                    isIntercomTarget={virtualRowItem.index === 2}
                    index={virtualRowItem.index}
                  />
                </BoxWithBg>
              ))
              .with({ type: "addFieldButton" }, () => <AddFieldButton />)
              .exhaustive()}
          </div>
        );
      })}
    </>
  );

  //   if (isReorderable && onReorderFields) {
  //     return (
  //       <DndContext onDragEnd={handleDragEnd} collisionDetection={pointerWithin}>
  //         <SortableContext items={fieldsOrder} strategy={rectSortingStrategy}>
  //           {_children}
  //         </SortableContext>
  //       </DndContext>
  //     );
  //   }

  return <>{_children}</>;
};

export { Header };
