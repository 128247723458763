import { ReactNode } from "react";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { Loadable } from "~/clientModel/loadable";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { TablesClientModel } from "~/clientModel/tables";
import { TableClientModel } from "~/clientModel/tables/table";
import { DropdownMenu } from "~/components_next/DropdownMenu";
import { ErrorFallback } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { Spinner } from "~/components_next/Spinner";

type TableAndFieldDropdownProps = {
  onSelect: ({
    table,
    field,
  }: {
    table: TableClientModel;
    field: FieldClientModel;
  }) => void;
  tablesLoadable: Loadable<TablesClientModel>;
  useFieldsLoadable: UseLoadable<{ tableSlug: string }, FieldsClientModel>;
  label?: string;
  trigger: ReactNode;
  renderTableItem: (table: TableClientModel) => ReactNode;
  renderFieldItem: (field: FieldClientModel) => ReactNode;
  isReadOnly?: boolean;
};

export const TableAndFieldDropdown = (props: TableAndFieldDropdownProps) => {
  const {
    tablesLoadable,
    useFieldsLoadable,
    onSelect,
    trigger,
    renderTableItem,
    renderFieldItem,
    label,
    isReadOnly,
  } = props;

  return (
    <InputStack label={label}>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <DropdownMenu.SelectLikeButton size="sm" isDisabled={isReadOnly}>
            {trigger}
          </DropdownMenu.SelectLikeButton>
        </DropdownMenu.Trigger>
        {!isReadOnly && (
          <DropdownMenu.Content>
            <WithFallback
              loadables={[tablesLoadable] as const}
              loadingFallback={<DropdownContentLoadingFallback />}
              errorFallback={(error) => <ErrorFallback error={error} />}
            >
              {([tables]) =>
                tables.allTables.map((table) => (
                  <FieldSubSelect
                    key={table.tableSlug}
                    table={table}
                    useFieldsLoadable={useFieldsLoadable}
                    onSelect={(field) =>
                      onSelect({
                        table,
                        field,
                      })
                    }
                    renderTableItem={renderTableItem}
                    renderFieldItem={renderFieldItem}
                  />
                ))
              }
            </WithFallback>
          </DropdownMenu.Content>
        )}
      </DropdownMenu.Root>
    </InputStack>
  );
};

type FieldSubSelectProps = {
  table: TableClientModel;
  onSelect: (field: FieldClientModel) => void;
  useFieldsLoadable: UseLoadable<{ tableSlug: string }, FieldsClientModel>;
  renderTableItem: (table: TableClientModel) => ReactNode;
  renderFieldItem: (field: FieldClientModel) => ReactNode;
};

const FieldSubSelect = (props: FieldSubSelectProps) => {
  const {
    table,
    onSelect,
    useFieldsLoadable,
    renderTableItem,
    renderFieldItem,
  } = props;

  const fieldsLoadable = useFieldsLoadable({
    tableSlug: table.tableSlug,
  });

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger>
        {renderTableItem(table)}
      </DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent>
        <WithFallback
          loadables={[fieldsLoadable] as const}
          loadingFallback={<DropdownContentLoadingFallback />}
          errorFallback={(error) => <ErrorFallback error={error} />}
        >
          {([fields]) =>
            fields.allFields.map((field) => (
              <DropdownMenu.Item
                key={field.name}
                onClick={() => onSelect(field)}
              >
                {renderFieldItem(field)}
              </DropdownMenu.Item>
            ))
          }
        </WithFallback>
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>
  );
};

const DropdownContentLoadingFallback = () => {
  return (
    <Flex
      align="center"
      justify="center"
      style={{ height: "160px", width: "160px" }}
    >
      <Spinner />
    </Flex>
  );
};
