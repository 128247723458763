import { useCallback, useState } from "react";
import { UseExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { Loadable } from "~/clientModel/loadable";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { RecordHistoriesClientModel } from "~/clientModel/recordHistories";
import {
  EditingRecordClientModel,
  EditingRecordClientModelFactory,
} from "~/clientModel/records/editingRecord";

import { RecordIdentifierClientModel } from "~/clientModel/records/record/recordIdentifier";
import { Tabs } from "~/components_next/Tabs";
import { EditRecordForm } from "~/presenters/records";
import { WithFallback } from "../../../../../../clientModel/loadable/WithFallback";
import { FooterButtonBase } from "../common/FooterButtonBase";
import { RightSidebarBase } from "../common/RightSidebarBase";
import { RecordHistoriesTabContent } from "./RecordHistoriesTabContent";

const tabLabels = ["Edit", "Histories"] as const;

type TabLabel = (typeof tabLabels)[number];

type EditRecordSidebarProps = {
  editingRecordLoadable: Loadable<EditingRecordClientModel>;
  onEditingRecordChange: (editingRecord: EditingRecordClientModel) => void;
  useUpdateRecordExecutable: UseExecutable<
    void,
    { editingRecord: EditingRecordClientModel }
  >;
  fieldsLoadable: Loadable<FieldsClientModel>;
  useUploadFileExecutable: UseExecutable<
    void,
    { file: File },
    {
      data: string;
      url: string;
    }
  >;
  useRecordHistoriesLoadable: UseLoadable<
    { recordIdentifier: RecordIdentifierClientModel },
    RecordHistoriesClientModel
  >;
};

export const EditRecordSidebar = (props: EditRecordSidebarProps) => {
  const {
    editingRecordLoadable,
    onEditingRecordChange,
    fieldsLoadable,
    useUpdateRecordExecutable,
    useUploadFileExecutable,
    useRecordHistoriesLoadable,
  } = props;

  const [tabValue, setTabValue] = useState<TabLabel>(tabLabels[0]);

  const updateRecordExecutable = useUpdateRecordExecutable();

  const handleUpdateRecord = useCallback(
    async (editingRecord: EditingRecordClientModel) => {
      await updateRecordExecutable.execute({ editingRecord });
      onEditingRecordChange(
        EditingRecordClientModelFactory.createFromClientRecordModel(
          editingRecord.editingRecord
        )
      );
    },
    [updateRecordExecutable, onEditingRecordChange]
  );

  return (
    <Tabs.Root
      value={tabValue}
      onChange={(value) => setTabValue(value as TabLabel)}
      size="md"
    >
      <RightSidebarBase
        sidebarType="editRecord"
        title={
          <Tabs.List>
            {tabLabels.map((label) => (
              <Tabs.Trigger key={label} value={label}>
                {label}
              </Tabs.Trigger>
            ))}
          </Tabs.List>
        }
        footer={
          <Tabs.Content value={tabLabels[0]}>
            <WithFallback
              loadables={[fieldsLoadable, editingRecordLoadable] as const}
            >
              {([fields, editingRecord]) => (
                <FooterButtonBase
                  onClick={() => handleUpdateRecord(editingRecord)}
                  isDisabled={!editingRecord.isEditingRecordValid(fields)}
                  isLoading={updateRecordExecutable.isExecuting}
                >
                  Save
                </FooterButtonBase>
              )}
            </WithFallback>
          </Tabs.Content>
        }
      >
        <Tabs.Content value={tabLabels[0]}>
          <WithFallback
            loadables={[fieldsLoadable, editingRecordLoadable] as const}
          >
            {([fields, editingRecord]) => (
              <EditRecordForm
                editingRecord={editingRecord}
                onEditingRecordChange={onEditingRecordChange}
                fields={fields}
                useUploadFileExecutable={useUploadFileExecutable}
              />
            )}
          </WithFallback>
        </Tabs.Content>
        <Tabs.Content value={tabLabels[1]}>
          <WithFallback loadables={[editingRecordLoadable] as const}>
            {([editingRecord]) =>
              // editingRecord.recordIdentifierBeforeEditはnon-nullであるが一応narrowingする
              editingRecord.recordIdentifierBeforeEdit && (
                <RecordHistoriesTabContent
                  useRecordHistoriesLoadable={useRecordHistoriesLoadable}
                  recordIdentifier={editingRecord.recordIdentifierBeforeEdit}
                />
              )
            }
          </WithFallback>
        </Tabs.Content>
      </RightSidebarBase>
    </Tabs.Root>
  );
};
