import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { Drawer } from "~/components_next/Drawer"
import { useErrorToast } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { Input } from "~/components_next/Input";
import { Text } from "~/components_next/Text";
import { TextArea } from "~/components_next/TextArea";
import { FlatFilterConditionForm } from "~/features/Fields/FilterCondition/FlatFilterConditionForm";
import { isFlatFilterCondition } from "~/features/Fields/FilterCondition/flatFilterConditionTypes";
import { useGetTableQuery, useSimpleFields, useUpdateTableMutation } from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTableSlug } from "~/utilHooks/useTableSlug";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { isArrayWithAtLeastOneElement } from "~/utils/commonTypeGuards";


type SourceSettingDrawerProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export const SourceSettingDrawer = (props: SourceSettingDrawerProps) => {
  const { isOpen, onOpenChange } = props

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const tableSlug = useTableSlug();

  const { data: tableData } = useQuery(
    useGetTableQuery({
      teamSlug,
      databaseId,
      tableSlug,
    })
  );

  const { data: simpleFields } = useSimpleFields({
    teamSlug,
    databaseId,
    tableSlug,
  });

  const [editingValue, setEditingValue] = useState<{
    displayName: string;
    description: string;
  }>({
    displayName: "",
    description: "",
  });

  useEffect(() => {
    if (tableData) {
      setEditingValue({
        displayName: tableData.displayName ?? "",
        description: tableData.comment ?? "",
      });
    }
  }, [tableData]);

  const { mutateAsync: updateTable, isLoading: isUpdatingTable } = useMutation({
    ...useUpdateTableMutation({
      teamSlug,
      databaseId,
      tableSlug,
    }),
  });

  const { errorToast } = useErrorToast({});

  const handleUpdateTable = () => {
    if (!tableData) {
      return;
    }
    try {
      updateTable({
        displayName: editingValue.displayName,
        comment: editingValue.description,
        isPrivate: tableData.isPrivate,
      });
    } catch (e) {
      errorToast(e);
    }
    onOpenChange(false);
  };

  if (!tableData || !simpleFields) {
    return <></>;
  }

  return (
    <>
      <Drawer.Root
        open={isOpen}
        onOpenChange={onOpenChange}
        size="xs"
      >
        <Drawer.Content>
          <Drawer.Title>Source Settings</Drawer.Title>

          <Drawer.Body>
            <Flex direction="column" align="stretch" gap="4">
              <Input
                variant="primary"
                label="Source Name"
                value={editingValue.displayName}
                onChange={(e) => {
                  setEditingValue((prev) => ({
                    ...prev,
                    displayName: e.target.value,
                  }));
                }}
              />
              <Text>Comment</Text>
              <TextArea
                variant="primary"
                value={editingValue.description}
                onChange={(e) => {
                  setEditingValue((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }));
                }}
              />

              {/* Lookup conditions */}
              {tableData.tableType === "jointable" &&
                tableData.joinTableCondition?.type === "query" &&
                isFlatFilterCondition(tableData.joinTableCondition.query.filter) &&
                isArrayWithAtLeastOneElement(simpleFields.fields) && (
                  <Box>
                    <Text>Filter conditions for lookup</Text>
                    <FlatFilterConditionForm
                      flatFilterCondition={tableData.joinTableCondition.query.filter}
                      simpleFields={simpleFields.fields}
                      isReadOnly
                    />
                  </Box>
                )}

              {tableData.tableType === "jointable" &&
                tableData.joinTableCondition?.type === "query" &&
                tableData.joinTableCondition.query.sort &&
                tableData.joinTableCondition.query.sort.length > 0 &&
                isArrayWithAtLeastOneElement(simpleFields.fields) && (
                  <Box>
                    <Text>Sort condition for lookup</Text>
                    {/* これもういらない？ */}
                    {/* Embedding sortの問題でコメントアウト */}
                    {/* <SortConditionForm
                sortCondition={tableData.joinTableCondition.query.sort[0]}
                simpleFields={simpleFields.fields}
                isReadOnly
              /> */}
                  </Box>
                )}

              {tableData.tableType === "jointable" &&
                tableData.joinTableCondition?.type === "sql" &&
                tableData.joinTableCondition.sql && (
                  <Box>
                    <Text>SQL for lookup</Text>
                    <TextArea
                      title="SQL for lookup"
                      variant="primary"
                      readOnly
                      disabled
                      value={tableData.joinTableCondition.sql}
                    />
                  </Box>
                )}
            </Flex>
          </Drawer.Body>

          <Drawer.Footer>
            <Button
              variant="primary"
              onClick={handleUpdateTable}
              isLoading={isUpdatingTable}
            >
              Save
            </Button>
          </Drawer.Footer>
        </Drawer.Content>

      </Drawer.Root>
    </>
  )
}
