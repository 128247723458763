import { forwardRef } from "react";
import { Flex } from "~/components_next/Flex";
import { Text } from "~/components_next/Text";
import { extractErrorDetails } from "../util/extractErrorDetails";

type ErrorFallbackProps = {
  error: unknown;
};

const ErrorFallback = forwardRef<HTMLDivElement, ErrorFallbackProps>(
  (props, ref) => {
    const { error } = props;

    const { title, description } = extractErrorDetails(error);

    return (
      <Flex
        ref={ref}
        direction="column"
        align="center"
        justify="center"
        height="100%"
        gap="3"
        p="4"
      >
        <Text variant="subheading">{title}</Text>
        <Text variant="description">{description}</Text>
      </Flex>
    );
  }
);

ErrorFallback.displayName = "ErrorFallback";

export { ErrorFallback };
