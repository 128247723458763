import { DashboardViewConditionObject } from "@usemorph/morph-dashboard-types";
import { Box } from "~/components_next/Box";
import { ErrorFallback } from "~/components_next/Error";
import { Menu } from "~/components_next/Menu";
import { SkeltonText } from "~/components_next/Skelton";
import { Text } from "~/components_next/Text";
import { usePromptSuggestions } from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

type PromptSuggestionsProps = {
  isOpen: boolean;
  onClickMenuItem: (message: string) => void;
} & (
  | {
      type: "table";
      tableSlug: string;
    }
  | {
      type: "view";
      viewId: string;
      condition?: DashboardViewConditionObject;
    }
);

const PromptSuggestions = (props: PromptSuggestionsProps) => {
  const { isOpen, onClickMenuItem, type } = props;
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const args =
    type === "table"
      ? {
          type,
          teamSlug,
          databaseId,
          tableSlug: props.tableSlug,
        }
      : {
          type,
          teamSlug,
          databaseId,
          viewId: props.viewId,
          condition: props.condition,
        };

  const { data, status, error } = usePromptSuggestions(args);

  if (!isOpen) return null;

  return (
    <Menu.Root>
      <Text ml="4" fontWeight="bold" variant="tinyDescription">
        Suggested Prompts
      </Text>

      {status === "loading" || status === "idle" ? (
        <Box mx="3" my="3">
          <SkeltonText size="sm" lines={5} />
        </Box>
      ) : status === "error" ? (
        <ErrorFallback error={error} />
      ) : (
        <>
          {data.messages.length > 0 ? (
            data.messages.map((message, i) => {
              return (
                <Menu.Item onSelect={() => onClickMenuItem(message)} key={i}>
                  {message}
                </Menu.Item>
              );
            })
          ) : (
            <Text my="4" style={{ textAlign: "center" }} variant="description">
              No suggestions
            </Text>
          )}
        </>
      )}
    </Menu.Root>
  );
};

export { PromptSuggestions };
