import { useMutation } from "react-query";
import useApiSQL from "~/api/useApiSQL";
import { Executable, useComposeExecutable } from "~/clientModel/executable";

import { useErrorToast } from "~/components_next/Error";
import { useSaveGenerateSqlHistoryMutation } from "~/serverStateStore";

type UseGenerateSqlFromPromptExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useGenerateSqlFromPromptExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseGenerateSqlFromPromptExecutableParams): Executable<
  { prompt: string },
  { sql: string }
> => {
  const { errorToast } = useErrorToast({});

  const { _generateSQL } = useApiSQL();

  const { mutateAsync: saveGenerateSqlHistory } = useMutation(
    useSaveGenerateSqlHistoryMutation({ teamSlug, databaseId, tableSlug })
  );

  return useComposeExecutable(
    useMutation({
      mutationFn: async ({ prompt }: { prompt: string }) => {
        const {
          result: { sql, action },
        } = await _generateSQL({
          teamSlug,
          databaseId,
          body: {
            message: prompt,
            tableSlug,
            extraTables: [], // todo
          },
        });

        if (action === "invalid") {
          throw new Error("Failed to generate SQL. Please try again.");
        }

        // サーバーで入れてもらう
        saveGenerateSqlHistory({
          message: prompt,
          sql,
        });

        return { sql };
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseGenerateSqlFromPromptExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
}) => {
  return () =>
    useGenerateSqlFromPromptExecutable({
      teamSlug,
      databaseId,
      tableSlug,
    });
};
