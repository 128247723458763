import { styled } from "~/stitches";
import { BorderedBox } from "../common/BorderedBox";
import {
  useRecordsTableBaseFields,
  useRecordsTableBaseHandleTableSelectionChange,
  useRecordsTableBaseRecords,
  useRecordsTableBaseTableSelection,
} from "../context/ReactTableBaseContext";
import { useTableSizings } from "../context/useTableSizing";
import { SelectBoxBase } from "./SelectBoxBase";

const BorderedBoxWithBg = styled(BorderedBox, {
  backgroundColor: "$bg1",
});

type HeaderSelectBoxProps = {
  hasRightBorder: boolean;
};

const HeaderSelectBox = (props: HeaderSelectBoxProps) => {
  const { hasRightBorder } = props;

  const records = useRecordsTableBaseRecords();
  const fields = useRecordsTableBaseFields();
  const tableSelection = useRecordsTableBaseTableSelection();
  const handleTableSelectionChange =
    useRecordsTableBaseHandleTableSelectionChange();

  const handleSelectAllClick = () => {
    if (isAllChecked) {
      handleTableSelectionChange(tableSelection.unselectAllRecords());
    } else {
      handleTableSelectionChange(
        tableSelection.selectRecords({ records: records.allRecords })
      );
    }
  };

  const { getHeaderRowHeight } = useTableSizings();

  const isAllChecked =
    records.count > 0 && tableSelection.isAllRecordsSelected({ records });

  const selectTooltip = isAllChecked ? "Deselect All" : "Select All";

  return (
    <BorderedBoxWithBg bottom right={hasRightBorder}>
      <SelectBoxBase
        isChecked={isAllChecked}
        onSelect={handleSelectAllClick}
        tooltip={selectTooltip}
        height={getHeaderRowHeight()}
      />
    </BorderedBoxWithBg>
  );
};

export { HeaderSelectBox };
