import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import {
  DashboardNotebookCellVariableRequestObject,
  RecordConditionRuleUnit,
  RecordFilterCondition,
  RecordFormatCondition,
  RecordSortConditionUnit,
} from "@usemorph/morph-dashboard-types";
import useApiPublicView from "~/api/useApiPublicView";
import { publicViewKeys } from "../keys";

type UseQueryViewRecordsQueryParams = {
  teamSlug: string;
  databaseId: string;
  viewId: string;
  publicApiKey: string;
  select: string[];
  join?: RecordConditionRuleUnit[];
  filter?: RecordFilterCondition;
  format?: RecordFormatCondition[];
  sort?: RecordSortConditionUnit[];
  limit?: number;
  skip?: number;
  // additionalFilter?: RecordFilterCondition;
  // additionalSort?: RecordSortConditionUnit[];
  variables?: DashboardNotebookCellVariableRequestObject;
};

const useQueryPublicViewRecordsQuery = ({
  databaseId,
  teamSlug,
  publicApiKey,
  viewId,
  select,
  join,
  filter,
  format,
  sort,
  limit,
  skip,
  // additionalFilter,
  // additionalSort,
  variables,
}: UseQueryViewRecordsQueryParams) => {
  const { _queryPublicViewRecords } = useApiPublicView();

  const client = useQueryClient();
  client.setQueryDefaults(
    publicViewKeys.allQueryViewRecords({ databaseId, teamSlug, viewId }),
    commonQueryOptions
  );

  return {
    queryKey: publicViewKeys.queryViewRecords({
      databaseId,
      teamSlug,
      viewId,
      select,
      join,
      filter,
      format,
      sort,
      limit,
      skip,
      // additionalFilter,
      // additionalSort,
      variables,
    }),
    queryFn: () => {
      return _queryPublicViewRecords({
        teamSlug,
        viewId,
        publicApiKey,
        body: {
          select,
          join,
          filter,
          format,
          sort,
          limit,
          skip,
          // additionalSort,
          // addtionalFilter: additionalFilter, // todo: バックエンド型定義のtypoの修正必要
          variables,
        },
      });
    },
  };
};

export { useQueryPublicViewRecordsQuery };
