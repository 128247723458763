import { BsPlus } from "react-icons/bs";
import { ListCanvasPageClientModel } from "~/clientModel/canvas/ListCanvasPageClientModel";
import { Executable } from "~/clientModel/executable";
import { Button } from "~/components_next/Button";
import { Flex } from "~/components_next/Flex";
import { Text } from "~/components_next/Text";

type CanvasDashboardPageProps = {
  createPageExecutable: Executable<
    {
      pageName: string;
      description: string;
      isPrivate: boolean;
    },
    ListCanvasPageClientModel
  >;
};

const CanvasDashboardEmptyPresenter = (props: CanvasDashboardPageProps) => {
  const { createPageExecutable } = props;
  const handleCreateFirstPage = () => {
    createPageExecutable.execute({
      pageName: "First Page",
      description: "",
      isPrivate: false,
    });
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      gap="4"
      css={{
        height: "100%",
        width: "100%",
      }}
    >
      <Text>This dashboard is empty. Create your first page.</Text>
      <Button variant="secondary" size="sm" onClick={handleCreateFirstPage}>
        <BsPlus /> Create Page
      </Button>
    </Flex>
  );
};

export { CanvasDashboardEmptyPresenter };
