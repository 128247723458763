import { useMutation } from "react-query";
import { useComposeExecutable } from "~/clientModel/executable";
import { useDeleteRssTableMappingMutation } from "~/serverStateStore/rssFeed/mutations/useDeleteRssTableMappingMutation";


const useDeleteRssTableMappingExecutable = (props: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
}) => {
  const { teamSlug, databaseId, tableSlug } = props

  const useDeleteRssTableMappingMutationOptions = useDeleteRssTableMappingMutation({
    teamSlug,
    databaseId,
    tableSlug,
  })

  return useComposeExecutable(
    useMutation({
      ...useDeleteRssTableMappingMutationOptions,
      mutationFn: async () => {
        return await useDeleteRssTableMappingMutationOptions.mutationFn()
      }
    })
  )
}

export const createUseDeleteRssTableMappingExecutable = (props: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
}) => {

  return () => useDeleteRssTableMappingExecutable(props)
}
