import { useQuery } from "react-query";
import { useListTableFavoritesQuery } from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { SidebarTableLinkItem } from "../LinkItems/SidebarTableLinkItem";

const Favorites = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const { data: favoriteTables } = useQuery({
    ...useListTableFavoritesQuery({
      databaseId,
      teamSlug,
    }),
  });

  return (
    <>
      {favoriteTables?.tables.map((table) => (
        <SidebarTableLinkItem
          key={table.tableSlug}
          databaseId={databaseId}
          table={table}
        ></SidebarTableLinkItem>
      ))}
    </>
  );
};

export { Favorites };
