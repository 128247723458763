import { BsPlus } from "react-icons/bs";
import SimpleBar from "simplebar-react";
import {
  useRecordsKanbanDraggingRecordId,
  useRecordsKanbanOnCreateClicked,
  useRecordsKanbanPropertyFieldNames,
  useRecordsKanbanTitleFieldName,
} from "../context/RecordsKanbanBaseContext";
import { KanbanValues } from "../RecordsKanbanBase";

import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { useDroppable } from "@dnd-kit/core";
import { getPrimaryKeyValues } from "../utils/getPrimaryKeyValues";
import { RecordsKanbanCard } from "../RecordsKanbanCard/RecordsKanbanCard";
import { Box, Flex } from "@radix-ui/themes";
import { styled } from "~/stitches";
import { Button } from "~/components_next/Button";

type RecordsKanbanColumnProps = {
  value: KanbanValues;
  width: number;
};

const StyledFlex = styled(Flex, {});
const StyledBox = styled(Box, {});

const RecordsKanbanColumn = (props: RecordsKanbanColumnProps) => {
  const { value, width } = props;

  const titleKey = useRecordsKanbanTitleFieldName();
  const propertyKeys = useRecordsKanbanPropertyFieldNames();

  const { setNodeRef } = useDroppable({ id: String(value.groupingFieldValue) });

  const columnId = String(value.groupingFieldValue);

  const recordIds = value.records.map((record) => {
    return getPrimaryKeyValues(record);
  });

  const draggingRecordId = useRecordsKanbanDraggingRecordId();

  const onCreateClicked = useRecordsKanbanOnCreateClicked();

  const handleOnCreateClicked = () => {
    if (onCreateClicked) {
      onCreateClicked(value.groupingFieldValue);
    }
  };

  return (
    <>
      <StyledFlex direction="column" css={{ width }} align="stretch" gap="2">
        <SimpleBar
          style={{
            maxHeight: "calc(100vh - 200px)",
          }}
        >
          <SortableContext
            id={columnId}
            items={recordIds}
            strategy={rectSortingStrategy}
          >
            <Flex direction="column" align="stretch" gap="2" ref={setNodeRef}>
              {value.records.map((record, i) => {
                // getPrimaryKeyValues(record) === draggingRecordId
                return (
                  <StyledBox
                    key={i}
                    css={{
                      opacity:
                        getPrimaryKeyValues(record) === draggingRecordId
                          ? 0
                          : 1,
                    }}
                  >
                    <RecordsKanbanCard
                      record={record}
                      titleKey={titleKey}
                      propertyKeys={propertyKeys}
                    />
                  </StyledBox>
                );
              })}
              {value.records.length === 0 && <Box width="100%" height="2" />}
            </Flex>
          </SortableContext>
        </SimpleBar>

        <Button variant="actionText" onClick={handleOnCreateClicked}>
          <BsPlus />
          Create Record
        </Button>
      </StyledFlex>
    </>
  );
};

export { RecordsKanbanColumn };
