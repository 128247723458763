import {
  DashboardViewResponse,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { useQuery } from "react-query";
import { match, P } from "ts-pattern";
import { useGetViewFieldsQuery } from "~/serverStateStore/views";
import { parseViewSetting } from "../../../features/View/viewSetting/setting";

type UseViewFieldsParams = {
  teamSlug: string;
  databaseId: string;
  viewId: string;
  viewData: DashboardViewResponse | undefined;
};

type SelectData = {
  fields: SimpleField[];
  // kanban view用
  titleField: SimpleField;
  displayedPropertyFields: SimpleField[];
  allPropertyFields: SimpleField[];
};

// view.setting.hiddenFields → isHidden
// view.setting.fieldsOrder → fieldsの順番
// をそれぞれ加工して返す
const useViewFields = ({
  teamSlug,
  databaseId,
  viewId,
  viewData,
}: UseViewFieldsParams) => {
  return useQuery({
    ...useGetViewFieldsQuery({
      teamSlug,
      databaseId,
      viewId,
    }),
    enabled: !!viewData,
    select: (data): SelectData => {
      const {
        fieldsOrder,
        hiddenFields,
        titleField: titleFieldSetting,
      } = parseViewSetting(viewData!.setting).data;

      const displayedFields = data.fields.map((field) => {
        const isHidden = hiddenFields?.some(
          (hiddenField) =>
            hiddenField.name === field.name &&
            hiddenField.originalTableSlug === field.originalTableSlug
        );
        return {
          ...field,
          isHidden,
        };
      });

      const orderedFields = match(fieldsOrder)
        .with(P.nullish, () => displayedFields)
        .with(P.not(P.nullish), (fieldsOrder) => {
          const getOrderIndex = (field: SimpleField) => {
            return fieldsOrder.findIndex(({ name, originalTableSlug }) => {
              return (
                field.originalTableSlug === originalTableSlug &&
                field.name === name
              );
            });
          };
          return [...displayedFields].sort(
            (a, b) => getOrderIndex(a) - getOrderIndex(b)
          );
        })
        .exhaustive();

      const findTitleField = titleFieldSetting
        ? orderedFields.find(
            ({ name, originalTableSlug }) =>
              name === titleFieldSetting.name &&
              originalTableSlug === titleFieldSetting.originalTableSlug
          )
        : undefined;

      const titleField = findTitleField ?? orderedFields[0];

      const allPropertyFields = orderedFields.filter(
        ({ name, originalTableSlug }) =>
          !(
            titleField.name === name &&
            titleField.originalTableSlug === originalTableSlug
          )
      );

      const displayedPropertyFields = allPropertyFields.filter(
        ({ isHidden }) => isHidden !== true
      );

      return {
        fields: orderedFields,
        titleField,
        allPropertyFields,
        displayedPropertyFields,
      };
    },
  });
};

export { useViewFields };
