import { Box, Code, Flex } from "@radix-ui/themes";
import {
  DashboardGenerateSqlHistoryObject,
  DashboardUserObject,
} from "@usemorph/morph-dashboard-types";
import dayjs from "dayjs";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";
import { Tooltip } from "~/components_next/Tooltip";
import { UserMention } from "~/components_next/User";

type PromptHistoryCellProps = {
  history: DashboardGenerateSqlHistoryObject;
  onHistoryClick: () => void;
};

const PromptHistoryCell = (props: PromptHistoryCellProps) => {
  const { history, onHistoryClick } = props;

  const dummyUser: DashboardUserObject = {
    email: "example@morphdb.io",
    username: "Morph User",
    userId: "morph_user",
    iconUrl: "/MorphLogoIcon.svg",
    firstName: "Morph",
    lastName: "User",
  };

  return (
    <Box
      px="2"
      py="3"
      onClick={onHistoryClick}
      style={{ cursor: "pointer" }}
      role="button"
    >
      <Flex direction="column" align="stretch" gap="3">
        {/* Date */}
        <Flex align="center">
          {history.createdBy ? (
            <UserMention user={history.createdBy} />
          ) : (
            <UserMention user={dummyUser} />
          )}

          <Spacer />
          <Tooltip content={history.createdAt}>
            <Text variant="description">
              {dayjs(history.createdAt).fromNow()}
            </Text>
          </Tooltip>
        </Flex>
        {/* todo: バッジで表示する */}
        <pre style={{ whiteSpace: "pre-wrap", font: "unset" }}>
          <Text fontWeight="bold">
            {history.message.replaceAll(/(\$\{|\})/g, "")}
          </Text>
        </pre>

        <Code>
          <pre style={{ padding: 20, overflowY: "auto" }}>{history.sql}</pre>
        </Code>
      </Flex>
    </Box>
  );
};

export { PromptHistoryCell };
