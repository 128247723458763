import { notebookKeys } from "../keys";
import useApiNotebook from "~/api/useApiNotebook";
import { useQueryClient } from "react-query";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseUpdateNotebookCellsMetaMutationParams {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
}

const useUpdateNotebookCellsMetaMutaiton = ({
  databaseId,
  teamSlug,
  notebookId,
}: UseUpdateNotebookCellsMetaMutationParams) => {
  const { _updateNotebookCellMeta } = useApiNotebook();

  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: (
      props: {
        cellId: string;
        afterCellId?: string | null;
        hidePrompt: boolean;
        settings?: any;
        parentIds?: string[] | null;
      }[]
    ) => {
      return Promise.all(
        props.map((prop) =>
          _updateNotebookCellMeta({
            teamSlug,
            notebookId,
            ...prop,
          })
        )
      );
    },
    onSuccess: () => {
      const keys = notebookKeys.findNotebook({
        databaseId,
        teamSlug,
        notebookId,
      });
      broadcast(keys);
      return invalidateAll(client, keys);
    },
  };
};

export { useUpdateNotebookCellsMetaMutaiton };
