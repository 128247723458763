import { DashboardViewConditionObject } from "@usemorph/morph-dashboard-types";
import { useCallback } from "react";
import { match } from "ts-pattern";
import { UseExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { Loadable } from "~/clientModel/loadable";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { FilterConditionsClientModel } from "~/clientModel/queryConditions/filterConditions";
import { PaginationClientModel } from "~/clientModel/queryConditions/pagination";
import { SortConditionsClientModel } from "~/clientModel/queryConditions/sortConditions";
import { RecordsClientModel } from "~/clientModel/records";
import { ClearAllFiltersAndSortsButton } from "~/features/SourceAndViews/common/components/QueryMode/Buttons/ClearAllFiltersAndSortsButton";
import { TablePaginationLimit } from "~/presenters/sourceAndViews/common/components/bottomItems/TablePagination";
import { ClearPromptButton } from "~/presenters/sourceAndViews/common/components/queryToolbar/buttons/ClearPromptButton";
import { ClearSQLButton } from "~/presenters/sourceAndViews/common/components/queryToolbar/buttons/ClearSQLButton";
import { FiltersAndSortsDropdown } from "~/presenters/sourceAndViews/common/components/queryToolbar/filtersAndSorts/FiltersAndSortsDropdown";
import { QueryToolbarLayout } from "~/presenters/sourceAndViews/common/components/queryToolbar/layout/QueryToolbarLayout";
import { PromptDropdown } from "~/presenters/sourceAndViews/common/components/queryToolbar/prompt/PromptDropdown";
import { PromptHistoryButton } from "~/presenters/sourceAndViews/common/components/queryToolbar/prompt/PromptHisoryButton";
import { SQLDropdown } from "~/presenters/sourceAndViews/common/components/queryToolbar/sql/SQLDropdown";
import { CanvasVariablesValue } from "../../common/CanvasVariablesProvider";

export type CanvasTableViewQueryConditions = {
  filterConditions: FilterConditionsClientModel;
  sortConditions: SortConditionsClientModel;
  prompt: string;
  sql: string;
  queryingSql: string;
  pagination: PaginationClientModel<TablePaginationLimit>;
};

type CanvasTableViewToolbarProps = {
  // loadable
  recordsLoadable: Loadable<RecordsClientModel>;
  fieldsLoadable: Loadable<FieldsClientModel>;

  // data
  queryConditions: CanvasTableViewQueryConditions;
  onQueryConditionsChange: (
    queryConditions: CanvasTableViewQueryConditions
  ) => void;
  queryMode: "query" | "prompt" | "sql" | "default";
  onQueryModeChange: (
    queryMode: "query" | "prompt" | "sql" | "default"
  ) => void;
  onHistoryClick: () => void;
  onClearAllConditions: () => void;

  // input suggestions loadables
  viewId: string;
  viewCondition: DashboardViewConditionObject;
  variablesForTypeaheadLoadable?: Loadable<CanvasVariablesValue[]>;
  useSuggestedPromptsLoadable: UseLoadable<
    { condition: DashboardViewConditionObject; viewId: string },
    { messages: string[] },
    unknown
  >;
  // executables
  useGenerateSqlFromPromptExecutable: UseExecutable<
    void,
    { prompt: string },
    { sql: string }
  >;
};

const CanvasTableViewToolbar = (props: CanvasTableViewToolbarProps) => {
  const {
    recordsLoadable,
    fieldsLoadable,
    // data
    queryConditions,
    onQueryConditionsChange,
    queryMode,
    onQueryModeChange,
    onClearAllConditions,

    // input suggestions loadables
    viewId,
    viewCondition,
    variablesForTypeaheadLoadable,
    useSuggestedPromptsLoadable,

    // executables
    useGenerateSqlFromPromptExecutable,

    onHistoryClick,
  } = props;

  const { filterConditions, sortConditions, prompt, sql } = queryConditions;

  const handlePromptHistoryButtonClick = useCallback(() => {
    onHistoryClick();
  }, [onHistoryClick]);

  /**
   * Loadables
   */
  const suggestedPromptsLoadable = useSuggestedPromptsLoadable({
    condition: viewCondition,
    viewId,
  });

  /**
   * Query condtions handlers
   */
  const handleFilterConditionsChange = useCallback(
    (filterConditions: FilterConditionsClientModel) => {
      match([
        filterConditions.hasFilterConditions,
        sortConditions.hasSortConditions,
      ])
        .with([false, false], () => {
          onQueryModeChange("default");
          onQueryConditionsChange({
            ...queryConditions,
            filterConditions,
          });
        })
        .otherwise(() => {
          onQueryModeChange("query");
          onQueryConditionsChange({
            ...queryConditions,
            filterConditions,
          });
        });
    },
    [
      onQueryConditionsChange,
      onQueryModeChange,
      queryConditions,
      sortConditions.hasSortConditions,
    ]
  );

  const handleSortConditionsChange = useCallback(
    (sortConditions: SortConditionsClientModel) => {
      match([
        filterConditions.hasFilterConditions,
        sortConditions.hasSortConditions,
      ])
        .with([false, false], () => {
          onQueryModeChange("default");
          onQueryConditionsChange({
            ...queryConditions,
            sortConditions,
          });
        })
        .otherwise(() => {
          onQueryModeChange("query");
          onQueryConditionsChange({
            ...queryConditions,
            sortConditions,
          });
        });
    },
    [
      filterConditions.hasFilterConditions,
      onQueryConditionsChange,
      onQueryModeChange,
      queryConditions,
    ]
  );

  const handlePromptChange = useCallback(
    (prompt: string) => {
      onQueryConditionsChange({
        ...queryConditions,
        prompt,
      });
    },
    [onQueryConditionsChange, queryConditions]
  );

  const handlePromptClear = useCallback(() => {
    onQueryModeChange("default");
    onQueryConditionsChange({
      ...queryConditions,
      prompt: "",
      sql: "",
      queryingSql: "",
    });
  }, []);

  const handleSqlGenerated = useCallback(
    (generatedSql: string) => {
      onQueryModeChange("prompt");
      onQueryConditionsChange({
        ...queryConditions,
        sql: generatedSql,
        queryingSql: generatedSql,
      });
    },
    [onQueryConditionsChange, onQueryModeChange, queryConditions]
  );

  const handleSqlChange = useCallback(
    (sql: string) => {
      onQueryConditionsChange({
        ...queryConditions,
        sql,
      });
    },
    [onQueryConditionsChange, queryConditions]
  );

  const handleSqlRun = useCallback(() => {
    onQueryModeChange("sql");
    onQueryConditionsChange({
      ...queryConditions,
      sql,
      queryingSql: sql,
    });
  }, [onQueryConditionsChange, onQueryModeChange, queryConditions, sql]);

  const handleSqlClear = useCallback(() => {
    onQueryModeChange("default");
    onQueryConditionsChange({
      ...queryConditions,
      sql: "",
      queryingSql: "",
    });
  }, [onQueryConditionsChange, onQueryModeChange, queryConditions]);

  const generateSqlFromPromptExecutable = useGenerateSqlFromPromptExecutable();

  /**
   * UI
   */
  const isDefaultMode = queryMode === "default";
  const isQueryMode = queryMode === "query";
  const isPromptMode = queryMode === "prompt";
  const isSQLMode = queryMode === "sql";

  return (
    <QueryToolbarLayout>
      {(isDefaultMode || isQueryMode) && (
        <FiltersAndSortsDropdown
          fieldsLoadable={fieldsLoadable}
          filterConditions={filterConditions}
          sortConditions={sortConditions}
          onFilterConditionsChange={handleFilterConditionsChange}
          onSortConditionsChange={handleSortConditionsChange}
          variables={variablesForTypeaheadLoadable?.data}
        />
      )}
      {isQueryMode && (
        <ClearAllFiltersAndSortsButton
          onClick={() => {
            // onQueryConditionsChange({
            //   ...queryConditions,
            //   filterConditions: filterConditions.clearAllFilterConditions(),
            //   sortConditions: sortConditions.clearAllSortConditions(),
            // });
            onClearAllConditions();
            onQueryModeChange("default");
          }}
        />
      )}

      {(isDefaultMode || isPromptMode) && (
        <PromptDropdown
          suggestedPromptsLoadable={suggestedPromptsLoadable}
          variablesForTypeaheadLoadable={variablesForTypeaheadLoadable}
          prompt={prompt}
          isActive={queryMode === "prompt"}
          onPromptChange={handlePromptChange}
          fieldsForTypeaheadLoadable={fieldsLoadable}
          promptRightButtons={
            <>
              <PromptHistoryButton onClick={handlePromptHistoryButtonClick} />
            </>
          }
          generateSqlFromPromptExecutable={generateSqlFromPromptExecutable}
          onSqlGenerated={handleSqlGenerated}
        />
      )}
      {isPromptMode && (
        <ClearPromptButton onClick={() => onClearAllConditions()} />
      )}

      {(isDefaultMode || isSQLMode) && (
        <SQLDropdown
          sql={sql}
          onSqlRun={handleSqlRun}
          onSqlChange={handleSqlChange}
          isActive={queryMode === "sql"}
          isSqlRunning={
            queryMode === "sql" && recordsLoadable.status === "loading"
          }
          suggestedFieldsLoadable={fieldsLoadable}
        />
      )}
      {isSQLMode && <ClearSQLButton onClick={() => onClearAllConditions()} />}
      {/* <FieldsSettingDropdown
        fieldsLoadable={fieldsLoadable}
        useUpdateFieldVisibilityExecutable={useUpdateFieldVisibilityExecutable}
        useUpdateFieldsOrderExecutable={useUpdateFieldsOrderExecutable}
        getFieldLabel={(field) => field.label}
      /> */}
    </QueryToolbarLayout>
  );
};

export { CanvasTableViewToolbar };
