import { useKey } from "react-use";
import { useCoworkerSelectionUtil } from "../common/useCoworkerSelectionUtil";
import {
  useRecordsTableBaseFields,
  useRecordsTableBaseHandleCellClickEvent,
  useRecordsTableBaseHandleCellEditingEnd,
  useRecordsTableBaseHandleTableSelectionChange,
  useRecordsTableBaseRecords,
  useRecordsTableBaseTableSelection,
} from "../context/ReactTableBaseContext";
import {
  arrowDownEventPred,
  arrowLeftEventPred,
  arrowRightEventPred,
  arrowUpEventPred,
  copyKeyPred,
  enterEventNotComposingPred,
  escapeEventPred,
  shiftArrowDownEventPred,
  shiftArrowLeftEventPred,
  shiftArrowRightEventPred,
  shiftArrowUpEventPred,
} from "./keyboardCommandsUtil";

const useKeyActions = () => {
  // data
  const records = useRecordsTableBaseRecords();
  const fields = useRecordsTableBaseFields();

  // state
  const tableSelection = useRecordsTableBaseTableSelection();
  const handleTableSelectionChange =
    useRecordsTableBaseHandleTableSelectionChange();
  const handleCellSelectionsChange =
    useRecordsTableBaseHandleTableSelectionChange();
  const { handleCellEditingEnd, isCellUpdating } =
    useRecordsTableBaseHandleCellEditingEnd();
  const handleCellClickEvent = useRecordsTableBaseHandleCellClickEvent();
  const { isCellLockedByCoworker } = useCoworkerSelectionUtil();

  // Copy event
  useKey(
    copyKeyPred,
    () => {
      // todo: セル選択とレコード選択が同時にあり得るので、UI検討(とりあえずセル選択を優先する)

      if (tableSelection.hasCellSelection) {
        const clipboardValue = tableSelection.getCellSelectionClipboardValue({
          records,
        });
        navigator?.clipboard.writeText(clipboardValue);
      } else if (tableSelection.isSomeRecordSelected) {
        const clipboardValue = tableSelection.getRecordSelectionClipboardValue({
          records,
          fields,
        });
        navigator?.clipboard.writeText(clipboardValue);
      }
    },
    {},
    [tableSelection, records]
  );

  // Arrow Events
  // Up
  useKey(
    arrowUpEventPred,
    () => {
      if (tableSelection.isCellEditing) {
        return;
      }

      const cellSelectionState = tableSelection.taggedCellSelectionState;

      if (cellSelectionState.type !== "single") {
        return;
      }

      const upperRecord = records.getUpperRecord(
        cellSelectionState.recordIdentifier
      );

      if (!upperRecord) {
        return;
      }

      if (
        isCellLockedByCoworker({
          recordIdentifier: upperRecord.recordIdentifier,
          fieldName: cellSelectionState.fieldName,
        })
      ) {
        return;
      }

      handleTableSelectionChange(
        tableSelection.selectSingleCell({
          recordIdentifier: upperRecord.recordIdentifier,
          fieldName: cellSelectionState.fieldName,
        })
      );
    },
    {},
    [tableSelection, records, fields]
  );

  // Down
  useKey(
    arrowDownEventPred,
    () => {
      if (tableSelection.isCellEditing) {
        return;
      }

      const cellSelectionState = tableSelection.taggedCellSelectionState;

      if (cellSelectionState.type !== "single") {
        return;
      }

      const downerRecord = records.getDownerRecord(
        cellSelectionState.recordIdentifier
      );

      if (!downerRecord) {
        return;
      }

      if (
        isCellLockedByCoworker({
          recordIdentifier: downerRecord.recordIdentifier,
          fieldName: cellSelectionState.fieldName,
        })
      ) {
        return;
      }

      handleTableSelectionChange(
        tableSelection.selectSingleCell({
          recordIdentifier: downerRecord.recordIdentifier,
          fieldName: cellSelectionState.fieldName,
        })
      );
    },
    {},
    [tableSelection, records, fields]
  );

  // Left
  useKey(
    arrowLeftEventPred,
    () => {
      if (tableSelection.isCellEditing) {
        return;
      }

      const cellSelectionState = tableSelection.taggedCellSelectionState;

      if (cellSelectionState.type !== "single") {
        return;
      }

      const leftField = fields.getPreviousVisibleField(
        cellSelectionState.fieldName
      );

      if (!leftField) {
        return;
      }

      if (
        isCellLockedByCoworker({
          recordIdentifier: cellSelectionState.recordIdentifier,
          fieldName: leftField.name,
        })
      ) {
        return;
      }

      handleTableSelectionChange(
        tableSelection.selectSingleCell({
          recordIdentifier: cellSelectionState.recordIdentifier,
          fieldName: leftField.name,
        })
      );
    },
    {},
    [tableSelection, records, fields]
  );
  // Right
  useKey(
    arrowRightEventPred,
    () => {
      if (tableSelection.isCellEditing) {
        return;
      }

      const cellSelectionState = tableSelection.taggedCellSelectionState;

      if (cellSelectionState.type !== "single") {
        return;
      }

      const rightField = fields.getNextVisibleField(
        cellSelectionState.fieldName
      );

      if (!rightField) {
        return;
      }

      if (
        isCellLockedByCoworker({
          recordIdentifier: cellSelectionState.recordIdentifier,
          fieldName: rightField.name,
        })
      ) {
        return;
      }

      handleTableSelectionChange(
        tableSelection.selectSingleCell({
          recordIdentifier: cellSelectionState.recordIdentifier,
          fieldName: rightField.name,
        })
      );
    },
    {},
    [tableSelection, records, fields]
  );

  // Shift + Arrow Event
  // Up
  useKey(
    shiftArrowUpEventPred,
    () => {
      const cellSelectionState = tableSelection.taggedCellSelectionState;

      if (cellSelectionState.type === "none" || tableSelection.isCellEditing) {
        return;
      }

      const currentSelectedRecordIdentifiers =
        cellSelectionState.type === "single"
          ? [cellSelectionState.recordIdentifier]
          : cellSelectionState.recordIdentifiers;
      const theTopRecordIdentifier = records.getTheTopRecordIdentifier(
        currentSelectedRecordIdentifiers
      );

      const addedRecord = records.getUpperRecord(theTopRecordIdentifier);

      if (!addedRecord) {
        return;
      }

      handleTableSelectionChange(
        tableSelection.addVerticalCellToSelection({
          recordIdentifier: addedRecord.recordIdentifier,
        })
      );
    },
    {},
    [
      tableSelection,
      records,
      handleTableSelectionChange,
      handleCellSelectionsChange,
    ]
  );

  useKey(
    shiftArrowDownEventPred,
    () => {
      const cellSelectionState = tableSelection.taggedCellSelectionState;

      if (cellSelectionState.type === "none" || tableSelection.isCellEditing) {
        return;
      }

      const currentSelectedRecordIdentifiers =
        cellSelectionState.type === "single"
          ? [cellSelectionState.recordIdentifier]
          : cellSelectionState.recordIdentifiers;
      const theBottomRecordIdentifier = records.getTheBottomRecordIdentifier(
        currentSelectedRecordIdentifiers
      );

      const addedRecord = records.getDownerRecord(theBottomRecordIdentifier);

      if (!addedRecord) {
        return;
      }

      handleTableSelectionChange(
        tableSelection.addVerticalCellToSelection({
          recordIdentifier: addedRecord.recordIdentifier,
        })
      );
    },
    {},
    [
      tableSelection,
      records,
      handleTableSelectionChange,
      handleCellSelectionsChange,
    ]
  );

  useKey(
    shiftArrowLeftEventPred,
    () => {
      const cellSelectionState = tableSelection.taggedCellSelectionState;

      if (cellSelectionState.type === "none" || tableSelection.isCellEditing) {
        return;
      }

      const currentSelectedFieldNames =
        cellSelectionState.type === "single"
          ? [cellSelectionState.fieldName]
          : cellSelectionState.fieldNames;
      const theLeftmostField = fields.getTheLeftmostFieldName(
        currentSelectedFieldNames
      );
      const leftField = fields.getPreviousVisibleField(theLeftmostField.name);

      if (!leftField) {
        return;
      }

      handleTableSelectionChange(
        tableSelection.addHorizontalCellToSelection({
          fieldName: leftField.name,
        })
      );
    },
    {},
    [
      tableSelection,
      records,
      handleTableSelectionChange,
      handleCellSelectionsChange,
    ]
  );

  useKey(
    shiftArrowRightEventPred,
    () => {
      const cellSelectionState = tableSelection.taggedCellSelectionState;

      if (cellSelectionState.type === "none" || tableSelection.isCellEditing) {
        return;
      }

      const currentSelectedFieldNames =
        cellSelectionState.type === "single"
          ? [cellSelectionState.fieldName]
          : cellSelectionState.fieldNames;
      const theRightmostField = fields.getTheRightmostFieldName(
        currentSelectedFieldNames
      );
      const rightField = fields.getNextVisibleField(theRightmostField.name);

      if (!rightField) {
        return;
      }

      handleTableSelectionChange(
        tableSelection.addHorizontalCellToSelection({
          fieldName: rightField.name,
        })
      );
    },
    {},
    [
      tableSelection,
      records,
      handleTableSelectionChange,
      handleCellSelectionsChange,
    ]
  );

  useKey(
    enterEventNotComposingPred,
    () => {
      if (tableSelection.taggedEditingRecord.isEditing) {
        // 編集中にEnterが押されたら、編集を終了する
        // ただし、array, json, htmlの場合は終了しない

        const editingField = fields.getFieldByFieldName(
          tableSelection.taggedEditingRecord.editingRecordEntry.key
        );
        const ignoreFieldTypes = ["array", "json", "html"];

        if (
          editingField &&
          !ignoreFieldTypes.includes(editingField.type.type)
        ) {
          handleCellEditingEnd();
        }
      } else {
        const cellSelectionState = tableSelection.taggedCellSelectionState;
        if (
          cellSelectionState.type === "single" &&
          !isCellUpdating({
            recordIdentifier: cellSelectionState.recordIdentifier,
            fieldName: cellSelectionState.fieldName,
          })
        ) {
          const record = records.findRecordByRecordIdentifier(
            cellSelectionState.recordIdentifier
          );
          record &&
            handleCellClickEvent(
              "goEdit",
              record,
              cellSelectionState.fieldName
            );
        }
      }
    },
    {},
    [tableSelection, records, fields]
  );

  // Escape
  useKey(
    escapeEventPred,
    () => {
      if (tableSelection.isCellEditing) {
        handleCellEditingEnd();
        return;
      }

      const cellSelectionState = tableSelection.taggedCellSelectionState;

      if (
        cellSelectionState.type === "single" ||
        cellSelectionState.type === "multiple"
      ) {
        handleTableSelectionChange(tableSelection.clearAllCellSelection());
      }
    },
    {},
    [tableSelection, records, fields]
  );

  return {};
};

export { useKeyActions };
