import { ReactNode, useMemo } from "react";
import { match, P } from "ts-pattern";
import { FieldTypeClientModel } from "~/clientModel/fields/field/fieldType";
import { DropdownMenu } from "~/components_next/DropdownMenu";
import { FieldTypeLabelAndIcon } from "./FieldTypeLabelAndIcon";

const filterFieldTypeOptions = <T extends readonly FieldTypeClientModel[]>(
  options: readonly FieldTypeClientModel[],
  filter: FieldTypeSubSelectProps<T>["options"]
): readonly FieldTypeClientModel[] => {
  return match(filter)
    .with(P.nullish, () => options)
    .with({ excludes: P.select() }, (excludedTypes) => {
      return options.filter((option) => !excludedTypes.includes(option.type));
    })
    .with({ includes: P.select() }, (includedTypes) => {
      return options.filter((option) => includedTypes.includes(option.type));
    })
    .exhaustive();
};

type FieldTypeSubSelectProps<T extends readonly FieldTypeClientModel[]> = {
  onSelect: (fieldType: FieldTypeClientModel) => void;
  triggerContent: ReactNode;
  fieldTypeOptions: T;
  options?:
    | {
        includes: T[number]["type"][];
      }
    | {
        excludes: T[number]["type"][];
      }; // 指定がなければ全部
};

// text, numberなどの基本的なフィールドタイプを選択するためのサブセレクト
export const FieldTypeSubSelect = <T extends readonly FieldTypeClientModel[]>(
  props: FieldTypeSubSelectProps<T>
) => {
  const { triggerContent, fieldTypeOptions, onSelect, options } = props;

  const displayedFieldTypeOptions = useMemo(
    () => filterFieldTypeOptions(fieldTypeOptions, options),
    [fieldTypeOptions, options]
  );

  if (displayedFieldTypeOptions.length === 0) {
    return null;
  }

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger>{triggerContent}</DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent>
        {displayedFieldTypeOptions.map((type) => (
          <DropdownMenu.Item key={type.type} onClick={() => onSelect(type)}>
            <FieldTypeLabelAndIcon type={type} />
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>
  );
};
