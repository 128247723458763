import { simpleFieldsKeys } from "../keys";

// types
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiFields from "~/api/useApiField";

interface UseListSimpleFieldsQueryParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
}

const useListSimpleFieldsQuery = ({
  databaseId,
  tableSlug,
  teamSlug,
}: UseListSimpleFieldsQueryParams) => {
  const { _listSimpleFields } = useApiFields();

  const client = useQueryClient();

  client.setQueryDefaults(
    simpleFieldsKeys.getSimpleField({
      databaseId,
      tableSlug,
      teamSlug,
    }),
    commonQueryOptions
  );

  return {
    queryKey: simpleFieldsKeys.getSimpleField({
      databaseId,
      tableSlug,
      teamSlug,
    }),
    queryFn: () => {
      return _listSimpleFields({
        databaseId,
        teamSlug,
        tableSlug,
      });
    },
    enabled: !!teamSlug && !!databaseId && !!tableSlug,
  };
};

export { useListSimpleFieldsQuery };
