import { RssField, RssFieldClientModel, } from "./RssFieldClientModel";

export const RssFieldClientModelFactory = {
  fromRssFieldResponse: (
    data: RssField
  ): RssFieldClientModel => {
    return new RssFieldClientModel(data);
  },

  createEmpty(): RssFieldClientModel {
    return new RssFieldClientModel({
      title: "",
      link: "",
      description: "",
      pubDate: "",
      image: "",
    });
  }
}
