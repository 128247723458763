import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import {
  FieldsClientModel,
  FieldsClientModelFactory,
} from "~/clientModel/fields";
import {
  useComposeLoadable,
  Loadable,
  fromLoadables,
} from "~/clientModel/loadable";
import {
  PaginationClientModel,
  PaginationClientModelDefactory,
} from "~/clientModel/queryConditions/pagination";
import {
  RecordsClientModel,
  RecordsClientModelFactory,
} from "~/clientModel/records";
import { TablePaginationLimit } from "~/presenters/sourceAndViews/common/components/bottomItems/TablePagination";
import { useListRecordsWithSQLQuery } from "~/serverStateStore";

type UseSourceRecordsAndFieldsWithSQLLoadableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  queryingSql: string;
  pagination: PaginationClientModel<TablePaginationLimit>;
};

export const useSourceRecordsAndFieldsWithSQLLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
  queryingSql,
  pagination,
}: UseSourceRecordsAndFieldsWithSQLLoadableParams): {
  recordsLoadable: Loadable<RecordsClientModel>;
  fieldsLoadable: Loadable<FieldsClientModel>;
} => {
  const listRecordsWithSqlLoadable = useComposeLoadable(
    useQuery({
      ...useListRecordsWithSQLQuery({
        teamSlug,
        databaseId,
        tableSlug,
        sql: queryingSql,
        limit: PaginationClientModelDefactory.toLimit(pagination),
        skip: PaginationClientModelDefactory.toSkip(pagination),
      }),
      enabled: !!queryingSql,
    })
  );

  const recordsLoadable = fromLoadables(
    [listRecordsWithSqlLoadable] as const,
    useCallback(
      (listRecordsWithSql) =>
        RecordsClientModelFactory.createFromQueryRecordListResponseWithFields(
          listRecordsWithSql
        ),
      []
    )
  );

  const fieldsLoadable = fromLoadables(
    [listRecordsWithSqlLoadable] as const,
    useCallback(
      (listRecordsWithSql) =>
        FieldsClientModelFactory.createFromSimpleFields(
          listRecordsWithSql.fields
        ),
      []
    )
  );
  return useMemo(
    () => ({
      recordsLoadable,
      fieldsLoadable,
    }),
    [recordsLoadable, fieldsLoadable]
  );
};
