import { TableNameClientModel } from "./TableNameClientModel";

export class TableNameClientModelFactory {
  static create(
    displayName: string,
    tableSlug: string,
    description: string,
    isAutoGenerateEnabled: boolean
  ) {
    return new TableNameClientModel(
      displayName,
      tableSlug,
      description,
      isAutoGenerateEnabled
    );
  }
}
