import { notebookKeys } from "../../notebook/keys";
import { useQueryClient } from "react-query";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import useApiPage from "~/api/useApiPage";

interface UseDeleteNotebookPageMutationParams {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
}

const useDeleteNotebookPageMutaiton = ({
  databaseId,
  teamSlug,
  notebookId,
}: UseDeleteNotebookPageMutationParams) => {
  const { _deletePage } = useApiPage();

  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({ pageId }: { pageId: string }) => {
      return _deletePage({
        teamSlug,
        pageId,
      });
    },
    onSuccess: () => {
      const keys = notebookKeys.findNotebook({
        databaseId,
        teamSlug,
        notebookId,
      });

      broadcast(keys);

      return invalidateAll(client, keys);
    },
  };
};

export { useDeleteNotebookPageMutaiton };
