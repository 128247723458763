import { ForwardedRef, forwardRef, ReactNode, MouseEvent } from "react";

import {
  Flex,
  withBreakpoints,
  Popover as RadixThemePopover,
} from "@radix-ui/themes";
import classNames from "classnames";
import {
  useMultiSelectInputRef,
  useMultiSelectOnOpenChange,
  useMultiSelectSize,
} from "./MultiSelectContext";
import { convertSize } from "./util";

type MultiSelectInputContainerProps = { children: ReactNode };

const MultiSelectInputContainer = forwardRef(
  (
    props: MultiSelectInputContainerProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const { children } = props;

    const size = useMultiSelectSize();

    const inputRef = useMultiSelectInputRef();
    const onOpenChange = useMultiSelectOnOpenChange();

    const handleClick = (event: MouseEvent<HTMLInputElement>) => {
      onOpenChange(true);
      inputRef.current?.focus();
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <RadixThemePopover.Trigger>
        <Flex
          ref={ref}
          className={classNames(
            "rt-reset",
            "morph-MultiSelectInputContainer",
            withBreakpoints(convertSize(size), "morph-r-size")
          )}
          onClick={handleClick}
        >
          {children}
        </Flex>
      </RadixThemePopover.Trigger>
    );
  }
);

MultiSelectInputContainer.displayName = "MultiSelectInputContainer";

export { MultiSelectInputContainer, type MultiSelectInputContainerProps };
