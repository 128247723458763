import {
  DashboardCheckUsernameExistResponse,
  DashboardMeResponse,
  DashboardUpdateMeRequestBody,
} from "@usemorph/morph-dashboard-types";
import { AxiosError } from "axios";
import { getPath } from "~/routing";
import useApi from "./useApi";

export default function useApiMe() {
  const { executeRequest } = useApi();

  // todo:  /apiは、APIコールのためのレイヤーなので
  // リダイレクト処理などのAPIコール以外のロジックを剥がす
  const _getMe = async () => {
    return executeRequest<DashboardMeResponse>("get", "/me").catch(
      (error: AxiosError | any) => {
        //is any pattern
        if (error.error && error.subCode) {
          if (error.error === "auth_error") {
            if (error.subCode === 3 && location.pathname !== "/auth/signup") {
              // CANNOT USE location.href because infinity loop happens
              // location.href = `${window.location.origin}/auth/signup`;
              throw error;
            } else if (
              error.subCode !== 3 &&
              location.pathname !== getPath("expire", {})
            ) {
              location.href = `${window.location.origin}${getPath(
                "expire",
                {}
              )}`;
              throw error;
            }
          }
          throw error.response.data;
        }
        //AxiosError
        if (error.response && error.response.data) {
          if (
            error.response.data.error &&
            error.response.data.error === "auth_error"
          ) {
            if (
              error.response.data.subCode === 3 &&
              location.pathname !== "/auth/signup"
            ) {
              // CANNOT USE location.href because infinity loop happens
              location.href = `${window.location.origin}/auth/signup`;
              throw error.response.data;
            } else if (
              error.response.data.subCode !== 3 &&
              location.pathname !== getPath("expire", {})
            ) {
              location.href = `${window.location.origin}${getPath(
                "expire",
                {}
              )}`;
            }
          }
          throw error.response.data;
        }
        throw {
          error: -1,
          subCode: -1,
        };
      }
    );
  };

  const _createMe = async ({
    username,
    firstName,
    lastName,
    iconUrl,
  }: {
    username: string;
    firstName: string;
    lastName: string;
    iconUrl?: string;
  }): Promise<DashboardMeResponse> => {
    return executeRequest<DashboardMeResponse>(
      "POST",
      "/me",
      undefined,
      undefined,
      {
        username,
        firstName,
        lastName,
        iconUrl,
      }
    );
  };

  const _updateMe = async ({
    requestBody,
  }: {
    requestBody: DashboardUpdateMeRequestBody;
  }): Promise<DashboardMeResponse> => {
    return executeRequest<DashboardMeResponse>(
      "PUT",
      "/me",
      undefined,
      undefined,
      {
        ...requestBody,
      }
    );
  };
  const _checkUserName = async ({
    username,
  }: {
    username: string;
  }): Promise<DashboardCheckUsernameExistResponse> => {
    return executeRequest<DashboardCheckUsernameExistResponse>(
      "GET",
      "/username",
      {
        username,
      },
      undefined,
      undefined
    );
  };

  return {
    _getMe,
    _createMe,
    _updateMe,
    _checkUserName,
  };
}
