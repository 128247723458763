import { tablesKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiTable from "~/api/useApiTable";

interface UseListNormalizedTableScheduleQueryParams {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
}

const useListNormalizedTableScheduleQuery = ({
  databaseId,
  teamSlug,
  tableSlug,
}: UseListNormalizedTableScheduleQueryParams) => {
  const { _listNormalizedTableSchedule } = useApiTable();

  const client = useQueryClient();
  client.setQueryDefaults(
    tablesKeys.listNormalizedTableSchedule({ databaseId, teamSlug, tableSlug }),
    commonQueryOptions
  );

  return {
    queryKey: tablesKeys.listNormalizedTableSchedule({
      databaseId,
      teamSlug,
      tableSlug,
    }),
    queryFn: () => {
      return _listNormalizedTableSchedule({
        databaseId,
        teamSlug,
        tableSlug,
      });
    },
  };
};

export { useListNormalizedTableScheduleQuery };
