import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "react-query"
import { DatabaseClientModel } from "~/clientModel/database/DatabaseClientModel"
import { ListDatabaseClientModel } from "~/clientModel/database/listDatabaseClientModel"
import { Executable } from "~/clientModel/executable"
import { MessageClientModel } from "~/clientModel/general"
import { PaymentClientModel } from "~/clientModel/payment/PaymentClientModel"
import { Box } from "~/components_next/Box"
import { Flex } from "~/components_next/Flex"
import { Spacer } from "~/components_next/Spacer"
import { Text } from "~/components_next/Text"
import { DatabaseAddPopover } from "~/features/GlobalNavigation/components/DatabaseSelect/DatabaseAddPopover"
import { DatabaseOptionPopover } from "~/features/GlobalNavigation/components/DatabaseSelect/DatabaseOptionPopover"
import { DatabaseSelect } from "~/features/GlobalNavigation/components/DatabaseSelect/DatabaseSelect"
import { UseDisclosureReturn } from "~/hooks/useDisclosure"
import { CreateDatabaseMutationProps, DeleteDatabaseMutationProps, UpdateDatabaseMutationProps } from "~/serverStateStore"


interface DatabasePresenterProps {
  listDatabaseClientModel: ListDatabaseClientModel;
  listDatabaseRefetcher: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined) => Promise<QueryObserverResult<ListDatabaseClientModel, unknown>>
  paymentClientModel: PaymentClientModel;
  createDatabaseExecutable: Executable<CreateDatabaseMutationProps, DatabaseClientModel>
  updateDatabaseExecutable: Executable<UpdateDatabaseMutationProps, DatabaseClientModel>
  deleteDatabaseExecutable: Executable<DeleteDatabaseMutationProps, MessageClientModel>

  createDatabaseModal: UseDisclosureReturn;
  connectDatabaseModal: UseDisclosureReturn;
}

export const DatabasePresenter = (props: DatabasePresenterProps) => {
  const {
    listDatabaseClientModel,
    listDatabaseRefetcher,
    paymentClientModel,
    createDatabaseExecutable,
    updateDatabaseExecutable,
    deleteDatabaseExecutable,
    createDatabaseModal,
    connectDatabaseModal,
  } = props;

  return (
    <>
      <Box px="2">
        <Flex align="center" justify="between" mb="1" pr="2">
          <Text variant="subheading">Database</Text>
          <Spacer />
          <DatabaseOptionPopover
            listDatabaseClientModel={listDatabaseClientModel}
            updateDatabaseExecutable={updateDatabaseExecutable}
            deleteDatabaseExecutable={deleteDatabaseExecutable}
          />
          <Box mr="2" />
          <DatabaseAddPopover
            paymentClientModel={paymentClientModel}
            createDatabaseExecutable={createDatabaseExecutable}
            createDatabaseModal={createDatabaseModal}
            connectDatabaseModal={connectDatabaseModal}
            listDatabaseRefetcher={listDatabaseRefetcher}
          />
        </Flex>
        <DatabaseSelect />
      </Box>
    </>
  );
};
