import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box } from "@radix-ui/themes";
import {
  RecordCard,
  RecordCardProps,
} from "~/components_next/RecordCard/RecordCard";
import { styled } from "~/stitches";
import { useRecordsKanbanOnClickRecord } from "../context/RecordsKanbanBaseContext";
import { getPrimaryKeyValues } from "../utils/getPrimaryKeyValues";

const StyledBox = styled(Box);

type RecrodsKanbanCardProps = RecordCardProps;

const RecordsKanbanCard = (props: RecrodsKanbanCardProps) => {
  const { record } = props;

  const primaryKeyValues = getPrimaryKeyValues(record);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: primaryKeyValues });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const onClickRecord = useRecordsKanbanOnClickRecord();

  const handleClickRecord = () => {
    if (onClickRecord) {
      onClickRecord(record);
    }
  };

  return (
    <StyledBox
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      css={style}
      onClick={handleClickRecord}
    >
      <RecordCard {...props} />
    </StyledBox>
  );
};

export { RecordsKanbanCard };
