import { ReactNode } from "react";
import { Box } from "~/components_next/Box";
import { Text } from "~/components_next/Text";
import { styled } from "~/stitches";

const OutlinedBox = styled(Box, {
  borderRadius: "4px",
  border: "1px solid $border",
});

type OutlinedWrapperProps = {
  title?: string;
  children: ReactNode;
};

export const OutlinedWrapper = (props: OutlinedWrapperProps) => {
  const { title, children } = props;

  return (
    <OutlinedBox px="3" py="4">
      {title && (
        <Text
          variant="description"
          mb="3"
          style={{ display: "inline-block", textTransform: "capitalize" }}
        >
          {title}
        </Text>
      )}
      {children}
    </OutlinedBox>
  );
};
