import { useQueryClient } from "react-query";
import useApiRecord from "~/api/useApiRecord";
import { recordsKeys } from "../../records/keys";

// types
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseEmptyTrashParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
}

const useEmptyTrashMutation = ({
  databaseId,
  tableSlug,
  teamSlug,
}: UseEmptyTrashParams) => {
  const { _emptyTrash } = useApiRecord();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: () => {
      return _emptyTrash({
        databaseId,
        teamSlug,
        tableSlug,
      });
    },
    onSuccess: () => {
      const getRecordKey = recordsKeys.allGetRecord({
        teamSlug,
        databaseId,
        tableSlug,
      })
      const getRecordWithSqlKey = recordsKeys.allGetRecordWithSql({
        teamSlug,
        databaseId,
        tableSlug,
      })
      broadcast(getRecordKey);
      broadcast(getRecordWithSqlKey);

      return invalidateAll(
        client,
        getRecordKey,
        getRecordWithSqlKey,
      );
    },
  };
};

export { useEmptyTrashMutation };
