import { css } from "@stitches/react";
import { FieldsClientModel } from "~/clientModel/fields";
import { RecordsClientModel } from "~/clientModel/records";
import { Button } from "~/components_next/Button";
import { Callout } from "~/components_next/Callout";
import { Drawer } from "~/components_next/Drawer";
import { Flex } from "~/components_next/Flex";
import { RecordsTable } from "~/components_next/RecordsTable";

const promptRootStyle = css({
  overflowY: "auto !important",
  height: "200px !important",
});

const promptTheme = {
  root: `${promptRootStyle()}`,
};

type FormulaTestRunResultDrawerProps = {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  records?: RecordsClientModel;
  fields?: FieldsClientModel;
};

export const FormulaTestRunResultDrawer = (
  props: FormulaTestRunResultDrawerProps
) => {
  const { isOpen, onOpenChange, records, fields } = props;

  return (
    <Drawer.Root open={isOpen} onOpenChange={onOpenChange} size="xl">
      <Drawer.Content>
        <Drawer.Title>Test run result</Drawer.Title>
        <Drawer.Body>
          <Flex direction="column" gap="4">
            <Callout
              size="sm"
              type="success"
              description="Your formula is valid."
            />
            {records && fields && (
              <RecordsTable
                records={records}
                fields={fields}
                isReadOnly
                noPadding
              />
            )}
          </Flex>
        </Drawer.Body>
        <Drawer.Footer>
          <Drawer.Close>
            <Button variant="tertiary">Close</Button>
          </Drawer.Close>
        </Drawer.Footer>
      </Drawer.Content>
    </Drawer.Root>
  );
};
