import { useMemo } from "react";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { SyncValueFieldTypeClientModel } from "~/clientModel/fields/field/fieldType";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { TablesClientModel } from "~/clientModel/tables";
import { TableClientModel } from "~/clientModel/tables/table";
import { Flex } from "~/components_next/Flex";
import { MergeKeyInput } from "../common/mergeKeyInput/MergeKeyInput";
import { TargetTableAndFieldInput } from "../common/TargetTableAndFieldInput";

type SyncValueSettingsInputProps = {
  table: TableClientModel;
  fields: FieldsClientModel;
  field: FieldClientModel;
  editingFieldsForUpdateMergeKey?: FieldsClientModel; // read only時不要
  tables: TablesClientModel;
  syncValueType: SyncValueFieldTypeClientModel;
  onFieldChange: (type: FieldClientModel) => void;
  onEditingFieldsForUpdateMergeKeyChange?: (fields: FieldsClientModel) => void; // read only時不要
  useFieldsLoadable: UseLoadable<{ tableSlug: string }, FieldsClientModel>;
  isReadOnly?: boolean;
};

export const SyncValueSettingsInput = (props: SyncValueSettingsInputProps) => {
  const {
    table,
    fields,
    field,
    editingFieldsForUpdateMergeKey,
    syncValueType,
    onFieldChange,
    onEditingFieldsForUpdateMergeKeyChange,
    tables,
    useFieldsLoadable,
    isReadOnly,
  } = props;

  const targetValue = useMemo(() => {
    if (
      syncValueType.syncTargetTableSlug &&
      syncValueType.syncTargetFieldName
    ) {
      return {
        targetTableSlug: syncValueType.syncTargetTableSlug,
        targetFieldName: syncValueType.syncTargetFieldName,
      };
    }

    return null;
  }, [syncValueType]);

  const handleTargetChange = ({
    targetTable,
    targetField,
  }: {
    targetTable: TableClientModel;
    targetField: FieldClientModel;
  }) => {
    onFieldChange(
      field.updateType(
        syncValueType
          .updateSyncTargetTableSlug(targetTable)
          .updateSyncTargetField(targetField)
      )
    );
  };

  const targetTable = syncValueType.syncTargetTableSlug
    ? tables.findTableByTableSlug(syncValueType.syncTargetTableSlug) ?? null
    : null;

  return (
    <Flex direction="column" gap="6">
      <TargetTableAndFieldInput
        value={targetValue}
        onChange={handleTargetChange}
        tables={tables}
        useFieldsLoadable={useFieldsLoadable}
        isReadOnly={isReadOnly}
      />
      {targetTable && (
        <MergeKeyInput
          table={table}
          fields={editingFieldsForUpdateMergeKey ?? fields}
          onFieldsChange={onEditingFieldsForUpdateMergeKeyChange}
          targetTable={targetTable}
          useFieldsLoadable={useFieldsLoadable}
          isReadOnly={isReadOnly}
        />
      )}
    </Flex>
  );
};
