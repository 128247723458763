import { Flex } from "@radix-ui/themes";
import { SimpleField } from "@usemorph/morph-dashboard-types";
import { Badge } from "~/components_next/Badge";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";
import { FieldTypeBadge } from "../Fields/FieldTypeBadge";

type FieldBadgesProps = {
  field: SimpleField;
};

const FieldBadges = (props: FieldBadgesProps) => {
  const { field } = props;

  return (
    <Flex direction="row" gap="2">
      <Text>{field.displayName || field.name}</Text>
      <Spacer />
      {field.primary === true && (
        <Badge variant="primary" size="xs">
          Primary
        </Badge>
      )}
      {field.nullable !== true && (
        <Badge size="xs" variant="primary">
          Required
        </Badge>
      )}
      <FieldTypeBadge size="xs" fieldType={field.type} />
    </Flex>
  );
};

export { FieldBadges };
