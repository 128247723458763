import {
  DashboardStorageBucketListResponse,
  DashboardStorageBucketResponse,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

type DashboardGeneralResponse = {
  message: string;
};

export default function useApiBucket() {
  const { executeRequest } = useApi();
  // storage bucket
  const _listBuckets = async ({
    teamSlug,
    bucketName,
    limit,
    skip,
  }: {
    teamSlug: string;
    bucketName?: string;
    limit?: number;
    skip?: number;
  }): Promise<DashboardStorageBucketListResponse> => {
    return executeRequest<DashboardStorageBucketListResponse>(
      "get",
      `/storage`,
      {
        limit,
        skip,
        bucketName,
      },
      {
        teamSlug,
      },
      undefined
    );
  };

  const _createBucket = async ({
    teamSlug,
    bucketName,
    readPermission,
    createPermission,
    updatePermission,
  }: {
    teamSlug: string;
    bucketName: string;
    readPermission: "forbidden" | "public" | "limitedPublic";
    createPermission: "forbidden" | "public" | "limitedPublic";
    updatePermission: "forbidden" | "public" | "limitedPublic";
  }): Promise<DashboardStorageBucketResponse> => {
    return executeRequest<DashboardStorageBucketResponse>(
      "post",
      `/storage`,
      undefined,
      {
        teamSlug,
      },
      {
        bucketName,
        readPermission,
        createPermission,
        updatePermission,
      }
    );
  };

  const _updateBucket = async ({
    bucketId,
    teamSlug,
    readPermission,
    createPermission,
    updatePermission,
  }: {
    bucketId: string;
    teamSlug: string;
    readPermission: "forbidden" | "public" | "limitedPublic";
    createPermission?: "forbidden" | "public" | "limitedPublic";
    updatePermission?: "forbidden" | "public" | "limitedPublic";
  }): Promise<DashboardStorageBucketResponse> => {
    return executeRequest<DashboardStorageBucketResponse>(
      "put",
      `/storage/${bucketId}`,
      undefined,
      {
        teamSlug,
      },
      {
        readPermission,
        createPermission,
        updatePermission,
      }
    );
  };

  const _deleteBucket = async ({
    bucketId,
    teamSlug,
  }: {
    bucketId: string;
    teamSlug: string;
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest<DashboardGeneralResponse>(
      "delete",
      `/storage/${bucketId}`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  return {
    _listBuckets,
    _createBucket,
    _updateBucket,
    _deleteBucket,
  };
}
