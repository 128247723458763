import {
  CreateRecordWithPromptResponse,
  DashboardRecordTrashListResponse,
  GroupQueryRecordListResponse,
  QueryRecordListResponse,
  QueryRecordWithFieldsResponse,
} from "@usemorph/morph-dashboard-types";

/**
 * todo: オーバーフロー対策
 */

const transformNumberToStringForQueryRecordListResponse = (
  rowResponse: string
) => {
  const parsedNormally = JSON.parse(rowResponse) as QueryRecordListResponse;
  const parsedJsonWithNumberAsString = JSON.parse(
    rowResponse,
    function (key, value) {
      return typeof value === "number" ? value.toString() : value;
    }
  ) as QueryRecordListResponse;

  return {
    ...parsedNormally,
    items: parsedJsonWithNumberAsString.items,
  };
};

const transformNumberToStringForCreateRecordWithPromptResponse = (
  rowResponse: string
) => {
  const parsedNormally = JSON.parse(
    rowResponse
  ) as CreateRecordWithPromptResponse;
  const parsedJsonWithNumberAsString = JSON.parse(
    rowResponse,
    function (key, value) {
      return typeof value === "number" ? value.toString() : value;
    }
  ) as CreateRecordWithPromptResponse;

  return {
    ...parsedNormally,
    records: parsedJsonWithNumberAsString.records,
  };
};

const transformNumberToStringForRecordTrashListResponse = (
  rowResponse: string
) => {
  const parsedNormally = JSON.parse(
    rowResponse
  ) as DashboardRecordTrashListResponse;
  const parsedJsonWithNumberAsString = JSON.parse(
    rowResponse,
    function (key, value) {
      return typeof value === "number" ? value.toString() : value;
    }
  ) as DashboardRecordTrashListResponse;

  return {
    ...parsedNormally,
    items: parsedNormally.items.map((normalItem, index) => ({
      ...normalItem,
      data: parsedJsonWithNumberAsString.items[index].data,
    })),
  };
};

const transformNumberToStringForQueryRecordWithFieldsResponse = (
  rowResponse: string
) => {
  const parsedNormally = JSON.parse(
    rowResponse
  ) as QueryRecordWithFieldsResponse;
  const parsedJsonWithNumberAsString = JSON.parse(
    rowResponse,
    function (key, value) {
      return typeof value === "number" ? value.toString() : value;
    }
  ) as QueryRecordWithFieldsResponse;

  return {
    ...parsedNormally,
    items: parsedJsonWithNumberAsString.items,
  };
};

const transformNumberToStringForGroupQueryViewRecordsResponse = (
  rowResponse: string
) => {
  const parsedNormally = JSON.parse(
    rowResponse
  ) as GroupQueryRecordListResponse;
  const parsedJsonWithNumberAsString = JSON.parse(
    rowResponse,
    function (key, value) {
      return typeof value === "number" ? value.toString() : value;
    }
  ) as GroupQueryRecordListResponse;

  return Object.entries(parsedNormally).reduce(
    (res, [fieldName, { count }]) => ({
      ...res,
      [fieldName]: {
        items: parsedJsonWithNumberAsString[fieldName].items,
        count,
      },
    }),
    {}
  );
};

export {
  transformNumberToStringForQueryRecordListResponse,
  transformNumberToStringForCreateRecordWithPromptResponse,
  transformNumberToStringForRecordTrashListResponse,
  transformNumberToStringForQueryRecordWithFieldsResponse,
  transformNumberToStringForGroupQueryViewRecordsResponse,
};
