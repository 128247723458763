import { LuEqual } from "react-icons/lu";

export const MergeKeyInputEqual = () => {
  return (
    <LuEqual
      size="16"
      color="gray"
      style={{ alignSelf: "center", justifySelf: "center" }}
    />
  );
};
