/* eslint-disable react/display-name */
import { memo } from "react";
import {
  SimpleField,
  TableHistoryDataCondition,
} from "@usemorph/morph-dashboard-types";
import {
  isAndCondition,
  isOrCondition,
} from "~/features/TableHistory/util/tableHistoryConditionUtils";
import { TableHistoryAndCondition } from "./TableHistoryAndCondition";
import { TableHistoryNormalCondition } from "./TableHistoryNormalCondition";
import { TableHistoryOrCondition } from "./TableHistoryOrCondition";
import { Flex } from "@radix-ui/themes";

export const TableHistoryConditionWrapper = memo(
  ({
    condition,
    fields,
  }: {
    condition: TableHistoryDataCondition;
    fields: SimpleField[];
  }) => {
    return (
      <Flex gap="1" style={{ flexWrap: "wrap" }}>
        {condition && isAndCondition(condition) && (
          <TableHistoryAndCondition condition={condition} fields={fields} />
        )}
        {condition && isOrCondition(condition) && (
          <TableHistoryOrCondition condition={condition} fields={fields} />
        )}
        {condition &&
          !isAndCondition(condition) &&
          !isOrCondition(condition) && (
            <TableHistoryNormalCondition
              condition={condition}
              fields={fields}
            />
          )}
      </Flex>
    );
  }
);
