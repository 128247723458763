interface LoadableInterface<DataType, ErrorType = unknown> {
  status: "success" | "error" | "loading" | "idle";
  data?: DataType;
  error?: ErrorType;
  refetch?: () => void; // refetch の返り値は使えないようにする
}

export class IdleLadable<DataType, ErrorType>
  implements LoadableInterface<DataType, ErrorType>
{
  status = "idle" as const;
  readonly #refetch: () => void;

  constructor(refetch: () => void) {
    this.#refetch = refetch;
  }

  get refetch(): (() => void) | undefined {
    return this.#refetch;
  }

  get data(): undefined {
    return undefined;
  }

  get error(): undefined {
    return undefined;
  }
}

export class ValueLoadable<DataType, ErrorType>
  implements LoadableInterface<DataType, ErrorType>
{
  status = "success" as const;
  private _data: DataType;

  constructor(data: DataType) {
    this._data = data;
  }

  get refetch(): undefined {
    return undefined;
  }

  get data(): DataType {
    return this._data;
  }

  get error(): undefined {
    return undefined;
  }
}

export class ErrorLoadable<DataType, ErrorType>
  implements LoadableInterface<DataType, ErrorType>
{
  status = "error" as const;
  private _error: ErrorType;

  constructor(error: ErrorType) {
    this._error = error;
  }

  get refetch(): undefined {
    return undefined;
  }

  get data(): undefined {
    return undefined;
  }

  get error(): ErrorType {
    return this._error;
  }
}

export class LoadingLoadable<DataType, ErrorType>
  implements LoadableInterface<DataType, ErrorType>
{
  status = "loading" as const;

  get refetch(): undefined {
    return undefined;
  }

  get data(): undefined {
    return undefined;
  }

  get error(): undefined {
    return undefined;
  }
}

export type Loadable<DataType, ErrorType = unknown> =
  | IdleLadable<DataType, ErrorType>
  | ValueLoadable<DataType, ErrorType>
  | ErrorLoadable<DataType, ErrorType>
  | LoadingLoadable<DataType, ErrorType>;
