import { Theme } from "@radix-ui/themes";
import { ReactNode } from "react";
// import "@radix-ui/themes/styles.css";

type RadixThemeProps = {
  children: ReactNode;
};

const RadixTheme = ({ children }: RadixThemeProps) => {
  return (
    <Theme
      accentColor="bronze"
      grayColor="slate"
      panelBackground="solid"
      radius="small"
      scaling="100%"
    >
      {children}
    </Theme>
  );
};

export { RadixTheme };
