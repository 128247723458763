import { sqlKeys } from "../keys";

// types
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiSQL from "~/api/useApiSQL";
import { DashboardViewConditionObject } from "@usemorph/morph-dashboard-types";

interface UseListSuggestPromptForGenerateSQLQueryParams {
  teamSlug: string;
  databaseId: string;
  tableSlug?: string;
  viewId?: string;
  language: string;
  condition?: DashboardViewConditionObject;
}

const useListSuggestPromptForGenerateSQLQuery = ({
  databaseId,
  tableSlug,
  viewId,
  teamSlug,
  language,
  condition,
}: UseListSuggestPromptForGenerateSQLQueryParams) => {
  const { _listSuggestPromptForGenerateSql } = useApiSQL();

  const client = useQueryClient();
  client.setQueryDefaults(
    sqlKeys.suggestPromptsForGenerateSql({
      databaseId,
      tableSlug,
      teamSlug,
      viewId,
      language,
      condition,
    }),
    { ...commonQueryOptions, staleTime: 1000 * 60 }
  );

  return {
    queryKey: sqlKeys.suggestPromptsForGenerateSql({
      databaseId,
      tableSlug,
      viewId,
      teamSlug,
      language,
      condition,
    }),
    queryFn: () => {
      return _listSuggestPromptForGenerateSql({
        databaseId,
        teamSlug,
        body: {
          tableSlug,
          viewId,
          language,
          condition,
        },
      });
    },
  };
};

export { useListSuggestPromptForGenerateSQLQuery };
