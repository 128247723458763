import { Box, Flex } from "@radix-ui/themes";
import {
  QueryRecordWithFieldsResponse,
  RecordConditionRuleUnit,
  RecordFilterCondition,
  RecordSortConditionUnit,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { match } from "ts-pattern";
import { extractErrorDetails } from "~/components_next/Error";
import { RecordsTableBase } from "~/components_next/RecordsTableBase";
import { RecordsTableBaseRecord } from "~/components_next/RecordsTableBase/types";
import { Callout } from "~/components_next/Callout";
import { Spinner } from "~/components_next/Spinner";
import { useListRecordsWithQueryQuery } from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import {
  convertDotNotatedSimpleFieldToSimpleField,
  convertSimpleFieldsToDotNotatedSimpleFields,
} from "../Fields/utils/converSimpleField";
import { convertRecordToRecordModel } from "../RecordModel";
import { HiddenFields } from "./HiddenFields";
import { MaskedFields } from "./MaskedFields";
import { DropdownMenu } from "~/components_next/DropdownMenu";

type DownloadConditions = {
  selectedFields: SimpleField[]; // dot notatedやunderscoredなどの変換がないSimpleField
  join?: RecordConditionRuleUnit[];
  sort?: RecordSortConditionUnit[];
  filter?: RecordFilterCondition;
  maskedFields: SimpleField[];
  limit?: number;
  skip?: number;
};

type DownloadRecordsPreviewProps = {
  mainTableSlug: string;
  allSimpleFields: SimpleField[]; //  dot notatedやunderscoredなどの変換がないSimpleField
  downloadConditions: DownloadConditions;
  onChangeCondition?: (downloadConditions: DownloadConditions) => void;
};

type SelectData = {
  recordsTableBaseRecord: RecordsTableBaseRecord[];
  fields: SimpleField[];
  count: number;
};

const DownloadRecordsPreviewWithQuery = (
  props: DownloadRecordsPreviewProps
) => {
  const {
    mainTableSlug,
    allSimpleFields,
    downloadConditions,
    onChangeCondition,
  } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const {
    data: queryRecordsData,
    status: queryRecordsStatus,
    error: queryRecordsError,
  } = useQuery({
    ...useListRecordsWithQueryQuery({
      teamSlug,
      databaseId,
      tableSlug: mainTableSlug,
      select: convertSimpleFieldsToDotNotatedSimpleFields(
        downloadConditions.selectedFields,
        mainTableSlug
      ).map(({ name }) => name),
      format: downloadConditions.maskedFields?.map(
        ({ name, originalTableSlug }) =>
          originalTableSlug === mainTableSlug
            ? {
                action: "mask",
                column: name,
              }
            : { action: "mask", column: `${originalTableSlug}.${name}` }
      ),
      filter: downloadConditions.filter,
      sort: downloadConditions.sort,
      join: downloadConditions.join,
    }),
    select: (data: QueryRecordWithFieldsResponse): SelectData => {
      return {
        recordsTableBaseRecord: data.items.map((record, index) => {
          return {
            values: convertRecordToRecordModel(record, data.fields),
            _reservedRecordIndex: index,
          };
        }),
        fields: data.fields,
        count: data.count,
      };
    },
  });

  const handleChangeFieldVisibility = (
    targetField: SimpleField,
    isHidden: boolean
  ) => {
    if (!onChangeCondition || !queryRecordsData) return;

    const normalizedTargetField =
      convertDotNotatedSimpleFieldToSimpleField(targetField);

    const updatedSelectedField = match(isHidden)
      .with(true, () =>
        downloadConditions.selectedFields.filter(
          ({ name, originalTableSlug }) =>
            name !== normalizedTargetField.name ||
            originalTableSlug !== normalizedTargetField.originalTableSlug
        )
      )
      .with(false, () => [
        ...downloadConditions.selectedFields,
        normalizedTargetField,
      ])
      .exhaustive();

    const updatedMaskedFields = isHidden
      ? downloadConditions.maskedFields.filter(
          ({ name, originalTableSlug }) =>
            name !== normalizedTargetField.name ||
            originalTableSlug !== normalizedTargetField.originalTableSlug
        )
      : downloadConditions.maskedFields;

    onChangeCondition?.({
      ...downloadConditions,
      selectedFields: updatedSelectedField,
      maskedFields: updatedMaskedFields,
    });
  };

  const handleChangeFieldMask = (
    targetField: SimpleField,
    isMasked: boolean
  ) => {
    const normalizedTargetField =
      convertDotNotatedSimpleFieldToSimpleField(targetField);

    const updatedMaskedFields: SimpleField[] = match(isMasked)
      .with(true, () => [
        ...downloadConditions.maskedFields,
        normalizedTargetField,
      ])
      .with(false, () =>
        downloadConditions.maskedFields.filter(
          ({ name, originalTableSlug }) =>
            name !== normalizedTargetField.name ||
            originalTableSlug !== normalizedTargetField.originalTableSlug
        )
      )
      .exhaustive();

    // query conditionの更新
    onChangeCondition?.({
      ...downloadConditions,
      maskedFields: updatedMaskedFields,
    });
  };

  const getIsMasked = (field: SimpleField): boolean => {
    if (!downloadConditions.maskedFields) return false;
    return !!convertSimpleFieldsToDotNotatedSimpleFields(
      downloadConditions.maskedFields,
      mainTableSlug
    ).find(({ name }) => name === field.name);
  };

  const hiddenFields = useMemo(() => {
    return convertSimpleFieldsToDotNotatedSimpleFields(
      allSimpleFields,
      mainTableSlug
    ).filter(
      ({ name }) =>
        !convertSimpleFieldsToDotNotatedSimpleFields(
          downloadConditions.selectedFields,
          mainTableSlug
        ).find((selectedField) => selectedField.name === name)
    );
  }, [allSimpleFields, downloadConditions.selectedFields, mainTableSlug]);

  if (queryRecordsStatus === "loading" || queryRecordsStatus === "idle") {
    return (
      <Flex align="center" justify="center" height="100%">
        <Spinner />
      </Flex>
    );
  }

  if (queryRecordsStatus === "error") {
    const { title, description } = extractErrorDetails(queryRecordsError);
    return <Callout type="alert" title={title} description={description} />;
  }

  return (
    <Flex
      direction="column"
      height="100%"
      width="100%"
      align="stretch"
      gap="2"
      style={{ overflow: "hidden" }}
    >
      {hiddenFields.length > 0 && (
        <HiddenFields
          hiddenFields={hiddenFields}
          onShowField={(field) => handleChangeFieldVisibility(field, false)}
        />
      )}
      {downloadConditions.maskedFields.length > 0 && (
        <MaskedFields
          maskedFields={downloadConditions.maskedFields}
          onUnmaskField={(field) => handleChangeFieldMask(field, false)}
        />
      )}

      <Box style={{ overflow: "auto" }}>
        <RecordsTableBase
          editableFields="none"
          // fields={convertSimpleFieldsToDotNotatedSimpleFields(
          //   downloadConditions.selectedFields,
          //   mainTableSlug
          // )}
          fields={queryRecordsData.fields}
          records={queryRecordsData.recordsTableBaseRecord}
          headerDropdown={
            onChangeCondition
              ? (field) => {
                  return (
                    <>
                      <DropdownMenu.Item
                        onClick={() => handleChangeFieldVisibility(field, true)}
                      >
                        Hide this field
                      </DropdownMenu.Item>
                      {getIsMasked(field) ? (
                        <DropdownMenu.Item
                          onClick={() => handleChangeFieldMask(field, false)}
                        >
                          Unmask this field
                        </DropdownMenu.Item>
                      ) : (
                        <DropdownMenu.Item
                          onClick={() => handleChangeFieldMask(field, true)}
                        >
                          Mask this field
                        </DropdownMenu.Item>
                      )}
                    </>
                  );
                }
              : undefined
          }
        />
      </Box>
    </Flex>
  );
};

export { DownloadRecordsPreviewWithQuery, type DownloadConditions };
