import { useQueryClient } from "react-query";
import { tableFavoriteKeys } from "../keys";

// types
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import useApiTableFavorite from "~/api/useApiTableFavorite";

interface UseCreateTableFavoriteParams {
  teamSlug: string;
  databaseId: string;
}

const useCreateTableFavoriteMutation = ({
  teamSlug,
  databaseId,
}: UseCreateTableFavoriteParams) => {
  const { _createTableFavorite } = useApiTableFavorite();
  const client = useQueryClient();

  return {
    mutationFn: ({ tableSlug }: { tableSlug: string }) => {
      return _createTableFavorite({
        teamSlug,
        databaseId,
        tableSlug,
      });
    },
    onSuccess: () => {
      return invalidateAll(
        client,
        tableFavoriteKeys.allListTableFavorites({ teamSlug, databaseId })
      );
    },
  };
};

export { useCreateTableFavoriteMutation };
