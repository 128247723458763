import dayjs from "dayjs";
import { match } from "ts-pattern";
import { FieldsClientModel } from "~/clientModel/fields";
import { RecordsClientModel } from "~/clientModel/records";
import { RecordClientModel } from "~/clientModel/records/record";
import { FindUserClientModel } from "~/clientModel/user";

type RecordHistoryClientModelOperation =
  | "update"
  | "delete"
  | "create"
  | "bulkCreate";

type RecordHistoryClientModelData = {
  createdBy: FindUserClientModel | null;
  operatedAt: string;
  operation: RecordHistoryClientModelOperation;
  previousRecord: RecordClientModel | null;
  subsequentRecord: RecordClientModel;
  fields: FieldsClientModel;
};

export class RecordHistoryClientModel {
  constructor(private readonly data: RecordHistoryClientModelData) {}

  public get key(): string {
    return `${this.data.operatedAt}-${this.data.operation}`;
  }

  public get operatedBy(): FindUserClientModel | null {
    return this.data.createdBy;
  }

  public get fromNow(): string {
    return dayjs(this.data.operatedAt).fromNow();
  }

  public get operationDescription(): string {
    const subject = this.data.createdBy?.username;
    const verb = match(this.data.operation)
      .with("create", () => "created this record.")
      .with("bulkCreate", () => "created records in bulk.")
      .with("update", () => "updated this record.")
      .with("delete", () => "deleted this record.")
      .exhaustive();

    return subject
      ? `${subject} ${verb}`
      : `${verb[0].toUpperCase()}${verb.slice(1)}`;
  }

  public get previousRecord(): RecordsClientModel | null {
    if (!this.data.previousRecord) return null;
    return new RecordsClientModel({
      records: [this.data.previousRecord],
      totalCount: 1,
    });
  }

  public get subsequentRecord(): RecordsClientModel {
    return new RecordsClientModel({
      records: [this.data.subsequentRecord],
      totalCount: 1,
    });
  }

  public get fields(): FieldsClientModel {
    return this.data.fields;
  }
}
