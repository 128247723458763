import { CanvasCellViewClientModel } from "~/clientModel/canvas/CanvasCellClientModel";
import { Flex } from "~/components_next/Flex";
import { SimpleSelect } from "~/components_next/Select";
import { useUseViewFieldsLoadable } from "../../providers/PlaygroundUseSourceFIeldsLoadableProvider";
import { useCallback, useMemo } from "react";
import {
  DashboardNotebookCellPromptAxisObject,
  DashboardNotebookCellPromptObject,
} from "@usemorph/morph-dashboard-types";
import { CanvasVisualizationPromptConfigCientModel } from "~/clientModel/canvas/CanvasVisualizationPromptConfigClientModel";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { FieldClientModel } from "~/clientModel/fields/field";
import { AxisMultiSelect } from "../../createCell/prompt/AxisMultiSelect";
import { Image } from "~/components_next/Image";
import { Box } from "~/components_next/Box";

type SidebarPromptXYSelectViewProps = {
  parentCell: CanvasCellViewClientModel;
  visualizationConfig: CanvasVisualizationPromptConfigCientModel;
  xAxisObject?: DashboardNotebookCellPromptAxisObject[] | null;
  yAxisObject?: DashboardNotebookCellPromptAxisObject[] | null;
  onChange: ({
    xAxisObject,
    yAxisObject,
  }: {
    xAxisObject?: DashboardNotebookCellPromptAxisObject[] | null;
    yAxisObject?: DashboardNotebookCellPromptAxisObject[] | null;
  }) => void;
  chartTypeOptions?: CanvasVisualizationPromptConfigCientModel[];
};

export const SidebarPromptXYSelectView = (
  props: SidebarPromptXYSelectViewProps
) => {
  const {
    parentCell,
    xAxisObject,
    yAxisObject,
    visualizationConfig,
    onChange,
    chartTypeOptions,
  } = props;

  const useViewFieldsLoadable = useUseViewFieldsLoadable();

  const fieldsLoadable = useViewFieldsLoadable({
    viewId: parentCell.viewId || "",
  });

  const fieldsOptions = useMemo(() => {
    return (
      fieldsLoadable.data?.allFields.map((field) => {
        return {
          label: field.displayName || field.name,
          value: field.name,
        };
      }) || []
    );
  }, [fieldsLoadable]);

  // const xAxisFields = useMemo(() => {
  //   return (
  //     xAxisObject
  //       ?.map((axis) => {
  //         if (axis.field) {
  //           return fieldsLoadable.data?.allFields.find(
  //             (field) => field.name === axis.field
  //           );
  //         }
  //       })
  //       .filter((result): result is FieldClientModel => result !== undefined) ||
  //     []
  //   );
  // }, [xAxisObject, fieldsLoadable]);

  // const yAxisFields = useMemo(() => {
  //   return (
  //     yAxisObject
  //       ?.map((axis) => {
  //         if (axis.field) {
  //           return fieldsLoadable.data?.allFields.find(
  //             (field) => field.name === axis.field
  //           );
  //         }
  //       })
  //       .filter((result): result is FieldClientModel => result !== undefined) ||
  //     []
  //   );
  // }, [yAxisObject, fieldsLoadable]);

  /**
   * Multi select callbacks
   */
  const handleSelectXAxisFieldFromMultiSelect = useCallback(
    (value: FieldClientModel) => {
      onChange({
        xAxisObject: [
          ...(xAxisObject?.filter((object) => object.field !== value.name) ||
            []),
          {
            field: value.name,
          },
        ],
        yAxisObject,
      });
    },
    [onChange, xAxisObject, yAxisObject]
  );

  const handleDeselectXAxisFieldFromMultiSelect = useCallback(
    (value: FieldClientModel) => {
      onChange({
        xAxisObject: (xAxisObject || []).filter(
          (object) => object.field !== value.name
        ),
        yAxisObject,
      });
    },
    [onChange, xAxisObject, yAxisObject]
  );

  const handleSelectYAxisFieldFromMultiSelect = useCallback(
    (value: FieldClientModel) => {
      onChange({
        xAxisObject,
        yAxisObject: [
          ...(yAxisObject?.filter((object) => object.field !== value.name) ||
            []),
          {
            field: value.name,
          },
        ],
      });
    },
    [onChange, yAxisObject, xAxisObject]
  );

  const handleDeselectYAxisFieldFromMultiSelect = useCallback(
    (value: FieldClientModel) => {
      onChange({
        xAxisObject,
        yAxisObject: (yAxisObject || []).filter(
          (object) => object.field !== value.name
        ),
      });
    },
    [onChange, yAxisObject, xAxisObject]
  );

  const handleSelectXAxisFieldAndConfigFromMultiSelect = useCallback(
    (
      field: FieldClientModel,
      config: CanvasVisualizationPromptConfigCientModel
    ) => {
      onChange({
        xAxisObject: [
          ...(xAxisObject?.filter((object) => object.field !== field.name) ||
            []),
          {
            field: field.name,
            chartType:
              config.chartType as DashboardNotebookCellPromptObject["type"], // サーバー駆動にするために無理キャスト
          },
        ],
        yAxisObject,
      });
    },
    [onChange, xAxisObject, yAxisObject]
  );

  const handleSelectYAxisFieldAndConfigFromMultiSelect = useCallback(
    (
      field: FieldClientModel,
      config: CanvasVisualizationPromptConfigCientModel
    ) => {
      onChange({
        xAxisObject,
        yAxisObject: [
          ...(yAxisObject?.filter((object) => object.field !== field.name) ||
            []),
          {
            field: field.name,
            chartType:
              config.chartType as DashboardNotebookCellPromptObject["type"], // サーバー駆動にするために無理キャスト
          },
        ],
      });
    },
    [onChange, xAxisObject, yAxisObject]
  );

  const getBadgeLabel = useCallback(
    (axisObject: DashboardNotebookCellPromptAxisObject) => {
      const findField = fieldsLoadable.data?.allFields.find(
        (field) => field.name === axisObject.field
      );
      if (!findField) return "";
      return findField.displayName || findField.name;
    },
    [fieldsLoadable]
  );

  const getChartTypeIcon = useCallback(
    (field: DashboardNotebookCellPromptAxisObject) => {
      const findConfig = chartTypeOptions?.find(
        (config) => config.chartType === field.chartType
      );
      if (!findConfig) return null;
      return <Image src={findConfig.icon} width={16} height={16} />;
    },
    [chartTypeOptions]
  );

  return (
    <>
      <Flex direction="column" gap="2">
        <WithFallback loadables={[fieldsLoadable]}>
          {([fields]) => (
            <>
              <Box css={{ flex: 1 }}>
                {visualizationConfig.xAxisInputType === "single" && (
                  <SimpleSelect
                    label="X Axis"
                    size="sm"
                    variant="primary"
                    options={fieldsOptions}
                    value={xAxisObject?.[0]?.field || null}
                    onChange={(value) =>
                      onChange({
                        xAxisObject: [{ field: value || undefined }],
                        yAxisObject,
                      })
                    }
                  />
                )}
                {visualizationConfig.xAxisInputType === "multiple" && (
                  <AxisMultiSelect
                    fields={fields.allFields}
                    value={xAxisObject || null}
                    badgeLabelSelector={getBadgeLabel}
                    badgeIconSelector={getChartTypeIcon}
                    label="X Axis"
                    onSelectField={handleSelectXAxisFieldFromMultiSelect}
                    onDeselectField={handleDeselectXAxisFieldFromMultiSelect}
                    onSelectFieldAndConfig={
                      handleSelectXAxisFieldAndConfigFromMultiSelect
                    }
                    getIsFieldOptionSelected={(field) =>
                      Boolean(xAxisObject?.find((f) => f.field === field.name))
                    }
                    chartTypeOptions={chartTypeOptions}
                  />
                )}
              </Box>
              <Box css={{ flex: 1 }}>
                {visualizationConfig.yAxisInputType === "single" && (
                  <SimpleSelect
                    label="Y Axis"
                    size="sm"
                    variant="primary"
                    options={fieldsOptions}
                    value={yAxisObject?.[0]?.field || null}
                    onChange={(value) =>
                      onChange({
                        xAxisObject,
                        yAxisObject: [{ field: value || undefined }],
                      })
                    }
                  />
                )}
                {visualizationConfig.yAxisInputType === "multiple" && (
                  <AxisMultiSelect
                    fields={fields.allFields}
                    value={yAxisObject || null}
                    badgeLabelSelector={getBadgeLabel}
                    badgeIconSelector={getChartTypeIcon}
                    label="Y Axis"
                    onSelectField={handleSelectYAxisFieldFromMultiSelect}
                    onDeselectField={handleDeselectYAxisFieldFromMultiSelect}
                    onSelectFieldAndConfig={
                      handleSelectYAxisFieldAndConfigFromMultiSelect
                    }
                    getIsFieldOptionSelected={(field) =>
                      Boolean(yAxisObject?.find((f) => f.field === field.name))
                    }
                    chartTypeOptions={chartTypeOptions}
                  />
                )}
              </Box>
            </>
          )}
        </WithFallback>
      </Flex>
    </>
  );
};
