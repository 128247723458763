import { ReactElement, ReactNode } from "react";
import { Loadable } from "~/clientModel/loadable";

const isDev = process.env.NODE_ENV === "development";

type WithFallbackProps<DataArray extends readonly unknown[]> = {
  loadables: { [I in keyof DataArray]: Loadable<DataArray[I]> };
  loadingFallback?: ReactElement;
  errorFallback?: (error: unknown) => ReactElement;
  debugMode?: "loading" | "error";
  children: (dataArray: DataArray) => ReactNode;
};

export const WithFallback = <DataArray extends readonly unknown[]>(
  props: WithFallbackProps<DataArray>
) => {
  const { loadables, loadingFallback, errorFallback, debugMode, children } =
    props;

  if (
    loadables.some(
      (loadable) => loadable.status === "loading" || loadable.status === "idle"
    ) ||
    (isDev && debugMode === "loading")
  ) {
    return loadingFallback ?? null;
  }

  if (
    loadables.some((loadable) => loadable.status === "error") ||
    (isDev && debugMode === "error")
  ) {
    const error = loadables.find(
      (loadable) => loadable.status === "error"
    )?.error;
    return errorFallback ? <>{errorFallback(error)}</> : null;
  }

  // ここちょっと検討
  const dataArray = loadables.map(
    (loadable) => loadable.data
  ) as readonly unknown[] as DataArray;

  return <>{children(dataArray)}</>;
};
