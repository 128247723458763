import { DashboardTeamObject } from "@usemorph/morph-dashboard-types";
import { BsPeople } from "react-icons/bs";
import { styled } from "~/stitches";
import { AccessAuthorityCard } from "./AccessAuthorityCard";
import { AccessAuthority } from "./types";

const PeopleIcon = styled(BsPeople, {
  width: "20px",
  height: "20px",
  color: "$slate10",
});

type GeneralAccessAuthorityCardProps = {
  team?: DashboardTeamObject;
  generalAccess: AccessAuthority;
  onGeneralAccessChange: (authority: AccessAuthority) => void;
};

export const GeneralAccessAuthorityCard = (
  props: GeneralAccessAuthorityCardProps
) => {
  const { team, generalAccess, onGeneralAccessChange } = props;

  return (
    <AccessAuthorityCard
      title={`Everyone in ${team?.teamName}`}
      description={`${team?.user.length || "?"} members`}
      authority={generalAccess}
      onChangeAuthority={onGeneralAccessChange}
      authorityOptions={["Read & Write", "No Access"]}
      leftIcon={<PeopleIcon />}
    />
  );
};
