import { FieldClientModel } from "../fields/field";

export class CsvImportSchemaFieldClientModel {
  readonly #field: FieldClientModel;
  readonly #colNum: number;

  constructor(field: FieldClientModel, colNum: number) {
    this.#field = field;
    this.#colNum = colNum;
  }

  get field() {
    return this.#field;
  }

  get colNum() {
    return this.#colNum;
  }
}
