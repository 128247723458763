import { QueryRecordRequestBody } from "@usemorph/morph-dashboard-types";

type ExecuteDataApiDependenciesBase = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

type ExecuteDataApiDependencies = {
  apiKey: string;
  body: QueryRecordRequestBody;
} & ExecuteDataApiDependenciesBase;

const executeDataApiKeys = {
  all: ({ teamSlug, databaseId }: ExecuteDataApiDependenciesBase) =>
    ["executeWidgetData", teamSlug, databaseId] as const,

  /**
   * get widget data apis
   */
  execute: ({
    teamSlug,
    databaseId,
    tableSlug,
    apiKey,
    body,
  }: ExecuteDataApiDependencies) =>
    [
      ...executeDataApiKeys.all({ teamSlug, databaseId, tableSlug }),
      apiKey,
      body,
    ] as const,
};

export { executeDataApiKeys };
