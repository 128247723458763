import { useMemo, useRef } from "react";
import { match, P } from "ts-pattern";
import { CsvImportSchemaClientModel } from "~/clientModel/csvImport";
import { Executable } from "~/clientModel/executable";
import { StorageObjectClientModel } from "~/clientModel/storage/object";
import { Button } from "~/components_next/Button";
import { Callout } from "~/components_next/Callout";
import { Flex } from "~/components_next/Flex";

type CsvImportFileUploadProps = {
  createObjectExecutable: Executable<
    { key: string; contentType: string; file: File },
    StorageObjectClientModel
  >;
  createImportSchemaExecutable: Executable<
    {
      sheet?: number | undefined;
    },
    CsvImportSchemaClientModel,
    unknown
  >;
};

const CsvImportFileUpload = (props: CsvImportFileUploadProps) => {
  const { createObjectExecutable, createImportSchemaExecutable } = props;

  const fileInputRef = useRef<HTMLInputElement>(null); // file input要素用のref

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      createObjectExecutable.execute({
        key: file.name,
        contentType: file.type,
        file,
      });
    }
  };

  const importSchemaErrorMessage = useMemo(() => {
    return match(createImportSchemaExecutable.error)
      .with({ data: { message: P.string } }, (error) => {
        return error.data.message;
      })
      .otherwise(() => undefined);
  }, [createImportSchemaExecutable]);

  return (
    <>
      <Flex direction="column" gap="4" py="2" width="100%">
        {importSchemaErrorMessage && (
          <Callout
            type="alert"
            title="CSV file error"
            description={String(importSchemaErrorMessage)}
          />
        )}
        <Button
          variant="primary"
          size="md"
          style={{ width: "100%" }}
          onClick={() => fileInputRef.current?.click()}
          isLoading={
            createObjectExecutable.isExecuting ||
            createImportSchemaExecutable.isExecuting
          }
        >
          Upload
        </Button>

        <input
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          accept={
            "(CSV).csv, .csv, text/csv, .xls, .xlsx, 	application/vnd.ms-excel"
          }
          onChange={handleChangeFile}
        />
      </Flex>
    </>
  );
};

export { CsvImportFileUpload, type CsvImportFileUploadProps };
