import { ForwardedRef, forwardRef, ReactNode, useMemo } from "react";
import {
  useMultiSelectOnChange,
  useMultiSelectSize,
  useMultiSelectValue,
} from "./MultiSelectContext";
import { Tag } from "~/components_next/Tag";
import { match, P } from "ts-pattern";

type MultiSelectTagProps = {
  children: ReactNode;
  value: string;
};

const MultiSelectTag = forwardRef(
  (props: MultiSelectTagProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { children, value } = props;

    const size = useMultiSelectSize();
    const multiSelectValue = useMultiSelectValue();
    const onChange = useMultiSelectOnChange();

    const handleClear = useMemo(() => {
      if (!onChange) return undefined;

      return () => {
        const updatedMultiSelectValue = multiSelectValue.filter(
          (v) => v !== value
        );
        onChange(updatedMultiSelectValue);
      };
    }, [multiSelectValue, onChange, value]);

    const tagSize = match(size)
      .with(P.union("xs", "sm", "md"), (s) => s)
      .with(P.union("lg", "xl"), () => "md" as const)
      .exhaustive();

    return (
      <Tag ref={ref} size={tagSize} variant="tertiary" onClear={handleClear}>
        {children}
      </Tag>
    );
  }
);

MultiSelectTag.displayName = "MultiSelectTag";

export { MultiSelectTag, type MultiSelectTagProps };
