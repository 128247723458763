import { FieldTypeClientModel } from "~/clientModel/fields/field/fieldType";
import { Flex } from "~/components_next/Flex";
import { FieldIcon } from "~/components_next/Icons/components/FieldIcon";

type FieldTypeLabelAndIconProps = {
  type: FieldTypeClientModel;
};

export const FieldTypeLabelAndIcon = (props: FieldTypeLabelAndIconProps) => {
  const { type } = props;
  return (
    <Flex align="center" gap="3">
      <FieldIcon type={type} />
      {type.fieldTypeLabel}
    </Flex>
  );
};
