import { BsPin } from "react-icons/bs";
import { LuPlus } from "react-icons/lu";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import {
  DropdownMenu,
  SimpleDropdownMenu,
} from "~/components_next/DropdownMenu";
import { IconButton } from "~/components_next/IconButton";
import {
  usePlaygroundCanvasPages,
  usePlaygroundUseAddCellToPageExecutable,
  usePlaygroundUseCreatePageExecutable,
} from "../../providers/PlaygroundAddCellToPageExecutableProvider";
import { PlaygroundCellActionButton } from "./PlaygroundCellActionButton";

type PlaygroundCellAddToPageButtonProps = {
  cellClientModel: CanvasCellClientModelUnion;
};

const PlaygroundCellAddToPageButton = (
  props: PlaygroundCellAddToPageButtonProps
) => {
  const { cellClientModel } = props;

  const pages = usePlaygroundCanvasPages();
  const useAddCellToPageExecutable = usePlaygroundUseAddCellToPageExecutable();
  const addCellToPageExecutable = useAddCellToPageExecutable();

  const useCreatePageExecutable = usePlaygroundUseCreatePageExecutable();
  const createPageExecutable = useCreatePageExecutable();

  const handleCreateFirstPage = async () => {
    const page = await createPageExecutable.execute({
      pageName: "First page",
      description: "",
      isPrivate: false,
    });
    if (page) {
      addCellToPageExecutable.execute({
        notebookCellId: cellClientModel.cellId,
        pageId: page.pageId,
        x: 0,
        y: 0,
        w: 6,
        h: 6,
      });
    }
  };

  return (
    <PlaygroundCellActionButton>
      <SimpleDropdownMenu
        trigger={
          <IconButton size="lg" icon={<BsPin />} tooltip="Add to page" />
        }
      >
        <DropdownMenu.Label>Add to dashboard</DropdownMenu.Label>
        {pages.length === 0 && (
          <DropdownMenu.Item onClick={() => handleCreateFirstPage()}>
            <LuPlus />
            Create your first page on dashboard
          </DropdownMenu.Item>
        )}
        {pages.map((page) => {
          return (
            <DropdownMenu.Item
              key={page.pageId}
              onClick={() =>
                addCellToPageExecutable.execute({
                  notebookCellId: cellClientModel.cellId,
                  pageId: page.pageId,
                  x: 0,
                  y: 0,
                  w: 6,
                  h: 6,
                })
              }
            >
              {page.pageName}
            </DropdownMenu.Item>
          );
        })}
      </SimpleDropdownMenu>
    </PlaygroundCellActionButton>
  );
};

export { PlaygroundCellAddToPageButton };
