import { useQuery } from "react-query";
import SimpleBar from "simplebar-react";
import { Box } from "~/components_next/Box";
import { Container } from "~/components_next/Container";
import { Grid } from "~/components_next/Grid";
import { Text } from "~/components_next/Text";
import {
  useListAllTablesHistoryQuery,
  useListTablesQuery,
} from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { RecentUpdatesTableHistoryCell } from "./components/RecentUpdatesTableHistoryCell";

const DatabaseHome = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const { data: allTableHistory } = useQuery(
    useListAllTablesHistoryQuery({
      teamSlug,
      databaseId,
    })
  );

  const { data: listTables } = useQuery({
    ...useListTablesQuery({
      teamSlug,
      databaseId,
    }),
  });

  return (
    <SimpleBar
      style={{
        height: "calc(100vh - 40px)",
      }}
    >
      <Container p="5" size="3">
        <Box mb="5">
          <Text variant="heading">Recent Updates</Text>
        </Box>
        <Grid gap="2">
          {allTableHistory?.items.map((history, i) => (
            <RecentUpdatesTableHistoryCell
              key={i}
              history={history}
              tables={listTables?.tables || []}
            />
          ))}
        </Grid>
      </Container>
    </SimpleBar>
  );
};

export { DatabaseHome };
