import { DashboardViewConditionObject } from "@usemorph/morph-dashboard-types";
import {
  CanvasCellClientModelBase,
  CanvasCellDataType,
  CanvasCellResizeParams,
} from "./CanvasCellClientModelBase";

export type CanvasCellViewDataType = CanvasCellDataType & {
  cellType: "view";
};

export class CanvasCellViewClientModel extends CanvasCellClientModelBase<
  CanvasCellViewClientModel,
  CanvasCellViewDataType
> {
  constructor(data: CanvasCellViewDataType) {
    super(data);
  }

  /**
   * Custom value getters
   */
  get viewId(): string | null {
    const findViewOutput = this.outputs.find(
      (
        output
      ): output is {
        value: string;
        contentType: "application/morph.view_id";
      } => !!output
    );

    return findViewOutput?.value || null;
  }

  get isResizable(): boolean {
    return true;
  }

  get showSourceHandle(): boolean {
    return true;
  }

  get showTargetHandle(): boolean {
    return true;
  }

  get viewCondition(): DashboardViewConditionObject | null {
    return this.data.source.condition ?? null;
  }

  updateCellName(cellName: string): CanvasCellViewClientModel {
    return new CanvasCellViewClientModel({
      ...this.data,
      cellName,
    });
  }

  onResize(resizePrams: CanvasCellResizeParams): CanvasCellViewClientModel {
    return new CanvasCellViewClientModel({
      ...this.data,
      settings: {
        ...this.data.settings,
        ...resizePrams,
      },
    });
  }

  updateCondition(
    condition: DashboardViewConditionObject
  ): CanvasCellViewClientModel {
    return new CanvasCellViewClientModel({
      ...this.data,
      source: {
        ...this.data.source,
        condition,
      },
    });
  }

  clearAllCondition(): CanvasCellViewClientModel {
    return new CanvasCellViewClientModel({
      ...this.data,
      source: {
        ...this.data.source,
        condition: undefined,
      },
    });
  }
}
