import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModel } from "..";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";

export class ArrayFieldTypeClientModel extends FieldTypeClientModelBase {
  public get type(): "array" {
    return "array";
  }

  public get fieldTypeLabel(): "Array" {
    return "Array";
  }

  public get isAutoGenerated(): false {
    return false;
  }

  public get isEmbeddable(): true {
    return true;
  }

  public get canBeTargetOfSyncingByComputedField(): true {
    return true;
  }

  public get _updatableFieldTypes(): FieldTypeClientModel["type"][] {
    return ["json"];
  }

  public validate(): ValidationResult {
    return { isValid: true };
  }
}
