import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { SimpleField } from "@usemorph/morph-dashboard-types";
import { TbGridDots } from "react-icons/tb";
import { Text } from "~/components_next/Text";
import { FieldIcon } from "~/components_next/Icons";
import { styled } from "~/stitches";
import { Switch } from "~/components_next/Switch";
import { Spacer } from "~/components_next/Spacer";
import { Box } from "~/components_next/Box";

const ListItem = styled("li", {
  display: "flex",
  flexDirection: "row",
  gap: 20,
  alignItems: "center",
  padding: "4px 0px",
});

type FieldToggleListItemProps = {
  field: SimpleField;
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  showTableSlug: boolean;
  draggable: boolean;
};

export const FieldToggleListItem = ({
  field,
  isChecked,
  onChange,
  showTableSlug,
  draggable,
}: FieldToggleListItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: field.name });

  const style = {
    transform: CSS.Transform.toString({
      x: 0,
      y: transform?.y || 0,
      scaleX: 1,
      scaleY: 1,
    }),
    transition,
  };

  const listItemProps = draggable
    ? { ref: setNodeRef, style, ...attributes, cursor: "default" }
    : {};

  const label =
    showTableSlug && field.originalTableSlug
      ? `${field.originalTableSlug}.${field.displayName || field.name}`
      : field.displayName || field.name;

  return (
    <ListItem key={field.name} {...listItemProps}>
      {draggable && (
        <Box {...listeners} style={{ cursor: "grab" }}>
          <TbGridDots />
        </Box>
      )}

      <Switch size="xs" isChecked={isChecked} onCheckedChange={onChange} />

      <Text variant={isChecked ? "default" : "description"}>{label}</Text>

      <Spacer />
      <Box style={{ height: "90%" }}>
        <FieldIcon type={field.type} />
      </Box>
    </ListItem>
  );
};
