import { notebookKeys } from "../keys";
import useApiNotebook from "~/api/useApiNotebook";
import { useQueryClient } from "react-query";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import { DashboardViewConditionObject } from "@usemorph/morph-dashboard-types";
import { viewKeys } from "~/serverStateStore/views";

interface UseCreateNotebookMutationParams {
  teamSlug: string;
  databaseId: string;
  tableSlug?: string;
  viewId?: string;
}

const useCreateNotebookMutation = ({
  databaseId,
  teamSlug,
  tableSlug,
  viewId,
}: UseCreateNotebookMutationParams) => {
  const { _createNotebook } = useApiNotebook();

  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      notebookName,
      description,
      condition,
      isPrivate,
    }: {
      notebookName: string;
      description: string | null;
      condition?: DashboardViewConditionObject;
      isPrivate?: boolean;
    }) => {
      return _createNotebook({
        teamSlug,
        body: {
          databaseId,
          tableSlug,
          viewId,
          notebookName,
          description,
          condition,
          isPrivate,
        },
      });
    },
    onSuccess: () => {
      const keys = notebookKeys.all({
        databaseId,
        teamSlug,
      });
      broadcast(keys);

      const listViewKeys = viewKeys.listViews({
        databaseId,
        teamSlug,
      });
      broadcast(listViewKeys);
      return invalidateAll(client, keys, listViewKeys);
    },
  };
};

export { useCreateNotebookMutation };
