import {
  DashboardRecordHistoryObject,
  SimpleField,
  zDashboardUserResponse,
} from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";
import { FieldsClientModelFactory } from "~/clientModel/fields";
import { RecordClientModelFactory } from "~/clientModel/records/record";
import { FindUserClientModelFactory } from "~/clientModel/user";
import { RecordHistoryClientModel } from "./RecordHistoryClientModel";
import { z } from "zod";

const attachmentValueSchema = z.object({ data: z.string(), url: z.string() });
const userSchema = zDashboardUserResponse;

export class RecordHistoryClientModelFactory {
  public static createFromDashboardRecordHistoryObject({
    createdBy,
    operatedAt,
    operation,
    previousData,
    data,
  }: DashboardRecordHistoryObject): RecordHistoryClientModel {
    // fieldの追加や削除があるので、現在のsimple fieldsは使えない。
    // そのため、表示のためにrecordからfieldsを推定して使う
    // operationの前後でfieldsが変わることはないので、
    // previousRecordとsubsequentRecordのfieldsは同じものでよい。

    const simpleFields: SimpleField[] = Object.entries(data).map(
      ([key, value]) => {
        return match(value)
          .with(
            P.string,
            () =>
              ({
                name: key,
                type: "shortText",
                nullable: true,
              } as const)
          )
          .with(
            P.number,
            () =>
              ({
                name: key,
                type: "formula",
                nullable: true,
              } as const)
          )
          .with(
            P.boolean,
            () =>
              ({
                name: key,
                type: "boolean",
                nullable: true,
              } as const)
          )
          .with(
            null,
            () =>
              ({
                name: key,
                type: "shortText",
                nullable: true,
              } as const)
          )
          .with(
            undefined,
            () =>
              ({
                name: key,
                type: "formula",
                nullable: true,
              } as const)
          )
          .with(
            P.when((value) => attachmentValueSchema.safeParse(value)),
            () => ({ name: key, type: "attachment", nullable: true } as const)
          )
          .with(
            P.when((value) => userSchema.safeParse(value)),
            () => ({ name: key, type: "createdBy", nullable: true } as const)
          )
          .with(
            P.array(P.string),
            (value) =>
              ({
                name: key,
                type: "multiSelect",
                nullable: true,
                members: value,
              } as const)
          )
          .otherwise(
            () =>
              ({
                name: key,
                type: "formula",
                nullable: true,
              } as const)
          );

        // if (typeof value === "string") {
        //   return {
        //     name: key,
        //     type: "shortText",
        //     nullable: true,
        //   };
        // }

        // if (typeof value === "number") {
        //   return {
        //     name: key,
        //     type: "formula",
        //     nullable: true,
        //   };
        // }

        // if (typeof value === "boolean") {
        //   return {
        //     name: key,
        //     type: "boolean",
        //     nullable: true,
        //   };
        // }
      }
    );

    const fields =
      FieldsClientModelFactory.createFromSimpleFields(simpleFields);

    return new RecordHistoryClientModel({
      operatedAt,
      createdBy: createdBy
        ? FindUserClientModelFactory.fromUserResponse(createdBy)
        : null,
      operation,
      previousRecord: previousData
        ? RecordClientModelFactory.createFromRecordAndSimpleFields(
            previousData,
            simpleFields
          )
        : null,
      subsequentRecord:
        RecordClientModelFactory.createFromRecordAndSimpleFields(
          data,
          simpleFields
        ),
      fields,
    });
  }
}
