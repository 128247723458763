import { defaultSerializer, DefaultSerializerUsableType } from "./serializers";
import { StorageItem } from "./storageItem";
import { Deserializer } from "./types";

/**
 * メタ情報付きの値の型
 * ストレージに保存する
 */
type ValueContainer<ValueType> = {
  value: ValueType;
  version: number;
  expiredAt: number | null; // timestamp
};

/**
 * ストレージを型安全にラップしたクラス
 */
class TypedStorage {
  private storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  public get<ValueType>(storageItem: StorageItem<ValueType>): ValueType | null {
    const { key, version, deserializer } = storageItem;
    const rowValue: string | null = this.storage.getItem(key);

    if (!rowValue) {
      return null;
    }

    try {
      const valueContainer =
        TypedStorage.parseRowValueToValueContainer<ValueType>(
          rowValue,
          deserializer
        );

      // remove if version is not matched
      if (version !== valueContainer.version) {
        this.storage.removeItem(key);
        return null;
      }

      // remove if expired
      if (valueContainer.expiredAt && valueContainer.expiredAt < Date.now()) {
        this.storage.removeItem(key);
        return null;
      }

      return valueContainer.value;
    } catch (e) {
      this.storage.removeItem(key);
      console.log(e);
      return null;
    }
  }

  public set<ValueType>(
    storageItem: StorageItem<ValueType>,
    value: ValueType,
    expiredAt?: number
  ): void {
    const { key, version, serializer } = storageItem;
    try {
      // 2段階でシリアライズする。(valueをシリアライズした後に、全体をシリアライズ)
      // カスタムのシリアライザー・デシリアライザーを扱いやすくするため。

      // serializerがオプショナルなのは、serializerがDefaultSerializerUsableTypeのサブタイプのときだけなのでキャストしてok
      const stringifiedValue = serializer
        ? serializer(value)
        : defaultSerializer(value as DefaultSerializerUsableType);
      this.storage.setItem(
        key,
        JSON.stringify({
          value: stringifiedValue,
          version,
          expiredAt,
        })
      );
    } catch {
      throw new Error("failed to set value");
    }
  }

  public remove<ValueType>(storageItem: StorageItem<ValueType>): void {
    const { key } = storageItem;
    this.storage.removeItem(key);
  }

  static parseRowValueToValueContainer<ValueType>(
    rowValue: string,
    deserializer: Deserializer<ValueType>
  ): ValueContainer<ValueType> {
    try {
      // 2段階でパースする。(シリアライズの逆)
      // カスタムのシリアライザー・デシリアライザーを扱いやすくするため。

      const parsedValueContainer = JSON.parse(rowValue) as unknown;

      // check if parsedValueContainer is valid
      if (
        parsedValueContainer &&
        typeof parsedValueContainer === "object" &&
        "value" in parsedValueContainer &&
        typeof parsedValueContainer.value === "string" &&
        "version" in parsedValueContainer &&
        typeof parsedValueContainer.version === "number"
      ) {
        const parsedValue = deserializer(parsedValueContainer.value);

        return {
          value: parsedValue,
          version: parsedValueContainer.version,
          expiredAt:
            "expiredAt" in parsedValueContainer &&
            typeof parsedValueContainer.expiredAt === "number"
              ? parsedValueContainer.expiredAt
              : null,
        };
      } else {
        throw new Error("failed to parse value container.");
      }
    } catch (e) {
      throw new Error("failed to parse value container.");
    }
  }
}

export { TypedStorage };
