import { useQuery } from "react-query";
import { FieldsClientModelFactory } from "~/clientModel/fields";
import { useComposeLoadable } from "~/clientModel/loadable";
import { useGetViewFieldsQuery } from "~/serverStateStore/views";

export const createUseViewFieldsLoadable = (props: {
  teamSlug: string;
  databaseId: string;
}) => {
  const { teamSlug, databaseId } = props;
  return ({ viewId }: { viewId: string }) =>
    useComposeLoadable(
      useQuery({
        ...useGetViewFieldsQuery({
          teamSlug,
          databaseId,
          viewId,
        }),
        select: ({ fields }) => {
          return FieldsClientModelFactory.createFromSimpleFields(fields);
        },
      })
    );
};
