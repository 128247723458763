import { memo, useCallback, useMemo, useRef } from "react";
import { Box } from "~/components_next/Box";

type NotebookHtmlUnitProps = {
  html: string;
  onSelect?: () => void;
  isSelected?: boolean;
};

const cssString = `
  body {
    font-size: 14px;
    color: #171923;
    font-family: "Inter", sans-serif;
  }

  body.dark {
    color: #F7FAFC;
    background-color: #000;
  }

  table {
    min-width: 100%;
    overflow-x: auto;
    margin-bottom: 10px;
    border: none;
  }

  th {
    text-align: left !important;
    height: 40px;
    padding: 0 10px;
  
    background-color: #EDF2F7;
    border-bottom: 1px solid #E2E8F0;
    border-right: 1px solid #E2E8F0;

    vertical-align: middle;
  }  

  .dark th {
    background-color: #1A202C;
    border-bottom: 1px solid #2D3748;
    border-right: 1px solid #2D3748;
  }

  td {
    text-align: left !important;
    height: 40px;
    padding: 0 10px;
    border-bottom: 1px solid #E2E8F0;
    border-right: 1px solid #E2E8F0;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
  }

  .dark td {
    border-bottom: 1px solid #2D3748;
    border-right: 1px solid #2D3748;
  }
`;

const NotebookHtmlUnit = (props: NotebookHtmlUnitProps) => {
  const { html, onSelect, isSelected = false } = props;

  const sourceUrl = useMemo(() => {
    const blob = new Blob(
      [
        `<html>
          <head>
            <link
              rel="stylesheet"
              href="https://cdn.jsdelivr.net/npm/destyle.css@1.0.15/destyle.css"
            />
            <style>${cssString}</style>
          </head>
          <body>${html}</body>
        </html>`,
      ],
      { type: "text/html" }
    );

    return URL.createObjectURL(blob);
  }, [html]); // isSelectedがガンガン変わるのでmemo化

  const boxRef = useRef<HTMLDivElement>(null);

  const handleIframeClick = useCallback(() => {
    onSelect?.();
  }, []);

  return (
    <Box ref={boxRef} width="100%" height="100%">
      {/* <Box
        className="notebook-html-element"
        dangerouslySetInnerHTML={{ __html: html }}
      /> */}
      <iframe
        ref={(node) => {
          node?.contentWindow?.addEventListener("click", () => {
            handleIframeClick();
          });
        }}
        width="100%"
        height="100%"
        src={sourceUrl}
        style={{
          pointerEvents: isSelected ? "auto" : "none",
        }}
      />
    </Box>
  );
};

const Memoized = memo(NotebookHtmlUnit);

export { Memoized as NotebookHtmlUnit };
