import { useRecordsTableBaseRecords } from "../context/ReactTableBaseContext";
import { RecordsTableRow } from "./RecordsTableRow";
import { Virtualizer } from "@tanstack/react-virtual";
import { RecordsTableHeaders } from "../RecordsTableHeader/RecordsTableHeaders";
import { RecordsTableBaseCellSelectionRects } from "../RecordsTableSelection/RecordsTableBaseCellSelectionRects";
import { Box } from "~/components_next/Box";

// Table Rows
type RecordsTableRowsProps = {
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  columnVirtualizer: Virtualizer<HTMLDivElement, Element>;
};

const RecordsTableRows = (props: RecordsTableRowsProps) => {
  const { rowVirtualizer, columnVirtualizer } = props;

  const records = useRecordsTableBaseRecords();

  const items = rowVirtualizer.getVirtualItems();

  return (
    <>
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: `${columnVirtualizer.getTotalSize()}px`,
          position: "relative",
          border: "none",
        }}
      >
        {items.map((virtualRow) => {
          if (virtualRow.index === 0) {
            return (
              <RecordsTableHeaders
                key={virtualRow.key}
                columnVirtualizer={columnVirtualizer}
                translateY={virtualRow.start}
              />
            );
          }
          if (virtualRow.index === records.length + 1) {
            return <Box key={virtualRow.key} />;
          }
          return (
            <RecordsTableRow
              key={virtualRow.key}
              record={records[virtualRow.index - 1]}
              index={virtualRow.index}
              data-index={virtualRow.index}
              columnVirtualizer={columnVirtualizer}
              translateY={virtualRow.start}
              rowWidth={rowVirtualizer.getTotalSize()}
            />
          );
        })}
        <RecordsTableBaseCellSelectionRects />
      </div>
    </>
  );
};

export { RecordsTableRows };
