import { useTranslation } from "react-i18next";
import { ScrollArea } from "@radix-ui/themes";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { Code } from "~/components_next/Code";
import { Flex } from "~/components_next/Flex";
import { Text } from "~/components_next/Text";
import { P, match } from "ts-pattern";
import { PlanStatusType } from '~/clientModel/payment/findPaymentClientModel';
import { Executable } from "~/clientModel/executable";
import {
  CreatePaymentSessionMutationProps,
  UpdatePaymentMutationProps,
} from "~/serverStateStore";
import { PlanTableHeader } from "./components/PlanTableHeader";
import { PlanTableContent } from "./components/PlanTableContent";
import { PaymentClientModel } from "~/clientModel/payment/PaymentClientModel";
import { VersionClientModel } from "~/clientModel/version/VersionClientModel";
import { useEffect, useMemo } from "react";
import { ListPlansClientModel } from "~/clientModel/plan/ListPlansClientModel";

interface PlanTableProps {
  plans: ListPlansClientModel;
  payment: PaymentClientModel;
  versions: VersionClientModel[];
  handleSelectPlan: ({ planId, planName }: { planId: string; planName: string; }) => void
  onConfirmSelectPlan: (planId: string) => void;
  onStartTrial: () => void;
  onClickContactUs: () => void;
  createPaymentSessionExecutable: Executable<CreatePaymentSessionMutationProps, { url: string; }>;
  updatePaymentExecutable: Executable<UpdatePaymentMutationProps, PaymentClientModel>
  registTrialExecutable: Executable<{ versionId: string; }, PaymentClientModel>
}

export const PlanTable = (props: PlanTableProps) => {
  const {
    plans,
    payment,
    versions,
    handleSelectPlan,
    onConfirmSelectPlan,
    onStartTrial,
    onClickContactUs,
    createPaymentSessionExecutable,
    updatePaymentExecutable,
    registTrialExecutable,
  } = props;

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(payment.userLangage)
  }, [payment])

  const filteredPlans = useMemo(() => {
    return plans.filteredItems(payment.userLangage)
  }, [plans, payment])

  const standardPlanId = useMemo(() => {
    return filteredPlans.find(plan => plan.name === "Standard")?.planId || ""
  }, [filteredPlans])

  const teamPlanId = useMemo(() => {
    return filteredPlans.find(plan => plan.name === "Team")?.planId || ""
  }, [filteredPlans])

  const getPlanStatus = ({ planId, planName, }: { planId: string; planName: string; }): PlanStatusType => {
    const isCurrent = planId === payment.currentPlanId
    const isTrialAvailable = payment.isTrialAvailable(versions)
    const status = match([
      isTrialAvailable,
      planName,
      isCurrent,
      payment.currentTrial,
    ])
      .with([true, "team", P._, P._], () => "trialButton")
      .with([P._, "team", P._, P.not(P.nullish)], () => "currentTrial")
      .with([P._, P._, true, P.not(P.nullish)], () => "nextPlan")
      .with([P._, P._, true, P._], () => "currentPlan")
      .otherwise(() => "selectButton");
    return status as PlanStatusType;
  };

  return (
    <>
      <Flex css={{ borderBottomWidth: 1 }}>
        <Box css={{ width: "160px" }} />

        <PlanTableHeader
          title={t("plans.free.title")}
          price={t("plans.free.price")}
          perMonth={t("plans.free.perMonth")}
          explain={t("plans.free.explain")}
          isTrialBadge={false}
        >
          {
            match(getPlanStatus({ planId: "free", planName: "free" }))
              .with("currentPlan", () => (
                <>
                  <Box mt="1"></Box>
                  <Code color="blue" size="3" style={{ textAlign: "center" }}>
                    Current Plan
                  </Code>
                </>
              ))
              .with("nextPlan", () => (
                <>
                  <Box mt="1"></Box>
                  <Code color="blue" size="3" style={{ textAlign: "center" }}>
                    Next Plan
                  </Code>
                </>
              ))
              .otherwise(() => (
                <Button
                  onClick={() => onConfirmSelectPlan("free")}
                  isLoading={createPaymentSessionExecutable.isExecuting || updatePaymentExecutable.isExecuting}
                  variant="primary"
                  size="sm"
                >
                  Select
                </Button>
              ))
          }
        </PlanTableHeader>

        <PlanTableHeader
          title={t("plans.standard.title")}
          price={t("plans.standard.price")}
          perMonth={t("plans.standard.perMonth")}
          explain={t("plans.standard.explain")}
          isTrialBadge={false}
        >
          {
            match(getPlanStatus({ planId: standardPlanId, planName: "standard" }))
              .with("currentPlan", () => (
                <>
                  <Box mt="1"></Box>
                  <Code color="blue" size="3" style={{ textAlign: "center" }}>
                    Current Plan
                  </Code>
                </>
              ))
              .with("nextPlan", () => (
                <>
                  <Box mt="1"></Box>
                  <Code color="gray" size="2" style={{ textAlign: "center" }}>
                    Next Plan
                  </Code>
                </>
              ))
              .otherwise(() => (
                <Button
                  onClick={() => handleSelectPlan({ planId: standardPlanId, planName: "Standard" })}
                  variant="primary"
                  isLoading={createPaymentSessionExecutable.isExecuting || updatePaymentExecutable.isExecuting}
                  size="sm"
                >
                  Select
                </Button>
              ))
          }
        </PlanTableHeader>

        <PlanTableHeader
          title={t("plans.team.title")}
          price={t("plans.team.price")}
          perMonth={t("plans.team.perMonth")}
          explain={t("plans.team.explain")}
          isTrialBadge={payment.isTrialAvailable(versions)}
        >
          {
            match(getPlanStatus({ planId: teamPlanId, planName: "team" }))
              .with("trialButton", () => (
                <Flex direction="column" gap="3" mt="1">
                  <Button
                    onClick={() => onStartTrial()}
                    isLoading={registTrialExecutable.isExecuting}
                    variant="primary"
                    size="sm"
                  >
                    Start Free Trial
                  </Button>
                </Flex>
              ))
              .with("currentTrial", () => (
                <>
                  <Box mt="1"></Box>
                  <Code color="blue" size="2" style={{ textAlign: "center" }}>
                    Current Trial
                  </Code>
                  {
                    payment.plan === null && (
                      <>
                        <Box mt="1"></Box>
                        <Button
                          onClick={() => handleSelectPlan({ planId: teamPlanId, planName: "Team" })}
                          isLoading={createPaymentSessionExecutable.isExecuting || updatePaymentExecutable.isExecuting}
                          variant="primary"
                          size="sm"
                        >
                          Select
                        </Button>
                      </>
                    )
                  }
                  {
                    payment.plan && payment.plan.planId === teamPlanId && (
                      <>
                        <Box mt="1"></Box>
                        <Code color="gray" size="2" style={{ textAlign: "center" }}>
                          Next Plan
                        </Code>
                      </>
                    )
                  }
                </>
              ))
              .with("currentPlan", () => (
                <>
                  <Box mt="1"></Box>
                  <Code color="blue" size="3" style={{ textAlign: "center" }}>
                    Current Plan
                  </Code>
                </>
              ))
              .otherwise(() => (
                <Button
                  onClick={() => handleSelectPlan({ planId: teamPlanId, planName: "Team" })}
                  isLoading={createPaymentSessionExecutable.isExecuting || updatePaymentExecutable.isExecuting}
                  variant="primary"
                  size="sm"
                >
                  Select
                </Button>
              ))
          }
        </PlanTableHeader>

        <PlanTableHeader
          title={t("plans.enterprise.title")}
          price={t("plans.enterprise.price")}
          perMonth={t("plans.enterprise.perMonth")}
          explain={t("plans.enterprise.explain")}
          isTrialBadge={false}
        >
          <>
            <Box css={{ padding: "9.5px" }} />
            <Button onClick={onClickContactUs} variant="secondary" size="sm">
              Contact us
            </Button>
          </>
        </PlanTableHeader>
      </Flex>

      <ScrollArea
        type="always"
        scrollbars="vertical"
        style={{ height: "190px" }}
      >
        {/* ユーザー数 */}
        <Flex py="3" css={{ borderBottomWidth: 1 }}>
          <Text variant="subheading">{t("plans.seats.title")}</Text>
        </Flex>

        <PlanTableContent
          title={t("plans.seats.includedSeats.title")}
          free={t("plans.seats.includedSeats.free")}
          standard={t("plans.seats.includedSeats.standard")}
          team={t("plans.seats.includedSeats.team")}
          enterprise={t("plans.seats.includedSeats.enterprise")}
        />

        <PlanTableContent
          title={t("plans.seats.seatLimit.title")}
          free={t("plans.seats.seatLimit.free")}
          standard={t("plans.seats.seatLimit.standard")}
          team={t("plans.seats.seatLimit.team")}
          enterprise={t("plans.seats.seatLimit.enterprise")}
        />

        <PlanTableContent
          title={t("plans.seats.costPerAdditionalSeat.title")}
          free={t("plans.seats.costPerAdditionalSeat.free")}
          standard={t("plans.seats.costPerAdditionalSeat.standard")}
          team={t("plans.seats.costPerAdditionalSeat.team")}
          enterprise={t("plans.seats.costPerAdditionalSeat.enterprise")}
        />

        {/* データ */}
        <Flex py="3" css={{ borderBottomWidth: 1 }}>
          <Text variant="subheading">{t("plans.data.title")}</Text>
        </Flex>

        <PlanTableContent
          title={t("plans.data.freeRecordTier.title")}
          free={t("plans.data.freeRecordTier.free")}
          standard={t("plans.data.freeRecordTier.standard")}
          team={t("plans.data.freeRecordTier.team")}
          enterprise={t("plans.data.freeRecordTier.enterprise")}
        />

        <PlanTableContent
          title={t("plans.data.recordsLimit.title")}
          free={t("plans.data.recordsLimit.free")}
          standard={t("plans.data.recordsLimit.standard")}
          team={t("plans.data.recordsLimit.team")}
          enterprise={t("plans.data.recordsLimit.enterprise")}
        />

        <PlanTableContent
          title={t("plans.data.costPerAdditionalSeat.title")}
          free={t("plans.data.costPerAdditionalSeat.free")}
          standard={t("plans.data.costPerAdditionalSeat.standard")}
          team={t("plans.data.costPerAdditionalSeat.team")}
          enterprise={t("plans.data.costPerAdditionalSeat.enterprise")}
        />

        {/* データ連携 */}
        <Flex py="3" css={{ borderBottomWidth: 1 }}>
          <Text variant="subheading">{t("plans.dataSync.title")}</Text>
        </Flex>

        <PlanTableContent
          title={t("plans.dataSync.integrationsLimit.title")}
          free={t("plans.dataSync.integrationsLimit.free")}
          standard={t("plans.dataSync.integrationsLimit.standard")}
          team={t("plans.dataSync.integrationsLimit.team")}
          enterprise={t("plans.dataSync.integrationsLimit.enterprise")}
        />

        <PlanTableContent
          title={t("plans.dataSync.oneTimeImport.title")}
          free={t("plans.dataSync.oneTimeImport.free")}
          standard={t("plans.dataSync.oneTimeImport.standard")}
          team={t("plans.dataSync.oneTimeImport.team")}
          enterprise={t("plans.dataSync.oneTimeImport.enterprise")}
        />

        <PlanTableContent
          title={t("plans.dataSync.continuousDataSync.title")}
          free={t("plans.dataSync.continuousDataSync.free")}
          standard={t("plans.dataSync.continuousDataSync.standard")}
          team={t("plans.dataSync.continuousDataSync.team")}
          enterprise={t("plans.dataSync.continuousDataSync.enterprise")}
        />

        <PlanTableContent
          title={t("plans.dataSync.continuousFieldSync.title")}
          free={t("plans.dataSync.continuousFieldSync.free")}
          standard={t("plans.dataSync.continuousFieldSync.standard")}
          team={t("plans.dataSync.continuousFieldSync.team")}
          enterprise={t("plans.dataSync.continuousFieldSync.enterprise")}
        />

        <PlanTableContent
          title={t("plans.dataSync.premiumIntegrations.title")}
          free={t("plans.dataSync.premiumIntegrations.free")}
          standard={t("plans.dataSync.premiumIntegrations.standard")}
          team={t("plans.dataSync.premiumIntegrations.team")}
          enterprise={t("plans.dataSync.premiumIntegrations.enterprise")}
        />

        <PlanTableContent
          title={t("plans.dataSync.connectToDatabase.title")}
          free={t("plans.dataSync.connectToDatabase.free")}
          standard={t("plans.dataSync.connectToDatabase.standard")}
          team={t("plans.dataSync.connectToDatabase.team")}
          enterprise={t("plans.dataSync.connectToDatabase.enterprise")}
        />

        <PlanTableContent
          title={t("plans.dataSync.costPerAdditional1kDataSync.title")}
          free={t("plans.dataSync.costPerAdditional1kDataSync.free")}
          standard={t("plans.dataSync.costPerAdditional1kDataSync.standard")}
          team={t("plans.dataSync.costPerAdditional1kDataSync.team")}
          enterprise={t(
            "plans.dataSync.costPerAdditional1kDataSync.enterprise"
          )}
        />

        {/* シミラリティサーチ */}
        <Flex py="3" css={{ borderBottomWidth: 1 }}>
          <Text variant="subheading">{t("plans.similaritySearch.title")}</Text>
        </Flex>

        <PlanTableContent
          title={t("plans.similaritySearch.useOnMorph.title")}
          free={t("plans.similaritySearch.useOnMorph.free")}
          standard={t("plans.similaritySearch.useOnMorph.standard")}
          team={t("plans.similaritySearch.useOnMorph.team")}
          enterprise={t("plans.similaritySearch.useOnMorph.enterprise")}
        />

        <PlanTableContent
          title={t("plans.similaritySearch.vectorSearch.title")}
          free={t("plans.similaritySearch.vectorSearch.free")}
          standard={t("plans.similaritySearch.vectorSearch.standard")}
          team={t("plans.similaritySearch.vectorSearch.team")}
          enterprise={t("plans.similaritySearch.vectorSearch.enterprise")}
        />

        {/* その他の機能 */}
        <Flex py="3" css={{ borderBottomWidth: 1 }}>
          <Text variant="subheading">{t("plans.others.title")}</Text>
        </Flex>

        <PlanTableContent
          title={t("plans.others.singleSignOn.title")}
          free={t("plans.others.singleSignOn.free")}
          standard={t("plans.others.singleSignOn.standard")}
          team={t("plans.others.singleSignOn.team")}
          enterprise={t("plans.others.singleSignOn.enterprise")}
        />

        <PlanTableContent
          title={t("plans.others.dedicatedServer.title")}
          free={t("plans.others.dedicatedServer.free")}
          standard={t("plans.others.dedicatedServer.standard")}
          team={t("plans.others.dedicatedServer.team")}
          enterprise={t("plans.others.dedicatedServer.enterprise")}
        />
      </ScrollArea>
    </>
  );
};
