import { SimpleField } from "@usemorph/morph-dashboard-types";
import { useEffect, useMemo, useRef } from "react";
import {
  RecordsTableBaseProvider,
  useRecordsTableBaseColumnSizing,
  useRecordsTableBaseFields,
  useRecordsTableBaseOptions,
  useRecordsTableBaseRecords,
} from "./context/ReactTableBaseContext";
import { RecrodsTableBaseKeyboardCommands } from "./RecordsTableAction/RecordsTableBaseKeyboardCommands";
import { RecordsTableRows } from "./RecordsTableRow/RecordsTableRows";
import {
  CoworkerState,
  RecordsTableBaseRecord,
  RecordsTableBaseSelectionState,
  RecordsTableColumnSizingState,
} from "./types";
import { RecordsTableBaseEditingCell } from "./RecordsTableCell/RecordsTableBaseEditingCell";
import { useVirtualizer } from "@tanstack/react-virtual";
import { match } from "ts-pattern";
import SimpleBar from "simplebar-react";

type RecordsTableBaseProps = {
  isSelectable?: boolean; // openable & selectable
  isOpenable?: boolean; // openable only
  isReorderable?: boolean;
  isInfiniteScroll?: boolean;
  editableFields?: string[] | "all" | "none";
  hasPadding?: boolean; // default: true
  propagateEvent?: boolean;
  // data
  records: RecordsTableBaseRecord[];
  fields: SimpleField[];
  // new selection state
  selectionState?: RecordsTableBaseSelectionState;
  onSelectionChange?: (state: RecordsTableBaseSelectionState) => void;
  // state
  columnSizing?: RecordsTableColumnSizingState;
  coworkerStates?: CoworkerState[];
  // children component
  headerDropdown?: (field: SimpleField, index: number) => React.ReactNode;
  headerPopover?: (field: SimpleField, index: number) => React.ReactNode;
  // event callbacks
  onRecordClick?: (record: RecordsTableBaseRecord) => void;
  onAddFieldClick?: () => void;
  onReorderFields?: (fieldsOrder: string[]) => void;
  onColumnSizingChange?: (columnSizing: RecordsTableColumnSizingState) => void;
  onUpdateRecord?: (record: RecordsTableBaseRecord) => void;
};

const RecordsTableRoot = () => {
  const { isSelectable, isOpenable, hasPadding, propagateEvent } =
    useRecordsTableBaseOptions();
  const records = useRecordsTableBaseRecords();
  const fields = useRecordsTableBaseFields();
  const columnSizing = useRecordsTableBaseColumnSizing();

  const columnSizingMapping = useMemo((): number[] => {
    return Object.entries(columnSizing).map(([key, value]) => {
      return value;
    });
  }, [columnSizing]);

  /**
   * Virtual scroll
   */
  const parentRef = useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: hasPadding ? records.length + 1 + 1 : records.length + 1,
    getScrollElement: () => parentRef.current,
    estimateSize: (i) => {
      if (i === records.length + 1) {
        return 400;
      }
      return 40;
    },
    overscan: 5,
  });

  /**
   * [ [checkbox], [field], [field], ..., [addFieldButton], [margin] ]
   */
  const CHECKBOX_INDEX = 0;
  const ADDFIELD_BUTTON_INDEX = 1 + (fields.length - 1) + 1;
  const MARGIN_COLUMN_INDEX = ADDFIELD_BUTTON_INDEX + 1;

  const columnVirtualizer = useVirtualizer({
    horizontal: true,
    count: hasPadding ? 1 + fields.length + 1 + 1 : 1 + fields.length + 1,
    getScrollElement: () => parentRef.current,
    estimateSize: (i) => {
      return match(i)
        .with(CHECKBOX_INDEX, () => {
          if (isSelectable) {
            return 80;
          }
          if (isOpenable) {
            return 40;
          }
          return 0;
        })
        .with(ADDFIELD_BUTTON_INDEX, () => 40)
        .with(MARGIN_COLUMN_INDEX, () => 400)
        .otherwise(() => {
          return columnSizingMapping[i - 1];
        });
    },
    overscan: 0,
  });

  useEffect(() => {
    columnVirtualizer.measure();
  }, [columnSizingMapping, columnVirtualizer]);

  return (
    <SimpleBar
      scrollableNodeProps={{ ref: parentRef }}
      style={{
        height: "100%",
        width: "100%",
        overflow: "auto",
        position: "relative",
        border: "none",
        overscrollBehavior: "none",
      }}
      onClick={(e) => {
        if (!propagateEvent) {
          e.stopPropagation();
        }
      }}
    >
      <RecordsTableRows
        rowVirtualizer={rowVirtualizer}
        columnVirtualizer={columnVirtualizer}
      />
      {/* <RecordsTableBaseCellSelectionRects /> */}
      <RecrodsTableBaseKeyboardCommands />
      <RecordsTableBaseEditingCell />
    </SimpleBar>
  );
};

const RecordsTableBase = (props: RecordsTableBaseProps) => {
  return (
    <RecordsTableBaseProvider {...props}>
      <RecordsTableRoot />
    </RecordsTableBaseProvider>
  );
};

export { RecordsTableBase, type RecordsTableBaseProps };
