import { MdDragIndicator } from "react-icons/md";
import { Box } from "~/components_next/Box";
import { IconButton } from "~/components_next/IconButton";
import { styled } from "~/stitches";

type DashboardPageCellDragHandleProps = {
  isVisible: boolean;
};

const DragHandleElement = styled(Box, {
  position: "absolute",
  top: "-6px",
  left: "-7px",
  backgroundColor: "var(--slate-a5)",
  borderRadius: "var(--radius-2)",
});

const DashboardPageCellDragHandle = (
  props: DashboardPageCellDragHandleProps
) => {
  const { isVisible } = props;

  if (!isVisible) return null;

  return (
    <DragHandleElement className="page-cell-drag-handle">
      <IconButton icon={<MdDragIndicator />} size="sm" tooltip="Drag to move" />
    </DragHandleElement>
  );
};

export { DashboardPageCellDragHandle };
