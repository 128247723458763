import { CodeEditor } from "~/components_next/CodeEditor";
import { Dialog } from "~/components_next/Dialog";

type JSONEditDialogProps = {
  value: string | null;
  onChange?: (value: string | null) => void;
  isReadonly: boolean;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export const JSONEditDialog = (props: JSONEditDialogProps) => {
  const { value, onChange, isReadonly, isOpen, onOpenChange } = props;

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange} size="lg">
      <Dialog.Content>
        <Dialog.Title>Edit</Dialog.Title>
        <Dialog.Body>
          <CodeEditor
            value={value || ""}
            onChange={(value) => onChange?.(value || null)}
            height="70vh"
            isReadOnly={isReadonly}
            language="json"
            toolBarItemOptions={{
              download: {
                baseName: "morph-download",
                extension: "json",
                mimeSubType: "json",
              },
              format: {},
              fontSize: {},
            }}
          />
        </Dialog.Body>
      </Dialog.Content>
    </Dialog.Root>
  );
};
