import { CodePreview } from "../components/CodePreview";
import { RecordValueInputCommonProps } from "../../../type";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { useDisclosure } from "~/hooks/useDisclosure";
import { ArrayEditDialog } from "./ArrayEditDialog";
import { ArrayRecordEntryClientModel } from "~/clientModel/records/record/recordEntry";

export const ArrayInput = (
  props: RecordValueInputCommonProps<ArrayRecordEntryClientModel>
) => {
  const { editingRecordEntry, onChange, field, size, isReadonly } = props;

  const { isOpen, onOpen, setIsOpen } = useDisclosure();

  const validatedValue = editingRecordEntry.getValidatedValue(field);

  const value = validatedValue.isValid ? validatedValue.value : null;

  const errorMessages = validatedValue.errorMessages;

  const handleChange = (value: string | null) => {
    onChange?.(editingRecordEntry.updateValue(value));
  };

  return (
    <>
      <InputStack errorMessage={errorMessages}>
        <CodePreview
          value={value}
          isEditDisabled={isReadonly}
          onEditClick={onOpen}
          size={size}
        />
      </InputStack>

      {/* modal */}
      <ArrayEditDialog
        value={value}
        onChange={handleChange}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        isReadonly={isReadonly}
      />
    </>
  );
};
