import { useCallback, useState } from "react";
import { useMutation } from "react-query";
import { useUpdateTeamUserMutation } from "~/serverStateStore/teams";
import { useErrorToast } from "~/components_next/Error";
import { Button } from "~/components_next/Button";
import { Dialog } from "~/components_next/Dialog";
import { useToast } from "~/components_next/Toast";
import { stringToBoolean } from "~/utils/stringUtils";
import { Box } from "~/components_next/Box";
import { SimpleRadio } from "~/components_next/Radio";

export const UpdateMemberDialog = ({
  isOpen,
  setIsOpen,
  onClose,
  userId,
  // username,
  teamSlug,
  isAdmin,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose: () => void;
  userId: string;
  // username: string;
  teamSlug: string;
  isAdmin: boolean;
}) => {
  const [adminValue, setAdminValue] = useState<string>(String(isAdmin));

  const { mutateAsync: updateUser, isLoading: isUpdating } = useMutation(
    useUpdateTeamUserMutation({ teamSlug })
  );

  const toast = useToast();

  const handleChangeRadio = useCallback((val: string) => {
    setAdminValue(val)
  }, [])

  const { errorToast } = useErrorToast({});
  const handleUpdateUser = async () => {
    try {
      await updateUser({
        userId: userId,
        isAdmin: stringToBoolean(adminValue),
      });
      toast({
        title: "Success",
        description: "User status changed.",
        type: "success",
        duration: 9000,
      });
      onClose();
    } catch (e) {
      errorToast(e);
    }
  };

  return (
    <>
      <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
        <Dialog.Content>
          <Dialog.Title>Change user type</Dialog.Title>
          <Dialog.Body>
            <Box mt="5">
              <SimpleRadio
                onValueChange={handleChangeRadio}
                value={adminValue}
                options={[
                  { label: "Admin", value: "true" },
                  { label: "General User", value: "false" },
                ]}
              />
            </Box>
          </Dialog.Body>
          <Dialog.Footer>
            <Dialog.Close>
              <Button variant="tertiary" size="sm" isDisabled={isUpdating}>
                Cancel
              </Button>
            </Dialog.Close>
            <Button
              variant="primary"
              size="sm"
              onClick={handleUpdateUser}
              isLoading={isUpdating}
            >
              Save
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};
