
import { DashboardNotificationListResponse } from "@usemorph/morph-dashboard-types";
import { ListNotificationClientModel } from "./ListNotificationClientModel";

export const ListNotificationClientModelFactory = {
  // factory methods
  fromNotificationListResponse: (
    data: DashboardNotificationListResponse
  ): ListNotificationClientModel => {
    return new ListNotificationClientModel(data);
  },
};
