import { useMutation, useQuery } from "react-query";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTableSlug } from "~/utilHooks/useTableSlug";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import {
  useGetTableQuery,
  useCreateNotebookMutation,
  useSimpleFields,
  useRegistDataApiMutation,
  ApiType,
} from "~/serverStateStore";
import { BsPlusLg } from "react-icons/bs";
import { useErrorToast } from "~/components_next/Error";
import { useNavigate } from "react-router-dom";
import { ToolbarWrapper } from "../components/atoms/ToolbarWrapper";
import { TableSettingsDropdown } from "../../Table";
import { SourceToolbarShare } from "./SourceToolbarShare";
import { TableHistoryDrawer } from "../../TableHistory";
import { RecordsTrashDrawer } from "../../RecordsTrash";
import { PresenceIcons } from "../../RealtimeCollaboration";
import { SourceFavoriteButton } from "./SourceFavoriteButton";
import { match } from "ts-pattern";
import { EmptyAggregationUnit } from "~/utils/aggregateUtils";
import { SourceBadge } from "../../Table/components/SourceBadge";
import { BadgeAndTitle } from "../components/atoms/BadgeAndTitle";
import { CreateViewMenuItem } from "./CreateViewMenuItem";
import { getPath } from "~/routing";
//Intercom Class setup
import { ElementClassNamesForOnboarding } from "~/constants/IntercomClassConst";
import { useDisclosure } from "~/hooks/useDisclosure";
import { Box, Flex } from "@radix-ui/themes";
import {
  DropdownMenu,
  SimpleDropdownMenu,
} from "~/components_next/DropdownMenu";
import { Text } from "~/components_next/Text";
import { Spacer } from "~/components_next/Spacer";
import { Button } from "~/components_next/Button";
import { ScheduleSettingDrawer } from "~/features/ImportOtherService/components/Form/Schedule/ScheduleSettingDrawer";
import { SourceSettingDrawer } from "./SourceSettingDrawer";
import { SourceToolbarBridge } from "~/containers/toolbars/bridge";

const SourceToolbar = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const tableSlug = useTableSlug();

  const { data: table } = useQuery(
    useGetTableQuery({
      teamSlug,
      databaseId,
      tableSlug,
    })
  );

  const { data: simpleFieldsData } = useSimpleFields({
    teamSlug,
    databaseId,
    tableSlug,
  });

  /**
   * Create Notebook
   */
  const { mutateAsync: createNotebook } = useMutation({
    ...useCreateNotebookMutation({ teamSlug, databaseId, tableSlug }),
  });

  const { errorToast } = useErrorToast({});
  const navigate = useNavigate();

  const handleCreateNotebookClicked = async () => {
    try {
      const notebook = await createNotebook({
        notebookName: "Untitled Notebook",
        description: "",
      });

      navigate(
        getPath("notebook", {
          teamSlug,
          databaseId,
          notebookId: notebook.notebookId,
        })
      );
    } catch (e: unknown) {
      errorToast(e);
    }
  };

  const handleCreateViewClicked = () => {
    createViewDisclosure.onOpen();
  };

  /**
   * Create Data API
   */
  const { mutateAsync: createDataApi } = useMutation({
    ...useRegistDataApiMutation({
      databaseId,
      teamSlug,
      tableSlug,
    }),
  });

  const handleDataCollectionClicked = async (type: "rss") => {
    match(type)
      .with("rss", () => {
        navigate(
          getPath("dataCollection", {
            teamSlug,
            databaseId,
            tableSlug,
          })
        );
      })
      .otherwise(() => console.log(`data collection not match ${type}`));
  }

  const handleCreateDataApiClicked = async (apiType: ApiType) => {
    console.log("DEBUG 0808 - handleCreateDataApiClicked");
    const execute = match(apiType)
      .with("query", () => {
        const defaultSelect =
          simpleFieldsData?.fields.map((field) => field.name) || [];
        return () =>
          createDataApi({
            requestBody: {
              apiType: "query",
              apiName: "Untitled Query API",
              query: {
                select: defaultSelect,
              },
            },
          });
      })
      .with("create", () => {
        const defaultValues =
          simpleFieldsData?.fields.map((field) => ({ key: field.name })) || [];
        return () =>
          createDataApi({
            requestBody: {
              apiType: "create",
              apiName: "Untitled Create API",
              create: {
                values: defaultValues,
                fixedValue: [],
              },
            },
          });
      })
      .with("update", () => {
        const defaultValues =
          simpleFieldsData?.fields.map((field) => ({ key: field.name })) || [];
        return () =>
          createDataApi({
            requestBody: {
              apiType: "update",
              apiName: "Untitled Update API",
              update: {
                values: defaultValues,
                fixedValue: [],
                filter: {
                  and: [],
                },
              },
            },
          });
      })
      .with("delete", () => {
        return () =>
          createDataApi({
            requestBody: {
              apiType: "delete",
              apiName: "Untitled Delete API",
              delete: {
                filter: {
                  and: [],
                },
              },
            },
          });
      })
      .with("download", () => {
        const defaultSelect =
          simpleFieldsData?.fields.map((field) => field.name) || [];
        return () =>
          createDataApi({
            requestBody: {
              apiType: "download",
              apiName: "Untitled Download API",
              download: {
                select: defaultSelect,
              },
            },
          });
      })
      .with("aggregate", () => {
        if (!simpleFieldsData || simpleFieldsData.fields.length === 0)
          return undefined;
        return () =>
          createDataApi({
            requestBody: {
              apiType: "aggregate",
              apiName: "Untitled Aggregate API",
              aggregate: {
                aggregation: EmptyAggregationUnit({
                  key: simpleFieldsData?.fields[0].name,
                }),
                groupKeys: [],
              },
            },
          });
      })
      .otherwise(() => console.log(`api type not match ${apiType}`));

    if (!execute) return; // errorをthrowしたい

    try {
      // console.log("DEBUG 0808 - handleCreateDataApiClicked > before execute");
      const dataApi = await execute();

      navigate(
        getPath("dataApi", {
          teamSlug,
          databaseId,
          tableSlug,
          dataApiKey: dataApi.apiKey,
        })
      );
    } catch (e) {
      // console.log("DEBUG 0808", e);
      errorToast(e);
    }
  };

  const settingsDisclosure = useDisclosure();
  const scheduleDisclosure = useDisclosure();
  const createViewDisclosure = useDisclosure();
  const syncScheduleSettingDisclosure = useDisclosure();

  return (
    <>
      <ToolbarWrapper>
        {table && simpleFieldsData && (
          <>
            <BadgeAndTitle
              badge={<SourceBadge />}
              title={table.displayName ?? ""}
            />
            {/* <SourceSetting
            isOpen={settingsDisclosure.isOpen}
            onOpenChange={settingsDisclosure.setIsOpen}
          /> */}
            <SourceSettingDrawer
              isOpen={settingsDisclosure.isOpen}
              onOpenChange={settingsDisclosure.setIsOpen}
            />
            {table && table.integration && (
              <ScheduleSettingDrawer
                isOpen={scheduleDisclosure.isOpen}
                syncOptions={table.integration.syncOptions}
                onOpenChange={scheduleDisclosure.setIsOpen}
              />
            )}
            {/* <Floater
            isOpen={true}
            target={
            }
            placement="bottom-start"
          ></Floater> */}
            <Flex gap="2">
              <SourceFavoriteButton />
              <TableSettingsDropdown
                table={table}
                prependChildren={
                  <>
                    <DropdownMenu.Item onClick={settingsDisclosure.onOpen}>
                      <Text>Settings</Text>
                    </DropdownMenu.Item>
                    {table &&
                      table.integration &&
                      (table.integration.syncOptions.sync ||
                        table.integration.syncOptions.append) && (
                        <DropdownMenu.Item onClick={scheduleDisclosure.onOpen}>
                          <Text>Schedule</Text>
                        </DropdownMenu.Item>
                      )}
                    <SourceToolbarBridge.SyncScheduleDropdownItems
                      teamSlug={teamSlug}
                      databaseId={databaseId}
                      tableSlug={tableSlug}
                      onSyncScheduleSettingsClick={
                        syncScheduleSettingDisclosure.onOpen
                      }
                    />
                  </>
                }
              />
            </Flex>

            <Spacer />

            <PresenceIcons maxAvatars={3} />
            <SimpleDropdownMenu
              trigger={
                <Box
                  className={
                    ElementClassNamesForOnboarding.databaseBaseTopBar
                      .createNoteBookButton.classNameText
                  }
                >
                  <Button variant="secondary" size="sm">
                    <BsPlusLg />
                    Create
                  </Button>
                </Box>
              }
            >
              <DropdownMenu.Item onClick={handleCreateViewClicked}>
                <Text>View</Text>
              </DropdownMenu.Item>
              <DropdownMenu.Item onClick={handleCreateNotebookClicked}>
                <Text>Canvas</Text>
              </DropdownMenu.Item>
              <DropdownMenu.Separator />
              <DropdownMenu.Sub>
                <DropdownMenu.SubTrigger>
                  <Text>Data Collection</Text>
                </DropdownMenu.SubTrigger>
                <DropdownMenu.SubContent>
                  <DropdownMenu.Item
                    onClick={() => handleDataCollectionClicked("rss")}
                  >
                    <Text>RSS</Text>
                  </DropdownMenu.Item>
                </DropdownMenu.SubContent>
              </DropdownMenu.Sub>
              <DropdownMenu.Separator />
              <DropdownMenu.Sub>
                <DropdownMenu.SubTrigger>
                  <Text>Data API</Text>
                </DropdownMenu.SubTrigger>
                <DropdownMenu.SubContent>
                  <DropdownMenu.Item
                    onClick={() => handleCreateDataApiClicked("query")}
                  >
                    <Text>Query API</Text>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    onClick={() => handleCreateDataApiClicked("create")}
                  >
                    <Text>Create API</Text>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    onClick={() => handleCreateDataApiClicked("update")}
                  >
                    <Text>Update API</Text>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    onClick={() => handleCreateDataApiClicked("delete")}
                  >
                    <Text>Delete API</Text>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    onClick={() => handleCreateDataApiClicked("download")}
                  >
                    <Text>Download API</Text>
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    onClick={() => handleCreateDataApiClicked("aggregate")}
                  >
                    <Text>Aggregate API</Text>
                  </DropdownMenu.Item>
                </DropdownMenu.SubContent>
              </DropdownMenu.Sub>
            </SimpleDropdownMenu>
            <SimpleDropdownMenu
              trigger={
                <Button variant="primary" size="sm">
                  Share
                </Button>
              }
            >
              <SourceToolbarShare />
            </SimpleDropdownMenu>
            <TableHistoryDrawer />
            <RecordsTrashDrawer />
            <CreateViewMenuItem
              isOpen={createViewDisclosure.isOpen}
              setIsOpen={createViewDisclosure.setIsOpen}
              popoverAnchor={<Box height="100%"></Box>}
              simpleFields={simpleFieldsData.fields}
            />
          </>
        )}
      </ToolbarWrapper>
      <SourceToolbarBridge.SyncScheduleSettingDrawer
        teamSlug={teamSlug}
        databaseId={databaseId}
        tableSlug={tableSlug}
        isOpen={syncScheduleSettingDisclosure.isOpen}
        onOpenChange={syncScheduleSettingDisclosure.setIsOpen}
      />
    </>
  );
};

const SourceToolbarWithKey = () => {
  const tableSlug = useTableSlug();
  return <SourceToolbar key={tableSlug} />;
};

export { SourceToolbarWithKey as SourceToolbar };
