import { css } from "@stitches/react";
import { useCallback, useMemo } from "react";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import {
  basicFieldFilterFn,
  BasicTypeaheadPrompt,
  FieldSuggestionItem,
} from "~/presenters/prompt";

const promptRootStyle = css({
  overflowY: "auto !important",
  height: "200px !important",
});

const promptTheme = {
  root: `${promptRootStyle()}`,
};

type PromptInputProps = {
  prompt: string;
  onPromptChange: (prompt: string) => void;
  fieldsForTypeahead: FieldsClientModel;
};

export const PromptInput = (props: PromptInputProps) => {
  const { prompt, onPromptChange, fieldsForTypeahead } = props;

  const optionItems = useMemo(
    () => fieldsForTypeahead.allFields,
    [fieldsForTypeahead]
  );

  const convertFieldToDotNotatedFieldName = useCallback(
    (field: FieldClientModel) => field.name,
    []
  );

  const renderHTMLTextContent = useCallback(
    (field: FieldClientModel) => field.label,
    []
  );

  const renderSuggestedItem = useCallback(
    (field: FieldClientModel) => <FieldSuggestionItem field={field} />,
    []
  );

  return (
    <BasicTypeaheadPrompt<FieldClientModel>
      textContent={prompt}
      onUpdate={onPromptChange}
      theme={promptTheme}
      optionItems={optionItems}
      filterFn={basicFieldFilterFn}
      convertOptionItemToText={convertFieldToDotNotatedFieldName}
      renderHTMLTextContent={renderHTMLTextContent}
      renderSuggestedItem={renderSuggestedItem}
      focusOnLoad
    />
  );
};
