import { EditingRecordClientModel } from "~/clientModel/records/editingRecord";

type EditingCellStateClientModelData = {
  editingRecord: EditingRecordClientModel | null;
};

export class EditingCellStateClientModel {
  constructor(readonly data: EditingCellStateClientModelData) {}

  public get isEditing(): boolean {
    return !!this.data.editingRecord;
  }

  public get taggedEditingRecord():
    | {
        isEditing: true;
        editingRecord: EditingRecordClientModel;
      }
    | { isEditing: false } {
    return this.data.editingRecord
      ? {
          isEditing: true,
          editingRecord: this.data.editingRecord,
        }
      : { isEditing: false };
  }

  public updateEditingRecord(
    editingRecord: EditingRecordClientModel
  ): EditingCellStateClientModel {
    return new EditingCellStateClientModel({
      editingRecord,
    });
  }

  public startEditing({
    editingRecord,
  }: {
    editingRecord: EditingRecordClientModel;
  }): EditingCellStateClientModel {
    return new EditingCellStateClientModel({
      editingRecord,
    });
  }

  public endEditing(): EditingCellStateClientModel {
    return new EditingCellStateClientModel({
      editingRecord: null,
    });
  }
}
