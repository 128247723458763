import { SimpleTableSchema } from "@usemorph/morph-dashboard-types";
import { useMutation } from "react-query";
import { CsvImportSchemaClientModelFactory } from "~/clientModel/csvImport";
import { CsvImportSchemaEditClientModel } from "~/clientModel/csvImport/CsvImportSchemaEditClientModel";
import { useComposeExecutable } from "~/clientModel/executable";
import { FieldsClientModelDefactory } from "~/clientModel/fields";
import { TableNameClientModel } from "~/clientModel/tables/tableName";
import { useUpdateSimpleImportDataSchemaMutation } from "~/serverStateStore";

export const useUpdateImportSchema = ({
  teamSlug,
  databaseId,
}: {
  teamSlug: string;
  databaseId: string;
}) => {
  const updateImportSchemaOptions = useUpdateSimpleImportDataSchemaMutation({
    teamSlug,
    databaseId,
  });
  const updateImportSchemaMutationResult = useMutation({
    ...updateImportSchemaOptions,
    mutationFn: async ({
      csvImportSchemaInstance,
      tableNameInstance,
    }: {
      csvImportSchemaInstance: CsvImportSchemaEditClientModel;
      tableNameInstance: TableNameClientModel;
    }) => {
      const schema: SimpleTableSchema = {
        name: tableNameInstance.tableSlug,
        displayName: tableNameInstance.displayName,
        datasource: csvImportSchemaInstance.estimatedDataSource,
        fields: FieldsClientModelDefactory.toSimpleFieldsWithoutSmartFields(
          csvImportSchemaInstance.fields
        ).map((simpleField, index) => ({
          ...simpleField,
          colNum: index,
        })),
      };

      const response = await updateImportSchemaOptions.mutationFn({
        importDataSchemaId: csvImportSchemaInstance.id,
        hasHeader: csvImportSchemaInstance.hasHeader,
        specifiedSchema: schema,
      });

      return CsvImportSchemaClientModelFactory.fromImportDataResponse(response);
    },
  });
  const updateImportSchemaExecutable = useComposeExecutable(
    updateImportSchemaMutationResult
  );

  return {
    updateImportSchemaExecutable,
    reset: updateImportSchemaMutationResult.reset,
  };
};
