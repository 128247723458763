import { databaseKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiDatabase from "~/api/useApiDatabase";

interface UseListDatabasesQueryParams {
  teamSlug: string;
  orderByCreatedAt?: "ASC" | "DESC" | undefined;
  orderByLastEditedAt?: "ASC" | "DESC" | undefined;
  orderByLastEditedAtByUser?: "ASC" | "DESC" | undefined;
  orderByName?: "ASC" | "DESC" | undefined;
  limit?: number;
  skip?: number;
}

const useListDatabasesQuery = ({
  teamSlug,
  orderByCreatedAt,
  orderByLastEditedAt,
  orderByLastEditedAtByUser,
  orderByName,
  limit,
  skip,
}: UseListDatabasesQueryParams) => {
  const { _listDatabases } = useApiDatabase();

  const client = useQueryClient();
  client.setQueryDefaults(
    databaseKeys.listDatabases({ teamSlug, skip, limit }),
    commonQueryOptions
  );

  return {
    queryKey: databaseKeys.listDatabases({
      teamSlug,
      orderByCreatedAt,
      orderByLastEditedAt,
      orderByLastEditedAtByUser,
      orderByName,
      limit,
      skip,
    }),
    queryFn: () => {
      return _listDatabases({
        teamSlug,
        orderByCreatedAt,
        orderByLastEditedAt,
        orderByLastEditedAtByUser,
        orderByName,
        limit,
        skip,
      });
    },
  };
};

export { useListDatabasesQuery };
