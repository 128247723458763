import { UseExecutable } from "~/clientModel/executable"
import { MessageClientModel } from "~/clientModel/general"
import { UseLoadable } from "~/clientModel/loadable/UseLoadable"
import { ListNotificationClientModel } from "~/clientModel/notification"
import { UseListNotificationQueryProps } from "~/containers/notification/createUseListNotificationLoadable"
import { NotificationDropdown } from "~/features/Notification/NotificationDropdown"
import { UseMarkAsReadNotificationMutationProps } from "~/serverStateStore/notification/mutations/useMarkAsReadNotificationMutation"

interface NotificationPresenterProps {
  useListNotificationLoadable: UseLoadable<UseListNotificationQueryProps, ListNotificationClientModel>
  useMarkAsReadExecutable: UseExecutable<void, UseMarkAsReadNotificationMutationProps, MessageClientModel, unknown>
}

export const NotificationPresenter = (props: NotificationPresenterProps) => {
  const { useListNotificationLoadable, useMarkAsReadExecutable } = props

  return (
    <>
      <NotificationDropdown
        useListNotificationLoadable={useListNotificationLoadable}
        useMarkAsReadExecutable={useMarkAsReadExecutable}
      />
    </>
  )
}
