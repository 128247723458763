import { FieldClientModel } from "~/clientModel/fields/field";
import { Input } from "~/components_next/Input";

type DisplayNameInputProps = {
  field: FieldClientModel;
  onFieldChange: (field: FieldClientModel) => void;
  isReadOnly?: boolean;
  linkedWithFieldName: boolean;
};

export const DisplayNameInput = (props: DisplayNameInputProps) => {
  const { field, onFieldChange, isReadOnly, linkedWithFieldName } = props;

  return (
    <Input
      label="Field Name"
      variant="primary"
      value={field.displayName ?? ""}
      onChange={(e) =>
        onFieldChange(
          linkedWithFieldName
            ? field.updateDisplayNameLinkingWithFieldName(e.target.value)
            : field.updateDisplayName(e.target.value)
        )
      }
      readOnly={isReadOnly}
    />
  );
};
