import { RefObject, useRef, useState } from "react";
import constate from "constate";
import { MultiSelectRootProps } from "./MultiSelectRoot";

export type MultiSelectContextValue = Required<
  Omit<
    MultiSelectRootProps,
    | "children"
    | "label"
    | "errorMessages"
    | "onChange"
    | "placeholder"
    | "filterFn"
  >
> &
  Pick<MultiSelectRootProps, "onChange" | "placeholder" | "filterFn"> & {
    isInvalid: boolean;
    queryString: string;
    setQueryString: (value: string) => void;
  } & {
    inputRef: RefObject<HTMLInputElement>;
    open: boolean;
    onOpenChange: (open: boolean) => void;
    inputValue: string;
    onChangeInputValue: (value: string) => void;
  };

const _useMultiSelectContext = (
  props: MultiSelectRootProps
): MultiSelectContextValue => {
  const {
    children,
    label,
    errorMessages,
    size = "md",
    isClearable = false,
    isDisabled = false,
    isReadOnly = false,
    ...restRequiredProps
  } = props;

  const [queryString, setQueryString] = useState("");
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const isInvalid = errorMessages && errorMessages.length > 0 ? true : false;

  const contextValue: MultiSelectContextValue = {
    size,
    isClearable,
    isDisabled,
    isInvalid,
    isReadOnly,
    queryString,
    setQueryString,
    inputRef,
    open,
    onOpenChange: setOpen,
    inputValue,
    onChangeInputValue: setInputValue,
    ...restRequiredProps,
  };

  return contextValue;
};

export const [
  MultiSelectContextProvider,
  useMultiSelectSize,
  useMultiSelectIsClearable,
  useMultiSelectIsDisabled,
  useMultiSelectIsInvalid,
  useMultiSelectIsReadOnly,
  useMultiSelectQueryString,
  useMultiSelectSetQueryString,
  useMultiSelectInputRef,
  useMultiSelectVariant,
  useMultiSelectValue,
  useMultiSelectOnChange,
  useMultiSelectFilterFn,
  useMultiSelectPlaceholder,
  useMultiSelectOpen,
  useMultiSelectOnOpenChange,
  useMultiSelectInputValue,
  useMultiSelectOnChangeInputValue,
] = constate(
  _useMultiSelectContext,
  (value) => value.size,
  (value) => value.isClearable,
  (value) => value.isDisabled,
  (value) => value.isInvalid,
  (value) => value.isReadOnly,
  (value) => value.queryString,
  (value) => value.setQueryString,
  (value) => value.inputRef,
  (value) => value.variant,
  (value) => value.value,
  (value) => value.onChange,
  (value) => value.filterFn,
  (value) => value.placeholder,
  (value) => value.open,
  (value) => value.onOpenChange,
  (value) => value.inputValue,
  (value) => value.onChangeInputValue
);
