import { notificationKeys } from "..";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiNotification from "~/api/useApiNotification";

type UseListNotificationsQueryParams = {
  teamSlug: string;
  limit?: number;
  skip?: number;
  isArchived?: boolean;
  isRead?: boolean;
};

const useListNotificationsQuery = ({
  teamSlug,
  limit,
  skip,
  isArchived,
  isRead,
}: UseListNotificationsQueryParams) => {
  const { _listNotifications } = useApiNotification();

  const client = useQueryClient();
  client.setQueryDefaults(
    notificationKeys.allListNotifications({
      teamSlug,
      limit,
      skip,
      isArchived,
      isRead,
    }),
    commonQueryOptions
  );

  return {
    queryKey: notificationKeys.allListNotifications({
      teamSlug,
      limit,
      skip,
      isArchived,
      isRead,
    }),
    queryFn: () => {
      return _listNotifications({
        teamSlug,
        limit,
        skip,
        isArchived,
        isRead,
      });
    },
  };
};

export { useListNotificationsQuery, type UseListNotificationsQueryParams };
