const getTableSlugAndFieldNameFromGroupKey = ({
  groupKey,
  mainTableSlug,
}: {
  groupKey: string;
  mainTableSlug: string;
}): {
  tableSlug: string;
  viewFieldName: string;
  originalFieldName: string;
} => {
  const splitResult = groupKey.split(".");
  const isMainTable = splitResult.length === 1;

  if (isMainTable) {
    return {
      tableSlug: mainTableSlug,
      viewFieldName: groupKey,
      originalFieldName: groupKey,
    };
  }

  return {
    tableSlug: splitResult[0],
    viewFieldName: `${splitResult[0]}_${splitResult[1]}`,
    originalFieldName: splitResult[1],
  };
};

export { getTableSlugAndFieldNameFromGroupKey };
