import {
  DashboardGenerateSqlHistoryResponse,
  DashboardGenerateSqlHistoryListResponse,
  DownloadAsCsvResponse,
  SimpleField,
  DashboardSuggestGenerateSqlResponse,
  DashboardGenerateSqlResponse,
  DashboardSqlResponse,
  DashboardGenerateSqlRequestBody,
  DashboardSuggestGenerateSqlRequestBody,
  DashboardCreateGenerateSqlHistoryRequestBody,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

export default function useApiSQL() {
  const { executeRequest } = useApi();

  const _generateSQL = async ({
    teamSlug,
    databaseId,
    body,
  }: {
    teamSlug: string;
    databaseId: string;
    body: DashboardGenerateSqlRequestBody;
  }): Promise<DashboardGenerateSqlResponse> => {
    return executeRequest<DashboardGenerateSqlResponse>(
      "POST",
      `/${databaseId}/sql/generate`,
      undefined,
      {
        teamSlug,
      },
      body
    );
  };

  const _executeSQL = async ({
    teamSlug,
    sql,
    databaseId,
  }: {
    sql: string;
    teamSlug: string;
    databaseId: string;
  }): Promise<DashboardSqlResponse> => {
    return executeRequest<DashboardSqlResponse>(
      "POST",
      `/${databaseId}/sql`,
      undefined,
      {
        teamSlug,
      },
      {
        sql,
      }
    );
  };

  const _saveGenerateSqlHistory = async ({
    teamSlug,
    databaseId,
    body,
  }: {
    teamSlug: string;
    databaseId: string;
    body: DashboardCreateGenerateSqlHistoryRequestBody;
  }): Promise<DashboardGenerateSqlHistoryResponse> => {
    return executeRequest<DashboardGenerateSqlHistoryResponse>(
      "POST",
      `/${databaseId}/sql/generate/history`,
      undefined,
      {
        teamSlug,
      },
      body
    );
  };

  const _getGeneratedSqlHistory = async ({
    databaseId,
    teamSlug,
    viewId,
    limit,
    skip,
    tableSlug,
  }: {
    databaseId: string;
    teamSlug?: string;
    tableSlug?: string;
    viewId?: string;
    limit?: number;
    skip?: number;
  }): Promise<DashboardGenerateSqlHistoryListResponse> => {
    return executeRequest<DashboardGenerateSqlHistoryListResponse>(
      "GET",
      `/${databaseId}/sql/generate/history`,
      { limit, skip, tableSlug, viewId },
      {
        teamSlug,
      },
      undefined
    );
  };

  const _downloadCSVFromExecuteSQL = async ({
    databaseId,
    teamSlug,
    sql,
    fields,
  }: {
    databaseId: string;
    teamSlug?: string;
    sql: string;
    fields: Array<SimpleField>;
  }): Promise<DownloadAsCsvResponse> => {
    return executeRequest<DownloadAsCsvResponse>(
      "POST",
      `/${databaseId}/sql/csv`,
      undefined,
      {
        teamSlug,
      },
      { sql, fields }
    );
  };

  const _listSuggestPromptForGenerateSql = async ({
    databaseId,
    teamSlug,
    body,
  }: {
    databaseId: string;
    teamSlug: string;
    body: DashboardSuggestGenerateSqlRequestBody;
  }) => {
    return executeRequest<DashboardSuggestGenerateSqlResponse>(
      "POST",
      `/${databaseId}/sql/generate/suggest`,
      undefined,
      {
        teamSlug,
      },
      body
    );
  };

  return {
    _generateSQL,
    _executeSQL,
    _saveGenerateSqlHistory,
    _getGeneratedSqlHistory,
    _downloadCSVFromExecuteSQL,
    _listSuggestPromptForGenerateSql,
  };
}
