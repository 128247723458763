import { match } from "ts-pattern";
import {
  useRecordsTableBaseColumnsMeta,
  useRecordsTableBaseRecords,
  useRecordsTableBaseTableSelection,
} from "../context/ReactTableBaseContext";
import { useTableSizings } from "../context/useTableSizing";

type EditingCellPosition = {
  x: number;
  y: number;
} | null;

export const useEditingCellPosition = (): EditingCellPosition => {
  const records = useRecordsTableBaseRecords();
  const tableSelection = useRecordsTableBaseTableSelection();
  const columnsMeta = useRecordsTableBaseColumnsMeta();
  const {
    getHeaderColumnCheckBoxWidth,
    getHeaderColumnOpenRecordButtonWidth,
    getRecordColumnWidth,
    getHeaderRowHeight,
    getBodyRowHeight,
  } = useTableSizings();

  if (!tableSelection || !tableSelection.taggedEditingRecord.isEditing) {
    return null;
  }

  const { editingRecordEntry, editingRecord } =
    tableSelection.taggedEditingRecord;

  /**
   * Calculate x
   */
  const columnsMetaBeforeTargetField = columnsMeta.slice(
    0,
    columnsMeta.findIndex(
      (columnMeta) =>
        columnMeta.type === "item" &&
        columnMeta.fieldName === editingRecordEntry.key
    )
  );

  const x = columnsMetaBeforeTargetField.reduce((widthSum, currentColumn) => {
    return match(currentColumn)
      .with(
        { type: "selectCheckbox" },
        () => widthSum + getHeaderColumnCheckBoxWidth()
      )
      .with(
        { type: "openRecordButton" },
        () => widthSum + getHeaderColumnOpenRecordButtonWidth()
      )
      .with(
        { type: "item" },
        (currentColumn) =>
          widthSum + getRecordColumnWidth(currentColumn.fieldName)
      )
      .with({ type: "addFieldButton" }, () => widthSum)
      .exhaustive();
  }, 0);

  /**
   * Calculate y
   */

  const recordIndex = records.allRecords.findIndex((record) =>
    record.recordIdentifier.isSame(editingRecord.recordIdentifierBeforeEdit)
  );

  const y = getHeaderRowHeight() + recordIndex * getBodyRowHeight();

  return {
    x,
    y,
  };
};
