import { DashboardNotebookCellObject } from "@usemorph/morph-dashboard-types";

export type CanvasCellPosition = {
  width?: number;
  height?: number;
  x?: number;
  y?: number;
};

export type CanvasCellDataType = Omit<
  DashboardNotebookCellObject,
  "settings"
> & {
  settings: CanvasCellPosition;
};

export type CanvasCellResizeParams = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export abstract class CanvasCellClientModelBase<
  Self,
  DataType extends CanvasCellDataType
> {
  constructor(readonly data: DataType) {}

  /**
   * Getters
   */
  get rawData(): DataType {
    // Liveblocksに渡すために必要なので特別に実装
    return this.data;
  }

  get cellId(): string {
    return this.data.cellId;
  }

  get cellName(): string {
    return this.data.cellName;
  }

  get cellNameForDisplay(): string {
    if (!this.cellName || this.cellName === "") {
      return "Untitled Cell";
    }
    return this.cellName;
  }

  get cellType(): CanvasCellDataType["cellType"] {
    return this.data.cellType;
  }

  get source(): CanvasCellDataType["source"] {
    return this.data.source;
  }

  get outputs(): CanvasCellDataType["outputs"] {
    return this.data.outputs;
  }

  get generatedSource(): string | null {
    return this.data.generatedSource;
  }

  get parentIds(): string[] {
    return this.data.parentIds || [];
  }

  get createdBy(): CanvasCellDataType["createdBy"] {
    return this.data.createdBy;
  }

  get settings(): CanvasCellDataType["settings"] {
    return this.data.settings;
  }

  get isPublic(): boolean {
    return this.data.isPublic;
  }

  get publicApiKey(): string | null {
    return this.data.publicApiKey;
  }

  isValuesChangedFromData(compare: CanvasCellDataType) {
    // source, outputs, settingsを比較する
    if (JSON.stringify(this.source) !== JSON.stringify(compare.source)) {
      return true;
    }
    if (JSON.stringify(this.outputs) !== JSON.stringify(compare.outputs)) {
      return true;
    }
    if (JSON.stringify(this.settings) !== JSON.stringify(compare.settings)) {
      return true;
    }
    if (this.isPublic !== compare.isPublic) {
      return true;
    }
    return false;
  }

  abstract get isResizable(): boolean;

  abstract get showTargetHandle(): boolean;

  abstract get showSourceHandle(): boolean;

  abstract updateCellName(cellName: string): Self;

  abstract onResize(resizePrams: CanvasCellResizeParams): Self;
}
