import { FieldsClientModel } from "~/clientModel/fields";
import { SortConditionUnitClientModel } from "./SortConditionUnitClientModel";

export class SortConditionUnitClientModelFactory {
  public static fromSortConditionUnit(
    sortConditionUnit: {
      key: string;
      direction: "ascending" | "descending";
    },
    fields: FieldsClientModel
  ) {
    const targetField = fields.allFields.find(
      (fieldInstance) => fieldInstance.name === sortConditionUnit.key
    );

    if (!targetField) {
      throw new Error("Failed to find target field");
    }

    return new SortConditionUnitClientModel({
      targetField: targetField,
      direction: sortConditionUnit.direction,
    });
  }
}
