import { viewKeys } from "../keys";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import useApiView from "~/api/useApiView";

type UseDeleteViewRecordMutationParams = {
  teamSlug: string;
  databaseId: string;
  viewId: string;
};

const useDeleteViewRecordMutation = ({
  teamSlug,
  databaseId,
  viewId,
}: UseDeleteViewRecordMutationParams) => {
  const { _deleteViewRecord } = useApiView();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      filterForRequest,
    }: {
      filterForRequest: Record<string, unknown>;
    }) => {
      return _deleteViewRecord({
        teamSlug,
        viewId,
        body: { filter: filterForRequest },
      });
    },
    onSuccess: () => {
      const keys = viewKeys.all({ databaseId, teamSlug });
      broadcast(keys);
      return invalidateAll(client, keys);
    },
  };
};

export { useDeleteViewRecordMutation };
