import { DashboardNotebookPromptListResponse } from "@usemorph/morph-dashboard-types";
import { CanvasVisualizationPromptConfigCientModel } from "./CanvasVisualizationPromptConfigClientModel";

export class CanvasVisualizationPromptConfigCientModelFactory {
  static fromPromptConfigResponse(
    data: DashboardNotebookPromptListResponse["prompts"][number]
  ) {
    return new CanvasVisualizationPromptConfigCientModel(data);
  }
}
