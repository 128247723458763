import {
  mergeValidationResults,
  ValidationResult,
} from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";
import { SmartFunctionsClientModel } from "./smartFunctions";

type SmartFunctionFieldTypeClientModelData = {
  smartFunctionId: string | null;
  settingData: Record<string, unknown>;
};

export class SmartFunctionFieldTypeClientModel extends FieldTypeClientModelBase {
  readonly #data: SmartFunctionFieldTypeClientModelData;

  public get type(): "smartFunction" {
    return "smartFunction";
  }

  public constructor(data: SmartFunctionFieldTypeClientModelData) {
    super();
    this.#data = data;
  }

  public get data(): SmartFunctionFieldTypeClientModelData {
    return this.#data;
  }

  public get fieldTypeLabel(): "AI" {
    return "AI";
  }

  public get isAutoGenerated(): true {
    return true;
  }

  public get isEmbeddable(): false {
    return false;
  }

  public get canBeTargetOfSyncingByComputedField(): false {
    return false;
  }

  public get smartFunctionId(): string | null {
    return this.#data.smartFunctionId;
  }

  public get smartFunctionIdOrThrow(): string {
    if (this.#data.smartFunctionId == null) {
      throw new Error("Smart function ID is not set");
    }
    return this.#data.smartFunctionId;
  }

  public get settingData(): Record<string, unknown> {
    return this.#data.settingData;
  }

  public updateSmartFunctionId(
    smartFunctionId: string
  ): SmartFunctionFieldTypeClientModel {
    return new SmartFunctionFieldTypeClientModel({
      ...this.#data,
      smartFunctionId,
    });
  }

  public updateSettingData({
    key,
    value,
  }: {
    key: string;
    value: unknown;
  }): SmartFunctionFieldTypeClientModel {
    return new SmartFunctionFieldTypeClientModel({
      ...this.#data,
      settingData: {
        ...this.#data.settingData,
        [key]: value,
      },
    });
  }

  public validate({
    smartFunctions,
  }: {
    smartFunctions: SmartFunctionsClientModel;
  }): ValidationResult {
    if (this.data.smartFunctionId == null) {
      return {
        isValid: false,
        errorMessages: ["Smart function is not selected."],
      };
    }

    const currentSmartFunction = smartFunctions.getSmartFunction(
      this.data.smartFunctionId
    );
    return mergeValidationResults(
      ...currentSmartFunction.inputForm.allInputs.map((input) =>
        input.validateValue(this)
      )
    );
  }

  public get _updatableFieldTypes() {
    return [];
  }
}
