import { RecordClientModel } from "~/clientModel/records/record";
import { BorderedBox } from "../common/BorderedBox";
import {
  useRecordsTableBaseTableSelection,
  useRecordsTableBaseHandleTableSelectionChange,
  useRecordsTableBaseFields,
} from "../context/ReactTableBaseContext";
import { useTableSizings } from "../context/useTableSizing";
import { SelectBoxBase } from "../Header/SelectBoxBase";

type ContentRowSelectBoxProps = {
  record: RecordClientModel;
  hasRightBorder: boolean;
  showSelectBox: boolean;
};

const ContentRowSelectBox = (props: ContentRowSelectBoxProps) => {
  const { record, hasRightBorder, showSelectBox } = props;

  const { getBodyRowHeight } = useTableSizings();

  const tableSelection = useRecordsTableBaseTableSelection();
  const fields = useRecordsTableBaseFields();
  const handleTableSelectionChange =
    useRecordsTableBaseHandleTableSelectionChange();

  const isChecked = tableSelection.isRecordSelected({
    recordIdentifier: record.recordIdentifier,
  });

  const handleSelectRecord = () => {
    if (isChecked) {
      handleTableSelectionChange(tableSelection.unselectRecord({ record }));
    } else {
      handleTableSelectionChange(tableSelection.selectRecord({ record }));
    }
  };

  const selectTooltip = isChecked
    ? "Deselect this record"
    : "Select this record";

  return (
    <BorderedBox bottom right={hasRightBorder}>
      {(showSelectBox || isChecked) && (
        <SelectBoxBase
          isChecked={isChecked}
          onSelect={handleSelectRecord}
          tooltip={selectTooltip}
          height={getBodyRowHeight()}
        />
      )}
    </BorderedBox>
  );
};

export { ContentRowSelectBox };
