import { DashboardDatabaseListResponse, DashboardDatabaseResponse } from "@usemorph/morph-dashboard-types";



export class ListDatabaseClientModel {
  constructor(private readonly data: DashboardDatabaseListResponse) { }

  /**
   * Getters
   */
  get count(): number {
    return this.data.count;
  }

  get items(): DashboardDatabaseResponse[] {
    return this.data.items;
  }

  findDatabase(databaseId: string) {
    return this.items.find((l) => l.databaseId === databaseId);
  }
}
