import { MutationOptions, useQueryClient } from "react-query";
import { viewKeys } from "../keys";

// types
import {
  QueryRecordListResponse,
  RecordValueType,
} from "@usemorph/morph-dashboard-types";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import useApiView from "~/api/useApiView";

// recordがfilter条件に合致するかどうかを判定する
const isMatchFilter = (
  record: Record<string, unknown>,
  filter: Record<string, unknown> // primary keyのkey, value
): boolean => {
  return Object.entries(filter).every(
    ([key, value]) => key in record && record[key] === value
  );
};

interface UseOptimisticUpdateViewRecordsParams {
  teamSlug: string;
  databaseId: string;
  viewId: string;
}

const useOptimisticUpdateViewRecordsMutation = ({
  teamSlug,
  databaseId,
  viewId,
}: UseOptimisticUpdateViewRecordsParams): MutationOptions<
  QueryRecordListResponse,
  unknown,
  {
    values: { key: string; value: RecordValueType }[];
    filterForRequest: Record<string, unknown>; // primaryKeyのkey, value
    currentKeyParams: Omit<
      Parameters<typeof viewKeys.queryViewRecords>[0],
      "teamSlug" | "databaseId" | "viewId"
    >;
  },
  unknown
> => {
  const { _updateViewRecord } = useApiView();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  const queryViewRecordsKey = viewKeys.allQueryViewRecords({
    teamSlug,
    databaseId,
    viewId,
  });

  return {
    mutationFn: ({ values, filterForRequest }) => {
      return _updateViewRecord({
        teamSlug,
        viewId,
        body: {
          values,
          filter: filterForRequest,
          fixedValue: [],
        },
      });
    },
    onMutate: ({ values, filterForRequest, currentKeyParams }) => {
      const currentQueryViewRecordsKey = viewKeys.queryViewRecords({
        teamSlug,
        databaseId,
        viewId,
        ...currentKeyParams,
      });
      const currentData = client.getQueryData<QueryRecordListResponse>(
        currentQueryViewRecordsKey
      );

      if (!currentData) return;

      const updatedItems = currentData.items.map((recordBeforeEdit) => {
        if (isMatchFilter(recordBeforeEdit, filterForRequest)) {
          return {
            ...recordBeforeEdit,
            ...values.reduce(
              (record, { key, value }) => ({ ...record, [key]: value }),
              {}
            ),
          };
        } else {
          return recordBeforeEdit;
        }
      });

      client.setQueryData(currentQueryViewRecordsKey, {
        ...currentData,
        items: updatedItems,
      });
    },
    onSuccess: () => {
      broadcast(queryViewRecordsKey);
      return invalidateAll(client, queryViewRecordsKey);
    },
    onError: () => {
      return invalidateAll(client, queryViewRecordsKey);
    },
  };
};

export { useOptimisticUpdateViewRecordsMutation };
