import { RecordModel } from "~/features/RecordModel";

export const getPrimaryKeyValues = (record: RecordModel) => {
  return Object.keys(record)
    .filter((key) => {
      const recordModelValue = record[key];
      return recordModelValue && recordModelValue.isPrimary;
    })
    .map((key) => {
      const recordModelValue = record[key];
      if (recordModelValue && recordModelValue.isPrimary) {
        return String(recordModelValue.value);
      }
      return undefined;
    })
    .join("-");
};
