import { SmartFunctionClientModel } from "./smartFunction/SmartFunctionClientModel";

type SmartFunctionsClientModelData = {
  smartFunctions: SmartFunctionClientModel[];
};

export class SmartFunctionsClientModel {
  public constructor(readonly data: SmartFunctionsClientModelData) {}

  public get allSmartFunctions(): SmartFunctionClientModel[] {
    return this.data.smartFunctions;
  }

  public getSmartFunction(functionId: string): SmartFunctionClientModel {
    const smartFunction = this.allSmartFunctions.find(
      (smartFunction) => smartFunction.functionId === functionId
    );
    if (!smartFunction) {
      throw new Error(
        `Smart function with id ${functionId} not found in smart functions client model`
      );
    }
    return smartFunction;
  }
}
