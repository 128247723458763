import { DashboardDatabaseResponse } from "@usemorph/morph-dashboard-types";
import { DatabaseClientModel } from ".";

export const DatabaseClientModelFactory = {
  // factory methods
  fromfindDatabaseResponse: (
    data: DashboardDatabaseResponse
  ): DatabaseClientModel => {
    return new DatabaseClientModel(data);
  },
};
