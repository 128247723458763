import {
  DashboardNotebookCellSourceVariableObject,
  DashboardNotebookCellVariableRequestObject,
} from "@usemorph/morph-dashboard-types";
import constate from "constate";

// 他のvariable使う箇所と共通とは限らないので、一旦ここに定義
export type ViewCellVariable = {
  cellId: string;
  name: string;
  value: DashboardNotebookCellSourceVariableObject["defaultValue"] | null;
};

const useVariablesContext = ({
  variables,
}: {
  variables: DashboardNotebookCellVariableRequestObject;
}) => {
  return {
    variables,
  };
};

export const [VariablesProvider, useVariables] = constate(
  useVariablesContext,
  (value) => value.variables
);
