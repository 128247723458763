import * as Form from '@radix-ui/react-form';
import { Checkbox } from "~/components_next/Checkbox";
import { Dispatch, SetStateAction, useMemo } from "react";
import { Text } from "~/components_next/Text";
import { ScheduleCoreInput } from "./IntegrationForm";
import { SimpleSelect } from '~/components_next/Select';
import { Box } from '~/components_next/Box';
import { Flex } from '~/components_next/Flex';
import { Link } from '@radix-ui/themes';
import { usePreferenceModal } from "../../../GlobalNavigation/components/Preference/usePreferenceModal";
import { TimezoneType } from '@usemorph/morph-dashboard-types';

interface IntegrationScheduleFormProps {
  scheduleBody: ScheduleCoreInput;
  syncOptions: { sync: boolean; append: boolean; };
  setScheduleBody: Dispatch<SetStateAction<ScheduleCoreInput>>
  timezone?: TimezoneType
}

export const convertToScheduledMins = (time: string) => {
  const timeParts = time.split(':'); // 時間と分を分割
  const hours = parseInt(timeParts[0]); // 時間部分を整数に変換
  const minutes = parseInt(timeParts[1]); // 分部分を整数に変換
  const totalMinutes = hours * 60 + minutes; // 時間を分に変換
  return totalMinutes
}

export const convertToHHMM = (minutes: number) => {
  const hours = Math.floor(minutes / 60); // 時間を計算
  const remainingMinutes = minutes % 60; // 残りの分を計算
  const formattedTime = `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}`;
  return formattedTime;
}

const convertToAMPM = (hour: number): string => {
  if (hour === 0) {
    return '12 AM'; // 午前 12 時は 12 AM
  } else if (hour === 12) {
    return '12 PM'; // 午後 12 時は 12 PM
  } else if (hour < 12) {
    return `${hour} AM`; // 午前 hh AM (1 から 11 時)
  } else {
    return `${hour - 12} PM`; // 午後 (hh - 12) PM (1 から 11 時)
  }
}

export const IntegrationScheduleForm = (props: IntegrationScheduleFormProps) => {
  const { scheduleBody, syncOptions, setScheduleBody, timezone } = props

  const { onOpen: onOpenPreferenceModal } = usePreferenceModal();

  const categoryOptions = useMemo(() => {
    const options = []
    if (syncOptions.sync) {
      options.push({ label: "Complete change", value: "sync" },)
    }
    if (syncOptions.append) {
      options.push({ label: "Add only new records", value: "append" })
    }
    return options
  }, [syncOptions])

  const onChangeScheduleTime = (type: 'hour' | 'minute', time: string) => {
    const hhmm = convertToHHMM(scheduleBody.scheduledMins)
    const timeParts = hhmm.split(':')
    const hours = parseInt(timeParts[0])
    const minutes = parseInt(timeParts[1])
    if (type === 'hour') {
      const newTime = `${time}:${minutes}`
      const newScheduledMins = convertToScheduledMins(newTime)
      setScheduleBody({ ...scheduleBody, scheduledMins: newScheduledMins })
    } else {
      const newTime = `${hours}:${time}`
      const newScheduledMins = convertToScheduledMins(newTime)
      setScheduleBody({ ...scheduleBody, scheduledMins: newScheduledMins })
    }
  }

  const dayOptions = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  return (
    <>
      {
        categoryOptions.length > 0 && (
          <Box>
            <Form.Field name="category">

              <SimpleSelect
                label="catogory"
                variant="primary"
                value={scheduleBody.category}
                onChange={(el) => {
                  if (el) {
                    setScheduleBody({ ...scheduleBody, category: el as 'sync' | 'append' })
                  }
                }}
                size="sm"
                options={categoryOptions}
              />
            </Form.Field>
          </Box>
        )
      }

      <Box mt="3">
        <Form.Field name="scheduleTime">
          <Form.Label>
            <Text variant="default" fontWeight="regular" mb="1">
              Schedule time
              {
                timezone ? ` (${timezone})` : ""
              }
            </Text>
          </Form.Label>
          <Flex align="center">
            <SimpleSelect
              variant='primary'
              value={convertToHHMM(scheduleBody.scheduledMins).split(':')[0]}
              onChange={(el) => {
                if (el) {
                  onChangeScheduleTime('hour', el)
                }
              }}
              size="sm"
              options={
                [...Array(24).keys()].map(i => {
                  return (
                    {
                      label: convertToAMPM(i + 1),
                      value: String(i + 1).padStart(2, '0'),
                    }
                  )
                })
              }
            />
            <Box px="2">
              <Text>:</Text>
            </Box>
            <SimpleSelect
              variant='primary'
              value={convertToHHMM(scheduleBody.scheduledMins).split(':')[1]}
              onChange={(el) => {
                if (el) {
                  onChangeScheduleTime('minute', el)
                }
              }}
              size="sm"
              options={[
                { label: "00", value: "00" },
                { label: "15", value: "15" },
                { label: "30", value: "30" },
                { label: "45", value: "45" },
              ]}
            />
          </Flex>
        </Form.Field>
      </Box>

      <Flex mt="2" mb="4" align="center" wrap="wrap">
        <Text variant="description" mr="2">{`This import will trigger according to your account-level`}</Text>
        <Link
          weight="medium"
          onClick={() => onOpenPreferenceModal("myAccount")}
        >
          timezone settings
        </Link>
        <Text>.</Text>
        <Text variant="description">{`If you haven't set a timezone, it will default to UTC (GMT+00:00). Timezone settings will not apply to this import.`}</Text>
      </Flex>

      <Box mt="3">
        <Form.Field name="day">
          {/* <Form.FormLabel variant="default" fontWeight="light" fontSize="sm" mb={3}>Day</Form.FormLabel> */}
          <Flex gap="4">
            {
              dayOptions.map((opt, ind) => {
                return (
                  <Flex key={`${opt}${ind}`} direction="column" align="center">
                    <Checkbox
                      id={`${opt}${ind}`}
                      value={scheduleBody.targetDays[ind] === 1}
                      onChange={(el) => {
                        const targetDays = scheduleBody.targetDays
                        targetDays[ind] = el ? 1 : 0
                        setScheduleBody({ ...scheduleBody, targetDays })
                      }}
                    />
                    <Text variant="description">{opt}</Text>
                  </Flex>
                )
              })
            }
          </Flex>
        </Form.Field>
      </Box>

    </>
  )
}
