import { SimpleField } from "@usemorph/morph-dashboard-types";
import { FieldsClientModel } from "~/clientModel/fields";
import { RecordIdentifierClientModel } from "./RecordIdentifierClientModel";

export class RecordIdentifierClientModelFactory {
  public static createFromRecordAndSimpleFields({
    record,
    simpleFields,
  }: {
    record: Record<string, unknown>;
    simpleFields: SimpleField[];
  }): RecordIdentifierClientModel {
    const primaryKeyFieldNames = simpleFields.flatMap(({ primary, name }) =>
      primary ? [name] : []
    );

    return this.createFromRecordAndPrimaryKeyFieldNames({
      record,
      primaryKeyFieldNames,
    });
  }

  public static createFromRecordAndFields({
    record,
    fields,
  }: {
    record: Record<string, unknown>;
    fields: FieldsClientModel;
  }): RecordIdentifierClientModel {
    const primaryKeyFieldNames = fields.primaryFields.allFields.map(
      ({ name }) => name
    );

    return this.createFromRecordAndPrimaryKeyFieldNames({
      record,
      primaryKeyFieldNames,
    });
  }

  private static createFromRecordAndPrimaryKeyFieldNames({
    record,
    primaryKeyFieldNames,
  }: {
    record: Record<string, unknown>;
    primaryKeyFieldNames: string[];
  }): RecordIdentifierClientModel {
    if (primaryKeyFieldNames.length > 0) {
      // pkがあるときは、pkのみidentityRecordに含める

      const identityRecord = Object.entries(record).reduce(
        (identityRecord, [key, value]) => {
          const isPrimaryKey = primaryKeyFieldNames.includes(key);

          if (!isPrimaryKey) return identityRecord;

          return {
            ...identityRecord,
            [key]: value,
          };
        },
        {} as Record<string, unknown>
      );

      return new RecordIdentifierClientModel({
        identityRecord,
      });
    } else {
      // pkがないときは、全てのフィールドをidentityRecordに含める

      return new RecordIdentifierClientModel({
        identityRecord: record,
      });
    }
  }
}
