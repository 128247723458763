import { NodeProps, NodeToolbar, Position } from "reactflow";
import {
  CanvasCellSourceClientModel,
  CanvasCellVariablesClientModel,
  CanvasCellViewClientModel,
} from "~/clientModel/canvas/CanvasCellClientModel";
import { CanvasCellVariables } from "~/presenters/canvas/cellContent/variable/CanvasCellVariables";
import { PlaygroundCellCard } from "../common/PlaygroundCellCard";
import { useGetAncestorVeiwOrSourceCells } from "../../providers/PlaygroundAncestorUtilProvider";
import { memo, useMemo } from "react";
import {
  useUseSourceFieldsLoadable,
  useUseViewFieldsLoadable,
} from "../../providers/PlaygroundUseSourceFIeldsLoadableProvider";
import { PlaygroundCellCreateCellButton } from "../common/CreateCellButton/PlaygroundCellCreateCellButton";
import { P, match } from "ts-pattern";
import { useUseVariableOptionsLoadable } from "../../../common/CanvasVariableOptionsLoadableProvider";

type PlaygroundCellVariablesProps = {
  cellClientModel: CanvasCellVariablesClientModel;
};

const AddCellButtonWrapperWithAncestorSource = (
  props: PlaygroundCellVariablesProps & {
    parentCell: CanvasCellSourceClientModel;
    isSelected: boolean;
  }
) => {
  const { cellClientModel, parentCell, isSelected } = props;

  const useSourceFieldsLoadable = useUseSourceFieldsLoadable();
  const fieldsLoadable = useSourceFieldsLoadable({
    tableSlug: parentCell.tableSlug || "",
  });

  return (
    <>
      <NodeToolbar position={Position.Bottom} isVisible={isSelected}>
        <PlaygroundCellCreateCellButton
          canvasCellClientModel={cellClientModel}
          fieldsLoadable={fieldsLoadable}
        />
      </NodeToolbar>
    </>
  );
};

const AddCellButtonWrapperWithAncestorView = (
  props: PlaygroundCellVariablesProps & {
    parentCell: CanvasCellViewClientModel;
    isSelected: boolean;
  }
) => {
  const { cellClientModel, parentCell, isSelected } = props;

  const useViewFieldsLoadable = useUseViewFieldsLoadable();
  const fieldsLoadable = useViewFieldsLoadable({
    viewId: parentCell.viewId || "",
  });

  return (
    <>
      <NodeToolbar position={Position.Bottom} isVisible={isSelected}>
        <PlaygroundCellCreateCellButton
          canvasCellClientModel={cellClientModel}
          fieldsLoadable={fieldsLoadable}
        />
      </NodeToolbar>
    </>
  );
};

const PlaygroundCellVariables = memo(
  (props: NodeProps<PlaygroundCellVariablesProps>) => {
    const { data, selected, dragging, xPos, yPos } = props;
    const { cellClientModel } = data;

    const getAncestorViewOrSourceCells = useGetAncestorVeiwOrSourceCells();
    const ancestorViewOrSourceCells = useMemo(() => {
      return getAncestorViewOrSourceCells(cellClientModel);
    }, [cellClientModel, getAncestorViewOrSourceCells]);
    const ancestorViewOrSourceCell = useMemo(() => {
      if (ancestorViewOrSourceCells.length > 0) {
        return ancestorViewOrSourceCells[0];
      }
      return null;
    }, [ancestorViewOrSourceCells]);

    const useVariableOptionsLoadable = useUseVariableOptionsLoadable();

    return (
      <PlaygroundCellCard
        cellClientModel={cellClientModel}
        isNodeDragging={dragging}
        isNodeSelected={selected}
        position={{ x: xPos, y: yPos }}
      >
        <CanvasCellVariables
          variableCellClientModel={cellClientModel}
          useVariableOptionsLoadable={useVariableOptionsLoadable}
        />
        {match(ancestorViewOrSourceCell)
          .with(
            P.instanceOf(CanvasCellSourceClientModel),
            (parentCell: CanvasCellSourceClientModel) => {
              return (
                <AddCellButtonWrapperWithAncestorSource
                  cellClientModel={cellClientModel}
                  parentCell={parentCell}
                  isSelected={selected}
                />
              );
            }
          )
          .with(
            P.instanceOf(CanvasCellViewClientModel),
            (parentCell: CanvasCellViewClientModel) => {
              return (
                <AddCellButtonWrapperWithAncestorView
                  cellClientModel={cellClientModel}
                  parentCell={parentCell}
                  isSelected={selected}
                />
              );
            }
          )
          .otherwise(() => null)}
      </PlaygroundCellCard>
    );
  }
);

PlaygroundCellVariables.displayName = "PlaygroundCellVariables";

export { PlaygroundCellVariables, type PlaygroundCellVariablesProps };
