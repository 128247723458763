import { useMutation } from "react-query";
import { ListCanvasPageClientModelFactory } from "~/clientModel/canvas/ListCanvasPageClientModel";
import { useComposeExecutable } from "~/clientModel/executable";
import { useCreateNotebookPageMutaiton } from "~/serverStateStore/notebookPage";

const useCreatePageExecutable = (props: {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
}) => {
  const { teamSlug, databaseId, notebookId } = props;

  const createPageMutationOptions = useCreateNotebookPageMutaiton({
    teamSlug,
    notebookId,
    databaseId,
  });

  return useComposeExecutable(
    useMutation({
      ...createPageMutationOptions,
      mutationFn: async (params: {
        pageName: string;
        description: string;
        isPrivate: boolean;
      }) => {
        const rawResponse = await createPageMutationOptions.mutationFn(params);
        return ListCanvasPageClientModelFactory.fromNotebookResponse(
          rawResponse
        );
      },
    })
  );
};

export const createUseCreatePageExecutable = (props: {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
}) => {
  const { teamSlug, databaseId, notebookId } = props;

  return () => useCreatePageExecutable({ teamSlug, databaseId, notebookId });
};
