import { useQueryClient } from "react-query";
import useApiCanvasStyle from "~/api/useApiCanvasStyle";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { canvasStyleKeys } from "../keys";

const useSaveNewColorsMutation = ({ teamSlug }: { teamSlug: string }) => {
  const { _saveNewColors } = useApiCanvasStyle();

  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({ colorCodes }: { colorCodes: string[] }) => {
      return _saveNewColors({
        colorCodes,
        teamSlug,
      });
    },
    onSuccess: () => {
      const keys = canvasStyleKeys.myColors({ teamSlug });

      broadcast(keys);

      return invalidateAll(client, keys);
    },
  };
};

export { useSaveNewColorsMutation };
