import { BsPlusLg } from "react-icons/bs";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useErrorToast } from "~/components_next/Error";
import { Button } from "~/components_next/Button";
import {
  DropdownMenu,
  SimpleDropdownMenu,
} from "~/components_next/DropdownMenu";
import { Text } from "~/components_next/Text";
import { getPath } from "~/routing";
import { useCreateNotebookMutation } from "~/serverStateStore";
import { useFindViewQuery } from "~/serverStateStore/views";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useViewId } from "~/utilHooks/useViewId";

const CreateButton = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  const { data: viewData } = useQuery({
    ...useFindViewQuery({
      teamSlug,
      databaseId,
      viewId,
    }),
  });

  const { mutateAsync: createNotebook } = useMutation({
    ...useCreateNotebookMutation({ teamSlug, databaseId, viewId }),
  });

  const { errorToast } = useErrorToast({});
  const navigate = useNavigate();

  const handleCreateNotebookClicked = async () => {
    try {
      if (!viewData) {
        throw new Error("Something went wrong.");
      }
      const notebook = await createNotebook({
        notebookName: "Untitled Notebook",
        description: "",
        condition: viewData.condition,
      });

      navigate(
        getPath("notebook", {
          teamSlug,
          databaseId,
          notebookId: notebook.notebookId,
        })
      );
    } catch (e: unknown) {
      errorToast(e);
    }
  };

  return (
    <SimpleDropdownMenu
      trigger={
        <Button isDisabled={!viewData} variant="tertiary" size="sm">
          <BsPlusLg />
          Create
        </Button>
      }
    >
      <DropdownMenu.Item onClick={handleCreateNotebookClicked}>
        <Text>Notebook</Text>
      </DropdownMenu.Item>
    </SimpleDropdownMenu>
    // <DropdownMenu
    //   trigger={
    //     <Button
    //       isDisabled={!viewData}
    //       variant="tertiary"
    //       size="sm"
    //       leftIcon={<BsPlusLg />}
    //     >
    //       Create
    //     </Button>
    //   }
    //   contentWidth="200px"
    // >
    //   <DropdownMenuItem onClick={handleCreateNotebookClicked}>
    //     <Text>Notebook</Text>
    //   </DropdownMenuItem>
    // </DropdownMenu>
  );
};

export { CreateButton };
