import { ScrollArea } from "@radix-ui/themes";
import { DashboardViewConditionObject } from "@usemorph/morph-dashboard-types";
import { CanvasCellViewClientModel } from "~/clientModel/canvas/CanvasCellClientModel";
import { usePublicDashboardTableViewQueryConditions } from "./usePublicDashboardTableViewQueryConditions";
import {
  useUseDownloadCsvExecutable,
  useUseDownloadCsvPreviewRecordsLoadable,
  useUseFieldsWithConditionLoadable,
  useUseRecordsWithConditionLoadable,
} from "../providers/PublicDashboardTableViewPropsProvider";
import { Flex } from "~/components_next/Flex";
import { PublicCanvasTableViewToolbar } from "../../cellContent/publicView/PublicCanvasTableViewToolbar";
import { PublicCanvasTableView } from "../../cellContent/publicView/PublicCanvasTableView";
import { useCallback } from "react";
import {
  CsvDownloadFieldsClientModel,
  CsvDownloadFieldsClientModelFactory,
} from "~/clientModel/csvDownload/csvDownloadFields";
import { useLoadableState } from "~/clientModel/loadable";
import { useDisclosure } from "~/hooks/useDisclosure";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { DownloadRecordsWithQueryDrawer } from "~/presenters/downloadRecords";

type DashboardPageViewCellProps = {
  viewCellInstance: CanvasCellViewClientModel;
};

const PublicDashboardPageViewCellContent = (
  props: DashboardPageViewCellProps & {
    viewId: string;
    condition: DashboardViewConditionObject;
  }
) => {
  const { viewCellInstance, viewId, condition } = props;

  const { queryConditions, onQueryConditionsChange } =
    usePublicDashboardTableViewQueryConditions();

  // loadables
  const useRecordsWithConditionLoadable = useUseRecordsWithConditionLoadable();
  const recordsLoadable = useRecordsWithConditionLoadable({
    filterConditions: queryConditions.filterConditions,
    sortConditions: queryConditions.sortConditions,
    pagination: queryConditions.pagination,
    viewId,
  });

  const useFieldsWithConditionLoadable = useUseFieldsWithConditionLoadable();
  const fieldsLoadable = useFieldsWithConditionLoadable({
    viewId,
  });

  /**
   * CSV download
   */
  const _useDownloadCsvPreviewRecordsLoadable =
    useUseDownloadCsvPreviewRecordsLoadable();
  const useDownloadCsvPreviewRecordsLoadable = useCallback(
    (props: { csvDownloadFields: CsvDownloadFieldsClientModel }) => {
      const { csvDownloadFields } = props;
      return _useDownloadCsvPreviewRecordsLoadable({
        viewId,
        csvDownloadFields,
      });
    },
    [viewId, _useDownloadCsvPreviewRecordsLoadable]
  );
  const _useDownloadCsvExecutable = useUseDownloadCsvExecutable();
  const useDownloadCsvExecutable = useCallback(() => {
    return _useDownloadCsvExecutable({
      viewId,
    });
  }, [viewId, _useDownloadCsvExecutable]);

  const [csvDownloadWithQueryFieldsLoadable, setCsvDownloadWithQueryFields] =
    useLoadableState<CsvDownloadFieldsClientModel>();

  const {
    onOpen: onDownloadCsvWithQueryDrawerOpen,
    isOpen: isDownloadCsvWithQueryDrawerOpen,
    setIsOpen: downloadCsvWithQueryDrawerOpenChange,
  } = useDisclosure();

  const onDownloadClick = useCallback(() => {
    onDownloadCsvWithQueryDrawerOpen();
    if (fieldsLoadable.data) {
      setCsvDownloadWithQueryFields(
        CsvDownloadFieldsClientModelFactory.fromFieldsClientModel(
          fieldsLoadable.data
        )
      );
    }
  }, [onDownloadCsvWithQueryDrawerOpen, fieldsLoadable.data]);

  return (
    <>
      <Flex direction="column" gap="2">
        <PublicCanvasTableViewToolbar
          recordsLoadable={recordsLoadable}
          fieldsLoadable={fieldsLoadable}
          queryConditions={queryConditions}
          onQueryConditionsChange={onQueryConditionsChange}
        />
        <PublicCanvasTableView
          recordsLoadable={recordsLoadable}
          fieldsLoadable={fieldsLoadable}
          pagination={queryConditions.pagination}
          onPaginationChange={(pagination) =>
            onQueryConditionsChange({
              ...queryConditions,
              pagination,
            })
          }
          isOpenable={false}
          height={600}
          onDownloadClick={onDownloadClick}
        />

        <WithFallback loadables={[csvDownloadWithQueryFieldsLoadable] as const}>
          {([csvDownloadWithQueryFields]) => (
            <DownloadRecordsWithQueryDrawer
              isOpen={isDownloadCsvWithQueryDrawerOpen}
              onOpenChange={downloadCsvWithQueryDrawerOpenChange}
              csvDownloadFields={csvDownloadWithQueryFields}
              onCsvDownloadFieldsChange={setCsvDownloadWithQueryFields}
              useDownloadExecutable={useDownloadCsvExecutable}
              usePreviewRecordsLoadable={useDownloadCsvPreviewRecordsLoadable}
            />
          )}
        </WithFallback>
      </Flex>
    </>
  );
};

const PublicDashboardPageViewCell = (props: DashboardPageViewCellProps) => {
  const { viewCellInstance } = props;

  // TODO: Fallback
  if (!viewCellInstance.viewId || !viewCellInstance.viewCondition) {
    return <></>;
  }

  return (
    <ScrollArea style={{ height: "100%" }}>
      <PublicDashboardPageViewCellContent
        {...props}
        viewId={viewCellInstance.viewId}
        condition={viewCellInstance.viewCondition}
      />
    </ScrollArea>
  );
};

export { PublicDashboardPageViewCell };
