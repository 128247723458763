export type MergeTarget = {
  tableSlug: string;
  fieldName: string;
};

type MergeKeyClientModelData = {
  targets: MergeTarget[];
};

export class MergeKeyClientModel {
  readonly #data: MergeKeyClientModelData;

  constructor(data: MergeKeyClientModelData) {
    this.#data = data;
  }

  public get data(): MergeKeyClientModelData {
    return this.#data;
  }

  public get hasTargets(): boolean {
    return this.#data.targets.length > 0;
  }

  public get findTargetFieldName(): (tableSlug: string) => string | null {
    return (tableSlug: string) => {
      const target = this.#data.targets.find(
        (target) => target.tableSlug === tableSlug
      );
      return target?.fieldName ?? null;
    };
  }

  public addTarget(target: MergeTarget): MergeKeyClientModel {
    return new MergeKeyClientModel({
      ...this.#data,
      targets: [...this.#data.targets, target],
    });
  }
}
