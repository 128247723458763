import {
  DashboardTableHistoryObject,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import dayjs from "dayjs";
import { useCallback } from "react";
import { BsArrowRight, BsDownload } from "react-icons/bs";
import { match } from "ts-pattern";
import { Button } from "~/components_next/Button";
import { Text } from "~/components_next/Text";
import { Tooltip } from "~/components_next/Tooltip";
import { UserMention } from "~/components_next/User";
import { Spacer } from "~/components_next/Spacer";
import { getOperationLabel } from "../util/getOperationLabel";
import { TableHistoryConditionWrapper } from "./TableHistoryConditionWrapper";
// import { Divider } from "~/components_next/Divider";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
import { Separator } from "@radix-ui/themes";

type TableHistoryCellProps = {
  history: DashboardTableHistoryObject;
  fields: SimpleField[];
};

const TableHistoryCell = (props: TableHistoryCellProps) => {
  const { history, fields } = props;

  const getDisplayName = useCallback(
    (name: string) => {
      const targetField = fields.find((field) => field.name === name);
      if (targetField) {
        return targetField.displayName;
      }
      return name;
    },
    [fields]
  );

  const getDetailLogsValue = ({
    detailLogs,
  }: {
    detailLogs: {
      type:
        | "tableDisplayName"
        | "fieldDisplayName"
        | "formula"
        | "tableType"
        | "permission";
      valueBefore: string | number | boolean | null;
      valueAfter: string | number | boolean | null;
    };
  }) => {
    return match(detailLogs.type)
      .with("permission", () => {
        return {
          ...detailLogs,
          valueBefore: detailLogs.valueBefore ? "Private" : "In Team",
          valueAfter: detailLogs.valueAfter ? "Private" : "In Team",
        };
      })
      .otherwise(() => detailLogs);
  };

  return (
    <Box p="2">
      <Flex direction="column" align="stretch" gap="3">
        {/* By & Date */}
        <Flex align="center" mb="2">
          {history.createdBy && <UserMention user={history.createdBy} />}
          <Spacer />
          <Tooltip content={history.operatedAt}>
            <Text variant="description">
              {dayjs(history.operatedAt).fromNow()}
            </Text>
          </Tooltip>
        </Flex>

        {/* Operation */}
        <Box>
          <Text fontWeight="medium">
            {getOperationLabel({
              key: history.operation,
              username: history.createdBy?.username ?? "Guest User",
              isAdmin: history.createdBy?.isAdmin ?? false,
              isBulk: history.data?.isBulk ?? false,
            })}
          </Text>
        </Box>

        {/* Targets */}
        {history.targets && !history.detailLogs && (
          <Box>
            <Box>
              <Text style={{ display: "inline" }} variant="subheading" mr="2">
                Target Fields :
              </Text>
            </Box>
            <Box>
              <Text style={{ display: "inline" }}>
                {history.targets
                  .map(
                    (target, index) =>
                      getDisplayName(target) ?? target.toUpperCase()
                  )
                  .join(", ")}
              </Text>
            </Box>
          </Box>
        )}

        {/* detailLogs */}
        {history.detailLogs &&
          history.detailLogs
            .map((detailLog) => getDetailLogsValue({ detailLogs: detailLog }))
            .map((d, index) => {
              return (
                <Flex key={index} gap="2" align="center">
                  {(typeof d.valueBefore === "string" ||
                    typeof d.valueBefore === "number" ||
                    typeof d.valueBefore === "boolean") && (
                    <>
                      <Text>{d.valueBefore}</Text>
                      <BsArrowRight />
                    </>
                  )}

                  <Text>{d.valueAfter}</Text>
                </Flex>
              );
            })}

        {/* condition */}
        {history.data && history.data.condition && (
          <TableHistoryConditionWrapper
            condition={history.data.condition}
            fields={fields ?? []}
          />
        )}

        {/* filePath */}
        {history.filePath && (
          <Flex justify="start">
            <Button
              onClick={() => window.open(history.filePath ?? "")}
              variant="actionText"
            >
              <BsDownload />
              Download as CSV
            </Button>
          </Flex>
        )}

        {/* WIP */}

        <Separator size="4" mt="2" />
      </Flex>
    </Box>
  );
};

export { TableHistoryCell };
