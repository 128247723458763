import { tablesKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiTable from "~/api/useApiTable";

interface UseListTablesQueryParams {
  teamSlug: string;
  databaseId: string;
  limit?: number;
  skip?: number;
}

const useListTablesQuery = ({
  databaseId,
  teamSlug,
  limit,
  skip,
}: UseListTablesQueryParams) => {
  const { _listTables } = useApiTable();

  const client = useQueryClient();
  client.setQueryDefaults(
    tablesKeys.listTables({ databaseId, teamSlug, skip, limit }),
    commonQueryOptions
  );

  return {
    queryKey: tablesKeys.listTables({
      databaseId,
      teamSlug,
      limit,
      skip,
    }),
    queryFn: () => {
      return _listTables({
        databaseId,
        teamSlug,
        limit,
        skip,
      });
    },
  };
};

export { useListTablesQuery };
