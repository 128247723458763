type SimpleFieldsDependencies = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

type GetSimpleFieldDependencies = SimpleFieldsDependencies;

type GenerateFormulaDependencies = {
  message: string;
} & SimpleFieldsDependencies;

const simpleFieldsKeys = {
  /**
   * all
   */
  all: ({ teamSlug, databaseId, tableSlug }: SimpleFieldsDependencies) =>
    [teamSlug, databaseId, tableSlug, "simpleFields"] as const,

  /**
   * list fields
   */
  allGetSimpleField: ({
    teamSlug,
    databaseId,
    tableSlug,
  }: SimpleFieldsDependencies) =>
    [
      ...simpleFieldsKeys.all({ teamSlug, databaseId, tableSlug }),
      "getSimpleField",
    ] as const,
  getSimpleField: ({
    teamSlug,
    databaseId,
    tableSlug,
  }: GetSimpleFieldDependencies) =>
    [
      ...simpleFieldsKeys.allGetSimpleField({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    ] as const,

  /**
   * Generate formula
   */
  allGenerateFormula: ({
    teamSlug,
    databaseId,
    tableSlug,
  }: SimpleFieldsDependencies) =>
    [
      ...simpleFieldsKeys.all({ teamSlug, databaseId, tableSlug }),
      "generateFormula",
    ] as const,
  generateFormula: ({
    teamSlug,
    databaseId,
    tableSlug,
    message,
  }: GenerateFormulaDependencies) => {
    return [
      ...simpleFieldsKeys.allGenerateFormula({
        teamSlug,
        databaseId,
        tableSlug,
      }),
      message,
    ];
  },

  /**
   * List Computed Field Options For Aggregation
   * 基本的に変わらないので常ににキャッシュする
   */
  listComputedFieldOptionsForAggregation: () => [
    "listComputedFieldOptionsForAggregation",
  ],

  /**
   * List Computed Field Options For Smart Function
   * 基本的に変わらないので常ににキャッシュする
   */
  listComputedFieldOptionsForSmartFunction: () => [
    "listComputedFieldOptionsForSmartFunction",
  ],
};

// all simple fieldsは、table直下のデータではなくdatabaseIdの直下のデータ
type AllSimpleFieldsDependencies = {
  teamSlug: string;
  databaseId: string;
};

type GetAllSimpleFieldDependencies = {
  teamSlug: string;
  databaseId: string;
};

const allSimpleFieldsKeys = {
  all: ({ teamSlug, databaseId }: AllSimpleFieldsDependencies) =>
    [teamSlug, databaseId, "allSimpleFields"] as const,

  allGetAllSimpleField: ({
    teamSlug,
    databaseId,
  }: AllSimpleFieldsDependencies) => [
    allSimpleFieldsKeys.all({ teamSlug, databaseId }),
    "getAllSimpleField",
  ],

  getAllSimpleField: ({
    teamSlug,
    databaseId,
  }: GetAllSimpleFieldDependencies) => [
    allSimpleFieldsKeys.allGetAllSimpleField({ teamSlug, databaseId }),
  ],
};

export { simpleFieldsKeys, allSimpleFieldsKeys };
