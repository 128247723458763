import { useState, useCallback } from "react";
import { Loadable, IdleLadable, ValueLoadable } from "./Loadable";

// refetchは使わないので空の関数を渡す
// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyRefetch = () => {};

export const useLoadableState = <Data, Error = unknown>(
  initialData?: Data
): [Loadable<Data, Error>, (data: Data) => void, () => void] => {
  const [loadable, setLoadable] = useState<Loadable<Data, Error>>(
    initialData == null
      ? new IdleLadable(emptyRefetch)
      : new ValueLoadable(initialData)
  );

  const setData = useCallback((data: Data) => {
    setLoadable(new ValueLoadable(data));
  }, []);

  const resetData = useCallback(() => {
    setLoadable(new IdleLadable(emptyRefetch));
  }, []);

  return [loadable, setData, resetData];
};
