import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  DashboardAllSimpleFieldListResponse,
  RecordConditionRuleUnit,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { useMemo, useState } from "react";
import { BsLink, BsPlus, BsX } from "react-icons/bs";
import { TbGridDots } from "react-icons/tb";
import { match, P } from "ts-pattern";
import { Button } from "~/components_next/Button";
import { HintPopover } from "~/components_next/Hint";
import { IconButton } from "~/components_next/IconButton";
import { SimpleSelect } from "~/components_next/Select";
import { Text } from "~/components_next/Text";
import { useDisclosure } from "~/hooks/useDisclosure";
import { createId } from "./sortableUtils";
import { styled } from "~/stitches";
import { Spacer } from "~/components_next/Spacer";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";

const Card = styled(Flex, {
  borderRadius: "$3",
  border: "1px solid $border",

  variants: {
    isJoined: {
      true: {
        backgroundColor: "$bg1",
      },
      false: {
        backgroundColor: "$bg0",
      },
    },
    isDragging: {
      true: {
        zIndex: 1,
      },
      false: {
        zIndex: "unset",
      },
    },
  },
});

type ViewConditionFieldSelectFieldCardProps = {
  field: SimpleField;
  mainTableSlug: string;
  allFields: DashboardAllSimpleFieldListResponse["tables"];
  currentJoin: RecordConditionRuleUnit[];
  isUsedInFilter: boolean;
  isUsedInGroupBy: boolean;
  onAddJoin: (
    join: RecordConditionRuleUnit,
    fieldsToAdd: SimpleField[]
  ) => void;
  onRemove: (select: string) => void;
};

const ViewConditionFieldSelectFieldCard = (
  props: ViewConditionFieldSelectFieldCardProps
) => {
  const {
    field,
    allFields,
    mainTableSlug,
    isUsedInFilter,
    isUsedInGroupBy,
    onAddJoin,
    onRemove,
    currentJoin,
  } = props;

  /**
   * UI
   */
  const joinFloater = useDisclosure();
  const isJoined = useMemo(() => {
    return field.originalTableSlug !== mainTableSlug;
  }, [field, mainTableSlug]);

  /**
   * Local state
   */
  const [targetTableSlug, setTargetTableSlug] = useState<string>("");
  const [targetFieldName, setTargetTableName] = useState<string>("");

  /**
   * Table
   */

  const targetTableOptions = useMemo(() => {
    const joinedTableSlugs = currentJoin.map(({ targetTable }) => targetTable);
    return allFields
      .filter(
        ({ tableSlug }) =>
          tableSlug !== mainTableSlug && !joinedTableSlugs.includes(tableSlug)
      )
      .map(({ tableSlug }) => ({
        label: tableSlug,
        value: tableSlug,
      }));
  }, [allFields, mainTableSlug, currentJoin]);

  const handleTargetTableChange = (value: string) => {
    setTargetTableSlug(value);
  };

  /**
   * Field
   */
  const targetTableFields = useMemo(
    () =>
      allFields.find(({ tableSlug }) => tableSlug === targetTableSlug)
        ?.fields ?? [],
    [allFields, targetTableSlug]
  );

  const targetFieldOptions = useMemo(() => {
    return targetTableFields
      .filter((tf) => {
        return (
          tf.type === field.type ||
          (tf.type === "autoNumber" && field.type === "number") ||
          (tf.type === "number" && field.type === "autoNumber") ||
          (tf.type === "bigNumber" && field.type === "autoBigNumber") ||
          (tf.type === "autoBigNumber" && field.type === "bigNumber") ||
          field.type === "formula"
        );
      })
      .map((field) => ({
        label: field.displayName || field.name,
        value: field.name,
      }));
  }, [targetTableFields, field]);

  const handleTargetFieldChange = (value: string) => {
    setTargetTableName(value);
  };

  const handleLinkClicked = () => {
    if (
      targetTableSlug.length > 0 &&
      targetFieldName.length > 0 &&
      targetTableFields &&
      targetTableFields.map((field) => field.name).includes(targetFieldName)
    ) {
      onAddJoin(
        {
          targetTable: targetTableSlug,
          rules: [
            {
              tableKey: field.name,
              targetTableKey: targetFieldName,
            },
          ],
        },
        targetTableFields.map((field) => ({
          ...field,
          originalTableSlug: targetTableSlug,
        }))
      );

      joinFloater.onClose();
    }
  };

  const handleRemove = () => {
    onRemove(field.name);
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: createId(field),
  });

  const style = {
    transform: CSS.Transform.toString({
      x: 0,
      y: transform?.y || 0,
      scaleX: 1,
      scaleY: 1,
    }),
    transition,
  };

  return (
    <Card
      ref={setNodeRef}
      p="2"
      gap="2"
      width="100%"
      align="center"
      isJoined={isJoined}
      isDragging={isDragging}
      style={style}
      {...attributes}
    >
      <Box {...listeners} style={{ cursor: "grab" }}>
        <TbGridDots />
      </Box>
      <Box
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        <Text mr="3">{field.displayName || field.name}</Text>
        <Text variant="description">{field.originalTableSlug}</Text>
      </Box>
      <Spacer />
      <SimpleDropdownMenu
        trigger={
          <Button size="xs" variant="tertiary">
            <BsPlus />
            Join
          </Button>
        }
      >
        <Flex direction="column" p="2" gap="3" style={{ minWidth: "300px" }}>
          <SimpleSelect
            label="Target Table"
            variant="primary"
            options={targetTableOptions}
            value={targetTableSlug}
            onChange={(value) => {
              handleTargetTableChange(value as string);
            }}
            size="sm"
          />
          <SimpleSelect
            label="Target Fields"
            variant="primary"
            options={targetFieldOptions}
            value={targetFieldName}
            onChange={(value) => {
              handleTargetFieldChange(value as string);
            }}
            size="sm"
          />
          <Button size="sm" variant="tertiary" onClick={handleLinkClicked}>
            <BsLink />
            Link
          </Button>
        </Flex>
      </SimpleDropdownMenu>
      {match([
        mainTableSlug === field.originalTableSlug,
        field.primary,
        isUsedInFilter,
        isUsedInGroupBy,
      ])
        .with([true, true, P._, P._], () => (
          <HintPopover title="Why is this field required?">
            This field is a primary key and serves to uniquely identify a record
            in the table. When updating or deleting a record from the View, it
            is used to identify which record in the original table it is.
          </HintPopover>
        ))
        .with([P._, false, true, P._], () => (
          <HintPopover title="Why is this field required?">
            The field included in the filter condition is required.
          </HintPopover>
        ))
        .with([P._, P._, P._, true], () => (
          <HintPopover title="Why is this field required?">
            This field is required because it is used to group records.
          </HintPopover>
        ))
        .otherwise(() => (
          <IconButton
            size="xs"
            icon={<BsX />}
            tooltip="Remove"
            onClick={handleRemove}
          />
        ))}
    </Card>
  );
};

export { ViewConditionFieldSelectFieldCard };
