import { Command as CommandPrimitive } from "cmdk";
import { ForwardedRef, forwardRef, ReactNode } from "react";

type MultiSelectEmptyFallbackProps = {
  children: ReactNode;
};

const MultiSelectEmptyFallback = forwardRef(
  (props: MultiSelectEmptyFallbackProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children } = props;

    return (
      <CommandPrimitive.Empty ref={ref}>{children}</CommandPrimitive.Empty>
    );
  }
);

MultiSelectEmptyFallback.displayName = "MultiSelectEmptyFallback";

export { MultiSelectEmptyFallback, type MultiSelectEmptyFallbackProps };
