import { SortConditionUnitClientModel } from "~/clientModel/queryConditions/sortConditions";
import { NativeSimpleSelect } from "~/components_next/Select/NativeSelect/NativeSimpleSelect";

type DirectionInputProps = {
  sortConditionUnit: SortConditionUnitClientModel;
  onSortConditionUnitChange?: (
    sortConditionUnit: SortConditionUnitClientModel
  ) => void;
  isReadOnly: boolean;
};

const DirectionInput = (props: DirectionInputProps) => {
  const {
    sortConditionUnit,
    onSortConditionUnitChange = () =>
      console.error("onSortConditionUnitChange is not defined"),
    isReadOnly,
  } = props;

  const handleDirectionChange = (direction: "ascending" | "descending") => {
    onSortConditionUnitChange(sortConditionUnit.updateDirection(direction));
  };

  const sortDirectionOptions = [
    { value: "ascending", label: "Ascending" },
    { value: "descending", label: "Descending" },
  ] as const;

  return (
    <NativeSimpleSelect
      variant="primary"
      value={sortConditionUnit.direction}
      onChange={(value) => handleDirectionChange(value!)}
      size="xs"
      isDisabled={isReadOnly}
      options={sortDirectionOptions}
    />
  );
};

export { DirectionInput };
