// types
import { DashboardUpdateImportIntegrationScheduleRequestBody } from "@usemorph/morph-dashboard-types";
import { useQueryClient } from "react-query";
import useApiImport from "~/api/useApiImport";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { importsKeys } from "../keys";

interface UseUpdateImportIntegrationScheduleMutationParams {
  databaseId: string;
  teamSlug: string;
}

const useUpdateImportIntegrationScheduleMutation = ({
  teamSlug,
  databaseId,
}: UseUpdateImportIntegrationScheduleMutationParams) => {
  const { _updateImportIntegrationSchedule } = useApiImport();

  const client = useQueryClient();

  return {
    mutationFn: ({
      importIntegrationScheduleId,
      body,
    }: {
      importIntegrationScheduleId: string;
      body: DashboardUpdateImportIntegrationScheduleRequestBody;
    }) => {
      return _updateImportIntegrationSchedule({
        teamSlug,
        databaseId,
        importIntegrationScheduleId,
        body,
      });
    },
    onSuccess: () => {
      return invalidateAll(client, importsKeys.all({ teamSlug, databaseId }));
    },
  };
};

export { useUpdateImportIntegrationScheduleMutation };
