import { Flex } from "@radix-ui/themes";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "~/components_next/Button";
import { Image } from "~/components_next/Image";
import { Text } from "~/components_next/Text";

export const Page404 = () => {
  const navigate = useNavigate();
  return (
    <Flex
      direction="column"
      gap="6"
      align="center"
      justify="center"
      style={{ height: "100vh" }}
    >
      <Link to="/">
        <Image height="40px" src="/MorphLogo.png" />
      </Link>
      <Text variant="heading">404 Page Not Found</Text>
      <Button variant="primary" onClick={() => navigate("/")}>
        Back to top
      </Button>
    </Flex>
  );
};
