type ImportsDependencies = { teamSlug: string; databaseId: string };

type ListSimpleImportDataSchemaDependencies = {
  limit?: number;
  skip?: number;
  action?: "add" | "create";
  from?: string;
} & ImportsDependencies;

type ListImportStatusFromOtherServicesDependencies = {
  limit?: number;
  skip?: number;
  tableSlug?: string;
} & ImportsDependencies;

type ListImportIntegrationHistoriesDependencies = {
  limit?: number;
  skip?: number;
  tableSlug?: string;
} & ImportsDependencies;

type ListImportIntegrationScheduleDependencies = {
  limit?: number;
  skip?: number;
  tableSlug?: string;
} & ImportsDependencies;

type FindImportIntegrationScheduleDependencies = {
  importIntegrationScheduleId: string;
} & ImportsDependencies;

const importsKeys = {
  all: ({ teamSlug, databaseId }: ImportsDependencies) =>
    ["imports", teamSlug, databaseId] as const,

  /**
   * list import data schema(simple)
   */
  allListSimpleImportDataSchema: ({
    teamSlug,
    databaseId,
  }: ImportsDependencies) =>
    [
      ...importsKeys.all({ teamSlug, databaseId }),
      "listSimpleImportDataSchema",
    ] as const,
  listSimpleImportDataSchema: ({
    teamSlug,
    databaseId,
    limit,
    skip,
    action,
    from,
  }: ListSimpleImportDataSchemaDependencies) =>
    [
      ...importsKeys.allListSimpleImportDataSchema({ teamSlug, databaseId }),
      {
        limit,
        skip,
        action,
        from,
      },
    ] as const,
  findSimpleImportDataSchema: ({
    teamSlug,
    databaseId,
    importDataSchemaId,
  }: {
    teamSlug: string;
    databaseId: string;
    importDataSchemaId: string | null;
  }) =>
    [
      ...importsKeys.allListSimpleImportDataSchema({ teamSlug, databaseId }),
      importDataSchemaId,
    ] as const,

  /**
   * list import status from other services
   */
  allListImportStatusFromOtherServices: ({
    teamSlug,
    databaseId,
  }: ImportsDependencies) =>
    [
      ...importsKeys.all({ teamSlug, databaseId }),
      "listImportStatusFromOtherServices",
    ] as const,
  listImportStatusFromOtherServices: ({
    teamSlug,
    databaseId,
    limit,
    skip,
    tableSlug,
  }: ListImportStatusFromOtherServicesDependencies) =>
    [
      ...importsKeys.allListImportStatusFromOtherServices({
        teamSlug,
        databaseId,
      }),
      {
        tableSlug,
      },
    ] as const,

  /**
   * list import integration histories
   */
  allListImportIntegrationHistories: ({
    teamSlug,
    databaseId,
  }: ImportsDependencies) =>
    [
      ...importsKeys.all({ teamSlug, databaseId }),
      "listImportIntegrationHistories",
    ] as const,
  listImportIntegrationHistories: ({
    teamSlug,
    databaseId,
    limit,
    skip,
    tableSlug,
  }: ListImportIntegrationHistoriesDependencies) =>
    [
      ...importsKeys.allListImportIntegrationHistories({
        teamSlug,
        databaseId,
      }),
      {
        limit,
        skip,
        tableSlug,
      },
    ] as const,

  allListImportIntegrationSchedule: ({
    teamSlug,
    databaseId,
  }: ImportsDependencies) =>
    [
      ...importsKeys.all({ teamSlug, databaseId }),
      "listImportIntegrationSchedule",
    ] as const,
  listImportIntegrationSchedule: ({
    teamSlug,
    databaseId,
    limit,
    skip,
    tableSlug,
  }: ListImportIntegrationScheduleDependencies) =>
    [
      ...importsKeys.allListImportIntegrationSchedule({
        teamSlug,
        databaseId,
      }),
      {
        limit,
        skip,
        tableSlug,
      },
    ] as const,

  findImportIntegrationSchedule: ({
    teamSlug,
    databaseId,
    importIntegrationScheduleId,
  }: FindImportIntegrationScheduleDependencies) =>
    [
      ...importsKeys.allListImportIntegrationHistories({
        teamSlug,
        databaseId,
      }),
    ] as const,
};

export { importsKeys };
