import { BsDownload } from "react-icons/bs";
import { Button } from "~/components_next/Button";

type DownloadButtonProps = {
  onClick: () => void;
};

const DownloadButton = ({ onClick }: DownloadButtonProps) => {
  return (
    <Button variant="tertiary" size="sm" onClick={onClick}>
      <BsDownload />
      Download
    </Button>
  );
};

export { DownloadButton };
