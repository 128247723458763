import { ImportStatusClientModel } from "./ImportStatusClientModel";

type ImportStatusesDataType = {
  items: ImportStatusClientModel[];
};

export class ImportStatusesClientModel {
  readonly #data: ImportStatusesDataType;
  constructor(data: ImportStatusesDataType) {
    this.#data = data;
  }

  get visibleItems(): ImportStatusClientModel[] {
    return this.#data.items.filter((item) => item.shouldShow);
  }

  get importingItems(): ImportStatusClientModel[] {
    return this.#data.items.filter((item) => item.isImporting);
  }
}
