import { SimpleField } from "@usemorph/morph-dashboard-types";
import { match } from "ts-pattern";
import { RecordsTableColumnSizingState } from "../types";

export const getDefaultColumnSizings = (fields: SimpleField[]) => {
  const sizings = fields.reduce((state, { name, type }) => {
    const width = match(type)
      .with("autoNumber", () => 100)
      .with("number", () => 100)
      .with("decimal", () => 100)
      .with("boolean", () => 100)
      .otherwise(() => 200);

    return { ...state, [name]: width };
  }, {} as RecordsTableColumnSizingState);

  return {
    defaultColumnSizings: sizings,
  };
};
