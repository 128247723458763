import { LiveList, LiveObject } from "@liveblocks/client";
import { NotebookCellObjectWithMeta } from "~/features/FlipNotebook/types/NotebookCellObjectWithMeta.type";
import {
  useNotebookCanRedo,
  useNotebookCanUndo,
  useNotebookMutation,
  useNotebookRedo,
  useNotebookStorage,
  useNotebookUndo,
} from "../notebook.liveblocks.config";
import { NotebookCellLiveObject } from "./notebookStorage";

export const useNotebookLiveObject = () => {
  const cells = useNotebookStorage((root) => root.cells);

  const overrideAllCells = useNotebookMutation(
    ({ storage }, cells: NotebookCellObjectWithMeta[]) => {
      storage.set(
        "cells",
        new LiveList(cells.map((cell) => new LiveObject(cell)))
      );
    },
    []
  );

  const updateNotebookCells = useNotebookMutation(
    (
      { storage },
      { cell, index }: { cell: NotebookCellObjectWithMeta; index: number }
    ) => {
      const cellItem = storage.get("cells");
      const newCellLiveObject: NotebookCellLiveObject = new LiveObject(cell);
      cellItem.set(index, newCellLiveObject);
    },
    []
  );

  const addNoteookCell = useNotebookMutation(
    ({ storage }, cell: NotebookCellObjectWithMeta) => {
      storage.get("cells").push(new LiveObject(cell));
    },
    []
  );

  const removeNotebookCell = useNotebookMutation(
    ({ storage }, index: number) => {
      storage.get("cells").delete(index);
    },
    []
  );

  const undo = useNotebookUndo();
  const redo = useNotebookRedo();
  const canUndo = useNotebookCanUndo();
  const canRedo = useNotebookCanRedo();

  return {
    cells,
    overrideAllCells,
    updateNotebookCells,
    addNoteookCell,
    undo,
    redo,
    canUndo,
    canRedo,
    removeNotebookCell,
  };
};
