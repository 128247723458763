import { useCallback, useState } from "react";
import { useErrorToast } from "~/components_next/Error";
import { PromptSuggestions } from "../../../../common/components/QueryMode/PromptSuggestions";
import { useMutation, useQuery } from "react-query";
import {
  useGenerateSqlQuery,
  useSaveGenerateSqlHistoryMutation,
} from "~/serverStateStore";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { usePromptInput, useSetPromptInput } from "../../../states/promptInput";
import {
  useQueryingSql,
  useSetQueryingSql,
  useSetSqlInput,
} from "../../../states/sql";
import { PromptForm } from "../../../../common/components/QueryMode/PromptForm";
import { useViewId } from "~/utilHooks/useViewId";
import { useSetQueryMode } from "../../../states/queryMode";
import { useView } from "../../../../common/utils/useView";
import { useViewFields } from "~/serverStateStore/views";
import { useViewRecords } from "../../../hooks/useViewRecords";
import { Box } from "~/components_next/Box";

type TableViewPromptFormProps = {
  onClosePromptForm: () => void;
  onOpenSqlForm: () => void;
  onOpenHistoryDrawer: () => void;
};

const TableViewPromptForm = (props: TableViewPromptFormProps) => {
  const { onClosePromptForm, onOpenSqlForm, onOpenHistoryDrawer } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  /**
   * server states
   */

  const { data: viewData } = useView();

  const { data: viewFieldsData } = useViewFields({
    teamSlug,
    databaseId,
    viewId,
    viewData,
  });

  const { status: viewRecordsStatus } = useViewRecords();

  /**
   * global client states
   */
  const promptInput = usePromptInput(viewId);
  const setPromptInput = useSetPromptInput(viewId);
  const queryingSql = useQueryingSql(viewId);
  const setQueryingSql = useSetQueryingSql(viewId);
  const setSqlInput = useSetSqlInput(viewId);
  const setQueryMode = useSetQueryMode(viewId);

  /**
   * handlers
   */
  const { errorToast } = useErrorToast({});

  const { refetch: generateSql } = useQuery({
    ...useGenerateSqlQuery({
      teamSlug,
      databaseId,
      message: promptInput,
      tableSlug: viewData!.tableSlug,
      condition: viewData!.condition,
    }),
    enabled: false,
    retry: false,
  });

  const [isRunning, setIsRunning] = useState(false);

  const { mutateAsync: saveGenerateSqlHistory } = useMutation(
    useSaveGenerateSqlHistoryMutation({
      teamSlug,
      databaseId,
      viewId,
    })
  );

  const handleRunPrompt = useCallback(async () => {
    setIsRunning(true);
    try {
      if (!viewData) {
        throw new Error("Something went wrong. Please try again.");
      }
      const result = await generateSql();
      const generatedSql = result.data?.result.sql;
      if (result.status === "error" || !generatedSql) {
        throw result.error;
      }
      saveGenerateSqlHistory({ message: promptInput, sql: generatedSql });
      setSqlInput(generatedSql);
      setQueryingSql(generatedSql);
      onClosePromptForm();
      setQueryMode("prompt");
    } catch (e) {
      errorToast(e);
    } finally {
      setIsRunning(false);
    }
  }, [
    errorToast,
    generateSql,
    onClosePromptForm,
    promptInput,
    saveGenerateSqlHistory,
    setQueryMode,
    setQueryingSql,
    setSqlInput,
    viewData,
  ]);

  const handleMoveToSQlMode = useCallback(() => {
    setPromptInput("");
    setQueryMode("sql");
    onClosePromptForm();
    onOpenSqlForm();
  }, [setPromptInput, setQueryMode, onClosePromptForm, onOpenSqlForm]);

  const handleOpenHistoryDrawer = useCallback(() => {
    onClosePromptForm();
    onOpenHistoryDrawer();
  }, [onClosePromptForm, onOpenHistoryDrawer]);

  return (
    <Box position="relative">
      <PromptForm
        promptInput={promptInput}
        onUpdatePromptInput={setPromptInput}
        isPromptRunning={isRunning || viewRecordsStatus === "loading"}
        onRunPrompt={handleRunPrompt}
        onMoveToSQLMode={handleMoveToSQlMode}
        onOpenHistoryDrawer={handleOpenHistoryDrawer}
        tablesForTypeahead={[]}
        fieldsForTypeahead={viewFieldsData?.fields || []}
        variablesForTypehead={[]}
        showMoveToSQLModeButton={!!queryingSql}
      />
      {viewData && (
        <Box
          position="fixed"
          style={{
            bottom: -4,
            left: 0,
            right: 0,
            transform: "auto",
            translate: "0 100%",
            // zIndex: 1000,
          }}
        >
          <PromptSuggestions
            onClickMenuItem={setPromptInput}
            isOpen={!promptInput}
            type="view"
            viewId={viewData.viewId}
            condition={viewData.condition}
          />
        </Box>
      )}
    </Box>
  );
};

export { TableViewPromptForm };
