/**
 * records
 */

type RecordHistoriesDependencies = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

type ListRecordHistoryDependencies = {
  data: Record<string, unknown>;
  limit?: number;
  skip?: number;
} & RecordHistoriesDependencies;

const recordHistoriesKeys = {
  /**
   * all
   */
  all: ({ teamSlug, databaseId, tableSlug }: RecordHistoriesDependencies) =>
    ["recordHistories", teamSlug, databaseId, tableSlug] as const,

  /**
   * list record history
   */
  allListRecordHistory: ({
    teamSlug,
    databaseId,
    tableSlug,
  }: RecordHistoriesDependencies) =>
    [
      ...recordHistoriesKeys.all({ teamSlug, databaseId, tableSlug }),
      "listRecordHistory",
    ] as const,

  listRecordHistory: ({
    teamSlug,
    databaseId,
    tableSlug,
    data,
    limit,
    skip,
  }: ListRecordHistoryDependencies) =>
    [
      recordHistoriesKeys.allListRecordHistory({
        teamSlug,
        databaseId,
        tableSlug,
      }),
      {
        data,
        limit,
        skip,
      },
    ] as const,
};

export { recordHistoriesKeys };
