import { DashboardTemplateAuthObject } from "@usemorph/morph-dashboard-types";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Button } from "~/components_next/Button";
import { Dialog } from "~/components_next/Dialog";
import { useErrorToast } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { Input } from "~/components_next/Input";
import { useToast } from "~/components_next/Toast";
import { useUpdateTemplateAuthSettingMutation } from "~/serverStateStore/templateAuth/mutations/useUpdateTemplateAuthSettingMutation";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

interface UpdateTemplateAuthDialogProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  selectedTemplateAuth?: DashboardTemplateAuthObject;
  setSelectedTemplateAuth: (templateAuth?: DashboardTemplateAuthObject) => void;
  templateId: string;
}

export const UpdateTemplateAuthDialog = (
  props: UpdateTemplateAuthDialogProps
) => {
  const {
    isOpen,
    onOpenChange,
    selectedTemplateAuth,
    setSelectedTemplateAuth,
    templateId,
  } = props;
  const teamSlug = useTeamSlug();
  const toast = useToast();
  const { errorToast } = useErrorToast({});

  // states
  const [title, setTitle] = useState("");
  const [label, setLabel] = useState("");
  useEffect(() => {
    if (selectedTemplateAuth) {
      setTitle(selectedTemplateAuth.title)
      setLabel(selectedTemplateAuth.label)
    }
  }, [selectedTemplateAuth])

  // functions
  const { mutateAsync: updateTemplateAuth, isLoading: isLoadingTemplateAuth } =
    useMutation({
      ...useUpdateTemplateAuthSettingMutation({ templateId, teamSlug }),
    });

  const handleOnUpdate = async () => {
    if (selectedTemplateAuth) {
      try {
        const response = await updateTemplateAuth({
          templateAuthId: selectedTemplateAuth.templateAuthId,
          title,
          label,
        });
        setSelectedTemplateAuth(response);
        toast({
          title: "Successfully edit.",
          // description: "Your account was successfully updated.",
          type: "success",
          duration: 5000,
        });
        onOpenChange(false);
      } catch (error) {
        errorToast(error);
      }
    }
  };

  return (
    <>
      <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
        <Dialog.Content>
          <Dialog.Title>Edit Account</Dialog.Title>
          <Dialog.Body>
            <Flex direction="column" gap="4">
              <Input
                variant="primary"
                label="Title"
                value={title}
                onChange={(el) => setTitle(el.target.value)}
              />
              <Input
                variant="primary"
                label="Label"
                value={label}
                onChange={(el) => setLabel(el.target.value)}
              />
            </Flex>
          </Dialog.Body>
          <Dialog.Footer>
            <Button
              onClick={handleOnUpdate}
              variant="primary"
              size="sm"
              isLoading={isLoadingTemplateAuth}
            >
              Edit
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};
