// import {
//   useClipboard,
// } from "@chakra-ui/react";

// TODO ここあとで
export const ApiKeyForm = ({ apiKey }: { apiKey: string }) => {
  // const { onCopy, hasCopied } = useClipboard(apiKey);

  return (
    <>
      {/* <InputStack>
        <Text fontWeight="medium">API Key</Text>
        <InputGroup size="sm">
          <Input isReadOnly={true} value={apiKey} />
          <InputRightElement
            cursor={"pointer"}
            onClick={() => {
              onCopy();
            }}
          >
            {hasCopied ? (
              <CheckIcon color="green.500" />
            ) : (
              <Icon as={BsClipboard} />
            )}
          </InputRightElement>
        </InputGroup>
      </InputStack> */}
    </>
  );
};
