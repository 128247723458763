import { Dispatch, SetStateAction } from "react";
import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil";

// 一応notebookIdで分けたいのでatomFamilyを使う
const notebookCellSelectionLockFamily = atomFamily<
  boolean,
  { notebookId: string }
>({
  key: "notebook-cell-selection-lock",
  default: false,
  effects: [],
});

const useNotebookCellSelectionLock = (notebookId: string): boolean => {
  const notebookCellSelectionLock = useRecoilValue(
    notebookCellSelectionLockFamily({ notebookId })
  );
  return notebookCellSelectionLock;
};

const useSetNotebookCellSelectionLock = (
  notebookId: string
): Dispatch<SetStateAction<boolean>> => {
  const setNotebookCellSelection = useSetRecoilState(
    notebookCellSelectionLockFamily({ notebookId })
  );
  return setNotebookCellSelection;
};

export { useNotebookCellSelectionLock, useSetNotebookCellSelectionLock };
