import {
  ValidationResult,
  zValidate,
} from "~/clientModel/utils/validationResultType";
import { SmartFunctionFieldTypeClientModel } from "../../../../SmartFunctionFieldTypeClientModel";
import {
  SmartFunctionInputClientModelBase,
  SmartFunctionInputClientModelBaseData,
} from "./SmartFunctionInputClientModelBase";
import { z } from "zod";

type SmartFunctionStringInputClientModelData =
  SmartFunctionInputClientModelBaseData;

export class SmartFunctionStringInputClientModel extends SmartFunctionInputClientModelBase<string> {
  public readonly type = "string";

  public constructor(data: SmartFunctionStringInputClientModelData) {
    super(data);
  }

  public getValue(
    smartFunctionFieldType: SmartFunctionFieldTypeClientModel
  ): string {
    const rawValue = smartFunctionFieldType.settingData[this.key];
    return typeof rawValue === "string" ? rawValue : "";
  }

  public validateValue(
    smartFunctionFieldType: SmartFunctionFieldTypeClientModel
  ): ValidationResult<string> {
    const rawValue = smartFunctionFieldType.settingData[this.key];

    return zValidate(z.string().nonempty(), rawValue);
  }
}
