import { Box, Flex } from "@radix-ui/themes";
import { Button } from "~/components_next/Button";
import { Text } from "~/components_next/Text";
import { styled } from "~/stitches";

type RichTextMigrationWarningCardProps = {
  onEditClick: () => void;
};

const Card = styled(Flex, {
  border: "1px solid $border",
  borderRadius: "$3",
});

const RichTextMigrationWarningCard = ({
  onEditClick,
}: RichTextMigrationWarningCardProps) => {
  return (
    <Card justify="center" align="center" gap="4" direction="column" p="4">
      <Box>
        <Text variant="default">
          To migrate the original HTML to rich text format, please note the
          following:
        </Text>

        <ul style={{ paddingLeft: "24px" }}>
          <li>
            <Text variant="default">
              The structure of the HTML may change and some tags may be ignored.
            </Text>
          </li>
          <li>
            <Text variant="default">Some attributes may be ignored.</Text>
          </li>
        </ul>
      </Box>
      <Button variant="primary" size="sm" onClick={onEditClick}>
        Edit as Rich Text
      </Button>
    </Card>
  );
};

export { RichTextMigrationWarningCard };
