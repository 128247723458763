import { FieldClientModel } from "~/clientModel/fields/field";
import { FindUserClientModel } from "~/clientModel/user";
import {
  ReadonlyRecordEntryClientModelBase,
  ReadonlyRecordEntryClientModelBaseConstructorParams,
} from "../RecordEntryClientModelBase";
import { zValidate } from "../validatorsUtil";
import { z } from "zod";

type LastEditedByRecordEntryClientModelData =
  ReadonlyRecordEntryClientModelBaseConstructorParams;

export class LastEditedByRecordEntryClientModel extends ReadonlyRecordEntryClientModelBase<FindUserClientModel | null> {
  constructor(data: LastEditedByRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "lastEditedBy" {
    return "lastEditedBy";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([z.instanceof(FindUserClientModel), z.null()]),
      this.data.rawValue
    );
  }

  public getValidatedDisplayValue = this.getValidatedValue;
}
