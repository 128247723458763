import { match, P } from "ts-pattern";
import { FieldClientModel } from "~/clientModel/fields/field";

export type ColumnSizingDict = Record<string, number>;

type TableColumnSizingClientModelData = {
  columnSizingDict: ColumnSizingDict;
};

export class TableColumnSizingClientModel {
  constructor(readonly data: TableColumnSizingClientModelData) {}

  public static minColumnWidth = 50 as const;

  public static getDefaultColumnWidth(field: FieldClientModel): number {
    const width = match(field.type)
      .with(
        {
          type: P.union(
            "number",
            "autoNumber",
            "bigNumber",
            "autoBigNumber",
            "decimal",
            "boolean"
          ),
        },
        () => 100
      )
      .with(
        {
          type: P.union(
            "shortText",
            "longText",
            "email",
            "phoneNumber",
            "url",
            "date",
            "datetime",
            "time",
            "singleSelect",
            "multiSelect",
            "attachment",
            "image",
            "html",
            "json",
            "array",
            "createdAt",
            "createdBy",
            "formula",
            "lastEditedAt",
            "lastEditedBy",
            "syncValue",
            "generateText",
            "calculation",
            "aggregateValue",
            "smartFunction"
          ),
        },
        () => 200
      )
      .exhaustive();

    return width;
  }

  public getColumnWidth(fieldName: string): number {
    const width = this.data.columnSizingDict[fieldName];
    if (!width) {
      return 200;
    }
    return width;
  }

  public updateColumnWidth(
    fieldName: string,
    width: number
  ): TableColumnSizingClientModel {
    return new TableColumnSizingClientModel({
      ...this.data,
      columnSizingDict: {
        ...this.data.columnSizingDict,
        [fieldName]: Math.max(
          width,
          TableColumnSizingClientModel.minColumnWidth
        ),
      },
    });
  }
}
