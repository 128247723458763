import {
  DashboardPlanResponse,
  DashboardPlanItemObject,
} from "@usemorph/morph-dashboard-types";


export class PlanClientModel {
  readonly #data: DashboardPlanResponse

  constructor(data: DashboardPlanResponse) {
    this.#data = data;
  }

  /**
   * Getters
   */
  get planId(): string {
    return this.#data.planId;
  }

  get name(): string {
    return this.#data.name;
  }

  get description(): string {
    return this.#data.description;
  }

  get currency(): "yen" | "dollar" {
    return this.#data.currency;
  }

  get items(): DashboardPlanItemObject[] {
    return this.#data.items;
  }

}
