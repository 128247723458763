import useApiResource from "~/api/useApiResource";

export const useGetResourceMutation = ({ teamSlug }: { teamSlug: string }) => {
  const { _getResource } = useApiResource();

  return {
    mutationFn: ({ path }: { path: string }) => {
      return _getResource({
        teamSlug,
        path,
      });
    },
  };
};
