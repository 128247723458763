import { CanvasVariableTypeType } from "./CanvasVariableClientModel";

const typeOptions: Array<{
  label: string;
  value: CanvasVariableTypeType;
}> = [
  {
    label: "String",
    value: "string",
  },
  {
    label: "Number",
    value: "number",
  },
  {
    label: "Boolean",
    value: "boolean",
  },
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Single Select (String)",
    value: "singleSelectString",
  },
  {
    label: "Single Select (Number)",
    value: "singleSelectNumber",
  },
  {
    label: "Single Select (Field Options)",
    value: "singleSelectSourceFieldOptions",
  },
  {
    label: "Single Select (View Result)",
    value: "singleSelectViewRecords",
  },
  // {
  //   label: "Multi Select (String)",
  //   value: "multiSelectString",
  // },
  // {
  //   label: "Multi Select (Number)",
  //   value: "multiSelectNumber",
  // },
  // {
  //   label: "Multi Select (Field Options)",
  //   value: "multiSelectSourceFieldOptions",
  // },
  // {
  //   label: "Multi Select (View Result)",
  //   value: "multiSelectViewRecords",
  // },
];

export class CanvasVariableClientModelService {
  static variableTypeOptions = typeOptions;

  static getStringValue(value: unknown) {
    if (typeof value === "string") {
      return value;
    }
    return undefined;
  }

  static getNumberValue(value: unknown) {
    if (typeof value === "number") {
      return value;
    }
    return undefined;
  }

  static getBooleanValue(value: unknown) {
    if (typeof value === "boolean") {
      return value;
    }
    return undefined;
  }

  static getDateValue(value: unknown) {
    if (typeof value === "string") {
      return value;
    }
    return undefined;
  }
}
