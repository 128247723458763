import { BsX } from "react-icons/bs";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { SortConditionUnitClientModel } from "~/clientModel/queryConditions/sortConditions";
import { Grid } from "~/components_next/Grid";
import { IconButton } from "~/components_next/IconButton";
import { DirectionInput } from "./DirectionInput";
import { KeyInput } from "./KeyInput";

type SortConditionUnitFormProps = {
  fields: FieldsClientModel;
  sortConditionUnit: SortConditionUnitClientModel;
  onSortConditionUnitChanges?: (
    sortConditionUnit: SortConditionUnitClientModel
  ) => void;
  onSortConditionUnitRemove?: () => void;
  isReadOnly: boolean;
};

const SortConditionUnitForm = (props: SortConditionUnitFormProps) => {
  const {
    fields,
    sortConditionUnit,
    onSortConditionUnitChanges = () =>
      console.error("onSortConditionUnitChanges is not defined"),
    onSortConditionUnitRemove = () =>
      console.error("onSortConditionUnitRemove is not defined"),
    isReadOnly,
  } = props;

  return (
    <Grid
      rows="1"
      columns="3"
      gap="1"
      style={{ gridTemplateColumns: "1fr 1fr 1fr 26px" }}
    >
      <KeyInput
        fields={fields}
        sortConditionUnit={sortConditionUnit}
        onSortConditionUnitChange={onSortConditionUnitChanges}
        isReadOnly={isReadOnly}
      />
      <DirectionInput
        sortConditionUnit={sortConditionUnit}
        onSortConditionUnitChange={onSortConditionUnitChanges}
        isReadOnly={isReadOnly}
      />
      <div />

      {/* 削除 */}
      {!isReadOnly && (
        <IconButton
          onClick={onSortConditionUnitRemove}
          icon={<BsX />}
          tooltip="Remove"
        />
      )}
    </Grid>
  );
};

export { SortConditionUnitForm };
