import { useMemo } from "react";
import {
  useGetUserColor,
  useOthersDecoded,
} from "~/features/RealtimeCollaboration";
import { NotebookCoworkerState } from "../types/NotebookCoworkerState.type";

const useNotebookCollaboration = () => {
  /**
   * Others
   */
  const { samePageOthers } = useOthersDecoded();
  const getUserColor = useGetUserColor();

  const notebookCoworkerStates = useMemo((): NotebookCoworkerState[] => {
    const result = samePageOthers
      .map((other) => {
        if (!other.presence.notebookState) return undefined;

        const userColor = getUserColor(other.presence.user.userId) || "amber";

        const coworkerState: NotebookCoworkerState = {
          ...other.presence.notebookState,
          user: other.presence.user,
          colorScheme: userColor,
        };

        return coworkerState;
      })
      .filter((r) => r !== undefined) as NotebookCoworkerState[];

    return result;
  }, [samePageOthers]);

  return {
    notebookCoworkerStates,
  };
};

export { useNotebookCollaboration };
