import { css } from "@stitches/react";
import { useCallback } from "react";
import { match } from "ts-pattern";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { SmartFunctionFieldTypeClientModel } from "~/clientModel/fields/field/fieldType";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";
import { Flex } from "~/components_next/Flex";
import { Input } from "~/components_next/Input";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { SimpleSelect } from "~/components_next/Select";
import {
  BasicTypeaheadPrompt,
  basicFieldFilterFn,
  FieldSuggestionItem,
} from "~/presenters/prompt";

const promptRootStyle = css({
  overflowY: "auto !important",
  height: "200px !important",
});

const promptTheme = {
  root: `${promptRootStyle()}`,
};

type SmartFunctionInputFormProps = {
  smartFunctionType: SmartFunctionFieldTypeClientModel;
  onSmartFunctionTypeChange: (type: SmartFunctionFieldTypeClientModel) => void;
  smartFunctions: SmartFunctionsClientModel;
  fields: FieldsClientModel;
  isReadOnly?: boolean;
};

export const SmartFunctionInputForm = (props: SmartFunctionInputFormProps) => {
  const {
    smartFunctionType,
    onSmartFunctionTypeChange,
    smartFunctions,
    fields,
    isReadOnly,
  } = props;

  const convertFieldToDotNotatedFieldName = useCallback(
    (field: FieldClientModel) => `\${${field.name}}`,
    []
  );

  const renderHTMLTextContent = useCallback(
    (field: FieldClientModel) => field.label,
    []
  );

  const renderSuggestedItem = useCallback(
    (field: FieldClientModel) => <FieldSuggestionItem field={field} />,
    []
  );

  if (!smartFunctionType.smartFunctionId) {
    return null;
  }

  const currentSmartFunction = smartFunctions.getSmartFunction(
    smartFunctionType.smartFunctionId
  );

  return (
    <Flex direction="column" gap="4">
      {currentSmartFunction.inputForm.allInputs.map((input) =>
        match(input)
          .with({ type: "string" }, (input) => (
            <Input
              key={input.key}
              variant="primary"
              size="sm"
              placeholder={input.description}
              readOnly={isReadOnly}
              label={input.label}
              value={input.getValue(smartFunctionType) ?? ""}
              errorMessage={
                input.validateValue(smartFunctionType).errorMessages
              }
              onChange={(e) =>
                onSmartFunctionTypeChange(
                  smartFunctionType.updateSettingData({
                    key: input.key,
                    value: e.target.value,
                  })
                )
              }
            />
          ))
          .with({ type: "selectField" }, (input) => (
            <SimpleSelect
              key={input.key}
              variant="primary"
              size="sm"
              placeholder={input.description}
              isReadOnly={isReadOnly}
              label={input.label}
              value={input.getValue(smartFunctionType) ?? ""}
              options={fields.allFields.map((field) => ({
                label: field.label,
                value: field.name,
              }))}
              errorMessages={
                input.validateValue(smartFunctionType).errorMessages
              }
              onChange={(fieldName) =>
                onSmartFunctionTypeChange(
                  smartFunctionType.updateSettingData({
                    key: input.key,
                    value: fieldName,
                  })
                )
              }
            />
          ))
          .with({ type: "promptString" }, (input) => (
            <InputStack
              label={input.label}
              description={input.description}
              errorMessage={
                input.validateValue(smartFunctionType).errorMessages
              }
            >
              <BasicTypeaheadPrompt<FieldClientModel>
                key={input.key}
                isReadOnly={isReadOnly}
                textContent={input.getValue(smartFunctionType)}
                onUpdate={(value) =>
                  onSmartFunctionTypeChange(
                    smartFunctionType.updateSettingData({
                      key: input.key,
                      value,
                    })
                  )
                }
                theme={promptTheme}
                optionItems={fields.allFields}
                filterFn={basicFieldFilterFn}
                convertOptionItemToText={convertFieldToDotNotatedFieldName}
                renderHTMLTextContent={renderHTMLTextContent}
                renderSuggestedItem={renderSuggestedItem}
                focusOnLoad
              />
            </InputStack>
          ))
          .exhaustive()
      )}
    </Flex>
  );
};
