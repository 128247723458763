import { Link } from '@radix-ui/themes';
import { Executable } from "~/clientModel/executable";
import { Loadable } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { PaymentClientModel } from "~/clientModel/payment/PaymentClientModel";
import { FindTemplateDetailClientModel, ImportIntegrationStatusClientModel } from "~/clientModel/template";
import { ListTemplateClientModel } from "~/clientModel/template/ListTemplateClientModel";
import { Box } from "~/components_next/Box";
import { Dialog } from "~/components_next/Dialog";
import { Flex } from "~/components_next/Flex";
import { Text } from "~/components_next/Text";
import { IntegrationForm } from "~/features/ImportOtherService/components/Form/IntegrationForm";
import { IntegrationSelect } from "~/features/ImportOtherService/components/IntegrationSelect";
import { TemplateSelect } from "~/features/ImportOtherService/components/TemplateSelect";
import { ModalStateType, OtherServiceType } from "~/features/ImportOtherService/useImportOtherServiceModal";
import { UseImportDataFromExternalServicesMutationProps } from "~/serverStateStore";


interface IntegrationDialogPresenterProps {
  paymentLoadable: Loadable<PaymentClientModel>
  listTemplateClientModel: ListTemplateClientModel;
  findTemplateDetailClientModel: FindTemplateDetailClientModel;
  importDataFromExternalServicesExecutable: Executable<UseImportDataFromExternalServicesMutationProps, ImportIntegrationStatusClientModel>
  modalState: ModalStateType;
  selectedIntegrationId: string | undefined;
  selectedTemplate: OtherServiceType | undefined;
  onOpenPreferenceModal: (initialSelectedTab: "plans" | "myAccount" | "teamSettings" | "members" | "billing") => void
  handleSelectIntegration: (integrationId: string) => void;
  setOtherService: (otherService: string) => void;
  onClose: () => void;
}

export const IntegrationDialogPresenter = (props: IntegrationDialogPresenterProps) => {

  const {
    paymentLoadable,
    listTemplateClientModel,
    findTemplateDetailClientModel,
    importDataFromExternalServicesExecutable,
    modalState,
    selectedIntegrationId,
    selectedTemplate,
    onOpenPreferenceModal,
    handleSelectIntegration,
    setOtherService,
    onClose,
  } = props

  return (
    <>
      <Dialog.Root
        open={modalState.isOpen}
        onOpenChange={(el) => {
          if (!el) {
            onClose();
          }
        }}
        size="lg"
      >
        <Dialog.Content>
          <Dialog.Title>Import data</Dialog.Title>
          <Dialog.Body>
            <WithFallback loadables={[paymentLoadable] as const}>
              {([payment]) => (
                <Flex grow="1" direction="column" height="100%" mt="5">

                  {/* template select */}
                  <Box mb="4">
                    {listTemplateClientModel && listTemplateClientModel.templates && (
                      <TemplateSelect
                        templates={listTemplateClientModel.templates}
                        setTemplate={(el) => {
                          if (el) {
                            setOtherService(JSON.stringify(el));
                          }
                        }}
                        selected={selectedTemplate}
                      />
                    )}
                  </Box>
                  {/* {JSON.stringify(selectedTemplate)}
                  {JSON.stringify(payment.plan)} */}

                  {/* integration select */}
                  <Box mb="4">
                    {
                      findTemplateDetailClientModel && findTemplateDetailClientModel.integrations && (
                        selectedTemplate?.isPremium && payment.plan?.name !== "Team" && payment.currentTrial === null
                          ? <>
                            <Text mr="2">It is Premium integration, so it can only be used with Team plan. Please change</Text>
                            <Link
                              weight="medium"
                              onClick={() => onOpenPreferenceModal("plans")}
                            >
                              plan
                            </Link>
                            <Text>.</Text>
                          </>
                          : <IntegrationSelect
                            selectedIntegrationId={selectedIntegrationId}
                            templateDetail={findTemplateDetailClientModel}
                            onSelect={handleSelectIntegration}
                          />
                      )
                    }
                  </Box>

                  {/* integration form */}
                  <Box grow="1">
                    {findTemplateDetailClientModel &&
                      findTemplateDetailClientModel.integrations &&
                      findTemplateDetailClientModel.integrations.map((integration) => {
                        return (
                          selectedIntegrationId === integration.integrationId && (
                            <IntegrationForm
                              key={integration.integrationId}
                              authConfig={findTemplateDetailClientModel.authConfig}
                              description={integration.description}
                              triggerDescription={integration.triggerDescription}
                              integrationId={integration.integrationId}
                              integrationName={integration.integrationName}
                              templateId={findTemplateDetailClientModel.templateId}
                              templateName={findTemplateDetailClientModel.templateName}
                              logoUrl={findTemplateDetailClientModel.logoUrl}
                              inputForm={integration.inputForm}
                              authInputForm={findTemplateDetailClientModel.authInputForm}
                              outputTables={integration.outputTables}
                              loginButtonImage={
                                findTemplateDetailClientModel.authConfig.loginButtonImage
                              }
                              syncOptions={integration.syncOptions}
                              onSubmitted={onClose}
                              importDataFromExternalServicesExecutable={importDataFromExternalServicesExecutable}
                            />
                          )
                        );
                      })}
                  </Box>

                </Flex>
              )}
            </WithFallback>
          </Dialog.Body>
        </Dialog.Content>
      </Dialog.Root>
    </>
  )
}
