import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { Text } from "~/components_next/Text";
import { DropdownMenu } from "~/components_next/DropdownMenu";
import { Flex } from "~/components_next/Flex";
import {
  useGetPaymentQuery,
  useListComputedFieldOptionsForSmartFunctionQuery,
} from "~/serverStateStore";
import { useQuery } from "react-query";
import { Progress } from "~/components_next/Progress";
import {
  PaymentClientModel,
  PaymentClientModelFactory,
} from "~/clientModel/payment/PaymentClientModel";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { useComposeLoadable } from "~/clientModel/loadable";
import { MdDataUsage } from "react-icons/md";
import { SmartFunctionsClientModelFactory } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";
import { LuSparkles } from "react-icons/lu";
import { ConsumedUsageTable } from "./ConsumedUsageTable";
import {
  AccordionRoot,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/components_next/Accordion";

export const CurrentUsageSubItem = () => {
  const teamSlug = useTeamSlug();

  const smartFunctionsLoadable = useComposeLoadable(
    useQuery({
      ...useListComputedFieldOptionsForSmartFunctionQuery({ teamSlug }),
      select: (response) =>
        SmartFunctionsClientModelFactory.createFromSmartFieldFunctionListResponse(
          response
        ),
    })
  );

  const paymentLoadable = useComposeLoadable(
    useQuery({
      ...useGetPaymentQuery({ teamSlug }),
      select: (response) =>
        PaymentClientModelFactory.fromPaymentResponse(response),
    })
  );

  const getCreditsUsageMessageTitle = (payment: PaymentClientModel) => {
    return `${payment.creditsUsage}/${payment.creditsLimit} credits used (${payment.creditsUsagePercent}%)`;
  };

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger>
        <Flex direction="row" gap="2" align="center">
          <MdDataUsage color="gray" />
          Credits Usage
        </Flex>
      </DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent>
        <WithFallback loadables={[paymentLoadable] as const}>
          {([payment]) => (
            <Flex
              gap="4"
              direction="column"
              px="4"
              py="2"
              style={{ width: "600px" }}
            >
              <Flex align="center" gap="3">
                <LuSparkles color="gray" />
                <Text variant="subheading" fontWeight="bold">
                  {getCreditsUsageMessageTitle(payment)}
                </Text>
              </Flex>
              <Progress progress={payment.creditsUsagePercent} size="sm" />
              <AccordionRoot type="single" collapsible>
                <AccordionItem value="import">
                  <AccordionTrigger size="sm">
                    Credit Consumption Details
                  </AccordionTrigger>
                  <AccordionContent>
                    <ConsumedUsageTable
                      smartFunctionsLoadable={smartFunctionsLoadable}
                    />
                  </AccordionContent>
                </AccordionItem>
              </AccordionRoot>
            </Flex>
          )}
        </WithFallback>
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>
  );
};
