import { useCallback, useEffect } from "react";
import {
  QueryMode,
  useQueryMode,
  useSetQueryMode,
} from "../../states/queryMode";
import {
  useQueryingSql,
  useSetQueryingSql,
  useSetSqlInput,
  useSqlInput,
} from "../../states/sql";
import { TableViewPromptForm } from "./Prompt/TableViewPromptForm";
import { SQLForm } from "../../../common/components/QueryMode/SQLForm";
import { TableViewFilterAndSortForm } from "./FiltersAndSorts/TableViewFilterAndSortForm";
import { FieldSettingButton } from "./FieldSetting/FieldSettingButton";
import { useViewId } from "~/utilHooks/useViewId";
import { useViewFields } from "~/serverStateStore/views";
import { useView } from "~/features/SourceAndViews/common/utils/useView";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { PromptHistoryDrawer } from "~/features/SqlHistory";
import { DashboardGenerateSqlHistoryObject } from "@usemorph/morph-dashboard-types";
import { useSetPromptInput } from "../../states/promptInput";
import { useDisclosure } from "~/hooks/useDisclosure";
import { Flex } from "@radix-ui/themes";
import { Spacer } from "~/components_next/Spacer";
import { styled } from "~/stitches";
import { FiltersAndSortsButton } from "~/features/SourceAndViews/common/components/QueryMode/Buttons/FiltersAndSortsButton";
import { PromptButton } from "~/features/SourceAndViews/common/components/QueryMode/Buttons/PromptButton";
import { SQLButton } from "~/features/SourceAndViews/common/components/QueryMode/Buttons/SQLButton";
import { TableViewClearFiltersAndSortsButton } from "./FiltersAndSorts/TableViewClearFiltersAndSortsButton";
import { TableViewClearPromptButton } from "./Prompt/TableViewClearPromptButton";
import { TableViewClearSQLButton } from "./SQL/TableViewSQLButton";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { Box } from "~/components_next/Box";

const ToolbarContentWrapper = styled(Box, {
  maxHeight: "70vh",
  overflow: "auto",
  width: "600px",
});

type ButtonType = "prompt" | "filtersAndSorts" | "sql" | null;

const QueryModeToolbar = () => {
  /**
   * global states from recoil
   */
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();
  const queryMode = useQueryMode(viewId);
  const setQueryMode = useSetQueryMode(viewId);
  const setSqlInput = useSetSqlInput(viewId);
  const sqlInput = useSqlInput(viewId);
  const setQueryingSql = useSetQueryingSql(viewId);
  const queryingSql = useQueryingSql(viewId);
  const setPromptInput = useSetPromptInput(viewId);

  const { data: viewData } = useView();
  const { data: viewFieldsData } = useViewFields({
    teamSlug,
    databaseId,
    viewId,
    viewData,
  });

  /**
   * local states
   */
  const filterAndSortsDisclosure = useDisclosure();
  const promptDisclosure = useDisclosure();
  const sqlDisclosure = useDisclosure();
  const historyDrawerDisclosure = useDisclosure();

  /**
   * handlers
   */
  const handleRunSql = useCallback(() => {
    setQueryingSql(sqlInput);
    sqlDisclosure.onClose();
    setQueryMode("sql");
  }, [setQueryingSql, sqlInput, sqlDisclosure, setQueryMode]);

  const handleHistoryClick = useCallback(
    async ({ sql, message }: DashboardGenerateSqlHistoryObject) => {
      historyDrawerDisclosure.onClose();
      setSqlInput(sql);
      setQueryingSql(sql);
      setPromptInput(message);
      setQueryMode("prompt");
    },
    [
      setSqlInput,
      setQueryingSql,
      setPromptInput,
      historyDrawerDisclosure,
      setQueryMode,
    ]
  );

  const handleOpenChangeHistoryDrawer = useCallback(
    (isOpen: boolean) => {
      historyDrawerDisclosure.setIsOpen(isOpen);
      promptDisclosure.setIsOpen(!isOpen);
    },
    [historyDrawerDisclosure, promptDisclosure]
  );

  /**
   * Effects
   */
  // set querying sql to sql input on mounted if exists
  useEffect(() => {
    if (queryingSql) {
      setSqlInput(queryingSql);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * UI
   */

  const getButtonStatus = (buttonType: QueryMode): "default" | "active" => {
    if (queryMode === buttonType) {
      return "active";
    } else {
      return "default";
    }
  };

  const showButton = (buttonType: QueryMode) => {
    return queryMode === buttonType || !queryMode;
  };

  return (
    <>
      <Flex direction="row" gap="3" align="center">
        {/* Filters And Sorts */}
        {showButton("filtersAndSorts") && (
          <SimpleDropdownMenu
            trigger={
              <FiltersAndSortsButton
                status={getButtonStatus("filtersAndSorts")}
              />
            }
            size="sm"
          >
            <Box css={{ minWidth: 500, overflow: "auto" }}>
              <TableViewFilterAndSortForm
                onClose={filterAndSortsDisclosure.onClose}
              />
            </Box>
          </SimpleDropdownMenu>
        )}
        {queryMode === "filtersAndSorts" && (
          <TableViewClearFiltersAndSortsButton />
        )}

        {/* Prompt */}
        {showButton("prompt") && (
          <SimpleDropdownMenu
            trigger={<PromptButton status={getButtonStatus("prompt")} />}
            size="sm"
          >
            <Box css={{ minWidth: 700, overflow: "auto", height: "100%" }}>
              <TableViewPromptForm
                onClosePromptForm={promptDisclosure.onClose}
                onOpenHistoryDrawer={historyDrawerDisclosure.onOpen}
                onOpenSqlForm={sqlDisclosure.onOpen}
              />
            </Box>
          </SimpleDropdownMenu>
        )}
        {queryMode === "prompt" && <TableViewClearPromptButton />}

        {/* SQL */}
        {showButton("sql") && (
          <SimpleDropdownMenu
            trigger={<SQLButton status={getButtonStatus("sql")} />}
            size="sm"
          >
            <Box css={{ minWidth: 500, overflow: "auto", height: "100%" }}>
              <SQLForm
                onRunSql={handleRunSql}
                sqlInput={sqlInput}
                setSqlInput={setSqlInput}
                fields={viewFieldsData?.fields ?? []}
              />
            </Box>
          </SimpleDropdownMenu>
        )}
        {queryMode === "sql" && <TableViewClearSQLButton />}

        <Spacer />

        {/* Fields Setting */}
        <FieldSettingButton />
      </Flex>

      <PromptHistoryDrawer
        isOpen={historyDrawerDisclosure.isOpen}
        onOpenChange={handleOpenChangeHistoryDrawer}
        onHistoryClick={handleHistoryClick}
        type="view"
        viewId={viewId}
      />
    </>
  );
};

export { QueryModeToolbar, type ButtonType };
