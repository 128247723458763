import {
  selectorFamily,
  useRecoilValue,
  useSetRecoilState,
  DefaultValue,
} from "recoil";
import {
  RecordsTableBaseRecord,
  RecordsTableBaseSelectionState,
} from "~/components_next/RecordsTableBase/types";
import { selectionStateFamily } from "./selectionState";

const recordHighlightsFamily = selectorFamily<
  RecordsTableBaseRecord[] | undefined,
  { viewId: string }
>({
  key: "table-view-record-highlights",
  get:
    ({ viewId }) =>
    ({ get }) => {
      const selectionState = get(selectionStateFamily({ viewId }));
      return selectionState?.recordHilights;
    },
  set:
    ({ viewId }) =>
    ({ set, reset }, newValue) => {
      if (newValue instanceof DefaultValue) {
        reset(selectionStateFamily({ viewId }));
        return;
      }

      set(
        selectionStateFamily({ viewId }),
        (prev): RecordsTableBaseSelectionState => {
          return prev
            ? { ...prev, recordHilights: newValue ?? [] }
            : {
                fieldSelection: [],
                cellSelection: [],
                recordSelection: [],
                editingCell: null,
                recordHilights: newValue,
              };
        }
      );
    },
});

const useRecordHighlights = (viewId: string) => {
  return useRecoilValue(recordHighlightsFamily({ viewId }));
};

const useSetRecordHighlights = (viewId: string) => {
  return useSetRecoilState(recordHighlightsFamily({ viewId }));
};

export { useRecordHighlights, useSetRecordHighlights, recordHighlightsFamily };
