import {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { match, P } from "ts-pattern";
import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragStartEvent,
  MouseSensor,
  useDraggable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { Box } from "@radix-ui/themes";
import { styled } from "~/stitches";
import { CSSProperties } from "@stitches/react";

type RadixBoxProps = ComponentPropsWithoutRef<typeof Box>;

type ResizableDirection = "left" | "right" | "top" | "bottom";

const StyledBox = styled(Box, {});

const useDirections = (
  direction: ResizableDirection | ResizableDirection[]
) => {
  const isHorizontallyResizable =
    direction === "left" ||
    direction === "right" ||
    direction.includes("left") ||
    direction.includes("right");

  const isVerticallyResizable =
    direction === "top" ||
    direction === "bottom" ||
    direction.includes("top") ||
    direction.includes("bottom");

  const isResizableToTheLeft =
    direction === "left" || direction.includes("left");
  const isResizableToTheRight =
    direction === "right" || direction.includes("right");
  const isResizableToTheTop = direction === "top" || direction.includes("top");
  const isResizableToTheBottom =
    direction === "bottom" || direction.includes("bottom");

  return {
    isHorizontallyResizable,
    isVerticallyResizable,
    isResizableToTheLeft,
    isResizableToTheRight,
    isResizableToTheTop,
    isResizableToTheBottom,
  };
};

// Handles
const ResizableHandleElement = styled("div", {
  position: "absolute",
  backgroundColor: "transparent",
  cursor: "col-resize",
  borderRadius: "2px",
  "&:hover": {
    backgroundColor: "$bg3",
  },
  "&:active": {
    backgroundColor: "$bg3",
  },
  variants: {
    direction: {
      h: {
        cursor: "col-resize",
      },
      v: {
        cursor: "row-resize",
      },
    },
  },
});
type ResizableHandleProps = {
  direction: ResizableDirection;
};

const ResizableHandle = (props: ResizableHandleProps) => {
  const { direction } = props;

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: `resize-handle-${direction}`,
  });

  const getHandleStyle = (): CSSProperties => {
    return match(props.direction)
      .with("left", () => ({
        left: 0,
        top: 0,
        bottom: 0,
        width: "5px",
      }))
      .with("right", () => ({
        right: 0,
        top: 0,
        bottom: 0,
        width: "5px",
      }))
      .with("top", () => ({
        right: 0,
        left: 0,
        top: 0,
        height: "5px",
      }))
      .with("bottom", () => ({
        right: 0,
        left: 0,
        bottom: 0,
        height: "5px",
      }))
      .otherwise(() => ({}));
  };

  return (
    <>
      <ResizableHandleElement
        direction={direction === "left" || direction === "right" ? "h" : "v"}
        style={{
          ...getHandleStyle(),
        }}
        ref={setNodeRef}
        {...listeners}
        {...attributes}
      ></ResizableHandleElement>
    </>
  );
};

type ResizableHandlesProps = {
  direction: ResizableDirection | ResizableDirection[];
};

const ResizableHandles = (props: ResizableHandlesProps) => {
  const getChildren = () => {
    return match(props.direction)
      .with("left", () => <ResizableHandle direction="left" />)
      .with("right", () => <ResizableHandle direction="right" />)
      .with("top", () => <ResizableHandle direction="top" />)
      .with("bottom", () => <ResizableHandle direction="bottom" />)
      .otherwise((direction) => {
        const childrenArray = [] as ReactElement[];
        direction.forEach((d) => {
          childrenArray.push(<ResizableHandle direction={d} />);
        });
        return <>{childrenArray}</>;
      });
  };
  return <>{getChildren()}</>;
};

// Box
type ResizableBoxProps = Omit<RadixBoxProps, "width" | "height"> & {
  direction: ResizableDirection | ResizableDirection[];
  onResizeDone?: (width: number, height: number) => void;
  onResizeStart?: () => void;
  width: number | string;
  height: number | string;
  maxWidth?: number;
  minWidth?: number;
  maxHeight?: number;
  minHeight?: number;
};

const _ResizableBox = (
  props: ResizableBoxProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const {
    direction,
    width,
    height,
    maxWidth,
    minWidth,
    maxHeight,
    minHeight,
    onResizeDone,
    onResizeStart,
    style,
  } = props;

  const { isHorizontallyResizable, isVerticallyResizable } =
    useDirections(direction);

  const [widthValue, setWidthValue] = useState<number>(0);
  const [heightValue, setHeightValue] = useState<number>(0);

  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [resizeDeltaWidth, setResizeDeltaWidth] = useState<number>(0);
  const [resizeDeltaHeight, setResizeDeltaHeight] = useState<number>(0);

  // Check direction and set width/height
  // run only once
  useEffect(() => {
    match([isHorizontallyResizable, width])
      .with([true, P.string], () => {
        throw new Error("Width must be a number when horizontally resizable.");
      })
      .with([true, P.nullish], () => {
        throw new Error("Width must be set when horizontally resizable.");
      })
      .with([true, P.number], () => {
        setWidthValue(width as number);
      })
      .otherwise(() => {
        console.log();
      });

    match([isVerticallyResizable, height])
      .with([true, P.string], () => {
        throw new Error("Height must be a number when vertically resizable.");
      })
      .with([true, P.nullish], () => {
        throw new Error("Height must be set when vertically resizable.");
      })
      .with([true, P.number], () => {
        setHeightValue(height as number);
      })
      .otherwise(() => {
        console.log();
      });
  }, []);

  // width and height
  const boxWidth = useMemo(() => {
    return match([isHorizontallyResizable, isResizing])
      .with([true, true], () => {
        return widthValue + resizeDeltaWidth;
      })
      .with([true, false], () => {
        return widthValue;
      })
      .otherwise(() => {
        return width;
      });
  }, [
    widthValue,
    width,
    isHorizontallyResizable,
    resizeDeltaWidth,
    isResizing,
  ]);

  const boxHeight = useMemo(() => {
    return match([isVerticallyResizable, isResizing])
      .with([true, true], () => {
        return heightValue + resizeDeltaHeight;
      })
      .with([true, false], () => {
        return heightValue;
      })
      .otherwise(() => {
        return height;
      });
  }, [
    heightValue,
    height,
    isVerticallyResizable,
    resizeDeltaHeight,
    isResizing,
  ]);

  // Get width and height values considering min/max value
  const getCorrectedWidth = (widthValue: number) => {
    return match([widthValue, maxWidth, minWidth])
      .with([P.number, P.number, P.number], () => {
        return Math.max(
          Math.min(widthValue, maxWidth as number),
          minWidth as number
        );
      })
      .with([P.number, P.number, P.nullish], () => {
        return Math.min(widthValue, maxWidth as number);
      })
      .with([P.number, P.nullish, P.number], () => {
        return Math.max(widthValue, minWidth as number);
      })
      .otherwise(() => {
        return widthValue;
      });
  };

  const getCorrectedHeight = (heightValue: number) => {
    return match([heightValue, maxHeight, minHeight])
      .with([P.number, P.number, P.number], () => {
        return Math.max(
          Math.min(heightValue, maxHeight as number),
          minHeight as number
        );
      })
      .with([P.number, P.number, P.nullish], () => {
        return Math.min(heightValue, maxHeight as number);
      })
      .with([P.number, P.nullish, P.number], () => {
        return Math.max(heightValue, minHeight as number);
      })
      .otherwise(() => {
        return heightValue;
      });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    // マージする作業
    const newWidth = getCorrectedWidth(widthValue + resizeDeltaWidth);
    const newHeight = getCorrectedHeight(heightValue + resizeDeltaHeight);

    setWidthValue(newWidth);
    setHeightValue(newHeight);

    setResizeDeltaHeight(0);
    setResizeDeltaWidth(0);

    if (onResizeDone) {
      onResizeDone(newWidth, newHeight);
    }
  };

  const handleDragMove = (event: DragMoveEvent) => {
    const { active, delta } = event;
    match(active)
      .with({ id: "resize-handle-left" }, () => {
        const diffAbs = Math.abs(
          Math.abs(resizeDeltaWidth) - Math.abs(delta.x)
        );
        if (diffAbs < 100) {
          setResizeDeltaWidth(-Math.round(delta.x));
        }
      })
      .with({ id: "resize-handle-right" }, () => {
        const diffAbs = Math.abs(resizeDeltaWidth - delta.x);
        if (diffAbs < 100) {
          setResizeDeltaWidth(delta.x);
        }
      })
      .with({ id: "resize-handle-top" }, () => {
        setResizeDeltaHeight(-delta.y);
      })
      .with({ id: "resize-handle-bottom" }, () => {
        setResizeDeltaHeight(delta.y);
      })
      .run();
  };

  const handleDragStart = (event: DragStartEvent) => {
    setIsResizing(true);
    onResizeStart?.();
  };

  const boxProps = (({ onResizeDone, onResizeStart, width, height, ...rest }) =>
    rest)(props);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5,
    },
  });

  const sensors = useSensors(mouseSensor);

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
    >
      <StyledBox
        className="resizable-box"
        position="relative"
        {...boxProps}
        css={{
          width: `${boxWidth}px`,
          height: `${boxHeight}px`,
          ...style,
        }}
        ref={ref}
      >
        <>
          {props.children}
          <ResizableHandles direction={props.direction} />
        </>
      </StyledBox>
    </DndContext>
  );
};

const ResizableBox = forwardRef<HTMLDivElement, ResizableBoxProps>(
  _ResizableBox
);

export { ResizableBox, type ResizableBoxProps };
