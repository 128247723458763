import { DashboardWidgetDataRegistApiResponse } from "@usemorph/morph-dashboard-types";
import { BsPlug } from "react-icons/bs";
import { getPath } from "~/routing";
import { LinkItemText } from "./common/LinkItemText";
import { LinkItemWrapper } from "./common/LinkItemWrapper";

type SidebarWidgetDataApiLinkItemProps = {
  dataApi: DashboardWidgetDataRegistApiResponse;
  teamSlug: string;
  tableSlug: string;
  databaseId: string;
};

const SidebarWidgetDataApiLinkItem = (props: SidebarWidgetDataApiLinkItemProps) => {
  const { dataApi, teamSlug, databaseId, tableSlug } = props;

  const to = getPath("widgetDataApi", {
    teamSlug,
    databaseId,
    tableSlug,
    dataApiKey: dataApi.apiKey,
  });

  return (
    <LinkItemWrapper to={to} px="1" gap="1">
      <BsPlug />
      <LinkItemText>{dataApi.apiName}</LinkItemText>
    </LinkItemWrapper>
  );
};

export { SidebarWidgetDataApiLinkItem };
