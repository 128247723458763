import { DashboardCellsClientModel } from "~/clientModel/canvas/DashboardCellClientModel";
import { FindDashboardPageClientModel } from "~/clientModel/canvas/FindDashboardPageClientModel/FindDashboardPageClientModel";
import { ListCanvasPageClientModel } from "~/clientModel/canvas/ListCanvasPageClientModel";
import { Executable } from "~/clientModel/executable";
import { Loadable } from "~/clientModel/loadable";
import { Box } from "~/components_next/Box";
import { CanvasVariablesProvider } from "../common/CanvasVariablesProvider";
import { DashboardPage } from "./page/DashboardPage";
import { DashboardPageTab } from "./pageTabs/DashboardPageTab";
import { DashboardPageTabs } from "./pageTabs/DashboardPageTabs";
import {
  DashboardTableViewPropsContextProps,
  DashboardTableViewPropsProvider,
} from "./providers/DashboardTableViewPropsProvider";
import {
  CanvasVariableOptionsLoadableProvider,
  UseVariableOptionsViewResultLoadable,
} from "../common/CanvasVariableOptionsLoadableProvider";
import { IconButton } from "~/components_next/IconButton";
import { BsPlusLg, BsThreeDotsVertical } from "react-icons/bs";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { DropdownMenu, ScrollArea } from "@radix-ui/themes";
import { useState } from "react";
import { DangerDialog, Dialog } from "~/components_next/Dialog";
import { Flex } from "~/components_next/Flex";
import { Input } from "~/components_next/Input";
import { Button } from "~/components_next/Button";

type CanvasDashboardPresenterProps = {
  pages: ListCanvasPageClientModel[]; // pagesは、Notebookのレスポンスに含まれているので、静的に受け取る
  activePageLoadable: Loadable<FindDashboardPageClientModel>;
  updateLayoutExecutable: Executable<DashboardCellsClientModel, unknown>;
  createPageExecutable: Executable<
    {
      pageName: string;
      description: string;
      isPrivate: boolean;
    },
    ListCanvasPageClientModel
  >;
  updatePageExecutable: Executable<
    {
      pageId: string;
      pageName: string;
      description: string;
    },
    ListCanvasPageClientModel
  >;
  deletePageExecutable: Executable<
    {
      pageId: string;
    },
    unknown
  >;
  removeCellExecutable: Executable<string, unknown>;
  setActivePageId: (pageId: string | null) => void;
  tableViewProps: DashboardTableViewPropsContextProps;
  useVariableOptionsLoadable: UseVariableOptionsViewResultLoadable;
};

// const CanvasDashboardPresenterContent = (props: CanvasDashboardPresenterProps) => {

// }

const CanvasDashboardPresenter = (props: CanvasDashboardPresenterProps) => {
  const {
    pages,
    activePageLoadable,
    setActivePageId,
    updateLayoutExecutable,
    tableViewProps,
    useVariableOptionsLoadable,
    createPageExecutable,
    updatePageExecutable,
    deletePageExecutable,
    removeCellExecutable,
  } = props;

  const handleCreatePage = () => {
    createPageExecutable.execute({
      pageName: "Untitled Page",
      description: "",
      isPrivate: false,
    });
  };

  /**
   * Edit Page
   */
  const [editTargetPage, setEditTargetPage] =
    useState<ListCanvasPageClientModel | null>(null);
  const handleUpdatePage = async () => {
    if (!editTargetPage) return;
    await updatePageExecutable.execute({
      pageId: editTargetPage.pageId,
      pageName: editTargetPage.pageName,
      description: editTargetPage.description || "",
    });
    setEditTargetPage(null);
  };

  /**
   * Delete Page
   */
  const [deleteTargetPage, setDeleteTargetPage] =
    useState<ListCanvasPageClientModel | null>(null);

  const handleDeletePage = async () => {
    if (!deleteTargetPage) return;
    // onDeletePage({
    //   pageId: deleteTargetPage.pageId,
    // });
    await deletePageExecutable.execute({
      pageId: deleteTargetPage.pageId,
    });
    setDeleteTargetPage(null);
  };

  return (
    <>
      <DashboardTableViewPropsProvider {...tableViewProps}>
        <Box width="100%">
          <DashboardPageTabs align="center" gap="1" px="1">
            <ScrollArea>
              <Flex align="center" gap="1">
                {pages.map((page) => {
                  return (
                    <DashboardPageTab
                      key={page.pageId}
                      isActive={page.pageId === activePageLoadable.data?.pageId}
                      onClick={() => {
                        if (page.pageId === activePageLoadable.data?.pageId)
                          return;
                        setActivePageId(page.pageId);
                      }}
                      px="3"
                      py="1"
                      gap="3"
                      align="center"
                    >
                      {page.pageName}
                      <SimpleDropdownMenu
                        trigger={
                          <IconButton
                            icon={<BsThreeDotsVertical />}
                            tooltip="More"
                            size="xs"
                          />
                        }
                      >
                        <DropdownMenu.Item
                          onClick={() => setEditTargetPage(page)}
                        >
                          Settings
                        </DropdownMenu.Item>
                        <DropdownMenu.Item
                          onClick={() => setDeleteTargetPage(page)}
                        >
                          Delete Page
                        </DropdownMenu.Item>
                      </SimpleDropdownMenu>
                    </DashboardPageTab>
                  );
                })}
              </Flex>
            </ScrollArea>
            <IconButton
              size="sm"
              icon={<BsPlusLg />}
              tooltip={"Add Page"}
              style={{ transform: "translateX(6px)" }}
              onClick={handleCreatePage}
            />
          </DashboardPageTabs>

          <CanvasVariablesProvider>
            <CanvasVariableOptionsLoadableProvider
              useVariableOptionsLoadable={useVariableOptionsLoadable}
            >
              <Box width="100%" p="2">
                {activePageLoadable.data && (
                  <DashboardPage
                    page={activePageLoadable.data}
                    updateLayoutExecutable={updateLayoutExecutable}
                    removeCellExecutable={removeCellExecutable}
                  />
                )}
              </Box>
            </CanvasVariableOptionsLoadableProvider>
          </CanvasVariablesProvider>
        </Box>
      </DashboardTableViewPropsProvider>

      {/* Delete Dialog */}
      <DangerDialog
        description="Are you sure to delete this page?"
        isOpen={deleteTargetPage !== null}
        onOpenChange={() => setDeleteTargetPage(null)}
        onConfirm={handleDeletePage}
      />

      {/* Settings Dialog */}
      <Dialog.Root
        open={editTargetPage !== null}
        onOpenChange={() => setEditTargetPage(null)}
        size="xs"
      >
        <Dialog.Content>
          <Dialog.Title>Page Settings</Dialog.Title>
          <Dialog.Body>
            {editTargetPage && (
              <Flex direction="column" gap="4" align="stretch" width="100%">
                <Input
                  label="Page Name"
                  variant="primary"
                  value={editTargetPage.pageName}
                  onChange={(e) => {
                    setEditTargetPage(
                      editTargetPage.updatePageName(e.target.value)
                    );
                  }}
                />

                <Input
                  label="Description"
                  variant="primary"
                  value={editTargetPage.description || ""}
                  onChange={(e) => {
                    setEditTargetPage(
                      editTargetPage.updateDescription(e.target.value)
                    );
                  }}
                />
              </Flex>
            )}
          </Dialog.Body>
          <Dialog.Footer>
            <Dialog.Close>
              <Button
                variant="secondary"
                size="sm"
                isDisabled={updatePageExecutable.isExecuting}
              >
                Close
              </Button>
            </Dialog.Close>
            <Button
              variant="primary"
              size="sm"
              isDisabled={updatePageExecutable.isExecuting}
              onClick={handleUpdatePage}
              isLoading={updatePageExecutable.isExecuting}
            >
              Update
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};

export { CanvasDashboardPresenter };
