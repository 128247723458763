import { FieldClientModel } from "~/clientModel/fields/field";
import {
  ReadonlyRecordEntryClientModelBase,
  ReadonlyRecordEntryClientModelBaseConstructorParams,
} from "../RecordEntryClientModelBase";
import { zValidate } from "../validatorsUtil";
import { z } from "zod";
import { NumberDisplaySettingClientModel } from "~/clientModel/fields/field/displaySetting/numberDisplaySetting";
import { DateDisplaySettingClientModel } from "~/clientModel/fields/field/displaySetting/date";

export type SyncValueRecordEntryValueType =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | boolean[]
  | null;

type SyncValueRecordEntryClientModelData =
  ReadonlyRecordEntryClientModelBaseConstructorParams;

export class SyncValueRecordEntryClientModel extends ReadonlyRecordEntryClientModelBase<SyncValueRecordEntryValueType> {
  constructor(data: SyncValueRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "syncValue" {
    return "syncValue";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([
        z.string(field),
        z.number(),
        z.boolean(),
        z.array(z.string()),
        z.array(z.number()),
        z.array(z.boolean()),
        z.null(),
      ]),
      this.data.rawValue
    );
  }

  public getValidatedDisplayValue(field: FieldClientModel) {
    const validatedValue = this.getValidatedValue(field);

    if (!validatedValue.isValid) {
      return validatedValue;
    }

    if (
      field.displaySetting instanceof NumberDisplaySettingClientModel &&
      typeof validatedValue.value === "string"
    ) {
      return {
        isValid: true,
        value: field.displaySetting.getDisplayValue(validatedValue.value),
      } as const;
    }

    if (
      field.displaySetting instanceof DateDisplaySettingClientModel &&
      typeof validatedValue.value === "string"
    ) {
      return {
        isValid: true,
        value: field.displaySetting.getDisplayValue(validatedValue.value),
      } as const;
    }

    return validatedValue;
  }
}
