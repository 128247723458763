import { ForwardedRef, forwardRef } from "react";
import { LastEditedAtRecordEntryClientModel } from "~/clientModel/records/record/recordEntry";
import { Input } from "~/components_next/Input";
import { RecordValueInputCommonProps } from "../../type";

const LastEditedAtReadonlyInput = forwardRef(function LastEditedAtReadonlyInput(
  props: RecordValueInputCommonProps<LastEditedAtRecordEntryClientModel>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { editingRecordEntry, size, field } = props;

  const validatedValue = editingRecordEntry.getValidatedValue(field);

  const value = validatedValue.isValid ? validatedValue.value : "";

  return (
    <Input
      ref={ref}
      size={size}
      variant="primary"
      value={value ?? ""}
      readOnly={true}
    />
  );
});

export { LastEditedAtReadonlyInput };
