import { UseExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { CreatingRecordClientModel } from "~/clientModel/records/creatingRecord";
import { Flex } from "~/components_next/Flex";
import { RecordValueInputWithFieldInfo } from "./RecordValueInputWithFieldInfo";

type CreateRecordFormProps = {
  creatingRecord: CreatingRecordClientModel;
  fields: FieldsClientModel;
  onCreatingRecordChange: (creatingRecord: CreatingRecordClientModel) => void;
  useUploadFileExecutable: UseExecutable<
    void,
    { file: File },
    {
      data: string;
      url: string;
    },
    unknown
  >;
  isReadonly?: boolean;
};

export const CreateRecordForm = (props: CreateRecordFormProps) => {
  const {
    creatingRecord,
    fields,
    onCreatingRecordChange,
    useUploadFileExecutable,
    isReadonly = false,
  } = props;

  return (
    <Flex direction="column" gap="7">
      {fields.allFields.map((field) => {
        const recordEntry = creatingRecord.selectCreatingRecordEntry(
          field.name
        );

        if (!recordEntry) {
          return null;
        }

        if (field.isAutoGenerated || field.isReservedField) {
          return null;
        }

        return (
          <RecordValueInputWithFieldInfo
            key={field.name}
            recordEntry={recordEntry}
            onRecordEntryChange={(entry) => {
              onCreatingRecordChange(creatingRecord.updateRecordEntry(entry));
            }}
            field={field}
            useUploadFileExecutable={useUploadFileExecutable}
            isReadonly={isReadonly}
          />
        );
      })}
    </Flex>
  );
};
