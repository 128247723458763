import { DashboardViewUnionObject } from "@usemorph/morph-dashboard-types";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { DangerDialog } from "~/components_next/Dialog";
import {
  SimpleDropdownMenu,
  DropdownMenu,
} from "~/components_next/DropdownMenu";
import { useErrorToast } from "~/components_next/Error";
import { IconButton } from "~/components_next/IconButton";
import { Text } from "~/components_next/Text";
import { useDisclosure } from "~/hooks/useDisclosure";
import { getPath } from "~/routing";
import { useDeleteViewMutation } from "~/serverStateStore/views";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useDeleteNotebookMutaiton } from "~/serverStateStore";

type ViewSettingsDropdownProps =
  | {
      view: DashboardViewUnionObject;
      displayDelete: boolean;
      isLoadingIcon?: boolean;
    } & (
      | {
          displaySetting: true;
          onClickSetting: () => void;
        }
      | {
          displaySetting?: false;
        }
    ) &
      (
        | {
            displayDuplicate: true;
            onClickDuplicate: () => void;
          }
        | {
            displayDuplicate?: false;
          }
      );

const ViewSettingsDropdown = (props: ViewSettingsDropdownProps) => {
  const {
    view,
    isLoadingIcon,
    displayDelete,
    displaySetting,
    displayDuplicate,
  } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  /**
   * Delete
   */
  const deleteDialogDisclosure = useDisclosure();

  const { errorToast } = useErrorToast({});

  const { mutateAsync: deleteView, isLoading: isDeletingView } = useMutation({
    ...useDeleteViewMutation({
      teamSlug,
      databaseId,
    }),
  });

  const { mutateAsync: deleteNotebook, isLoading: isDeletingNotebook } =
    useMutation({
      ...useDeleteNotebookMutaiton({
        teamSlug,
        databaseId,
      }),
    });

  const navigate = useNavigate();

  const handleDeleteTable = async () => {
    try {
      if (view.viewCategory === "notebook") {
        await deleteNotebook({
          notebookId: view.viewId,
        });
      } else {
        await deleteView({
          viewId: view.viewId,
        });
      }
    } catch (e) {
      errorToast(e);
    } finally {
      navigate(
        getPath("databaseHome", {
          teamSlug,
          databaseId,
        })
      );
    }
    deleteDialogDisclosure.onClose();
  };

  return (
    <>
      <SimpleDropdownMenu
        trigger={
          <IconButton
            icon={<BsThreeDotsVertical />}
            tooltip="View settings"
            isLoading={isLoadingIcon}
          />
        }
      >
        {displaySetting && (
          <DropdownMenu.Item onClick={props.onClickSetting}>
            Settings
          </DropdownMenu.Item>
        )}
        {displayDuplicate && (
          <DropdownMenu.Item onClick={props.onClickDuplicate}>
            Duplicate
          </DropdownMenu.Item>
        )}
        {displayDelete && (
          <>
            <DropdownMenu.Item onClick={deleteDialogDisclosure.onOpen}>
              <Text variant="errorMessage">Delete View</Text>
            </DropdownMenu.Item>
          </>
        )}
      </SimpleDropdownMenu>

      <DangerDialog
        description={`Are you sure to delete ${view.name} view permanently?`}
        isOpen={deleteDialogDisclosure.isOpen}
        onOpenChange={deleteDialogDisclosure.setIsOpen}
        onConfirm={handleDeleteTable}
        isConfirmLoading={isDeletingNotebook || isDeletingView}
      />
    </>
  );
};

export { ViewSettingsDropdown };
