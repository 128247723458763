import { useMemo } from "react";
import { BsTable } from "react-icons/bs";
import { CanvasCreateTableViewCellClientModel } from "~/clientModel/canvas/CanvasCreateCellClientModel";
import { FieldsClientModel } from "~/clientModel/fields";
import { Loadable } from "~/clientModel/loadable";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { ControlledDropdown } from "~/components_next/DropdownMenu";
import { useErrorToast } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { SimpleSelect } from "~/components_next/Select";
import { Text } from "~/components_next/Text";
import { TextArea } from "~/components_next/TextArea";
import { GroupingFieldsSelect } from "./GroupingFieldsSelect";

type CreateTableViewCellDropdownProps = {
  createCellInstance: CanvasCreateTableViewCellClientModel | null;
  onCreateCellInstanceChange: (
    payload: CanvasCreateTableViewCellClientModel | null
  ) => void;
  isCreatingCell: boolean;
  onCreateTableViewCell: () => void;
  fieldsLoadable: Loadable<FieldsClientModel>;
};

const CreateTableViewCellDropdown = (
  props: CreateTableViewCellDropdownProps
) => {
  const {
    createCellInstance,
    onCreateCellInstanceChange,
    isCreatingCell,
    onCreateTableViewCell,
    fieldsLoadable,
  } = props;

  const { errorToast } = useErrorToast({});

  const fieldsOptions = useMemo(() => {
    return (
      fieldsLoadable.data?.allFields.map((field) => {
        return {
          label: field.displayName || field.name,
          value: field.name,
        };
      }) || []
    );
  }, [fieldsLoadable.data?.allFields]);

  const timeFieldOptions = useMemo(() => {
    return (
      fieldsLoadable.data?.allFields
        .filter(
          (field) =>
            field.type.type === "date" ||
            field.type.type === "datetime" ||
            field.type.type === "time"
        )
        .map((field) => {
          return {
            label: field.displayName || field.name,
            value: field.name,
          };
        }) || []
    );
  }, [fieldsLoadable.data?.allFields]);

  const handleSelectTimeseriesField = (value?: string | null) => {
    const findXAxisField = fieldsLoadable.data?.allFields.find(
      (field) => field.name === value
    );
    if (!findXAxisField) {
      errorToast({
        title: "Error",
        description: "Cannot find field",
      });
      return;
    }

    onCreateCellInstanceChange(
      createCellInstance?.updateTimeSeriesTargetField(findXAxisField) ?? null
    );
  };

  // const handleSelectGroupingField = (value?: string | null) => {
  //   if (!createCellInstance) {
  //     return;
  //   }
  //   const findXAxisField = fieldsLoadable.data?.allFields.find(
  //     (field) => field.name === value
  //   );
  //   if (!findXAxisField) {
  //     errorToast({
  //       title: "Error",
  //       description: "Cannot find field",
  //     });
  //     return;
  //   }

  //   onCreateCellInstanceChange(
  //     createCellInstance.updateGroupingTargetField(findXAxisField)
  //   );
  // };

  // const handleGroupingFieldsChange = (value?: string[] | null) => {
  //   if (!createCellInstance) {
  //     return;
  //   }
  //   const groupingFieldInstances = fieldsLoadable.data?.allFields.filter(
  //     (field) => value?.includes(field.name) ?? false
  //   );

  //   onCreateCellInstanceChange(
  //     createCellInstance.updateGroupingTargetFields(groupingFieldInstances)
  //   );
  // };

  if (!createCellInstance) {
    return null;
  }

  return (
    <>
      <ControlledDropdown
        trigger={
          <Box
            css={{
              width: "30px",
              height: "2px",
              backgroundColor: "transparent",
            }}
          ></Box>
        }
        isOpen={createCellInstance !== null}
        onOpenChange={() => null}
      >
        <Flex gap="4" direction="column" p="2" css={{ width: "610px" }}>
          <Flex gap="1" align="center">
            <BsTable />
            <Text fontWeight="medium">
              Table view / {createCellInstance.typeLabel} /{" "}
              {createCellInstance.operatorLabel}
            </Text>
          </Flex>

          <Flex direction="column" gap="2">
            <Text variant="description">Settings</Text>
            {createCellInstance.tableViewType === "timeSeries" && (
              <SimpleSelect
                size="sm"
                variant="primary"
                options={timeFieldOptions}
                value={createCellInstance.timeSeriesTargetField?.name}
                label="Series"
                onChange={handleSelectTimeseriesField}
              />
            )}
            {createCellInstance.tableViewType === "grouping" && (
              <GroupingFieldsSelect
                fieldsLoadable={fieldsLoadable}
                createCellInstance={createCellInstance}
                onCreateCellInstanceChange={onCreateCellInstanceChange}
              />
            )}
          </Flex>

          <Flex direction="column" gap="2">
            <Text variant="description">Prompt</Text>
            <TextArea
              variant="primary"
              value={createCellInstance.prompt}
              onChange={(e) =>
                onCreateCellInstanceChange(
                  createCellInstance.updatePrompt(e.target.value)
                )
              }
            />
          </Flex>

          <Flex gap="4">
            <Button
              variant="primary"
              size="sm"
              onClick={onCreateTableViewCell}
              isLoading={isCreatingCell}
            >
              Create Cell
            </Button>
            <Button
              variant="tertiary"
              size="sm"
              onClick={() => onCreateCellInstanceChange(null)}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
      </ControlledDropdown>
    </>
  );
};

export { CreateTableViewCellDropdown };
