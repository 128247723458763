import { Tabs as RadixThemeTabs } from "@radix-ui/themes";
import { createContext, ReactNode, useContext, useMemo } from "react";
import { MorphThemeSize } from "../../commonProps.type";

type TabsRootProps = {
  children: ReactNode;
  size?: MorphThemeSize;
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => void;
};

type TabsContextType = {
  size: MorphThemeSize;
};

const TabsContext = createContext({} as TabsContextType);

const useTabsContext = () => {
  return useContext(TabsContext);
};

const TabsRoot = (props: TabsRootProps) => {
  const { children, size = "md", defaultValue, value, onChange } = props;

  const contextValue = useMemo(() => ({ size }), [size]);

  return (
    <TabsContext.Provider value={contextValue}>
      <RadixThemeTabs.Root
        defaultValue={defaultValue ?? undefined}
        value={value}
        onValueChange={onChange}
        color="gray"
      >
        {children}
      </RadixThemeTabs.Root>
    </TabsContext.Provider>
  );
};

export { TabsRoot, useTabsContext };
export type { TabsRootProps, TabsContextType };
