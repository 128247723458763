import useApiPayment from "~/api/useApiPayment";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { paymentKeys } from "../keys";

interface UseRegistTrialMutationParams {
  teamSlug: string;
}

type RegistTrialMutationProps = {
  versionId: string;
}

const useRegistTrialMutation = ({
  teamSlug,
}: UseRegistTrialMutationParams) => {
  const { _registerTrial } = useApiPayment();
  const client = useQueryClient();

  return {
    mutationFn: ({ versionId }: RegistTrialMutationProps) => {
      return _registerTrial({
        teamSlug,
        versionId,
      });
    },
    onSuccess: () => {
      return invalidateAll(client, paymentKeys.all({ teamSlug }));
    },
  };
};

export { useRegistTrialMutation, type RegistTrialMutationProps };
