import { DashboardColorCodesListResponse } from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

export default function useApiCanvasStyle() {
  const { executeRequest } = useApi();

  const _listMyColors = async ({ teamSlug }: { teamSlug: string }) => {
    return executeRequest<DashboardColorCodesListResponse>(
      "get",
      `/color-code`,
      undefined,
      {
        teamSlug,
      }
    );
  };

  const _listPresetColors = async ({ teamSlug }: { teamSlug: string }) => {
    return executeRequest<DashboardColorCodesListResponse>(
      "get",
      `/color-code/default`,
      undefined,
      {
        teamSlug,
      }
    );
  };

  const _saveNewColors = async ({
    colorCodes,
    teamSlug,
  }: {
    colorCodes: string[];
    teamSlug: string;
  }) => {
    return executeRequest<{ colorCodeId: string; colorCodes: string[] }>(
      "post",
      `/color-code`,
      undefined,
      {
        teamSlug,
      },
      {
        colorCodes,
      }
    );
  };

  const _generateNewColors = async ({
    prompt,
    teamSlug,
  }: {
    prompt: string;
    teamSlug: string;
  }) => {
    return executeRequest<{ colorCodes: string[] }>(
      "post",
      `/color-code/generate`,
      undefined,
      {
        teamSlug,
      },
      {
        prompt,
      }
    );
  };

  const _deleteMyColors = async ({
    colorCodeId,
    teamSlug,
  }: {
    colorCodeId: string;
    teamSlug: string;
  }) => {
    return executeRequest<{ message: string }>(
      "delete",
      `/color-code/${colorCodeId}`,
      undefined,
      {
        teamSlug,
      }
    );
  };

  const _previewColors = async ({
    colorCodes,
    teamSlug,
  }: {
    colorCodes: string[];
    teamSlug: string;
  }) => {
    return executeRequest<{ previews: Array<{ html: string }> }>(
      "post",
      `/color-code/preview`,
      undefined,
      {
        teamSlug,
      },
      {
        colorCodes,
      }
    );
  };

  return {
    _listMyColors,
    _listPresetColors,
    _saveNewColors,
    _generateNewColors,
    _deleteMyColors,
    _previewColors,
  };
}
