import {
  GenerateTextFieldTypeClientModel,
  LongTextFieldTypeClientModel,
  ShortTextFieldTypeClientModel,
} from "~/clientModel/fields/field/fieldType";
import {
  GenerateTextAndCalculationCommonSettingsInput,
  GenerateTextAndCalculationCommonSettingsInputProps,
} from "./GenerateTextAndCalculationCommonSettingsInput";

const syncValueTypeOptions = [
  new ShortTextFieldTypeClientModel(),
  new LongTextFieldTypeClientModel(),
] as const;

type GenerateTextSettingsInputProps = Omit<
  GenerateTextAndCalculationCommonSettingsInputProps<
    typeof syncValueTypeOptions
  >,
  "syncValueTypeOptions" | "onSyncValueTypeChange" | "fieldType"
> & {
  fieldType: GenerateTextFieldTypeClientModel;
};

export const GenerateTextSettingsInput = (
  props: GenerateTextSettingsInputProps
) => {
  const { field, onFieldChange, fieldType } = props;

  const handleSyncValueTypeChange = (
    syncedValueType:
      | ShortTextFieldTypeClientModel
      | LongTextFieldTypeClientModel
  ) => {
    onFieldChange(
      field.updateType(fieldType.updateSyncedValueType(syncedValueType))
    );
  };

  return (
    <GenerateTextAndCalculationCommonSettingsInput
      {...props}
      syncValueTypeOptions={syncValueTypeOptions}
      onSyncValueTypeChange={handleSyncValueTypeChange}
    />
  );
};
