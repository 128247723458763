import { SmartFieldInputSetting } from "@usemorph/morph-dashboard-types";
import { match } from "ts-pattern";
import { SmartFunctionInputClientModel } from "./SmartFunctionInputClientModel";
import { SmartFunctionPromptStringInputClientModel } from "./SmartFunctionPromptStringInputClientModel";
import { SmartFunctionSelectFieldInputClientModel } from "./SmartFunctionSelectFieldInputClientModel";
import { SmartFunctionStringInputClientModel } from "./SmartFunctionStringInputClientModel";

export class SmartFunctionInputClientModelFactory {
  public static createFromSmartFieldInputSettingItem(
    item: SmartFieldInputSetting["inputs"][number]
  ): SmartFunctionInputClientModel {
    return match(item.type)
      .with(
        "string",
        () =>
          new SmartFunctionStringInputClientModel({
            key: item.key,
            label: item.label,
            description: item.description,
            required: item.required,
          })
      )
      .with(
        "promptString",
        () =>
          new SmartFunctionPromptStringInputClientModel({
            key: item.key,
            label: item.label,
            description: item.description,
            required: item.required,
            annotatedFieldNameRequired:
              item.promptStringOptions?.annotatedFieldNameRequired ?? false,
            annotateFieldName:
              item.promptStringOptions?.annotateFieldName ?? false,
          })
      )
      .with(
        "selectField",
        () =>
          new SmartFunctionSelectFieldInputClientModel({
            key: item.key,
            label: item.label,
            description: item.description,
            required: item.required,
          })
      )
      .otherwise(() => {
        throw new Error(`Unknown input type: ${item.type}`);
      });
  }
}
