import { SimpleField } from "@usemorph/morph-dashboard-types";
import { BsEye } from "react-icons/bs";
import { Text } from "~/components_next/Text";
import { IconButton } from "~/components_next/IconButton";
import { Flex } from "~/components_next/Flex";
import { Badge } from "~/components_next/Badge";

type HiddenFieldsProps = {
  hiddenFields: SimpleField[];
  onShowField: (field: SimpleField) => void;
};

const HiddenFields = (props: HiddenFieldsProps) => {
  const { hiddenFields, onShowField } = props;

  return (
    <Flex direction="row">
      <Text variant="subheading" style={{ width: "120px", flex: "none" }}>
        Hidden Fields
      </Text>
      <Flex direction="row" gap="2" style={{ flexWrap: "wrap" }}>
        {hiddenFields.map((hiddenField) => (
          <Badge
            variant="tertiary"
            key={hiddenField.name}
            size="sm"
            onClick={() => onShowField(hiddenField)}
          >
            {hiddenField.displayName ?? hiddenField.name}
            <IconButton size="xs" icon={<BsEye />} tooltip="Show" />
          </Badge>
        ))}
      </Flex>
    </Flex>
  );
};

export { HiddenFields };
