import { useMemo } from "react";

import { Source } from "~/presenters/sourceAndViews/source/Source";
import { useSourceRecordsAndFieldsLoadable } from "./hooks/useSourceRecordsAndFieldsLoadable/useSourceRecordsAndFieldsLoadable";
import { createUseUpdateRecordExecutable } from "./hooks/records/createUseUpdateRecordExecutable";
import { createUseDeleteRecordsExecutable } from "./hooks/records/createUseDeleteRecordsExecutable";
import { useSuggestedPromptsLoadable } from "./hooks/useSuggestedPromptsLoadable";
import { createUseCreateRecordExecutable } from "./hooks/records/createUseCreateRecordExecutable";
import { createUseCreateFieldExecutable } from "./hooks/fields/createUseCreateFieldExecutable";
import { createUseUpdateFieldExecutable } from "./hooks/fields/createUseUpdateFieldExecutable";
import { createUseDeleteFieldExecutable } from "./hooks/fields/createUseDeleteFieldExecutable";
import { createUseTestFormulaExecutable } from "./hooks/fields/createUseTestFormulaExecutable";
import { createUseUploadFileExecutable } from "./hooks/createUseUploadFileExecutable";
import { createUseActivateEmbeddingExecutable } from "./hooks/fields/createUseActivateEmbeddingExecutable";
import { createUseBulkEditRecordsByRowExecutable } from "./hooks/records/createUseBulkEditRecordsByRowExecutable";
import { createUseBulkEditRecordsByFormulaExecutable } from "./hooks/records/createUseBulkEditRecordsByFormulaExecutable";
import { createUsePreviewBulkEditRecordsByFormulaExecutable } from "./hooks/records/createUsePreviewBulkEditRecordsByFormulaExecutable";
import { createUseGenerateFormulaExecutable } from "./hooks/records/createUseGenerateFormulaExecutable";
import { createUsePreviewBulkEditRecordsByPromptExecutable } from "./hooks/records/createUsePreviewBulkEditRecordsByPromptExecutable";
import { createUseBulkEditRecordsByPromptExecutable } from "./hooks/records/createUseBulkEditRecordsByPromptExecutable";
import { createUseGenerateSqlFromPromptExecutable } from "./hooks/createUseGenerateSqlFromPromptExecutable";
import { createUseUpdateFieldsOrderExecutable } from "./hooks/fields/createUseUpdateFieldsOrderExecutable";
import { createUseUpdateFieldVisibilityExecutable } from "./hooks/fields/createUseUpdateFieldVisibilityExecutable";
import { createUseCreateRecordsExecutable } from "./hooks/records/createUseCreateRecordsExecutable";
import { createUseGenerateRecordsWithPromptExecutable } from "./hooks/records/createUseGenerateRecordsWithPromptExecutable";
import { usePromptHistoriesLoadable } from "./hooks/usePromptHistoriesLoadable";
import { useTablesLoadable } from "./hooks/tables/useTablesLoadable";
import { createUseFieldsLoadable } from "./hooks/fields/createUseFieldsLoadable";
import { useTableLoadable } from "./hooks/tables/useTableLoadable";
import { createUseUpdateMergeKeyExecutable } from "./hooks/fields/createUseUpdateMergeKeyExecutable";
import { useSyncStatusLoadable } from "./hooks/useSyncStatusLoadable";
import { useAggregateValueFieldOperatorsLoadable } from "./hooks/fields/useAggregateValueFieldOperatorsLoadable";
import { useCsvInsertContainer } from "../csvInsert/useCsvInsertContainer";
import { useSmartFunctionsLoadable } from "./hooks/fields/useSmartFunctionsLoadable";
import { useSourceTableColumnSizingLoadable } from "./hooks/useSourceTableColumnSizingLoadable";
import { useSourceTableSelection } from "./hooks/useSourceTableSelection";
import { useSourceCoworkersState } from "./hooks/useSourceCoworkersState";
import { createUseDownloadCsvWithQueryConditionsExecutable } from "./hooks/downloadCsv/createUseDownloadCsvWithQueryConditionsExecutable";
import { createUseDownloadCsvWithQueryPreviewRecordsLoadable } from "./hooks/downloadCsv/createUseDownloadCsvWithQueryPreviewRecordsLoadable";
import { createUseDownloadCsvWithSqlExecutable } from "./hooks/downloadCsv/createUseDownloadCsvWithSqlExecutable";
import { createUseDownloadCsvWithSqlPreviewRecordsLoadable } from "./hooks/downloadCsv/createUseDownloadCsvWithSqlPreviewRecordsLoadable";
import { createUseRecordHistoriesLoadable } from "./hooks/createUseRecordHistoriesLoadable";
import { useSourceQueryModeState } from "./hooks/useSourceQueryModeState";
import { useSourceQueryConditionsState } from "./hooks/useSourceQueryConditionsState";
import { createUseGenerateSqlForComputedFieldFromPromptExecutable } from "./hooks/fields/createUseGenerateSqlForComputedFieldFromPromptExecutable";
import { useSourceFieldsLoadable } from "./hooks/fields/useSourceFieldsLoadable";
import { createUseStartSyncComputedFieldsExecutable } from "./hooks/fields/createUseStartSyncComputedFieldsExecutable";
import { createUseCreateComputedFieldExecutable } from "./hooks/fields/createUseCreateComputedFieldExecutable";
import { createUseMakeFieldsNullableExecutable } from "./hooks/fields/createUseMakeFieldsNullableExecutable";
import { createUseRunSmartFieldExecutable } from "./hooks/fields/createUseRunSmartFieldExecutable";

type SourceContainerProps = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

export const SourceContainer = (props: SourceContainerProps) => {
  const { teamSlug, databaseId, tableSlug } = props;

  /**
   * Local State
   */
  const [queryConditions, setQueryConditions] = useSourceQueryConditionsState();
  const [queryMode, setQueryMode] = useSourceQueryModeState();

  const [tableSelection, setTableSelection] = useSourceTableSelection();

  const coworkersState = useSourceCoworkersState();

  /**
   * Loadables
   */

  const { recordsLoadable, fieldsLoadable } = useSourceRecordsAndFieldsLoadable(
    {
      teamSlug,
      databaseId,
      tableSlug,
      queryMode,
      queryConditions,
    }
  );

  const sourceFieldsLoadable = useSourceFieldsLoadable({
    teamSlug,
    databaseId,
    tableSlug,
  });

  const suggestedPromptsLoadable = useSuggestedPromptsLoadable({
    teamSlug,
    databaseId,
    tableSlug,
  });

  const promptHistoriesLoadable = usePromptHistoriesLoadable({
    teamSlug,
    databaseId,
    tableSlug,
  });

  const tablesLoadable = useTablesLoadable({
    teamSlug,
    databaseId,
  });

  const tableLoadable = useTableLoadable({
    teamSlug,
    databaseId,
    tableSlug,
  });

  const syncStatusLoadable = useSyncStatusLoadable({
    teamSlug,
    databaseId,
    tableSlug,
  });

  const aggregateValueFieldOperatorsLoadable =
    useAggregateValueFieldOperatorsLoadable({ teamSlug });

  const smartFunctionsLoadable = useSmartFunctionsLoadable({ teamSlug });

  const useFieldsLoadable = useMemo(
    () => createUseFieldsLoadable({ teamSlug, databaseId }),
    [teamSlug, databaseId]
  );

  const [tableColumnSizingLoadable, onTableColumnSizingChange] =
    useSourceTableColumnSizingLoadable({
      tableSlug,
      fieldsLoadable,
    });

  const useDownloadCsvWithQueryPreviewRecordsLoadable = useMemo(
    () =>
      createUseDownloadCsvWithQueryPreviewRecordsLoadable({
        teamSlug,
        databaseId,
        tableSlug,
        filterConditions: queryConditions.filterConditions,
        sortConditions: queryConditions.sortConditions,
      }),
    [
      teamSlug,
      databaseId,
      tableSlug,
      queryConditions.filterConditions,
      queryConditions.sortConditions,
    ]
  );

  const useDownloadCsvWithSqlPreviewRecordsLoadable = useMemo(
    () =>
      createUseDownloadCsvWithSqlPreviewRecordsLoadable({
        teamSlug,
        databaseId,
        tableSlug,
        sql: queryConditions.queryingSql,
      }),
    [teamSlug, databaseId, tableSlug, queryConditions.queryingSql]
  );

  const useRecordHistoriesLoadable = useMemo(
    () =>
      createUseRecordHistoriesLoadable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  /**
   * Handlers
   */

  const useCreateRecordExecutable = useMemo(
    () =>
      createUseCreateRecordExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const useCreateRecordsExecutable = useMemo(
    () =>
      createUseCreateRecordsExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const useGenerateRecordsWithPromptExecutable = useMemo(
    () =>
      createUseGenerateRecordsWithPromptExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const useUpdateRecordExecutable = useMemo(
    () =>
      createUseUpdateRecordExecutable({
        teamSlug,
        databaseId,
        tableSlug,
        fieldsLoadable,
      }),
    [teamSlug, databaseId, tableSlug, fieldsLoadable]
  );

  const useBulkEditRecordsByRowExecutable = useMemo(
    () =>
      createUseBulkEditRecordsByRowExecutable({
        teamSlug,
        databaseId,
        tableSlug,
        filterConditions: queryConditions.filterConditions,
        tableSelection: tableSelection,
      }),
    [
      teamSlug,
      databaseId,
      tableSlug,
      queryConditions.filterConditions,
      tableSelection,
    ]
  );

  const useBulkEditRecordsByFormulaExecutable = useMemo(
    () =>
      createUseBulkEditRecordsByFormulaExecutable({
        teamSlug,
        databaseId,
        tableSlug,
        filterConditions: queryConditions.filterConditions,
        tableSelection: tableSelection,
      }),
    [
      teamSlug,
      databaseId,
      tableSlug,
      queryConditions.filterConditions,
      tableSelection,
    ]
  );

  const usePreviewBulkEditRecordsByFormulaExecutable = useMemo(
    () =>
      createUsePreviewBulkEditRecordsByFormulaExecutable({
        teamSlug,
        databaseId,
        tableSlug,
        fieldsLoadable,
      }),
    [teamSlug, databaseId, tableSlug, fieldsLoadable]
  );

  const useGenerateFormulaExecutable = useMemo(
    () =>
      createUseGenerateFormulaExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const usePreviewBulkEditRecordsByPromptExecutable = useMemo(
    () =>
      createUsePreviewBulkEditRecordsByPromptExecutable({
        teamSlug,
        databaseId,
        tableSlug,
        fieldsLoadable,
      }),
    [teamSlug, databaseId, tableSlug, fieldsLoadable]
  );

  const useBulkEditRecordsByPromptExecutable = useMemo(
    () =>
      createUseBulkEditRecordsByPromptExecutable({
        teamSlug,
        databaseId,
        tableSlug,
        filterConditions: queryConditions.filterConditions,
        tableSelection: tableSelection,
      }),
    [
      teamSlug,
      databaseId,
      tableSlug,
      queryConditions.filterConditions,
      tableSelection,
    ]
  );

  const useDeleteRecordsExecutable = useMemo(
    () =>
      createUseDeleteRecordsExecutable({
        teamSlug,
        databaseId,
        tableSlug,
        fieldsLoadable,
      }),
    [teamSlug, databaseId, tableSlug, fieldsLoadable]
  );

  const useCreateFieldExecutable = useMemo(
    () =>
      createUseCreateFieldExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const useCreateComputedFieldExecutable = useMemo(
    () =>
      createUseCreateComputedFieldExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const useUpdateFieldExecutable = useMemo(
    () =>
      createUseUpdateFieldExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const useUpdateMergeKeyExecutable = useMemo(
    () =>
      createUseUpdateMergeKeyExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const useUpdateFieldVisibilityExecutable = useMemo(
    () =>
      createUseUpdateFieldVisibilityExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const useUpdateFieldsOrderExecutable = useMemo(
    () =>
      createUseUpdateFieldsOrderExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const useMakeFieldsNullableExecutable = useMemo(
    () =>
      createUseMakeFieldsNullableExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const useDeleteFieldExecutable = useMemo(
    () =>
      createUseDeleteFieldExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const useRunSmartFieldExecutable = useMemo(
    () =>
      createUseRunSmartFieldExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const useTestFormulaExecutable = createUseTestFormulaExecutable({
    teamSlug,
    databaseId,
    tableSlug,
    fieldsLoadable,
  });

  const useUploadFileExecutable = useMemo(
    () =>
      createUseUploadFileExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const useActivateEmbeddingExecutable = useMemo(
    () =>
      createUseActivateEmbeddingExecutable({ teamSlug, databaseId, tableSlug }),
    [teamSlug, databaseId, tableSlug]
  );

  const useGenerateSqlFromPromptExecutable = useMemo(
    () =>
      createUseGenerateSqlFromPromptExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const useDownloadRecordsWithQueryConditionsExecutable = useMemo(
    () =>
      createUseDownloadCsvWithQueryConditionsExecutable({
        teamSlug,
        databaseId,
        tableSlug,
        filterConditions: queryConditions.filterConditions,
        sortConditions: queryConditions.sortConditions,
      }),
    [
      teamSlug,
      databaseId,
      tableSlug,
      queryConditions.filterConditions,
      queryConditions.sortConditions,
    ]
  );

  const useDownloadRecordsWithSqlExecutable = useMemo(
    () =>
      createUseDownloadCsvWithSqlExecutable({
        teamSlug,
        databaseId,
        sql: queryConditions.queryingSql,
      }),
    [teamSlug, databaseId, queryConditions.queryingSql]
  );

  const useGenerateSqlForComputedFieldFromPromptExecutable = useMemo(
    () =>
      createUseGenerateSqlForComputedFieldFromPromptExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const useStartSyncComputedFieldsExecutable = useMemo(
    () =>
      createUseStartSyncComputedFieldsExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const csvInsertProps = useCsvInsertContainer({
    tableSlug,
    teamSlug,
    databaseId,
  });

  return (
    <Source
      // loadables
      recordsLoadable={recordsLoadable}
      fieldsLoadable={fieldsLoadable}
      sourceFieldsLoadable={sourceFieldsLoadable}
      suggestedPromptsLoadable={suggestedPromptsLoadable}
      promptHistoriesLoadable={promptHistoriesLoadable}
      tablesLoadable={tablesLoadable}
      tableLoadable={tableLoadable}
      syncStatusLoadable={syncStatusLoadable}
      useFieldsLoadable={useFieldsLoadable}
      aggregateValueFieldOperatorsLoadable={
        aggregateValueFieldOperatorsLoadable
      }
      smartFunctionsLoadable={smartFunctionsLoadable}
      tableColumnSizingLoadable={tableColumnSizingLoadable}
      useDownloadCsvWithQueryPreviewRecordsLoadable={
        useDownloadCsvWithQueryPreviewRecordsLoadable
      }
      useDownloadCsvWithSqlPreviewRecordsLoadable={
        useDownloadCsvWithSqlPreviewRecordsLoadable
      }
      useRecordHistoriesLoadable={useRecordHistoriesLoadable}
      // ui state
      queryMode={queryMode}
      tableSelection={tableSelection}
      coworkersState={coworkersState}
      queryConditions={queryConditions}
      // on query conditions change
      onQueryModeChange={setQueryMode}
      onQueryConditionsChange={setQueryConditions}
      onTableSelectionChange={setTableSelection}
      onTableColumnSizingChange={onTableColumnSizingChange}
      // executables
      useCreateRecordExecutable={useCreateRecordExecutable}
      useCreateRecordsExecutable={useCreateRecordsExecutable}
      useGenerateRecordsWithPromptExecutable={
        useGenerateRecordsWithPromptExecutable
      }
      useUpdateRecordExecutable={useUpdateRecordExecutable}
      useDeleteRecordsExecutable={useDeleteRecordsExecutable}
      useCreateFieldExecutable={useCreateFieldExecutable}
      useCreateComputedFieldExecutable={useCreateComputedFieldExecutable}
      useUpdateFieldExecutable={useUpdateFieldExecutable}
      useUpdateFieldVisibilityExecutable={useUpdateFieldVisibilityExecutable}
      useUpdateFieldsOrderExecutable={useUpdateFieldsOrderExecutable}
      useMakeFieldsNullableExecutable={useMakeFieldsNullableExecutable}
      useDeleteFieldExecutable={useDeleteFieldExecutable}
      useRunSmartFieldExecutable={useRunSmartFieldExecutable}
      useTestFormulaExecutable={useTestFormulaExecutable}
      useUploadFileExecutable={useUploadFileExecutable}
      useActivateEmbeddingExecutable={useActivateEmbeddingExecutable}
      useBulkEditRecordsByRowExecutable={useBulkEditRecordsByRowExecutable}
      useBulkEditRecordsByFormulaExecutable={
        useBulkEditRecordsByFormulaExecutable
      }
      usePreviewBulkEditRecordsByFormulaExecutable={
        usePreviewBulkEditRecordsByFormulaExecutable
      }
      useGenerateFormulaExecutable={useGenerateFormulaExecutable}
      usePreviewBulkEditRecordsByPromptExecutable={
        usePreviewBulkEditRecordsByPromptExecutable
      }
      useBulkEditRecordsByPromptExecutable={
        useBulkEditRecordsByPromptExecutable
      }
      useGenerateSqlFromPromptExecutable={useGenerateSqlFromPromptExecutable}
      useGenerateSqlForComputedFieldFromPromptExecutable={
        useGenerateSqlForComputedFieldFromPromptExecutable
      }
      useDownloadRecordsWithQueryConditionsExecutable={
        useDownloadRecordsWithQueryConditionsExecutable
      }
      useDownloadRecordsWithSqlExecutable={useDownloadRecordsWithSqlExecutable}
      useStartSyncComputedFieldsExecutable={
        useStartSyncComputedFieldsExecutable
      }
      csvInsertProps={{
        ...csvInsertProps,
        fieldsLoadable,
      }}
    />
  );
};
