import {
  DashboardNotebookCellVariableRequestObject,
  RecordConditionRuleUnit,
  RecordFilterCondition,
  RecordFormatCondition,
  RecordSortConditionUnit,
} from "@usemorph/morph-dashboard-types";

type ViewDependencies = { teamSlug: string; databaseId: string };

type FindViewDependencies = { viewId: string } & ViewDependencies;

type ListViewsDependencies = ViewDependencies;

type ViewRecordDependencies = ViewDependencies & { viewId: string };

type QueryViewRecordsDependencies = ViewRecordDependencies & {
  select: string[];
  join?: RecordConditionRuleUnit[];
  filter?: RecordFilterCondition;
  format?: RecordFormatCondition[];
  sort?: RecordSortConditionUnit[];
  limit?: number;
  skip?: number;
  additionalFilter?: RecordFilterCondition;
  additionalSort?: RecordSortConditionUnit[];
  variables?: DashboardNotebookCellVariableRequestObject;
};

type QueryViewRecordsSqlDependencies = ViewRecordDependencies & {
  sql: string;
  limit?: number;
  skip?: number;
  variables?: DashboardNotebookCellVariableRequestObject;
};

type GroupQueryViewRecordsDependencies = ViewRecordDependencies & {
  select: string[];
  join?: RecordConditionRuleUnit[];
  filter?: RecordFilterCondition;
  format?: RecordFormatCondition[];
  sort?: RecordSortConditionUnit[];
  limit?: number;
  skip?: number;
  groupSelect?: string;
};

type GetViewFieldsDependencies = ViewRecordDependencies;

const viewKeys = {
  all: ({ teamSlug, databaseId }: ViewDependencies) =>
    [teamSlug, databaseId, "views"] as const,

  /**
   * find view
   */

  allFindViews: ({ teamSlug, databaseId }: ViewDependencies) =>
    [...viewKeys.all({ teamSlug, databaseId }), "findView"] as const,
  findView: ({ teamSlug, databaseId, viewId }: FindViewDependencies) =>
    [...viewKeys.allFindViews({ teamSlug, databaseId }), viewId] as const,

  /**
   * list views
   */
  allListViews: ({ teamSlug, databaseId }: ViewDependencies) =>
    [...viewKeys.all({ teamSlug, databaseId }), "listViews"] as const,
  listViews: ({ teamSlug, databaseId }: ListViewsDependencies) =>
    [...viewKeys.allListViews({ teamSlug, databaseId })] as const,

  /**
   * query view records
   */
  allQueryViewRecords: ({
    teamSlug,
    databaseId,
    viewId,
  }: ViewRecordDependencies) =>
    [
      ...viewKeys.all({ teamSlug, databaseId }),
      viewId,
      "queryViewRecords",
    ] as const,
  queryViewRecords: ({
    teamSlug,
    databaseId,
    viewId,
    select,
    join,
    filter,
    format,
    sort,
    limit,
    skip,
    additionalFilter,
    additionalSort,
    variables,
  }: QueryViewRecordsDependencies) =>
    [
      ...viewKeys.allQueryViewRecords({ teamSlug, databaseId, viewId }),
      viewId,
      {
        select,
        join,
        filter,
        format,
        sort,
        limit,
        skip,
        additionalFilter,
        additionalSort,
        variables,
      },
    ] as const,

  queryViewRecordsSql: ({
    teamSlug,
    databaseId,
    viewId,
    limit,
    skip,
    variables,
  }: QueryViewRecordsSqlDependencies) =>
    [
      ...viewKeys.allQueryViewRecords({ teamSlug, databaseId, viewId }),
      viewId,
      {
        limit,
        skip,
        variables,
      },
    ] as const,

  /**
   * group query view records
   */
  allGroupQueryViewRecords: ({
    teamSlug,
    databaseId,
    viewId,
  }: ViewRecordDependencies) =>
    [
      ...viewKeys.all({ teamSlug, databaseId }),
      viewId,
      "groupQueryViewRecords",
    ] as const,
  groupQueryViewRecords: ({
    teamSlug,
    databaseId,
    viewId,
    select,
    join,
    filter,
    format,
    sort,
    limit,
    skip,
    groupSelect,
  }: GroupQueryViewRecordsDependencies) =>
    [
      ...viewKeys.allGroupQueryViewRecords({ teamSlug, databaseId, viewId }),
      viewId,
      {
        select,
        join,
        filter,
        format,
        sort,
        limit,
        skip,
        groupSelect,
      },
    ] as const,

  /**
   * get view fields
   */
  allGetViewFields: ({ teamSlug, databaseId }: ViewDependencies) =>
    [...viewKeys.all({ teamSlug, databaseId }), "getViewFields"] as const,
  getViewFields: ({
    teamSlug,
    databaseId,
    viewId,
  }: GetViewFieldsDependencies) =>
    [...viewKeys.allGetViewFields({ teamSlug, databaseId }), viewId] as const,
};

export { viewKeys };
