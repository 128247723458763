import { RecordModel } from "~/features/RecordModel/types/RecordModelValue";
import { RecordsTableBaseRecord } from "../types";

export const isSameRecord = (
  record1: RecordsTableBaseRecord,
  record2: RecordsTableBaseRecord
) => {
  return record1._reservedRecordIndex === record2._reservedRecordIndex;
};

export const isSameRecordWithPrimaryKey = (
  record1: RecordsTableBaseRecord,
  record2: RecordsTableBaseRecord
) => {
  const record1PrimaryKeyValues = Object.keys(record1.values).reduce(
    (result, key) => {
      const value = record1.values[key];
      if (value.isPrimary) {
        result[key] = value;
      }
      return result;
    },
    {} as RecordModel
  );

  const record2PrimaryKeyValues = Object.keys(record2.values).reduce(
    (result, key) => {
      const value = record2.values[key];
      if (value.isPrimary) {
        result[key] = value;
      }
      return result;
    },
    {} as RecordModel
  );

  return Object.keys(record1PrimaryKeyValues).every((key) => {
    return (
      record1PrimaryKeyValues[key].value === record2PrimaryKeyValues[key].value
    );
  });
};
