import { z } from "zod";
import { FieldClientModel } from "~/clientModel/fields/field";
import { ValidationResultWithValue } from "./RecordEntryClientModelBase";

/**
 * Utility Schemas
 */

// zodのスキーマを受け取って、ValidationResultWithValueを返す
export const zValidate = <ValueType>(
  schema: z.ZodType<ValueType>,
  value: unknown
): ValidationResultWithValue<ValueType> => {
  const parsed = schema.safeParse(value);
  if (parsed.success) {
    return { isValid: true, value: parsed.data };
  } else {
    return {
      isValid: false,
      errorType: "type",
      value,
      errorMessages: parsed.error.errors.map((e) => e.message),
    };
  }
};

// 整数を表す文字列
export const zIntString = z.string().regex(/^[-]?\d+$/);

// 小数を表す文字列
export const zDecimalString = z.string().regex(/^[-]?\d+\.\d+$/);

export const zAllowNullIfNullable = (field: FieldClientModel) => {
  return field.isNullable ? z.null() : z.never();
};

export const zNumericString = z.union([zIntString, zDecimalString]);

export const zRequiredString = (field: FieldClientModel) => {
  // todo: 仕様相談(non nullable な shortText は空文字を許容するかどうか)
  return field.isNullable ? z.string() : z.string().min(1);
};
