
import { UseExecutable } from "~/clientModel/executable"
import { FieldsClientModel } from "~/clientModel/fields"
import { MessageClientModel } from "~/clientModel/general"
import { UseLoadable } from "~/clientModel/loadable/UseLoadable"
import { RssFeedItemsClientModel, RssTableMappingClientModel } from "~/clientModel/rssFeed"
import { Container } from "~/components_next/Container"
import { CreateUseListSampleRssFeedLoadableProps } from "~/containers/rssFeed/createUseListSampleRssFeedLoadable"
import { RssForm } from "~/features/RssFeed/RssForm"
import { UseDisclosureReturn } from "~/hooks/useDisclosure"
import { UseImportRssIntoTableMutationProps } from "~/serverStateStore/rssFeed"
import { UseUpdateRssTableMappingMutationProps } from "~/serverStateStore/rssFeed/mutations/useUpdateRssTableMappingMutation"
import { useTableSlug } from "~/utilHooks/useTableSlug"

interface RssFeedPresenterProps {
  useFindRssLinkedToTableLoadable: UseLoadable<void, RssTableMappingClientModel, unknown>
  useListSampleRssFeedLoadable: UseLoadable<CreateUseListSampleRssFeedLoadableProps, RssFeedItemsClientModel, unknown>
  useImportRssIntoTableExecutable: UseExecutable<void, UseImportRssIntoTableMutationProps, RssTableMappingClientModel, unknown>
  useFieldsLoadable: UseLoadable<{ tableSlug: string }, FieldsClientModel>;
  useUpdateRssTableMappingExecutable: UseExecutable<void, UseUpdateRssTableMappingMutationProps, RssTableMappingClientModel, unknown>
  useDeleteRssTableMappingExecutable: UseExecutable<void, void, MessageClientModel, unknown>
  deleteDialogDisclosure: UseDisclosureReturn;
}

export const RssFeedPresenter = (props: RssFeedPresenterProps) => {
  const {
    useFindRssLinkedToTableLoadable,
    useListSampleRssFeedLoadable,
    useImportRssIntoTableExecutable,
    useFieldsLoadable,
    useUpdateRssTableMappingExecutable,
    useDeleteRssTableMappingExecutable,
    deleteDialogDisclosure,
  } = props
  const tableSlug = useTableSlug()

  const findRssLinkedToTable = useFindRssLinkedToTableLoadable()
  const importRssIntoTableExecutable = useImportRssIntoTableExecutable()
  const fieldsLoadable = useFieldsLoadable({ tableSlug })
  const updateRssTableMappingExecutable = useUpdateRssTableMappingExecutable()
  const deleteRssTableMappingExecutable = useDeleteRssTableMappingExecutable()

  return (
    <>
      <Container p="5" size="3">
        <RssForm
          findRssLinkedToTable={findRssLinkedToTable}
          useListSampleRssFeedLoadable={useListSampleRssFeedLoadable}
          importRssIntoTableExecutable={importRssIntoTableExecutable}
          fieldsLoadable={fieldsLoadable}
          updateRssTableMappingExecutable={updateRssTableMappingExecutable}
          deleteRssTableMappingExecutable={deleteRssTableMappingExecutable}
          deleteDialogDisclosure={deleteDialogDisclosure}
        />
      </Container>
    </>
  )
}
