import { useQuery } from "react-query";
import {
  FieldsClientModel,
  FieldsClientModelFactory,
} from "~/clientModel/fields";
import { useComposeLoadable } from "~/clientModel/loadable";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";

import { useQueryViewRecordsSqlQuery } from "~/serverStateStore/views/queries/useQueryViewRecordsSqlQuery";

export const createUseFieldsWithSqlLoadable = (props: {
  teamSlug: string;
  databaseId: string;
}): UseLoadable<{ viewId: string; queryingSql: string }, FieldsClientModel> => {
  const { teamSlug, databaseId } = props;

  return ({ viewId, queryingSql }: { viewId: string; queryingSql: string }) =>
    useComposeLoadable(
      useQuery({
        ...useQueryViewRecordsSqlQuery({
          teamSlug,
          databaseId,
          viewId,
          sql: queryingSql,
        }),
        enabled: queryingSql !== "",
        select: (data) =>
          FieldsClientModelFactory.createFromSimpleFields(data.fields),
      })
    );
};
