import {
  DashboardNotebookCellSourceVariableObject,
  DashboardNotebookCellVariableRequestObject,
} from "@usemorph/morph-dashboard-types";
import constate from "constate";
import { useState } from "react";

/**
 * Variablesは、Providerをどこに置くかで値の共有スコープを変えられるので、共有パーツとしてここに置く
 */

export type FlipVariableValue = {
  name: string;
  value: DashboardNotebookCellSourceVariableObject["defaultValue"] | null;
};

const useFlipVariablesContext = () => {
  const [variableValues, setVariableValues] =
    useState<DashboardNotebookCellVariableRequestObject>([]);

  return {
    variableValues,
    setVariableValues,
  };
};

export const [
  FlipVariablesProvider,
  useFlipVariableValues,
  useFlipSetVariableValues,
] = constate(
  useFlipVariablesContext,
  (value) => value.variableValues,
  (value) => value.setVariableValues
);
