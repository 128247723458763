import { CanvasVisualizationPromptConfigCientModel } from "./CanvasVisualizationPromptConfigClientModel";

type CanvasVisualizationPromptConfigsDataType = {
  configs: CanvasVisualizationPromptConfigCientModel[];
};

export class CanvasVisualizationPromptConfigsCientModel {
  readonly #data: CanvasVisualizationPromptConfigsDataType;
  constructor(data: CanvasVisualizationPromptConfigsDataType) {
    this.#data = data;
  }

  get configs() {
    return this.#data.configs;
  }
}
