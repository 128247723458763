import { Flex } from "@radix-ui/themes";
import { styled } from "~/stitches";

const Wrapper = styled(Flex, {
  minHeight: "60px",
  backgroundColor: "$bg1",
  borderRadius: "$2",
  p: "$4",
});

const NotebookNotFoundUnit = () => {
  return (
    <Wrapper justify="center" align="center">
      There is no content to display.
    </Wrapper>
  );
};

export { NotebookNotFoundUnit };
