import { RecordValueInputCommonProps } from "../../type";
import { TimeInputBase } from "./common/TimeInputBase";
import { forwardRef, ForwardedRef } from "react";

export const TimeInput = forwardRef(function TimeInput(
  props: RecordValueInputCommonProps<"time">,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { value, onChange, errorMessages, isNullable, isReadOnly, size } =
    props;
  return (
    <TimeInputBase
      value={value}
      onChange={onChange}
      errorMessages={errorMessages}
      isNullable={isNullable}
      isReadOnly={isReadOnly}
      size={size}
      ref={ref}
    />
  );
});
