import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from "react";

// とりあえず素通しで作っておく
// todo: ローディング、フォールバック

type ImageProps = ComponentPropsWithoutRef<"img">;

const _Image = (props: ImageProps, ref?: ForwardedRef<HTMLImageElement>) => {
  return <img ref={ref} {...props}></img>;
};

const Image = forwardRef(_Image);

export { Image, type ImageProps };
