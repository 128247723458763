import { useCallback } from "react";
import { match, P } from "ts-pattern";
import { EditingRecordClientModelFactory } from "~/clientModel/records/editingRecord";
import { RecordClientModel } from "~/clientModel/records/record";
import { RecordsTableProps } from "../RecordsTable";

type RecordsTableBaseCellEventType = "click" | "shiftClick" | "goEdit";

export const useHandleCellClickEvent = (
  props: Pick<RecordsTableProps, "tableSelection" | "onTableSelectionChange">
) => {
  const { tableSelection, onTableSelectionChange } = props;

  const handleCellClickEvent = useCallback(
    (
      event: RecordsTableBaseCellEventType,
      record: RecordClientModel,
      fieldName: string
    ) => {
      if (!tableSelection || !onTableSelectionChange) {
        return;
      }

      match([event, tableSelection.taggedCellSelectionState.type])
        .with(["click", P._], () => {
          onTableSelectionChange(
            tableSelection.selectSingleCell({
              recordIdentifier: record.recordIdentifier,
              fieldName,
            })
          );
        })
        .with(["shiftClick", "none"], () => {
          // todo
        })
        .with(["shiftClick", "single"], () => {
          // todo
        })
        .with(["shiftClick", "multiple"], () => {
          // todo
        })
        .with(["goEdit", P._], () => {
          onTableSelectionChange(
            tableSelection.startCellEditing({
              editingRecord:
                EditingRecordClientModelFactory.createFromClientRecordModel(
                  record
                ),
              fieldName,
            })
          );
        })
        .run();
    },
    [onTableSelectionChange, tableSelection]
  );

  return handleCellClickEvent;
};
