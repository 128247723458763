import useApiTable from "~/api/useApiTable";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { tablesKeys } from "../keys";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseUpdateTableMutationParams {
  databaseId: string;
  teamSlug: string;
  tableSlug: string;
}

const useUpdateTableMutation = ({
  databaseId,
  teamSlug,
  tableSlug,
}: UseUpdateTableMutationParams) => {
  const { _updateTable } = useApiTable();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      displayName,
      tableType,
      isPrivate,
      comment,
    }: {
      displayName: string;
      tableType?: "readonly" | "readwrite" | "jointable" | undefined;
      isPrivate: boolean;
      comment?: string | undefined;
    }) => {
      return _updateTable({
        teamSlug,
        databaseId,
        tableSlug,
        displayName,
        tableType,
        isPrivate,
        comment,
      });
    },
    onSuccess: () => {
      const keys = tablesKeys.all({ databaseId, teamSlug });
      broadcast(keys);
      return invalidateAll(client, tablesKeys.all({ databaseId, teamSlug }));
    },
  };
};

export { useUpdateTableMutation };
