import { ForwardedRef, forwardRef, useMemo } from "react";
import { match } from "ts-pattern";
import { SimpleSelect } from "~/components_next/Select";
import { RecordValueInputCommonProps } from "../../type";

const boolStrings = ["true", "false", "null"] as const;

type BoolString = (typeof boolStrings)[number];

const isBoolStr = (value: unknown): value is BoolString => {
  return (
    typeof value === "string" &&
    (boolStrings as readonly string[]).includes(value)
  );
};

const stringify = (value: boolean | null): BoolString => {
  return match(value)
    .with(true, () => "true" as const)
    .with(false, () => "false" as const)
    .with(null, () => "null" as const)
    .exhaustive();
};

const getBoolValue = (value: BoolString): boolean | null => {
  return match(value)
    .with("true", () => true)
    .with("false", () => false)
    .with("null", () => null)
    .exhaustive();
};

const BooleanInput = forwardRef(function BooleanInput(
  props: RecordValueInputCommonProps<"boolean">,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const { value, onChange, errorMessages, isNullable, isReadOnly, size } =
    props;

  const options = useMemo(
    () =>
      isNullable
        ? [
            {
              label: "True",
              value: stringify(true),
            },
            {
              label: "False",
              value: stringify(false),
            },
            {
              label: "Null",
              value: stringify(null),
            },
          ]
        : [
            {
              label: "True",
              value: stringify(true),
            },
            {
              label: "False",
              value: stringify(false),
            },
          ],
    [isNullable]
  );

  return (
    <SimpleSelect
      variant="primary"
      size={size}
      options={options}
      value={stringify(value ?? null)}
      onChange={(newValue) => {
        onChange?.(isBoolStr(newValue) ? getBoolValue(newValue) : null);
      }}
      errorMessages={errorMessages}
      ref={ref}
    />
  );
});

export { BooleanInput };
