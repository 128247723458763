import { forwardRef } from "react";
import { Button } from "~/components_next/Button";

type SimilaritySearchButtonProps = {
  isActive: boolean;
};

const SimilaritySearchButton = forwardRef<
  HTMLButtonElement,
  SimilaritySearchButtonProps
>((props, ref) => {
  const { isActive, ...rest } = props;
  const buttonVariant = isActive ? "primary" : "tertiary";

  return (
    <Button ref={ref} variant={buttonVariant} size="xs" {...rest}>
      Similarity Search
    </Button>
  );
});

SimilaritySearchButton.displayName = "SimilaritySearchButton";

export { SimilaritySearchButton };
