import { useQueryClient } from "react-query";
import { match } from "ts-pattern";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useEventListener } from "../liveblocks.config";

const useBroadcastEventListener = () => {
  const client = useQueryClient();
  useEventListener(({ connectionId, event }) => {
    match(event.type)
      .with("INVALIDATE_QUERY", () => {
        event.value.forEach((queryKey: string[]) => {
          invalidateAll(client, queryKey);
        });
      })
      .otherwise(() => {
        return;
      });
  });
  return;
};

export { useBroadcastEventListener };
