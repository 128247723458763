import { useState } from "react";
import { CsvImportSchemaClientModel } from "~/clientModel/csvImport";
import { Executable } from "~/clientModel/executable";
import { Button } from "~/components_next/Button";
import { useErrorToast } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { SimpleSelect } from "~/components_next/Select";
import { Text } from "~/components_next/Text";

type CsvImportSheetSelectProps = {
  sheets: string[];
  createImportSchemaExecutable: Executable<
    {
      sheet?: number | undefined;
    },
    CsvImportSchemaClientModel,
    unknown
  >;
};

const CsvImportSheetSelect = (props: CsvImportSheetSelectProps) => {
  const { sheets, createImportSchemaExecutable } = props;

  const sheetOptions = sheets.map((sheet, i) => ({
    label: sheet,
    value: i,
  }));

  const [selectedSheetIndex, setSelectedSheetIndex] = useState<number | null>(
    null
  );

  const { errorToast } = useErrorToast({});

  const handleConfirm = () => {
    if (selectedSheetIndex === null) {
      errorToast(new Error("Sheet is not selected"));
      return;
    }
    createImportSchemaExecutable.execute({ sheet: selectedSheetIndex });
  };

  return (
    <>
      <Flex direction="column" gap="3" width="100%">
        <Text>Which Sheet do you import?</Text>
        <Flex align="center" width="100%" gap="2">
          <SimpleSelect
            variant="primary"
            size="sm"
            options={sheetOptions}
            value={selectedSheetIndex}
            onChange={(value) => setSelectedSheetIndex(value)}
            getValueStringFromValue={(value: number) => value.toString()}
          />
          <Button
            variant="primary"
            size="sm"
            onClick={handleConfirm}
            isDisabled={selectedSheetIndex === null}
            isLoading={createImportSchemaExecutable.isExecuting}
          >
            Confirm
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export { CsvImportSheetSelect, type CsvImportSheetSelectProps };
