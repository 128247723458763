import { useMemo } from "react";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { JoinTwoForm, JoinTwoFormProps } from "./JoinTwoForm";
import { FieldsClientModel } from "~/clientModel/fields";

type JoinSourceAndViewProps = {
  tableSlug: string;
  tableName: string;
  viewId: string;
  viewName: string;
  parentCells: CanvasCellClientModelUnion[];
  useSourceFieldsLoadable: UseLoadable<
    { tableSlug: string },
    FieldsClientModel
  >;
  useViewFieldsLoadable: UseLoadable<{ viewId: string }, FieldsClientModel>;
  useCreateCellExecutable: JoinTwoFormProps["useCreateCellExecutable"];
  onCompleteCreateCell?: () => void;
  isInverse?: boolean;
};

const JoinSourceAndView = (props: JoinSourceAndViewProps) => {
  const {
    tableSlug,
    tableName,
    viewId,
    viewName,
    parentCells,
    useSourceFieldsLoadable,
    useViewFieldsLoadable,
    useCreateCellExecutable,
    onCompleteCreateCell,
    isInverse,
  } = props;

  const tableFieldsLoadable = useSourceFieldsLoadable({ tableSlug });
  const viewFieldsLoadable = useViewFieldsLoadable({ viewId });

  const tableFieldsOptions = useMemo(() => {
    return (
      tableFieldsLoadable.data?.allFields.map((field) => {
        return {
          label: field.displayName || field.name,
          value: field.name,
        };
      }) || []
    );
  }, [tableFieldsLoadable]);

  const viewFieldsOptions = useMemo(() => {
    return (
      viewFieldsLoadable.data?.allFields.map((field) => {
        return {
          label: field.displayName || field.name,
          value: field.name,
        };
      }) || []
    );
  }, [viewFieldsLoadable]);

  return (
    <>
      <JoinTwoForm
        slug1={tableSlug}
        label1={tableName}
        options1={tableFieldsOptions}
        slug2={viewId}
        label2={viewName}
        options2={viewFieldsOptions}
        parentCells={parentCells}
        useCreateCellExecutable={useCreateCellExecutable}
        onCompleteCreateCell={onCompleteCreateCell}
        isInverse={isInverse}
      />
    </>
  );
};

export { JoinSourceAndView };
