import { DashboardNotebookCellSourceObject } from "@usemorph/morph-dashboard-types";
import { match } from "ts-pattern";
import { CanvasCellClientModelUnion } from "../CanvasCellClientModel";
import {
  CanvasCellDataType,
  CanvasCellPosition,
} from "../CanvasCellClientModel/CanvasCellClientModelBase";

export type CanvasCreateCellDataType = {
  parentCells: CanvasCellClientModelUnion[];
  cellType: CanvasCellDataType["cellType"];
  cellName: string;
  source: DashboardNotebookCellSourceObject;
  settings?: CanvasCellPosition; // 外部から決めてもいいし、なければparentCellsから決める
};

export class CanvasCreateCellClientModel {
  readonly #data: CanvasCreateCellDataType;
  constructor(data: CanvasCreateCellDataType) {
    this.#data = data;
  }

  private getCellSize(): Pick<CanvasCellPosition, "width" | "height"> {
    try {
      return match(this.#data.cellType)
        .with("source", () => ({
          width: 300,
          height: 80,
        }))
        .with("richText", () => ({
          width: 200,
          height: 80,
        }))
        .with("stickyNote", () => ({
          width: 240,
          height: 108,
        }))
        .with("image", () => ({
          width: 400,
          height: 400,
        }))
        .with("prompt", () => ({
          width: 600,
          height: 600,
        }))
        .with("variables", () => ({
          width: 400,
          height: 200,
        }))
        .with("view", () => ({
          width: 600,
          height: 600,
        }))
        .exhaustive();
    } catch {
      return {
        width: 300,
        height: 300,
      };
    }
  }

  get position(): CanvasCellPosition {
    if (this.#data.settings) {
      return this.#data.settings;
    }

    const maxY = this.#data.parentCells.reduce((acc, cell) => {
      return Math.max(
        acc,
        (cell.settings.y || 0) + (cell.settings.height || 0)
      );
    }, 0);
    const averageX =
      this.#data.parentCells.reduce((acc, cell) => {
        return acc + (cell.settings.x || 0);
      }, 0) / this.#data.parentCells.length;

    return {
      x: averageX,
      y: maxY + 100,
      ...this.getCellSize(),
    };
  }

  get requestBody() {
    return {
      cellType: this.#data.cellType,
      cellName: this.#data.cellName,
      source: this.#data.source,
      settings: {
        ...this.position,
      },
      parentIds: this.#data.parentCells.map((cell) => cell.cellId),
    };
  }
}
