import { useSidebarDisclosure } from "../../common/components/sidebars/common/sidebarState/useSidebarDisclosure";
import { SourceQueryConditions, SourceQueryMode } from "../types";
import { CreateViewButton } from "./CreateViewButton";

type CreateViewWithSqlButtonProps = {
  queryMode: SourceQueryMode;
  queryConditions: SourceQueryConditions;
};

const CreateViewWithSqlButton = (props: CreateViewWithSqlButtonProps) => {
  const { queryMode, queryConditions } = props;

  const { onOpen } = useSidebarDisclosure();

  const isDisplayed =
    (queryMode === "sql" || queryMode === "prompt") &&
    !!queryConditions.queryingSql;

  if (!isDisplayed) return null;

  return <CreateViewButton onClick={() => onOpen("createViewWithSql")} />;
};

export { CreateViewWithSqlButton };
