import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { SimpleSelect } from "~/components_next/Select";
import {
  useListSimpleFieldsQuery,
  useListTablesQuery,
} from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

const VariablesFieldOptions = (props: {
  tableSlug: string;
  onChange: (value: string | null) => void;
  value: string | null;
}) => {
  const { tableSlug, onChange, value } = props;
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const { data: simpleFields } = useQuery({
    ...useListSimpleFieldsQuery({
      teamSlug,
      databaseId,
      tableSlug,
    }),
  });

  const fieldsOptions = useMemo(() => {
    return (
      simpleFields?.fields
        .filter((f) => f.type === "singleSelect" || f.type === "multiSelect")
        .map((field) => ({
          label: field.displayName || field.name,
          value: field.name,
        })) || []
    );
  }, [simpleFields]);

  return (
    <>
      <SimpleSelect
        description="Field"
        variant="primary"
        options={fieldsOptions}
        onChange={(selected) => onChange(selected)}
        value={value}
        size="xs"
      />
    </>
  );
};

type VariablesSourceFieldOptionsProps = {
  tableSlug?: string | null;
  field?: string | null;
  onChange: ({
    tableSlug,
    field,
  }: {
    tableSlug: string;
    field: string;
  }) => void;
};

const VariableSourceFieldOptions = (
  props: VariablesSourceFieldOptionsProps
) => {
  const { tableSlug, field, onChange } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const [selectedSource, setSelectedSource] = useState<string | null>(
    tableSlug || null
  );
  const [selectedField, setSelectedField] = useState<string | null>(
    field || null
  );

  useEffect(() => {
    if (!selectedSource || !selectedField) {
      return;
    }
    onChange({ tableSlug: selectedSource, field: selectedField });
  }, [selectedSource, selectedField]);

  const { data: sources } = useQuery(
    useListTablesQuery({
      teamSlug,
      databaseId,
    })
  );

  const sourceOptions = useMemo(() => {
    return (
      sources?.tables.map((table) => ({
        label: table.displayName || table.tableSlug,
        value: table.tableSlug,
      })) || []
    );
  }, [sources]);

  return (
    <>
      <SimpleSelect
        description="Source"
        variant="primary"
        options={sourceOptions}
        onChange={(selected) => setSelectedSource(selected)}
        value={selectedSource}
        size="xs"
      />

      {selectedSource && (
        <VariablesFieldOptions
          tableSlug={selectedSource}
          onChange={(selected) => setSelectedField(selected)}
          value={selectedField}
        />
      )}
    </>
  );
};

export { VariableSourceFieldOptions };
