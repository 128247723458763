import { match, P } from "ts-pattern";

type ScheduledTimeClientModelData = {
  scheduledMins: number;
};

type Hour = {
  value: number; // 1, 2, ... , 23, 0
  label: `${number} AM` | `${number} PM`; // AM1, AM2, ... , PM11, PM12
};

type Minutes = {
  value: number;
  label: string;
};

export class ScheduledTimeClientModel {
  readonly #data: ScheduledTimeClientModelData;

  constructor(data: ScheduledTimeClientModelData) {
    this.#data = data;
  }

  public get data(): ScheduledTimeClientModelData {
    return this.#data;
  }

  public get hoursOptions(): readonly Hour[] {
    const options = [...new Array(24)].map((_, i) =>
      ScheduledTimeClientModel.getHour(i * 60)
    );
    return [...options.slice(1), options[0]];
  }

  public get minutesOptions(): readonly Minutes[] {
    return [...new Array(4)].map((_, i) =>
      ScheduledTimeClientModel.getMinutes(i * 15)
    );
  }

  public updateHours(hoursValue: number): ScheduledTimeClientModel {
    const diffMins = (hoursValue - this.hour.value) * 60;
    return new ScheduledTimeClientModel({
      scheduledMins: this.#data.scheduledMins + diffMins,
    });
  }

  public updateMinutes(minutesValue: number): ScheduledTimeClientModel {
    const diffMins = minutesValue - this.minute.value;
    return new ScheduledTimeClientModel({
      scheduledMins: this.#data.scheduledMins + diffMins,
    });
  }

  public get hour(): Hour {
    return ScheduledTimeClientModel.getHour(this.#data.scheduledMins);
  }

  public get minute(): Minutes {
    return ScheduledTimeClientModel.getMinutes(this.#data.scheduledMins);
  }

  private static getHour(mins: number): Hour {
    const hours = Math.floor(mins / 60);

    if (1 <= hours && hours <= 12) {
      // AM
      return {
        value: hours,
        label: `${hours} AM`,
      };
    }

    // 12 PM
    if (hours === 0) {
      return {
        value: 0,
        label: `${12} PM`,
      };
    }

    // PM(PM 12以外)
    if (13 <= hours && hours <= 23) {
      return {
        value: hours,
        label: `${hours - 12} PM`,
      };
    }

    throw new Error("scheduledMins should be between 0 and 1439");
  }

  private static getMinutes(mins: number): Minutes {
    return match(mins % 60)
      .with(0, () => ({ value: 0, label: "00" } as const))
      .with(
        P.union(15, 30, 45),
        (mins) => ({ value: mins, label: `${mins}` } as const)
      )
      .otherwise(() => {
        throw new Error("scheduledMins should be a multiple of 15.");
      });
  }
}
