import { templateKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiTemplate from "~/api/useApiTemplate";

interface UseFindTemplateDetailQuery {
  templateId: string;
  teamSlug: string;
}

const useFindTemplateDetailQuery = ({ templateId, teamSlug }: UseFindTemplateDetailQuery) => {
  const { _findTemplateDetail } = useApiTemplate();

  const client = useQueryClient();
  client.setQueryDefaults(
    templateKeys.findTemplateDetail({ templateId, teamSlug }),
    commonQueryOptions
  );

  return {
    queryKey: templateKeys.findTemplateDetail({ templateId, teamSlug }),
    queryFn: () => {
      return _findTemplateDetail({ templateId, teamSlug })
    },
  };
};

export { useFindTemplateDetailQuery };
