import { DownloadButton } from "../../../common/components/atoms/DownloadButton";
import { useView } from "~/features/SourceAndViews/common/utils/useView";
import { getViewConditionType } from "~/features/View";
import { useViewRecords } from "../../hooks/useViewRecords";
import { useQueryMode } from "../../states/queryMode";
import { useViewId } from "~/utilHooks/useViewId";
import { ViewWithSQLDownloadRecordsWithSQLButton } from "./ViewWithSQLDownloadRecordsWithSQLButton";
import { ViewWithQueryConditionsDownloadRecordsWithSQLButton } from "./ViewWithQueryConditionsDownloadRecordsWithSQLButton";
import { ViewWithQueryConditionsDownloadRecordsWithQueryButton } from "./ViewWithQueryConditionsDownloadRecordsWithQueryButton";

const DownloadRecordsButton = () => {
  const { data: viewData } = useView();
  const { status } = useViewRecords();
  const viewId = useViewId();

  const viewConditionType = viewData
    ? getViewConditionType(viewData)
    : undefined;

  const queryMode = useQueryMode(viewId);

  return (
    <>
      {viewData && status === "success" ? (
        <>
          {/* View with Query Conditions */}
          {viewConditionType === "query" &&
            (queryMode === "prompt" || queryMode === "sql") && (
              <ViewWithQueryConditionsDownloadRecordsWithSQLButton
                view={viewData}
              />
            )}
          {viewConditionType === "query" &&
            (!queryMode || queryMode === "filtersAndSorts") && (
              <ViewWithQueryConditionsDownloadRecordsWithQueryButton
                view={viewData}
              />
            )}

          {/* View with SQL */}
          {viewConditionType === "sql" && queryMode !== "filtersAndSorts" && (
            <ViewWithSQLDownloadRecordsWithSQLButton view={viewData} />
          )}
        </>
      ) : (
        <DownloadButton isDisabled={true} />
      )}
    </>
  );
};

export { DownloadRecordsButton };
