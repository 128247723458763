import { DashboardNotebookCellVariableRequestObject } from "@usemorph/morph-dashboard-types";
import { useQuery } from "react-query";
import { useComposeLoadable } from "~/clientModel/loadable";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { CanvasVariablesValue } from "~/presenters/canvas/common/CanvasVariablesProvider";
import { useGetPublicVariableViewResultOptionsQuery } from "~/serverStateStore";

export const createUsePublicVariableOptionsLoadable = (props: {
  teamSlug: string;
  apiKey: string;
}): UseLoadable<
  { cellId: string; name: string; variables: CanvasVariablesValue[] },
  {
    options: Array<{ label: string; value?: unknown }>;
    count: number;
  }
> => {
  const { teamSlug, apiKey } = props;

  return ({
    cellId,
    name,
    skip,
    limit,
    variables,
  }: {
    cellId: string;
    name: string;
    skip?: number;
    limit?: number;
    variables?: CanvasVariablesValue[];
  }) => {
    return useComposeLoadable(
      useQuery({
        ...useGetPublicVariableViewResultOptionsQuery({
          teamSlug,
          apiKey,
          cellId,
          name,
          skip,
          limit,
          variables: variables as DashboardNotebookCellVariableRequestObject, // 型パズルエグすぎて一旦キャスト
        }),
      })
    );
  };
};
