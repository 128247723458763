import { DashboardRegisterDataApiRequestBody } from "@usemorph/morph-dashboard-types";
import { useMemo } from "react";
import { match } from "ts-pattern";
import { Editor } from "@monaco-editor/react";

type SampleCodeProps = {
  body: DashboardRegisterDataApiRequestBody;
  url: string;
};

const SampleCode = (props: SampleCodeProps) => {
  const { body, url } = props;

  const cUrl = useMemo(() => {
    return match(body)
      .with(
        {
          apiType: "query",
        },
        () => {
          return `curl ${url} -X POST -H "Content-Type: application/json" -H "X-API-KEY: ${body.apiKey
            }" -d '${JSON.stringify(body.query)}'`;
        }
      )
      .with(
        {
          apiType: "create",
        },
        () => {
          return `curl ${url} -X POST -H "Content-Type: application/json" -H "X-API-KEY: ${body.apiKey
            }" -d '${JSON.stringify(body.create)}'`;
        }
      )
      .with(
        {
          apiType: "update",
        },
        () => {
          return `curl ${url} -X POST -H "Content-Type: application/json" -H "X-API-KEY: ${body.apiKey
            }" -d '${JSON.stringify(body.update)}'`;
        }
      )
      .with(
        {
          apiType: "delete",
        },
        () => {
          return `curl ${url} -X POST -H "Content-Type: application/json" -H "X-API-KEY: ${body.apiKey
            }" -d '${JSON.stringify(body.delete)}'`;
        }
      )
      .with(
        {
          apiType: "upload",
        },
        () => {
          return `curl ${url} -X POST -H "Content-Type: multipart/form-data" -H "X-API-KEY: ${body.apiKey
            }" -d '${JSON.stringify(body.upload)}'`;
        }
      )
      .otherwise(() => "");
  }, [body, url]);

  return (
    <Editor
      width={"100%"}
      height={"calc(100vh - 160px)"}
      language={"bash"}
      theme={"vs-dark"}
      value={cUrl}
      options={{
        readOnly: true,
        wordWrap: "on",
        minimap: {
          enabled: false,
        },
        padding: {
          top: 20,
        },
        scrollBeyondLastLine: false,
      }}
    />
  );
};

export { SampleCode };
