import { ChangeEvent, ComponentPropsWithoutRef, useRef } from "react";
import { IconButton, IconButtonProps } from "~/components_next/IconButton";
import { RichTextIcon } from "~/components_next/Icons";
import { capitalize } from "~/utils/stringUtils";
import { FontColorDisplayBox } from "../components/FontColorDisplayBox";
import { LinkForm } from "../components/LinkForm";
import { useBlockquote } from "../../hooks/blockTypes/useBlockquote";
import { useBulletList } from "../../hooks/blockTypes/useBulletList";
import { useCodeBlock } from "../../hooks/blockTypes/useCodeBlock";
import { useHeading } from "../../hooks/blockTypes/useHeading";
import { useHorizontalRule } from "../../hooks/blockTypes/useHorizontalRule";
import { useImage } from "../../hooks/blockTypes/useImage";
import { useOrderedList } from "../../hooks/blockTypes/useOrderedList";
import { useRedo } from "../../hooks/other/useRedo";
import { useUndo } from "../../hooks/other/useUndo";
import { useBold } from "../../hooks/textStyles/useBold";
import { useClearFormat } from "../../hooks/textStyles/useClearFormat";
import { useCode } from "../../hooks/textStyles/useCode";
import { useFontColor } from "../../hooks/textStyles/useFontColor";
import { useHighlight } from "../../hooks/textStyles/useHighlight";
import { useItalic } from "../../hooks/textStyles/useItalic";
import { useLink } from "../../hooks/textStyles/useLink";
import { useStrike } from "../../hooks/textStyles/useStrike";
import { useSubscript } from "../../hooks/textStyles/useSubscript";
import { useSuperscript } from "../../hooks/textStyles/useSuperscript";
import { useTextAlign } from "../../hooks/textStyles/useTextAlign";
import { useUnderline } from "../../hooks/textStyles/useUnderline";
import { useFocusEditor } from "../../hooks/utils";
import { Popover } from "~/components_next/Popover";
import { Box } from "@radix-ui/themes";

/**
 * Common Button
 */

type MenuBarIconButtonProps = Pick<
  ComponentPropsWithoutRef<typeof RichTextIcon>,
  "type" | "isActive"
> &
  Pick<IconButtonProps, "tooltip" | "onClick" | "isDisabled">;

const MenuBarIconButton = ({
  type,
  isActive,
  isDisabled,
  tooltip,
  onClick,
}: MenuBarIconButtonProps) => {
  return (
    <IconButton
      tooltip={tooltip}
      size="sm"
      isDisabled={isDisabled}
      onClick={onClick}
      icon={<RichTextIcon size="lg" type={type} isActive={isActive} />}
    />
  );
};

/**
 * Block Type
 */
export const HeadingButton = ({ level }: { level: 1 | 2 | 3 | 4 | 5 | 6 }) => {
  const { isActive, isDisabled, handleToggle } = useHeading({ level });

  return (
    <MenuBarIconButton
      tooltip={`Heading ${level}`}
      isDisabled={isDisabled}
      onClick={handleToggle}
      isActive={isActive}
      type={`heading${level}`}
    />
  );
};

export const CodeBlockButton = () => {
  const { isActive, isDisabled, handleToggle } = useCodeBlock();

  return (
    <MenuBarIconButton
      tooltip="Code Block"
      isDisabled={isDisabled}
      onClick={handleToggle}
      isActive={isActive}
      type="code"
    />
  );
};

export const OrderedListButton = () => {
  const { isActive, isDisabled, handleToggle } = useOrderedList();

  return (
    <MenuBarIconButton
      tooltip="Ordered List"
      isDisabled={isDisabled}
      onClick={handleToggle}
      type="orderedList"
      isActive={isActive}
    />
  );
};

export const BulletListButton = () => {
  const { isActive, isDisabled, handleToggle } = useBulletList();

  return (
    <MenuBarIconButton
      tooltip="Bullet List"
      onClick={handleToggle}
      type="bulletList"
      isActive={isActive}
    />
  );
};

export const BlockQuoteButton = () => {
  const { isActive, isDisabled, handleToggle } = useBlockquote();

  return (
    <MenuBarIconButton
      tooltip="Block Quote"
      isDisabled={isDisabled}
      onClick={handleToggle}
      type="quote"
      isActive={isActive}
    />
  );
};

export const HorizontalRuleButton = () => {
  const { isActive, isDisabled, handleSet } = useHorizontalRule();

  return (
    <MenuBarIconButton
      tooltip="Horizontal Rule"
      isDisabled={isDisabled}
      onClick={handleSet}
      type="horizontalRule"
      isActive={isActive}
    />
  );
};

export const ImageButton = () => {
  const { isActive, isDisabled, handleSet } = useImage();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    files && handleSet(Array.from(files));
  };

  return (
    <>
      <MenuBarIconButton
        tooltip="Image"
        isDisabled={isDisabled}
        onClick={() => inputRef.current?.click()}
        type="image"
        isActive={isActive}
      />

      <input
        aria-label="Upload Image"
        ref={inputRef}
        type="file"
        style={{
          visibility: "hidden",
          width: 0,
          height: 0,
          position: "absolute",
        }}
        onChange={handleChange}
      />
    </>
  );
};

/**
 * Text Format
 */

export const BoldButton = () => {
  const { isActive, isDisabled, handleToggle } = useBold();

  return (
    <MenuBarIconButton
      tooltip="Bold"
      isDisabled={isDisabled}
      onClick={handleToggle}
      type="bold"
      isActive={isActive}
    />
  );
};

export const ItalicButton = () => {
  const { isActive, isDisabled, handleToggle } = useItalic();

  return (
    <MenuBarIconButton
      tooltip="Italic"
      isDisabled={isDisabled}
      onClick={handleToggle}
      type="italic"
      isActive={isActive}
    />
  );
};

export const UnderlineButton = () => {
  const { isActive, isDisabled, handleToggle } = useUnderline();

  return (
    <MenuBarIconButton
      tooltip="Underline"
      isDisabled={isDisabled}
      onClick={handleToggle}
      type="underline"
      isActive={isActive}
    />
  );
};

export const StrikeButton = () => {
  const { isActive, isDisabled, handleToggle } = useStrike();

  return (
    <MenuBarIconButton
      tooltip="Strike"
      isDisabled={isDisabled}
      onClick={handleToggle}
      type="strikethrough"
      isActive={isActive}
    />
  );
};

export const SuperscriptButton = () => {
  const { isActive, isDisabled, handleToggle } = useSuperscript();

  return (
    <MenuBarIconButton
      tooltip="Superscript"
      isDisabled={isDisabled}
      onClick={handleToggle}
      type="superscript"
      isActive={isActive}
    />
  );
};

export const SubscriptButton = () => {
  const { isActive, isDisabled, handleToggle } = useSubscript();

  return (
    <MenuBarIconButton
      tooltip="Subscript"
      isDisabled={isDisabled}
      onClick={handleToggle}
      type="subscript"
      isActive={isActive}
    />
  );
};

export const CodeButton = () => {
  const { isActive, isDisabled, handleToggle } = useCode();

  return (
    <MenuBarIconButton
      tooltip="Inline Code"
      isDisabled={isDisabled}
      onClick={handleToggle}
      type="code"
      isActive={isActive}
    />
  );
};

export const HighlightButton = () => {
  const { isActive, isDisabled, handleToggle } = useHighlight();

  return (
    <MenuBarIconButton
      tooltip="Highlight"
      isDisabled={isDisabled}
      onClick={handleToggle}
      type="highlighter"
      isActive={isActive}
    />
  );
};

export const ClearFormatButton = () => {
  const { isDisabled, handleClear } = useClearFormat();

  return (
    <MenuBarIconButton
      tooltip="Clear Format"
      isDisabled={isDisabled}
      onClick={handleClear}
      isActive={false}
      type="clearFormat"
    />
  );
};

export const FontColorButton = () => {
  const { currentColor, isDisabled, handleSet } = useFontColor();

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <IconButton
        tooltip="Font Color"
        size="sm"
        icon={<FontColorDisplayBox color={currentColor} size="3" />}
        isDisabled={isDisabled}
        onClick={() => inputRef.current?.click()}
      />

      <input
        aria-label="Set Text Color"
        ref={inputRef}
        type="color"
        style={{
          visibility: "hidden",
          height: 0,
          width: 0,
          border: 0,
          position: "absolute",
        }}
        color={currentColor}
        onChange={(event) => handleSet(event.target.value)}
      />
    </>
  );
};

export const TextAlignButton = ({
  align,
}: {
  align: "left" | "center" | "right";
}) => {
  const { isActive, isDisabled, handleToggle } = useTextAlign({ align });

  const capitalizedAlign = capitalize(align);

  return (
    <MenuBarIconButton
      tooltip={`Text Align ${capitalizedAlign}`}
      isDisabled={isDisabled}
      onClick={handleToggle}
      isActive={isActive}
      type={`align${capitalizedAlign}`}
    />
  );
};

export const LinkButton = () => {
  const {
    isActive,
    isToggleDisabled,
    isSetDisabled,
    currentValue,
    handleSet,
    handleUnset,
  } = useLink();

  const focus = useFocusEditor();

  return (
    <Popover
      trigger={
        <Box>
          <MenuBarIconButton
            tooltip="Link"
            isDisabled={isToggleDisabled && isSetDisabled}
            type="link"
            isActive={isActive}
          />
        </Box>
      }
    >
      <LinkForm
        initialValue={currentValue}
        onSave={handleSet}
        onClear={handleUnset}
      />
    </Popover>
  );
};

/**
 * Redo / Undo
 */

export const UndoButton = () => {
  const { isDisabled, handleUndo } = useUndo();

  return (
    <MenuBarIconButton
      tooltip="Undo"
      isDisabled={isDisabled}
      onClick={handleUndo}
      isActive={false}
      type="undo"
    />
  );
};

export const RedoButton = () => {
  const { isDisabled, handleRedo } = useRedo();

  return (
    <MenuBarIconButton
      tooltip="Redo"
      isDisabled={isDisabled}
      onClick={handleRedo}
      isActive={false}
      type="redo"
    />
  );
};
