import { SimpleField } from "@usemorph/morph-dashboard-types";
import { MergeKeyClientModel } from "./MergeKeyClientModel";

export class MergeKeyClientModelDefactory {
  public static toSimpleFieldMergeSource(
    mergeKey: MergeKeyClientModel
  ): SimpleField["mergeSource"] {
    return mergeKey.data.targets.map(({ tableSlug, fieldName }) => ({
      tableSlug,
      fieldName,
    }));
  }
}
