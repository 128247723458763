import { DashboardComputedFieldOptionListResponse } from "@usemorph/morph-dashboard-types";
import { AggregateValueFieldOperatorClientModel } from "./AggregateValueFieldOperatorClientModel";

export class AggregateValueFieldOperatorClientModelFactory {
  public static createFromDashboardComputedFieldOptionListResponseItem(
    item: DashboardComputedFieldOptionListResponse["items"][number]
  ): AggregateValueFieldOperatorClientModel {
    return new AggregateValueFieldOperatorClientModel({
      label: item.label,
      value: item.value,
    });
  }
}
