// types
import { DashboardCreateImportIntegrationScheduleRequestBody } from "@usemorph/morph-dashboard-types";
import { useQueryClient } from "react-query";
import useApiImport from "~/api/useApiImport";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { importsKeys } from "../keys";

interface UseCreateImportIntegrationScheduleMutationParams {
  databaseId: string;
  teamSlug: string;
}

const useCreateImportIntegrationScheduleMutation = ({
  teamSlug,
  databaseId,
}: UseCreateImportIntegrationScheduleMutationParams) => {
  const { _createImportIntegrationSchedule } = useApiImport();

  const client = useQueryClient();

  return {
    mutationFn: ({
      body,
    }: {
      body: DashboardCreateImportIntegrationScheduleRequestBody;
    }) => {
      return _createImportIntegrationSchedule({
        teamSlug,
        databaseId,
        body,
      });
    },
    onSuccess: () => {
      return invalidateAll(client, importsKeys.all({ teamSlug, databaseId }));
    },
  };
};

export { useCreateImportIntegrationScheduleMutation };
