const coworkerBorderVariants = {
  teal: {
    outlineStyle: "solid",
    outlineWidth: "2px",
    outlineColor: "$teal7",
  },
  crimson: {
    outlineStyle: "solid",
    outlineWidth: "2px",
    outlineColor: "$crimson7",
  },
  purple: {
    outlineStyle: "solid",
    outlineWidth: "2px",
    outlineColor: "$purple7",
  },
  blue: {
    outlineStyle: "solid",
    outlineWidth: "2px",
    outlineColor: "$blue7",
  },
  green: {
    outlineStyle: "solid",
    outlineWidth: "2px",
    outlineColor: "$green7",
  },
  amber: {
    outlineStyle: "solid",
    outlineWidth: "2px",
    outlineColor: "$amber7",
  },
};

type CollaboratorColorScheme =
  | "teal"
  | "crimson"
  | "purple"
  | "blue"
  | "green"
  | "amber";

const CollaboratorColorSchemes: CollaboratorColorScheme[] = [
  "teal",
  "crimson",
  "purple",
  "blue",
  "green",
  "amber",
];

export {
  type CollaboratorColorScheme,
  CollaboratorColorSchemes,
  coworkerBorderVariants,
};
