type GetTeamDependencies = { teamSlug: string };

const teamsKeys = {
  all: () => ["teams"] as const,

  /**
   * get team
   */
  allGetTeam: () => [...teamsKeys.all(), "getTeam"] as const,
  getTeam: ({ teamSlug }: GetTeamDependencies) =>
    [...teamsKeys.allGetTeam(), teamSlug] as const,
};

export { teamsKeys };
