import { notebookKeys } from "../../notebook/keys";
import { useQueryClient } from "react-query";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import useApiPage from "~/api/useApiPage";
import { pageKeys } from "../keys";

interface UseUpdateNotebookPageMutationParams {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
}

type UpdateParams = {
  pageId: string;
  pageName: string;
  description?: string | null;
  isPrivate?: boolean;
  isPublic?: boolean;
  publicApiKeyType?: string;
};

const useUpdateNotebookPageMutaiton = ({
  databaseId,
  teamSlug,
  notebookId,
}: UseUpdateNotebookPageMutationParams) => {
  const { _updatePage } = useApiPage();

  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      pageId,
      pageName,
      description,
      isPrivate,
      isPublic,
      publicApiKeyType,
    }: UpdateParams) => {
      return _updatePage({
        teamSlug,
        pageId,
        pageName,
        description,
        isPrivate,
        isPublic,
        publicApiKeyType,
      });
    },
    onSuccess: (data: unknown, variable: UpdateParams) => {
      const _notebookKeys = notebookKeys.findNotebook({
        databaseId,
        teamSlug,
        notebookId,
      });

      const _pageKeys = pageKeys.findPageDetail({
        teamSlug,
        databaseId,
        notebookId,
        pageId: variable.pageId,
      });

      broadcast(_notebookKeys);
      broadcast(_pageKeys);

      return invalidateAll(client, _notebookKeys, _pageKeys);
    },
  };
};

export { useUpdateNotebookPageMutaiton };
