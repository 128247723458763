import { ComponentPropsWithRef, ReactNode } from "react";
import { Flex } from "~/components_next/Flex";

type TableCompositeLayoutProps = {
  style?: ComponentPropsWithRef<typeof Flex>["style"];
  children: ReactNode;
};

export const TableCompositeLayout = (props: TableCompositeLayoutProps) => {
  const { children, style } = props;

  return (
    <Flex
      direction="column"
      px="3"
      py="3"
      gap="2"
      style={{ ...style }}
      position="relative"
    >
      {children}
    </Flex>
  );
};
