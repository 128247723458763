import { useMemo } from "react";
import { useQuery } from "react-query";
import { matchPath, useNavigate } from "react-router-dom";
// import { useMorphColors } from "~/components_next/hooks";
import { useListDatabasesQuery } from "~/serverStateStore/databases";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";


import {
  useGetUserColor,
  useOthersDecoded,
} from "~/features/RealtimeCollaboration";
import { UserAvatar } from "~/components_next/User";
import {
  useGlobalNavigationUIState,
  useSetGlobalNavigationUIState,
} from "../../states/globalNavigationUIState";
import { getPath, routes } from "~/routing";
import { Text } from "~/components_next/Text";
import { Flex } from "~/components_next/Flex";
import { Box } from "~/components_next/Box";
import { SimpleSelect } from "~/components_next/Select";

type DatabaseOption = {
  value: string;
  label: string;
};

const DatabaseSelect = () => {
  const databaseId = useDatabaseId();
  const teamSlug = useTeamSlug();

  const { data: listDatabase } = useQuery({
    ...useListDatabasesQuery({
      teamSlug,
      skip: 0,
      limit: 99999,
    }),
  });

  const selectedDb = useMemo(() => {
    const findDb = listDatabase?.items.find(
      (db) => db.databaseId === databaseId
    );
    if (findDb) {
      return {
        value: findDb.databaseId,
        label: findDb.databaseName,
      };
    }
    return undefined;
  }, [databaseId, listDatabase]);

  const navigate = useNavigate();

  const handleDbChange = (databaseId: string) => {
    navigate(getPath("databaseHome", { teamSlug, databaseId }));
  };

  // const { bg0, textSupplementary } = useMorphColors();

  const { othersDecoded } = useOthersDecoded();
  const getUserColor = useGetUserColor();

  const formatOptionLabel = (data: DatabaseOption) => {
    const othresOnThisDb = othersDecoded.filter((other) =>
      matchPath(routes.databaseHome, other.presence.path)
    );

    const isSelected = databaseId === data.value;

    return (
      <Flex align="center" gap="1">
        <Box grow="1" css={{ width: "360px" }}>
          <Text
          // size="sm"
          // textOverflow="ellipsis"
          // overflow="hidden"
          // whiteSpace="nowrap"
          >
            {data.label}
          </Text>
        </Box>
        {othresOnThisDb.length > 0 && !isSelected && (
          <Flex ml="3">
            {othresOnThisDb.map((other) => (
              <Box key={other.presence.user.userId} width="1">
                <UserAvatar
                  user={other.presence.user}
                  size="xs"
                // highlightColor={getUserColor(other.presence.user.userId)}
                />
              </Box>
            ))}
          </Flex>
        )}
      </Flex>
    );
  };

  /**
   * UI
   */
  const globalNavigationUIState = useGlobalNavigationUIState();
  const setGlobalNavigationUIState = useSetGlobalNavigationUIState();

  return (
    <Box css={{ backgroundColor: "$bg0" }}>
      {
        listDatabase && (
          <SimpleSelect
            variant="primary"
            size="sm"
            value={databaseId}
            options={[...listDatabase.items.map(db => {
              return { label: db.databaseName, value: db.databaseId }
            })]}
            onChange={(value) => {
              if (value) {
                handleDbChange(value);
              }
            }}
            onOpenChange={(open) => {
              if (open) {
                setGlobalNavigationUIState({
                  ...globalNavigationUIState,
                  isDatabaseSelectOpen: true,
                })
              } else {
                setGlobalNavigationUIState({
                  ...globalNavigationUIState,
                  isDatabaseSelectOpen: false,
                })
              }
            }}
          />
        )
      }
    </Box>
  );
};

export { DatabaseSelect };
