import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { Box } from "~/components_next/Box";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { PromptForm } from "./PromptForm";
import { PromptButton } from "../buttons/PromptButton";
import { TablesClientModel } from "~/clientModel/tables";
import { Loadable } from "~/clientModel/loadable";
import { ReactNode } from "react";
import { PromptSuggestions } from "./PromptSuggestions";
import { Executable } from "~/clientModel/executable";
import { CanvasVariablesValue } from "~/presenters/canvas/common/CanvasVariablesProvider";

type PromptDropdownProps = {
  prompt: string;
  isActive: boolean;
  onPromptChange: (prompt: string) => void;
  fieldsForTypeaheadLoadable?: Loadable<FieldsClientModel>;
  tablesForTypeaheadLoadable?: Loadable<TablesClientModel>;
  variablesForTypeaheadLoadable?: Loadable<CanvasVariablesValue[]>;
  suggestedPromptsLoadable: Loadable<{ messages: string[] }, unknown>;
  promptRightButtons?: ReactNode;
  generateSqlFromPromptExecutable: Executable<
    { prompt: string },
    { sql: string }
  >;
  onSqlGenerated: (sql: string) => void;
};

export const PromptDropdown = (props: PromptDropdownProps) => {
  const {
    prompt,
    onPromptChange,
    onSqlGenerated,
    fieldsForTypeaheadLoadable,
    tablesForTypeaheadLoadable,
    variablesForTypeaheadLoadable,
    suggestedPromptsLoadable,
    isActive,
    promptRightButtons,
    generateSqlFromPromptExecutable,
  } = props;

  return (
    <SimpleDropdownMenu trigger={<PromptButton isActive={isActive} />}>
      <Box css={{ minWidth: 600, overflow: "auto" }}>
        <PromptForm
          prompt={prompt}
          onPromptChange={onPromptChange}
          onSqlGenerated={onSqlGenerated}
          fieldsForTypeahead={fieldsForTypeaheadLoadable?.data}
          tablesForTypeahead={tablesForTypeaheadLoadable?.data}
          variablesForTypeahead={variablesForTypeaheadLoadable?.data}
          rightButtons={promptRightButtons}
          generateSqlFromPromptExecutable={generateSqlFromPromptExecutable}
        />
        <Box
          position="fixed"
          style={{
            bottom: -4,
            left: 0,
            right: 0,
            transform: "auto",
            translate: "0 100%",
          }}
        >
          <PromptSuggestions
            onClickMenuItem={onPromptChange}
            isOpen={!prompt}
            suggestedPromptsLoadable={suggestedPromptsLoadable}
          />
        </Box>
      </Box>
    </SimpleDropdownMenu>
  );
};
