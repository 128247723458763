import { useCallback, useState } from "react";
import { useErrorToast } from "~/components_next/Error";
import { PromptSuggestions } from "../../../../../common/components/QueryMode/PromptSuggestions";
import { useMutation, useQuery } from "react-query";
import {
  useGenerateSqlQuery,
  useSaveGenerateSqlHistoryMutation,
} from "~/serverStateStore";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import {
  usePromptInput,
  useSetPromptInput,
  useQueryingSql,
  useSetQueryingSql,
  useSetSqlInput,
} from "../../../states/sqlConditions";
import { PromptForm } from "../../../../../common/components/QueryMode/PromptForm";
import { useSetQueryMode } from "../../../states/queryMode";
import { useViewFields } from "~/serverStateStore/views";
import { useViewRecords } from "../../../hooks/useViewRecords";
import { useVariables } from "~/features/SourceAndViews/ViewCell/common/states/variables";
import { Box } from "~/components_next/Box";
import { DashboardViewResponse } from "@usemorph/morph-dashboard-types";

type TableViewPromptFormProps = {
  onClosePromptForm: () => void;
  onOpenSqlForm: () => void;
  onOpenHistoryDrawer: () => void;
  view: DashboardViewResponse;
};

const TableViewPromptForm = (props: TableViewPromptFormProps) => {
  const { onClosePromptForm, onOpenSqlForm, onOpenHistoryDrawer, view } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  /**
   * server states
   */

  const { data: viewFieldsData } = useViewFields({
    teamSlug,
    databaseId,
    viewId: view.viewId,
    viewData: view,
  });

  const { status: viewRecordsStatus } = useViewRecords();

  /**
   * global client states
   */
  const promptInput = usePromptInput();
  const setPromptInput = useSetPromptInput();
  const queryingSql = useQueryingSql();
  const setQueryingSql = useSetQueryingSql();
  const setSqlInput = useSetSqlInput();
  const setQueryMode = useSetQueryMode();
  const variables = useVariables();

  /**
   * handlers
   */
  const { errorToast } = useErrorToast({});

  const { refetch: generateSql } = useQuery({
    ...useGenerateSqlQuery({
      teamSlug,
      databaseId,
      message: promptInput,
      tableSlug: view.tableSlug,
      condition: view.condition,
    }),
    enabled: false,
    retry: false,
  });

  const [isRunning, setIsRunning] = useState(false);

  const { mutateAsync: saveGenerateSqlHistory } = useMutation(
    useSaveGenerateSqlHistoryMutation({
      teamSlug,
      databaseId,
      viewId: view.viewId,
    })
  );

  const handleRunPrompt = useCallback(async () => {
    setIsRunning(true);
    try {
      if (!view) {
        throw new Error("Something went wrong. Please try again.");
      }
      const result = await generateSql();
      const generatedSql = result.data?.result.sql;
      if (result.status === "error" || !generatedSql) {
        throw result.error;
      }
      saveGenerateSqlHistory({ message: promptInput, sql: generatedSql });
      setSqlInput(generatedSql);
      setQueryingSql(generatedSql);
      onClosePromptForm();
      setQueryMode("prompt");
    } catch (e) {
      errorToast(e);
    } finally {
      setIsRunning(false);
    }
  }, [
    errorToast,
    generateSql,
    onClosePromptForm,
    promptInput,
    saveGenerateSqlHistory,
    setQueryMode,
    setQueryingSql,
    setSqlInput,
    view,
  ]);

  const handleMoveToSQlMode = useCallback(() => {
    setPromptInput("");
    setQueryMode("sql");
    onClosePromptForm();
    onOpenSqlForm();
  }, [setPromptInput, setQueryMode, onClosePromptForm, onOpenSqlForm]);

  const handleOpenHistoryDrawer = useCallback(() => {
    onClosePromptForm();
    onOpenHistoryDrawer();
  }, [onClosePromptForm, onOpenHistoryDrawer]);

  return (
    <Box position="relative">
      <PromptForm
        promptInput={promptInput}
        onUpdatePromptInput={setPromptInput}
        isPromptRunning={isRunning || viewRecordsStatus === "loading"}
        onRunPrompt={handleRunPrompt}
        onMoveToSQLMode={handleMoveToSQlMode}
        onOpenHistoryDrawer={handleOpenHistoryDrawer}
        tablesForTypeahead={[]}
        fieldsForTypeahead={viewFieldsData?.fields || []}
        variablesForTypehead={variables}
        showMoveToSQLModeButton={!!queryingSql}
      />
      <Box
        position="fixed"
        style={{
          bottom: -4,
          left: 0,
          right: 0,
          transform: "auto",
          translate: "0 100%",
          // zIndex: 1000,
        }}
      >
        <PromptSuggestions
          onClickMenuItem={setPromptInput}
          isOpen={!promptInput}
          type="view"
          viewId={view.viewId}
          condition={view.condition}
        />
      </Box>
    </Box>
  );
};

export { TableViewPromptForm };
