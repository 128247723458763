import useApiMe from "~/api/useApiMe";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { meKeys } from "../keys";

const useSignUpMutation = () => {
  const { _createMe } = useApiMe();
  const client = useQueryClient();

  return {
    mutationFn: ({
      username,
      firstName,
      lastName,
      iconUrl,
    }: {
      username: string;
      firstName: string;
      lastName: string;
      iconUrl?: string | undefined;
    }) => {
      return _createMe({
        username,
        firstName,
        lastName,
        iconUrl,
      });
    },
    onSuccess: () => {
      return invalidateAll(client, meKeys.all());
    },
  };
};

export { useSignUpMutation };
