import { useMutation } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import {
  FieldsClientModel,
  FieldsClientModelDefactory,
} from "~/clientModel/fields";
import { useErrorToast } from "~/components_next/Error";
import { useOptimisticUpdateSimpleFieldOrderMutation } from "~/serverStateStore";

type UseUpdateFieldsOrderExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useUpdateFieldsOrderExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseUpdateFieldsOrderExecutableParams): Executable<
  { fields: FieldsClientModel },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  const updateFieldsOrderOptions = useOptimisticUpdateSimpleFieldOrderMutation({
    teamSlug,
    databaseId,
    tableSlug,
  });

  return useComposeExecutable(
    useMutation({
      ...updateFieldsOrderOptions,
      onMutate: ({ fields }) => {
        const order = FieldsClientModelDefactory.toFieldNames(fields);

        return updateFieldsOrderOptions.onMutate({ order });
      },
      mutationFn: ({ fields }: { fields: FieldsClientModel }) => {
        const order = FieldsClientModelDefactory.toFieldNames(fields);

        return updateFieldsOrderOptions.mutationFn({
          order,
        });
      },
      onError: (e) => {
        errorToast(e);
        return updateFieldsOrderOptions.onError();
      },
    })
  );
};

export const createUseUpdateFieldsOrderExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseUpdateFieldsOrderExecutableParams) => {
  return () =>
    useUpdateFieldsOrderExecutable({ teamSlug, databaseId, tableSlug });
};
