import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { RecordHistoriesClientModel } from "~/clientModel/recordHistories";

import { RecordIdentifierClientModel } from "~/clientModel/records/record/recordIdentifier";
import { Flex } from "~/components_next/Flex";
import { Spinner } from "~/components_next/Spinner";
import { RecordHistories } from "~/presenters/recordHistories";
import { WithFallback } from "../../../../../../clientModel/loadable/WithFallback";

type RecordHistoriesTabContentProps = {
  recordIdentifier: RecordIdentifierClientModel;
  useRecordHistoriesLoadable: UseLoadable<
    { recordIdentifier: RecordIdentifierClientModel },
    RecordHistoriesClientModel
  >;
};

export const RecordHistoriesTabContent = (
  props: RecordHistoriesTabContentProps
) => {
  const { recordIdentifier, useRecordHistoriesLoadable } = props;

  const recordHistoriesLoadable = useRecordHistoriesLoadable({
    recordIdentifier,
  });

  return (
    <WithFallback
      loadables={[recordHistoriesLoadable] as const}
      loadingFallback={<LoadingFallback />}
    >
      {([recordHistories]) => (
        <RecordHistories recordHistories={recordHistories} />
      )}
    </WithFallback>
  );
};

const LoadingFallback = () => {
  return (
    <Flex width="100%" pt="9" align="center" justify="center">
      <Spinner size="md" />
    </Flex>
  );
};
