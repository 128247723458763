import { SimpleField } from "@usemorph/morph-dashboard-types";
import { Flex } from "~/components_next/Flex";
import { Tag } from "~/components_next/Tag";
import { Text } from "~/components_next/Text";

type MaskedFieldsProps = {
  maskedFields: SimpleField[];
  onUnmaskField: (field: SimpleField) => void;
};

const MaskedFields = (props: MaskedFieldsProps) => {
  const { maskedFields, onUnmaskField } = props;

  return (
    <Flex direction="row">
      <Text variant="subheading" style={{ width: "120px", flex: "none" }}>
        Masked Fields
      </Text>
      <Flex direction="row" gap="2" wrap="wrap">
        {maskedFields.map((maskedField) => (
          <Tag
            variant="tertiary"
            key={maskedField.name}
            size="sm"
            onClear={() => onUnmaskField(maskedField)}
          >
            {maskedField.displayName || maskedField.name}
          </Tag>
        ))}
      </Flex>
    </Flex>
  );
};

export { MaskedFields };
