import { Tabs as RadixThemeTabs } from "@radix-ui/themes";
import { ComponentPropsWithoutRef, ReactNode } from "react";
import { match } from "ts-pattern";
import { MorphThemeSize } from "../../commonProps.type";
import { useTabsContext } from "./TabsRoot";

type RadixTabsListProps = ComponentPropsWithoutRef<typeof RadixThemeTabs.List>;

type TabsListProps = {
  children: ReactNode;
};

const getSize = (size?: MorphThemeSize) => {
  return match(size)
    .with("xs", () => "1" as RadixTabsListProps["size"])
    .with("sm", () => "1" as RadixTabsListProps["size"])
    .with("md", () => "2" as RadixTabsListProps["size"])
    .with("lg", () => "2" as RadixTabsListProps["size"])
    .with("xl", () => "2" as RadixTabsListProps["size"])
    .otherwise(() => "2" as RadixTabsListProps["size"]);
};

const TabsList = (props: TabsListProps) => {
  const { children } = props;

  const { size } = useTabsContext();

  const radixTabSize = getSize(size);

  return (
    <RadixThemeTabs.List size={radixTabSize}>{children}</RadixThemeTabs.List>
  );
};

export { TabsList, type TabsListProps };
