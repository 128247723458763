import * as Form from '@radix-ui/react-form';
import {
  SimpleField,
  WidgetDataCreateRecordRequestBody,
} from "@usemorph/morph-dashboard-types";
import { useState } from "react";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { FixedValuesForm } from "../common/FiexedValuesForm";
import { ValuesForm } from '../common/ValuesForm';

export const CreateForm = ({
  defaultBody,
  fields,
  onChange,
}: {
  defaultBody?: WidgetDataCreateRecordRequestBody;
  fields: SimpleField[];
  onChange: (body: WidgetDataCreateRecordRequestBody) => void;
}) => {
  const [body, setBody] = useState<WidgetDataCreateRecordRequestBody>(
    defaultBody ?? { values: [], fixedValue: [] }
  );

  return (
    <Form.Field name="form" style={{ width: "100%" }}>
      <InputStack>

        <ValuesForm
          values={defaultBody ? defaultBody.values : []}
          fields={fields}
          onChange={(e) => {
            setBody({ ...body, values: e });
            onChange({ ...body, values: e });
          }}
        />

        <FixedValuesForm
          values={defaultBody ? defaultBody.fixedValue : []}
          fields={fields}
          onChange={(e) => {
            setBody({ ...body, fixedValue: e });
            onChange({ ...body, fixedValue: e });
          }}
        />
      </InputStack>
    </Form.Field>
  );
};
