import { SimpleField } from "@usemorph/morph-dashboard-types";
import { match } from "ts-pattern";
import { DisplaySettingClientModelDefactory } from "./displaySetting";
import { FieldClientModel } from "./FieldClientModel";
import { SmartFunctionsClientModel } from "./fieldType/smartFunction/smartFunctions";
import { MergeKeyClientModelDefactory } from "./mergeKey";

export class FieldClientModelDefactory {
  public static toSimpleField(
    field: FieldClientModel,
    supplements?: {
      smartFunctions: SmartFunctionsClientModel; // smart function fieldがある場合にのみ必要
    }
  ): SimpleField {
    const { data } = field;

    const baseProps: Omit<SimpleField, "type"> = {
      name: data.name,
      displayName: data.displayName ?? undefined,
      isHidden: data.isHidden,
      primary: data.isPrimary,
      nullable: data.isNullable,
      comment: data.description ?? undefined,
      mergeSource: data.mergeKey
        ? MergeKeyClientModelDefactory.toSimpleFieldMergeSource(data.mergeKey)
        : undefined,
      displaySetting: data.displaySetting
        ? DisplaySettingClientModelDefactory.toSmartFieldDisplaySetting(
            data.displaySetting
          )
        : undefined,
    };

    return (
      match(field.type)
        // text
        .with(
          { type: "shortText" },
          () => ({ ...baseProps, type: "shortText" } as const)
        )
        .with(
          { type: "longText" },
          () => ({ ...baseProps, type: "longText" } as const)
        )
        .with(
          { type: "email" },
          () => ({ ...baseProps, type: "email" } as const)
        )
        .with(
          { type: "phoneNumber" },
          () => ({ ...baseProps, type: "phoneNumber" } as const)
        )
        .with({ type: "url" }, () => ({ ...baseProps, type: "url" } as const))
        .with({ type: "date" }, () => ({ ...baseProps, type: "date" } as const))
        .with(
          { type: "datetime" },
          () => ({ ...baseProps, type: "datetime" } as const)
        )
        .with({ type: "time" }, () => ({ ...baseProps, type: "time" } as const))

        // number
        .with(
          { type: "number" },
          () => ({ ...baseProps, type: "number" } as const)
        )
        .with(
          { type: "bigNumber" },
          () => ({ ...baseProps, type: "bigNumber" } as const)
        )
        .with(
          { type: "decimal" },
          () => ({ ...baseProps, type: "decimal" } as const)
        )
        .with(
          { type: "autoNumber" },
          () => ({ ...baseProps, type: "autoNumber" } as const)
        )
        .with(
          { type: "autoBigNumber" },
          () => ({ ...baseProps, type: "autoBigNumber" } as const)
        )

        // other primitives
        .with(
          { type: "boolean" },
          () => ({ ...baseProps, type: "boolean" } as const)
        )

        // select
        .with(
          { type: "singleSelect" },
          (type) =>
            ({
              ...baseProps,
              type: "singleSelect",
              members: type.members,
            } as const)
        )
        .with(
          { type: "multiSelect" },
          (type) =>
            ({
              ...baseProps,
              type: "multiSelect",
              members: type.members,
            } as const)
        )

        // file
        .with(
          { type: "image" },
          () => ({ ...baseProps, type: "image" } as const)
        )
        .with(
          { type: "attachment" },
          () => ({ ...baseProps, type: "attachment" } as const)
        )

        // structured data
        .with({ type: "json" }, () => ({ ...baseProps, type: "json" } as const))
        .with(
          { type: "array" },
          () => ({ ...baseProps, type: "array" } as const)
        )
        .with({ type: "html" }, () => ({ ...baseProps, type: "html" } as const))

        // computed
        .with(
          { type: "formula" },
          (type) =>
            ({ ...baseProps, type: "formula", formula: type.formula } as const)
        )
        .with(
          { type: "syncValue" },
          (type) =>
            ({
              ...baseProps,
              type: type.syncTargetFieldTypeOrThrow,
              normalizedSource: {
                type: "sync",
                tableSlug: type.syncTargetTableSlugOrThrow,
                fieldName: type.syncTargetFieldNameOrThrow,
              },
            } as const)
        )
        .with(
          { type: "generateText" },
          (type) =>
            ({
              ...baseProps,
              type: type.data.syncedValueType.type,
              normalizedSource: {
                type: "calculation",
                tableSlug: type.syncTargetTableSlugOrThrow,
                fieldName: field.name,
                sql: type.sqlOrThrow,
              },
            } as const)
        )
        .with(
          { type: "calculation" },
          (type) =>
            ({
              ...baseProps,
              type: type.data.syncedValueType.type,
              normalizedSource: {
                type: "calculation",
                tableSlug: type.syncTargetTableSlugOrThrow,
                fieldName: field.name,
                sql: type.sqlOrThrow,
              },
            } as const)
        )
        .with(
          { type: "aggregateValue" },
          (type) =>
            ({
              ...baseProps,
              type: type.syncedValueType.type,
              normalizedSource: {
                type: "aggregation",
                tableSlug: type.syncTargetTableSlugOrThrow,
                fieldName: type.syncTargetFieldNameOrThrow,
                operator: type.operatorValueOrThrow,
              },
            } as const)
        )
        .with({ type: "smartFunction" }, (type) => {
          if (!supplements?.smartFunctions) {
            throw new Error(
              "Cannot create smart function field without smart functions client model"
            );
          }
          return {
            ...baseProps,
            type: supplements.smartFunctions.getSmartFunction(
              type.smartFunctionIdOrThrow
            ).valueType,
            smartFunction: {
              functionId: type.smartFunctionIdOrThrow,
              data: type.settingData,
            },
          } as const;
        })

        // system
        .with(
          { type: "createdAt" },
          () => ({ ...baseProps, type: "createdAt" } as const)
        )
        .with(
          { type: "lastEditedAt" },
          () => ({ ...baseProps, type: "lastEditedAt" } as const)
        )
        .with(
          { type: "createdBy" },
          () => ({ ...baseProps, type: "createdBy" } as const)
        )
        .with(
          { type: "lastEditedBy" },
          () => ({ ...baseProps, type: "lastEditedBy" } as const)
        )
        .exhaustive()
    );
  }

  public static toEmbeddingField(targetField: FieldClientModel): SimpleField {
    return {
      name: `morph_reserved_embeddings_${targetField.name}`,
      displayName: `morph_reserved_embeddings_${targetField.name}`,
      type: "embeddings",
      embeddings: targetField.name,
    };
  }

  public static toFieldName(field: FieldClientModel): string {
    return field.name;
  }
}
