import { MouseEventHandler } from "react";
import { BsPlus } from "react-icons/bs";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { styled } from "~/stitches";

const BoxWithBg = styled(Box, {
  backgroundColor: "$bg0",
});

type CreateViewButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
};

const CreateViewButton = (props: CreateViewButtonProps) => {
  const { onClick, isDisabled } = props;

  return (
    <BoxWithBg>
      <Button
        size="sm"
        isDisabled={isDisabled}
        variant="secondary"
        onClick={onClick}
      >
        <BsPlus />
        Create View
      </Button>
    </BoxWithBg>
  );
};

export { CreateViewButton };
