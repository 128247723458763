import { FindDashboardPageClientModel } from "~/clientModel/canvas/FindDashboardPageClientModel/FindDashboardPageClientModel";
import { ListCanvasPageClientModel } from "~/clientModel/canvas/ListCanvasPageClientModel";
import { Loadable } from "~/clientModel/loadable";
import {
  PublicDashboardTableViewPropsContextProps,
  PublicDashboardTableViewPropsProvider,
} from "./providers/PublicDashboardTableViewPropsProvider";
import { Box } from "~/components_next/Box";
import { DashboardPageTabs } from "../dashboard/pageTabs/DashboardPageTabs";
import { DashboardPageTab } from "../dashboard/pageTabs/DashboardPageTab";
import { CanvasVariablesProvider } from "../common/CanvasVariablesProvider";
import { PublicDashboardPage } from "./page/PublicDashboardPage";
import {
  CanvasVariableOptionsLoadableProvider,
  UseVariableOptionsViewResultLoadable,
} from "../common/CanvasVariableOptionsLoadableProvider";
import { Spacer } from "~/components_next/Spacer";
import { IconButton } from "~/components_next/IconButton";
import { LuRefreshCcw } from "react-icons/lu";
import { ScrollArea } from "@radix-ui/themes";
import { Flex } from "~/components_next/Flex";

type CanvasPublicDashboardPresenterProps = {
  pages: ListCanvasPageClientModel[]; // pagesは、Notebookのレスポンスに含まれているので、静的に受け取る
  activePageLoadable: Loadable<FindDashboardPageClientModel>;
  setActivePageId: (pageId: string | null) => void;
  tableViewProps: PublicDashboardTableViewPropsContextProps;
  useVariableOptionsLoadable: UseVariableOptionsViewResultLoadable;
  onRefresh: () => void;
};

const CanvasPublicDashboardPresenter = (
  props: CanvasPublicDashboardPresenterProps
) => {
  const {
    pages,
    activePageLoadable,
    setActivePageId,
    tableViewProps,
    useVariableOptionsLoadable,
    onRefresh,
  } = props;

  return (
    <>
      <PublicDashboardTableViewPropsProvider {...tableViewProps}>
        <Box width="100%">
          <DashboardPageTabs align="center" gap="1" px="1">
            <ScrollArea>
              <Flex align="center" gap="1">
                {pages.map((page) => {
                  return (
                    <DashboardPageTab
                      key={page.pageId}
                      isActive={page.pageId === activePageLoadable.data?.pageId}
                      onClick={() => {
                        if (page.pageId === activePageLoadable.data?.pageId)
                          return;
                        setActivePageId(page.pageId);
                      }}
                      px="3"
                      py="1"
                      gap="3"
                      align="center"
                    >
                      {page.pageName}
                    </DashboardPageTab>
                  );
                })}
              </Flex>
            </ScrollArea>
            <Spacer />
            <IconButton
              icon={<LuRefreshCcw />}
              tooltip="Refresh"
              onClick={() => onRefresh()}
            />
          </DashboardPageTabs>
          <CanvasVariablesProvider>
            <CanvasVariableOptionsLoadableProvider
              useVariableOptionsLoadable={useVariableOptionsLoadable}
            >
              <Box width="100%" p="2">
                {activePageLoadable.data && (
                  <PublicDashboardPage page={activePageLoadable.data} />
                )}
              </Box>
            </CanvasVariableOptionsLoadableProvider>
          </CanvasVariablesProvider>
        </Box>
      </PublicDashboardTableViewPropsProvider>
    </>
  );
};

export { CanvasPublicDashboardPresenter };
