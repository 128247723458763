import { UseQueryOptions } from "react-query";

const sec = 1000;
const min = 60 * sec;

const commonQueryOptions: UseQueryOptions = {
  staleTime: 1 * min,
  cacheTime: 5 * min,
  retry: 1,
};

export { commonQueryOptions };
