import { UniqueIdentifier } from "@dnd-kit/core";
import { SimpleField } from "@usemorph/morph-dashboard-types";

const createId = (simpleField: SimpleField): string => {
  return `${simpleField.originalTableSlug}.${simpleField.name}`;
};

const parseId = (
  id: UniqueIdentifier
): { originalTableSlug: string; fieldName: string } => {
  if (typeof id === "number") {
    throw new Error("Invalid id type");
  }
  const [originalTableSlug, fieldName] = id.split(".");
  return {
    originalTableSlug,
    fieldName,
  };
};

const isMatchIdAndSimpleField = (
  id: UniqueIdentifier,
  simpleField: SimpleField
): boolean => {
  const { originalTableSlug, fieldName } = parseId(id);
  return (
    simpleField.name === fieldName &&
    simpleField.originalTableSlug === originalTableSlug
  );
};

export { createId, parseId, isMatchIdAndSimpleField };
