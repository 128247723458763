import { useMutation, useQuery } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import { FieldsClientModelFactory } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import {
  RecordClientModel,
  RecordClientModelDefactory,
} from "~/clientModel/records/record";

import { useErrorToast } from "~/components_next/Error";
import {
  useListSimpleFieldsQuery,
  useRunSmartFieldMutation,
} from "~/serverStateStore";

type UseRunSmartFieldExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useRunSmartFieldExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseRunSmartFieldExecutableParams): Executable<
  {
    records?: RecordClientModel[];
    fields?: FieldClientModel[];
    onlyError: boolean;
  },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  // todo: v4移行後、queryClient.ensureQueryDataにする
  const { data: allFields } = useQuery({
    ...useListSimpleFieldsQuery({ teamSlug, databaseId, tableSlug }),
    select: (data) =>
      FieldsClientModelFactory.createFromSimpleFields(data.fields),
  });

  const createFieldOptions = useRunSmartFieldMutation({
    teamSlug,
    databaseId,
    tableSlug,
  });

  return useComposeExecutable(
    useMutation({
      ...createFieldOptions,
      mutationFn: ({
        records,
        fields,
        onlyError,
      }: {
        records?: RecordClientModel[];
        fields?: FieldClientModel[];
        onlyError: boolean;
      }) => {
        if (!allFields) {
          throw new Error("Something went wrong.");
        }

        const select = fields ? fields.map((field) => field.name) : ["*"];

        const filter = records
          ? {
              or: records.map((record) =>
                RecordClientModelDefactory.toRecordFilterConditionAnd(
                  record,
                  allFields
                )
              ),
            }
          : undefined;

        return createFieldOptions.mutationFn({
          select,
          filter,
          onlyError,
        });
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseRunSmartFieldExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseRunSmartFieldExecutableParams) => {
  return () => useRunSmartFieldExecutable({ teamSlug, databaseId, tableSlug });
};
