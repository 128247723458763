import { useState } from "react";
import {
  CsvImportSchemaClientModel,
  CsvInsertSchemaEditClientModel,
} from "~/clientModel/csvImport";
import { Executable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { Loadable } from "~/clientModel/loadable";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { Callout } from "~/components_next/Callout";
import { Dialog } from "~/components_next/Dialog";
import { useErrorToast, extractErrorDetails } from "~/components_next/Error";
import { Spacer } from "~/components_next/Spacer";
import { CsvInsertSchemaEditor } from "../dialogContent/CsvInsertSchemaEditor";

type CsvInsertSchemaEditorDialogContentProps = {
  onClose: () => void;
  importSchema: CsvImportSchemaClientModel;
  updateImportSchemaExecutable: Executable<
    {
      csvImportSchemaInstance: CsvInsertSchemaEditClientModel;
    },
    CsvImportSchemaClientModel,
    unknown
  >;
  runImportExecutable: Executable<
    { importDataSchemaId: string },
    { message: string },
    unknown
  >;
  fieldsLoadable: Loadable<FieldsClientModel>;
};

const CsvInsertSchemaEditorDialogContent = (
  props: CsvInsertSchemaEditorDialogContentProps
) => {
  const {
    importSchema,
    updateImportSchemaExecutable,
    runImportExecutable,
    fieldsLoadable,
  } = props;

  const { errorToast } = useErrorToast({});

  const [schemaEditInstance, setSchemaEditInstance] =
    useState<CsvInsertSchemaEditClientModel | null>(
      importSchema.toInsertEditModel()
    );

  const [importError, setImportError] = useState<{
    title: string;
    description: string;
  } | null>(null);

  const handleRunImport = async () => {
    setImportError(null);

    try {
      if (!schemaEditInstance) {
        throw new Error("There is no schema to run import.");
      }

      const updatedSchema = await updateImportSchemaExecutable.execute({
        csvImportSchemaInstance: schemaEditInstance,
      });

      const result = await runImportExecutable.execute({
        importDataSchemaId: updatedSchema.id,
      });

      if (result) {
        props.onClose();
      }
    } catch (e) {
      errorToast(e);

      const { title, description } = extractErrorDetails(e);
      setImportError({ title, description });
    }
  };

  return (
    <>
      <Dialog.Title>Edit import schema</Dialog.Title>
      <Dialog.Body>
        {importError && (
          <Box my="4">
            <Callout
              type="alert"
              title={importError.title}
              description={importError.description}
            />
          </Box>
        )}
        <Box p="2">
          <CsvInsertSchemaEditor
            importSchema={schemaEditInstance}
            onImportSchemaChange={setSchemaEditInstance}
            fieldsLoadable={fieldsLoadable}
          />
        </Box>
      </Dialog.Body>
      <Dialog.Footer>
        <Spacer />
        <Button variant="tertiary" onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => handleRunImport()}
          isLoading={
            updateImportSchemaExecutable.isExecuting ||
            runImportExecutable.isExecuting
          }
        >
          Start Import
        </Button>
      </Dialog.Footer>
    </>
  );
};

export { CsvInsertSchemaEditorDialogContent };
