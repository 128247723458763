import { SimpleField } from "@usemorph/morph-dashboard-types";
import useApiView from "~/api/useApiView";
import { RecordsTableBaseRecord } from "~/components_next/RecordsTableBase/types";
import { convertRecordToRecordModel } from "~/features/RecordModel";

export type TestFormulaForViewParams = {
  teamSlug: string;
  viewId: string;
  testingFormula: string;
};

export type TestFormulaForViewReturn = {
  count: number;
  recordsTableBaseRecords: RecordsTableBaseRecord[];
  fields: SimpleField[];
};

const useTestFormulaForView = () => {
  const { _getViewFields, _queryViewRecords } = useApiView();

  const testFormulaForView = async ({
    teamSlug,
    viewId,
    testingFormula,
  }: TestFormulaForViewParams): Promise<TestFormulaForViewReturn> => {
    const { fields: currentViewFields } = await _getViewFields({
      teamSlug,
      viewId,
    });

    const fieldsForTestRequest: SimpleField[] = [
      {
        type: "shortText",
        name: "morph_formula_result",
        displayName: "Formula Result",
      },
      ...currentViewFields,
    ];

    const select = [
      `[formula]${testingFormula}`,
      ...fieldsForTestRequest.map((f) => f.name),
    ];

    const result = await _queryViewRecords({
      teamSlug,
      viewId,
      body: {
        select,
        limit: 10,
        skip: 0,
      },
    });

    return {
      count: result.count,
      recordsTableBaseRecords: result.items.map((record, index) => {
        return {
          values: convertRecordToRecordModel(record, fieldsForTestRequest),
          _reservedRecordIndex: index,
        };
      }),
      fields: fieldsForTestRequest,
    };
  };

  return {
    testFormulaForView,
  };
};

export { useTestFormulaForView };
