import { SimpleField } from "@usemorph/morph-dashboard-types";
import { MergeKeyClientModel } from "./MergeKeyClientModel";

export class MergeKeyClientModelFactory {
  public static createFromSimpleFieldMergeSource({
    mergeSource,
  }: {
    mergeSource: NonNullable<SimpleField["mergeSource"]>;
  }): MergeKeyClientModel {
    const mergeKey = new MergeKeyClientModel({
      targets: mergeSource,
    });
    return mergeKey;
  }

  public static createEmpty(): MergeKeyClientModel {
    return new MergeKeyClientModel({ targets: [] });
  }
}
