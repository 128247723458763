type TemplateDependencies = { teamSlug: string; };

const templateKeys = {
  all: ({ teamSlug }: TemplateDependencies) =>
    [teamSlug, "templates"] as const,

  /**
   * get team
   */
  listTemplate: ({ teamSlug }: { teamSlug: string }) => [...templateKeys.all({ teamSlug }), "listTemplate"] as const,
  findTemplateDetail: ({ templateId, teamSlug }: { templateId: string; teamSlug: string }) =>
    [
      ...templateKeys.listTemplate({ teamSlug }),
      templateId,
    ] as const,
  listDynamicValue: ({
    templateId,
    integrationId,
    teamSlug,
    targetKey,
    dependentValues,
    templateAuthId,
  }: {
    templateId: string;
    integrationId: string;
    teamSlug: string;
    targetKey?: string;
    dependentValues?: { [key: string]: unknown; }
    templateAuthId?: string;
  }) =>
    [
      ...templateKeys.findTemplateDetail({ templateId, teamSlug }),
      integrationId,
      dependentValues,
      targetKey,
      'listDynamicValue',
    ] as const,
};

export { templateKeys };
