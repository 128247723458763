import {
  QueryRecordWithFieldsResponse,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { match } from "ts-pattern";
import { extractErrorDetails } from "~/components_next/Error";
import { RecordsTableBase } from "~/components_next/RecordsTableBase";
import { RecordsTableBaseRecord } from "~/components_next/RecordsTableBase/types";
import { Callout } from "~/components_next/Callout";
import { Spinner } from "~/components_next/Spinner";
import { useListRecordsWithSQLQuery } from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { convertRecordToRecordModel } from "../RecordModel";
import { HiddenFields } from "./HiddenFields";
import { DropdownMenu } from "~/components_next/DropdownMenu";
import { Flex } from "~/components_next/Flex";
import { Box } from "~/components_next/Box";

type DownloadConditions = {
  sql: string;
  selectedFields: SimpleField[];
};

type DownloadRecordsPreviewWithSQLProps = {
  downloadConditions: DownloadConditions;
  allSimpleFields: SimpleField[];
  tableSlug: string;
  onChangeCondition?: (downloadConditions: DownloadConditions) => void;
};

type SelectData = {
  recordsTableBaseRecord: RecordsTableBaseRecord[];
  fields: SimpleField[];
  count: number;
};

const DownloadRecordsPreviewWithSQL = (
  props: DownloadRecordsPreviewWithSQLProps
) => {
  const { downloadConditions, allSimpleFields, tableSlug, onChangeCondition } =
    props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const {
    data: queryRecordsData,
    status: queryRecordsStatus,
    error: queryRecordsError,
  } = useQuery({
    ...useListRecordsWithSQLQuery({
      teamSlug,
      databaseId,
      tableSlug,
      sql: downloadConditions.sql,
    }),
    select: (data: QueryRecordWithFieldsResponse): SelectData => {
      return {
        recordsTableBaseRecord: data.items.map((record, index) => {
          return {
            values: convertRecordToRecordModel(record, data.fields),
            _reservedRecordIndex: index,
          };
        }),
        fields: data.fields,
        count: data.count,
      };
    },
  });

  const handleChangeFieldVisibility = (
    targetField: SimpleField,
    isHidden: boolean
  ) => {
    if (!onChangeCondition || !queryRecordsData) return;

    const updatedSelectedFields: SimpleField[] = match(isHidden)
      .with(true, () =>
        downloadConditions.selectedFields.filter(
          ({ name, originalTableSlug }) =>
            name !== targetField.name ||
            originalTableSlug !== targetField.originalTableSlug
        )
      )
      .with(false, () => [...downloadConditions.selectedFields, targetField])
      .exhaustive();

    onChangeCondition({
      ...downloadConditions,
      selectedFields: updatedSelectedFields,
    });
  };

  const hiddenFields = useMemo(() => {
    return allSimpleFields.filter(
      ({ name }) =>
        !downloadConditions.selectedFields.find(
          (selectedField) => selectedField.name === name
        )
    );
  }, [allSimpleFields, downloadConditions.selectedFields]);

  if (queryRecordsStatus === "loading" || queryRecordsStatus === "idle") {
    return (
      <Flex align="center" justify="center" height="100%">
        <Spinner />
      </Flex>
    );
  }

  if (queryRecordsStatus === "error") {
    const { title, description } = extractErrorDetails(queryRecordsError);
    return <Callout type="alert" title={title} description={description} />;
  }

  return (
    <>
      <Flex
        direction="column"
        height="100%"
        width="100%"
        align="stretch"
        style={{ overflow: "auto" }}
      >
        {hiddenFields.length > 0 && (
          <HiddenFields
            hiddenFields={hiddenFields}
            onShowField={(field) => handleChangeFieldVisibility(field, false)}
          />
        )}
        <Box style={{ overflow: "auto" }}>
          <RecordsTableBase
            editableFields="none"
            fields={downloadConditions.selectedFields}
            records={queryRecordsData.recordsTableBaseRecord}
            headerDropdown={
              onChangeCondition
                ? (field) => {
                    return (
                      <>
                        <DropdownMenu.Item
                          onClick={() =>
                            handleChangeFieldVisibility(field, true)
                          }
                        >
                          Hide this field
                        </DropdownMenu.Item>
                      </>
                    );
                  }
                : undefined
            }
          />
        </Box>
      </Flex>
    </>
  );
};

export { DownloadRecordsPreviewWithSQL, type DownloadConditions };
