import { Fragment } from "react";
import { match, P } from "ts-pattern";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import {
  FilterConditionUnitClientModel,
  FilterConditionsClientModel,
} from "~/clientModel/queryConditions/filterConditions";
import { Box } from "~/components_next/Box";

import { Flex } from "~/components_next/Flex";
import { styled } from "~/stitches";
import { AddFilterDropdown } from "./AddFilterDropdown";
import { FilterConditionUnitForm } from "./FilterConditionUnitForm/FilterConditionUnitForm";
import { LogicalOperatorInput } from "./LogicalOperatorInput";
import { CanvasVariablesValue } from "~/presenters/canvas/common/CanvasVariablesProvider";

// とりあえず1段ネストまで対応

const NestedFilterConditionsFormContainer = styled(Box, {
  padding: "12px 12px 12px 44px",
  borderRadius: "4px",

  variants: {
    withBg: {
      true: {
        backgroundColor: "$bg1",
      },
    },
  },
});

export type NestLevel = 0 | 1;

type FilterConditionsFormProps = {
  filterConditions: FilterConditionsClientModel;
  onFilterConditionsChange?: (
    filterConditions: FilterConditionsClientModel
  ) => void;
  fields: FieldsClientModel;
  isReadOnly?: boolean;
  variables?: CanvasVariablesValue[];
} & (
  | {
      nestLevel: 0;
    }
  | {
      nestLevel: NestLevel;
      onFilterConditionsRemove: () => void;
    }
);

const FilterConditionsFormComponent = (props: FilterConditionsFormProps) => {
  const {
    filterConditions,
    onFilterConditionsChange = () =>
      console.error("onFilterConditionsChange is not defined"),
    fields,
    nestLevel,
    isReadOnly = false,
    variables,
  } = props;

  const handleFilterConditionsItemChange = (
    replacedFilterConditions: FilterConditionsClientModel,
    index: number
  ) => {
    onFilterConditionsChange(
      filterConditions.replaceFilterConditionItemByIndex(
        replacedFilterConditions,
        index
      )
    );
  };

  const handleFilterConditionUnitItemChange = (
    filterConditionUnit: FilterConditionUnitClientModel,
    index: number
  ) => {
    onFilterConditionsChange(
      filterConditions.replaceFilterConditionItemByIndex(
        filterConditionUnit,
        index
      )
    );
  };

  const handleFilterConditionUnitRemove = (index: number) => {
    const updatedFilterCondition =
      filterConditions.removeFilterConditionItemByIndex(index);

    if (props.nestLevel === 0) {
      // トップレベルの時は条件が空になってもよい
      onFilterConditionsChange(updatedFilterCondition);
    } else {
      // ネストされた条件の時は、条件が空になったら削除する
      if (updatedFilterCondition.hasFilterConditions) {
        onFilterConditionsChange(updatedFilterCondition);
      } else {
        props.onFilterConditionsRemove();
      }
    }
  };

  return (
    <Flex direction="column" gap="2">
      {filterConditions.allConditionItems.map((unitOrConditions, index) => {
        return (
          <Fragment key={index}>
            {/* 入力部分 */}
            {match(unitOrConditions)
              .with(P.instanceOf(FilterConditionsClientModel), (conditions) => (
                <NestedFilterConditionsFormContainer
                  withBg={nestLevel % 2 === 0}
                >
                  <FilterConditionsFormComponent
                    filterConditions={conditions}
                    onFilterConditionsChange={(conditions) =>
                      handleFilterConditionsItemChange(conditions, index)
                    }
                    fields={fields}
                    isReadOnly={isReadOnly}
                    nestLevel={(nestLevel + 1) as NestLevel}
                    onFilterConditionsRemove={() =>
                      handleFilterConditionUnitRemove(index)
                    }
                    variables={variables}
                  />
                </NestedFilterConditionsFormContainer>
              ))
              .otherwise((filterConditionItem) => (
                <FilterConditionUnitForm
                  key={index}
                  filterConditionUnit={filterConditionItem}
                  onFilterConditionUnitChange={(unit) =>
                    handleFilterConditionUnitItemChange(unit, index)
                  }
                  onFilterConditionUnitRemove={() =>
                    handleFilterConditionUnitRemove(index)
                  }
                  fields={fields}
                  isReadOnly={isReadOnly}
                  variables={variables}
                />
              ))}
            {filterConditions.allConditionItems.length - 1 !== index && (
              <LogicalOperatorInput
                filterConditions={filterConditions}
                onFilterConditionsChange={onFilterConditionsChange}
                isReadOnly={isReadOnly}
              />
            )}
          </Fragment>
        );
      })}
      {!isReadOnly && (
        <AddFilterDropdown
          filterConditions={filterConditions}
          onFilterConditionsChange={onFilterConditionsChange}
          fields={fields}
          nestLevel={nestLevel}
        />
      )}
    </Flex>
  );
};

export const FilterConditionsForm = (
  props: Omit<FilterConditionsFormProps, "nestLevel">
) => {
  return <FilterConditionsFormComponent {...props} nestLevel={0} />;
};
