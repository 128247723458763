import { SqlViewer } from "./atoms/SqlViewer";
import { FormSection } from "./atoms/FormSection";
import { ViewMetaForm, ViewMetaFormState } from "./ViewMetaForm";
import { ViewSetting } from "../viewSetting/setting";
import {
  DashboardTableObject,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { Text } from "~/components_next/Text";
import { isArrayWithAtLeastOneElement } from "~/utils/commonTypeGuards";
import { PromptViewer } from "./atoms/PromptViewer";
import { useAllSimpleFields } from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useMemo } from "react";
import { Flex } from "@radix-ui/themes";

type ViewWithSQLFormValueType = {
  meta: ViewMetaFormState;
  sql: string;
  setting: ViewSetting;
};

type ViewWithSQLFormProps = {
  value: ViewWithSQLFormValueType;
  onChange: (value: ViewWithSQLFormValueType) => void;
  viewFields: SimpleField[];
  tables: DashboardTableObject[];
};

const ViewWithSQLForm = (props: ViewWithSQLFormProps) => {
  const { value, onChange, viewFields, tables } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const { data: allFieldsData } = useAllSimpleFields({ teamSlug, databaseId });

  const allFields: SimpleField[] = useMemo(() => {
    return allFieldsData?.tables.flatMap(({ fields }) => fields) ?? [];
  }, [allFieldsData]);

  if (!isArrayWithAtLeastOneElement(viewFields)) {
    return (
      <Flex align="center" justify="center" py="6">
        <Text>One field is required at least.</Text>
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap="9">
      <Flex direction="column" gap="4">
        <Flex direction="column">
          <ViewMetaForm
            value={value.meta}
            onChange={(meta) => onChange({ ...value, meta })}
            groupByFieldOptions={viewFields}
          />
        </Flex>
      </Flex>
      {!!value.setting.data.prompt && (
        <FormSection label="Prompt">
          <PromptViewer
            prompt={value.setting.data.prompt}
            tables={tables}
            fields={allFields}
          />
        </FormSection>
      )}
      <FormSection label="SQL">
        <SqlViewer sql={value.sql} />
      </FormSection>
    </Flex>
  );
};

export { ViewWithSQLForm, type ViewWithSQLFormValueType };
