import { ForwardedRef, forwardRef } from "react";
import { match } from "ts-pattern";
import { Box } from "~/components_next/Box";
import { styled } from "~/stitches";
import "./resize-handle.css";

type DashboardCellCardResizeHandleProps = {
  handleAxis?: string;
};

const ResizeHandleElement = styled(Box, {
  width: "8px",
  height: "8px",
  backgroundColor: "$blue9",
  position: "absolute",
  variants: {
    position: {
      ne: {
        top: "-4px",
        right: "-4px",
      },
      se: {
        bottom: "-4px",
        right: "-4px",
      },
      sw: {
        bottom: "-4px",
        left: "-4px",
      },
      nw: {
        top: "-4px",
        left: "-4px",
      },
    },
  },
});

const _DashboardCellCardResizeHandle = (
  props: DashboardCellCardResizeHandleProps & object,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const { handleAxis, ...restProps } = props;

  const variant = match(handleAxis)
    .with("ne", () => "ne" as const)
    .with("se", () => "se" as const)
    .with("sw", () => "sw" as const)
    .with("nw", () => "nw" as const)
    .otherwise(() => undefined);

  return (
    <ResizeHandleElement
      ref={ref}
      position={variant}
      {...restProps}
      className="dashboard-cell-card-resize-handle"
    />
  );
};

const DashboardCellCardResizeHandle = forwardRef<
  HTMLDivElement,
  DashboardCellCardResizeHandleProps
>(_DashboardCellCardResizeHandle);

export { DashboardCellCardResizeHandle };
