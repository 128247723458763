import { useMutation } from "react-query";
import useApiView from "~/api/useApiView";
import { CsvDownloadFieldsClientModel } from "~/clientModel/csvDownload/csvDownloadFields";
import { CsvDownloadFieldsClientModelDefactory } from "~/clientModel/csvDownload/csvDownloadFields/CsvDownloadFieldsClientModelDefactory";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import { useErrorToast } from "~/components_next/Error";

const useDownloadCsvExecutable = ({
  teamSlug,
  viewId,
}: {
  teamSlug: string;
  viewId: string;
}): Executable<
  { csvDownloadFields: CsvDownloadFieldsClientModel },
  { url: string }
> => {
  const { errorToast } = useErrorToast({});

  const { _downloadRecordsCSV } = useApiView();

  return useComposeExecutable(
    useMutation({
      mutationFn: async ({
        csvDownloadFields,
      }: {
        csvDownloadFields: CsvDownloadFieldsClientModel;
      }) => {
        const select =
          CsvDownloadFieldsClientModelDefactory.toQueryRecordRequestBodyObjectSelect(
            csvDownloadFields
          );
        const format =
          CsvDownloadFieldsClientModelDefactory.toRecordFormatConditions(
            csvDownloadFields
          );

        const { url } = await _downloadRecordsCSV({
          teamSlug,
          viewId,
          body: {
            select,
            format,
          },
        });

        return { url };
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseDownloadCsvExecutable = ({
  teamSlug,
}: {
  teamSlug: string;
}) => {
  return ({ viewId }: { viewId: string }) => {
    return useDownloadCsvExecutable({
      teamSlug,
      viewId,
    });
  };
};
