import { UseExecutable } from "~/clientModel/executable";
import { FieldClientModel } from "~/clientModel/fields/field";
import { Loadable } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { FooterButtonBase } from "../../common/FooterButtonBase";

type BulkEditRecordsByPromptFooterProps = {
  prompt: string;
  targetFieldLoadable: Loadable<FieldClientModel, unknown>;
  useBulkEditRecordsByPromptExecutable: UseExecutable<
    void,
    {
      prompt: string;
      field: FieldClientModel;
    },
    unknown,
    unknown
  >;
};

export const BulkEditRecordsByPromptFooter = (
  props: BulkEditRecordsByPromptFooterProps
) => {
  const { prompt, useBulkEditRecordsByPromptExecutable, targetFieldLoadable } =
    props;

  const bulkEditRecordsByFormulaExecutable =
    useBulkEditRecordsByPromptExecutable();

  return (
    <WithFallback loadables={[targetFieldLoadable] as const}>
      {([targetField]) => (
        <FooterButtonBase
          isDisabled={!prompt}
          isLoading={bulkEditRecordsByFormulaExecutable.isExecuting}
          onClick={() =>
            bulkEditRecordsByFormulaExecutable.execute({
              prompt,
              field: targetField,
            })
          }
        >
          Start Update
        </FooterButtonBase>
      )}
    </WithFallback>
  );
};
