import { DashboardViewConditionObject } from "@usemorph/morph-dashboard-types";
import { useQuery } from "react-query";
import { useComposeLoadable } from "~/clientModel/loadable";
import { useListSuggestPromptForGenerateSQLQuery } from "~/serverStateStore";

// TODO: View向けに変更する
export const createUseSuggestedPromptsLoadable = ({
  teamSlug,
  databaseId,
}: {
  teamSlug: string;
  databaseId: string;
}) => {
  return ({
    condition,
    viewId,
  }: {
    condition: DashboardViewConditionObject;
    viewId: string;
  }) =>
    useComposeLoadable(
      useQuery({
        ...useListSuggestPromptForGenerateSQLQuery({
          teamSlug,
          databaseId,
          condition,
          language: navigator.language,
          viewId,
        }),
      })
    );
};
