import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModel } from "..";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";

export class BooleanFieldTypeClientModel extends FieldTypeClientModelBase {
  public get type(): "boolean" {
    return "boolean";
  }

  public get fieldTypeLabel(): "Boolean" {
    return "Boolean";
  }

  public get isAutoGenerated(): false {
    return false;
  }

  public get isEmbeddable(): false {
    return false;
  }

  public get canBeTargetOfSyncingByComputedField(): true {
    return true;
  }

  public get _updatableFieldTypes(): FieldTypeClientModel["type"][] {
    return ["shortText", "longText", "singleSelect", "multiSelect"];
  }

  public validate(): ValidationResult {
    return { isValid: true };
  }
}
