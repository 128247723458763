import { useMutation } from "react-query";
import { useComposeExecutable } from "~/clientModel/executable";
import { PaymentClientModelFactory } from "~/clientModel/payment/PaymentClientModel";
import { useDeletePaymentMutation } from "~/serverStateStore";

export const useDeletePaymentExecutable = (props: { teamSlug: string }) => {
  const { teamSlug } = props

  const deletePaymentMutationOptions = useDeletePaymentMutation({ teamSlug })
  const deletePaymentMutationResult = useMutation({
    ...deletePaymentMutationOptions,
    mutationFn: async () => {
      const response = await deletePaymentMutationOptions.mutationFn()
      return PaymentClientModelFactory.fromPaymentResponse(response)
    }
  });
  return useComposeExecutable(deletePaymentMutationResult)
}
