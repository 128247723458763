import useApiTeam from "~/api/useApiTeam";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { teamsKeys } from "../keys";

type UseUpdateTeamUserMutationParams = {
  teamSlug: string;
};

const useUpdateTeamUserMutation = ({
  teamSlug,
}: UseUpdateTeamUserMutationParams) => {
  const { _updateTeamUser } = useApiTeam();
  const client = useQueryClient();

  return {
    mutationFn: ({ userId, isAdmin }: { userId: string; isAdmin: boolean }) => {
      return _updateTeamUser({
        teamSlug,
        userId,
        isAdmin,
      });
    },
    onSuccess: () => {
      return invalidateAll(client, teamsKeys.getTeam({ teamSlug }));
    },
  };
};

export { useUpdateTeamUserMutation };
