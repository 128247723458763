import { LuSparkles } from "react-icons/lu";
import { Flex } from "~/components_next/Flex";
import { Text } from "~/components_next/Text";

type CreditConsumptionWrapperProps = {
  message: string;
};

export const CreditConsumptionWrapper = (
  props: CreditConsumptionWrapperProps
) => {
  const { message } = props;

  return (
    <Flex px="2" py="2" direction="row" align="center" gap="4">
      <Flex align="center" justify="center">
        <LuSparkles color="gray" size="16" />
      </Flex>
      <Text variant="description">{message}</Text>
    </Flex>
  );
};
