import { useViewId } from "~/utilHooks/useViewId";
import { SimpleField } from "@usemorph/morph-dashboard-types";
import { RecordsTableBaseRecord } from "~/components_next/RecordsTableBase/types";
import { useQueryMode } from "../states/queryMode";
import { useViewRecordsWithQuery } from "./useViewRecordsWithQuery";
import { UseQueryResult } from "react-query";
import { useViewRecordsWithSQL } from "./useViewRecordsWithSQL";
import { match, P } from "ts-pattern";

type ViewRecordModelsData = {
  recordsTableBaseRecords: RecordsTableBaseRecord[];
  fields: SimpleField[];
  count: number;
};

type UseViewRecordsResult = Pick<
  UseQueryResult<ViewRecordModelsData, unknown>,
  "status" | "data" | "error"
>;

const useViewRecords = (): UseViewRecordsResult => {
  const viewId = useViewId();

  const queryMode = useQueryMode(viewId);

  const queryResult = useViewRecordsWithQuery();

  const sqlResult = useViewRecordsWithSQL();

  return match(queryMode)
    .with(P.union("filtersAndSorts", P.nullish), () => queryResult)
    .with(P.union("sql", "prompt"), () => sqlResult)
    .exhaustive();
};

export { useViewRecords, type UseViewRecordsResult };
