/**
 * Dialogの中で使われる時用のコンポーネント。Dialogへの依存アリ。
 */

import { Dialog } from "@radix-ui/themes";
import {
  CsvImportSheetSelect,
  CsvImportSheetSelectProps,
} from "../sheetSelect/CsvImportSheetSelect";

type CsvImportSheetselectDialogContentProps = CsvImportSheetSelectProps;

const CsvImportSheetSelectDialogContent = (
  props: CsvImportSheetselectDialogContentProps
) => {
  return (
    <>
      <Dialog.Title>Slect sheet to import</Dialog.Title>
      <Dialog.Content>
        <CsvImportSheetSelect {...props} />
      </Dialog.Content>
    </>
  );
};

export { CsvImportSheetSelectDialogContent };
