// types
import { useQueryClient } from "react-query";
import useApiObject from "~/api/useApiObject";
// import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
// import { objectsKeys } from "../keys";

interface UseCreateObjectMutationParams {
  teamSlug: string;
}

const useCreateObjectMutation = ({
  teamSlug,
}: UseCreateObjectMutationParams) => {
  const { _createObject } = useApiObject();

  const client = useQueryClient();

  return {
    mutationFn: ({
      bucketId,
      contentType,
      key,
      userpoolUserId,
      userpoolTeamId,
      groupId,
      file,
    }: {
      bucketId: string;
      contentType?: string | undefined;
      key: string;
      userpoolUserId?: string | undefined;
      userpoolTeamId?: string | undefined;
      groupId?: string | undefined;
      file: File;
    }) => {
      return _createObject({
        teamSlug,
        bucketId,
        contentType,
        key,
        userpoolUserId,
        userpoolTeamId,
        groupId,
        file,
      });
    },
    // invalidateが現状不要なので、コメントアウト
    // onSuccess: () => {
    //   return invalidateAll(client, objectsKeys.all({ teamSlug, bucketId }));
    // },
  };
};

export { useCreateObjectMutation };
