type BucketsDependencies = { teamSlug: string };

type ListBucketDependencies = {
  limit?: number;
  skip?: number;
  bucketName?: string;
} & BucketsDependencies;

const bucketKeys = {
  all: ({ teamSlug }: BucketsDependencies) => [teamSlug, "buckets"] as const,

  /**
   * list
   */
  allListBucket: ({ teamSlug }: BucketsDependencies) =>
    [...bucketKeys.all({ teamSlug }), "listBucket"] as const,
  listBucket: ({ teamSlug, bucketName, limit, skip }: ListBucketDependencies) =>
    [
      ...bucketKeys.allListBucket({ teamSlug }),
      { bucketName, limit, skip },
    ] as const,
};

export { bucketKeys };
