import constate from "constate";
import { useState } from "react";

const useNotebookPseudoCellSelection = () => {
  const [mouseoveredCells, setMouseoveredCells] = useState<string[]>([]);

  return {
    mouseoveredCells,
    setMouseoveredCells,
  };
};

export const [
  NotebookPseudoCellSelectionProvider,
  useNotebookPseudoHoveredCells,
  useNotebookSetPseudoHoveredCells,
] = constate(
  useNotebookPseudoCellSelection,
  (value) => value.mouseoveredCells,
  (value) => value.setMouseoveredCells
);
