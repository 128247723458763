import { ForwardedRef, forwardRef, ReactNode, MouseEvent } from "react";
import { Command as CommandPrimitive } from "cmdk";
import classNames from "classnames";
import { Box, withBreakpoints } from "@radix-ui/themes";
import {
  useMultiSelectOnChange,
  useMultiSelectOnChangeInputValue,
  useMultiSelectSize,
  useMultiSelectValue,
} from "./MultiSelectContext";
import { convertSize } from "./util";
import { ThickCheckIcon } from "~/components_next/Icons";

type MultiSelectItemProps = {
  value: string;
  children: ReactNode;
};

const MultiSelectItem = forwardRef(
  (props: MultiSelectItemProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, value } = props;

    const onChange = useMultiSelectOnChange();
    const multiSelectValue = useMultiSelectValue();
    const size = useMultiSelectSize();
    const onChangeInputValue = useMultiSelectOnChangeInputValue();

    const isSelected = multiSelectValue.includes(value);

    const handleSelect = () => {
      if (!onChange) return;

      const updatedValue = isSelected
        ? multiSelectValue.filter((v) => v !== value)
        : [...multiSelectValue, value];

      onChange(updatedValue);
      onChangeInputValue("");
    };

    const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };

    return (
      <CommandPrimitive.Item
        ref={ref}
        className={classNames(
          "rt-reset",
          "morph-MultiSelectItem",
          withBreakpoints(convertSize(size), "morph-r-size")
        )}
        key={value}
        value={value}
        onSelect={handleSelect}
        onMouseDown={handleMouseDown}
      >
        {isSelected && (
          <Box className="morph-MultiSelectItemIndicator">
            <ThickCheckIcon className="morph-MultiSelectItemIndicatorIcon" />
          </Box>
        )}
        {children}
      </CommandPrimitive.Item>
    );
  }
);

MultiSelectItem.displayName = "MultiSelectItem";

export { MultiSelectItem, type MultiSelectItemProps };
