import { useQueryClient } from "react-query";
import useApiRecord from "~/api/useApiRecord";
import { recordsKeys } from "../keys";

// types
import {
  RecordFilterCondition,
  RecordValueType,
} from "@usemorph/morph-dashboard-types";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseGetRecordsParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
}

const useUpdateRecordMutation = ({
  databaseId,
  tableSlug,
  teamSlug,
}: UseGetRecordsParams) => {
  const { _updateRecord } = useApiRecord();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      values,
      filter,
    }: {
      values: { key: string; value: RecordValueType }[];
      filter: RecordFilterCondition;
    }) => {
      return _updateRecord({
        teamSlug,
        databaseId,
        tableSlug,
        body: {
          values,
          filter,
          fixedValue: [],
        },
      });
    },
    onSuccess: () => {
      const getRecordKey = recordsKeys.allGetRecord({
        teamSlug,
        databaseId,
        tableSlug,
      });
      const getRecordWithSqlKey = recordsKeys.allGetRecordWithSql({
        teamSlug,
        databaseId,
        tableSlug,
      });
      const getSoftDeletedRecordKey = recordsKeys.allGetRecordWithSql({
        teamSlug,
        databaseId,
        tableSlug,
      });
      broadcast(getRecordKey);
      broadcast(getRecordWithSqlKey);
      broadcast(getSoftDeletedRecordKey);

      return invalidateAll(
        client,
        getRecordKey,
        getRecordWithSqlKey,
        getSoftDeletedRecordKey
      );
    },
  };
};

export { useUpdateRecordMutation };
