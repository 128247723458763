type BotsDependencies = { teamSlug: string; databaseId: string };

type GetBotListDependencies = {
  limit?: number;
  skip?: number;
} & BotsDependencies;

type FindBotDependencies = { botId: string } & BotsDependencies;

const botKeys = {
  all: ({ teamSlug, databaseId }: BotsDependencies) =>
    ["bots", teamSlug, databaseId] as const,

  /**
   * list
   */
  allGetBotList: ({ teamSlug, databaseId }: BotsDependencies) =>
    [...botKeys.all({ teamSlug, databaseId }), "getBotList"] as const,

  getBotList: ({ teamSlug, databaseId, limit, skip }: GetBotListDependencies) =>
    [
      ...botKeys.allGetBotList({ teamSlug, databaseId }),
      { limit, skip },
    ] as const,

  /**
   * get
   */
  allFindBot: ({ teamSlug, databaseId }: BotsDependencies) =>
    [...botKeys.all({ teamSlug, databaseId }), "findBot"] as const,
  findBot: ({ teamSlug, databaseId, botId }: FindBotDependencies) =>
    [...botKeys.allFindBot({ teamSlug, databaseId }), botId] as const,
};

export { botKeys };
