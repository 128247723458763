import * as Form from "@radix-ui/react-form";
import {
  SimpleField,
  WidgetDataAggregateRecordRequestBody,
} from "@usemorph/morph-dashboard-types";
import { useState } from "react";
import { MultipleFilterSelect } from "../common/MultipleFilterSelect";
import { MultipleSortSelect } from "../common/MultipleSortSelect";
import { AggregationForm } from "../common/AggregationForm";
import { EmptyAggregationUnit } from "~/utils/aggregateUtils";
import { SelectForm } from "../common/SelectForm";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { Text } from "~/components_next/Text";
import { Flex } from "@radix-ui/themes";
import { sortObjectUtils } from "~/utils/sortObjectUtils";

export const AggregateForm = ({
  defaultBody,
  fields,
  onChange,
}: {
  defaultBody?: WidgetDataAggregateRecordRequestBody;
  fields: SimpleField[];
  onChange: (body: WidgetDataAggregateRecordRequestBody) => void;
}) => {
  const [body, setBody] = useState<WidgetDataAggregateRecordRequestBody>(
    defaultBody ?? {
      aggregation: EmptyAggregationUnit({
        key: fields[0].name,
      }),
      groupKeys: [],
    }
  );

  return (
    <Form.Field name="aggregation" style={{ width: "100%" }}>
      <Flex direction="column" my="5" gap="5">
        <InputStack>
          <Text fontWeight="medium">
            <Text>
              Aggregation <span style={{ color: "red" }}>*</span>
            </Text>
          </Text>
          <AggregationForm
            defaultValue={defaultBody?.aggregation ?? body.aggregation}
            fields={fields}
            onChange={(e) => {
              setBody({ ...body, aggregation: e });
              onChange({ ...body, aggregation: e });
            }}
          />
        </InputStack>

        <InputStack>
          <Text fontWeight="medium">
            <Text>
              Group keys <span style={{ color: "red" }}>*</span>
            </Text>
          </Text>
          <SelectForm
            defaultValue={defaultBody?.groupKeys ?? body.groupKeys}
            fields={fields}
            onChange={(e) => {
              setBody({ ...body, groupKeys: e });
              onChange({ ...body, groupKeys: e });
            }}
          />
        </InputStack>
        <InputStack>
          <Text fontWeight="medium">Filter</Text>
          <MultipleFilterSelect
            filter={body.filter}
            fields={fields}
            onChange={(filter) => {
              setBody({ ...body, filter });
              onChange({ ...body, filter });
            }}
          />
        </InputStack>

        <InputStack>
          <Text fontWeight="medium">Sort</Text>
          <MultipleSortSelect
            sort={sortObjectUtils.getRecordSortConditionUnits(body.sort)}
            fields={fields}
            onChange={(sort) => {
              setBody({ ...body, sort });
              onChange({ ...body, sort });
            }}
          />
        </InputStack>
      </Flex>
    </Form.Field>
  );
};
