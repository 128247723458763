import {
  SimpleField,
  RecordFormatCondition,
} from "@usemorph/morph-dashboard-types";

export const FormatForm = ({
  defaultValue,
  fields,
  onChange,
}: {
  defaultValue?: RecordFormatCondition[];
  fields: SimpleField[];
  onChange?: (format: RecordFormatCondition[] | undefined) => void;
}) => {
  const getDefaultValue = (defaultValue: RecordFormatCondition[]) => {
    return defaultValue.reduce(
      (acc: { label: string; value: string }[], val: RecordFormatCondition) => {
        const field = fields.find((field) => field.name === val.column);
        if (field) {
          return [
            ...acc,
            {
              label: field.displayName ?? field.name,
              value: field.name,
            },
          ];
        } else {
          return acc;
        }
      },
      []
    );
  };

  return (
    <>
      {/* <MultiSelect
        variant="primary"
        options={fields.map((field) => {
          return {
            label: field.displayName ?? field.name,
            value: field.name,
          };
        })}
        value={defaultValue ? getDefaultValue(defaultValue) : undefined}
        placeholder={""}
        onChange={(e) => {
          if (onChange) {
            onChange(
              e.map((_e) => {
                return {
                  action: "mask",
                  column: _e.value,
                };
              })
            );
          }
        }}
      /> */}
    </>
  );
};
