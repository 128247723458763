import { SmartFieldDateDisplaySetting } from "@usemorph/morph-dashboard-types";
import { DateDisplaySettingClientModel } from "./DateDisplaySettingClientModel";

export class DateDisplaySettingClientModelDefactory {
  public static toSmartFieldDateDisplaySetting(
    smartFieldNumberDisplaySetting: DateDisplaySettingClientModel
  ): SmartFieldDateDisplaySetting {
    return {
      parts: smartFieldNumberDisplaySetting.data.parts,
      dateFormat: smartFieldNumberDisplaySetting.data.dateFormat,
      timeFormat: smartFieldNumberDisplaySetting.data.timeFormat,
    };
  }
}
