import { notebookKeys } from "../keys";
import useApiNotebook from "~/api/useApiNotebook";
import { useQueryClient } from "react-query";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import { DashboardNotebookCellSourceObject } from "@usemorph/morph-dashboard-types";

interface UseCreateNotebookCellMutationParams {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
}

type CreateNotebookCellMutationProps = {
  cellType: string;
  cellName: string;
  source: DashboardNotebookCellSourceObject;
  afterCellId?: string | null;
  requestId?: string;
  parentIds?: string[] | null;
  settings: any;
};

const useCreateNotebookCellMutaiton = ({
  databaseId,
  teamSlug,
  notebookId,
}: UseCreateNotebookCellMutationParams) => {
  const { _createNotebookCell } = useApiNotebook();

  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      cellType,
      cellName,
      source,
      afterCellId,
      requestId,
      parentIds,
      settings,
    }: CreateNotebookCellMutationProps) => {
      return _createNotebookCell({
        teamSlug,
        notebookId,
        cellType,
        cellName,
        source,
        afterCellId,
        requestId,
        parentIds,
        settings,
      });
    },
    onSuccess: () => {
      const keys = notebookKeys.findNotebook({
        databaseId,
        teamSlug,
        notebookId,
      });

      broadcast(keys);

      return invalidateAll(client, keys);
    },
  };
};

export { useCreateNotebookCellMutaiton, type CreateNotebookCellMutationProps };
