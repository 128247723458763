import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { RecordClientModel } from "../record";
import { RecordEntryClientModel } from "../record/recordEntry";

type CreatingRecordClientModelData = {
  creatingRecord: RecordClientModel;
};

export class CreatingRecordClientModel {
  public constructor(readonly data: CreatingRecordClientModelData) {}

  public get creatingRecord(): RecordClientModel {
    return this.data.creatingRecord;
  }

  public selectCreatingRecordEntry(key: string): RecordEntryClientModel | null {
    return this.data.creatingRecord.selectEntry(key);
  }

  public selectCreatingRecordEntryOrThrow(key: string): RecordEntryClientModel {
    return this.data.creatingRecord.selectEntryOrThrow(key);
  }

  public updateRecordEntry(
    entry: RecordEntryClientModel
  ): CreatingRecordClientModel {
    return new CreatingRecordClientModel({
      creatingRecord: new RecordClientModel({
        entries: this.data.creatingRecord.data.entries.map((e) =>
          e.key === entry.key ? entry : e
        ),
        recordIdentifier: this.data.creatingRecord.data.recordIdentifier,
      }),
    });
  }

  public isCreatingRecordValid(fields: FieldsClientModel): boolean {
    return this.data.creatingRecord.data.entries.every((entry) => {
      const field = fields.getFieldByFieldNameOrThrow(entry.key);

      if (field.isAutoGenerated) {
        return true;
      }

      return entry.getValidatedValue(field).isValid;
    });
  }
}
