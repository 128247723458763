import {
  DownloadRecordsPreviewWithQuery,
  DownloadConditions,
} from "./DownloadRecordsPreviewWithQuery";
import { DownloadDrawerTitle } from "./DownloadDrawerTitle";
import { SimpleField } from "@usemorph/morph-dashboard-types";
import { Drawer } from "~/components_next/Drawer";

type DownloadRecordsWithQueryDrawerProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onClickDownload: () => void;
  isDownloading: boolean;
  tableSlug: string;
  allSimpleFields: SimpleField[];
  downloadConditions: DownloadConditions;
  onChangeCondition: (downloadConditions: DownloadConditions) => void;
};

const DownloadRecordsWithQueryDrawer = (
  props: DownloadRecordsWithQueryDrawerProps
) => {
  const {
    isOpen,
    onOpenChange,
    tableSlug,
    onClickDownload,
    isDownloading,
    downloadConditions,
    onChangeCondition,
    allSimpleFields,
  } = props;

  return (
    <Drawer.Root open={isOpen} onOpenChange={onOpenChange} size="xl">
      <Drawer.Content>
        <Drawer.Title>
          <DownloadDrawerTitle
            onClickDownload={onClickDownload}
            isDownloading={isDownloading}
            isDisabled={downloadConditions.selectedFields.length === 0}
          />
        </Drawer.Title>
        <Drawer.Body>
          <DownloadRecordsPreviewWithQuery
            mainTableSlug={tableSlug}
            onChangeCondition={onChangeCondition}
            downloadConditions={downloadConditions}
            allSimpleFields={allSimpleFields}
          />
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};

export { DownloadRecordsWithQueryDrawer };
