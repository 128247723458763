import { ReactNode } from "react";
import { Flex } from "~/components_next/Flex";

type FloatingItemsLayoutProps = {
  children: ReactNode;
};

const FloatingItemsLayout = ({ children }: FloatingItemsLayoutProps) => {
  return (
    <Flex position="absolute" gap="4" style={{ right: 32, bottom: 64 }}>
      {children}
    </Flex>
  );
};

export { FloatingItemsLayout };
