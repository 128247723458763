import { useMutation } from "react-query";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import { CanvasCellClientModelFactory } from "~/clientModel/canvas/CanvasCellClientModel";
import { useComposeExecutable } from "~/clientModel/executable";
import { useUpdateNotebookCellMutaiton } from "~/serverStateStore";

const useUpdateCellWithoutRunExecutable = (props: {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
  onUpdateLiveCell: (cell: CanvasCellClientModelUnion) => void;
}) => {
  const { teamSlug, databaseId, notebookId, onUpdateLiveCell } = props;

  const updateCellMutationOptions = useUpdateNotebookCellMutaiton({
    teamSlug,
    databaseId,
    notebookId,
  });

  return useComposeExecutable(
    useMutation({
      ...updateCellMutationOptions,
      mutationFn: async (cellClientModel: CanvasCellClientModelUnion) => {
        const rawResponse = await updateCellMutationOptions.mutationFn({
          cellId: cellClientModel.cellId,
          cellType: cellClientModel.cellType,
          source: cellClientModel.source,
          isPublic: cellClientModel.isPublic,
          andRun: false,
          shouldGenerate: false,
        });
        return CanvasCellClientModelFactory.fromNotebookCellResponse(
          rawResponse
        );
      },
      onSuccess: (updatedCell) => {
        onUpdateLiveCell(updatedCell);
        return updateCellMutationOptions.onSuccess();
      },
    })
  );
};

export const createUseUpdateCellWithoutRunExecutable = (props: {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
  onUpdateLiveCell: (cell: CanvasCellClientModelUnion) => void;
}) => {
  const { teamSlug, databaseId, notebookId, onUpdateLiveCell } = props;

  return () =>
    useUpdateCellWithoutRunExecutable({
      teamSlug,
      databaseId,
      notebookId,
      onUpdateLiveCell,
    });
};
