import { templateKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiTemplate from "~/api/useApiTemplate";

interface UseListDynamicValueMutationProps {
  integrationId: string;
  templateId: string;
  teamSlug: string;
  targetKey?: string;
  dependentValues?: { [key: string]: unknown; }
  templateAuthId?: string;
}

const useListDynamicValueQuery = (props: UseListDynamicValueMutationProps) => {
  const { integrationId, templateId, teamSlug, targetKey, dependentValues, templateAuthId } = props

  const { _listDynamicValue } = useApiTemplate();

  const client = useQueryClient();
  client.setQueryDefaults(
    templateKeys.listDynamicValue({ integrationId, templateId, teamSlug, targetKey, dependentValues, templateAuthId }),
    commonQueryOptions
  );

  return {
    queryKey: templateKeys.listDynamicValue({ integrationId, templateId, teamSlug, targetKey, dependentValues, templateAuthId }),
    queryFn: () => {
      return _listDynamicValue({
        integrationId,
        templateId,
        teamSlug,
        targetKey,
        dependentValues,
        templateAuthId,
      })
    },
  };
};

export { useListDynamicValueQuery };
