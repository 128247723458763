import { useParams } from "react-router-dom";

const useDatabaseId = () => {
  const { databaseId } = useParams();

  if (!databaseId) {
    throw new Error(`databaseId not found.`);
  }

  return databaseId;
};

export { useDatabaseId };
