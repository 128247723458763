import { Box } from "@radix-ui/themes";
import { styled } from "@stitches/react";
import { ComponentPropsWithoutRef } from "react";

type FontColorDisplayBoxProps = {
  color?: string;
  size: ComponentPropsWithoutRef<typeof Box>["height"] &
    ComponentPropsWithoutRef<typeof Box>["width"];
};

const RoundedBox = styled(Box, {
  borderRadius: "10%",
  borderColor: "$border",
  borderWidth: 1,
  borderStyle: "solid",
});

const FontColorDisplayBox = ({ color, size }: FontColorDisplayBoxProps) => {
  const gray = "rgba(113, 128, 150, 0.7)";

  const colorBoxStyle = color
    ? { backgroundColor: color }
    : {
        backgroundImage: `linear-gradient(45deg, ${gray} 25%, transparent 25%, transparent 75%, ${gray} 75%, ${gray}), 
                        linear-gradient(45deg, ${gray} 25%, transparent 25%, transparent 75%, ${gray} 75%, ${gray})`,
        backgroundSize: "8px 8px",
        backgroundPosition: "0 0, 4px 4px",
      };

  return <RoundedBox style={colorBoxStyle} width={size} height={size} />;
};

export { FontColorDisplayBox };
