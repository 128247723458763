import { ScrollArea } from "@radix-ui/themes";
import { useEffect, useMemo, useRef, useState } from "react";
import { FindDashboardPageClientModel } from "~/clientModel/canvas/FindDashboardPageClientModel/FindDashboardPageClientModel";
import { Box } from "~/components_next/Box";
import { useDebounceState } from "~/hooks/useDebounceState";
import GridLayout, { Layout } from "react-grid-layout";
import { DashboardCellsClientModel } from "~/clientModel/canvas/DashboardCellClientModel";
import { Executable } from "~/clientModel/executable";
import { DashboardCellCardResizeHandle } from "../cell/common/DashboardCellCardResizeHandle";
import "./dashboard-grid-util.css";
import { DashboardCellCard } from "../cell/common/DashboardCellCard";
import { DashboardPageCell } from "../cell/DashboardPageCell";

type DashboardPageProps = {
  page: FindDashboardPageClientModel;
  updateLayoutExecutable: Executable<DashboardCellsClientModel, unknown>;
  removeCellExecutable: Executable<string, unknown>;
};

const DashboardPageContent = (
  props: DashboardPageProps & { width: number }
) => {
  const { page, width, updateLayoutExecutable, removeCellExecutable } = props;

  /**
   * Selection
   */
  const [selectedCellId, setSelectedCellId] = useState<string | null>(null);
  const handleCellClick = (cellId: string) => {
    if (selectedCellId !== cellId) setSelectedCellId(cellId);
  };

  /**
   * Layout
   */
  const layout = useMemo((): Layout[] => page.cells.layouts, [page]);
  const handleLayoutChange = (_layout: Layout[]) => {
    // 変更があったレイアウト要素のみを抽出して、サーバーにリクエストを送る
    updateLayoutExecutable.execute(page.cells.getLayoutsDiff(_layout));
  };

  return (
    <ScrollArea
      style={{ height: "calc(100vh - 80px)", paddingRight: "10px" }}
      onClick={() => setSelectedCellId(null)}
    >
      <GridLayout
        className="layout"
        cols={12}
        rowHeight={60}
        width={width - 10}
        layout={layout}
        onLayoutChange={handleLayoutChange}
        style={{
          marginBottom: "40px",
        }}
        draggableHandle=".page-cell-drag-handle"
        resizeHandles={["ne", "nw", "se", "sw"]}
        resizeHandle={<DashboardCellCardResizeHandle />}
      >
        {page.cells.cells.map((cell) => {
          return (
            <DashboardCellCard
              key={cell.cellId}
              id={cell.cellId}
              isSelected={cell.cellId === selectedCellId}
              onClick={(e) => {
                e.stopPropagation();
                handleCellClick(cell.cellId);
              }}
              className={`dashboard-cell-card ${
                cell.cellId === selectedCellId ? "selected" : ""
              }`}
            >
              <DashboardPageCell
                cellClientModel={cell}
                isSelected={cell.cellId === selectedCellId}
                removeCellExecutable={removeCellExecutable}
              />
            </DashboardCellCard>
          );
        })}
      </GridLayout>
    </ScrollArea>
  );
};

const DashboardPage = (props: DashboardPageProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [wrapperWidth, setWrapperWidth] = useState<number>(
    window.innerWidth - 300
  );
  const debouncedWidth = useDebounceState(wrapperRef.current?.clientWidth, 250);

  useEffect(() => {
    calcWidth();
  }, [debouncedWidth]);

  const calcWidth = () => {
    if (wrapperRef.current && wrapperRef.current.clientWidth > 0) {
      setWrapperWidth(wrapperRef.current.clientWidth);
    }
  };

  return (
    <Box ref={wrapperRef}>
      <DashboardPageContent
        key={`${props.page.pageId}-${wrapperWidth}`}
        width={wrapperWidth}
        {...props}
      />
    </Box>
  );
};

export { DashboardPage, type DashboardPageProps };
