import { IframeHTMLAttributes, useState } from "react";
import { Text } from "~/components_next/Text";
import { v4 as uuidv4 } from "uuid";
import { Box, Flex } from "@radix-ui/themes";
import { styled } from "~/stitches";
import { HTMLPreviewToolbar } from "./HTMLPreviewToolbar";

const PreviewContainer = styled(Box, {
  border: "1px solid $border",
  overflow: "hidden",
});

type HTMLPreviewProps = {
  value: string | null | undefined;
  height: IframeHTMLAttributes<HTMLIFrameElement>["height"];
};

const HTMLPreview = (props: HTMLPreviewProps) => {
  const { value, height } = props;

  const [iframeId] = useState(() => `iframe-${uuidv4()}`);

  const [reloading, setReloading] = useState(false);
  const handleReload = () => {
    setReloading(true);
    setTimeout(() => {
      setReloading(false);
    });
  };

  return (
    <PreviewContainer style={{ height }} position="relative">
      <HTMLPreviewToolbar onReload={handleReload} />
      {value != null ? (
        <iframe
          sandbox="allow-scripts"
          srcDoc={reloading ? "" : value}
          width="100%"
          height="100%"
          style={{
            overflow: "auto",
            border: "none",
          }}
          id={iframeId}
          loading="lazy"
        />
      ) : (
        <Flex style={{ height: "200px" }} align="center" justify="center">
          <Text variant="description">Empty</Text>
        </Flex>
      )}
    </PreviewContainer>
  );
};

export { HTMLPreview };
