import { useQuery } from "react-query";
import { useComposeLoadable } from "~/clientModel/loadable";

import { useListSuggestPromptForGenerateSQLQuery } from "~/serverStateStore";

type UseSuggestedPromptsLoadableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

export const useSuggestedPromptsLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseSuggestedPromptsLoadableParams) => {
  return useComposeLoadable(
    useQuery({
      ...useListSuggestPromptForGenerateSQLQuery({
        teamSlug,
        databaseId,
        tableSlug,
        language: navigator.language,
      }),
      retry: false,
    })
  );
};
