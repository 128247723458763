import { useMutation } from "react-query";
import { composeExecutable } from "~/clientModel/executable";
import { useDeleteMyColorMutation } from "~/serverStateStore/canvasStyle";

export const createUseDeleteMyColorsExecutable = ({
  teamSlug,
}: {
  teamSlug: string;
}) => {
  return () => {
    return composeExecutable(
      useMutation(useDeleteMyColorMutation({ teamSlug }))
    );
  };
};
