import { useEditorOrThrow } from "../utils";

const useUndo = () => {
  const editor = useEditorOrThrow();

  const handleUndo = () => {
    editor.chain().focus().undo().run();
  };

  const isDisabled = !editor.can().chain().focus().undo().run();

  return {
    handleUndo,
    isDisabled,
  };
};

export { useUndo };
