import { ContextMenu } from "@radix-ui/themes";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
import { styled } from "~/stitches";

const ColorChip = ({ colorCode }: { colorCode: string }) => {
  return (
    <Box
      css={{
        width: "20px",
        height: "20px",
        borderRadius: "100%",
        backgroundColor: colorCode,
      }}
    />
  );
};

const StyleChipWrapper = styled(Flex, {
  cursor: "pointer",
  borderRadius: "var(--radius-4)",
  border: "solid 1px $slate4",
  "&:hover": {
    border: "solid 1px $slate8",
  },
  variants: {
    selected: {
      true: {
        boxShadow: "var(--shadow-1)",
        border: "solid 1px $slate12",
        "&:hover": {
          border: "solid 1px $slate12",
        },
      },
    },
  },
});

const StyleChip = (props: {
  colorCodes: string[];
  isSelected: boolean;
  onClick?: () => void;
  onDelete?: () => void;
}) => {
  const { isSelected, onClick, onDelete } = props;
  return (
    <ContextMenu.Root>
      <ContextMenu.Trigger>
        <StyleChipWrapper selected={isSelected} gap="2" p="1" onClick={onClick}>
          {props.colorCodes.map((colorCode) => {
            return <ColorChip key={colorCode} colorCode={colorCode} />;
          })}
        </StyleChipWrapper>
      </ContextMenu.Trigger>
      <ContextMenu.Content>
        <ContextMenu.Item onSelect={onDelete} color="red">
          Delete
        </ContextMenu.Item>
      </ContextMenu.Content>
    </ContextMenu.Root>
  );
};

export { StyleChip };
