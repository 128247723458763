import { DashboardViewResponse } from "@usemorph/morph-dashboard-types";
import { useMemo, useState } from "react";
import { useErrorToast } from "~/components_next/Error";
import { DownloadRecordsWithQueryDrawer } from "~/features/DownloadRecords";
import { DownloadButton } from "../../../common/components/atoms/DownloadButton";
import { useDownloadRecordsWithQuery } from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useViewId } from "~/utilHooks/useViewId";
import { useFilterCondition } from "../../states/filterCondition";
import { useSortCondition } from "../../states/sortCondition";
import { useSkip } from "../../states/skip";
import { useLimit } from "../../states/limit";
import { DownloadConditions } from "~/features/DownloadRecords/DownloadRecordsPreviewWithQuery";
import { useViewFields } from "~/serverStateStore/views";
import { convertUnderscoreNotatedSimpleFieldsToSimpleFields } from "~/features/Fields/utils/converSimpleField";
import { useView } from "~/features/SourceAndViews/common/utils/useView";
import { useDisclosure } from "~/hooks/useDisclosure";
import { sortObjectUtils } from "~/utils/sortObjectUtils";

type ViewWithQueryConditionsDownloadRecordsWithQueryButtonProps = {
  view: DashboardViewResponse;
};

const ViewWithQueryConditionsDownloadRecordsWithQueryButton = (
  props: ViewWithQueryConditionsDownloadRecordsWithQueryButtonProps
) => {
  const { view } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  const mainTableSlug = view.tableSlug;

  const { errorToast } = useErrorToast({});

  const { data: viewData } = useView();
  const { data: viewFieldsData } = useViewFields({
    teamSlug,
    databaseId,
    viewId,
    viewData,
  });

  const viewFields = useMemo(() => {
    return viewFieldsData
      ? convertUnderscoreNotatedSimpleFieldsToSimpleFields(
          viewFieldsData.fields,
          mainTableSlug
        )
      : [];
  }, [mainTableSlug, viewFieldsData]);

  const [downloadConditions, setDownloadConditions] =
    useState<DownloadConditions>({ selectedFields: [], maskedFields: [] });

  const { refetch: downloadRecords, isLoading: isDownloading } =
    useDownloadRecordsWithQuery({
      teamSlug,
      databaseId,
      tableSlug: mainTableSlug,
      select: downloadConditions.selectedFields.map(
        ({ name, originalTableSlug }) =>
          originalTableSlug === mainTableSlug
            ? name
            : `${originalTableSlug}.${name}`
      ),
      format: downloadConditions.maskedFields.map(
        ({ name, originalTableSlug }) =>
          originalTableSlug === mainTableSlug
            ? { action: "mask", column: name }
            : { action: "mask", column: `${originalTableSlug}.${name}` }
      ),
      filter: downloadConditions.filter,
      sort: downloadConditions.sort,
      join: downloadConditions.join,
      skip: downloadConditions.skip,
      limit: downloadConditions.limit,
    });

  const handleDownloadClick = async () => {
    try {
      const response = await downloadRecords({ throwOnError: true });
      if (response.data) {
        window.open(response.data.url, "_blank");
      }
    } catch (e) {
      errorToast(e);
    }
  };

  const filterCondition = useFilterCondition(viewId);
  const sortCondition = useSortCondition(viewId);
  const limit = useLimit(viewId);
  const skip = useSkip(viewId);

  const handleOpen = () => {
    const selectedFields = viewFields.filter(
      (field) => field.isHidden !== true
    );
    setDownloadConditions({
      selectedFields,
      join: view.condition.join,
      filter: {
        and: [filterCondition, view.condition.filter].flatMap((f) =>
          f ? [f] : []
        ),
      },
      sort: [
        // 一時的にembeddingを無視する
        ...(sortObjectUtils.getRecordSortConditionUnits(view.condition.sort) ??
          []),
        sortCondition,
      ].flatMap((s) => (s ? [s] : [])),
      limit,
      skip,
      maskedFields: [],
    });
    onOpen();
  };

  const { isOpen, setIsOpen, onOpen } = useDisclosure();

  return (
    <>
      <DownloadButton
        onClick={handleOpen}
        isDisabled={viewFields.length === 0}
      />
      <DownloadRecordsWithQueryDrawer
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        tableSlug={mainTableSlug}
        downloadConditions={downloadConditions}
        onChangeCondition={setDownloadConditions}
        onClickDownload={handleDownloadClick}
        isDownloading={isDownloading}
        allSimpleFields={viewFields}
      />
    </>
  );
};

export { ViewWithQueryConditionsDownloadRecordsWithQueryButton };
