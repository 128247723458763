import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiRssFeed from "~/api/useApiRssFeed";
import { rssFeedKeys } from "../keys";

type UseRssFindLinkedToTableQueryParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useFindRssLinkedToTableQuery = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseRssFindLinkedToTableQueryParams) => {
  const { _findRssLinkedToTable } = useApiRssFeed();

  const client = useQueryClient();
  client.setQueryDefaults(
    rssFeedKeys.allFindRssLinkedToTable({
      teamSlug,
      databaseId,
      tableSlug,
    }),
    commonQueryOptions
  );

  return {
    queryKey: rssFeedKeys.allFindRssLinkedToTable({
      teamSlug,
      databaseId,
      tableSlug,
    }),
    queryFn: () => {
      return _findRssLinkedToTable({
        teamSlug,
        databaseId,
        tableSlug,
      });
    },
  };
};

export { useFindRssLinkedToTableQuery };
