import { BsThreeDotsVertical, BsTrash } from "react-icons/bs";
import { useMutation, useQuery } from "react-query";
import {
  useDeleteNotebookMutaiton,
  useFindNotebookQuery,
  useGetTableQuery,
  useUpdateNotebookMutaiton,
} from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useNotebookId } from "~/utilHooks/useNotebookId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { ToolbarWrapper } from "../components/atoms/ToolbarWrapper";
import { NotebookMetaInput } from "./NotebookMetaInput";
import { useState } from "react";
import { useErrorToast } from "~/components_next/Error";
import { useNavigate } from "react-router-dom";
import { PresenceIcons } from "../../RealtimeCollaboration";
import { NotebookToolbarShare } from "./NotebookToolbarShare";
import { getPath } from "~/routing";
import { Badge } from "~/components_next/Badge";
import { Text } from "~/components_next/Text";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { IconButton } from "~/components_next/IconButton";
import { Spacer } from "~/components_next/Spacer";
import { Button } from "~/components_next/Button";
import { Divider } from "~/components_next/Divider";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
import {
  useNotebookTabState,
  useSetNotebookTabState,
} from "~/features/FlipNotebook/state/notebookTabState";
import { styled } from "~/stitches";
import { SimpleTabs } from "~/components_next/Tabs";
import { NotebookToolbarPublish } from "./NotebookToolbarPublish";
import { NotebookToolbarModeTabs } from "./NotebookToolbarModeTabs";

const StyledButton = styled(Button);

const NotebookToolbar = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const notebookId = useNotebookId();

  const { errorToast } = useErrorToast({});

  const { data: notebook } = useQuery({
    ...useFindNotebookQuery({
      teamSlug,
      databaseId,
      notebookId,
    }),
    onSuccess(data) {
      setNotebookMeta({
        notebookName: data.notebookName,
        description: data.description,
      });
    },
  });

  /**
   * Usage
   */
  // const { data: usage } = useQuery(useGetNotebookUsageQuery({ teamSlug }));
  // const usageValue = useMemo(() => {
  //   if (usage) {
  //     return Math.round((usage.usage / usage.maxUsage) * 100);
  //   }
  //   return undefined;
  // }, [usage]);

  // const usageColorScheme = useMemo(() => {
  //   if (!usageValue) return undefined;

  //   if (usageValue > 90) {
  //     return "red";
  //   }
  //   if (usageValue > 60) {
  //     return "yellow";
  //   }
  //   return "green";
  // }, [usageValue]);

  /**
   * Update
   */
  const { mutateAsync: updateNotebook, isLoading: isUpdatingNotebook } =
    useMutation({
      ...useUpdateNotebookMutaiton({
        teamSlug,
        databaseId,
        notebookId,
      }),
    });

  const [notebookMeta, setNotebookMeta] = useState<{
    notebookName: string;
    description: string | null;
  }>({ notebookName: "", description: null });

  const handleSaveClicked = async () => {
    updateNotebook({
      notebookName: notebookMeta.notebookName,
      description: notebookMeta.description || "",
      publicApiKeyType: "auth",
    });
  };

  /**
   * Delete
   */
  const navigate = useNavigate();

  const { mutateAsync: deleteNotebook, isLoading: isDeleting } = useMutation({
    ...useDeleteNotebookMutaiton({
      teamSlug,
      databaseId,
    }),
  });

  const handleDeleteClicked = async () => {
    try {
      await deleteNotebook({
        notebookId,
      });
      if (notebook?.tableSlug) {
        navigate(
          getPath("source", {
            teamSlug,
            databaseId,
            tableSlug: notebook?.tableSlug,
          })
        );
      } else {
        navigate(getPath("databaseHome", { teamSlug, databaseId }));
      }
    } catch (e) {
      errorToast(e);
    }
  };

  const mainTableSlug = notebook?.tableSlug;

  const { data: mainTableData } = useQuery({
    ...useGetTableQuery({
      teamSlug,
      databaseId,
      tableSlug: mainTableSlug as string,
    }),
    enabled: !!mainTableSlug,
  });

  // Tab Mode
  const tabState = useNotebookTabState(notebookId);
  const setTabState = useSetNotebookTabState(notebookId);

  if (!notebook) return null;

  return (
    <ToolbarWrapper key={notebookId}>
      {notebook && (
        <>
          <Badge variant="secondary">Canvas</Badge>
          <Text>{notebook.notebookName}</Text>
          <SimpleDropdownMenu
            trigger={
              <IconButton
                icon={<BsThreeDotsVertical />}
                tooltip="Notebook Settings"
              />
            }
          >
            <Flex direction="column" gap="2" p="1">
              {/* <Flex align="end" gap="2">
                <Input
                  variant="primary"
                  label="Source"
                  value={mainTableData?.displayName ?? ""}
                  readOnly
                />
                <Link
                  to={getPath("source", {
                    teamSlug,
                    databaseId,
                    tableSlug: notebook.tableSlug,
                  })}
                >
                  <IconButton
                    icon={<BsDatabase />}
                    tooltip="Go to source"
                    size="sm"
                  />
                </Link>
              </Flex> */}
              <NotebookMetaInput
                notebookName={notebookMeta.notebookName}
                description={notebookMeta.description}
                onChange={(notebookMeta) => setNotebookMeta(notebookMeta)}
              />
              <Flex>
                <Spacer />
                <Button
                  variant="primary"
                  onClick={handleSaveClicked}
                  isLoading={isUpdatingNotebook}
                  size="sm"
                >
                  Save
                </Button>
              </Flex>

              <Divider />
              <Flex>
                <Spacer />
                <Button
                  variant="actionText"
                  onClick={handleDeleteClicked}
                  isLoading={isDeleting}
                  size="sm"
                >
                  <BsTrash />
                  Delete Notebook
                </Button>
              </Flex>
            </Flex>
          </SimpleDropdownMenu>

          <Spacer />
          <NotebookToolbarModeTabs />
          <Spacer />

          <PresenceIcons maxAvatars={3} />
          <SimpleDropdownMenu
            trigger={
              <Button variant="primary" size="sm">
                Share
              </Button>
            }
          >
            <SimpleTabs tabLabels={["Share", "Publish"]}>
              <Box p="2">
                <NotebookToolbarShare />
              </Box>
              <Box py="2">
                <NotebookToolbarPublish />
              </Box>
            </SimpleTabs>
          </SimpleDropdownMenu>
          {/* {usageValue != null && usage && (
            <Tooltip label={`Notebook Usage: ${usage.usage}/${usage.maxUsage}`}>
              <Flex width="160px" gap={2.5} alignItems="center">
                <Text variant="description">
                  <BsActivity />
                </Text>
                <Progress
                  flex={1}
                  colorScheme={usageColorScheme}
                  size="sm"
                  value={usageValue}
                  rounded="md"
                />
              </Flex>
            </Tooltip>
          )} */}
        </>
      )}
    </ToolbarWrapper>
  );
};

export { NotebookToolbar };
