import {
  useRecordsTableBaseFields,
  useRecordsTableBaseHandleCellEditingEnd,
  useRecordsTableBaseUseUploadFileExecutable,
} from "../context/ReactTableBaseContext";
import { useMemo, useRef } from "react";
import { Box } from "@radix-ui/themes";
import { useClickAway } from "react-use";
import { TableSelectionClientModel } from "~/clientModel/tables/tableMeta/TableSelection";
import { match } from "ts-pattern";
import { RecordEntryClientModel } from "~/clientModel/records/record/recordEntry";
import {
  RecordValueInput,
  RecordValueInputRefType,
} from "~/components_next/RecordValueInput";
import { useEditingCellPosition } from "./useEditingCellPosition";
import { styled } from "~/stitches";
import { FieldTypeClientModel } from "~/clientModel/fields/field/fieldType";
import { useTableSizings } from "../context/useTableSizing";

const StyledBox = styled(Box, {
  backgroundColor: "$bg0",
  position: "absolute",
  boxShadow: "var(--shadow-4)",
});

type EditingCellProps = {
  tableSelection: TableSelectionClientModel;
  onTableSelectionChange: (tableSelection: TableSelectionClientModel) => void;
};

const EditingCell = (props: EditingCellProps) => {
  const { tableSelection, onTableSelectionChange } = props;
  const fields = useRecordsTableBaseFields();
  const { handleCellEditingEnd } = useRecordsTableBaseHandleCellEditingEnd();
  const useUploadFileExecutable = useRecordsTableBaseUseUploadFileExecutable();
  const editingCellPosition = useEditingCellPosition();
  const { getRecordColumnWidth } = useTableSizings();

  const editingRecordState = tableSelection.taggedEditingRecord;
  const editingCellData = useMemo(() => {
    if (!editingRecordState.isEditing) {
      return null;
    }

    const editingCellField = fields.getFieldByFieldName(
      editingRecordState.editingRecordEntry.key
    );

    return editingCellField && editingCellPosition
      ? {
          editingCellField,
          editingRecordEntry: editingRecordState.editingRecordEntry,
          editingCellPosition,
        }
      : null;
  }, [editingCellPosition, editingRecordState, fields]);

  const handleInputChange = (editingRecordEntry: RecordEntryClientModel) => {
    onTableSelectionChange(
      tableSelection.updateEditingRecordEntry({
        recordEntry: editingRecordEntry,
      })
    );
  };

  const wrapperRef = useRef<HTMLDivElement>(null);
  useClickAway(wrapperRef, () => {
    // 応急処置
    // 外をクリックした判定になるものだと編集する前にセルが閉じてしまうので、
    // endCellEditingの対象外にしている

    const ignoreFieldTypes: readonly FieldTypeClientModel["type"][] = [
      "json",
      "array",
      "html",
    ] as const;

    if (
      editingCellData &&
      !ignoreFieldTypes.includes(editingCellData.editingCellField.type.type)
    ) {
      handleCellEditingEnd();
    }
  });

  const editingCellRef = (ref: RecordValueInputRefType) => {
    if (ref) {
      ref.focus();
    }
  };

  /**
   * Rendering
   */

  if (!editingCellData) {
    return null;
  }

  const columnWidth = getRecordColumnWidth(
    editingCellData.editingCellField.name
  );

  const editingCellStyles = match(editingCellData.editingCellField.type.type)
    .with("longText", () => ({
      width: columnWidth,
      minWidth: "400px",
    }))
    .with("image", () => ({
      width: columnWidth,
      minWidth: "300px",
      padding: "4px",
    }))
    .with("attachment", () => ({
      width: columnWidth,
      minWidth: "300px",
      padding: "4px",
    }))
    .otherwise(() => ({
      width: columnWidth,
      minWidth: "240px",
    }));

  return (
    <StyledBox
      ref={wrapperRef}
      css={{
        left: `${editingCellData.editingCellPosition.x}px`,
        top: `${editingCellData.editingCellPosition.y}px`,
        ...editingCellStyles,
      }}
    >
      <RecordValueInput
        ref={editingCellRef}
        editingRecordEntry={editingCellData.editingRecordEntry}
        onChange={handleInputChange}
        field={editingCellData.editingCellField}
        useUploadFileExecutable={useUploadFileExecutable}
        size="md"
      />
    </StyledBox>
  );
};

export { EditingCell };
