import { BsPlus } from "react-icons/bs";
import { match, P } from "ts-pattern";
import { UseExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";

import { FooterButtonBase } from "../common/FooterButtonBase";

type CreateFieldSidebarFooterProps = {
  creatingField: FieldClientModel;
  smartFunctions: SmartFunctionsClientModel;
  editingFieldsForUpdateMergeKey: FieldsClientModel;
  useCreateFieldExecutable: UseExecutable<void, { field: FieldClientModel }>;
  onCreateComputedFieldDialogOpen: () => unknown;
  onMakeAllFieldsNullableDialogOpen: () => unknown;
  onFieldCreated: () => unknown;
};

export const CreateFieldSidebarFooter = (
  props: CreateFieldSidebarFooterProps
) => {
  const {
    creatingField,
    smartFunctions,
    editingFieldsForUpdateMergeKey,
    useCreateFieldExecutable,
    onCreateComputedFieldDialogOpen,
    onMakeAllFieldsNullableDialogOpen,
    onFieldCreated,
  } = props;

  const createFieldExecutable = useCreateFieldExecutable();

  const handleCreateRecordButtonClick = async () => {
    const isSyncFieldCreated = match(creatingField.type.type)
      .with(
        P.union("syncValue", "generateText", "calculation", "aggregateValue"),
        () => true
      )
      .otherwise(() => false);
    if (isSyncFieldCreated) {
      if (
        editingFieldsForUpdateMergeKey
          .fieldsToBeNullableForCreatingComputedField.count > 0
      ) {
        onMakeAllFieldsNullableDialogOpen();
      } else {
        onCreateComputedFieldDialogOpen();
      }
    } else {
      await createFieldExecutable.execute({
        field: creatingField,
      });
      onFieldCreated();
    }
  };

  return (
    <FooterButtonBase
      onClick={handleCreateRecordButtonClick}
      rightIcon={<BsPlus />}
      isLoading={createFieldExecutable.isExecuting}
      isDisabled={
        !creatingField.isAllValid({
          fields: editingFieldsForUpdateMergeKey,
          smartFunctions,
        })
      }
    >
      Create
    </FooterButtonBase>
  );
};
