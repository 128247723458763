import { DashboardRegisterDataApiRequestBody } from "@usemorph/morph-dashboard-types";
import { useQueryClient } from "react-query";
import useApiDataApi from "~/api/useApiDataApi";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { dataApiKeys } from "../keys";

type ApiType =
  | "query"
  | "create"
  | "update"
  | "delete"
  | "upload"
  | "aggregate"
  | "download"
  | "chat";

type UseRegistDataApiMutationParams = {
  databaseId: string;
  teamSlug: string;
  tableSlug: string;
};

const useRegistDataApiMutation = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseRegistDataApiMutationParams) => {
  const { _registDataApi } = useApiDataApi();
  const client = useQueryClient();

  return {
    mutationFn: ({ requestBody }: {
      requestBody: DashboardRegisterDataApiRequestBody;
    }) => {
      return _registDataApi({ teamSlug, databaseId, tableSlug, requestBody });
    },
    onSuccess: () => {
      return invalidateAll(
        client,
        dataApiKeys.all({
          teamSlug,
          databaseId,
        })
      );
    },
  };
};

export { useRegistDataApiMutation };
export type { ApiType };
