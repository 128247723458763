import {
  DashboardUserObject,
  SimpleField,
  zDashboardUserObject,
} from "@usemorph/morph-dashboard-types";
import {
  RecordModelNullableValueTypes,
  RecordModelValueTypes,
} from "../types/RecordModelValueTypes";

/**
 * Utility functions
 */
const isNull = (value: unknown): value is null => {
  return value === null;
};

const isString = (value: unknown): value is string => {
  return typeof value === "string";
};

const isBoolean = (value: unknown): value is boolean => {
  return typeof value === "boolean";
};

const isUserObject = (value: unknown): value is DashboardUserObject => {
  return zDashboardUserObject.nonstrict().safeParse(value).success;
};

const isFileObject = (
  value: unknown
): value is { data: string; url: string } => {
  return (
    value !== null &&
    typeof value === "object" &&
    "data" in value &&
    typeof value.data === "string" &&
    "url" in value &&
    typeof value.url === "string"
  );
};

const isFileUrlObject = (
  // 明らかに場合が違うので、一応分けとく。外部URLを直接入力された場合。
  value: unknown
): value is { data: string; url: string } => {
  return (
    value !== null &&
    typeof value === "object" &&
    "url" in value &&
    typeof value.url === "string"
  );
};

/**
 * RecordModelValueTypesの型ガード
 */
const validationDict: {
  [Type in SimpleField["type"]]: (
    value: unknown
  ) => value is RecordModelValueTypes[Type];
} = {
  shortText: (value: unknown): value is string => {
    return isString(value);
  },

  longText: (value: unknown): value is string => {
    return isString(value);
  },

  richText: (value: unknown): value is string => {
    return isString(value);
  },

  number: (value: unknown): value is string => {
    return isString(value);
  },

  decimal: (value: unknown): value is string => {
    return isString(value);
  },

  bigNumber: (value: unknown): value is string => {
    return isString(value);
  },

  autoNumber: (value: unknown): value is string => {
    return isString(value);
  },

  autoBigNumber: (value: unknown): value is string => {
    return isString(value);
  },

  boolean: (value: unknown): value is boolean => {
    return isBoolean(value);
  },

  date: (value: unknown): value is string => {
    return isString(value);
  },

  datetime: (value: unknown): value is string => {
    return isString(value);
  },

  time: (value: unknown): value is string => {
    return isString(value);
  },

  email: (value: unknown): value is string => {
    return isString(value);
  },

  url: (value: unknown): value is string => {
    return isString(value);
  },

  phoneNumber: (value: unknown): value is string => {
    return isString(value);
  },

  image: (value: unknown): value is { data: string; url: string } => {
    return isFileObject(value) || isFileUrlObject(value);
  },

  attachment: (value: unknown): value is { data: string; url: string } => {
    return isFileObject(value) || isFileUrlObject(value);
  },

  formula: (value: unknown): value is unknown => {
    return true;
  },

  singleSelect: (value: unknown): value is string => {
    return isString(value);
  },

  multiSelect: (value: unknown): value is string[] => {
    return Array.isArray(value) && value.every((el) => isString(el));
  },

  json: (value: unknown): value is string => {
    return isString(value);
  },

  array: (value: unknown): value is unknown[] => {
    return Array.isArray(value);
  },

  lastEditedAt: (value: unknown): value is string => {
    return isString(value);
  },

  lastEditedBy: (value: unknown): value is DashboardUserObject => {
    return isUserObject(value);
  },

  createdAt: (value: unknown): value is string => {
    return isString(value);
  },

  createdBy: (value: unknown): value is DashboardUserObject => {
    return isUserObject(value);
  },

  multiReference: (value: unknown): value is unknown => {
    return true;
  },

  reference: (value: unknown): value is unknown => {
    return true;
  },

  html: (value: unknown): value is string => {
    return isString(value);
  },

  embeddings: (value: unknown): value is string => {
    return isString(value);
  },

  python: (value: unknown): value is unknown => {
    return true;
  },

  rowInfo: (value: unknown): value is unknown => {
    return true;
  },
};

/**
 * 値がfield typeの型として適当であるかを検査する。
 * フォーマットについてはformatCheck.tsの責務
 */

const validateNonNullableRecordModelValue = <Type extends SimpleField["type"]>(
  value: unknown,
  type: Type
): value is RecordModelValueTypes[Type] => {
  return validationDict[type](value);
};

const validateNullableRecordModelValue = <Type extends SimpleField["type"]>(
  value: unknown,
  type: Type
): value is RecordModelNullableValueTypes[Type] => {
  return isNull(value) || validationDict[type](value);
};

export {
  validateNonNullableRecordModelValue,
  validateNullableRecordModelValue,
};
