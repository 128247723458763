import { useMemo } from "react"
import { useTeamSlug } from "~/utilHooks/useTeamSlug"
import { NotificationPresenter } from "~/presenters/notification/NotificationPresenter"
import { createUseMarkAsReadNotificationExecutable } from "./createUseMarkAsReadNotificationExecutable"
import { createUseListNotificationLoadable } from "./createUseListNotificationLoadable"


export const NotificationContainer = () => {
  const teamSlug = useTeamSlug()

  const useListNotificationLoadable = useMemo(() => {
    return createUseListNotificationLoadable({ teamSlug })
  }, [teamSlug])

  const useMarkAsReadExecutable = useMemo(() => {
    return createUseMarkAsReadNotificationExecutable({ teamSlug })
  }, [teamSlug])

  return (
    <>
      <NotificationPresenter
        useListNotificationLoadable={useListNotificationLoadable}
        useMarkAsReadExecutable={useMarkAsReadExecutable}
      />
    </>
  )
}
