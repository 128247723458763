import { useMemo } from "react";
import { match } from "ts-pattern";
import { useBlockquote } from "./useBlockquote";
import { useBulletList } from "./useBulletList";
import { useCodeBlock } from "./useCodeBlock";
import { useHeading } from "./useHeading";
import { useOrderedList } from "./useOrderedList";
import { useParagraph } from "./useParagraph";

const optionsDict = {
  paragraph: "Paragraph",
  heading1: "Heading 1",
  heading2: "Heading 2",
  heading3: "Heading 3",
  heading4: "Heading 4",
  heading5: "Heading 5",
  heading6: "Heading 6",
  bulletList: "Bullet list",
  orderedList: "Ordered list",
  blockquote: "Blockquote",
  codeBlock: "Code block",
} as const;

type NodeOptionValue = keyof typeof optionsDict;
type NodeOptionLabel = (typeof optionsDict)[NodeOptionValue];
type NodeOption = { value: NodeOptionValue; label: NodeOptionLabel };

const useNodeOptions = () => {
  const {
    isActive: isActiveParagraph,
    handleSet: handleSetParagraph,
    isDisabled: isDisabledParagraph,
  } = useParagraph();

  const {
    isActive: isActiveHeading1,
    handleToggle: handleToggleH1,
    isDisabled: isDisabledH1,
  } = useHeading({ level: 1 });

  const {
    isActive: isActiveHeading2,
    handleToggle: handleToggleH2,
    isDisabled: isDisabledH2,
  } = useHeading({ level: 2 });

  const {
    isActive: isActiveHeading3,
    handleToggle: handleToggleH3,
    isDisabled: isDisabledH3,
  } = useHeading({ level: 3 });

  const {
    isActive: isActiveHeading4,
    handleToggle: handleToggleH4,
    isDisabled: isDisabledH4,
  } = useHeading({ level: 4 });

  const {
    isActive: isActiveHeading5,
    handleToggle: handleToggleH5,
    isDisabled: isDisabledH5,
  } = useHeading({ level: 5 });

  const {
    isActive: isActiveHeading6,
    handleToggle: handleToggleH6,
    isDisabled: isDisabledH6,
  } = useHeading({ level: 6 });

  const {
    isActive: isActiveBulletList,
    handleToggle: handleToggleBulletList,
    isDisabled: isDisabledBulletList,
  } = useBulletList();

  const {
    isActive: isActiveOrderedList,
    handleToggle: handleToggleOrderedList,
    isDisabled: isDisabledOrderedList,
  } = useOrderedList();

  const {
    isActive: isActiveBlockquote,
    handleToggle: handleToggleBlockquote,
    isDisabled: isDisabledBlockquote,
  } = useBlockquote();

  const {
    isActive: isActiveCodeBlock,

    handleToggle: handleToggleCodeBlock,
    isDisabled: isDisabledCodeBlock,
  } = useCodeBlock();

  const options: NodeOption[] = (
    Object.entries(optionsDict) as [NodeOptionValue, NodeOptionLabel][]
  ).flatMap(([value, label]) => {
    const isDisabledDict: Record<NodeOptionValue, boolean> = {
      paragraph: isDisabledParagraph,
      heading1: isDisabledH1,
      heading2: isDisabledH2,
      heading3: isDisabledH3,
      heading4: isDisabledH4,
      heading5: isDisabledH5,
      heading6: isDisabledH6,
      bulletList: isDisabledBulletList,
      orderedList: isDisabledOrderedList,
      blockquote: isDisabledBlockquote,
      codeBlock: isDisabledCodeBlock,
    };
    return isDisabledDict[value] ? [] : [{ value, label }];
  });

  const currentBlock: NodeOptionValue | null = useMemo(() => {
    const findValue = (value: string) =>
      options.find((option) => option.value === value)?.value ?? null;

    if (isActiveBulletList) return findValue("bulletList");
    if (isActiveOrderedList) return findValue("orderedList");
    if (isActiveBlockquote) return findValue("blockquote");
    if (isActiveCodeBlock) return findValue("codeBlock");
    if (isActiveParagraph) return findValue("paragraph");
    if (isActiveHeading1) return findValue("heading1");
    if (isActiveHeading2) return findValue("heading2");
    if (isActiveHeading3) return findValue("heading3");
    if (isActiveHeading4) return findValue("heading4");
    if (isActiveHeading5) return findValue("heading5");
    if (isActiveHeading6) return findValue("heading6");

    return null;
  }, [
    options,
    isActiveParagraph,
    isActiveHeading1,
    isActiveHeading2,
    isActiveHeading3,
    isActiveHeading4,
    isActiveHeading5,
    isActiveHeading6,
    isActiveBulletList,
    isActiveOrderedList,
    isActiveBlockquote,
    isActiveCodeBlock,
  ]);

  const handleSelect = (value: NodeOptionValue) => {
    if (value === currentBlock) {
      return;
    }

    match(value)
      .with("paragraph", () => {
        handleSetParagraph();
      })
      .with("heading1", () => {
        handleToggleH1();
      })
      .with("heading2", () => {
        handleToggleH2();
      })
      .with("heading3", () => {
        handleToggleH3();
      })
      .with("heading4", () => {
        handleToggleH4();
      })
      .with("heading5", () => {
        handleToggleH5();
      })
      .with("heading6", () => {
        handleToggleH6();
      })
      .with("bulletList", () => {
        handleToggleBulletList();
      })
      .with("orderedList", () => {
        handleToggleOrderedList();
      })
      .with("blockquote", () => {
        handleToggleBlockquote();
      })
      .with("codeBlock", () => {
        handleToggleCodeBlock();
      })
      .exhaustive();
  };

  return { options, currentBlock, handleSelect };
};

export { useNodeOptions };
export type { NodeOption, NodeOptionValue, NodeOptionLabel };
