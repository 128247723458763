import { useMemo, useState, useEffect, Fragment } from "react";
import {
  useRecordsKanbanDragFallbackMessage,
  useRecordsKanbanDraggingRecordId,
  useRecordsKanbanFlattenedRecords,
  useRecordsKanbanGroupingField,
  useRecordsKanbanOnUpdateRecord,
  useRecordsKanbanPropertyFieldNames,
  useRecordsKanbanSetDraggingGroupingValue,
  useRecordsKanbanSetDraggingRecordId,
  useRecordsKanbanTitleFieldName,
  useRecordsKanbanValues,
} from "../context/RecordsKanbanBaseContext";
import { RecordsKanbanColumn } from "./RecordsKanbanColumn";

import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { hasSortableData } from "@dnd-kit/sortable";
import { getPrimaryKeyValues } from "../utils/getPrimaryKeyValues";
import SimpleBar from "simplebar-react";
import { getRecordModelValueFromIdentifier } from "../utils/getRecordModelValueFromIdentifier";
import { RecordsKanbanHeaders } from "../RecordsKanbanHeader/RecordsKanbanHeaders";
import { styled } from "~/stitches";
import { Box, Flex } from "@radix-ui/themes";
import { RecordsKanbanCard } from "../RecordsKanbanCard/RecordsKanbanCard";
import { useToast } from "~/components_next/Toast";

const StyledFlex = styled(Flex, {});
const StyledBox = styled(Box, {});

const itemWidth = 300;

const RecordsKanbanColumns = () => {
  const values = useRecordsKanbanValues();
  const titleKey = useRecordsKanbanTitleFieldName();
  const propertyKeys = useRecordsKanbanPropertyFieldNames();

  const onUpdateRecord = useRecordsKanbanOnUpdateRecord();
  const dragFallbackMessage = useRecordsKanbanDragFallbackMessage();
  const groupingField = useRecordsKanbanGroupingField();
  const flattenedRecords = useRecordsKanbanFlattenedRecords();

  // local value
  const [localValues, setLocalValues] = useState(values);
  useEffect(() => {
    setLocalValues(values);
  }, [values]);

  const totalWidth = useMemo(() => {
    return values.length * (itemWidth + 20);
  }, [values]);

  // const sensors = useSensors(
  //   useSensor(PointerSensor),
  //   useSensor(KeyboardSensor, {
  //     coordinateGetter: sortableKeyboardCoordinates,
  //   })
  // );

  const toast = useToast();

  const draggingRecordId = useRecordsKanbanDraggingRecordId();
  const setDraggingRecordId = useRecordsKanbanSetDraggingRecordId();
  const draggingGroupingValue = useRecordsKanbanDraggingRecordId();
  const setDraggingGroupingValue = useRecordsKanbanSetDraggingGroupingValue();

  const draggingRecord = useMemo(() => {
    return values
      .map((value) => value.records)
      .flat()
      .find((record) => {
        return getPrimaryKeyValues(record) === draggingRecordId;
      });
  }, [values, draggingRecordId]);

  const moveGrouping = (
    targetRecordId: UniqueIdentifier,
    overRecordId: UniqueIdentifier,
    gropuingValue: UniqueIdentifier
  ) => {
    if (!draggingRecord) {
      return;
    }

    const newValue = localValues.map((kanbanValue) => {
      if (String(kanbanValue.groupingFieldValue) === draggingGroupingValue) {
        return {
          ...kanbanValue,
          records: kanbanValue.records.filter(
            (r) => getPrimaryKeyValues(r) !== targetRecordId
          ),
        };
      } else if (String(kanbanValue.groupingFieldValue) === gropuingValue) {
        const overIndex = kanbanValue.records.findIndex(
          (r) => getPrimaryKeyValues(r) === overRecordId
        );

        const targetColumnRecords = kanbanValue.records.filter(
          (r) => getPrimaryKeyValues(r) !== targetRecordId
        );

        const records = overIndex
          ? [
              ...targetColumnRecords.slice(0, overIndex),
              draggingRecord,
              ...targetColumnRecords.slice(overIndex),
            ]
          : [draggingRecord, ...targetColumnRecords];

        return {
          ...kanbanValue,
          records: records,
        };
      } else {
        const targetColumnRecords = kanbanValue.records.filter(
          (r) => getPrimaryKeyValues(r) !== targetRecordId
        );
        return {
          ...kanbanValue,
          records: targetColumnRecords,
        };
      }
    });

    setLocalValues(newValue);
  };

  const onDragStart = (event: DragStartEvent) => {
    if (!onUpdateRecord && dragFallbackMessage) {
      toast({
        title: dragFallbackMessage,
        type: "info",
      });
    }

    const recordId = event.active.id;
    if (hasSortableData(event.active)) {
      const groupingValue = event.active.data.current.sortable.containerId;
      setDraggingGroupingValue(groupingValue);
    }

    setDraggingRecordId(recordId);
  };

  const onDragOver = (event: DragOverEvent) => {
    if (!onUpdateRecord) {
      return;
    }
    const activeId = event.active.id;
    const overId = event.over?.id;

    if (!overId) {
      return;
    }

    if (!hasSortableData(event.over)) {
      // 空の列かもしれない
      const findGroupingValue = localValues.find(
        (localValue) => String(localValue.groupingFieldValue) === overId
      );
      if (findGroupingValue) {
        moveGrouping(activeId, overId, overId);
      }
      return;
    }

    const overGroupingValue = event.over.data.current.sortable.containerId;

    if (overGroupingValue === draggingGroupingValue) {
      setLocalValues(values);
      return;
    }

    moveGrouping(activeId, overId, overGroupingValue);
  };

  const onDragEnd = (event: DragEndEvent) => {
    setDraggingRecordId(null);
    setDraggingGroupingValue(null);

    const findRecord = flattenedRecords.find(
      (record) => getPrimaryKeyValues(record) === draggingRecordId
    );

    if (!onUpdateRecord || !hasSortableData(event.active) || !findRecord)
      return;

    const groupingValue = event.active.data.current.sortable.containerId;

    const recordValue = getRecordModelValueFromIdentifier(
      String(groupingValue),
      groupingField
    );

    const record = {
      ...findRecord,
      [groupingField.name]: recordValue,
    };

    const targetGroup = localValues.find(
      (value) => String(value.groupingFieldValue) === groupingValue
    );

    if (targetGroup) {
      const findIndex = targetGroup.records.findIndex(
        (record) => getPrimaryKeyValues(record) === draggingRecordId
      );
      if (findIndex === 0) {
        onUpdateRecord(record, null);
      } else if (findIndex > 0) {
        const prevRecord = targetGroup.records[findIndex - 1];

        onUpdateRecord(record, prevRecord);
      }
    }
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5,
    },
  });
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, keyboardSensor);

  return (
    <SimpleBar
      style={{
        width: "100%",
      }}
    >
      <RecordsKanbanHeaders width={itemWidth} localValues={localValues} />
      <DndContext
        collisionDetection={closestCorners}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDragEnd={onDragEnd}
        autoScroll={{ layoutShiftCompensation: false }}
        sensors={sensors}
      >
        <Flex width={`${totalWidth}px`} gap={"4"}>
          {localValues.map((value) => {
            return (
              <Fragment key={String(value.groupingFieldValue)}>
                <Box>
                  <RecordsKanbanColumn width={itemWidth} value={value} />
                </Box>
              </Fragment>
            );
          })}
        </Flex>

        <DragOverlay>
          {draggingRecord && (
            <RecordsKanbanCard
              record={draggingRecord}
              titleKey={titleKey}
              propertyKeys={propertyKeys}
            />
          )}
        </DragOverlay>
      </DndContext>
    </SimpleBar>
  );
};

export { RecordsKanbanColumns };
