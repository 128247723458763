import { useQuery } from "react-query";
import { ListDatabaseClientModelFactory } from "~/clientModel/database/listDatabaseClientModel";
import { useComposeLoadable } from "~/clientModel/loadable";
import { useListDatabasesQuery } from "~/serverStateStore";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";


export const useListDatabase = () => {
  const teamSlug = useTeamSlug();

  const listDatabaseQueryOptions = useListDatabasesQuery({ teamSlug: teamSlug, skip: 0, limit: 99999, })
  const listDatabaseQueryResult = useQuery({
    ...listDatabaseQueryOptions,
    select: (data) => {
      return ListDatabaseClientModelFactory.fromlistDatabaseResponse(data)
    }
  });

  const listDatabaseLoadable = useComposeLoadable(listDatabaseQueryResult)

  return {
    listDatabaseLoadable,
    listDatabaseQueryResult,
  }
}
