import {
  DashboardTableListResponse,
  DashboardTableResponse,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";
type DashboardGeneralResponse = {
  message: string;
};

export default function useApiTableFavorite() {
  const { executeRequest } = useApi();

  const _listTableFavorites = async ({
    teamSlug,
    databaseId,
  }: {
    teamSlug: string;
    databaseId: string;
  }): Promise<DashboardTableListResponse> => {
    return executeRequest(
      "get",
      `/${databaseId}/table-favorite`,
      undefined,
      {
        teamSlug,
      },
      {}
    );
  };

  const _createTableFavorite = async ({
    teamSlug,
    databaseId,
    tableSlug,
  }: {
    teamSlug: string;
    databaseId: string;
    tableSlug: string;
  }): Promise<DashboardTableResponse> => {
    return executeRequest(
      "post",
      `/${databaseId}/table-favorite/${tableSlug}`,
      undefined,
      {
        teamSlug,
      },
      {}
    );
  };

  const _deleteTableFavorite = async ({
    teamSlug,
    databaseId,
    tableSlug,
  }: {
    teamSlug: string;
    databaseId: string;
    tableSlug: string;
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest(
      "delete",
      `/${databaseId}/table-favorite/${tableSlug}`,
      undefined,
      {
        teamSlug,
      },
      {}
    );
  };

  return {
    _listTableFavorites,
    _createTableFavorite,
    _deleteTableFavorite,
  };
}
