import { DashboardImportIntegrationStatusResponse } from "@usemorph/morph-dashboard-types";
import { ImportIntegrationStatusClientModel } from "./ImportIntegrationStatusClientModel";

export const ImportIntegrationStatusClientModelFactory = {
  fromImportIntegrationStatus: (
    data: DashboardImportIntegrationStatusResponse
  ): ImportIntegrationStatusClientModel => {
    return new ImportIntegrationStatusClientModel(data)
  }
}
