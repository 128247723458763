import { DateDisplaySettingClientModel } from "~/clientModel/fields/field/displaySetting/date";
import { Flex } from "~/components_next/Flex";
import { SimpleSelect } from "~/components_next/Select";
import { DisplaySample } from "./DisplaySample";

type DateDisplaySettingInputProps = {
  value: DateDisplaySettingClientModel;
  onChange: (value: DateDisplaySettingClientModel) => void;
  isReadOnly?: boolean;
};

export const DateDisplaySettingInput = (
  props: DateDisplaySettingInputProps
) => {
  const { value, onChange, isReadOnly } = props;

  const handlePartsChange = (parts: DateDisplaySettingClientModel.Parts) => {
    onChange(value.updateParts(parts));
  };

  const handleDateFormatChange = (
    dateFormat: DateDisplaySettingClientModel.DateFormat
  ) => {
    onChange(value.updateDateFormat(dateFormat));
  };

  const handleTimeFormatChange = (
    timeFormat: DateDisplaySettingClientModel.TimeFormat
  ) => {
    onChange(value.updateTimeFormat(timeFormat));
  };

  return (
    <Flex direction="column" gap="4">
      <SimpleSelect
        size="sm"
        variant="primary"
        label="Parts"
        value={value.parts}
        options={value.partsOptions}
        isReadOnly={isReadOnly}
        onChange={(parts) => handlePartsChange(parts!)}
      />

      {value.hasDateParts && (
        <SimpleSelect
          size="sm"
          variant="primary"
          label="Date formate"
          value={value.dateFormat}
          options={value.dateFormatOptions}
          isReadOnly={isReadOnly}
          onChange={(dateFormat) => handleDateFormatChange(dateFormat!)}
        />
      )}

      {value.hasTimeParts && (
        <SimpleSelect
          size="sm"
          variant="primary"
          label="Time formate"
          value={value.timeFormat}
          options={value.timeFormatOptions}
          isReadOnly={isReadOnly}
          onChange={(timeFormat) => handleTimeFormatChange(timeFormat!)}
        />
      )}

      <DisplaySample>{value.displayExample}</DisplaySample>
    </Flex>
  );
};
