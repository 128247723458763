import { useEffect, useMemo } from "react";
import { Executable } from "~/clientModel/executable";
import { TableNameClientModel } from "~/clientModel/tables/tableName";
import { useDebounceState } from "~/hooks/useDebounceState";
import { Input } from "../Input";
import { TextArea } from "../TextArea";

type TableNameInputProps = {
  tableNameInstance: TableNameClientModel;
  onUpdateTableNameInstance: (tableNameInstance: TableNameClientModel) => void;
  checkTableSlugExecutable?: Executable<string, { isAvailable: boolean }>;
};

const TableNameInput = (props: TableNameInputProps) => {
  const {
    tableNameInstance,
    onUpdateTableNameInstance,
    checkTableSlugExecutable,
  } = props;

  const debouncedTableSlug = useDebounceState(tableNameInstance.tableSlug, 500);

  useEffect(() => {
    if (debouncedTableSlug && checkTableSlugExecutable) {
      checkTableSlugExecutable.execute(debouncedTableSlug);
    }
  }, [debouncedTableSlug]);

  const tableSlugErrors = useMemo(() => {
    if (checkTableSlugExecutable?.data?.isAvailable === false) {
      return [
        `${tableNameInstance.tableSlug} is already taken`,
        ...tableNameInstance.tableSlugErrors,
      ];
    }
    return tableNameInstance.tableSlugErrors;
  }, [checkTableSlugExecutable?.data, tableNameInstance.tableSlugErrors]);

  return (
    <>
      <Input
        variant="primary"
        value={tableNameInstance.displayName}
        label="Source Name"
        onChange={(e) =>
          onUpdateTableNameInstance(
            tableNameInstance.updateDisplayName(e.target.value)
          )
        }
        errorMessage={tableNameInstance.displayNameErrors}
      />

      <Input
        label="Source Slug"
        variant="supplementary"
        value={tableNameInstance.tableSlug}
        errorMessage={tableSlugErrors}
        onChange={(e) =>
          onUpdateTableNameInstance(
            tableNameInstance.updateTableSlug(e.target.value)
          )
        }
      />

      <TextArea
        label="Description"
        variant="primary"
        value={tableNameInstance.description}
        onChange={(e) =>
          onUpdateTableNameInstance(
            tableNameInstance.updateDescription(e.target.value)
          )
        }
      />
    </>
  );
};

export { TableNameInput, type TableNameInputProps };
