import { SmartFieldDisplaySetting } from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";
import { DisplaySettingClientModel } from ".";
import {
  DateDisplaySettingClientModel,
  DateDisplaySettingClientModelDefactory,
} from "./date";
import {
  NumberDisplaySettingClientModel,
  NumberDisplaySettingClientModelDefactory,
} from "./numberDisplaySetting";

export class DisplaySettingClientModelDefactory {
  public static toSmartFieldDisplaySetting(
    displaySetting: DisplaySettingClientModel
  ): SmartFieldDisplaySetting {
    return match(displaySetting)
      .with(P.instanceOf(NumberDisplaySettingClientModel), (displaySetting) =>
        NumberDisplaySettingClientModelDefactory.toSmartFieldNumberDisplaySetting(
          displaySetting
        )
      )
      .with(P.instanceOf(DateDisplaySettingClientModel), (displaySetting) =>
        DateDisplaySettingClientModelDefactory.toSmartFieldDateDisplaySetting(
          displaySetting
        )
      )
      .exhaustive();
  }
}
