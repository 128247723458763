import { forwardRef } from "react";
import { Button } from "~/components_next/Button";

type FiltersAndSortsButtonProps = {
  isActive?: boolean;
  isDisabled?: boolean;
};

const FiltersAndSortsButton = forwardRef<
  HTMLButtonElement,
  FiltersAndSortsButtonProps
>((props, ref) => {
  const { isActive, ...rest } = props;
  const buttonVariant = isActive ? "primary" : "tertiary";

  return (
    <Button ref={ref} variant={buttonVariant} size="xs" {...rest}>
      Filters & Sorts
    </Button>
  );
});

FiltersAndSortsButton.displayName = "FiltersAndSortsButton";

export { FiltersAndSortsButton };
