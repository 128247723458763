import { useEditorOrThrow } from "../utils";

const useHighlight = () => {
  const editor = useEditorOrThrow();

  const handleToggle = () => {
    editor.chain().focus().toggleHighlight().run();
  };

  const isActive = editor.isActive("highlight");

  const isDisabled = !editor.can().chain().focus().toggleHighlight().run();

  return {
    handleToggle,
    isActive,
    isDisabled,
  };
};

export { useHighlight };
