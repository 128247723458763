import * as Form from "@radix-ui/react-form";
import { Text as TextComp } from "~/components_next/Text";
import {
  SimpleField,
  WidgetDataQueryRecordRequestBody,
} from "@usemorph/morph-dashboard-types";
import { useState } from "react";
import { MultipleFilterSelect } from "../common/MultipleFilterSelect";
import { MultipleSortSelect } from "../common/MultipleSortSelect";
import { FormatForm } from "../common/FormatForm";
import { SelectForm } from "../common/SelectForm";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { Flex } from "@radix-ui/themes";
import { sortObjectUtils } from "~/utils/sortObjectUtils";

export const QueryForm = ({
  defaultBody,
  fields,
  onChange,
}: {
  defaultBody?: WidgetDataQueryRecordRequestBody;
  fields: SimpleField[];
  onChange: (body: WidgetDataQueryRecordRequestBody) => void;
}) => {
  const [body, setBody] = useState<WidgetDataQueryRecordRequestBody>(
    defaultBody ?? { select: [] }
  );

  return (
    <Form.Field name="query" style={{ width: "100%" }}>
      <Flex align="stretch" my="5">
        <InputStack>
          <TextComp fontWeight="medium">Select</TextComp>
          <SelectForm
            defaultValue={defaultBody?.select ?? body.select}
            fields={fields}
            onChange={(e) => {
              setBody({ ...body, select: e });
              onChange({ ...body, select: e });
            }}
          />
        </InputStack>
      </Flex>

      <Flex align="stretch" my="5">
        <InputStack>
          <TextComp fontWeight="medium">Mask</TextComp>
          <FormatForm
            defaultValue={
              defaultBody && "format" in defaultBody
                ? defaultBody.format
                : undefined
            }
            fields={fields}
            onChange={(e) => {
              setBody({ ...body, format: e });
              onChange({ ...body, format: e });
            }}
          />
        </InputStack>
      </Flex>

      <Flex align="stretch" my="5">
        <InputStack>
          <TextComp fontWeight="medium">Filter</TextComp>
          <MultipleFilterSelect
            filter={body.filter}
            fields={fields}
            onChange={(filter) => {
              setBody({ ...body, filter });
              onChange({ ...body, filter });
            }}
          />
        </InputStack>
      </Flex>

      <Flex align="stretch" my="5">
        <InputStack>
          <TextComp fontWeight="medium">Sort</TextComp>
          <MultipleSortSelect
            sort={sortObjectUtils.getRecordSortConditionUnits(body.sort)}
            fields={fields}
            onChange={(sort) => {
              setBody({ ...body, sort });
              onChange({ ...body, sort });
            }}
          />
        </InputStack>
      </Flex>
    </Form.Field>
  );
};
