import { tablesKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiTable from "~/api/useApiTable";

interface UseListNormalizedTableStatusQueryParams {
  teamSlug: string;
  databaseId: string;
}

const useListNormalizedTableStatusQuery = ({
  databaseId,
  teamSlug,
}: UseListNormalizedTableStatusQueryParams) => {
  const { _listNormalizedTableStatus } = useApiTable();

  const client = useQueryClient();
  client.setQueryDefaults(
    tablesKeys.listNormalizedTableStatus({ databaseId, teamSlug }),
    commonQueryOptions
  );

  return {
    queryKey: tablesKeys.listNormalizedTableStatus({
      databaseId,
      teamSlug,
    }),
    queryFn: () => {
      return _listNormalizedTableStatus({
        databaseId,
        teamSlug,
      });
    },
  };
};

export { useListNormalizedTableStatusQuery };
