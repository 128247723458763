import * as Form from "@radix-ui/react-form";
import {
  SimpleField,
  WidgetDataQueryRecordRequestBody,
} from "@usemorph/morph-dashboard-types";
import { useState } from "react";
import { MultipleFilterSelect } from "../common/MultipleFilterSelect";
import { MultipleSortSelect } from "../common/MultipleSortSelect";
import { FormatForm } from "../common/FormatForm";
import { SelectForm } from "../common/SelectForm";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { Text } from "~/components_next/Text";
import { Box } from "~/components_next/Box";
import { sortObjectUtils } from "~/utils/sortObjectUtils";

export const DownloadForm = ({
  defaultBody,
  fields,
  onChange,
}: {
  defaultBody?: WidgetDataQueryRecordRequestBody;
  fields: SimpleField[];
  onChange: (body: WidgetDataQueryRecordRequestBody) => void;
}) => {
  const [body, setBody] = useState<WidgetDataQueryRecordRequestBody>(
    defaultBody ?? { select: [] }
  );

  return (
    <Form.Field name="donload" style={{ width: "100%" }}>
      <Box my="5">
        <Box mb="4">
          <InputStack>
            <Text fontWeight="medium">Select</Text>
            <SelectForm
              defaultValue={defaultBody?.select ?? body.select}
              fields={fields}
              onChange={(e) => {
                setBody({ ...body, select: e });
                onChange({ ...body, select: e });
              }}
            />
          </InputStack>
        </Box>

        <Box mb="4">
          <InputStack>
            <Text fontWeight="medium">Mask</Text>
            <FormatForm
              defaultValue={
                defaultBody && "format" in defaultBody
                  ? defaultBody.format
                  : undefined
              }
              fields={fields}
              onChange={(e) => {
                setBody({ ...body, format: e });
                onChange({ ...body, format: e });
              }}
            />
          </InputStack>
        </Box>

        <Box mb="4">
          <InputStack>
            <Text fontWeight="medium">Filter</Text>
            <MultipleFilterSelect
              fields={fields}
              filter={body.filter}
              onChange={(filter) => {
                setBody({ ...body, filter });
                onChange({ ...body, filter });
              }}
            />
          </InputStack>
        </Box>

        <Box mb="2">
          <InputStack>
            <Text fontWeight="medium">Sort</Text>
            <MultipleSortSelect
              sort={sortObjectUtils.getRecordSortConditionUnits(body.sort)}
              fields={fields}
              onChange={(sort) => {
                setBody({ ...body, sort });
                onChange({ ...body, sort });
              }}
            />
          </InputStack>
        </Box>
      </Box>
    </Form.Field>
  );
};
