import { DashboardRssTableMappingResponse } from "@usemorph/morph-dashboard-types";
import { RssTableMappingClientModel } from "./RssTableMappingClientModel"
import { RssFieldClientModelFactory } from "./RssFieldClientModelFactory";

export const RssTableMappingClientModelFactory = {
  fromRssTableMappingResponse: (
    data: DashboardRssTableMappingResponse
  ): RssTableMappingClientModel => {
    return new RssTableMappingClientModel(data);
  },

  createEmpty(): RssTableMappingClientModel {
    return new RssTableMappingClientModel({
      createdAt: "",
      fields: RssFieldClientModelFactory.createEmpty(),
      tableSlug: "",
      updatedAt: "",
      rssUrl: "",
      isEnabled: false,
    });
  }
}
