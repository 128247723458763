import { DashboardNotebookCellVariableRequestObject } from "@usemorph/morph-dashboard-types";
import { useQuery } from "react-query";
import { useComposeLoadable } from "~/clientModel/loadable";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import {
  PaginationClientModel,
  PaginationClientModelDefactory,
} from "~/clientModel/queryConditions/pagination";
import {
  RecordsClientModel,
  RecordsClientModelFactory,
} from "~/clientModel/records";
import { CanvasVariablesValue } from "~/presenters/canvas/common/CanvasVariablesProvider";
import { TablePaginationLimit } from "~/presenters/sourceAndViews/common/components/bottomItems/TablePagination";

import { useQueryViewRecordsSqlQuery } from "~/serverStateStore/views/queries/useQueryViewRecordsSqlQuery";

export const createUseRecordsWithSqlLoadable = (props: {
  teamSlug: string;
  databaseId: string;
}): UseLoadable<
  {
    viewId: string;
    queryingSql: string;
    pagination: PaginationClientModel<TablePaginationLimit>;
    variables?: CanvasVariablesValue[];
    enabled?: boolean;
  },
  RecordsClientModel
> => {
  const { teamSlug, databaseId } = props;

  return ({
    viewId,
    queryingSql,
    pagination,
    variables,
    enabled = true,
  }: {
    viewId: string;
    queryingSql: string;
    pagination: PaginationClientModel<TablePaginationLimit>;
    variables?: CanvasVariablesValue[];
    enabled?: boolean;
  }) => {
    return useComposeLoadable(
      useQuery({
        ...useQueryViewRecordsSqlQuery({
          teamSlug,
          databaseId,
          viewId,
          sql: queryingSql,
          limit: PaginationClientModelDefactory.toLimit(pagination),
          skip: PaginationClientModelDefactory.toSkip(pagination),
          variables: variables as
            | DashboardNotebookCellVariableRequestObject
            | undefined,
        }),
        enabled: queryingSql !== "",
        select: (data) =>
          RecordsClientModelFactory.createFromQueryRecordListResponseWithFields(
            data
          ),
      })
    );
  };
};
