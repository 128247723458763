import { Text } from "~/components_next/Text";

const PublishedView = () => {
  return (
    <>
      <Text>Published View</Text>
    </>
  );
};

export { PublishedView };
