import { ScheduledTimeClientModel } from "~/clientModel/scheduleCore/ScheduledTime";
import { Grid } from "~/components_next/Grid";
import { SimpleSelect } from "~/components_next/Select";
import { Text } from "~/components_next/Text";

type ScheduledTimeInputProps = {
  value: ScheduledTimeClientModel;
  onChange: (value: ScheduledTimeClientModel) => void;
};

export const ScheduledTimeInput = (props: ScheduledTimeInputProps) => {
  const { value, onChange } = props;

  return (
    <Grid
      rows="1"
      columns="3"
      style={{
        gridTemplateColumns: "1fr auto 1fr",
        alignItems: "center",
      }}
      gapX="2"
    >
      <SimpleSelect
        size="sm"
        variant="primary"
        value={value.hour.value}
        getValueStringFromValue={(value) => `${value}`}
        options={value.hoursOptions}
        onChange={(hoursValue) => onChange(value.updateHours(hoursValue!))}
      />
      <Text fontWeight="bold" variant="description">
        :
      </Text>
      <SimpleSelect
        size="sm"
        variant="primary"
        value={value.minute.value}
        getValueStringFromValue={(value) => `${value}`}
        options={value.minutesOptions}
        onChange={(minutesValue) =>
          onChange(value.updateMinutes(minutesValue!))
        }
      />
    </Grid>
  );
};
