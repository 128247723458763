type TableDependencies = { teamSlug: string; databaseId: string };

type FindTableDependencies = { tableSlug: string } & TableDependencies;

type ListTablesDependencies = {
  skip?: number;
  limit?: number;
} & TableDependencies;

type ListNormalizedTableScheduleDependencies = {
  tableSlug?: string;
  limit?: number;
  skip?: number;
} & TableDependencies;

type ListNormalizedTableStatusDependencies = TableDependencies;

type FindNormalizedTableStatusDependencies = {
  tableSlug: string;
} & TableDependencies;

const tablesKeys = {
  all: ({ teamSlug, databaseId }: TableDependencies) =>
    [teamSlug, databaseId, "tables"] as const,

  /**
   * find table
   */

  allFindTables: ({ teamSlug, databaseId }: TableDependencies) =>
    [...tablesKeys.all({ teamSlug, databaseId }), "findTable"] as const,
  findTable: ({ teamSlug, databaseId, tableSlug }: FindTableDependencies) =>
    [...tablesKeys.allFindTables({ teamSlug, databaseId }), tableSlug] as const,

  /**
   * list tables
   */
  allListTables: ({ teamSlug, databaseId }: TableDependencies) =>
    [...tablesKeys.all({ teamSlug, databaseId }), "listTables"] as const,
  listTables: ({ teamSlug, databaseId, skip, limit }: ListTablesDependencies) =>
    [
      ...tablesKeys.allListTables({ teamSlug, databaseId }),
      { skip, limit },
    ] as const,

  /**
   * normalized table schedule
   */
  allListNormalizedTableSchedule: ({
    teamSlug,
    databaseId,
  }: TableDependencies) =>
    [
      ...tablesKeys.all({ teamSlug, databaseId }),
      "listNormalizedTableSchedule",
    ] as const,

  listNormalizedTableSchedule: ({
    teamSlug,
    databaseId,
    tableSlug,
    limit,
    skip,
  }: ListNormalizedTableScheduleDependencies) =>
    [
      ...tablesKeys.allListNormalizedTableSchedule({ teamSlug, databaseId }),
      { tableSlug, limit, skip },
    ] as const,

  /**
   * list normalized table status
   */
  allListNormalizedTableStatus: ({ teamSlug, databaseId }: TableDependencies) =>
    [
      ...tablesKeys.all({ teamSlug, databaseId }),
      "listNormalizedTableStatus",
    ] as const,
  listNormalizedTableStatus: ({
    teamSlug,
    databaseId,
  }: ListNormalizedTableStatusDependencies) =>
    [
      ...tablesKeys.allListNormalizedTableStatus({ teamSlug, databaseId }),
    ] as const,
};

export { tablesKeys };
