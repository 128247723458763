import { RecordsClientModel } from "~/clientModel/records";
import { RecordClientModel } from "~/clientModel/records/record";
import { RecordIdentifierClientModel } from "~/clientModel/records/record/recordIdentifier";

export type CellSelectionStateClientModelData = {
  recordIdentifiers: RecordIdentifierClientModel[];
  fieldNames: string[];
};

type TaggedCellSelectionState =
  | {
      type: "none";
    }
  | {
      type: "single";
      recordIdentifier: RecordIdentifierClientModel;
      fieldName: string;
    }
  | {
      type: "multiple";
      recordIdentifiers: RecordIdentifierClientModel[];
      fieldNames: string[];
    };

export class CellSelectionStateClientModel {
  constructor(readonly data: CellSelectionStateClientModelData) {}

  public hasSelection(): boolean {
    return this.taggedCellSelectionState.type !== "none";
  }

  public get taggedCellSelectionState(): TaggedCellSelectionState {
    const { fieldNames, recordIdentifiers } = this.data;
    if (fieldNames.length === 0 || recordIdentifiers.length === 0) {
      return { type: "none" };
    }

    if (fieldNames.length === 1 && recordIdentifiers.length === 1) {
      return {
        type: "single",
        fieldName: fieldNames[0],
        recordIdentifier: recordIdentifiers[0],
      };
    }

    return {
      type: "multiple",
      fieldNames,
      recordIdentifiers,
    };
  }

  public isCellSelected({
    recordIdentifier,
    fieldName,
  }: {
    recordIdentifier: RecordIdentifierClientModel;
    fieldName: string;
  }): boolean {
    return (
      this.data.recordIdentifiers.some((selectedRecordIdentifier) =>
        selectedRecordIdentifier.isSame(recordIdentifier)
      ) && this.data.fieldNames.includes(fieldName)
    );
  }

  public clearSelection(): CellSelectionStateClientModel {
    return new CellSelectionStateClientModel({
      recordIdentifiers: [],
      fieldNames: [],
    });
  }

  public selectSingleCell({
    recordIdentifier,
    fieldName,
  }: {
    recordIdentifier: RecordIdentifierClientModel;
    fieldName: string;
  }): CellSelectionStateClientModel {
    return new CellSelectionStateClientModel({
      recordIdentifiers: [recordIdentifier],
      fieldNames: [fieldName],
    });
  }

  public addVerticalCellToSelection({
    recordIdentifier,
  }: {
    recordIdentifier: RecordIdentifierClientModel;
  }): CellSelectionStateClientModel {
    const recordIdentifiers = this.removeDuplicateRecordIdentifiers([
      ...this.data.recordIdentifiers,
      recordIdentifier,
    ]);
    return new CellSelectionStateClientModel({
      recordIdentifiers,
      fieldNames: this.data.fieldNames,
    });
  }

  public addHorizontalCellToSelection({
    fieldName,
  }: {
    fieldName: string;
  }): CellSelectionStateClientModel {
    const fieldNames = this.removeDuplicateFieldNames([
      ...this.data.fieldNames,
      fieldName,
    ]);
    return new CellSelectionStateClientModel({
      recordIdentifiers: this.data.recordIdentifiers,
      fieldNames,
    });
  }

  private removeDuplicateRecordIdentifiers(
    recordIdentifiers: RecordIdentifierClientModel[]
  ): RecordIdentifierClientModel[] {
    return recordIdentifiers.filter(
      (recordIdentifier, index) =>
        recordIdentifiers.findIndex((r) => r.isSame(recordIdentifier)) === index
    );
  }

  private removeDuplicateFieldNames(fieldNames: string[]): string[] {
    return fieldNames.filter(
      (fieldName, index) => fieldNames.indexOf(fieldName) === index
    );
  }

  public getClipboardValue({
    records,
  }: {
    records: RecordsClientModel;
  }): string {
    const selectedRecords: RecordClientModel[] = records.allRecords.filter(
      (record) =>
        this.data.recordIdentifiers.some((selectedRecordIdentifier) =>
          selectedRecordIdentifier.isSame(record.recordIdentifier)
        )
    );
    const clipboardValue: string[] = selectedRecords.map((record) => {
      const values = record
        .selectEntries(this.data.fieldNames)
        .allEntries.map((entry) => `${entry.rawValue}`);

      return values.join(`\t`);
    });

    return [...clipboardValue].join("\n") + "\n";
  }
}
