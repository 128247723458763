import {
  DashboardPlanListResponse,
} from "@usemorph/morph-dashboard-types";
import { PlanClientModel } from "./PlanClientModel";
import { PlanClientModelFactory } from "./PlanClientModelFactory";


export class ListPlansClientModel {
  readonly #data: DashboardPlanListResponse

  constructor(data: DashboardPlanListResponse) {
    this.#data = data;
  }

  /**
   * Getters
   */

  get items(): PlanClientModel[] {
    return this.#data.items.map(item => PlanClientModelFactory.fromPlanResponse(item))
  }

  public filteredItems(lang: "ja" | "en"): PlanClientModel[] {
    const currency = lang === "ja" ? "yen" : "dollar"
    return this.items.filter(item => item.currency === currency)
  }

}
