import { notebookKeys } from "../keys";
import useApiNotebook from "~/api/useApiNotebook";
import { useQueryClient } from "react-query";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import { viewKeys } from "~/serverStateStore/views";

interface UseUpdateNotebookMutationParams {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
}

const useUpdateNotebookMutaiton = ({
  teamSlug,
  notebookId,
  databaseId,
}: UseUpdateNotebookMutationParams) => {
  const { _updateNotebook } = useApiNotebook();

  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      notebookName,
      description,
      isPrivate,
      isPagePublic,
      publicApiKeyType,
    }: {
      notebookName: string;
      description?: string;
      isPrivate?: boolean;
      isPagePublic?: boolean;
      publicApiKeyType?: string;
    }) => {
      return _updateNotebook({
        teamSlug,
        notebookId,
        notebookName,
        description,
        isPrivate,
        isPagePublic,
        publicApiKeyType,
      });
    },
    onSuccess: () => {
      const findKeys = notebookKeys.findNotebook({
        databaseId,
        teamSlug,
        notebookId,
      });
      const listKeys = notebookKeys.listNotebooks({
        databaseId,
        teamSlug,
      });
      const listViewsKeys = viewKeys.listViews({
        databaseId,
        teamSlug,
      });

      broadcast(findKeys);
      broadcast(listKeys);
      broadcast(listViewsKeys);
      invalidateAll(client, findKeys);
      invalidateAll(client, listKeys);
      invalidateAll(client, listViewsKeys);
    },
  };
};

export { useUpdateNotebookMutaiton };
