import { ResourceCategoryType, ResourceNotebookSchema, ResourceTableSchema, ResourceViewSchema } from "@usemorph/morph-dashboard-types";

type resourceMembersDependencies = { teamSlug: string; resourceType: ResourceCategoryType };

type ListResourceMembersDependencies = {
  teamSlug: string;
  resource: ResourceTableSchema | ResourceNotebookSchema | ResourceViewSchema;
  limit?: number;
  skip?: number;
} & resourceMembersDependencies;

const resourceMembersKeys = {
  all: ({ teamSlug, resourceType }: resourceMembersDependencies) =>
    ["resourceMembers", teamSlug, resourceType] as const,

  /**
   * list plans
   */
  allListResourceMembers: ({ teamSlug, resourceType }: resourceMembersDependencies) =>
    [...resourceMembersKeys.all({ teamSlug, resourceType }), "list"] as const,
  listResourceMembers: ({ teamSlug, resource }: ListResourceMembersDependencies) =>
    [...resourceMembersKeys.allListResourceMembers({ teamSlug, resourceType: resource.resourceType, })] as const,
};

export { resourceMembersKeys };
