import { Flex } from "@radix-ui/themes";
import { Text } from "~/components_next/Text";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { useMemo } from "react";
import { UseExecutable } from "~/clientModel/executable";
import { FieldItem } from "./FieldItem";

type EmbeddingFieldsSettingProps = {
  fields: FieldsClientModel;
  useActivateEmbeddingExecutable: UseExecutable<
    void,
    { targetField: FieldClientModel },
    unknown,
    unknown
  >;
};

const EmbeddingFieldsSetting = (props: EmbeddingFieldsSettingProps) => {
  const { fields, useActivateEmbeddingExecutable } = props;

  const embeddableFields = useMemo(() => fields.embeddableFields, [fields]);

  return (
    <Flex direction="column" gap="2" p="3">
      <Text variant="tinyDescription">
        When there are many records it can take some time to conduct similarity
        searches.
      </Text>
      {embeddableFields.allFields.map((field) => (
        <FieldItem
          key={field.name}
          field={field}
          useActivateEmbeddingExecutable={useActivateEmbeddingExecutable}
        />
      ))}
      {embeddableFields.count === 0 && (
        <Text my="3" style={{ display: "inline-block" }} align="center">
          No fields currently match.
        </Text>
      )}
    </Flex>
  );
};

export { EmbeddingFieldsSetting };
