import constate from "constate";
import { NotebookCoworkerState } from "~/features/FlipNotebook";
import { PresenceNotebookState } from "~/features/RealtimeCollaboration/types";

export type PlaygroundCoworkerStateProps = {
  coworkerStates: NotebookCoworkerState[];
  onUpdateState: (state: PresenceNotebookState) => void;
};

export const usePlaygroundCoworkerStateContext = (
  props: PlaygroundCoworkerStateProps
) => {
  const { coworkerStates, onUpdateState } = props;

  return {
    coworkerStates,
    onUpdateState,
  };
};

export const [
  PlaygroundCoworkerStateProvider,
  usePlaygroundCoworkerState,
  usePlaygroundOnUpdateState,
] = constate(
  usePlaygroundCoworkerStateContext,
  (v) => v.coworkerStates,
  (v) => v.onUpdateState
);
