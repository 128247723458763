import { TextField as RadixThemeTextField } from "@radix-ui/themes";
import { styled } from "@stitches/react";
import {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  ReactNode,
  useMemo,
} from "react";
import { match, P } from "ts-pattern";
import { MorphThemeSize } from "../commonProps.type";
import {
  getInputStackProps,
  InputStack,
  InputStackProps,
} from "../InputStack/InputStack";

type RadixThemeInputProps = ComponentPropsWithoutRef<
  typeof RadixThemeTextField.Input
>;

type InputProps = InputStackProps & {
  variant: "primary" | "supplementary";
  isInvalid?: boolean;
  size?: MorphThemeSize;
  leftSlot?: ReactNode;
  rightSlot?: ReactNode;
} & Omit<RadixThemeInputProps, "variant" | "size">;

const StyledInput = styled(RadixThemeTextField.Input, {
  paddingRight: "12px",
});

const getSize = (size: MorphThemeSize): RadixThemeInputProps["size"] => {
  return match(size)
    .with("xs", () => "1" as RadixThemeInputProps["size"])
    .with("sm", () => "2" as RadixThemeInputProps["size"])
    .with("md", () => "3" as RadixThemeInputProps["size"])
    .with("lg", () => "3" as RadixThemeInputProps["size"])
    .with("xl", () => "3" as RadixThemeInputProps["size"])
    .otherwise(() => "3" as RadixThemeInputProps["size"]);
};

const _Input = (props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    variant,
    errorMessage,
    isInvalid = false,
    size = "sm",
    leftSlot,
    rightSlot,
    ...radixInputProps
  } = props;

  // const radixInputProps = (({
  //   variant,
  //   width,
  //   label,
  //   description,
  //   errorMessage,
  //   isInvalid,
  //   size,
  //   ...rest
  // }) => rest)(props);

  const inputStackProps = getInputStackProps(props);

  const _isInvalid = useMemo(() => {
    return match([isInvalid, errorMessage])
      .with([true, P._], () => true)
      .with([false, P.string], () => true)
      .with(
        [false, P.array(P.string)],
        ([_, errorMessages]) => errorMessages.length > 0
      )
      .otherwise(() => false);
  }, [errorMessage, isInvalid]);

  const radixInputVariant = useMemo((): RadixThemeInputProps["variant"] => {
    return match([_isInvalid, variant])
      .with([true, P._], () => "soft" as RadixThemeInputProps["variant"])
      .with(
        [false, "supplementary"],
        () => "soft" as RadixThemeInputProps["variant"]
      )
      .otherwise(() => "surface" as RadixThemeInputProps["variant"]);
  }, [_isInvalid, variant]);

  const radixInputSize = getSize(size);

  return (
    <InputStack {...inputStackProps}>
      <RadixThemeTextField.Root>
        {leftSlot && (
          <RadixThemeTextField.Slot>{leftSlot}</RadixThemeTextField.Slot>
        )}
        <StyledInput
          ref={ref}
          size={radixInputSize}
          variant={radixInputVariant}
          color={_isInvalid ? "red" : "gray"}
          {...radixInputProps}
        />
        {rightSlot && (
          <RadixThemeTextField.Slot>{rightSlot}</RadixThemeTextField.Slot>
        )}
      </RadixThemeTextField.Root>
    </InputStack>
  );
};

const Input = forwardRef<HTMLInputElement, InputProps>(_Input);

export { Input, type InputProps };
