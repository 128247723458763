import { PaginationClientModel } from "./PaginationClientModel";

export class PaginationClientModelDefactory {
  public static toLimit<Limit extends number>(
    pagination: PaginationClientModel<Limit>
  ): number {
    return pagination.data.limit;
  }

  public static toSkip<Limit extends number>(
    pagination: PaginationClientModel<Limit>
  ): number {
    return pagination.data.skip;
  }
}
