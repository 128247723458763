import { Box as RadixThemeBox } from "@radix-ui/themes";
import { ForwardedRef, forwardRef } from "react";
import { styled } from "@stitches/react";

// SpacerはProps禁止で運用する
// type SpacerProps = ComponentPropsWithoutRef<typeof RadixThemeBox>;

const SpacerElement = styled(RadixThemeBox, {
  flex: 1,
});

const _Spacer = (
  props: Record<string, never>,
  ref?: ForwardedRef<HTMLDivElement>
) => {
  return <SpacerElement ref={ref} />;
};

const Spacer = forwardRef(_Spacer);

export { Spacer };
