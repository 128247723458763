import { FieldClientModel } from "~/clientModel/fields/field";
import { FieldsClientModel } from "~/clientModel/fields";
import { Executable } from "~/clientModel/executable";
import { RecordsClientModel } from "~/clientModel/records";
import { FieldTypeInput } from "./FieldTypeInput";
import { TablesClientModel } from "~/clientModel/tables";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { TableClientModel } from "~/clientModel/tables/table";
import { AggregateValueFieldOperatorsClientModel } from "~/clientModel/fields/field/fieldType/computed/aggregateValue/aggregateValueFieldOperators";
import {
  DisplayNameInput,
  FieldNameInput,
  FieldFormLayout,
  DescriptionInput,
  NullableInput,
  DisplaySettingInput,
} from "../../components";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";

type SourceCreateFieldFormProps = {
  table: TableClientModel;
  fields: FieldsClientModel;
  field: FieldClientModel;
  editingFieldsForUpdateMergeKey: FieldsClientModel;
  tables: TablesClientModel;
  aggregateValueFieldOperators: AggregateValueFieldOperatorsClientModel;
  smartFunctions: SmartFunctionsClientModel;
  testFormulaExecutable: Executable<
    { formula: string },
    { records: RecordsClientModel; fields: FieldsClientModel }
  >;
  useFieldsLoadable: UseLoadable<{ tableSlug: string }, FieldsClientModel>;
  onFieldChange: (field: FieldClientModel) => void;
  onEditingFieldsForUpdateMergeKeyChange: (fields: FieldsClientModel) => void;
  generateSqlForComputedFieldFromPromptExecutable: Executable<
    {
      prompt: string;
      creatingFieldName: string;
      margeFieldName: string;
      mergeTargetTableSlug: string;
    },
    { sql: string }
  >;
};

export const SourceCreateFieldForm = (props: SourceCreateFieldFormProps) => {
  const {
    table,
    field,
    fields,
    editingFieldsForUpdateMergeKey,
    tables,
    smartFunctions,
    aggregateValueFieldOperators,
    testFormulaExecutable,
    useFieldsLoadable,
    onFieldChange,
    onEditingFieldsForUpdateMergeKeyChange,
    generateSqlForComputedFieldFromPromptExecutable,
  } = props;

  return (
    <FieldFormLayout>
      <DisplayNameInput
        field={field}
        onFieldChange={onFieldChange}
        linkedWithFieldName
      />
      <FieldNameInput field={field} onFieldChange={onFieldChange} />
      <FieldTypeInput
        table={table}
        field={field}
        fields={fields}
        editingFieldsForUpdateMergeKey={editingFieldsForUpdateMergeKey}
        tables={tables}
        smartFunctions={smartFunctions}
        aggregateValueFieldOperators={aggregateValueFieldOperators}
        testFormulaExecutable={testFormulaExecutable}
        useFieldsLoadable={useFieldsLoadable}
        onFieldChange={onFieldChange}
        onEditingFieldsForUpdateMergeKeyChange={
          onEditingFieldsForUpdateMergeKeyChange
        }
        generateSqlForComputedFieldFromPromptExecutable={
          generateSqlForComputedFieldFromPromptExecutable
        }
      />
      <DisplaySettingInput field={field} onFieldChange={onFieldChange} />
      <NullableInput field={field} onFieldChange={onFieldChange} />
      <DescriptionInput field={field} onFieldChange={onFieldChange} />
    </FieldFormLayout>
  );
};
