import { useCallback, useState } from "react";
import { UseExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { Loadable } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { RecordsClientModel } from "~/clientModel/records";
import { Badge } from "~/components_next/Badge";
import { Flex } from "~/components_next/Flex";
import { FormulaInput } from "./formulaInput/FormulaInput";
import { PromptInput } from "./promptInput/PromptInput";

type BulkEditRecordsByFormulaContentProps = {
  formula: string;
  onFormulaChange: (formula: string) => void;
  targetFieldLoadable: Loadable<FieldClientModel, unknown>;
  fieldsLoadable: Loadable<FieldsClientModel, unknown>;
  usePreviewBulkEditRecordsByFormulaExecutable: UseExecutable<
    void,
    { formula: string; field: FieldClientModel },
    { records: RecordsClientModel; fields: FieldsClientModel },
    unknown
  >;
  useGenerateFormulaExecutable: UseExecutable<
    void,
    { prompt: string },
    { formula: string },
    unknown
  >;
};

export const BulkEditRecordsByFormulaContent = (
  props: BulkEditRecordsByFormulaContentProps
) => {
  const {
    formula,
    onFormulaChange,
    targetFieldLoadable,
    fieldsLoadable,
    usePreviewBulkEditRecordsByFormulaExecutable,
    useGenerateFormulaExecutable,
  } = props;

  const [prompt, setPrompt] = useState<string>("");

  const previewBulkUpdateRecordsByFormulaExecutable =
    usePreviewBulkEditRecordsByFormulaExecutable();

  const generateFormulaExecutable = useGenerateFormulaExecutable();

  const handleGenerateFormula = useCallback(async () => {
    const { formula } = await generateFormulaExecutable.execute({ prompt });
    onFormulaChange(formula);
  }, [generateFormulaExecutable, prompt, onFormulaChange]);

  return (
    <WithFallback loadables={[targetFieldLoadable, fieldsLoadable] as const}>
      {([targetField, fields]) => (
        <Flex gap="7" direction="column">
          <Flex direction="column" gap="2">
            <Badge variant="tertiary">Generate Formula with Prompt</Badge>
            <PromptInput
              prompt={prompt}
              onPromptChange={setPrompt}
              fieldsForTypeahead={fields}
              onGenerateClick={handleGenerateFormula}
              isGenerating={generateFormulaExecutable.isExecuting}
            />
          </Flex>
          <Flex direction="column" gap="2">
            <Badge variant="tertiary">Formula</Badge>
            <FormulaInput
              formula={formula}
              onFormulaChange={onFormulaChange}
              targetField={targetField}
              fieldsForTypeahead={fields}
              previewExecutable={previewBulkUpdateRecordsByFormulaExecutable}
            />
          </Flex>
        </Flex>
      )}
    </WithFallback>
  );
};
