import {
  SimpleField,
  RecordFormatCondition,
} from "@usemorph/morph-dashboard-types";
import { useEffect, useState } from "react";
import { SimpleMultiSelect } from "~/components_next/Select";

export const FormatForm = ({
  defaultValue,
  fields,
  onChange,
}: {
  defaultValue?: RecordFormatCondition[];
  fields: SimpleField[];
  onChange?: (format: RecordFormatCondition[] | undefined) => void;
}) => {
  // states
  const [value, setValue] = useState<string[]>([]);

  // functions
  useEffect(() => {
    const defaultValues: string[] = [];
    if (defaultValue) {
      defaultValue.map((dv) => defaultValues.push(dv.column));
    }
    setValue(defaultValues);
  }, [defaultValue]);

  const getLabel = (option: string) => {
    const field = fields.find((field) => field.name === option);
    if (field) {
      return field.displayName || field.name;
    } else {
      return option;
    }
  };

  return (
    <>
      <SimpleMultiSelect
        variant="primary"
        size="md"
        value={value}
        onChange={(value) => {
          onChange?.(
            value?.map((column) => {
              return {
                action: "mask",
                column,
              };
            })
          );
        }}
        options={fields.map((field) => field.name)}
        renderBadge={(option) => getLabel(option)}
        renderListItem={(option) => getLabel(option)}
      />
    </>
  );
};
