import { MessageClientModel } from ".";

export const MessageClientModelFactory = {
  // factory methods
  fromMessageResponse: (
    data: { message: string }
  ): MessageClientModel => {
    return new MessageClientModel(data);
  },
};
