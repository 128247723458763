import { ValidationResult } from "~/clientModel/utils/validationResultType";

export abstract class FilterOperationClientModelBase {
  public abstract get type(): string;
  public abstract get operatorLabel(): string;
  public abstract get operatorValue(): string;
  public abstract validate(...params: unknown[]): ValidationResult;
}

export interface FilterOperationWithValueInterface<ValueType, Self> {
  readonly operandValue: ValueType;
  updateValue: (value: ValueType) => Self;
}
