import { CollaboratorColorScheme } from "~/components_next/hooks/coworkerColors";

// ClientModelへの過渡期なので、いったん古い型定義をそのまま使う
type ColorType = CollaboratorColorScheme;

type UserColorClientModelData = {
  colorType: ColorType;
};

export class UserColorClientModel {
  constructor(readonly data: UserColorClientModelData) {}

  get colorType(): ColorType {
    return this.data.colorType;
  }

  get colorScheme(): string {
    return this.data.colorType;
  }
}
