import { NodeProps, useStore, useStoreApi } from "reactflow";
import { match } from "ts-pattern";
import { Box } from "~/components_next/Box";
import { useErrorToast } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { HTMLRichTextEditor } from "~/components_next/RichTextEditor";
import { useNotebookLiveObject } from "~/features/RealtimeCollaboration/Notebook/useNotebookLiveObject";

import { styled } from "~/stitches";
import { useNotebookId } from "~/utilHooks/useNotebookId";
import { useSetNotebookCellSelectionLock } from "../../state/notebookCellSelectionLock";
import { NotebookCellObjectWithMeta } from "../../types/NotebookCellObjectWithMeta.type";
import { NotebookCellCard } from "../NotebookCellCommon";
import "./sticky-note.css";

type NotebookCellStickyNoteProps = {
  cellObject: NotebookCellObjectWithMeta;
};

const StickyNoteElement = styled(Box, {
  height: "100%",
  padding: "$3",
  boxShadow: "var(--shadow-4), var(--shadow-2)",
  variants: {
    color: {
      amber: {
        backgroundColor: "$amber5",
      },
      crimson: {
        backgroundColor: "$crimson5",
      },
      green: {
        backgroundColor: "$green5",
      },
      blue: {
        backgroundColor: "$blue5",
      },
    },
  },
});

const NotebookCellStickyNote = (
  props: NodeProps<NotebookCellStickyNoteProps>
) => {
  const { data, id, selected } = props;
  const { cellObject } = data;
  const { source, cellId, cellType, settings } = cellObject;

  const html = source.stickyNote?.value;

  const size = useStore((s) => {
    const node = s.nodeInternals.get(id);

    return {
      width: node?.width || undefined,
      height: node?.height || undefined,
    };
  });

  const onUploadFileMock = async (file: File) => {
    return {
      url: "https://kt-private.api.morphdb.io/v0/storage/public/UploadedImages_form_user/1679891948259-lucy.jpeg",
      data: "morph:storage:::UploadedImages_form_user/1679891948259-lucy.jpeg",
    };
  };

  /**
   * Update Node
   */
  const notebookId = useNotebookId();

  const { cells: liveCells = [], updateNotebookCells: updateLiveCell } =
    useNotebookLiveObject();

  const { errorToast } = useErrorToast({});

  const handleOnHtmlChange = (html: string, height: number) => {
    const rounded = Math.ceil(height / 10) * 10 + 60;

    onUpdateLiveCell(html, rounded);
    // MARK: LIVEBLOCKS
    // onUpdateServerCell({ html, height: rounded });
  };

  // Liveblocksの方は即時
  const onUpdateLiveCell = (html: string, height: number) => {
    const cellIndex = liveCells?.findIndex(
      (cell) => cell.cellId === cellObject.cellId
    );

    if (cellIndex === undefined || cellIndex < 0) {
      errorToast(new Error("Cell not found"));
      return;
    }

    updateLiveCell({
      cell: {
        ...cellObject,
        source: {
          ...cellObject.source,
          stickyNote: {
            value: html,
            color: source.stickyNote?.color || "amber",
          },
        },
        settings: {
          ...cellObject.settings,
          height,
        },
      },
      index: cellIndex,
    });
  };

  /**
   * Selection Control
   */
  const store = useStoreApi();
  const { addSelectedNodes } = store.getState();

  const setNotebookCellSelectionLock =
    useSetNotebookCellSelectionLock(notebookId);

  const handleFocusChange = (isFocused: boolean) => {
    if (isFocused) {
      addSelectedNodes([id]);
      setNotebookCellSelectionLock(true);
    } else {
      // addSelectedNodes([]);
      setNotebookCellSelectionLock(false);
    }
  };

  /**
   * Sticky Note Design
   */
  const color = match(source.stickyNote?.color)
    .with("amber", () => "amber" as const)
    .with("green", () => "green" as const)
    .with("blue", () => "blue" as const)
    .with("crimson", () => "crimson" as const)
    .otherwise(() => "amber" as const);

  return (
    <>
      <NotebookCellCard
        cellObject={cellObject}
        isNodeDragging={props.dragging}
        isNodeSelected={props.selected}
        variant="skelton"
      >
        <StickyNoteElement color={color}>
          <HTMLRichTextEditor
            html={html || ""}
            height={`${(size.height || 0) - 60}px`}
            isReadOnly={false}
            onChange={handleOnHtmlChange}
            onUploadImage={onUploadFileMock}
            hideTopbar
            basicTextsOnly
            autoFocus={false}
            onFocusChange={handleFocusChange}
            customEditorClass="sticky-note-html-editor"
          />
          <Flex align="center" css={{ height: "36px", opacity: 0.5 }}>
            {cellObject.createdBy?.username}
          </Flex>
        </StickyNoteElement>
      </NotebookCellCard>
    </>
  );
};

export { NotebookCellStickyNote, type NotebookCellStickyNoteProps };
