import {
  DashboardRegisterDataApiListResponse,
  DashboardRegisterDataApiResponse,
  DashboardRegisterDataApiRequestBody,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

export default function useApiDataApi() {
  const { executeRequest } = useApi();

  const _listDataApi = async ({
    databaseId,
    teamSlug,
    tableSlug,
    limit = 30,
    skip = 0,
  }: {
    databaseId: string;
    teamSlug: string;
    tableSlug?: string;
    limit?: number;
    skip?: number;
  }) => {
    return executeRequest<DashboardRegisterDataApiListResponse>(
      "get",
      `/${databaseId}/data-api`,
      {
        limit,
        skip,
        tableSlug,
      },
      {
        teamSlug,
      },
      undefined
    );
  };

  const _findDataApi = async ({
    databaseId,
    teamSlug,
    tableSlug,
    apiKey,
  }: {
    databaseId: string;
    teamSlug: string;
    tableSlug: string;
    apiKey: string;
  }) => {
    return executeRequest<DashboardRegisterDataApiResponse>(
      "get",
      `/${databaseId}/data-api/${tableSlug}/${apiKey}`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  // create / update
  const _registDataApi = async ({
    teamSlug,
    databaseId,
    tableSlug,
    requestBody,
  }: {
    teamSlug: string;
    databaseId: string;
    tableSlug: string;
    requestBody: DashboardRegisterDataApiRequestBody
  }) => {
    return executeRequest<DashboardRegisterDataApiResponse>(
      "post",
      `/${databaseId}/data-api/register/${tableSlug}`,
      undefined,
      {
        teamSlug,
      },
      {
        ...requestBody,
      }
    );
  };

  // delete
  const _deleteDataApi = ({
    apiKey,
    databaseId,
    tableSlug,
    teamSlug,
  }: {
    apiKey?: string;
    databaseId: string;
    tableSlug?: string;
    teamSlug: string;
  }) => {
    return executeRequest(
      "DELETE",
      `/${databaseId}/data-api/${tableSlug}/${apiKey}`,
      undefined,
      {
        teamSlug,
      }
    );
  };

  return {
    _listDataApi,
    _findDataApi,
    _registDataApi,
    _deleteDataApi,
  };
}
