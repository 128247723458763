import { FieldClientModel } from "~/clientModel/fields/field";
import { Checkbox } from "~/components_next/Checkbox";

type UniqueInputProps = {
  field: FieldClientModel;
};

export const UniqueInput = (props: UniqueInputProps) => {
  const { field } = props;

  return (
    <Checkbox
      label="Unique"
      size="lg"
      isDisabled
      isReadOnly
      value={field.isUnique}
    />
  );
};
