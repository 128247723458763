import { useMutation } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import {
  FieldClientModel,
  FieldClientModelDefactory,
} from "~/clientModel/fields/field";

import { useErrorToast } from "~/components_next/Error";
import { useCreateSimpleFieldMutation } from "~/serverStateStore";

type UseActivateEmbeddingExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useActivateEmbeddingExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseActivateEmbeddingExecutableParams): Executable<
  { targetField: FieldClientModel },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  const createFieldOptions = useCreateSimpleFieldMutation({
    teamSlug,
    databaseId,
    tableSlug,
  });

  return useComposeExecutable(
    useMutation({
      ...createFieldOptions,
      mutationFn: ({ targetField }: { targetField: FieldClientModel }) => {
        return createFieldOptions.mutationFn({
          requestBody: FieldClientModelDefactory.toEmbeddingField(targetField),
        });
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseActivateEmbeddingExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
}) => {
  return () =>
    useActivateEmbeddingExecutable({
      teamSlug,
      databaseId,
      tableSlug,
    });
};
