import { Flex } from "@radix-ui/themes";
import { Callout } from "~/components_next/Callout";
import { styled } from "~/stitches";

const Wrapper = styled(Flex, {
  alignItems: "center",
  justifyContent: "center",
  minHeight: "60px",
  backgroundColor: "$bg1",
  color: "$textSupplementary",
  borderRadius: "$2",
  p: "$4",
});

const CanvasCellPromptUnitNotFound = () => {
  return (
    <Callout
      type="alert"
      title="Generation error."
      description="Cell contents did not generated. Try different prompt or aggregate records properly before trying visualization using table views."
    />
  );
};

export { CanvasCellPromptUnitNotFound };
