import { useCallback, useMemo } from "react";
import { NotebookCoworkerState } from "~/features/FlipNotebook";
import {
  useGetUserColor,
  useOthersDecoded,
} from "~/features/RealtimeCollaboration";
import { useMyPresence } from "~/features/RealtimeCollaboration/liveblocks.config";
import { PresenceNotebookState } from "~/features/RealtimeCollaboration/types";

export const usePlaygroundCoworkerState = () => {
  const { samePageOthers } = useOthersDecoded();
  const getUserColor = useGetUserColor();

  const playgroundCoworkerStates = useMemo((): NotebookCoworkerState[] => {
    const result = samePageOthers
      .map((other) => {
        if (!other.presence.notebookState) return undefined;

        const userColor = getUserColor(other.presence.user.userId) || "amber";

        const coworkerState: NotebookCoworkerState = {
          ...other.presence.notebookState,
          user: other.presence.user,
          colorScheme: userColor,
        };

        return coworkerState;
      })
      .filter((r): r is NotebookCoworkerState => r !== undefined);

    return result;
  }, [samePageOthers, getUserColor]);

  const [myPresence, updateMyPresence] = useMyPresence();

  const updateMyNotebookState = useCallback(
    (notebookState: PresenceNotebookState) => {
      updateMyPresence({
        ...myPresence,
        notebookState: JSON.stringify(notebookState),
      });
    },
    [myPresence, updateMyPresence]
  );

  const memoizedValue = useMemo(() => {
    return {
      playgroundCoworkerStates,
      updateMyNotebookState,
    };
  }, [playgroundCoworkerStates, updateMyNotebookState]);

  return memoizedValue;
};
