import { SimpleField } from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";
import { getFieldsRange } from "../util/cellSelectionUtil";

type RecordsTableBaseFieldEventKey = "click" | "shiftClick";

export const useHandleFieldClick = (props: {
  onFieldSelectionChange?: (fieldSelection: string[]) => void;
  fieldSelection?: string[];
  fields: SimpleField[];
}) => {
  const { fields, fieldSelection, onFieldSelectionChange } = props;

  const handleFieldClickEvent = (
    event: RecordsTableBaseFieldEventKey,
    fieldSlug: string
  ) => {
    if (!onFieldSelectionChange || !fieldSelection) {
      return;
    }
    match([event, fieldSelection.length])
      .with(["click", P._], () => {
        onFieldSelectionChange([fieldSlug]);
      })
      .with(["shiftClick", 0], () => {
        // 左上から全部。
        const firstField = fields[0];
        const fieldsRange = getFieldsRange(
          [firstField.name, fieldSlug],
          fields
        );

        onFieldSelectionChange(fieldsRange.map((field) => field.name));
      })
      .with(["shiftClick", P.number], () => {
        const fieldsRange = getFieldsRange(
          [...fieldSelection, fieldSlug],
          fields
        );

        onFieldSelectionChange(fieldsRange.map((field) => field.name));
      })
      .exhaustive();
  };

  return {
    handleFieldClickEvent,
  };
};
