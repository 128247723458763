import { useQuery } from "react-query";
import { useComposeLoadable } from "~/clientModel/loadable";
import { PromptHistoriesClientModelFactory } from "~/clientModel/promptHistories";
import { useListGenerateSqlHistoriesQuery } from "~/serverStateStore";

export const createUsePromptHistoryLoadable = (props: {
  teamSlug: string;
  databaseId: string;
}) => {
  const { teamSlug, databaseId } = props;

  return ({ viewId }: { viewId: string }) =>
    useComposeLoadable(
      useQuery({
        ...useListGenerateSqlHistoriesQuery({
          teamSlug,
          databaseId,
          viewId,
        }),
        select: (data) => {
          return PromptHistoriesClientModelFactory.createFromDashboardGenerateSqlHistoryListResponse(
            data
          );
        },
      })
    );
};
