import { MutationOptions, useQueryClient } from "react-query";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { removeTrailingUndefined } from "~/serverStateStore/util/removeTrailingUndefined";
import {
  zDashboardViewResponse,
  zDashboardViewListResponse,
  DashboardViewResponse,
  DashboardViewListResponse,
  DashboardViewConditionObject,
} from "@usemorph/morph-dashboard-types";
import { zodGuard } from "~/utils/zodGuard";
import { viewKeys } from "../keys";
import { useUpdateViewMutation } from "./useUpdateViewMutation";
import { ViewSetting } from "~/features/View";

const mutationKey = "optimistic-update-view";

interface UseOptimisticUpdateViewMutationParams {
  teamSlug: string;
  databaseId: string;
  viewId: string;
}

const useOptimisticUpdateViewMutation = ({
  teamSlug,
  databaseId,
  viewId,
}: UseOptimisticUpdateViewMutationParams): MutationOptions<
  DashboardViewResponse,
  unknown,
  {
    type: "table" | "kanban";
    name: string;
    condition: DashboardViewConditionObject;
    setting: ViewSetting;
    afterViewId?: string;
  },
  unknown
> => {
  const client = useQueryClient();

  const findViewQueryKey = viewKeys.findView({
    teamSlug,
    databaseId,
    viewId,
  });

  const listViewsQueryKey = viewKeys.listViews({
    teamSlug,
    databaseId,
  });

  const { mutationFn, onSuccess } = useUpdateViewMutation({
    teamSlug,
    databaseId,
    viewId,
  });

  return {
    mutationKey,
    mutationFn,
    onMutate: async ({ type, name, condition, setting, afterViewId }) => {
      const getUpdatedView = (
        prevView: DashboardViewResponse
      ): DashboardViewResponse => ({
        ...prevView,
        type,
        name,
        condition,
        setting,
        viewId: afterViewId ?? prevView.viewId,
      });

      // find viewsの更新
      client
        .getQueryCache()
        .findAll(removeTrailingUndefined(findViewQueryKey))
        .forEach(({ queryKey, state: { data } }) => {
          if (zodGuard(data, zDashboardViewResponse)) {
            client.setQueryData<DashboardViewResponse>(
              queryKey,
              getUpdatedView(data)
            );
          }
        });

      const getUpdatedViews = (
        prevViews: DashboardViewListResponse
      ): DashboardViewListResponse => ({
        count: prevViews.count,
        items: prevViews.items,
        // items: prevViews.items.map((view) =>
        //   view.viewId === viewId ? getUpdatedView(view) : view
        // ),
      });

      // list viewsの更新
      client
        .getQueryCache()
        .findAll(removeTrailingUndefined(listViewsQueryKey))
        .forEach(({ queryKey, state: { data } }) => {
          if (zodGuard(data, zDashboardViewListResponse)) {
            client.setQueryData<DashboardViewListResponse>(
              queryKey,
              getUpdatedViews(data)
            );
          }
        });
    },
    onSuccess: () => {
      const count = client.isMutating({ mutationKey, exact: true });
      if (count === 1) {
        return onSuccess();
      }
    },
    onError: () => {
      return invalidateAll(client, findViewQueryKey, listViewsQueryKey);
    },
  };
};

export { useOptimisticUpdateViewMutation };
