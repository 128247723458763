import { CanvasCellClientModelUnion } from "../CanvasCellClientModel";

export type DashboardCellDataTypeLaoutProperties = {
  x: number;
  y: number;
  w: number;
  h: number;
};

export type DashboardCellDataType = DashboardCellDataTypeLaoutProperties & {
  createdAt: string;
  cellId: string;
  cell: CanvasCellClientModelUnion;
};

export class DashboardCellClientModel {
  constructor(private data: DashboardCellDataType) {}

  get createdAt() {
    return this.data.createdAt;
  }

  get cellId() {
    return this.data.cellId;
  }

  get x() {
    return this.data.x;
  }

  get y() {
    return this.data.y;
  }

  get w() {
    return this.data.w;
  }

  get h() {
    return this.data.h;
  }

  get cell() {
    return this.data.cell;
  }

  updateLayout(props: { x: number; y: number; w: number; h: number }) {
    return new DashboardCellClientModel({
      cellId: this.cellId,
      cell: this.cell,
      createdAt: this.createdAt,
      x: props.x,
      y: props.y,
      w: props.w,
      h: props.h,
    });
  }
}
