import { useEffect, useState } from "react";
import { BsCursorFill } from "react-icons/bs";
import { useViewport } from "reactflow";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
import { UserAvatar } from "~/components_next/User";
import { styled } from "~/stitches";
import { useNotebookCollaboration } from "../../state/useNotebookCollaboration";
import { NotebookCoworkerState } from "../../types";

const StyledCursor = styled(BsCursorFill);

const NotebookCollaborationCursors = () => {
  const viewport = useViewport();

  const [cursorStates, setCursorStates] = useState<NotebookCoworkerState[]>([]);

  const { notebookCoworkerStates } = useNotebookCollaboration();

  useEffect(() => {
    // console.log(
    //   notebookCoworkerStates.map((state) => `${JSON.stringify(state.pointer)}`)
    // );
    const _cursors = notebookCoworkerStates.filter(
      (coworkerState) => coworkerState.pointer
    );

    setCursorStates((states) => {
      const newState = _cursors.filter(
        (cursor) =>
          !states.find((state) => state.user.userId === cursor.user.userId)
      );
      const updatedStates = states.map((state) => {
        const updatedState = _cursors.find(
          (cursor) => cursor.user.userId === state.user.userId
        );
        return updatedState || state;
      });
      return [...updatedStates, ...newState];
    });
  }, [notebookCoworkerStates]);

  return (
    <>
      {cursorStates.map((coworkerState) => {
        const { pointer } = coworkerState;
        const x = (pointer?.x || 0) * viewport.zoom + viewport.x;
        const y = (pointer?.y || 0) * viewport.zoom + viewport.y;
        return (
          <Flex
            key={coworkerState.user.userId}
            css={{
              position: "absolute",
              left: x,
              top: y,
              width: "20px",
              height: "20px",
              zIndex: 1000,
              // transitionProperty: "all",
              // transitionDuration: "0.1s",
            }}
            align="end"
          >
            <Box>
              <StyledCursor
                css={{
                  transform: `scaleX(-1)`,
                  color: `$${coworkerState.colorScheme}8`,
                  fontSize: "20px",
                }}
              />
            </Box>
            <UserAvatar size="xs" user={coworkerState.user} />
          </Flex>
        );
      })}
    </>
  );
};

export { NotebookCollaborationCursors };
