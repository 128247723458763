
import {
  DashboardResourceResponse,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

export default function useApiResource() {

  const { executeRequest } = useApi();

  // other
  const _getResource = async ({
    path,
    teamSlug,
  }: {
    path: string;
    teamSlug: string;
  }): Promise<DashboardResourceResponse> => {
    return executeRequest<DashboardResourceResponse>(
      "GET",
      `/resources`,
      {
        path,
      },
      {
        teamSlug,
      },
      undefined
    );
  };
  const _postResource = async ({
    fileName,
    teamSlug,
    isPublic,
  }: {
    fileName: string;
    teamSlug: string;
    isPublic: boolean;
  }): Promise<DashboardResourceResponse> => {
    return executeRequest<DashboardResourceResponse>(
      "POST",
      `/resources`,
      undefined,
      {
        teamSlug,
      },
      {
        fileName,
        isPublic,
      }
    );
  };

  return {
    _getResource,
    _postResource,
  };
}
