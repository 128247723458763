import { useCallback } from "react";
import { Loadable } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { TablesClientModel } from "~/clientModel/tables";
import { TableClientModel } from "~/clientModel/tables/table";
import { DropdownMenu } from "~/components_next/DropdownMenu";

type JoinTargetsDropdownItemsProps = {
  sourcesLoadable: Loadable<TablesClientModel>;
  onSelectTarget: (tableClientModel: TableClientModel) => void;
};

const JoinTargetsDropdownItems = (props: JoinTargetsDropdownItemsProps) => {
  const { sourcesLoadable, onSelectTarget } = props;

  const handleSelectTarget = useCallback(
    (tableClientModel: TableClientModel) => {
      onSelectTarget(tableClientModel);
    },
    [onSelectTarget]
  );

  return (
    <WithFallback loadables={[sourcesLoadable]}>
      {([sources]) => {
        return sources.allTables.map((table) => {
          return (
            <DropdownMenu.Item
              key={table.tableSlug}
              onClick={() => handleSelectTarget(table)}
            >
              {table.displayName}
            </DropdownMenu.Item>
          );
        });
      }}
    </WithFallback>
  );
};

export { JoinTargetsDropdownItems };
