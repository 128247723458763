import {
  selectorFamily,
  useRecoilValue,
  useSetRecoilState,
  DefaultValue,
} from "recoil";
import { RecordsTableBaseSelectionState } from "~/components_next/RecordsTableBase/types";
import { selectionStateFamily } from "./selectionState";

const fieldSelectionFamily = selectorFamily<
  string[] | undefined,
  { viewId: string }
>({
  key: "table-view-field-selection",
  get:
    ({ viewId }) =>
    ({ get }) => {
      const selectionState = get(selectionStateFamily({ viewId }));
      return selectionState?.fieldSelection;
    },
  set:
    ({ viewId }) =>
    ({ set, reset }, newValue) => {
      if (newValue instanceof DefaultValue) {
        reset(selectionStateFamily({ viewId }));
        return;
      }

      set(
        selectionStateFamily({ viewId }),
        (prev): RecordsTableBaseSelectionState => {
          return prev
            ? { ...prev, fieldSelection: newValue ?? [] }
            : {
                fieldSelection: newValue ?? [],
                cellSelection: [],
                recordSelection: [],
                editingCell: null,
              };
        }
      );
    },
});

const useFieldSelection = (viewId: string) => {
  return useRecoilValue(fieldSelectionFamily({ viewId }));
};

const useSetFieldSelection = (viewId: string) => {
  return useSetRecoilState(fieldSelectionFamily({ viewId }));
};

export { useFieldSelection, useSetFieldSelection, fieldSelectionFamily };
