import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { SortConditionUnitClientModel } from "~/clientModel/queryConditions/sortConditions";
import { NativeSimpleSelect } from "~/components_next/Select/NativeSelect/NativeSimpleSelect";

type KeyInputProps = {
  fields: FieldsClientModel;
  sortConditionUnit: SortConditionUnitClientModel;
  onSortConditionUnitChange?: (
    sortConditionUnit: SortConditionUnitClientModel
  ) => void;
  isReadOnly: boolean;
};

const KeyInput = (props: KeyInputProps) => {
  const {
    fields,
    sortConditionUnit,
    onSortConditionUnitChange = () =>
      console.error("onSortConditionUnitChange is not defined"),
    isReadOnly,
  } = props;

  const handleKeyChange = (key: string) => {
    onSortConditionUnitChange(
      sortConditionUnit.updateTargetField(
        fields.getFieldByFieldNameOrThrow(key)
      )
    );
  };

  const keyOptions = fields.allFields.map((field) => ({
    value: field.name,
    label: field.label,
  }));

  return (
    <NativeSimpleSelect
      variant="primary"
      value={sortConditionUnit.key}
      onChange={(value) => handleKeyChange(value!)}
      size="xs"
      isDisabled={isReadOnly}
      options={keyOptions}
    />
  );
};

export { KeyInput };
