import { FieldClientModel } from "~/clientModel/fields/field";
import {
  UpdatableRecordEntryClientModelBaseConstructorParams,
  UpdatableRecordEntryClientModelBase,
} from "../RecordEntryClientModelBase";
import {
  zAllowNullIfNullable,
  zRequiredString,
  zValidate,
} from "../validatorsUtil";
import { z } from "zod";

type ShortTextRecordEntryClientModelData =
  UpdatableRecordEntryClientModelBaseConstructorParams;

export class ShortTextRecordEntryClientModel extends UpdatableRecordEntryClientModelBase<
  string | null
> {
  constructor(data: ShortTextRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "shortText" {
    return "shortText";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([zRequiredString(field), zAllowNullIfNullable(field)]),
      this.data.rawValue
    );
  }

  public getValidatedDisplayValue = this.getValidatedValue;

  public updateValue(value: string | null): ShortTextRecordEntryClientModel {
    return new ShortTextRecordEntryClientModel({
      key: this.data.key,
      rawValue: value,
      meta: this.data.meta,
    });
  }
}
