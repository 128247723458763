/**
 * Dialogの中で使われる時用のコンポーネント。Dialogへの依存アリ。
 */

import { Dialog } from "~/components_next/Dialog";
import {
  CsvImportFileUpload,
  CsvImportFileUploadProps,
} from "../flieUpload/CsvImportFileUpload";

type CsvImportFileUplaodDialogContentProps = CsvImportFileUploadProps;

const CsvImportFileUploadDialogContent = (
  props: CsvImportFileUplaodDialogContentProps
) => {
  return (
    <>
      <Dialog.Title>Upload Excel / CSV file</Dialog.Title>
      <Dialog.Body>
        <CsvImportFileUpload {...props} />
      </Dialog.Body>
    </>
  );
};

export { CsvImportFileUploadDialogContent };
