import { convertToSlug } from "./convertToSlug";

const alphabetRegex = /^[a-zA-Z_]/;
const tableSlugRegex = /^[0-9a-zA-Z_]+$/;

export class TableNameClientModel {
  private _displayName: string;
  private _tableSlug: string;
  private _description: string;
  private _isAutoGenerateEnabled: boolean;

  static reservedWords = ["health_check"];

  constructor(
    displayName: string,
    tableSlug: string,
    description: string,
    isAutoGenerateEnabled?: boolean
  ) {
    this._displayName = displayName;
    this._tableSlug = tableSlug;
    this._description = description;
    this._isAutoGenerateEnabled = isAutoGenerateEnabled || true;
  }

  get displayName(): string {
    return this._displayName;
  }

  get tableSlug(): string {
    return this._tableSlug;
  }

  get description(): string | undefined {
    return this._description;
  }

  get tableSlugErrors(): string[] {
    const errorMessages: string[] = [];

    if (this._tableSlug.length === 0) {
      errorMessages.push("Source slug cannot be empty");
    }

    if (!alphabetRegex.test(this._tableSlug[0])) {
      errorMessages.push("Source slug must start with a letter or underscore");
    }

    if (!tableSlugRegex.test(this._tableSlug)) {
      errorMessages.push(
        "Source slug must contain only letters, numbers, and underscores"
      );
    }

    if (TableNameClientModel.reservedWords.includes(this._tableSlug)) {
      errorMessages.push(`${this._tableSlug} is a reserved word`);
    }

    return errorMessages;
  }

  get displayNameErrors(): string[] {
    const errorMessages: string[] = [];

    if (this._displayName.length === 0) {
      errorMessages.push("Display name cannot be empty");
    }

    return errorMessages;
  }

  get isValid(): boolean {
    return (
      this.tableSlugErrors.length === 0 && this.displayNameErrors.length === 0
    );
  }

  updateDisplayName(displayName: string) {
    if (this._isAutoGenerateEnabled) {
      const slug = convertToSlug(displayName);
      return new TableNameClientModel(
        displayName,
        slug,
        this._description,
        this._isAutoGenerateEnabled
      );
    }

    return new TableNameClientModel(
      displayName,
      this._tableSlug,
      this._description,
      this._isAutoGenerateEnabled
    );
  }

  updateTableSlug(slug: string) {
    return new TableNameClientModel(
      this._displayName,
      convertToSlug(slug),
      this._description,
      this._isAutoGenerateEnabled
    );
  }

  updateDescription(description: string) {
    return new TableNameClientModel(
      this._displayName,
      this._tableSlug,
      description,
      this._isAutoGenerateEnabled
    );
  }
}
