import { UseExecutable } from "~/clientModel/executable";
import { FieldClientModel } from "~/clientModel/fields/field";
import { DangerDialog } from "~/components_next/Dialog";

type DeleteFieldConfirmDialog = {
  targetField: FieldClientModel;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  useDeleteFieldExecutable: UseExecutable<void, { field: FieldClientModel }>;
};

export const DeleteFieldConfirmDialog = (props: DeleteFieldConfirmDialog) => {
  const { targetField, isOpen, onOpenChange, useDeleteFieldExecutable } = props;

  const deleteFieldExecutable = useDeleteFieldExecutable();

  const handleDelete = async () => {
    await deleteFieldExecutable.execute({ field: targetField });
    onOpenChange(false);
  };

  return (
    <DangerDialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onConfirm={handleDelete}
      isConfirmLoading={deleteFieldExecutable.isExecuting}
      description="Are you sure you want to delete the selected records? You cannot undo this action."
    />
  );
};
