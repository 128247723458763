import { importsKeys } from "../keys";

// types
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiImport from "~/api/useApiImport";

interface UseFindImportDataSchemaQueryPrams {
  databaseId: string;
  teamSlug: string;
  importDataSchemaId: string | null;
}

const useFindImportDataSchemaQuery = ({
  databaseId,
  teamSlug,
  importDataSchemaId,
}: UseFindImportDataSchemaQueryPrams) => {
  const { _findImportDataSchema } = useApiImport();
  const client = useQueryClient();

  client.setQueryDefaults(
    importsKeys.listImportStatusFromOtherServices({ teamSlug, databaseId }),
    commonQueryOptions
  );

  return {
    queryKey: importsKeys.findSimpleImportDataSchema({
      teamSlug,
      databaseId,
      importDataSchemaId: importDataSchemaId,
    }),
    queryFn: () => {
      return _findImportDataSchema({
        teamSlug,
        databaseId,
        importDataSchemaId: importDataSchemaId || "",
      });
    },
    enabled: !!importDataSchemaId,
  };
};

export { useFindImportDataSchemaQuery };
