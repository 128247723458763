import {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from "react";
import {
  Popover as RadixThemePopover,
  withBreakpoints,
} from "@radix-ui/themes";
import classNames from "classnames";
import { convertSize, convertVariant } from "./util";
import {
  useMultiSelectSize,
  useMultiSelectVariant,
} from "./MultiSelectContext";

type RadixThemePopoverContentProps = ComponentPropsWithoutRef<
  typeof RadixThemePopover.Content
>;

type MultiSelectContentProps = {
  children: ReactNode;
} & Omit<RadixThemePopoverContentProps, "children">;

const MultiSelectContent = forwardRef(
  (props: MultiSelectContentProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, ...popoverProps } = props;

    const size = useMultiSelectSize();
    const variant = useMultiSelectVariant();

    return (
      <RadixThemePopover.Content
        ref={ref}
        className={classNames(
          "rt-reset",
          "morph-MultiSelectContent",
          withBreakpoints(convertSize(size), "morph-r-size"),
          `morph-variant-${convertVariant(variant)}`
        )}
        {...popoverProps}
      >
        {children}
      </RadixThemePopover.Content>
    );
  }
);

MultiSelectContent.displayName = "MultiSelectContent";

export { MultiSelectContent, type MultiSelectContentProps };
