import { PromptHistoryClientModel } from "./promptHistory";

type PromptHistoriesClientModelData = {
  histories: PromptHistoryClientModel[];
  totalCount: number;
};

export class PromptHistoriesClientModel {
  readonly #data: PromptHistoriesClientModelData;

  constructor(data: PromptHistoriesClientModelData) {
    this.#data = data;
  }

  public get allHistories(): PromptHistoryClientModel[] {
    return this.#data.histories;
  }

  public get count(): number {
    return this.#data.histories.length;
  }

  public get totalCount(): number {
    return this.#data.totalCount;
  }
}
