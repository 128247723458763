import { DashboardPageCellDetailObject } from "@usemorph/morph-dashboard-types";
import { DashboardCellClientModelFactory } from "./DashboardCellClientModelFactory";
import { DashboardCellsClientModel } from "./DashboardCellsClientModel";

export class DashboardCellsClientModelFactory {
  static fromDashboardPageCellResponse(
    dashboardPageCell: DashboardPageCellDetailObject[]
  ) {
    return new DashboardCellsClientModel(
      dashboardPageCell.map((cell) => {
        return DashboardCellClientModelFactory.fromDashboardPageCellResponse(
          cell
        );
      })
    );
  }
}
