import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { Loadable } from "~/clientModel/loadable";
import { FilterConditionsClientModel } from "~/clientModel/queryConditions/filterConditions";
import { SortConditionsClientModel } from "~/clientModel/queryConditions/sortConditions";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { ErrorFallback } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { Spinner } from "~/components_next/Spinner";
import { Text } from "~/components_next/Text";
import { FilterConditionsForm, SortConditionsForm } from "~/presenters/fields";
import { WithFallback } from "../../../../../../clientModel/loadable/WithFallback";
import { FiltersAndSortsButton } from "../buttons/FiltersAndSortsButton";
import { CanvasVariablesValue } from "~/presenters/canvas/common/CanvasVariablesProvider";

type FiltersAndSortsDropdownProps = {
  fieldsLoadable: Loadable<FieldsClientModel>;
  sortConditions: SortConditionsClientModel;
  filterConditions: FilterConditionsClientModel;
  variables?: CanvasVariablesValue[];
  onFilterConditionsChange: (
    filterConditions: FilterConditionsClientModel
  ) => void;
  onSortConditionsChange: (sortConditions: SortConditionsClientModel) => void;
};

export const FiltersAndSortsDropdown = (
  props: FiltersAndSortsDropdownProps
) => {
  const {
    fieldsLoadable,
    sortConditions,
    filterConditions,
    onFilterConditionsChange,
    onSortConditionsChange,
    variables,
  } = props;

  const isActive =
    filterConditions.hasValidFilterConditions ||
    sortConditions.hasSortConditions;

  return (
    <SimpleDropdownMenu trigger={<FiltersAndSortsButton isActive={isActive} />}>
      <Flex
        gap="6"
        px="4"
        py="3"
        direction="column"
        css={{ minWidth: 600, overflow: "auto", maxWidth: 720 }}
      >
        <WithFallback
          loadables={[fieldsLoadable] as const}
          loadingFallback={<LoadingFallback />}
          errorFallback={(error) => <ErrorFallback error={error} />}
        >
          {([fields]) => (
            <>
              <Flex direction="column" gap="2">
                <Text variant="subheading">Filter Conditions</Text>
                <FilterConditionsForm
                  filterConditions={filterConditions}
                  onFilterConditionsChange={onFilterConditionsChange}
                  fields={fields}
                  variables={variables}
                />
              </Flex>
              <Flex direction="column" gap="2">
                <Text variant="subheading">Sort Conditions</Text>
                <SortConditionsForm
                  sortConditions={sortConditions}
                  onSortConditionChanges={onSortConditionsChange}
                  fields={fields}
                />
              </Flex>
            </>
          )}
        </WithFallback>
      </Flex>
    </SimpleDropdownMenu>
  );
};

const LoadingFallback = () => {
  return (
    <Flex align="center" justify="center" height="9">
      <Spinner size="md" />
    </Flex>
  );
};
