import {
  FieldTypeClientModelFactory,
  SmartFunctionFieldTypeClientModel,
} from "~/clientModel/fields/field/fieldType";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";
import { SmartFunctionClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions/smartFunction";
import { DropdownMenu } from "~/components_next/DropdownMenu";

type SmartFunctionFieldSubSelectProps = {
  smartFunctions: SmartFunctionsClientModel;
  onSelect: (fieldType: SmartFunctionFieldTypeClientModel) => void;
};

export const SmartFunctionFieldSubSelect = (
  props: SmartFunctionFieldSubSelectProps
) => {
  const { smartFunctions, onSelect } = props;

  const handleSmartFunctionSelect = (
    smartFunction: SmartFunctionClientModel
  ) => {
    const emptySmartFunctionField = FieldTypeClientModelFactory.createEmpty(
      "smartFunction"
    ) as SmartFunctionFieldTypeClientModel;

    onSelect(
      emptySmartFunctionField.updateSmartFunctionId(smartFunction.functionId)
    );
  };

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger>AI</DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent>
        {smartFunctions.allSmartFunctions.map((smartFunction) => (
          <DropdownMenu.Item
            key={smartFunction.functionId}
            onClick={() => handleSmartFunctionSelect(smartFunction)}
          >
            {smartFunction.label}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>
  );
};
