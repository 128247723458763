import { ReactNode } from "react";
import { Flex } from "~/components_next/Flex";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";
import { AccessAuthoritySelect } from "./AccessAuthoritySelect";
import { AccessAuthority } from "./types";

type AccessAuthorityCardProps = {
  title: string;
  description: string;
  authority: AccessAuthority;
  onChangeAuthority: (authority: AccessAuthority) => void;
  authorityOptions?: AccessAuthority[];
  authorityIsLoading?: boolean;
  leftIcon?: ReactNode;
};

export const AccessAuthorityCard = (props: AccessAuthorityCardProps) => {
  const {
    title,
    description,
    authority,
    onChangeAuthority,
    authorityOptions,
    authorityIsLoading,
    leftIcon,
  } = props;

  return (
    <Flex align="center" py="2" gap="2" css={{ width: "100%" }}>
      <Flex style={{ width: "40px" }} align="center" justify="center">
        {leftIcon}
      </Flex>
      <Flex
        direction="column"
        align="start"
        justify="center"
        css={{
          flex: 1,
          height: "50px",
          whiteSpace: "nowrap",
          textOverflow: "clip",
        }}
      >
        <Text fontWeight="bold">{title}</Text>
        <Text variant="description">{description}</Text>
      </Flex>
      <Spacer />
      <AccessAuthoritySelect
        authority={authority}
        onChange={onChangeAuthority}
        options={authorityOptions}
        isLoading={authorityIsLoading}
      />
    </Flex>
  );
};
