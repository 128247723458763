import { DashboardViewUnionObject } from "@usemorph/morph-dashboard-types";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { match } from "ts-pattern";
import { useErrorToast } from "~/components_next/Error";
import { ViewIcon, NotebookIcon } from "~/components_next/Icons";
import { ViewSettingsDropdown } from "~/features/View";
import { getPath } from "~/routing";
import { useCreateViewMutation } from "~/serverStateStore/views";
import { LinkItemText } from "./common/LinkItemText";
import { LinkItemWrapper } from "./common/LinkItemWrapper";

type SidebarViewLinkItemProps = {
  view: DashboardViewUnionObject;
  teamSlug: string;
  databaseId: string;
};

const SidebarViewLinkItem = (props: SidebarViewLinkItemProps) => {
  const { view, teamSlug, databaseId } = props;

  /**
   * Coworker Indicator
   */
  // const to = getPath("view", {
  //   teamSlug,
  //   databaseId,
  //   viewId: view.viewId,
  // });

  const to = match(view)
    .with({ viewCategory: "view" }, () =>
      getPath("view", {
        teamSlug,
        databaseId,
        viewId: view.viewId,
      })
    )
    .with({ viewCategory: "notebook" }, () =>
      getPath("notebook", {
        teamSlug,
        databaseId,
        notebookId: view.viewId,
      })
    )
    .exhaustive();

  /**
   * Duplicate View
   */
  const { mutateAsync: duplicateView, isLoading: isDuplicating } = useMutation(
    useCreateViewMutation({ teamSlug, databaseId })
  );

  const { errorToast } = useErrorToast({});
  const navigate = useNavigate();

  const handleDuplicateView = async () => {
    try {
      // const { viewId } = await duplicateView({
      //   ...view,
      //   setting: parseViewSetting(view.setting),
      //   name: `${view.name}_copy`,
      // });
      // navigate(getPath("view", { teamSlug, databaseId, viewId }));
    } catch (e) {
      errorToast(e);
    }
  };

  const icon = match(view)
    .with({ type: "table", viewCategory: "view" }, () => (
      <ViewIcon type="table" />
    ))
    .with({ type: "kanban", viewCategory: "view" }, () => (
      <ViewIcon type="kanban" />
    ))
    .with({ viewCategory: "notebook" }, () => <NotebookIcon />)
    .otherwise(() => null);

  return (
    <LinkItemWrapper to={to} pl="1" gap="1">
      {icon}
      <LinkItemText>{view.name}</LinkItemText>
      <ViewSettingsDropdown
        view={view}
        displayDelete
        // displayDuplicate
        isLoadingIcon={isDuplicating}
        // onClickDuplicate={handleDuplicateView}
      />
    </LinkItemWrapper>
  );
};

export { SidebarViewLinkItem };
