import {
  RecordFilterConditionUnit,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import {
  BasicTypeaheadPrompt,
  basicVariableFilterFn,
  VariableSuggestionItem,
} from "~/features/Prompt";
import { ViewCellVariable } from "~/features/SourceAndViews/ViewCell/common/states/variables";
import { useDebounceCallback } from "~/hooks/useDebounceRequest";
import "./filter-input.css";

type TableViewCellFilterConditionUnitInputProps = {
  targetField: SimpleField;
  isReadOnly: boolean;
  value: RecordFilterConditionUnit["value"];
  variables: ViewCellVariable[];
  onChange: (value: RecordFilterConditionUnit["value"]) => void;
};

// "number" | "boolean" | "shortText" | "longText" | "richText" | "formula" | "date" | "datetime" | "time" | "email" | "url" | "phoneNumber" | "singleSelect" | "multiSelect" | "bigNumber" | "decimal" | "autoNumber" | "autoBigNumber" | "image" | "attachment" | "array" | "json" | "createdAt" | "createdBy" | "lastEditedAt" | "lastEditedBy" | "html" | "reference" | "multiReference" | "embeddings";

const TableViewCellFilterConditionUnitInput = (
  props: TableViewCellFilterConditionUnitInputProps
) => {
  const { targetField, isReadOnly, value, onChange, variables } = props;

  const handleValueChange = useDebounceCallback(
    (value: RecordFilterConditionUnit["value"]) => {
      onChange(value);
    },
    500
  );

  const fieldType = targetField.type;

  /**
   * タイプ別の入力と補完の共存がめっちゃむずい..
   */

  return (
    <>
      <BasicTypeaheadPrompt
        textContent={String(value)}
        onUpdate={handleValueChange}
        optionItems={variables}
        isReadOnly={isReadOnly}
        filterFn={basicVariableFilterFn}
        convertOptionItemToText={(variable) =>
          `\${${variable.cellId}+${variable.name}}`
        }
        renderHTMLTextContent={(variable) => `#${variable.name}}`}
        renderSuggestedItem={(variable) => (
          <VariableSuggestionItem variable={variable} />
        )}
        theme={{
          root: "source-prompt__filter-input-root",
        }}
      />
    </>
  );
};

export { TableViewCellFilterConditionUnitInput };
