import {
  useMemo,
  useCallback,
  ReactNode,
  ForwardedRef,
  forwardRef,
} from "react";
import { Flex } from "~/components_next/Flex";
import { Text } from "~/components_next/Text";

import { MultiSelect, MultiSelectRootProps } from "../";

type GetValueStringFromOption<OptionType> = (option: OptionType) => string;

// OptionTypeがstring | nullの場合は、getValueStringFromValueを省略できる
type getValueFromOptionProps<OptionType> = OptionType extends string
  ? { getValueFromOption?: GetValueStringFromOption<OptionType> }
  : { getValueFromOption: GetValueStringFromOption<OptionType> };

type SimpleMultiSelectProps<OptionType> = Pick<
  MultiSelectRootProps,
  | "variant"
  | "size"
  | "label"
  | "errorMessages"
  | "isDisabled"
  | "isClearable"
  | "isReadOnly"
  | "filterFn"
> & {
  value: OptionType[] | null;
  onChange?: (value: OptionType[] | null) => void;
  renderBadge?: (option: OptionType) => ReactNode;
  renderListItem?: (option: OptionType) => ReactNode;
} & (
    | {
        isGrouped: true;
        options: { groupLabel: string; groupOptions: OptionType[] }[];
      }
    | { isGrouped?: false; options: OptionType[] }
  ) &
  getValueFromOptionProps<OptionType>;

const _SimpleMultiSelect = <OptionType,>(
  props: SimpleMultiSelectProps<OptionType>,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const {
    variant,
    size,
    isGrouped,
    options,
    getValueFromOption,
    value,
    onChange,
    label,
    errorMessages,
    isDisabled,
    isReadOnly,
    isClearable,
    renderBadge,
    renderListItem,
    filterFn,
  } = props;

  const _getValueFromOption: (option: OptionType) => string = useMemo(() => {
    if (getValueFromOption) {
      return (option: OptionType) => getValueFromOption(option);
    }
    return (option: OptionType) => option as string;
  }, [getValueFromOption]);

  const findOption = useCallback(
    (valueItem: string): OptionType => {
      const allOptions = isGrouped
        ? options.flatMap(({ groupOptions }) => groupOptions)
        : options;

      const option = allOptions.find(
        (option) => _getValueFromOption(option) === valueItem
      );

      if (!option) {
        throw new Error("option not found");
      }

      return option;
    },
    [_getValueFromOption, isGrouped, options]
  );

  const handleOnChange = (value: string[] | null) => {
    if (!onChange) return;

    if (value === null) {
      onChange(null);
      return;
    }

    const updatedValue = value.map((valueItem) => findOption(valueItem));
    onChange(updatedValue);
  };

  const stringifiedValue = useMemo(() => {
    return value?.map((option) => _getValueFromOption(option)) ?? [];
  }, [value, _getValueFromOption]);

  const getKey = useCallback(
    (option: OptionType) => {
      return _getValueFromOption(option);
    },
    [_getValueFromOption]
  );

  const getValue = useCallback(
    (option: OptionType) => {
      return _getValueFromOption(option);
    },
    [_getValueFromOption]
  );

  return (
    <MultiSelect.Root
      ref={ref}
      variant={variant}
      value={stringifiedValue}
      onChange={handleOnChange}
      size={size}
      errorMessages={errorMessages}
      label={label}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      filterFn={filterFn}
    >
      <MultiSelect.InputContainer>
        {value?.map((option) => {
          return (
            <MultiSelect.Tag key={getKey(option)} value={getValue(option)}>
              {renderBadge ? renderBadge(option) : getValue(option)}
            </MultiSelect.Tag>
          );
        })}
        <MultiSelect.Input />
      </MultiSelect.InputContainer>
      <MultiSelect.Content>
        {/* グルーピングされている時 */}
        {isGrouped &&
          options.map(({ groupLabel, groupOptions }) =>
            groupOptions.length === 0 ? null : (
              <MultiSelect.Group key={groupLabel} heading={groupLabel}>
                {
                  <MultiSelect.List>
                    {groupOptions.map((option) => (
                      <MultiSelect.Item
                        key={getValue(option)}
                        value={getValue(option)}
                      >
                        {renderListItem
                          ? renderListItem(option)
                          : getValue(option)}
                      </MultiSelect.Item>
                    ))}
                  </MultiSelect.List>
                }
              </MultiSelect.Group>
            )
          )}

        {/* グルーピングされてない時 */}
        {!isGrouped && (
          <MultiSelect.List>
            {options.map((option) => (
              <MultiSelect.Item key={getValue(option)} value={getValue(option)}>
                {renderListItem ? renderListItem(option) : getValue(option)}
              </MultiSelect.Item>
            ))}
          </MultiSelect.List>
        )}

        <MultiSelect.EmptyFallback>
          <Flex align="center" justify="center" pt="5" pb="3" mt="-3">
            <Text variant="description">No Option</Text>
          </Flex>
        </MultiSelect.EmptyFallback>
      </MultiSelect.Content>
    </MultiSelect.Root>
  );
};

// ここのキャストは仕方がない
// ref
const SimpleMultiSelect = forwardRef(_SimpleMultiSelect) as <OptionType>(
  props: SimpleMultiSelectProps<OptionType> & {
    ref?: ForwardedRef<HTMLDivElement>;
  }
) => JSX.Element;

export { SimpleMultiSelect, type SimpleMultiSelectProps };
