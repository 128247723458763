import { useMutation } from "react-query";
import { useComposeExecutable } from "~/clientModel/executable";
import { ImportIntegrationStatusClientModelFactory } from "~/clientModel/template";
import { UseImportDataFromExternalServicesMutationProps, useImportDataFromExternalServicesMutation } from "~/serverStateStore"
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

export const useImportDataFromExternalServices = () => {
  const databaseId = useDatabaseId();
  const teamSlug = useTeamSlug();

  const importDataFromExternalServicesMutationOptions = useImportDataFromExternalServicesMutation({ databaseId, teamSlug })
  const importDataFromExternalServicesMutationResult = useMutation({
    ...useImportDataFromExternalServicesMutation,
    mutationFn: async (params: UseImportDataFromExternalServicesMutationProps) => {
      const response = await importDataFromExternalServicesMutationOptions.mutationFn(params)
      return ImportIntegrationStatusClientModelFactory.fromImportIntegrationStatus(response)
    }
  })

  const importDataFromExternalServicesExecutable = useComposeExecutable(importDataFromExternalServicesMutationResult)

  return {
    importDataFromExternalServicesExecutable
  }
}
