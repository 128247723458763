import { BsBraces, BsClockHistory, BsFillCaretRightFill } from "react-icons/bs";
import {
  basicFieldFilterFn,
  BasicTypeaheadPrompt,
  basicVariableFilterFn,
  FieldSuggestionItem,
  VariableSuggestionItem,
} from "~/features/Prompt";
import { IconButton } from "~/components_next/IconButton";
import { Button } from "~/components_next/Button";
import "./prompt.css";
import {
  DashboardTableObject,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { Box, Flex } from "@radix-ui/themes";
import { styled } from "~/stitches";
import { forwardRef, useCallback, useMemo } from "react";
import { ViewCellVariable } from "~/features/SourceAndViews/ViewCell/common/states/variables";
import { match } from "ts-pattern";

const RightButtonWrapper = styled(Flex, {
  position: "absolute",
  right: 16,
  bottom: 8,
  gap: 12,
  height: "40px",
  // zIndex: 1000,
  flexDirection: "row",
  alignItems: "center",
});

const RunIcon = styled(BsFillCaretRightFill, {
  marginRight: "4px",
});

const promptTheme = {
  root: "source-prompt__prompt-editor-root",
};

type SuggestOptionType =
  | {
      type: "field";
      value: SimpleField;
    }
  | {
      type: "variable";
      value: ViewCellVariable;
    };

type PromptFormProps = {
  promptInput: string;
  tablesForTypeahead: DashboardTableObject[];
  fieldsForTypeahead: SimpleField[];
  variablesForTypehead?: ViewCellVariable[];
  showMoveToSQLModeButton: boolean;
  isPromptRunning: boolean;
  onUpdatePromptInput: (input: string) => void;
  onOpenHistoryDrawer?: () => void;
  onRunPrompt: () => void;
  onMoveToSQLMode: () => void;
  sortOptionsCompareFunc?: (tableSlugA: string, tableSlugB: string) => number;
};

const PromptForm = forwardRef<HTMLDivElement, PromptFormProps>((props, ref) => {
  const {
    onOpenHistoryDrawer,
    onRunPrompt,
    onMoveToSQLMode,
    promptInput,
    onUpdatePromptInput,
    tablesForTypeahead,
    fieldsForTypeahead,
    variablesForTypehead = [],
    showMoveToSQLModeButton,
    isPromptRunning,
    sortOptionsCompareFunc,
  } = props;

  const optionItems = useMemo(() => {
    return [
      ...variablesForTypehead.map((variable) => ({
        type: "variable" as const,
        value: variable,
      })),
      ...fieldsForTypeahead.map((field) => ({
        type: "field" as const,
        value: field,
      })),
    ];
  }, [fieldsForTypeahead, variablesForTypehead]);

  const filterFn = useCallback((item: SuggestOptionType, query: string) => {
    return match(item)
      .with({ type: "field" }, ({ value }) => basicFieldFilterFn(value, query))
      .with({ type: "variable" }, ({ value }) =>
        basicVariableFilterFn(value, query)
      )
      .exhaustive();
  }, []);

  const convertOptionItemToText = useCallback((item: SuggestOptionType) => {
    return match(item)
      .with({ type: "field" }, ({ value }) => {
        return value.originalTableSlug
          ? `\${${value.originalTableSlug}.${value.name}}`
          : `\${${value.name}}`;
      })
      .with({ type: "variable" }, ({ value }) => {
        return `\${${value.cellId}+${value.name}}`;
      })
      .exhaustive();
  }, []);

  const renderHTMLTextContent = useCallback((item: SuggestOptionType) => {
    return match(item)
      .with({ type: "field" }, ({ value }) => {
        return value.displayName || value.name;
      })
      .with({ type: "variable" }, ({ value }) => {
        return `#${value.name}`;
      })
      .exhaustive();
  }, []);

  const renderSuggestedItem = useCallback((item: SuggestOptionType) => {
    return match(item)
      .with({ type: "field" }, ({ value }) => (
        <FieldSuggestionItem field={value} />
      ))
      .with({ type: "variable" }, ({ value }) => (
        <VariableSuggestionItem variable={value} />
      ))
      .exhaustive();
  }, []);

  return (
    <Box position="relative" p="2" style={{ maxWidth: "70vw" }}>
      <BasicTypeaheadPrompt<SuggestOptionType>
        ref={ref}
        textContent={promptInput}
        onUpdate={onUpdatePromptInput}
        theme={promptTheme}
        optionItems={optionItems}
        filterFn={filterFn}
        convertOptionItemToText={convertOptionItemToText}
        renderHTMLTextContent={renderHTMLTextContent}
        renderSuggestedItem={renderSuggestedItem}
        focusOnLoad
      />
      <RightButtonWrapper>
        {showMoveToSQLModeButton && (
          <IconButton
            icon={<BsBraces />}
            onClick={onMoveToSQLMode}
            tooltip="Edit SQL"
            size="sm"
          />
        )}

        <IconButton
          icon={<BsClockHistory />}
          onClick={onOpenHistoryDrawer}
          tooltip="Prompt Histories"
          size="xs"
        />

        <Button
          variant="primary"
          size="xs"
          isLoading={isPromptRunning}
          isDisabled={!promptInput}
          onClick={onRunPrompt}
        >
          <RunIcon />
          Run
        </Button>
      </RightButtonWrapper>
    </Box>
  );
});

PromptForm.displayName = "PromptForm";

export { PromptForm };
