import { Editor } from "@monaco-editor/react";
import { Spinner } from "~/components_next/Spinner";

type SqlViewerProps = {
  sql: string;
};

const SqlViewer = (props: SqlViewerProps) => {
  const { sql } = props;

  return (
    <>
      <Editor
        height="30vh"
        language="sql"
        value={sql}
        loading={<Spinner />}
        theme={"vs-dark"}
        options={{
          padding: {
            top: 24,
            bottom: 24,
          },
          scrollBeyondLastLine: false,
          minimap: {
            enabled: false,
          },
          fontSize: 14,
          lineNumbers: "off",
          readOnly: true,
        }}
      />
    </>
  );
};

export { SqlViewer };
