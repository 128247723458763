import { SimpleField } from "@usemorph/morph-dashboard-types";
import { useMemo } from "react";
import { RecordModel } from "../types/RecordModelValue";
import { validateFieldValueFormat } from "./formatCheck";

type ValidationError = { detail: `${string}.` };

type ValidationErrorsDict = Record<string, ValidationError[]>;

/**
 * ユーザーが入力可能なfieldに対してバリデーションする
 */
const useRecordModelFormatValidation = ({
  recordModel,
  simpleFields,
}: {
  recordModel: RecordModel;
  simpleFields: SimpleField[];
}): {
  validationErrorsDict: ValidationErrorsDict;
  isAllValid: boolean;
} => {
  const validationErrorsDict: ValidationErrorsDict = useMemo(() => {
    return Object.entries(recordModel).reduce(
      (
        errorDict,
        [key, recordModelValue]
      ): Record<string, ValidationError[]> => {
        const field = simpleFields.find((field) => field.name === key);
        if (!field) return errorDict;

        // ユーザーの入力と関係ないfieldは無視する
        if (field.name.startsWith("morph_reserved_")) return errorDict;
        if (
          [
            "createdAt",
            "createdBy",
            "lastEditedAt",
            "lastEditedBy",
            "autoNumber",
            "autoBigNumber",
            "formula",
          ].includes(field.type)
        )
          return errorDict;

        if (!recordModelValue.isValid) {
          return {
            ...errorDict,
            [key]: [{ detail: "Type corrupted." }],
          };
        }

        return {
          ...errorDict,
          [key]: validateFieldValueFormat(recordModelValue, field),
        };
      },
      {} as ValidationErrorsDict
    );
  }, [recordModel, simpleFields]);

  const isAllValid = useMemo(() => {
    return Object.keys(validationErrorsDict).every((key) => {
      const errors = validationErrorsDict[key];
      return errors.length === 0;
    });
  }, [validationErrorsDict]);

  return {
    validationErrorsDict,
    isAllValid,
  };
};

export {
  useRecordModelFormatValidation,
  type ValidationError,
  type ValidationErrorsDict,
};
