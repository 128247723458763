import { FieldTypeClientModelFactory } from "~/clientModel/fields/field/fieldType";
import { FieldTypeSubSelect } from "./common/FieldTypeSubSelectBase";

const fileFieldTypes = [
  FieldTypeClientModelFactory.createEmpty("image"),
  FieldTypeClientModelFactory.createEmpty("attachment"),
] as const;

type fileFieldType = (typeof fileFieldTypes)[number];

type FileFieldsTypeSubSelectProps = {
  onSelect: (fieldType: fileFieldType) => void;
  options?:
    | {
        includes: fileFieldType["type"][];
      }
    | {
        excludes: fileFieldType["type"][];
      }; // 指定がなければ全部
};

export const FileFieldsTypeSubSelect = (
  props: FileFieldsTypeSubSelectProps
) => {
  const { onSelect, options } = props;

  return (
    <FieldTypeSubSelect
      triggerContent="File"
      onSelect={onSelect}
      fieldTypeOptions={fileFieldTypes}
      options={options}
    />
  );
};
