import { ChangeEvent, useMemo, useState } from "react";
import { Button } from "~/components_next/Button";
import { Flex } from "~/components_next/Flex";
import { Input } from "~/components_next/Input";
import { Text } from "~/components_next/Text";
import { VariableOptionElement } from "./VariableOptionElement";

type VariablesNumberOptionsProps = {
  options: number[];
  onOptionsChange: (options: number[]) => void;
};

const VariablesNumberOptions = (props: VariablesNumberOptionsProps) => {
  const { options, onOptionsChange } = props;

  const [inputStringValue, setInputStringValue] = useState("");
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputStringValue(event.target.value);
  };

  const inputNumberValue = useMemo(() => {
    if (Number.isNaN(inputStringValue)) {
      return undefined;
    }
    return Number(inputStringValue);
  }, [inputStringValue]);

  const handleAddOption = () => {
    if (inputNumberValue === undefined) {
      return;
    }
    onOptionsChange([...options, inputNumberValue]);
    setInputStringValue("");
  };

  const handleRemoveOption = (index: number) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    onOptionsChange(newOptions);
  };

  return (
    <>
      <Flex align="center" gap="2">
        <Input
          size="xs"
          variant="primary"
          placeholder="Add option"
          onChange={onInputChange}
          type="number"
        />
        <Button
          variant="secondary"
          size="xs"
          onClick={handleAddOption}
          isDisabled={inputNumberValue === undefined}
        >
          Add
        </Button>
      </Flex>
      {options.map((option, index) => {
        return (
          <VariableOptionElement
            key={index}
            onRemove={() => handleRemoveOption(index)}
          >
            <Text>{option}</Text>
          </VariableOptionElement>
        );
      })}
    </>
  );
};

export { VariablesNumberOptions };
