import { useMemo } from "react";
import { createUseRecordsWithConditionLoadable } from "../../playground/tableViewProps/createUseRecordsWithConditionLoadable";
import { createUseRecordsWithSqlLoadable } from "../../playground/tableViewProps/createUseRecordsWithSqlLoadable";
import { createUseFieldsWithConditionLoadable } from "../../playground/tableViewProps/createUseFieldsWithConditionLoadable";
import { createUseFieldsWithSqlLoadable } from "../../playground/tableViewProps/createUseFieldsWithSqlLoadable";
import { createUseSuggestedPromptsLoadable } from "../../playground/tableViewProps/createUseSuggestedPromptsLoadable";
import { createUseGenerateSqlFromPromptExecutable } from "../../playground/tableViewProps/createUseGenerateSqlFromPromptExecutable";
import { createUsePromptHistoryLoadable } from "../../playground/tableViewProps/createUsePromptHistoryLoadable";
import { createUseDownloadCsvExecutable } from "../../playground/tableViewProps/createUseDownloadCsvExecutable";
import { createuseDownloadCsvPreviewRecordsLoadable } from "../../playground/tableViewProps/createUseDownloadCsvPreviewRecordsLoadable";

export const useTableViewProps = (props: {
  teamSlug: string;
  databaseId: string;
}) => {
  const { teamSlug, databaseId } = props;

  /**
   * v1.1
   * Canvas内のDashboardの場合、LoadableはPlaygroundと同じ処理になるので、importして流用する。
   * 仕様が変わったら、この部分を変更する。
   */

  const useRecordsWithConditionLoadable = useMemo(() => {
    return createUseRecordsWithConditionLoadable({
      teamSlug,
      databaseId,
    });
  }, [teamSlug, databaseId]);

  const useRecordsWithSqlLoadable = useMemo(() => {
    return createUseRecordsWithSqlLoadable({
      teamSlug,
      databaseId,
    });
  }, [teamSlug, databaseId]);

  const useFieldsWithConditionLoadable = useMemo(() => {
    return createUseFieldsWithConditionLoadable({
      teamSlug,
      databaseId,
    });
  }, [teamSlug, databaseId]);

  const useFieldsWithSqlLoadable = useMemo(() => {
    return createUseFieldsWithSqlLoadable({
      teamSlug,
      databaseId,
    });
  }, [teamSlug, databaseId]);

  const useSuggestedPromptsLoadable = useMemo(() => {
    return createUseSuggestedPromptsLoadable({
      teamSlug,
      databaseId,
    });
  }, [teamSlug, databaseId]);

  const useGenerateSqlFromPromptExecutable = useMemo(() => {
    return createUseGenerateSqlFromPromptExecutable({
      teamSlug,
      databaseId,
    });
  }, [teamSlug, databaseId]);

  const usePromptHistoriesLoadable = useMemo(() => {
    return createUsePromptHistoryLoadable({
      teamSlug,
      databaseId,
    });
  }, [teamSlug, databaseId]);

  // csv download
  const useDownloadCsvExecutable = useMemo(() => {
    return createUseDownloadCsvExecutable({
      teamSlug,
    });
  }, [teamSlug]);

  const useDownloadCsvPreviewRecordsLoadable = useMemo(() => {
    return createuseDownloadCsvPreviewRecordsLoadable({
      teamSlug,
      databaseId,
    });
  }, [teamSlug, databaseId]);

  return {
    useRecordsWithConditionLoadable,
    useRecordsWithSqlLoadable,
    useFieldsWithConditionLoadable,
    useFieldsWithSqlLoadable,
    useSuggestedPromptsLoadable,
    useGenerateSqlFromPromptExecutable,
    usePromptHistoriesLoadable,
    useDownloadCsvExecutable,
    useDownloadCsvPreviewRecordsLoadable,
    // useUpdateFieldsOrderExecutable,
    // useUpdateFieldVisibilityExecutable,
  };
};
