import { useEditorOrThrow } from "../utils";

const useFontColor = () => {
  const editor = useEditorOrThrow();

  const handleSet = (color: string) => {
    editor.chain().focus().setColor(color).run();
  };

  const isDisabled = !editor.can().chain().focus().setColor("").run();

  const currentColor = editor.getAttributes("textStyle").color;

  return {
    handleSet,
    isDisabled,
    currentColor,
  };
};

export { useFontColor };
