import { useMutation } from "react-query";
import { useComposeExecutable } from "~/clientModel/executable";
import { useAddNotebookCellToPageMutaiton } from "~/serverStateStore/notebookPage";

const useAddCellToPageExecutable = (props: {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
}) => {
  const { teamSlug, databaseId, notebookId } = props;

  return useComposeExecutable(
    useMutation(
      useAddNotebookCellToPageMutaiton({
        teamSlug,
        notebookId,
        databaseId,
      })
    )
  );
};

export const createUseAddCellToPageExecutable = (props: {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
}) => {
  const { teamSlug, databaseId, notebookId } = props;

  return () => useAddCellToPageExecutable({ teamSlug, databaseId, notebookId });
};
