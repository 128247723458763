import { ReactNode } from "react";
import { Flex } from "~/components_next/Flex";

type BottomItemsLayoutProps = {
  children: ReactNode;
};

export const BottomItemsLayout = (props: BottomItemsLayoutProps) => {
  const { children } = props;

  return (
    <Flex direction="row" gap="2">
      {children}
    </Flex>
  );
};
