import { viewKeys } from "../keys";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import useApiView from "~/api/useApiView";

type UseCreateViewFieldMutationParams = {
  teamSlug: string;
  databaseId: string;
  viewId: string;
};

// Formulaのみ作成可能
const useCreateViewFieldMutation = ({
  teamSlug,
  databaseId,
  viewId,
}: UseCreateViewFieldMutationParams) => {
  const { _createViewField } = useApiView();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      name,
      displayName,
      formula,
    }: {
      name: string;
      displayName?: string;
      formula: string;
    }) => {
      return _createViewField({
        teamSlug,
        viewId,
        body: {
          name,
          displayName,
          formula,
          type: "formula",
        },
      });
    },
    onSuccess: () => {
      const keys = viewKeys.all({ databaseId, teamSlug });
      broadcast(keys);
      return invalidateAll(client, keys);
    },
  };
};

export { useCreateViewFieldMutation };
