import { useMemo } from "react";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { JoinTwoForm, JoinTwoFormProps } from "./JoinTwoForm";
import { FieldsClientModel } from "~/clientModel/fields";

type JoinViewsProps = {
  mainViewId: string;
  mainViewName: string;
  targetViewId: string;
  targetViewName: string;
  useViewFieldsLoadable: UseLoadable<{ viewId: string }, FieldsClientModel>;
  useCreateCellExecutable: JoinTwoFormProps["useCreateCellExecutable"];
  parentCells: CanvasCellClientModelUnion[];
};

const JoinViews = (props: JoinViewsProps) => {
  const {
    mainViewId,
    mainViewName,
    targetViewId,
    targetViewName,
    useViewFieldsLoadable,
    useCreateCellExecutable,
    parentCells,
  } = props;

  const mainViewFieldsLoadable = useViewFieldsLoadable({ viewId: mainViewId });
  const targetViewFieldsLoadable = useViewFieldsLoadable({
    viewId: targetViewId,
  });

  const mainViewFieldsOptions = useMemo(() => {
    return (
      mainViewFieldsLoadable.data?.allFields.map((field) => {
        return {
          label: field.displayName || field.name,
          value: field.name,
        };
      }) || []
    );
  }, [mainViewFieldsLoadable]);

  const targetViewFieldsOptions = useMemo(() => {
    return (
      targetViewFieldsLoadable.data?.allFields.map((field) => {
        return {
          label: field.displayName || field.name,
          value: field.name,
        };
      }) || []
    );
  }, [targetViewFieldsLoadable]);

  return (
    <>
      <JoinTwoForm
        slug1={mainViewId}
        label1={mainViewName}
        options1={mainViewFieldsOptions}
        slug2={targetViewId}
        label2={targetViewName}
        options2={targetViewFieldsOptions}
        parentCells={parentCells}
        useCreateCellExecutable={useCreateCellExecutable}
      />
    </>
  );
};

export { JoinViews };
