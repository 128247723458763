import { Flex } from "@radix-ui/themes";
import { BsDownload } from "react-icons/bs";
import { Button } from "~/components_next/Button";
import { Text } from "~/components_next/Text";

type DownloadDrawerTitleProps = {
  onClickDownload: () => void;
  isDownloading: boolean;
  isDisabled: boolean;
};

const DownloadDrawerTitle = (props: DownloadDrawerTitleProps) => {
  const { onClickDownload, isDownloading, isDisabled } = props;

  return (
    <Flex align="center" gap="4">
      <Text variant="heading">Download Records</Text>
      <Button
        variant="primary"
        onClick={onClickDownload}
        isLoading={isDownloading}
        isDisabled={isDisabled}
        size="sm"
      >
        <BsDownload />
        Download
      </Button>
    </Flex>
  );
};

export { DownloadDrawerTitle };
