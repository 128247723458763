// Notebook Cellのスタイルを全く分岐したくなったので、ロジック部分だけ切り出し
import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { OnResizeEnd, useNodes, useStore } from "reactflow";
import { useErrorToast } from "~/components_next/Error";
import { useNotebookLiveObject } from "~/features/RealtimeCollaboration/Notebook/useNotebookLiveObject";
import { useUpdateNotebookCellMetaMutaiton } from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useNotebookId } from "~/utilHooks/useNotebookId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { NotebookCellCardProps } from "./NotebookCellCard";

export const useNotebookCellCommonLogic = (props: NotebookCellCardProps) => {
  const { cellObject, isNodeDragging, isNodeSelected } = props;
  const { cellId, cellType } = cellObject;

  /**
   * Sizing & Position
   */
  const position = useStore((s) => {
    const node = s.nodeInternals.get(cellId);

    return {
      x: node?.position.x,
      y: node?.position.y,
    };
  });

  const nodeSize = useStore((s) => {
    const node = s.nodeInternals.get(cellId);

    return {
      width: node?.width || undefined,
      height: node?.height || undefined,
    };
  });

  const size = useMemo(() => {
    if (
      cellObject.settings &&
      cellObject.settings.height &&
      cellObject.settings.width
    ) {
      return cellObject.settings;
    }

    return nodeSize;
  }, [nodeSize, cellObject.settings]);

  /**
   * Select
   */
  const allNodes = useNodes();
  const addSelectedNodes = useStore((s) => s.addSelectedNodes);
  const selectedNodes = allNodes.filter((node) => node.selected);

  const handleCardClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      const isMulti = event.shiftKey;
      if (isMulti) {
        addSelectedNodes([...selectedNodes.map((node) => node.id), cellId]);
        return;
      }
      addSelectedNodes([cellId]);
    },
    [selectedNodes]
  );

  const showTargetHandle = useMemo(() => {
    if (
      cellType === "source" ||
      cellType === "richText" ||
      cellType === "stickyNote"
    )
      return false;

    // if (parentIds && parentIds?.length > 0) return true;

    // Connectingの取り回しは別途考える

    return true;
  }, [cellType]);

  const showSourceHandle = useMemo(() => {
    if (cellType === "richText" || cellType === "stickyNote") return false;
    return true;
  }, [cellType]);

  /**
   * Update Cell
   */
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const notebookId = useNotebookId();

  const { cells: liveCells = [], updateNotebookCells: updateLiveCell } =
    useNotebookLiveObject();

  const {
    mutateAsync: onUpdateNotebookCellSettings,
    isLoading: isUpdatingCellsSettings,
  } = useMutation(
    useUpdateNotebookCellMetaMutaiton({
      teamSlug,
      databaseId,
      notebookId,
    })
  );

  const { errorToast } = useErrorToast({});

  // Resize
  const handleResizeEnd: OnResizeEnd = (event, params) => {
    const cellIndex = liveCells?.findIndex(
      (cell) => cell.cellId === cellObject.cellId
    );

    if (cellIndex === undefined || cellIndex < 0) {
      errorToast(new Error("Cell not found"));
      return;
    }

    updateLiveCell({
      cell: {
        ...cellObject,
        settings: {
          ...params,
        },
      },
      index: cellIndex,
    });
    // MARK: LIVEBLOCKS
    // onUpdateNotebookCellSettings({
    //   cellId,
    //   hidePrompt: false,
    //   settings: {
    //     ...params,
    //   },
    // });
  };

  // Drag
  const handleDragEnd = () => {
    const cellIndex = liveCells?.findIndex(
      (cell) => cell.cellId === cellObject.cellId
    );

    if (cellIndex === undefined || cellIndex < 0) {
      errorToast(new Error("Cell not found"));
      return;
    }

    updateLiveCell({
      cell: {
        ...cellObject,
        settings: {
          ...size,
          ...position,
        },
      },
      index: cellIndex,
    });
    // MARK: LIVEBLOCKS
    // onUpdateNotebookCellSettings({
    //   cellId,
    //   hidePrompt: false,
    //   settings: {
    //     ...size,
    //     ...position,
    //   },
    // });
  };

  const [isDragging, setIsDragging] = useState(false);

  // TODO: このやり方でいいのか？
  useEffect(() => {
    if (isNodeSelected) {
      if (isDragging && !isNodeDragging) {
        handleDragEnd();
      }
      setIsDragging(() => isNodeDragging);
    }
  }, [isNodeSelected, isNodeDragging]);

  return {
    size,
    position,
    showTargetHandle,
    showSourceHandle,
    handleCardClick,
    handleResizeEnd,
    handleDragEnd,
  };
};
