import { mergeAttributes, Node, Editor } from "@tiptap/core";
import { StrictProseMirrorNode } from "./type";
import { PluginKey } from "@tiptap/pm/state";
import { Suggestion, SuggestionOptions } from "@tiptap/suggestion";

/**
 * optionItemsは、propsが変化した時に外部から更新できるようにstorageに保存している
 */

const EXTENSION_NAME = "mention";
const MENTION_NODE_NAME = "mention";
const OPTION_ITEMS_EXTENSION_STORAGE_KEY = "optionItems";

type MentionOptions<OptionItem> = {
  HTMLAttributes: { class?: string };
  renderHTMLTextContent: (props: {
    options: MentionOptions<OptionItem>;
    node: StrictProseMirrorNode<{ value: OptionItem }>;
  }) => string;
  convertNodeToText: (props: {
    options: MentionOptions<OptionItem>;
    node: StrictProseMirrorNode<{ value: OptionItem }>;
  }) => string;
  suggestion: Omit<SuggestionOptions, "editor">;
};

const MentionPluginKey = new PluginKey(EXTENSION_NAME);

const composeMention = <OptionItem>() =>
  Node.create<MentionOptions<OptionItem>>({
    name: MENTION_NODE_NAME,

    addOptions() {
      return {
        HTMLAttributes: {},
        renderHTMLTextContent() {
          return "";
        },
        convertNodeToText() {
          return "";
        },
        suggestion: {
          char: "@",
          pluginKey: MentionPluginKey,
          command: ({ editor, range, props }) => {
            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                {
                  type: this.name,
                  attrs: { value: props },
                },
              ])
              .run();

            window.getSelection()?.collapseToEnd();
          },
          allow: ({ state, range }) => {
            const $from = state.doc.resolve(range.from);
            const type = state.schema.nodes[this.name];
            const allow = !!$from.parent.type.contentMatch.matchType(type);

            return allow;
          },
        },
      };
    },

    group: "inline",

    inline: true,

    selectable: false,

    atom: true,

    addAttributes() {
      return {
        value: {
          default: null,
          parseHTML: (element) =>
            JSON.parse(element.getAttribute("data-value") ?? ""),
          renderHTML: (attributes) => {
            if (!attributes.value) {
              return {};
            }

            return {
              "data-value": JSON.stringify(attributes.value),
            };
          },
        },
      };
    },

    parseHTML() {
      return [
        {
          tag: `span[data-type="${this.name}"]`,
        },
      ];
    },

    renderHTML({ node, HTMLAttributes }) {
      return [
        "span",
        mergeAttributes(
          { "data-type": this.name },
          this.options.HTMLAttributes,
          HTMLAttributes,
          {
            class: "basic-typeahead-prompt-mention-item",
          }
        ),
        this.options.renderHTMLTextContent({
          options: this.options,
          node: node as StrictProseMirrorNode<{ value: OptionItem }>,
        }),
      ];
    },

    renderText({ node }) {
      return this.options.convertNodeToText({
        options: this.options,
        node: node as StrictProseMirrorNode<{ value: OptionItem }>,
      });
    },

    addKeyboardShortcuts() {
      return {
        Backspace: () =>
          this.editor.commands.command(({ tr, state }) => {
            let isMention = false;
            const { selection } = state;
            const { empty, anchor } = selection;

            if (!empty) {
              return false;
            }

            state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
              if (node.type.name === this.name) {
                isMention = true;
                tr.insertText(
                  this.options.suggestion.char || "",
                  pos,
                  pos + node.nodeSize
                );

                return false;
              }
            });

            return isMention;
          }),
      };
    },

    addProseMirrorPlugins() {
      return [
        Suggestion({
          editor: this.editor,
          ...this.options.suggestion,
        }),
      ];
    },

    addStorage() {
      return {
        [OPTION_ITEMS_EXTENSION_STORAGE_KEY]: [],
      };
    },
  });

const setOptionItems = <OptionItem>(
  items: OptionItem[],
  editor: Editor
): void => {
  editor.storage[EXTENSION_NAME][OPTION_ITEMS_EXTENSION_STORAGE_KEY] = items;
};

const getOptionItems = <OptionItem>(editor: Editor): OptionItem[] => {
  return editor.storage[EXTENSION_NAME][OPTION_ITEMS_EXTENSION_STORAGE_KEY];
};

export {
  type MentionOptions,
  MENTION_NODE_NAME,
  MentionPluginKey,
  composeMention,
  setOptionItems,
  getOptionItems,
};
