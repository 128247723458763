/* eslint-disable react/display-name */
import { Input } from "~/components_next/Input";
import { Editor } from "@monaco-editor/react";
import { useEffect, useState } from "react";
import { Checkbox } from '~/components_next/Checkbox';
import { Text } from '~/components_next/Text';
import { Flex } from '~/components_next/Flex';
import { Box } from '~/components_next/Box';
import * as Form from "@radix-ui/react-form";
import { TextArea } from "~/components_next/TextArea";
import { styled } from "@stitches/react";

export interface IntegrationInputProps {
  type: "string" | "number" | "boolean" | "select" | "password" | "date" | "sql" | "textarea" | "dynamic";
  requestData: { [key: string]: unknown };
  integrationKey: string;
  description: string;
  label: string;
  required: boolean;
  descritpionUrlLabel?: string | undefined;
  descritpionUrl?: string | undefined;
  onChangeValue: ({ integrationKey, value }: { integrationKey: string, value: unknown }) => void;
}

export const BooleanInput = (props: IntegrationInputProps) => {

  const {
    requestData,
    integrationKey,
    description,
    label,
    required,
    descritpionUrlLabel,
    descritpionUrl,
    onChangeValue,
  } = props

  return (
    <>
      <Box mb="3">

        <Checkbox
          value={integrationKey in requestData ? Boolean(requestData[integrationKey]) : false}
          required={required}
          label={label}
          onChange={(e) => {
            onChangeValue({ integrationKey, value: e })
          }}
        />

        <Box><Text variant='description'>{description}</Text></Box>
        <Flex>
          <Text variant='default'>{descritpionUrlLabel}</Text>
          <Text variant='description' ml="2">{descritpionUrl}</Text>
        </Flex>
      </Box>
    </>
  )
}

export const NumInput = (props: IntegrationInputProps) => {

  const {
    requestData,
    integrationKey,
    description,
    label,
    required,
    descritpionUrlLabel,
    descritpionUrl,
    onChangeValue,
  } = props

  return (
    <Form.Field name={integrationKey}>
      <Box mb="3">
        <Flex justify="between">
          <Text variant="default" fontWeight="light" mb="1">{label}</Text>
          <Form.Message
            style={{
              fontSize: "13px",
              color: "red",
              opacity: 0.8,
            }}
            match="valueMissing"
          >
            Please enter {label}
          </Form.Message>
        </Flex>

        <Form.Control asChild>
          <Input
            required={required}
            value={integrationKey in requestData ? Number(requestData[integrationKey]) : undefined}
            variant='primary'
            onChange={(el) => {
              onChangeValue({ integrationKey, value: Number(el.target.value) })
            }}
            type="number"
          >
          </Input>
        </Form.Control>

        <Box><Text variant='description'>{description}</Text></Box>
        <Flex>
          <Text variant='default'>{descritpionUrlLabel}</Text>
          <Text variant='description' ml="2">{descritpionUrl}</Text>
        </Flex>
      </Box>
    </Form.Field>
  )
}

export const TextInput = (props: IntegrationInputProps) => {

  const {
    requestData,
    integrationKey,
    description,
    label,
    required,
    descritpionUrlLabel,
    descritpionUrl,
    onChangeValue,
  } = props

  return (
    <Form.Field name={integrationKey}>
      <Box mb="3">
        <Flex justify="between">
          <Text variant="default" fontWeight="light" mb="1">{label}</Text>
          <Form.Message
            style={{
              fontSize: "13px",
              color: "red",
              opacity: 0.8,
            }}
            match="valueMissing"
          >
            Please enter {label}
          </Form.Message>
        </Flex>

        <Form.Control asChild>
          <TextArea
            variant='primary'
            required={required}
            value={integrationKey in requestData ? String(requestData[integrationKey]) : undefined}
            onChange={(e) => {
              onChangeValue({ integrationKey, value: e.target.value })
            }}
          />
        </Form.Control>

        <Box><Text variant='description'>{description}</Text></Box>
        <Flex>
          <Text variant='default'>{descritpionUrlLabel}</Text>
          <Text variant='description' ml="2">{descritpionUrl}</Text>
        </Flex>
      </Box>
    </Form.Field>
  )
}

export const SqlInput = (props: IntegrationInputProps) => {

  const {
    requestData,
    integrationKey,
    description,
    label,
    required,
    descritpionUrlLabel,
    descritpionUrl,
    onChangeValue,
  } = props

  const [sqlInput, setSqlInput] = useState("")
  useEffect(() => {
    if (integrationKey in requestData) {
      setSqlInput(String(requestData[integrationKey]))
    }
  }, [requestData])

  return (
    <Form.Field name={integrationKey}>
      <Box mb="3">
        <Flex justify="between">
          <Text variant="default" fontWeight="light" mb="1">{label}</Text>
          <Form.Message
            style={{
              fontSize: "13px",
              color: "red",
              opacity: 0.8,
            }}
            match="valueMissing"
          >
            Please enter {label}
          </Form.Message>
        </Flex>

        <Form.Control asChild>
          <Input required={required} variant="supplementary" style={{ position: "absolute", borderWidth: 0 }} value={sqlInput} height="1px" />
        </Form.Control>

        <Editor
          height="40vh"
          language="sql"
          value={sqlInput}
          // loading={<Spinner />}
          onChange={(value) => {
            onChangeValue({ integrationKey, value })
            if (!value) {
              setSqlInput("");
            } else {
              setSqlInput(value);
            }
          }}
          theme={"vs-dark"}
          options={{
            padding: {
              top: 24,
              bottom: 24,
            },
            scrollBeyondLastLine: false,
            minimap: {
              enabled: false,
            },
            fontSize: 14,
            lineNumbers: "off",
          }}
        />

        <Box><Text variant='description'>{description}</Text></Box>
        <Flex>
          <Text variant='default'>{descritpionUrlLabel}</Text>
          <Text variant='description' ml="2">{descritpionUrl}</Text>
        </Flex>
      </Box>
    </Form.Field>
  )
}

export const DefaultInput = (props: IntegrationInputProps) => {

  const {
    type,
    requestData,
    integrationKey,
    description,
    label,
    required,
    descritpionUrlLabel,
    descritpionUrl,
    onChangeValue,
  } = props

  return (
    <Form.Field name={integrationKey}>
      <Box mb="3">
        <Flex justify="between">
          <Flex>
            <Text variant="default" fontWeight="light" mb="1">{label}</Text>
            {
              required && (
                <Box css={{ color: "red" }}>*</Box>
              )
            }
          </Flex>
          <Form.Message
            style={{
              fontSize: "13px",
              color: "red",
              opacity: 0.8,
            }}
            match="valueMissing"
          >
            Please enter {label}
          </Form.Message>
        </Flex>

        <Form.Control asChild>
          <Input
            required={required}
            variant="primary"
            type={type === 'password' ? 'password' : 'text'}
            title={required ? 'Required this field.' : undefined}
            value={integrationKey in requestData ? String(requestData[integrationKey]) : ''}
            onChange={(e) => {
              onChangeValue({ integrationKey, value: e.target.value })
            }}
          />
        </Form.Control>

        <Box><Text variant='description'>{description}</Text></Box>
        <Flex>
          <Text variant='default'>{descritpionUrlLabel}</Text>
          <Text variant='description' ml="2">{descritpionUrl}</Text>
        </Flex>
      </Box>
    </Form.Field>
  )
}


const StyledInput = styled(Input, {
  webkitCalendarPickerIndicator: {
    width: "100%",
    height: "100%",
    position: "absolute",
    opacity: "0",
    top: "0",
    left: "0",
  },
  "&:before": {
    content: 'attr(placeholder)',
    width: '100%',
    color: '#C2C2C2',
  },
  "&.is-blank": {
    "input[type=date]::-webkit-datetime-edit-fields-wrapper": {
      display: "none",
    },
    "input[type='date']": {
      color: "#C2C2C2"
    }

  }
})

export const DateInput = (props: IntegrationInputProps) => {

  const {
    requestData,
    integrationKey,
    description,
    label,
    required,
    descritpionUrlLabel,
    descritpionUrl,
    onChangeValue,
  } = props

  return (
    <Form.Field name={integrationKey}>
      <Box mb="3">
        <Flex justify="between">
          <Text variant="default" fontWeight="light" mb="1">{label}</Text>
          <Form.Message
            style={{
              fontSize: "13px",
              color: "red",
              opacity: 0.8,
            }}
            match="valueMissing"
          >
            Please enter {label}
          </Form.Message>
        </Flex>

        <Form.Control asChild>
          {
            integrationKey in requestData
              && String(requestData[integrationKey]) !== ""
              ? <Input
                required={required}
                variant="primary"
                type={"date"}
                placeholder="YYYY/MM/DD"
                title={required ? 'Required this field.' : undefined}
                value={integrationKey in requestData ? String(requestData[integrationKey]) : ''}
                onChange={(e) => {
                  onChangeValue({ integrationKey, value: e.target.value })
                }}
              />
              : <StyledInput
                required={required}
                variant="primary"
                type={"date"}
                placeholder="YYYY/MM/DD"
                title={required ? 'Required this field.' : undefined}
                value={integrationKey in requestData ? String(requestData[integrationKey]) : ''}
                onChange={(e) => {
                  onChangeValue({ integrationKey, value: e.target.value })
                }}
              />
          }
        </Form.Control>

        <Box><Text variant='description'>{description}</Text></Box>
        <Flex>
          <Text variant='default'>{descritpionUrlLabel}</Text>
          <Text variant='description' ml="2">{descritpionUrl}</Text>
        </Flex>
      </Box>
    </Form.Field>
  )
}
