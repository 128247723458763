import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";

export class CreatedByFieldTypeClientModel extends FieldTypeClientModelBase {
  public get type(): "createdBy" {
    return "createdBy";
  }

  public get fieldTypeLabel(): "Created By" {
    return "Created By";
  }

  public get isAutoGenerated(): true {
    return true;
  }

  public get isEmbeddable(): false {
    return false;
  }

  public get canBeTargetOfSyncingByComputedField(): true {
    return true;
  }

  public get _updatableFieldTypes() {
    return [];
  }

  public validate(): ValidationResult {
    return { isValid: true };
  }
}
