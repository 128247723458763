import { ReactNode, useEffect } from "react";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useBroadcastEventListener } from "./Broadcast/useBroadcastEventListener";
import { RealtimeCollaborationUtilContextProvider } from "./context/RealtimeCollaborationUtilContext";
import { usePresenceBasics } from "./hooks/usePresenceBasics";
import { RoomProvider, useUpdateMyPresence } from "./liveblocks.config";
import { ClientSideSuspense } from "@liveblocks/react";

const CollaborativeRoomUtility = () => {
  const updateMyPresence = useUpdateMyPresence();

  const PresenceBasics = usePresenceBasics();

  useEffect(() => {
    if (!PresenceBasics.user) return;
    updateMyPresence({
      ...PresenceBasics,
    });
  }, [PresenceBasics]);

  // // Real-time broadcast
  useBroadcastEventListener();

  return <></>;
};

type CollaborativeRoomProps = {
  children: ReactNode;
};

const CollaborativeRoom = (props: CollaborativeRoomProps) => {
  const { children } = props;

  const teamSlug = useTeamSlug();
  const { user, path } = usePresenceBasics();

  return (
    <RoomProvider
      id={teamSlug}
      initialPresence={{
        path,
        user: user || {
          username: "",
          firstName: "",
          lastName: "",
          email: "",
          iconUrl: null,
          userId: "dummy",
        },
      }}
      initialStorage={{}}
    >
      <ClientSideSuspense fallback={<div>Loading…</div>}>
        {() => (
          <>
            <RealtimeCollaborationUtilContextProvider>
              {children}
            </RealtimeCollaborationUtilContextProvider>
            <CollaborativeRoomUtility />
          </>
        )}
      </ClientSideSuspense>
    </RoomProvider>
  );
};

// const CollaborativeRoomWrapper = (props: CollaborativeRoomProps) => {
//   const teamSlug = useTeamSlug();
//   const { user, path } = usePresenceBasics();
//   return (
//     <RoomProvider id={teamSlug} initialPresence={{ path, user }}>
//       <ClientSideSuspense fallback={<div>Loading…</div>}>
//         {() => <CollaborativeRoom {...props} />}
//       </ClientSideSuspense>
//     </RoomProvider>
//   );
// }

export { CollaborativeRoom };
