import {
  RecordConditionRuleUnit,
  RecordEmbeddingSortConditionUnit,
  RecordFilterCondition,
  RecordFormatCondition,
  RecordSortConditionUnit,
} from "@usemorph/morph-dashboard-types";

/**
 * records
 */

type RecordsDependencies = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

type GetRecordDependencies = {
  select: string[];
  sort?: (RecordSortConditionUnit | RecordEmbeddingSortConditionUnit)[];
  filter?: RecordFilterCondition;
  additionalSort?: RecordSortConditionUnit[];
  addtionalFilter?: RecordFilterCondition;
  format?: RecordFormatCondition[];
  join?: RecordConditionRuleUnit[];
  limit?: number;
  skip?: number;
} & RecordsDependencies;

type GetRecordWithSQLDependencies = {
  sql: string;
  limit?: number;
  skip?: number;
} & RecordsDependencies;

type PreviewForUpdatingRecordWithFormulaDependencies = {
  formula: string;
  filter?: RecordFilterCondition;
  fieldName: string;
} & RecordsDependencies;

type PreviewForUpdatingRecordWithPromptDependencies = {
  prompt: string;
  fieldName: string;
  filter?: RecordFilterCondition;
} & RecordsDependencies;

type DownloadRecordAsCSVDependencies = {
  select: string[];
  filter?: RecordFilterCondition;
  sort?: RecordSortConditionUnit[];
  join?: RecordConditionRuleUnit[];
  format?: RecordFormatCondition[];
  limit?: number;
  skip?: number;
  additionalFilter?: RecordFilterCondition;
  additionalSort?: RecordSortConditionUnit[];
} & RecordsDependencies;

type GetSoftDeletedRecordsDependencies = {
  select: string[];
  withFields?: boolean;
  filter?: RecordFilterCondition;
  sort?: RecordSortConditionUnit[];
  limit?: number;
  skip?: number;
} & RecordsDependencies;

type CreateRecordObjectWithPromptDependencies = {
  prompt: string;
} & RecordsDependencies;

const recordsKeys = {
  /**
   * all
   */
  all: ({ teamSlug, databaseId, tableSlug }: RecordsDependencies) =>
    ["records", teamSlug, databaseId, tableSlug] as const,

  /**
   * get record
   */
  allGetRecord: ({ teamSlug, databaseId, tableSlug }: RecordsDependencies) =>
    [
      ...recordsKeys.all({ teamSlug, databaseId, tableSlug }),
      "getRecord",
    ] as const,
  getRecord: ({
    teamSlug,
    databaseId,
    tableSlug,
    select,
    sort,
    filter,
    additionalSort,
    addtionalFilter,
    format,
    join,
    limit,
    skip,
  }: GetRecordDependencies) =>
    [
      ...recordsKeys.allGetRecord({ teamSlug, databaseId, tableSlug }),
      select,
      sort ||
      filter ||
      limit ||
      skip ||
      format ||
      join ||
      addtionalFilter ||
      additionalSort
        ? {
            sort,
            filter,
            limit,
            skip,
            format,
            join,
            addtionalFilter,
            additionalSort,
          }
        : undefined,
    ] as const,

  /**
   * get record with sql
   */
  allGetRecordWithSql: ({
    teamSlug,
    databaseId,
    tableSlug,
  }: RecordsDependencies) =>
    [
      ...recordsKeys.all({ teamSlug, databaseId, tableSlug }),
      "getRecordWithSql",
    ] as const,
  getRecordWithSql: ({
    teamSlug,
    databaseId,
    tableSlug,
    sql,
    limit,
    skip,
  }: GetRecordWithSQLDependencies) =>
    [
      ...recordsKeys.allGetRecordWithSql({ teamSlug, databaseId, tableSlug }),
      { sql, limit, skip },
    ] as const,

  /**
   * Preview for updating record with prompt
   */
  allPreviewRecordForUpdatingWithPrompt: ({
    teamSlug,
    databaseId,
    tableSlug,
  }: RecordsDependencies) =>
    [
      ...recordsKeys.all({ teamSlug, databaseId, tableSlug }),
      "previewRecordForUpdatingWithPrompt",
    ] as const,

  previewRecordForUpdatingWithPrompt: ({
    teamSlug,
    databaseId,
    tableSlug,
    prompt,
    fieldName,
    filter,
  }: PreviewForUpdatingRecordWithPromptDependencies) =>
    [
      ...recordsKeys.allPreviewRecordForUpdatingWithPrompt({
        teamSlug,
        databaseId,
        tableSlug,
      }),
      {
        prompt,
        fieldName,
        filter,
      },
    ] as const,

  /**
   * Preview for updating record with formula
   */
  allPreviewRecordForUpdatingWithFormula: ({
    teamSlug,
    databaseId,
    tableSlug,
  }: RecordsDependencies) =>
    [
      ...recordsKeys.all({ teamSlug, databaseId, tableSlug }),
      "previewRecordForUpdatingWithFormula",
    ] as const,

  previewRecordForUpdatingWithFormula: ({
    teamSlug,
    databaseId,
    tableSlug,
    formula,
    fieldName,
    filter,
  }: PreviewForUpdatingRecordWithFormulaDependencies) =>
    [
      ...recordsKeys.allPreviewRecordForUpdatingWithFormula({
        teamSlug,
        databaseId,
        tableSlug,
      }),
      {
        formula,
        fieldName,
        filter,
      },
    ] as const,

  /**
   * download record as csv
   */
  allDownloadRecordAsCSV: ({
    teamSlug,
    databaseId,
    tableSlug,
  }: RecordsDependencies) =>
    [
      ...recordsKeys.all({ teamSlug, databaseId, tableSlug }),
      "downloadRecordAsCSV",
    ] as const,
  downloadRecordAsCSV: ({
    teamSlug,
    databaseId,
    tableSlug,
    select,
    sort,
    format,
    filter,
    join,
    limit,
    skip,
    additionalFilter,
    additionalSort,
  }: DownloadRecordAsCSVDependencies) =>
    [
      ...recordsKeys.allDownloadRecordAsCSV({
        teamSlug,
        databaseId,
        tableSlug,
      }),
      {
        select,
        sort,
        filter,
        format,
        limit,
        skip,
        join,
        additionalFilter,
        additionalSort,
      },
    ] as const,

  /**
   * get soft deleted record
   */
  allGetSoftDeletedRecord: ({
    teamSlug,
    databaseId,
    tableSlug,
  }: RecordsDependencies) =>
    [
      ...recordsKeys.all({ teamSlug, databaseId, tableSlug }),
      "getSoftDeletedRecord",
    ] as const,
  getSoftDeletedRecord: ({
    teamSlug,
    databaseId,
    tableSlug,
    select,
    filter,
    sort,
    limit,
    skip,
  }: GetSoftDeletedRecordsDependencies) =>
    [
      ...recordsKeys.allGetSoftDeletedRecord({
        teamSlug,
        databaseId,
        tableSlug,
      }),
      {
        select,
        filter,
        sort,
        limit,
        skip,
      },
    ] as const,

  /**
   * Freeform - Create Record Object with Prompt
   */
  createRecordObjectWithPrompt: ({
    teamSlug,
    databaseId,
    tableSlug,
    prompt,
  }: CreateRecordObjectWithPromptDependencies) =>
    [
      ...recordsKeys.allPreviewRecordForUpdatingWithPrompt({
        teamSlug,
        databaseId,
        tableSlug,
      }),
      {
        prompt,
      },
      "createRecordObjectWithPrompt",
    ] as const,
};

export { recordsKeys };
