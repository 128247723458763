import { useCallback, useRef } from "react";
import { Text } from "~/components_next/Text";
import { FlatFilterConditionForm } from "~/features/Fields/FilterCondition/FlatFilterConditionForm";
import { FlatFilterCondition } from "~/features/Fields/FilterCondition/flatFilterConditionTypes";
import { SortConditionForm } from "~/features/Fields/SortCondition/SortConditionForm";
import {
  useFilterCondition,
  useSetFilterCondition,
} from "../../../states/filterCondition";
import {
  useSortCondition,
  useSetSortCondition,
} from "../../../states/sortCondition";
import { validateFilterCondition } from "~/features/Fields/FilterCondition/validateFilterCondition";
import { useViewId } from "~/utilHooks/useViewId";
import { useQuery } from "react-query";
import { useGetViewFieldsQuery } from "~/serverStateStore/views";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { isArrayWithAtLeastOneElement } from "~/utils/commonTypeGuards";
import { useView } from "~/features/SourceAndViews/common/utils/useView";
import { HintPopover } from "~/components_next/Hint";
import { RecordSortConditionUnit } from "@usemorph/morph-dashboard-types";
import { Box, Flex } from "@radix-ui/themes";
import { Spinner } from "~/components_next/Spinner";

type FilterAndSortFormProps = {
  onClose: () => void;
};

const FilterAndSortForm = (props: FilterAndSortFormProps) => {
  const { onClose } = props;
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  const filterCondition = useFilterCondition(viewId);
  const setFilterCondition = useSetFilterCondition(viewId);

  const sortCondition = useSortCondition(viewId);
  const setSortCondition = useSetSortCondition(viewId);

  const { data: viewData } = useView();

  const { data: viewFieldsData } = useQuery(
    useGetViewFieldsQuery({
      teamSlug,
      databaseId,
      viewId,
    })
  );

  const handleFilterChange = useCallback(
    (filterCondition: FlatFilterCondition | null) => {
      if (!filterCondition && !sortCondition) {
        setFilterCondition(null);
        onClose();
        return;
      }

      if (!filterCondition) {
        setFilterCondition(null);
        return;
      }

      // filterが存在する時、バリデーションに成功すればconditionalQuery.filterを更新する
      if (
        viewFieldsData &&
        validateFilterCondition(filterCondition, viewFieldsData.fields)
      ) {
        setFilterCondition(filterCondition);
      }
    },
    [onClose, setFilterCondition, sortCondition, viewFieldsData]
  );

  const handleSortChange = useCallback(
    (sortCondition: RecordSortConditionUnit | null) => {
      if (!filterCondition && !sortCondition) {
        setSortCondition(null);
        onClose();
        return;
      }

      setSortCondition(sortCondition);
    },
    [filterCondition, onClose, setSortCondition]
  );
  const containerRef = useRef<HTMLDivElement>(null);

  if (
    !viewFieldsData ||
    !viewData ||
    !isArrayWithAtLeastOneElement(viewFieldsData.fields)
  ) {
    return (
      <Flex py="9" justify="center" align="center">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Flex ref={containerRef} direction="column" p="4" align="start" gap="6">
      <Box>
        <Text variant="subheading" mb="2">
          Filters
        </Text>
        <FlatFilterConditionForm
          simpleFields={viewFieldsData.fields}
          flatFilterCondition={filterCondition}
          onChangeFlatFilterCondition={handleFilterChange}
        />
      </Box>
      <Box>
        <Flex gap="2" align="center" mb="2">
          <Text variant="subheading">Sorts</Text>
          <HintPopover title="About sort from Kanban view">
            If reordering is enabled, sorting from the View is not possible.
          </HintPopover>
        </Flex>

        <SortConditionForm
          simpleFields={viewFieldsData.fields}
          sortCondition={sortCondition}
          onChangeSortCondition={handleSortChange}
          isReadOnly={viewData.condition.reorderable}
        />
      </Box>
    </Flex>
  );
};

export { FilterAndSortForm };
