import { useEditorOrThrow } from "../utils";

const useHorizontalRule = () => {
  const editor = useEditorOrThrow();

  const handleSet = () => {
    editor.chain().focus().setHorizontalRule().run();
  };

  const isActive = editor.isActive("horizontalRule");

  const isDisabled = !editor.can().chain().focus().setHorizontalRule().run();

  return {
    handleSet,
    isActive,
    isDisabled,
  };
};

export { useHorizontalRule };
