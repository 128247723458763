import { BsArrowRepeat, BsPlayFill } from "react-icons/bs";
import { CanvasCellPromptClientModel } from "~/clientModel/canvas";
import { IconButton } from "~/components_next/IconButton";
import { PlaygroundCellActionButton } from "../common/PlaygroundCellActionButton";

type PlaygroundCellPromptExecuteButtonProps = {
  cellClientModel: CanvasCellPromptClientModel;
  onExecute?: () => void;
  isExecuting: boolean;
  onRetry: () => void;
  isRetrying: boolean;
};

const PlaygroundCellPromptExecuteButton = (
  props: PlaygroundCellPromptExecuteButtonProps
) => {
  const { cellClientModel, onExecute, isExecuting, onRetry, isRetrying } =
    props;

  if (cellClientModel.isFailed) {
    return (
      <PlaygroundCellActionButton>
        <IconButton
          size="lg"
          icon={<BsArrowRepeat />}
          tooltip="Retry to generate code and execute it."
          onClick={onRetry}
          isLoading={isRetrying}
        />
      </PlaygroundCellActionButton>
    );
  }

  if (!onExecute) {
    return null;
  }

  return (
    <PlaygroundCellActionButton>
      <IconButton
        size="lg"
        icon={<BsPlayFill />}
        tooltip="Execute generated code again."
        onClick={onExecute}
        isLoading={isExecuting}
      />
    </PlaygroundCellActionButton>
  );
};

export { PlaygroundCellPromptExecuteButton };
