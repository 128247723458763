import { TableClientModel } from "./table/TableClientModel";

type TablesClientModelData = {
  tables: TableClientModel[];
};

export class TablesClientModel {
  readonly #data: TablesClientModelData;

  constructor(data: TablesClientModelData) {
    this.#data = data;
  }

  public get data(): TablesClientModelData {
    return this.#data;
  }

  public get allTables(): TableClientModel[] {
    return this.#data.tables;
  }

  public findTableByTableSlug(tableSlug: string): TableClientModel | undefined {
    return this.allTables.find((table) => table.data.tableSlug === tableSlug);
  }
}
