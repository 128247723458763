import { Box, Flex } from "@radix-ui/themes";
import { Dispatch, SetStateAction } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { Button } from "~/components_next/Button";
import { SimpleSelect } from "~/components_next/Select";
import { Text } from "~/components_next/Text";

const limits = [10, 30, 50, 100] as const;

type Limit = (typeof limits)[number];

const limitOptions = limits.map((limit) => ({
  value: limit.toString(),
  label: limit.toString(),
}));

type TablePaginationProps = {
  skip: number;
  setSkip: Dispatch<SetStateAction<number>>;
  limit: number;
  setLimit: Dispatch<SetStateAction<Limit>>;
} & (
  | {
      isDisabled: true;
      count?: number;
    }
  | {
      isDisabled: false;
      count: number;
    }
);

const TablePagination = (props: TablePaginationProps) => {
  const { skip, setSkip, limit, setLimit, isDisabled } = props;
  const handleLimitSelect = (value: string | null) => {
    setLimit(Number(value) as Limit);
    setSkip(0);
  };

  const handlePrevClick = () => {
    setSkip((prev) => prev - limit);
  };

  const handleNextClick = () => {
    setSkip((prev) => prev + limit);
  };

  const prevDisabled = isDisabled || skip === 0;
  const nextDisabled = isDisabled || skip + limit >= props.count;

  const start = skip + 1;
  const end = (count: number) => Math.min(skip + limit, count);
  const showingItemsLabel = isDisabled
    ? null
    : `${start} - ${end(props.count)} of ${props.count}`;

  return (
    <Flex align="center" gap="2">
      <Box>
        <SimpleSelect
          variant="supplementary"
          value={limit.toString()}
          size="xs"
          onChange={handleLimitSelect}
          isDisabled={isDisabled}
          options={limitOptions}
        />
      </Box>

      <Button
        size="xs"
        variant="tertiary"
        isDisabled={prevDisabled}
        onClick={handlePrevClick}
      >
        <BsChevronLeft />
      </Button>
      <Button
        size="xs"
        variant="tertiary"
        isDisabled={nextDisabled}
        onClick={handleNextClick}
      >
        <BsChevronRight />
      </Button>
      <Text variant="description">{showingItemsLabel}</Text>
    </Flex>
  );
};

export { TablePagination, limitOptions, type Limit };
