import { viewKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiView from "~/api/useApiView";
import {
  RecordConditionRuleUnit,
  RecordFilterCondition,
  RecordFormatCondition,
  RecordSortConditionUnit,
} from "@usemorph/morph-dashboard-types";

type UseGroupQueryViewRecordsQueryParams = {
  teamSlug: string;
  databaseId: string;
  viewId: string;
  select: string[];
  join?: RecordConditionRuleUnit[];
  filter?: RecordFilterCondition;
  format?: RecordFormatCondition[];
  sort?: RecordSortConditionUnit[];
  limit?: number;
  skip?: number;
  groupSelect?: string;
};

const useGroupQueryViewRecordsQuery = ({
  databaseId,
  teamSlug,
  viewId,
  select,
  join,
  filter,
  format,
  sort,
  limit,
  skip,
  groupSelect,
}: UseGroupQueryViewRecordsQueryParams) => {
  const { _groupQueryViewRecords } = useApiView();

  const client = useQueryClient();
  client.setQueryDefaults(
    viewKeys.allGroupQueryViewRecords({ databaseId, teamSlug, viewId }),
    commonQueryOptions
  );

  return {
    queryKey: viewKeys.groupQueryViewRecords({
      databaseId,
      teamSlug,
      viewId,
      select,
      join,
      filter,
      format,
      sort,
      limit,
      skip,
      groupSelect,
    }),
    queryFn: () => {
      return _groupQueryViewRecords({
        teamSlug,
        viewId,
        body: {
          select,
          join,
          filter,
          format,
          sort,
          limit,
          skip,
          groupSelect,
        },
      });
    },
  };
};

export { useGroupQueryViewRecordsQuery };
