import { BsEye } from "react-icons/bs";
import { Text } from "~/components_next/Text";
import { IconButton } from "~/components_next/IconButton";
import { Flex } from "~/components_next/Flex";
import { Badge } from "~/components_next/Badge";
import { CsvDownloadFieldsClientModel } from "~/clientModel/csvDownload/csvDownloadFields";
import { CsvDownloadFieldClientModel } from "~/clientModel/csvDownload/csvDownloadFields/csvDownloadField";

type HiddenFieldsProps = {
  csvDownloadFields: CsvDownloadFieldsClientModel;
  onCsvDownloadFieldsChange: (
    csvDownloadFields: CsvDownloadFieldsClientModel
  ) => void;
};

const HiddenFields = (props: HiddenFieldsProps) => {
  const { csvDownloadFields, onCsvDownloadFieldsChange } = props;

  const handleShowField = (csvDownloadField: CsvDownloadFieldClientModel) => {
    onCsvDownloadFieldsChange(
      csvDownloadFields.updateCsvDownloadField(csvDownloadField.showField())
    );
  };

  return (
    <Flex direction="row">
      <Text variant="subheading" style={{ width: "120px", flex: "none" }}>
        Hidden Fields
      </Text>
      <Flex direction="row" gap="2" style={{ flexWrap: "wrap" }}>
        {csvDownloadFields.hiddenCsvDownloadFields.allCsvDownloadFields.map(
          (hiddenField) => (
            <Badge
              variant="tertiary"
              key={hiddenField.baseField.name}
              size="sm"
              onClick={() => handleShowField(hiddenField)}
            >
              {hiddenField.baseField.label}
              <IconButton size="xs" icon={<BsEye />} tooltip="Show" />
            </Badge>
          )
        )}
      </Flex>
    </Flex>
  );
};

export { HiddenFields };
