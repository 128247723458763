import { match } from "ts-pattern";
import {
  CanvasCellClientModelBase,
  CanvasCellDataType,
  CanvasCellResizeParams,
} from "./CanvasCellClientModelBase";

export type CanvasCellStickyNoteDataType = CanvasCellDataType & {
  cellType: "stickyNote";
};

export type StickyNoteColor = "amber" | "crimson" | "green" | "blue";

export class CanvasCellStickyNoteClientModel extends CanvasCellClientModelBase<
  CanvasCellStickyNoteClientModel,
  CanvasCellStickyNoteDataType
> {
  constructor(data: CanvasCellStickyNoteDataType) {
    super(data);
  }

  /**
   *
   * Custom value getters
   *
   */

  get html(): string | null {
    return this.source.stickyNote?.value || null;
  }

  get color(): StickyNoteColor {
    return match(this.source.stickyNote?.color)
      .with("amber", () => "amber" as const)
      .with("green", () => "green" as const)
      .with("blue", () => "blue" as const)
      .with("crimson", () => "crimson" as const)
      .otherwise(() => "amber" as const);
  }

  get isResizable(): boolean {
    return false;
  }

  get showSourceHandle(): boolean {
    return false;
  }

  get showTargetHandle(): boolean {
    return false;
  }

  updateRichText(richText: string): CanvasCellStickyNoteClientModel {
    return new CanvasCellStickyNoteClientModel({
      ...this.data,
      source: {
        ...this.data.source,
        stickyNote: {
          color: this.color,
          value: richText,
        },
      },
    });
  }

  updateSize(size: {
    width: number;
    height: number;
  }): CanvasCellStickyNoteClientModel {
    return new CanvasCellStickyNoteClientModel({
      ...this.data,
      settings: {
        ...this.data.settings,
        width: size.width,
        height: size.height,
      },
    });
  }

  updateCellName(cellName: string): CanvasCellStickyNoteClientModel {
    return new CanvasCellStickyNoteClientModel({
      ...this.data,
      cellName,
    });
  }

  onResize(
    resizePrams: CanvasCellResizeParams
  ): CanvasCellStickyNoteClientModel {
    return new CanvasCellStickyNoteClientModel({
      ...this.data,
      settings: {
        ...this.data.settings,
        ...resizePrams,
      },
    });
  }
}
