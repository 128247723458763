import {
  RowInfoSchemaDataSmartFieldStatus,
  RowInfoSchemaDataTypeCastFailure,
} from "@usemorph/morph-dashboard-types";
import { RecordEntryMetaClientModel } from "./RecordEntryMetaClientModel";

export class RecordEntryMetaClientModelFactory {
  public static fromRowInfoSchemaData(
    rowInfoSchemaData: (
      | RowInfoSchemaDataSmartFieldStatus
      | RowInfoSchemaDataTypeCastFailure
    )[]
  ): RecordEntryMetaClientModel {
    return new RecordEntryMetaClientModel({ meta: rowInfoSchemaData });
  }
}
