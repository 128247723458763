import { Dispatch, SetStateAction } from "react";
import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil";
import { urlSyncEffect } from "recoil-sync";
import { string } from "@recoiljs/refine";
import { createTableViewUrlSyncStoreEffectOptions } from "./TableViewUrlSyncStore/createTableViewUrlSyncStoreEffectOptions";

// 最後に実行したsql
const queryingSqlFamily = atomFamily<string, { tableSlug: string }>({
  key: "tableViewQueryingSql",
  default: "",
  effects: [
    urlSyncEffect(
      createTableViewUrlSyncStoreEffectOptions({
        checker: string(),
        key: "tableViewQueryingSql",
        defaultValue: "",
      })
    ),
  ],
});

const useQueryingSql = (tableSlug: string): string => {
  const sql = useRecoilValue(queryingSqlFamily({ tableSlug }));
  return sql;
};

const useSetQueryingSql = (
  tableSlug: string
): Dispatch<SetStateAction<string>> => {
  const setSql = useSetRecoilState(queryingSqlFamily({ tableSlug }));
  return setSql;
};

// 入力中のsql
const sqlInputFamily = atomFamily<string, { tableSlug: string }>({
  key: "table-view-sql-input",
  default: "",
});

const useSqlInput = (tableSlug: string) => {
  return useRecoilValue(sqlInputFamily({ tableSlug }));
};

const useSetSqlInput = (tableSlug: string) => {
  return useSetRecoilState(sqlInputFamily({ tableSlug }));
};

export { useQueryingSql, useSetQueryingSql, useSqlInput, useSetSqlInput };
