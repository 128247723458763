import { Button } from "~/components_next/Button";
import { Dialog } from "~/components_next/Dialog";

export const ConfirmDialog = ({
  isOpen,
  setIsOpen,
  onConfirm,
  selectPlan,
  isLoading,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onConfirm: (planId: string) => void;
  selectPlan?: { planId: string, planName: string };
  isLoading: boolean;
}) => {

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Content>
        <>
          <Dialog.Title>{selectPlan?.planName ?? ""} plan</Dialog.Title>
          <Dialog.Body>Do you really want to choose this plan?</Dialog.Body>
          <Dialog.Footer>
            <Button
              variant="primary"
              onClick={() => onConfirm(selectPlan?.planId ?? "")}
              isLoading={isLoading}
            >
              Select
            </Button>
          </Dialog.Footer>
        </>
      </Dialog.Content>
    </Dialog.Root>
  );
};
