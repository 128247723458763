import { ForwardedRef, forwardRef, ReactNode } from "react";
import { MorphThemeSize } from "../commonProps.type";
import { Badge } from "../Badge";
import { IconButton } from "../IconButton/IconButton";
import { ActionIcon } from "../Icons";
import { Flex } from "@radix-ui/themes";
import { styled } from "@stitches/react";

type TagProps = {
  size?: MorphThemeSize;
  variant: "primary" | "secondary" | "tertiary";
  tooltip?: string;
  onClear?: () => void;
  children?: ReactNode;
};

const ContentWrapper = styled(Flex, {
  variants: {
    size: {
      xs: {
        gap: 6,
      },
      sm: {
        gap: 6,
      },
      md: {
        gap: 6,
      },
      lg: {
        gap: 14,
      },
      xl: {
        gap: 14,
      },
    },
  },
});

const _Tag = (props: TagProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { size, variant, tooltip = "remove", onClear, children } = props;

  return (
    <Badge ref={ref} size={size} variant={variant}>
      <ContentWrapper align="center" size={size}>
        {children}
        <IconButton
          tooltip={tooltip}
          size="xs"
          isDisabled={!onClear}
          style={{ backgroundColor: "transparent" }}
          icon={<ActionIcon type="close" size="sm" />}
          onClick={onClear}
        />
      </ContentWrapper>
    </Badge>
  );
};

const Tag = forwardRef(_Tag);

export { Tag, type TagProps };
