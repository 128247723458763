// types
import { useQueryClient } from "react-query";
import useApiImport from "~/api/useApiImport";
import { tablesKeys } from "~/serverStateStore/tables";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { importsKeys } from "../keys";

interface UseRunImportMutationParams {
  teamSlug: string;
  databaseId: string;
}

const useRunImportMutation = ({
  teamSlug,
  databaseId,
}: UseRunImportMutationParams) => {
  const { _runImport } = useApiImport();

  const client = useQueryClient();

  const invalidateImportKeys = () => {
    invalidateAll(
      client,
      importsKeys.listImportStatusFromOtherServices({
        teamSlug,
        databaseId,
      })
    );
  };

  return {
    mutationFn: ({ importDataSchemaId }: { importDataSchemaId: string }) => {
      return _runImport({
        teamSlug,
        databaseId,
        importDataSchemaId,
      });
    },
    onSuccess: () => {
      setTimeout(invalidateImportKeys, 1000);
      // レスポンスが来る時点でたった今実行したimportの情報が含まれてない
      return Promise.all([
        invalidateAll(client, tablesKeys.all({ teamSlug, databaseId })),
        invalidateAll(
          client,
          importsKeys.listImportStatusFromOtherServices({
            teamSlug,
            databaseId,
          })
        ),
      ]);
    },
  };
};

export { useRunImportMutation };
