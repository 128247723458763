import { FieldClientModel } from "~/clientModel/fields/field";
import {
  ReadonlyRecordEntryClientModelBase,
  ReadonlyRecordEntryClientModelBaseConstructorParams,
} from "../RecordEntryClientModelBase";
import { zAllowNullIfNullable, zIntString, zValidate } from "../validatorsUtil";
import { z } from "zod";
import { NumberDisplaySettingClientModel } from "~/clientModel/fields/field/displaySetting/numberDisplaySetting";

type AutoBigNumberRecordEntryClientModelData =
  ReadonlyRecordEntryClientModelBaseConstructorParams;

export class AutoBigNumberRecordEntryClientModel extends ReadonlyRecordEntryClientModelBase<
  string | null
> {
  constructor(data: AutoBigNumberRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "autoBigNumber" {
    return "autoBigNumber";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([zIntString, zAllowNullIfNullable(field)]),
      this.rawValue
    );
  }

  public getValidatedDisplayValue(field: FieldClientModel) {
    const validatedValue = this.getValidatedValue(field);

    return validatedValue.isValid &&
      field.displaySetting instanceof NumberDisplaySettingClientModel
      ? ({
          isValid: true,
          value: field.displaySetting.getDisplayValue(validatedValue.value),
        } as const)
      : validatedValue;
  }
}
