import { Responsive, Text as RadixThemeText } from "@radix-ui/themes";
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from "react";
import { match, P } from "ts-pattern";

type RadixThemeTextProps = ComponentPropsWithoutRef<typeof RadixThemeText>;

type TextComponentVariant =
  | "default"
  | "heading"
  | "subheading"
  | "description"
  | "errorMessage"
  | "tinyDescription"
  | "tinyHeading";

type TextProps = Omit<RadixThemeTextProps, "variant" | "color" | "weight"> & {
  variant?: TextComponentVariant;
  colorOverride?: RadixThemeTextProps["color"];
  fontWeight?: RadixThemeTextProps["weight"];
};

const _Text = (props: TextProps, ref?: ForwardedRef<HTMLParagraphElement>) => {
  const {
    variant = "default",
    children,
    colorOverride,
    fontWeight,
    style,
  } = props;

  // const radixTextSize = variant === "heading" ? "5" : "2";
  const getRadixTextSize = (): Responsive<
    "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9"
  > => {
    return match(variant)
      .with("heading", () => "5" as const)
      .with("tinyHeading", () => "1" as const)
      .with("tinyDescription", () => "1" as const)
      .otherwise(() => "2" as const);
  };
  const getWeight = () => {
    return match(variant)
      .with("heading", () => "bold" as const)
      .with("subheading", () => "bold" as const)
      .with("default", () => "regular" as const)
      .with("description", () => "regular" as const)
      .with("errorMessage", () => "regular" as const)
      .with("tinyDescription", () => "regular" as const)
      .with("tinyHeading", () => "medium" as const)
      .exhaustive();
  };

  const getColor = () => {
    // if (colorOverride) {
    //   return getCollaboratorColor(colorOverride).text;
    // }
    return match(variant)
      .with(
        P.union("subheading", "description", "tinyDescription"),
        () => "gray" as RadixThemeTextProps["color"]
      )
      .with("errorMessage", () => "red" as RadixThemeTextProps["color"])
      .otherwise(() => undefined);
  };

  // todo: なぜか型エラーになるのでとりあえずキャストしている
  // need fix
  const radixTextProps: RadixThemeTextProps = (({
    variant,
    colorOverride,
    fontWeight,
    ...rest
  }) => rest as RadixThemeTextProps)(props);

  return (
    <RadixThemeText
      ref={ref}
      size={getRadixTextSize()}
      weight={fontWeight || getWeight()}
      color={getColor()}
      {...radixTextProps}
    >
      {children}
    </RadixThemeText>
  );
};

const Text = forwardRef(_Text);

export { Text, type TextProps };
