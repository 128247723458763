import { useRef, ChangeEvent } from "react";
import { useUploadFile } from "./useUploadFile";
import { useErrorToast } from "~/components_next/Error";
import { ImagePreview } from "./ImagePreview";
import { RecordValueInputCommonProps } from "../../type";
import { Box, Flex } from "@radix-ui/themes";
import { Button } from "~/components_next/Button";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { ActionIcon } from "~/components_next/Icons";
import { styled } from "@stitches/react";

const StyledImageUploadIconButton = styled(Button, {
  width: "100%",
});

const ImageUploadButton = (props: {
  onClick: () => void;
  size: RecordValueInputCommonProps<"image">["size"];
}) => {
  const { onClick, size } = props;
  return (
    <StyledImageUploadIconButton
      variant="tertiary"
      size={size}
      onClick={onClick}
    >
      <ActionIcon type="add" /> Upload
    </StyledImageUploadIconButton>
  );
};

const ImageInput = (props: RecordValueInputCommonProps<"image">) => {
  const { value, onChange, size, isNullable, errorMessages, isReadOnly } =
    props;

  const { isUploading, uploadFile } = useUploadFile();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const openFiler = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  const { errorToast } = useErrorToast({});
  const uploadImage = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const { files } = event.target;
      if (!files) {
        throw new Error("Something went wrong");
      }
      const newValue = await uploadFile(files[0]);
      onChange?.(newValue);
    } catch (e) {
      errorToast(e);
    }
  };

  return (
    <>
      <Flex align="center">
        {value ? (
          <Box style={{ maxWidth: "600px" }}>
            <ImagePreview
              url={value.url}
              onChangeClick={openFiler}
              onRemove={() => onChange?.(null)}
            />
          </Box>
        ) : (
          <ImageUploadButton size={size} onClick={openFiler} />
        )}
      </Flex>
      <InputStack errorMessage={errorMessages}>
        <input
          ref={fileInputRef}
          style={{ visibility: "hidden", width: 0, height: 0 }}
          type="file"
          onChange={uploadImage}
          accept="image/*"
        />
      </InputStack>
    </>
  );
};

export { ImageInput };
