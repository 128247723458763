import { FieldTypeClientModelFactory } from "~/clientModel/fields/field/fieldType";
import { FieldTypeSubSelect } from "./common/FieldTypeSubSelectBase";

const structuredDataFieldTypes = [
  FieldTypeClientModelFactory.createEmpty("html"),
  FieldTypeClientModelFactory.createEmpty("json"),
  FieldTypeClientModelFactory.createEmpty("array"),
] as const;

type StructuredDataFieldType = (typeof structuredDataFieldTypes)[number];

type StructuredDataFieldsTypeSubSelectProps = {
  onSelect: (fieldType: StructuredDataFieldType) => void;
  options?:
    | {
        includes: StructuredDataFieldType["type"][];
      }
    | {
        excludes: StructuredDataFieldType["type"][];
      }; // 指定がなければ全部
};

export const StructuredDataFieldsTypeSubSelect = (
  props: StructuredDataFieldsTypeSubSelectProps
) => {
  const { onSelect, options } = props;

  return (
    <FieldTypeSubSelect
      triggerContent="Structured Data"
      onSelect={onSelect}
      fieldTypeOptions={structuredDataFieldTypes}
      options={options}
    />
  );
};
