import { SourceContainer } from "~/containers/source/SourceContainer";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTableSlug } from "~/utilHooks/useTableSlug";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

const SourcePage = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const tableSlug = useTableSlug();

  return (
    <>
      {/* <SourceToolbarContainer
        teamSlug={teamSlug}
        databaseId={databaseId}
        tableSlug={tableSlug}
      /> */}
      <SourceContainer
        key={tableSlug}
        teamSlug={teamSlug}
        databaseId={databaseId}
        tableSlug={tableSlug}
      />
    </>
  );
};

export { SourcePage };
