import { atom, useRecoilValue, useSetRecoilState } from "recoil";

type GlobalNavigationUIState = {
  isLockSidebar: boolean;
  isDatabaseSelectOpen: boolean;
};

const globalNavigationUIState = atom<GlobalNavigationUIState>({
  key: "global-navigation-state",
  default: {
    isLockSidebar: true,
    isDatabaseSelectOpen: false,
  },
});

const useGlobalNavigationUIState = () => {
  return useRecoilValue(globalNavigationUIState);
};

const useSetGlobalNavigationUIState = () => {
  return useSetRecoilState(globalNavigationUIState);
};

export { useGlobalNavigationUIState, useSetGlobalNavigationUIState };
