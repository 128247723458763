import useApiTeam from "~/api/useApiTeam";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { teamsKeys } from "../keys";

type UseDeleteTeamUserMutationParams = {
  teamSlug: string;
};

const useDeleteTeamUserMutation = ({
  teamSlug,
}: UseDeleteTeamUserMutationParams) => {
  const { _deleteTeamUser } = useApiTeam();
  const client = useQueryClient();

  return {
    mutationFn: ({ userId }: { userId: string }) => {
      return _deleteTeamUser({
        teamSlug,
        userId,
      });
    },
    onSuccess: () => {
      return invalidateAll(client, teamsKeys.getTeam({ teamSlug }));
    },
  };
};

export { useDeleteTeamUserMutation };
