import { useState } from "react";
import {
  TestFormulaForViewReturn,
  useTestFormulaForView as useTestFormulaForViewAction,
} from "~/serverActions";

type UseTestFormulaForViewParams = {
  teamSlug: string;
  viewId: string;
};

const useTestFormulaForView = ({
  teamSlug,
  viewId,
}: UseTestFormulaForViewParams) => {
  const [isTestRunning, setIsTestRunning] = useState<boolean>(false);
  const [latestTestResult, setLatestTestResult] =
    useState<TestFormulaForViewReturn | null>(null);

  const { testFormulaForView } = useTestFormulaForViewAction();

  const testFormula = async (formula: string) => {
    setIsTestRunning(true);
    try {
      const result = await testFormulaForView({
        teamSlug,
        viewId,
        testingFormula: formula,
      });
      setLatestTestResult(result);
    } finally {
      setIsTestRunning(false);
    }
  };

  return {
    testFormula,
    isTestRunning,
    latestTestResult,
  };
};

export { useTestFormulaForView };
