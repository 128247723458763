import { Tabs as RadixThemeTabs } from "@radix-ui/themes";
import { ReactNode } from "react";

type TabsContentProps = {
  children: ReactNode;
  value: string;
};

const TabsContent = (props: TabsContentProps) => {
  const { children, value } = props;

  return (
    <RadixThemeTabs.Content value={value}>{children}</RadixThemeTabs.Content>
  );
};

export { TabsContent, type TabsContentProps };
