import { DashboardRssFeedItemsResponse } from "@usemorph/morph-dashboard-types";
import { RssFeedItemsClientModel } from "./RssFeedItemsClientModel";

export const RssFeedItemsClientModelFactory = {
  fromRssFeedItemResponse: (
    data: DashboardRssFeedItemsResponse
  ): RssFeedItemsClientModel => {
    return new RssFeedItemsClientModel(data);
  },
}
