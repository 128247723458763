import { ErrorResponse as ErrorData } from "@usemorph/morph-dashboard-types";
import { z } from "zod";

const schemaForType =
  <T>() =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <S extends z.ZodType<T, any, any>>(arg: S) => {
      return arg;
    };

const errorDataSchema = schemaForType<ErrorData>()(
  z.object({
    error: z.string(),
    subCode: z.number(),
    message: z.string().optional(),
    detail: z.string().optional(),
  })
);

interface ErrorResponse {
  data: ErrorData;
}

const errorResponseSchema = z.object({
  data: errorDataSchema,
});

const isErrorResponse = (error: unknown): error is ErrorResponse => {
  try {
    errorResponseSchema.parse(error);
    return true;
  } catch (e) {
    return false;
  }
};

export { isErrorResponse };
