type WidgetDataDependencies = {
  teamSlug: string;
  databaseId: string;
};

type GetWidgetDataApisDependencies = {
  tableSlug?: string;
  widgetId?: string;
  limit?: number;
  skip?: number;
} & WidgetDataDependencies;

type FindWidgetDataApiDependencies = {
  tableSlug: string;
  apiKey: string;
} & WidgetDataDependencies;

const widgetDataKeys = {
  all: ({ teamSlug, databaseId }: WidgetDataDependencies) =>
    ["widgetData", teamSlug, databaseId] as const,

  /**
   * get widget data apis
   */
  allGetWidgetDataApis: ({ teamSlug, databaseId }: WidgetDataDependencies) =>
    [
      ...widgetDataKeys.all({ teamSlug, databaseId }),
      "getWidgetDataApis",
    ] as const,
  getWidgetDataApis: ({
    teamSlug,
    databaseId,
    tableSlug,
    widgetId,
    limit,
    skip,
  }: GetWidgetDataApisDependencies) =>
    [
      ...widgetDataKeys.allGetWidgetDataApis({
        teamSlug,
        databaseId,
      }),
      {
        widgetId,
        tableSlug,
        limit,
        skip,
      },
    ] as const,

  /**
   * find widget data api
   */
  allFindWidgetDataApi: ({ teamSlug, databaseId }: WidgetDataDependencies) =>
    [
      ...widgetDataKeys.all({ teamSlug, databaseId }),
      "findWidgetDataApi",
    ] as const,
  findWidgetDataApi: ({
    teamSlug,
    databaseId,
    tableSlug,
    apiKey,
  }: FindWidgetDataApiDependencies) =>
    [
      ...widgetDataKeys.allFindWidgetDataApi({
        teamSlug,
        databaseId,
      }),
      { tableSlug, apiKey },
    ] as const,
};

export { widgetDataKeys };
