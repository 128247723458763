import {
  DashboardNotebookCellVariableRequestObject,
  QueryRecordListResponse,
} from "@usemorph/morph-dashboard-types";
import { useCallback } from "react";
import { useQuery } from "react-query";
import { useComposeLoadable } from "~/clientModel/loadable";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";

import {
  PaginationClientModel,
  PaginationClientModelDefactory,
} from "~/clientModel/queryConditions/pagination";

import {
  RecordsClientModel,
  RecordsClientModelFactory,
} from "~/clientModel/records";
import { CanvasVariablesValue } from "~/presenters/canvas/common/CanvasVariablesProvider";
import { TablePaginationLimit } from "~/presenters/sourceAndViews/common/components/bottomItems/TablePagination";

import { useQueryViewRecordsQuery } from "~/serverStateStore/views";

export const createUseViewRecordsLoadable = (props: {
  teamSlug: string;
  databaseId: string;
}): UseLoadable<
  {
    viewId: string;
    variables?: CanvasVariablesValue[];
    pagination: PaginationClientModel<TablePaginationLimit>;
  },
  RecordsClientModel
> => {
  const { teamSlug, databaseId } = props;

  return ({
    viewId,
    variables,
    pagination,
  }: {
    viewId: string;
    variables?: CanvasVariablesValue[];
    pagination: PaginationClientModel<TablePaginationLimit>;
  }) => {
    return useComposeLoadable(
      useQuery({
        ...useQueryViewRecordsQuery({
          teamSlug,
          databaseId,
          viewId,
          select: ["*"],
          limit: PaginationClientModelDefactory.toLimit(pagination),
          skip: PaginationClientModelDefactory.toSkip(pagination),
          variables: variables as
            | DashboardNotebookCellVariableRequestObject
            | undefined,
        }),
        select: useCallback(
          (response: Required<QueryRecordListResponse>) =>
            RecordsClientModelFactory.createFromQueryRecordListResponseWithFields(
              response
            ),
          []
        ),
      })
    );
  };
};
