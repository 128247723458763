import { RecordClientModel } from "~/clientModel/records/record";
import { EditingCellStateClientModel } from "./editingCell/EditingCellStateClientModel";
import { CellSelectionStateClientModel } from "./cellSelection/CellSelectionStateClientModel";
import { RecordSelectionStateClientModel } from "./recordSelection";
import { RecordEntryClientModel } from "~/clientModel/records/record/recordEntry";
import { RecordsClientModel } from "~/clientModel/records";
import { RecordIdentifierClientModel } from "~/clientModel/records/record/recordIdentifier";
import { FieldsClientModel } from "~/clientModel/fields";
import { EditingRecordClientModel } from "~/clientModel/records/editingRecord";

type TableSelectionClientModelData = {
  editingCellState: EditingCellStateClientModel;
  cellSelectionState: CellSelectionStateClientModel;
  recordSelectionState: RecordSelectionStateClientModel;
};

export class TableSelectionClientModel {
  constructor(readonly data: TableSelectionClientModelData) {}

  public clearAllTableSelection(): TableSelectionClientModel {
    return new TableSelectionClientModel({
      editingCellState: this.data.editingCellState.endEditing(),
      cellSelectionState: this.data.cellSelectionState.clearSelection(),
      recordSelectionState: this.data.recordSelectionState.unselectAll(),
    });
  }

  /**
   * Cell editing
   */

  public get isCellEditing(): boolean {
    return this.data.editingCellState.taggedEditingRecord.isEditing;
  }

  public get taggedEditingRecord():
    | {
        isEditing: true;
        editingRecord: EditingRecordClientModel;
        editingRecordEntry: RecordEntryClientModel;
      }
    | {
        isEditing: false;
      } {
    if (
      this.data.cellSelectionState.taggedCellSelectionState.type !== "single" ||
      !this.data.editingCellState.taggedEditingRecord.isEditing
    ) {
      return {
        isEditing: false,
      };
    }

    const editingRecordEntryFieldName =
      this.data.cellSelectionState.taggedCellSelectionState.fieldName;

    const editingRecordEntry =
      this.data.editingCellState.taggedEditingRecord.editingRecord.selectEditingRecordEntryOrThrow(
        editingRecordEntryFieldName
      );

    return {
      isEditing: true,
      editingRecord:
        this.data.editingCellState.taggedEditingRecord.editingRecord,
      editingRecordEntry,
    };
  }

  public startCellEditing({
    editingRecord,
    fieldName,
  }: {
    editingRecord: EditingRecordClientModel;
    fieldName: string;
  }): TableSelectionClientModel {
    return new TableSelectionClientModel({
      editingCellState: this.data.editingCellState.startEditing({
        editingRecord,
      }),
      cellSelectionState: this.data.cellSelectionState.selectSingleCell({
        recordIdentifier: editingRecord.recordIdentifierBeforeEdit,
        fieldName,
      }),
      recordSelectionState: this.data.recordSelectionState,
    });
  }

  public endCellEditing(): TableSelectionClientModel {
    return new TableSelectionClientModel({
      editingCellState: this.data.editingCellState.endEditing(),
      cellSelectionState: this.data.cellSelectionState,
      recordSelectionState: this.data.recordSelectionState,
    });
  }

  public updateEditingRecordEntry({
    recordEntry,
  }: {
    recordEntry: RecordEntryClientModel;
  }): TableSelectionClientModel {
    const taggedEditingRecord = this.data.editingCellState.taggedEditingRecord;

    if (!taggedEditingRecord.isEditing) {
      throw new Error("Not in editing mode");
    }

    const updatedRecord =
      taggedEditingRecord.editingRecord.updateRecordEntry(recordEntry);

    return new TableSelectionClientModel({
      editingCellState:
        this.data.editingCellState.updateEditingRecord(updatedRecord),
      cellSelectionState: this.data.cellSelectionState,
      recordSelectionState: this.data.recordSelectionState,
    });
  }

  /**
   * Cell selection
   */

  public get taggedCellSelectionState() {
    return this.data.cellSelectionState.taggedCellSelectionState;
  }

  public get hasCellSelection(): boolean {
    return this.data.cellSelectionState.hasSelection();
  }

  public isCellSelected({
    recordIdentifier,
    fieldName,
  }: {
    recordIdentifier: RecordIdentifierClientModel;
    fieldName: string;
  }): boolean {
    return this.data.cellSelectionState.isCellSelected({
      recordIdentifier,
      fieldName,
    });
  }

  public selectSingleCell({
    recordIdentifier,
    fieldName,
  }: {
    recordIdentifier: RecordIdentifierClientModel;
    fieldName: string;
  }): TableSelectionClientModel {
    return new TableSelectionClientModel({
      editingCellState: this.data.editingCellState,
      cellSelectionState: this.data.cellSelectionState.selectSingleCell({
        recordIdentifier,
        fieldName,
      }),
      recordSelectionState: this.data.recordSelectionState,
    });
  }

  public addVerticalCellToSelection({
    recordIdentifier,
  }: {
    recordIdentifier: RecordIdentifierClientModel;
  }): TableSelectionClientModel {
    return new TableSelectionClientModel({
      editingCellState: this.data.editingCellState,
      cellSelectionState:
        this.data.cellSelectionState.addVerticalCellToSelection({
          recordIdentifier,
        }),
      recordSelectionState: this.data.recordSelectionState,
    });
  }

  public addHorizontalCellToSelection({
    fieldName,
  }: {
    fieldName: string;
  }): TableSelectionClientModel {
    return new TableSelectionClientModel({
      editingCellState: this.data.editingCellState,
      cellSelectionState:
        this.data.cellSelectionState.addHorizontalCellToSelection({
          fieldName,
        }),
      recordSelectionState: this.data.recordSelectionState,
    });
  }

  public clearAllCellSelection(): TableSelectionClientModel {
    return new TableSelectionClientModel({
      editingCellState: this.data.editingCellState,
      cellSelectionState: this.data.cellSelectionState.clearSelection(),
      recordSelectionState: this.data.recordSelectionState,
    });
  }

  public getCellSelectionClipboardValue({
    records,
  }: {
    records: RecordsClientModel;
  }): string {
    return this.data.cellSelectionState.getClipboardValue({
      records,
    });
  }

  /**
   * Record selection
   */

  public get selectedRecordsIdentifiers(): RecordIdentifierClientModel[] {
    return this.data.recordSelectionState.selectedRecordsIdentifiers;
  }

  public get isSomeRecordSelected(): boolean {
    return this.data.recordSelectionState.isSomeRecordSelected();
  }

  public isAllRecordsSelected({
    records,
  }: {
    records: RecordsClientModel;
  }): boolean {
    return this.data.recordSelectionState.isAllSelected({
      recordIdentifiers: records.allRecords.map(
        (record) => record.recordIdentifier
      ),
    });
  }

  public isRecordSelected({
    recordIdentifier,
  }: {
    recordIdentifier: RecordIdentifierClientModel;
  }): boolean {
    return this.data.recordSelectionState.isRecordSelected({
      recordIdentifier,
    });
  }

  public selectRecord({
    record,
  }: {
    record: RecordClientModel;
  }): TableSelectionClientModel {
    return new TableSelectionClientModel({
      editingCellState: this.data.editingCellState,
      cellSelectionState: this.data.cellSelectionState,
      recordSelectionState: this.data.recordSelectionState.selectRecord({
        recordIdentifier: record.recordIdentifier,
      }),
    });
  }

  public selectRecords({
    records,
  }: {
    records: RecordClientModel[];
  }): TableSelectionClientModel {
    return new TableSelectionClientModel({
      editingCellState: this.data.editingCellState,
      cellSelectionState: this.data.cellSelectionState,
      recordSelectionState: this.data.recordSelectionState.selectRecords({
        recordIdentifiers: records.map((record) => record.recordIdentifier),
      }),
    });
  }

  public unselectRecord({
    record,
  }: {
    record: RecordClientModel;
  }): TableSelectionClientModel {
    return new TableSelectionClientModel({
      editingCellState: this.data.editingCellState,
      cellSelectionState: this.data.cellSelectionState,
      recordSelectionState: this.data.recordSelectionState.unselectRecord({
        recordIdentifier: record.recordIdentifier,
      }),
    });
  }

  public unselectAllRecords(): TableSelectionClientModel {
    return new TableSelectionClientModel({
      editingCellState: this.data.editingCellState,
      cellSelectionState: this.data.cellSelectionState,
      recordSelectionState: this.data.recordSelectionState.unselectAll(),
    });
  }

  public getRecordSelectionClipboardValue({
    records,
    fields,
  }: {
    records: RecordsClientModel;
    fields: FieldsClientModel;
  }): string {
    return this.data.recordSelectionState.getClipboardValue({
      records,
      fields,
    });
  }
}
