import {
  zRecordFilterConditionUnit,
  RecordFilterConditionUnit,
} from "@usemorph/morph-dashboard-types";
import { zodGuard } from "~/utils/zodGuard";

type FlatFilterConditionAnd = {
  and: RecordFilterConditionUnit[];
};

type FlatFilterConditionOr = {
  or: RecordFilterConditionUnit[];
};

type FlatFilterCondition = FlatFilterConditionAnd | FlatFilterConditionOr;

const isFlatFilterConditionAnd = (
  value: unknown
): value is FlatFilterConditionAnd => {
  return (
    typeof value === "object" &&
    value !== null &&
    "and" in value &&
    Array.isArray(value.and) &&
    value.and.every((el) => zodGuard(el, zRecordFilterConditionUnit))
  );
};

const isFlatFilterConditionOr = (
  value: unknown
): value is FlatFilterConditionOr => {
  return (
    typeof value === "object" &&
    value !== null &&
    "or" in value &&
    Array.isArray(value.or) &&
    value.or.every((el) => zodGuard(el, zRecordFilterConditionUnit))
  );
};

const isFlatFilterCondition = (
  value: unknown
): value is FlatFilterCondition => {
  return (
    isFlatFilterConditionAnd(value) ||
    isFlatFilterConditionOr(value) ||
    value === null
  );
};

export {
  isFlatFilterConditionOr,
  isFlatFilterConditionAnd,
  isFlatFilterCondition,
  type FlatFilterCondition,
  type FlatFilterConditionAnd,
  type FlatFilterConditionOr,
};
