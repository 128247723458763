import { FieldClientModel } from "~/clientModel/fields/field";
import {
  ValidationResult,
  zValidate,
} from "~/clientModel/utils/validationResultType";
import {
  FilterOperationClientModelBase,
  FilterOperationWithValueInterface,
} from "./FilterOperationClientModelBase";
import { zStringArrayValidation } from "./zValidations";

type NotInFilterOperationClientModelData = {
  value: string[];
};

export class NotInFilterOperationClientModel
  extends FilterOperationClientModelBase
  implements
    FilterOperationWithValueInterface<
      string[],
      NotInFilterOperationClientModel
    >
{
  readonly #data: NotInFilterOperationClientModelData;

  public constructor(data: NotInFilterOperationClientModelData) {
    super();
    this.#data = data;
  }

  public get data() {
    return this.#data;
  }

  public get type(): "notIn" {
    return "notIn";
  }

  public get operatorValue(): "notIn" {
    return "notIn";
  }

  public get operatorLabel(): "Not in" {
    return "Not in";
  }

  public get operandValue(): string[] {
    return this.#data.value;
  }

  public updateValue(value: string[]): NotInFilterOperationClientModel {
    return new NotInFilterOperationClientModel({
      ...this.#data,
      value,
    });
  }

  public validate(targetField: FieldClientModel): ValidationResult {
    return zValidate(zStringArrayValidation(targetField), this.#data.value);
  }
}
