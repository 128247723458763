import { useEffect } from "react";
import { Edge, Node, useEdgesState, useNodesState } from "reactflow";
import { match, P } from "ts-pattern";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import {
  CanvasCellImageClientModel,
  CanvasCellPromptClientModel,
  CanvasCellRichTextClientModel,
  CanvasCellSourceClientModel,
  CanvasCellStickyNoteClientModel,
  CanvasCellVariablesClientModel,
  CanvasCellViewClientModel,
} from "~/clientModel/canvas/CanvasCellClientModel";
import {
  PlaygroundCellPrompt,
  PlaygroundCellPromptProps,
} from "./cell/prompt/PlaygroundCellPrompt";
import {
  PlaygroundCellRichText,
  PlaygroundCellRichTextProps,
} from "./cell/richText/PlaygroundCellRichText";
import {
  PlaygroundCellSource,
  PlaygroundCellSourceProps,
} from "./cell/source/PlaygroundCellSource";
import {
  PlaygroundCellStickyNote,
  PlaygroundCellStickyNoteProps,
} from "./cell/stickyNote/PlaygroundCellStickyNote";
import {
  PlaygroundCellVariables,
  PlaygroundCellVariablesProps,
} from "./cell/variables/PlaygroundCellVariables";
import {
  PlaygroundCellView,
  PlaygroundCellViewProps,
} from "./cell/view/PlaygroundCellView";

type PlaygroundNodeType = Node<
  | PlaygroundCellSourceProps
  | PlaygroundCellPromptProps
  | PlaygroundCellVariablesProps
  | PlaygroundCellRichTextProps
  | PlaygroundCellStickyNoteProps
  | PlaygroundCellViewProps
>;

const nodeTypes = {
  source: PlaygroundCellSource,
  prompt: PlaygroundCellPrompt,
  variables: PlaygroundCellVariables,
  richText: PlaygroundCellRichText,
  stickyNote: PlaygroundCellStickyNote,
  view: PlaygroundCellView,
};

type UseReactFlowDataProps = {
  cells: CanvasCellClientModelUnion[];
};

const useReactFlowNodes = (props: UseReactFlowDataProps) => {
  const { cells } = props;

  const [nodes, setNodes, onNodesChange] = useNodesState<
    | PlaygroundCellSourceProps
    | PlaygroundCellPromptProps
    | PlaygroundCellVariablesProps
    | PlaygroundCellRichTextProps
    | PlaygroundCellStickyNoteProps
    | PlaygroundCellViewProps
  >([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const initNodes = () => {
    const _nodes = [] as PlaygroundNodeType[];
    const _edges = [] as Edge[];

    cells.forEach((cell) => {
      // edge

      if (cell.parentIds) {
        cell.parentIds.forEach((parentId) => {
          _edges.push({
            id: `${parentId}-${cell.cellId}`,
            source: parentId,
            target: cell.cellId,
          });
        });
      }

      const existingNode = nodes.find((node) => node.id === cell.cellId);
      const commonSettings = {
        id: cell.cellId,
        selected: existingNode?.selected,
        dragHandle: ".notebook-cell-drag-handle",
        width: cell.settings.width,
        height: cell.settings.height,
        position: { x: cell.settings.x, y: cell.settings.y },
        deletable: false,
        connectable: false,
      };

      // TODO: 一致判定する
      if (existingNode) {
        const existingCellClientMode = existingNode.data.cellClientModel;
        if (!existingCellClientMode.isValuesChangedFromData(cell.rawData)) {
          _nodes.push(existingNode);
          return;
        }
      }

      try {
        match(cell)
          .with(
            P.instanceOf(CanvasCellSourceClientModel),
            (sourceClientModel) => {
              _nodes.push({
                ...commonSettings,
                type: "source",
                data: {
                  cellClientModel: sourceClientModel,
                },
              } as Node<PlaygroundCellSourceProps>);
            }
          )
          .with(P.instanceOf(CanvasCellViewClientModel), (viewClientModel) => {
            _nodes.push({
              ...commonSettings,
              type: "view",
              data: {
                cellClientModel: viewClientModel,
              },
            } as Node<PlaygroundCellViewProps>);
          })
          .with(
            P.instanceOf(CanvasCellPromptClientModel),
            (promptClientModel) => {
              _nodes.push({
                ...commonSettings,
                type: "prompt",
                data: {
                  cellClientModel: promptClientModel,
                },
              } as Node<PlaygroundCellPromptProps>);
            }
          )
          .with(
            P.instanceOf(CanvasCellVariablesClientModel),
            (variablesClientModel) => {
              _nodes.push({
                ...commonSettings,
                type: "variables",
                data: {
                  cellClientModel: variablesClientModel,
                },
              } as Node<PlaygroundCellVariablesProps>);
            }
          )
          .with(
            P.instanceOf(CanvasCellRichTextClientModel),
            (richTextClientModel) => {
              _nodes.push({
                ...commonSettings,
                type: "richText",
                data: {
                  cellClientModel: richTextClientModel,
                },
              } as Node<PlaygroundCellRichTextProps>);
            }
          )
          .with(
            P.instanceOf(CanvasCellStickyNoteClientModel),
            (stickyNoteClientModel) => {
              _nodes.push({
                ...commonSettings,
                type: "stickyNote",
                data: {
                  cellClientModel: stickyNoteClientModel,
                },
              } as Node<PlaygroundCellStickyNoteProps>);
            }
          )
          .with(
            P.instanceOf(CanvasCellImageClientModel),
            (imageClientModel) => {
              // 未実装
              // _nodes.push({
              //   ...commonSettings,
              //   type: "image",
              //   data: {
              //     cellClientModel: imageClientModel,
              //   },
              // } as Node<PlaygroundCellIma>);
            }
          )
          .exhaustive();
      } catch {
        console.error("Unknown cell type", cell);
      }
    });

    setNodes(_nodes);
    setEdges(_edges);
  };

  useEffect(() => {
    initNodes();
  }, [cells]);

  return {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
  };
};

export { useReactFlowNodes, nodeTypes };
