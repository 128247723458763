import { Box } from "@radix-ui/themes";
import { FilterConditionsClientModel } from "~/clientModel/queryConditions/filterConditions";
import { SimpleSelect } from "~/components_next/Select";

type LogicalOperatorInputInputProps = {
  filterConditions: FilterConditionsClientModel;
  onFilterConditionsChange: (
    filterConditions: FilterConditionsClientModel
  ) => void;
  isReadOnly: boolean;
};

const options = [
  {
    value: "and",
    label: "And",
  },
  {
    value: "or",
    label: "Or",
  },
] as const;

export const LogicalOperatorInput = (props: LogicalOperatorInputInputProps) => {
  const { filterConditions, onFilterConditionsChange, isReadOnly } = props;

  return (
    <Box style={{ alignSelf: "start" }}>
      <SimpleSelect
        variant="supplementary"
        value={filterConditions.logicalOperator}
        onChange={(value) =>
          onFilterConditionsChange(
            filterConditions.updateLogicalOperator(value!)
          )
        }
        size="xs"
        isDisabled={isReadOnly}
        options={options}
      />
    </Box>
  );
};
