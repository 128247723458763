import { RssField, RssFieldClientModel } from "./RssFieldClientModel";



export class RssFieldClientModelDefactory {
  public static toRssField(rssField: RssFieldClientModel): RssField {
    return {
      title: !rssField.title ? undefined : rssField.title,
      description: !rssField.description ? undefined : rssField.description,
      image: !rssField.image ? undefined : rssField.image,
      link: !rssField.link ? undefined : rssField.link,
      pubDate: !rssField.pubDate ? undefined : rssField.pubDate,
    }
  }
}
