import { useEditorOrThrow } from "../utils";

const useItalic = () => {
  const editor = useEditorOrThrow();

  const handleToggle = () => {
    editor.chain().focus().toggleItalic().run();
  };

  const isActive = editor.isActive("italic");

  const isDisabled = !editor.can().chain().focus().toggleItalic().run();

  return {
    handleToggle,
    isActive,
    isDisabled,
  };
};

export { useItalic };
