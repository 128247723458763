import { BsLayoutWtf } from "react-icons/bs";
import { useNodes } from "reactflow";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import { CanvasCellClientModelFactory } from "~/clientModel/canvas/CanvasCellClientModel";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { useNotebookPositionUtil } from "~/features/FlipNotebook/util/useNotebookPositionUtil";

type PlaygourndTidyLayoutProps = {
  cells: CanvasCellClientModelUnion[];
  onUpdateLiveCell: (cell: CanvasCellClientModelUnion) => void;
};

const PlaygroundTidyLayout = (props: PlaygourndTidyLayoutProps) => {
  const { cells, onUpdateLiveCell } = props;

  /**
   * React Flow Selection
   */
  const allNodes = useNodes();
  const selectedNodes = allNodes.filter((node) => node.selected);
  const selectedNodeIds = selectedNodes.map((node) => node.id);
  const selectedCells = cells.filter((cell) => {
    return selectedNodeIds.includes(cell.cellId);
  });

  const { recalcPositons } = useNotebookPositionUtil();
  const handleTidyClick = () => {
    // TODO: 本当はClientModelに寄せてやりたいが、いったん。
    const updatedCells = recalcPositons(
      selectedCells.map((cell) => cell.rawData)
    );
    updatedCells.forEach((cell) => {
      const cellInstance =
        CanvasCellClientModelFactory.fromLiveblocksObject(cell);
      if (!cellInstance) {
        console.error("cellInstance is null");
        return;
      }
      onUpdateLiveCell(cellInstance);
    });
  };

  if (selectedNodes.length < 2) {
    return null;
  }

  return (
    <Box
      css={{
        boxShadow: "var(--shadow-4)",
      }}
    >
      <Button variant="primary" size="sm" onClick={handleTidyClick}>
        <BsLayoutWtf />
        Tidy Layout
      </Button>
    </Box>
  );
};

export { PlaygroundTidyLayout };
