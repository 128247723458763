import { ClientError } from "./ClientError";
import { isErrorResponse } from "./isErrorResponse";

const convertSnakeCaseToText = (str: string) => {
  return str.replace("_", " ");
};

const capitalize = (str: string) => {
  return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}`;
};

const isErrorWithMessage = (error: unknown): error is { message: string } => {
  return (
    error instanceof Object &&
    "message" in error &&
    typeof error.message === "string"
  );
};

const extractErrorDetails = (
  error: unknown
): { title: string; description: string } => {
  const title = isErrorResponse(error)
    ? capitalize(convertSnakeCaseToText(error.data.error))
    : error instanceof ClientError
      ? error.message
      : "Unexpected Error";

  const description =
    isErrorResponse(error) && typeof error.data.message === "string"
      ? error.data.message
      : error instanceof ClientError
        ? error.description
        : isErrorWithMessage(error)
          ? error.message
          : "Something went wrong.";

  return {
    title,
    description,
  };
};

export { extractErrorDetails };
