import { Dispatch, SetStateAction } from "react";
import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil";
import {
  dynamicStorageEffect,
  dynamicStorageItem,
} from "~/clientStateStore/typedLocalStorage";
import { TypedStorage } from "~/clientStateStore/typedLocalStorage/storage";

type NotebookTabState = {
  tab: "canvas" | "dashboard";
};

const storage = new TypedStorage(localStorage);

const key = "notebook-tab-state";
const version = 1;

const deserializer = (value: string): NotebookTabState => {
  return JSON.parse(value);
};

const serializer = (value: NotebookTabState | null) => {
  return JSON.stringify(value);
};

const notebookTabStateStorageItem = dynamicStorageItem<
  NotebookTabState | null,
  { notebookId: string }
>({
  key,
  version,
  serializer,
  deserializer,
});

const notebookLayoutFamily = atomFamily<
  NotebookTabState | null,
  { notebookId: string }
>({
  key: "notebook-tab-state",
  default: {
    tab: "canvas",
  },
  effects: ({ notebookId }) => [
    dynamicStorageEffect(
      storage,
      notebookTabStateStorageItem,
      { notebookId },
      {}
    ),
  ],
});

const useNotebookTabState = (notebookId: string): NotebookTabState | null => {
  const notebookLayout = useRecoilValue(notebookLayoutFamily({ notebookId }));
  return notebookLayout;
};

const useSetNotebookTabState = (
  notebookId: string
): Dispatch<SetStateAction<NotebookTabState | null>> => {
  const setNotebookLayout = useSetRecoilState(
    notebookLayoutFamily({ notebookId })
  );
  return setNotebookLayout;
};

export { useNotebookTabState, useSetNotebookTabState };
