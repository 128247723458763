import {
  ReactNode,
  useMemo,
  createContext,
  useContext,
  ComponentPropsWithoutRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useCoworkerIndicatorUtil } from "./useCoworkerIndicatorUtil";
import { useGlobalNavigationUIState } from "../../../states/globalNavigationUIState";
import { Flex } from "~/components_next/Flex";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { OtherAvatars } from "./OtherAvatars";
import { Box } from "~/components_next/Box";
import { useRecalculateCoworkerIconsPositionKey } from "./LinkItemContext";

type FlexProps = ComponentPropsWithoutRef<typeof Flex>;

type LinkItemWrapperProps = {
  to: string;
  children: ReactNode;
} & FlexProps;

const LinkItemContext = createContext<{ to: string; isActive: boolean }>({
  to: "",
  isActive: false,
});

const useLinkItemContext = () => {
  const contextValue = useContext(LinkItemContext);

  return {
    to: contextValue.to,
    isActive: contextValue.isActive,
  };
};

const LinkItemWrapper = (props: LinkItemWrapperProps) => {
  const { to, children, ...rest } = props;
  const { pathname } = useLocation();

  const isActive = useMemo(() => {
    return to === pathname;
  }, [to, pathname]);

  // const bgActiveColor = useColorModeValue("blue.50", "blue.900");
  // const bgHoverColor = useColorModeValue("gray.100", "gray.700");
  const bgDefaultColor = undefined;
  const bgActiveHoverColor = bgDefaultColor;

  /**
   * User Presence
   */

  const { shouldShowCoworkerIndicator, othersOnThisTarget } =
    useCoworkerIndicatorUtil(to);

  const globalNavigationUIState = useGlobalNavigationUIState();

  const otherUsers = othersOnThisTarget.map((other) => other.presence.user);

  const recalculateCoworkerIconPositionKey =
    useRecalculateCoworkerIconsPositionKey();

  const [isHover, setIsHover] = useState(false);

  // recalculateCoworkerIconPositionKeyが変わったら位置を再計算する。
  // OtherAvatarsがアンマウントされるとアイコンのフォールバックがフラッシュするので、
  // OtherAvatarsを残したままDropdownMenuの位置を再計算させたい。
  // そのために、微小なsideOffsetを与える。
  const sideOffset = useMemo(() => {
    return Math.random() / 100;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recalculateCoworkerIconPositionKey]);

  return (
    <Flex direction="row" align="center">
      <Flex
        align="center"
        {...rest}
        css={{
          width: "100%",
          flexGrow: 1,
          paddingRight: "8px",
          borderRadius: "md",
          color: isActive ? "$bg2" : bgDefaultColor,
          "&:hover": {
            backgroundColor: isActive ? bgActiveHoverColor : "$bg1",
          },
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <LinkItemContext.Provider value={{ to, isActive }}>
          {children}
        </LinkItemContext.Provider>
      </Flex>
      {shouldShowCoworkerIndicator &&
        !globalNavigationUIState.isDatabaseSelectOpen && (
          <DropdownMenu.Root open modal={false}>
            <DropdownMenu.Trigger>
              <Box
                style={{
                  top: 0,
                  right: 0,
                  height: "100%",
                  width: 0,
                }}
              />
            </DropdownMenu.Trigger>
            <DropdownMenu.Content
              side="right"
              sideOffset={sideOffset}
              style={{ zIndex: 1 }}
            >
              <Flex
                align="center"
                style={{
                  transform: "translateX(-12px)",
                }}
              >
                <OtherAvatars
                  others={otherUsers}
                  userAvatarOptionProps={{
                    size: "xs",
                    showTooltipOnHover: true,
                    showCoworkerBorder: true,
                  }}
                  showAll={isHover}
                />
              </Flex>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        )}
    </Flex>
  );
};

export { LinkItemWrapper, useLinkItemContext };
