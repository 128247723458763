import { css } from "@stitches/react";
import { useCallback, useMemo } from "react";
import { BsPlayFill } from "react-icons/bs";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { Button } from "~/components_next/Button";
import { Flex } from "~/components_next/Flex";
import {
  basicFieldFilterFn,
  BasicTypeaheadPrompt,
  FieldSuggestionItem,
} from "~/presenters/prompt";

const promptRootStyle = css({
  overflowY: "auto !important",
  height: "120px !important",
});

const promptTheme = {
  root: `${promptRootStyle()}`,
};

type PromptInputProps = {
  fieldsForTypeahead: FieldsClientModel;
  prompt: string;
  onPromptChange: (prompt: string) => void;
  onGenerateClick: () => void;
  isGenerating: boolean;
};

export const PromptInput = (props: PromptInputProps) => {
  const {
    prompt,
    onPromptChange,
    fieldsForTypeahead,
    onGenerateClick,
    isGenerating,
  } = props;

  const optionItems = useMemo(
    () => fieldsForTypeahead.allFields,
    [fieldsForTypeahead]
  );

  const convertFieldToDotNotatedFieldName = useCallback(
    (field: FieldClientModel) => `\${${field.name}}`,
    []
  );

  const renderHTMLTextContent = useCallback(
    (field: FieldClientModel) => field.label,
    []
  );

  const renderSuggestedItem = useCallback(
    (field: FieldClientModel) => <FieldSuggestionItem field={field} />,
    []
  );

  return (
    <>
      <Flex direction="column" gap="2">
        <BasicTypeaheadPrompt<FieldClientModel>
          textContent={prompt}
          onUpdate={onPromptChange}
          theme={promptTheme}
          optionItems={optionItems}
          filterFn={basicFieldFilterFn}
          convertOptionItemToText={convertFieldToDotNotatedFieldName}
          renderHTMLTextContent={renderHTMLTextContent}
          renderSuggestedItem={renderSuggestedItem}
          focusOnLoad
        />

        <Button
          variant="secondary"
          onClick={onGenerateClick}
          isDisabled={!prompt}
          isLoading={isGenerating}
          size="sm"
        >
          <BsPlayFill />
          Generate Formula
        </Button>
      </Flex>
    </>
  );
};
