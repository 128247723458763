import { DashboardNotificationListResponse } from "@usemorph/morph-dashboard-types";
import dayjs from "dayjs";
import { NotificationClientModel } from "./NotificationClientModel";
import { NotificationClientModelFactory } from "./NotificationClientModelFactory";

export class ListNotificationClientModel {
  constructor(private readonly data: DashboardNotificationListResponse) { }

  /**
   * Getters
   */
  get count(): number {
    return this.data.count
  }

  get items(): NotificationClientModel[] {
    return this.data.items.map(item => NotificationClientModelFactory.fromNotificationResponse(item))
  }

  get isUnread(): boolean {
    return this.items.some(item => item.readAt === null)
  }

  get unreadSums(): number {
    return this.items.filter(item => item.readAt === null).length
  }

  get inboxItems(): NotificationClientModel[] {
    return this.items.filter(item => !item.isArchived).sort((a, b) => {
      const _createdAt_a = dayjs(a.createdAt)
      const _createdAt_b = dayjs(b.createdAt)
      if (dayjs(_createdAt_a).isAfter(_createdAt_b)) return -1
      if (dayjs(_createdAt_a).isBefore(_createdAt_b)) return 1
      return 0
    }).sort((a) => {
      if (a.readAt === null) return -1
      return 0
    }).map(item => NotificationClientModelFactory.fromNotificationResponse(item))
  }

  get archivedItems(): NotificationClientModel[] {
    return this.items.filter(item => item.isArchived).sort((a, b) => {
      const _createdAt_a = dayjs(a.createdAt)
      const _createdAt_b = dayjs(b.createdAt)
      if (dayjs(_createdAt_a).isAfter(_createdAt_b)) return -1
      if (dayjs(_createdAt_a).isBefore(_createdAt_b)) return 1
      return 0
    }).map(item => NotificationClientModelFactory.fromNotificationResponse(item))
  }

  get allItems(): NotificationClientModel[] {
    return this.items.sort((a, b) => {
      const _createdAt_a = dayjs(a.createdAt)
      const _createdAt_b = dayjs(b.createdAt)
      if (dayjs(_createdAt_a).isAfter(_createdAt_b)) return -1
      if (dayjs(_createdAt_a).isBefore(_createdAt_b)) return 1
      return 0
    }).map(item => NotificationClientModelFactory.fromNotificationResponse(item))
  }

}
