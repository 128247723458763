import { useState } from "react";
import { UseExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { Button } from "~/components_next/Button";
import { Callout } from "~/components_next/Callout";
import { Dialog } from "~/components_next/Dialog";
import { Flex } from "~/components_next/Flex";

type CreateFieldSidebarCreateComputedFieldDialogProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => unknown;
  creatingField: FieldClientModel;
  editingFieldsForUpdateMergeKey: FieldsClientModel;
  useCreateComputedFieldExecutable: UseExecutable<
    void,
    {
      createdField: FieldClientModel;
      fields: FieldsClientModel;
    }
  >;
  useStartSyncComputedFieldsExecutable: UseExecutable<void, void>;
  onFieldCreated: () => unknown;
};

export const CreateFieldSidebarCreateComputedFieldDialog = (
  props: CreateFieldSidebarCreateComputedFieldDialogProps
) => {
  const {
    isOpen,
    onOpenChange,
    creatingField,
    editingFieldsForUpdateMergeKey,
    useCreateComputedFieldExecutable,
    useStartSyncComputedFieldsExecutable,
    onFieldCreated,
  } = props;

  const createComputedFieldExecutable = useCreateComputedFieldExecutable();

  const startSyncComputedFieldsExecutable =
    useStartSyncComputedFieldsExecutable();

  const [isCreating, setIsCreating] = useState(false);
  const [isCreatingAndSyncing, setIsCreatingAndSyncing] = useState(false);

  const handleCreateAndSyncButtonClick = async () => {
    setIsCreatingAndSyncing(true);
    try {
      await createComputedFieldExecutable.execute({
        createdField: creatingField,
        fields: editingFieldsForUpdateMergeKey,
      });
      await startSyncComputedFieldsExecutable.execute();
      onOpenChange(false);
      onFieldCreated();
    } finally {
      setIsCreatingAndSyncing(false);
    }
  };

  const handleCreateButtonClick = async () => {
    setIsCreating(true);
    try {
      await createComputedFieldExecutable.execute({
        createdField: creatingField,
        fields: editingFieldsForUpdateMergeKey,
      });
      onOpenChange(false);
      onFieldCreated();
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange} size="xs">
      <Dialog.Content>
        <Dialog.Body>
          <Flex direction="column" gap="4">
            <Callout
              type="info"
              description={`It requires synchronization to Populate data. You can synchronize manually from the table settings or set up a scheduled synchronization. Create a field and initiate synchronization?`}
            />

            <Flex direction="column" gap="3">
              <Button
                variant="primary"
                size="sm"
                onClick={handleCreateAndSyncButtonClick}
                isDisabled={isCreating}
                isLoading={isCreatingAndSyncing}
              >
                Create & Sync
              </Button>
              <Button
                variant="secondary"
                size="sm"
                onClick={handleCreateButtonClick}
                isDisabled={isCreatingAndSyncing}
                isLoading={isCreating}
              >
                Create
              </Button>
            </Flex>
          </Flex>
        </Dialog.Body>
      </Dialog.Content>
    </Dialog.Root>
  );
};
