import { useQuery } from "react-query";
import {
  FieldsClientModel,
  FieldsClientModelFactory,
} from "~/clientModel/fields";
import { Loadable, useComposeLoadable } from "~/clientModel/loadable";

import { useListSimpleFieldsQuery } from "~/serverStateStore";

type useFieldsLoadableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

export const useFieldsLoadable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: useFieldsLoadableParams): Loadable<FieldsClientModel> => {
  return useComposeLoadable(
    useQuery({
      ...useListSimpleFieldsQuery({
        teamSlug,
        databaseId,
        tableSlug,
      }),
      select: (data) => {
        return FieldsClientModelFactory.createFromSimpleFields(data.fields);
      },
    })
  );
};
