import {
  selectorFamily,
  useRecoilValue,
  useSetRecoilState,
  DefaultValue,
} from "recoil";
import {
  CellSelection,
  RecordsTableBaseSelectionState,
} from "~/components_next/RecordsTableBase/types";
import { selectionStateFamily } from "./selectionState";

const cellSelectionFamily = selectorFamily<
  CellSelection[] | undefined,
  { viewId: string }
>({
  key: "table-view-cell-selection",
  get:
    ({ viewId }) =>
    ({ get }) => {
      const selectionState = get(selectionStateFamily({ viewId }));
      return selectionState?.cellSelection;
    },
  set:
    ({ viewId }) =>
    ({ set, reset }, newValue) => {
      if (newValue instanceof DefaultValue) {
        reset(selectionStateFamily({ viewId }));
      } else {
        set(
          selectionStateFamily({ viewId }),
          (prev): RecordsTableBaseSelectionState => {
            return prev
              ? { ...prev, cellSelection: newValue ?? [] }
              : {
                  fieldSelection: [],
                  cellSelection: newValue ?? [],
                  recordSelection: [],
                  editingCell: null,
                };
          }
        );
      }
    },
});

const useCellSelection = (viewId: string) => {
  return useRecoilValue(cellSelectionFamily({ viewId }));
};

const useSetCellSelection = (viewId: string) => {
  return useSetRecoilState(cellSelectionFamily({ viewId }));
};

export { useCellSelection, useSetCellSelection, cellSelectionFamily };
