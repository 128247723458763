import { useMemo } from "react";
import {
  useGetUserColor,
  useOthersDecoded,
  usePresenceBasics,
} from "~/features/RealtimeCollaboration";
import { useMe } from "~/serverStateStore/me";

export const useCoworkerIndicatorUtil = (targetPath: string) => {
  const { path } = usePresenceBasics();
  const { othersDecoded } = useOthersDecoded();
  const { data: meData } = useMe();

  const getUserColor = useGetUserColor();
  const othersOnThisTarget = useMemo(() => {
    const others = othersDecoded.filter((other) => {
      return (
        other.presence.path === targetPath &&
        other.presence.user.userId !== meData?.userId
      );
    });
    // 重複を除く
    return Array.from(
      new Map(
        others.map((other) => [other.presence.user.userId, other])
      ).values()
    );
  }, [meData, othersDecoded, targetPath]);

  const shouldShowCoworkerIndicator = useMemo(() => {
    if (path === targetPath) return false; // 今のページと同じ場合は出さない
    return othersOnThisTarget.length > 0;
  }, [othersOnThisTarget.length, path, targetPath]);

  return {
    shouldShowCoworkerIndicator,
    othersOnThisTarget,
    getUserColor,
  };
};
