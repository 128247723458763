import {
  DashboardTemplateAuthObject,
  DashboardTemplateAuthListResponse,
  DashboardTemplateAuthVerifyResponse
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

type DashboardGeneralResponse = {
  message: string;
};

export default function useApiTemplate() {
  const { executeRequest } = useApi();

  const _createNewTemplateAuth = async ({
    templateId,
    code,
    authInfo,
    teamSlug,
  }: {
    templateId: string;
    code?: string;
    authInfo?: { [key: string]: unknown };
    teamSlug: string;
  }): Promise<DashboardTemplateAuthObject> => {
    return executeRequest(
      "post",
      `/template/${templateId}/auth`,
      undefined,
      {
        teamSlug,
      },
      {
        code,
        authInfo,
      },
    );
  };

  const _getTemplateAuthList = async ({
    templateId,
    limit,
    skip,
    teamSlug,
  }: {
    templateId: string;
    limit?: number;
    skip?: number;
    teamSlug: string;
  }): Promise<DashboardTemplateAuthListResponse> => {
    return executeRequest(
      "get",
      `/template/${templateId}/auth`,
      undefined,
      {
        teamSlug
      },
      {
        limit,
        skip,
      },
    );
  };

  const _updateTemplateAuth = async ({
    templateId,
    templateAuthId,
    code,
  }: {
    templateId: string;
    templateAuthId: string;
    code: string
  }): Promise<DashboardTemplateAuthObject> => {
    return executeRequest(
      "put",
      `/template/${templateId}/auth/${templateAuthId}`,
      undefined,
      undefined,
      {
        code,
      },
    );
  };

  const _deleteTemplateAuth = async ({
    templateId,
    templateAuthId,
    teamSlug,
  }: {
    templateId: string;
    templateAuthId: string;
    teamSlug: string;
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest(
      "delete",
      `/template/${templateId}/auth/${templateAuthId}`,
      undefined,
      {
        teamSlug,
      },
      undefined,
    );
  };

  const _updateTemplateAuthSetting = async ({
    templateId,
    templateAuthId,
    teamSlug,
    title,
    label,
  }: {
    templateId: string;
    templateAuthId: string;
    teamSlug: string;
    title?: string
    label?: string
  }): Promise<DashboardTemplateAuthObject> => {
    return executeRequest(
      "put",
      `/template/${templateId}/auth/${templateAuthId}/setting`,
      undefined,
      {
        teamSlug,
      },
      {
        title,
        label,
      },
    );
  };

  const _verifyTemplateAuth = async ({
    templateId,
    templateAuthId,
    teamSlug,
  }: {
    templateId: string;
    templateAuthId: string;
    teamSlug: string;
  }): Promise<DashboardTemplateAuthVerifyResponse> => {
    return executeRequest(
      "post",
      `/template/${templateId}/auth/${templateAuthId}/verify`,
      undefined,
      {
        teamSlug,
      },
      undefined,
    );
  };

  return {
    _createNewTemplateAuth,
    _getTemplateAuthList,
    _updateTemplateAuth,
    _deleteTemplateAuth,
    _updateTemplateAuthSetting,
    _verifyTemplateAuth,
  };
}
