import { SimpleField } from "@usemorph/morph-dashboard-types";
import { useCallback, useMemo } from "react";
import { match } from "ts-pattern";
import {
  CellSelection,
  CellSelectionState,
  EditingCellState,
  RecordsTableColumnSizingState,
} from "../types";
import {
  splitFieldsIntoSeries,
  splitSelectionsIntoSeries,
} from "../util/cellSelectionUtil";

type Position = {
  x: number;
  y: number;
};

type CellSelectionRect = {
  start: Position;
  width: number;
  height: number;
  selection: CellSelection;
};

// type FieldAndSize = SimpleField & { width: number };
// type CellSelectionAndIndex = CellSelection & { index: number };

const useCellSelectionUtil = (
  columnSizings: RecordsTableColumnSizingState,
  cellSelections: CellSelectionState,
  editingCell: EditingCellState | null,
  fields: SimpleField[],
  isSelectable?: boolean
) => {
  const fieldSizes: Array<SimpleField & { width: number }> = useMemo(() => {
    return fields.map((field) => {
      return {
        ...field,
        width: columnSizings[field.name] ?? 0,
      };
    });
  }, [fields, columnSizings]);

  // internal
  const createRect = useCallback(
    (
      startRowIndex: number,
      endRowIndex: number,
      fieldsInARow: string[],
      selection: CellSelection
    ): CellSelectionRect => {
      const startFieldSlug = fieldsInARow[0];
      const endFieldSlug = fieldsInARow[fieldsInARow.length - 1];

      const start = {
        x: fieldSizes
          .slice(
            0,
            fields.findIndex((field) => field.name === startFieldSlug)
          )
          .reduce((sum, field) => sum + field.width, 0),
        y: startRowIndex * 40 + 40,
      };
      const end = {
        x: fieldSizes
          .slice(
            0,
            fields.findIndex((field) => field.name === endFieldSlug) + 1
          )
          .reduce((sum, field) => sum + field.width, 0),
        y: endRowIndex + 1,
      };

      return {
        start: isSelectable ? { ...start, x: start.x + 80 } : start,
        width: end.x - start.x,
        height: (endRowIndex - startRowIndex + 1) * 40,
        selection,
      };
    },
    [fieldSizes, fields, isSelectable]
  );

  const createRectsWithSingleSelections = useCallback(
    (selection: CellSelection): CellSelectionRect => {
      const startRowIndex = selection.record._reservedRecordIndex;
      const endRowIndex = selection.record._reservedRecordIndex;

      const fieldSlugs = selection.fieldSlugs;

      const rect = createRect(
        startRowIndex,
        endRowIndex,
        fieldSlugs,
        selection
      );

      return rect;
    },
    [createRect]
  );

  const createRectsWithMultipleSelections = useCallback(
    (selections: CellSelectionState): CellSelectionRect[] => {
      // 連続する行の塊ごとに分ける
      // 例: [1, 2, 3, 5, 6, 7, 8, 10, 11, 12] => [[1, 2, 3], [5, 6, 7, 8], [10, 11, 12]]
      const rowsInARow = splitSelectionsIntoSeries(selections);

      const allRects: CellSelectionRect[] = rowsInARow.flatMap((rows) => {
        // 縦のindex
        const startRowIndex = rows[0].record._reservedRecordIndex;
        const endRowIndex = rows[rows.length - 1].record._reservedRecordIndex;

        // 横のindexを計算するために、0番目を代表としてfieldSlugsを取得する
        const fieldSlugs = rows[0].fieldSlugs;
        // fiedSlugのindexを計算する
        const fieldSlugsWithIndex = fieldSlugs
          .map((fieldSlug) => {
            const index = fields.findIndex((field) => field.name === fieldSlug);
            return {
              slug: fieldSlug,
              index,
            };
          })
          .sort((a, b) => a.index - b.index);
        // 連続するものに分ける
        const fieldSlugsInARow = splitFieldsIntoSeries(fieldSlugsWithIndex);

        // Rectをつくる
        const rects = fieldSlugsInARow.map((fieldSlugs) =>
          createRect(startRowIndex, endRowIndex, fieldSlugs, rows[0])
        );

        return rects;
      });

      return allRects;
    },
    [createRect, fields]
  );

  const rects = useMemo(() => {
    if (editingCell) {
      return [];
    }

    return match(cellSelections.length)
      .with(0, () => {
        return [];
      })
      .with(1, () => {
        const rect = createRectsWithSingleSelections(cellSelections[0]);
        return [rect];
      })
      .otherwise(() => {
        const rects = createRectsWithMultipleSelections(cellSelections);
        return rects;
      });
  }, [
    editingCell,
    cellSelections,
    createRectsWithSingleSelections,
    createRectsWithMultipleSelections,
  ]);

  const editingCellPosition = useMemo(() => {
    if (!editingCell) {
      return null;
    }

    const start = {
      x: fieldSizes
        .slice(
          0,
          fields.findIndex((field) => field.name === editingCell.fieldSlug)
        )
        .reduce((sum, field) => sum + field.width, 0),
      y: editingCell.record._reservedRecordIndex * 40 + 40,
    };

    return isSelectable ? { ...start, x: start.x + 80 } : start;
  }, [editingCell, fieldSizes, fields, isSelectable]);

  return {
    rects,
    editingCellPosition,
  };
};

export { useCellSelectionUtil, type CellSelectionRect };
