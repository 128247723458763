import { match } from "ts-pattern";
import { MorphThemeSize } from "~/components_next/commonProps.type";
import { MultiSelectVariant } from "./type";

export const convertSize = (size: MorphThemeSize): "1" | "2" | "3" => {
  return match(size)
    .with("xs", () => "1" as const)
    .with("sm", () => "2" as const)
    .with("md", () => "3" as const)
    .with("lg", () => "3" as const)
    .with("xl", () => "3" as const)
    .exhaustive();
};

export const convertVariant = (
  variant: MultiSelectVariant
): "solid" | "soft" => {
  return "solid";
};

// ref: https://github.com/pacocoursey/cmdk/blob/main/README.md
export const normalizeValue = (value: string): string => {
  return value.toLowerCase().trim();
};
