import {
  Box, Flex, Text,
  RadioGroup as RadixThemeRadioGroup,
  RadioGroupItem as RadixThemeRadioGroupItem,
} from '@radix-ui/themes'
import { ComponentPropsWithoutRef } from "react";

const RadioItem = RadixThemeRadioGroupItem

type Option = {
  label: string;
  value: string;
  isDisabled?: boolean; // default false
};

type RadixThemeRadioGroupProps = ComponentPropsWithoutRef<
  typeof RadixThemeRadioGroup.Root
>;

type SimpleRadioProps = Omit<RadixThemeRadioGroupProps, "value" | "options"> & {
  value: string;
  options: Option[]
}

const SimpleRadio = (props: SimpleRadioProps) => {
  const { value, options } = props

  return (
    <RadixThemeRadioGroup.Root {...props} value={value}>
      <Flex gap="2" direction="column">
        {
          options.map((o, i) => {
            return (
              <Box key={i}>
                <Text as="label" size="2">
                  <Flex gap="2">
                    <RadioItem value={o.value} /> {o.label}
                  </Flex>
                </Text>
              </Box>
            )
          })
        }
      </Flex>
    </RadixThemeRadioGroup.Root>
  )
}

export {
  SimpleRadio, type SimpleRadioProps,
  RadioItem,
}
