import { DataApi } from "~/features/DataApi/DataApi";

const DataApiPage = () => {
  return <DataApi />;
};

const DataApiPageWithKey = () => {
  return <DataApiPage />;
};

export { DataApiPageWithKey as DataApiPage };
