import constate from "constate";
import { PublicTableViewCellComponentProps } from "../PublicTableViewCellComponent";

const useTableViewCellComponentContext = (
  props: PublicTableViewCellComponentProps
) => {
  return {
    ...props,
  };
};

export const [PublicTableViewCellComponentPropsProvider, usePublicTableView] =
  constate(useTableViewCellComponentContext, (value) => value.view);
