import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { Loadable, ValueLoadable } from "~/clientModel/loadable";
import { RecordsClientModel } from "~/clientModel/records";
import {
  RecordsTable,
  RecordsTableProps,
} from "~/components_next/RecordsTable/RecordsTable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { RecordsTableErrorFallback } from "./RecordsTableErrorFallback";
import { RecordsTableLoadingFallback } from "./RecordsTableLoadingFallback";
import { TableColumnSizingClientModel } from "~/clientModel/tables/tableMeta/TableColumnSizing";

export const RecordsTableWithFallback = (
  props: Omit<
    RecordsTableProps,
    "records" | "fields" | "columnSizing" | "onAddFieldClick"
  > & {
    recordsLoadable: Loadable<RecordsClientModel>;
    fieldsLoadable: Loadable<FieldsClientModel>;
    tableColumnSizingLoadable?: Loadable<TableColumnSizingClientModel>;
    forceLoading?: boolean;
    onAddFieldClick?: (fields: FieldsClientModel) => void;
  }
) => {
  const {
    recordsLoadable,
    fieldsLoadable,
    tableColumnSizingLoadable,
    forceLoading,
    onAddFieldClick,
    ...recordsTableProps
  } = props;

  return (
    <WithFallback
      loadables={
        [
          recordsLoadable,
          fieldsLoadable,
          tableColumnSizingLoadable ?? new ValueLoadable(undefined),
        ] as const
      }
      loadingFallback={<RecordsTableLoadingFallback />}
      errorFallback={(error) => <RecordsTableErrorFallback error={error} />}
    >
      {([records, fields, columnSizing]) =>
        !forceLoading ? (
          <RecordsTable
            records={records}
            fields={fields}
            columnSizing={columnSizing}
            onAddFieldClick={
              onAddFieldClick ? () => onAddFieldClick(fields) : undefined
            }
            {...recordsTableProps}
          />
        ) : (
          <RecordsTableLoadingFallback />
        )
      }
    </WithFallback>
  );
};
