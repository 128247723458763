import { Link } from "@radix-ui/themes";
import { styled } from "@stitches/react";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "~/components_next/Box";
// import { Link as ReactLink } from "react-router-dom";
import { Text } from "~/components_next/Text";
import { useLinkItemContext } from "./LinkItemWrapper";

type LinkItemTextProps = {
  onClick?: () => void;
  children: ReactNode;
};

const StyledLink = styled(Link, {
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  fontSize: "sm",
  // paddingRight: "4px",
  paddingTop: "4px",
  paddingBottom: "4px",
  // as={ReactLink}
  // to={to}
  flex: 1,
});

const LinkItemText = (props: LinkItemTextProps) => {
  const { onClick, children } = props;
  const { to, isActive } = useLinkItemContext();

  const navigate = useNavigate();

  return (
    <StyledLink
      onClick={() => {
        if (onClick) {
          onClick();
        }
        navigate(to);
      }}
    >
      <Box
        css={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        <Text
          variant="description"
          fontWeight={isActive ? "medium" : undefined}
        >
          {children}
        </Text>
      </Box>
    </StyledLink>
  );
};

export { LinkItemText };
