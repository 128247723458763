import { createCtx } from "~/utils/createCtx";

type UploadFileContextProps = {
  onUploadFile: (file: File) => Promise<{ data: string; url: string }>;
};

type UploadFileContextValue = {
  onUploadFile: UploadFileContextProps["onUploadFile"];
};

const { Provider: UploadFileProvider, useCtx: useUploadFileCtx } =
  createCtx<UploadFileContextValue>();

export { UploadFileProvider, useUploadFileCtx };
