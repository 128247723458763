import { SimpleField } from "@usemorph/morph-dashboard-types";
import { useState } from "react";
import { BsTrash } from "react-icons/bs";
import { useMutation, useQuery } from "react-query";
import { Button } from "~/components_next/Button";
import { Drawer } from "~/components_next/Drawer";
import { useErrorToast } from "~/components_next/Error";
import { IconButton } from "~/components_next/IconButton";
import { RecordsTableBase } from "~/components_next/RecordsTableBase";
import {
  RecordsTableBaseRecord,
  RecordsTableBaseSelectionState,
} from "~/components_next/RecordsTableBase/types";
import { useDisclosure } from "~/hooks/useDisclosure";
import {
  useListSoftDeletedRecordsQuery,
  useDeleteRecordsFromTrashMutation,
  useRestoreRecordsFromTrashMutation,
  useEmptyTrashMutation,
  useListClientSimpleFieldsQuery,
} from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTableSlug } from "~/utilHooks/useTableSlug";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { convertRecordToRecordModel } from "../RecordModel";
import { Box, Flex } from "@radix-ui/themes";
import { Spacer } from "~/components_next/Spacer";
import { DangerDialog } from "~/components_next/Dialog";

const trashFields: SimpleField[] = [
  {
    name: "recordTrashId",
    displayName: "Record Trash ID",
    type: "shortText",
    primary: true,
  },
  {
    name: "deletedAt",
    displayName: "Deleted At",
    type: "datetime",
  },
  {
    name: "deletedBy",
    displayName: "Deleted By",
    type: "lastEditedBy",
  },
];

const RecordsTrashDrawer = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const tableSlug = useTableSlug();

  const { errorToast } = useErrorToast({});

  /**
   * Record selection
   */
  const [recordSelection, setRecordSelection] = useState<
    RecordsTableBaseRecord[]
  >([]);

  const selectionState: RecordsTableBaseSelectionState = {
    recordSelection,
    fieldSelection: [],
    cellSelection: [],
    editingCell: null,
  };

  const handleSelectionChange = (
    selectionState: RecordsTableBaseSelectionState | undefined
  ) => {
    if (!selectionState) {
      setRecordSelection([]);
    } else {
      setRecordSelection(selectionState.recordSelection);
    }
  };

  const { data: simpleFields } = useQuery(
    useListClientSimpleFieldsQuery({
      teamSlug,
      databaseId,
      tableSlug,
    })
  );

  const { data: records } = useQuery({
    ...useListSoftDeletedRecordsQuery({
      teamSlug,
      databaseId,
      tableSlug,
      withFields: true,
      select: ["*"],
      limit: 100,
      skip: 0,
    }),
    select: (data) => {
      return {
        recordsTableBaseRecord: data.items.map((record, index) => {
          return {
            values: convertRecordToRecordModel(
              {
                ...record.data,
                recordTrashId: record.recordTrashId,
                deletedAt: record.deletedAt,
                deletedBy: record.deletedBy,
              },
              [...(simpleFields?.fields || []), ...trashFields]
            ),
            _reservedRecordIndex: index,
          };
        }),
        count: data.count,
      };
    },
  });

  /**
   * Delete selected records
   */
  const { mutateAsync: deleteRecords, isLoading: isDeletingRecords } =
    useMutation({
      ...useDeleteRecordsFromTrashMutation({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    });

  const handleDeleteSelectedRecords = async () => {
    const targetIds: string[] = recordSelection
      .map((record) => record.values["recordTrashId"]?.value as string)
      .filter((r) => r !== undefined);
    try {
      await deleteRecords({
        recordTrashIds: targetIds,
      });
      setRecordSelection([]);
    } catch (e) {
      errorToast(e);
    }
    DeleteConirm.onClose();
  };

  /**
   * Resotre selected records
   */
  const { mutateAsync: restoreRecords, isLoading: isRestoringRecords } =
    useMutation({
      ...useRestoreRecordsFromTrashMutation({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    });

  const handleRestoreSelectedRecords = async () => {
    const targetIds: string[] = recordSelection
      .map((record) => record.values["recordTrashId"]?.value as string)
      .filter((r) => r !== undefined);
    try {
      await restoreRecords({
        recordTrashIds: targetIds,
      });
      setRecordSelection([]);
    } catch (e) {
      errorToast(e);
    }
  };

  /**
   * Empty trash
   */
  const { mutateAsync: emptyTrash, isLoading: isProcessingEmptyTrash } =
    useMutation({
      ...useEmptyTrashMutation({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    });

  const handleEmptyTrash = async () => {
    try {
      await emptyTrash();
      MainDrawer.onClose();
    } catch (e) {
      errorToast(e);
    }
    EmptyConirm.onClose();
  };

  /**
   * UI
   */
  const MainDrawer = useDisclosure();
  const DeleteConirm = useDisclosure();
  const EmptyConirm = useDisclosure();

  if (!records || !simpleFields) return null;

  const displayedFields = [...simpleFields.fields, ...trashFields].filter(
    ({ name }) => name !== "recordTrashId"
  );

  return (
    <>
      <IconButton
        icon={<BsTrash />}
        tooltip="Trash"
        onClick={MainDrawer.onOpen}
      />
      <Drawer.Root
        size="xl"
        open={MainDrawer.isOpen}
        onOpenChange={MainDrawer.onToggle}
      >
        <Drawer.Content>
          <Drawer.Title>Trash</Drawer.Title>
          <Drawer.Body>
            <Flex
              direction="column"
              style={{ height: "100%", width: "100%", overflow: "hidden" }}
              gap="2"
            >
              <Flex mb="2" gap="2" justify="end">
                {recordSelection.length > 0 && (
                  <>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={handleRestoreSelectedRecords}
                      isLoading={isRestoringRecords}
                    >
                      Restore
                    </Button>
                    <Spacer />
                    <Button
                      variant="secondary"
                      color="red"
                      size="sm"
                      onClick={DeleteConirm.onOpen}
                      isLoading={isDeletingRecords}
                    >
                      Delete Selected Records
                    </Button>
                  </>
                )}

                {records.recordsTableBaseRecord.length > 0 && (
                  <Button
                    variant="secondary"
                    color="red"
                    size="sm"
                    onClick={EmptyConirm.onOpen}
                    isLoading={isProcessingEmptyTrash}
                  >
                    Empty Trash
                  </Button>
                )}
              </Flex>
              <Box style={{ overflow: "auto" }}>
                <RecordsTableBase
                  isSelectable
                  selectionState={selectionState}
                  onSelectionChange={handleSelectionChange}
                  editableFields="none"
                  records={records.recordsTableBaseRecord}
                  fields={displayedFields}
                />
              </Box>
            </Flex>
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>

      <DangerDialog
        description="Are you sure to delete selected records?"
        isOpen={DeleteConirm.isOpen}
        onOpenChange={DeleteConirm.setIsOpen}
        onConfirm={() => {
          handleDeleteSelectedRecords();
        }}
      ></DangerDialog>

      <DangerDialog
        description="Are you sure to delete all the records in trash?"
        isOpen={EmptyConirm.isOpen}
        onOpenChange={EmptyConirm.setIsOpen}
        onConfirm={() => {
          handleEmptyTrash();
        }}
      ></DangerDialog>
    </>
  );
};

export { RecordsTrashDrawer };
