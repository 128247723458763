import { FieldClientModel } from "~/clientModel/fields/field";
import {
  UpdatableRecordEntryClientModelBaseConstructorParams,
  UpdatableRecordEntryClientModelBase,
} from "../RecordEntryClientModelBase";
import { zValidate, zAllowNullIfNullable } from "../validatorsUtil";
import { z } from "zod";

type BooleanRecordEntryClientModelData =
  UpdatableRecordEntryClientModelBaseConstructorParams;

export class BooleanRecordEntryClientModel extends UpdatableRecordEntryClientModelBase<
  boolean | null
> {
  constructor(data: BooleanRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "boolean" {
    return "boolean";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([z.boolean(), zAllowNullIfNullable(field)]),
      this.data.rawValue
    );
  }

  public getValidatedDisplayValue = this.getValidatedValue;

  public updateValue(value: boolean | null): BooleanRecordEntryClientModel {
    return new BooleanRecordEntryClientModel({
      key: this.data.key,
      rawValue: value,
      meta: this.data.meta,
    });
  }
}
