import { SimpleSelect } from "~/components_next/Select";
import { Flex } from "@radix-ui/themes";
import { FieldsClientModel } from "~/clientModel/fields";
import {
  EmbeddingSortConditionUnitClientModel,
  EmbeddingSortConditionUnitClientModelFactory,
} from "~/clientModel/queryConditions/embeddingSortConditionUnit";
import { FieldClientModel } from "~/clientModel/fields/field";
import { IconButton } from "~/components_next/IconButton";
import { BsGear } from "react-icons/bs";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { EmbeddingFieldsSetting } from "./embeddingFieldSetting/EmbeddingFieldsSetting";
import { UseExecutable } from "~/clientModel/executable";
import { TextArea } from "~/components_next/TextArea";
import { useDebouncedCallback } from "use-debounce";

type EmbeddingSortConditionInputProps = {
  fields: FieldsClientModel;
  embeddingSortCondition: EmbeddingSortConditionUnitClientModel | null;
  isReadOnly?: boolean;
  onEmbeddingSortConditionChange: (
    condition: EmbeddingSortConditionUnitClientModel | null
  ) => void;
  useActivateEmbeddingExecutable: UseExecutable<
    void,
    { targetField: FieldClientModel },
    unknown,
    unknown
  >;
  debounceDelay?: number;
};

const EmbeddingSortConditionInput = (
  props: EmbeddingSortConditionInputProps
) => {
  const {
    fields,
    embeddingSortCondition,
    onEmbeddingSortConditionChange,
    useActivateEmbeddingExecutable,
    isReadOnly,
    debounceDelay,
  } = props;

  const targetOptions = fields.embeddedFields.allFields.map((field) => ({
    value: field,
    label: field.label,
  }));

  const handleTargetFieldChange = (field: FieldClientModel) => {
    if (!embeddingSortCondition) {
      onEmbeddingSortConditionChange(
        EmbeddingSortConditionUnitClientModelFactory.createEmptyEmbeddingSortConditions(
          field
        )
      );
    } else {
      onEmbeddingSortConditionChange(
        embeddingSortCondition.updateTargetField(field)
      );
    }
  };

  const handleInputValueChange = (value: string) => {
    if (!embeddingSortCondition) return;
    onEmbeddingSortConditionChange(
      embeddingSortCondition.updateInputValue(value)
    );
  };

  const debouncedHandleInputValueChange = useDebouncedCallback(
    handleInputValueChange,
    debounceDelay ?? 0
  );

  return (
    <Flex direction="column" align="stretch" gap="2">
      <Flex direction="row" align="center" gap="1">
        <SimpleSelect
          variant="primary"
          value={embeddingSortCondition?.targetField ?? null}
          getValueStringFromValue={(field) => field.name}
          onChange={(field) => handleTargetFieldChange(field!)}
          size="xs"
          isDisabled={isReadOnly}
          options={targetOptions}
          isReadOnly={isReadOnly}
        />

        <SimpleDropdownMenu
          trigger={<IconButton tooltip="Setting" icon={<BsGear />} />}
          size="sm"
        >
          <EmbeddingFieldsSetting
            fields={fields}
            useActivateEmbeddingExecutable={useActivateEmbeddingExecutable}
          />
        </SimpleDropdownMenu>
      </Flex>
      {embeddingSortCondition && (
        <TextArea
          variant="primary"
          // value={embeddingSortCondition.inputValue}
          defaultValue={embeddingSortCondition.inputValue}
          onChange={({ target: { value } }) =>
            debouncedHandleInputValueChange(value)
          }
          width="100%"
          disabled={isReadOnly}
        />
      )}
    </Flex>
  );
};

export { EmbeddingSortConditionInput };
