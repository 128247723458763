import { ReactNode } from "react";
import { FieldTypeClientModel } from "~/clientModel/fields/field/fieldType";
import { MorphThemeSize } from "~/components_next/commonProps.type";
import { DropdownMenu } from "~/components_next/DropdownMenu";
import { Flex } from "~/components_next/Flex";
import { FieldIcon } from "~/components_next/Icons/components/FieldIcon";
import { InputStack } from "~/components_next/InputStack/InputStack";

type FieldTypeSelectRootProps = {
  fieldType: FieldTypeClientModel;
  children: ReactNode;
  isReadOnly?: boolean;
  size?: MorphThemeSize;
};

export const FieldTypeSelectRoot = (props: FieldTypeSelectRootProps) => {
  const { children, fieldType, size, isReadOnly } = props;
  return (
    <InputStack label="Field Type">
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <DropdownMenu.SelectLikeButton size={size} isDisabled={isReadOnly}>
            <Flex gap="2" align="center">
              <FieldIcon type={fieldType} />
              {fieldType.fieldTypeLabel}
            </Flex>
          </DropdownMenu.SelectLikeButton>
        </DropdownMenu.Trigger>
        {!isReadOnly && (
          <DropdownMenu.Content>{!isReadOnly && children}</DropdownMenu.Content>
        )}
      </DropdownMenu.Root>
    </InputStack>
  );
};
