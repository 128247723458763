import { Flex } from "~/components_next/Flex";
import { Input } from "~/components_next/Input";

type NotebookMetaInputProps = {
  notebookName: string;
  description: string | null;
  onChange?: (value: {
    notebookName: string;
    description: string | null;
  }) => void;
};

const NotebookMetaInput = (props: NotebookMetaInputProps) => {
  const { notebookName, description, onChange } = props;

  return (
    <Flex direction="column" align="start" gap="2" style={{ width: "320px" }}>
      <Input
        variant="primary"
        label="Name"
        value={notebookName}
        onChange={(event) => {
          const value = event.target.value as string;
          onChange?.({
            notebookName: value,
            description,
          });
        }}
      />
      <Input
        variant="primary"
        label="Description"
        value={description || ""}
        onChange={(event) => {
          const value = event.target.value as string;
          onChange?.({
            notebookName,
            description: value,
          });
        }}
      />
    </Flex>
  );
};

export { NotebookMetaInput };
