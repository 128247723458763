import { Tabs as RadixThemeTabs } from "@radix-ui/themes";
import {
  useNotebookTabState,
  useSetNotebookTabState,
} from "~/features/FlipNotebook/state/notebookTabState";
import { useNotebookId } from "~/utilHooks/useNotebookId";

const NotebookToolbarModeTabs = () => {
  const notebookId = useNotebookId();

  // Tab Mode
  const tabState = useNotebookTabState(notebookId);
  const setTabState = useSetNotebookTabState(notebookId);

  return (
    <>
      <RadixThemeTabs.Root
        value={tabState?.tab || "canvas"}
        onValueChange={(tabValue) =>
          setTabState({ tab: tabValue as "canvas" | "dashboard" })
        }
      >
        <RadixThemeTabs.List>
          <RadixThemeTabs.Trigger value="canvas">
            Playground
          </RadixThemeTabs.Trigger>
          <RadixThemeTabs.Trigger value="dashboard">
            Dashboard
          </RadixThemeTabs.Trigger>
        </RadixThemeTabs.List>
      </RadixThemeTabs.Root>
    </>
  );
};

export { NotebookToolbarModeTabs };
