import { Dispatch, SetStateAction } from "react";
import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil";

type NotebookCellSelectionState = string[] | null | undefined;

const notebookCellSelectionFamily = atomFamily<
  NotebookCellSelectionState,
  { notebookId: string }
>({
  key: "notebook-cell-selection",
  default: null,
  effects: [],
});

const useNotebookCellSelection = (
  notebookId: string
): string[] | null | undefined => {
  const notebookCellSelection = useRecoilValue(
    notebookCellSelectionFamily({ notebookId })
  );
  return notebookCellSelection;
};

const useSetNotebookCellSelection = (
  notebookId: string
): Dispatch<SetStateAction<NotebookCellSelectionState | null>> => {
  const setNotebookCellSelection = useSetRecoilState(
    notebookCellSelectionFamily({ notebookId })
  );
  return setNotebookCellSelection;
};

export { useNotebookCellSelection, useSetNotebookCellSelection };
