import { UseExecutable } from "~/clientModel/executable";
import { FieldClientModel } from "~/clientModel/fields/field";
import { RecordEntryClientModel } from "~/clientModel/records/record/recordEntry";
import { Flex } from "~/components_next/Flex";
import { RecordValueInput } from "~/components_next/RecordValueInput";
import { FieldInfo } from "./FieldInfo";

type RecordFormProps = {
  recordEntry: RecordEntryClientModel;
  field: FieldClientModel;
  onRecordEntryChange: (recordEntry: RecordEntryClientModel) => void;
  useUploadFileExecutable: UseExecutable<
    void,
    { file: File },
    {
      data: string;
      url: string;
    },
    unknown
  >;
  isReadonly?: boolean;
};

export const RecordValueInputWithFieldInfo = (props: RecordFormProps) => {
  const {
    recordEntry,
    field,
    onRecordEntryChange,
    useUploadFileExecutable,
    isReadonly = false,
  } = props;

  return (
    <Flex direction="column" justify="start" gap="1" key={field.name}>
      <FieldInfo field={field} />
      <RecordValueInput
        field={field}
        editingRecordEntry={recordEntry}
        onChange={(entry) => onRecordEntryChange(entry)}
        useUploadFileExecutable={useUploadFileExecutable}
        isReadonly={isReadonly}
        size="sm"
      />
    </Flex>
  );
};
