import { useMutation } from "react-query";
import { RightSidebar } from "~/components_next/RightSidebar";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import {
  DashboardViewResponse,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { useErrorToast } from "~/components_next/Error";
import {
  useFieldSelection,
  useSetFieldSelection,
} from "../../states/fieldSelection";
import { useState } from "react";
import { useSidebarDisclosure } from "../../states/sidebar";
import { useCloseEditFieldSidebar } from "./useCloseEditFieldSidebar";
import { FormulaTestRunResultDrawer } from "~/features/Fields/Form/FormulaTestRunResult";
import { DangerDialog } from "~/components_next/Dialog";
import { useDisclosure } from "~/hooks/useDisclosure";
import { useViewId } from "~/utilHooks/useViewId";
import { useViewFields } from "~/serverStateStore/views";
import { useView } from "~/features/SourceAndViews/common/utils/useView";
import { Button } from "~/components_next/Button";
import { Flex } from "~/components_next/Flex";
import { useUpdateViewFieldMutation } from "~/serverStateStore/views/mutations/useUpdateViewFieldMutation";
import { useDeleteViewFieldMutation } from "~/serverStateStore/views/mutations/useDeleteViewFieldMutation";
import { useTestFormulaForView } from "../../hooks/useTestRun";
import { EditFieldForm } from "~/features/Fields/Form/EditFieldForm";

type EditFieldSidebarPresenterProps = {
  onClose: () => void;
  selectedField: SimpleField;
  viewFields: SimpleField[];
  view: DashboardViewResponse;
};

const EditFieldSidebarPresenter = (props: EditFieldSidebarPresenterProps) => {
  const { onClose, selectedField, viewFields, view } = props;
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  /**
   * Client states
   */

  const [editingField, setEditingField] = useState<SimpleField>(selectedField);

  /**
   * handlers
   */

  const { errorToast } = useErrorToast({});

  const { mutateAsync: updateField, isLoading: isSaving } = useMutation(
    useUpdateViewFieldMutation({ teamSlug, databaseId, viewId })
  );
  const handleSaveField = async () => {
    if (!editingField.formula) {
      return errorToast("Formula is required.");
    }
    try {
      await updateField({
        ...editingField,
        formula: editingField.formula,
      });
      onClose();
    } catch (e) {
      errorToast(e);
    }
  };

  const setFieldSelection = useSetFieldSelection(viewId);

  const { mutateAsync: deleteField, isLoading: isDeleting } = useMutation(
    useDeleteViewFieldMutation({ teamSlug, databaseId, viewId })
  );
  const handleDeleteField = async () => {
    try {
      await deleteField({
        fieldName: selectedField.name,
      });
      setFieldSelection([]);
      onClose();
    } catch (e) {
      errorToast(e);
    }
  };

  const testRunDrawer = useDisclosure();

  const handleTestFormula = (formula: string) => {
    testFormula(formula);
    testRunDrawer.onOpen();
  };

  const { testFormula, isTestRunning, latestTestResult } =
    useTestFormulaForView({
      teamSlug,
      viewId,
    });

  const deleteConfirmModal = useDisclosure();

  // - SQLで作られたviewの場合
  //    field.name
  // - SQLで作られたviewではない場合
  //    fieldがoriginal table由来であればドット繋ぎ
  //    fieldがjoin先由来であればfield nameのみ
  const convertFieldToText = (field: SimpleField) =>
    field.originalTableSlug && view?.condition.from !== field.originalTableSlug
      ? `${field.originalTableSlug}.${field.name}`
      : field.name;

  return (
    <>
      <RightSidebar
        width={400}
        minWidth={300}
        title="Edit Field"
        footer={
          <Flex direction="row" align="center" justify="between" gap="6">
            <Button
              variant="secondary"
              colorSchemeOverride="red"
              isLoading={isDeleting}
              onClick={deleteConfirmModal.onOpen}
              size="sm"
            >
              Delete
            </Button>

            <Button
              isLoading={isSaving}
              variant="primary"
              onClick={handleSaveField}
              size="sm"
            >
              Save
            </Button>
          </Flex>
        }
        onClose={onClose}
        height="calc(100vh - 40px)"
      >
        <EditFieldForm
          field={editingField}
          fieldBeforeEdit={selectedField}
          onChange={setEditingField}
          simpleFields={viewFields}
          onTestRunStart={handleTestFormula}
          isTestRunning={isTestRunning}
          convertFieldToText={convertFieldToText}
        />
      </RightSidebar>
      {latestTestResult && (
        <FormulaTestRunResultDrawer
          isOpen={testRunDrawer.isOpen}
          onOpenChange={testRunDrawer.onToggle}
          testRunResultRecord={latestTestResult.recordsTableBaseRecords}
          testRunResultFields={latestTestResult.fields}
        />
      )}
      <DangerDialog
        isOpen={deleteConfirmModal.isOpen}
        onOpenChange={deleteConfirmModal.setIsOpen}
        description="Are you sure you want to delete the field? You cannot undo this action."
        onConfirm={handleDeleteField}
        isConfirmLoading={isDeleting}
      />
    </>
  );
};

const EditFieldSidebar = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  const { isOpen, onClose } = useSidebarDisclosure("editField");

  const fieldSelection = useFieldSelection(viewId);
  const { data: viewData } = useView();
  const { data: simpleFieldsData } = useViewFields({
    teamSlug,
    databaseId,
    viewId,
    viewData,
  });

  useCloseEditFieldSidebar();

  if (!isOpen) {
    return null;
  }

  if (!fieldSelection || fieldSelection.length !== 1) {
    return null;
  }

  if (!simpleFieldsData || !viewData) {
    return null;
  }

  const selectedField = simpleFieldsData.fields.find(
    (field) => field.name === fieldSelection[0]
  );

  if (!selectedField) {
    throw new Error("Target field not found.");
  }

  return (
    <EditFieldSidebarPresenter
      onClose={onClose}
      selectedField={selectedField}
      viewFields={simpleFieldsData.fields}
      view={viewData}
      key={selectedField.name}
    />
  );
};

export { EditFieldSidebar };
