import {
  DashboardNotebookCellVariableRequestObject,
  RecordConditionRuleUnit,
  RecordFilterCondition,
  RecordFormatCondition,
  RecordSortConditionUnit,
} from "@usemorph/morph-dashboard-types";
import { viewKeys } from "../views";

type ViewDependencies = { teamSlug: string; databaseId: string };

type FindViewDependencies = { viewId: string } & ViewDependencies;

type ViewRecordDependencies = FindViewDependencies;

const publicViewKeys = {
  findView: ({ teamSlug, databaseId, viewId }: FindViewDependencies) =>
    [...viewKeys.findView({ teamSlug, databaseId, viewId }), "public"] as const,
  getViewFields: ({ teamSlug, databaseId, viewId }: FindViewDependencies) =>
    [
      ...viewKeys.getViewFields({ teamSlug, databaseId, viewId }),
      "public",
    ] as const,
  allQueryViewRecords: ({
    teamSlug,
    databaseId,
    viewId,
  }: ViewRecordDependencies) =>
    [
      ...viewKeys.allQueryViewRecords({ teamSlug, databaseId, viewId }),
      "public",
    ] as const,
  queryViewRecords: ({
    teamSlug,
    databaseId,
    viewId,
    select,
    join,
    filter,
    format,
    sort,
    limit,
    skip,
    variables,
  }: {
    teamSlug: string;
    databaseId: string;
    viewId: string;
    select: string[];
    join?: RecordConditionRuleUnit[];
    filter?: RecordFilterCondition;
    format?: RecordFormatCondition[];
    sort?: RecordSortConditionUnit[];
    limit?: number;
    skip?: number;
    variables?: DashboardNotebookCellVariableRequestObject;
  }) =>
    [
      ...viewKeys.queryViewRecords({
        teamSlug,
        databaseId,
        viewId,
        select,
        join,
        filter,
        format,
        sort,
        limit,
        skip,
        variables,
      }),
      "public",
    ] as const,
  queryViewRecordsWithSql: ({
    teamSlug,
    databaseId,
    viewId,
    sql,
    skip,
    limit,
    variables,
  }: {
    teamSlug: string;
    databaseId: string;
    viewId: string;
    sql: string;
    limit?: number;
    skip?: number;
    variables?: DashboardNotebookCellVariableRequestObject;
  }) =>
    [
      ...viewKeys.queryViewRecordsSql({
        teamSlug,
        databaseId,
        viewId,
        sql,
        variables,
        skip,
        limit,
      }),
      "public",
    ] as const,
};

export { publicViewKeys };
