import { Box, Tooltip as RadixThemeTooltip } from "@radix-ui/themes";
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from "react";

type RadixThemeTooltipProps = ComponentPropsWithoutRef<
  typeof RadixThemeTooltip
>;

type TooltipProps = Pick<RadixThemeTooltipProps, "content" | "children"> & {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

const _Tooltip = (props: TooltipProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { content, children, isOpen, onOpenChange } = props;
  return (
    <RadixThemeTooltip
      ref={ref}
      content={content}
      open={isOpen}
      onOpenChange={onOpenChange}
    >
      <Box>{children}</Box>
    </RadixThemeTooltip>
  );
};

const Tooltip = forwardRef(_Tooltip);

export { Tooltip as ControlledTooltip };
