import * as RadixCollapsiblePrimitive from "@radix-ui/react-collapsible";
import { Card } from "@radix-ui/themes";
import {
  ComponentPropsWithoutRef,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from "react";
import { styled } from "~/stitches";
import { MorphThemeSize } from "../commonProps.type";

type SimpleCollapsibleProps = {
  variant?: "primary" | "secondary" | "tertiary" | "skelton";
  size?: MorphThemeSize;
  trigger?: ReactNode;
  children?: ReactNode;
};

type RadixThemeCardProps = ComponentPropsWithoutRef<typeof Card>;

const UnstyledButton = styled("button", {
  all: "unset",
  display: "inline-b",
});

// background-color: transparent;
// border: none;
// cursor: pointer;
// outline: none;
// padding: 0;
// appearance: none;

const SimpleCollapsibleRoot = styled(RadixCollapsiblePrimitive.Root, {
  cursor: "pointer",
  variants: {
    variant: {
      primary: {
        border: "1px solid $border",
        "&:hover": {
          borderColor: "$border2",
        },
      },
      secondary: {
        "&:hover": {
          backgroundColor: "$bg1",
        },
      },
      tertiary: {
        backgroundColor: "$bg1",
        "&:hover": {
          backgroundColor: "$bg2",
        },
      },
      skelton: {},
    },
    size: {
      xs: {
        borderRadius: "2px",
      },
      sm: {
        borderRadius: "4px",
      },
      md: {
        borderRadius: "6px",
      },
      lg: {},
      xl: {},
    },
  },
});

const simpleCollapsibleContentSizings = {
  xs: {
    padding: "$1",
  },
  sm: {
    padding: "$2",
  },
  md: {
    padding: "$4",
  },
  lg: {},
  xl: {},
};

const SimpleCollapsibleTrigger = styled(RadixCollapsiblePrimitive.Trigger, {
  "&:hover": {
    textDecoration: "underline",
  },
  variants: {
    size: {
      ...simpleCollapsibleContentSizings,
    },
  },
});

const SimpleCollapsibleContent = styled(RadixCollapsiblePrimitive.Content, {
  variants: {
    size: {
      ...simpleCollapsibleContentSizings,
    },
  },
});

const _SimpleCollabpsible = (
  props: SimpleCollapsibleProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const { variant = "primary", size = "md", trigger, children } = props;

  return (
    <SimpleCollapsibleRoot
      variant={variant}
      size={size}
      className="simple-collapsible-root"
      ref={ref}
    >
      <SimpleCollapsibleTrigger size={size} asChild>
        <UnstyledButton>{trigger}</UnstyledButton>
      </SimpleCollapsibleTrigger>

      <SimpleCollapsibleContent size={size}>
        {children}
      </SimpleCollapsibleContent>
    </SimpleCollapsibleRoot>
  );
};

const SimpleCollapsible = forwardRef(_SimpleCollabpsible);

export { SimpleCollapsible, type SimpleCollapsibleProps };
