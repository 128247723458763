import { FieldTypeClientModel } from "~/clientModel/fields/field/fieldType";
import { MorphThemeSize } from "~/components_next/commonProps.type";
import { SimpleSelect } from "~/components_next/Select";

type FlatFieldTypeInputProps<Option extends FieldTypeClientModel> = {
  value: Option | null;
  options: readonly Option[];
  onChange: (value: Option) => void;
  label?: string;
  isReadOnly?: boolean;
  size?: MorphThemeSize;
};

export const FlatFieldTypeInput = <Option extends FieldTypeClientModel>(
  props: FlatFieldTypeInputProps<Option>
) => {
  const { value, options, onChange, label, isReadOnly, size } = props;

  const fieldTypeOptions = options.map((fieldType) => ({
    value: fieldType,
    label: fieldType.fieldTypeLabel,
  }));

  const getValueStringFromValue = (fieldType: FieldTypeClientModel) =>
    fieldType.type;

  // ジェネリクスでOptionを渡してもうまくいかなかったので、FieldTypeClientModelを渡してonChangeでキャストしている
  return (
    <SimpleSelect<FieldTypeClientModel>
      variant="primary"
      label={label}
      value={value}
      getValueStringFromValue={getValueStringFromValue}
      options={fieldTypeOptions}
      onChange={(fieldType) => onChange(fieldType as Option)}
      isReadOnly={isReadOnly}
      size={size}
    />
  );
};
