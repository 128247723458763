import { UseQueryResult } from "react-query";
import { match } from "ts-pattern";
import {
  ErrorLoadable,
  IdleLadable,
  Loadable,
  LoadingLoadable,
  ValueLoadable,
} from "./Loadable";

export const composeLoadable = <DataType, ErrorType>(
  useQueryResult: UseQueryResult<DataType, ErrorType>
): Loadable<DataType, ErrorType> => {
  return match(useQueryResult)
    .with(
      { status: "idle" },
      (idleQueryResult) =>
        new IdleLadable<DataType, ErrorType>(idleQueryResult.refetch)
    )
    .with({ status: "loading" }, () => {
      return new LoadingLoadable<DataType, ErrorType>();
    })
    .with({ status: "success" }, (successQueryResult) => {
      return new ValueLoadable<DataType, ErrorType>(successQueryResult.data);
    })
    .with({ status: "error" }, (errorQueryResult) => {
      return new ErrorLoadable<DataType, ErrorType>(errorQueryResult.error);
    })
    .exhaustive();
};
