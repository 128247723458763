import useApiRecord from "~/api/useApiRecord";
import { recordsKeys } from "../../records/keys";

// types
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import {
  RecordFilterCondition,
  RecordSortConditionUnit,
} from "@usemorph/morph-dashboard-types";

interface UseListSoftDeletedRecordsQueryParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
  withFields: boolean;
  select: string[];
  sort?: RecordSortConditionUnit[];
  filter?: RecordFilterCondition;
  limit: number;
  skip: number;
}

const useListSoftDeletedRecordsQuery = ({
  databaseId,
  tableSlug,
  teamSlug,
  withFields,
  select,
  sort,
  filter,
  limit,
  skip,
}: UseListSoftDeletedRecordsQueryParams) => {
  const { _getSoftDeletedRecord } = useApiRecord();

  const client = useQueryClient();
  client.setQueryDefaults(
    recordsKeys.all({ databaseId, tableSlug, teamSlug }),
    commonQueryOptions
  );

  return {
    queryKey: recordsKeys.getSoftDeletedRecord({
      databaseId,
      tableSlug,
      teamSlug,
      withFields,
      select,
      sort,
      filter,
      limit,
      skip,
    }),
    queryFn: () => {
      return _getSoftDeletedRecord({
        databaseId,
        teamSlug,
        tableSlug,
        withFields,
        requestBody: {
          select,
          filter,
          sort,
          limit,
          skip,
        },
      });
    },
  };
};

export { useListSoftDeletedRecordsQuery };
