import {
  ValidationResult,
  zValidate,
} from "~/clientModel/utils/validationResultType";
import { SmartFunctionFieldTypeClientModel } from "../../../../SmartFunctionFieldTypeClientModel";
import {
  SmartFunctionInputClientModelBase,
  SmartFunctionInputClientModelBaseData,
} from "./SmartFunctionInputClientModelBase";
import { z } from "zod";

type SmartFunctionPromptStringInputClientModelData =
  SmartFunctionInputClientModelBaseData & {
    annotatedFieldNameRequired: boolean;
    annotateFieldName: boolean;
  };

export class SmartFunctionPromptStringInputClientModel extends SmartFunctionInputClientModelBase<string> {
  public readonly type = "promptString";

  public constructor(
    override readonly data: SmartFunctionPromptStringInputClientModelData
  ) {
    super(data);
  }

  public getValue(
    smartFunctionFieldType: SmartFunctionFieldTypeClientModel
  ): string {
    const rawValue = smartFunctionFieldType.settingData[this.key];
    return typeof rawValue === "string" ? rawValue : "";
  }

  public validateValue(
    smartFunctionFieldType: SmartFunctionFieldTypeClientModel
  ): ValidationResult<string> {
    const rawValue = smartFunctionFieldType.settingData[this.key];

    const annotatedFieldRequiredRegex = this.data.annotatedFieldNameRequired
      ? /\$\{.+\}/
      : /.*/;

    return zValidate(
      z
        .string()
        .nonempty()
        .regex(
          annotatedFieldRequiredRegex,
          "The prompt must contain at least one or more field names. Type @ and then enter field names."
        ),
      rawValue
    );
  }
}
