import { SmartFieldNumberDisplaySetting } from "@usemorph/morph-dashboard-types";
import { NumberDisplaySettingClientModel } from "./NumberDisplaySettingClientModel";

export class NumberDisplaySettingClientModelFactory {
  public static createFromSmartFieldNumberDisplaySetting(
    smartFieldNumberDisplaySetting: SmartFieldNumberDisplaySetting
  ): NumberDisplaySettingClientModel {
    return new NumberDisplaySettingClientModel({
      precision:
        smartFieldNumberDisplaySetting.precision as NumberDisplaySettingClientModel.Precision,
      useGroupSeparator: smartFieldNumberDisplaySetting.useGroupSeparator,
      units: smartFieldNumberDisplaySetting.units,
      displayUnitsAfterNumber:
        smartFieldNumberDisplaySetting.displayUnitsAfterNumber,
    });
  }

  public static createEmpty(): NumberDisplaySettingClientModel {
    return new NumberDisplaySettingClientModel({
      precision: 2,
      useGroupSeparator: false,
      units: "",
      displayUnitsAfterNumber: true,
    });
  }
}
