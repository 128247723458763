import {
  DashboardTableHistoryListResponse,
  DashboardTableListResponse,
  DashboardTableResponse,
  QueryRecordRequestBodyObject,
  DashboardCreateReadonlyTableRequestBody,
  DashboardNormalizedTableStatusObject,
  DashboardCreateNormalizedTableScheduleRequestBody,
  DashboardNormalizedTableScheduleResponse,
  DashboardUpdateNormalizedTableScheduleRequestBody,
  DashboardNormalizedTableStatusListResponse,
  DashboardNormalizedTableStatusResponse,
  DashboardNormalizedTableScheduleListResponse,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";
import { AxiosResponse } from "axios";
type DashboardGeneralResponse = {
  message: string;
};

export default function useApiTable() {
  const { executeRequest } = useApi();

  const _listTables = async ({
    teamSlug,
    databaseId,
    branchSlug,
    limit,
    skip,
  }: {
    teamSlug: string;
    databaseId: string;
    branchSlug?: string;
    limit?: number;
    skip?: number;
  }): Promise<DashboardTableListResponse> => {
    return executeRequest(
      "get",
      `/${databaseId}/table`,
      undefined,
      {
        teamSlug,
      },
      {
        branchSlug,
        limit,
        skip,
      }
    );
  };

  const _findTable = async ({
    teamSlug,
    databaseId,
    branchSlug,
    tableSlug,
  }: {
    teamSlug: string;
    databaseId: string;
    branchSlug?: string;
    tableSlug?: string;
  }): Promise<DashboardTableResponse> => {
    return executeRequest(
      "get",
      `/${databaseId}/table/${tableSlug}`,
      undefined,
      {
        teamSlug,
      },
      {
        branchSlug,
      }
    );
  };

  const _createTable = ({
    databaseId,
    teamSlug,
    tableSlug,
    displayName,
    initPrompt,
    withDummyData,
    dummyDataRows,
    comment,
    isPrivate,
  }: {
    databaseId: string;
    teamSlug: string;
    tableSlug: string;
    displayName: string;
    initPrompt?: string;
    withDummyData?: boolean;
    dummyDataRows?: number;
    comment?: string;
    isPrivate?: boolean;
  }): Promise<AxiosResponse<DashboardTableResponse>> => {
    return executeRequest(
      "post",
      `/${databaseId}/table`,
      undefined,
      {
        teamSlug,
      },
      {
        tableSlug,
        displayName,
        initPrompt,
        withDummyData,
        dummyDataRows,
        comment,
        isPrivate,
      }
    );
  };

  const _updateTable = ({
    databaseId,
    teamSlug,
    tableSlug,
    displayName,
    tableType,
    isPrivate,
    comment,
  }: {
    databaseId: string;
    teamSlug: string;
    tableSlug: string;
    displayName: string;
    tableType?: "readonly" | "readwrite" | "jointable";
    isPrivate: boolean;
    comment?: string;
  }): Promise<AxiosResponse<DashboardTableResponse>> => {
    return executeRequest(
      "put",
      `/${databaseId}/table`,
      undefined,
      {
        teamSlug,
      },
      {
        tableSlug,
        displayName,
        tableType,
        isPrivate,
        comment,
      }
    );
  };

  const _deleteTable = ({
    databaseId,
    teamSlug,
    tableSlug,
  }: {
    databaseId: string;
    teamSlug: string;
    tableSlug: string;
  }): Promise<AxiosResponse<DashboardGeneralResponse>> => {
    return executeRequest(
      "DELETE",
      `/${databaseId}/table/${tableSlug}`,
      undefined,
      { teamSlug },
      undefined
    );
  };

  const _createReadonlyTable = ({
    databaseId,
    teamSlug,
    tableSlug,
    targetTableSlug,
    displayName,
    query,
    comment,
  }: {
    databaseId: string;
    teamSlug: string;
    tableSlug: string;
    targetTableSlug: string;
    displayName: string;
    query: QueryRecordRequestBodyObject;
    comment?: string;
  }): Promise<AxiosResponse<DashboardCreateReadonlyTableRequestBody>> => {
    return executeRequest(
      "post",
      `/${databaseId}/readonly-table`,
      undefined,
      {
        teamSlug,
      },
      {
        tableSlug,
        displayName,
        targetTableSlug,
        query,
        comment,
      }
    );
  };

  const _createReadonlyTableWithSql = ({
    databaseId,
    teamSlug,
    tableSlug,
    displayName,
    sql,
    comment,
  }: {
    databaseId: string;
    teamSlug: string;
    tableSlug: string;
    displayName: string;
    sql: string;
    comment?: string;
  }): Promise<AxiosResponse<DashboardCreateReadonlyTableRequestBody>> => {
    return executeRequest(
      "post",
      `/${databaseId}/readonly-table/sql`,
      undefined,
      {
        teamSlug,
      },
      {
        tableSlug,
        displayName,
        sql,
        comment,
      }
    );
  };

  const _getTableHistory = ({
    databaseId,
    teamSlug,
    tableSlug,
    limit,
    skip,
  }: {
    databaseId: string;
    teamSlug: string;
    tableSlug: string;
    limit?: number;
    skip?: number;
  }) => {
    return executeRequest<DashboardTableHistoryListResponse>(
      "get",
      `/${databaseId}/table-history/${tableSlug}`,
      undefined,
      {
        teamSlug,
      },
      {
        limit,
        skip,
      }
    );
  };

  const _getAllTableHistory = ({
    databaseId,
    teamSlug,
    limit,
    skip,
  }: {
    databaseId: string;
    teamSlug: string;
    limit?: number;
    skip?: number;
  }) => {
    return executeRequest<DashboardTableHistoryListResponse>(
      "get",
      `/${databaseId}/table-history`,
      undefined,
      {
        teamSlug,
      },
      {
        limit,
        skip,
      }
    );
  };

  const _duplicateTable = ({
    databaseId,
    teamSlug,
    targetTableSlug,
    tableSlug,
    displayName,
    withRecords,
    isPrivate,
    comment,
  }: {
    databaseId: string;
    teamSlug: string;
    targetTableSlug: string;
    tableSlug: string;
    displayName: string;
    withRecords: boolean;
    isPrivate?: boolean;
    comment?: string;
  }) => {
    return executeRequest<DashboardTableResponse>(
      "post",
      `/${databaseId}/table/duplicate`,
      undefined,
      {
        teamSlug,
      },
      {
        targetTableSlug,
        tableSlug,
        displayName,
        withRecords,
        isPrivate,
        comment,
      }
    );
  };

  const _checkTableSlugExist = ({
    databaseId,
    teamSlug,
    tableSlug,
  }: {
    databaseId: string;
    teamSlug: string;
    tableSlug: string;
  }) => {
    return executeRequest<{ isAvailable: boolean }>(
      "get",
      `/${databaseId}/table/${tableSlug}/check-slug`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  const _runNormalizedTable = async ({
    teamSlug,
    databaseId,
    tableSlug,
  }: {
    teamSlug: string;
    databaseId: string;
    tableSlug: string;
  }): Promise<DashboardNormalizedTableStatusObject> => {
    return executeRequest(
      "POST",
      `/${databaseId}/normalized-table/${tableSlug}/run`,
      undefined,
      { teamSlug }
    );
  };

  const _createNormalizedTableSchedule = async ({
    teamSlug,
    requestBody,
  }: {
    teamSlug: string;
    requestBody: DashboardCreateNormalizedTableScheduleRequestBody;
  }): Promise<DashboardNormalizedTableScheduleResponse> => {
    return executeRequest(
      "POST",
      `/normalized-table-schedule`,
      undefined,
      {
        teamSlug,
      },
      requestBody
    );
  };

  // todo: 暫定対応
  const _listNormalizedTableSchedule = async ({
    teamSlug,
    databaseId,
    tableSlug,
  }: {
    teamSlug: string;
    databaseId: string;
    tableSlug?: string;
  }): Promise<DashboardNormalizedTableScheduleListResponse> => {
    return executeRequest(
      "GET",
      `/normalized-table-schedule`,
      { databaseId, tableSlug, limit: 100, skip: 0 },
      {
        teamSlug,
      }
    );
  };

  const _updateNormalizedTableSchedule = async ({
    teamSlug,
    normalizedTableScheduleId,
    requestBody,
  }: {
    teamSlug: string;
    normalizedTableScheduleId: string;
    requestBody: DashboardUpdateNormalizedTableScheduleRequestBody;
  }): Promise<DashboardNormalizedTableScheduleResponse> => {
    return executeRequest(
      "PUT",
      `/normalized-table-schedule/${normalizedTableScheduleId}`,
      undefined,
      {
        teamSlug,
      },
      requestBody
    );
  };

  const _deleteNormalizedTableSchedule = async ({
    teamSlug,
    normalizedTableScheduleId,
  }: {
    teamSlug: string;
    normalizedTableScheduleId: string;
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest(
      "DELETE",
      `/normalized-table-schedule/${normalizedTableScheduleId}`,
      undefined,
      {
        teamSlug,
      }
    );
  };

  const _listNormalizedTableStatus = ({
    databaseId,
    teamSlug,
  }: {
    databaseId: string;
    teamSlug: string;
  }) => {
    return executeRequest<DashboardNormalizedTableStatusListResponse>(
      "GET",
      `/${databaseId}/normalized-table-status`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  const _findNormalizedTableStatus = ({
    databaseId,
    teamSlug,
    normalizedTableStatusId,
  }: {
    databaseId: string;
    teamSlug: string;
    normalizedTableStatusId: string;
  }) => {
    return executeRequest<DashboardNormalizedTableStatusResponse>(
      "GET",
      `/${databaseId}/normalized-table-status/${normalizedTableStatusId}`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  return {
    _listTables,
    _findTable,
    _createTable,
    _updateTable,
    _deleteTable,
    _createReadonlyTable,
    _createReadonlyTableWithSql,
    _getTableHistory,
    _getAllTableHistory,
    _duplicateTable,
    _checkTableSlugExist,
    _runNormalizedTable,
    _createNormalizedTableSchedule,
    _listNormalizedTableSchedule,
    _updateNormalizedTableSchedule,
    _deleteNormalizedTableSchedule,
    _listNormalizedTableStatus,
    _findNormalizedTableStatus,
  };
}
