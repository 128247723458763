import { ChangeEvent, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { useInviteUserToTeamMutation } from "~/serverStateStore";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useErrorToast } from "~/components_next/Error";
import { useToast } from "~/components_next/Toast";
import { Code } from "@radix-ui/themes";
import { Dialog } from "~/components_next/Dialog";
import { TextArea } from "~/components_next/TextArea";
import { Button } from "~/components_next/Button";
import { Box } from "~/components_next/Box";

export interface InviteMemberModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose: () => void;
}

export const InviteMemberModal = (props: InviteMemberModalProps) => {
  const { isOpen, setIsOpen, onClose } = props;

  const toast = useToast();
  const { errorToast } = useErrorToast({});
  const teamSlug = useTeamSlug();

  const [newEmailsRaw, setNewEmailsRaw] = useState("");
  const handleNewEmailsRaw = (event: ChangeEvent) =>
    setNewEmailsRaw((event.target as HTMLTextAreaElement).value);

  const invitingEmails = useMemo(() => {
    return newEmailsRaw.split(",").map((email) => email.trim());
  }, [newEmailsRaw]);

  // request
  const { mutateAsync: inviteUserToTeam, isLoading: isInvitingUserToTeam } =
    useMutation(useInviteUserToTeamMutation({ teamSlug }));

  const inviteRequest = async () => {
    return await inviteUserToTeam({
      emails: invitingEmails,
    });
  };

  const invite = async () => {
    try {
      await inviteRequest();
      toast({
        title: "Users Invited.",
        description: "Invitation email sent.",
        type: "success",
        duration: 5000,
      });
      setNewEmailsRaw("");
    } catch (e) {
      errorToast(e);
    }
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Content>
        <Dialog.Body>
          <TextArea
            variant="primary"
            placeholder="Comma separated emails"
            value={newEmailsRaw}
            onChange={handleNewEmailsRaw}
            mb="1"
            label="Invite Members"
          />

          {invitingEmails.map((email, index) => {
            if (email.length > 0) {
              return (
                <Box key={index} my="1">
                  <Code color="gray">{email}</Code>
                </Box>
              );
            }
            return <Box key={index}></Box>;
          })}
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            variant="primary"
            onClick={invite}
            isLoading={isInvitingUserToTeam}
          >
            Invite
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
};
