type TemplateAuthDependencies = { templateId: string };

type ListTemplateAuthDependencies = {
  teamSlug: string;
  skip?: number;
  limit?: number;
} & TemplateAuthDependencies;

const templateAuthKeys = {
  all: ({ templateId }: TemplateAuthDependencies) =>
    [templateId, "templateAuth"] as const,

  allListTemplateAuth: ({ templateId }: TemplateAuthDependencies) =>
    [...templateAuthKeys.all({ templateId }), "listTemplateAuth"] as const,
  listTemplateAuth: ({ teamSlug, templateId, skip, limit }: ListTemplateAuthDependencies) =>
    [
      ...templateAuthKeys.allListTemplateAuth({ templateId }),
      { teamSlug, skip, limit },
    ] as const,
};

export { templateAuthKeys };
