import { Code } from "@radix-ui/themes";
import { useMutation } from "react-query";
import { useDeleteTeamUserMutation } from "~/serverStateStore/teams/mutations/useDeleteTeamUserMutation";
import { useErrorToast } from "~/components_next/Error";
import { useTeam } from "~/serverStateStore/teams";
import { Button } from "~/components_next/Button";
import { Text } from "~/components_next/Text";
import { Dialog } from "~/components_next/Dialog";
import { useToast } from "~/components_next/Toast";

export const DeleteMemberDialog = ({
  isOpen,
  setIsOpen,
  onClose,
  userId,
  username,
  teamSlug,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose: () => void;
  userId: string;
  username: string;
  teamSlug: string;
}) => {
  const toast = useToast();

  const { mutateAsync: deleteTeamUser, isLoading: isDeleting } = useMutation(
    useDeleteTeamUserMutation({ teamSlug })
  );

  const { data: team } = useTeam({ teamSlug });

  const { errorToast } = useErrorToast({});
  const handleRemoveUser = async () => {
    try {
      await deleteTeamUser({ userId });
    } catch (e) {
      errorToast(e);
    }
    toast({
      title: "Removed",
      description: `${username} has been removed from ${
        team ? team.teamName : "this team"
      }`,
      type: "success",
      duration: 9000,
    });
    onClose();
  };

  return (
    <>
      <Dialog.Root onOpenChange={setIsOpen} open={isOpen}>
        <Dialog.Content>
          <Dialog.Title>You&apos;re Outta Here</Dialog.Title>
          <Dialog.Body>
            <Text>
              Are you sure you want to remove <Code>{username}</Code> from{" "}
              <Code>{team ? team.teamName : "this team"}</Code>?
            </Text>
          </Dialog.Body>
          <Dialog.Footer>
            <Dialog.Close>
              <Button variant="tertiary" size="sm" isDisabled={isDeleting}>
                Cancel
              </Button>
            </Dialog.Close>
            <Button
              variant="danger"
              size="sm"
              isDisabled={isDeleting}
              isLoading={isDeleting}
              onClick={handleRemoveUser}
            >
              Remove
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};
