import { CanvasVisualizationPromptConfigCientModel } from "~/clientModel/canvas/CanvasVisualizationPromptConfigClientModel";
import { DropdownMenu } from "~/components_next/DropdownMenu";
import { Flex } from "~/components_next/Flex";
import { Image } from "~/components_next/Image";

type VisualizationConfigDropdownItemProps = {
  config: CanvasVisualizationPromptConfigCientModel;
  onSelect: (config: CanvasVisualizationPromptConfigCientModel) => void;
};

const VisualizationConfigDropdownItem = (
  props: VisualizationConfigDropdownItemProps
) => {
  const { config, onSelect } = props;

  return (
    <DropdownMenu.Item onClick={() => onSelect(config)}>
      <Flex gap="2" align="center">
        <Image src={config.icon} width={16} height={16} />
        {config.chartTypeLabel}
      </Flex>
    </DropdownMenu.Item>
  );
};

export { VisualizationConfigDropdownItem };
