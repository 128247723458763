import { useQuery } from "react-query";
import { useGetTeamQuery } from "../queries/useGetTeamQuery";

type UseTeamParams = {
  teamSlug: string;
};

export const useTeam = ({ teamSlug }: UseTeamParams) => {
  return useQuery(useGetTeamQuery({ teamSlug }));
};
