import { Flex } from "@radix-ui/themes";
import { BsClockHistory } from "react-icons/bs";
import { useQuery } from "react-query";
import { Drawer } from "~/components_next/Drawer";
import { IconButton } from "~/components_next/IconButton";
import { useDisclosure } from "~/hooks/useDisclosure";
import { useListTableHistoryQuery, useSimpleFields } from "~/serverStateStore";
import { uselistImportIntegrationHistoriesQuery } from "~/serverStateStore/imports/queries/useListImportIntegrationHistoriesQuery";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTableSlug } from "~/utilHooks/useTableSlug";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useMemo } from "react";
import { TableHistoryCellWrapper } from "./components/TableHistoryCellWrapper";
import { isTableHistoryObject } from "./util/isTableHistoryObject";
import dayjs from "dayjs";

const TableHistoryDrawer = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const tableSlug = useTableSlug();

  const { data: tableHistory } = useQuery({
    ...useListTableHistoryQuery({
      teamSlug,
      databaseId,
      tableSlug,
    }),
  });

  const { data: simpleFields } = useSimpleFields({
    teamSlug,
    databaseId,
    tableSlug,
  });

  const { data: listIntegrationHistory } = useQuery({
    ...uselistImportIntegrationHistoriesQuery({
      databaseId,
      tableSlug,
      teamSlug,
      limit: 9999,
      skip: 0,
    }),
  });

  const listItems = useMemo(() => {
    if (
      tableHistory &&
      tableHistory.items &&
      listIntegrationHistory &&
      listIntegrationHistory.items
    ) {
      return [...tableHistory.items, ...listIntegrationHistory.items].sort(
        (s1, s2) => {
          const s1Time = isTableHistoryObject(s1)
            ? s1.operatedAt
            : s1.createdAt;
          const s2Time = isTableHistoryObject(s2)
            ? s2.operatedAt
            : s2.createdAt;
          return dayjs(s1Time).isBefore(s2Time) ? 1 : -1;
        }
      );
    }
    return [];
  }, [tableHistory, listIntegrationHistory]);

  const { isOpen, setIsOpen } = useDisclosure();

  if (!tableHistory) return null;
  if (!simpleFields) return null;
  if (!listIntegrationHistory) return null;

  return (
    <>
      <IconButton
        icon={<BsClockHistory />}
        tooltip="Source History"
        onClick={() => setIsOpen(true)}
      />
      <Drawer.Root open={isOpen} onOpenChange={setIsOpen} size="xs">
        <Drawer.Content>
          <Drawer.Title>History</Drawer.Title>
          <Drawer.Body>
            <Flex direction="column">
              {
                <TableHistoryCellWrapper
                  items={listItems}
                  fields={simpleFields?.fields || []}
                />
              }
            </Flex>
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
    </>
  );
};

export { TableHistoryDrawer };
