import { SimpleFieldCategoryType } from "@usemorph/morph-dashboard-types";
import { Badge, BadgeProps } from "~/components_next/Badge";
import { getSimpleFieldTypeLabel } from "./utils/getSimpleFieldTypeLabel";

type FieldTypeBadgeProps = Omit<BadgeProps, "variant"> & {
  fieldType: SimpleFieldCategoryType;
};

const FieldTypeBadge = (props: FieldTypeBadgeProps) => {
  const { fieldType, ...badgeProps } = props;
  return (
    <Badge variant="tertiary" {...badgeProps}>
      {getSimpleFieldTypeLabel(fieldType)}
    </Badge>
  );
};
export { FieldTypeBadge };
