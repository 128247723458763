
/* eslint-disable react/display-name */
import { DropdownMenu, SimpleDropdownMenu } from "~/components_next/DropdownMenu"
import { IconButton } from "~/components_next/IconButton"
import { useMutation } from "react-query";
import { useDeleteTemplateAuthMutation } from "~/serverStateStore/templateAuth";
import { useToast } from "~/components_next/Toast";
import { useErrorToast } from "~/components_next/Error";
import { DashboardTemplateAuthObject } from "@usemorph/morph-dashboard-types";
import { BsThreeDots } from "react-icons/bs"
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useDisclosure } from "~/hooks/useDisclosure";
import { UpdateTemplateAuthDialog } from "./UpdateTemplateAuthDialog";
import { Button } from "~/components_next/Button";
import { memo } from "react";

interface AuthSettingDropdown {
  selectedTemplateAuth?: DashboardTemplateAuthObject;
  setSelectedTemplateAuth: (templateAuth?: DashboardTemplateAuthObject) => void;
  templateId: string;
}

export const AuthSettingDropdown = memo(
  (props: AuthSettingDropdown) => {
    const {
      selectedTemplateAuth,
      setSelectedTemplateAuth,
      templateId,
    } = props
    const teamSlug = useTeamSlug()
    const toast = useToast();
    const { errorToast } = useErrorToast({});

    // states
    const updateTemplateAuthDialog = useDisclosure()

    // functions
    const { mutateAsync: deleteTemplateAuth, isLoading } = useMutation({ ...useDeleteTemplateAuthMutation({ templateId, teamSlug }) })

    const handleOnDelete = async () => {
      if (selectedTemplateAuth) {
        try {
          await deleteTemplateAuth({ templateAuthId: selectedTemplateAuth?.templateAuthId })
          setSelectedTemplateAuth(undefined)
          toast({
            title: "Successfully delisted.",
            type: "success",
            duration: 5000,
          });
        } catch (error) {
          errorToast(error)
        }
      }
    }

    return (
      <>
        <SimpleDropdownMenu
          trigger={
            <IconButton
              icon={<BsThreeDots />}
              tooltip="Account setting"
              size="lg"
            />
          }
        >
          <DropdownMenu.Item>
            <Button
              variant="actionText"
              onClick={updateTemplateAuthDialog.onOpen}
            >
              Edit
            </Button>
          </DropdownMenu.Item>
          <DropdownMenu.Item>
            <Button
              variant="actionText"
              onClick={handleOnDelete}
              isLoading={isLoading}
            >
              Delete
            </Button>
          </DropdownMenu.Item>
        </SimpleDropdownMenu>

        <UpdateTemplateAuthDialog
          isOpen={updateTemplateAuthDialog.isOpen}
          onOpenChange={updateTemplateAuthDialog.setIsOpen}
          selectedTemplateAuth={selectedTemplateAuth}
          setSelectedTemplateAuth={setSelectedTemplateAuth}
          templateId={templateId}
        />
      </>
    )
  }
)
