import { css } from "@stitches/react";
import { useCallback, useMemo, useState } from "react";
import { BsPlayFill } from "react-icons/bs";
import { Executable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { useLoadableState } from "~/clientModel/loadable";
import { RecordsClientModel } from "~/clientModel/records";
import { Button } from "~/components_next/Button";
import { Callout } from "~/components_next/Callout";
import { extractErrorDetails } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { useDisclosure } from "~/hooks/useDisclosure";
import {
  basicFieldFilterFn,
  BasicTypeaheadPrompt,
  FieldSuggestionItem,
} from "~/presenters/prompt";
import { FormulaPreviewResultDrawer } from "./FormulaPreviewResultDrawer";

const promptRootStyle = css({
  overflowY: "auto !important",
  height: "120px !important",
});

const promptTheme = {
  root: `${promptRootStyle()}`,
};

type FormulaInputProps = {
  formula: string;
  onFormulaChange: (formula: string) => void;
  fieldsForTypeahead: FieldsClientModel;
  targetField: FieldClientModel;
  previewExecutable: Executable<
    { formula: string; field: FieldClientModel },
    { records: RecordsClientModel; fields: FieldsClientModel }
  >;
};

export const FormulaInput = (props: FormulaInputProps) => {
  const {
    formula,
    onFormulaChange,
    targetField,
    fieldsForTypeahead,
    previewExecutable,
  } = props;

  const [lastPreviewedFormula, setLastPreviewedFormula] = useState<string>("");
  const [previewResultLoadable, setPreviewResultLoadable] = useLoadableState<{
    records: RecordsClientModel;
    fields: FieldsClientModel;
  }>();

  const {
    isOpen: isResultDrawerOpen,
    onOpen: onResultDrawerOpen,
    setIsOpen: onResultDrawerOpenChange,
  } = useDisclosure();

  const optionItems = useMemo(
    () => fieldsForTypeahead.allFields,
    [fieldsForTypeahead]
  );

  const convertFieldToDotNotatedFieldName = useCallback(
    (field: FieldClientModel) => `\${${field.name}}`,
    []
  );

  const renderHTMLTextContent = useCallback(
    (field: FieldClientModel) => field.label,
    []
  );

  const renderSuggestedItem = useCallback(
    (field: FieldClientModel) => <FieldSuggestionItem field={field} />,
    []
  );

  const handlePreviewRun = async () => {
    setLastPreviewedFormula(formula);
    const { records, fields } = await previewExecutable.execute({
      formula,
      field: targetField,
    });
    setPreviewResultLoadable({ records, fields });
    onResultDrawerOpen();
  };

  const showPreviewResult = lastPreviewedFormula === formula;

  return (
    <>
      <Flex direction="column" gap="2">
        <BasicTypeaheadPrompt<FieldClientModel>
          textContent={formula}
          onUpdate={onFormulaChange}
          theme={promptTheme}
          optionItems={optionItems}
          filterFn={basicFieldFilterFn}
          convertOptionItemToText={convertFieldToDotNotatedFieldName}
          renderHTMLTextContent={renderHTMLTextContent}
          renderSuggestedItem={renderSuggestedItem}
          focusOnLoad
        />

        {/* Preview Button */}
        <Button
          variant="secondary"
          onClick={handlePreviewRun}
          isDisabled={!formula}
          isLoading={previewExecutable.isExecuting}
          size="sm"
        >
          <BsPlayFill />
          Preview
        </Button>

        {/* Test Run Result(Error) */}
        {showPreviewResult && previewExecutable.status === "error" && (
          <Callout
            type="alert"
            title="Invalid Formula"
            description={
              extractErrorDetails(previewExecutable.error).description
            }
          />
        )}

        {/* Test Run Result(Success) */}
        {showPreviewResult && previewExecutable.status === "success" && (
          <Callout type="success" description={"The input formula is valid"} />
        )}
      </Flex>
      <FormulaPreviewResultDrawer
        isOpen={isResultDrawerOpen}
        onOpenChange={onResultDrawerOpenChange}
        previewResultLoadable={previewResultLoadable}
      />
    </>
  );
};
