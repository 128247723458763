import { useCallback } from "react";
import { LuDownload } from "react-icons/lu";
import { IconButton } from "~/components_next/IconButton/IconButton";

type DownloadCodeOptions = {
  extension: "json" | "html" | (string & {});
  mimeSubType: "json" | "html" | (string & {});
  baseName: string;
};

type IncreaseFontSizeProps = { value: string } & DownloadCodeOptions;

const DownloadCode = (props: IncreaseFontSizeProps) => {
  const { value, extension, baseName, mimeSubType } = props;

  const handleDownload = useCallback(() => {
    const blob = new Blob([value], { type: `text/${mimeSubType}` });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${baseName}.${extension}`;
    link.click();
    link.remove();
    URL.revokeObjectURL(url);
  }, [extension, baseName, value, mimeSubType]);

  return (
    <IconButton
      tooltip="Download"
      onClick={handleDownload}
      size="sm"
      icon={<LuDownload />}
    />
  );
};

export { DownloadCode };
export type { DownloadCodeOptions };
