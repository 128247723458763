import { forwardRef, ReactNode } from "react";
import { DropdownMenu } from "./DropdownMenu";

type RadixDropdownMenuContentProps = React.ComponentPropsWithoutRef<
  typeof DropdownMenu.Content
>;

type SimpleDropdownMenuProps = {
  trigger: ReactNode;
  children: ReactNode;
  variant?: RadixDropdownMenuContentProps["variant"];
  size?: "md" | "sm";
};

const SimpleDropdownMenu = forwardRef<
  HTMLButtonElement,
  SimpleDropdownMenuProps
>((props, ref) => {
  const { trigger, children, variant = "soft", size = "md" } = props;

  const contentSize = size === "md" ? "2" : "1";

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger ref={ref}>{trigger}</DropdownMenu.Trigger>
      <DropdownMenu.Content
        color="gray"
        highContrast
        variant={variant}
        size={contentSize}
      >
        {children}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
});

SimpleDropdownMenu.displayName = "SimpleDropdownMenu";

type SimpleDropdownItemProps = React.ComponentPropsWithoutRef<
  typeof DropdownMenu.Item
>;

const SimpleDropdownItem = forwardRef<HTMLDivElement, SimpleDropdownItemProps>(
  (props, ref) => {
    return <DropdownMenu.Item ref={ref} {...props} />;
  }
);

SimpleDropdownItem.displayName = "SimpleDropdownItem";

export { SimpleDropdownMenu, type SimpleDropdownMenuProps };
