import { useQuery } from "react-query";
import { Loadable, useComposeLoadable } from "~/clientModel/loadable";
import {
  TablesClientModel,
  TablesClientModelFactory,
} from "~/clientModel/tables";

import { useListTablesQuery } from "~/serverStateStore";

type useTablesLoadableParams = {
  teamSlug: string;
  databaseId: string;
};

export const useTablesLoadable = ({
  teamSlug,
  databaseId,
}: useTablesLoadableParams): Loadable<TablesClientModel> => {
  return useComposeLoadable(
    useQuery({
      ...useListTablesQuery({
        teamSlug,
        databaseId,
      }),
      select: (data) => {
        return TablesClientModelFactory.createFromDashboardTableListResponse(
          data
        );
      },
    })
  );
};
