import { type ForwardedRef, forwardRef, ComponentPropsWithoutRef } from "react";
import { MorphThemeSize } from "../commonProps.type";
import {
  Checkbox as RadixThemeCheckbox,
  Text as RadixThemeText,
} from "@radix-ui/themes";
import { match } from "ts-pattern";
import { Flex } from "../Flex";
import { Text, TextProps } from "../Text";

type RadixThemeCheckboxProps = ComponentPropsWithoutRef<
  typeof RadixThemeCheckbox
>;

type CheckboxProps = {
  size?: MorphThemeSize;
  value: boolean;
  onChange?: (value: boolean) => void;
  isDisabled?: boolean; // default false
  isReadOnly?: boolean; // default false
  label?: string;
} & Omit<
  RadixThemeCheckboxProps,
  "size" | "disabled" | "onChange" | "checked" | "value"
>;

const getSize = (size?: MorphThemeSize): RadixThemeCheckboxProps["size"] => {
  return match(size)
    .with("xs", () => "1" as const)
    .with("sm", () => "2" as const)
    .with("md", () => "3" as const)
    .with("lg", () => "3" as const)
    .with("xl", () => "3" as const)
    .with(undefined, () => "3" as const)
    .exhaustive();
};

const getTextVariant = (size?: MorphThemeSize): TextProps["variant"] => {
  return match(size)
    .with("xs", () => "tinyDescription" as const)
    .with("sm", () => "description" as const)
    .with("md", () => "default" as const)
    .with("lg", () => "default" as const)
    .with("xl", () => "default" as const)
    .with(undefined, () => "default" as const)
    .exhaustive();
};

const _Checkbox = (
  props: CheckboxProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const {
    size,
    isDisabled = false,
    isReadOnly = false,
    onChange,
    value,
    label,
    ...radixProps
  } = props;

  const radixCheckboxSize = getSize(size);

  const textVariant = getTextVariant(size);

  return (
    <RadixThemeText as="label">
      <Flex
        align="center"
        gap="2"
        css={{ "&:hover": { textDecoration: "underline", cursor: "pointer" } }}
      >
        <RadixThemeCheckbox
          ref={ref}
          size={radixCheckboxSize}
          disabled={isDisabled || isReadOnly}
          color="gray"
          highContrast
          checked={value}
          onCheckedChange={(checked) => onChange?.(checked.valueOf() === true)}
          {...radixProps}
        />
        <Text variant={textVariant}> {label}</Text>
      </Flex>
    </RadixThemeText>
  );
};

const Checkbox = forwardRef(_Checkbox);

export { Checkbox, type CheckboxProps };
