import {
  RecordFilterCondition,
  RecordFilterConditionAnd,
  RecordValueType,
} from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { RecordClientModel } from "./RecordClientModel";
import { RecordEntryClientModelDefactory } from "./recordEntry";

export class RecordClientModelDefactory {
  public static toRecordValues(
    record: RecordClientModel,
    fields: FieldsClientModel
  ): { key: string; value: RecordValueType }[] {
    const values: { key: string; value: RecordValueType }[] =
      fields.data.fields.flatMap((field) => {
        return match(field.type)
          .with(
            // 自動生成系はリクエストから抜く
            {
              type: P.union(
                "formula",
                "autoNumber",
                "autoBigNumber",
                "createdAt",
                "createdBy",
                "lastEditedAt",
                "lastEditedBy",
                "syncValue",
                "generateText",
                "calculation",
                "aggregateValue",
                "smartFunction"
              ),
            },
            () => []
          )
          .with(
            {
              type: P.union(
                "shortText",
                "longText",
                "email",
                "phoneNumber",
                "date",
                "datetime",
                "time",
                "url",
                "number",
                "bigNumber",
                "decimal",
                "boolean",
                "singleSelect",
                "multiSelect",
                "attachment",
                "image",
                "html",
                "json",
                "array"
              ),
            },
            () => ({
              key: field.name,
              value: RecordEntryClientModelDefactory.toValueForRecordRequest(
                record.selectEntryOrThrow(field.name),
                field
              ),
            })
          )
          .exhaustive();
      });

    return values;
  }

  public static toRecordFilterConditionAnd(
    record: RecordClientModel,
    fields: FieldsClientModel
  ): RecordFilterCondition {
    const filter: RecordFilterConditionAnd = {
      and: fields.data.fields.flatMap((field) => {
        return field.data.isPrimary
          ? {
              key: field.data.name,
              operator: "equal",
              value: RecordEntryClientModelDefactory.toValueForRecordRequest(
                record.selectEntryOrThrow(field.name),
                field
              ),
            }
          : [];
      }),
    };

    return filter;
  }
}
