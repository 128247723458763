import { useCallback } from "react";
import { useSetFilter, useSetSort } from "../../../states/queryConditions";
import { ClearAllFiltersAndSortsButton } from "../../../../../common/components/QueryMode/Buttons/ClearAllFiltersAndSortsButton";
import { useSetQueryMode } from "../../../states/queryMode";

const TableViewClearFiltersAndSortsButton = () => {
  /**
   * global states from recoil
   */
  const setFilter = useSetFilter();
  const setSort = useSetSort();
  const setQueryMode = useSetQueryMode();

  /**
   * handlers
   */

  const handleClickClearAllFiltersAndSortsButton = useCallback(() => {
    setFilter(null);
    setSort(null);
    setQueryMode(null);
  }, [setFilter, setSort, setQueryMode]);

  return (
    <ClearAllFiltersAndSortsButton
      onClick={handleClickClearAllFiltersAndSortsButton}
    />
  );
};

export { TableViewClearFiltersAndSortsButton };
