import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardCreateDatabaseRequestBody } from "@usemorph/morph-dashboard-types";
import { CreateDatabaseMutationProps } from "~/serverStateStore/databases";
import { getPath } from "~/routing";
import { useErrorToast } from "~/components_next/Error";
import { Dialog } from "~/components_next/Dialog";
import { Input } from "~/components_next/Input";
import { stringToBoolean } from "~/utils/stringUtils";
import { Button } from "~/components_next/Button";
import { Box } from "~/components_next/Box";
import { Executable } from "~/clientModel/executable";
import { DatabaseClientModel } from "~/clientModel/database/DatabaseClientModel";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import { ListDatabaseClientModel } from "~/clientModel/database/listDatabaseClientModel";

export const CreateDatabaseDialog = ({
  isOpen,
  setIsOpen,
  onClose,
  createDatabaseExecutable,
  listDatabaseRefetcher,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose: () => void;
  createDatabaseExecutable: Executable<
    CreateDatabaseMutationProps,
    DatabaseClientModel
  >;
  listDatabaseRefetcher: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<ListDatabaseClientModel, unknown>>;
}) => {
  const teamSlug = useTeamSlug();
  const navigate = useNavigate();
  const { errorToast } = useErrorToast({});

  const [requestBody, setRequestBody] =
    useState<DashboardCreateDatabaseRequestBody>({
      databaseName: "",
      protocol: "postgresql",
      host: "",
      port: 5432,
      database: "",
      username: "",
      password: "",
      isBuiltin: true,
    });

  const handleChangeRequestBody = ({
    id,
    value,
  }: {
    id: string;
    value: string;
  }) => {
    if (id === "isBuiltin") {
      setRequestBody({
        ...requestBody,
        isBuiltin: stringToBoolean(value),
      });
    } else {
      setRequestBody({
        ...requestBody,
        [id]: value,
      });
    }
  };

  const isNewDatabaseValueValid = useMemo((): boolean => {
    return requestBody.databaseName !== undefined;
  }, [requestBody]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.nativeEvent.isComposing || e.key !== "Enter") return;
    handleCreateNewDatabase();
  };

  const resetValues = () => {
    setRequestBody({
      databaseName: "",
      protocol: "postgresql",
      host: "",
      port: 5432,
      database: "",
      username: "",
      password: "",
      isBuiltin: true,
    });
  };

  const handleCreateNewDatabase = async () => {
    try {
      const response = await createDatabaseExecutable.execute(requestBody);
      navigate(
        getPath("databaseHome", { teamSlug, databaseId: response.databaseId })
      );
      resetValues();
      await listDatabaseRefetcher();
      onClose();
    } catch (e) {
      errorToast(e);
    }
  };

  return (
    <>
      <Dialog.Root open={isOpen} onOpenChange={setIsOpen} size="xs">
        <Dialog.Content>
          <Dialog.Title>Create New Database</Dialog.Title>
          <Dialog.Body>
            <Box my="3" width="100%">
              <Input
                id={"databaseName"}
                variant="primary"
                width="100%"
                autoFocus
                value={requestBody.databaseName}
                onChange={(e) =>
                  handleChangeRequestBody({
                    id: "databaseName",
                    value: e.target.value,
                  })
                }
                onKeyDown={handleKeyDown}
                label="Database Name"
              />
            </Box>
          </Dialog.Body>
          <Dialog.Footer>
            <Dialog.Close>
              <Button
                variant="secondary"
                size="sm"
                isDisabled={createDatabaseExecutable.isExecuting}
              >
                Close
              </Button>
            </Dialog.Close>
            <Button
              variant="primary"
              size="sm"
              isDisabled={
                !isNewDatabaseValueValid || createDatabaseExecutable.isExecuting
              }
              onClick={handleCreateNewDatabase}
              isLoading={createDatabaseExecutable.isExecuting}
            >
              Create
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};
