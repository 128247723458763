import {
  useViewConditionChange,
  UseViewConditionChangeProps,
} from "./useViewConditionChange";

type UpdateViewConditionHandlerProps = UseViewConditionChangeProps;

const UpdateViewConditionHandler = (props: UpdateViewConditionHandlerProps) => {
  useViewConditionChange(props);

  return null;
};

export { UpdateViewConditionHandler };
