import { DropdownMenu } from "~/components_next/DropdownMenu";
import { FieldClientModel } from "~/clientModel/fields/field";
import { Text } from "~/components_next/Text";
import { UseExecutable } from "~/clientModel/executable";

type RecordsTableQueryModeHeaderDropdownProps = {
  field: FieldClientModel;
  onBulkEditRecordsClick: () => void;
  onEditFieldClick: () => void;
  onDeleteFieldClick: () => void;
  useUpdateFieldVisibilityExecutable: UseExecutable<
    void,
    { field: FieldClientModel }
  >;
};

export const RecordsTableQueryModeHeaderDropdown = (
  props: RecordsTableQueryModeHeaderDropdownProps
) => {
  const {
    field,
    onBulkEditRecordsClick,
    onEditFieldClick,
    useUpdateFieldVisibilityExecutable,
    onDeleteFieldClick,
  } = props;

  const updateFieldVisibilityExecutable = useUpdateFieldVisibilityExecutable();

  return (
    <>
      {!field.isAutoGenerated && (
        <DropdownMenu.Item onClick={onBulkEditRecordsClick}>
          Bulk Edit Records
        </DropdownMenu.Item>
      )}

      <DropdownMenu.Item onClick={onEditFieldClick}>
        Edit Field
      </DropdownMenu.Item>

      <DropdownMenu.Item
        onClick={() =>
          updateFieldVisibilityExecutable.execute({ field: field.hideField() })
        }
      >
        Hide Field
      </DropdownMenu.Item>

      {field.isDeletable && (
        <>
          <DropdownMenu.Separator />
          <DropdownMenu.Item onClick={onDeleteFieldClick}>
            <Text variant="errorMessage">Delete Field</Text>
          </DropdownMenu.Item>
        </>
      )}
    </>
  );
};
