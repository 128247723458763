/**
 * What's this?
 * useState + useEffect alternative
 * When props change, the state will be updated, without useEffect
 */

import { Dispatch, SetStateAction, useState } from "react";

export const useReactiveState = <T>(props: T) => {
  const [value, setValue] = useState(props);
  const [prevValue, setPrevValue] = useState(props);

  if (props !== prevValue) {
    setValue(props);
    setPrevValue(props);
  }

  return [value, setValue] as [T, Dispatch<SetStateAction<T>>];
};
