import useApiTeam from "~/api/useApiTeam";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { teamsKeys } from "../keys";

interface UseInviteUserToTeamParams {
  teamSlug: string;
}

const useInviteUserToTeamMutation = ({
  teamSlug,
}: UseInviteUserToTeamParams) => {
  const { _inviteUser } = useApiTeam();
  const client = useQueryClient();

  return {
    mutationFn: ({
      emails,
    }: {
      emails: string[];
    }) => {
      return _inviteUser({
        teamSlug,
        emails,
      });
    },
    onSuccess: () => {
      return invalidateAll(client, teamsKeys.getTeam({ teamSlug }));
    },
  };
};

export { useInviteUserToTeamMutation };
