import { ReactNode } from "react";
import { match } from "ts-pattern";
import { Badge } from "~/components_next/Badge";
import { Box } from "~/components_next/Box"
import { Flex } from "~/components_next/Flex"
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text"

interface PlanTableHeaderProps {
  title: string;
  price: string;
  perMonth: string;
  explain: string;
  isTrialBadge: boolean;
  children: ReactNode;
}

export const PlanTableHeader = (props: PlanTableHeaderProps) => {
  const {
    title,
    price,
    perMonth,
    explain,
    isTrialBadge,
    children,
  } = props

  return (
    <>
      <Flex
        direction="column"
        p="3"
        css={{ width: "150px", borderLeftWidth: 1 }}
      >
        {
          match(isTrialBadge)
            .with(true, () => {
              return (
                <Badge variant="tertiary" color="bronze" size="xs">
                  Try for free
                </Badge>
              );
            })
            .otherwise(() => <Box css={{ padding: "10px" }} />)
        }
        <Text variant="subheading" mt="2">
          {title}
        </Text>
        <Text variant="heading">{price}</Text>
        <Text variant="subheading">{perMonth}</Text>
        <Box mt="3" />
        {children}
        <Spacer />
        <Box mt="2" mb="1" css={{ height: "60px" }}>
          <Text>{explain}</Text>
        </Box>
      </Flex>
    </>
  )
}
