import constate from "constate";
import { TableViewCellComponentProps } from "../TableViewCellComponent";

const useTableViewCellComponentContext = (
  props: TableViewCellComponentProps
) => {
  return {
    ...props,
  };
};

export const [
  TableViewCellComponentPropsProvider,
  useTableView,
  useTableViewIsReadOnly,
] = constate(
  useTableViewCellComponentContext,
  (value) => value.view,
  (value) => value.isReadOnly
);
