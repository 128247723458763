import { CollaboratorColorSchemes } from "~/components_next/hooks/coworkerColors";
import { UserColorClientModel } from "./UserColorClientModel";

export class UserColorClientModelFactory {
  public static createFromStringValue(str: string): UserColorClientModel {
    // 最初の10文字のcharCodeの合計をとって、剰余でcolorTypeを決める
    const charCodeSum = [...new Array(10)].reduce(
      (sum, charIndex) => sum + str.charCodeAt(charIndex),
      0
    );
    const colorType =
      CollaboratorColorSchemes[charCodeSum % CollaboratorColorSchemes.length];

    return new UserColorClientModel({
      colorType,
    });
  }
}
