import { ReactNode } from "react";
import { Flex } from "~/components_next/Flex";
import { styled } from "~/stitches";

const Layout = styled(Flex, {
  position: "fixed",
  bottom: "16px",
  left: "4px",
  boxShadow: "$5",
  alignItems: "center",
  direction: "column",
  width: "230px",
  maxHeight: "100%",
  overflow: "auto",
});

const LeftBottomToastLayout = (props: { children: ReactNode }) => {
  const { children } = props;

  return (
    <Layout direction="column" gap="2">
      {children}
    </Layout>
  );
};

export { LeftBottomToastLayout };
