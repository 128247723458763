import { RecordHistoryClientModel } from "~/clientModel/recordHistories/recordHistory";
import { Badge } from "~/components_next/Badge";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
import { RecordsTable } from "~/components_next/RecordsTable";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";
import { UserMention } from "~/presenters/user";

type RecordHistoryCardProps = {
  recordHistory: RecordHistoryClientModel;
};

export const RecordHistoryCard = (props: RecordHistoryCardProps) => {
  const { recordHistory } = props;
  return (
    <Flex direction="column" gap="4" px="2" py="4">
      <Flex align="center">
        {recordHistory.operatedBy && (
          <UserMention user={recordHistory.operatedBy} />
        )}
        <Spacer />
        <Text variant="description">{recordHistory.fromNow}</Text>
      </Flex>
      <Text>{recordHistory.operationDescription}</Text>

      {/* previous data */}
      {recordHistory.previousRecord && (
        <Flex direction="column" align="start" gap="2">
          <Badge variant="tertiary" size="sm">
            Previous
          </Badge>
          <Box width="100%" style={{ overflowX: "auto", overflowY: "hidden" }}>
            <RecordsTable
              isReadOnly
              fields={recordHistory.fields}
              records={recordHistory.previousRecord}
              noPadding
              noFieldTypeIcon
            />
          </Box>
        </Flex>
      )}

      {/* updated data */}
      <Flex direction="column" align="start" gap="2">
        <Badge variant="tertiary" size="sm">
          Subsequent
        </Badge>
        <Box width="100%" style={{ overflowX: "auto", overflowY: "hidden" }}>
          <RecordsTable
            isReadOnly
            fields={recordHistory.fields}
            records={recordHistory.subsequentRecord}
            noPadding
            noFieldTypeIcon
          />
        </Box>
      </Flex>
    </Flex>
  );
};
