/**
 * おいおい共通化する
 */
import { z } from "zod";
import { P, match } from "ts-pattern";
import { FieldClientModel } from "~/clientModel/fields/field";

export const intRegex = /^\d+$/;
export const decimalRegex = /^\d+\.\d+$/;

export const zStringArrayValidation = (field: FieldClientModel) => {
  return match(field.type.type)
    .with(P.union("number", "autoNumber"), () => {
      return z.string().array().nonempty().element.regex(intRegex);
    })
    .with("decimal", () => {
      return z.string().array().nonempty().element.regex(decimalRegex);
    })
    .otherwise(() => {
      return z.string().array().nonempty();
    });
};
