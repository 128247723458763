import { ScrollArea } from "@radix-ui/themes";
import { memo, useMemo } from "react";
import { BsDatabase, BsSticky, BsTrash, BsType } from "react-icons/bs";
import { useReactFlow, useViewport } from "reactflow";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import {
  CanvasCreateCellClientModel,
  CanvasCreateCellClientModelFactory,
} from "~/clientModel/canvas/CanvasCreateCellClientModel";
import { UseExecutable } from "~/clientModel/executable";
import { Box } from "~/components_next/Box";
import {
  SimpleDropdownMenu,
  DropdownMenu,
} from "~/components_next/DropdownMenu";
import { useErrorToast } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { IconButton } from "~/components_next/IconButton";
import { styled } from "~/stitches";
import { usePlaygroundRenderAreaSize } from "../../providers/PlaygroundRenderAreaSizeProvider";
import { PlaygroundControlPanelFlex } from "../PlaygroundControlPanelFlex";
import { useReactFlowSelectedCells } from "../../providers/ReactFlowSelectedCellsProvider";
import { useUseSourcesLoadable } from "../../providers/PlaygroundUseSourceFIeldsLoadableProvider";

const StickyNoteColorElement = styled(Box, {
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  variants: {
    color: {
      amber: {
        backgroundColor: "$amber5",
      },
      crimson: {
        backgroundColor: "$crimson5",
      },
      green: {
        backgroundColor: "$green5",
      },
      blue: {
        backgroundColor: "$blue5",
      },
    },
  },
});

type PlaygroundAddCellControlsProps = {
  useCreateCellExecutable: UseExecutable<
    void,
    CanvasCreateCellClientModel,
    CanvasCellClientModelUnion,
    unknown
  >;
  useDeleteCellExecutable: UseExecutable<
    void,
    { cellId: string },
    unknown,
    unknown
  >;
  cells: CanvasCellClientModelUnion[];
  onAddLiveCell: (cell: CanvasCellClientModelUnion) => void;
  onRemoveLiveCell: (cellId: string) => void;
};

const PlaygroundAddCellControls = (props: PlaygroundAddCellControlsProps) => {
  const {
    useCreateCellExecutable,
    useDeleteCellExecutable,
    cells,
    onRemoveLiveCell,
  } = props;

  /**
   * ReactFlowの座標計算
   */
  /**
   * ここでviewportの座標をずっと監視してるから、ドラッグのたびに再計算される
   */
  const viewPort = useViewport();
  const renderAreaSize = usePlaygroundRenderAreaSize();
  const reactFlow = useReactFlow();

  const centerPosition = useMemo((): {
    x: number;
    y: number;
  } => {
    if (renderAreaSize.width < 0 || renderAreaSize.height < 0)
      return {
        x: viewPort.x,
        y: viewPort.y,
      };

    const projectedCenter = reactFlow.project({
      x: renderAreaSize.width / 2,
      y: renderAreaSize.height / 2,
    });
    return {
      x: projectedCenter.x,
      y: projectedCenter.y,
    };
  }, [viewPort.x, viewPort.y, renderAreaSize, reactFlow]);

  /**
   * React Flow Selection
   */
  const selectedCells = useReactFlowSelectedCells();

  /**
   * Event handlers
   */
  const createCellExecutable = useCreateCellExecutable();
  const handleCreateSourceCell = async (
    tableSlug: string,
    tableName: string
  ) => {
    const createCellInstace = CanvasCreateCellClientModelFactory.create({
      cellType: "source",
      cellName: tableName,
      source: {
        tableSlug: tableSlug,
      },
      parentCells: [],
      settings: {
        x: centerPosition.x,
        y: centerPosition.y,
        width: 300,
        height: 120,
      },
    });
    createCellExecutable.execute(createCellInstace);
  };

  const handleCreateRichTextCell = async () => {
    const createCellInstace = CanvasCreateCellClientModelFactory.create({
      cellType: "richText",
      cellName: "Text Cell",
      source: {
        richText: "<p></p><p></p><p></p>",
      },
      parentCells: [],
      settings: {
        x: centerPosition.x,
        y: centerPosition.y,
        width: 200,
        height: 80,
      },
    });
    createCellExecutable.execute(createCellInstace);
  };

  const handleCreateStickyNote = async (color: string) => {
    const createCellInstace = CanvasCreateCellClientModelFactory.create({
      cellType: "stickyNote",
      cellName: "Sticky Note",
      source: {
        stickyNote: {
          value: "<p></p><p></p>",
          color: color,
        },
      },
      parentCells: [],
      settings: {
        x: centerPosition.x,
        y: centerPosition.y,
        width: 240,
        height: 108,
      },
    });
    createCellExecutable.execute(createCellInstace);
  };

  const { errorToast } = useErrorToast({});

  /**
   * Delete cell
   */
  const deleteCellExecutable = useDeleteCellExecutable();

  const handleRemoveCells = async () => {
    if (selectedCells.length !== 1) {
      errorToast(new Error("Select only one cell to delete"));
      return;
    }
    try {
      await deleteCellExecutable.execute({
        cellId: selectedCells[0].cellId,
      });
      onRemoveLiveCell(selectedCells[0].cellId);
    } catch (e) {
      errorToast(e);
    }
  };

  /**
   * sources
   */
  const useSourcesLoadable = useUseSourcesLoadable();
  const sourcesLoadable = useSourcesLoadable();

  return (
    <Flex align="center" gap="2">
      <PlaygroundControlPanelFlex gap="2" py="2" px="2">
        {/* Source */}
        <SimpleDropdownMenu
          trigger={
            <IconButton size="xl" icon={<BsDatabase />} tooltip="Source" />
          }
        >
          <ScrollArea style={{ maxHeight: "300px" }}>
            {sourcesLoadable.data?.allTables.map((table) => (
              <DropdownMenu.Item
                key={table.tableSlug}
                onClick={() =>
                  handleCreateSourceCell(
                    table.tableSlug,
                    table.displayName || table.tableSlug
                  )
                }
              >
                {table.displayName}
              </DropdownMenu.Item>
            ))}
          </ScrollArea>
        </SimpleDropdownMenu>

        {/* Rich Text */}
        <IconButton
          size="xl"
          icon={<BsType />}
          tooltip="Text"
          onClick={handleCreateRichTextCell}
        />

        {/* Sticky Note */}
        <SimpleDropdownMenu
          trigger={<IconButton size="xl" icon={<BsSticky />} tooltip="Note" />}
        >
          <Flex gap="4">
            <IconButton
              icon={<StickyNoteColorElement color="amber" />}
              tooltip="Amber"
              onClick={() => handleCreateStickyNote("amber")}
            />
            <IconButton
              icon={<StickyNoteColorElement color="crimson" />}
              tooltip="Crimson"
              onClick={() => handleCreateStickyNote("crimson")}
            />
            <IconButton
              icon={<StickyNoteColorElement color="green" />}
              tooltip="Green"
              onClick={() => handleCreateStickyNote("green")}
            />
            <IconButton
              icon={<StickyNoteColorElement color="blue" />}
              tooltip="Blue"
              onClick={() => handleCreateStickyNote("blue")}
            />
          </Flex>
        </SimpleDropdownMenu>
      </PlaygroundControlPanelFlex>
      {/* Remove Cell */}
      {selectedCells.length === 1 && (
        <PlaygroundControlPanelFlex gap="2" py="2" px="2">
          <IconButton
            size="xl"
            icon={<BsTrash />}
            tooltip="Delete Cells"
            onClick={handleRemoveCells}
          />
        </PlaygroundControlPanelFlex>
      )}
    </Flex>
  );
};

const memoized = memo(PlaygroundAddCellControls);

export { memoized as PlaygroundAddCellControls };
