type NotificationDependencies = { teamSlug: string };

type ListNotificationsDependencies = {
  limit?: number;
  skip?: number;
  isArchived?: boolean;
  isRead?: boolean;
} & NotificationDependencies;

const notificationKeys = {
  all: ({ teamSlug }: NotificationDependencies) => [teamSlug, "notification"] as const,

  allListNotifications: ({ teamSlug, limit, skip, isArchived, isRead }: ListNotificationsDependencies) =>
    [
      ...notificationKeys.all({ teamSlug }),
      limit,
      skip,
      isArchived,
      isRead,
      "listNotifications"] as const,
};

export { notificationKeys };
