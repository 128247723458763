import { DashboardTableListResponse } from "@usemorph/morph-dashboard-types";
import { useCallback } from "react";
import { useQuery } from "react-query";
import { useComposeLoadable } from "~/clientModel/loadable";
import { TablesClientModelFactory } from "~/clientModel/tables";
import { useListTablesQuery } from "~/serverStateStore";

export const useSourcesLoadable = (props: {
  teamSlug: string;
  databaseId: string;
}) => {
  const { teamSlug, databaseId } = props;

  return useComposeLoadable(
    useQuery({
      ...useListTablesQuery({
        teamSlug,
        databaseId,
      }),
      select: useCallback(
        (data: DashboardTableListResponse) =>
          TablesClientModelFactory.createFromDashboardTableListResponse(data),
        []
      ),
    })
  );
};

export const createUseSourcesLoadable = (props: {
  teamSlug: string;
  databaseId: string;
}) => {
  return () => useSourcesLoadable(props);
};
