import { useMutation } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import {
  CreatingComputedFieldSyncScheduleClientModel,
  CreatingComputedFieldSyncScheduleClientModelDefactory,
} from "~/clientModel/tables/table/computedFieldSyncSchedule";
import { useErrorToast } from "~/components_next/Error";
import { useCreateNormalizedTableScheduleMutation } from "~/serverStateStore";

type UseCreateSyncScheduleExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useCreateSyncScheduleExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseCreateSyncScheduleExecutableParams): Executable<
  { syncSchedule: CreatingComputedFieldSyncScheduleClientModel },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  const createNormalizedTableScheduleOptions =
    useCreateNormalizedTableScheduleMutation({
      teamSlug,
      databaseId,
    });

  return useComposeExecutable(
    useMutation({
      ...createNormalizedTableScheduleOptions,
      mutationFn: ({
        syncSchedule,
      }: {
        syncSchedule: CreatingComputedFieldSyncScheduleClientModel;
      }) => {
        return createNormalizedTableScheduleOptions.mutationFn({
          requestBody:
            CreatingComputedFieldSyncScheduleClientModelDefactory.toDashboardCreateNormalizedTableScheduleRequestBody(
              {
                creatingComputedFieldSyncSchedule: syncSchedule,
                databaseId,
                tableSlug,
              }
            ),
        });
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseCreateSyncScheduleExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseCreateSyncScheduleExecutableParams) => {
  return () =>
    useCreateSyncScheduleExecutable({
      teamSlug,
      databaseId,
      tableSlug,
    });
};
