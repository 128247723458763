import { match } from "ts-pattern";
import { FieldClientModel } from "~/clientModel/fields/field";
import {
  FieldTypeFormLayout,
  FieldTypeSelectRoot,
  TextFieldsTypeSubSelect,
  SelectFieldsTypeSubSelect,
  NumberFieldsTypeSubSelect,
  OtherPrimitiveFieldsTypeSubSelect,
  DateFieldsTypeSubSelect,
  FileFieldsTypeSubSelect,
  TypeConfigurationWrapper,
  SingleSelectMembersInput,
  MultiSelectMembersInput,
} from "../../components";

type ImportSchemaFieldTypeInputProps = {
  field: FieldClientModel;
  onFieldChange: (field: FieldClientModel) => void;
};

export const ImportSchemaFieldTypeInput = (
  props: ImportSchemaFieldTypeInputProps
) => {
  const { field, onFieldChange } = props;

  const handleTypeChange = (type: FieldClientModel["type"]) => {
    onFieldChange(field.updateType(type));
  };

  return (
    <FieldTypeFormLayout>
      <FieldTypeSelectRoot size="sm" fieldType={field.type}>
        <TextFieldsTypeSubSelect
          onSelect={handleTypeChange}
          options={{ includes: field.type.updatableFieldTypes }}
        />
        <SelectFieldsTypeSubSelect
          onSelect={handleTypeChange}
          options={{ includes: field.type.updatableFieldTypes }}
        />
        <NumberFieldsTypeSubSelect
          onSelect={handleTypeChange}
          options={{ includes: field.type.updatableFieldTypes }}
        />
        <OtherPrimitiveFieldsTypeSubSelect
          onSelect={handleTypeChange}
          options={{ includes: field.type.updatableFieldTypes }}
        />
        <DateFieldsTypeSubSelect
          onSelect={handleTypeChange}
          options={{ includes: field.type.updatableFieldTypes }}
        />
        <FileFieldsTypeSubSelect
          onSelect={handleTypeChange}
          options={{ includes: field.type.updatableFieldTypes }}
        />
      </FieldTypeSelectRoot>
      <TypeConfigurationWrapper>
        {match(field.type)
          .with({ type: "singleSelect" }, (singleSelectType) => (
            <SingleSelectMembersInput
              singleSelectType={singleSelectType}
              onFieldTypeChange={(type) =>
                onFieldChange(field.updateType(type))
              }
            />
          ))
          .with({ type: "multiSelect" }, (multiSelectType) => (
            <MultiSelectMembersInput
              multiSelectType={multiSelectType}
              onFieldTypeChange={(type) =>
                onFieldChange(field.updateType(type))
              }
            />
          ))
          .otherwise(() => null)}
      </TypeConfigurationWrapper>
    </FieldTypeFormLayout>
  );
};
