import { Card, Flex } from "@radix-ui/themes";
import { useMemo } from "react";
import { RecordModel } from "~/features/RecordModel";
import { styled } from "~/stitches";
import { RecordValueDisplay } from "../RecordValue/RecordValueDisplay/RecordValueDisplay";
import { Text } from "~/components_next/Text";

const StyledCard = styled(Card);
const StyledFlex = styled(Flex);

type RecordCardProps = {
  record: RecordModel;
  titleKey: string;
  propertyKeys: string[];
};

const RecordCard = (props: RecordCardProps) => {
  const { record, titleKey, propertyKeys } = props;

  const titleValue = useMemo(() => {
    return String(record[titleKey]?.value);
  }, [record, titleKey]);

  const propertyValues = useMemo(() => {
    return propertyKeys.map((propertyKey) => {
      return record[propertyKey];
    });
  }, [record, propertyKeys]);

  return (
    <StyledCard
      variant="classic"
      css={{
        "&:hover": {
          backgroundColor: "$bg1",
        },
        cursor: "pointer",
        userSelect: "none",
      }}
    >
      <StyledFlex direction="column" gap="2" align="stretch">
        <Text fontWeight="bold">{titleValue}</Text>
        {propertyValues.map((propertyValue, i) => (
          <RecordValueDisplay key={i} recordModelValue={propertyValue} />
        ))}
      </StyledFlex>
    </StyledCard>
  );
};

export { RecordCard, type RecordCardProps };
