import { useMutation } from "react-query";
import useApiFields from "~/api/useApiField";
import { Executable, useComposeExecutable } from "~/clientModel/executable";

import { useErrorToast } from "~/components_next/Error";

type UseGenerateFormulaExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useGenerateFormulaExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseGenerateFormulaExecutableParams): Executable<
  { prompt: string },
  {
    formula: string;
  }
> => {
  const { errorToast } = useErrorToast({});

  const { _generateFormula } = useApiFields();

  return useComposeExecutable(
    useMutation({
      mutationFn: async ({ prompt }: { prompt: string }) => {
        const { formula } = await _generateFormula({
          teamSlug,
          databaseId,
          tableSlug,
          requestBody: {
            message: prompt,
          },
        });
        return { formula };
      },
      onError: (error) => {
        errorToast(error);
      },
    })
  );
};

export const createUseGenerateFormulaExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
}) => {
  return () =>
    useGenerateFormulaExecutable({
      teamSlug,
      databaseId,
      tableSlug,
    });
};
