import { useMutation } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { Loadable } from "~/clientModel/loadable";
import {
  EditingRecordClientModel,
  EditingRecordClientModelDefactory,
} from "~/clientModel/records/editingRecord";
import { useErrorToast } from "~/components_next/Error";
import { useUpdateRecordMutation } from "~/serverStateStore";

type UseUpdateRecordExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  fieldsLoadable: Loadable<FieldsClientModel>;
};

const useUpdateRecordExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
  fieldsLoadable,
}: UseUpdateRecordExecutableParams): Executable<
  { editingRecord: EditingRecordClientModel },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  const updateRecordOptions = useUpdateRecordMutation({
    teamSlug,
    databaseId,
    tableSlug,
  });

  return useComposeExecutable(
    useMutation({
      ...updateRecordOptions,
      mutationFn: ({
        editingRecord,
      }: {
        editingRecord: EditingRecordClientModel;
      }) => {
        if (fieldsLoadable.status !== "success") {
          throw new Error("Something went wrong.");
        }

        const values = EditingRecordClientModelDefactory.toPatchRecordValues(
          editingRecord,
          fieldsLoadable.data
        );

        if (values.length === 0) {
          return Promise.resolve(null);
        }

        const filter =
          EditingRecordClientModelDefactory.toRecordFilterConditionAnd(
            editingRecord,
            fieldsLoadable.data
          );

        return updateRecordOptions.mutationFn({
          values,
          filter,
        });
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseUpdateRecordExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
  fieldsLoadable,
}: UseUpdateRecordExecutableParams) => {
  return () =>
    useUpdateRecordExecutable({
      teamSlug,
      databaseId,
      tableSlug,
      fieldsLoadable,
    });
};
