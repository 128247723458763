import { match, P } from "ts-pattern";

export const copyKeyPred = (event: KeyboardEvent) => {
  return match(event)
    .with(
      {
        code: "KeyC",
        ctrlKey: true,
      },
      () => true
    )
    .with(
      {
        code: "KeyC",
        metaKey: true,
      },
      () => true
    )
    .otherwise(() => false);
};

export const arrowUpEventPred = (event: KeyboardEvent) => {
  return match(event)
    .with(
      {
        code: "ArrowUp",
        shiftKey: P.union(false, P.nullish),
      },
      () => true
    )
    .otherwise(() => false);
};

export const arrowDownEventPred = (event: KeyboardEvent) => {
  return match(event)
    .with(
      {
        code: "ArrowDown",
        shiftKey: P.union(false, P.nullish),
      },
      () => true
    )
    .otherwise(() => false);
};

export const arrowLeftEventPred = (event: KeyboardEvent) => {
  return match(event)
    .with(
      {
        code: "ArrowLeft",
        shiftKey: P.union(false, P.nullish),
      },
      () => true
    )
    .otherwise(() => false);
};

export const arrowRightEventPred = (event: KeyboardEvent) => {
  return match(event)
    .with(
      {
        code: "ArrowRight",
        shiftKey: P.union(false, P.nullish),
      },
      () => true
    )
    .otherwise(() => false);
};

export const shiftArrowUpEventPred = (event: KeyboardEvent) => {
  return match(event)
    .with(
      {
        code: "ArrowUp",
        shiftKey: true,
      },
      () => true
    )
    .otherwise(() => false);
};

export const shiftArrowDownEventPred = (event: KeyboardEvent) => {
  return match(event)
    .with(
      {
        code: "ArrowDown",
        shiftKey: true,
      },
      () => true
    )
    .otherwise(() => false);
};

export const shiftArrowRightEventPred = (event: KeyboardEvent) => {
  return match(event)
    .with(
      {
        code: "ArrowRight",
        shiftKey: true,
      },
      () => true
    )
    .otherwise(() => false);
};

export const shiftArrowLeftEventPred = (event: KeyboardEvent) => {
  return match(event)
    .with(
      {
        code: "ArrowLeft",
        shiftKey: true,
      },
      () => true
    )
    .otherwise(() => false);
};

export const enterEventNotComposingPred = (event: KeyboardEvent) => {
  return match(event)
    .with(
      {
        code: "Enter",
        isComposing: false,
      },
      () => true
    )
    .otherwise(() => false);
};

export const escapeEventPred = (event: KeyboardEvent) => {
  return match(event)
    .with(
      {
        code: "Escape",
      },
      () => true
    )
    .otherwise(() => false);
};
