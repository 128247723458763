import { ReactNode, useEffect, useState } from "react";
import { useTableSlug } from "~/utilHooks/useTableSlug";
import {
  createFieldsOrderFromSimpleFields,
  createGroupKeyFromViewMetaFormState,
  ViewWithQueryConditionsForm,
  ViewWithQueryConditionsFormValueType,
} from "~/features/View";
import { SimpleField } from "@usemorph/morph-dashboard-types";
import { useMutation } from "react-query";
import { useCreateViewMutation } from "~/serverStateStore/views";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useNavigate } from "react-router-dom";
import { convertSimpleFieldsToSelect } from "~/features/View/utils/convertSimpleFieldsToSelect";
import { useErrorToast } from "~/components_next/Error";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { getPath } from "~/routing";
import { useAllSimpleFields } from "~/serverStateStore";
import { Button } from "~/components_next/Button";
import { Dialog } from "~/components_next/Dialog";
import { Box } from "~/components_next/Box";

type CreateViewMenuItemProps = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  popoverAnchor: ReactNode;
  simpleFields: SimpleField[];
};

const CreateViewMenuItem = (props: CreateViewMenuItemProps) => {
  const { isOpen, setIsOpen, popoverAnchor, simpleFields } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const tableSlug = useTableSlug();

  const [formValue, setFormValue] =
    useState<ViewWithQueryConditionsFormValueType>({
      meta: {
        name: "",
        type: "table",
        isPrivate: false,
      },
      join: [],
      selectedFields: simpleFields,
      filter: null,
      sort: null,
    });

  useEffect(() => {
    if (isOpen) {
      setFormValue({
        meta: {
          name: "",
          type: "table",
          isPrivate: false,
        },
        join: [],
        selectedFields: simpleFields,
        filter: null,
        sort: null,
      });
    }
  }, [isOpen]);

  const { data: allFieldsData } = useAllSimpleFields({
    teamSlug,
    databaseId,
  });

  const { mutateAsync: createView, isLoading: isCreating } = useMutation(
    useCreateViewMutation({
      teamSlug,
      databaseId,
    })
  );

  const navigate = useNavigate();
  const { errorToast } = useErrorToast({});

  const handleClickCreate = async () => {
    try {
      const { join, selectedFields, filter, sort, meta } = formValue;

      const { name, type, isPrivate } = meta;
      const { viewId: newViewId } = await createView({
        name,
        type,
        isPrivate,
        condition: {
          from: tableSlug,
          join,
          select: convertSimpleFieldsToSelect(selectedFields, tableSlug),
          filter: filter ?? undefined,
          sort: sort ? [sort] : undefined,
          groupKey: createGroupKeyFromViewMetaFormState(meta, tableSlug),
          reorderable: type === "kanban" ? meta.reorderable : undefined,
        },
        setting: {
          data: {
            hiddenFields: [],
            fieldsOrder: createFieldsOrderFromSimpleFields(
              selectedFields,
              tableSlug
            ),
          },
        },
      });
      setIsOpen(false);
      navigate(
        getPath("view", {
          teamSlug,
          databaseId,
          viewId: newViewId,
        })
      );
    } catch (e) {
      errorToast(e);
    }
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Content>
        <Dialog.Title>Create view</Dialog.Title>
        <Dialog.Body>
          <Box py="4">
            {allFieldsData && (
              <ViewWithQueryConditionsForm
                allFields={allFieldsData.tables}
                mainTableSlug={tableSlug}
                value={formValue}
                onChange={({ formValue }) => setFormValue(formValue)}
              />
            )}
          </Box>
        </Dialog.Body>
        <Dialog.Footer>
          <Button
            variant="primary"
            onClick={handleClickCreate}
            isLoading={isCreating}
          >
            Create
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export { CreateViewMenuItem };
