import { useMutation } from "react-query";
import { useComposeExecutable } from "~/clientModel/executable";
import { PaymentClientModelFactory } from "~/clientModel/payment/PaymentClientModel";
import { RegistTrialMutationProps, useRegistTrialMutation } from "~/serverStateStore";

const useRegistTrialExecutable = (props: { teamSlug: string }) => {
  const { teamSlug } = props

  const registTrialMutationOptions = useRegistTrialMutation({ teamSlug })
  const registTrialMutationResult = useMutation({
    ...registTrialMutationOptions,
    mutationFn: async (params: RegistTrialMutationProps) => {
      const response = await registTrialMutationOptions.mutationFn(params)
      return PaymentClientModelFactory.fromPaymentResponse(response)
    }
  });

  return useComposeExecutable(registTrialMutationResult)

}

export const createUseRegistTrialExecutable = ({ teamSlug }: { teamSlug: string }) => {
  return () => useRegistTrialExecutable({ teamSlug })
}
