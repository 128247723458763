import { ScrollArea } from "@radix-ui/themes";
import GridLayout, { Layout } from "react-grid-layout";
// import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./grid-util.css";
import { styled } from "~/stitches";
import { useMutation, useQuery } from "react-query";
import {
  useFindPageDetailQuery,
  useUpdatePageCellsMutaiton,
  UpdatePageCellsMutateProps,
} from "~/serverStateStore/notebookPage";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useNotebookId } from "~/utilHooks/useNotebookId";
import { useEffect, useMemo, useRef, useState } from "react";
import { DashboardPageCell } from "../DashboardPageCell/DashboardPageCell";
import { Box } from "~/components_next/Box";
import { DashboardPageCellDetailObject } from "@usemorph/morph-dashboard-types";
import { DashboardGridResizeHandle } from "./DashboardGridResizeHandle";
import "./resize-handle.css";
import { DashboardGridItemOptions } from "./DasboardGridItemOptions";
import { useDebounceState } from "~/hooks/useDebounceState";

const DashboardCard = styled(Box, {
  position: "relative",
  borderRadius: "var(--radius-2)",
  padding: "10px",
  backgroundColor: "$bg0",
  boxShadow: "var(--shadow-2)",
  variants: {
    isSelected: {
      true: {
        outline: "solid 2px $blue9",
      },
    },
  },
});

type DashboardGridProps = {
  pageId: string;
};

const filterLayoutDiff = (
  currentLayout: Layout[],
  updatedLayout: Layout[]
): Layout[] => {
  return updatedLayout.filter((layout) => {
    const currentLayoutItem = currentLayout.find((item) => item.i === layout.i);
    if (!currentLayoutItem) return true;
    return (
      currentLayoutItem.x !== layout.x ||
      currentLayoutItem.y !== layout.y ||
      currentLayoutItem.w !== layout.w ||
      currentLayoutItem.h !== layout.h
    );
  });
};

const _DashboardPage = (props: DashboardGridProps & { width: number }) => {
  const { pageId, width } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const notebookId = useNotebookId();

  const { data: pageDetailResponse } = useQuery(
    useFindPageDetailQuery({
      teamSlug,
      databaseId,
      notebookId,
      pageId,
    })
  );

  const layout = useMemo((): Layout[] => {
    if (!pageDetailResponse) return [];
    return pageDetailResponse.cells.map((cell) => {
      return {
        i: cell.cellId,
        x: cell.x,
        y: cell.y,
        w: cell.w,
        h: cell.h,
      };
    });
  }, [pageDetailResponse]);

  /**
   * Selection
   */
  const [selectedCellId, setSelectedCellId] = useState<string | null>(null);

  const handleCellClick = (cellId: string) => {
    if (selectedCellId !== cellId) setSelectedCellId(cellId);
  };

  const cells = useMemo((): {
    cell: DashboardPageCellDetailObject;
    isSelected: boolean;
  }[] => {
    if (!pageDetailResponse) return [];
    return pageDetailResponse.cells.map((cell) => {
      return {
        cell,
        isSelected: cell.cellId === selectedCellId,
      };
    });
  }, [pageDetailResponse, selectedCellId]);

  /**
   * Update Layout
   */
  const { mutateAsync: updatePageCellLayouts } = useMutation({
    ...useUpdatePageCellsMutaiton({ teamSlug, databaseId, notebookId, pageId }),
  });

  const handleLayoutChange = (_layout: Layout[]) => {
    const diff = filterLayoutDiff(layout, _layout);
    const updateProps = diff
      .map((layout) => {
        const findCell = pageDetailResponse?.cells.find(
          (cell) => cell.cellId === layout.i
        );
        if (!findCell) return null;
        return {
          cellId: layout.i,
          notebookCellId: findCell.notebookCell.cellId,
          x: layout.x,
          y: layout.y,
          w: layout.w,
          h: layout.h,
        };
      })
      .filter((r) => r !== null) as UpdatePageCellsMutateProps[];
    updatePageCellLayouts(updateProps);
  };

  if (!pageDetailResponse) return null;

  return (
    <ScrollArea
      style={{ height: "calc(100vh - 80px)", paddingRight: "10px" }}
      onClick={() => setSelectedCellId(null)}
    >
      <GridLayout
        className="layout"
        cols={12}
        rowHeight={60}
        width={width - 10}
        layout={layout}
        onLayoutChange={handleLayoutChange}
        style={{
          marginBottom: "40px",
        }}
        draggableHandle=".page-cell-drag-handle"
        resizeHandles={["ne", "nw", "se", "sw"]}
        resizeHandle={<DashboardGridResizeHandle />}
      >
        {cells.map((cell) => {
          return (
            <DashboardCard
              key={cell.cell.cellId}
              isSelected={cell.isSelected}
              onClick={(e) => {
                e.stopPropagation();
                handleCellClick(cell.cell.cellId);
              }}
              className={`dashboard-cell-card ${
                cell.isSelected ? "selected" : ""
              }`}
            >
              <DashboardPageCell
                notebookCell={cell.cell.notebookCell}
                notebookId={notebookId}
              />
              <DashboardGridItemOptions
                isSelected={cell.isSelected}
                cell={cell.cell}
                pageId={pageId}
              />
            </DashboardCard>
          );
        })}
      </GridLayout>
    </ScrollArea>
  );
};

const KeyedDashboardPage = (props: DashboardGridProps) => {
  const [wrapperWidth, setWrapperWidth] = useState<number>(
    window.innerWidth - 300
  );
  const wrapperRef = useRef<HTMLDivElement>(null);

  const debouncedWidth = useDebounceState(wrapperRef.current?.clientWidth, 250);

  const calcWidth = () => {
    if (wrapperRef.current && wrapperRef.current.clientWidth > 0) {
      setWrapperWidth(wrapperRef.current.clientWidth);
    }
  };

  useEffect(() => {
    calcWidth();
  }, [debouncedWidth]);

  return (
    <Box ref={wrapperRef}>
      <_DashboardPage
        key={`${props.pageId}-${wrapperWidth}`}
        width={wrapperWidth}
        {...props}
      />
    </Box>
  );
};

export { KeyedDashboardPage as DashboardPage };
