import { ReactNode } from "react";
import { BsQuestionCircle } from "react-icons/bs";
import { Popover } from "../Popover";
import { IconButton } from "../IconButton";
import { Text } from "~/components_next/Text";
import { Box } from "@radix-ui/themes";

type HintPopoverProps = {
  title: string;
  children: ReactNode;
};

const HintPopover = (props: HintPopoverProps) => {
  const { title, children } = props;
  return (
    <Popover
      trigger={
        <IconButton
          style={{ borderRadius: "50%" }}
          size="xs"
          tooltip={title}
          icon={<BsQuestionCircle />}
        />
      }
      contentStyle={{ maxWidth: "400px" }}
    >
      <Text variant="subheading">{title}</Text>
      <Box>{children}</Box>
    </Popover>
  );
};

export { HintPopover, type HintPopoverProps };
