import { ComputedFieldSyncScheduleClientModel } from "~/clientModel/tables/table/computedFieldSyncSchedule";
import { Flex } from "~/components_next/Flex";
import { ScheduledTimeInput, TargetDaysInput } from "~/presenters/schedules";
import { EnabledInput } from "./EnabledInput";

type EditComputedFieldsScheduledSyncSettingFormProps = {
  value: ComputedFieldSyncScheduleClientModel;
  onChange: (value: ComputedFieldSyncScheduleClientModel) => void;
};

export const EditComputedFieldsScheduledSyncSettingForm = (
  props: EditComputedFieldsScheduledSyncSettingFormProps
) => {
  const { value, onChange } = props;

  return (
    <Flex direction="column" gap="5" py="5">
      <ScheduledTimeInput
        value={value.scheduledTime}
        onChange={(scheduledTime) =>
          onChange(value.updateScheduledTime(scheduledTime))
        }
      />
      <TargetDaysInput
        value={value.targetDays}
        onChange={(targetDays) => onChange(value.updateTargetDays(targetDays))}
      />
      <EnabledInput
        value={value.isEnabled}
        onChange={(isEnabled) => onChange(value.updateIsEnabled(isEnabled))}
      />
    </Flex>
  );
};
