import { FieldsClientModel } from "~/clientModel/fields";
import { Loadable } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { RecordsClientModel } from "~/clientModel/records";
import { Button } from "~/components_next/Button";
import { Callout } from "~/components_next/Callout";
import { Drawer } from "~/components_next/Drawer";
import { Flex } from "~/components_next/Flex";
import { RecordsTable } from "~/components_next/RecordsTable";

type FormulaPreviewResultDrawerProps = {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  previewResultLoadable: Loadable<{
    records: RecordsClientModel;
    fields: FieldsClientModel;
  }>;
};

export const FormulaPreviewResultDrawer = (
  props: FormulaPreviewResultDrawerProps
) => {
  const { isOpen, onOpenChange, previewResultLoadable } = props;

  return (
    <Drawer.Root open={isOpen} onOpenChange={onOpenChange} size="xl">
      <Drawer.Content>
        <Drawer.Title>Preview for bulk updating</Drawer.Title>
        <Drawer.Body>
          <Flex direction="column" gap="4">
            <Callout
              size="sm"
              type="success"
              description="Your formula is valid."
            />
            <WithFallback loadables={[previewResultLoadable] as const}>
              {([{ records, fields }]) => (
                <RecordsTable
                  records={records}
                  fields={fields}
                  isReadOnly
                  noPadding
                />
              )}
            </WithFallback>
          </Flex>
        </Drawer.Body>
        <Drawer.Footer>
          <Drawer.Close>
            <Button variant="tertiary">Close</Button>
          </Drawer.Close>
        </Drawer.Footer>
      </Drawer.Content>
    </Drawer.Root>
  );
};
