import { resourceMembersKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import { ResourceNotebookSchema, ResourceTableSchema, ResourceViewSchema } from "@usemorph/morph-dashboard-types";
import useApiResourceMembers from "~/api/useApiResourceMember";

interface UseListGroupMembersQueryParams {
  teamSlug: string;
  resource: ResourceTableSchema | ResourceNotebookSchema | ResourceViewSchema;
  limit: number;
  skip: number;
}

const useListResourceMembers = ({
  teamSlug,
  resource,
  limit,
  skip,
}: UseListGroupMembersQueryParams) => {
  const { _listResourceMembers } = useApiResourceMembers();

  const client = useQueryClient();
  client.setQueryDefaults(
    resourceMembersKeys.listResourceMembers({
      teamSlug,
      resource,
      resourceType: resource.resourceType,
      limit,
      skip,
    }),
    commonQueryOptions
  );

  return {
    queryKey: resourceMembersKeys.listResourceMembers({
      teamSlug,
      resource,
      resourceType: resource.resourceType,
      limit,
      skip
    }),
    queryFn: () => {
      return _listResourceMembers({
        teamSlug,
        resource,
        limit,
        skip,
      });
    },
  };
};

export { useListResourceMembers };
