import { useCallback } from "react";
import { ClearSQLButton } from "../../../../../common/components/QueryMode/Buttons/ClearSQLButton";
import { useSetQueryMode } from "../../../states/queryMode";
import {
  useSetQueryingSql,
  useSetSqlInput,
} from "../../../states/sqlConditions";

const TableViewClearSQLButton = () => {
  /**
   * global states from recoil
   */
  const setQueryMode = useSetQueryMode();
  const setSqlInput = useSetSqlInput();
  const setQueryingSql = useSetQueryingSql();

  /**
   * handlers
   */

  const handleClickClearSQLButton = useCallback(() => {
    setQueryMode(null);
    setQueryingSql("");
    setSqlInput("");
  }, [setQueryMode, setQueryingSql, setSqlInput]);

  return <ClearSQLButton onClick={handleClickClearSQLButton} />;
};

export { TableViewClearSQLButton };
