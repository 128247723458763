import { useQueryClient } from "react-query";
import useApiRecord from "~/api/useApiRecord";
import { recordsKeys } from "../../records/keys";

// types
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseRestoreRecordsParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
}

const useRestoreRecordsFromTrashMutation = ({
  databaseId,
  tableSlug,
  teamSlug,
}: UseRestoreRecordsParams) => {
  const { _restoreRecordFromTrash } = useApiRecord();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({ recordTrashIds }: { recordTrashIds: string[] }) => {
      return Promise.all(
        recordTrashIds.map((recordTrashId) => {
          return _restoreRecordFromTrash({
            databaseId,
            teamSlug,
            tableSlug,
            recordTrashId,
          });
        })
      );
    },
    onSuccess: () => {
      const getRecordKey = recordsKeys.allGetSoftDeletedRecord({
        teamSlug,
        databaseId,
        tableSlug,
      })
      const getRecordWithSqlKey = recordsKeys.allGetRecord({
        teamSlug,
        databaseId,
        tableSlug,
      })
      broadcast(getRecordKey);
      broadcast(getRecordWithSqlKey);

      return invalidateAll(
        client,
        getRecordKey,
        getRecordWithSqlKey,
      );
    },
  };
};

export { useRestoreRecordsFromTrashMutation };
