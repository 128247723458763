import {
  SmartFieldDisplaySetting,
  zSmartFieldDateDisplaySetting,
  zSmartFieldNumberDisplaySetting,
} from "@usemorph/morph-dashboard-types";
import { match } from "ts-pattern";
import { zodGuard } from "~/utils/zodGuard";
import { DisplaySettingClientModel } from ".";
import { DateDisplaySettingClientModelFactory } from "./date";
import { NumberDisplaySettingClientModelFactory } from "./numberDisplaySetting";

export class DisplaySettingClientModelFactory {
  public static createFromSmartFieldDisplaySetting(
    smartFieldDisplaySetting: SmartFieldDisplaySetting
  ): DisplaySettingClientModel {
    if (zodGuard(smartFieldDisplaySetting, zSmartFieldNumberDisplaySetting)) {
      return NumberDisplaySettingClientModelFactory.createFromSmartFieldNumberDisplaySetting(
        smartFieldDisplaySetting
      );
    }

    if (zodGuard(smartFieldDisplaySetting, zSmartFieldDateDisplaySetting)) {
      return DateDisplaySettingClientModelFactory.createFromSmartFieldDateDisplaySetting(
        smartFieldDisplaySetting
      );
    }

    const unreachable: never = smartFieldDisplaySetting;
    throw new Error("Something went wrong.");
  }

  public static createEmpty(
    type: "number" | "date"
  ): DisplaySettingClientModel {
    return match(type)
      .with("number", () =>
        NumberDisplaySettingClientModelFactory.createEmpty()
      )
      .with("date", () => DateDisplaySettingClientModelFactory.createEmpty())
      .exhaustive();
  }
}
