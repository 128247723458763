export type BucketPermissions = "public" | "forbidden" | "limitedPublic";

export class BucketClientModelBase {
  private _bucketId: string;
  private _bucketName: string;
  private _readPermission: BucketPermissions;
  private _createPermission: BucketPermissions;
  private _updatePermission: BucketPermissions;

  constructor({
    bucketId,
    bucketName,
    readPermission,
    createPermission,
    updatePermission,
  }: {
    bucketId: string;
    bucketName: string;
    readPermission: BucketPermissions;
    createPermission: BucketPermissions;
    updatePermission: BucketPermissions;
  }) {
    this._bucketId = bucketId;
    this._bucketName = bucketName;
    this._readPermission = readPermission;
    this._createPermission = createPermission;
    this._updatePermission = updatePermission;
  }

  get bucketId(): string {
    return this._bucketId;
  }

  get bucketName(): string {
    return this._bucketName;
  }

  get readPermission(): BucketPermissions {
    return this._readPermission;
  }

  get createPermission(): BucketPermissions {
    return this._createPermission;
  }

  get updatePermission(): BucketPermissions {
    return this._updatePermission;
  }
}
