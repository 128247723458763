import {
  DashboardImportIntegrationStatusListResponse,
  DashboardSimpleImportDataListResponse,
} from "@usemorph/morph-dashboard-types";
import { ImportStatusesClientModel } from "./ImportStatusesClientModel";
import { ImportStatusClientModelFactory } from "./ImportStatusClientModelFactory";

export class ImportStatusesClientModelFactory {
  static fromCsvImportResponse(
    response: DashboardSimpleImportDataListResponse["items"]
  ) {
    return new ImportStatusesClientModel({
      items: response.map((item) =>
        ImportStatusClientModelFactory.fromCsvImportResponse(item)
      ),
    });
  }

  static fromExternalImportResponse(
    response: DashboardImportIntegrationStatusListResponse["items"]
  ) {
    return new ImportStatusesClientModel({
      items: response.map((item) =>
        ImportStatusClientModelFactory.fromExternalImportResponse(item)
      ),
    });
  }

  static fromImportResponses(
    csvImportResponse?: DashboardSimpleImportDataListResponse["items"],
    intergrationImportResponse?: DashboardImportIntegrationStatusListResponse["items"]
  ) {
    return new ImportStatusesClientModel({
      items: [
        ...(csvImportResponse ?? []).map((item) =>
          ImportStatusClientModelFactory.fromCsvImportResponse(item)
        ),
        ...(intergrationImportResponse ?? []).map((item) =>
          ImportStatusClientModelFactory.fromExternalImportResponse(item)
        ),
      ],
    });
  }
}
