import { FieldsClientModel } from "~/clientModel/fields";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { DropdownMenu } from "~/components_next/DropdownMenu";
import { ErrorFallback } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { Spinner } from "~/components_next/Spinner";

type MergeKeyInputUnitProps = {
  tableSlug: string;
  fieldName: string | null;
  onChange: (fieldName: string) => void;
  useFieldsLoadable: UseLoadable<{ tableSlug: string }, FieldsClientModel>;
  isReadOnly?: boolean;
};

export const MergeKeyInputUnit = (props: MergeKeyInputUnitProps) => {
  const { fieldName, tableSlug, useFieldsLoadable, onChange, isReadOnly } =
    props;

  const fieldsLoadable = useFieldsLoadable({
    tableSlug,
  });

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <DropdownMenu.SelectLikeButton size="sm" isDisabled={isReadOnly}>
          <WithFallback
            loadables={[fieldsLoadable] as const}
            loadingFallback={<DropdownContentLoadingFallback />}
            errorFallback={(error) => <ErrorFallback error={error} />}
          >
            {([fields]) =>
              fieldName ? fields.getFieldByFieldName(fieldName)?.label : null
            }
          </WithFallback>
        </DropdownMenu.SelectLikeButton>
      </DropdownMenu.Trigger>
      {!isReadOnly && (
        <DropdownMenu.Content>
          <WithFallback
            loadables={[fieldsLoadable] as const}
            loadingFallback={<DropdownContentLoadingFallback />}
            errorFallback={(error) => <ErrorFallback error={error} />}
          >
            {([fields]) =>
              fields.allFields.map((field) => (
                <DropdownMenu.Item
                  key={field.name}
                  onClick={() => onChange(field.name)}
                >
                  {field.label}
                </DropdownMenu.Item>
              ))
            }
          </WithFallback>
        </DropdownMenu.Content>
      )}
    </DropdownMenu.Root>
  );
};

const DropdownContentLoadingFallback = () => {
  return (
    <Flex
      align="center"
      justify="center"
      style={{ height: "160px", width: "160px" }}
    >
      <Spinner />
    </Flex>
  );
};
