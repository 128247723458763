import {
  RecordConditionRuleUnit,
  RecordFilterCondition,
  RecordFormatCondition,
  RecordSortConditionUnit,
} from "@usemorph/morph-dashboard-types";
import { useQuery } from "react-query";
import { useDownloadRecordsWithQueryQuery } from "../queries/useDownloadRecordsWithQueryQuery";

interface UseDownloadRecordsWithQueryParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
  select: string[];
  filter?: RecordFilterCondition;
  sort?: RecordSortConditionUnit[];
  join?: RecordConditionRuleUnit[];
  format?: RecordFormatCondition[];
  limit?: number;
  skip?: number;
  additionalFilter?: RecordFilterCondition;
  additionalSort?: RecordSortConditionUnit[];
}

const useDownloadRecordsWithQuery = ({
  teamSlug,
  databaseId,
  tableSlug,
  select,
  filter,
  join,
  sort,
  format,
  limit,
  skip,
  additionalFilter,
  additionalSort,
}: UseDownloadRecordsWithQueryParams) => {
  const options = useDownloadRecordsWithQueryQuery({
    teamSlug,
    databaseId,
    tableSlug,
    select,
    filter,
    format,
    sort,
    join,
    limit,
    skip,
    additionalFilter,
    additionalSort,
  });
  return useQuery({
    ...options,
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
  });
};

export { useDownloadRecordsWithQuery };
