import { useRichTextEditorUploadImage } from "../../Context/RichTextEditorRoot";
import { useEditorOrThrow } from "../utils";

const useImage = () => {
  const editor = useEditorOrThrow();

  const uploadImage = useRichTextEditorUploadImage();

  const handleSet = async (files: File[]) => {
    if (!uploadImage) {
      throw new Error("Uploading image is now allowed.");
    }

    if (files.length === 0) {
      return;
    }

    const { url } = await uploadImage(files[0]);

    editor.chain().focus().setImage({ src: url }).run();
  };

  const isActive = editor.isActive("image");

  const isDisabled = !editor.can().chain().focus().setImage({ src: "" }).run();

  return {
    handleSet,
    isActive,
    isDisabled,
  };
};

export { useImage };
