import { UseExecutable } from "~/clientModel/executable";
import { FieldClientModel } from "~/clientModel/fields/field";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { Loadable } from "~/clientModel/loadable";
import { EmbeddingSortConditionUnitClientModel } from "~/clientModel/queryConditions/embeddingSortConditionUnit";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { ErrorFallback } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { Spinner } from "~/components_next/Spinner";
import { Text } from "~/components_next/Text";
import { EmbeddingSortConditionForm } from "~/presenters/fields";
import { WithFallback } from "../../../../../../clientModel/loadable/WithFallback";
import { SimilaritySearchButton } from "../buttons/SimilaritySearchButton";

type SimilaritySearchDropdownProps = {
  fieldsLoadable: Loadable<FieldsClientModel>;
  embeddingSortCondition: EmbeddingSortConditionUnitClientModel | null;
  onEmbeddingSortConditionChange: (
    embeddingSortCondition: EmbeddingSortConditionUnitClientModel | null
  ) => void;
  useActivateEmbeddingExecutable: UseExecutable<
    void,
    { targetField: FieldClientModel },
    unknown,
    unknown
  >;
};

export const SimilaritySearchDropdown = (
  props: SimilaritySearchDropdownProps
) => {
  const {
    fieldsLoadable,
    embeddingSortCondition,
    onEmbeddingSortConditionChange,
    useActivateEmbeddingExecutable,
  } = props;

  return (
    <SimpleDropdownMenu
      trigger={
        <SimilaritySearchButton
          isActive={!!embeddingSortCondition?.validate().isValid}
        />
      }
    >
      <Flex
        gap="6"
        px="4"
        py="3"
        direction="column"
        css={{ minWidth: 500, overflow: "auto" }}
      >
        <WithFallback
          loadables={[fieldsLoadable] as const}
          loadingFallback={<LoadingFallback />}
          errorFallback={(error) => <ErrorFallback error={error} />}
        >
          {([fields]) => (
            <Flex direction="column" gap="2">
              <Text variant="subheading">Similarity Search</Text>
              <EmbeddingSortConditionForm
                fields={fields}
                embeddingSortCondition={embeddingSortCondition}
                onEmbeddingSortConditionChange={onEmbeddingSortConditionChange}
                useActivateEmbeddingExecutable={useActivateEmbeddingExecutable}
                inputDebounceDelay={700}
              />
            </Flex>
          )}
        </WithFallback>
      </Flex>
    </SimpleDropdownMenu>
  );
};

const LoadingFallback = () => {
  return (
    <Flex align="center" justify="center" height="9">
      <Spinner size="md" />
    </Flex>
  );
};
