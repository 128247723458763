import { useCallback } from "react";
import { Text } from "~/components_next/Text";
import { FlatFilterCondition } from "~/features/Fields/FilterCondition/flatFilterConditionTypes";
import { SortConditionForm } from "~/features/Fields/SortCondition/SortConditionForm";
import {
  useFilter,
  useSetFilter,
  useSort,
  useSetSort,
} from "../../../states/queryConditions";
import { validateFilterCondition } from "~/features/Fields/FilterCondition/validateFilterCondition";
import { useQuery } from "react-query";
import { useGetViewFieldsQuery } from "~/serverStateStore/views";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { isArrayWithAtLeastOneElement } from "~/utils/commonTypeGuards";
import { RecordSortConditionUnit } from "@usemorph/morph-dashboard-types";
import { useSetQueryMode } from "../../../states/queryMode";
import { match, P } from "ts-pattern";
import { Box, Flex } from "@radix-ui/themes";
import { Spinner } from "~/components_next/Spinner";
import { TableViewCellFlatFilterConditionForm } from "./TableViewCellFlatFilterConditionForm";
import { useVariables } from "~/features/SourceAndViews/ViewCell/common/states/variables";

type TableViewFilterAndSortFormProps = {
  onClose: () => void;
  viewId: string;
};

const TableViewFilterAndSortForm = (props: TableViewFilterAndSortFormProps) => {
  const { onClose, viewId } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const filter = useFilter();
  const setFilter = useSetFilter();

  const sort = useSort();
  const setSort = useSetSort();

  const setQueryMode = useSetQueryMode();

  const variables = useVariables();

  const { data: viewFieldsData } = useQuery(
    useGetViewFieldsQuery({
      teamSlug,
      databaseId,
      viewId: viewId,
    })
  );

  const handleFilterChange = useCallback(
    (updatedFilter: FlatFilterCondition | null) => {
      match([updatedFilter, sort])
        .with([P.nullish, P.nullish], () => {
          setFilter(null);
          setQueryMode(null);
          onClose();
        })
        .with([P.not(P.nullish), P._], ([updatedFilter]) => {
          if (
            viewFieldsData &&
            validateFilterCondition(updatedFilter, viewFieldsData.fields)
          ) {
            setFilter(updatedFilter);
            setQueryMode("filtersAndSorts");
          }
        })
        .with([P.nullish, P.not(P.nullish)], () => {
          setFilter(null);
        })
        .exhaustive();
    },
    [viewFieldsData, setFilter, sort, onClose, setQueryMode]
  );

  const handleSortChange = useCallback(
    (updatedSort: RecordSortConditionUnit | null) => {
      match([filter, updatedSort])
        .with([P.nullish, P.nullish], () => {
          setSort(null);
          setQueryMode(null);
          onClose();
        })
        .otherwise(() => {
          setQueryMode("filtersAndSorts");
          setSort(updatedSort);
        });
    },
    [setSort, filter, onClose, setQueryMode]
  );

  if (!viewFieldsData || !isArrayWithAtLeastOneElement(viewFieldsData.fields)) {
    return (
      <Flex py="9" align="center" justify="center">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Flex direction="column" p="4" align="start" gap="6" width="100%">
      <Box width="100%">
        <Text variant="subheading" mb="2">
          Filters
        </Text>
        <TableViewCellFlatFilterConditionForm
          simpleFields={viewFieldsData.fields}
          flatFilterCondition={filter}
          variables={variables}
          onChangeFlatFilterCondition={handleFilterChange}
        />
      </Box>
      <Box>
        <Text variant="subheading" mb="2">
          Sorts
        </Text>
        <SortConditionForm
          simpleFields={viewFieldsData.fields}
          sortCondition={sort}
          onChangeSortCondition={handleSortChange}
        />
      </Box>
    </Flex>
  );
};

export { TableViewFilterAndSortForm };
