import { notebookKeys } from "../keys";
import useApiNotebook from "~/api/useApiNotebook";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import { useQueryClient } from "react-query";
import { DashboardNotebookCellVariableRequestObject } from "@usemorph/morph-dashboard-types";

interface UseGetPublicVariableViewResultOptionsQueryParams {
  teamSlug: string;
  apiKey: string;
  cellId: string;
  name: string;
  skip?: number;
  limit?: number;
  variables?: DashboardNotebookCellVariableRequestObject;
}

const useGetPublicVariableViewResultOptionsQuery = ({
  teamSlug,
  apiKey,
  cellId,
  name,
  skip,
  limit,
  variables,
}: UseGetPublicVariableViewResultOptionsQueryParams) => {
  const { _getPublicVariableViewResultOptions } = useApiNotebook();

  const client = useQueryClient();
  client.setQueryDefaults(
    notebookKeys.usage({
      teamSlug,
    }),
    {
      ...commonQueryOptions,
      staleTime: 60 * 1000,
    }
  );

  return {
    queryKey: notebookKeys.publicVariablesViewResultOptions({
      teamSlug,
      apiKey,
      cellId,
      name,
      skip,
      limit,
      variables,
    }),
    queryFn: () => {
      return _getPublicVariableViewResultOptions({
        teamSlug,
        apiKey,
        cellId,
        name,
        skip,
        limit,
        variables,
      });
    },
  };
};

export { useGetPublicVariableViewResultOptionsQuery };
