import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useRecordsKanbanOnUpdateColumnValues } from "../context/RecordsKanbanBaseContext";
import { TbGridDots } from "react-icons/tb";
import { IconButton } from "~/components_next/IconButton";
import { Text } from "~/components_next/Text";
import { Box, Flex } from "@radix-ui/themes";
import { styled } from "~/stitches";

const StyledFlex = styled(Flex, {});
const StyledBox = styled(Box, {});

type RecordsKanbanHeaderProps = {
  value: string;
  width: number;
};

const RecordsKanbanHeader = (props: RecordsKanbanHeaderProps) => {
  const { value, width } = props;

  const onUpdateColumnValues = useRecordsKanbanOnUpdateColumnValues();

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: value });

  const style = {
    transform: CSS.Transform.toString({
      x: transform?.x || 0,
      y: 0,
      scaleX: 1,
      scaleY: 1,
    }),
    transition,
  };

  return (
    <StyledFlex
      ref={setNodeRef}
      css={{
        backgroundColor: `$bg1`,
        cursor: "default",
        borderRadius: "$2",
        width: `${width}px`,
        ...style,
      }}
      {...attributes}
      p="2"
      align="center"
      gap="2"
    >
      {/* hoverTag */}
      {onUpdateColumnValues && (
        <StyledBox {...listeners} css={{ cursor: "grab" }}>
          <IconButton
            size="xs"
            icon={<TbGridDots />}
            tooltip="Drag to reorder"
          />
        </StyledBox>
      )}
      <Text my="2" variant="subheading">
        {value}
      </Text>
    </StyledFlex>
  );
};

export { RecordsKanbanHeader };
