import { BsPlus } from "react-icons/bs";
import { Button } from "~/components_next/Button";
import {
  SimpleDropdownMenu,
  DropdownMenu,
} from "~/components_next/DropdownMenu";
import { Box } from "@radix-ui/themes";

type CreateRecordButtonClickType = "createRecord" | "bulkInsert" | "freeform";

type CreateRecordButtonProps = {
  onClick: (type: CreateRecordButtonClickType) => void;
  isDisabled?: boolean;
  forbiddens?: CreateRecordButtonClickType[];
};

const CreateRecordButton = ({
  onClick,
  isDisabled,
  forbiddens = [],
}: CreateRecordButtonProps) => {
  return (
    <SimpleDropdownMenu
      trigger={
        <Button isDisabled={isDisabled} variant="secondary" size="sm">
          <BsPlus />
          Create Record
        </Button>
      }
    >
      <Box>
        <DropdownMenu.Item onClick={() => onClick("createRecord")}>
          Create a record
        </DropdownMenu.Item>
        {forbiddens.includes("freeform") ? null : (
          <DropdownMenu.Item onClick={() => onClick("freeform")}>
            Create records with freeform
          </DropdownMenu.Item>
        )}
        {forbiddens.includes("bulkInsert") ? null : (
          <DropdownMenu.Item onClick={() => onClick("bulkInsert")}>
            Bulk insert
          </DropdownMenu.Item>
        )}
      </Box>
    </SimpleDropdownMenu>
  );
};

export { CreateRecordButton, type CreateRecordButtonClickType };
