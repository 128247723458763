import { ReactNode } from "react";
import { Flex } from "~/components_next/Flex";

type FieldFormLayoutProps = {
  children: ReactNode;
};

export const FieldFormLayout = (props: FieldFormLayoutProps) => {
  const { children } = props;

  return (
    <Flex direction="column" gap="6">
      {children}
    </Flex>
  );
};

type FieldFormSectionProps = {
  children: ReactNode;
};

export const FieldFormSection = (props: FieldFormSectionProps) => {
  const { children } = props;

  return (
    <Flex direction="column" gap="2">
      {children}
    </Flex>
  );
};
