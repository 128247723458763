import { MultiSelectFieldTypeClientModel } from "~/clientModel/fields/field/fieldType";
import { MembersInput } from "./MembersInput";

type MultiSelectMembersInputProps = {
  multiSelectType: MultiSelectFieldTypeClientModel;
  onFieldTypeChange: (field: MultiSelectFieldTypeClientModel) => void;
  isReadOnly?: boolean;
};

export const MultiSelectMembersInput = (
  props: MultiSelectMembersInputProps
) => {
  const { multiSelectType, onFieldTypeChange, isReadOnly } = props;

  return (
    <>
      <MembersInput
        members={multiSelectType.members}
        onAddMember={(member) =>
          onFieldTypeChange(multiSelectType.addMember(member))
        }
        onDeleteMember={(member) =>
          onFieldTypeChange(multiSelectType.deleteMember(member))
        }
        isReadOnly={isReadOnly}
      />
    </>
  );
};
