import { SimpleTableSchema } from "@usemorph/morph-dashboard-types";
import { useMutation } from "react-query";
import {
  CsvImportSchemaClientModelFactory,
  CsvInsertSchemaEditClientModel,
} from "~/clientModel/csvImport";
import { useComposeExecutable } from "~/clientModel/executable";
import { FieldClientModelDefactory } from "~/clientModel/fields/field";
import { useUpdateSimpleImportDataSchemaMutation } from "~/serverStateStore";

export const useUpdateInsertSchema = ({
  teamSlug,
  databaseId,
  tableSlug,
}: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
}) => {
  const updateImportSchemaOptions = useUpdateSimpleImportDataSchemaMutation({
    teamSlug,
    databaseId,
  });
  const updateImportSchemaMutationResult = useMutation({
    ...updateImportSchemaOptions,
    mutationFn: async ({
      csvImportSchemaInstance,
    }: {
      csvImportSchemaInstance: CsvInsertSchemaEditClientModel;
    }) => {
      const schema: SimpleTableSchema = {
        name: tableSlug,
        datasource: csvImportSchemaInstance.estimatedDataSource,
        fields: csvImportSchemaInstance.mappedFields.map((mappedField) => {
          return {
            ...FieldClientModelDefactory.toSimpleField(mappedField.field),
            colNum: mappedField.colNum,
          };
        }),
      };

      const response = await updateImportSchemaOptions.mutationFn({
        importDataSchemaId: csvImportSchemaInstance.id,
        hasHeader: csvImportSchemaInstance.hasHeader,
        specifiedSchema: schema,
      });

      return CsvImportSchemaClientModelFactory.fromImportDataResponse(response);
    },
  });
  const updateImportSchemaExecutable = useComposeExecutable(
    updateImportSchemaMutationResult
  );

  return {
    updateImportSchemaExecutable,
  };
};
