import { FieldsClientModel } from "~/clientModel/fields";
import { fromLoadables, Loadable } from "~/clientModel/loadable";
import {
  TableColumnSizingClientModel,
  TableColumnSizingClientModelFactory,
} from "~/clientModel/tables/tableMeta/TableColumnSizing";
import { TypedStorage } from "~/clientStateStore/typedLocalStorage/storage";

import { z } from "zod";
import { dynamicStorageItem } from "~/clientStateStore/typedLocalStorage";
import { useCallback } from "react";
import { ColumnSizingDict } from "~/clientModel/tables/tableMeta/TableColumnSizing/TableColumnSizingClientModel";
import { useUpdate } from "react-use";

const typedStorage = new TypedStorage(localStorage);

const serializer = (value: ColumnSizingDict): string => {
  return JSON.stringify(value);
};

const deserializer = (value: string): ColumnSizingDict => {
  const result = z
    .record(
      z.string(),
      z.number().min(TableColumnSizingClientModel.minColumnWidth)
    )
    .safeParse(JSON.parse(value));

  if (!result.success) {
    throw new Error("Failed to parse column sizing dict");
  }

  return result.data;
};

const sourceTableColumnSizingItem = dynamicStorageItem<
  ColumnSizingDict,
  { tableSlug: string }
>({
  key: "source-table-column-sizing",
  version: 1,
  serializer: serializer,
  deserializer: deserializer,
});

type useFieldsLoadableParams = {
  tableSlug: string;
  fieldsLoadable: Loadable<FieldsClientModel>;
};

export const useSourceTableColumnSizingLoadable = ({
  tableSlug,
  fieldsLoadable,
}: useFieldsLoadableParams): [
  Loadable<TableColumnSizingClientModel>,
  (TableColumnSizingClientModel: TableColumnSizingClientModel) => void
] => {
  const selector = useCallback(
    (fields: FieldsClientModel): TableColumnSizingClientModel => {
      const columnSizingDict = typedStorage.get(
        sourceTableColumnSizingItem({ tableSlug })
      );
      return columnSizingDict
        ? TableColumnSizingClientModelFactory.create({
            columnSizingDict,
            fields,
          })
        : TableColumnSizingClientModelFactory.createEmpty({ fields });
    },
    [tableSlug]
  );

  const triggerRerender = useUpdate();

  const columnSizingLoadable = fromLoadables([fieldsLoadable], selector);

  const setColumnSizing = useCallback(
    (columnSizing: TableColumnSizingClientModel) => {
      typedStorage.set(
        sourceTableColumnSizingItem({ tableSlug }),
        columnSizing.data.columnSizingDict
      );
      triggerRerender();
    },
    [tableSlug, triggerRerender]
  );

  return [columnSizingLoadable, setColumnSizing];
};
