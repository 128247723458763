import {
  useRecordsTableBaseCellSelection,
  useRecordsTableBaseEditingCell,
  useRecordsTableBaseEndCellEditing,
  useRecordsTableBaseFields,
  useRecordsTableBaseOnCellSelectionChange,
  useRecordsTableBaseOnUpdateRecord,
} from "../context/ReactTableBaseContext";
import { useEffect, useMemo, useRef, useState } from "react";
import { RecordValueInputRefType, RecordValueInput } from "../../RecordValue";
import { EditingCellState } from "../types";
import { AllRecordModelValueUnion, RecordModel } from "~/features/RecordModel";
import { match } from "ts-pattern";
import { useEditingCellPosition } from "./useEditingCellPosition";
import { Box } from "@radix-ui/themes";
import { styled } from "~/stitches";
import { useClickAway } from "react-use";
import { SimpleFieldCategoryType } from "@usemorph/morph-dashboard-types";

const StyledBox = styled(Box, {});

const RecordsTableBaseEditingCell = () => {
  const fields = useRecordsTableBaseFields();
  // states
  const editingCell = useRecordsTableBaseEditingCell();
  const endCellEditing = useRecordsTableBaseEndCellEditing();
  const onUpdateRecord = useRecordsTableBaseOnUpdateRecord();
  const cellSelection = useRecordsTableBaseCellSelection();
  const onCellSelectionChange = useRecordsTableBaseOnCellSelectionChange();

  const { editingCellPosition } = useEditingCellPosition();

  const field = useMemo(() => {
    if (!editingCell) {
      return undefined;
    }

    return fields.find((field) => field.name === editingCell.fieldSlug);
  }, [editingCell, fields]);

  // editing value
  const [editingState, setEditingState] = useState<{
    editingRecordModelValue: AllRecordModelValueUnion;
    editingCellBeforeEdit: EditingCellState;
  } | null>(null);

  const handleBlur = () => {
    if (!editingState || !onUpdateRecord) {
      setEditingState(null);
      return;
    }

    const { editingCellBeforeEdit, editingRecordModelValue } = editingState;

    const { record, fieldSlug } = editingCellBeforeEdit;

    const currentValue = editingRecordModelValue.value;
    const valueBeforeEdit = record.values[fieldSlug].value;
    // if the value has changed, update the record
    if (currentValue !== valueBeforeEdit) {
      onUpdateRecord({
        _reservedRecordIndex: record._reservedRecordIndex,
        values: { ...record.values, [fieldSlug]: editingRecordModelValue },
      });

      // cell blur → cell focus時に前のcell selectionが残らないように、cell selectionを更新する
      // cell編集時は、cellSelectionはlength=1
      const {
        fieldSlugs,
        record: { _reservedRecordIndex, values: cellSelectionValues },
      } = cellSelection[0];
      const updatedCellSelection = [
        {
          fieldSlugs,
          record: {
            _reservedRecordIndex,
            values: Object.entries(cellSelectionValues).reduce(
              (recordModel, [key, recordModelValue]) => {
                return key === fieldSlug
                  ? {
                      ...recordModel,
                      [key]: editingRecordModelValue,
                    }
                  : {
                      ...recordModel,
                      [key]: recordModelValue,
                    };
              },
              {} as RecordModel
            ),
          },
        },
      ];
      onCellSelectionChange(updatedCellSelection);
    }
    setEditingState(null);
  };

  const onCellValueChange = (recordModelValue: AllRecordModelValueUnion) => {
    setEditingState((prev) => {
      if (!prev) {
        return null;
      }

      return {
        ...prev,
        editingRecordModelValue: recordModelValue,
      };
    });
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useClickAway(wrapperRef, () => {
    // 応急処置
    // select系など外をクリックした判定になるものだと編集する前にセルが閉じてしまうので、
    // endCellEditingの対象外にしている
    const targetField = fields.find(
      ({ name }) => name === editingCell?.fieldSlug
    );
    const ignoreFieldTypes: SimpleFieldCategoryType[] = [
      "singleSelect",
      "boolean",
      "json",
      "array",
      "html",
    ];
    if (targetField && ignoreFieldTypes.includes(targetField.type)) {
      return;
    }
    endCellEditing();
  });

  const editingCellRef = useRef<RecordValueInputRefType>(null);

  useEffect(() => {
    if (!editingCell) {
      handleBlur();
    } else {
      setEditingState({
        editingCellBeforeEdit: editingCell,
        editingRecordModelValue:
          editingCell.record.values[editingCell.fieldSlug],
      });
    }
  }, [editingCell]);

  useEffect(() => {
    editingCellRef.current?.focus();
  }, [editingState]);

  const cellAttributes = useMemo(() => {
    return match(field?.type)
      .with("longText", () => ({
        width: "400px",
      }))
      .with("image", () => ({
        width: "200px",
        padding: "4px",
      }))
      .otherwise(() => ({
        width: "240px",
      }));
  }, [field]);

  /**
   * Rendering
   */
  if (!editingCellPosition || !field) {
    return null;
  }

  return (
    <StyledBox
      ref={wrapperRef}
      css={{
        left: `${editingCellPosition.x}px`,
        top: `${editingCellPosition.y}px`,
        backgroundColor: "$bg0",
        position: "absolute",
        boxShadow: "$lg",
        ...cellAttributes,
      }}
    >
      {editingState && (
        <RecordValueInput
          ref={editingCellRef}
          field={field}
          recordModelValue={editingState.editingRecordModelValue}
          errorMessages={[]}
          size="md"
          onChange={onCellValueChange}
        />
      )}
    </StyledBox>
  );
};

export { RecordsTableBaseEditingCell };
