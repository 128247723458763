import { SimpleField } from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";
import {
  DisplaySettingClientModel,
  DisplaySettingClientModelFactory,
} from "./displaySetting";
import { EmbeddingClientModelFactory } from "./embedding";
import { FieldClientModel } from "./FieldClientModel";
import { FieldTypeClientModelFactory } from "./fieldType";
import { MergeKeyClientModelFactory } from "./mergeKey";

const getInitialDisplaySetting = (
  type: FieldClientModel["type"]["type"]
): DisplaySettingClientModel | null => {
  return match(type)
    .with(
      P.union(
        "number",
        "bigNumber",
        "decimal",
        "autoNumber",
        "autoBigNumber",
        "calculation",
        "aggregateValue"
      ),
      () => DisplaySettingClientModelFactory.createEmpty("number")
    )
    .with(
      P.union("date", "datetime", "time", "createdAt", "lastEditedAt"),
      () => DisplaySettingClientModelFactory.createEmpty("date")
    )
    .otherwise(() => null);
};

export class FieldClientModelFactory {
  public static createFromSimpleField(
    simpleField: SimpleField,
    supplements?: {
      allSimpleFields?: SimpleField[]; // embedding fieldがある場合にのみ必要
    }
  ): FieldClientModel {
    const embeddingField =
      supplements?.allSimpleFields &&
      supplements.allSimpleFields.find(
        (f) => f.type === "embeddings" && f.embeddings === simpleField.name
      );

    const type = FieldTypeClientModelFactory.createFromSimpleField(simpleField);

    const displaySetting = simpleField.displaySetting
      ? DisplaySettingClientModelFactory.createFromSmartFieldDisplaySetting(
          simpleField.displaySetting
        )
      : getInitialDisplaySetting(type.type);

    return new FieldClientModel({
      name: simpleField.name,
      displayName: simpleField.displayName ?? null,
      description: simpleField.comment ?? null,
      originalTableSlug: simpleField.originalTableSlug,
      type,
      isHidden: simpleField.isHidden ?? false,
      isPrimary: simpleField.primary ?? false,
      isNullable: simpleField.nullable ?? false,
      embedding: embeddingField
        ? EmbeddingClientModelFactory.createFromSimpleField(embeddingField)
        : null,
      mergeKey: simpleField.mergeSource
        ? MergeKeyClientModelFactory.createFromSimpleFieldMergeSource({
            mergeSource: simpleField.mergeSource,
          })
        : null,
      displaySetting,
      isUnique: !!simpleField.mergeSource && simpleField.mergeSource.length > 0,
    });
  }

  public static createEmpty(
    type: FieldClientModel["type"]["type"]
  ): FieldClientModel {
    return new FieldClientModel({
      name: "",
      displayName: null,
      description: null,
      type: FieldTypeClientModelFactory.createEmpty(type),
      isHidden: false,
      isPrimary: false,
      isNullable: false,
      embedding: null,
      mergeKey: null,
      displaySetting: getInitialDisplaySetting(type),
      isUnique: false,
    });
  }
}
