import {
  RecordFilterConditionAndType,
  RecordFilterConditionOrType,
  RecordFilterConditionUnit,
} from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";
import { FieldsClientModel } from "~/clientModel/fields";
import { FilterConditionsClientModel } from "./FilterConditionsClientModel";
import { FilterConditionUnitClientModel } from "./FilterConditionUnit/FilterConditionUnitClientModel";
import { FilterConditionUnitClientModelFactory } from "./FilterConditionUnit/FilterConditionUnitClientModelFactory";

export class FilterConditionsClientModelFactory {
  public static createEmptyFilterConditions(): FilterConditionsClientModel {
    return new FilterConditionsClientModel({
      conditionItems: [],
      logicalOperator: "and",
    });
  }

  public static fromRecordFilterCondition(
    condition:
      | RecordFilterConditionAndType
      | RecordFilterConditionOrType
      | RecordFilterConditionUnit,
    fields: FieldsClientModel
  ): FilterConditionsClientModel | FilterConditionUnitClientModel {
    try {
      return match(condition)
        .with(
          P.union({ and: P.not(undefined) }, { or: P.not(undefined) }),
          (andOrFilter) => {
            return FilterConditionsClientModelFactory.fromRecordFilterConditionAndOr(
              andOrFilter,
              fields
            );
          }
        )
        .with({ key: P.string }, (unitFilter) => {
          return FilterConditionUnitClientModelFactory.fromRecordConditionUnit(
            unitFilter,
            fields
          );
        })
        .exhaustive();
    } catch {
      console.error("Failed to parse filter conditions");
      return FilterConditionsClientModelFactory.createEmptyFilterConditions();
    }
  }

  /**
   * RecordFilterConditionAndType | RecordFilterConditionOrType | undefined のとき
   * DashboardViewConditionObject['filter'] がこれにあたる
   */
  public static fromRecordFilterConditionAndOr(
    filter:
      | RecordFilterConditionAndType
      | RecordFilterConditionOrType
      | undefined,
    fields: FieldsClientModel
  ): FilterConditionsClientModel {
    try {
      return match(filter)
        .with({ and: P.not(undefined) }, (andFilter) => {
          return new FilterConditionsClientModel({
            conditionItems: andFilter.and.map(
              (
                filter:
                  | RecordFilterConditionAndType
                  | RecordFilterConditionOrType
                  | RecordFilterConditionUnit
              ) => {
                return FilterConditionsClientModelFactory.fromRecordFilterCondition(
                  filter,
                  fields
                );
              }
            ),
            logicalOperator: "and",
          });
        })
        .with({ or: P.not(undefined) }, (orFilter) => {
          return new FilterConditionsClientModel({
            conditionItems: orFilter.or.map(
              (
                filter:
                  | RecordFilterConditionAndType
                  | RecordFilterConditionOrType
                  | RecordFilterConditionUnit
              ) => {
                return FilterConditionsClientModelFactory.fromRecordFilterCondition(
                  filter,
                  fields
                );
              }
            ),
            logicalOperator: "or",
          });
        })
        .with(P.nullish, () => {
          return FilterConditionsClientModelFactory.createEmptyFilterConditions();
        })
        .exhaustive();
    } catch {
      console.error("Failed to parse filter conditions");
      return FilterConditionsClientModelFactory.createEmptyFilterConditions();
    }
  }
}
