import { useMemo, useState } from "react";

import { match, P } from "ts-pattern";
import { RecordClientModel } from "~/clientModel/records/record";
import { useCoworkerSelectionUtil } from "../common/useCoworkerSelectionUtil";

const useTableCellBackgroundStyles = ({
  record,
}: {
  record: RecordClientModel;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const { getRecordColorSchemeLockedByCoworker } = useCoworkerSelectionUtil();

  const backgroundColor = useMemo(() => {
    // todo
    const isFieldSelected = false;
    const isRecordHilighted = false;

    return match([
      isRecordHilighted,
      isFieldSelected,
      isHovered,
      getRecordColorSchemeLockedByCoworker({
        recordIdentifier: record.recordIdentifier,
      }),
    ])
      .with(
        [P._, P._, P._, P.string],
        ([_, __, ___, colorScheme]) => `$${colorScheme}2`
      )
      .with([true, P._, P._, P._], () => "$bg1")
      .with([P._, true, true, P._], () => "$bg2")
      .with([P._, true, false, P._], () => "$bg2")
      .with([P._, false, true, P._], () => "$bg2")
      .otherwise(() => "transparent");
  }, [getRecordColorSchemeLockedByCoworker, isHovered, record]);

  return { backgroundColor, onHoverChange: setIsHovered };
};

export { useTableCellBackgroundStyles };
