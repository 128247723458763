import { notebookKeys } from "../../notebook/keys";
import { useQueryClient } from "react-query";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import useApiPage from "~/api/useApiPage";
import { pageKeys } from "../keys";

interface UseRemovePageCellMutationParams {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
  pageId: string;
}

const useRemovePageCellMutaiton = ({
  databaseId,
  teamSlug,
  notebookId,
  pageId,
}: UseRemovePageCellMutationParams) => {
  const { _removePageCell } = useApiPage();

  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({ cellId }: { cellId: string }) => {
      return _removePageCell({
        teamSlug,
        pageId,
        cellId,
      });
    },
    onSuccess: () => {
      const keys = notebookKeys.findNotebook({
        databaseId,
        teamSlug,
        notebookId,
      });

      const _pageKeys = pageKeys.findPageDetail({
        databaseId,
        teamSlug,
        notebookId,
        pageId,
      });

      broadcast(keys);

      return invalidateAll(client, keys, _pageKeys);
    },
  };
};

export { useRemovePageCellMutaiton };
