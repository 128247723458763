import { useQueryClient } from "react-query";
import useApiCanvasStyle from "~/api/useApiCanvasStyle";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { canvasStyleKeys } from "../keys";

const useDeleteMyColorMutation = ({ teamSlug }: { teamSlug: string }) => {
  const { _deleteMyColors } = useApiCanvasStyle();

  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({ colorCodeId }: { colorCodeId: string }) => {
      return _deleteMyColors({
        colorCodeId,
        teamSlug,
      });
    },
    onSuccess: () => {
      const keys = canvasStyleKeys.myColors({ teamSlug });

      console.log(keys);

      broadcast(keys);

      return invalidateAll(client, keys);
    },
  };
};

export { useDeleteMyColorMutation };
