import { ReactNode } from "react";
import { DropdownMenu as RadixThemeDropdownMenu } from "@radix-ui/themes";

type RadixDropdownMenuContentProps = React.ComponentPropsWithoutRef<
  typeof RadixThemeDropdownMenu.Content
>;

type ControlledDropdownProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  trigger: ReactNode;
  children: ReactNode;
  variant?: RadixDropdownMenuContentProps["variant"];
  size?: "md" | "sm";
};

const ControlledDropdown = (props: ControlledDropdownProps) => {
  const {
    trigger,
    children,
    variant = "soft",
    size = "md",
    isOpen,
    onOpenChange,
  } = props;

  const contentSize = size === "md" ? "2" : "1";

  return (
    <RadixThemeDropdownMenu.Root open={isOpen} onOpenChange={onOpenChange}>
      <RadixThemeDropdownMenu.Trigger>{trigger}</RadixThemeDropdownMenu.Trigger>
      <RadixThemeDropdownMenu.Content
        color="gray"
        highContrast
        variant={variant}
        size={contentSize}
      >
        {children}
      </RadixThemeDropdownMenu.Content>
    </RadixThemeDropdownMenu.Root>
  );
};

export { ControlledDropdown, type ControlledDropdownProps };
