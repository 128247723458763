import { SimpleField } from "@usemorph/morph-dashboard-types";
import { useMutation } from "react-query";
import { Drawer } from "~/components_next/Drawer";
import { useErrorToast } from "~/components_next/Error";
import { RecordModel } from "~/features/RecordModel";
import { convertRecordModelToValuesForRequest } from "~/features/RecordModel/utils/convertRecordModelToValuesForRequest";
import { useRecordModelFormatValidation } from "~/features/RecordModel/utils/useRecordModelValidation";
import { createPrimaryKeyObjectForOneRecord } from "~/features/Records";
import { ViewRecordForm } from "~/features/Records/ViewRecordForm";
import { DeleteAndSaveSidebarFooter } from "~/features/SourceAndViews/common/components/atoms/DeleteAndSaveSidebarFooter";
import { useReactiveState } from "~/hooks/useReactiveState";
import {
  useUpdateViewRecordMutation,
  useDeleteViewRecordMutation,
} from "~/serverStateStore/views";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

type TableComponentRecordDrawerBodyProps = {
  viewId: string;
  record: RecordModel;
  fields: SimpleField[];
  updateRules: Record<string, boolean>;
  deleteRule: boolean;
  onClose: () => void;
};

const TableComponentRecordDrawerBody = (
  props: TableComponentRecordDrawerBodyProps
) => {
  const { record, fields, updateRules, deleteRule, onClose, viewId } = props;
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const [editingRecord, setEditingRecord] =
    useReactiveState<RecordModel>(record);

  const { isAllValid, validationErrorsDict } = useRecordModelFormatValidation({
    recordModel: editingRecord,
    simpleFields: fields,
  });

  const handleSetRecord = (record: RecordModel) => {
    setEditingRecord(record);
  };

  /**
   * handlers
   */

  const { errorToast } = useErrorToast({});

  const { mutateAsync: updateRecord, isLoading: isSaving } = useMutation(
    useUpdateViewRecordMutation({ teamSlug, databaseId, viewId })
  );

  const handleSaveRecord = async () => {
    if (isAllValid) {
      try {
        await updateRecord({
          values: convertRecordModelToValuesForRequest({
            recordModelAfterEdit: editingRecord,
          }),
          filterForRequest: createPrimaryKeyObjectForOneRecord(
            fields,
            editingRecord
          ),
        });
        onClose();
      } catch (e) {
        errorToast(e);
      }
    }
  };

  const { mutateAsync: deleteRecord, isLoading: isDeleting } = useMutation(
    useDeleteViewRecordMutation({ teamSlug, databaseId, viewId })
  );

  const handleDelete = async () => {
    try {
      await deleteRecord({
        filterForRequest: createPrimaryKeyObjectForOneRecord(
          fields,
          editingRecord
        ),
      });
      onClose();
    } catch (e) {
      errorToast(e);
    }
  };

  return (
    <>
      <Drawer.Body>
        <ViewRecordForm
          simpleViewFields={fields}
          recordModel={editingRecord}
          setRecordModel={handleSetRecord}
          validationErrorsDict={validationErrorsDict}
          displayedFieldCategories="all"
          updateRules={updateRules}
        ></ViewRecordForm>
      </Drawer.Body>
      <Drawer.Footer>
        <DeleteAndSaveSidebarFooter
          isSaveDisabled={!isAllValid}
          onClickSaveRecordButton={handleSaveRecord}
          onClickDeleteRecordButton={handleDelete}
          isSaving={isSaving}
          isDeleting={isDeleting}
          canDelete={deleteRule}
          canUpdate={Object.entries(updateRules).some(([_, value]) => value)}
        />
      </Drawer.Footer>
    </>
  );
};

type TableComponentRecordDrawerProps = Omit<
  TableComponentRecordDrawerBodyProps,
  "record"
> & {
  record: RecordModel | null;
  onClose: () => void;
};

const TableComponentRecordDrawer = (props: TableComponentRecordDrawerProps) => {
  const { record, onClose, fields, updateRules, deleteRule, viewId } = props;

  return (
    <Drawer.Root open={record !== null} onOpenChange={onClose}>
      <Drawer.Content>
        <Drawer.Title>Record Detail</Drawer.Title>
        {record && (
          <TableComponentRecordDrawerBody
            record={record}
            fields={fields}
            updateRules={updateRules}
            onClose={onClose}
            deleteRule={deleteRule}
            viewId={viewId}
          ></TableComponentRecordDrawerBody>
        )}
      </Drawer.Content>
    </Drawer.Root>
  );
};

export { TableComponentRecordDrawer };
