import { executeDataApiKeys } from "../keys";

// types
import { QueryRecordRequestBody } from "@usemorph/morph-dashboard-types";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiExecuteDataApi from "~/api/useApiExecuteDataApi";

interface UseExecuteQueryDataApiParams {
  apiKey: string;
  databaseId: string;
  teamSlug: string;
  tableSlug: string;
  body: QueryRecordRequestBody;
}

const useExecuteQueryDataApi = ({
  apiKey,
  body,
  teamSlug,
  databaseId,
  tableSlug,
}: UseExecuteQueryDataApiParams) => {
  const { _executeQueryDataApi } = useApiExecuteDataApi();

  const client = useQueryClient();
  client.setQueryDefaults(
    executeDataApiKeys.all({ databaseId, teamSlug, tableSlug }),
    commonQueryOptions
  );

  return {
    queryKey: executeDataApiKeys.execute({
      databaseId,
      teamSlug,
      tableSlug,
      apiKey,
      body,
    }),
    queryFn: () => {
      return _executeQueryDataApi({
        apiKey,
        requestBody: body,
        teamSlug,
        databaseId,
        tableSlug,
      });
    },
  };
};

export { useExecuteQueryDataApi };
