import { match, P } from "ts-pattern";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { FilterConditionUnitClientModel } from "~/clientModel/queryConditions/filterConditions";
import { FilterOperationClientModel } from "~/clientModel/queryConditions/filterConditions/FilterConditionUnit/operations";
import { Grid } from "~/components_next/Grid";
import { KeyInput } from "./KeyInput";
import { OperandInput } from "./OperandInput";
import { OperatorInput } from "./OperatorInput";
import { RemoveButton } from "./RemoveButton";
import { OperandArrayInput } from "./OperandArrayInput";
import { CanvasVariablesValue } from "~/presenters/canvas/common/CanvasVariablesProvider";

type FilterConditionUnitFormProps = {
  filterConditionUnit: FilterConditionUnitClientModel;
  onFilterConditionUnitChange: (
    filterConditionUnit: FilterConditionUnitClientModel
  ) => void;
  onFilterConditionUnitRemove: () => void;
  isReadOnly: boolean;
  fields: FieldsClientModel;
  variables?: CanvasVariablesValue[];
};

export const FilterConditionUnitForm = (
  props: FilterConditionUnitFormProps
) => {
  const {
    filterConditionUnit,
    onFilterConditionUnitChange,
    onFilterConditionUnitRemove,
    fields,
    isReadOnly,
    variables,
  } = props;

  const handleOperationChange = (operation: FilterOperationClientModel) => {
    onFilterConditionUnitChange(filterConditionUnit.updateOperation(operation));
  };

  return (
    <Grid
      columns="4"
      rows="1"
      gap="1"
      style={{
        gridTemplateColumns: "1fr 1fr 1fr auto",
        alignItems: "start",
      }}
    >
      {/* 対象fieldの入力 */}
      <KeyInput
        filterConditionUnit={filterConditionUnit}
        fields={fields}
        onFilterConditionUnitChange={onFilterConditionUnitChange}
        isReadOnly={isReadOnly}
      />

      {/* オペレーターの入力 */}
      <OperatorInput
        filterConditionUnit={filterConditionUnit}
        onOperationChange={handleOperationChange}
        isReadOnly={isReadOnly}
      />

      {/* オペランドの入力 */}
      {match(filterConditionUnit.operation)
        .with(
          {
            type: P.union(
              "equal",
              "notEqual",
              "lessThan",
              "lessThanOrEqual",
              "greaterThan",
              "greaterThanOrEqual",
              "like",
              "startsWith",
              "endsWith"
            ),
          },
          (operation) => (
            <OperandInput
              operation={operation}
              onOperationChange={handleOperationChange}
              isReadOnly={isReadOnly}
              errorMessages={filterConditionUnit.validate().errorMessages}
              variables={variables}
            />
          )
        )
        .with(
          {
            type: P.union("in", "notIn"),
          },
          (operation) => (
            <OperandArrayInput
              operation={operation}
              onOperationChange={handleOperationChange}
              isReadOnly={isReadOnly}
              errorMessages={filterConditionUnit.validate().errorMessages}
            />
          )
        )
        .otherwise(() => (
          <div />
        ))}

      {/* 削除 */}
      {!isReadOnly && <RemoveButton onClick={onFilterConditionUnitRemove} />}
    </Grid>
  );
};
