import { getColor, getSizePx } from "./constants";
import { match } from "ts-pattern";
import { TbGridDots } from "react-icons/tb";
import { BsX, BsPlus } from "react-icons/bs";
import { IconCommonProps } from "../type";

type ActionIconType = "draggable" | "close" | "add";

type ActionIconProps = { type: ActionIconType } & IconCommonProps;

const ActionIcon = (props: ActionIconProps) => {
  const { type, size } = props;
  const iconProps = { size: getSizePx(size), color: getColor("gray") };
  return match(type)
    .with("draggable", () => <TbGridDots {...iconProps} />)
    .with("close", () => <BsX {...iconProps} />)
    .with("add", () => <BsPlus {...iconProps} />)
    .exhaustive();
};

export { ActionIcon };
