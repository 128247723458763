import {
  DashboardWidgetDataRegistApiListResponse,
  DashboardWidgetDataRegistApiResponse,
  WidgetDataAggregateRecordRequestBody,
  WidgetDataCreateRecordRequestBody,
  WidgetDataDeleteRecordRequestBody,
  WidgetDataQueryRecordRequestBody,
  WidgetDataUpdateRecordRequestBody,
  WidgetDataUploadFileRequestBody,
  ChatReplyRequestObject,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

export type RegistDataApiReuqestParams = {
  databaseId: string;
  teamSlug: string;
  tableSlug: string;
  apiName: string;
  apiType: string;
  apiKey?: string;
  query?: WidgetDataQueryRecordRequestBody;
  create?: WidgetDataCreateRecordRequestBody;
  update?: WidgetDataUpdateRecordRequestBody;
  _delete?: WidgetDataDeleteRecordRequestBody;
  upload?: WidgetDataUploadFileRequestBody;
  download?: WidgetDataQueryRecordRequestBody;
  aggregate?: WidgetDataAggregateRecordRequestBody;
  chat?: ChatReplyRequestObject;
  isDynamicCondition?: boolean;
  acl?: "admin" | "morphAuth";
  isIndependent?: boolean;
};

export default function useApiWidgetData() {
  const { executeRequest } = useApi();

  const _listWidgetData = async ({
    databaseId,
    teamSlug,
    tableSlug,
    widgetId,
    limit = 30,
    skip = 0,
  }: {
    databaseId: string;
    teamSlug: string;
    tableSlug?: string;
    widgetId?: string;
    limit?: number;
    skip?: number;
  }) => {
    return executeRequest<DashboardWidgetDataRegistApiListResponse>(
      "get",
      `/${databaseId}/widget-data`,
      {
        limit,
        skip,
        tableSlug,
        widgetId,
      },
      {
        teamSlug,
      },
      undefined
    );
  };

  const _findWidgetData = async ({
    databaseId,
    teamSlug,
    tableSlug,
    apiKey,
  }: {
    databaseId: string;
    teamSlug: string;
    tableSlug: string;
    apiKey: string;
  }) => {
    return executeRequest<DashboardWidgetDataRegistApiResponse>(
      "get",
      `/${databaseId}/widget-data/${tableSlug}/${apiKey}`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  // create / update
  const _registWidgetData = async ({
    teamSlug,
    databaseId,
    tableSlug,
    ...requestBody
  }: RegistDataApiReuqestParams) => {
    return executeRequest<DashboardWidgetDataRegistApiResponse>(
      "post",
      `/${databaseId}/widget-data/regist/${tableSlug}`,
      undefined,
      {
        teamSlug,
      },
      {
        ...requestBody,
        _delete: undefined,
        delete: requestBody._delete,
      }
    );
  };

  // delete
  const _deleteWidgetData = ({
    apiKey,
    databaseId,
    tableSlug,
    teamSlug,
  }: {
    apiKey?: string;
    databaseId: string;
    tableSlug?: string;
    teamSlug: string;
  }) => {
    return executeRequest(
      "DELETE",
      `/${databaseId}/widget-data/${tableSlug}/${apiKey}`,
      undefined,
      {
        teamSlug,
      }
    );
  };

  return {
    _listWidgetData,
    _registWidgetData,
    _deleteWidgetData,
    _findWidgetData,
  };
}
