type RssFeedDependencies = { teamSlug: string };

type ListRssFeedSampleDependencies = RssFeedDependencies & {
  rssUrl: string;
};
type ListRssLinkedToTableDependencies = RssFeedDependencies & {
  databaseId: string;
  tableSlug: string;
};

const rssFeedKeys = {
  all: ({ teamSlug }: RssFeedDependencies) => [teamSlug, "rssFeed"] as const,

  allListRssFeedSample: ({ teamSlug, rssUrl }: ListRssFeedSampleDependencies) =>
    [
      ...rssFeedKeys.all({ teamSlug }),
      teamSlug,
      rssUrl,
      "listRssFeedSample",
    ] as const,

  allFindRssLinkedToTable: ({ teamSlug, databaseId, tableSlug }: ListRssLinkedToTableDependencies) =>
    [
      ...rssFeedKeys.all({ teamSlug }),
      teamSlug,
      databaseId,
      tableSlug,
      "findRssLinkedToTable",
    ] as const,
};

export { rssFeedKeys };
