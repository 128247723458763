
import { useNavigate } from "react-router-dom";
import { Button } from "~/components_next/Button";
import { Spacer } from "~/components_next/Spacer";
import { SimpleSelect } from "~/components_next/Select";
import { Text } from "~/components_next/Text";
import { useMe, useSelectedMeTeam } from "~/serverStateStore/me";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { CreateTeamDialog } from "~/features/GlobalNavigation/components/Preference/teamSettings/CreateTeamDialog";
import { useDisclosure } from "~/hooks/useDisclosure";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";

export const TeamSettings = () => {
  const navigate = useNavigate();;

  const teamSlug = useTeamSlug();

  const { data: me } = useMe();
  const { data: selectedTeam } = useSelectedMeTeam({ teamSlug });

  const onCurrentTeamChange = (teamSlug: string | null) => {
    if (teamSlug) {
      navigate(`/${teamSlug}`);
    }
  };

  const createTeamDialog = useDisclosure()

  return (
    <Box p="4">
      <Flex gap="4" align="center">
        <Text variant="heading">Team Settings</Text>
        <Spacer />
        <Button
          variant="primary"
          onClick={createTeamDialog.onOpen}
          size="sm"
        >
          Create New Team
        </Button>
      </Flex>

      <Box my="4">
        <Text>Current team</Text>
        {
          me && (
            <SimpleSelect
              variant="primary"
              value={selectedTeam?.teamSlug ?? null}
              onChange={(el) => onCurrentTeamChange(el)}
              options={
                me.teams.map((team) => {
                  return (
                    { label: team.teamName, value: team.teamSlug }
                  );
                })
              }
            />
          )
        }
      </Box>

      <CreateTeamDialog
        isOpen={createTeamDialog.isOpen}
        setIsOpen={createTeamDialog.setIsOpen}
        onClose={createTeamDialog.onClose}
      />
    </Box>
  );
};
