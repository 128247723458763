import {
  DashboardNotebookCellObject,
  DashboardNotebookCellOutputObject,
} from "@usemorph/morph-dashboard-types";

export const findViewIdFromViewCell = (cell: DashboardNotebookCellObject) => {
  const { outputs } = cell;

  const foundOutput = outputs.find(
    ({ contentType }) => contentType === "application/morph.view_id"
  ) as DashboardNotebookCellOutputObject & {
    contentType: "application/morph.view_id";
  };
  if (!foundOutput) {
    throw new Error("Something went wrong. View is not found.");
  }
  return foundOutput.value;
};
