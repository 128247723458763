import { Box as RadixThemeBox } from "@radix-ui/themes";
import { CSS, styled } from "@stitches/react"
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from "react"

type RadixThemeBoxProps = ComponentPropsWithoutRef<
  typeof RadixThemeBox
>;

type BoxProps = {
  hoverable?: boolean;
  css?: CSS;
} & Omit<RadixThemeBoxProps, "css">

const StyledBox = styled(RadixThemeBox, {})
const HoverableStyle: CSS = {
  cursor: "pointer",
  transitionProperty: "background-color",
  transitionDuration: "200ms",
  "&:hover": {
    backgroundColor: "$hoverColor"
  },
  "&:active": {
    backgroundColor: "$activeColor"
  }
}

const _Box = (props: BoxProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { hoverable = false, css } = props

  const radixBoxProps = (({
    css,
    hoverable,
    ...rest
  }) => rest)(props);

  return (
    <StyledBox
      ref={ref}
      css={hoverable ? { ...HoverableStyle, ...css } : css}
      {...radixBoxProps}
    ></StyledBox>
  )
}

const Box = forwardRef<HTMLDivElement, BoxProps>(_Box)

export { Box }
