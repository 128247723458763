import { useQueryClient } from "react-query";
import { allSimpleFieldsKeys, simpleFieldsKeys } from "../keys";
import useApiFields from "~/api/useApiField";
import { SimpleField } from "@usemorph/morph-dashboard-types";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { recordsKeys } from "~/serverStateStore/records/keys";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseCreateComputeFieldMutationParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
}

const useCreateComputeFieldMutation = ({
  databaseId,
  tableSlug,
  teamSlug,
}: UseCreateComputeFieldMutationParams) => {
  const { _createComputeField } = useApiFields();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      mergeField,
      normalizedField,
    }: {
      mergeField: SimpleField;
      normalizedField: SimpleField;
    }) => {
      return _createComputeField({
        teamSlug,
        databaseId,
        tableSlug,
        requestBody: {
          mergeField,
          normalizedField,
        },
      });
    },
    onSuccess: () => {
      const getAllSimpleField = allSimpleFieldsKeys.getAllSimpleField({
        teamSlug,
        databaseId,
      });
      const getSimpleField = simpleFieldsKeys.getSimpleField({
        teamSlug,
        databaseId,
        tableSlug,
      });
      const allGetRecord = recordsKeys.allGetRecord({
        teamSlug,
        databaseId,
        tableSlug,
      });
      const allGetRecordWithSql = recordsKeys.allGetRecordWithSql({
        teamSlug,
        databaseId,
        tableSlug,
      });
      broadcast(getAllSimpleField);
      broadcast(getSimpleField);
      broadcast(allGetRecord);
      broadcast(allGetRecordWithSql);

      return invalidateAll(
        client,
        getAllSimpleField,
        getSimpleField,
        allGetRecord,
        allGetRecordWithSql
      );
    },
  };
};

export { useCreateComputeFieldMutation };
