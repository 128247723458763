import { RecordHistoriesClientModel } from "~/clientModel/recordHistories";
import { Divider } from "~/components_next/Divider";
import { Flex } from "~/components_next/Flex";
import { RecordHistoryCard } from "./RecordHistoryCard";

type RecordHistoriesProps = {
  recordHistories: RecordHistoriesClientModel;
};

export const RecordHistories = (props: RecordHistoriesProps) => {
  const { recordHistories } = props;
  return (
    <Flex
      direction="column"
      gap="0"
      divider={<Divider orientation="horizontal" />}
    >
      {recordHistories.allRecordHistories.map((recordHistory) => (
        <RecordHistoryCard
          key={recordHistory.key}
          recordHistory={recordHistory}
        />
      ))}
    </Flex>
  );
};
