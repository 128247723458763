import { tablesKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiTable from "~/api/useApiTable";

interface UseGetTableQueryParams {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
}

const useGetTableQuery = ({
  databaseId,
  teamSlug,
  tableSlug,
}: UseGetTableQueryParams) => {
  const { _findTable } = useApiTable();

  const client = useQueryClient();
  client.setQueryDefaults(
    tablesKeys.findTable({ databaseId, teamSlug, tableSlug }),
    commonQueryOptions
  );

  return {
    queryKey: tablesKeys.findTable({
      databaseId,
      teamSlug,
      tableSlug,
    }),
    queryFn: () => {
      return _findTable({
        databaseId,
        teamSlug,
        tableSlug,
      });
    },
  };
};

export { useGetTableQuery };
