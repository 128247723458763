import {
  QueryRecordWithFieldsResponse,
  SimpleField,
  WidgetDataQueryRecordRequestBody,
} from "@usemorph/morph-dashboard-types";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { RecordsTableBase } from "~/components_next/RecordsTableBase";
import { RecordsTableBaseRecord } from "~/components_next/RecordsTableBase/types";
import { convertRecordToRecordModel } from "~/features/RecordModel";
import { useExecuteQueryDataApi } from "~/serverStateStore/executeDataApi";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

type QueryDataApiPreviewProps = {
  body: WidgetDataQueryRecordRequestBody;
  tableSlug: string;
  apiKey: string;
};

type SelectData = {
  recordsTableBaseRecord: RecordsTableBaseRecord[];
  fields: SimpleField[];
  count: number;
};

const QueryDataApiPreview = (props: QueryDataApiPreviewProps) => {
  const { body, tableSlug, apiKey } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const { data: queryRecords } = useQuery({
    ...useExecuteQueryDataApi({
      databaseId,
      teamSlug,
      tableSlug,
      apiKey,
      body,
    }),
    select: (data: QueryRecordWithFieldsResponse): SelectData => {
      return {
        recordsTableBaseRecord: data.items.map((record, index) => {
          return {
            values: convertRecordToRecordModel(record, data.fields),
            _reservedRecordIndex: index,
          };
        }),
        fields: data.fields,
        count: data.count,
      };
    },
  });

  const fields = useMemo(() => {
    if (body.select.length === 0 || body.select.includes("*")) {
      return queryRecords?.fields || [];
    }

    return (
      queryRecords?.fields.filter((field) =>
        body.select.includes(field.name)
      ) || []
    );
  }, [queryRecords?.fields, body]);

  if (!queryRecords) return <></>;

  if (fields.length === 0) return <></>;

  return (
    <>
      <RecordsTableBase
        editableFields="none"
        fields={fields}
        records={queryRecords.recordsTableBaseRecord}
      />
    </>
  );
};

export { QueryDataApiPreview };
