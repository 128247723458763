import { Box } from "@radix-ui/themes";
import { useState, useCallback } from "react";
// import { HTMLRichTextEditor } from "./RichTextEditor/HTMLRichTextEditor";
import { Dialog } from "~/components_next/Dialog";
import { HTMLRichTextEditor } from "~/components_next/RichTextEditor";
import { SimpleTabs } from "~/components_next/Tabs";
import { styled } from "~/stitches";
import { HTMLEditor } from "./HTMLEditor/HTMLEditor";
import { HTMLPreview } from "./Preview/HTMLPreview";
import { RichTextMigrationWarningCard } from "./RichTextMigrationWarningCard";

const BlurBox = styled(Box, {
  filter: "blur(2px)",
  pointerEvents: "none",
  userSelect: "none",
  height: "100%",
});

const CenterBox = styled(Box, {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
});

const TabContentContainer = styled(Box, {
  height: "70vh",
  marginTop: "$2",
});

type HTMLEditModalProps = {
  html: string | null | undefined;
  onChange?: (html: string | null) => void;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  isReadOnly: boolean;
};

const HTMLEditModal = (props: HTMLEditModalProps) => {
  const { html, onChange, onOpenChange, isOpen, isReadOnly } = props;

  const [isOpenRichTextEditor, setIsOpenRichTextEditor] = useState(false);

  const handleOpenRichTextEditor = useCallback(() => {
    setIsOpenRichTextEditor(true);
  }, []);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        setIsOpenRichTextEditor(false);
      }
      onOpenChange(isOpen);
    },
    [onOpenChange]
  );

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleOpenChange} size="xl">
      <Dialog.Content>
        <Dialog.Title>Edit</Dialog.Title>
        <Dialog.Body>
          <SimpleTabs
            tabLabels={["Preview", "HTML Editor", "Rich Text Editor"]}
          >
            <TabContentContainer>
              <HTMLPreview value={html} height="100%" />
            </TabContentContainer>
            <TabContentContainer>
              <HTMLEditor
                html={html ?? null}
                onChange={onChange}
                isReadOnly={isReadOnly}
                height="70vh"
              />
            </TabContentContainer>
            <TabContentContainer>
              {isOpenRichTextEditor ? (
                <HTMLRichTextEditor
                  html={html ?? ""}
                  isReadOnly={false}
                  onChange={(value) => onChange?.(value || null)}
                  onUploadImage={async (file) => {
                    return { url: "https://picsum.photos/200/300" };
                  }}
                  height="100%"
                />
              ) : (
                <>
                  <BlurBox>
                    <HTMLRichTextEditor
                      html={html ?? ""}
                      isReadOnly={true}
                      height="100%"
                    />
                  </BlurBox>
                  <CenterBox>
                    <RichTextMigrationWarningCard
                      onEditClick={handleOpenRichTextEditor}
                    />
                  </CenterBox>
                </>
              )}
            </TabContentContainer>
          </SimpleTabs>
        </Dialog.Body>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export { HTMLEditModal };
