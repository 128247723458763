import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModel } from "..";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";

type MultiSelectFieldTypeClientModelData = {
  members: string[];
};

export class MultiSelectFieldTypeClientModel extends FieldTypeClientModelBase {
  #data: MultiSelectFieldTypeClientModelData;

  public constructor(data: MultiSelectFieldTypeClientModelData) {
    super();
    this.#data = data;
  }

  public get data(): MultiSelectFieldTypeClientModelData {
    return this.#data;
  }

  public get type(): "multiSelect" {
    return "multiSelect";
  }

  public get fieldTypeLabel(): "Multi Select" {
    return "Multi Select";
  }

  public get isAutoGenerated(): false {
    return false;
  }

  public get isEmbeddable(): true {
    return true;
  }

  public get canBeTargetOfSyncingByComputedField(): true {
    return true;
  }

  public get _updatableFieldTypes(): FieldTypeClientModel["type"][] {
    return [];
  }

  public validate(): ValidationResult {
    return { isValid: true };
  }

  public get members(): string[] {
    return this.#data.members;
  }

  public addMember(member: string): MultiSelectFieldTypeClientModel {
    return new MultiSelectFieldTypeClientModel({
      members: [...this.#data.members, member],
    });
  }

  public deleteMember(member: string): MultiSelectFieldTypeClientModel {
    return new MultiSelectFieldTypeClientModel({
      members: this.#data.members.filter((m) => m !== member),
    });
  }
}
