import { useMutation } from "react-query";
import { composeExecutable } from "~/clientModel/executable";
import { useGenerateColorsMutation } from "~/serverStateStore/canvasStyle";

export const createUseGenerateColorsExecutable = ({
  teamSlug,
}: {
  teamSlug: string;
}) => {
  return () => {
    return composeExecutable(
      useMutation(useGenerateColorsMutation({ teamSlug }))
    );
  };
};
