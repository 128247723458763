import { BsDownload } from "react-icons/bs";
import { Button } from "~/components_next/Button";

type DownloadButtonProps = {
  onClick?: () => void;
  isDisabled?: boolean;
};

const DownloadButton = ({ onClick, isDisabled }: DownloadButtonProps) => {
  return (
    <Button
      isDisabled={isDisabled}
      variant="tertiary"
      size="sm"
      onClick={onClick}
    >
      <BsDownload />
      Download
    </Button>
  );
};

export { DownloadButton };
