import { FieldsClientModel } from "~/clientModel/fields";
import {
  ColumnSizingDict,
  TableColumnSizingClientModel,
} from "./TableColumnSizingClientModel";

export class TableColumnSizingClientModelFactory {
  public static create({
    columnSizingDict,
    fields,
  }: {
    columnSizingDict: ColumnSizingDict | null;
    fields: FieldsClientModel;
  }): TableColumnSizingClientModel {
    const columnSizingDictWithDefaultWidths = fields.allFields.reduce(
      (dict, field) => {
        const width =
          columnSizingDict?.[field.name] ??
          TableColumnSizingClientModel.getDefaultColumnWidth(field);
        return { ...dict, [field.name]: width };
      },
      {} as ColumnSizingDict
    );
    return new TableColumnSizingClientModel({
      columnSizingDict: columnSizingDictWithDefaultWidths,
    });
  }

  public static createEmpty({
    fields,
  }: {
    fields: FieldsClientModel;
  }): TableColumnSizingClientModel {
    return TableColumnSizingClientModelFactory.create({
      columnSizingDict: {},
      fields,
    });
  }
}
