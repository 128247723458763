import { BsPlus } from "react-icons/bs";
import { LuBarChart4, LuSparkles } from "react-icons/lu";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { ControlledDropdown } from "~/components_next/DropdownMenu";
import { Flex } from "~/components_next/Flex";
import { Text } from "~/components_next/Text";
import { useDisclosure } from "~/hooks/useDisclosure";
import { styled } from "~/stitches";
import {
  useUseMyColorsLoadable,
  useUseDeleteMyColorsExecutable,
} from "../../providers/PlaygroundCreateCellExecutableProvider";
import { GenerateMyColorsNativeDialog } from "../promptStyle/GenerateMyColorsDialog";
import { StyleChip } from "../promptStyle/StyleChip";
import {
  CanvasCreatePromptCellClientModel,
  CreatePromptCellField,
} from "~/clientModel/canvas/CanvasCreateCellClientModel";
import { Input } from "~/components_next/Input";
import { Loadable } from "~/clientModel/loadable";
import { FieldsClientModel } from "~/clientModel/fields";
import { useErrorToast } from "~/components_next/Error";
import { PlaygroundPromptForm } from "~/presenters/canvas/playground/textEditor/PlaygroundPromptForm";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { CanvasVisualizationPromptConfigCientModel } from "~/clientModel/canvas/CanvasVisualizationPromptConfigClientModel";
import { PromptCellXYInput } from "./PromptCellXYInput";
import { useReactFlowSetIsNodeSelectionLocked } from "../../providers/ReactFlowSelectedCellsProvider";

type CreatePromptCellDropdownProps = {
  createCellInstance: CanvasCreatePromptCellClientModel | null;
  visualizationConfig: CanvasVisualizationPromptConfigCientModel | null;
  onCreateCellInstanceChange: (
    payload: CanvasCreatePromptCellClientModel | null
  ) => void;
  isCreatingCell: boolean;
  onCreatePromptCell: () => void;
  fieldsLoadable: Loadable<FieldsClientModel>;
  chartTypeOptions?: CanvasVisualizationPromptConfigCientModel[];
};

const ChartIcon = styled(LuBarChart4, {
  color: "$purple10",
});

const AIIcon = styled(LuSparkles, {
  color: "$purple10",
});

const CreatePromptCellDropdown = (props: CreatePromptCellDropdownProps) => {
  const {
    createCellInstance,
    isCreatingCell,
    onCreatePromptCell,
    onCreateCellInstanceChange,
    fieldsLoadable,
    visualizationConfig,
    chartTypeOptions,
  } = props;

  const { errorToast } = useErrorToast({});

  const generateDialogDisclosure = useDisclosure();

  const useMyColorsLoadable = useUseMyColorsLoadable();
  const myColorsLoadable = useMyColorsLoadable();

  const handleSelectXAxisFields = (
    fields: CreatePromptCellField[] | null | undefined
  ) => {
    if (!createCellInstance) {
      return;
    }

    if (fields === null || fields === undefined) {
      onCreateCellInstanceChange(createCellInstance?.updateXAxisFields([]));
      return;
    }

    onCreateCellInstanceChange(createCellInstance?.updateXAxisFields(fields));
  };

  const handleSelectYAxisFields = (
    fields: CreatePromptCellField[] | null | undefined
  ) => {
    if (!createCellInstance) {
      return;
    }

    if (fields === null || fields === undefined) {
      onCreateCellInstanceChange(createCellInstance?.updateYAxisFields([]));
      return;
    }

    onCreateCellInstanceChange(createCellInstance?.updateYAxisFields(fields));
  };

  const useDeleteMyColorsExecutable = useUseDeleteMyColorsExecutable();
  const deleteMyColorsExecutable = useDeleteMyColorsExecutable();

  const handleDeleteMyColor = (colorCodeId: string) => {
    deleteMyColorsExecutable.execute({ colorCodeId });
  };

  const setIsNodeChangeLocked = useReactFlowSetIsNodeSelectionLocked();

  if (createCellInstance === null || visualizationConfig === null) {
    return null;
  }

  return (
    <>
      <ControlledDropdown
        trigger={
          <Box
            css={{
              width: "30px",
              height: "2px",
              backgroundColor: "transparent",
            }}
          ></Box>
        }
        isOpen={createCellInstance !== null}
        onOpenChange={() => null}
      >
        <Flex gap="4" direction="column" p="2" css={{ width: "610px" }}>
          <Flex gap="1" align="center">
            <AIIcon />
            <ChartIcon />
            <Text fontWeight="medium">
              AI Visualization / {visualizationConfig.chartTypeLabel}
            </Text>
          </Flex>
          <Flex direction="column" gap="2">
            <Text variant="description">Labels</Text>
            <Input
              variant="primary"
              label="Title"
              value={createCellInstance.title}
              onChange={(e) =>
                onCreateCellInstanceChange(
                  createCellInstance.updateTitle(e.target.value)
                )
              }
            />
            {visualizationConfig.hasXYAxis && (
              <Flex gap="4">
                <Input
                  variant="primary"
                  label="X Axis Label"
                  value={createCellInstance.xAxisLabel}
                  onChange={(e) =>
                    onCreateCellInstanceChange(
                      createCellInstance.updateXAxisLabel(e.target.value)
                    )
                  }
                />
                <Input
                  variant="primary"
                  label="Y Axis Label"
                  value={createCellInstance.yAxisLabel}
                  onChange={(e) =>
                    onCreateCellInstanceChange(
                      createCellInstance.updateYAxisLabel(e.target.value)
                    )
                  }
                />
              </Flex>
            )}
          </Flex>
          {visualizationConfig.hasXYAxis && (
            <WithFallback loadables={[fieldsLoadable]}>
              {([fields]) => (
                <>
                  <Flex direction="column" gap="2">
                    <Text variant="description">Series</Text>
                    <PromptCellXYInput
                      xAxisInputType={visualizationConfig.xAxisInputType}
                      yAxisInputType={visualizationConfig.yAxisInputType}
                      fields={fields.allFields}
                      xAxisFields={createCellInstance.xAxisFields}
                      yAxisFields={createCellInstance.yAxisFields}
                      onXAxisFieldsChange={handleSelectXAxisFields}
                      onYAxisFieldsChange={handleSelectYAxisFields}
                      chartTypeOptions={chartTypeOptions}
                    />
                  </Flex>
                </>
              )}
            </WithFallback>
          )}

          <Flex direction="column" gap="2">
            <Text variant="description">Custom Prompt</Text>
            <WithFallback loadables={[fieldsLoadable]}>
              {([fields]) => {
                return (
                  <PlaygroundPromptForm
                    variant="textarea"
                    value={createCellInstance.prompt}
                    onChange={(value) =>
                      onCreateCellInstanceChange(
                        createCellInstance.updatePrompt(value)
                      )
                    }
                    fieldsForTypeahead={fields}
                  />
                );
              }}
            </WithFallback>
          </Flex>

          <Flex direction="column" gap="2">
            <Text variant="description">Style</Text>
            <Flex gap="2" wrap="wrap">
              {myColorsLoadable.data?.items.map((item, index) => {
                return (
                  <StyleChip
                    key={index}
                    isSelected={createCellInstance.isSameColorCodes(
                      item.colorCodes
                    )}
                    colorCodes={item.colorCodes}
                    onClick={() =>
                      onCreateCellInstanceChange(
                        createCellInstance.updateColorCodes(item.colorCodes)
                      )
                    }
                    onDelete={() => handleDeleteMyColor(item.colorCodeId)}
                  />
                );
              })}
              {/* {presetColorsLoadable.data?.items.map((item, index) => {
                return (
                  <StyleChip
                    key={index}
                    isSelected={selectedStyle?.colorCodeId === item.colorCodeId}
                    colorCodes={item.colorCodes}
                    onClick={() =>
                      setSelectedStyle({
                        colorCodeId: item.colorCodeId,
                      })
                    }
                  />
                );
              })} */}
            </Flex>
          </Flex>

          <Box px="2">
            <Button
              variant="actionText"
              size="sm"
              onClick={() => generateDialogDisclosure.onOpen()}
            >
              <BsPlus />
              Create New Style
            </Button>
          </Box>

          <Flex gap="4">
            <Button
              variant="primary"
              size="sm"
              onClick={onCreatePromptCell}
              isLoading={isCreatingCell}
            >
              Create Cell
            </Button>
            <Button
              variant="tertiary"
              size="sm"
              onClick={() => {
                onCreateCellInstanceChange(null);
              }}
            >
              Cancel
            </Button>
          </Flex>
        </Flex>
        <GenerateMyColorsNativeDialog
          isOpen={generateDialogDisclosure.isOpen}
          onIsOpenChange={generateDialogDisclosure.setIsOpen}
        />
      </ControlledDropdown>
    </>
  );
};

export { CreatePromptCellDropdown, type CreatePromptCellDropdownProps };
