import { useMemo, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { CanvasCreateTableViewCellClientModel } from "~/clientModel/canvas/CanvasCreateCellClientModel";
import { FieldsClientModel } from "~/clientModel/fields";
import { Loadable } from "~/clientModel/loadable";
import { Button } from "~/components_next/Button";
import { useErrorToast } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { NativeSimpleSelect } from "~/components_next/Select/NativeSelect/NativeSimpleSelect";
import { Text } from "~/components_next/Text";

type GroupingFieldsSelectProps = {
  createCellInstance: CanvasCreateTableViewCellClientModel;
  onCreateCellInstanceChange: (
    createCellInstance: CanvasCreateTableViewCellClientModel
  ) => void;
  fieldsLoadable: Loadable<FieldsClientModel>;
};

const GroupingFieldsSelect = (props: GroupingFieldsSelectProps) => {
  const { createCellInstance, onCreateCellInstanceChange, fieldsLoadable } =
    props;

  // null = フォームが表示されてるけど、まだ値が入力されてない
  // undefined = フォームが表示されてない
  const [addingFieldName, setAddingFieldName] = useState<
    string | null | undefined
  >(null);

  const { errorToast } = useErrorToast({});

  const fieldsOptions = useMemo(() => {
    return (
      fieldsLoadable.data?.allFields.map((field) => {
        return {
          label: field.displayName || field.name,
          value: field.name,
        };
      }) || []
    );
  }, [fieldsLoadable.data?.allFields]);

  const handleSelectGroupingFieldAt = (
    index: number,
    value?: string | null
  ) => {
    if (!createCellInstance) {
      return;
    }
    const findXAxisField = fieldsLoadable.data?.allFields.find(
      (field) => field.name === value
    );
    if (!findXAxisField) {
      errorToast({
        title: "Error",
        description: "Cannot find field",
      });
      return;
    }

    onCreateCellInstanceChange(
      createCellInstance.updateGroupingTargetFieldAt(index, findXAxisField)
    );
  };

  const handleSelectAddingGroupingField = (value?: string | null) => {
    if (!createCellInstance) {
      return;
    }
    const findGroupingField = fieldsLoadable.data?.allFields.find(
      (field) => field.name === value
    );
    if (!findGroupingField) {
      errorToast({
        title: "Error",
        description: "Cannot find field",
      });
      return;
    }

    onCreateCellInstanceChange(
      createCellInstance.updateGroupingTargetFields([
        ...(createCellInstance.groupingTargetFields ?? []),
        findGroupingField,
      ])
    );

    setAddingFieldName(undefined);
  };

  return (
    <Flex direction="column" gap="2">
      <Text variant="description">Grouping fields</Text>
      {createCellInstance.groupingTargetFields?.map((field, index) => {
        return (
          <NativeSimpleSelect
            key={index}
            size="sm"
            variant="primary"
            options={fieldsOptions}
            value={field.name}
            onChange={(value) => handleSelectGroupingFieldAt(index, value)}
          />
        );
      })}
      {addingFieldName !== undefined && (
        <NativeSimpleSelect
          size="sm"
          variant="primary"
          options={fieldsOptions}
          value={addingFieldName}
          onChange={(value) => handleSelectAddingGroupingField(value)}
        />
      )}
      {addingFieldName === undefined && (
        <Button
          variant="tertiary"
          size="sm"
          onClick={() => setAddingFieldName(null)}
        >
          <BsPlusLg />
          Add
        </Button>
      )}
    </Flex>
  );
};

export { GroupingFieldsSelect };
