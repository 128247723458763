import { useQueryClient } from "react-query";
import { allSimpleFieldsKeys, simpleFieldsKeys } from "../keys";
import { DashboardSimpleUpdateFieldRequestBody } from "@usemorph/morph-dashboard-types";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import useApiFields from "~/api/useApiField";
import { recordsKeys } from "~/serverStateStore/records/keys";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseUpdateSimpleFieldMutationParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
}

const useUpdateSimpleFieldMutation = ({
  databaseId,
  tableSlug,
  teamSlug,
}: UseUpdateSimpleFieldMutationParams) => {
  const { _updateSimpleField } = useApiFields();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      requestBody,
    }: {
      requestBody: DashboardSimpleUpdateFieldRequestBody;
    }) => {
      return _updateSimpleField({
        teamSlug,
        databaseId,
        tableSlug,
        requestBody,
      });
    },
    onSuccess: () => {
      const getAllSimpleField = allSimpleFieldsKeys.getAllSimpleField({
        teamSlug,
        databaseId,
      });
      const getSimpleField = simpleFieldsKeys.getSimpleField({
        teamSlug,
        databaseId,
        tableSlug,
      });
      const allGetRecord = recordsKeys.allGetRecord({
        teamSlug,
        databaseId,
        tableSlug,
      });
      broadcast(getAllSimpleField);
      broadcast(getSimpleField);
      broadcast(allGetRecord);

      return invalidateAll(
        client,
        getAllSimpleField,
        getSimpleField,
        allGetRecord
      );
    },
  };
};

export { useUpdateSimpleFieldMutation };
