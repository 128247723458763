import { Flex } from "@radix-ui/themes";
import { Box } from "~/components_next/Box";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { Spacer } from "~/components_next/Spacer";
import { FiltersAndSortsButton } from "~/features/SourceAndViews/common/components/QueryMode/Buttons/FiltersAndSortsButton";
import { useDisclosure } from "~/hooks/useDisclosure";
import { useViewId } from "~/utilHooks/useViewId";
import { useFilterCondition } from "../../states/filterCondition";
import { useSortCondition } from "../../states/sortCondition";
import { FieldSettingButton } from "./FieldSetting/FieldSettingButton";
import { FilterAndSortForm } from "./FiltersAndSorts/FilterAndSortForm";
import { KanbanViewClearFiltersAndSortsButton } from "./FiltersAndSorts/KanbanViewClearFiltersAndSortsButton";

type ButtonType = "prompt" | "filtersAndSorts" | "sql" | null;

const QueryConditionsToolbar = () => {
  const filterAndSortsDisclosure = useDisclosure();

  const viewId = useViewId();
  const filterCondition = useFilterCondition(viewId);
  const sortCondition = useSortCondition(viewId);

  const queryMode =
    !!filterCondition || !!sortCondition ? "filtersAndSorts" : null;

  const buttonStatus = queryMode === "filtersAndSorts" ? "active" : "default";

  return (
    <Flex direction="row" align="center" gap="3">
      <SimpleDropdownMenu
        trigger={<FiltersAndSortsButton status={buttonStatus} />}
      >
        <Box css={{ minWidth: 500, overflow: "auto" }}>
          <FilterAndSortForm onClose={filterAndSortsDisclosure.onClose} />
        </Box>
      </SimpleDropdownMenu>
      {queryMode === "filtersAndSorts" && (
        <KanbanViewClearFiltersAndSortsButton />
      )}
      <Spacer />
      <FieldSettingButton />
    </Flex>
  );
};

export { QueryConditionsToolbar, type ButtonType };
