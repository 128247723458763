import {
  BucketClientModelBase,
  BucketPermissions,
} from "./BucketClientModelBase";

export class CsvImportBucketClientModel extends BucketClientModelBase {
  constructor({
    bucketId,
    bucketName,
    readPermission,
    createPermission,
    updatePermission,
  }: {
    bucketId: string;
    bucketName: string;
    readPermission: BucketPermissions;
    createPermission: BucketPermissions;
    updatePermission: BucketPermissions;
  }) {
    super({
      bucketId,
      bucketName,
      readPermission,
      createPermission,
      updatePermission,
    });
  }

  static csvImportFileBucketName = "ImportedSystemFiles";
}
