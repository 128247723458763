import { ForwardedRef, forwardRef } from "react";
import { LastEditedByRecordEntryClientModel } from "~/clientModel/records/record/recordEntry";
import { RecordValueInputCommonProps } from "../../type";
import { UserReadOnlyInput } from "./UserReadOnlyInput";

const LastEditedByReadonlyInput = forwardRef(function LastEditedByReadonlyInput(
  props: RecordValueInputCommonProps<LastEditedByRecordEntryClientModel>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { editingRecordEntry, size, field } = props;

  const validatedValue = editingRecordEntry.getValidatedValue(field);

  const value = validatedValue.isValid ? validatedValue.value : null;

  return (
    <UserReadOnlyInput
      ref={ref}
      user={value}
      errorMessages={validatedValue.errorMessages}
      size={size}
    />
  );
});

export { LastEditedByReadonlyInput };
