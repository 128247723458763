import { ViewMetaFormState } from "../Form/ViewMetaForm";

const createGroupKeyFromViewMetaFormState = (
  viewMetaFormState: ViewMetaFormState,
  mainTableSlug: string
) => {
  if (viewMetaFormState.type !== "kanban") {
    return undefined;
  }

  const { originalTableSlug, name } = viewMetaFormState.groupedByField;

  return originalTableSlug === mainTableSlug
    ? name
    : `${originalTableSlug}.${name}`;
};

export { createGroupKeyFromViewMetaFormState };
