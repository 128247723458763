import { useDisclosure } from "~/hooks/useDisclosure";
import { DatabasePresenter } from "~/presenters/database";
import { useListDatabase } from "./useListDatabase";
import { useFindPaymentObjectLoadable } from "../plan/useFindPaymentObjectLoadable";
import { useCreateDatabase } from "./useCreateDatabase";
import { useUpdateDatabase } from "./useUpdateDatabase";
import { useDeleteDatabase } from "./useDeleteDatabase";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

export const DatabaseContainer = () => {
  const teamSlug = useTeamSlug()
  /**
   * Loadable
   */
  const { listDatabaseLoadable, listDatabaseQueryResult } = useListDatabase()
  const paymentLoadable = useFindPaymentObjectLoadable({ teamSlug })
  const { createDatabaseExecutable } = useCreateDatabase()
  const { updateDatabaseExecutable } = useUpdateDatabase()
  const { deleteDatabaseExecutable } = useDeleteDatabase()

  /**
   * UI State
   */
  const createDatabaseModal = useDisclosure();
  const connectDatabaseModal = useDisclosure();

  if (!paymentLoadable.data || !listDatabaseLoadable.data) {
    return null; // TODO: Loading
  }

  return (
    <>
      <DatabasePresenter
        listDatabaseClientModel={listDatabaseLoadable.data}
        listDatabaseRefetcher={listDatabaseQueryResult.refetch}
        paymentClientModel={paymentLoadable.data}
        createDatabaseExecutable={createDatabaseExecutable}
        updateDatabaseExecutable={updateDatabaseExecutable}
        deleteDatabaseExecutable={deleteDatabaseExecutable}
        createDatabaseModal={createDatabaseModal}
        connectDatabaseModal={connectDatabaseModal}
      />
    </>
  );
};
