import {
  CanvasVariablesClientModel,
  CanvasVariablesClientModelFactory,
} from "../CanvasVariableClientModel";
import {
  CanvasCellClientModelBase,
  CanvasCellDataType,
  CanvasCellResizeParams,
} from "./CanvasCellClientModelBase";

export type CanvasCellVariablesDataType = CanvasCellDataType & {
  cellType: "variables";
};

export class CanvasCellVariablesClientModel extends CanvasCellClientModelBase<
  CanvasCellVariablesClientModel,
  CanvasCellVariablesDataType
> {
  private _variables: CanvasVariablesClientModel;
  constructor(data: CanvasCellVariablesDataType) {
    super(data);

    this._variables = CanvasVariablesClientModelFactory.fromVariableData(
      data.source.variables || []
    );
  }

  get variables() {
    return this._variables;
  }

  get isResizable(): boolean {
    return true;
  }

  get showSourceHandle(): boolean {
    return true;
  }

  get showTargetHandle(): boolean {
    return true;
  }

  updateCellName(cellName: string): CanvasCellVariablesClientModel {
    return new CanvasCellVariablesClientModel({
      ...this.data,
      cellName,
    });
  }

  updateVariables(variables: CanvasVariablesClientModel) {
    return new CanvasCellVariablesClientModel({
      ...this.data,
      source: {
        ...this.data.source,
        variables: variables.rawData, // 謎
      },
    });
  }

  onResize(
    resizePrams: CanvasCellResizeParams
  ): CanvasCellVariablesClientModel {
    return new CanvasCellVariablesClientModel({
      ...this.data,
      settings: {
        ...this.data.settings,
        ...resizePrams,
      },
    });
  }
}
