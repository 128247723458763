import { FieldClientModel } from "~/clientModel/fields/field";
import {
  UpdatableRecordEntryClientModelBaseConstructorParams,
  UpdatableRecordEntryClientModelBase,
} from "../RecordEntryClientModelBase";
import { zValidate, zAllowNullIfNullable } from "../validatorsUtil";
import { z } from "zod";

type MultiSelectRecordEntryClientModelData =
  UpdatableRecordEntryClientModelBaseConstructorParams;

export class MultiSelectRecordEntryClientModel extends UpdatableRecordEntryClientModelBase<
  string[] | null
> {
  constructor(data: MultiSelectRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "multiSelect" {
    return "multiSelect";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([z.array(z.string()), zAllowNullIfNullable(field)]),
      this.data.rawValue
    );
  }

  public getValidatedDisplayValue = this.getValidatedValue;

  public updateValue(
    value: string[] | null
  ): MultiSelectRecordEntryClientModel {
    return new MultiSelectRecordEntryClientModel({
      key: this.data.key,
      rawValue: value,
      meta: this.data.meta,
    });
  }
}
