import { useQueryClient } from "react-query";
import useApiRecord from "~/api/useApiRecord";
import { recordsKeys } from "../keys";

// types
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";

interface UseCreateRecordWithPromptMutationParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
}

const useCreateRecordWithPromptMutation = ({
  databaseId,
  tableSlug,
  teamSlug,
}: UseCreateRecordWithPromptMutationParams) => {
  const { _createRecordObjectWithPrompt } = useApiRecord();
  const client = useQueryClient();

  return {
    mutationFn: ({ prompt }: { prompt: string }) => {
      return _createRecordObjectWithPrompt({
        teamSlug,
        databaseId,
        tableSlug,
        prompt,
      });
    },
    onSuccess: () => {
      return invalidateAll(
        client,
        recordsKeys.allGetRecord({
          teamSlug,
          databaseId,
          tableSlug,
        }),
        recordsKeys.allGetRecordWithSql({
          teamSlug,
          databaseId,
          tableSlug,
        })
      );
    },
  };
};

export { useCreateRecordWithPromptMutation };
