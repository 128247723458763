import { DashboardPaymentResponse } from "@usemorph/morph-dashboard-types";
import { FindPaymentClientModel } from ".";

export const findPaymentClientModelService = {
  // factory methods
  fromPaymentResponse: (
    data: DashboardPaymentResponse
  ): FindPaymentClientModel => {
    return new FindPaymentClientModel(data);
  },
};
