import { useMemo } from "react";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import { useCachedValue } from "~/hooks/useCachedValue/useCachedValue";
import { usePlaygroundAllVariableValues } from "../providers/PlaygroundAllVariablesProvider";
import { useGetAncestorVariableValues } from "../providers/PlaygroundAncestorUtilProvider";

/**
 * Requires PlaygroundAllVariablesProvider & PlaygroundAncestorUtilProvider
 */
export const useAncestorVariableValues = (cell: CanvasCellClientModelUnion) => {
  const allVariableValues = usePlaygroundAllVariableValues();
  const getAncestorVariableValues = useGetAncestorVariableValues();
  const variableValues = useMemo(() => {
    return getAncestorVariableValues(cell, allVariableValues);
  }, [allVariableValues, cell, getAncestorVariableValues]);
  const cachedVariableValues = useCachedValue(variableValues, (value) =>
    JSON.stringify(value)
  );

  return cachedVariableValues;
};
