import { allSimpleFieldsKeys } from "../keys";

// types
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiFields from "~/api/useApiField";

interface UseListAllSimpleFieldsQueryParams {
  databaseId: string;
  teamSlug: string;
}

const useListAllSimpleFieldsQuery = ({
  databaseId,
  teamSlug,
}: UseListAllSimpleFieldsQueryParams) => {
  const { _listAllSimpleFields } = useApiFields();

  const client = useQueryClient();

  client.setQueryDefaults(
    allSimpleFieldsKeys.getAllSimpleField({ databaseId, teamSlug }),
    commonQueryOptions
  );

  return {
    queryKey: allSimpleFieldsKeys.getAllSimpleField({
      databaseId,
      teamSlug,
    }),
    queryFn: () => {
      return _listAllSimpleFields({
        databaseId,
        teamSlug,
      });
    },
  };
};

export { useListAllSimpleFieldsQuery };
