
export class MessageClientModel {
  constructor(private readonly data: { message: string; }) { }

  /**
   * Getters
   */
  get message(): string {
    return this.data.message;
  }

}
