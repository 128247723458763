import { useMemo } from "react";
import { usePresenceBasics } from "../hooks/usePresenceBasics";
import { useOthers } from "../liveblocks.config";
import { usePresenceKanbanViewStateUtil } from "./usePresenceKanbanViewStateUtil";
import { useNotebookStateUtil } from "./usePresenceNotebookStateUtil";
import { usePresenceSourceStateUtil } from "./usePresenceSourceStateUtil";
import { usePresenceTableViewStateUtil } from "./usePresenceTableViewStateUtil";

export const useOthersDecoded = () => {
  const others = useOthers();

  const { decodeSourceState } = usePresenceSourceStateUtil();
  const { decodeTableViewState } = usePresenceTableViewStateUtil();
  const { decodeNotebookState } = useNotebookStateUtil();
  const { decodeKanbanViewState } = usePresenceKanbanViewStateUtil();

  const { path } = usePresenceBasics();

  const othersDecoded = useMemo(() => {
    return others.map((other) => {
      const sourceStateDecoded = decodeSourceState(other.presence.sourceState);
      const tableViewStateDecoded = decodeTableViewState(
        other.presence.tableViewState
      );
      const notebookStateDecoded = decodeNotebookState(
        other.presence.notebookState
      );
      const kanbanStateDecoded = decodeKanbanViewState(
        other.presence.kanbanViewState
      );

      return {
        ...other,
        presence: {
          ...other.presence,
          tableState: sourceStateDecoded,
          notebookState: notebookStateDecoded,
          tableViewState: tableViewStateDecoded,
          kanbanViewState: kanbanStateDecoded,
        },
      };
    });
  }, [
    decodeNotebookState,
    decodeSourceState,
    decodeTableViewState,
    decodeKanbanViewState,
    others,
  ]);

  const samePageOthers = useMemo(() => {
    return othersDecoded.filter((other) => other.presence.path === path);
  }, [othersDecoded, path]);

  return {
    othersDecoded,
    samePageOthers,
  };
};
