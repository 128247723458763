import { useCallback } from "react";
import { PresenceNotebookState } from "../types";

const useNotebookStateUtil = () => {
  const decodeNotebookState = useCallback((notebookState?: string) => {
    if (!notebookState) return undefined;

    const parsed = JSON.parse(notebookState);
    // 本当は形式チェック入れたい
    return parsed as PresenceNotebookState;
  }, []);

  return {
    decodeNotebookState,
  };
};

export { useNotebookStateUtil };
