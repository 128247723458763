import { useMutation } from "react-query";
import useApiRecord from "~/api/useApiRecord";
import { CsvDownloadFieldsClientModel } from "~/clientModel/csvDownload/csvDownloadFields";
import { CsvDownloadFieldsClientModelDefactory } from "~/clientModel/csvDownload/csvDownloadFields/CsvDownloadFieldsClientModelDefactory";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import {
  FilterConditionsClientModel,
  FilterConditionsClientModelDefactory,
} from "~/clientModel/queryConditions/filterConditions";
import {
  SortConditionsClientModel,
  SortConditionsClientModelDefactory,
} from "~/clientModel/queryConditions/sortConditions";

import { useErrorToast } from "~/components_next/Error";

type UseDownloadCsvWithQueryConditionsExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  filterConditions: FilterConditionsClientModel;
  sortConditions: SortConditionsClientModel;
};

const useDownloadCsvWithQueryConditionsExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
  filterConditions,
  sortConditions,
}: UseDownloadCsvWithQueryConditionsExecutableParams): Executable<
  { csvDownloadFields: CsvDownloadFieldsClientModel },
  { url: string }
> => {
  const { errorToast } = useErrorToast({});

  const { _downloadRecordsCSV } = useApiRecord();

  return useComposeExecutable(
    useMutation({
      mutationFn: async ({
        csvDownloadFields,
      }: {
        csvDownloadFields: CsvDownloadFieldsClientModel;
      }) => {
        const select =
          CsvDownloadFieldsClientModelDefactory.toQueryRecordRequestBodyObjectSelect(
            csvDownloadFields
          );
        const format =
          CsvDownloadFieldsClientModelDefactory.toRecordFormatConditions(
            csvDownloadFields
          );

        const { url } = await _downloadRecordsCSV({
          teamSlug,
          databaseId,
          tableSlug,
          requestBody: {
            select,
            format,
            filter:
              FilterConditionsClientModelDefactory.toRecordFilterCondition(
                filterConditions
              ),
            sort: SortConditionsClientModelDefactory.toRecordSortConditionUnits(
              sortConditions
            ),
          },
        });

        return { url };
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseDownloadCsvWithQueryConditionsExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
  filterConditions,
  sortConditions,
}: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  filterConditions: FilterConditionsClientModel;
  sortConditions: SortConditionsClientModel;
}) => {
  return () =>
    useDownloadCsvWithQueryConditionsExecutable({
      teamSlug,
      databaseId,
      tableSlug,
      filterConditions,
      sortConditions,
    });
};
