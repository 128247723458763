import constate from "constate";
import { FindCanvasClientModel } from "~/clientModel/canvas";
import { ListCanvasPageClientModel } from "~/clientModel/canvas/ListCanvasPageClientModel";
import { UseExecutable } from "~/clientModel/executable";

export type UsePlaygroundAddCellToPageContextProps = {
  useAddCellToPageExecutable: UseExecutable<
    void,
    {
      notebookCellId: string;
      pageId: string;
      x: number;
      y: number;
      w: number;
      h: number;
    },
    unknown,
    unknown
  >;
  useCreatePageExecutable: UseExecutable<
    void,
    {
      pageName: string;
      description: string;
      isPrivate: boolean;
    },
    ListCanvasPageClientModel
  >;
  pages: FindCanvasClientModel["pages"];
};

const usePlaygroundAddCellToPageContext = (
  props: UsePlaygroundAddCellToPageContextProps
) => {
  const { useAddCellToPageExecutable, useCreatePageExecutable, pages } = props;

  return {
    useAddCellToPageExecutable,
    useCreatePageExecutable,
    pages,
  };
};

export const [
  PlaygroundOnAddCellToPageExecutableProvider,
  usePlaygroundUseAddCellToPageExecutable,
  usePlaygroundUseCreatePageExecutable,
  usePlaygroundCanvasPages,
] = constate(
  usePlaygroundAddCellToPageContext,
  (value) => value.useAddCellToPageExecutable,
  (value) => value.useCreatePageExecutable,
  (value) => value.pages
);
