import { Outlet } from "react-router-dom";
import { SqlFromChatAssistantProvider } from "~/contexts/ChatAssistantContext";
import { useRouteGuard } from "~/App";
import {
  SpecifiableErrorBoundary,
  TableErrorFallback,
} from "~/features/Common/ErrorBoundary";
import {
  GlobalNavigation,
  usePreferenceModal,
} from "~/features/GlobalNavigation";
import { useEffect, useMemo } from "react";
import { Toolbar } from "~/features/Toolbar";
import { useWarmup } from "~/serverActions";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { CollaborativeRoom } from "~/features/RealtimeCollaboration";
import { useMe } from "~/serverStateStore/me";

import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";

//intercom setup
import { useIntercom } from "react-use-intercom";
import { useQuery } from "react-query";
import { useGetPaymentQuery } from "~/serverStateStore";
import { Text } from "~/components_next/Text";
import { Button } from "~/components_next/Button";
import { useWatchSystemNotifications } from "~/containers/systemNotification/useWatchSystemNotifications";
import { useFindPaymentObjectLoadable } from "~/containers/plan/useFindPaymentObjectLoadable";
import { useListVersionLoadable } from "~/containers/plan/useListVersionLoadable";

export const DatabaseBasePage = () => {
  useRouteGuard();
  const { data: meData } = useMe();

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const { activateNotebook, activateDatabase } = useWarmup();
  useEffect(() => {
    activateNotebook({
      teamSlug,
      databaseId,
    });
    activateDatabase({
      teamSlug,
      databaseId,
    });
  }, [databaseId]);
  const {
    boot,
    shutdown,
    hardShutdown,
    update,
    hide,
    show,
    showMessages,
    getVisitorId,
    startTour,
    trackEvent,
  } = useIntercom();

  useEffect(() => {
    if (meData) {
      boot({
        name: `${meData.firstName} ${meData.lastName}`,
        email: meData.email,
        userId: meData.userId,
      });
      update({
        name: `${meData.firstName} ${meData.lastName}`,
        email: meData.email,
        userId: meData.userId,
      });
    }
  }, [boot, hide, meData, update]);

  /**
   * Free plan screen lock
   */
  const paymentLoadable = useFindPaymentObjectLoadable({ teamSlug })
  const listVersionLoadable = useListVersionLoadable({ teamSlug })
  const isTrialAvailable = useMemo(() => {
    if (
      paymentLoadable.data &&
      listVersionLoadable.data
    ) {
      const versions = listVersionLoadable.data
      return paymentLoadable.data.isTrialAvailable(versions) && paymentLoadable.data.plan?.name !== "Team"
    }
  }, [teamSlug])

  const { data: payment } = useQuery({
    ...useGetPaymentQuery({ teamSlug }),
  });
  const shouldLockContent = useMemo(() => {
    if (!payment) {
      return false;
    }

    if (
      payment.isLimitExceeded &&
      payment.isSubscribed === false &&
      !payment.currentTrial
    ) {
      return true;
    }

    return false;
  }, [payment]);

  const { onOpen } = usePreferenceModal();

  const showPlanInfo = () => {
    onOpen("plans");
  };

  /**
   * Watch System Notification
   */
  useWatchSystemNotifications({
    teamSlug,
    databaseId,
  });

  return (
    <>
      <CollaborativeRoom>
        <SqlFromChatAssistantProvider>
          <Flex
            css={{
              width: "100vw",
              height: "100vh",
              overflow: "hidden",
            }}
          >
            <GlobalNavigation />
            <Box grow="1" css={{ overflow: "hidden" }}>
              {shouldLockContent ? (
                <>
                  <Flex
                    justify="center"
                    align="center"
                    css={{
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                    }}
                    direction="column"
                    gap="3"
                  >
                    <Text variant="heading">
                      You have reached your plan's maximum usage.
                    </Text>
                    {
                      isTrialAvailable && (
                        <Text>You can start free trial of the Team Plan.</Text>
                      )
                    }
                    <Button variant="secondary" onClick={showPlanInfo}>
                      {
                        isTrialAvailable
                          ? 'Start Free Trial'
                          : 'Select Plan'
                      }
                    </Button>
                  </Flex>
                </>
              ) : (
                <>
                  <Toolbar />
                  <SpecifiableErrorBoundary
                    errorBoundaryKey="table"
                    FallbackComponent={TableErrorFallback}
                  >
                    <Outlet />
                  </SpecifiableErrorBoundary>
                </>
              )}
            </Box>
          </Flex>
        </SqlFromChatAssistantProvider>
      </CollaborativeRoom>
    </>
  );
};
