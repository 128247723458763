import { SimpleField } from "@usemorph/morph-dashboard-types";

import { useState } from "react";
import { Button } from "~/components_next/Button";
import { BsPlayFill } from "react-icons/bs";
import { Text } from "~/components_next/Text";
import "./prompt.css";
import { Box, Flex } from "@radix-ui/themes";
import {
  basicFieldFilterFn,
  BasicTypeaheadPrompt,
  FieldSuggestionItem,
} from "~/features/Prompt";
import { Spacer } from "~/components_next/Spacer";

type FormulaInputProps = {
  currentFormula: string | undefined;
  simpleFields: SimpleField[];
  onTestRunStart: (formula: string) => void;
  isTestRunning: boolean;
  convertFieldToText: (field: SimpleField) => string;
};

// formulaはローカルでスーテト管理し、test runの結果を見て上で状態を変更させる
const FormulaInput = (props: FormulaInputProps) => {
  const {
    currentFormula,
    simpleFields,
    onTestRunStart,
    isTestRunning,
    convertFieldToText,
  } = props;

  const [formulaInput, setFormulaInput] = useState(currentFormula ?? "");

  const handleTestRun = async () => {
    await onTestRunStart(formulaInput);
  };

  const isFormulaTestRunDisplayed =
    formulaInput && formulaInput !== currentFormula;

  return (
    <Box>
      <BasicTypeaheadPrompt<SimpleField>
        textContent={formulaInput}
        onUpdate={setFormulaInput}
        optionItems={simpleFields}
        filterFn={basicFieldFilterFn}
        renderSuggestedItem={(item) => <FieldSuggestionItem field={item} />}
        renderHTMLTextContent={(item) => item.displayName || item.name}
        convertOptionItemToText={convertFieldToText}
        theme={{ root: "field-edit__prompt-editor-root" }}
      />

      {isFormulaTestRunDisplayed && (
        <Flex mt="2" gap="1" align="center">
          <Text variant="errorMessage">Your need to confirm your formula.</Text>
          <Spacer />
          <Button
            variant="secondary"
            onClick={handleTestRun}
            isLoading={isTestRunning}
            size="xs"
          >
            <BsPlayFill />
            Test Run
          </Button>
        </Flex>
      )}
      {!!currentFormula && currentFormula === formulaInput && (
        <Text mt="2" variant="description">
          Your formula is valid.
        </Text>
      )}
    </Box>
  );
};

type FormulaReadOnlyInputProps = {
  currentFormula: string;
  simpleFields: SimpleField[];
  convertFieldToText: (field: SimpleField) => string;
};

const FormulaReadOnlyInput = (props: FormulaReadOnlyInputProps) => {
  const { currentFormula, simpleFields, convertFieldToText } = props;

  return (
    <BasicTypeaheadPrompt<SimpleField>
      textContent={currentFormula}
      optionItems={simpleFields}
      renderSuggestedItem={(item) => <FieldSuggestionItem field={item} />}
      renderHTMLTextContent={(item) => item.displayName || item.name}
      convertOptionItemToText={convertFieldToText}
      theme={{ root: "field-edit__prompt-editor-root" }}
    />
  );
};

export { FormulaInput, FormulaReadOnlyInput };
