import {
  DashboardNotebookCellVariableRequestObject,
  RecordConditionRuleUnit,
} from "@usemorph/morph-dashboard-types";
import { useQuery } from "react-query";
import { useComposeLoadable } from "~/clientModel/loadable";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import {
  FilterConditionsClientModel,
  FilterConditionsClientModelDefactory,
} from "~/clientModel/queryConditions/filterConditions";
import {
  PaginationClientModel,
  PaginationClientModelDefactory,
} from "~/clientModel/queryConditions/pagination";
import {
  SortConditionsClientModel,
  SortConditionsClientModelDefactory,
} from "~/clientModel/queryConditions/sortConditions";
import {
  RecordsClientModel,
  RecordsClientModelFactory,
} from "~/clientModel/records";
import { CanvasVariablesValue } from "~/presenters/canvas/common/CanvasVariablesProvider";
import { TablePaginationLimit } from "~/presenters/sourceAndViews/common/components/bottomItems/TablePagination";

import { useQueryViewRecordsQuery } from "~/serverStateStore/views";

export const createUseRecordsWithConditionLoadable = (props: {
  teamSlug: string;
  databaseId: string;
}): UseLoadable<
  {
    viewId: string;
    filterConditions: FilterConditionsClientModel;
    sortConditions: SortConditionsClientModel;
    pagination: PaginationClientModel<TablePaginationLimit>;
    variables?: CanvasVariablesValue[];
    enabled?: boolean;
  },
  RecordsClientModel
> => {
  const { teamSlug, databaseId } = props;

  return ({
    viewId,
    filterConditions,
    sortConditions,
    pagination,
    variables,
    join,
    enabled = true,
  }: {
    viewId: string;
    filterConditions: FilterConditionsClientModel;
    sortConditions: SortConditionsClientModel;
    pagination: PaginationClientModel<TablePaginationLimit>;
    join?: RecordConditionRuleUnit[];
    variables?: CanvasVariablesValue[];
    enabled?: boolean;
  }) => {
    return useComposeLoadable(
      useQuery({
        ...useQueryViewRecordsQuery({
          teamSlug,
          databaseId,
          viewId,
          select: ["*"],
          filter:
            FilterConditionsClientModelDefactory.toRecordFilterCondition(
              filterConditions
            ),
          sort: [
            ...SortConditionsClientModelDefactory.toRecordSortConditionUnits(
              sortConditions
            ),
          ],
          limit: PaginationClientModelDefactory.toLimit(pagination),
          skip: PaginationClientModelDefactory.toSkip(pagination),
          variables: variables as
            | DashboardNotebookCellVariableRequestObject
            | undefined,
          join,
        }),
        select: (response) =>
          RecordsClientModelFactory.createFromQueryRecordListResponseWithFields(
            response
          ),
        enabled: enabled,
      })
    );
  };
};
