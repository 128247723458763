import { DashboardTemplateDetailResponse } from "@usemorph/morph-dashboard-types";
import { SimpleSelect } from "~/components_next/Select";

interface IntegrationSelectProps {
  selectedIntegrationId?: string;
  templateDetail: DashboardTemplateDetailResponse;
  onSelect: (integrationId: string) => void;
}

export const IntegrationSelect = (props: IntegrationSelectProps) => {
  const { selectedIntegrationId, templateDetail, onSelect } = props

  return (
    <>
      <SimpleSelect
        variant="primary"
        value={selectedIntegrationId}
        onChange={(el) => {
          if (el) {
            onSelect(el)
          }
        }}
        options={[
          ...templateDetail.integrations.map((integration) => {
            return {
              label: integration.integrationName,
              value: integration.integrationId,
            };
          }),
        ]}
      />
    </>
  )
}
