import { ChangeEvent, useState } from "react";
import { Button } from "~/components_next/Button";
import { Flex } from "~/components_next/Flex";
import { Input } from "~/components_next/Input";
import { Text } from "~/components_next/Text";
import { VariableOptionElement } from "./VariableOptionElement";

type VariablesStringOptionsProps = {
  options: string[];
  onOptionsChange: (options: string[]) => void;
};

const VariablesStringOptions = (props: VariablesStringOptionsProps) => {
  const { options, onOptionsChange } = props;

  const [inputValue, setInputValue] = useState("");
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleAddOption = () => {
    onOptionsChange([...options, inputValue]);
    setInputValue("");
  };

  const handleRemoveOption = (index: number) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    onOptionsChange(newOptions);
  };

  return (
    <>
      <Flex align="center" gap="2">
        <Input
          size="xs"
          variant="primary"
          placeholder="Add option"
          onChange={onInputChange}
        />
        <Button variant="secondary" size="xs" onClick={handleAddOption}>
          Add
        </Button>
      </Flex>
      {options.map((option, index) => {
        return (
          <VariableOptionElement
            key={index}
            onRemove={() => handleRemoveOption(index)}
          >
            <Text>{option}</Text>
          </VariableOptionElement>
        );
      })}
    </>
  );
};

export { VariablesStringOptions };
