import {
  RecordsTableBase,
  getDefaultColumnSizings,
} from "~/components_next/RecordsTableBase";
import { useViewRecords } from "../../hooks/useViewRecords";

import { useColumnSizing, useSetColumnSizing } from "../../states/columnSizing";
import { extractErrorDetails, useErrorToast } from "~/components_next/Error";
import {
  RecordsTableBaseRecord,
  RecordsTableBaseSelectionState,
} from "~/components_next/RecordsTableBase/types";
import { SimpleField } from "@usemorph/morph-dashboard-types";
import { useCallback, useMemo } from "react";
import { FieldDropdown } from "./FieldDropdown";
import { useSetOpeningSidebarType } from "../../states/sidebar";
import { useSetEditingRecord } from "../../states/editingRecord";
import {
  useSelectionState,
  useSetSelectionState,
} from "../../states/selectionState";
import { RecordModel } from "~/features/RecordModel";
import { useSetRecordHighlights } from "../../states/recordHighlight";
import { useViewId } from "~/utilHooks/useViewId";
import { useView } from "~/features/SourceAndViews/common/utils/useView";
import { useTableViewCollaboration } from "../../hooks/useTableViewCollaboration";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import {
  useOptimisticUpdateViewMutation,
  useOptimisticUpdateViewRecordsMutation,
} from "~/serverStateStore/views";
import { useMutation } from "react-query";
import { convertRecordModelToValuesForRequest } from "~/features/RecordModel/utils/convertRecordModelToValuesForRequest";
import { useFilterCondition } from "../../states/filterCondition";
import { useSortCondition } from "../../states/sortCondition";
import { useSkip } from "../../states/skip";
import { useLimit } from "../../states/limit";
import { parseViewSetting } from "~/features/View";
import { createPrimaryKeyObjectForOneRecord } from "~/features/Records";
import { useSetFieldSelection } from "../../states/fieldSelection";
import { useSetRecordSelection } from "../../states/recordSelection";
import { useQueryMode } from "../../states/queryMode";
import { match, P } from "ts-pattern";
import { Callout } from "~/components_next/Callout";
import { Flex } from "@radix-ui/themes";
import { Spinner } from "~/components_next/Spinner";

const TableComponent = () => {
  /**
   * Basic params
   */
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  /**
   * Server states
   */

  const {
    data: viewRecordsData,
    status: viewRecordsStatus,
    error: viewRecordsError,
  } = useViewRecords();

  const queryMode = useQueryMode(viewId);

  const [displayedSimpleFields, displayedRecordsTableBaseRecords] =
    useMemo(() => {
      if (!viewRecordsData) return [undefined, undefined];

      // 以下を除く
      // - morph_reserved_deleted_atのもの
      // - [queryModeがfiltersAndSorts or nullのとき]isHiddenがtrueのもの
      const displayedSimpleFields = viewRecordsData.fields.filter(
        ({ isHidden, name }) => {
          const removed =
            name === "morph_reserved_deleted_at" ||
            ((!queryMode || queryMode === "filtersAndSorts") &&
              isHidden === true);
          return !removed;
        }
      );

      const displayedTableBaseRecords: RecordsTableBaseRecord[] =
        viewRecordsData.recordsTableBaseRecords.map(
          ({ values, _reservedRecordIndex }) => {
            return {
              _reservedRecordIndex,
              values: displayedSimpleFields.reduce((_values, { name }) => {
                return { ..._values, [name]: values[name] };
              }, {} as RecordModel),
            };
          }
        );

      return [displayedSimpleFields, displayedTableBaseRecords];
    }, [queryMode, viewRecordsData]);

  const { data: viewData, status: viewStatus, error: viewError } = useView();

  /**
   * Client states
   */

  const selectionState = useSelectionState(viewId);
  const setSelectionState = useSetSelectionState(viewId);

  const setRecordHighlights = useSetRecordHighlights(viewId);

  const columnSizing =
    useColumnSizing(viewId) ??
    getDefaultColumnSizings(viewRecordsData?.fields ?? []).defaultColumnSizings;
  const setColumnSizing = useSetColumnSizing(viewId);

  /**
   * Calculated states for table
   */
  const editableFields: string[] | "none" = useMemo(() => {
    if (!viewData) return "none";

    return Object.entries(viewData.rules.update).flatMap(
      ([fieldName, isUpdatable]) => (isUpdatable ? [fieldName] : [])
    );
  }, [viewData]);

  /**
   * handlers
   */
  const setOpeningSidebarType = useSetOpeningSidebarType();

  const getOriginalRecord = (
    record: RecordsTableBaseRecord
  ): RecordsTableBaseRecord | undefined => {
    const originalRecord = viewRecordsData?.recordsTableBaseRecords.find(
      ({ _reservedRecordIndex }) =>
        _reservedRecordIndex === record._reservedRecordIndex
    );
    return originalRecord;
  };

  const { mutateAsync: optimisticUpdateRecord } = useMutation(
    useOptimisticUpdateViewRecordsMutation({
      teamSlug,
      databaseId,
      viewId,
    })
  );

  const filterCondition = useFilterCondition(viewId);
  const sortCondition = useSortCondition(viewId);
  const skip = useSkip(viewId);
  const limit = useLimit(viewId);

  const handleUpdateRecord = async (record: RecordsTableBaseRecord) => {
    // recordの値はisHiddenがfalseのものだけなので、元のrecordを探す必要がある
    const originalRecord = getOriginalRecord(record);

    if (!originalRecord || !viewRecordsData) {
      errorToast("Failed to update record.");
      return;
    }

    try {
      await optimisticUpdateRecord({
        values: convertRecordModelToValuesForRequest({
          recordModelAfterEdit: record.values,
        }),
        filterForRequest: createPrimaryKeyObjectForOneRecord(
          viewRecordsData.fields,
          originalRecord.values
        ),
        currentKeyParams: {
          select: ["*"],
          filter: filterCondition ?? undefined,
          sort: sortCondition ? [sortCondition] : undefined,
          skip,
          limit,
        },
      });
    } catch (e) {
      errorToast(e);
    }
  };

  const handleSelectionChange = (
    _selectionState: RecordsTableBaseSelectionState
  ) => {
    setSelectionState(_selectionState);
    if (
      !(
        _selectionState.recordHilights &&
        _selectionState.recordHilights.length === 1
      )
    ) {
      setEditingRecord(null);
    }
  };

  const { errorToast } = useErrorToast({});

  const { mutateAsync: optimisticUpdateView } = useMutation(
    useOptimisticUpdateViewMutation({ teamSlug, databaseId, viewId })
  );

  const handleClickHideField = useCallback(
    async (targetField: SimpleField) => {
      try {
        if (!viewData || !viewRecordsData) {
          throw new Error("Failed to update field order");
        }
        const { type, name, condition, setting } = viewData;
        const parsedSetting = parseViewSetting(setting);
        await optimisticUpdateView({
          type,
          name,
          condition,
          setting: {
            data: {
              ...parsedSetting.data,
              hiddenFields: [
                ...(parsedSetting.data.hiddenFields ?? []),
                {
                  name: targetField.name,
                  originalTableSlug: targetField.originalTableSlug,
                },
              ],
            },
          },
        });
      } catch (e) {
        errorToast(e);
      }
    },
    [errorToast, viewData, optimisticUpdateView, viewRecordsData]
  );

  const handleClickEditField = (field: SimpleField) => {
    setFieldSelection([field.name]);
    setOpeningSidebarType("editField");
  };

  const setEditingRecord = useSetEditingRecord(viewId);
  const setRecordSelection = useSetRecordSelection(viewId);
  const setFieldSelection = useSetFieldSelection(viewId);
  const handleRecordClick = (record: RecordsTableBaseRecord) => {
    // recordの値はisHiddenがfalseのものだけなので、元のrecordを探す必要がある
    const originalRecord = getOriginalRecord(record);

    if (!originalRecord) {
      errorToast("Something went wrong.");
      return;
    }

    setEditingRecord(originalRecord);
    setRecordHighlights([originalRecord]);
    setFieldSelection([]);
    setRecordSelection([]);
    setOpeningSidebarType("editRecord");
  };

  const handleAddField = async () => {
    setOpeningSidebarType("createFormula");
  };

  /**
   * Realtime collaboration
   */
  // todo
  const { tableViewCoworkerState } = useTableViewCollaboration();

  /**
   * UI
   */

  if (viewRecordsStatus === "error" || viewStatus === "error") {
    const { title, description } = extractErrorDetails(
      viewRecordsError ?? viewError
    );
    return (
      <Callout size="sm" type="alert" title={title} description={description} />
    );
  }

  if (
    viewRecordsStatus === "loading" ||
    viewRecordsStatus === "idle" ||
    viewStatus === "loading" ||
    viewStatus === "idle" ||
    !displayedSimpleFields
  ) {
    return (
      <Flex height="100%" align="center" justify="center">
        <Spinner />
      </Flex>
    );
  }

  return match(queryMode)
    .with(P.union(P.nullish, "filtersAndSorts"), () => (
      <RecordsTableBase
        editableFields={editableFields}
        fields={displayedSimpleFields}
        records={displayedRecordsTableBaseRecords}
        isSelectable
        columnSizing={columnSizing}
        onColumnSizingChange={setColumnSizing}
        headerDropdown={(field) => (
          <FieldDropdown
            field={field}
            onClickHideField={handleClickHideField}
            onClickEditField={handleClickEditField}
          />
        )}
        onUpdateRecord={handleUpdateRecord}
        coworkerStates={tableViewCoworkerState}
        onRecordClick={handleRecordClick}
        selectionState={selectionState}
        onSelectionChange={handleSelectionChange}
        onAddFieldClick={handleAddField}
      />
    ))
    .with(P.union("prompt", "sql"), () => (
      <RecordsTableBase
        editableFields="none"
        fields={displayedSimpleFields}
        records={displayedRecordsTableBaseRecords}
        isSelectable={false}
      />
    ))
    .exhaustive();
};

export { TableComponent };
