import { Callout } from "~/components_next/Callout";
import { extractErrorDetails } from "~/components_next/Error";

type RecordsTableErrorFallbackProps = {
  error: unknown;
};

export const RecordsTableErrorFallback = (
  props: RecordsTableErrorFallbackProps
) => {
  const { error } = props;
  const { title, description } = extractErrorDetails(error);

  return <Callout type="alert" title={title} description={description} />;
};
