import { resourceGroupsKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import { ResourceNotebookSchema, ResourceTableSchema, ResourceViewSchema } from "@usemorph/morph-dashboard-types";
import useApiResourceGroups from "~/api/useApiResourceGroup";

interface UseListGroupGroupsQueryParams {
  teamSlug: string;
  resource: ResourceTableSchema | ResourceNotebookSchema | ResourceViewSchema;
  limit: number;
  skip: number;
}

const useListResourceGroups = ({
  teamSlug,
  resource,
  limit,
  skip,
}: UseListGroupGroupsQueryParams) => {
  const { _listResourceGroups } = useApiResourceGroups();

  const client = useQueryClient();
  client.setQueryDefaults(
    resourceGroupsKeys.listResourceGroups({
      teamSlug,
      resource,
      resourceType: resource.resourceType,
      limit,
      skip,
    }),
    commonQueryOptions
  );

  return {
    queryKey: resourceGroupsKeys.listResourceGroups({
      teamSlug,
      resource,
      resourceType: resource.resourceType,
      limit,
      skip
    }),
    queryFn: () => {
      return _listResourceGroups({
        teamSlug,
        resource,
        limit,
        skip,
      });
    },
  };
};

export { useListResourceGroups };
