import { RecordValueReadOnlyInputCommonPropsWithValueType } from "../../type";
import { DashboardUserObject } from "@usemorph/morph-dashboard-types";
import { Input } from "~/components_next/Input";

const UserReadOnlyInput = (
  props: RecordValueReadOnlyInputCommonPropsWithValueType<DashboardUserObject | null>
) => {
  const { value, errorMessages, size } = props;
  return (
    <Input
      variant="primary"
      size={size}
      readOnly={true}
      value={value?.username ?? ""}
      errorMessage={errorMessages}
    />
  );
};

export { UserReadOnlyInput };
