import { useQuery } from "react-query";
import { useFindViewQuery } from "~/serverStateStore/views";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useViewId } from "~/utilHooks/useViewId";
import { convertRecordToRecordModel } from "~/features/RecordModel";
import { useSkip } from "../states/skip";
import { useLimit } from "../states/limit";
import { useQueryMode } from "../states/queryMode";
import { useQueryingSql } from "../states/sql";
import { useListRecordsWithSQLQuery } from "~/serverStateStore";
import { UseViewRecordsResult } from "./useViewRecords";

const useViewRecordsWithSQL = (): UseViewRecordsResult => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  const queryMode = useQueryMode(viewId);

  const skip = useSkip(viewId);
  const limit = useLimit(viewId);
  const queryingSql = useQueryingSql(viewId);

  const { data: viewData } = useQuery(
    useFindViewQuery({ teamSlug, databaseId, viewId })
  );

  const sqlResult = useQuery({
    ...useListRecordsWithSQLQuery({
      teamSlug,
      databaseId,
      tableSlug: viewData!.tableSlug,
      sql: queryingSql,
      skip,
      limit,
    }),
    select: (data) => {
      return {
        recordsTableBaseRecords: data.items.map((record, index) => {
          return {
            values: convertRecordToRecordModel(record, data.fields),
            _reservedRecordIndex: index,
          };
        }),
        fields: data.fields,
        count: data.count,
      };
    },
    enabled:
      !!queryingSql &&
      !!viewData &&
      (queryMode === "sql" || queryMode === "prompt"),
  });

  return sqlResult;
};

export { useViewRecordsWithSQL, type UseViewRecordsResult };
