import * as RadixProgress from "@radix-ui/react-progress";
import { styled } from "~/stitches";
import { MorphThemeSize } from "../commonProps.type";

type ProgressProps = {
  progress: number;
  size?: MorphThemeSize;
};

const Progress = (props: ProgressProps) => {
  const { progress, size } = props;
  return (
    <ProgressRoot value={progress} size={size}>
      <ProgressIndicator
        style={{ transform: `translateX(-${100 - progress}%)` }}
      />
    </ProgressRoot>
  );
};

const ProgressRoot = styled(RadixProgress.Root, {
  variants: {
    size: {
      xs: {
        height: 4,
      },
      sm: {
        height: 8,
      },
      md: {
        height: 12,
      },
      lg: {
        height: 16,
      },
      xl: {
        height: 20,
      },
    },
  },
  position: "relative",
  overflow: "hidden",
  background: "$bg3",
  borderRadius: "9999px",
  width: "100%",

  // Fix overflow clipping in Safari
  // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
  transform: "translateZ(0)",
});

const ProgressIndicator = styled(RadixProgress.Indicator, {
  backgroundColor: "$bronze8",
  width: "100%",
  height: "100%",
  transition: "transform 660ms cubic-bezier(0.65, 0, 0.35, 1)",
});

export { Progress, type ProgressProps };
