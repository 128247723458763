import { DashboardViewConditionObject } from "@usemorph/morph-dashboard-types";
import { useState } from "react";
import { FieldsClientModel } from "~/clientModel/fields";
import { FilterConditionsClientModelFactory } from "~/clientModel/queryConditions/filterConditions";
import { PaginationClientModelFactory } from "~/clientModel/queryConditions/pagination/PaginationClientModelFactory";
import { SortConditionsClientModelFactory } from "~/clientModel/queryConditions/sortConditions";
import { CanvasTableViewQueryConditions } from "~/presenters/canvas/cellContent/view/CanvasTableViewToolbar";

export const useTableViewQueryConditions = (
  initialCondition: DashboardViewConditionObject,
  fields: FieldsClientModel,
  isFollowingToView?: boolean
) => {
  const [queryConditions, setQueryConditions] =
    useState<CanvasTableViewQueryConditions>({
      filterConditions:
        initialCondition.filter && fields
          ? FilterConditionsClientModelFactory.fromRecordFilterConditionAndOr(
              initialCondition.filter,
              fields
            )
          : FilterConditionsClientModelFactory.createEmptyFilterConditions(),
      sortConditions:
        initialCondition.sort && fields
          ? SortConditionsClientModelFactory.fromRecordSortCondition(
              initialCondition.sort,
              fields
            )
          : SortConditionsClientModelFactory.createEmptySortConditions(),
      prompt: "",
      sql: initialCondition.from.includes(" ") ? initialCondition.from : "",
      queryingSql: initialCondition.from.includes(" ")
        ? initialCondition.from
        : "",
      pagination: PaginationClientModelFactory.createInitialPagination({
        limit: 30,
      }),
    });

  // const initQueryMode = useCallback(() => {
  //   if (!isFollowingToView) {
  //     return "default";
  //   }

  //   return queryConditions.queryingSql !== ""
  //     ? "sql"
  //     : queryConditions.filterConditions.hasValidFilterConditions ||
  //       queryConditions.sortConditions.hasSortConditions
  //     ? "query"
  //     : "default";
  // }, [isFollowingToView, queryConditions]);

  const [queryMode, setQueryMode] = useState<
    "default" | "query" | "prompt" | "sql"
  >(
    (() => {
      if (isFollowingToView) {
        return "default";
      }

      return queryConditions.queryingSql !== ""
        ? "sql"
        : queryConditions.filterConditions.hasValidFilterConditions ||
          queryConditions.sortConditions.hasSortConditions
        ? "query"
        : "default";
    })()
  );

  return {
    queryMode,
    onQueryModeChange: setQueryMode,
    queryConditions,
    onQueryConditionsChange: setQueryConditions,
  };
};
