// types
import { DashboardRunImportIntegrationDataRequestBody } from "@usemorph/morph-dashboard-types";
import { useQueryClient } from "react-query";
import useApiImport from "~/api/useApiImport";
import { tablesKeys } from "~/serverStateStore/tables";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";

interface UseImportDataFromExternalServicesMutationParams {
  teamSlug: string;
  databaseId: string;
}

type UseImportDataFromExternalServicesMutationProps = DashboardRunImportIntegrationDataRequestBody

const useImportDataFromExternalServicesMutation = ({
  teamSlug,
  databaseId,
}: UseImportDataFromExternalServicesMutationParams) => {
  const { _importFormExternal } = useApiImport();

  const client = useQueryClient();

  return {
    mutationFn: (body: DashboardRunImportIntegrationDataRequestBody) => {
      return _importFormExternal({
        databaseId,
        teamSlug,
        body,
      });
    },
    onSuccess: () => {
      return invalidateAll(client, tablesKeys.all({ teamSlug, databaseId }));
    },
  };
};

export { useImportDataFromExternalServicesMutation, type UseImportDataFromExternalServicesMutationProps };
