import constate from "constate";
import { useState } from "react";

/**
 * ReactFlowの外部からセルの選択状態を触りたい時用のstate
 *
 * ルートのPresenterのローカルステートで管理してpropsで渡してしまうと、
 * ReactFlowのnode管理を経由する必要があるので、
 * nodesの再生成がたくさん発生してしまう。
 *
 * そのため、描画関連のロジックしか使わないかつ、Presenterの内部で閉じるように
 * Providerをつくる。
 */

const usePlaygroundPseudoCellSelection = () => {
  const [mouseoveredCells, setMouseoveredCells] = useState<string[]>([]);

  return {
    mouseoveredCells,
    setMouseoveredCells,
  };
};

export const [
  PlaygroundPseudoCellSelectionProvider,
  usePlaygroundPseudoHoveredCells,
  usePlaygroundSetPseudoHoveredCells,
] = constate(
  usePlaygroundPseudoCellSelection,
  (value) => value.mouseoveredCells,
  (value) => value.setMouseoveredCells
);
