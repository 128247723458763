import {
  useRecordsTableBaseOptions,
  useRecordsTableBaseRecords,
} from "../context/ReactTableBaseContext";
import { Virtualizer } from "@tanstack/react-virtual";
import { ContentRow } from "./ContentRow";
import { Header } from "../Header/Header";

type RowsProps = {
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  columnVirtualizer: Virtualizer<HTMLDivElement, Element>;
};

const Rows = (props: RowsProps) => {
  const { rowVirtualizer, columnVirtualizer } = props;

  const records = useRecordsTableBaseRecords();

  const virtualRowItems = rowVirtualizer.getVirtualItems();

  const { noStickyHeader } = useRecordsTableBaseOptions();

  const isStickyHeader = (rowIndex: number) => {
    return !noStickyHeader && rowIndex === 0;
  };

  return (
    <div
      style={{
        height: `${rowVirtualizer.getTotalSize()}px`,
        width: `${columnVirtualizer.getTotalSize()}px`,
        position: "relative",
        border: "none",
      }}
    >
      {virtualRowItems.map((virtualRowItem) => {
        return (
          <div
            key={virtualRowItem.key}
            style={{
              ...(isStickyHeader(virtualRowItem.index)
                ? {
                    position: "sticky",
                    zIndex: 1,
                  }
                : {
                    position: "absolute",
                    transform: `translateY(${virtualRowItem.start}px)`,
                  }),

              top: 0,
              left: 0,
              width: "100%",
              height: `${virtualRowItem.size}px`,
            }}
          >
            {virtualRowItem.index === 0 ? (
              <Header columnVirtualizer={columnVirtualizer} />
            ) : (
              <ContentRow
                columnVirtualizer={columnVirtualizer}
                record={records.getRecordByIndex(virtualRowItem.index - 1)}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export { Rows };
