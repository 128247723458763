import { DashboardNotebookCellVariableRequestObject } from "@usemorph/morph-dashboard-types";

type NotebookDependencies = {
  teamSlug: string;
  databaseId: string;
};

type FindNotebookDependencies = { notebookId: string } & NotebookDependencies;

type ListNotebookDependencies = {
  tableSlug?: string;
  skip?: number;
  limit?: number;
} & NotebookDependencies;

/**
 * 運用上はtableSlugでもinvalidateするものを絞ることできるが、
 * ルーティング的にtableSlugが決定できない場合があるのでkeyの依存からは外す。
 */

const notebookKeys = {
  all: ({ teamSlug, databaseId }: NotebookDependencies) =>
    [teamSlug, databaseId, "notebooks"] as const,

  /**
   * find notebook
   */

  allFindNotebooks: ({ teamSlug, databaseId }: NotebookDependencies) =>
    [...notebookKeys.all({ teamSlug, databaseId }), "findNotebook"] as const,
  findNotebook: ({
    teamSlug,
    notebookId,
    databaseId,
  }: FindNotebookDependencies) =>
    [
      ...notebookKeys.allFindNotebooks({ teamSlug, databaseId }),
      notebookId,
    ] as const,

  /**
   * list notebooks
   */
  allListNotebooks: ({ teamSlug, databaseId }: NotebookDependencies) =>
    [...notebookKeys.all({ teamSlug, databaseId }), "listNotebooks"] as const,
  listNotebooks: ({ teamSlug, databaseId }: ListNotebookDependencies) =>
    [...notebookKeys.allListNotebooks({ teamSlug, databaseId })] as const,
  listNotebooksWithTableSlug: ({
    teamSlug,
    databaseId,
    tableSlug,
  }: ListNotebookDependencies) =>
    [
      ...notebookKeys.allListNotebooks({ teamSlug, databaseId }),
      tableSlug,
    ] as const,

  usage: ({ teamSlug }: { teamSlug: string }) =>
    [teamSlug, "notebook-usage"] as const,

  /**
   * Variables
   */
  variablesViewResultOptions: ({
    teamSlug,
    notebookId,
    cellId,
    name,
    skip,
    limit,
    variables,
  }: {
    teamSlug: string;
    notebookId: string;
    cellId: string;
    name: string;
    skip?: number;
    limit?: number;
    variables?: DashboardNotebookCellVariableRequestObject;
  }) =>
    [
      teamSlug,
      notebookId,
      cellId,
      name,
      skip,
      limit,
      variables,
      "variablesViewResultOptions",
    ] as const,
  publicVariablesViewResultOptions: ({
    teamSlug,
    apiKey,
    cellId,
    name,
    skip,
    limit,
    variables,
  }: {
    teamSlug: string;
    apiKey: string;
    cellId: string;
    name: string;
    skip?: number;
    limit?: number;
    variables?: DashboardNotebookCellVariableRequestObject;
  }) =>
    [
      teamSlug,
      apiKey,
      cellId,
      name,
      skip,
      limit,
      variables,
      "variablesViewResultOptions",
    ] as const,
  publicCell: ({ teamSlug, apiKey }: { teamSlug: string; apiKey: string }) =>
    [teamSlug, apiKey, "publicCell"] as const,

  /**
   * Execute Cell
   */
  executeCell: ({
    teamSlug,
    notebookId,
    cellId,
    variables,
  }: {
    teamSlug: string;
    notebookId: string;
    cellId: string;
    variables?: DashboardNotebookCellVariableRequestObject;
  }) => [teamSlug, notebookId, cellId, variables, "executeCell"] as const,
  executePublicCell: ({
    teamSlug,
    apiKey,
    cellId,
    variables,
  }: {
    teamSlug: string;
    apiKey: string;
    cellId: string;
    variables?: DashboardNotebookCellVariableRequestObject;
  }) => [teamSlug, apiKey, cellId, variables, "executePubliCell"] as const,

  listVisualizationPromptConfigs: ({ teamSlug }: { teamSlug: string }) =>
    [teamSlug, "listVisualizationPromptConfigs"] as const,
};

export { notebookKeys };
