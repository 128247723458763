import { ReactNode } from "react";
import { Box } from "~/components_next/Box";

type CanvasTabLayoutProps = {
  tabValue: number;
  children: ReactNode[];
};

const CanvasTabLayout = (props: CanvasTabLayoutProps) => {
  const { tabValue, children } = props;

  return (
    <>
      {[...Array(children.length)].map((_, index) => {
        return (
          <Box
            width="100%"
            height="100%"
            key={index}
            css={{ display: index === tabValue ? "block" : "none" }}
          >
            {children[index]}
          </Box>
        );
      })}
    </>
  );
};

export { CanvasTabLayout };
