import { SimpleField } from "@usemorph/morph-dashboard-types";
import { Drawer } from "~/components_next/Drawer";
import { RecordsTableBase } from "~/components_next/RecordsTableBase";
import { RecordsTableBaseRecord } from "~/components_next/RecordsTableBase/types";
import { Callout } from "~/components_next/Callout";
import { Box } from "~/components_next/Box";

type FormulaTestRunResultDrawerProps = {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  testRunResultRecord: RecordsTableBaseRecord[];
  testRunResultFields: SimpleField[];
};

const FormulaTestRunResultDrawer = (props: FormulaTestRunResultDrawerProps) => {
  const { isOpen, onOpenChange, testRunResultRecord, testRunResultFields } =
    props;
  return (
    <Drawer.Root open={isOpen} onOpenChange={onOpenChange} size="xl">
      <Drawer.Content>
        <Drawer.Title>Test Run Result</Drawer.Title>
        <Drawer.Body>
          <Box mb="3">
            <Callout
              size="sm"
              type="success"
              description="Your formula is valid!"
            />
          </Box>
          <RecordsTableBase
            editableFields="none"
            records={testRunResultRecord}
            fields={testRunResultFields}
          />
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};

export { FormulaTestRunResultDrawer };
