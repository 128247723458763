class ClientError extends Error {
  public title: string;
  public description: string;

  constructor(
    { title, description }: { title: string; description: string },
    options?: ErrorOptions
  ) {
    super(title, options);
    this.title = title;
    this.description = description;
    this.name = "ClientError";
  }
}

export { ClientError };
