import { MorphThemeSize } from "~/components_next/commonProps.type";
import { SourceVariableSingleSelectObject } from "@usemorph/morph-dashboard-types";
import { CanvasCellVariablesClientModel } from "~/clientModel/canvas/CanvasCellClientModel";
import { SimpleSelect } from "~/components_next/Select";
import { UseVariableOptionsViewResultLoadable } from "../../common/CanvasVariableOptionsLoadableProvider";

type ViewResultSelectProps = {
  variableObject: SourceVariableSingleSelectObject & {
    type: "singleSelectViewRecords";
  };
  size?: MorphThemeSize;
  value?: unknown;
  onChange: (value: unknown) => void;
  useVariableOptionsLoadable: UseVariableOptionsViewResultLoadable;
  variableCellClientModel: CanvasCellVariablesClientModel;
};

const ViewResultSelect = (props: ViewResultSelectProps) => {
  const {
    variableObject,
    size,
    value = null,
    onChange,
    useVariableOptionsLoadable,
    variableCellClientModel,
  } = props;

  const optionsLoadable = useVariableOptionsLoadable({
    cellId: variableCellClientModel.cellId,
    name: variableObject.name,
    variables: [],
  });

  const options =
    optionsLoadable.data?.options.map((option) => {
      return {
        label: option.label,
        value: option.value,
      };
    }) || [];

  return (
    <>
      <SimpleSelect<unknown>
        variant="primary"
        size={size}
        value={value}
        options={options}
        getValueStringFromValue={(value: unknown) => String(value)}
        onChange={(value) => {
          if (value) onChange(value);
        }}
      />
    </>
  );
};

export { ViewResultSelect };
