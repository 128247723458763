import { DashboardGenerateSqlHistoryListResponse } from "@usemorph/morph-dashboard-types";
import { PromptHistoriesClientModel } from ".";
import { PromptHistoryClientModelFactory } from "./promptHistory";

export class PromptHistoriesClientModelFactory {
  public static createFromDashboardGenerateSqlHistoryListResponse(
    historyListResponse: DashboardGenerateSqlHistoryListResponse
  ): PromptHistoriesClientModel {
    const { count, histories } = historyListResponse;
    return new PromptHistoriesClientModel({
      totalCount: count,
      histories: histories.map((history) =>
        PromptHistoryClientModelFactory.createFromDashboardGenerateSqlHistoryObject(
          history
        )
      ),
    });
  }
}
