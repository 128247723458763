import { ReactNode } from "react";
import { BsX } from "react-icons/bs";
import { Flex } from "~/components_next/Flex";
import { IconButton } from "~/components_next/IconButton";
import { getRightButtonSize } from "./convertButtonSize";

type ClearValueButtonProps = {
  size?: "xs" | "sm" | "md";
  isDisabled: boolean;
  onClear: () => void;
};

const ClearValueButton = (props: ClearValueButtonProps) => {
  const { size, onClear, isDisabled } = props;

  return (
    <IconButton
      onClick={onClear}
      isDisabled={isDisabled}
      tooltip="Clear"
      icon={<BsX />}
      size={getRightButtonSize(size)}
    />
  );
};

type ClearValueButtonWrapperProps = {
  children: ReactNode;
};

const ClearValueButtonWrapper = (props: ClearValueButtonWrapperProps) => {
  const { children } = props;

  return (
    <Flex direction="row" align="center" gap="3">
      {children}
    </Flex>
  );
};

export { ClearValueButton, ClearValueButtonWrapper };
