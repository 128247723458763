import {
  DashboardCreateDatabaseRequestBody,
  DashboardDatabaseListResponse,
  DashboardDatabaseResponse,
  DashboardUpdateDatabaseRequestBody,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";
type DashboardGeneralResponse = {
  message: string;
};

export default function useApiDatabase() {
  const BASE_PATH = "/database";

  const { executeRequest } = useApi();

  /**
   * List
   */
  const _listDatabases = async ({
    teamSlug,
    limit,
    skip,
    orderByCreatedAt,
    orderByLastEditedAt,
    orderByLastEditedAtByUser,
    orderByName,
  }: {
    teamSlug: string;
    limit?: number;
    skip?: number;
    orderByCreatedAt?: "ASC" | "DESC";
    orderByLastEditedAt?: "ASC" | "DESC";
    orderByLastEditedAtByUser?: "ASC" | "DESC";
    orderByName?: "ASC" | "DESC";
  }): Promise<DashboardDatabaseListResponse> => {
    return executeRequest(
      "get",
      BASE_PATH,
      {
        limit,
        skip,
        orderByCreatedAt,
        orderByLastEditedAt,
        orderByLastEditedAtByUser,
        orderByName,
      },
      {
        teamSlug,
      },
      undefined
    );
  };

  const _createDatabase = async ({
    teamSlug,
    requestBody,
  }: {
    teamSlug: string;
    requestBody: DashboardCreateDatabaseRequestBody;
  }): Promise<DashboardDatabaseResponse> => {
    return executeRequest<DashboardDatabaseResponse>(
      "post",
      `/database`,
      undefined,
      {
        teamSlug,
      },
      {
        ...requestBody,
      }
    );
  };

  const _findDatabase = async ({
    teamSlug,
    databaseId,
  }: {
    teamSlug: string;
    databaseId: string;
  }): Promise<DashboardDatabaseResponse> => {
    return executeRequest<DashboardDatabaseResponse>(
      "get",
      `/database/${databaseId}`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  const _updateDatabase = async ({
    teamSlug,
    databaseId,
    requestBody,
  }: {
    teamSlug: string;
    databaseId: string;
    requestBody: DashboardUpdateDatabaseRequestBody;
  }): Promise<DashboardDatabaseResponse> => {
    return executeRequest<DashboardDatabaseResponse>(
      "PUT",
      `/database/${databaseId}`,
      undefined,
      {
        teamSlug,
      },
      {
        ...requestBody,
      }
    );
  };

  const _deleteDatabase = async ({
    teamSlug,
    databaseId,
  }: {
    teamSlug: string;
    databaseId: string;
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest<DashboardGeneralResponse>(
      "DELETE",
      `/database/${databaseId}`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  const _activateDatabase = async ({
    teamSlug,
    databaseId,
  }: {
    teamSlug: string;
    databaseId: string;
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest<DashboardGeneralResponse>(
      "POST",
      `/database/${databaseId}/activate`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  return {
    _listDatabases,
    _createDatabase,
    _findDatabase,
    _updateDatabase,
    _deleteDatabase,
    _activateDatabase,
  };
}
