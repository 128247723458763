import { useCallback } from "react";
import { PresenceKanbanViewState } from "../types";

const usePresenceKanbanViewStateUtil = () => {
  const decodeKanbanViewState = useCallback((kanbanView?: string) => {
    if (!kanbanView) return undefined;

    const parsed = JSON.parse(kanbanView);
    // 本当は形式チェック入れたい
    return parsed as PresenceKanbanViewState;
  }, []);

  return {
    decodeKanbanViewState,
  };
};

export { usePresenceKanbanViewStateUtil };
