import { Code } from "@radix-ui/themes";

type FileNameDisplayProps = {
  data: string;
};

export const FileNameDisplay = ({ data }: FileNameDisplayProps) => {
  const fileName = data.split("/")[1];

  return <Code>{fileName}</Code>;
};
