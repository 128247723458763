import constate from "constate";
import { useState } from "react";

type Limit = 10 | 30 | 50 | 100;

const usePaginationContext = () => {
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState<Limit>(30);

  return {
    skip,
    limit,
    setSkip,
    setLimit,
  };
};

export const [PaginationProvider, useSkip, useLimit, useSetSkip, useSetLimit] =
  constate(
    usePaginationContext,
    (value) => value.skip,
    (value) => value.limit,
    (value) => value.setSkip,
    (value) => value.setLimit
  );
