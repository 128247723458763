import { useMutation } from "react-query";
import { useComposeExecutable } from "~/clientModel/executable";
import { useImportRssIntoTableMutation } from "~/serverStateStore/rssFeed";


export const createUseImportRssIntoTableExecutable = (props: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
}) => {
  const { teamSlug, databaseId, tableSlug } = props

  return () => useComposeExecutable(
    useMutation(
      useImportRssIntoTableMutation({
        teamSlug,
        databaseId,
        tableSlug,
      })
    )
  )
}
