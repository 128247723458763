import {
  DashboardStorageObjectListResponse,
  DashboardStorageObjectResponse,
  DashboardGetStorageObjectUrlResponse,
  DashboardStorageObjectObject,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";

type DashboardGeneralResponse = {
  message: string;
};

export default function useApiObject() {
  const { executeRequest } = useApi();

  // storage object
  const _listObjects = async ({
    bucketId,
    teamSlug,
    limit,
    skip,
    prefix,
  }: {
    bucketId: string;
    teamSlug: string;
    limit?: number;
    skip?: number;
    prefix?: string;
  }): Promise<DashboardStorageObjectListResponse> => {
    return executeRequest<DashboardStorageObjectListResponse>(
      "post",
      `/storage/list/${bucketId}`,
      undefined,
      {
        teamSlug,
      },
      {
        limit,
        skip,
        prefix,
      }
    );
  };

  const _getObject = async ({
    bucketId,
    key,
    teamSlug,
  }: {
    bucketId: string;
    key: string;
    teamSlug: string;
  }): Promise<DashboardStorageObjectResponse> => {
    return executeRequest<DashboardStorageObjectResponse>(
      "get",
      `/storage/object/${bucketId}/${key}`,
      {},
      {
        teamSlug,
      },
      undefined
    );
  };

  const _moveObject = async ({
    bucketId,
    objectId,
    teamSlug,
    destKey,
  }: {
    bucketId: string;
    objectId: string;
    teamSlug: string;
    destKey: string;
  }): Promise<DashboardStorageObjectResponse> => {
    return executeRequest<DashboardStorageObjectResponse>(
      "put",
      `/storage/object/${bucketId}/${objectId}`,
      {
        bucketId,
        objectId,
      },
      {
        teamSlug,
      },
      {
        destKey,
      }
    );
  };

  const _deleteObject = async ({
    bucketId,
    key,
    teamSlug,
  }: {
    bucketId: string;
    key: string;
    teamSlug: string;
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest<DashboardGeneralResponse>(
      "delete",
      `/storage/object/${bucketId}/${key}`,
      {},
      {
        teamSlug,
      },
      undefined
    );
  };

  const _getPublicUrl = async ({
    bucketId,
    key,
    teamSlug,
  }: {
    bucketId: string;
    key: string;
    teamSlug: string;
  }): Promise<DashboardGetStorageObjectUrlResponse> => {
    return executeRequest<DashboardGetStorageObjectUrlResponse>(
      "get",
      `/storage/url/public/${bucketId}/${key}`,
      {},
      {
        teamSlug,
      },
      undefined
    );
  };

  const _getSignedUrl = async ({
    bucketId,
    key,
    teamSlug,
    w,
    h,
  }: {
    bucketId: string;
    key: string;
    teamSlug: string;
    w: number;
    h: number;
  }): Promise<DashboardGetStorageObjectUrlResponse> => {
    return executeRequest<DashboardGetStorageObjectUrlResponse>(
      "get",
      `/storage/url/sign/${bucketId}/${key}`,
      { w, h },
      {
        teamSlug,
      },
      undefined
    );
  };

  const _createObject = async ({
    bucketId,
    contentType = "multipart/form-data",
    teamSlug,
    key,
    userpoolUserId,
    userpoolTeamId,
    groupId,
    file,
  }: {
    bucketId: string;
    contentType?: string;
    teamSlug: string;
    key: string;
    userpoolUserId?: string;
    userpoolTeamId?: string;
    groupId?: string;
    file: File;
  }): Promise<DashboardStorageObjectObject> => {
    const params = new FormData();
    params.append("file", file);
    params.append("key", key);
    if (userpoolUserId) {
      params.append("userpoolUserId", userpoolUserId);
    }
    if (userpoolTeamId) {
      params.append("userpoolTeamId", userpoolTeamId);
    }
    if (groupId) {
      params.append("groupId", groupId);
    }
    return executeRequest<DashboardStorageObjectObject>(
      "post",
      `/storage/object/${bucketId}`,
      undefined,
      {
        "content-type": contentType,
        teamSlug,
      },
      params
    );
  };

  const _createFolder = async ({
    bucketId,
    teamSlug,
    key,
  }: {
    bucketId: string;
    teamSlug: string;
    key: string;
  }): Promise<DashboardStorageObjectResponse> => {
    return executeRequest<DashboardStorageObjectResponse>(
      "post",
      `/storage/folder/${bucketId}`,
      {
        bucketId,
      },
      {
        teamSlug,
      },
      {
        key,
      }
    );
  };

  return {
    _listObjects,
    _getObject,
    _moveObject,
    _deleteObject,
    _getPublicUrl,
    _getSignedUrl,
    _createObject,
    _createFolder,
  };
}
