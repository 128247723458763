import { ForwardedRef, forwardRef } from "react";
import {
  BigNumberRecordEntryClientModel,
  DecimalRecordEntryClientModel,
  NumberRecordEntryClientModel,
} from "~/clientModel/records/record/recordEntry";
import { Input } from "~/components_next/Input";
import {
  ClearValueButtonWrapper,
  ClearValueButton,
} from "../../atoms/ClearValueButton";
import { RecordValueInputCommonProps } from "../../type";

const NumberInput = forwardRef(function NumberInput(
  props: RecordValueInputCommonProps<
    | NumberRecordEntryClientModel
    | BigNumberRecordEntryClientModel
    | DecimalRecordEntryClientModel
  >,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { editingRecordEntry, onChange, field, size, isReadonly } = props;

  const validatedValue = editingRecordEntry.getValidatedValue(field);

  const value = validatedValue.isValid ? validatedValue.value : "";

  const errorMessages = validatedValue.errorMessages;

  const handleChange = (value: string | null) => {
    onChange?.(editingRecordEntry.updateValue(value));
  };

  return (
    <ClearValueButtonWrapper>
      <Input
        size={size}
        variant="primary"
        value={value ?? ""}
        type="number"
        onChange={(event) =>
          event.target.value.length > 0
            ? handleChange?.(event.target.value)
            : handleChange?.(null)
        }
        errorMessage={errorMessages}
        readOnly={isReadonly}
        ref={ref}
      />
      {field.isNullable && (
        <ClearValueButton
          size={size}
          isDisabled={isReadonly}
          onClear={() => handleChange(null)}
        />
      )}
    </ClearValueButtonWrapper>
  );
});

export { NumberInput };
