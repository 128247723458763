import { useMutation } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";

import { useErrorToast } from "~/components_next/Error";
import { useRunNormalizedTableMutation } from "~/serverStateStore";

type UseStartSyncExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useStartSyncExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseStartSyncExecutableParams): Executable<void, unknown> => {
  const { errorToast } = useErrorToast({});

  const runNormalizedTableOptions = useRunNormalizedTableMutation({
    teamSlug,
    databaseId,
    tableSlug,
  });

  return useComposeExecutable(
    useMutation({
      ...runNormalizedTableOptions,
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseStartSyncExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseStartSyncExecutableParams) => {
  return () =>
    useStartSyncExecutable({
      teamSlug,
      databaseId,
      tableSlug,
    });
};
