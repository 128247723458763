import useApiCanvasStyle from "~/api/useApiCanvasStyle";

const useGenerateColorsMutation = ({ teamSlug }: { teamSlug: string }) => {
  const { _generateNewColors } = useApiCanvasStyle();

  // なにもinvalidateしない
  return {
    mutationFn: ({ prompt }: { prompt: string }) => {
      return _generateNewColors({
        prompt,
        teamSlug,
      });
    },
  };
};

export { useGenerateColorsMutation };
