import { match } from "ts-pattern";
import { Flex } from "~/components_next/Flex";
import { Grid } from "~/components_next/Grid";
import { DashboardNotebookCellObject } from "@usemorph/morph-dashboard-types";
import { HTMLRichTextEditor } from "~/components_next/RichTextEditor";
import { NotebookCellVariableRow } from "../../../FlipNotebook/components/NotebookCellVariables/NotebookCellVariableRow";
import { DashboardPageViewCell } from "./cells/DashboardPageViewCell";
import { styled } from "~/stitches";
import { Box } from "~/components_next/Box";
import { IconButton } from "~/components_next/IconButton";
import { MdDragIndicator } from "react-icons/md";
import { PublicDashboardPageViewCell } from "./cells/PublicDashboardPageViewCell";
import { DashboardPagePromptCell } from "./cells/DashboardPagePromptCell";
import { useFlipVariableValues } from "~/features/Flip/context/FlipVariablesContext";
import { PublicDashboardPagePromptCell } from "./cells/PublicDashboardPagePromptCell";
import { useCachedValue } from "~/hooks/useCachedValue/useCachedValue";

type DashboardPageCellProps = {
  notebookCell: DashboardNotebookCellObject;
  isPublic?: boolean;
  notebookId?: string;
  apiKey?: string;
};

const DragHandleElement = styled(Box, {
  position: "absolute",
  top: "-6px",
  left: "-7px",
});

const DashboardPageCell = (props: DashboardPageCellProps) => {
  const { notebookCell, isPublic = false, notebookId, apiKey } = props;
  const { cellId } = notebookCell;

  const allVariables = useFlipVariableValues();
  const cachedValue = useCachedValue(allVariables, (value) =>
    JSON.stringify(value)
  );

  return (
    <Flex
      direction="column"
      gap="2"
      height="100%"
      css={{ position: "relative" }}
    >
      {/* TODO: 表示部分をNotebookと共通化する */}
      {match(notebookCell)
        .with({ cellType: "prompt" }, (_cell) => {
          // <NotebookCellPromptUnits units={_cell.outputs} isSelected={false} />
          if (apiKey && isPublic) {
            return (
              <PublicDashboardPagePromptCell
                cellObject={_cell}
                apiKey={apiKey}
              />
            );
          }
          if (!isPublic && notebookId) {
            return (
              <DashboardPagePromptCell
                cellObject={_cell}
                notebookId={notebookId}
              />
            );
          }
        })
        .with({ cellType: "variables" }, (_cell) => {
          return _cell.source?.variables?.map((variable, index) => {
            return (
              <Grid key={index} columns="2" align="center">
                <NotebookCellVariableRow
                  cellId={cellId}
                  variable={variable}
                  variables={cachedValue}
                  notebookId={notebookId}
                  apiKey={apiKey}
                />
              </Grid>
            );
          });
        })
        .with({ cellType: "richText" }, (_cell) => {
          return (
            <HTMLRichTextEditor
              html={_cell.source.richText || ""}
              isReadOnly
              height="100%"
              hideTopbar
            />
          );
        })
        .with({ cellType: "view" }, (_cell) => {
          if (isPublic) {
            return <PublicDashboardPageViewCell cellObject={_cell} />;
          }
          return <DashboardPageViewCell cellObject={_cell} />;
        })
        .otherwise(() => {
          return null;
        })}

      {/* Drag Handle */}
      {!isPublic && (
        <DragHandleElement className="page-cell-drag-handle">
          <IconButton icon={<MdDragIndicator />} tooltip="Move" size="sm" />
        </DragHandleElement>
      )}
    </Flex>
  );
};

export { DashboardPageCell, type DashboardPageCellProps };
