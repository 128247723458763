import {
  selectorFamily,
  useRecoilValue,
  useSetRecoilState,
  DefaultValue,
} from "recoil";
import {
  RecordsTableBaseRecord,
  RecordsTableBaseSelectionState,
} from "~/components_next/RecordsTableBase/types";
import { selectionStateFamily } from "./selectionState";

const recordSelectionFamily = selectorFamily<
  RecordsTableBaseRecord[] | undefined,
  { viewId: string }
>({
  key: "view-table-record-selection",
  get:
    ({ viewId }) =>
    ({ get }) => {
      const selectionState = get(selectionStateFamily({ viewId }));
      return selectionState?.recordSelection;
    },
  set:
    ({ viewId }) =>
    ({ set, reset }, newValue) => {
      if (newValue instanceof DefaultValue) {
        reset(selectionStateFamily({ viewId }));
        return;
      }

      set(
        selectionStateFamily({ viewId }),
        (prev): RecordsTableBaseSelectionState => {
          return prev
            ? { ...prev, recordSelection: newValue ?? [] }
            : {
                fieldSelection: [],
                cellSelection: [],
                recordSelection: newValue ?? [],
                editingCell: null,
              };
        }
      );
    },
});

const useRecordSelection = (viewId: string) => {
  return useRecoilValue(recordSelectionFamily({ viewId }));
};

const useSetRecordSelection = (viewId: string) => {
  return useSetRecoilState(recordSelectionFamily({ viewId }));
};

export { useRecordSelection, useSetRecordSelection };
