import {
  ValidationResult,
  zValidate,
} from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";
import { z } from "zod";

type FormulaFieldTypeClientModelData = {
  formula: string;
};

export class FormulaFieldTypeClientModel extends FieldTypeClientModelBase {
  readonly #data: FormulaFieldTypeClientModelData;

  public get type(): "formula" {
    return "formula";
  }

  public constructor(data: FormulaFieldTypeClientModelData) {
    super();
    this.#data = data;
  }

  public get fieldTypeLabel(): "Formula" {
    return "Formula";
  }

  public get isAutoGenerated(): true {
    return true;
  }

  public get isEmbeddable(): false {
    return false;
  }

  public get canBeTargetOfSyncingByComputedField(): false {
    return false;
  }

  public get formula(): string {
    return this.#data.formula;
  }

  public validate(): ValidationResult {
    return zValidate(z.string().min(1), this.#data.formula);
  }

  public get _updatableFieldTypes() {
    return [];
  }

  public updateFormula(formula: string): FormulaFieldTypeClientModel {
    return new FormulaFieldTypeClientModel({ ...this.#data, formula });
  }
}
