import { useMemo } from "react";
import {
  ErrorLoadable,
  IdleLadable,
  Loadable,
  LoadingLoadable,
  ValueLoadable,
} from "./Loadable";

export const fromLoadables = <
  DataArray extends readonly unknown[],
  SelectedData
>(
  loadables: { readonly [I in keyof DataArray]: Loadable<DataArray[I]> },
  selector: (...args: DataArray) => SelectedData
): Loadable<SelectedData> => {
  const refetch = () => {
    loadables.map((loadable) => loadable.refetch?.());
  };

  if (loadables.some((loadable) => loadable.status === "idle")) {
    return new IdleLadable(refetch);
  }

  if (loadables.some((loadable) => loadable.status === "loading")) {
    return new LoadingLoadable();
  }

  if (loadables.some((loadable) => loadable.status === "error")) {
    const error = loadables.find(
      (loadable) => loadable.status === "error"
    )?.error;

    return new ErrorLoadable(error);
  }

  return new ValueLoadable(
    selector(
      ...(loadables.map(
        (loadable) => loadable.data
      ) as readonly unknown[] as DataArray)
    )
  );
};

export const useFromLoadables = <
  DataArray extends readonly unknown[],
  SelectedData
>(
  loadables: { [I in keyof DataArray]: Loadable<DataArray[I]> },
  selector: (...args: DataArray) => SelectedData
): Loadable<SelectedData> => {
  return useMemo(
    () => fromLoadables(loadables, selector),
    [...loadables, selector]
  );
};
