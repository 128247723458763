import { Loadable } from "~/clientModel/loadable";
import { Box } from "~/components_next/Box";
import { ErrorFallback } from "~/components_next/Error";
import { Menu } from "~/components_next/Menu";
import { SkeltonText } from "~/components_next/Skelton";
import { Text } from "~/components_next/Text";
import { WithFallback } from "../../../../../../clientModel/loadable/WithFallback";

type PromptSuggestionsProps = {
  isOpen: boolean;
  suggestedPromptsLoadable: Loadable<{ messages: string[] }, unknown>;
  onClickMenuItem: (message: string) => void;
};

const PromptSuggestions = (props: PromptSuggestionsProps) => {
  const { isOpen, suggestedPromptsLoadable, onClickMenuItem } = props;

  if (!isOpen) return null;

  return (
    <Menu.Root>
      <Text ml="4" fontWeight="bold" variant="tinyDescription">
        Suggested Prompts
      </Text>
      <WithFallback
        loadables={[suggestedPromptsLoadable] as const}
        loadingFallback={<LoadingFallback />}
        errorFallback={(error) => <ErrorFallback error={error} />}
      >
        {([suggestedPrompts]) => (
          <>
            {suggestedPrompts.messages.length > 0 ? (
              suggestedPrompts.messages.map((message, i) => {
                return (
                  <Menu.Item onSelect={() => onClickMenuItem(message)} key={i}>
                    {message}
                  </Menu.Item>
                );
              })
            ) : (
              <Text
                my="4"
                style={{ textAlign: "center" }}
                variant="description"
              >
                No suggestions
              </Text>
            )}
          </>
        )}
      </WithFallback>
    </Menu.Root>
  );
};

const LoadingFallback = () => {
  return (
    <Box mx="3" my="3">
      <SkeltonText size="sm" lines={5} />
    </Box>
  );
};

export { PromptSuggestions };
