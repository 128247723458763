import { SimpleField } from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";
import { CellSelectionState, RecordsTableBaseRecord } from "../types";
import { getFieldsRange, getRecordsRange } from "../util/cellSelectionUtil";
import { isSameRecord } from "../util/recordsTableRecordUtil";

type RecordsTableBaseCellEventKey = "click" | "shiftClick" | "goEdit";

export const useHandleCellClick = (props: {
  cellSelection: CellSelectionState;
  fields: SimpleField[];
  records: RecordsTableBaseRecord[];
  handleCellSelectionChange: (cellSelection: CellSelectionState) => void;
  setEditingCell: (editingCell: {
    record: RecordsTableBaseRecord;
    fieldSlug: string;
  }) => void;
}) => {
  const {
    records,
    fields,
    cellSelection,
    handleCellSelectionChange,
    setEditingCell,
  } = props;

  const handleCellClickEvent = (
    event: RecordsTableBaseCellEventKey,
    record: RecordsTableBaseRecord,
    fieldSlug: string
  ) => {
    match([event, cellSelection.length])
      .with(["click", P._], () => {
        handleCellSelectionChange([
          {
            fieldSlugs: [fieldSlug],
            record,
          },
        ]);
      })
      .with(["shiftClick", 0], () => {
        // 左上から全部。
        const firstField = fields[0];
        const firstRecord = records[0];
        const fieldsRange = getFieldsRange(
          [firstField.name, fieldSlug],
          fields
        );

        const _records = getRecordsRange(records, [firstRecord, record]);
        const _cellSelections = _records.map((_record) => ({
          fieldSlugs: fieldsRange.map((field) => field.name),
          record: _record,
        }));
        handleCellSelectionChange(_cellSelections);
      })
      .with(["shiftClick", 1], () => {
        // 準備
        const existingCellSelection = cellSelection[0];
        const isSame = isSameRecord(existingCellSelection.record, record);
        const fieldsRange = getFieldsRange(
          [...existingCellSelection.fieldSlugs, fieldSlug],
          fields
        );

        if (isSame) {
          // 同じレコードの違うフィールドを選択した時
          // 既存のcellSelecitonにfieldSlugを追加する
          handleCellSelectionChange([
            {
              fieldSlugs: fieldsRange.map((field) => field.name),
              record,
            },
          ]);
        } else {
          // 違うレコードのフィールドを選択した時
          const _records = getRecordsRange(records, [
            existingCellSelection.record,
            record,
          ]);
          const _cellSelections = _records.map((_record) => ({
            fieldSlugs: fieldsRange.map((field) => field.name),
            record: _record,
          }));
          handleCellSelectionChange(_cellSelections);
        }
      })
      .with(["shiftClick", P._], () => {
        const existingAllRecords = cellSelection.map(
          (cellSelection) => cellSelection.record
        );
        const existingAllFileds = [
          ...new Set(
            cellSelection.flatMap((cellSelection) => cellSelection.fieldSlugs)
          ),
        ];

        const fieldsRange = getFieldsRange(
          [...existingAllFileds, fieldSlug],
          fields
        );

        const _records = getRecordsRange(records, [
          ...existingAllRecords,
          record,
        ]);
        const _cellSelections = _records.map((_record) => ({
          fieldSlugs: fieldsRange.map((field) => field.name),
          record: _record,
        }));
        handleCellSelectionChange(_cellSelections);
      })
      .with(["goEdit", P._], () => {
        setEditingCell({
          record,
          fieldSlug,
        });
      })
      .run();
  };

  return {
    handleCellClickEvent,
  };
};
