import { SimpleField } from "@usemorph/morph-dashboard-types";

const convertSimpleFieldsToSelect = (
  fields: SimpleField[],
  mainTableSlug: string
): string[] => {
  return fields.map(({ name, originalTableSlug }) =>
    originalTableSlug === mainTableSlug || !originalTableSlug
      ? name
      : `${originalTableSlug}.${name}`
  );
};

export { convertSimpleFieldsToSelect };
