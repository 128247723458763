import z from "zod";

const zodGuard = <T extends z.ZodType>(
  value: unknown,
  zodSchema: T
): value is z.infer<T> => {
  return zodSchema.safeParse(value).success;
};

export { zodGuard };
