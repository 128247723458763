import { useState } from "react";
import { useTableBucket } from "~/utilHooks/useTableBucket";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import useApiObject from "~/api/useApiObject";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

export const useUploadFile = () => {
  const [isUploading, setIsUploading] = useState(false);

  // params
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const { preprocessCreateCommonTableBucket } = useTableBucket();
  const { _createObject } = useApiObject();

  const uploadFile = async (
    file: File,
    identifier: { tableSlug: string } | { viewId: string }
  ): Promise<{ data: string; url: string }> => {
    setIsUploading(true);
    try {
      const { bucketName, bucketId } = await preprocessCreateCommonTableBucket({
        databaseId,
        ...identifier,
      });

      const timestamp = Date.now();
      const { objectName } = await _createObject({
        bucketId,
        teamSlug,
        key: `${timestamp}-${file.name}`,
        file,
      });

      const data = `morph:storage:::${bucketName}/${objectName}`;

      const url = URL.createObjectURL(file);
      return {
        data,
        url,
      };
    } finally {
      setIsUploading(false);
    }
  };

  return {
    uploadFile,
    isUploading,
  };
};
