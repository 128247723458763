import { useQueryClient } from "react-query";
import { allSimpleFieldsKeys, simpleFieldsKeys } from "../keys";
import useApiFields from "~/api/useApiField";
import { DashboardSimpleCreateFieldRequestBody } from "@usemorph/morph-dashboard-types";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { recordsKeys } from "~/serverStateStore/records/keys";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseCreateSimpleFieldMutationParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
}

const useCreateSimpleFieldMutation = ({
  databaseId,
  tableSlug,
  teamSlug,
}: UseCreateSimpleFieldMutationParams) => {
  const { _createSimpleField } = useApiFields();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      requestBody,
    }: {
      requestBody: DashboardSimpleCreateFieldRequestBody;
    }) => {
      return _createSimpleField({
        teamSlug,
        databaseId,
        tableSlug,
        requestBody,
      });
    },
    onSuccess: () => {
      const getAllSimpleField = allSimpleFieldsKeys.getAllSimpleField({ teamSlug, databaseId })
      const getSimpleField = simpleFieldsKeys.getSimpleField({ teamSlug, databaseId, tableSlug })
      const allGetRecord = recordsKeys.allGetRecord({
        teamSlug,
        databaseId,
        tableSlug,
      })
      const allGetRecordWithSql = recordsKeys.allGetRecordWithSql({
        teamSlug,
        databaseId,
        tableSlug,
      })
      broadcast(getAllSimpleField);
      broadcast(getSimpleField);
      broadcast(allGetRecord);
      broadcast(allGetRecordWithSql);

      return invalidateAll(
        client,
        getAllSimpleField,
        getSimpleField,
        allGetRecord,
        allGetRecordWithSql,
      );
    },
  };
};

export { useCreateSimpleFieldMutation };
