import { Input } from "~/components_next/Input";
import { FindUserClientModel } from "~/clientModel/user";
import { forwardRef } from "react";

type UserReadOnlyInput = {
  user: FindUserClientModel | null;
  errorMessages: string[] | undefined;
  size: "xs" | "sm" | "md" | undefined;
};

const UserReadOnlyInput = forwardRef<HTMLInputElement, UserReadOnlyInput>(
  function UserReadOnlyInput(props, ref) {
    const { user, errorMessages, size } = props;
    return (
      <Input
        ref={ref}
        variant="primary"
        size={size}
        readOnly={true}
        value={user?.username ?? ""}
        errorMessage={errorMessages}
      />
    );
  }
);

export { UserReadOnlyInput };
