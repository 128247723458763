import {
  QueryRecordListResponse,
  SimpleField,
  zQueryRecordListResponse,
  DashboardSimpleUpdateFieldRequestBody,
  DashboardSimpleFieldListResponse,
  zDashboardSimpleFieldListResponse,
  DashboardAllSimpleFieldListResponse,
  zDashboardAllSimpleFieldListResponse,
} from "@usemorph/morph-dashboard-types";
import { useQueryClient } from "react-query";
import { recordsKeys } from "~/serverStateStore/records/keys";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { removeTrailingUndefined } from "~/serverStateStore/util/removeTrailingUndefined";
import { zodGuard } from "~/utils/zodGuard";
import { allSimpleFieldsKeys, simpleFieldsKeys } from "../keys";
import { useUpdateSimpleFieldMutation } from "./useUpdateSimpleFieldMutation";

const mutationKey = "optimistic-update-simple-field";

type UseOptimisticUpdateSimpleFieldMutationParams = {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
};

export const useOptimisticUpdateSimpleFieldMutation = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseOptimisticUpdateSimpleFieldMutationParams) => {
  const client = useQueryClient();

  const getSimpleFieldKey = simpleFieldsKeys.getSimpleField({
    teamSlug,
    databaseId,
    tableSlug,
  });

  const getAllSimpleFieldKey = allSimpleFieldsKeys.getAllSimpleField({
    teamSlug,
    databaseId,
  });

  const getRecordKey = recordsKeys.getRecord({
    teamSlug,
    databaseId,
    tableSlug,
    select: ["*"],
  });

  const { mutationFn, onSuccess } = useUpdateSimpleFieldMutation({
    teamSlug,
    databaseId,
    tableSlug,
  });

  return {
    mutationKey,
    mutationFn,
    onMutate: ({
      requestBody,
    }: {
      requestBody: DashboardSimpleUpdateFieldRequestBody;
    }) => {
      const getUpdatedFields = (oldFields: SimpleField[]): SimpleField[] =>
        oldFields.map((oldField): SimpleField => {
          if (oldField.name === requestBody.name) {
            return { ...oldField, ...requestBody };
          } else {
            return oldField;
          }
        });

      // get simple field
      client
        .getQueryCache()
        .findAll(removeTrailingUndefined(getSimpleFieldKey))
        .forEach(({ queryKey, state: { data } }) => {
          if (zodGuard(data, zDashboardSimpleFieldListResponse)) {
            client.setQueryData<DashboardSimpleFieldListResponse>(queryKey, {
              fields: getUpdatedFields(data.fields),
            });
          }
        });

      // get all simple field
      client
        .getQueryCache()
        .findAll(removeTrailingUndefined(getAllSimpleFieldKey))
        .forEach(({ queryKey, state: { data } }) => {
          if (zodGuard(data, zDashboardAllSimpleFieldListResponse)) {
            client.setQueryData<DashboardAllSimpleFieldListResponse>(queryKey, {
              tables: data.tables.map((tableData) => {
                return {
                  fields:
                    tableSlug === tableData.tableSlug
                      ? getUpdatedFields(tableData.fields)
                      : tableData.fields,
                  tableSlug: tableData.tableSlug,
                  displayName: tableData.displayName,
                };
              }),
            });
          }
        });

      // get record(with simple fields)
      client
        .getQueryCache()
        .findAll(removeTrailingUndefined(getRecordKey))
        .forEach(({ queryKey, state: { data } }) => {
          if (zodGuard(data, zQueryRecordListResponse)) {
            client.setQueryData<QueryRecordListResponse>(queryKey, {
              items: data.items,
              count: data.count,
              fields: data.fields ? getUpdatedFields(data.fields) : undefined,
            });
          }
        });
    },
    onSuccess: () => {
      const count = client.isMutating({ mutationKey, exact: true });
      if (count === 1) {
        return onSuccess();
      }
    },
    onError: () => {
      return invalidateAll(
        client,
        getSimpleFieldKey,
        getAllSimpleFieldKey,
        getRecordKey
      );
    },
  };
};
