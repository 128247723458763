import { NodeProps, NodeToolbar, Position } from "reactflow";
import { CanvasCellViewClientModel } from "~/clientModel/canvas/CanvasCellClientModel";
import { PlaygroundCellCard } from "../common/PlaygroundCellCard";
import { CanvasTableView } from "~/presenters/canvas/cellContent/view/CanvasTableView";
import {
  useUseViewFieldsLoadable,
  useUseGenerateSqlFromPromptExecutable,
  useUseSuggestedPromptsLoadable,
  useUseViewRecordsLoadable,
  useUseDownloadCsvPreviewRecordsLoadable,
  useUseDownloadCsvExecutable,
} from "../../providers/PlaygroundTableViewPropsProvider";
import {
  CanvasTableViewQueryConditions,
  CanvasTableViewToolbar,
} from "~/presenters/canvas/cellContent/view/CanvasTableViewToolbar";
import { DashboardViewConditionObject } from "@usemorph/morph-dashboard-types";
import { PlaygroundControlPanelBox } from "../../controlPanel/PlaygroundControlPanelBox";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  fromLoadables,
  Loadable,
  useLoadableState,
  ValueLoadable,
} from "~/clientModel/loadable";
import { useTableViewQueryConditions } from "./useTableViewQueryConditions";
import { useDebounceState } from "~/hooks/useDebounceState";
import { useUseUpdateCellExecutable } from "../../providers/PlaygroundUpdateCellExecutableProvider";
import { FilterConditionsClientModelDefactory } from "~/clientModel/queryConditions/filterConditions";
import { SortConditionsClientModelDefactory } from "~/clientModel/queryConditions/sortConditions";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { FieldsClientModel } from "~/clientModel/fields";
import { PlaygroundCellAddToPageButton } from "../common/PlaygroundCellAddToPageButton";
import { PlaygroundCellCreateCellButton } from "../common/CreateCellButton/PlaygroundCellCreateCellButton";
import { useGetAncestorVeiwOrSourceCells } from "../../providers/PlaygroundAncestorUtilProvider";
import { P, match } from "ts-pattern";
import { PaginationClientModel } from "~/clientModel/queryConditions/pagination";
import { useDisclosure } from "~/hooks/useDisclosure";
import { PlaygroundCellViewPromptHistoryDrawer } from "./PlaygroundCellViewPromptHistoryDrawer";
import { PromptHistoryClientModel } from "~/clientModel/promptHistories/promptHistory";
import { RecordsClientModel } from "~/clientModel/records";
import {
  TableColumnSizingClientModel,
  TableColumnSizingClientModelFactory,
} from "~/clientModel/tables/tableMeta/TableColumnSizing";
import { useAncestorVariableValues } from "../../util/useAncestorVariableValues";
import {
  CsvDownloadFieldsClientModel,
  CsvDownloadFieldsClientModelFactory,
} from "~/clientModel/csvDownload/csvDownloadFields";
import { DownloadRecordsWithQueryDrawer } from "~/presenters/downloadRecords";

/**
 * 重要
 * このコンポーネントは多層構造になっている。末端から順に説明すると、
 *
 * 1. CanvasTableViewのコンポーネントに、query records等のLoadableを渡したい
 *
 * 2. useLoadableを実行するために、queryConditionの作成が完了している必要がある。
 *    初期値nullをPUTリクエストの判定に使っているので、これを保証する層が必要
 *
 * 3. queryConditionsの作成にfieldsが必要なので、それを保証する層が必要。
 */

/**
 * TODO: ファイル分割して整理する
 */

type PlaygroundCellViewProps = {
  cellClientModel: CanvasCellViewClientModel;
};

const TableViewRenderer = memo(
  (props: {
    tableHeight: number;
    onPaginationChange: (
      pagination: PaginationClientModel<10 | 30 | 50 | 100>
    ) => void;
    pagination: PaginationClientModel<10 | 30 | 50 | 100>;
    fieldsLoadable: Loadable<FieldsClientModel, unknown>;
    recordsLoadable: Loadable<RecordsClientModel, unknown>;
    onDownloadClick?: () => void;
  }) => {
    const {
      tableHeight,
      onPaginationChange,
      pagination,
      fieldsLoadable,
      recordsLoadable,
      onDownloadClick,
    } = props;

    /**
     * Column sizing
     */
    const fieldsToColumnSizing = useCallback(
      (fields: FieldsClientModel): TableColumnSizingClientModel => {
        return TableColumnSizingClientModelFactory.createEmpty({ fields });
      },
      []
    );

    const columnSizingLoadable = useMemo(() => {
      const sizingLoadable = fromLoadables(
        [fieldsLoadable],
        fieldsToColumnSizing
      );
      return sizingLoadable;
    }, [fieldsLoadable, fieldsToColumnSizing]);

    return (
      <>
        <CanvasTableView
          recordsLoadable={recordsLoadable}
          fieldsLoadable={fieldsLoadable}
          tableColumnSizingLoadable={columnSizingLoadable}
          pagination={pagination}
          onPaginationChange={onPaginationChange}
          isOpenable={false}
          height={tableHeight - 20} // 上下のpadding分を引く
          onDownloadClick={onDownloadClick}
        />
      </>
    );
  }
);

TableViewRenderer.displayName = "TableViewRenderer";

const PlaygroundCellViewContnent = memo(
  (
    props: PlaygroundCellViewProps & {
      viewId: string;
      isSelected: boolean;
      condition: DashboardViewConditionObject;
      queryConditions: CanvasTableViewQueryConditions;
      queryMode: "default" | "query" | "prompt" | "sql";
      onQueryConditionsChange: (
        queryConditions: CanvasTableViewQueryConditions
      ) => void;
      onQueryModeChange: (
        queryMode: "default" | "query" | "prompt" | "sql"
      ) => void;
      fieldsLoadable: Loadable<FieldsClientModel, unknown>;
    }
  ) => {
    const {
      viewId,
      cellClientModel,
      condition,
      queryConditions,
      queryMode,
      onQueryConditionsChange,
      onQueryModeChange,
      isSelected,
      fieldsLoadable,
    } = props;

    const queryConditionsDebouncedValue = useDebounceState(
      queryConditions,
      500
    );

    /**
     * Varaibleの処理
     */
    const variableValues = useAncestorVariableValues(cellClientModel);
    const variablesLoadable = useMemo(() => {
      return new ValueLoadable(variableValues);
    }, [variableValues]);

    const useSuggestedPromptsLoadable = useUseSuggestedPromptsLoadable();

    const _useGenerateSqlFromPromptExecutable =
      useUseGenerateSqlFromPromptExecutable();
    const useGenerateSqlFromPromptExecutable = () => {
      return _useGenerateSqlFromPromptExecutable({
        condition,
      });
    };

    /**
     * Autosave queryConditions
     */
    const useUpdateCellExecutable = useUseUpdateCellExecutable();
    const updateCellExecutable = useUpdateCellExecutable();

    /**
     * view conditionのアップデートリクエストを実行すべきかどうかの判定用に、
     * view conditionの変更のキャッシュを保持しておく
     */
    const [viewConditionCache, setViewConditionCache] =
      useState<DashboardViewConditionObject | null>(null);
    /**
     * queryConditionsはコンポーネントの表示パラメータとして使われているので、
     * それをもとにCanvas Cellのview conditionを作って更新する処理が以下。
     */
    useEffect(() => {
      const viewCondition = {
        select: cellClientModel.viewCondition?.select || ["*"],
        join: cellClientModel.viewCondition?.join || undefined,
        from:
          queryConditionsDebouncedValue.queryingSql !== ""
            ? queryConditionsDebouncedValue.queryingSql
            : cellClientModel.viewCondition?.from || "",
        filter: FilterConditionsClientModelDefactory.toRecordFilterCondition(
          queryConditionsDebouncedValue.filterConditions
        ),
        sort: SortConditionsClientModelDefactory.toRecordSortConditionUnits(
          queryConditionsDebouncedValue.sortConditions
        ),
      };

      if (viewConditionCache === null) {
        setViewConditionCache(viewCondition);
      } else {
        if (
          JSON.stringify(viewCondition) !== JSON.stringify(viewConditionCache)
        ) {
          updateCellExecutable.execute({
            cell: cellClientModel.updateCondition(viewCondition),
            shouldGenerate: false,
          });
          setViewConditionCache(viewCondition);
        }
      }
    }, [queryConditionsDebouncedValue]);

    const handleClearAllConditions = useCallback(async () => {
      const updateResponse = await updateCellExecutable.execute({
        cell: cellClientModel.clearAllCondition(),
        shouldGenerate: false,
      });
      setViewConditionCache(null);
      if (updateResponse instanceof CanvasCellViewClientModel) {
        const viewCondition = {
          select: updateResponse.viewCondition?.select || ["*"],
          join: updateResponse.viewCondition?.join || undefined,
          from: updateResponse.viewCondition?.from || "",
          filter: updateResponse.viewCondition?.filter,
          sort: updateResponse.viewCondition?.sort,
        };
      }
    }, [viewId, cellClientModel, updateCellExecutable]);

    const pagination = useMemo(() => {
      return queryConditionsDebouncedValue.pagination;
    }, [queryConditionsDebouncedValue.pagination]);

    const onPaginationChange = useCallback(
      (pagination: PaginationClientModel<10 | 30 | 50 | 100>) => {
        onQueryConditionsChange({
          ...queryConditions,
          pagination,
        });
      },
      [onQueryConditionsChange, queryConditions]
    );

    const tableHeight = useMemo(() => {
      return cellClientModel.settings?.height || 600;
    }, [cellClientModel.settings?.height]);

    /**
     * Records
     */
    const useRecordsLoadable = useUseViewRecordsLoadable();
    const recordsLoadable = useRecordsLoadable({
      viewId: viewId,
      pagination: pagination,
      variables: variableValues,
    });

    /**
     * History
     */
    const {
      isOpen: isPromptHistoryDrawerOpen,
      onOpen: onPromptHistoryDrawerOpen,
      onClose: onPromptHistoryDrawerClose,
      setIsOpen: onPromptHistoryDrawerOpenChange,
    } = useDisclosure();

    const handlePromptHistorySelected = (
      promptHistory: PromptHistoryClientModel
    ) => {
      onPromptHistoryDrawerClose();
      onQueryConditionsChange({
        ...queryConditions,
        prompt: promptHistory.message,
        sql: promptHistory.sql,
        queryingSql: promptHistory.sql,
      });
      onQueryModeChange("prompt");
    };

    /**
     * CSV download
     */
    const _useDownloadCsvPreviewRecordsLoadable =
      useUseDownloadCsvPreviewRecordsLoadable();
    const useDownloadCsvPreviewRecordsLoadable = useCallback(
      (props: { csvDownloadFields: CsvDownloadFieldsClientModel }) => {
        const { csvDownloadFields } = props;
        return _useDownloadCsvPreviewRecordsLoadable({
          viewId,
          csvDownloadFields,
        });
      },
      [viewId, _useDownloadCsvPreviewRecordsLoadable]
    );
    const _useDownloadCsvExecutable = useUseDownloadCsvExecutable();
    const useDownloadCsvExecutable = useCallback(() => {
      return _useDownloadCsvExecutable({
        viewId,
      });
    }, [viewId, _useDownloadCsvExecutable]);

    const [csvDownloadWithQueryFieldsLoadable, setCsvDownloadWithQueryFields] =
      useLoadableState<CsvDownloadFieldsClientModel>();

    const {
      onOpen: onDownloadCsvWithQueryDrawerOpen,
      isOpen: isDownloadCsvWithQueryDrawerOpen,
      setIsOpen: downloadCsvWithQueryDrawerOpenChange,
    } = useDisclosure();

    const onDownloadClick = useCallback(() => {
      onDownloadCsvWithQueryDrawerOpen();
      if (fieldsLoadable.data) {
        setCsvDownloadWithQueryFields(
          CsvDownloadFieldsClientModelFactory.fromFieldsClientModel(
            fieldsLoadable.data
          )
        );
      }
    }, [
      onDownloadCsvWithQueryDrawerOpen,
      fieldsLoadable.data,
      setCsvDownloadWithQueryFields,
    ]);

    return (
      <>
        <NodeToolbar position={Position.Top} isVisible={props.isSelected}>
          <PlaygroundControlPanelBox p="2">
            <CanvasTableViewToolbar
              recordsLoadable={recordsLoadable}
              fieldsLoadable={fieldsLoadable}
              queryConditions={queryConditions}
              onQueryConditionsChange={onQueryConditionsChange}
              queryMode={queryMode}
              onQueryModeChange={onQueryModeChange}
              useSuggestedPromptsLoadable={useSuggestedPromptsLoadable}
              viewCondition={condition}
              viewId={viewId}
              useGenerateSqlFromPromptExecutable={
                useGenerateSqlFromPromptExecutable
              }
              variablesForTypeaheadLoadable={variablesLoadable}
              onHistoryClick={onPromptHistoryDrawerOpen}
              onClearAllConditions={handleClearAllConditions}
            />
          </PlaygroundControlPanelBox>
        </NodeToolbar>
        <TableViewRenderer
          recordsLoadable={recordsLoadable}
          fieldsLoadable={fieldsLoadable}
          tableHeight={tableHeight}
          onPaginationChange={onPaginationChange}
          pagination={pagination}
          onDownloadClick={onDownloadClick}
        />
        <NodeToolbar position={Position.Bottom} isVisible={isSelected}>
          <PlaygroundCellCreateCellButton
            canvasCellClientModel={cellClientModel}
            fieldsLoadable={fieldsLoadable}
          />
        </NodeToolbar>

        <PlaygroundCellViewPromptHistoryDrawer
          isOpen={isPromptHistoryDrawerOpen}
          onOpenChange={onPromptHistoryDrawerOpenChange}
          onHistoryClick={handlePromptHistorySelected}
          viewId={viewId}
        />

        <WithFallback loadables={[csvDownloadWithQueryFieldsLoadable] as const}>
          {([csvDownloadWithQueryFields]) => (
            <DownloadRecordsWithQueryDrawer
              isOpen={isDownloadCsvWithQueryDrawerOpen}
              onOpenChange={downloadCsvWithQueryDrawerOpenChange}
              csvDownloadFields={csvDownloadWithQueryFields}
              onCsvDownloadFieldsChange={setCsvDownloadWithQueryFields}
              useDownloadExecutable={useDownloadCsvExecutable}
              usePreviewRecordsLoadable={useDownloadCsvPreviewRecordsLoadable}
            />
          )}
        </WithFallback>
      </>
    );
  }
);

PlaygroundCellViewContnent.displayName = "PlaygroundCellViewContnent";

const QueryConditionsConfirmer = (
  props: PlaygroundCellViewProps & {
    viewId: string;
    isSelected: boolean;
    condition: DashboardViewConditionObject;
    fieldsLoadable: ValueLoadable<FieldsClientModel, unknown>;
  }
) => {
  const { condition, fieldsLoadable, cellClientModel } = props;

  /**
   * ViewのConditionは、source直下の場合は保存された値から復旧されるべきで、
   * Viewに続く場合はその条件がバックエンドに保存されて、さらに追加の条件を追加できるので、復旧されるべきじゃない。
   * その判定をする。
   */
  const getAncestorViewOrSourceCells = useGetAncestorVeiwOrSourceCells();
  const ancestorViewOrSourceCells = useMemo(() => {
    return getAncestorViewOrSourceCells(cellClientModel);
  }, [cellClientModel, getAncestorViewOrSourceCells]);
  const isFollowingToView = useMemo(() => {
    if (ancestorViewOrSourceCells.length > 0) {
      return match(ancestorViewOrSourceCells[0])
        .with(P.instanceOf(CanvasCellViewClientModel), () => true)
        .otherwise(() => false);
    }
    return false;
  }, [ancestorViewOrSourceCells]);

  /**
   * 以下の第二引数のfieldsLoadableは、FilterCondition, SortConditionのClientModel作成のために必要なもの。
   * ・viewIdのみ (sqlの実行ではない) のレスポンスでよい
   */
  const {
    queryConditions,
    queryMode,
    onQueryConditionsChange,
    onQueryModeChange,
  } = useTableViewQueryConditions(
    condition,
    fieldsLoadable.data,
    isFollowingToView
  );

  if (!queryConditions) return <></>;

  return (
    <PlaygroundCellViewContnent
      {...props}
      queryConditions={queryConditions}
      queryMode={queryMode}
      onQueryConditionsChange={onQueryConditionsChange}
      onQueryModeChange={onQueryModeChange}
    />
  );
};

const FieldsLoadableConfirmer = (
  props: PlaygroundCellViewProps & {
    viewId: string;
    isSelected: boolean;
    condition: DashboardViewConditionObject;
  }
) => {
  const { viewId, cellClientModel } = props;

  const useFieldsLoadable = useUseViewFieldsLoadable();
  const fieldsLoadable = useFieldsLoadable({
    viewId: viewId,
  });

  return (
    <WithFallback loadables={[fieldsLoadable]}>
      {([fields]) => {
        return (
          <QueryConditionsConfirmer
            {...props}
            fieldsLoadable={new ValueLoadable(fields)}
            key={JSON.stringify(cellClientModel.viewCondition)}
          />
        );
      }}
    </WithFallback>
  );
};

const PlaygroundCellViewContent = memo(
  (
    props: {
      isDragging: boolean;
      isSelected: boolean;
      xPos: number;
      yPos: number;
    } & PlaygroundCellViewProps
  ) => {
    const { cellClientModel, isDragging, isSelected, xPos, yPos } = props;

    return (
      <PlaygroundCellCard
        cellClientModel={cellClientModel}
        isNodeSelected={isSelected}
        isNodeDragging={isDragging}
        position={{ x: xPos, y: yPos }}
      >
        <>
          {cellClientModel.viewId && cellClientModel.source.condition && (
            <FieldsLoadableConfirmer
              cellClientModel={cellClientModel}
              viewId={cellClientModel.viewId}
              isSelected={isSelected}
              condition={cellClientModel.source.condition}
            />
          )}
        </>
        <NodeToolbar position={Position.Left} isVisible={isSelected}>
          <PlaygroundCellAddToPageButton cellClientModel={cellClientModel} />
        </NodeToolbar>
      </PlaygroundCellCard>
    );
  }
);

PlaygroundCellViewContent.displayName = "PlaygroundCellViewContent";

const PlaygroundCellView = memo((props: NodeProps<PlaygroundCellViewProps>) => {
  const { data, selected, dragging, xPos, yPos } = props;
  const { cellClientModel } = data;

  return (
    <PlaygroundCellViewContent
      cellClientModel={cellClientModel}
      isDragging={dragging}
      isSelected={selected}
      xPos={xPos}
      yPos={yPos}
    />
  );
});

PlaygroundCellView.displayName = "PlaygroundCellView";

export { PlaygroundCellView, type PlaygroundCellViewProps };
