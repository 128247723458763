import { Box, Flex, Separator } from "@radix-ui/themes";
import {
  RecordFilterCondition,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { useCallback, useMemo } from "react";
import { Button } from "~/components_next/Button";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { Input } from "~/components_next/Input";
import { FlatFilterConditionForm } from "~/features/Fields/FilterCondition/FlatFilterConditionForm";
import {
  isFlatFilterCondition,
  isFlatFilterConditionAnd,
  isFlatFilterConditionOr,
} from "~/features/Fields/FilterCondition/flatFilterConditionTypes";
import { isArrayWithAtLeastOneElement } from "~/utils/commonTypeGuards";

type MultipleFilterSelectProps = {
  filter: RecordFilterCondition | undefined;
  fields: SimpleField[];
  onChange: (filter: RecordFilterCondition | undefined) => void;
};

export const MultipleFilterSelect = (props: MultipleFilterSelectProps) => {
  const { filter, fields, onChange } = props;

  const inputValue = useMemo(() => {
    if (isFlatFilterConditionAnd(filter)) {
      return `${filter.and.length} Filters`;
    } else if (isFlatFilterConditionOr(filter)) {
      return `${filter.or.length} Filters`;
    } else {
      return "";
    }
  }, [filter]);

  const handleSelectOpen = useCallback(() => {
    if (filter) return;

    onChange({
      and: [
        {
          key: fields[0].name,
          operator: "equal",
          value: null,
        },
      ],
    });
  }, [fields, filter, onChange]);

  // UI
  // const popoverBgColor = useColorModeValue("white", "gray.800");

  if (
    !isArrayWithAtLeastOneElement(fields) ||
    (filter !== undefined && !isFlatFilterCondition(filter))
  ) {
    return null;
  }

  return (
    <>
      <SimpleDropdownMenu
        trigger={
          <Box width="100%" onClick={handleSelectOpen}>
            <Input
              variant="primary"
              value={inputValue}
            />
          </Box>
        }
      >
        <Box style={{ minWidth: "500px" }} p="2">
          {filter ? (
            <Flex direction="column" align="start" gap="1">
              <FlatFilterConditionForm
                simpleFields={fields}
                flatFilterCondition={filter}
                onChangeFlatFilterCondition={(value) => {
                  onChange(value ?? undefined);
                }}
              />
              <Separator size="4" my="2" />
              <Button
                onClick={() => {
                  onChange(undefined);
                }}
                variant="tertiary"
                size="xs"
              >
                Clear All
              </Button>
            </Flex>
          ) : (
            <></>
          )}
        </Box>
      </SimpleDropdownMenu>
    </>
  );
};
