import { FieldClientModel } from "~/clientModel/fields/field";
import { SortConditionUnitClientModel } from "./SortConditionUnit/SortConditionUnitClientModel";

type SortConditionsClientModelData = {
  sortConditionUnits: SortConditionUnitClientModel[];
};

export class SortConditionsClientModel {
  readonly #data: SortConditionsClientModelData;

  public constructor(data: SortConditionsClientModelData) {
    this.#data = data;
  }

  public get data(): SortConditionsClientModelData {
    return this.#data;
  }

  public get allSortConditionUnits(): SortConditionUnitClientModel[] {
    return this.#data.sortConditionUnits;
  }

  public get hasSortConditions(): boolean {
    return this.#data.sortConditionUnits.length > 0;
  }

  public addInitialSortConditionUnit(
    field: FieldClientModel
  ): SortConditionsClientModel {
    return new SortConditionsClientModel({
      sortConditionUnits: [
        ...this.#data.sortConditionUnits,
        new SortConditionUnitClientModel({
          targetField: field,
          direction: "ascending",
        }),
      ],
    });
  }

  public clearAllSortConditions(): SortConditionsClientModel {
    return new SortConditionsClientModel({
      sortConditionUnits: [],
    });
  }

  public removeSortConditionByIndex(index: number): SortConditionsClientModel {
    return new SortConditionsClientModel({
      sortConditionUnits: this.#data.sortConditionUnits.filter(
        (_, i) => i !== index
      ),
    });
  }

  public replaceSortConditionItemByIndex(
    sortConditionUnit: SortConditionUnitClientModel,
    index: number
  ): SortConditionsClientModel {
    return new SortConditionsClientModel({
      sortConditionUnits: this.#data.sortConditionUnits.map((item, i) =>
        i === index ? sortConditionUnit : item
      ),
    });
  }
}
