import { useMemo } from "react";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { AggregateValueFieldTypeClientModel } from "~/clientModel/fields/field/fieldType";
import { AggregateValueFieldOperatorsClientModel } from "~/clientModel/fields/field/fieldType/computed/aggregateValue/aggregateValueFieldOperators";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { TablesClientModel } from "~/clientModel/tables";
import { TableClientModel } from "~/clientModel/tables/table";
import { Flex } from "~/components_next/Flex";
import { MergeKeyInput } from "../common/mergeKeyInput/MergeKeyInput";
import { TargetTableAndFieldInput } from "../common/TargetTableAndFieldInput";
import { OperatorInput } from "./OperatorInput";

type AggregateValueSettingsInputProps = {
  table: TableClientModel;
  fields: FieldsClientModel;
  field: FieldClientModel;
  editingFieldsForUpdateMergeKey?: FieldsClientModel; // read only時不要
  aggregateValueFieldOperators: AggregateValueFieldOperatorsClientModel;
  aggregateValueType: AggregateValueFieldTypeClientModel;
  onFieldChange: (type: FieldClientModel) => void;
  onEditingFieldsForUpdateMergeKeyChange?: (fields: FieldsClientModel) => void; // read only時不要
  tables: TablesClientModel;
  useFieldsLoadable: UseLoadable<{ tableSlug: string }, FieldsClientModel>;
  isReadOnly?: boolean;
};

export const AggregateValueSettingsInput = (
  props: AggregateValueSettingsInputProps
) => {
  const {
    table,
    fields,
    editingFieldsForUpdateMergeKey,
    field,
    aggregateValueType,
    aggregateValueFieldOperators,
    onFieldChange,
    onEditingFieldsForUpdateMergeKeyChange,
    tables,
    useFieldsLoadable,
    isReadOnly,
  } = props;

  const handleTargetChange = ({
    targetTable,
    targetField,
  }: {
    targetTable: TableClientModel;
    targetField: FieldClientModel;
  }) => {
    onFieldChange(
      field.updateType(
        aggregateValueType
          .updateSyncTargetTableSlug(targetTable.tableSlug)
          .updateSyncTargetFieldName(targetField.name)
      )
    );
  };

  const handleOperatorValueChange = (operatorValue: string) => {
    onFieldChange(
      field.updateType(aggregateValueType.updateOperatorValue(operatorValue))
    );
  };

  const targetTable = aggregateValueType.syncTargetTableSlug
    ? tables.findTableByTableSlug(aggregateValueType.syncTargetTableSlug) ??
      null
    : null;

  const targetValue = useMemo(() => {
    if (
      aggregateValueType.syncTargetTableSlug &&
      aggregateValueType.syncTargetFieldName
    ) {
      return {
        targetTableSlug: aggregateValueType.syncTargetTableSlug,
        targetFieldName: aggregateValueType.syncTargetFieldName,
      };
    }

    return null;
  }, [
    aggregateValueType.syncTargetTableSlug,
    aggregateValueType.syncTargetFieldName,
  ]);

  return (
    <Flex direction="column" gap="6">
      <OperatorInput
        value={aggregateValueType.operatorValue}
        aggregateValueFieldOperators={aggregateValueFieldOperators}
        onChange={handleOperatorValueChange}
        isReadOnly={isReadOnly}
      />
      <TargetTableAndFieldInput
        value={targetValue}
        onChange={handleTargetChange}
        tables={tables}
        useFieldsLoadable={useFieldsLoadable}
        isReadOnly={isReadOnly}
      />

      {targetTable && (
        <MergeKeyInput
          table={table}
          fields={editingFieldsForUpdateMergeKey ?? fields}
          targetTable={targetTable}
          useFieldsLoadable={useFieldsLoadable}
          onFieldsChange={onEditingFieldsForUpdateMergeKeyChange}
          isReadOnly={isReadOnly}
        />
      )}
    </Flex>
  );
};
