import { paymentKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiPayment from "~/api/useApiPayment";

interface UseGetPaymentQueryParams {
  teamSlug: string;
}

const useGetPaymentQuery = ({ teamSlug }: UseGetPaymentQueryParams) => {
  const { _findPayment } = useApiPayment();

  const client = useQueryClient();
  client.setQueryDefaults(
    paymentKeys.findPayment({ teamSlug }),
    commonQueryOptions
  );

  return {
    queryKey: paymentKeys.findPayment({
      teamSlug,
    }),
    queryFn: () => {
      return _findPayment({
        teamSlug,
      });
    },
  };
};

export { useGetPaymentQuery };
