import useApiTeam from "~/api/useApiTeam";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { teamsKeys } from "../keys";

interface useJoinTeamMutationParams {
  teamSlug: string;
}

const useJoinTeamMutation = ({ teamSlug }: useJoinTeamMutationParams) => {
  const { _joinUser } = useApiTeam();
  const client = useQueryClient();

  return {
    mutationFn: () => {
      return _joinUser({
        teamSlug,
      });
    },
    onSuccess: () => {
      return invalidateAll(client, teamsKeys.getTeam({ teamSlug }));
    },
  };
};

export { useJoinTeamMutation };
