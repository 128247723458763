/**
 * 末尾の連続するundefined要素を取り除く
 */
const removeTrailingUndefined = (key: readonly unknown[]) => {
  return [...key]
    .reverse()
    .reduce(
      (arr: unknown[], cur) =>
        arr.length === 0 && cur === undefined ? [] : [cur, ...arr],
      [] as unknown[]
    );
};

export { removeTrailingUndefined };
