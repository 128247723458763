import { Executable } from "~/clientModel/executable"
import { MessageClientModel } from "~/clientModel/general"
import { Box } from "~/components_next/Box"
import { Button } from "~/components_next/Button"
import { Flex } from "~/components_next/Flex"
import { UseMarkAsReadNotificationMutationProps } from "~/serverStateStore/notification/mutations/useMarkAsReadNotificationMutation"

interface NotificationControlProps {
  markAsReadExecutable: Executable<UseMarkAsReadNotificationMutationProps, MessageClientModel, unknown>
}

export const NotificationControl = (props: NotificationControlProps) => {
  const { markAsReadExecutable } = props

  const handleMarkAllAsRead = () => {
    markAsReadExecutable.execute({ operation: "read" })
  }

  const handleArchiveAll = () => {
    markAsReadExecutable.execute({ operation: "archive" })
  }

  return (
    <>
      <Flex gap="2" py="3">
        <Box grow="1">
          <Button
            onClick={handleMarkAllAsRead}
            isLoading={markAsReadExecutable.isExecuting}
            variant="secondary" size="sm" style={{ width: "100%" }}
          >
            Mark all as read
          </Button>
        </Box>
        <Box grow="1">
          <Button
            onClick={handleArchiveAll}
            isLoading={markAsReadExecutable.isExecuting}
            variant="secondary" size="sm" style={{ width: "100%" }}
          >
            Archive all
          </Button>
        </Box>
      </Flex>
    </>
  )
}
