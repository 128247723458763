import { DashboardSimpleImportDataResponse } from "@usemorph/morph-dashboard-types";
import { RecordsClientModel, RecordsClientModelFactory } from "../records";
import { CsvImportSchemaEditClientModel } from "./CsvImportSchemaEditClientModel";
import { CsvInsertSchemaEditClientModel } from "./CsvInsertSchemaEditClientModel";
import { CsvImportSchemaFieldClientModel } from "./CsvImportSchemaField";
import { FieldClientModelFactory } from "../fields/field";

export type CsvImportSchemaClientModelDataType =
  DashboardSimpleImportDataResponse;

export class CsvImportSchemaClientModel {
  readonly #estimatedFields: CsvImportSchemaFieldClientModel[];
  readonly #specifiedFields: CsvImportSchemaFieldClientModel[];
  readonly #previewReocrds: RecordsClientModel;

  constructor(private _data: CsvImportSchemaClientModelDataType) {
    this.#estimatedFields = _data.estimatedSchema.fields.map(
      (fieldResponse) => {
        return new CsvImportSchemaFieldClientModel(
          FieldClientModelFactory.createFromSimpleField(fieldResponse),
          fieldResponse.colNum || -1
        );
      }
    );
    this.#specifiedFields = _data.specifiedSchema.fields.map(
      (fieldResponse) => {
        return new CsvImportSchemaFieldClientModel(
          FieldClientModelFactory.createFromSimpleField(fieldResponse),
          fieldResponse.colNum || -1
        );
      }
    );

    this.#previewReocrds =
      RecordsClientModelFactory.createFromQueryRecordListResponseWithFields({
        count: _data.preview.length,
        items: _data.preview.map((eachRecordArray) => {
          const recordJson: Record<string, unknown> = {};
          _data.estimatedSchema.fields.forEach((field, i) => {
            recordJson[field.name] = eachRecordArray[i];
          });
          return recordJson;
        }),
        fields: _data.estimatedSchema.fields,
      });
  }

  get id(): string {
    return this._data.id;
  }

  get databaseId(): string {
    return this._data.databaseId;
  }

  get storageObjectId(): string {
    return this._data.storageObjectId;
  }

  get fileType(): "excel" | "csv" {
    return this._data.fileType;
  }

  get hasHeader(): boolean {
    return this._data.hasHeader;
  }

  get status() {
    return this._data.status;
  }

  get rowsProcessed() {
    return this._data.rowsProcessed;
  }

  get rowsTotal() {
    return this._data.rowsTotal;
  }

  get estimatedDataSource() {
    return this._data.estimatedSchema.datasource;
  }

  get estimatedName() {
    return this._data.estimatedSchema.name;
  }

  get estimatedFields() {
    return this.#estimatedFields;
  }

  get specifiedFields() {
    return this.#specifiedFields;
  }

  get previewRecords(): RecordsClientModel {
    return this.#previewReocrds;
  }

  toEditModel() {
    return new CsvImportSchemaEditClientModel(this);
  }

  toInsertEditModel() {
    return new CsvInsertSchemaEditClientModel(this);
  }
}
