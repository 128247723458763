import { forwardRef } from "react"
import { MdOutlineNotifications } from "react-icons/md"
import { UseLoadable } from "~/clientModel/loadable/UseLoadable"
import { WithFallback } from "~/clientModel/loadable/WithFallback"
import { ListNotificationClientModel } from "~/clientModel/notification"
import { Box } from "~/components_next/Box"
import { Flex } from "~/components_next/Flex"
import { Spinner } from "~/components_next/Spinner"
import { Text } from "~/components_next/Text"
import { UseListNotificationQueryProps } from "~/containers/notification/createUseListNotificationLoadable"

interface NotificationDropdownTriggerProps {
  useListNotificationLoadable: UseLoadable<UseListNotificationQueryProps, ListNotificationClientModel>
}

const LoadingFallback = () => {
  return (
    <Flex width="100%" height="100%" align="center" justify="center">
      <Spinner size="lg" />
    </Flex>
  );
};

const NotificationDropdownTrigger = forwardRef<HTMLDivElement, NotificationDropdownTriggerProps>((props, ref) => {
  const { useListNotificationLoadable } = props

  const listNotificationLoadable = useListNotificationLoadable({
    limit: 9999,
    skip: 0,
    isRead: false,
    isArchived: false,
  })

  return (
    <>
      <WithFallback
        loadables={[listNotificationLoadable]}
        loadingFallback={<LoadingFallback />}
      >
        {([listNotification]) => (
          <Flex
            align="center"
            justify="start"
            gap="1"
            css={{ height: "30px" }}
          >
            <MdOutlineNotifications />
            <Text variant="subheading">Notifications</Text>
            {
              listNotification.allItems.length > 0 && (
                <Box p="1" css={{ width: "8px", height: "8px", backgroundColor: "$crimson9", borderRadius: "100%" }}></Box>
              )
            }
          </Flex>
        )}
      </WithFallback>
    </>
  )
})

NotificationDropdownTrigger.displayName = "NotificationDropdownTrigger";

export { NotificationDropdownTrigger };
