import { ReactNode } from "react";
import { Flex } from "~/components_next/Flex";

type QueryToolbarLayoutProps = {
  children: ReactNode;
};

export const QueryToolbarLayout = (props: QueryToolbarLayoutProps) => {
  const { children } = props;

  return (
    <Flex gap="3" align="center">
      {children}
    </Flex>
  );
};
