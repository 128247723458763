type DataApiDependencies = {
  teamSlug: string;
  databaseId: string;
};

type GetDataApiApisDependencies = {
  tableSlug?: string;
  widgetId?: string;
  limit?: number;
  skip?: number;
} & DataApiDependencies;

type FindDataApiApiDependencies = {
  tableSlug: string;
  apiKey: string;
} & DataApiDependencies;

const dataApiKeys = {
  all: ({ teamSlug, databaseId }: DataApiDependencies) =>
    ["DataApi", teamSlug, databaseId] as const,

  /**
   * get widget data apis
   */
  allGetDataApis: ({ teamSlug, databaseId }: DataApiDependencies) =>
    [
      ...dataApiKeys.all({ teamSlug, databaseId }),
      "getDataApiApis",
    ] as const,
  getDataApis: ({
    teamSlug,
    databaseId,
    tableSlug,
    widgetId,
    limit,
    skip,
  }: GetDataApiApisDependencies) =>
    [
      ...dataApiKeys.allGetDataApis({
        teamSlug,
        databaseId,
      }),
      {
        widgetId,
        tableSlug,
        limit,
        skip,
      },
    ] as const,

  /**
   * find widget data api
   */
  allFindDataApi: ({ teamSlug, databaseId }: DataApiDependencies) =>
    [
      ...dataApiKeys.all({ teamSlug, databaseId }),
      "findDataApiApi",
    ] as const,
  findDataApi: ({
    teamSlug,
    databaseId,
    tableSlug,
    apiKey,
  }: FindDataApiApiDependencies) =>
    [
      ...dataApiKeys.allFindDataApi({
        teamSlug,
        databaseId,
      }),
      { tableSlug, apiKey },
    ] as const,
};

export { dataApiKeys };
