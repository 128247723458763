import { widgetDataKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiWidgetData from "~/api/useApiWidgetData";

type UseFindWidgetDataApiQueryParams = {
  databaseId: string;
  teamSlug: string;
  tableSlug: string;
  apiKey: string;
};

const useFindWidgetDataApiQuery = ({
  teamSlug,
  databaseId,
  tableSlug,
  apiKey,
}: UseFindWidgetDataApiQueryParams) => {
  const { _findWidgetData } = useApiWidgetData();

  const client = useQueryClient();
  client.setQueryDefaults(
    widgetDataKeys.findWidgetDataApi({
      teamSlug,
      databaseId,
      tableSlug,
      apiKey,
    }),
    commonQueryOptions
  );

  return {
    queryKey: widgetDataKeys.findWidgetDataApi({
      teamSlug,
      databaseId,
      tableSlug,
      apiKey,
    }),
    queryFn: () => {
      return _findWidgetData({
        teamSlug,
        databaseId,
        tableSlug,
        apiKey,
      });
    },
  };
};

export { useFindWidgetDataApiQuery };
