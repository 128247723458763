import { match, P } from "ts-pattern";
import { FieldClientModel } from "./field";
import { MergeKeyClientModel } from "./field/mergeKey";

type FieldsClientModelData = {
  fields: FieldClientModel[];
};

export class FieldsClientModel {
  readonly #data: FieldsClientModelData;

  constructor(data: FieldsClientModelData) {
    this.#data = data;
  }

  public get data(): FieldsClientModelData {
    return this.#data;
  }

  public get allFields(): FieldClientModel[] {
    return this.#data.fields;
  }

  public get embeddedFields(): FieldsClientModel {
    return new FieldsClientModel({
      ...this.#data,
      fields: this.#data.fields.filter((field) => field.isEmbedded),
    });
  }

  public get embeddableFields(): FieldsClientModel {
    return new FieldsClientModel({
      ...this.#data,
      fields: this.#data.fields.filter((field) => field.isEmbeddable),
    });
  }

  public get primaryFields(): FieldsClientModel {
    return new FieldsClientModel({
      ...this.#data,
      fields: this.#data.fields.filter((field) => field.isPrimary),
    });
  }

  public get visibleFields(): FieldsClientModel {
    return new FieldsClientModel({
      ...this.#data,
      fields: this.#data.fields.filter((field) => !field.isHidden),
    });
  }

  public get hiddenFields(): FieldsClientModel {
    return new FieldsClientModel({
      ...this.#data,
      fields: this.#data.fields.filter((field) => field.isHidden),
    });
  }

  public get count(): number {
    return this.#data.fields.length;
  }

  public get fieldsToBeNullableForCreatingComputedField(): FieldsClientModel {
    // computed fieldを作成するためには、computed fieldとmerge field以外の自動生成ではないフィールドが全てnullableである必要がある。
    // nullableであるべきだが、non-nullableなフィールドの一覧を返す

    const mergeField = this.mergeKeyField;

    if (!mergeField) {
      return this;
    }

    return new FieldsClientModel({
      fields: this.#data.fields.filter(
        (field) =>
          field.name !== mergeField.name &&
          !field.isAutoGenerated &&
          !field.isNullable
      ),
    });
  }

  public getFieldByFieldNameOrThrow(fieldName: string): FieldClientModel {
    const field = this.#data.fields.find((field) => field.name === fieldName);
    if (!field) {
      throw new Error(`Field ${fieldName} not found`);
    }
    return field;
  }

  public getFieldByFieldName(fieldName: string): FieldClientModel | null {
    return this.#data.fields.find((field) => field.name === fieldName) ?? null;
  }

  public getNextVisibleField(fieldName: string): FieldClientModel | null {
    const visibleFields = this.visibleFields.allFields;
    const fieldIndex = visibleFields.findIndex(
      (field) => field.name === fieldName
    );

    return fieldIndex === visibleFields.length - 1
      ? null
      : visibleFields[fieldIndex + 1];
  }

  public getPreviousVisibleField(fieldName: string): FieldClientModel | null {
    const visibleFields = this.visibleFields.allFields;
    const fieldIndex = visibleFields.findIndex(
      (field) => field.name === fieldName
    );

    return fieldIndex === 0 ? null : visibleFields[fieldIndex - 1];
  }

  // fieldNamesの中で最も左にあるフィールドを返す
  public getTheLeftmostFieldName(fieldNames: string[]): FieldClientModel {
    const visibleFields = this.visibleFields.allFields;
    const visibleFieldNames = this.visibleFields.allFields.map(
      (field) => field.name
    );

    const minIndex = Math.min(
      ...fieldNames.map((fieldName) => visibleFieldNames.indexOf(fieldName))
    );

    return visibleFields[minIndex];
  }

  // fieldNamesの中で最も右にあるフィールドを返す
  public getTheRightmostFieldName(fieldNames: string[]): FieldClientModel {
    const visibleFields = this.visibleFields.allFields;
    const visibleFieldNames = this.visibleFields.allFields.map(
      (field) => field.name
    );

    const maxIndex = Math.max(
      ...fieldNames.map((fieldName) => visibleFieldNames.indexOf(fieldName))
    );

    return visibleFields[maxIndex];
  }

  public get hasComputedField(): boolean {
    return this.#data.fields.some((field) =>
      match(field.type.type)
        .with(
          P.union("syncValue", "generateText", "calculation", "aggregateValue"),
          () => true
        )
        .with(
          P.union(
            "number",
            "boolean",
            "shortText",
            "longText",
            "email",
            "phoneNumber",
            "url",
            "date",
            "datetime",
            "time",
            "bigNumber",
            "decimal",
            "autoNumber",
            "autoBigNumber",
            "singleSelect",
            "multiSelect",
            "image",
            "attachment",
            "json",
            "array",
            "html",
            "formula",
            "createdAt",
            "lastEditedAt",
            "createdBy",
            "lastEditedBy",
            "smartFunction"
          ),
          () => false
        )
        .exhaustive()
    );
  }

  public get mergeKey(): MergeKeyClientModel | null {
    return this.mergeKeyField?.mergeKey ?? null;
  }

  public get mergeKeyField(): FieldClientModel | null {
    return this.#data.fields.find((field) => field.mergeKey) ?? null;
  }

  public findMergeKeyTargetFieldName(targetTableSlug: string): string | null {
    if (!this.mergeKey) return null;

    return this.mergeKey.findTargetFieldName(targetTableSlug);
  }

  public get canChangeMergeKeyField(): boolean {
    return !this.hasComputedField;
  }

  public changeMergeKeyField({
    fieldName,
  }: {
    fieldName: string;
  }): FieldsClientModel {
    return new FieldsClientModel({
      ...this.#data,
      fields: this.#data.fields.map((field) => {
        if (field.mergeKey && field.name !== fieldName) {
          return field.removeMergeKey();
        }

        if (field.name === fieldName) {
          return field.initMergeKey();
        }

        return field;
      }),
    });
  }

  public get canAddMergeKeyTarget(): boolean {
    return !!this.mergeKey;
  }

  public addMergeKeyTarget({
    targetTableSlug,
    targetFieldName,
  }: {
    targetTableSlug: string;
    targetFieldName: string;
  }): FieldsClientModel {
    const mergeKeyField = this.mergeKeyField;
    if (!mergeKeyField) {
      throw new Error("Merge key field not found");
    }

    return new FieldsClientModel({
      ...this.#data,
      fields: this.#data.fields.map((field) => {
        if (field.name === mergeKeyField.name) {
          return field.addMergeKeyTarget({ targetTableSlug, targetFieldName });
        }
        return field;
      }),
    });
  }
}
