import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil";
import { RecordsTableBaseSelectionState } from "~/components_next/RecordsTableBase/types";

const selectionStateFamily = atomFamily<
  RecordsTableBaseSelectionState | undefined,
  { viewId: string }
>({
  key: "table-view-selection-state",
  default: undefined,
});

const useSelectionState = (viewId: string) => {
  return useRecoilValue(selectionStateFamily({ viewId }));
};

const useSetSelectionState = (viewId: string) => {
  return useSetRecoilState(selectionStateFamily({ viewId }));
};

export { selectionStateFamily, useSelectionState, useSetSelectionState };
