import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translation_en from "./en.json";
import translation_ja from "./ja.json";

const resources = {
  ja: {
    translation: translation_ja,
  },
  en: {
    translation: translation_en,
  },
} as const;

const defaultNS = "translation";

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
  lng: "ja",
  ns: ["translation"],
  resources,
  defaultNS,
});

export { resources, defaultNS };
