import { SimpleSelect } from "~/components_next/Select/SimpleSelect/SimpleSelect";
import { RecordValueInputCommonProps } from "../../type";
import { ForwardedRef, forwardRef, useMemo } from "react";
import { match, P } from "ts-pattern";
import { SingleSelectFieldTypeClientModel } from "~/clientModel/fields/field/fieldType";
import { SingleSelectRecordEntryClientModel } from "~/clientModel/records/record/recordEntry";

const SingleSelectInput = forwardRef(function SingleSelectInput(
  props: RecordValueInputCommonProps<SingleSelectRecordEntryClientModel>,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const { editingRecordEntry, onChange, field, size, isReadonly } = props;

  const validatedValue = editingRecordEntry.getValidatedValue(field);

  const value = validatedValue.isValid ? validatedValue.value : null;

  const errorMessages = validatedValue.errorMessages;

  const handleChange = (value: string | null) => {
    onChange?.(editingRecordEntry.updateValue(value));
  };

  const options = useMemo(
    () =>
      match(field.type)
        .with(P.instanceOf(SingleSelectFieldTypeClientModel), (type) =>
          type.members.map((member) => ({ value: member, label: member }))
        )
        .otherwise(() => {
          console.error("Invalid field type for SingleSelectInput");
          return [];
        }),
    [field.type]
  );

  return (
    <SimpleSelect
      variant="primary"
      size={size}
      options={options}
      value={value ?? null}
      onChange={handleChange}
      errorMessages={errorMessages}
      isClearable={field.isNullable && !isReadonly}
      isReadOnly={isReadonly}
      noPortal
      ref={ref}
    />
  );
});

export { SingleSelectInput };
