import { FieldsClientModel } from "~/clientModel/fields";
import { RecordsClientModel } from "~/clientModel/records";
import { RecordClientModel } from "~/clientModel/records/record";
import { RecordIdentifierClientModel } from "~/clientModel/records/record/recordIdentifier";

export type RecordSelectionStateClientModelData = {
  recordIdentifiers: RecordIdentifierClientModel[];
};

export class RecordSelectionStateClientModel {
  constructor(readonly data: RecordSelectionStateClientModelData) {}

  public isRecordSelected({
    recordIdentifier,
  }: {
    recordIdentifier: RecordIdentifierClientModel;
  }): boolean {
    return this.data.recordIdentifiers.some((selectedRecordIdentifier) =>
      selectedRecordIdentifier.isSame(recordIdentifier)
    );
  }

  public get selectedRecordsIdentifiers(): RecordIdentifierClientModel[] {
    return this.data.recordIdentifiers;
  }

  public isAllSelected({
    recordIdentifiers,
  }: {
    recordIdentifiers: RecordIdentifierClientModel[];
  }): boolean {
    return recordIdentifiers.every((recordIdentifier) =>
      this.isRecordSelected({ recordIdentifier })
    );
  }

  public isSomeRecordSelected(): boolean {
    return this.data.recordIdentifiers.length > 0;
  }

  public selectRecord({
    recordIdentifier,
  }: {
    recordIdentifier: RecordIdentifierClientModel;
  }): RecordSelectionStateClientModel {
    return new RecordSelectionStateClientModel({
      recordIdentifiers: [...this.data.recordIdentifiers, recordIdentifier],
    });
  }

  public selectRecords({
    recordIdentifiers,
  }: {
    recordIdentifiers: RecordIdentifierClientModel[];
  }): RecordSelectionStateClientModel {
    return new RecordSelectionStateClientModel({
      recordIdentifiers,
    });
  }

  public unselectRecord({
    recordIdentifier,
  }: {
    recordIdentifier: RecordIdentifierClientModel;
  }): RecordSelectionStateClientModel {
    return new RecordSelectionStateClientModel({
      recordIdentifiers: this.data.recordIdentifiers.filter(
        (selectedRecordIdentifier) =>
          !selectedRecordIdentifier.isSame(recordIdentifier)
      ),
    });
  }

  public unselectAll(): RecordSelectionStateClientModel {
    return new RecordSelectionStateClientModel({
      recordIdentifiers: [],
    });
  }

  public getClipboardValue({
    records,
    fields,
  }: {
    records: RecordsClientModel;
    fields: FieldsClientModel;
  }): string {
    const selectedRecords: RecordClientModel[] = records.allRecords.filter(
      (record) =>
        this.data.recordIdentifiers.some((selectedRecordIdentifier) =>
          selectedRecordIdentifier.isSame(record.recordIdentifier)
        )
    );
    const clipboardValue: string[] = selectedRecords.map((record) => {
      const visibleFieldNames = fields.visibleFields.allFields.map(
        ({ name }) => name
      );
      const values = record
        .selectEntries(visibleFieldNames)
        .allEntries.map((entry) => `${entry.rawValue}`);

      return values.join(`\t`);
    });

    return [...clipboardValue].join("\n") + "\n";
  }
}
