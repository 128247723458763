import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { templateAuthKeys } from "../keys";
import useApiTemplateAuth from '~/api/useApiTemplateAuth';

interface UseUpdateTemplateAuthSettingMutationParams {
  templateId: string;
  teamSlug: string;
}

const useUpdateTemplateAuthSettingMutation = ({
  templateId,
  teamSlug,
}: UseUpdateTemplateAuthSettingMutationParams) => {
  const { _updateTemplateAuthSetting } = useApiTemplateAuth()
  const client = useQueryClient();

  return {
    mutationFn: ({
      templateAuthId,
      title,
      label,
    }: {
      templateAuthId: string;
      title?: string;
      label?: string;
    }) => {
      return _updateTemplateAuthSetting({
        templateId,
        templateAuthId,
        teamSlug,
        title,
        label,
      });
    },
    onSuccess: () => {
      const keys = templateAuthKeys.allListTemplateAuth({ templateId });
      return invalidateAll(client, keys);
    },
  };
};

export { useUpdateTemplateAuthSettingMutation };
