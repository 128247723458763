import {
  createContext,
  forwardRef,
  ReactNode,
  useContext,
  useRef,
} from "react";
import { match, P } from "ts-pattern";
import { styled, keyframes } from "~/stitches";
import { Box } from "../Box";
import { MorphThemeSize } from "../commonProps.type";

type NativeDialogRootProps = {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  children: ReactNode;
  size?: MorphThemeSize | "full";
  scrollBehavior?: "inside" | "outside";
};

type NativeDialogContextType = Required<
  Pick<NativeDialogRootProps, "scrollBehavior" | "size">
>;

const DialogContext = createContext({} as NativeDialogContextType);

const useDialogContext = () => {
  return useContext(DialogContext);
};

const fadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

const StyledDialogRoot = styled("dialog", {
  "&[open]": {
    animation: `${fadeIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  "&::backdrop": {
    backgroundColor: "rgba(0, 8, 47, 0.275)",
  },
});

const NativeDialogRoot = (props: NativeDialogRootProps) => {
  const {
    open,
    onOpenChange,
    children,
    size = "md",
    scrollBehavior = "inside",
  } = props;

  const dialogRef = useRef<HTMLDialogElement>(null);

  if (dialogRef.current) {
    if (open) {
      dialogRef.current.showModal();
    } else {
      dialogRef.current.close();
    }
  }

  const handleDialogClick = () => {
    onOpenChange(false);
  };

  return (
    <DialogContext.Provider value={{ scrollBehavior, size }}>
      <StyledDialogRoot ref={dialogRef} onClick={handleDialogClick}>
        <div onClick={(event) => event.stopPropagation()}>{children}</div>
      </StyledDialogRoot>
    </DialogContext.Provider>
  );
};

/**
 * Dialog Content
 */

type NativeDialogContentProps = {
  children: ReactNode;
};

const StyledDialogContent = styled(Box, {
  display: "flex",
  flexDirection: "column",
  padding: "$6 $5 $3 $5",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "var(--radius-4)",
  backgroundColor: "white",
  boxShadow: "var(--shadow-4)",
  animation: "contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1)",
});

const NativeDialogContent = forwardRef<
  HTMLDivElement,
  NativeDialogContentProps
>((props, ref) => {
  const { children } = props;

  const { size, scrollBehavior } = useDialogContext();

  const width = match(size)
    .with("xs", () => "50vw")
    .with("sm", () => "60vw")
    .with("md", () => "70vw")
    .with("lg", () => "80vw")
    .with("xl", () => "90vw")
    .with("full", () => "100vw")
    .exhaustive();

  const height = match([size, scrollBehavior])
    .with(
      [P.union("xs", "sm", "md", "lg", "xl"), "inside"],
      () => "content-fit"
    )
    .with(["full", P._], () => "100vh")
    .with([P._, "outside"], () => "content-fit")
    .exhaustive();

  const maxWidth = match(size)
    .with(P.union("xs", "sm", "md", "lg", "xl"), () => "1200px")
    .with("full", () => "none")
    .exhaustive();

  return (
    <StyledDialogContent
      ref={ref}
      style={{
        width,
        height,
        maxWidth,
        minWidth: "400px",
      }}
    >
      {children}
    </StyledDialogContent>
  );
});

NativeDialogContent.displayName = "NativeDialogContent";

const NativeDialog = {
  Root: NativeDialogRoot,
  Content: NativeDialogContent,
};

export { NativeDialog };
