import { useState } from "react";
import { FilterConditionsClientModelFactory } from "~/clientModel/queryConditions/filterConditions";
import { PaginationClientModelFactory } from "~/clientModel/queryConditions/pagination/PaginationClientModelFactory";
import { SortConditionsClientModelFactory } from "~/clientModel/queryConditions/sortConditions";
import { PublicCanvasTableViewQueryConditions } from "../../cellContent/publicView/PublicCanvasTableViewToolbar";

export const usePublicDashboardTableViewQueryConditions = () => {
  const [queryConditions, setQueryConditions] =
    useState<PublicCanvasTableViewQueryConditions>({
      filterConditions:
        FilterConditionsClientModelFactory.createEmptyFilterConditions(),
      sortConditions:
        SortConditionsClientModelFactory.createEmptySortConditions(),
      pagination: PaginationClientModelFactory.createInitialPagination({
        limit: 30,
      }),
    });

  return {
    queryConditions,
    onQueryConditionsChange: setQueryConditions,
  };
};
