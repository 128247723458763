type TableHistoriesDependencies = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

type ListTableHistoryDependencies = {
  skip?: number;
  limit?: number;
} & TableHistoriesDependencies;

const tableHistoriesKeys = {
  all: ({ teamSlug, databaseId, tableSlug }: TableHistoriesDependencies) =>
    ["tableHistories", teamSlug, databaseId, tableSlug] as const,

  /**
   * list table history
   */
  allListTableHistory: ({
    teamSlug,
    databaseId,
    tableSlug,
  }: TableHistoriesDependencies) =>
    [
      ...tableHistoriesKeys.all({ teamSlug, databaseId, tableSlug }),
      "listTableHistory",
    ] as const,
  listTableHistory: ({
    teamSlug,
    databaseId,
    tableSlug,
    skip,
    limit,
  }: ListTableHistoryDependencies) =>
    [
      ...tableHistoriesKeys.allListTableHistory({
        teamSlug,
        databaseId,
        tableSlug,
      }),
      { skip, limit },
    ] as const,
  listAllTableHistory: ({
    teamSlug,
    databaseId,
    skip,
    limit,
  }: Omit<ListTableHistoryDependencies, "tableSlug">) =>
    [
      ["tableHistories", teamSlug, databaseId, "allTables"],
      { skip, limit },
    ] as const,
};

export { tableHistoriesKeys };
