import { useMutation } from "react-query";
import { DatabaseClientModelFactory } from "~/clientModel/database/DatabaseClientModel";
import { useComposeExecutable } from "~/clientModel/executable";
import { CreateDatabaseMutationProps, useCreateDatabaseMutation } from "~/serverStateStore";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

export const useCreateDatabase = () => {
  const teamSlug = useTeamSlug();

  const createDatabaseMutationOptions = useCreateDatabaseMutation({ teamSlug })
  const createDatabaseMutationResult = useMutation({
    ...createDatabaseMutationOptions,
    mutationFn: async (params: CreateDatabaseMutationProps) => {
      const response = await createDatabaseMutationOptions.mutationFn(params)
      return DatabaseClientModelFactory.fromfindDatabaseResponse(response)
    }
  })

  const createDatabaseExecutable = useComposeExecutable(createDatabaseMutationResult)

  return {
    createDatabaseExecutable,
  }
}
