import { FC } from "react";
import {
  LuAlignCenter,
  LuAlignLeft,
  LuAlignRight,
  LuBold,
  LuCode,
  LuDivide,
  LuHeading1,
  LuHeading2,
  LuHeading3,
  LuHeading4,
  LuHeading5,
  LuHeading6,
  LuHighlighter,
  LuImage,
  LuItalic,
  LuLink,
  LuList,
  LuListOrdered,
  LuQuote,
  LuRedo,
  LuRemoveFormatting,
  LuStrikethrough,
  LuSubscript,
  LuSuperscript,
  LuUnderline,
  LuUndo,
} from "react-icons/lu";
import { IconCommonProps } from "../type";
import { getColor, getSizePx } from "./constants";

const richTextIcons = {
  alignCenter: LuAlignCenter,
  alignLeft: LuAlignLeft,
  alignRight: LuAlignRight,
  bold: LuBold,
  code: LuCode,
  heading1: LuHeading1,
  heading2: LuHeading2,
  heading3: LuHeading3,
  heading4: LuHeading4,
  heading5: LuHeading5,
  heading6: LuHeading6,
  highlighter: LuHighlighter,
  image: LuImage,
  italic: LuItalic,
  bulletList: LuList,
  orderedList: LuListOrdered,
  horizontalRule: LuDivide,
  quote: LuQuote,
  redo: LuRedo,
  clearFormat: LuRemoveFormatting,
  strikethrough: LuStrikethrough,
  subscript: LuSubscript,
  superscript: LuSuperscript,
  underline: LuUnderline,
  undo: LuUndo,
  link: LuLink,
} as const satisfies Record<string, FC>;

type RichTextIconType = keyof typeof richTextIcons;

type RichTextIconProps = {
  type: RichTextIconType;
  isActive: boolean;
} & IconCommonProps;

const RichTextIcon = (props: RichTextIconProps) => {
  const { size, isActive, type } = props;

  const color = getColor(isActive ? "black" : "gray");
  const iconProps = { size: getSizePx(size), color };

  const Component = richTextIcons[type];

  return <Component {...iconProps} />;
};

export { RichTextIcon };
export type { RichTextIconProps };
