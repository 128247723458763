import { useEffect } from "react";
import useApiNotification from "~/api/useApiNotification";
import Pusher from "pusher-js/with-encryption";
import {
  DashboardImportIntegrationStatusObject,
  DashboardNormalizedTableStatusObject,
  DashboardTeamUserObject,
  NotificationCategoryType,
  NotificationCsvBody,
  NotificationFromType,
  NotificationIntegrationBody,
  NotificationNormalizationBody,
  NotificationToType,
  PusherChannelEvent,
} from "@usemorph/morph-dashboard-types";
import { useQueryClient } from "react-query";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { importsKeys, recordsKeys, tablesKeys } from "~/serverStateStore";

type NotificationPusherEventPayload = {
  userIds: string[];
  category: NotificationCategoryType;
  body:
    | NotificationCsvBody
    | NotificationIntegrationBody
    | NotificationNormalizationBody;
  from: NotificationFromType;
  to: NotificationToType;
  createdBy: DashboardTeamUserObject | null;
};

const useWatchSystemNotifications = ({
  teamSlug,
  databaseId,
}: {
  teamSlug: string;
  databaseId: string;
}) => {
  const client = useQueryClient();

  /**
   * Pusherの処理
   */
  const { _mqttAuthForPrivateCh } = useApiNotification();

  useEffect(() => {
    const pusher = new Pusher(import.meta.env.VITE_PUSHER_KEY, {
      cluster: import.meta.env.VITE_PUSHER_CLUSTER,
      authorizer: () => {
        return {
          authorize: async (socketId, callback) => {
            const response = await _mqttAuthForPrivateCh({
              socketId,
              teamSlug: teamSlug,
            });
            callback(null, { auth: response.auth });
          },
        };
      },
    });

    const channelSlug = `private-team-${teamSlug}`;

    const _channel = pusher.subscribe(channelSlug);

    if (_channel) {
      /**
       * Pusherからくるイベントに対する処理を追加したいときは、ここのbindを追加する
       */
      // 外部サービス・CSVインポートのステータス更新
      _channel.bind(
        PusherChannelEvent.integrationStatusUpdated,
        ({ databaseId }: DashboardImportIntegrationStatusObject) => {
          // import status のinvalidation
          const importInvalidationKeys =
            importsKeys.listImportStatusFromOtherServices({
              teamSlug,
              databaseId,
            });
          invalidateAll(client, importInvalidationKeys);

          // table一覧のinvalidation
          const tableInvalidationKeys = tablesKeys.listTables({
            teamSlug,
            databaseId,
          });
          invalidateAll(client, tableInvalidationKeys);
        }
      );

      // normalized tableのステータス更新
      _channel.bind(
        PusherChannelEvent.normalizedTableStatusUpdated,
        ({ databaseId, tableSlug }: DashboardNormalizedTableStatusObject) => {
          const normalizationStatusKeys =
            tablesKeys.allListNormalizedTableStatus({ teamSlug, databaseId });

          const recordKeys = recordsKeys.all({
            teamSlug,
            databaseId,
            tableSlug,
          });
          invalidateAll(client, normalizationStatusKeys, recordKeys);
        }
      );

      // AI Fieldの同期完了
      _channel.bind(
        PusherChannelEvent.smartFunctionStatusUpdated,
        ({
          databaseId,
          tableSlug,
        }: {
          databaseId: string;
          tableSlug: string;
          fieldName: string;
          status: "success" | "failed";
        }) => {
          // recordのinvalidation
          const recordKey = recordsKeys.all({
            teamSlug,
            databaseId,
            tableSlug,
          });

          invalidateAll(client, recordKey);
        }
      );

      // notificationの発行
      _channel.bind(
        PusherChannelEvent.notificationPublished,
        (data: NotificationPusherEventPayload) => {
          // TODO: ここに書く
        }
      );

      return () => {
        _channel.unbind();
        pusher.unsubscribe(channelSlug);
      };
    }
  }, [client, teamSlug]);
};

export { useWatchSystemNotifications };
