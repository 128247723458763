import { useMyPresence } from "../liveblocks.config";
import { PresenceTableViewState } from "../types";

export const useUpdateMyPresenceTableViewState = () => {
  const [myPresence, updateMyPresence] = useMyPresence();

  const updateMyPresenceTableState = (
    tableViewState: PresenceTableViewState
  ) => {
    updateMyPresence({
      ...myPresence,
      tableViewState: JSON.stringify(tableViewState),
    });
  };

  return updateMyPresenceTableState;
};
