import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil";
import { RecordModel } from "~/features/RecordModel";

const editingRecordModelFamily = atomFamily<
  RecordModel | null,
  { viewId: string }
>({
  key: "kanban-view-editing-record-model",
  default: null,
});

const useEditingRecordModel = (viewId: string) => {
  return useRecoilValue(editingRecordModelFamily({ viewId }));
};

const useSetEditingRecordModel = (viewId: string) => {
  return useSetRecoilState(editingRecordModelFamily({ viewId }));
};

export { useEditingRecordModel, useSetEditingRecordModel };
