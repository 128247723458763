import { ReactNode, useCallback } from "react";
import { RightSidebar } from "~/components_next/RightSidebar";
import {
  SidebarType,
  useSidebarDisclosure,
} from "./sidebarState/useSidebarDisclosure";

type RightSidebarBaseProps = {
  sidebarType: SidebarType;
  title: ReactNode;
  footer: ReactNode;
  children: ReactNode;
};

export const RightSidebarBase = (props: RightSidebarBaseProps) => {
  const { sidebarType, title, footer, children } = props;

  const { isOpen, onClose } = useSidebarDisclosure();

  const isOpenSidebar = isOpen(sidebarType);

  const handleClose = useCallback(() => {
    onClose(sidebarType);
  }, [onClose, sidebarType]);

  if (!isOpenSidebar) return null;

  return (
    <RightSidebar
      width={400}
      minWidth={400}
      maxWidth={700}
      title={title}
      footer={footer}
      height="calc(100vh - 40px)"
      onClose={handleClose}
    >
      {children}
    </RightSidebar>
  );
};
