import { RightSidebar } from "~/components_next/RightSidebar";

import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { SimpleField } from "@usemorph/morph-dashboard-types";
import { useErrorToast } from "~/components_next/Error";
import { Footer } from "./Footer";
import { useState } from "react";
import { isValidFieldSlug } from "~/features/Fields/utils/isValidFieldSlug";
import { useSidebarDisclosure } from "../../states/sidebar";
import { useMutation } from "react-query";
import { FormulaTestRunResultDrawer } from "~/features/Fields/Form/FormulaTestRunResult";
import { useDisclosure } from "~/hooks/useDisclosure";
import { useViewId } from "~/utilHooks/useViewId";
import { useViewFields } from "~/serverStateStore/views";
import { useView } from "../../../common/utils/useView";
import { useCreateViewFieldMutation } from "~/serverStateStore/views/mutations/useCreateViewFieldMutation";
import { useTestFormulaForView } from "../../hooks/useTestRun";
import { CreateFieldForm } from "~/features/Fields/Form/CreateFieldForm";

type CreateFieldSidebarPresenterProps = {
  onClose: () => void;
};

const CreateFieldSidebarPresenter = (
  props: CreateFieldSidebarPresenterProps
) => {
  const { onClose } = props;
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  const { data: viewData } = useView();
  const { data: viewFieldsData } = useViewFields({
    teamSlug,
    databaseId,
    viewId,
    viewData,
  });

  /**
   * Client states
   */

  const [field, setField] = useState<SimpleField>({
    name: "",
    displayName: "",
    type: "formula", // formula固定
    formula: undefined,
    comment: "",
    nullable: true,
  });

  /**
   * handlers
   */

  const { errorToast } = useErrorToast({});

  const { mutateAsync: createField, isLoading: isCreating } = useMutation(
    useCreateViewFieldMutation({ teamSlug, databaseId, viewId })
  );

  const handleCreateField = async () => {
    if (!field.formula) {
      errorToast("Please enter a formula.");
      return;
    }
    try {
      await createField({
        name: field.name,
        displayName: field.displayName,
        formula: field.formula,
      });
      onClose();
    } catch (e) {
      errorToast(e);
    }
  };

  const testRunDrawer = useDisclosure();

  const { testFormula, isTestRunning, latestTestResult } =
    useTestFormulaForView({
      teamSlug,
      viewId,
    });

  const handleTestRun = async (formula: string) => {
    try {
      await testFormula(formula);
      testRunDrawer.onOpen();
      setField((prev) => ({ ...prev, formula }));
    } catch (e) {
      errorToast(e);
    }
  };

  const isValid =
    isValidFieldSlug(field.name) &&
    !(field.type === "formula" && !field.formula);

  // - SQLで作られたviewの場合
  //    field.name
  // - SQLで作られたviewではない場合
  //    fieldがoriginal table由来であればドット繋ぎ
  //    fieldがjoin先由来であればfield nameのみ
  const convertFieldToText = (field: SimpleField) =>
    field.originalTableSlug &&
    viewData?.condition.from !== field.originalTableSlug
      ? `${field.originalTableSlug}.${field.name}`
      : field.name;

  return (
    <>
      <RightSidebar
        width={400}
        minWidth={300}
        title="Create Field"
        footer={
          <Footer
            isCreateDisabled={!isValid}
            onCreate={handleCreateField}
            isCreating={isCreating}
          />
        }
        height="calc(100vh - 40px)"
        onClose={onClose}
      >
        <CreateFieldForm
          field={field}
          onChange={setField}
          simpleFields={viewFieldsData?.fields ?? []}
          onTestRunStart={handleTestRun}
          isTestRunning={isTestRunning}
          convertFieldToText={convertFieldToText}
          fieldTypes={["formula"]}
        />
      </RightSidebar>

      {latestTestResult && (
        <FormulaTestRunResultDrawer
          isOpen={testRunDrawer.isOpen}
          onOpenChange={testRunDrawer.onToggle}
          testRunResultRecord={latestTestResult.recordsTableBaseRecords}
          testRunResultFields={latestTestResult.fields}
        />
      )}
    </>
  );
};

const CreateFieldSidebar = () => {
  const viewId = useViewId();
  const { isOpen, onClose } = useSidebarDisclosure("createFormula");

  if (!isOpen) {
    return null;
  }

  return <CreateFieldSidebarPresenter onClose={onClose} key={viewId} />;
};

export { CreateFieldSidebar };
