import { Dialog } from "~/components_next/Dialog";
import { useMemo } from "react";
import { useMutation } from "react-query";
import { Button } from "~/components_next/Button";
import { useCreateTableMutation } from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { TableNameInputSet } from "../Table";
import { useNavigate } from "react-router-dom";
import { getPath } from "~/routing";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";

type CreateTableModalProps = {
  isOpen: boolean;
  onOpenChange(open: boolean): void;
  onClose: () => void;
  isPrivate: boolean;
};

const CreateTableModal = (props: CreateTableModalProps) => {
  const { isOpen, onOpenChange, onClose, isPrivate } = props;
  const _onClose = () => {
    resetTableNameInputs();
    onClose();
  };
  const navigate = useNavigate();
  const {
    TableNameInput,
    TableSlugInput,
    DescriptionInput,
    tableName,
    tableSlug,
    description,
    tableSlugError,
    onCreate: resetTableNameInputs,
  } = TableNameInputSet({
    oldDescription: "",
    oldTableName: "",
    oldTableSlug: "",
  });

  const isValid = useMemo(() => {
    return tableName.length > 0 && !tableSlugError;
  }, [tableSlugError, tableName]);

  /**
   * API request
   */
  const databaseId = useDatabaseId();
  const teamSlug = useTeamSlug();
  const { mutateAsync: createTable, isLoading: isCreatingTable } = useMutation({
    ...useCreateTableMutation({
      databaseId,
      teamSlug,
    }),
  });

  const handleCreateTable = async () => {
    try {
      await createTable({
        displayName: tableName,
        tableSlug,
        comment: description,
        isPrivate,
      });
      navigate(getPath("source", { teamSlug, databaseId, tableSlug }));
      _onClose();
    } catch (e: unknown) {
      console.error(e);
    }
  };

  return (
    <>
      <Dialog.Root size="xs" open={isOpen} onOpenChange={onOpenChange}>
        <Dialog.Content>
          <Dialog.Title>Create New Source</Dialog.Title>
          <Dialog.Body>
            <Flex direction="column" width="100%" mt="5">
              <Box>{TableNameInput}</Box>
              <Box>{TableSlugInput}</Box>
              <Box>{DescriptionInput}</Box>
            </Flex>
          </Dialog.Body>
          <Dialog.Footer>
            <Button
              variant="primary"
              isDisabled={!isValid}
              isLoading={isCreatingTable}
              onClick={handleCreateTable}
            >
              Create
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};

export { CreateTableModal };
