import { SimpleFieldListResponse } from "@usemorph/morph-dashboard-types";
import { useCallback } from "react";
import { useQuery } from "react-query";
import {
  FieldsClientModel,
  FieldsClientModelFactory,
} from "~/clientModel/fields";
import { useComposeLoadable } from "~/clientModel/loadable";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";

import { useGetViewFieldsQuery } from "~/serverStateStore/views";

export const createUseFieldsWithConditionLoadable = (props: {
  teamSlug: string;
  databaseId: string;
}): UseLoadable<{ viewId: string }, FieldsClientModel> => {
  const { teamSlug, databaseId } = props;

  return ({ viewId }: { viewId: string }) =>
    useComposeLoadable(
      useQuery({
        ...useGetViewFieldsQuery({
          teamSlug,
          databaseId,
          viewId,
        }),
        select: useCallback(
          (data: SimpleFieldListResponse) =>
            FieldsClientModelFactory.createFromSimpleFields(data.fields),
          []
        ),
      })
    );
};
