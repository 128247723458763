/**
 * What's this?
 * useState + useEffect alternative
 * When props change, the state will be updated, without useEffect
 */

import { useState } from "react";

export const useCachedValue = <ValueType, CachedValueType>(
  value: ValueType,
  calcCahceValue: (value: ValueType) => CachedValueType
) => {
  const [currentValue, setCurrentValue] = useState<ValueType>(value);
  const [prevValue, setPrevValue] = useState<ValueType>(value);

  if (calcCahceValue(value) !== calcCahceValue(prevValue)) {
    setCurrentValue(value);
    setPrevValue(value);
  }

  return currentValue;
};
