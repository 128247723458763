import { CSSProperties } from "react";

export const useBorderStyle = () => {
  return {
    borderAttributes: {
      borderRight: "solid 1px $border",
      borderBottom: "solid 1px $border",
    } as CSSProperties,
  };
};
