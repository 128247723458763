import { DashboardNotebookCellPromptAxisObject } from "@usemorph/morph-dashboard-types";
import { P, match } from "ts-pattern";
import {
  CanvasCellClientModelBase,
  CanvasCellDataType,
  CanvasCellResizeParams,
} from "./CanvasCellClientModelBase";

export type CanvasCellPromptDataType = CanvasCellDataType & {
  cellType: "prompt";
};

const camelCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

/**
 * ありえないフォールバックがいくつか入ってる。要調整
 */

export class CanvasCellPromptClientModel extends CanvasCellClientModelBase<
  CanvasCellPromptClientModel,
  CanvasCellPromptDataType
> {
  constructor(data: CanvasCellPromptDataType) {
    super(data);
  }

  /**
   *
   * Custom value getters
   *
   */

  get isFailed(): boolean {
    const failedOutputs = this.outputs.filter((output) => {
      return (
        output.contentType === "application/morph.error" ||
        output.contentType === "application/morph.python_error"
      );
    });
    return failedOutputs.length > 0;
  }

  get isOutputEmpty(): boolean {
    return this.outputs.length === 0;
  }

  /**
   * ChatGPT, Pythonの出力なのであくまで経験則だが、以下のように処理している。
   * `text/plain` は基本的にシステムメッセージが吐き出されているはずなのでそう扱う
   */
  get systemMessageOutputs(): Array<{
    value: string;
    contentType: "text/plain";
  }> {
    return this.outputs.filter(
      (output): output is { value: string; contentType: "text/plain" } =>
        !!output
    );
  }

  get isSystemMessagesOnly() {
    return this.outputs.length === this.systemMessageOutputs.length;
  }

  get htmlOutputs(): Array<{
    value: string;
    contentType: "text/html";
  }> {
    return this.outputs.filter(
      (output): output is { value: string; contentType: "text/html" } =>
        !!output
    );
  }

  get htmlOuput(): { value: string; contentType: "text/html" } | null {
    if (this.htmlOutputs.length === 0) {
      return null;
    }
    return this.htmlOutputs[0];
  }

  get isResizable(): boolean {
    return true;
  }

  get showSourceHandle(): boolean {
    return true;
  }

  get showTargetHandle(): boolean {
    return true;
  }

  get chartType() {
    return this.data.source.prompt?.type || "";
  }

  get chartTypeLabel(): string {
    return camelCaseToWords(this.chartType);
  }

  get title(): string {
    return this.data.source.prompt?.title || "";
  }

  get xAxisFieldObject() {
    return this.data.source.prompt?.xAxisObject || null;
  }

  get yAxisFieldObject() {
    return this.data.source.prompt?.yAxisObject || null;
  }

  get xAxisFieldLabel() {
    return this.data.source.prompt?.xAxisLabel || "";
  }

  get yAxisFieldLabel() {
    return this.data.source.prompt?.yAxisLabel || "";
  }

  get prompt(): string {
    return this.data.source.prompt?.prompt || "";
  }

  get colorCodes(): string[] | null {
    return this.data.source.prompt?.colorCodes || null;
  }

  updateIsPublic(isPublic: boolean): CanvasCellPromptClientModel {
    return new CanvasCellPromptClientModel({
      ...this.data,
      isPublic,
    });
  }

  updateCellName(cellName: string): CanvasCellPromptClientModel {
    return new CanvasCellPromptClientModel({
      ...this.data,
      cellName,
    });
  }

  onResize(resizePrams: CanvasCellResizeParams): CanvasCellPromptClientModel {
    return new CanvasCellPromptClientModel({
      ...this.data,
      settings: {
        ...this.data.settings,
        ...resizePrams,
      },
    });
  }

  updateTitle(title: string): CanvasCellPromptClientModel {
    return new CanvasCellPromptClientModel({
      ...this.data,
      source: {
        ...this.data.source,
        prompt: {
          ...(this.data.source.prompt || { type: "barChart", prompt: "" }),
          title,
        },
      },
    });
  }

  updateXYAxis(
    xAxisObject?: DashboardNotebookCellPromptAxisObject[],
    yAxisObject?: DashboardNotebookCellPromptAxisObject[]
  ): CanvasCellPromptClientModel {
    const promptObject = { ...this.data.source.prompt };
    return match(promptObject)
      .with(
        {
          type: P.union("barChart", "lineChart", "scatterPlot", "histogram"),
          prompt: P.string,
        },
        (_promptObject: {
          type: "barChart" | "lineChart" | "scatterPlot" | "histogram";
          prompt: string;
        }) => {
          return new CanvasCellPromptClientModel({
            ...this.data,
            source: {
              ...this.data.source,
              prompt: {
                ..._promptObject,
                xAxisObject,
                yAxisObject,
              },
            },
          });
        }
      )
      .otherwise(() => this);
  }

  updateXYAxisLabel(
    xAxisLabel: string,
    yAxisLabel: string
  ): CanvasCellPromptClientModel {
    return new CanvasCellPromptClientModel({
      ...this.data,
      source: {
        ...this.data.source,
        prompt: {
          ...(this.data.source.prompt || { type: "barChart", prompt: "" }),
          xAxisLabel,
          yAxisLabel,
        },
      },
    });
  }

  updateXAxisLabel(xAxisLabel: string): CanvasCellPromptClientModel {
    return new CanvasCellPromptClientModel({
      ...this.data,
      source: {
        ...this.data.source,
        prompt: {
          ...(this.data.source.prompt || { type: "barChart", prompt: "" }),
          xAxisLabel,
        },
      },
    });
  }

  updateYAxisLabel(yAxisLabel: string): CanvasCellPromptClientModel {
    return new CanvasCellPromptClientModel({
      ...this.data,
      source: {
        ...this.data.source,
        prompt: {
          ...(this.data.source.prompt || { type: "barChart", prompt: "" }),
          yAxisLabel,
        },
      },
    });
  }

  updatePrompt(prompt: string): CanvasCellPromptClientModel {
    return new CanvasCellPromptClientModel({
      ...this.data,
      source: {
        ...this.data.source,
        prompt: {
          ...(this.data.source.prompt || { type: "barChart", prompt: "" }),
          prompt,
        },
      },
    });
  }

  updateColorCodes(colorCodes: string[]): CanvasCellPromptClientModel {
    return new CanvasCellPromptClientModel({
      ...this.data,
      source: {
        ...this.data.source,
        prompt: {
          ...(this.data.source.prompt || { type: "barChart", prompt: "" }),
          colorCodes,
        },
      },
    });
  }

  isSameColorCodes(colorCodes: string[]) {
    if (!this.colorCodes) return false;
    return this.colorCodes.join(",") === colorCodes.join(",");
  }
}
