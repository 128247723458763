import { RowInfoSchema, SimpleField } from "@usemorph/morph-dashboard-types";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModelFactory } from "~/clientModel/fields/field";
import { RecordClientModel } from ".";
import { RecordEntryClientModelFactory } from "./recordEntry";
import { RecordIdentifierClientModelFactory } from "./recordIdentifier";

export class RecordClientModelFactory {
  public static createFromRecordAndSimpleFields(
    record: Record<string, unknown>,
    simpleFields: SimpleField[]
  ): RecordClientModel {
    // embeddingsとrow infoは除外
    const displayedSimpleFields = simpleFields.filter(
      ({ type }) => type !== "embeddings" && type !== "rowInfo"
    );

    const rowInfo =
      "morph_reserved_row_info" in record
        ? (record["morph_reserved_row_info"] as RowInfoSchema)
        : null;

    return new RecordClientModel({
      entries: displayedSimpleFields.map((simpleField) =>
        RecordEntryClientModelFactory.createFromRawValueAndFieldClientModel(
          record[simpleField.name],
          FieldClientModelFactory.createFromSimpleField(simpleField, {
            allSimpleFields: simpleFields,
          }),
          {
            rowInfo: rowInfo ?? undefined,
          }
        )
      ),
      recordIdentifier:
        RecordIdentifierClientModelFactory.createFromRecordAndSimpleFields({
          record,
          simpleFields,
        }),
    });
  }

  public static createEmptyFromFieldsClientModel(
    fields: FieldsClientModel
  ): RecordClientModel {
    const emptyRecord = fields.allFields.reduce(
      (record, field) => ({ ...record, [field.name]: null }),
      {} as Record<string, unknown>
    );

    return new RecordClientModel({
      entries: fields.allFields.map((field) =>
        RecordEntryClientModelFactory.createFromRawValueAndFieldClientModel(
          emptyRecord[field.name],
          field
        )
      ),
      recordIdentifier:
        RecordIdentifierClientModelFactory.createFromRecordAndFields({
          record: emptyRecord,
          fields,
        }),
    });
  }
}
