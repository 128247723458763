import { ForwardedRef, forwardRef } from "react";
import { LongTextRecordEntryClientModel } from "~/clientModel/records/record/recordEntry";
import { TextArea } from "~/components_next/TextArea";
import { RecordValueInputCommonProps } from "../../type";

const LongTextInput = forwardRef(function LongTextInput(
  props: RecordValueInputCommonProps<LongTextRecordEntryClientModel>,
  ref: ForwardedRef<HTMLTextAreaElement>
) {
  const { editingRecordEntry, onChange, field, size, isReadonly } = props;

  const validatedValue = editingRecordEntry.getValidatedValue(field);

  const value = validatedValue.isValid ? validatedValue.value : "";

  const errorMessages = validatedValue.errorMessages;

  const handleChange = (value: string) => {
    onChange?.(editingRecordEntry.updateValue(value));
  };

  return (
    <TextArea
      ref={ref}
      variant="primary"
      size={size}
      value={value ?? ""}
      onChange={(e) => handleChange(e.target.value)}
      readOnly={isReadonly}
      errorMessage={errorMessages}
    />
  );
});

export { LongTextInput };
