import { useQuery } from "react-query";
import { useComposeLoadable } from "~/clientModel/loadable";
import { CsvImportBucketClientModelFactory } from "~/clientModel/storage/bucket";
import { useFindCsvImportBucketQuery } from "~/serverStateStore";

export const useCsvImportBucket = ({ teamSlug }: { teamSlug: string }) => {
  const findCsvImportFileBucketResult = useQuery({
    ...useFindCsvImportBucketQuery({
      teamSlug,
    }),
    select: (data) => {
      return CsvImportBucketClientModelFactory.fromBucketResponse(data);
    },
  });
  const bucketLoadable = useComposeLoadable(findCsvImportFileBucketResult);

  return {
    bucketLoadable,
  };
};
