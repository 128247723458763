import { Editor } from "@monaco-editor/react";
import {
  DashboardNotebookCellSourceObject,
  RecordFilterCondition,
  RecordFilterConditionAndType,
  RecordFilterConditionOrType,
} from "@usemorph/morph-dashboard-types";
import { ReactNode } from "react";
import { BsTrash } from "react-icons/bs";
import { useQuery } from "react-query";
import { NodeToolbar, Position } from "reactflow";
import { match, P } from "ts-pattern";
import { Badge } from "~/components_next/Badge";
import { Button } from "~/components_next/Button";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { Flex } from "~/components_next/Flex";
import { IconButton } from "~/components_next/IconButton";
import { Spinner } from "~/components_next/Spinner";
import { Text } from "~/components_next/Text";
import { FlatFilterConditionForm } from "~/features/Fields/FilterCondition/FlatFilterConditionForm";
import {
  isFlatFilterCondition,
  isFlatFilterConditionAnd,
} from "~/features/Fields/FilterCondition/flatFilterConditionTypes";
import { ViewSetting } from "~/features/View";
import {
  useFindViewQuery,
  useGetViewFieldsQuery,
} from "~/serverStateStore/views";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { isArrayWithAtLeastOneElement } from "~/utils/commonTypeGuards";

const assembleFilterOrCondtionString = (
  filter: RecordFilterConditionOrType
): ReactNode => {
  const { or } = filter;
  const orConditionNodes: ReactNode[] = or.map((_filter) => {
    return match(_filter)
      .with({ key: P.string, operator: P.string }, (_rawCondition) => {
        return (
          <Badge variant="tertiary">
            <>
              {_rawCondition.key} {_rawCondition.operator} {_rawCondition.value}
            </>
          </Badge>
        );
      })
      .with({ and: P.not(P.nullish) }, (_andFilter) => {
        return assembleFilterAndCondtionString(_andFilter);
      })
      .with({ or: P.not(P.nullish) }, (_orFilter) => {
        return assembleFilterOrCondtionString(_orFilter);
      })
      .exhaustive();
  });

  return (
    <>
      {orConditionNodes.map((condition, index) => {
        if (index === orConditionNodes.length - 1) {
          return <>{condition}</>;
        }
        return <>{condition} OR </>;
      })}
    </>
  );
};

const assembleFilterAndCondtionString = (
  filter: RecordFilterConditionAndType
): ReactNode => {
  const { and } = filter;
  const andConditions: ReactNode[] = and.map((_filter) => {
    return match(_filter)
      .with({ key: P.string, operator: P.string }, (_rawCondition) => {
        return (
          <Badge variant="tertiary">
            <>
              {_rawCondition.key} {_rawCondition.operator} {_rawCondition.value}
            </>
          </Badge>
        );
      })
      .with({ and: P.not(P.nullish) }, (_andFilter) => {
        return assembleFilterAndCondtionString(_andFilter);
      })
      .with({ or: P.not(P.nullish) }, (_orFilter) => {
        return assembleFilterOrCondtionString(_orFilter);
      })
      .exhaustive();
  });

  return (
    <>
      {andConditions.map((condition, index) => {
        if (index === andConditions.length - 1) {
          return <>{condition}</>;
        }
        return <>{condition} AND </>;
      })}
    </>
  );
};

const assembleFilterConditionString = (filter: RecordFilterCondition) => {
  return match(filter)
    .with({ and: P.not(P.nullish) }, (_andFilter) => {
      return assembleFilterAndCondtionString(_andFilter);
    })
    .with({ or: P.not(P.nullish) }, (_orFilter) => {
      return assembleFilterOrCondtionString(_orFilter);
    })
    .exhaustive();
};

type NotebookCellViewCondtionLabelsProps = {
  source: DashboardNotebookCellSourceObject;
  viewSetting: ViewSetting;
  viewId: string;
  onClearCondition: () => void;
};

const FilterConditions = ({
  filterCondition,
  viewId,
}: {
  filterCondition?: RecordFilterCondition;
  viewId: string;
}) => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const isFlatConditions = isFlatFilterCondition(filterCondition);
  const { data: viewFields } = useQuery(
    useGetViewFieldsQuery({
      teamSlug,
      databaseId,
      viewId,
    })
  );

  if (!filterCondition) return <></>;

  if (isFlatConditions) {
    if (!viewFields || !isArrayWithAtLeastOneElement(viewFields.fields)) {
      return (
        <Flex py="9" align="center" justify="center">
          <Spinner />
        </Flex>
      );
    }

    const filterLength = isFlatFilterConditionAnd(filterCondition)
      ? filterCondition.and.length
      : filterCondition.or.length;

    return (
      <>
        <SimpleDropdownMenu
          trigger={
            <Button variant="primary" size="xs">
              Filters ({filterLength})
            </Button>
          }
        >
          <FlatFilterConditionForm
            flatFilterCondition={filterCondition}
            simpleFields={viewFields.fields}
            isReadOnly={true}
          />
        </SimpleDropdownMenu>
      </>
    );
  }

  return <>{assembleFilterConditionString(filterCondition)}</>;
};

const PromptCondtions = (props: { prompt?: string; sql?: string }) => {
  const { prompt, sql } = props;

  if (!prompt || !sql) return null;

  return (
    <>
      <SimpleDropdownMenu
        trigger={
          <Button variant="primary" size="xs">
            SQL
          </Button>
        }
      >
        <Editor
          height="400px"
          width="600px"
          language="sql"
          value={sql}
          theme={"vs-dark"}
          options={{
            padding: {
              top: 24,
              bottom: 24,
            },
            scrollBeyondLastLine: false,
            minimap: {
              enabled: false,
            },
            fontSize: 14,
            lineNumbers: "off",
            readOnly: true,
          }}
        />
      </SimpleDropdownMenu>

      <Text variant="description">{prompt}</Text>
    </>
  );
};

const NoteboolCellViewConditionLabels = (
  props: NotebookCellViewCondtionLabelsProps
) => {
  const { source, viewId, viewSetting, onClearCondition } = props;
  const { condition } = source;

  const filterCondition = condition?.filter;
  const from = condition?.from;
  const sqlCondtion = from?.includes(" ") ? from : undefined;
  const prompt = viewSetting.data.prompt;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const { data: viewResponse } = useQuery(
    useFindViewQuery({
      teamSlug,
      databaseId,
      viewId,
    })
  );

  if (!condition || (!filterCondition && !sqlCondtion)) return null;

  return (
    <NodeToolbar position={Position.Top} offset={20}>
      <Flex
        css={{
          backgroundColor: "$whiteA10",
          borderRadius: "var(--radius-4)",
          boxShadow: "var(--shadow-4)",
        }}
        p="3"
        direction="column"
        gap="2"
        align="start"
      >
        <Flex align="center" gap="4">
          <Text variant="subheading">Condition</Text>
          <FilterConditions filterCondition={filterCondition} viewId={viewId} />
          <PromptCondtions prompt={prompt} sql={sqlCondtion} />
          <IconButton
            icon={<BsTrash />}
            tooltip="Clear View Condition"
            onClick={onClearCondition}
          />
        </Flex>
      </Flex>
    </NodeToolbar>
  );
};

export { NoteboolCellViewConditionLabels };
