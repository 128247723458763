import { SimpleField } from "@usemorph/morph-dashboard-types";
import { useMemo } from "react";
import {
  useRecordsTableBaseColumnSizing,
  useRecordsTableBaseEditingCell,
  useRecordsTableBaseFields,
  useRecordsTableBaseOptions,
} from "../context/ReactTableBaseContext";

export const useEditingCellPosition = () => {
  const { isSelectable } = useRecordsTableBaseOptions();
  const fields = useRecordsTableBaseFields();
  const editingCell = useRecordsTableBaseEditingCell();
  const columnSizings = useRecordsTableBaseColumnSizing();

  const fieldSizes: Array<SimpleField & { width: number }> = useMemo(() => {
    return fields.map((field) => {
      return {
        ...field,
        width: columnSizings[field.name] ?? 0,
      };
    });
  }, [fields, columnSizings]);

  const editingCellPosition = useMemo(() => {
    if (!editingCell) {
      return null;
    }

    const start = {
      x: fieldSizes
        .slice(
          0,
          fields.findIndex((field) => field.name === editingCell.fieldSlug)
        )
        .reduce((sum, field) => sum + field.width, 0),
      y: editingCell.record._reservedRecordIndex * 40 + 40,
    };

    return isSelectable ? { ...start, x: start.x + 80 } : start;
  }, [editingCell, fieldSizes, fields, isSelectable]);

  return {
    editingCellPosition,
  };
};
