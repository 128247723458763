import { FieldClientModel } from "../fields/field";
import { FieldsClientModel } from "../fields/FieldsClientModel";
import { RecordsClientModel } from "../records";
import { RecordClientModel } from "../records/record";
import { RecordEntryClientModelFactory } from "../records/record/recordEntry";
import { CsvImportSchemaClientModel } from "./CsvImportSchemaClientModel";

export class CsvImportSchemaEditClientModel {
  readonly #importSchemaInstance: CsvImportSchemaClientModel;
  readonly #records: RecordClientModel[];
  readonly #allFields: FieldClientModel[]; // ただの配列でいい
  readonly #removedFields: FieldClientModel[]; // ただの配列でいい

  constructor(
    importSchemaInstance: CsvImportSchemaClientModel,
    records: RecordClientModel[] = [],
    allFields: FieldClientModel[] = [],
    removedFields: FieldClientModel[] = []
  ) {
    this.#importSchemaInstance = importSchemaInstance;
    this.#records = records.length
      ? records
      : importSchemaInstance.previewRecords.allRecords;
    this.#allFields = allFields.length
      ? allFields
      : importSchemaInstance.estimatedFields.map(
          (estimatedField) => estimatedField.field
        );
    this.#removedFields = removedFields;
  }

  get fields() {
    // FieldsClientModelをあんまりいじりたくないので以下の形式で。
    // もっといい感じにできるかもしれない。
    const fieldInstances = this.#allFields.filter((fieldInstance) => {
      return !this.#removedFields.some((removedFieldInstance) => {
        return fieldInstance.name === removedFieldInstance.name;
      });
    });

    return new FieldsClientModel({
      fields: fieldInstances,
    });
  }

  get removedFields() {
    return this.#removedFields;
  }

  get id() {
    return this.#importSchemaInstance.id;
  }

  get hasHeader() {
    return this.#importSchemaInstance.hasHeader;
  }

  get records() {
    return new RecordsClientModel({
      records: this.#records,
      totalCount: this.#records.length,
    });
  }

  get estimatedDataSource() {
    return this.#importSchemaInstance.estimatedDataSource;
  }

  get estimatedName() {
    return this.#importSchemaInstance.estimatedName;
  }

  removeField(fieldInstance: FieldClientModel) {
    return new CsvImportSchemaEditClientModel(
      this.#importSchemaInstance,
      this.#records,
      this.#allFields,
      [...this.#removedFields, fieldInstance]
    );
  }

  revertField(fieldInstance: FieldClientModel) {
    return new CsvImportSchemaEditClientModel(
      this.#importSchemaInstance,
      this.#records,
      this.#allFields,
      this.#removedFields.filter((removedFieldInstance) => {
        return removedFieldInstance.name !== fieldInstance.name;
      })
    );
  }

  updateField(fieldInstance: FieldClientModel) {
    return new CsvImportSchemaEditClientModel(
      this.#importSchemaInstance,
      this.#records,
      this.#allFields.map((allFieldInstance) => {
        if (allFieldInstance.name === fieldInstance.name) {
          return fieldInstance;
        } else {
          return allFieldInstance;
        }
      }),
      this.#removedFields.map((removedFieldInstance) => {
        if (removedFieldInstance.name === fieldInstance.name) {
          return fieldInstance;
        } else {
          return removedFieldInstance;
        }
      })
    );
  }

  updateFieldWithIndex(fieldInstance: FieldClientModel, index: number) {
    return new CsvImportSchemaEditClientModel(
      this.#importSchemaInstance,
      this.#records.map((recordInstance) => {
        return new RecordClientModel({
          ...recordInstance.data,
          entries: recordInstance.allEntries.map((entryInstance, i) => {
            if (i == index) {
              return RecordEntryClientModelFactory.createFromRawValueAndFieldClientModel(
                entryInstance.data.rawValue,
                fieldInstance
              );
            }
            return entryInstance;
          }),
        });
      }),
      this.#allFields.map((allFieldInstance, i) => {
        if (i == index) {
          return fieldInstance;
        } else {
          return allFieldInstance;
        }
      }),
      this.#removedFields
    );
  }
}
