import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModel } from "..";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";

type SingleSelectFieldTypeClientModelData = {
  members: string[];
};

export class SingleSelectFieldTypeClientModel extends FieldTypeClientModelBase {
  #data: SingleSelectFieldTypeClientModelData;

  public constructor(data: SingleSelectFieldTypeClientModelData) {
    super();
    this.#data = data;
  }

  public get data(): SingleSelectFieldTypeClientModelData {
    return this.#data;
  }

  public get type(): "singleSelect" {
    return "singleSelect";
  }

  public get fieldTypeLabel(): "Single Select" {
    return "Single Select";
  }

  public get isAutoGenerated(): false {
    return false;
  }

  public get isEmbeddable(): true {
    return true;
  }

  public get canBeTargetOfSyncingByComputedField(): true {
    return true;
  }

  public get _updatableFieldTypes(): FieldTypeClientModel["type"][] {
    return ["shortText", "longText", "number", "bigNumber", "decimal"];
  }

  public validate(): ValidationResult {
    return { isValid: true };
  }

  public get members(): string[] {
    return this.#data.members;
  }

  public addMember(member: string): SingleSelectFieldTypeClientModel {
    return new SingleSelectFieldTypeClientModel({
      members: [...this.#data.members, member],
    });
  }

  public deleteMember(member: string): SingleSelectFieldTypeClientModel {
    return new SingleSelectFieldTypeClientModel({
      members: this.#data.members.filter((m) => m !== member),
    });
  }
}
