import { FieldsClientModel } from "~/clientModel/fields";
import { CsvDownloadFieldClientModelFactory } from "./csvDownloadField/CsvDownloadFieldClientModelFactory";
import { CsvDownloadFieldsClientModel } from "./CsvDownloadFieldsClientModel";

export class CsvDownloadFieldsClientModelFactory {
  public static fromFieldsClientModel(
    fields: FieldsClientModel
  ): CsvDownloadFieldsClientModel {
    return new CsvDownloadFieldsClientModel({
      csvDownloadFields: fields.allFields.map((field) =>
        CsvDownloadFieldClientModelFactory.fromFieldClientModel(field)
      ),
    });
  }
}
