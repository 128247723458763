import { IconBase, IconBaseProps } from "react-icons";

const MorphIconDateTime = (props: IconBaseProps) => {
  return (
    <IconBase {...props} viewBox="0 0 24 24">
      <g>
        <path d="M12.1 20.4001C13.8239 20.4001 15.4772 19.7258 16.6962 18.5256C17.9152 17.3254 18.6 15.6975 18.6 14.0001C18.6 12.3027 17.9152 10.6748 16.6962 9.47462C15.4772 8.27438 13.8239 7.6001 12.1 7.6001C10.3761 7.6001 8.72281 8.27438 7.50381 9.47462C6.28483 10.6748 5.60001 12.3027 5.60001 14.0001C5.60001 15.6975 6.28483 17.3254 7.50381 18.5256C8.72281 19.7258 10.3761 20.4001 12.1 20.4001ZM16.85 14.0001C16.85 15.2405 16.3496 16.4301 15.4588 17.3072C14.568 18.1843 13.3598 18.677 12.1 18.677C10.8402 18.677 9.63205 18.1843 8.74125 17.3072C7.85045 16.4301 7.35001 15.2405 7.35001 14.0001C7.35001 12.7597 7.85045 11.5701 8.74125 10.693C9.63205 9.81592 10.8402 9.32318 12.1 9.32318C13.3598 9.32318 14.568 9.81592 15.4588 10.693C16.3496 11.5701 16.85 12.7597 16.85 14.0001ZM11.725 9.93856C11.5261 9.93856 11.3353 10.0164 11.1947 10.1549C11.054 10.2933 10.975 10.4812 10.975 10.677V14.7387C10.975 14.8688 11.01 14.9967 11.0764 15.1092C11.1428 15.2218 11.2382 15.3151 11.353 15.3797L13.9818 16.8587L13.9857 16.8608C14.158 16.9525 14.3599 16.9742 14.5482 16.9212C14.7365 16.8683 14.8963 16.7449 14.9935 16.5775C15.0906 16.4101 15.1174 16.2119 15.0682 16.0253C15.0189 15.8387 14.8974 15.6784 14.7297 15.5788L14.7259 15.5766L12.475 14.3101V10.677C12.475 10.4812 12.396 10.2933 12.2553 10.1549C12.1147 10.0164 11.9239 9.93856 11.725 9.93856Z" />
        <path d="M12.1 20.4001C13.8239 20.4001 15.4772 19.7258 16.6962 18.5256C17.9152 17.3254 18.6 15.6975 18.6 14.0001C18.6 12.3027 17.9152 10.6748 16.6962 9.47462C15.4772 8.27438 13.8239 7.6001 12.1 7.6001C10.3761 7.6001 8.72281 8.27438 7.50381 9.47462C6.28483 10.6748 5.60001 12.3027 5.60001 14.0001C5.60001 15.6975 6.28483 17.3254 7.50381 18.5256C8.72281 19.7258 10.3761 20.4001 12.1 20.4001ZM16.85 14.0001C16.85 15.2405 16.3496 16.4301 15.4588 17.3072C14.568 18.1843 13.3598 18.677 12.1 18.677C10.8402 18.677 9.63205 18.1843 8.74125 17.3072C7.85045 16.4301 7.35001 15.2405 7.35001 14.0001C7.35001 12.7597 7.85045 11.5701 8.74125 10.693C9.63205 9.81592 10.8402 9.32318 12.1 9.32318C13.3598 9.32318 14.568 9.81592 15.4588 10.693C16.3496 11.5701 16.85 12.7597 16.85 14.0001ZM11.725 9.93856C11.5261 9.93856 11.3353 10.0164 11.1947 10.1549C11.054 10.2933 10.975 10.4812 10.975 10.677V14.7387C10.975 14.8688 11.01 14.9967 11.0764 15.1092C11.1428 15.2218 11.2382 15.3151 11.353 15.3797L13.9818 16.8587L13.9857 16.8608C14.158 16.9525 14.3599 16.9742 14.5482 16.9212C14.7365 16.8683 14.8963 16.7449 14.9935 16.5775C15.0906 16.4101 15.1174 16.2119 15.0682 16.0253C15.0189 15.8387 14.8974 15.6784 14.7297 15.5788L14.7259 15.5766L12.475 14.3101V10.677C12.475 10.4812 12.396 10.2933 12.2553 10.1549C12.1147 10.0164 11.9239 9.93856 11.725 9.93856Z" />
        <path d="M5.25 0C5.44892 0 5.63968 0.0790176 5.78032 0.21967C5.92098 0.360322 6 0.551088 6 0.75V1.5H18V0.75C18 0.551088 18.079 0.360322 18.2197 0.21967C18.3603 0.0790176 18.5511 0 18.75 0C18.9489 0 19.1397 0.0790176 19.2803 0.21967C19.421 0.360322 19.5 0.551088 19.5 0.75V1.5H21C21.7956 1.5 22.5588 1.81607 23.1214 2.37868C23.684 2.94128 24 3.70436 24 4.5V21C24 21.7956 23.684 22.5588 23.1214 23.1214C22.5588 23.684 21.7956 24 21 24H3C2.20436 24 1.44129 23.684 0.87868 23.1214C0.31607 22.5588 0 21.7956 0 21V4.5C0 3.70436 0.31607 2.94128 0.87868 2.37868C1.44129 1.81607 2.20436 1.5 3 1.5H4.5V0.75C4.5 0.551088 4.57902 0.360322 4.71968 0.21967C4.86032 0.0790176 5.05108 0 5.25 0ZM1.5 6V21C1.5 21.3978 1.65804 21.7794 1.93934 22.0606C2.22064 22.342 2.60218 22.5 3 22.5H21C21.3978 22.5 21.7794 22.342 22.0606 22.0606C22.342 21.7794 22.5 21.3978 22.5 21V6H1.5Z" />
      </g>
    </IconBase>
  );
};

export { MorphIconDateTime };
