import { Button } from "~/components_next/Button";
import { Dialog } from "~/components_next/Dialog";
import { Text } from "~/components_next/Text";
import { Executable } from '~/clientModel/executable';
import { PaymentClientModel } from "~/clientModel/payment/PaymentClientModel";

export const UnsubscribeDialog = ({
  isOpen,
  setIsOpen,
  onUnsubscribe,
  deletePaymentExecutable,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onUnsubscribe: () => void;
  deletePaymentExecutable: Executable<void, PaymentClientModel>
}) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Content>
        {deletePaymentExecutable.status === "success" || deletePaymentExecutable.isExecuting ? (
          <>
            <Dialog.Title>Complete Unsubscribe.</Dialog.Title>
            <Dialog.Body>
              <Text>You will be placed on the Free Plan after the period.</Text>
            </Dialog.Body>
            <Dialog.Footer>
              <Dialog.Close>
                <Button variant="tertiary">Close</Button>
              </Dialog.Close>
            </Dialog.Footer>
          </>
        ) : (
          <>
            <Dialog.Title>Danger Zone</Dialog.Title>
            <Dialog.Body>Are you sure you want to unsubscribe?</Dialog.Body>
            <Dialog.Footer>
              <Button
                variant="danger"
                onClick={onUnsubscribe}
                isLoading={deletePaymentExecutable.isExecuting}
              >
                Unsubscribe
              </Button>
            </Dialog.Footer>
          </>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};
