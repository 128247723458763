import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil";
import { urlSyncEffect } from "recoil-sync";
import { number } from "@recoiljs/refine";
import { createTableViewUrlSyncStoreEffectOptions } from "./TableViewUrlSyncStore/createTableViewUrlSyncStoreEffectOptions";

const skipFamily = atomFamily<number, { viewId: string }>({
  key: "tableViewSkip",
  default: 0,
  effects: [
    urlSyncEffect(
      createTableViewUrlSyncStoreEffectOptions({
        checker: number(),
        key: "tableViewSkip",
        defaultValue: 0,
      })
    ),
  ],
});

const useSkip = (viewId: string) => {
  return useRecoilValue(skipFamily({ viewId }));
};

const useSetSkip = (viewId: string) => {
  return useSetRecoilState(skipFamily({ viewId }));
};

export { useSkip, useSetSkip };
