import {
  TableHistoryDataConditionAnd,
  TableHistoryDataConditionOr,
  zTableHistoryDataConditionAnd,
  zTableHistoryDataConditionOr,
} from "@usemorph/morph-dashboard-types";
import { zodGuard } from "../../../utils/zodGuard";

export const isAndCondition = (
  params: unknown
): params is TableHistoryDataConditionAnd => {
  return zodGuard(params, zTableHistoryDataConditionAnd);
};

export const isOrCondition = (
  params: unknown
): params is TableHistoryDataConditionOr => {
  return zodGuard(params, zTableHistoryDataConditionOr);
};
