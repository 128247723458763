import { useEffect } from "react";
import { useViewId } from "~/utilHooks/useViewId";
import { useSetEditingRecordModel } from "../../states/editingRecordModel";
import {
  useSetOpeningSidebarType,
  useSidebarDisclosure,
} from "../../states/sidebar";

const useCloseEditRecordSidebar = () => {
  const viewId = useViewId();
  const editingRecordModel = useSetEditingRecordModel(viewId);

  const { isOpen } = useSidebarDisclosure("editRecord");
  const setOpeningSidebarType = useSetOpeningSidebarType();
  useEffect(() => {
    if (isOpen && !editingRecordModel) {
      setOpeningSidebarType(null);
    }
  }, [isOpen, setOpeningSidebarType, editingRecordModel]);
};

export { useCloseEditRecordSidebar };
