import { RecordValueReadOnlyInputCommonProps } from "../../type";
import { InputTooltip } from "../../atoms/InputTooltip";
import { UserReadOnlyInput } from "./UserReadOnlyInput";

const CreatedByReadonlyInput = (
  props: RecordValueReadOnlyInputCommonProps<"createdBy">
) => {
  const { value, errorMessages, isNullable, size } = props;
  return (
    <InputTooltip isAutoGenerated>
      <UserReadOnlyInput
        value={value}
        errorMessages={errorMessages}
        size={size}
        isNullable={isNullable}
      />
    </InputTooltip>
  );
};

export { CreatedByReadonlyInput };
