import slugify from "slugify";
import { CsvImportSchemaFieldClientModel } from "~/clientModel/csvImport/CsvImportSchemaField";
import { FieldClientModel } from "~/clientModel/fields/field";

const isEstimatedAsSameName = (a: string, b: string) => {
  // フィールド名をslugifyして、_でsplitしたものが順不同で一致する
  const aSlug = slugify(a, { lower: true, replacement: "_" });
  const bSlug = slugify(b, { lower: true, replacement: "_" });

  const aSlugSplit = aSlug.split("_");
  const bSlugSplit = bSlug.split("_");

  if (aSlugSplit.length !== bSlugSplit.length) {
    return false;
  }

  const aSlugSplitSorted = aSlugSplit.sort();
  const bSlugSplitSorted = bSlugSplit.sort();

  return aSlugSplitSorted.every((aSlugSplitItem, index) => {
    return aSlugSplitItem === bSlugSplitSorted[index];
  });
};

const estimateField = (
  estimatedField: CsvImportSchemaFieldClientModel,
  sourceFields: FieldClientModel[]
) => {
  // 同じであろうとする条件
  // 1. タイプが一致する
  // 2. フィールド名をslugifyして、_でsplitしたものが順不同で一致する
  // 3. estimatedのdisplayNameをslugifyして、_でsplitしたものが、同じ処理をしたfield.nameもしくはfield.displayNameと順不同で一致する

  const maybeSameField = sourceFields.find((sourceField) => {
    if (estimatedField.field.type.type !== sourceField.type.type) {
      return false;
    }

    if (isEstimatedAsSameName(estimatedField.field.name, sourceField.name)) {
      return true;
    }

    if (
      estimatedField.field.displayName &&
      isEstimatedAsSameName(estimatedField.field.displayName, sourceField.name)
    ) {
      return true;
    }

    if (
      estimatedField.field.displayName &&
      sourceField.displayName &&
      isEstimatedAsSameName(
        estimatedField.field.displayName,
        sourceField.displayName
      )
    ) {
      return true;
    }

    return false;
  });

  if (maybeSameField) {
    return maybeSameField;
  }

  return null;
};

export const useAutoDecetMappingFields = () => {
  const runAutoDetect = (
    estimatedFields: CsvImportSchemaFieldClientModel[],
    sourceFields: FieldClientModel[]
  ): Array<{
    field: FieldClientModel;
    colNum: number;
    originalKey: string;
  }> => {
    const mappingEstimations = estimatedFields
      .map((estimatedField) => {
        const field = estimateField(estimatedField, sourceFields);

        if (!field) {
          return null;
        }

        return {
          field,
          colNum: estimatedField.colNum,
          originalKey: estimatedField.field.name,
        };
      })
      .filter(
        (
          mappingEstimation
        ): mappingEstimation is {
          field: FieldClientModel;
          colNum: number;
          originalKey: string;
        } => {
          return !!mappingEstimation;
        }
      );

    return mappingEstimations;
  };

  return {
    runAutoDetect,
  };
};
