import { Box } from "~/components_next/Box"
import { Flex } from "~/components_next/Flex"
import { Input } from "~/components_next/Input"
import { Text } from "~/components_next/Text"
import { TextArea } from "~/components_next/TextArea"
import { FieldMappingSelect } from "./components/FieldMappingSelect"
import { Executable } from "~/clientModel/executable"
import { RssFeedItemsClientModel } from "~/clientModel/rssFeed/RssFeedItemsClientModel"
import { UseImportRssIntoTableMutationProps } from "~/serverStateStore/rssFeed"
import { Button } from "~/components_next/Button"
import { Spacer } from "~/components_next/Spacer"
import { RssTableMappingClientModel, RssTableMappingClientModelFactory } from "~/clientModel/rssFeed"
import { WithFallback } from "~/clientModel/loadable/WithFallback"
import { Loadable, useLoadableState } from "~/clientModel/loadable"
import { RssFieldClientModelDefactory } from "~/clientModel/rssFeed/RssFieldClientModelDefactory"
import { FieldsClientModel } from "~/clientModel/fields"
import { UseLoadable } from "~/clientModel/loadable/UseLoadable"
import { CreateUseListSampleRssFeedLoadableProps } from "~/containers/rssFeed/createUseListSampleRssFeedLoadable"
import { AiOutlineReload } from "react-icons/ai"
import { useEffect } from "react"
import { Switch } from "~/components_next/Switch"
import { MessageClientModel } from "~/clientModel/general"
import { UseUpdateRssTableMappingMutationProps } from "~/serverStateStore/rssFeed/mutations/useUpdateRssTableMappingMutation"
import { RssTableMappingClientModelDefactory } from "~/clientModel/rssFeed/RssTableMappingClientModelDefactory"
import { DeleteAlertDialog } from "./components/DeleteAlertDialog"
import { UseDisclosureReturn } from "~/hooks/useDisclosure"
import { RssFieldClientModel } from "~/clientModel/rssFeed/RssFieldClientModel"
import { Spinner } from "~/components_next/Spinner"
import { useNavigate } from "react-router-dom"
import { useTeamSlug } from "~/utilHooks/useTeamSlug"
import { useDatabaseId } from "~/utilHooks/useDatabaseId"
import { useTableSlug } from "~/utilHooks/useTableSlug"
import { getPath } from "~/routing"
import { UrlChecker } from "./utils/urlChecker"

interface RssFormProps {
  findRssLinkedToTable: Loadable<RssTableMappingClientModel, unknown>
  useListSampleRssFeedLoadable: UseLoadable<CreateUseListSampleRssFeedLoadableProps, RssFeedItemsClientModel, unknown>
  importRssIntoTableExecutable: Executable<UseImportRssIntoTableMutationProps, RssTableMappingClientModel, unknown>
  fieldsLoadable: Loadable<FieldsClientModel, unknown>;
  updateRssTableMappingExecutable: Executable<UseUpdateRssTableMappingMutationProps, RssTableMappingClientModel, unknown>
  deleteRssTableMappingExecutable: Executable<void, MessageClientModel, unknown>
  deleteDialogDisclosure: UseDisclosureReturn;
}

export const RssForm = (props: RssFormProps) => {
  const {
    findRssLinkedToTable,
    useListSampleRssFeedLoadable,
    importRssIntoTableExecutable,
    fieldsLoadable,
    updateRssTableMappingExecutable,
    deleteRssTableMappingExecutable,
    deleteDialogDisclosure,
  } = props
  const navigate = useNavigate();
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const tableSlug = useTableSlug();

  const [rssLoadable, setRssLoadable] = useLoadableState<RssTableMappingClientModel>(RssTableMappingClientModelFactory.createEmpty())
  useEffect(() => {
    if (findRssLinkedToTable.data && findRssLinkedToTable.data.rssUrl) {
      setRssLoadable(findRssLinkedToTable.data)
    }
  }, [findRssLinkedToTable])

  const listSampleRssFeedLoadable = useListSampleRssFeedLoadable({
    rssUrl: rssLoadable.data?.rssUrl ?? "",
    enabled: !!rssLoadable.data?.rssUrl && UrlChecker(rssLoadable.data.rssUrl)
  })

  const handleChangeRssField = (rssField: RssFieldClientModel) => {
    if (rssLoadable.data) {
      const rss = rssLoadable.data.updateFields(rssField)
      setRssLoadable(rss)
    }
  }

  const handleImportBtn = async (rss: RssTableMappingClientModel) => {
    const fields = RssFieldClientModelDefactory.toRssField(rss.fields)
    await importRssIntoTableExecutable.execute({ rssUrl: rss.rssUrl, fields })
  }

  const handleUpdateBtn = async (rss: RssTableMappingClientModel) => {
    const requestBody = RssTableMappingClientModelDefactory.toUpdateRequestBody(rss)
    await updateRssTableMappingExecutable.execute(requestBody)
  }

  const handleDeleteRss = async () => {
    await deleteRssTableMappingExecutable.execute()
    deleteDialogDisclosure.onClose()
    navigate(getPath("source", {
      teamSlug,
      databaseId,
      tableSlug,
    }));
  }

  return (
    <>
      <WithFallback loadables={[rssLoadable] as const}>
        {([rss]) => (
          <>
            <Box mb="5">
              <Text>Feed URL</Text>
              <Flex gap="2">
                <Box grow="1">
                  <Input
                    variant="primary"
                    value={rss.rssUrl}
                    onChange={(e) => setRssLoadable(rss.updateRssUrl(e.target.value))}
                  />
                  {
                    listSampleRssFeedLoadable.status === "loading" && (
                      <Flex align="center">
                        <AiOutlineReload size="14" />
                        <Text variant="description" ml="1">
                          Fetching feed...
                        </Text>
                      </Flex>
                    )
                  }
                </Box>
              </Flex>
            </Box>

            <WithFallback loadables={[listSampleRssFeedLoadable]}>
              {([samples]) => (
                <>
                  <Box mb="5">
                    <Text>Response</Text>
                    <TextArea
                      variant="primary"
                      value={samples.stringify}
                      disabled={true}
                    />
                  </Box>

                  <WithFallback loadables={[rssLoadable, fieldsLoadable] as const}>
                    {([rss, fields]) => (
                      <>
                        <Box mb="5">
                          <Text>Field Mapping</Text>
                          <FieldMappingSelect
                            fields={fields}
                            rssField={rss.fields}
                            onChangeField={handleChangeRssField}
                            field={"title"}
                            options={samples.titleOptions}
                          />
                          <FieldMappingSelect
                            fields={fields}
                            rssField={rss.fields}
                            onChangeField={handleChangeRssField}
                            field={"description"}
                            options={samples.descriptionOptions}
                          />
                          <FieldMappingSelect
                            fields={fields}
                            rssField={rss.fields}
                            onChangeField={handleChangeRssField}
                            field={"image"}
                            options={samples.imagesOptions}
                          />
                          <FieldMappingSelect
                            fields={fields}
                            rssField={rss.fields}
                            onChangeField={handleChangeRssField}
                            field={"link"}
                            options={samples.linksOptions}
                          />
                          <FieldMappingSelect
                            fields={fields}
                            rssField={rss.fields}
                            onChangeField={handleChangeRssField}
                            field={"pubDate"}
                            options={samples.pubDateOptions}
                          />
                        </Box>
                        {
                          findRssLinkedToTable.data && findRssLinkedToTable.data.rssUrl && (
                            <Flex align="center">
                              {
                                updateRssTableMappingExecutable.isExecuting
                                  ? <Spinner />
                                  : <Switch
                                    size="sm"
                                    isChecked={rss.isEnabled}
                                    onCheckedChange={(checked) => {
                                      setRssLoadable(rss.updateIsEnabled(checked))
                                    }}
                                  />
                              }
                              <Box ml="2" />
                              <Text>Enable</Text>
                              <Box ml="1" />
                              <Text variant="description">(Imports via RSS are performed periodically once every 4 hours.)</Text>
                            </Flex>
                          )
                        }

                        <Box mt="3">
                          {
                            findRssLinkedToTable.data && findRssLinkedToTable.data.rssUrl
                              ? <Flex>
                                <Spacer />
                                <Button
                                  variant="secondary"
                                  onClick={deleteDialogDisclosure.onOpen}
                                  color="red"
                                  isLoading={deleteRssTableMappingExecutable.isExecuting}
                                >Delete</Button>
                                <Box mr="5" />
                                <Button
                                  variant="primary"
                                  onClick={() => handleUpdateBtn(rss)}
                                  isLoading={updateRssTableMappingExecutable.isExecuting}
                                >Update</Button>
                              </Flex>
                              : <Flex>
                                <Spacer />
                                <Button
                                  variant="primary"
                                  onClick={() => handleImportBtn(rss)}
                                  isLoading={importRssIntoTableExecutable.isExecuting}
                                >Import Table</Button>
                              </Flex>
                          }
                        </Box>
                      </>
                    )}
                  </WithFallback>
                </>
              )}
            </WithFallback>
          </>
        )}
      </WithFallback>

      <DeleteAlertDialog
        isOpen={deleteDialogDisclosure.isOpen}
        setIsOpen={deleteDialogDisclosure.setIsOpen}
        onDelete={handleDeleteRss}
        deleteExecutable={deleteRssTableMappingExecutable}
      />
    </>
  )
}
