import { DashboardViewConditionObject } from "@usemorph/morph-dashboard-types";
import { useQuery } from "react-query";
import { useListSuggestPromptForGenerateSQLQuery } from "~/serverStateStore/sql/queries/useListSuggestPromptForGenerateSQLQuery";

type UsePromptSuggestionsParams = {
  teamSlug: string;
  databaseId: string;
} & (
  | {
      type: "table";
      tableSlug: string;
    }
  | {
      type: "view";
      viewId: string;
      condition?: DashboardViewConditionObject;
    }
);

const usePromptSuggestions = (params: UsePromptSuggestionsParams) => {
  const { teamSlug, databaseId, type } = params;

  const language = navigator.language;

  return useQuery({
    ...useListSuggestPromptForGenerateSQLQuery({
      teamSlug,
      databaseId,
      tableSlug: type === "table" ? params.tableSlug : undefined,
      viewId: type === "view" ? params.viewId : undefined,
      language,
      condition: type === "view" ? params.condition : undefined,
    }),
    retry: false,
  });
};

export { usePromptSuggestions };
