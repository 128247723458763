export const helpLinks = [
  {
    link: "https://notionforms.io/forms/inquiry-5",
    label: "Inquiry",
  },
];

export const helpCenterLinks = {
  top: "https://help.morphdb.io/",
};
