import { databaseRecordCountKeys } from "~/serverStateStore/databaseRecordCount";
// types
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiDatabaseRecordCount from "~/api/useApiDatabaseRecordCount";

interface UseGetDatabaseRecordCountParams {
  teamSlug: string;
}

const useGetDatabaseRecordCount = ({
  teamSlug,
}: UseGetDatabaseRecordCountParams) => {
  const { _getDatabaseRecordCount } = useApiDatabaseRecordCount();

  const client = useQueryClient();
  client.setQueryDefaults(
    databaseRecordCountKeys.all({ teamSlug }),
    commonQueryOptions
  );

  return {
    queryKey: databaseRecordCountKeys.all({ teamSlug }),
    queryFn: () => {
      return _getDatabaseRecordCount({
        teamSlug,
      });
    },
  };
};

export { useGetDatabaseRecordCount };
