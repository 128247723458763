import { RecordValueType } from "@usemorph/morph-dashboard-types";
import { match, P } from "ts-pattern";
import { FieldClientModel } from "~/clientModel/fields/field";
import { RecordEntryClientModel } from ".";

export class RecordEntryClientModelDefactory {
  public static toValueForRecordRequest(
    entry: RecordEntryClientModel,
    field: FieldClientModel
  ): RecordValueType {
    return (
      match(entry)
        // ユーザー系はpost時には、userIdのみ送る
        .with({ type: P.union("createdBy", "lastEditedBy") }, (entry) => {
          const validatedValue = entry.getValidatedValue(field);

          if (!validatedValue.isValid) {
            throw new Error(`Entry with key ${entry.key} is not valid`);
          }

          return validatedValue.value?.data.userId ?? null;
        })

        // アセット系はpost時には、dataのみ送る
        .with({ type: P.union("image", "attachment") }, (entry) => {
          const validatedValue = entry.getValidatedValue(field);

          if (!validatedValue.isValid) {
            throw new Error(`Entry with key ${entry.key} is not valid`);
          }

          return validatedValue.value?.data ?? null;
        })
        .otherwise((entry) => {
          const validatedValue = entry.getValidatedValue(field);

          if (!validatedValue.isValid) {
            throw new Error(`Entry with key ${entry.key} is not valid`);
          }

          return validatedValue.value;
        })
    );
  }
}
