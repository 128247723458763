import { Box } from "~/components_next/Box";
import { styled } from "~/stitches";

const DashboardCellCard = styled(Box, {
  position: "relative",
  borderRadius: "var(--radius-2)",
  padding: "10px",
  backgroundColor: "$bg0",
  boxShadow: "var(--shadow-2)",
  variants: {
    isSelected: {
      true: {
        outline: "solid 2px $blue9",
      },
    },
  },
});

export { DashboardCellCard };
