import { useState } from "react";
import { Executable } from "~/clientModel/executable";
import {
  CreatingComputedFieldSyncScheduleClientModel,
  CreatingComputedFieldSyncScheduleClientModelFactory,
} from "~/clientModel/tables/table/computedFieldSyncSchedule";
import { TimezoneClientModel } from "~/clientModel/timezone";
import { Button } from "~/components_next/Button";
import { Drawer } from "~/components_next/Drawer";
import { CreateComputedFieldsScheduledSyncSettingForm } from "./CreateComputedFieldsScheduledSyncSettingForm";

type CreateDrawerBodyAndFooterProps = {
  createSyncScheduleExecutable: Executable<
    {
      syncSchedule: CreatingComputedFieldSyncScheduleClientModel;
    },
    unknown
  >;
};

export const CreateDrawerBodyAndFooter = (
  props: CreateDrawerBodyAndFooterProps
) => {
  const { createSyncScheduleExecutable } = props;

  const [creatingSchedule, setCreatingSchedule] = useState(
    CreatingComputedFieldSyncScheduleClientModelFactory.createInitialCreatingComputedFieldSyncSchedule(
      {
        // todo: 一旦UTCで入れてる, 後でユーザー設定のタイムゾーンを入れるようにする
        // IntegrationのスケジュールとUXを揃えたい
        userTimezone: new TimezoneClientModel({ timezone: "UTC" }),
      }
    )
  );

  const handleCreateClick = () => {
    createSyncScheduleExecutable.execute({
      syncSchedule: creatingSchedule,
    });
  };

  return (
    <>
      <Drawer.Body>
        <CreateComputedFieldsScheduledSyncSettingForm
          value={creatingSchedule}
          onChange={setCreatingSchedule}
        />
      </Drawer.Body>
      <Drawer.Footer>
        <Button
          onClick={handleCreateClick}
          isLoading={createSyncScheduleExecutable.isExecuting}
          variant="primary"
        >
          Create
        </Button>
      </Drawer.Footer>
    </>
  );
};
