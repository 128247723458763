import { DashboardPageObject } from "@usemorph/morph-dashboard-types";
import { useState } from "react";
import { BsPlusLg, BsThreeDotsVertical } from "react-icons/bs";
import { useMutation } from "react-query";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { DangerDialog, Dialog } from "~/components_next/Dialog";
import {
  SimpleDropdownMenu,
  DropdownMenu,
} from "~/components_next/DropdownMenu";
import { Flex } from "~/components_next/Flex";
import { IconButton } from "~/components_next/IconButton";
import { Input } from "~/components_next/Input";
import { FlipVariablesProvider } from "~/features/Flip/context/FlipVariablesContext";
import { useNotebookPages } from "~/features/FlipNotebook/context/NotebookMetaContext";
import {
  useCreateNotebookPageMutaiton,
  useDeleteNotebookPageMutaiton,
  useUpdateNotebookPageMutaiton,
} from "~/serverStateStore/notebookPage";
import { styled } from "~/stitches";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useNotebookId } from "~/utilHooks/useNotebookId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { DashboardPage } from "../DashboardPage/DashboardPage";

const PageTabsWrapper = styled(Flex, {
  backgroundColor: "$bg1",
  paddingTop: "4px",
});

const PageTab = styled(Flex, {
  fontSize: "12px",
  variants: {
    isActive: {
      true: {
        backgroundColor: "$bg0",
      },
      false: {
        "&:hover": {
          backgroundColor: "$bg2",
          textDecoration: "underline",
        },
      },
    },
  },
});

const DashboardRoot = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const notebookId = useNotebookId();

  const pages = useNotebookPages();

  const [activePage, setActivePage] = useState<string>(pages[0].pageId);

  const { mutateAsync: onCreateNotebookPage, isLoading: isCreatingPage } =
    useMutation(
      useCreateNotebookPageMutaiton({
        notebookId,
        databaseId,
        teamSlug,
      })
    );

  const handleCreatePage = () => {
    onCreateNotebookPage({
      pageName: "Untitled Page",
      description: "",
      isPrivate: true,
    });
  };

  /**
   * Edit Page
   */
  const [editTargetPage, setEditTargetPage] =
    useState<DashboardPageObject | null>(null);
  const { mutateAsync: onUpdatePage, isLoading: isUpdatingPage } = useMutation(
    useUpdateNotebookPageMutaiton({
      teamSlug,
      databaseId,
      notebookId,
    })
  );
  const handleUpdatePage = () => {
    if (!editTargetPage) return;
    onUpdatePage({
      pageId: editTargetPage.pageId,
      pageName: editTargetPage.pageName,
      description: editTargetPage.description,
    });
  };

  /**
   * Delete Page
   */
  const [deleteTargetPage, setDeleteTargetPage] =
    useState<DashboardPageObject | null>(null);
  const { mutateAsync: onDeletePage } = useMutation(
    useDeleteNotebookPageMutaiton({
      teamSlug,
      databaseId,
      notebookId,
    })
  );

  const handleDeletePage = () => {
    if (!deleteTargetPage) return;
    onDeletePage({
      pageId: deleteTargetPage.pageId,
    });
  };

  return (
    <>
      <Box width="100%">
        {/* 上部タブ風 */}
        <PageTabsWrapper align="center" gap="1" px="1">
          {pages.map((page) => {
            return (
              <PageTab
                key={page.pageId}
                px="3"
                py="1"
                isActive={activePage === page.pageId}
                onClick={() => setActivePage(page.pageId)}
                gap="3"
                align="center"
              >
                {page.pageName}
                <SimpleDropdownMenu
                  trigger={
                    <IconButton
                      icon={<BsThreeDotsVertical />}
                      tooltip="More"
                      size="xs"
                    />
                  }
                >
                  <DropdownMenu.Item onClick={() => setEditTargetPage(page)}>
                    Settings
                  </DropdownMenu.Item>
                  <DropdownMenu.Item onClick={() => setDeleteTargetPage(page)}>
                    Delete Page
                  </DropdownMenu.Item>
                </SimpleDropdownMenu>
              </PageTab>
            );
          })}
          <IconButton
            size="sm"
            icon={<BsPlusLg />}
            tooltip={"Add Page"}
            style={{ transform: "translateX(6px)" }}
            onClick={handleCreatePage}
          />
        </PageTabsWrapper>
        <FlipVariablesProvider>
          <Box width="100%" p="2">
            {activePage && <DashboardPage pageId={activePage} />}
          </Box>
        </FlipVariablesProvider>
      </Box>

      {/* Delete Dialog */}
      <DangerDialog
        description="Are you sure to delete this page?"
        isOpen={deleteTargetPage !== null}
        onOpenChange={() => setDeleteTargetPage(null)}
        onConfirm={handleDeletePage}
      />

      {/* Settings Dialog */}
      <Dialog.Root
        open={editTargetPage !== null}
        onOpenChange={() => setEditTargetPage(null)}
        size="xs"
      >
        <Dialog.Content>
          <Dialog.Title>Page Settings</Dialog.Title>
          <Dialog.Body>
            {editTargetPage && (
              <Flex direction="column" gap="4" align="stretch" width="100%">
                <Input
                  label="Page Name"
                  variant="primary"
                  value={editTargetPage.pageName}
                  onChange={(e) => {
                    setEditTargetPage({
                      ...editTargetPage,
                      pageName: e.target.value,
                    });
                  }}
                />

                <Input
                  label="Description"
                  variant="primary"
                  value={editTargetPage.description || ""}
                  onChange={(e) => {
                    setEditTargetPage({
                      ...editTargetPage,
                      description: e.target.value,
                    });
                  }}
                />
              </Flex>
            )}
          </Dialog.Body>
          <Dialog.Footer>
            <Dialog.Close>
              <Button variant="secondary" size="sm" isDisabled={isUpdatingPage}>
                Close
              </Button>
            </Dialog.Close>
            <Button
              variant="primary"
              size="sm"
              isDisabled={isUpdatingPage}
              onClick={handleUpdatePage}
              isLoading={isUpdatingPage}
            >
              Update
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};

export { DashboardRoot };
