import { PromptHistoryClientModel } from "~/clientModel/promptHistories/promptHistory";
import { Drawer } from "~/components_next/Drawer";
import { PromptHistories } from "~/presenters/promptHistories/PromptHistories";
import { useUsePromptHistoriesLoadable } from "../../providers/DashboardTableViewPropsProvider";

type DashboardCellViewPromptHistoryDrawerProps = {
  viewId: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onHistoryClick: (history: PromptHistoryClientModel) => void;
};

const DashboardCellViewPromptHistoryDrawer = (
  props: DashboardCellViewPromptHistoryDrawerProps
) => {
  const { isOpen, onOpenChange, onHistoryClick, viewId } = props;

  const usePromptHistoriesLoadable = useUsePromptHistoriesLoadable();
  const promptHistoriesLoadable = usePromptHistoriesLoadable({
    viewId,
  });

  return (
    <Drawer.Root size="xl" open={isOpen} onOpenChange={onOpenChange}>
      <Drawer.Content>
        <Drawer.Title>Prompt History</Drawer.Title>
        <Drawer.Body>
          <PromptHistories
            promptHistoriesLoadable={promptHistoriesLoadable}
            onHistoryClick={onHistoryClick}
          />
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};

export { DashboardCellViewPromptHistoryDrawer };
