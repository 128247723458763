import useApiTable from "~/api/useApiTable";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { tablesKeys } from "../keys";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseCreateTableMutationParams {
  databaseId: string;
  teamSlug: string;
}

const useCreateTableMutation = ({
  databaseId,
  teamSlug,
}: UseCreateTableMutationParams) => {
  const { _createTable } = useApiTable();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      tableSlug,
      displayName,
      initPrompt,
      withDummyData,
      dummyDataRows,
      comment,
      isPrivate,
    }: {
      tableSlug: string;
      displayName: string;
      initPrompt?: string | undefined;
      withDummyData?: boolean | undefined;
      dummyDataRows?: number | undefined;
      comment?: string | undefined;
      isPrivate?: boolean | undefined;
    }) => {
      return _createTable({
        teamSlug,
        databaseId,
        tableSlug,
        displayName,
        initPrompt,
        withDummyData,
        dummyDataRows,
        comment,
        isPrivate,
      });
    },
    onSuccess: () => {
      const keys = tablesKeys.all({ databaseId, teamSlug });
      broadcast(keys);
      return invalidateAll(client, keys);
    },
  };
};

export { useCreateTableMutation };
