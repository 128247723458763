type CanvasVisualizationPromptConfigDataType = {
  type: string;
  chartType: string;
  icon: string;
  inputForms: {
    type: "title" | "grid" | "xyAxis" | "stackedBar";
    required: boolean;
    validation?: {
      xAxisLength?: number;
      yAxisLength?: number;
    };
  }[];
};

const camelCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export class CanvasVisualizationPromptConfigCientModel {
  readonly #data: CanvasVisualizationPromptConfigDataType;

  constructor(data: CanvasVisualizationPromptConfigDataType) {
    this.#data = data;
  }

  get type() {
    return this.#data.type;
  }

  get chartType() {
    return this.#data.chartType;
  }

  get chartTypeLabel() {
    return camelCaseToWords(this.#data.chartType);
  }

  get icon() {
    return this.#data.icon;
  }

  get inputForms() {
    return this.#data.inputForms;
  }

  get hasTitle() {
    return this.#data.inputForms.some(
      (inputForm) => inputForm.type === "title"
    );
  }

  get hasXYAxis() {
    return this.#data.inputForms.some(
      (inputForm) => inputForm.type === "xyAxis"
    );
  }

  private get xAxisLength() {
    const findXYAxis = this.#data.inputForms.find(
      (inputForm) => inputForm.type === "xyAxis"
    );

    if (!findXYAxis) {
      return undefined;
    }

    return findXYAxis.validation?.xAxisLength;
  }

  private get yAxisLength() {
    const findXYAxis = this.#data.inputForms.find(
      (inputForm) => inputForm.type === "xyAxis"
    );

    if (!findXYAxis) {
      return undefined;
    }

    return findXYAxis.validation?.yAxisLength;
  }

  /**
   * XY Axisの表示ロジック
   * xyAxisの項目があってvalidationが空 -> multiselect
   * xyAxisの項目があってvalidationのxyAxisの値が1 -> single select
   * xyAxisの項目がない -> 項目ごと表示しない
   * バックエンド仕様上はありえない: xyAxisの項目があってvalidationのxyAxisの値が0 -> 項目ごと表示しない
   * バックエンド仕様上はありえない: xyAxisの項目がなくてvalidationが2以上 -> multiselect
   */

  get xAxisInputType() {
    if (this.hasXYAxis && this.xAxisLength === 1) {
      return "single" as const;
    }
    if (this.hasXYAxis) {
      return "multiple" as const;
    }
    return "none" as const;
  }

  get yAxisInputType() {
    if (this.hasXYAxis && this.yAxisLength === 1) {
      return "single" as const;
    }
    if (this.hasXYAxis) {
      return "multiple" as const;
    }
    return "none" as const;
  }
}
