import { SimpleSelect } from "~/components_next/Select";

type LogicalOperator = "and" | "or";

type FilterConditionLogicalOperatorSelectProps = {
  logicalOperator: LogicalOperator;
  onLogicalOperatorChange: (logicalOperator: LogicalOperator) => void;
  isReadOnly?: boolean;
};

const options = [
  { value: "and", label: "And" },
  { value: "or", label: "Or" },
];

const FilterConditionLogicalOperatorSelect = ({
  logicalOperator,
  onLogicalOperatorChange,
  isReadOnly = false,
}: FilterConditionLogicalOperatorSelectProps) => {
  return (
    <SimpleSelect
      variant="supplementary"
      value={logicalOperator}
      onChange={(value) => onLogicalOperatorChange(value as LogicalOperator)}
      size="xs"
      isDisabled={isReadOnly}
      options={options}
    />
  );
};

export { FilterConditionLogicalOperatorSelect };
