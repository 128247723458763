import useApiDatabase from "~/api/useApiDatabase";
import useApiNotebook from "../api/useApiNotebook";

const useWarmup = () => {
  const { _activateNotebook } = useApiNotebook();

  const activateNotebook = ({
    teamSlug,
    databaseId,
  }: {
    teamSlug: string;
    databaseId: string;
  }) => {
    return _activateNotebook({
      teamSlug,
      databaseId,
    });
  };

  const { _activateDatabase } = useApiDatabase();

  return {
    activateNotebook,
    activateDatabase: _activateDatabase,
  };
};

export { useWarmup };
