import {
  ClearValueButton,
  ClearValueButtonWrapper,
} from "../../atoms/ClearValueButton";
import { RecordValueInputCommonProps } from "../../type";
import { DateInputBase } from "./common/DateInputBase";
import { forwardRef, ForwardedRef } from "react";

export const DateInput = forwardRef(function DateInput(
  props: RecordValueInputCommonProps<"date">,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { value, onChange, errorMessages, isNullable, isReadOnly, size } =
    props;
  return (
    <ClearValueButtonWrapper>
      <DateInputBase
        value={value}
        onChange={onChange}
        errorMessages={errorMessages}
        isNullable={isNullable}
        isReadOnly={isReadOnly}
        size={size}
        ref={ref}
      />
      {isNullable && (
        <ClearValueButton
          size={size}
          isDisabled={isReadOnly}
          onClear={() => onChange?.(null)}
        />
      )}
    </ClearValueButtonWrapper>
  );
});
