import { DashboardDatabaseResponse } from "@usemorph/morph-dashboard-types";



export class DatabaseClientModel {
  constructor(private readonly data: DashboardDatabaseResponse) { }

  /**
   * Getters
   */
  get status(): "inProgress" | "created" {
    return this.data.status;
  }

  get createdAt(): string {
    return this.data.createdAt;
  }

  get lastEditedAt(): string {
    return this.data.lastEditedAt;
  }

  get databaseId(): string {
    return this.data.databaseId;
  }

  get databaseName(): string {
    return this.data.databaseName;
  }

  get protocol(): string {
    return this.data.protocol;
  }

  get isBuiltin(): boolean {
    return this.data.isBuiltin;
  }

  get isPrivate(): boolean {
    return this.data.isPrivate;
  }

  get updateAt(): string | null {
    return this.data.updatedAt;
  }
}
