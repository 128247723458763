import { ComponentPropsWithoutRef, ReactNode } from "react";
import { Button } from "~/components_next/Button";
import { Flex } from "~/components_next/Flex";

type FooterButtonBaseProps = {
  rightIcon?: ReactNode;
  children: ReactNode;
} & Omit<
  ComponentPropsWithoutRef<typeof Button>,
  "rightIcon" | "children" | "variant" | "size"
>;

export const FooterButtonBase = (props: FooterButtonBaseProps) => {
  const { rightIcon, children, ...buttonProps } = props;

  return (
    <Flex direction="column" align="stretch">
      <Button
        variant="primary"
        size="sm"
        style={{ width: "100%", ...buttonProps.style }}
        {...buttonProps}
      >
        {rightIcon && <>{rightIcon}</>}
        {children}
      </Button>
    </Flex>
  );
};
