
import { NotificationBody, NotificationBodyClientModel } from "./NotificationBodyClientModel";

export const NotificationBodyClientModelFactory = {
  // factory methods
  fromNotificationBodyResponse: (
    data: NotificationBody
  ): NotificationBodyClientModel => {
    return new NotificationBodyClientModel(data);
  },
};
