import { UniqueIdentifier } from "@dnd-kit/core";
import constate from "constate";
import { useMemo, useState } from "react";
import { RecordsKanbanBaseProps } from "../RecordsKanbanBase";

type RecordsKanbanBaseContextProps = RecordsKanbanBaseProps;

const useRecordsKanbanBaseContext = (props: RecordsKanbanBaseContextProps) => {
  const [draggingRecordId, setDraggingRecordId] =
    useState<UniqueIdentifier | null>(null);
  const [draggingGroupingValue, setDraggingGroupingValue] =
    useState<UniqueIdentifier | null>(null);

  const flattenedRecords = useMemo(() => {
    return props.values.flatMap((value) => value.records);
  }, [props.values]);

  return {
    ...props,
    draggingRecordId,
    setDraggingRecordId,
    draggingGroupingValue,
    setDraggingGroupingValue,
    flattenedRecords,
  };
};

export const [
  RecordsKanbanBaseContextProvider,
  // values
  useRecordsKanbanValues,
  // meta
  useRecordsKanbanTitleFieldName,
  useRecordsKanbanPropertyFieldNames,
  useRecordsKanbanColumnValues,
  // dragging
  useRecordsKanbanDraggingRecordId,
  useRecordsKanbanSetDraggingRecordId,
  useRecordsKanbanDraggingGroupingValue,
  useRecordsKanbanSetDraggingGroupingValue,
  useRecordsKanbanDragFallbackMessage,
  // callback
  useRecordsKanbanOnCreateClicked,
  useRecordsKanbanOnUpdateRecord,
  useRecordsKanbanOnReorderRecord,
  useRecordsKanbanOnClickRecord,
  useRecordsKanbanGroupingField,
  useRecordsKanbanFlattenedRecords,
  useRecordsKanbanOnUpdateColumnValues,
] = constate(
  useRecordsKanbanBaseContext,
  // values
  (value) => value.values,
  // meta
  (value) => value.titleFieldName,
  (value) => value.propertyFieldNames,
  (value) => value.columnValues,
  // dragging
  (value) => value.draggingRecordId,
  (value) => value.setDraggingRecordId,
  (value) => value.draggingGroupingValue,
  (value) => value.setDraggingGroupingValue,
  (value) => value.dragFallbackMessage,
  // callback
  (value) => value.onCreateClicked,
  (value) => value.onUpdateRecord,
  (value) => value.onReorderRecord,
  (value) => value.onClickRecord,
  (value) => value.groupingField,
  (value) => value.flattenedRecords,
  (value) => value.onUpdateColumnValues
);
