import { Separator } from "@radix-ui/themes";
import { useMemo } from "react";
import { Executable } from "~/clientModel/executable";
import { MessageClientModel } from "~/clientModel/general";
import { NotificationClientModel } from "~/clientModel/notification"
import { NotificationBody } from "~/clientModel/notification/NotificationBodyClientModel";
import { NotificationBodyClientModelFactory } from "~/clientModel/notification/NotificationBodyClientModelFactory";
import { Badge } from "~/components_next/Badge";
import { Box } from "~/components_next/Box"
import { Flex } from "~/components_next/Flex";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";
import { getPath } from "~/routing";
import { UseMarkAsReadNotificationMutationProps } from "~/serverStateStore/notification/mutations/useMarkAsReadNotificationMutation";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { LinkItemWrapper } from "../GlobalNavigation/components/LinkItems/common/LinkItemWrapper";
import { LinkItemText } from "../GlobalNavigation/components/LinkItems/common/LinkItemText";

interface NotificationListItemProps {
  notification: NotificationClientModel;
  markAsReadExecutable: Executable<UseMarkAsReadNotificationMutationProps, MessageClientModel, unknown>
}

export const NotificationListItem = (props: NotificationListItemProps) => {
  const { notification, markAsReadExecutable } = props
  const teamSlug = useTeamSlug()

  const notificationBody = useMemo(() => {
    return NotificationBodyClientModelFactory.fromNotificationBodyResponse(notification.body as NotificationBody)
  }, [notification])

  const handleMarkAsRead = async ({ notificationId }: { notificationId: string }) => {
    if (notification.readAt === null) {
      await markAsReadExecutable.execute({ notificationId, operation: "read" })
    }
  }

  const to = useMemo(() => {
    return getPath("source", {
      teamSlug: notificationBody.team,
      databaseId: notificationBody.database,
      tableSlug: notificationBody.table,
    });
  }, [teamSlug, notification, notificationBody]);

  return (
    <>
      {notification && (
        <Box
          onClick={() => handleMarkAsRead({ notificationId: notification.notificationId })}
        >
          <Box p="2">
            <Box display="inline-block">
              <LinkItemWrapper to={to}>
                <LinkItemText>
                  <Box pl="2">
                    <Text variant="subheading">{notificationBody.table}</Text>
                  </Box>
                </LinkItemText>
              </LinkItemWrapper>
            </Box>

            <Flex my="1" ml="1" gap="2" align="center">
              {
                notification.readAt === null && (
                  <Box p="1" css={{ width: "8px", height: "8px", backgroundColor: "$crimson9", borderRadius: "100%" }}></Box>
                )
              }
              {
                notificationBody.status && (
                  notificationBody.status === "success"
                    ? <Badge variant="tertiary" color="green" radius="small">Success</Badge>
                    : <Badge variant="tertiary" color="red" radius="small">Error</Badge>
                )
              }
              <Badge variant="tertiary" radius="small">
                {notification.category}
              </Badge>
              <Badge variant="tertiary" radius="small">
                {notification.subCategory}
              </Badge>
              <Spacer />
            </Flex>

            {/* {
              !!notificationBody.resources.importDataSchema && (
                <Box py="1" ml="1">
                  {notificationBody.resources.importDataSchema}
                </Box>
              )
            }

            {
              !!notificationBody.resources.importIntegrationStatus && (
                <Box py="1" ml="1">
                  {notificationBody.resources.importIntegrationStatus}
                </Box>
              )
            }

            {
              !!notificationBody.resources.importIntegrationSchedule && (
                <Box py="1" ml="1">
                  {notificationBody.resources.importIntegrationSchedule}
                </Box>
              )
            }

            {
              !!notificationBody.resources.normalizedTableStatus && (
                <Box py="1" ml="1">
                  {notificationBody.resources.normalizedTableStatus}
                </Box>
              )
            } */}

            {/* {
              !!notificationBody.errors && (
                <Box py="1" ml="1" css={{ whiteSpace: "break-space" }}>
                  {notificationBody.formattedErrors}
                </Box>
              )
            } */}

            <Box ml="2">
              <Text variant="description">
                {notification.formattedCreatedAt}
              </Text>
            </Box>
          </Box>

          <Separator size="4" />
        </Box>
      )}
    </>
  )
}
