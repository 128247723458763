import { ForwardedRef, forwardRef } from "react";
import { Input } from "~/components_next/Input";
import { RecordValueInputCommonProps } from "../../type";

const UrlInput = forwardRef(function UrlInput(
  props: RecordValueInputCommonProps<"url">,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { value, onChange, errorMessages, isNullable, isReadOnly, size } =
    props;

  return (
    <Input
      variant="primary"
      size={size}
      value={value || ""}
      onChange={(event) => onChange?.(event.target.value)}
      readOnly={isReadOnly}
      errorMessage={errorMessages}
      type="url"
      ref={ref}
    />
  );
});

export { UrlInput };
