import { notebookKeys } from "../keys";
import useApiNotebook from "~/api/useApiNotebook";
import { useQueryClient } from "react-query";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseUpdateNotebookCellMetaMutationParams {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
}

const useUpdateNotebookCellMetaMutaiton = ({
  databaseId,
  teamSlug,
  notebookId,
}: UseUpdateNotebookCellMetaMutationParams) => {
  const { _updateNotebookCellMeta } = useApiNotebook();

  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      cellId,
      cellName,
      afterCellId,
      parentIds,
      hidePrompt,
      settings,
    }: {
      cellId: string;
      cellName?: string;
      afterCellId?: string | null;
      parentIds?: string[] | null;
      hidePrompt: boolean;
      settings?: any;
    }) => {
      return _updateNotebookCellMeta({
        teamSlug,
        notebookId,
        cellId,
        cellName,
        afterCellId,
        hidePrompt,
        settings,
        parentIds,
      });
    },
    onSuccess: () => {
      const keys = notebookKeys.findNotebook({
        databaseId,
        teamSlug,
        notebookId,
      });
      broadcast(keys);
      return invalidateAll(client, keys);
    },
  };
};

export { useUpdateNotebookCellMetaMutaiton };
