import { Container as RadixThemeContainer } from "@radix-ui/themes";
import { CSS, styled } from "@stitches/react"
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from "react"

type RadixThemeContainerProps = ComponentPropsWithoutRef<
  typeof RadixThemeContainer
>;

type ContainerProps = {
  css?: CSS
} & Omit<RadixThemeContainerProps, "css">

const StyledContainer = styled(RadixThemeContainer, {})

const _Container = (props: ContainerProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { css } = props

  const radixContainerProps = (({
    css,
    ...rest
  }) => rest)(props);

  return (
    <StyledContainer
      ref={ref}
      css={css}
      {...radixContainerProps}
    ></StyledContainer>
  )
}

const Container = forwardRef<HTMLDivElement, ContainerProps>(_Container)

export { Container }
