import { MouseEvent, useState } from "react";
import { FieldClientModel } from "~/clientModel/fields/field";
import { useTableSizings } from "../context/useTableSizing";
import { match, P } from "ts-pattern";
import {
  useRecordsTableBaseHandleCellClickEvent,
  useRecordsTableBaseHandleCellEditingEnd,
  useRecordsTableBaseTableSelection,
  useRecordsTableBaseUseRunSmartFieldExecutable,
} from "../context/ReactTableBaseContext";
import { RecordClientModel } from "~/clientModel/records/record";
import { Box } from "~/components_next/Box";
import { useTableCellBorderStyles } from "./useTableCellBorderStyles";
import { useTableCellBackgroundStyles } from "./useTableCellBackgroundStyles";
import { RecordEntryClientModel } from "~/clientModel/records/record/recordEntry";
import { CoworkerAvatar } from "./CoworkerAvatar";
import { RecordValueDisplay } from "~/components_next/RecordValueDisplay";
import { useCoworkerSelectionUtil } from "../common/useCoworkerSelectionUtil";
import { UseExecutable } from "~/clientModel/executable";

type TableCellProps = {
  field: FieldClientModel;
  record: RecordClientModel;
  className?: string;
};

const TableCell = (props: TableCellProps) => {
  const { field, record, className } = props;

  const recordEntry = record.selectEntry(field.name);

  const { getBodyRowHeight, getRecordColumnWidth } = useTableSizings();

  const tableSelection = useRecordsTableBaseTableSelection();

  const useRunSmartFieldExecutable =
    useRecordsTableBaseUseRunSmartFieldExecutable();

  const { isCellLockedByCoworker } = useCoworkerSelectionUtil();

  const isThisCellSelectedByCoworker = isCellLockedByCoworker({
    recordIdentifier: record.recordIdentifier,
    fieldName: field.name,
  });

  const { isCellUpdating } = useRecordsTableBaseHandleCellEditingEnd();

  const isThisCellUpdating = isCellUpdating({
    recordIdentifier: record.recordIdentifier,
    fieldName: field.name,
  });

  /**
   * Handlers
   */

  const handleCellClickEvent = useRecordsTableBaseHandleCellClickEvent();

  const handleCellClick = (event: MouseEvent<HTMLDivElement>) => {
    if (isThisCellSelectedByCoworker || isThisCellUpdating) {
      return;
    }

    const isCellSelected = tableSelection.isCellSelected({
      recordIdentifier: record.recordIdentifier,
      fieldName: field.name,
    });

    match([
      event.shiftKey,
      isCellSelected,
      tableSelection.taggedCellSelectionState.type,
    ])
      .with([true, P._, P._], () => {
        handleCellClickEvent("shiftClick", record, field.name);
      })
      .with([false, true, "single"], () => {
        handleCellClickEvent("goEdit", record, field.name);
      })
      .otherwise(() => {
        handleCellClickEvent("click", record, field.name);
      });
  };

  /**
   * UI
   */

  const { backgroundColor, onHoverChange } = useTableCellBackgroundStyles({
    record,
  });

  const borderStyles = useTableCellBorderStyles({ record, field });

  const getColumnWidthForNullableRecordEntry = (
    recordEntry: RecordEntryClientModel | null
  ): number => {
    if (recordEntry) {
      return getRecordColumnWidth(recordEntry.key);
    } else {
      return 200;
    }
  };

  const cursor =
    isThisCellSelectedByCoworker || isThisCellUpdating ? "not-allowed" : "cell";

  return (
    <Box
      style={{
        width: getColumnWidthForNullableRecordEntry(recordEntry),
        position: "relative",
        height: getBodyRowHeight(),
        cursor,
        userSelect: "none",
        padding: "4px",
      }}
      css={{ backgroundColor, ...borderStyles }}
      onMouseEnter={() => onHoverChange(true)}
      onMouseLeave={() => onHoverChange(false)}
      onClick={handleCellClick}
      className={className}
    >
      {recordEntry && useRunSmartFieldExecutable && (
        <RecordValueDisplayWithRetrySmartField
          record={record}
          isLoading={isThisCellUpdating}
          recordEntry={recordEntry}
          field={field}
          useRunSmartFieldExecutable={useRunSmartFieldExecutable}
        />
      )}

      {recordEntry && !useRunSmartFieldExecutable && (
        <RecordValueDisplayWithoutRetrySmartField
          isLoading={isThisCellUpdating}
          recordEntry={recordEntry}
          field={field}
        />
      )}

      <Box
        style={{
          position: "absolute",
          top: "50%",
          right: 6,
          transform: "translateY(-50%)",
        }}
      >
        <CoworkerAvatar record={record} field={field} />
      </Box>
    </Box>
  );
};

type RecordValueDisplayWithRetrySmartFieldProps = {
  record: RecordClientModel;
  isLoading: boolean;
  recordEntry: RecordEntryClientModel;
  field: FieldClientModel;
  useRunSmartFieldExecutable: UseExecutable<
    void,
    {
      records?: RecordClientModel[];
      fields?: FieldClientModel[];
      onlyError: boolean;
    }
  >;
};

const RecordValueDisplayWithRetrySmartField = (
  props: RecordValueDisplayWithRetrySmartFieldProps
) => {
  const { record, isLoading, recordEntry, field, useRunSmartFieldExecutable } =
    props;

  const runSmartFieldExecutable = useRunSmartFieldExecutable();

  // リトライをトリガーした後、pusherで通知がきてrefetchして初めてinProgressのフラグが立つので、それまでにラグがある
  // リトライされた後基底時間は強制的にローディングにする
  const [hasJustRetried, setHasJustRetried] = useState(false);

  const handleRetrySmartField = () => {
    setHasJustRetried(true);
    runSmartFieldExecutable.execute({
      records: [record],
      fields: [field],
      onlyError: true,
    });
    setTimeout(() => setHasJustRetried(false), 10 * 1000);
  };

  return (
    <RecordValueDisplay
      isLoading={isLoading || hasJustRetried}
      recordEntry={recordEntry}
      field={field}
      onRetrySmartField={handleRetrySmartField}
    />
  );
};

type RecordValueDisplayWithoutRetrySmartFieldProps = {
  isLoading: boolean;
  recordEntry: RecordEntryClientModel;
  field: FieldClientModel;
};

const RecordValueDisplayWithoutRetrySmartField = (
  props: RecordValueDisplayWithoutRetrySmartFieldProps
) => {
  const { isLoading, recordEntry, field } = props;

  return (
    <RecordValueDisplay
      isLoading={isLoading}
      recordEntry={recordEntry}
      field={field}
    />
  );
};

export { TableCell };
