import { useEffect } from "react";
import { useCreateImportSchema } from "../csvImport/useCreateImportSchema";
import { useCreateStorageObject } from "../csvImport/useCreateStorageObject";
import { useRunImportExecutable } from "../csvImport/useRunImportExecutable";
import { useCsvInsertBucket } from "./useCsvInsertBucket";
import { useUpdateInsertSchema } from "./useUpdateInsertSchema";
import { useDisclosure } from "~/hooks/useDisclosure";

type CsvInsertContainerProps = {
  tableSlug: string;
  teamSlug: string;
  databaseId: string;
};

const useCsvInsertContainer = (props: CsvInsertContainerProps) => {
  const { isOpen, setIsOpen } = useDisclosure();

  const { tableSlug, teamSlug, databaseId } = props;

  const { bucketLoadable } = useCsvInsertBucket({
    teamSlug,
    databaseId,
    tableSlug,
  });

  const { createObjectExecutable, reset: resetCreateObject } =
    useCreateStorageObject({
      bucketLoadable,
      teamSlug,
    });

  const { createImportSchemaExecutable, reset: resetImportSchema } =
    useCreateImportSchema({
      storageObjectInstance: createObjectExecutable.data,
      bucketInstance: bucketLoadable.data,
      databaseId,
      teamSlug,
      tableSlug,
    });

  const { updateImportSchemaExecutable } = useUpdateInsertSchema({
    teamSlug,
    databaseId,
    tableSlug,
  });

  const runImportExecutable = useRunImportExecutable({
    teamSlug,
    databaseId,
  });

  useEffect(() => {
    if (
      createObjectExecutable.data &&
      !createObjectExecutable.data.shouldSelectSheet
    ) {
      // sheetを選択する必要がない場合は、スキーマ作成へ
      createImportSchemaExecutable.execute({});
    }
  }, [createObjectExecutable.data]);

  const handleIsOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);
    if (!isOpen) {
      resetCreateObject();
      resetImportSchema();
    }
  };

  return {
    isOpen,
    onIsOpenChange: handleIsOpenChange,
    createObjectExecutable,
    createImportSchemaExecutable,
    updateImportSchemaExecutable,
    runImportExecutable,
  };
};

export { useCsvInsertContainer };
