import {
  EditorProvider,
  EditorProviderProps,
  useCurrentEditor,
} from "@tiptap/react";
import { extensions, extensionsBasicTexts } from "./extensions/extensions";
import "./tiptap.css";
import { RichTextEditorRootProvider } from "./Context/RichTextEditorRoot";
import { Flex } from "@radix-ui/themes";
import { styled } from "~/stitches";
import { BubbleMenu } from "./Menu/BubbleMenu/BubbleMenu";
import { TopMenuBar } from "./Menu/TopMenuBar/TopMenuBar";
import { useEffect, useState } from "react";

const EDITOR_ROOT_CLASS_NAME = "tiptap-html-rich-text-editor__root";

type EditableRichTextEditorProps = {
  onChange: (html: string, height: number) => void;
  onUploadImage?: (file: File) => Promise<{ url: string }>;
  onFocusChange?: (isFocused: boolean) => void;
};

type HTMLRichTextEditorProps = {
  html: string;
  height: string;
  hideTopbar?: boolean;
  onHeightChange?: (height: number) => void;
  autoFocus?: boolean;
  basicTextsOnly?: boolean;
  customEditorClass?: string;
} & (
  | ({
      isReadOnly?: false;
    } & EditableRichTextEditorProps)
  | ({
      isReadOnly: true;
    } & Partial<EditableRichTextEditorProps>)
);

const EditorWrapper = styled(Flex, {
  // borderColor: "$border",
  // borderWidth: 2,
  // borderStyle: "solid",
  overflowY: "auto",
  borderRadius: "$2",
});

const ContentSetter = ({
  html,
  isReadOnly,
}: Pick<HTMLRichTextEditorProps, "html" | "isReadOnly">) => {
  const { editor } = useCurrentEditor();
  const [prevValue, setPrevValue] = useState<string | null>(null);

  if (html !== prevValue && !editor?.isFocused) {
    setPrevValue(html);
    const ancherPosition = editor?.state.selection.anchor;
    editor?.commands.setContent(html);

    if (ancherPosition !== undefined) {
      editor?.commands.setTextSelection(ancherPosition);
    }
  }

  useEffect(() => {
    if (isReadOnly !== undefined) {
      editor?.setOptions({
        editable: !isReadOnly,
      });
    }
  }, [isReadOnly]);

  return null;
};

const HTMLRichTextEditor = ({
  html,
  isReadOnly,
  height,
  hideTopbar = false,
  autoFocus = true,
  basicTextsOnly = false,
  customEditorClass,
  onChange,
  onHeightChange,
  onUploadImage,
  onFocusChange,
}: HTMLRichTextEditorProps) => {
  const handleUpdate: EditorProviderProps["onUpdate"] = ({ editor }) => {
    const updatedHTML = editor.getHTML();
    onChange?.(updatedHTML, editor.view.dom.clientHeight);
  };

  const handleFocus = () => {
    onFocusChange?.(true);
  };

  const handleBlur = () => {
    onFocusChange?.(false);
  };

  return (
    <RichTextEditorRootProvider onUploadImage={onUploadImage}>
      <EditorWrapper direction="column">
        <EditorProvider
          extensions={basicTextsOnly ? extensionsBasicTexts : extensions}
          content={html}
          onUpdate={handleUpdate}
          editable={!isReadOnly}
          autofocus={autoFocus}
          editorProps={{
            attributes: {
              class: `${EDITOR_ROOT_CLASS_NAME} ${customEditorClass}`,
            },
          }}
          slotBefore={hideTopbar ? null : <TopMenuBar />}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          {!basicTextsOnly && <BubbleMenu />}
          {/* ↓ これやんないとpropsが変わってもcontentEditableの中が変わらない */}
          <ContentSetter html={html} isReadOnly={isReadOnly} />
        </EditorProvider>
      </EditorWrapper>
    </RichTextEditorRootProvider>
  );
};

export { HTMLRichTextEditor, EDITOR_ROOT_CLASS_NAME };
export type { HTMLRichTextEditorProps };
