type StorageObjectDataType = {
  createdAt: string | null;
  updatedAt: string | null;
  objectId: string | null;
  objectName: string;
  metaData: {
    mimetype: string;
    size: number;
    cacheControl: string;
    sheets: string[];
  } | null;
  lastAccessedAt: string | null;
};

export class StorageObjectClientModel {
  constructor(private _data: StorageObjectDataType) {}

  get objectId(): string | null {
    return this._data.objectId;
  }

  get objectName(): string {
    return this._data.objectName;
  }

  get metaData() {
    return this._data.metaData;
  }

  get lastAccessedAt(): string | null {
    return this._data.lastAccessedAt;
  }

  get updatedAt(): string | null {
    return this._data.updatedAt;
  }

  get createdAt(): string | null {
    return this._data.createdAt;
  }

  get isExel(): boolean {
    return this._data.metaData?.mimetype ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ? true
      : false;
  }

  get sheetLength(): number {
    return this._data.metaData?.sheets.length || 0;
  }

  get shouldSelectSheet(): boolean {
    return this.sheetLength > 1 && this.isExel;
  }
}
