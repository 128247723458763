import { useMutation } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import {
  FieldClientModel,
  FieldClientModelDefactory,
} from "~/clientModel/fields/field";

import { useErrorToast } from "~/components_next/Error";
import { useUpdateSimpleFieldMutation } from "~/serverStateStore";

type UseUpdateFieldExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useUpdateFieldExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseUpdateFieldExecutableParams): Executable<
  { field: FieldClientModel },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  const updateFieldOptions = useUpdateSimpleFieldMutation({
    teamSlug,
    databaseId,
    tableSlug,
  });

  return useComposeExecutable(
    useMutation({
      ...updateFieldOptions,
      mutationFn: ({ field }: { field: FieldClientModel }) => {
        const simpleField = FieldClientModelDefactory.toSimpleField(field);

        return updateFieldOptions.mutationFn({
          requestBody: simpleField,
        });
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseUpdateFieldExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseUpdateFieldExecutableParams) => {
  return () => useUpdateFieldExecutable({ teamSlug, databaseId, tableSlug });
};
