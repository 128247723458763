import { useCallback } from "react";
import { useRecordsTableBaseColumnSizing } from "./ReactTableBaseContext";

// propsベースで調整できるようにhooksで定義しておく。
export const useTableSizings = () => {
  const columnSizing = useRecordsTableBaseColumnSizing();

  // height
  const getHeaderRowHeight = useCallback(() => 40, []);
  const getBodyRowHeight = useCallback(() => 40, []);

  // width
  const getHeaderColumnCheckBoxWidth = useCallback(() => 40, []);
  const getHeaderColumnOpenRecordButtonWidth = useCallback(() => 40, []);
  const getRecordColumnWidth = useCallback(
    (fieldName: string) => {
      return columnSizing.getColumnWidth(fieldName);
    },
    [columnSizing]
  );
  const getAddFieldButtonWidth = useCallback(() => 40, []);

  return {
    getHeaderRowHeight,
    getBodyRowHeight,
    getHeaderColumnCheckBoxWidth,
    getHeaderColumnOpenRecordButtonWidth,
    getRecordColumnWidth,
    getAddFieldButtonWidth,
  };
};
