import { teamsKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiTeam from "~/api/useApiTeam";

interface UseGetTeamQueryParams {
  teamSlug: string;
}

const useGetTeamQuery = ({ teamSlug }: UseGetTeamQueryParams) => {
  const { _findTeam } = useApiTeam();

  const client = useQueryClient();
  client.setQueryDefaults(teamsKeys.getTeam({ teamSlug }), commonQueryOptions);

  return {
    queryKey: teamsKeys.getTeam({
      teamSlug,
    }),
    queryFn: () => {
      return _findTeam({
        teamSlug,
      });
    },
  };
};

export { useGetTeamQuery };
