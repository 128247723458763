import { useMutation } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import {
  ComputedFieldSyncScheduleClientModel,
  ComputedFieldSyncScheduleClientModelDefactory,
} from "~/clientModel/tables/table/computedFieldSyncSchedule";
import { useErrorToast } from "~/components_next/Error";
import { useUpdateNormalizedTableScheduleMutation } from "~/serverStateStore";

type UseUpdateSyncScheduleExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useUpdateSyncScheduleExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseUpdateSyncScheduleExecutableParams): Executable<
  { syncSchedule: ComputedFieldSyncScheduleClientModel },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  const updateNormalizedTableScheduleOptions =
    useUpdateNormalizedTableScheduleMutation({
      teamSlug,
      databaseId,
    });

  return useComposeExecutable(
    useMutation({
      ...updateNormalizedTableScheduleOptions,
      mutationFn: ({
        syncSchedule,
      }: {
        syncSchedule: ComputedFieldSyncScheduleClientModel;
      }) => {
        return updateNormalizedTableScheduleOptions.mutationFn({
          normalizedTableScheduleId: syncSchedule.data.id,
          requestBody:
            ComputedFieldSyncScheduleClientModelDefactory.toDashboardUpdateNormalizedTableScheduleRequestBody(
              {
                computedFieldSyncSchedule: syncSchedule,
              }
            ),
        });
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseUpdateSyncScheduleExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseUpdateSyncScheduleExecutableParams) => {
  return () =>
    useUpdateSyncScheduleExecutable({
      teamSlug,
      databaseId,
      tableSlug,
    });
};
