import { useMemo } from "react";
import { BsCodeSlash } from "react-icons/bs";
import { CanvasCellPromptClientModel } from "~/clientModel/canvas";
import { IconButton } from "~/components_next/IconButton";
import { PlaygroundCellActionButton } from "../common/PlaygroundCellActionButton";

type PlaygroundCellPromptCopyEmbedCodeProps = {
  cellClientModel: CanvasCellPromptClientModel;
};

const PlaygroundCellPromptCopyEmbedCode = (
  props: PlaygroundCellPromptCopyEmbedCodeProps
) => {
  const { cellClientModel } = props;

  const html = useMemo(() => {
    const htmlOutput = cellClientModel.htmlOuput;
    if (htmlOutput) {
      return `<div>${htmlOutput.value}</div>`;
    }
    return undefined;
  }, [cellClientModel]);

  const handleCopyHtml = () => {
    navigator.clipboard.writeText(
      html
        ?.replace(/>[\r\n ]+</g, "><")
        .replace(/(<.*?>)|\s+/g, (m, $1) => ($1 ? $1 : " "))
        .trim() || ""
    );
  };

  return (
    <PlaygroundCellActionButton>
      <IconButton
        size="lg"
        icon={<BsCodeSlash />}
        tooltip="Copy HTML code (static)"
        onClick={handleCopyHtml}
      />
    </PlaygroundCellActionButton>
  );
};

export { PlaygroundCellPromptCopyEmbedCode };
