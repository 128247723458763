import constate from "constate";
import { useMutation } from "react-query";
import { useErrorToast } from "~/components_next/Error";
import { useNotebookLiveObject } from "~/features/RealtimeCollaboration/Notebook/useNotebookLiveObject";
import { useRemoveNotebookCellMetaMutaiton } from "~/serverStateStore/notebook/mutations/useRemoveNotebookCellMutation";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useNotebookId } from "~/utilHooks/useNotebookId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

const useNotebookDeleteCellContext = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const notebookId = useNotebookId();

  const { cells: liveCells, removeNotebookCell } = useNotebookLiveObject();

  const { errorToast } = useErrorToast({});

  const { mutateAsync: deleteCell, isLoading: isRemoveingCells } = useMutation(
    useRemoveNotebookCellMetaMutaiton({
      teamSlug,
      databaseId,
      notebookId,
    })
  );

  // 1件ずつの処理に限定する
  // 複数件同時処理にするにしても、UI側でループ回してもらう
  const onDeleteCell = async (cellId: string) => {
    try {
      // 先にサーバーから削除: ちゃんと削除できるか確認した上で
      await deleteCell({ cellId });

      const index = liveCells?.findIndex((cell) => cell.cellId === cellId);

      if (index !== undefined && index >= 0) {
        removeNotebookCell(index);
      }
    } catch (e) {
      errorToast(e);
    }
  };

  return {
    onDeleteCell,
  };
};

export const [NotebookDeleteCellProvider, useNotebookOnDeleteCell] = constate(
  useNotebookDeleteCellContext,
  (value) => value.onDeleteCell
);
