import { Box, Flex } from "@radix-ui/themes";
import { ReactNode } from "react";
import { BsXLg } from "react-icons/bs";
import { IconButton } from "~/components_next/IconButton";
import { Popover } from "~/components_next/Popover";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";

type SettingPopoverProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  trigger?: ReactNode;
  footer?: ReactNode;
  children: ReactNode;
};

const SettingPopover = (props: SettingPopoverProps) => {
  const { title, isOpen, onClose, trigger, footer, children } = props;

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      // onClose();
    }
  };

  return (
    <Popover isOpen={isOpen} trigger={trigger} onOpenChange={handleOpenChange}>
      <Flex align="center" px="2">
        <Text variant="heading">{title}</Text>
        <Spacer />
        <IconButton
          size="xs"
          icon={<BsXLg />}
          tooltip="Close"
          onClick={onClose}
        />
      </Flex>
      <Box px="3" py="2">
        {children}
      </Box>

      {footer && (
        <>
          <Box px="2" style={{ width: "100%" }}>
            {footer}
          </Box>
        </>
      )}
    </Popover>
  );
};

export { SettingPopover };
