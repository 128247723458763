import {
  ResourceMemberResponse,
  ResourceNotebookSchema,
  ResourceTableSchema,
  ResourceViewSchema,
} from "@usemorph/morph-dashboard-types";
import { useMutation, useQuery } from "react-query";
import { useErrorToast } from "~/components_next/Error";
import { UserAvatar } from "~/components_next/User";
import { Flex } from "~/components_next/Flex";
import {
  useDeleteResourceMemberMutation,
  useListResourceMembers,
  useUpdateResourceMemberMutation,
} from "~/serverStateStore/resourceMember";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { AccessAuthorityCard } from "./AccessAuthorityCard";
import {
  convertAuthorityLabelToPermission,
  convertPermissionToAuthorityLabel,
} from "./permissionUtil";
import { AccessAuthority } from "./types";

type BelongedTableMemberProps = {
  member: ResourceMemberResponse;
  resource: ResourceTableSchema | ResourceNotebookSchema | ResourceViewSchema;
};
const BelongedResourceMember = (props: BelongedTableMemberProps) => {
  const { member, resource } = props;

  const teamSlug = useTeamSlug();

  const {
    mutateAsync: updateResourceMember,
    isLoading: isUpdatingResourceMember,
  } = useMutation({
    ...useUpdateResourceMemberMutation({
      teamSlug,
      resource,
    }),
  });

  const { mutateAsync: deleteResourceMember } = useMutation({
    ...useDeleteResourceMemberMutation({
      teamSlug,
      resource,
    }),
  });

  const { errorToast } = useErrorToast({});

  const handleAuthorityChange = async (authority: AccessAuthority) => {
    const permission = convertAuthorityLabelToPermission(authority);
    if (!permission) {
      try {
        await deleteResourceMember({
          userId: member.user.userId,
        });
      } catch (error) {
        errorToast(error);
      }
      return;
    }

    if (permission) {
      try {
        await updateResourceMember({
          userId: member.user.userId,
          permission,
        });
      } catch (error) {
        errorToast(error);
      }
    }
  };

  return (
    <AccessAuthorityCard
      key={member.user.userId}
      title={member.user?.username || "Unknown member"}
      description={member.user?.email || "Unknown email"}
      authority={convertPermissionToAuthorityLabel(member.permission)}
      onChangeAuthority={handleAuthorityChange}
      authorityIsLoading={isUpdatingResourceMember}
      leftIcon={
        member.user ? <UserAvatar user={member.user} size="sm" /> : undefined
      }
    />
  );
};

type BelongedTableMembersProps = {
  resource: ResourceTableSchema | ResourceNotebookSchema | ResourceViewSchema;
};
const BelongedResourceMembers = (props: BelongedTableMembersProps) => {
  const { resource } = props;

  const teamSlug = useTeamSlug();

  const { data: members } = useQuery({
    ...useListResourceMembers({
      teamSlug,
      resource,
      limit: 99999,
      skip: 0,
    }),
  });

  return (
    <Flex direction="column" align="stretch">
      {members?.items.map((member) => {
        return (
          <BelongedResourceMember
            key={member.user.userId}
            member={member}
            resource={resource}
          />
        );
      })}
      {/* General */}
      {/* <AccessAuthorityCard
          title={`Everyone in ${team?.teamName}`}
          description={`${team?.user.length || "?"} members`}
          authority={generalAccess}
          onChangeAuthority={handleGeneralAccessChange}
          authorityOptions={["Read & Write", "No Access"]}
        /> */}
    </Flex>
  );
};

export { BelongedResourceMembers };
