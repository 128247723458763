import { SimpleField } from "@usemorph/morph-dashboard-types";
import { useMemo, useRef, MouseEvent, CSSProperties, useState } from "react";
import { match, P } from "ts-pattern";
import {
  useRecordsTableBaseColumnSizing,
  useRecordsTableBaseCoworkerStates,
  useRecordsTableBaseFieldSelection,
  useRecordsTableBaseHandleCellEvent,
  useRecordsTableBaseRecordHilights,
} from "../context/ReactTableBaseContext";
import { useBorderStyle } from "../hooks/useBorderStyle";
import { RecordsTableBaseRecord } from "../types";
import {
  getCoworkerSelectionForField,
  getCoworkerSelectionRecordSelection,
  isCellSelectedByCoworker,
} from "../util/coworkerStateUtil";
import { isSameRecord } from "../util/recordsTableRecordUtil";
import { Box, Flex } from "@radix-ui/themes";
import { styled } from "~/stitches";
import { useClickAway } from "react-use";
import { RecordValueDisplay } from "~/components_next/RecordValue";

type RecordsTableCellProps = {
  field: SimpleField;
  record: RecordsTableBaseRecord;
  style?: CSSProperties;
  className?: string;
};

const StyledBox = styled(Box, {});

const RecordsTableCell = (props: RecordsTableCellProps) => {
  const { field, record, style, className } = props;

  // state
  const handleCellClickEvent = useRecordsTableBaseHandleCellEvent();
  const columnSizing = useRecordsTableBaseColumnSizing();
  const fieldSelection = useRecordsTableBaseFieldSelection();
  const coworkerStates = useRecordsTableBaseCoworkerStates();
  const recordHilights = useRecordsTableBaseRecordHilights();

  // local state
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const { borderAttributes } = useBorderStyle();

  const columnWidth = useMemo(() => {
    return columnSizing?.[field.name] || 200;
  }, [columnSizing, field]);

  const isFieldSelected = useMemo(() => {
    return fieldSelection?.includes(field.name);
  }, [fieldSelection, field]);

  const isRecordHilighted = useMemo(() => {
    if (recordHilights.length === 0) return false;

    const findRecord = recordHilights.find((r) => {
      return isSameRecord(r, record);
    });
    return findRecord !== undefined;
  }, [record, recordHilights]);

  // coworker states
  const coworkerSelection = useMemo(() => {
    if (!coworkerStates) {
      return undefined;
    }
    // セル編集・範囲選択は背景色を変えない

    const recordSelection = getCoworkerSelectionRecordSelection(
      record,
      coworkerStates
    );
    if (recordSelection.length > 0) {
      return recordSelection[0];
    }

    // 最後にフィールド選択をチェック
    const fieldSelections = getCoworkerSelectionForField(
      field.name,
      coworkerStates
    );
    return fieldSelections[0] || undefined;
  }, [coworkerStates, record, field.name]);

  const _isCellSelectedByCoworker = useMemo(() => {
    return isCellSelectedByCoworker(field.name, record, coworkerStates || []);
  }, [coworkerStates, field.name, record]);

  // events
  const handleCellClick = (event: MouseEvent<HTMLDivElement>) => {
    if (_isCellSelectedByCoworker) {
      console.log(`cell is locked by coworker`);
      return;
    }
    match([event.shiftKey, isFocused])
      .with([true, P._], () => {
        handleCellClickEvent("shiftClick", record, field.name);
      })
      .with([false, true], () => {
        handleCellClickEvent("goEdit", record, field.name);
      })
      .otherwise(() => {
        setIsFocused(true);
        handleCellClickEvent("click", record, field.name);
      });
  };

  const backgroundColor = useMemo(() => {
    return match([
      isRecordHilighted,
      isFieldSelected,
      isHovered,
      _isCellSelectedByCoworker,
    ])
      .with(
        [P._, P._, P._, true],
        () => `$${coworkerSelection?.colorScheme}2` || "$bg0"
      )
      .with([true, P._, P._, P._], () => "$bg1")
      .with([P._, true, true, P._], () => "$bg2")
      .with([P._, true, false, P._], () => "$bg2")
      .with([P._, false, true, P._], () => "$bg2")
      .otherwise(() => "transparent");
  }, [
    isRecordHilighted,
    isFieldSelected,
    isHovered,
    coworkerSelection,
    _isCellSelectedByCoworker,
  ]);

  const cellRef = useRef(null);
  useClickAway(cellRef, () => setIsFocused(false));

  return (
    <StyledBox
      ref={cellRef}
      css={{
        width: `${columnWidth}px`,
        textTransform: "none",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        position: "relative",
        height: "40px",
        cursor: "cell",
        userSelect: "none",
        backgroundColor,
        ...borderAttributes,
        ...style,
      }}
      // style
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleCellClick}
      className={className}
    >
      <Flex align="center" height="100%" width="100%" p="1">
        <RecordValueDisplay recordModelValue={record.values[field.name]} />
      </Flex>
    </StyledBox>
  );
};

export { RecordsTableCell };
