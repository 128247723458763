import { widgetDataKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiWidgetData from "~/api/useApiWidgetData";

type UseGetWidgetDataApisQueryParams = {
  databaseId: string;
  teamSlug: string;
  tableSlug?: string | undefined;
  widgetId?: string | undefined;
  limit?: number | undefined;
  skip?: number | undefined;
};

const useGetWidgetDataApisQuery = ({
  teamSlug,
  databaseId,
  tableSlug,
  widgetId,
  limit,
  skip,
}: UseGetWidgetDataApisQueryParams) => {
  const { _listWidgetData } = useApiWidgetData();

  const client = useQueryClient();
  client.setQueryDefaults(
    widgetDataKeys.getWidgetDataApis({
      teamSlug,
      databaseId,
      tableSlug,
    }),
    commonQueryOptions
  );

  return {
    queryKey: widgetDataKeys.getWidgetDataApis({
      teamSlug,
      databaseId,
      tableSlug,
      widgetId,
      limit,
      skip,
    }),
    queryFn: () => {
      return _listWidgetData({
        teamSlug,
        databaseId,
        tableSlug,
        widgetId,
        limit,
        skip,
      });
    },
  };
};

export { useGetWidgetDataApisQuery };
