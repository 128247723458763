import {
  DashboardAllSimpleFieldListResponse,
  DashboardComputedFieldOptionListResponse,
  DashboardCreateComputeFieldRequestBody,
  DashboardFieldListResponse,
  DashboardGenerateFormulaRequestBody,
  DashboardGenerateFormulaResponse,
  DashboardRunSmartFieldRequestBody,
  DashboardSimpleCreateFieldRequestBody,
  DashboardSimpleFieldListResponse,
  DashboardSimpleUpdateFieldRequestBody,
  SmartFieldFunctionListResponse,
} from "@usemorph/morph-dashboard-types";
import useApi from "./useApi";
export type DashboardGeneralResponse = {
  message: string;
};
import { AxiosResponse } from "axios";

export default function useApiFields() {
  const { executeRequest } = useApi();

  const _listFields = async ({
    teamSlug,
    databaseId,
    branchSlug,
    tableSlug,
  }: {
    teamSlug: string;
    databaseId: string;
    branchSlug?: string;
    tableSlug: string;
  }): Promise<{ fields: DashboardFieldListResponse }> => {
    return executeRequest(
      "GET",
      `/${databaseId}/field/${tableSlug}`,
      { branchSlug },
      {
        teamSlug,
      },
      undefined
    );
  };

  const _createField = async ({
    databaseId,
    tableSlug,
    branchSlug,
    teamSlug,
    field,
  }: {
    teamSlug: string;
    databaseId: string;
    branchSlug?: string;
    tableSlug: string;
    field: string;
  }): Promise<DashboardFieldListResponse> => {
    return executeRequest(
      "POST",
      `/${databaseId}/field/${tableSlug}`,
      { branchSlug },
      {
        teamSlug,
      },
      { field }
    );
  };

  const _updateField = async ({
    databaseId,
    tableSlug,
    branchSlug,
    teamSlug,
    field,
    newName,
  }: {
    databaseId: string;
    teamSlug: string;
    branchSlug: string;
    tableSlug: string;
    field: string;
    newName?: string;
  }): Promise<DashboardFieldListResponse> => {
    return executeRequest(
      "POST",
      `/${databaseId}/field/${tableSlug}`,
      { branchSlug },
      {
        teamSlug,
      },
      { field, newName }
    );
  };

  const _deleteField = async ({
    databaseId,
    fieldName,
    tableSlug,
    teamSlug,
  }: {
    databaseId: string;
    fieldName: string;
    teamSlug: string;
    tableSlug: string;
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest(
      "POST",
      `/${databaseId}/field/${tableSlug}/${fieldName}`,
      undefined,
      {
        teamSlug,
      },
      undefined
    );
  };

  const _listSimpleFields = async ({
    teamSlug,
    databaseId,
    branchSlug,
    tableSlug,
  }: {
    teamSlug: string;
    databaseId: string;
    branchSlug?: string;
    tableSlug: string;
  }): Promise<DashboardSimpleFieldListResponse> => {
    return executeRequest(
      "GET",
      `/${databaseId}/simple-field/${tableSlug}`,
      { branchSlug },
      {
        teamSlug,
      },
      undefined
    );
  };

  const _listAllSimpleFields = async ({
    teamSlug,
    databaseId,
    branchSlug,
  }: {
    teamSlug: string;
    databaseId: string;
    branchSlug?: string;
  }): Promise<DashboardAllSimpleFieldListResponse> => {
    return executeRequest(
      "GET",
      `/${databaseId}/simple-field`,
      { branchSlug },
      {
        teamSlug,
      },
      undefined
    );
  };

  const _createSimpleField = async ({
    teamSlug,
    databaseId,
    tableSlug,
    requestBody,
  }: {
    teamSlug: string;
    databaseId: string;
    tableSlug: string;
    requestBody: DashboardSimpleCreateFieldRequestBody;
  }): Promise<DashboardSimpleFieldListResponse> => {
    return executeRequest(
      "POST",
      `/${databaseId}/simple-field/${tableSlug}`,
      {},
      {
        teamSlug,
      },
      { ...requestBody }
    );
  };

  const _createComputeField = async ({
    teamSlug,
    databaseId,
    tableSlug,
    requestBody,
  }: {
    teamSlug: string;
    databaseId: string;
    tableSlug: string;
    requestBody: DashboardCreateComputeFieldRequestBody;
  }): Promise<DashboardSimpleFieldListResponse> => {
    return executeRequest(
      "POST",
      `/${databaseId}/simple-field/${tableSlug}/compute`,
      {},
      {
        teamSlug,
      },
      { ...requestBody }
    );
  };

  const _updateSimpleField = async ({
    databaseId,
    tableSlug,
    teamSlug,
    requestBody,
  }: {
    databaseId: string;
    tableSlug: string;
    teamSlug: string;
    requestBody: DashboardSimpleUpdateFieldRequestBody;
  }): Promise<AxiosResponse<DashboardSimpleFieldListResponse>> => {
    return executeRequest(
      "PUT",
      `/${databaseId}/simple-field/${tableSlug}`,
      undefined,
      { teamSlug },
      { ...requestBody }
    );
  };

  const _deleteSimpleField = async ({
    databaseId,
    fieldName,
    tableSlug,
    teamSlug,
  }: {
    databaseId: string;
    fieldName: string;
    tableSlug: string;
    teamSlug: string;
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest(
      "DELETE",
      `/${databaseId}/simple-field/${tableSlug}/${fieldName}`,
      undefined,
      { teamSlug },
      undefined
    );
  };

  const _runSmartField = async ({
    databaseId,
    tableSlug,
    teamSlug,
    requestBody,
  }: {
    databaseId: string;
    tableSlug: string;
    teamSlug: string;
    requestBody: DashboardRunSmartFieldRequestBody;
  }): Promise<DashboardGeneralResponse> => {
    return executeRequest(
      "POST",
      `/${databaseId}/simple-field/${tableSlug}/smart-field/run`,
      undefined,
      { teamSlug },
      { ...requestBody }
    );
  };

  const _generateFormula = async ({
    databaseId,
    tableSlug,
    teamSlug,
    requestBody,
  }: {
    databaseId: string;
    tableSlug: string;
    teamSlug: string;
    requestBody: DashboardGenerateFormulaRequestBody;
  }): Promise<DashboardGenerateFormulaResponse> => {
    return executeRequest(
      "POST",
      `/${databaseId}/simple-field/${tableSlug}/formula/generate`,
      undefined,
      { teamSlug },
      { ...requestBody }
    );
  };

  const _updateSimpleFieldOrder = async ({
    databaseId,
    tableSlug,
    teamSlug,
    order,
  }: {
    databaseId: string;
    tableSlug: string;
    teamSlug: string;
    order: string[];
  }): Promise<DashboardSimpleFieldListResponse> => {
    return executeRequest(
      "PUT",
      `/${databaseId}/simple-field/${tableSlug}/order`,
      undefined,
      { teamSlug },
      { fields: order }
    );
  };

  const _listComputedFieldOptionsForAggregation = async ({
    teamSlug,
  }: {
    teamSlug: string;
  }): Promise<DashboardComputedFieldOptionListResponse> => {
    return executeRequest(
      "GET",
      `/computed-field-option/aggregation`,
      undefined,
      { teamSlug }
    );
  };

  const _listComputedFieldOptionsForSmartFunction = async ({
    teamSlug,
  }: {
    teamSlug: string;
  }): Promise<SmartFieldFunctionListResponse> => {
    return executeRequest(
      "GET",
      `/computed-field-option/smart-function`,
      undefined,
      { teamSlug }
    );
  };

  return {
    _listFields,
    _createField,
    _updateField,
    _deleteField,
    _listSimpleFields,
    _listAllSimpleFields,
    _createSimpleField,
    _createComputeField,
    _updateSimpleField,
    _deleteSimpleField,
    _runSmartField,
    _generateFormula,
    _updateSimpleFieldOrder,
    _listComputedFieldOptionsForAggregation,
    _listComputedFieldOptionsForSmartFunction,
  };
}
