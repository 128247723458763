import { useMutation } from "react-query";
import useApiRecord from "~/api/useApiRecord";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import {
  FieldsClientModel,
  FieldsClientModelDefactory,
} from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";

import { Loadable } from "~/clientModel/loadable";
import {
  RecordsClientModel,
  RecordsClientModelFactory,
} from "~/clientModel/records";

type UsePreviewBulkEditRecordsByPromptExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  fieldsLoadable: Loadable<FieldsClientModel>;
};

const usePreviewBulkEditRecordsByPromptExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
  fieldsLoadable,
}: UsePreviewBulkEditRecordsByPromptExecutableParams): Executable<
  {
    prompt: string;
    field: FieldClientModel;
    smartFunctions: SmartFunctionsClientModel;
  },
  {
    records: RecordsClientModel;
    fields: FieldsClientModel;
  }
> => {
  const { _previewBulkUpdateRecordByPrompt } = useApiRecord();

  return useComposeExecutable(
    useMutation({
      mutationFn: async ({
        prompt,
        field,
        smartFunctions,
      }: {
        prompt: string;
        field: FieldClientModel;
        smartFunctions: SmartFunctionsClientModel;
      }) => {
        if (fieldsLoadable.status !== "success") {
          throw new Error("Something went wrong. Please try again.");
        }

        const { items, count } = await _previewBulkUpdateRecordByPrompt({
          teamSlug,
          databaseId,
          tableSlug,
          fieldName: field.data.name,
          prompt,
          filter: { or: [] },
        });

        // todo: サーバーでレスポンスにfieldsを入れてもらうようにする
        const records =
          RecordsClientModelFactory.createFromQueryRecordListResponseWithFields(
            {
              items,
              count,
              fields: FieldsClientModelDefactory.toSimpleFields(
                fieldsLoadable.data,
                {
                  smartFunctions,
                }
              ),
            }
          );

        return { records, fields: fieldsLoadable.data };
      },
    })
  );
};

export const createUsePreviewBulkEditRecordsByPromptExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
  fieldsLoadable,
}: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  fieldsLoadable: Loadable<FieldsClientModel>;
}) => {
  return () =>
    usePreviewBulkEditRecordsByPromptExecutable({
      teamSlug,
      databaseId,
      tableSlug,
      fieldsLoadable,
    });
};
