import { useDeleteImportIntegrationScheduleMutation } from "~/serverStateStore/imports/mutations/useDeleteImportIntegrationSchedule";
import { useMutation, useQuery } from "react-query";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useErrorToast } from "~/components_next/Error";
import { Drawer } from "~/components_next/Drawer";
import { uselistImportIntegrationScheduleQuery } from "~/serverStateStore/imports/queries/useListImportIntegrationSchedule";
import { useTableSlug } from "~/utilHooks/useTableSlug";
import { useUpdateImportIntegrationScheduleMutation } from "~/serverStateStore/imports/mutations/useUpdateImportIntegrationSchedule";
import { IntegrationSyncOptions } from "@usemorph/morph-dashboard-types";
import { ScheduleCoreInput } from "../IntegrationForm";
import { useMemo, useState } from "react";
import { convertToScheduledMins, IntegrationScheduleForm } from "../IntegrationScheduleForm";
import dayjs from "dayjs";
import { useCreateImportIntegrationScheduleMutation } from "~/serverStateStore/imports/mutations/useCreateImportIntegrationSchedule";
import { Form } from "@radix-ui/react-form";
import { Flex } from "~/components_next/Flex";
import { Checkbox } from "~/components_next/Checkbox";
import { Spacer } from "~/components_next/Spacer";
import { Button } from "~/components_next/Button";
import { useToast } from "~/components_next/Toast";

type ScheduleSettingPopoverProps = {
  isOpen: boolean;
  syncOptions: IntegrationSyncOptions
  onOpenChange: (isOpen: boolean) => void;
};

export const ScheduleSettingDrawer = (props: ScheduleSettingPopoverProps) => {
  const { isOpen, syncOptions, onOpenChange } = props;
  const databaseId = useDatabaseId()
  const teamSlug = useTeamSlug()
  const tableSlug = useTableSlug()
  const toast = useToast();
  const { errorToast } = useErrorToast({});

  // states
  const [scheduleBody, setScheduleBody] = useState<ScheduleCoreInput>({
    targetDays: [0, 0, 0, 0, 0, 0, 0],
    category: 'sync',
    scheduledMins: convertToScheduledMins(dayjs().format('hh:00')),
    isEnabled: false,
  })

  // functions
  const { data: listSchedule } = useQuery({
    ...uselistImportIntegrationScheduleQuery({
      databaseId,
      teamSlug,
      tableSlug,
      limit: 9999,
      skip: 0,
    }),
    enabled: tableSlug !== undefined,
    onSuccess: (response) => {
      if (response.items.length > 0) {
        const scheduleResponse = response.items[0]
        setScheduleBody({
          targetDays: scheduleResponse.targetDays,
          category: scheduleResponse.category,
          scheduledMins: scheduleResponse.scheduledMins,
          isEnabled: scheduleResponse.isEnabled,
        })
      }
    }
  });

  const schedule = useMemo(() => {
    if (listSchedule && listSchedule.items.length > 0) {
      return listSchedule.items[0]
    }
  }, [listSchedule])

  const { mutateAsync: createSchedule, isLoading: isCreating } = useMutation({
    ...useCreateImportIntegrationScheduleMutation({ databaseId, teamSlug })
  })
  const { mutateAsync: updateSchedule, isLoading: isUpdating } = useMutation({
    ...useUpdateImportIntegrationScheduleMutation({ databaseId, teamSlug })
  })
  const { mutateAsync: deleteSchedule, isLoading: isDeleting } = useMutation({
    ...useDeleteImportIntegrationScheduleMutation({ databaseId, teamSlug })
  })

  const handleCreateSchedule = async () => {
    try {
      await createSchedule({
        body: {
          ...scheduleBody,
          tableSlug,
        }
      })
    } catch (error) {
      errorToast(error)
    }
  }

  const handleUpdateSchedule = async () => {
    if (schedule) {
      try {
        await updateSchedule({
          importIntegrationScheduleId: schedule.id,
          body: scheduleBody,
        })
        toast({
          title: 'Saving is complete.',
          type: "success",
          duration: 3000,
        });
      } catch (e) {
        errorToast(e);
      }
    }
  }

  const handleDeleteSchedule = async () => {
    if (listSchedule && listSchedule.items.length) {
      try {
        await deleteSchedule({
          importIntegrationScheduleId: listSchedule?.items[0].id
        })
        onOpenChange(false)
      } catch (e) {
        errorToast(e)
      }
    }
  }

  return (
    <>
      <Drawer.Root
        open={isOpen}
        onOpenChange={onOpenChange}
        size="xs"
      >
        <Drawer.Content>
          <Drawer.Title>Schedule Settings</Drawer.Title>
          <Drawer.Body>
            {/* {JSON.stringify(schedule)} */}
            <Form>
              <IntegrationScheduleForm
                scheduleBody={scheduleBody}
                syncOptions={syncOptions}
                setScheduleBody={setScheduleBody}
                timezone={schedule?.timezone}
              />
            </Form>
            <Flex align="center" gap="2" mt="5">
              <Checkbox
                value={scheduleBody.isEnabled}
                onChange={(el) => setScheduleBody({ ...scheduleBody, isEnabled: el })}
              /> Enable
              <Spacer />
            </Flex>
            {/* <Spacer /> */}
          </Drawer.Body>
          <Drawer.Footer>
            {
              schedule && (
                <Button
                  variant="secondary"
                  colorSchemeOverride="red"
                  onClick={handleDeleteSchedule}
                  isLoading={isDeleting}
                  type="button"
                >
                  Delete
                </Button>
              )
            }
            {
              schedule
                ? <Button
                  variant="primary"
                  onClick={handleUpdateSchedule}
                  isLoading={isUpdating}
                  type="button"
                >
                  Save
                </Button>
                : <Button
                  variant="primary"
                  onClick={handleCreateSchedule}
                  isLoading={isCreating}
                  type="button"
                >
                  Create
                </Button>
            }
          </Drawer.Footer>
        </Drawer.Content>
      </Drawer.Root>
    </>
  );
};
