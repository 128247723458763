import { DashboardUserResponse } from "@usemorph/morph-dashboard-types";
import { FindUserClientModel } from ".";
import { UserColorClientModelFactory } from "./userColor";

export const FindUserClientModelFactory = {
  // factory methods
  fromUserResponse: (
    userResponse: DashboardUserResponse
  ): FindUserClientModel => {
    return new FindUserClientModel({
      email: userResponse.email,
      username: userResponse.username,
      firstName: userResponse.firstName,
      lastName: userResponse.lastName,
      iconUrl: userResponse.iconUrl,
      userId: userResponse.userId,
      color: UserColorClientModelFactory.createFromStringValue(
        userResponse.userId
      ),
    });
  },
};
