import { useState } from "react";
import { useDataApiKey } from "~/utilHooks/useDataApiKey";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useTableSlug } from "~/utilHooks/useTableSlug";
import { ToolbarWrapper } from "../components/atoms/ToolbarWrapper";
import { useMutation, useQuery } from "react-query";
import {
  useDeleteWidgetDataMutation,
  useFindWidgetDataApiQuery,
} from "~/serverStateStore";
import { BsThreeDotsVertical, BsTrash } from "react-icons/bs";
import { PresenceIcons } from "../../RealtimeCollaboration";
import { useNavigate } from "react-router-dom";
import { useErrorToast } from "~/components_next/Error";
import { getPath } from "~/routing";
import { Badge } from "~/components_next/Badge";
import { Box, Flex } from "@radix-ui/themes";
import { Text } from "~/components_next/Text";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { IconButton } from "~/components_next/IconButton";
import { Input } from "~/components_next/Input/Input";
import { Divider } from "~/components_next/Divider";
import { Spacer } from "~/components_next/Spacer";
import { Button } from "~/components_next/Button";

const WidgetDataApiToolbar = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const tableSlug = useTableSlug();
  const widgetDataApiKey = useDataApiKey();

  const { data: widgetDataApi } = useQuery({
    ...useFindWidgetDataApiQuery({
      databaseId,
      teamSlug,
      tableSlug,
      apiKey: widgetDataApiKey,
    }),
    onSuccess: (data) => {
      setWidgetDataApiMeta({
        apiName: data.apiName,
      });
    },
  });

  /**
   * Data API Meta
   */
  const [widgetDataApiMeta, setWidgetDataApiMeta] = useState<{
    apiName: string;
  }>({
    apiName: "",
  });

  /**
   * API Requests
   */
  const { errorToast } = useErrorToast({});

  // Delete
  const navigate = useNavigate();

  const { mutateAsync: deleteWidgetDataApi, isLoading: isDeleting } =
    useMutation({
      ...useDeleteWidgetDataMutation({
        databaseId,
        teamSlug,
      }),
    });

  const handleDeleteClicked = async () => {
    try {
      await deleteWidgetDataApi({
        apiKey: widgetDataApi?.apiKey,
      });
      if (widgetDataApi?.tableSlug) {
        navigate(
          getPath("source", {
            teamSlug,
            databaseId,
            tableSlug: widgetDataApi?.tableSlug,
          })
        );
      } else {
        navigate(getPath("databaseHome", { teamSlug, databaseId }));
      }
    } catch (e) {
      errorToast(e);
    }
  };

  return (
    <ToolbarWrapper>
      {widgetDataApi && (
        <>
          <Badge variant="tertiary">
            Data API - {widgetDataApi.apiType.toUpperCase()}
          </Badge>
          <Box>
            <Text>{widgetDataApi.apiName}</Text>
          </Box>
          <SimpleDropdownMenu
            trigger={
              <IconButton
                tooltip="Data API Settings"
                icon={<BsThreeDotsVertical />}
              />
            }
          >
            <Box p="2">
              <Input
                variant="primary"
                label="Name"
                value={widgetDataApiMeta.apiName}
                readOnly
              />
              <Divider />
              <Flex>
                <Spacer />
                <Button
                  variant="danger"
                  onClick={handleDeleteClicked}
                  isLoading={isDeleting}
                >
                  <BsTrash />
                  Delete API
                </Button>
              </Flex>
            </Box>
          </SimpleDropdownMenu>

          <Spacer />
          <PresenceIcons maxAvatars={3} />
        </>
      )}
    </ToolbarWrapper>
  );
};

export { WidgetDataApiToolbar };
