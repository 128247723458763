import { TableHistoryOperationType } from "@usemorph/morph-dashboard-types";
import { match } from "ts-pattern";

// todo: embeddingsの操作がfieldCreateになっているので、変換をしてユーザーに示す。
// 現状は普通のfieldCreateと区別する手段がないので一旦このままにしている。

export const getOperationLabel = ({
  key,
  username,
  isAdmin,
  isBulk,
}: {
  key: TableHistoryOperationType;
  username: string;
  isAdmin: boolean;
  isBulk: boolean;
}) => {
  const roleText = isAdmin ? "Admin" : "General";

  const subject = `${username}(${roleText})`;
  const bulkOrEmpty = isBulk ? "bulk " : "";

  return match(key)
    .with("tableCreate", () => `${subject} ${bulkOrEmpty}created table.`)
    .with("tableUpdate", () => `${subject} ${bulkOrEmpty}updated table.`)
    .with("tableDelete", () => `${subject} ${bulkOrEmpty}deleted table.`)
    .with("fieldCreate", () => `${subject} ${bulkOrEmpty}created a new field.`)
    .with("fieldUpdate", () => `${subject} ${bulkOrEmpty}updated a field.`)
    .with("fieldDelete", () => `${subject} ${bulkOrEmpty}deleted a field.`)
    .with(
      "recordCreate",
      () => `${subject} ${bulkOrEmpty}created a new record.`
    )
    .with("recordUpdate", () => `${subject} ${bulkOrEmpty}updated a record.`)
    .with("recordDelete", () => `${subject} ${bulkOrEmpty}deleted a record.`)
    .with(
      "recordBulkCreate",
      () => `${subject} ${bulkOrEmpty}created records. (CSV import)`
    )
    .with(
      "recordBulkCreateAuto",
      () => `${subject} ${bulkOrEmpty}created records. (Generated dummy data)`
    )
    .with(
      "recordBulkCreateIntegration",
      () =>
        `${subject} ${bulkOrEmpty}created records. (Import data from other services)`
    )
    .with("viewCreate", () => `${subject} ${bulkOrEmpty}created a new view.`)
    .with("viewDelete", () => `${subject} ${bulkOrEmpty}deleted a view.`)
    .with("viewUpdate", () => `${subject} ${bulkOrEmpty}updated a view.`)
    .with(
      "tableIndexCreate",
      () => `${subject} ${bulkOrEmpty}created a new table index.`
    )
    .with(
      "tableIndexDelete",
      () => `${subject} ${bulkOrEmpty}deleted a table index.`
    )
    .exhaustive();
};
