import useApiTable from "~/api/useApiTable";

interface UseCheckTableSlugExistMutationParams {
  teamSlug: string;
  databaseId: string;
}

const useCheckTableSlugExistMutaiton = ({
  teamSlug,
  databaseId,
}: UseCheckTableSlugExistMutationParams) => {
  const { _checkTableSlugExist } = useApiTable();
  return {
    mutationFn: ({ tableSlug }: { tableSlug: string }) => {
      return _checkTableSlugExist({
        teamSlug,
        databaseId,
        tableSlug,
      });
    },
    // なにもinvalidationしない
  };
};

export { useCheckTableSlugExistMutaiton };
