import {
  mergeValidationResults,
  ValidationResult,
  zValidate,
} from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";
import { z } from "zod";
import { TableClientModel } from "~/clientModel/tables/table";
import { FieldClientModel } from "../../FieldClientModel";
import { FieldTypeClientModel } from "..";

type GetSyncFieldTargetFieldType<T extends FieldTypeClientModel> = T extends {
  canBeTargetOfSyncingByComputedField: true;
}
  ? T["type"]
  : never;

export type SyncFieldTargetFieldType =
  GetSyncFieldTargetFieldType<FieldTypeClientModel>;

type SyncValueFieldTypeClientModelData = {
  syncTargetTableSlug: string | null;
  syncTargetFieldName: string | null;
  syncTargetFieldType: SyncFieldTargetFieldType | null;
};

export class SyncValueFieldTypeClientModel extends FieldTypeClientModelBase {
  readonly #data: SyncValueFieldTypeClientModelData;

  public get type(): "syncValue" {
    return "syncValue";
  }

  public constructor(data: SyncValueFieldTypeClientModelData) {
    super();
    this.#data = data;
  }

  public get data(): SyncValueFieldTypeClientModelData {
    return this.#data;
  }

  public get fieldTypeLabel(): "Sync Value" {
    return "Sync Value";
  }

  public get isAutoGenerated(): true {
    return true;
  }

  public get isEmbeddable(): false {
    return false;
  }

  public get canBeTargetOfSyncingByComputedField(): false {
    return false;
  }

  public validate(): ValidationResult {
    return mergeValidationResults(
      zValidate(z.string(), this.#data.syncTargetTableSlug),
      zValidate(z.string(), this.#data.syncTargetFieldName)
    );
  }

  public get _updatableFieldTypes() {
    return [];
  }

  public get syncTargetTableSlug(): string | null {
    return this.#data.syncTargetTableSlug;
  }

  public get syncTargetFieldName(): string | null {
    return this.#data.syncTargetFieldName;
  }

  public get syncTargetTableSlugOrThrow(): string {
    if (!this.#data.syncTargetTableSlug) {
      throw new Error("syncTargetTable dees not exist");
    }
    return this.#data.syncTargetTableSlug;
  }

  public get syncTargetFieldNameOrThrow(): string {
    if (!this.#data.syncTargetFieldName) {
      throw new Error("syncTargetField dees not exist");
    }
    return this.#data.syncTargetFieldName;
  }

  public get syncTargetFieldType(): SyncFieldTargetFieldType | null {
    return this.#data.syncTargetFieldType;
  }

  public get syncTargetFieldTypeOrThrow(): SyncFieldTargetFieldType {
    if (!this.#data.syncTargetFieldType) {
      throw new Error("syncTargetFieldType dees not exist");
    }
    return this.#data.syncTargetFieldType;
  }

  public updateSyncTargetTableSlug(
    table: TableClientModel
  ): SyncValueFieldTypeClientModel {
    return new SyncValueFieldTypeClientModel({
      ...this.#data,
      syncTargetTableSlug: table.tableSlug,
    });
  }

  public updateSyncTargetField(
    field: FieldClientModel
  ): SyncValueFieldTypeClientModel {
    return new SyncValueFieldTypeClientModel({
      ...this.#data,
      syncTargetFieldName: field.name,
      syncTargetFieldType: field.type.type as SyncFieldTargetFieldType, // todo: 一旦キャスト
    });
  }
}
