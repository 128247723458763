import { Box } from "~/components_next/Box";
import { Text } from "~/components_next/Text";
import { PlanTable } from "~/features/GlobalNavigation/components/Preference/plans/PlanTable";
import { UnsubscribeDialog } from "~/features/GlobalNavigation/components/Preference/plans/components/UnsubscribeDialog";
import { UseDisclosureReturn } from "~/hooks/useDisclosure";
import { Executable, UseExecutable } from "~/clientModel/executable";
import { CreatePaymentSessionMutationProps, RegistTrialMutationProps, UpdatePaymentMutationProps } from "~/serverStateStore";
import { PaymentClientModel } from "~/clientModel/payment/PaymentClientModel";
import { DangerDialog } from "~/components_next/Dialog";
import { Loadable } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { VersionClientModel } from "~/clientModel/version/VersionClientModel";
import { ListPlansClientModel } from "~/clientModel/plan/ListPlansClientModel";
import { ConfirmDialog } from "~/features/GlobalNavigation/components/Preference/plans/components/ConfirmDialog";


interface PlanPresenterProps {
  plansLoadable: Loadable<ListPlansClientModel, unknown>
  paymentLoadable: Loadable<PaymentClientModel>
  listVersionLoadable: Loadable<VersionClientModel[]>
  useCreatePaymentSessionExecutable: UseExecutable<void, CreatePaymentSessionMutationProps, { url: string; }>
  useUpdatePaymentExecutable: UseExecutable<void, UpdatePaymentMutationProps, PaymentClientModel>
  useRegistTrialExecutable: UseExecutable<void, RegistTrialMutationProps, PaymentClientModel>
  deletePaymentExecutable: Executable<void, PaymentClientModel>
  selectPlan: { planId: string; planName: string; } | undefined
  handleSelectPlan: ({ planId, planName }: { planId: string, planName: string }) => void;
  confirmDialogDisclosure: UseDisclosureReturn;
  unsubscribeDialogDisclosure: UseDisclosureReturn;
  dangerDialogDisclosure: UseDisclosureReturn;
  dangerErrors: { title: string; description: string; } | undefined
  onConfirmSelectPlan: (planId: string) => void;
  onStartTrial: () => void;
  onClickContactUs: () => void;
  onUnsubscribe: () => void;
}

export const PlanPresenter = (props: PlanPresenterProps) => {
  const {
    plansLoadable,
    paymentLoadable,
    listVersionLoadable,
    useCreatePaymentSessionExecutable,
    useUpdatePaymentExecutable,
    useRegistTrialExecutable,
    deletePaymentExecutable,
    selectPlan,
    handleSelectPlan,
    onConfirmSelectPlan,
    confirmDialogDisclosure,
    unsubscribeDialogDisclosure,
    dangerDialogDisclosure,
    dangerErrors,
    onStartTrial,
    onClickContactUs,
    onUnsubscribe,
  } = props

  const createPaymentSessionExecutable = useCreatePaymentSessionExecutable()
  const updatePaymentExecutable = useUpdatePaymentExecutable()
  const registTrialExecutable = useRegistTrialExecutable()

  return (
    <>
      <Box
        css={{ maxWidth: "1080px", height: "520px", overflowY: "hidden" }}
        p="4"
      >
        <Box mb="4">
          <Text variant="heading">Plans</Text>
        </Box>
        <WithFallback loadables={[plansLoadable, paymentLoadable, listVersionLoadable] as const}>
          {([plans, payment, versions]) => (
            <PlanTable
              plans={plans}
              payment={payment}
              versions={versions}
              handleSelectPlan={handleSelectPlan}
              onConfirmSelectPlan={onConfirmSelectPlan}
              onStartTrial={onStartTrial}
              onClickContactUs={onClickContactUs}
              createPaymentSessionExecutable={createPaymentSessionExecutable}
              updatePaymentExecutable={updatePaymentExecutable}
              registTrialExecutable={registTrialExecutable}
            />
          )}
        </WithFallback>
      </Box>

      <ConfirmDialog
        isOpen={confirmDialogDisclosure.isOpen}
        setIsOpen={confirmDialogDisclosure.setIsOpen}
        onConfirm={onConfirmSelectPlan}
        selectPlan={selectPlan}
        isLoading={updatePaymentExecutable.isExecuting || createPaymentSessionExecutable.isExecuting}
      />

      <UnsubscribeDialog
        isOpen={unsubscribeDialogDisclosure.isOpen}
        setIsOpen={unsubscribeDialogDisclosure.setIsOpen}
        onUnsubscribe={onUnsubscribe}
        deletePaymentExecutable={deletePaymentExecutable}
      />

      {
        dangerErrors && (
          <DangerDialog
            isOpen={dangerDialogDisclosure.isOpen}
            onOpenChange={dangerDialogDisclosure.setIsOpen}
            description={dangerErrors?.description}
            onConfirm={dangerDialogDisclosure.onClose}
          />
        )
      }
    </>
  );
};
