// types
import { useQueryClient } from "react-query";
import useApiResource from "~/api/useApiResource";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { resourcesKeys } from "../keys";

type UsePostResourceMutationParams = {
  teamSlug: string;
};

const usePostResourceMutation = ({
  teamSlug,
}: UsePostResourceMutationParams) => {
  const { _postResource } = useApiResource();

  const client = useQueryClient();

  return {
    mutationFn: ({
      fileName,
      isPublic,
    }: {
      isPublic: boolean;
      fileName: string;
    }) => {
      return _postResource({
        teamSlug,
        fileName,
        isPublic,
      });
    },
    onSuccess: () => {
      return invalidateAll(client, resourcesKeys.all());
    },
  };
};

export { usePostResourceMutation };
