import dayjs from "dayjs";
import { ScheduledTimeClientModel } from "./ScheduledTimeClientModel";

export class ScheduledTimeClientModelFactory {
  public static fromScheduledMins(
    scheduledMins: number
  ): ScheduledTimeClientModel {
    return new ScheduledTimeClientModel({ scheduledMins });
  }

  public static createNow(): ScheduledTimeClientModel {
    const now = dayjs();
    const mins = now.hour() * 60 + now.minute();
    const scheduledMins = Math.floor(mins / 15) * 15;
    return new ScheduledTimeClientModel({ scheduledMins });
  }
}
