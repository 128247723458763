import { RecordFilterCondition } from "@usemorph/morph-dashboard-types";
import { useMutation } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields/FieldsClientModel";
import { Loadable } from "~/clientModel/loadable";
import {
  RecordClientModel,
  RecordClientModelDefactory,
} from "~/clientModel/records/record";
import { useErrorToast } from "~/components_next/Error";
import { useDeleteRecordMutation } from "~/serverStateStore";

type UseDeleteRecordsExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  fieldsLoadable: Loadable<FieldsClientModel>;
};

const useDeleteRecordsExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
  fieldsLoadable,
}: UseDeleteRecordsExecutableParams): Executable<
  { recordArray: RecordClientModel[] },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  const deleteRecordsOptions = useDeleteRecordMutation({
    teamSlug,
    databaseId,
    tableSlug,
  });

  return useComposeExecutable(
    useMutation({
      ...deleteRecordsOptions,
      mutationFn: ({ recordArray }: { recordArray: RecordClientModel[] }) => {
        if (fieldsLoadable.status !== "success") {
          throw new Error("Something went wrong.");
        }

        const filter: RecordFilterCondition = {
          or: recordArray.map((record) =>
            RecordClientModelDefactory.toRecordFilterConditionAnd(
              record,
              fieldsLoadable.data
            )
          ),
        };

        return deleteRecordsOptions.mutationFn({
          filter,
          isHardDelete: false,
        });
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseDeleteRecordsExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
  fieldsLoadable,
}: UseDeleteRecordsExecutableParams) => {
  return () =>
    useDeleteRecordsExecutable({
      teamSlug,
      databaseId,
      tableSlug,
      fieldsLoadable,
    });
};
