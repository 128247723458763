import { AggregateValueFieldOperatorClientModel } from "./aggregateValueFieldOperator/AggregateValueFieldOperatorClientModel";

type AggregateValueFieldOperatorsClientModelData = {
  operators: AggregateValueFieldOperatorClientModel[];
};

export class AggregateValueFieldOperatorsClientModel {
  public constructor(
    readonly data: AggregateValueFieldOperatorsClientModelData
  ) {}

  public get operators(): { label: string; value: string }[] {
    return this.data.operators;
  }
}
