import { BsDatabase, BsSticky, BsTrash, BsType } from "react-icons/bs";
import { useReactFlow, useViewport } from "reactflow";
import {
  DropdownMenu,
  SimpleDropdownMenu,
} from "~/components_next/DropdownMenu";
import { Flex } from "~/components_next/Flex";
import { IconButton } from "~/components_next/IconButton";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useSortedTables } from "~/serverStateStore/tables";
import { useMemo } from "react";
import { ScrollArea } from "@radix-ui/themes";
import { useNotebookId } from "~/utilHooks/useNotebookId";
import { useNotebookSelectedNodes } from "../../util/useNotebookSelectedNodes";
import { useNotebookRenderAreaSize } from "../../context/NotebookRenderAreaContext";
import { useSetNotebookCellSelection } from "../../state/notebookCellSelection";
import { useNotebookIsPrivate } from "../../context/NotebookMetaContext";
import { styled } from "~/stitches";
import { Box } from "~/components_next/Box";
import { useNotebookOnDeleteCell } from "../../context/NotebookDeleteCellContext";
import { useNotebookOnCreateCell } from "../../context/NotebookCreateCellContext";

const StickyNoteColorElement = styled(Box, {
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  variants: {
    color: {
      amber: {
        backgroundColor: "$amber5",
      },
      crimson: {
        backgroundColor: "$crimson5",
      },
      green: {
        backgroundColor: "$green5",
      },
      blue: {
        backgroundColor: "$blue5",
      },
    },
  },
});

const NotebookAddCellControls = () => {
  const databaseId = useDatabaseId();
  const teamSlug = useTeamSlug();
  const notebookId = useNotebookId();

  const { queryResult } = useSortedTables({ teamSlug, databaseId });

  const isPrivate = useNotebookIsPrivate();

  const sources = useMemo(() => {
    // TODO: ロジック
    if (queryResult.data) {
      if (isPrivate) {
        return queryResult.data.tables;
      }
      return queryResult.data.tables.filter((t) => !t.isPrivate);
    }
    return [];
  }, [queryResult, isPrivate]);

  /**
   * Node Selection State
   */
  const { selectedCells, selectedCell } = useNotebookSelectedNodes(); // 何が選択されてるかによって、表示するボタンを変える

  /**
   * Create Node
   */
  const onCreateCell = useNotebookOnCreateCell();

  const viewPort = useViewport();
  const renderAreaSize = useNotebookRenderAreaSize();
  const reactFlow = useReactFlow();

  const centerPosition = useMemo((): {
    x: number;
    y: number;
  } => {
    if (renderAreaSize.width < 0 || renderAreaSize.height < 0)
      return {
        x: viewPort.x,
        y: viewPort.y,
      };

    const projectedCenter = reactFlow.project({
      x: renderAreaSize.width / 2,
      y: renderAreaSize.height / 2,
    });
    return {
      x: projectedCenter.x,
      y: projectedCenter.y,
    };
  }, [viewPort.x, viewPort.y, renderAreaSize, reactFlow]);

  /**
   * Create Cells
   */
  const setCellSelection = useSetNotebookCellSelection(notebookId);

  const handleCreateSourceCell = async (
    tableSlug: string,
    tableName: string
  ) => {
    const createResponse = await onCreateCell({
      cellType: "source",
      cellName: tableName,
      source: {
        tableSlug: tableSlug,
      },
      settings: {
        x: centerPosition.x,
        y: centerPosition.y,
        width: 300,
        height: 120,
      },
    });

    setCellSelection([createResponse.cellId]);
  };

  const handleCreateRichTextCell = async () => {
    const createResponse = await onCreateCell({
      cellType: "richText",
      cellName: "Text Cell",
      source: {
        richText: "<p></p><p></p><p></p>",
      },
      settings: {
        x: centerPosition.x,
        y: centerPosition.y,
        width: 200,
        height: 80,
      },
    });

    setCellSelection([createResponse.cellId]);
  };

  const handleCreateStickyNote = async (color: string) => {
    const createResponse = await onCreateCell({
      cellType: "stickyNote",
      cellName: "Sticky Note",
      source: {
        stickyNote: {
          value: "<p></p><p></p>",
          color: color,
        },
      },
      settings: {
        x: centerPosition.x,
        y: centerPosition.y,
        width: 240,
        height: 120,
      },
    });

    setCellSelection([createResponse.cellId]);
  };

  /**
   * Remove Cells
   */
  const onRemoveCell = useNotebookOnDeleteCell();

  const handleRemoveCells = () => {
    if (selectedCells.length === 0) return;
    selectedCells.forEach((cell) => onRemoveCell(cell.cellId));
  };

  return (
    <>
      <Flex align="center" gap="2">
        <Flex
          gap="2"
          py="2"
          px="2"
          css={{
            backgroundColor: "$whiteA10",
            borderRadius: "var(--radius-4)",
            boxShadow: "var(--shadow-4)",
          }}
        >
          <SimpleDropdownMenu
            trigger={
              <IconButton size="xl" icon={<BsDatabase />} tooltip="Source" />
            }
          >
            <ScrollArea style={{ maxHeight: "300px" }}>
              {sources.map((source) => (
                <DropdownMenu.Item
                  key={source.tableSlug}
                  onClick={() =>
                    handleCreateSourceCell(
                      source.tableSlug,
                      source.displayName || source.tableSlug
                    )
                  }
                >
                  {source.displayName}
                </DropdownMenu.Item>
              ))}
            </ScrollArea>
          </SimpleDropdownMenu>
          <IconButton
            size="xl"
            icon={<BsType />}
            tooltip="Text"
            onClick={handleCreateRichTextCell}
          />
          <SimpleDropdownMenu
            trigger={
              <IconButton size="xl" icon={<BsSticky />} tooltip="Note" />
            }
          >
            <Flex gap="4">
              <IconButton
                icon={<StickyNoteColorElement color="amber" />}
                tooltip="Amber"
                onClick={() => handleCreateStickyNote("amber")}
              />
              <IconButton
                icon={<StickyNoteColorElement color="crimson" />}
                tooltip="Crimson"
                onClick={() => handleCreateStickyNote("crimson")}
              />
              <IconButton
                icon={<StickyNoteColorElement color="green" />}
                tooltip="Green"
                onClick={() => handleCreateStickyNote("green")}
              />
              <IconButton
                icon={<StickyNoteColorElement color="blue" />}
                tooltip="Blue"
                onClick={() => handleCreateStickyNote("blue")}
              />
            </Flex>
          </SimpleDropdownMenu>
        </Flex>

        {selectedCells.length === 1 && (
          <Flex
            gap="2"
            py="2"
            px="2"
            css={{
              backgroundColor: "$whiteA10",
              borderRadius: "var(--radius-4)",
              boxShadow: "var(--shadow-4)",
            }}
          >
            <IconButton
              size="xl"
              icon={<BsTrash />}
              tooltip="Delete Cells"
              onClick={handleRemoveCells}
            />
          </Flex>
        )}
      </Flex>
    </>
  );
};

export { NotebookAddCellControls };
