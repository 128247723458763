import constate from "constate";
import { useCallback, useMemo, useState } from "react";
import { ReactFlowState, useNodes, useStore } from "reactflow";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";

const _useReactFlowSelectedCells = ({
  cells,
}: {
  cells: CanvasCellClientModelUnion[];
}) => {
  const allNodes = useNodes();
  const selectedNodes = useMemo(() => {
    return allNodes.filter((node) => node.selected);
  }, [allNodes]);
  const selectedNodeIds = useMemo(() => {
    return selectedNodes.map((node) => node.id);
  }, [selectedNodes]);

  const selectedCells = useMemo(() => {
    return cells.filter((cell) => {
      return selectedNodeIds.includes(cell.cellId);
    });
  }, [cells, selectedNodeIds]);

  const addSelectedNodesSelector = useCallback((s: ReactFlowState) => {
    return s.addSelectedNodes;
  }, []);
  const addSelectedNodes = useStore(addSelectedNodesSelector);

  const [isNodeSelectionLocked, setIsNodeSelectionLocked] = useState(false);

  const memoizedValue = useMemo(() => {
    return {
      selectedNodes,
      selectedNodeIds,
      selectedCells,
      addSelectedNodes,
      isNodeSelectionLocked,
      setIsNodeSelectionLocked,
    };
  }, [
    selectedNodes,
    selectedNodeIds,
    selectedCells,
    addSelectedNodes,
    isNodeSelectionLocked,
    setIsNodeSelectionLocked,
  ]);

  return memoizedValue;
};

export const [
  ReactFlowSelectedCellsProvider,
  useReactFlowSelectedCells,
  useReactFlowSelectedNodes,
  useReactFlowSelectedNodeIds,
  useReactFlowAddSelectedNodes,
  useReactFlowIsNodeSelectionLocked,
  useReactFlowSetIsNodeSelectionLocked,
] = constate(
  _useReactFlowSelectedCells,
  (value) => value.selectedCells,
  (value) => value.selectedNodes,
  (value) => value.selectedNodeIds,
  (value) => value.addSelectedNodes,
  (value) => value.isNodeSelectionLocked,
  (value) => value.setIsNodeSelectionLocked
);
