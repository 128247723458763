import { FieldClientModel } from "~/clientModel/fields/field";
import { Input } from "~/components_next/Input";

type FieldNameInputProps = {
  field: FieldClientModel;
  onFieldChange: (field: FieldClientModel) => void;
  isReadOnly?: boolean;
};

export const FieldNameInput = (props: FieldNameInputProps) => {
  const { field, onFieldChange, isReadOnly } = props;

  return (
    <Input
      label="Field Slug"
      variant="supplementary"
      value={field.name}
      onChange={(e) => onFieldChange(field.updateName(e.target.value))}
      readOnly={isReadOnly}
      errorMessage={field.validateName().errorMessages}
    />
  );
};
