import { ReactNode } from "react";
import { MorphThemeSize } from "../commonProps.type";
import { Tabs } from "./Tabs";

type SimpleTabsProps = {
  tabLabels: string[];
  children: ReactNode[];
  size?: MorphThemeSize;
  defaultValue?: string;
  onChange?: (value: string) => void;
};

const SimpleTabs = (props: SimpleTabsProps) => {
  const { tabLabels, children, size = "md", defaultValue, onChange } = props;

  const getTabValue = (index: number) => {
    if (index < tabLabels.length) {
      return tabLabels[index];
    }
    return "";
  };

  return (
    <Tabs.Root
      defaultValue={
        defaultValue || tabLabels.length > 0 ? tabLabels[0] : undefined
      }
      size={size}
      onChange={onChange}
    >
      <Tabs.List>
        {tabLabels.map((label) => (
          <Tabs.Trigger key={label} value={label}>
            {label}
          </Tabs.Trigger>
        ))}
      </Tabs.List>
      {children.map((content, index) => (
        <Tabs.Content key={index} value={getTabValue(index)}>
          {content}
        </Tabs.Content>
      ))}
    </Tabs.Root>
  );
};

export { SimpleTabs, type SimpleTabsProps };
