import { viewKeys } from "../keys";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { useQueryClient } from "react-query";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";
import useApiView from "~/api/useApiView";
import { SimpleFieldCategoryType } from "@usemorph/morph-dashboard-types";

type UseUpdateViewFieldMutationParams = {
  teamSlug: string;
  databaseId: string;
  viewId: string;
};

// 各fieldのdisplayNameおよび、create view fieldで作成したtype = formulaのみ更新可能
const useUpdateViewFieldMutation = ({
  teamSlug,
  databaseId,
  viewId,
}: UseUpdateViewFieldMutationParams) => {
  const { _updateViewField } = useApiView();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      name,
      displayName,
      formula,
      type,
    }: {
      name: string;
      displayName?: string;
      type: SimpleFieldCategoryType;
      formula: string;
    }) => {
      return _updateViewField({
        teamSlug,
        viewId,
        body: {
          name,
          displayName,
          formula,
          type,
        },
      });
    },
    onSuccess: () => {
      const keys = viewKeys.all({ databaseId, teamSlug });
      broadcast(keys);
      return invalidateAll(client, keys);
    },
  };
};

export { useUpdateViewFieldMutation };
