import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useCallback,
  useRef,
} from "react";
import { BsUpload } from "react-icons/bs";
import { UseExecutable } from "~/clientModel/executable";
import { AttachmentRecordEntryClientModel } from "~/clientModel/records/record/recordEntry";
import { Button } from "~/components_next/Button";
import { IconButton } from "~/components_next/IconButton";
import { ActionIcon } from "~/components_next/Icons";
import { InputStack } from "~/components_next/InputStack/InputStack";
import { Spacer } from "~/components_next/Spacer";
import {
  ClearValueButton,
  ClearValueButtonWrapper,
} from "../../atoms/ClearValueButton";
import { getRightButtonSize } from "../../atoms/convertButtonSize";

import { RecordValueInputCommonProps } from "../../type";
import { FileDownloadButton } from "./FileDownloadButton";
import { FileNameDisplay } from "./FileNameDisplay";

type AttachmentInputProps =
  RecordValueInputCommonProps<AttachmentRecordEntryClientModel> & {
    useUploadFileExecutable?: UseExecutable<
      void,
      { file: File },
      {
        data: string;
        url: string;
      },
      unknown
    >;
  };

const AttachmentInput = forwardRef(function AttachmentInput(
  props: AttachmentInputProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const {
    editingRecordEntry,
    onChange,
    field,
    size,
    isReadonly,
    useUploadFileExecutable,
  } = props;

  const uploadFileExecutable = useUploadFileExecutable?.();

  const validatedValue = editingRecordEntry.getValidatedValue(field);

  const value = validatedValue.isValid ? validatedValue.value : null;

  const errorMessages = validatedValue.errorMessages;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFilterOpen = useCallback(() => {
    fileInputRef.current && fileInputRef.current.click();
  }, []);

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file || !uploadFileExecutable || !onChange) {
      return;
    }

    const { data, url } = await uploadFileExecutable.execute({ file });

    onChange(editingRecordEntry.updateValue({ data, url }));
  };

  const handleFileClear = () => {
    onChange?.(editingRecordEntry.updateValue(null));
  };

  return (
    <>
      {value ? (
        <ClearValueButtonWrapper>
          <FileNameDisplay data={value.data} />
          <Spacer />
          <FileDownloadButton size={getRightButtonSize(size)} url={value.url} />
          <IconButton
            tooltip="Change"
            size={getRightButtonSize(size)}
            icon={<BsUpload />}
            onClick={handleFilterOpen}
            isLoading={uploadFileExecutable?.isExecuting}
            isDisabled={isReadonly}
          />
          <ClearValueButton
            isDisabled={!field.isNullable || isReadonly}
            onClear={handleFileClear}
            size={size}
          />
        </ClearValueButtonWrapper>
      ) : (
        <Button
          variant="tertiary"
          onClick={handleFilterOpen}
          isLoading={uploadFileExecutable?.isExecuting}
          isDisabled={isReadonly}
          size={size}
          style={{ width: "100%" }}
        >
          <ActionIcon type="add" />
          Upload
        </Button>
      )}
      <InputStack errorMessage={errorMessages}>
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleFileUpload}
          accept="*"
          style={{ visibility: "hidden", height: 0, width: 0 }}
        />
      </InputStack>
    </>
  );
});

export { AttachmentInput };
