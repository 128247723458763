import { Fragment, useCallback } from "react";
import { UploadFileProvider } from "~/components_next/RecordValue/RecordValueInput/Inputs/ImageAndAttachment/UploadFileContext";
import { useViewId } from "~/utilHooks/useViewId";
import { useUploadFile } from "../common/utils/useUploadFile";
import { KanbanComponent } from "./components/KanbanComponent/KanbanComponent";
import { KanbanViewSidebarContextProvider } from "./states/sidebar";
import { KanbanViewURLSyncStore } from "./states/KanbanViewUrlSyncStore/KanbanViewURLSyncStore";
import { EditRecordSidebar } from "./components/EditRecord/EditRecordSidebar";
import { CreateRecordSidebar } from "./components/CreateRecord/CreateRecordSidebar";
import { QueryConditionsToolbar } from "./components/QueryConditionsToolbar/QueryConditionsToolbar";
import { Box, Flex } from "@radix-ui/themes";

const KanbanViewComponent = () => {
  const viewId = useViewId();
  const { uploadFile } = useUploadFile();

  const handleUploadFile = useCallback(
    async (file: File) => {
      return await uploadFile(file, { viewId });
    },
    [uploadFile, viewId]
  );

  return (
    <UploadFileProvider value={{ onUploadFile: handleUploadFile }}>
      <Fragment key={viewId}>
        <KanbanViewSidebarContextProvider>
          <Flex
            style={{ height: "calc(100vh - 40px)" }}
            direction="row"
            position="relative"
          >
            <Flex
              style={{ flex: 1, overflow: "hidden" }}
              direction="column"
              px="3"
              py="3"
              gap="2"
            >
              <QueryConditionsToolbar />
              <Box style={{ flexGrow: 1, overflow: "auto", width: "100%" }}>
                <KanbanComponent />
              </Box>
              {/* <Flex gap={4} direction="row" align="center"></Flex> */}
            </Flex>
            {/* 右下のボタン類 */}
            {/* 条件に応じて可能なアクションのボタンを出し分ける */}
            <Flex
              style={{ position: "absolute", right: 32, bottom: 48 }}
              gap="3"
            ></Flex>

            {/* Sidebars */}
            <EditRecordSidebar />
            <CreateRecordSidebar />
          </Flex>
        </KanbanViewSidebarContextProvider>
      </Fragment>
    </UploadFileProvider>
  );
};

const KanbanView = () => {
  return (
    <KanbanViewURLSyncStore>
      <KanbanViewComponent />
    </KanbanViewURLSyncStore>
  );
};

export { KanbanView };
