import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";

export class LastEditedByFieldTypeClientModel extends FieldTypeClientModelBase {
  public get type(): "lastEditedBy" {
    return "lastEditedBy";
  }

  public get fieldTypeLabel(): "Last Edited By" {
    return "Last Edited By";
  }

  public get isAutoGenerated(): true {
    return true;
  }

  public get isEmbeddable(): false {
    return false;
  }

  public get _updatableFieldTypes() {
    return [];
  }

  public get canBeTargetOfSyncingByComputedField(): true {
    return true;
  }

  public validate(): ValidationResult {
    return { isValid: true };
  }
}
