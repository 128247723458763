import { simpleFieldsKeys } from "../keys";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";
import useApiFields from "~/api/useApiField";

type UseListComputedFieldOptionsForAggregationQueryParams = {
  teamSlug: string;
};

const useListComputedFieldOptionsForAggregationQuery = ({
  teamSlug,
}: UseListComputedFieldOptionsForAggregationQueryParams) => {
  const { _listComputedFieldOptionsForAggregation } = useApiFields();

  const client = useQueryClient();

  client.setQueryDefaults(
    simpleFieldsKeys.listComputedFieldOptionsForAggregation(),
    commonQueryOptions
  );

  return {
    // 常にキャッシュする
    queryKey: simpleFieldsKeys.listComputedFieldOptionsForAggregation(),
    queryFn: () => {
      return _listComputedFieldOptionsForAggregation({
        teamSlug,
      });
    },
  };
};

export { useListComputedFieldOptionsForAggregationQuery };
