import { useQueryClient } from "react-query";
import useApiDataApi from "~/api/useApiDataApi";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { dataApiKeys } from "../keys";

type UseDeleteDataApiMutationParams = {
  databaseId: string;
  teamSlug: string;
};

const useDeleteDataApiMutation = ({
  teamSlug,
  databaseId,
}: UseDeleteDataApiMutationParams) => {
  const { _deleteDataApi } = useApiDataApi();
  const client = useQueryClient();

  return {
    mutationFn: ({
      tableSlug,
      apiKey,
    }: {
      apiKey?: string | undefined;
      tableSlug?: string | undefined;
    }) => {
      return _deleteDataApi({
        teamSlug,
        databaseId,
        tableSlug,
        apiKey,
      });
    },
    onSuccess: () => {
      return invalidateAll(
        client,
        dataApiKeys.all({
          teamSlug,
          databaseId,
        })
      );
    },
  };
};

export { useDeleteDataApiMutation };
