import { SimpleField } from "@usemorph/morph-dashboard-types";
import { FieldClientModel, FieldClientModelFactory } from "./field";
import { FieldsClientModel } from "./FieldsClientModel";

export class FieldsClientModelFactory {
  public static createFromSimpleFields(
    simpleFields: SimpleField[]
  ): FieldsClientModel {
    // embeddingsとrow infoは除外
    const displayedSimpleFields = simpleFields.filter(
      ({ type }) => type !== "embeddings" && type !== "rowInfo"
    );
    return new FieldsClientModel({
      fields: displayedSimpleFields.flatMap((targetSimpleField) => {
        return FieldClientModelFactory.createFromSimpleField(
          targetSimpleField,
          {
            allSimpleFields: simpleFields,
          }
        );
      }),
    });
  }

  public static createFromFields(
    fields: FieldClientModel[]
  ): FieldsClientModel {
    return new FieldsClientModel({ fields });
  }
}
