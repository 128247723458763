import { useMemo } from "react";
import { useNotebookCollaboration } from "../../state/useNotebookCollaboration";

export const useCoworkersOnCell = (cellId: string) => {
  const { notebookCoworkerStates } = useNotebookCollaboration();

  const coworkersOnThisCell = useMemo(() => {
    return (
      notebookCoworkerStates?.filter((coworkerState) => {
        return coworkerState.selectingCellId?.includes(cellId);
      }) || []
    );
  }, [notebookCoworkerStates, cellId]);

  const coworkerCellColor = useMemo(() => {
    if (coworkersOnThisCell.length === 0) {
      return undefined;
    }
    return coworkersOnThisCell[0].colorScheme;
  }, [coworkersOnThisCell]);

  return {
    coworkersOnCell: coworkersOnThisCell,
    coworkerCellColor,
  };
};
