import { DashboardNotebookDetailResponse } from "@usemorph/morph-dashboard-types";
import { FindCanvasClientModel } from "./FindCanvasClientModel";

export const findCanvasClientModelService = {
  // factory methods
  fromNotebookDetailResponse: (
    data: DashboardNotebookDetailResponse
  ): FindCanvasClientModel => {
    return new FindCanvasClientModel(data);
  },
};
