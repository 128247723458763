type DatabaseRecordCountsDependencies = { teamSlug: string };
type FindDatabasRecordConteDependencies = {
  databaseId: string;
} & DatabaseRecordCountsDependencies;

const databaseRecordCountKeys = {
  all: ({ teamSlug }: DatabaseRecordCountsDependencies) =>
    [teamSlug, "databaseRecordCount"] as const,

  allFindDatabase: ({ teamSlug }: DatabaseRecordCountsDependencies) =>
    [
      ...databaseRecordCountKeys.all({ teamSlug }),
      "findDatabaseRecordCount",
    ] as const,
  findDatabase: ({
    teamSlug,
    databaseId,
  }: FindDatabasRecordConteDependencies) =>
    [
      ...databaseRecordCountKeys.allFindDatabase({ teamSlug }),
      databaseId,
    ] as const,
};

export { databaseRecordCountKeys };
