/* eslint-disable react/display-name */
import {
  DashboardTemplateAuthObject,
  IntegrationAuthConfig,
  IntegrationInputForm,
} from "@usemorph/morph-dashboard-types";
import { memo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Button } from "~/components_next/Button/Button";
import { useErrorToast } from "~/components_next/Error";
import {
  useCreateNewTemplateAuthMutation,
  useListTemplateAuthQuery,
  useVerifyTemplateAuthMutation,
} from "~/serverStateStore/templateAuth";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { IntegrationInputs } from "../Trigger/IntegrationInputs";
import { AuthChangeDropdown } from "./AuthChangeDropdown";
import { AuthSettingDropdown } from "./AuthSettingDropdown";
import { Card } from "@radix-ui/themes";
import { Flex } from "~/components_next/Flex";
import { Spinner } from "~/components_next/Spinner";
import { Image } from "~/components_next/Image";
import { Box } from "~/components_next/Box";
import { Text } from "~/components_next/Text";
import { Spacer } from "~/components_next/Spacer";

interface IntegrationAuthInput {
  authConfig: IntegrationAuthConfig;
  templateId: string;
  templateName: string;
  integrationId: string;
  logoUrl: string;
  authInputForm: IntegrationInputForm;
  loginButtonImage?: { light: string; dark: string };
  setRequestData: (value: { [key: string]: unknown }) => void;
  selectedTemplateAuth?: DashboardTemplateAuthObject;
  setSelectedTemplateAuth: (templateAuth?: DashboardTemplateAuthObject) => void;
  onContinue: () => void;
}

export const IntegrationAuthInput = memo((props: IntegrationAuthInput) => {
  const {
    authConfig,
    templateId,
    templateName,
    integrationId,
    logoUrl,
    authInputForm,
    loginButtonImage,
    selectedTemplateAuth,
    setSelectedTemplateAuth,
    setRequestData,
    onContinue,
  } = props;
  const teamSlug = useTeamSlug();
  const { errorToast } = useErrorToast({});

  // state
  const [requestAuthInfo, setRequestAuthInfo] = useState<{
    [key: string]: unknown;
  }>({});
  const [verifyError, setVerifyError] = useState<string>();

  // functions
  const { data: listTemplateAuth, isLoading } = useQuery({
    ...useListTemplateAuthQuery({
      templateId,
      limit: 99999,
      skip: 0,
      teamSlug,
    }),
    onSuccess: async (data) => {
      // 初期値を入れる
      if (data.items.length > 0 && !selectedTemplateAuth) {
        handleSelectTemplateAuth(data.items[0]);
      } else if (data.items.length === 0) {
        setSelectedTemplateAuth(undefined);
      }
    },
  });
  const { mutateAsync: createTemplateAuth, isLoading: isCreatingTemplateAuth } =
    useMutation({
      ...useCreateNewTemplateAuthMutation({ templateId, teamSlug }),
    });
  const { mutateAsync: verifyTemplateAuth } = useMutation({
    ...useVerifyTemplateAuthMutation({ templateId, teamSlug }),
  });

  const handleSelectTemplateAuth = (
    templateAuth: DashboardTemplateAuthObject
  ) => {
    verifyAuth(templateAuth.templateAuthId);
    setSelectedTemplateAuth(templateAuth);
    // onCreated(templateAuth.templateAuthId)
  };

  const handleClickContinue = () => {
    if (!!selectedTemplateAuth && !verifyError) {
      onContinue()
    } else {
      handleCreateTemplateAuth()
    }
  }

  const handleCreateTemplateAuth = async () => {
    try {
      const response = await createTemplateAuth({ authInfo: requestAuthInfo });
      setSelectedTemplateAuth(response);
      onContinue();
    } catch (error) {
      errorToast(error);
    }
  };

  const verifyAuth = async (templateAuthId: string) => {
    try {
      // setIsLoading(true)
      setVerifyError(undefined);
      const response = await verifyTemplateAuth({ templateAuthId });
      if (response.success) {
        setVerifyError(undefined);
        // setIsLoading(false)
        return true;
      }
    } catch (e) {
      setVerifyError("An authentication error has occurred.");
      // setIsLoading(false)
      return false;
    }
  };

  return (
    <>
      {/* {JSON.stringify(listTemplateAuth)} */}
      {/* {JSON.stringify(selectedTemplateAuth)} */}
      {listTemplateAuth && selectedTemplateAuth && (
        <Card>
          <Flex align="center">
            {isLoading ? (
              <Spinner width="28px" height="28px" />
            ) : (
              <Image
                src={logoUrl}
                width="28px"
                height="28px"
                style={{ objectFit: "contain" }}
              />
            )}
            <Box ml="4">
              <Box>
                <Text fontWeight="bold">{selectedTemplateAuth.title}</Text>
              </Box>
              <Box>
                <Text variant="description">{selectedTemplateAuth.label}</Text>
              </Box>
            </Box>
            <Spacer />
            {verifyError && (
              <Box css={{ color: "red" }} mr="2">
                <Text variant="default">{verifyError}</Text>
              </Box>
            )}
            <AuthChangeDropdown
              listTemplateAuth={listTemplateAuth.items}
              selectedTemplateAuth={selectedTemplateAuth}
              authConfig={authConfig}
              authorizeUrl={authConfig.authorizeUrl ?? ""}
              templateName={templateName}
              loginButtonImage={loginButtonImage}
              isOauthSignUp={false}
              isButtonDisabled={false}
              onSelect={handleSelectTemplateAuth}
            />
            <Box mr="5" />
            <AuthSettingDropdown
              selectedTemplateAuth={selectedTemplateAuth}
              setSelectedTemplateAuth={setSelectedTemplateAuth}
              templateId={templateId}
            />
            <Box mr="2" />
          </Flex>
          {/* {JSON.stringify(selectedAuth)} */}
        </Card>
      )}

      <Box m="4"></Box>
      <IntegrationInputs
        templateId={templateId}
        integrationId={integrationId}
        inputForm={authInputForm}
        requestData={requestAuthInfo}
        setRequestData={(el) => {
          setRequestAuthInfo(el);
          setRequestData(el);
        }}
      />
      <Box mb="3">
        <Button
          onClick={handleClickContinue}
          variant="primary"
          size="sm"
          type="button"
          isLoading={isCreatingTemplateAuth}
        >
          Continue
        </Button>
      </Box>
    </>
  );
});
