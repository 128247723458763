import { useMemo } from "react";
import { ComputedFieldsScheduledSyncSettingDrawer } from "~/presenters/table";
import { createUseCreateSyncScheduleExecutable } from "../hooks/createUseCreateSyncScheduleExecutable";
import { createUseUpdateSyncScheduleExecutable } from "../hooks/createUseUpdateSyncScheduleExecutable";
import { useComputedFieldSyncScheduleLoadable } from "../hooks/useComputedFieldSyncScheduleLoadable";

type SyncScheduleSettingDrawerContainerProps = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export const SyncScheduleSettingDrawerContainer = (
  props: SyncScheduleSettingDrawerContainerProps
) => {
  const { teamSlug, databaseId, tableSlug, isOpen, onOpenChange } = props;

  const computedFieldSyncScheduleLoadable =
    useComputedFieldSyncScheduleLoadable({
      teamSlug,
      databaseId,
      tableSlug,
    });

  const useCreateSyncScheduleExecutable = useMemo(
    () =>
      createUseCreateSyncScheduleExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const useUpdateSyncScheduleExecutable = useMemo(
    () =>
      createUseUpdateSyncScheduleExecutable({
        teamSlug,
        databaseId,
        tableSlug,
      }),
    [teamSlug, databaseId, tableSlug]
  );

  const createSyncScheduleExecutable = useCreateSyncScheduleExecutable();
  const updateSyncScheduleExecutable = useUpdateSyncScheduleExecutable();

  return (
    <ComputedFieldsScheduledSyncSettingDrawer
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      computedFieldSyncScheduleLoadable={computedFieldSyncScheduleLoadable}
      createSyncScheduleExecutable={createSyncScheduleExecutable}
      updateSyncScheduleExecutable={updateSyncScheduleExecutable}
    />
  );
};
