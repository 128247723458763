import { FieldClientModel } from "~/clientModel/fields/field";
import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { StringValidator } from "./CommonValueValidator";
import {
  FilterOperationClientModelBase,
  FilterOperationWithValueInterface,
} from "./FilterOperationClientModelBase";

type EqualFilterOperationClientModelData = {
  value: string;
};

export class EqualFilterOperationClientModel
  extends FilterOperationClientModelBase
  implements
    FilterOperationWithValueInterface<string, EqualFilterOperationClientModel>
{
  readonly #data: EqualFilterOperationClientModelData;

  public constructor(data: EqualFilterOperationClientModelData) {
    super();
    this.#data = data;
  }

  public get data() {
    return this.#data;
  }

  public get type(): "equal" {
    return "equal";
  }

  public get operatorValue(): "equal" {
    return "equal";
  }

  public get operatorLabel(): "Equals" {
    return "Equals";
  }

  public get operandValue(): string {
    return this.#data.value;
  }

  public updateValue(value: string): EqualFilterOperationClientModel {
    return new EqualFilterOperationClientModel({
      ...this.#data,
      value,
    });
  }

  public validate(targetField: FieldClientModel): ValidationResult {
    return new StringValidator(this.#data.value)
      .shouldNotBeEmpty()
      .shouldBeIntStringIfNumberType(targetField)
      .end();
  }
}
