import { UseExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { Loadable } from "~/clientModel/loadable";
import { WithFallback } from "~/clientModel/loadable/WithFallback";
import { Box } from "~/components_next/Box";
import { Button } from "~/components_next/Button";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { ErrorFallback } from "~/components_next/Error";
import { Flex } from "~/components_next/Flex";
import { Spinner } from "~/components_next/Spinner";
import { FieldSettingForm } from "./FieldSettingForm";

type FieldsSettingDropdownProps = {
  fieldsLoadable: Loadable<FieldsClientModel>;
  useUpdateFieldVisibilityExecutable: UseExecutable<
    void,
    { field: FieldClientModel }
  >;
  useUpdateFieldsOrderExecutable: UseExecutable<
    void,
    { fields: FieldsClientModel }
  >;
  getFieldLabel: (field: FieldClientModel) => string;
};

export const FieldsSettingDropdown = (props: FieldsSettingDropdownProps) => {
  const {
    fieldsLoadable,
    useUpdateFieldVisibilityExecutable,
    useUpdateFieldsOrderExecutable,
    getFieldLabel,
  } = props;

  return (
    <SimpleDropdownMenu
      trigger={
        <Button variant="actionText" size="xs">
          Fields
        </Button>
      }
    >
      <Box css={{ minWidth: 300, overflow: "auto" }}>
        <WithFallback
          loadables={[fieldsLoadable] as const}
          loadingFallback={<LoadingFallback />}
          errorFallback={(error) => <ErrorFallback error={error} />}
        >
          {([fields]) => (
            <FieldSettingForm
              fields={fields}
              useUpdateFieldVisibilityExecutable={
                useUpdateFieldVisibilityExecutable
              }
              useUpdateFieldsOrderExecutable={useUpdateFieldsOrderExecutable}
              getFieldLabel={getFieldLabel}
            />
          )}
        </WithFallback>
      </Box>
    </SimpleDropdownMenu>
  );
};

const LoadingFallback = () => {
  return (
    <Flex align="center" justify="center" height="9">
      <Spinner size="md" />
    </Flex>
  );
};
