import { useMutation } from "react-query";
import { useComposeExecutable } from "~/clientModel/executable";

import { useRemoveNotebookCellMetaMutaiton } from "~/serverStateStore/notebook/mutations/useRemoveNotebookCellMutation";

const useDeleteCellExecutable = (props: {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
}) => {
  const { teamSlug, databaseId, notebookId } = props;

  const deleteCellMutationOptions = useRemoveNotebookCellMetaMutaiton({
    teamSlug,
    notebookId,
    databaseId,
  });

  return useComposeExecutable(
    useMutation({
      ...deleteCellMutationOptions,
    })
  );
};

export const createUseDeleteCellExecutable = (props: {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
}) => {
  const { teamSlug, databaseId, notebookId } = props;

  return () => useDeleteCellExecutable({ teamSlug, databaseId, notebookId });
};
