import { SmartFieldFunctionListResponse } from "@usemorph/morph-dashboard-types";
import { SmartFunctionClientModelFactory } from "./smartFunction/SmartFunctionClientModelFactory";
import { SmartFunctionsClientModel } from "./SmartFunctionsClientModel";

export class SmartFunctionsClientModelFactory {
  public static createFromSmartFieldFunctionListResponse(
    smartFieldFunctions: SmartFieldFunctionListResponse
  ): SmartFunctionsClientModel {
    return new SmartFunctionsClientModel({
      smartFunctions: smartFieldFunctions.items.map((item) =>
        SmartFunctionClientModelFactory.createFromSmartFieldFunctionObject(item)
      ),
    });
  }
}
