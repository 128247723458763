import { Drawer } from "~/components_next/Drawer";
import { Loadable } from "~/clientModel/loadable";
import { PromptHistoriesClientModel } from "~/clientModel/promptHistories";
import { PromptHistories } from "./PromptHistories";
import { PromptHistoryClientModel } from "~/clientModel/promptHistories/promptHistory";

type PromptHistoriesDrawerProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  onHistoryClick: (history: PromptHistoryClientModel) => void;
  promptHistoriesLoadable: Loadable<PromptHistoriesClientModel>;
};
const PromptHistoriesDrawer = (props: PromptHistoriesDrawerProps) => {
  const { promptHistoriesLoadable, isOpen, onOpenChange, onHistoryClick } =
    props;

  return (
    <Drawer.Root size="xl" open={isOpen} onOpenChange={onOpenChange}>
      <Drawer.Content>
        <Drawer.Title>Prompt History</Drawer.Title>
        <Drawer.Body>
          <PromptHistories
            promptHistoriesLoadable={promptHistoriesLoadable}
            onHistoryClick={onHistoryClick}
          />
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  );
};

export { PromptHistoriesDrawer };
