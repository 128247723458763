import { Flex } from "@radix-ui/themes";
import { Text } from "~/components_next/Text";
import { BsArrowRight, BsGear } from "react-icons/bs";
import { IconButton } from "~/components_next/IconButton";
import { SimpleDropdownMenu } from "~/components_next/DropdownMenu";
import { FieldsClientModel } from "~/clientModel/fields";
import { EmbeddingSortConditionUnitClientModel } from "~/clientModel/queryConditions/embeddingSortConditionUnit";
import { useMemo } from "react";
import { EmbeddingFieldsSetting } from "./embeddingFieldSetting/EmbeddingFieldsSetting";
import { UseExecutable } from "~/clientModel/executable";
import { FieldClientModel } from "~/clientModel/fields/field";
import { EmbeddingSortConditionInput } from "./EmbeddingSortConditionInput";
import { Box } from "~/components_next/Box";

type EmbeddingSortConditionFormProps = {
  fields: FieldsClientModel;
  embeddingSortCondition: EmbeddingSortConditionUnitClientModel | null;
  isReadOnly?: boolean;
  onEmbeddingSortConditionChange: (
    condition: EmbeddingSortConditionUnitClientModel | null
  ) => void;
  useActivateEmbeddingExecutable: UseExecutable<
    void,
    { targetField: FieldClientModel },
    unknown,
    unknown
  >;
  inputDebounceDelay?: number;
};

const EmbeddingSortConditionForm = (props: EmbeddingSortConditionFormProps) => {
  const {
    fields,
    embeddingSortCondition,
    onEmbeddingSortConditionChange,
    useActivateEmbeddingExecutable,
    isReadOnly,
    inputDebounceDelay,
  } = props;

  const embeddedFields = useMemo(() => fields.embeddedFields, [fields]);

  return (
    <Box>
      {/* embeddedFieldsがない場合 */}
      {embeddedFields.count === 0 ? (
        <Flex align="center" gap="3">
          <Text>
            Please activate and select the fields to be targeted for similarity
            search.
          </Text>
          {!isReadOnly && (
            <>
              <BsArrowRight />
              <SimpleDropdownMenu
                trigger={<IconButton tooltip="Setting" icon={<BsGear />} />}
                size="sm"
              >
                <EmbeddingFieldsSetting
                  fields={fields}
                  useActivateEmbeddingExecutable={
                    useActivateEmbeddingExecutable
                  }
                />
              </SimpleDropdownMenu>
            </>
          )}
        </Flex>
      ) : (
        <EmbeddingSortConditionInput
          fields={fields}
          embeddingSortCondition={embeddingSortCondition}
          onEmbeddingSortConditionChange={onEmbeddingSortConditionChange}
          isReadOnly={isReadOnly}
          useActivateEmbeddingExecutable={useActivateEmbeddingExecutable}
          debounceDelay={inputDebounceDelay}
        />
      )}
    </Box>
  );
};

export { EmbeddingSortConditionForm };
