import { SimpleField } from "@usemorph/morph-dashboard-types";
import { SimpleSelect, SimpleSelectProps } from "~/components_next/Select";

type FieldSelectProps = {
  fields: SimpleField[];
  value: SimpleField | null;
  onChange: (value: SimpleField) => void;
} & Omit<
  SimpleSelectProps<SimpleField>,
  "value" | "onChange" | "options" | "variant" | "getValueStringFromValue"
>;

const FieldSelect = (props: FieldSelectProps) => {
  const { fields, value, onChange, ...restProps } = props;

  const options = fields.map((field) => ({
    value: field,
    label: field.displayName || field.name,
  }));

  return (
    <SimpleSelect
      variant="primary"
      value={value}
      options={options}
      onChange={(value) => onChange(value as SimpleField)}
      getValueStringFromValue={(value) => value.name}
      {...restProps}
    />
  );
};

export { FieldSelect };
