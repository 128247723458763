import { getPath } from "~/routing";
import { LinkItemText } from "./common/LinkItemText";
import { LinkItemWrapper } from "./common/LinkItemWrapper";
import { RssTableMappingClientModel } from "~/clientModel/rssFeed";
import { MdOutlineRssFeed } from "react-icons/md";

type SidebarRssLinkItemProps = {
  rss: RssTableMappingClientModel
  teamSlug: string;
  tableSlug: string;
  databaseId: string;
};

const SidebarRssLinkItem = (props: SidebarRssLinkItemProps) => {
  const { rss, teamSlug, databaseId, tableSlug } = props;

  const to = getPath("dataCollection", {
    teamSlug,
    databaseId,
    tableSlug,
  });

  return (
    <LinkItemWrapper to={to} px="1" gap="1">
      <MdOutlineRssFeed color="gray" />
      <LinkItemText>RSS Feed Collection</LinkItemText>
    </LinkItemWrapper>
  );
};

export { SidebarRssLinkItem };
