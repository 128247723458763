import { Flex } from "@radix-ui/themes";
import { MouseEventHandler } from "react";
import { Button } from "~/components_next/Button";

type DeleteAndSaveSidebarFooterProps = {
  onClickSaveRecordButton?: MouseEventHandler<HTMLButtonElement>;
  onClickDeleteRecordButton?: MouseEventHandler<HTMLButtonElement>;
  isSaving?: boolean;
  isDeleting?: boolean;
  isSaveDisabled?: boolean;
  canDelete?: boolean;
  canUpdate?: boolean;
};

const DeleteAndSaveSidebarFooter = (props: DeleteAndSaveSidebarFooterProps) => {
  const {
    onClickSaveRecordButton,
    isSaving,
    isDeleting,
    isSaveDisabled,
    onClickDeleteRecordButton,
    canDelete,
    canUpdate,
  } = props;

  return (
    <Flex direction="row" align="center" justify="between" gap="6">
      {canDelete !== false && (
        <Button
          variant="secondary"
          colorSchemeOverride="red"
          isLoading={isDeleting}
          onClick={onClickDeleteRecordButton}
          style={{ width: "100%" }}
          size="sm"
        >
          Delete
        </Button>
      )}

      {canUpdate !== false && (
        <Button
          isLoading={isSaving}
          isDisabled={isSaveDisabled}
          variant="primary"
          onClick={onClickSaveRecordButton}
          style={{ width: "100%" }}
          size="sm"
        >
          Save
        </Button>
      )}
    </Flex>
  );
};

export { DeleteAndSaveSidebarFooter };
