import constate from "constate";
import { useCallback } from "react";
import { CanvasCellClientModelUnion } from "~/clientModel/canvas";
import {
  CanvasCellSourceClientModel,
  CanvasCellViewClientModel,
} from "~/clientModel/canvas/CanvasCellClientModel";
import { CanvasVariablesValue } from "../../common/CanvasVariablesProvider";

export type PlaygroundAncestorUtilContextProps = {
  cells: CanvasCellClientModelUnion[];
};

const usePlaygroundAncestorUtilContext = (
  props: PlaygroundAncestorUtilContextProps
) => {
  const { cells } = props;

  const getAncestorViewOrSourceCells = useCallback(
    (
      cell: CanvasCellClientModelUnion
    ): (CanvasCellSourceClientModel | CanvasCellViewClientModel)[] => {
      const parentCells = cell.parentIds
        .map((parentId) => {
          return cells.find((cell) => cell.cellId === parentId);
        })
        .filter((cell): cell is CanvasCellClientModelUnion => {
          return !!cell;
        });

      const ancestorViewOrSourceCells = parentCells.filter(
        (
          cell
        ): cell is CanvasCellViewClientModel | CanvasCellSourceClientModel => {
          return (
            cell instanceof CanvasCellViewClientModel ||
            cell instanceof CanvasCellSourceClientModel
          );
        }
      );

      if (ancestorViewOrSourceCells.length > 0) {
        return ancestorViewOrSourceCells;
      }

      return parentCells.flatMap((cell) => {
        return getAncestorViewOrSourceCells(cell);
      });
    },
    [cells]
  );

  const getAncestorVariableValues = useCallback(
    (
      cell: CanvasCellClientModelUnion,
      variableValues: CanvasVariablesValue[]
    ): CanvasVariablesValue[] => {
      const parentCells = cell.parentIds
        .map((parentId) => {
          return cells.find((cell) => cell.cellId === parentId);
        })
        .filter((cell): cell is CanvasCellClientModelUnion => {
          return !!cell;
        });

      const parentVariableValues = variableValues.filter((variableValue) => {
        return parentCells.some((cell) => {
          return cell.cellId === variableValue.cellId;
        });
      });

      const ancestorVariablevalues = parentCells.flatMap((cell) => {
        return getAncestorVariableValues(cell, variableValues);
      });

      return [...parentVariableValues, ...ancestorVariablevalues];
    },
    [cells]
  );

  return {
    getAncestorViewOrSourceCells,
    getAncestorVariableValues,
  };
};

export const [
  PlaygroundAncestorUtilProvider,
  useGetAncestorVeiwOrSourceCells,
  useGetAncestorVariableValues,
] = constate(
  usePlaygroundAncestorUtilContext,
  (value) => value.getAncestorViewOrSourceCells,
  (value) => value.getAncestorVariableValues
);
