import {
  RecordFilterConditionAnd,
  RecordFilterConditionOr,
} from "@usemorph/morph-dashboard-types";
import { FilterConditionUnitClientModelDefactory } from "./FilterConditionUnit/FilterConditionUnitClientModelDefactory";
import { FilterConditionsClientModel } from "./FilterConditionsClientModel";

export class FilterConditionsClientModelDefactory {
  public static toRecordFilterCondition(
    filterConditions: FilterConditionsClientModel
  ): RecordFilterConditionAnd | RecordFilterConditionOr {
    const operator: "and" | "or" = filterConditions.data.logicalOperator;

    // ネストしたフィルター条件を再帰的に変換する
    const filterConditionUnits = filterConditions.data.conditionItems.flatMap(
      (filterCondition) => {
        if (filterCondition instanceof FilterConditionsClientModel) {
          return FilterConditionsClientModelDefactory.toRecordFilterCondition(
            filterCondition
          );
        }

        // 無効なフィルター条件はリクエストから抜く

        if (!filterCondition.validate().isValid) {
          return [];
        }

        return FilterConditionUnitClientModelDefactory.toRecordFilterConditionUnit(
          filterCondition
        );
      }
    );

    if (operator === "and") {
      return { and: filterConditionUnits };
    } else {
      return { or: filterConditionUnits };
    }
  }
}
