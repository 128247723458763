import { useEffect } from "react";
import { useViewId } from "~/utilHooks/useViewId";
import { useEditingRecord } from "../../states/editingRecord";
import {
  useSetOpeningSidebarType,
  useSidebarDisclosure,
} from "../../states/sidebar";

const useCloseEditRecordSidebar = () => {
  const viewId = useViewId();
  const editingRecord = useEditingRecord(viewId);

  const { isOpen } = useSidebarDisclosure("editRecord");
  const setOpeningSidebarType = useSetOpeningSidebarType();
  useEffect(() => {
    if (isOpen && !editingRecord) {
      setOpeningSidebarType(null);
    }
  }, [isOpen, editingRecord, setOpeningSidebarType]);
};

export { useCloseEditRecordSidebar };
