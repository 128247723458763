import { useMemo } from "react";
import { CsvImportSchemaClientModel } from "~/clientModel/csvImport";
import { Executable } from "~/clientModel/executable";
import { StorageObjectClientModel } from "~/clientModel/storage/object";
import { Dialog } from "~/components_next/Dialog";
import { CsvImportFileUploadDialogContent } from "./dialogContent/CsvImportFileUploadDialogContent";
import { CsvImportSchemaEditorDialogContent } from "./dialogContent/CsvImportSchemaEditorDialog";
import { CsvImportSheetSelectDialogContent } from "./dialogContent/CsvImportSheetSelectDialogContent";
import { CsvImportSchemaEditClientModel } from "~/clientModel/csvImport/CsvImportSchemaEditClientModel";
import { TableNameClientModel } from "~/clientModel/tables/tableName";

type CsvImportPresenterProps = {
  isOpen: boolean;
  onIsOpenChange: (isOpen: boolean) => void;
  createObjectExecutable: Executable<
    { key: string; contentType: string; file: File },
    StorageObjectClientModel
  >;
  createImportSchemaExecutable: Executable<
    {
      sheet?: number | undefined;
    },
    CsvImportSchemaClientModel,
    unknown
  >;
  checkTableSlugExistExecutable: Executable<string, { isAvailable: boolean }>;
  updateImportSchemaExecutable: Executable<
    {
      csvImportSchemaInstance: CsvImportSchemaEditClientModel;
      tableNameInstance: TableNameClientModel;
    },
    CsvImportSchemaClientModel,
    unknown
  >;
  runImportExecutable: Executable<
    { importDataSchemaId: string },
    { message: string },
    unknown
  >;
};

const CsvImportDialogPresenter = (props: CsvImportPresenterProps) => {
  const {
    isOpen,
    onIsOpenChange,
    createObjectExecutable,
    createImportSchemaExecutable,
    checkTableSlugExistExecutable,
    updateImportSchemaExecutable,
    runImportExecutable,
  } = props;

  /**
   * UI state
   */
  const step = useMemo(() => {
    if (createImportSchemaExecutable.data) {
      return "editSchema";
    }
    if (
      createObjectExecutable.data &&
      createObjectExecutable.data?.shouldSelectSheet
    ) {
      return "selectSheet";
    }
    return "upload";
  }, [createObjectExecutable.data, createImportSchemaExecutable.data]);

  return (
    <>
      <Dialog.Root open={isOpen} onOpenChange={onIsOpenChange}>
        <Dialog.Content>
          {/* アップロード前 */}
          {step === "upload" && (
            <CsvImportFileUploadDialogContent
              createObjectExecutable={createObjectExecutable}
              createImportSchemaExecutable={createImportSchemaExecutable}
            />
          )}

          {/* アップロード後 */}
          {/* シート選択 */}
          {step === "selectSheet" && (
            <CsvImportSheetSelectDialogContent
              sheets={createObjectExecutable.data!.metaData?.sheets || []}
              createImportSchemaExecutable={createImportSchemaExecutable}
            />
          )}

          {/* スキーマ編集 */}
          {step === "editSchema" && (
            <CsvImportSchemaEditorDialogContent
              importSchema={createImportSchemaExecutable.data!}
              onClose={() => onIsOpenChange(false)}
              checkTableSlugExecutable={checkTableSlugExistExecutable}
              updateImportSchemaExecutable={updateImportSchemaExecutable}
              runImportExecutable={runImportExecutable}
            />
          )}
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};

export { CsvImportDialogPresenter };
