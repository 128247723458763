import { CanvasCellVariablesClientModel } from "~/clientModel/canvas/CanvasCellClientModel";
import { Badge } from "~/components_next/Badge";
import { Flex } from "~/components_next/Flex";
import { Grid } from "~/components_next/Grid";
import { Spacer } from "~/components_next/Spacer";
import { CanvasCellVariableRow } from "./CanvasCellVariableRow";
import { UseVariableOptionsViewResultLoadable } from "../../common/CanvasVariableOptionsLoadableProvider";

type CanvasCellVariablesProps = {
  variableCellClientModel: CanvasCellVariablesClientModel;
  useVariableOptionsLoadable: UseVariableOptionsViewResultLoadable;
};

const CanvasCellVariables = (props: CanvasCellVariablesProps) => {
  const { variableCellClientModel, useVariableOptionsLoadable } = props;

  return (
    <Flex direction="column" gap="4">
      <Flex>
        <Spacer />
        <Badge variant="secondary">Variables</Badge>
      </Flex>
      {variableCellClientModel.variables.data.map((variable, index) => {
        return (
          <Grid key={index} columns="2" align="center">
            <CanvasCellVariableRow
              variable={variable}
              variableCellClientModel={variableCellClientModel}
              useVariableOptionsLoadable={useVariableOptionsLoadable}
            />
          </Grid>
        );
      })}
    </Flex>
  );
};

export { CanvasCellVariables };
