import { Checkbox } from "~/components_next/Checkbox";

type EnabledInputProps = {
  value: boolean;
  onChange: (value: boolean) => void;
};

export const EnabledInput = (props: EnabledInputProps) => {
  const { value, onChange } = props;

  return (
    <Checkbox size="md" label="Enabled" value={value} onChange={onChange} />
  );
};
