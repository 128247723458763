import { useState } from "react";
import { Link } from "react-router-dom";
import { match } from "ts-pattern";
import { getPath } from "~/routing";
import { useDisclosure } from "~/hooks/useDisclosure";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";

import { ScrollArea, Separator } from "@radix-ui/themes";
import { Spacer } from "~/components_next/Spacer";
import { IconButton } from "~/components_next/IconButton";
import { Text } from "~/components_next/Text";

import { AiOutlineMenu } from "react-icons/ai";
import { BsChevronDoubleLeft } from "react-icons/bs";
import { LuUser, LuUsers } from "react-icons/lu";

import { TeamSelect } from "./components/TeamSelect/TeamSelect";
import { TableSelect } from "./components/TableSelect/TableSelect";
import { TableAddPopover } from "./components/TableSelect/TableAddPopover";
import { Favorites } from "./components/Favorites/Favorites";
import { ViewSelect } from "./components/ViewSelect/ViewSelect";

import { CreateTableModal } from "../CreateTable";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
import { useImportOtherServiceModal } from "../ImportOtherService/useImportOtherServiceModal";
import { NotebookAddOption } from "./components/NotebookSelect/NotebookAddOption";
import {
  useGlobalNavigationUIState,
  useSetGlobalNavigationUIState,
} from "./states/globalNavigationUIState";
import { DatabaseContainer } from "~/containers/database/DatabaseContainer";
import { CsvImportContainer } from "~/containers/csvImport/CsvImportContainer";
import { IntegrationDialogContainer } from "~/containers/integration/IntegrationDialogContainer";
import { NotificationContainer } from "~/containers/notification/NotificationContainer";
import { LeftBottomToastContainer } from "~/containers/leftBottomToast/LeftBottomToastContainer";
import { useMeasure } from "react-use";
import { LinkItemContextProvider } from "./components/LinkItems/common/LinkItemContext";

interface NavigationProps {
  onToggleIsLock: () => void;
}

const GlobalNavigation = () => {
  const globalNavigationUIState = useGlobalNavigationUIState();
  const setGlobalNavigationUIState = useSetGlobalNavigationUIState();

  return (
    <Box>
      {!globalNavigationUIState.isLockSidebar && (
        <Box>
          {!globalNavigationUIState.isLockSidebar && (
            <>
              <Flex
                align="center"
                justify="center"
                css={{
                  height: "40px",
                  borderBottomWidth: 1,
                  position: "absolute",
                }}
                px="3"
              >
                <IconButton
                  onClick={() =>
                    setGlobalNavigationUIState({
                      ...globalNavigationUIState,
                      isLockSidebar: true,
                    })
                  }
                  icon={<AiOutlineMenu />}
                  tooltip="Lock sidebar open"
                  size="sm"
                />
              </Flex>
            </>
          )}
        </Box>
      )}

      {globalNavigationUIState.isLockSidebar && (
        <Navigation
          onToggleIsLock={() =>
            setGlobalNavigationUIState({
              ...globalNavigationUIState,
              isLockSidebar: false,
            })
          }
        />
      )}
    </Box>
  );
};

const Navigation = (props: NavigationProps) => {
  const { onToggleIsLock } = props;

  /**
   * Home
   */
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const homeLink = getPath("databaseHome", { teamSlug, databaseId });

  /**
   * Modal
   */
  const [isCreatingPrivate, setIsCreatingPrivate] = useState(false);

  const createBlackableModalState = useDisclosure();
  const createImportTableModalState = useDisclosure();
  const {
    onOpen: onCreateOtherServiceTableModalOpen,
    setIsPrivate: onCreateOtherServiceTableModalSetIsPrivate,
  } = useImportOtherServiceModal();

  const _onClickAddTable = (
    type: "blank" | "csv" | "otherService",
    isPrivate: boolean
  ) => {
    setIsCreatingPrivate(isPrivate);
    match(type)
      .with("blank", () => {
        createBlackableModalState.onOpen();
      })
      .with("csv", () => {
        createImportTableModalState.onOpen();
      })
      .with("otherService", () => {
        onCreateOtherServiceTableModalSetIsPrivate(isPrivate);
        onCreateOtherServiceTableModalOpen({
          otherService: null,
          integrationId: null,
        });
      })
      .run();
  };

  const [scrollContentRef, { height: scrollContentHeight }] =
    useMeasure<HTMLDivElement>();

  return (
    <LinkItemContextProvider
      value={{ recalculateCoworkerIconsPositionKey: scrollContentHeight }}
    >
      <Flex
        direction={"column"}
        gap="3"
        css={{
          width: "240px",
          backgroundColor: "$slate2",
          borderRightWidth: 1,
        }}
      >
        <Flex
          css={{
            height: "40px",
            borderBottomWidth: 1,
          }}
          justify="between"
          align="center"
        >
          <TeamSelect />
          <Flex align="center" mr="2">
            <IconButton
              onClick={onToggleIsLock}
              icon={<BsChevronDoubleLeft color="gray" />}
              tooltip="Close sidebar"
              size="sm"
            />
          </Flex>
        </Flex>
        <ScrollArea
          style={{
            height: innerHeight - 40,
            width: 240,
            paddingBottom: 10,
          }}
          scrollbars="vertical"
        >
          <Flex
            ref={scrollContentRef}
            direction="column"
            gap="3"
            css={{
              width: "240px",
            }}
          >
            <NotificationContainer />
            <DatabaseContainer />

            <Box>
              <Link to={homeLink}>
                <Box
                  hoverable
                  css={{
                    "&:hover": {
                      backgroundColor: "$bg2",
                    },
                    "&:active": {
                      backgroundColor: "$bg3",
                    },
                  }}
                  px="2"
                >
                  <Flex
                    align="center"
                    justify="between"
                    css={{ height: "30px" }}
                  >
                    <Text variant="subheading">Home</Text>
                  </Flex>
                </Box>
              </Link>

              <Box
                hoverable
                css={{
                  "&:hover": {
                    backgroundColor: "$bg2",
                  },
                  "&:active": {
                    backgroundColor: "$bg3",
                  },
                }}
                px="2"
              >
                <Flex align="center" justify="between" css={{ height: "30px" }}>
                  <Text variant="subheading">Favorites</Text>
                </Flex>
                <Favorites />
              </Box>
            </Box>

            <Separator size="4" />

            <Box px="2">
              <Flex align="center" mb="1" gap="1">
                <LuUsers color="gray" />
                <Text variant="subheading">Team Space</Text>
              </Flex>

              <Flex align="center" justify="between" mb="1" pr="2">
                <Text variant="subheading">Sources</Text>
                <Spacer />
                <TableAddPopover
                  isPrivate={false}
                  onClickAddTable={_onClickAddTable}
                />
              </Flex>
              <TableSelect isPrivate={false} />

              <Flex align="center" mt="3" mb="1" pr="2">
                <Text variant="subheading">Views & Canvases</Text>
                <Spacer />
                <NotebookAddOption />
              </Flex>
              <ViewSelect isPrivate={false} />
              {/* <NotebookSelect isPrivate={false} /> */}
            </Box>

            <Separator size="4" />

            <Box px="2">
              <Flex align="center" mb="1" gap="1">
                <LuUser color="gray" />
                <Text variant="subheading">Private Space</Text>
              </Flex>

              <Flex align="center" justify="between" mb="1" pr="2">
                <Text variant="subheading">Sources</Text>
                <TableAddPopover
                  isPrivate={true}
                  onClickAddTable={_onClickAddTable}
                />
              </Flex>
              <TableSelect isPrivate={true} />

              <Flex align="center" justify="between" mt="3" mb="1" pr="2">
                <Text variant="subheading">Views & Canvases</Text>
                <Spacer />
                <NotebookAddOption />
              </Flex>
              <ViewSelect isPrivate={true} />
              {/* <NotebookSelect isPrivate={true} /> */}
            </Box>
          </Flex>
          <LeftBottomToastContainer />
          {/* <ImportAndPlanStatus /> */}
        </ScrollArea>
      </Flex>

      {/* Modal */}
      {/* <FileUploadModal
        type="create"
        isOpen={createImportTableModalState.isOpen}
        onOpenChange={createImportTableModalState.setIsOpen}
        onClose={createImportTableModalState.onClose}
        tableSlug={undefined}
      /> */}
      <CsvImportContainer
        isOpen={createImportTableModalState.isOpen}
        onIsOpenChange={createImportTableModalState.setIsOpen}
      />
      <CreateTableModal
        isOpen={createBlackableModalState.isOpen}
        onOpenChange={createBlackableModalState.setIsOpen}
        onClose={createBlackableModalState.onClose}
        isPrivate={isCreatingPrivate}
      />
      <IntegrationDialogContainer />
    </LinkItemContextProvider>
  );
};

export { GlobalNavigation, type NavigationProps };
