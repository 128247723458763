import useApiRecord from "~/api/useApiRecord";
import { recordsKeys } from "../keys";

// types
import {
  RecordConditionRuleUnit,
  RecordEmbeddingSortConditionUnit,
  RecordFilterCondition,
  RecordFormatCondition,
  RecordSortConditionUnit,
} from "@usemorph/morph-dashboard-types";
import { useQueryClient } from "react-query";
import { commonQueryOptions } from "../../util/commonQueryOptions";

interface UseListRecordParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
  select: string[];
  sort?: (RecordSortConditionUnit | RecordEmbeddingSortConditionUnit)[];
  filter?: RecordFilterCondition;
  additionalSort?: RecordSortConditionUnit[];
  addtionalFilter?: RecordFilterCondition;
  format?: RecordFormatCondition[];
  join?: RecordConditionRuleUnit[];
  limit?: number;
  skip?: number;
}

const useListRecordsWithQueryQuery = ({
  databaseId,
  tableSlug,
  teamSlug,
  select,
  filter,
  sort,
  additionalSort,
  addtionalFilter,
  format,
  join,
  limit,
  skip,
}: UseListRecordParams) => {
  const { _getRecords } = useApiRecord();

  const client = useQueryClient();
  client.setQueryDefaults(
    recordsKeys.all({ databaseId, tableSlug, teamSlug }),
    commonQueryOptions
  );

  return {
    queryKey: recordsKeys.getRecord({
      databaseId,
      tableSlug,
      teamSlug,
      select,
      filter,
      sort,
      additionalSort,
      addtionalFilter,
      format,
      join,
      limit,
      skip,
    }),
    queryFn: () => {
      return _getRecords({
        databaseId,
        teamSlug,
        tableSlug,
        requestBody: {
          select,
          filter,
          sort,
          limit,
          format,
          skip,
          join,
          additionalSort,
          addtionalFilter,
        },
      });
    },
  };
};

export { useListRecordsWithQueryQuery };
