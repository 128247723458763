import { ViewType } from "@usemorph/morph-dashboard-types";
import { SimpleSelect } from "~/components_next/Select";

type ViewTypeSelectProps = {
  value: ViewType | null;
  onChange: (value: ViewType) => void;
};

const viewTypeOptions = [
  { label: "Table", value: "table" },
  { label: "Kanban", value: "kanban" },
];

const ViewTypeSelect = (props: ViewTypeSelectProps) => {
  const { value, onChange } = props;

  return (
    <SimpleSelect
      label="View Type"
      variant="primary"
      size="sm"
      options={viewTypeOptions}
      value={value}
      onChange={(value) => {
        onChange(value as ViewType);
      }}
    />
  );
};

export { ViewTypeSelect };
