import { DashboardRssTableMappingResponse } from "@usemorph/morph-dashboard-types";
import { RssField, RssFieldClientModel } from "./RssFieldClientModel";
import { RssFieldClientModelFactory } from "./RssFieldClientModelFactory";

export class RssTableMappingClientModel {
  readonly #data: DashboardRssTableMappingResponse

  constructor(data: DashboardRssTableMappingResponse) {
    this.#data = data
  }

  public get createdAt(): string {
    return this.#data.createdAt
  }

  public get fields(): RssFieldClientModel {
    return RssFieldClientModelFactory.fromRssFieldResponse(this.#data.fields)
  }

  public get tableSlug(): string {
    return this.#data.tableSlug
  }

  public get updatedAt(): string {
    return this.#data.updatedAt
  }

  public get rssUrl(): string {
    return this.#data.rssUrl
  }

  public get isEnabled(): boolean {
    return this.#data.isEnabled
  }

  public updateFields(fields: RssField) {
    return new RssTableMappingClientModel({
      ...this.#data,
      fields
    })
  }

  public updateRssUrl(rssUrl: string) {
    return new RssTableMappingClientModel({
      ...this.#data,
      rssUrl,
    })
  }

  public updateIsEnabled(isEnabled: boolean) {
    return new RssTableMappingClientModel({
      ...this.#data,
      isEnabled,
    })
  }
}
