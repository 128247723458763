import { FieldClientModel } from "~/clientModel/fields/field";
import {
  UpdatableRecordEntryClientModelBaseConstructorParams,
  UpdatableRecordEntryClientModelBase,
} from "../RecordEntryClientModelBase";
import {
  zAllowNullIfNullable,
  zRequiredString,
  zValidate,
} from "../validatorsUtil";
import { z } from "zod";

type LongTextRecordEntryClientModelData =
  UpdatableRecordEntryClientModelBaseConstructorParams;

export class LongTextRecordEntryClientModel extends UpdatableRecordEntryClientModelBase<
  string | null
> {
  constructor(data: LongTextRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "longText" {
    return "longText";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([zRequiredString(field), zAllowNullIfNullable(field)]),
      this.data.rawValue
    );
  }

  public getValidatedDisplayValue = this.getValidatedValue;

  public updateValue(value: string | null): LongTextRecordEntryClientModel {
    return new LongTextRecordEntryClientModel({
      key: this.data.key,
      rawValue: value,
      meta: this.data.meta,
    });
  }
}
