import { useEditorOrThrow } from "../utils";

const targets = ["_blank", "_self", "_parent", "_top"] as const;

type Target = (typeof targets)[number];

const isTarget = (value: string): value is Target => {
  return targets.includes(value as Target);
};

type LinkValueType = {
  href: string;
  target: Target;
};

const useLink = () => {
  const editor = useEditorOrThrow();

  const handleSet = ({ href, target }: LinkValueType) => {
    editor.chain().focus().setLink({ href, target }).run();
  };

  const handleUnset = () => {
    editor.chain().focus().unsetLink().run();
  };

  const isActive = editor.isActive("link");

  const isSetDisabled = !editor
    .can()
    .chain()
    .focus()
    .setLink({ href: "" })
    .run();

  const isToggleDisabled = !editor
    .can()
    .chain()
    .focus()
    .toggleLink({ href: "" })
    .run();

  const getHref = () => {
    return editor.getAttributes("link").href;
  };

  const getTarget = () => {
    return editor.getAttributes("link").target;
  };

  const href = getHref();
  const target = getTarget();

  const currentValue: LinkValueType = {
    href: typeof href === "string" ? href : "",
    target: isTarget(target) ? target : "_blank",
  };

  return {
    currentValue,
    handleSet,
    handleUnset,
    isActive,
    isSetDisabled,
    isToggleDisabled,
  };
};

export { useLink };
export type { LinkValueType, Target };
