import {
  SimpleField,
  SimpleFieldCategoryType,
} from "@usemorph/morph-dashboard-types";
import { FieldTypeSelect } from "./FieldTypeSelect";
import { UpdateFieldTypeSelect } from "./UpdateFieldTypeSelect";

import { match, P } from "ts-pattern";
import { MembersInput } from "./MembersInput";
import "./prompt.css";

import { FormulaInput, FormulaReadOnlyInput } from "./FormulaInput";
import { Flex } from "~/components_next/Flex";
import { Box } from "~/components_next/Box";

type FieldTypeWithMeta = {
  previousType?: SimpleFieldCategoryType;
  type: SimpleFieldCategoryType;
  members?: string[];
  formula?: string;
  embeddingTarget: SimpleField | null;
};

type FieldFormProps = {
  value: FieldTypeWithMeta;
  simpleFields: SimpleField[];
  options?:
    | { includes: SimpleFieldCategoryType[] }
    | { excludes: SimpleFieldCategoryType[] };
  convertFieldToText: (field: SimpleField) => string;
} & (
  | {
      isReadOnly: true;
      onChange?: unknown;
      onTestRunStart?: unknown;
      isTestRunning?: unknown;
    }
  | {
      isReadOnly: false;
      onChange: (value: Omit<FieldTypeWithMeta, "previousType">) => void;
      onTestRunStart: (formula: string) => void;
      isTestRunning: boolean;
      convertFieldToText?: (field: SimpleField) => string;
    }
);

// formulaはローカルでスーテト管理し、test runの結果を見て上で状態を変更させる
const FieldTypeForm = (props: FieldFormProps) => {
  const {
    value,
    isReadOnly,
    onChange,
    simpleFields,
    onTestRunStart,
    isTestRunning,
    options,
    convertFieldToText,
  } = props;

  return (
    <Flex direction="column">
      <Box mb="2">
        {value.previousType ? (
          <UpdateFieldTypeSelect
            value={value.type}
            currentValue={value.previousType}
            onChange={(newFieldType) => {
              !isReadOnly &&
                onChange?.({
                  ...value,
                  type: newFieldType,
                });
            }}
            isReadOnly={isReadOnly}
          />
        ) : (
          <FieldTypeSelect
            value={value.type}
            onChange={(fieldType) => {
              !isReadOnly &&
                onChange?.({
                  ...value,
                  type: fieldType,
                });
            }}
            isReadOnly={isReadOnly}
            options={options}
          />
        )}
      </Box>
      {match(value.type)
        .with(P.union("singleSelect", "multiSelect"), () => (
          <MembersInput
            members={value.members ?? []}
            setMembers={(members) => {
              !isReadOnly &&
                onChange?.({
                  ...value,
                  members,
                });
            }}
            isReadOnly={isReadOnly}
          />
        ))
        .with("formula", () =>
          isReadOnly ? (
            <FormulaReadOnlyInput
              currentFormula={value.formula ?? ""}
              simpleFields={simpleFields}
              convertFieldToText={convertFieldToText}
            />
          ) : (
            <FormulaInput
              currentFormula={value.formula}
              simpleFields={simpleFields}
              onTestRunStart={onTestRunStart}
              isTestRunning={isTestRunning}
              convertFieldToText={convertFieldToText}
            />
          )
        )
        .otherwise(() => null)}
    </Flex>
  );
};

export { FieldTypeForm };
