import { css } from "@stitches/react";
import { useCallback, useMemo } from "react";
import { BsPlayFill } from "react-icons/bs";
import { UseExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { FieldClientModel } from "~/clientModel/fields/field";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";
import { useLoadableState } from "~/clientModel/loadable";
import { RecordsClientModel } from "~/clientModel/records";
import { Button } from "~/components_next/Button";
import { Flex } from "~/components_next/Flex";
import { useDisclosure } from "~/hooks/useDisclosure";
import {
  basicFieldFilterFn,
  BasicTypeaheadPrompt,
  FieldSuggestionItem,
} from "~/presenters/prompt";
import { PromptPreviewResultDrawer } from "./PromptPreviewResultDrawer";

const promptRootStyle = css({
  overflowY: "auto !important",
  height: "200px !important",
});

const promptTheme = {
  root: `${promptRootStyle()}`,
};

type PromptInputProps = {
  prompt: string;
  onPromptChange: (prompt: string) => void;
  fieldsForTypeahead: FieldsClientModel;
  smartFunctions: SmartFunctionsClientModel;
  usePreviewBulkEditRecordsByPromptExecutable: UseExecutable<
    void,
    {
      prompt: string;
      field: FieldClientModel;
      smartFunctions: SmartFunctionsClientModel;
    },
    { records: RecordsClientModel; fields: FieldsClientModel },
    unknown
  >;
  targetField: FieldClientModel;
};

export const PromptInput = (props: PromptInputProps) => {
  const {
    prompt,
    onPromptChange,
    fieldsForTypeahead,
    targetField,
    smartFunctions,
    usePreviewBulkEditRecordsByPromptExecutable,
  } = props;

  const optionItems = useMemo(
    () => fieldsForTypeahead.allFields,
    [fieldsForTypeahead]
  );

  const convertFieldToDotNotatedFieldName = useCallback(
    (field: FieldClientModel) => `\${${field.name}}`,
    []
  );

  const renderHTMLTextContent = useCallback(
    (field: FieldClientModel) => field.label,
    []
  );

  const renderSuggestedItem = useCallback(
    (field: FieldClientModel) => <FieldSuggestionItem field={field} />,
    []
  );

  const previewBulkEditRecordsByPromptExecutable =
    usePreviewBulkEditRecordsByPromptExecutable();

  const [previewResultLoadable, setPreviewResult] = useLoadableState<{
    records: RecordsClientModel;
    fields: FieldsClientModel;
  }>();

  const { isOpen, onOpen, setIsOpen } = useDisclosure();

  const handlePreview = useCallback(async () => {
    const { records, fields } =
      await previewBulkEditRecordsByPromptExecutable.execute({
        prompt,
        field: targetField,
        smartFunctions,
      });
    setPreviewResult({ records, fields });
    onOpen();
  }, [
    onOpen,
    previewBulkEditRecordsByPromptExecutable,
    prompt,
    setPreviewResult,
    targetField,
    smartFunctions,
  ]);

  return (
    <>
      <Flex direction="column" gap="2">
        <BasicTypeaheadPrompt<FieldClientModel>
          textContent={prompt}
          onUpdate={onPromptChange}
          theme={promptTheme}
          optionItems={optionItems}
          filterFn={basicFieldFilterFn}
          convertOptionItemToText={convertFieldToDotNotatedFieldName}
          renderHTMLTextContent={renderHTMLTextContent}
          renderSuggestedItem={renderSuggestedItem}
          focusOnLoad
        />

        <Button
          variant="secondary"
          onClick={handlePreview}
          isDisabled={!prompt}
          isLoading={previewBulkEditRecordsByPromptExecutable.isExecuting}
          size="sm"
        >
          <BsPlayFill />
          Generate Formula
        </Button>
      </Flex>
      <PromptPreviewResultDrawer
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        previewResultLoadable={previewResultLoadable}
      />
    </>
  );
};
