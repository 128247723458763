import constate from "constate";
import { HTMLRichTextEditorProps } from "../HTMLRichTextEditor";

type RichTextEditorRootPrams = {
  onUploadImage: HTMLRichTextEditorProps["onUploadImage"];
};

const useRichTextEditorRoot = ({ onUploadImage }: RichTextEditorRootPrams) => {
  return {
    onUploadImage,
  };
};

const [RichTextEditorRootProvider, useRichTextEditorUploadImage] = constate(
  useRichTextEditorRoot,
  (value) => value.onUploadImage
);

export { RichTextEditorRootProvider, useRichTextEditorUploadImage };
