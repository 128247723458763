import { useQueryClient } from "react-query";
import { RecordFilterCondition } from "@usemorph/morph-dashboard-types";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import useApiFields from "~/api/useApiField";
import { recordsKeys } from "~/serverStateStore/records/keys";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseRunSmartFieldMutationParams {
  databaseId: string;
  tableSlug: string;
  teamSlug: string;
}

const useRunSmartFieldMutation = ({
  databaseId,
  tableSlug,
  teamSlug,
}: UseRunSmartFieldMutationParams) => {
  const { _runSmartField } = useApiFields();
  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({
      select,
      filter,
      onlyError,
    }: {
      select: string[] | ["*"];
      filter?: RecordFilterCondition;
      onlyError: boolean;
    }) => {
      return _runSmartField({
        teamSlug,
        databaseId,
        tableSlug,
        requestBody: {
          select,
          filter,
          onlyError,
        },
      });
    },
    onSuccess: () => {
      const allGetRecord = recordsKeys.allGetRecord({
        teamSlug,
        databaseId,
        tableSlug,
      });
      broadcast(allGetRecord);

      return invalidateAll(client, allGetRecord);
    },
  };
};

export { useRunSmartFieldMutation };
