import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FilterOperationClientModelBase } from "./FilterOperationClientModelBase";

export class NotNullFilterOperationClientModel extends FilterOperationClientModelBase {
  public constructor() {
    super();
  }

  public get type(): "notNull" {
    return "notNull";
  }

  public get operatorValue(): "notNull" {
    return "notNull";
  }

  public get operatorLabel(): "Not null" {
    return "Not null";
  }

  public validate(): ValidationResult {
    return { isValid: true };
  }
}
