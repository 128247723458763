import { useQuery } from "react-query";
import {
  useViewFields,
  useQueryViewRecordsQuery,
} from "~/serverStateStore/views";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { convertRecordToRecordModel } from "~/features/RecordModel";
import { useFilter, useSort } from "../states/queryConditions";
import { useLimit, useSkip } from "../states/pagination";
import { useQueryMode } from "../states/queryMode";
import { UseViewRecordsResult } from "./useViewRecords";
import { useTableView } from "./useProps";
import { useVariables } from "../../common/states/variables";
import { useViewSetting } from "../states/viewSetting";

const useViewRecordsWithQuery = (): UseViewRecordsResult => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const view = useTableView();
  const viewSetting = useViewSetting();

  const queryMode = useQueryMode();

  // with filter and sort
  const filter = useFilter();
  const sort = useSort();
  const skip = useSkip();
  const limit = useLimit();
  const variables = useVariables();

  const {
    data: viewRecordsWithQueryData,
    status: viewRecordsWithQueryStatus,
    error: viewRecordsWithQueryError,
  } = useQuery({
    ...useQueryViewRecordsQuery({
      teamSlug,
      databaseId,
      viewId: view.viewId,
      select: ["*"],
      filter: filter ?? undefined,
      sort: sort ? [sort] : undefined,
      skip,
      limit,
      variables,
    }),
    enabled: !queryMode || queryMode === "filtersAndSorts",
  });

  const {
    data: fieldsData,
    status: fieldsStatus,
    error: fieldsError,
  } = useViewFields({
    teamSlug,
    databaseId,
    viewId: view.viewId,
    viewData: {
      ...view,
      setting: viewSetting,
    },
  });

  if (viewRecordsWithQueryStatus === "error" || fieldsStatus === "error") {
    return {
      status: "error",
      data: undefined,
      error: viewRecordsWithQueryError ?? fieldsError,
    };
  }

  if (viewRecordsWithQueryStatus === "loading" || fieldsStatus === "loading") {
    return {
      status: "loading",
      data: undefined,
      error: undefined,
    };
  }

  if (viewRecordsWithQueryStatus === "idle" || fieldsStatus === "idle") {
    return {
      status: "idle",
      data: undefined,
      error: undefined,
    };
  }

  return {
    status: "success",
    data: {
      recordsTableBaseRecords: viewRecordsWithQueryData.items.map(
        (record, index) => {
          return {
            values: convertRecordToRecordModel(record, fieldsData.fields),
            _reservedRecordIndex: index,
          };
        }
      ),
      count: viewRecordsWithQueryData.count,
      fields: fieldsData.fields,
    },
    error: undefined,
  };
};

export { useViewRecordsWithQuery };
