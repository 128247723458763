import { FieldClientModel } from "~/clientModel/fields/field";
import {
  ReadonlyRecordEntryClientModelBase,
  ReadonlyRecordEntryClientModelBaseConstructorParams,
} from "../RecordEntryClientModelBase";
import { zValidate } from "../validatorsUtil";
import { z } from "zod";

// 一旦データ型これでやる(formulaと同じ)
// 本当はSmartFunctionFieldTypeClientModelのvalueTypeに応じて型狭められる

export type SmartFunctionRecordEntryValueType =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | boolean[]
  | null;

type SmartFunctionRecordEntryClientModelData =
  ReadonlyRecordEntryClientModelBaseConstructorParams;

export class SmartFunctionRecordEntryClientModel extends ReadonlyRecordEntryClientModelBase<SmartFunctionRecordEntryValueType> {
  constructor(data: SmartFunctionRecordEntryClientModelData) {
    super(data);
  }

  public get type(): "smartFunction" {
    return "smartFunction";
  }

  public getValidatedValue(field: FieldClientModel) {
    return zValidate(
      z.union([
        z.string(field),
        z.number(),
        z.boolean(),
        z.array(z.string()),
        z.array(z.number()),
        z.array(z.boolean()),
        z.null(),
      ]),
      this.data.rawValue
    );
  }

  public getValidatedDisplayValue = this.getValidatedValue;
}
