import { useMutation } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import {
  FieldsClientModel,
  FieldsClientModelDefactory,
} from "~/clientModel/fields";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";
import {
  CreatingRecordClientModel,
  CreatingRecordClientModelFactory,
} from "~/clientModel/records/creatingRecord";
import { useErrorToast } from "~/components_next/Error";
import { useCreateRecordWithPromptMutation } from "~/serverStateStore";

type UseGenerateRecordsWithPromptExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useGenerateRecordsWithPromptExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseGenerateRecordsWithPromptExecutableParams): Executable<
  {
    prompt: string;
    fields: FieldsClientModel;
    smartFunctions: SmartFunctionsClientModel;
  },
  { creatingRecordArray: CreatingRecordClientModel[] }
> => {
  const { errorToast } = useErrorToast({});

  const createRecordsWithPromptOptions = useCreateRecordWithPromptMutation({
    teamSlug,
    databaseId,
    tableSlug,
  });

  return useComposeExecutable(
    useMutation({
      ...createRecordsWithPromptOptions,
      mutationFn: async ({
        prompt,
        fields,
        smartFunctions,
      }: {
        prompt: string;
        fields: FieldsClientModel;
        smartFunctions: SmartFunctionsClientModel;
      }) => {
        const createRecordResult =
          await createRecordsWithPromptOptions.mutationFn({
            prompt,
          });

        if (createRecordResult.error) {
          throw createRecordResult.error;
        }

        const creatingRecordArray = createRecordResult.records.map(
          ({ record }) =>
            CreatingRecordClientModelFactory.createWithInitialRecordValue(
              record,
              FieldsClientModelDefactory.toSimpleFields(fields, {
                smartFunctions,
              })
            )
        );

        return { creatingRecordArray };
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseGenerateRecordsWithPromptExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseGenerateRecordsWithPromptExecutableParams) => {
  return () =>
    useGenerateRecordsWithPromptExecutable({
      teamSlug,
      databaseId,
      tableSlug,
    });
};
