// types
import { DashboardNotebookCellVariableRequestObject } from "@usemorph/morph-dashboard-types";
import { useQueryClient } from "react-query";
import useApiView from "~/api/useApiView";
import { commonQueryOptions } from "~/serverStateStore/util/commonQueryOptions";
import { viewKeys } from "../keys";

interface UseQueryViewRecordsSqlQueryParams {
  databaseId: string;
  viewId: string;
  teamSlug: string;
  sql: string;
  limit?: number | undefined;
  skip?: number | undefined;
  variables?: DashboardNotebookCellVariableRequestObject;
}

const useQueryViewRecordsSqlQuery = ({
  databaseId,
  viewId,
  teamSlug,
  sql,
  limit,
  skip,
  variables = [],
}: UseQueryViewRecordsSqlQueryParams) => {
  const { _queryViewRecordsWithSQL } = useApiView();
  const client = useQueryClient();
  client.setQueryDefaults(
    viewKeys.allQueryViewRecords({ databaseId, viewId, teamSlug }),
    commonQueryOptions
  );
  return {
    queryKey: viewKeys.queryViewRecordsSql({
      databaseId,
      viewId,
      teamSlug,
      sql,
      limit,
      skip,
      variables,
    }),
    queryFn: () => {
      return _queryViewRecordsWithSQL({
        teamSlug,
        body: {
          sql,
          limit,
          skip,
          databaseId,
          variables,
        },
      });
    },
  };
};

export { useQueryViewRecordsSqlQuery };
