import { useCallback } from "react";
import { FilterOperationWithStringValueClientModel } from "~/clientModel/queryConditions/filterConditions/FilterConditionUnit/operations";
import { CanvasVariablesValue } from "~/presenters/canvas/common/CanvasVariablesProvider";
import {
  BasicTypeaheadPrompt,
  VariableSuggestionItem,
  basicVariableValueFilterFn,
} from "~/presenters/prompt";
import { css } from "@stitches/react";
import { Input } from "~/components_next/Input";

type OperandInputProps = {
  operation: FilterOperationWithStringValueClientModel;
  onOperationChange: (
    operation: FilterOperationWithStringValueClientModel
  ) => void;
  isReadOnly: boolean;
  errorMessages?: readonly string[] | undefined;
  variables?: CanvasVariablesValue[];
};

const inputRootStyle = css({
  overflowY: "auto !important",
  width: "100%",
  padding: "3px 8px !important",
  fontSize: "12px !important",
  borderRadius: "2px !important",
});

const filterInputTheme = {
  root: `${inputRootStyle()}`,
};

export const OperandInput = (props: OperandInputProps) => {
  const { operation, onOperationChange, isReadOnly, errorMessages, variables } =
    props;

  const convertOptionItemToText = useCallback((item: CanvasVariablesValue) => {
    return `\${${item.cellId}+${item.name}}`;
  }, []);

  const renderHTMLTextContent = useCallback((item: CanvasVariablesValue) => {
    return item.name;
  }, []);

  const renderSuggestedItem = useCallback((item: CanvasVariablesValue) => {
    return <VariableSuggestionItem variable={item} />;
  }, []);

  return variables ? (
    <BasicTypeaheadPrompt
      theme={filterInputTheme}
      textContent={operation.operandValue}
      onUpdate={(value) => onOperationChange(operation.updateValue(value))}
      optionItems={variables || []}
      filterFn={basicVariableValueFilterFn}
      convertOptionItemToText={convertOptionItemToText}
      renderHTMLTextContent={renderHTMLTextContent}
      renderSuggestedItem={renderSuggestedItem}
    />
  ) : (
    <Input
      variant="primary"
      value={operation.operandValue}
      onChange={(e) => onOperationChange(operation.updateValue(e.target.value))}
      size="xs"
      readOnly={isReadOnly}
      disabled={isReadOnly}
      isInvalid={errorMessages && errorMessages.length > 0}
    />
  );
};
