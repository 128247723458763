import { useTranslation } from "react-i18next";
import { useDisclosure } from "~/hooks/useDisclosure";
import { match } from "ts-pattern";
import { useToast } from "~/components_next/Toast";
import { extractErrorDetails } from "~/components_next/Error";
import { useFindPaymentObjectLoadable } from "./useFindPaymentObjectLoadable";
import { createUseUpdatePaymentExecutable } from "./createUseUpdatePaymentExecutable";
import { createUseRegistTrialExecutable } from "./createUseRegistTrialExecutable";
import { useDeletePaymentExecutable } from "./createUseDeletePaymentExecutable";
import { PaymentClientModelService } from "~/clientModel/payment/PaymentClientModel";
import { PlanPresenter } from "~/presenters/plan";
import { useMemo, useState } from "react";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useListVersionLoadable } from "./useListVersionLoadable";
import { createUseCreatePaymentSessionExecutable } from "./createUseCreatePaymentSessionExecutable";
import { useListPlansLoadable } from "./useListPlanLoadable";

const PlanContainer = () => {
  const teamSlug = useTeamSlug()
  const toast = useToast();
  const { t } = useTranslation();

  /**
   * Loadable
   */
  const plansLoadable = useListPlansLoadable({ teamSlug })
  const paymentLoadable = useFindPaymentObjectLoadable({ teamSlug })

  const useCreatePaymentSessionExecutable = useMemo(() => {
    return createUseCreatePaymentSessionExecutable({ teamSlug })
  }, [teamSlug])
  const createPaymentSessionExecutable = useCreatePaymentSessionExecutable()

  const useUpdatePaymentExecutable = useMemo(() => {
    return createUseUpdatePaymentExecutable({ teamSlug })
  }, [teamSlug])
  const updatePaymentExecutable = useUpdatePaymentExecutable()

  const useRegistTrialExecutable = useMemo(() => {
    return createUseRegistTrialExecutable({ teamSlug })
  }, [teamSlug])
  const registTrialExecutable = useRegistTrialExecutable()

  const deletePaymentExecutable = useDeletePaymentExecutable({ teamSlug })

  const listVersionLoadable = useListVersionLoadable({ teamSlug })

  /**
   * Handler
   */
  const handleConfirmSelectPlan = async (planId: string) => {
    match(planId)
      .with("free", () => {
        unsubscribeDialogDisclosure.onOpen();
      })
      .otherwise(async () => {
        if (paymentLoadable.data && paymentLoadable.data.isSubscribed) {
          try {
            await updatePaymentExecutable.execute({ planId })
            toast({
              title: "Success, Upgrade plan.",
              type: "success",
              duration: 4000,
            });
          } catch (error) {
            const errors = extractErrorDetails(error);
            setDangerErrors(errors)
          }
        } else {
          const redirectUrl = location.href
          const response = await createPaymentSessionExecutable.execute({ planId, redirectUrl })
          location.href = response.url;
        }
      });
  };

  const handleStartTrial = async () => {
    if (listVersionLoadable.data) {
      try {
        const versionId = paymentLoadable.data?.getTrialVersionId(listVersionLoadable.data)
        if (versionId) {
          await registTrialExecutable.execute({ versionId: PaymentClientModelService.trialVersionId })
          toast({
            title: "Success, trial.",
            type: "success",
            duration: 4000,
          });
        } else {
          throw ("You can not start trial.")
        }
      } catch (error) {
        const errors = extractErrorDetails(error);
        setDangerErrors(errors)
      }
    }
  };

  const handleContactUs = () => {
    const url = t("plans.contactUrl");
    window.open(url);
  };

  const handleUnsubscribe = async () => {
    await deletePaymentExecutable.execute();
  }

  const handleSelectPlan = ({ planId, planName }: { planId: string, planName: string }) => {
    setSelectPlan({ planId, planName })
    confirmDialogDisclosure.onOpen()
  }

  /**
   * UI state
   */
  const [selectPlan, setSelectPlan] = useState<{ planId: string; planName: string }>()
  const confirmDialogDisclosure = useDisclosure();

  const [dangerErrors, setDangerErrors] = useState<{ title: string; description: string; }>()
  const dangerDialogDisclosure = useDisclosure();

  const unsubscribeDialogDisclosure = useDisclosure();

  if (!paymentLoadable.data) {
    return null;
  }

  return (
    <>
      <PlanPresenter
        plansLoadable={plansLoadable}
        paymentLoadable={paymentLoadable}
        listVersionLoadable={listVersionLoadable}
        useCreatePaymentSessionExecutable={useCreatePaymentSessionExecutable}
        useUpdatePaymentExecutable={useUpdatePaymentExecutable}
        useRegistTrialExecutable={useRegistTrialExecutable}
        deletePaymentExecutable={deletePaymentExecutable}
        unsubscribeDialogDisclosure={unsubscribeDialogDisclosure}
        selectPlan={selectPlan}
        handleSelectPlan={handleSelectPlan}
        confirmDialogDisclosure={confirmDialogDisclosure}
        dangerErrors={dangerErrors}
        dangerDialogDisclosure={dangerDialogDisclosure}
        onConfirmSelectPlan={handleConfirmSelectPlan}
        onStartTrial={handleStartTrial}
        onClickContactUs={handleContactUs}
        onUnsubscribe={handleUnsubscribe}
      />
    </>
  );
};

export { PlanContainer };
