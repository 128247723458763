import { useMutation } from "react-query";
import { match, P } from "ts-pattern";
import { Executable, useComposeExecutable } from "~/clientModel/executable";
import { FieldClientModel } from "~/clientModel/fields/field";
import {
  FilterConditionsClientModel,
  FilterConditionsClientModelDefactory,
} from "~/clientModel/queryConditions/filterConditions";

import {
  RecordEntryClientModel,
  RecordEntryClientModelDefactory,
} from "~/clientModel/records/record/recordEntry";
import { RecordIdentifierClientModelDefactory } from "~/clientModel/records/record/recordIdentifier";
import { TableSelectionClientModel } from "~/clientModel/tables/tableMeta/TableSelection";
import { useErrorToast } from "~/components_next/Error";
import { useUpdateRecordMutation } from "~/serverStateStore";

type UseBulkEditRecordsByRowExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  filterConditions: FilterConditionsClientModel;
  tableSelection: TableSelectionClientModel;
};

const useBulkEditRecordsByRowExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
  filterConditions,
  tableSelection,
}: UseBulkEditRecordsByRowExecutableParams): Executable<
  { recordEntry: RecordEntryClientModel; field: FieldClientModel },
  unknown
> => {
  const { errorToast } = useErrorToast({});

  const updateRecordOptions = useUpdateRecordMutation({
    teamSlug,
    databaseId,
    tableSlug,
  });

  return useComposeExecutable(
    useMutation({
      ...updateRecordOptions,
      mutationFn: ({
        recordEntry,
        field,
      }: {
        recordEntry: RecordEntryClientModel;
        field: FieldClientModel;
      }) => {
        const value = RecordEntryClientModelDefactory.toValueForRecordRequest(
          recordEntry,
          field
        );

        const filter = match([
          filterConditions.hasValidFilterConditions,
          !!tableSelection.selectedRecordsIdentifiers.length,
        ])
          .with([false, false], () => ({ or: [] }))
          .with([true, false], () =>
            FilterConditionsClientModelDefactory.toRecordFilterCondition(
              filterConditions
            )
          )
          .with([P._, true], () => ({
            or: tableSelection.selectedRecordsIdentifiers.map((recordId) =>
              RecordIdentifierClientModelDefactory.toRecordFilterCondition(
                recordId
              )
            ),
          }))
          .exhaustive();

        return updateRecordOptions.mutationFn({
          values: [{ key: field.name, value }],
          filter,
        });
      },
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseBulkEditRecordsByRowExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
  filterConditions,
  tableSelection,
}: {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
  filterConditions: FilterConditionsClientModel;
  tableSelection: TableSelectionClientModel;
}) => {
  return () =>
    useBulkEditRecordsByRowExecutable({
      teamSlug,
      databaseId,
      tableSlug,
      filterConditions,
      tableSelection,
    });
};
