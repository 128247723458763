import { ReactNode } from "react";
import { Flex } from "~/components_next/Flex";

type FieldTypeFormLayoutProps = {
  children: ReactNode;
};

export const FieldTypeFormLayout = (props: FieldTypeFormLayoutProps) => {
  const { children } = props;
  return (
    <Flex direction="column" gap="2">
      {children}
    </Flex>
  );
};
