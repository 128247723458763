import { FieldClientModel } from "~/clientModel/fields/field";
import { CsvDownloadFieldClientModel } from "./CsvDownloadFieldClientModel";

export class CsvDownloadFieldClientModelFactory {
  public static fromFieldClientModel(
    field: FieldClientModel
  ): CsvDownloadFieldClientModel {
    return new CsvDownloadFieldClientModel({
      baseField: field,
      isMasked: false,
    });
  }
}
