import dayjs from "dayjs";
import { match } from "ts-pattern";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DateDisplaySettingClientModel {
  export type Parts = "dateTime" | "date" | "time";
  export type DateFormat = "short" | "medium" | "long";
  export type TimeFormat = "withSeconds" | "withoutSeconds";
}

type Parts = DateDisplaySettingClientModel.Parts;
type DateFormat = DateDisplaySettingClientModel.DateFormat;
type TimeFormat = DateDisplaySettingClientModel.TimeFormat;

type DateDisplaySettingClientModelData = {
  parts: Parts;
  dateFormat: DateFormat;
  timeFormat: TimeFormat;
};

export class DateDisplaySettingClientModel {
  public constructor(readonly data: DateDisplaySettingClientModelData) {}

  public get parts(): "dateTime" | "date" | "time" {
    return this.data.parts;
  }

  public get partsOptions(): readonly { value: Parts; label: string }[] {
    return [
      { value: "dateTime", label: "Date and time" },
      { value: "date", label: "Date only" },
      { value: "time", label: "Time only" },
    ];
  }

  public get dateFormat(): "short" | "medium" | "long" {
    return this.data.dateFormat;
  }

  public get dateFormatOptions(): readonly {
    value: DateFormat;
    label: string;
  }[] {
    return [
      { value: "short", label: "Short" },
      { value: "medium", label: "Medium" },
      { value: "long", label: "Long" },
    ];
  }

  public get timeFormat(): "withSeconds" | "withoutSeconds" {
    return this.data.timeFormat;
  }

  public get timeFormatOptions(): readonly {
    value: TimeFormat;
    label: string;
  }[] {
    return [
      { value: "withSeconds", label: "With seconds" },
      { value: "withoutSeconds", label: "Without seconds" },
    ];
  }

  public get hasDateParts(): boolean {
    return this.parts === "dateTime" || this.parts === "date";
  }

  public get hasTimeParts(): boolean {
    return this.parts === "dateTime" || this.parts === "time";
  }

  public get displayExample(): string {
    const sampleDate = "2024-01-01 12:34:56";
    return this.getDisplayValue(sampleDate) ?? "";
  }

  public updateParts(parts: Parts): DateDisplaySettingClientModel {
    return new DateDisplaySettingClientModel({
      ...this.data,
      parts,
    });
  }

  public updateDateFormat(
    dateFormat: DateFormat
  ): DateDisplaySettingClientModel {
    return new DateDisplaySettingClientModel({
      ...this.data,
      dateFormat,
    });
  }

  public updateTimeFormat(
    timeFormat: TimeFormat
  ): DateDisplaySettingClientModel {
    return new DateDisplaySettingClientModel({
      ...this.data,
      timeFormat,
    });
  }

  public getDisplayValue(value: string | null): string | null {
    if (value == null) {
      return null;
    }

    const dateMatch = value.match(/\d{4}-\d{2}-\d{2}/);
    const timeMatch = value.match(/\d{2}:\d{2}:\d{2}/);

    let datePart = "";
    let timePart = "";

    if (
      dateMatch &&
      dateMatch.length > 0 &&
      (this.data.parts === "date" || this.data.parts === "dateTime")
    ) {
      const dayjsInstance = dayjs(dateMatch[0]);

      datePart = match(this.data.dateFormat)
        .with("short", () => dayjsInstance.format("MM/DD/YYYY"))
        .with("medium", () => dayjsInstance.format("MMMM D, YYYY"))
        .with("long", () => dayjsInstance.format("dddd, MMMM D, YYYY"))
        .exhaustive();
    }

    if (
      timeMatch &&
      timeMatch.length > 0 &&
      (this.data.parts === "time" || this.data.parts === "dateTime")
    ) {
      timePart = match(this.data.timeFormat)
        .with("withSeconds", () => timeMatch[0])
        .with("withoutSeconds", () => timeMatch[0].slice(0, 5))
        .exhaustive();
    }

    return `${datePart} ${timePart}`.trim();
  }
}
