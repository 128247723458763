import { TargetDayClientModel } from "./targetDay";

type TargetDays = readonly [
  TargetDayClientModel,
  TargetDayClientModel,
  TargetDayClientModel,
  TargetDayClientModel,
  TargetDayClientModel,
  TargetDayClientModel,
  TargetDayClientModel
];

type TargetDaysClientModelData = {
  targetDays: TargetDays;
};

export class TargetDaysClientModel {
  readonly #data: TargetDaysClientModelData;

  public constructor(data: TargetDaysClientModelData) {
    this.#data = data;
  }

  public get data(): TargetDaysClientModelData {
    return this.#data;
  }

  public get allDays(): TargetDays {
    return this.#data.targetDays;
  }

  public selectDay(day: TargetDayClientModel): TargetDaysClientModel {
    return new TargetDaysClientModel({
      ...this.#data,
      targetDays: this.mapTargetDays((targetDay) =>
        targetDay.dayType === day.dayType ? targetDay.select() : targetDay
      ),
    });
  }

  public unselectDay(day: TargetDayClientModel): TargetDaysClientModel {
    return new TargetDaysClientModel({
      ...this.#data,
      targetDays: this.mapTargetDays((targetDay) =>
        targetDay.dayType === day.dayType ? targetDay.unselect() : targetDay
      ),
    });
  }

  public selectAllTargetDays(): TargetDaysClientModel {
    return new TargetDaysClientModel({
      ...this.#data,
      targetDays: this.mapTargetDays((targetDay) => targetDay.select()),
    });
  }

  public unselectAllTargetDays(): TargetDaysClientModel {
    return new TargetDaysClientModel({
      ...this.#data,
      targetDays: this.mapTargetDays((targetDay) => targetDay.unselect()),
    });
  }

  private mapTargetDays(
    callback: (targetDay: TargetDayClientModel) => TargetDayClientModel
  ): TargetDays {
    // TargetDayClientModel[]と推論されるが、要素数は7つなのでTargetDaysにキャストして良い
    return this.#data.targetDays.map(callback) as unknown as TargetDays;
  }
}
