import { ChangeEvent, useMemo, useState, useEffect } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useErrorToast } from "~/components_next/Error";
import { useSignUpMutation, useMe } from "~/serverStateStore/me";
import { useAuth0 } from "@auth0/auth0-react";
import { Text } from "~/components_next/Text";
import { Input } from "~/components_next/Input";
import { Spacer } from "~/components_next/Spacer";
import { Button } from "~/components_next/Button";
import { Container } from "~/components_next/Container";
import { Box } from "~/components_next/Box";
import { Flex } from "~/components_next/Flex";
export const SignupPage = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const { data: me } = useMe();
  useEffect(() => {
    if (me && me.userId && me.username) {
      navigate("/");
    }
  }, [me]);
  const onUsernameChange = (event: ChangeEvent) =>
    setUsername((event.target as HTMLInputElement).value);

  const [firstName, setFirstname] = useState("");
  const onFirstnameChange = (event: ChangeEvent) =>
    setFirstname((event.target as HTMLInputElement).value);

  const [lastName, setLastname] = useState("");
  const onLastnameChange = (event: ChangeEvent) =>
    setLastname((event.target as HTMLInputElement).value);

  const { mutateAsync: signUp, isLoading: isSignUpProceeding } = useMutation(
    useSignUpMutation()
  );

  const isSignupValid = useMemo(() => {
    return username.length > 0 && firstName.length > 0 && lastName.length > 0;
  }, [username, firstName, lastName]);

  const { errorToast } = useErrorToast({});

  const createUser = async () => {
    try {
      await signUp({
        firstName,
        lastName,
        username,
      });
      navigate("/");
    } catch (e) {
      errorToast(e);
    }
  };
  const { logout } = useAuth0();
  const onClickSignOut = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <>
      <Container height="100%" p="4">
        <Text variant="heading" mb="8">Sign Up</Text>
        <Box my="4">
          <Text>Username</Text>
          <Input variant="primary" value={username} onChange={onUsernameChange} />
        </Box>
        <Box my="4">
          <Text>First name</Text>
          <Input variant="primary" value={firstName} onChange={onFirstnameChange} />
        </Box>
        <Box my="4">
          <Text>Last name</Text>
          <Input variant="primary" value={lastName} onChange={onLastnameChange} />
        </Box>
        {/* <Box my={4}>
          <Text>Team Slug</Text>
          <Input value={teamSlug} onChange={onTeamSlugChange} />
        </Box> */}
        {/* {isSignUpProceeding ? (
          <Progress my={4} isIndeterminate size="xs" />
        ) : (
          <></>
        )} */}
        <Flex>
          <Spacer />
          <Button
            variant="primary"
            // colorScheme="blue"
            isDisabled={!isSignupValid || isSignUpProceeding}
            isLoading={isSignUpProceeding}
            onClick={createUser}
          >
            Sign up
          </Button>
          <Spacer />
        </Flex>
        <Flex mt="9">
          <Spacer />
          <Button
            variant="tertiary"
            isDisabled={!isSignupValid || isSignUpProceeding}
            onClick={onClickSignOut}
          >
            Log out
          </Button>
          <Spacer />
        </Flex>
      </Container>
    </>
  );
};
