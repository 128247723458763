import { RecordValueInputCommonProps } from "../../type";
import { forwardRef, ForwardedRef } from "react";
import { SimpleMultiSelect } from "~/components_next/Select";

type MultiSelectInputProps = RecordValueInputCommonProps<"multiSelect"> & {
  members: string[];
};

const MultiSelectInput = forwardRef(function MultiSelectInput(
  props: MultiSelectInputProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const {
    value,
    members,
    onChange,
    errorMessages,
    isNullable,
    isReadOnly,
    size,
  } = props;

  return (
    <SimpleMultiSelect
      ref={ref}
      variant="primary"
      size={size}
      isReadOnly={isReadOnly}
      value={value ?? null}
      onChange={onChange}
      options={members}
      errorMessages={errorMessages}
      isClearable={isNullable && !isReadOnly}
    />
  );
});

export { MultiSelectInput };
