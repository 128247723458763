import { DashboardViewResponse } from "@usemorph/morph-dashboard-types";
import { useState } from "react";
import { useQuery } from "react-query";
import { useErrorToast } from "~/components_next/Error";
import { DownloadRecordsWithSQLDrawer } from "~/features/DownloadRecords";
import { DownloadButton } from "../../../common/components/atoms/DownloadButton";
import { useDownloadAsCSVFromExecuteSQLQuery } from "~/serverStateStore";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { DownloadConditions } from "~/features/DownloadRecords/DownloadRecordsPreviewWithSQL";
import { useViewRecords } from "../../hooks/useViewRecords";
import { useQueryingSql } from "../../states/sql";
import { useViewId } from "~/utilHooks/useViewId";
import { useQueryMode } from "../../states/queryMode";
import { match, P } from "ts-pattern";
import { useDisclosure } from "~/hooks/useDisclosure";

type ViewWithSQLDownloadRecordsWithSQLButtonProps = {
  view: DashboardViewResponse;
};

const ViewWithSQLDownloadRecordsWithSQLButton = (
  props: ViewWithSQLDownloadRecordsWithSQLButtonProps
) => {
  const { view } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  const queryingSql = useQueryingSql(viewId);
  const queryMode = useQueryMode(viewId);

  const mainTableSlug = view.tableSlug;

  const { errorToast } = useErrorToast({});

  const [downloadConditions, setDownloadConditions] =
    useState<DownloadConditions>({ sql: "", selectedFields: [] });

  const { data: viewRecordsData } = useViewRecords();

  const { refetch: downloadRecords, isLoading: isDownloading } = useQuery({
    ...useDownloadAsCSVFromExecuteSQLQuery({
      teamSlug,
      databaseId,
      sql: downloadConditions.sql,
      fields: downloadConditions.selectedFields,
    }),
    enabled: false,
    retry: false,
  });

  const handleDownloadClick = async () => {
    try {
      const response = await downloadRecords({ throwOnError: true });
      if (response.data) {
        window.open(response.data.url, "_blank");
      }
    } catch (e) {
      errorToast(e);
    }
  };

  const { isOpen, setIsOpen, onOpen } = useDisclosure();

  const handleOpen = () => {
    const sql = match(queryMode)
      .with(P.union("prompt", "sql"), () => queryingSql)
      .with(P.union(P.nullish), () => view.condition.from)
      .with(P.union("filtersAndSorts"), () => {
        throw new Error("Something went wrong");
      })
      .exhaustive();
    setDownloadConditions({
      sql,
      selectedFields: viewRecordsData?.fields ?? [],
    });
    onOpen();
  };

  return (
    <>
      <DownloadButton onClick={handleOpen} isDisabled={!viewRecordsData} />
      {viewRecordsData && (
        <DownloadRecordsWithSQLDrawer
          isOpen={isOpen}
          onOpenChange={setIsOpen}
          tableSlug={mainTableSlug}
          downloadConditions={downloadConditions}
          onChangeCondition={setDownloadConditions}
          onClickDownload={handleDownloadClick}
          isDownloading={isDownloading}
          allSimpleFields={viewRecordsData.fields}
        />
      )}
    </>
  );
};

export { ViewWithSQLDownloadRecordsWithSQLButton };
