import { useToast, ToastItem } from "~/components_next/Toast";
import { extractErrorDetails } from "../util/extractErrorDetails";

interface UseErrorToastArgs {
  overwriteOptions?: ToastItem;
}

interface UseErrorToastReturn {
  errorToast: (error: unknown) => void;
}

/**
 *
 * @param overwriteOptions toast()のオプションを上書きする。
 * @returns toastId
 */
const useErrorToast = ({
  overwriteOptions,
}: UseErrorToastArgs): UseErrorToastReturn => {
  const toast = useToast();

  const errorToast = (error: unknown) => {
    const { title, description } = extractErrorDetails(error);
    return toast({
      title,
      description,
      type: "error",
      duration: 5000,
      ...overwriteOptions,
    });
  };

  return { errorToast };
};

export { useErrorToast };
