import { DashboardPageDetailObject } from "@usemorph/morph-dashboard-types";
import { useQueryClient } from "react-query";
import useApiPage from "~/api/useApiPage";
import { invalidateAll } from "~/serverStateStore/util/invalidateAll";
import { pageKeys } from "../keys";
import { useBroadcastQueryInvalidate } from "~/features/RealtimeCollaboration";

interface UseAddNotebookCellToPageMutationParams {
  teamSlug: string;
  databaseId: string;
  notebookId: string;
}

type MutateProps = {
  pageId: string;
  notebookCellId: string;
  x: number;
  y: number;
  w: number;
  h: number;
};

const useAddNotebookCellToPageMutaiton = ({
  teamSlug,
  databaseId,
  notebookId,
}: UseAddNotebookCellToPageMutationParams) => {
  const { _addNotebookCellToPage } = useApiPage();

  const client = useQueryClient();

  const broadcast = useBroadcastQueryInvalidate();

  return {
    mutationFn: ({ pageId, notebookCellId, x, y, w, h }: MutateProps) => {
      return _addNotebookCellToPage({
        pageId,
        teamSlug,
        notebookCellId,
        x,
        y,
        w,
        h,
      });
    },
    onSuccess: (data: DashboardPageDetailObject, variables: MutateProps) => {
      const keys = pageKeys.findPageDetail({
        teamSlug,
        databaseId,
        notebookId,
        pageId: variables.pageId,
      });
      broadcast(keys);
      return invalidateAll(client, keys);
    },
  };
};

export { useAddNotebookCellToPageMutaiton };
