import {
  RecordSortConditionUnit,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { BsPlus, BsX } from "react-icons/bs";
import { Button } from "~/components_next/Button";
import { SimpleSelect } from "~/components_next/Select";
import { IconButton } from "~/components_next/IconButton";
import { Box, Flex } from "@radix-ui/themes";
import { Spacer } from "~/components_next/Spacer";

type MultipleSortConditionFormProps = {
  simpleFields: [SimpleField, ...SimpleField[]];
  sortCondition: RecordSortConditionUnit[] | null;
} & (
    | {
      isReadOnly?: false;
      onChangeSortCondition: (
        sortCondition: RecordSortConditionUnit[] | null
      ) => void;
    }
    | {
      isReadOnly: true;
    }
  );

const MultipleSortConditionForm = (props: MultipleSortConditionFormProps) => {
  const { simpleFields, sortCondition, isReadOnly } = props;

  const emptyCondition: RecordSortConditionUnit = {
    key: simpleFields[0].name,
    direction: "ascending",
  }

  const handleAddSortCondition = () => {
    if (isReadOnly) return;

    const newSortCondition: RecordSortConditionUnit[] = sortCondition ?? []

    props.onChangeSortCondition([
      ...newSortCondition,
      emptyCondition,
    ]);
  }

  const handleRemoveSortCondition = (index: number) => {
    if (isReadOnly) return;

    const newSortCondition: RecordSortConditionUnit[] = sortCondition ?? []

    props.onChangeSortCondition(
      newSortCondition.filter((sort, i) => i !== index)
    );
  }

  const handleChangeDirectionSelect = (
    direction: "ascending" | "descending",
    index: number,
  ) => {
    if (isReadOnly) return;

    const newSortCondition: RecordSortConditionUnit[] = sortCondition ?? [emptyCondition]

    props.onChangeSortCondition(
      newSortCondition.map((sort, i) => i === index ? { ...sort, direction } : sort)
    );
  }

  const handleChangeKeySelect = (key: string, index: number) => {
    if (isReadOnly) return;

    const newSortCondition: RecordSortConditionUnit[] = sortCondition ?? [emptyCondition]

    props.onChangeSortCondition(
      newSortCondition.map((sort, i) => i === index ? { ...sort, key } : sort)
    );
  }

  const keyOptions = simpleFields.map(({ name, displayName }) => ({
    value: name,
    label: displayName || name,
  }));

  const sortDirectionOptions = [
    { value: "ascending", label: "Ascending" },
    { value: "descending", label: "Descending" },
  ];

  return (
    <Flex direction="column" gap="2">
      {sortCondition && (
        sortCondition.map((sort, index) => (
          <Flex key={index} direction="row" align="center" gap="1">
            <SimpleSelect
              variant="primary"
              value={sort.key}
              onChange={(value) => handleChangeKeySelect(value as string, index)}
              size="xs"
              isDisabled={isReadOnly}
              options={keyOptions}
            />
            <SimpleSelect
              variant="primary"
              value={sort.direction}
              onChange={(value) =>
                handleChangeDirectionSelect(value as "ascending" | "descending", index)
              }
              size="xs"
              isDisabled={isReadOnly}
              options={sortDirectionOptions}
            />
            <Spacer />
            {!isReadOnly && (
              <IconButton
                onClick={() => handleRemoveSortCondition(index)}
                icon={<BsX />}
                tooltip="Remove"
              />
            )}
          </Flex>
        ))
      )}

      <Box style={{ alignSelf: "start" }}>
        <Button
          onClick={() => handleAddSortCondition()}
          variant="tertiary"
          size="xs"
          isDisabled={isReadOnly}
        >
          <BsPlus />
          Add Sort
        </Button>
      </Box>
    </Flex>
  );
};

export { MultipleSortConditionForm };
