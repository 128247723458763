import { useState } from "react";
import constate from "constate";

const useSqlConditionsContext = ({
  initialPrompt,
  initialSql,
}: {
  initialSql: string;
  initialPrompt: string;
}) => {
  const [queryingSql, setQueryingSql] = useState<string>(initialSql);
  const [sqlInput, setSqlInput] = useState<string>(initialSql);
  const [promptInput, setPromptInput] = useState<string>(initialPrompt);

  return {
    queryingSql,
    setQueryingSql,
    sqlInput,
    setSqlInput,
    promptInput,
    setPromptInput,
  };
};

export const [
  SqlConditionsProvider,
  useQueryingSql,
  useSetQueryingSql,
  useSqlInput,
  useSetSqlInput,
  usePromptInput,
  useSetPromptInput,
] = constate(
  useSqlConditionsContext,
  (value) => value.queryingSql,
  (value) => value.setQueryingSql,
  (value) => value.sqlInput,
  (value) => value.setSqlInput,
  (value) => value.promptInput,
  (value) => value.setPromptInput
);
