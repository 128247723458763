import { DashboardNotebookDetailResponse } from "@usemorph/morph-dashboard-types";

export class FindCanvasClientModel {
  constructor(private readonly data: DashboardNotebookDetailResponse) {}
  /**
   * Getters
   */
  // 次のプロパティはもう使ってないので、getterを用意しない: tableSlug, viewId, condition

  get notebookId(): string {
    return this.data.notebookId;
  }

  get notebookName(): string {
    return this.data.notebookName;
  }

  get description(): string | null {
    return this.data.description;
  }

  get databaseId(): string {
    return this.data.databaseId;
  }

  get isPagePublic(): boolean {
    return this.data.isPagePublic;
  }

  get publicApiKeyType(): string | null {
    return this.data.publicApiKeyType;
  }

  get publicApiKey(): string | null {
    return this.data.publicApiKey;
  }

  get pages(): DashboardNotebookDetailResponse["pages"] {
    return this.data.pages;
  }
}
