import { storageItem } from "~/clientStateStore/typedLocalStorage";
import { TypedStorage } from "~/clientStateStore/typedLocalStorage/storage";
import { LOCAL_STORAGE_KEYS } from "./keys";

export type ColorMode = "light" | "dark";

const typedStorage = new TypedStorage(localStorage);

const colorModeDesirializer = (value: string): ColorMode => {
  switch (value) {
    case "light":
      return "light";
    case "dark":
      return "dark";
    default:
      return "light";
  }
};

const colorModeStorageItem = storageItem<ColorMode>({
  key: LOCAL_STORAGE_KEYS.COLOR_MODE,
  version: 1,
  serializer: (value) => value,
  deserializer: colorModeDesirializer,
});

export const useColorModeStorage = () => {
  const getColorModeFromStorage = () => {
    return typedStorage.get(colorModeStorageItem);
  };

  const setColorModeToStorage = (value: ColorMode) => {
    typedStorage.set(colorModeStorageItem, value);
  };

  return {
    getColorModeFromStorage,
    setColorModeToStorage,
  };
};
