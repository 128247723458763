import { useParams } from "react-router-dom";

const usePublicApiKey = () => {
  const { publicApiKey } = useParams();

  if (!publicApiKey) {
    throw new Error(`publicApiKey not found.`);
  }

  return publicApiKey;
};

export { usePublicApiKey };
