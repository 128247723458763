import { ValidationResult } from "~/clientModel/utils/validationResultType";
import { FieldTypeClientModel } from "..";
import { FieldTypeClientModelBase } from "../FieldTypeClientModelBase";

export class AttachmentFieldTypeClientModel extends FieldTypeClientModelBase {
  public get type(): "attachment" {
    return "attachment";
  }

  public get fieldTypeLabel(): "Attachment" {
    return "Attachment";
  }

  public get isAutoGenerated(): false {
    return false;
  }

  public get isEmbeddable(): false {
    return false;
  }

  public get canBeTargetOfSyncingByComputedField(): true {
    return true;
  }

  public get _updatableFieldTypes(): FieldTypeClientModel["type"][] {
    return [];
  }

  public validate(): ValidationResult {
    return { isValid: true };
  }
}
