import { TabsContent } from "./TabsContent";
import { TabsList } from "./TabsList";
import { TabsRoot } from "./TabsRoot";
import { TabsTrigger } from "./TabsTrigger";

const Tabs = {
  Root: TabsRoot,
  List: TabsList,
  Trigger: TabsTrigger,
  Content: TabsContent,
} as const;

export { Tabs };
export type { TabsRootProps } from "./TabsRoot";
export type { TabsListProps } from "./TabsList";
export type { TabsTriggerProps } from "./TabsTrigger";
export type { TabsContentProps } from "./TabsContent";
