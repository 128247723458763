
import { DashboardTemplateDetailResponse } from "@usemorph/morph-dashboard-types";
import { FindTemplateDetailClientModel } from "./FindTemplateDetailClientModel";

export const FindTemplateDetailClientModelFactory = {
  // factory methods
  fromTemplateDetailResponse: (
    data: DashboardTemplateDetailResponse
  ): FindTemplateDetailClientModel => {
    return new FindTemplateDetailClientModel(data);
  },
};
