import { useCallback, useRef } from "react";

export const useDebounceCallback = <T>(
  callback: (payload: T) => void,
  delay: number
) => {
  const timerRef = useRef<NodeJS.Timer | null>(null);

  const debounce = useCallback(
    (payload: T) => {
      if (timerRef.current) clearTimeout(timerRef.current);

      timerRef.current = setTimeout(() => {
        callback(payload);
      }, delay);
    },
    [delay, callback]
  );

  return debounce;
};
