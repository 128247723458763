import { SimpleField } from "@usemorph/morph-dashboard-types";
import { RecordModel, AllRecordModelValueUnion } from "~/features/RecordModel";
import { RecordValueInput } from "~/components_next/RecordValue";
import { ValidationErrorsDict } from "~/features/RecordModel/utils/useRecordModelValidation";
import { FieldBadges } from "./FieldBadges";
import { isAutoGeneratedField } from "./utils/classifyFields";
import { Flex } from "~/components_next/Flex";
import { Box } from "~/components_next/Box";
import { Tooltip } from "~/components_next/Tooltip";

type FieldCategory = "editable" | "readonly" | "autoGenerated";

type ViewRecordFormProps = {
  recordModel: RecordModel;
  setRecordModel: (recordModel: RecordModel) => void;
  validationErrorsDict: ValidationErrorsDict;
  simpleViewFields: SimpleField[];
  isReadOnly?: boolean;
  displayedFieldCategories:
    | FieldCategory[] // editable: 自動生成でなく編集可能なフィールド, readonly: 自動生成でなく編集不可なフィールド, autoGenerated: 自動生成フィールド
    | "all";
  updateRules: Record<string, boolean>;
};

export const ViewRecordForm = (props: ViewRecordFormProps) => {
  const {
    simpleViewFields,
    recordModel,
    setRecordModel,
    validationErrorsDict,
    displayedFieldCategories,
    isReadOnly,
    updateRules,
  } = props;

  const handleRecordModelChange = (
    field: SimpleField,
    recordModelValue: AllRecordModelValueUnion
  ) => {
    setRecordModel({
      ...recordModel,
      [field.name]: recordModelValue,
    });
  };

  const isReadonlyViewField = (field: SimpleField): boolean => {
    // updateRulesにfield.nameがない場合は、安全側に倒しておく
    return `${field.name}` in updateRules ? !updateRules[field.name] : true;
  };

  const displayedFields = simpleViewFields.flatMap((field) => {
    if (displayedFieldCategories === "all") return [field];

    const category: FieldCategory = isAutoGeneratedField(field)
      ? "autoGenerated"
      : isReadonlyViewField(field)
      ? "editable"
      : "readonly";

    return displayedFieldCategories.includes(category) ? [field] : [];
  });

  const showReadonlyTooltip = (field: SimpleField): boolean => {
    return isReadonlyViewField(field) && !isAutoGeneratedField(field);
  };

  return (
    <Flex direction="column" gap="7">
      {displayedFields.map((field) => {
        return (
          <Flex direction="column" gap="1" key={field.name}>
            <FieldBadges field={field} />
            <Tooltip
              content="This field is readonly."
              isDisabled={!showReadonlyTooltip(field)}
            >
              <Box>
                <RecordValueInput
                  field={field}
                  recordModelValue={recordModel[field.name]}
                  errorMessages={
                    `${field.name}` in validationErrorsDict
                      ? validationErrorsDict[field.name].map(
                          ({ detail }) => detail
                        )
                      : []
                  }
                  onChange={(recordModelValue) =>
                    handleRecordModelChange(field, recordModelValue)
                  }
                  isReadOnly={isReadOnly || isReadonlyViewField(field)}
                />
              </Box>
            </Tooltip>
          </Flex>
        );
      })}
    </Flex>
  );
};
