import { DashboardPageObject } from "@usemorph/morph-dashboard-types";
import { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { match } from "ts-pattern";
import { Button } from "~/components_next/Button";
import { Divider } from "~/components_next/Divider";
import { Flex } from "~/components_next/Flex";
import { Input } from "~/components_next/Input";
import { SimpleSelect } from "~/components_next/Select";
import { Spacer } from "~/components_next/Spacer";
import { Switch } from "~/components_next/Switch";
import { Text } from "~/components_next/Text";
import { useDebounceCallback } from "~/hooks/useDebounceRequest";
import { getPath } from "~/routing";
import {
  useFindNotebookQuery,
  useUpdateNotebookMutaiton,
} from "~/serverStateStore";
import { useUpdateNotebookPageMutaiton } from "~/serverStateStore/notebookPage";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useNotebookId } from "~/utilHooks/useNotebookId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";

type PagePublishSetting = {
  pageId: string;

  pageName: string;
  isPublic: boolean;
};

type NotebookToolbarPublishProps = {
  authType: PublishAuthType;
  onChange: (authType: PublishAuthType) => void;
  publicApiKey?: string | null;
  pages: DashboardPageObject[];
};

type PublishAuthType = "public" | "auth" | "private";

const publishAuthOptions: Array<{ label: string; value: PublishAuthType }> = [
  {
    label: "No public access",
    value: "private",
  },
  {
    label: "Anyone with the public link can access",
    value: "public",
  },
  {
    label: "Team members with the public link can access",
    value: "auth",
  },
];

const PagePublishCell = ({
  page,
  authType,
}: {
  page: DashboardPageObject;
  authType: PublishAuthType;
}) => {
  const [isPagePublicLocal, setIsPagePublicLocal] = useState<boolean>(
    page.isPublic
  );

  /**
   * Update: 今回はPageがそれぞれ独立なのでここの枝葉で捌いちゃっても問題ないはず
   */
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const notebookId = useNotebookId();

  const { mutateAsync: updatePage } = useMutation(
    useUpdateNotebookPageMutaiton({ teamSlug, databaseId, notebookId })
  );

  // ローカルの値はすぐに更新、サーバー通信は一応デバウンス
  const handlePublicChange = (checked: boolean) => {
    setIsPagePublicLocal(checked);
    handleUpdatePage(checked);
  };

  // 一応連打だけ対応しておく
  const handleUpdatePage = useDebounceCallback((checked: boolean) => {
    updatePage({
      pageId: page.pageId,
      pageName: page.pageName,
      description: page.description,
      isPublic: checked,
      publicApiKeyType: authType,
    });
  }, 500);

  return (
    <Flex align="center">
      <Text>{page.pageName}</Text>
      <Spacer />
      <Switch
        isChecked={isPagePublicLocal}
        onCheckedChange={handlePublicChange}
      />
    </Flex>
  );
};

const NotebookToolbarPublish = (props: NotebookToolbarPublishProps) => {
  const { authType, onChange, publicApiKey, pages } = props;
  const [authTypeLocal, setAuthTypeLocal] = useState<PublishAuthType>(authType);

  const handleAuthTypeChange = (authType: PublishAuthType | null) => {
    if (!authType) return;
    onChange(authType);
    setAuthTypeLocal(authType);
  };

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const publishedUrl = publicApiKey
    ? `${location.origin}${getPath("publishedDashboard", {
        teamSlug,
        publicApiKey,
        databaseId,
      })}`
    : "";

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(publishedUrl);
  };

  return (
    <Flex direction="column" p="2" gap="2" css={{ width: "400px" }}>
      <Flex align="center" width="100%" gap="5">
        <SimpleSelect
          label="Public Access"
          variant="primary"
          options={publishAuthOptions}
          value={authTypeLocal}
          size="sm"
          onChange={handleAuthTypeChange}
        />
      </Flex>
      {publicApiKey && (
        <Input
          variant="primary"
          readOnly
          value={publishedUrl}
          rightSlot={
            <Button
              size="xs"
              variant="tertiary"
              onClick={handleCopyToClipboard}
            >
              Copy
            </Button>
          }
        ></Input>
      )}
      {publicApiKey && pages.length > 0 && (
        <>
          <Divider />
          <Text fontWeight="medium">Page Settings</Text>
          {pages.map((page) => {
            return (
              <PagePublishCell
                page={page}
                key={page.pageId}
                authType={authType}
              />
            );
          })}
        </>
      )}
    </Flex>
  );
};

const NotebookToolbarPublishWrapper = () => {
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const notebookId = useNotebookId();

  const { data: notebook } = useQuery({
    ...useFindNotebookQuery({
      teamSlug,
      databaseId,
      notebookId,
    }),
  });

  const authTypeValue = useMemo(() => {
    if (!notebook) return "private";
    if (!notebook.isPagePublic) return "private";
    if (notebook.publicApiKeyType === "auth") return "auth";
    return "public";
  }, [notebook]);

  /**
   * Update
   */
  const { mutateAsync: updateNotebook } = useMutation(
    useUpdateNotebookMutaiton({ teamSlug, databaseId, notebookId })
  );

  const handleAuthTypeChange = (authType: PublishAuthType) => {
    match(authType)
      .with("private", () => {
        updateNotebook({
          notebookName: notebook?.notebookName || "",
          description: notebook?.description || "",
          isPagePublic: false,
          publicApiKeyType: "auth",
        });
      })
      .otherwise(() => {
        updateNotebook({
          notebookName: notebook?.notebookName || "",
          description: notebook?.description || "",
          isPagePublic: true,
          publicApiKeyType: authType,
        });
      });
    return;
  };

  if (!notebook) return null;

  return (
    <NotebookToolbarPublish
      authType={authTypeValue}
      onChange={handleAuthTypeChange}
      publicApiKey={notebook.publicApiKey}
      pages={notebook.pages}
    />
  );
};

export { NotebookToolbarPublishWrapper as NotebookToolbarPublish };
