import {
  DashboardViewResponse,
  SimpleField,
} from "@usemorph/morph-dashboard-types";
import { useCallback } from "react";
import { useMutation } from "react-query";
import {
  getViewConditionType,
  parseViewSetting,
  convertSimpleFieldsToSelect,
  createFieldsOrderFromViewFields,
  createGroupKeyFromViewMetaFormState,
} from "~/features/View";
import { useUpdateViewMutation } from "~/serverStateStore/views";
import { FormValue } from "../ViewToolbar";

type UseUpdateViewParams = {
  teamSlug: string;
  databaseId: string;
  viewId: string;
  formValue: FormValue | undefined;
  view: DashboardViewResponse | undefined;
  viewFields: SimpleField[] | undefined;
  isPublic?: boolean;
};

const useUpdateView = ({
  teamSlug,
  databaseId,
  viewId,
  view,
  viewFields,
  formValue,
}: UseUpdateViewParams) => {
  const { mutateAsync: _updateView, isLoading: isUpdating } = useMutation(
    useUpdateViewMutation({
      teamSlug,
      databaseId,
      viewId,
    })
  );

  const updateView = useCallback(async () => {
    if (!view || !viewFields || !formValue) {
      throw new Error("Failed to save");
    }

    const viewConditionType = getViewConditionType(view);
    if (viewConditionType !== formValue.viewType) {
      throw new Error("Failed to save");
    }

    const viewSetting = parseViewSetting(view.setting);

    if (formValue.viewType === "query") {
      const {
        value: { meta, selectedFields, join, filter, sort },
      } = formValue;
      const { name, type, isPrivate } = meta;

      const select = convertSimpleFieldsToSelect(
        selectedFields,
        view.tableSlug
      );
      await _updateView({
        name: name,
        type: type,
        isPrivate: isPrivate,
        condition: {
          from: view.condition.from,
          select,
          join,
          filter: filter ?? undefined,
          sort: sort ? [sort] : undefined,
          groupKey: createGroupKeyFromViewMetaFormState(meta, view.tableSlug),
          reorderable: meta.type === "kanban" ? meta.reorderable : undefined,
        },
        setting: {
          data: {
            prompt: viewSetting.data.prompt,
            hiddenFields: viewSetting.data.hiddenFields?.filter((hiddenField) =>
              selectedFields.some(
                (selectedField) =>
                  selectedField.name === hiddenField.name &&
                  selectedField.originalTableSlug ===
                    hiddenField.originalTableSlug
              )
            ),
            fieldsOrder: createFieldsOrderFromViewFields(selectedFields),
            titleField: viewSetting.data.titleField,
            columnsOrder: viewSetting.data.columnsOrder,
            hiddenColumns: viewSetting.data.hiddenColumns,
          },
        },
      });
    } else {
      const {
        value: { meta, sql, setting },
      } = formValue;
      const { name, type, isPrivate } = meta;
      await _updateView({
        name,
        type,
        isPrivate,
        condition: {
          from: sql,
          select: view.condition.select,
          groupKey: createGroupKeyFromViewMetaFormState(meta, view.tableSlug),
        },
        setting,
      });
    }
  }, [_updateView, view, viewFields, formValue]);

  return {
    updateView,
    isUpdating,
  };
};

export {};

export { useUpdateView };
