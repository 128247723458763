import { FieldClientModel } from "~/clientModel/fields/field";
import { FilterConditionsClientModelFactory } from "./FilterConditionsClientModelFactory";
import { FilterConditionUnitClientModel } from "./FilterConditionUnit/FilterConditionUnitClientModel";
import { FilterConditionUnitClientModelFactory } from "./FilterConditionUnit/FilterConditionUnitClientModelFactory";

type FilterConditionsLogicalOperator = "and" | "or";

type FilterConditionsClientModelData = {
  conditionItems: (
    | FilterConditionUnitClientModel
    | FilterConditionsClientModel
  )[];
  logicalOperator: FilterConditionsLogicalOperator;
};

export class FilterConditionsClientModel {
  readonly #data: FilterConditionsClientModelData;

  public constructor(data: FilterConditionsClientModelData) {
    this.#data = data;
  }

  public get data(): FilterConditionsClientModelData {
    return this.#data;
  }

  public get allConditionItems(): (
    | FilterConditionUnitClientModel
    | FilterConditionsClientModel
  )[] {
    return this.#data.conditionItems;
  }

  public get logicalOperator(): FilterConditionsLogicalOperator {
    return this.#data.logicalOperator;
  }

  public get hasFilterConditions(): boolean {
    return this.#data.conditionItems.length > 0;
  }

  public get hasValidFilterConditions(): boolean {
    return this.#data.conditionItems.some((conditionItem) => {
      if (conditionItem instanceof FilterConditionUnitClientModel) {
        return conditionItem.validate().isValid;
      } else {
        return conditionItem.hasValidFilterConditions;
      }
    });
  }

  /**
   * Updaters
   */

  public addInitialFilterConditionUnit(
    targetField: FieldClientModel
  ): FilterConditionsClientModel {
    return new FilterConditionsClientModel({
      conditionItems: [
        ...this.#data.conditionItems,
        FilterConditionUnitClientModelFactory.createInitialFilterConditionUnitClientModel(
          targetField
        ),
      ],
      logicalOperator: "and",
    });
  }

  public addNestedInitialFilterConditions(
    targetField: FieldClientModel
  ): FilterConditionsClientModel {
    return new FilterConditionsClientModel({
      conditionItems: [
        ...this.#data.conditionItems,
        FilterConditionsClientModelFactory.createEmptyFilterConditions().addInitialFilterConditionUnit(
          targetField
        ),
      ],
      logicalOperator: this.#data.logicalOperator,
    });
  }

  public updateLogicalOperator(
    logicalOperator: FilterConditionsLogicalOperator
  ): FilterConditionsClientModel {
    return new FilterConditionsClientModel({
      ...this.#data,
      logicalOperator,
    });
  }

  public clearAllFilterConditions(): FilterConditionsClientModel {
    return new FilterConditionsClientModel({
      ...this.#data,
      conditionItems: [],
    });
  }

  public removeFilterConditionItemByIndex(
    removedConditionItemIndex: number
  ): FilterConditionsClientModel {
    return new FilterConditionsClientModel({
      conditionItems: this.#data.conditionItems.filter(
        (_, conditionItemIndex) =>
          conditionItemIndex !== removedConditionItemIndex
      ),
      logicalOperator: this.#data.logicalOperator,
    });
  }

  public replaceFilterConditionItemByIndex(
    replacedConditionItem:
      | FilterConditionUnitClientModel
      | FilterConditionsClientModel,
    replacedConditionItemIndex: number
  ): FilterConditionsClientModel {
    return new FilterConditionsClientModel({
      conditionItems: this.#data.conditionItems.map(
        (conditionItem, conditionItemIndex) => {
          if (conditionItemIndex === replacedConditionItemIndex) {
            return replacedConditionItem;
          } else {
            return conditionItem;
          }
        }
      ),
      logicalOperator: this.#data.logicalOperator,
    });
  }
}
