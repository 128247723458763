import { SimpleField } from "@usemorph/morph-dashboard-types";
import { useCallback } from "react";
import { BsArrow90DegUp } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { HintPopover } from "~/components_next/Hint";
import { Divider } from "~/components_next/Divider";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";
import { getPath } from "~/routing";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { useView } from "../../../common/utils/useView";
import { DropdownMenu } from "~/components_next/DropdownMenu";
import { Flex } from "~/components_next/Flex";

type FieldDropdownProps = {
  field: SimpleField;
  onClickHideField: (simpleField: SimpleField) => void;
  onClickEditField: (simpleField: SimpleField) => void;
};

const FieldDropdown = (props: FieldDropdownProps) => {
  const { field, onClickHideField, onClickEditField } = props;

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();

  const { data: viewData } = useView();

  const navigate = useNavigate();

  const handleSourceClick = useCallback(() => {
    if (field.originalTableSlug) {
      navigate(
        getPath("source", {
          teamSlug,
          databaseId,
          tableSlug: field.originalTableSlug,
        })
      );
    }
  }, [field, navigate, teamSlug, databaseId]);

  if (!viewData) return null;

  const isUpdatable = viewData.rules.update[field.name];

  return (
    <>
      <DropdownMenu.Item onClick={() => onClickHideField(field)}>
        Hide Field
      </DropdownMenu.Item>
      {field.type === "formula" && (
        <DropdownMenu.Item onClick={() => onClickEditField(field)}>
          Edit Field
        </DropdownMenu.Item>
      )}

      {!!field.originalTableSlug && (
        <>
          <Divider />
          <DropdownMenu.Item
            onClick={handleSourceClick}
            style={{
              height: "100%",
              paddingTop: 12,
              paddingBottom: 12,
            }}
          >
            <Flex direction="column" gap="1">
              <Text variant="description">Go to referring source</Text>
              <Flex align="center" gap="2">
                <BsArrow90DegUp />
                <Text>
                  {field.originalTableSlug}.{field.name}
                </Text>
              </Flex>
            </Flex>
          </DropdownMenu.Item>
        </>
      )}
      {isUpdatable !== true && (
        <>
          <Divider />
          <Flex px="3" pt="2" align="center">
            <Text>This field is readonly.</Text>
            <Spacer />
            <HintPopover title="Readonly Fields">
              <Text variant="description">This field is readonly because:</Text>
              <ul>
                <li>
                  <Text variant="description">
                    Field in the source is primary key.
                  </Text>
                </li>
                <li>
                  <Text variant="description">
                    Field on the View does not have a one-to-one correspondence
                    with a field in the Source.(e.g., when using an aggregate
                    function)
                  </Text>
                </li>
                <li>
                  <Text variant="description">
                    Field values are automatically generated(e.g., when field
                    type is formula)
                  </Text>
                </li>
              </ul>
            </HintPopover>
          </Flex>
        </>
      )}
    </>
  );
};

export { FieldDropdown };
