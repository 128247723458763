import { useMemo } from "react";
import { createUseMyColorsLoadable } from "./useCanvasColorsLoadable";
import { createUseGenerateColorsExecutable } from "./createUseGenerateColorsExecutable";
import { createUseSaveNewColorsExecutable } from "./createUseSaveNewColorsExecutable";
import { createUseDeleteMyColorsExecutable } from "./createUseDeleteMyColorsExecutable";
import { createUsePreviewColorsExecutable } from "./createUsePreviewColorsExecutable";

export const useCellStyleProps = (props: { teamSlug: string }) => {
  const { teamSlug } = props;

  const useMyColorsLoadable = useMemo(() => {
    return createUseMyColorsLoadable({ teamSlug });
  }, [teamSlug]);

  const useGenerateColorsExecutable = useMemo(() => {
    return createUseGenerateColorsExecutable({ teamSlug });
  }, [teamSlug]);

  const useSaveNewColorCodeExecutable = useMemo(() => {
    return createUseSaveNewColorsExecutable({ teamSlug });
  }, [teamSlug]);

  const useDeleteMyColorsExecutable = useMemo(() => {
    return createUseDeleteMyColorsExecutable({ teamSlug });
  }, [teamSlug]);

  const usePreviewColorsExecutable = useMemo(() => {
    return createUsePreviewColorsExecutable({ teamSlug });
  }, [teamSlug]);

  const memoizedValue = useMemo(() => {
    return {
      useMyColorsLoadable,
      useGenerateColorsExecutable,
      useSaveNewColorCodeExecutable,
      useDeleteMyColorsExecutable,
      usePreviewColorsExecutable,
    };
  }, [
    useMyColorsLoadable,
    useGenerateColorsExecutable,
    useSaveNewColorCodeExecutable,
    useDeleteMyColorsExecutable,
    usePreviewColorsExecutable,
  ]);

  return memoizedValue;

  // return {
  //   useMyColorsLoadable,
  //   useGenerateColorsExecutable,
  //   useSaveNewColorCodeExecutable,
  //   useDeleteMyColorsExecutable,
  //   usePreviewColorsExecutable,
  // };
};
