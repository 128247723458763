import constate from "constate";
import { CsvDownloadFieldsClientModel } from "~/clientModel/csvDownload/csvDownloadFields";
import { UseExecutable } from "~/clientModel/executable";
import { FieldsClientModel } from "~/clientModel/fields";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { FilterConditionsClientModel } from "~/clientModel/queryConditions/filterConditions";
import { PaginationClientModel } from "~/clientModel/queryConditions/pagination";
import { SortConditionsClientModel } from "~/clientModel/queryConditions/sortConditions";
import { RecordsClientModel } from "~/clientModel/records";
import { TablePaginationLimit } from "~/presenters/sourceAndViews/common/components/bottomItems/TablePagination";

/**
 * Public Dashboardでは、Filter, Sortのみ可能
 */
export type PublicDashboardTableViewPropsContextProps = {
  // loadable
  useRecordsWithConditionLoadable: UseLoadable<
    {
      viewId: string;
      filterConditions: FilterConditionsClientModel;
      sortConditions: SortConditionsClientModel;
      pagination: PaginationClientModel<TablePaginationLimit>;
    },
    RecordsClientModel
  >;

  useFieldsWithConditionLoadable: UseLoadable<
    { viewId: string },
    FieldsClientModel
  >;

  // csv download
  useDownloadCsvPreviewRecordsLoadable: UseLoadable<
    { csvDownloadFields: CsvDownloadFieldsClientModel; viewId: string },
    RecordsClientModel
  >;
  useDownloadCsvExecutable: UseExecutable<
    { viewId: string },
    { csvDownloadFields: CsvDownloadFieldsClientModel },
    { url: string }
  >;
};

const useDashboardTableViewPropsContext = (
  props: PublicDashboardTableViewPropsContextProps
) => {
  const {
    useRecordsWithConditionLoadable,
    useFieldsWithConditionLoadable,
    useDownloadCsvExecutable,
    useDownloadCsvPreviewRecordsLoadable,
  } = props;

  return {
    useRecordsWithConditionLoadable,
    useFieldsWithConditionLoadable,
    useDownloadCsvExecutable,
    useDownloadCsvPreviewRecordsLoadable,
  };
};

export const [
  PublicDashboardTableViewPropsProvider,
  useUseRecordsWithConditionLoadable,
  useUseFieldsWithConditionLoadable,
  useUseDownloadCsvExecutable,
  useUseDownloadCsvPreviewRecordsLoadable,
] = constate(
  useDashboardTableViewPropsContext,
  (value) => value.useRecordsWithConditionLoadable,
  (value) => value.useFieldsWithConditionLoadable,
  (value) => value.useDownloadCsvExecutable,
  (value) => value.useDownloadCsvPreviewRecordsLoadable
);
