type EmbeddingClientModelData = {
  fieldName: string;
};

export class EmbeddingClientModel {
  readonly #data: EmbeddingClientModelData;

  public constructor(data: EmbeddingClientModelData) {
    this.#data = data;
  }

  public get data(): EmbeddingClientModelData {
    return this.#data;
  }

  public get fieldName(): string {
    return this.#data.fieldName;
  }
}
