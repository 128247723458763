import { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { RightSidebar } from "~/components_next/RightSidebar";
import { useDatabaseId } from "~/utilHooks/useDatabaseId";
import { useTeamSlug } from "~/utilHooks/useTeamSlug";
import { SimpleField } from "@usemorph/morph-dashboard-types";
import {
  RecordModel,
  createRecordModelWithEmptyValues,
  AllRecordModelValueUnion,
} from "~/features/RecordModel";
import { useErrorToast } from "~/components_next/Error";
import { useRecordModelFormatValidation } from "~/features/RecordModel/utils/useRecordModelValidation";
import { Footer } from "./Footer";
import { useSidebarDisclosure } from "../../states/sidebar";
import { useViewId } from "~/utilHooks/useViewId";
import { useGroupingViewRecords } from "../../hooks/useGroupingViewRecords";
import {
  useCreateViewRecordMutation,
  useGetViewFieldsQuery,
} from "~/serverStateStore/views";
import { convertRecordModelToValuesForRequest } from "~/features/RecordModel/utils/convertRecordModelToValuesForRequest";
import { useSelectedGroupingValue } from "../../states/selectedGroupingValue";
import { RecordForm } from "~/features/Records/RecordForm";
import { useToast } from "~/components_next/Toast";

type CreateRecordSidebarPresenterProps = {
  onClose: () => void;
  simpleFields: SimpleField[];
  groupingField: SimpleField;
  groupingFieldValue: unknown;
};

const CreateRecordSidebarPresenter = (
  props: CreateRecordSidebarPresenterProps
) => {
  const { simpleFields, onClose, groupingField, groupingFieldValue } = props;
  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  /**
   * Client states
   */

  // grouping fieldは初期値をセットする
  const defaultRecordModel = useMemo(
    () => ({
      ...createRecordModelWithEmptyValues(simpleFields),
      [groupingField.name]: {
        isValid: true,
        isPrimary: groupingField.primary === true,
        nullable: groupingField.nullable === true,
        type: groupingField.type,
        value: groupingFieldValue,
      } as AllRecordModelValueUnion,
    }),
    [simpleFields, groupingField, groupingFieldValue]
  );

  const [recordModel, setRecordModel] =
    useState<RecordModel>(defaultRecordModel);

  // バリデーション系
  // createボタンがクリックされたかどうかのフラグ
  // 最初のクリックまではvalidationを表示しない
  const [isAfterFirstSubmit, setIsAfterFirstSubmit] = useState(false);

  const { isAllValid, validationErrorsDict } = useRecordModelFormatValidation({
    recordModel,
    simpleFields,
  });

  /**
   * handlers
   */

  const toast = useToast();
  const { errorToast } = useErrorToast({});

  const { mutateAsync: createViewRecord, isLoading: isCreating } = useMutation(
    useCreateViewRecordMutation({ teamSlug, databaseId, viewId })
  );

  const handleCreateRecord = async () => {
    setIsAfterFirstSubmit(true);
    if (isAllValid) {
      try {
        await createViewRecord({
          values: convertRecordModelToValuesForRequest({
            recordModelAfterEdit: recordModel,
          }),
        });
        toast({
          type: "success",
          title: "New record created",
        });
        setRecordModel(createRecordModelWithEmptyValues(simpleFields));
        setIsAfterFirstSubmit(false);
      } catch (e) {
        errorToast(e);
      }
    } else if (!isAllValid) {
      toast({
        type: "error",
        title: "Validation error. Please check your input.",
      });
    }
  };

  return (
    <RightSidebar
      width={400}
      minWidth={300}
      title="Create Record"
      footer={
        <Footer
          isCreateRecordButtonDisabled={!isAllValid && isAfterFirstSubmit}
          onClickCreateRecordButton={handleCreateRecord}
          isCreatingRecord={isCreating}
        />
      }
      height="calc(100vh - 40px)"
      onClose={onClose}
    >
      <RecordForm
        simpleFields={simpleFields}
        recordModel={recordModel}
        setRecordModel={setRecordModel}
        validationErrorsDict={isAfterFirstSubmit ? validationErrorsDict : {}}
        displayedFieldCategories={["nonAutoGenerated"]}
      />
    </RightSidebar>
  );
};

const CreateRecordSidebar = () => {
  const { isOpen, onClose } = useSidebarDisclosure("createRecord");

  const teamSlug = useTeamSlug();
  const databaseId = useDatabaseId();
  const viewId = useViewId();

  const { data: viewFieldsData } = useQuery(
    useGetViewFieldsQuery({
      teamSlug,
      databaseId,
      viewId,
    })
  );

  const { data: groupingViewRecordsData } = useGroupingViewRecords();

  const selectedGroupingValue = useSelectedGroupingValue(viewId);

  if (!viewFieldsData || !groupingViewRecordsData) return null;

  if (!isOpen) return null;

  return (
    <CreateRecordSidebarPresenter
      onClose={onClose}
      simpleFields={viewFieldsData.fields}
      groupingField={groupingViewRecordsData.groupingField}
      groupingFieldValue={selectedGroupingValue}
    />
  );
};

export { CreateRecordSidebar };
