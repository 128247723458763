import { RecordEmbeddingSortConditionUnit } from "@usemorph/morph-dashboard-types";
import { EmbeddingSortConditionUnitClientModel } from "./EmbeddingSortConditionUnitClientModel";

export class EmbeddingSortConditionUnitClientModelDefactory {
  public static toRecordEmbeddingSortConditionUnit(
    embeddingSortConditionUnit: EmbeddingSortConditionUnitClientModel
  ): RecordEmbeddingSortConditionUnit {
    const embeddingFieldName =
      embeddingSortConditionUnit.targetField.embedding?.fieldName;
    if (!embeddingFieldName) {
      throw new Error(
        `${embeddingSortConditionUnit.targetField.name} is not embedding`
      );
    }
    return {
      key: embeddingFieldName,
      operator: embeddingSortConditionUnit.data.operator,
      value: embeddingSortConditionUnit.data.inputValue,
    };
  }
}
