import { FieldClientModel } from "~/clientModel/fields/field";
import { FieldsClientModel } from "~/clientModel/fields";
import { Executable } from "~/clientModel/executable";
import { RecordsClientModel } from "~/clientModel/records";
import { FieldTypeInput } from "./FieldTypeInput";
import { TableClientModel } from "~/clientModel/tables/table";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { TablesClientModel } from "~/clientModel/tables";
import { AggregateValueFieldOperatorsClientModel } from "~/clientModel/fields/field/fieldType/computed/aggregateValue/aggregateValueFieldOperators";
import {
  FieldFormLayout,
  DisplayNameInput,
  FieldNameInput,
  DisplaySettingInput,
  NullableInput,
  DescriptionInput,
} from "../../components";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";
import { UniqueInput } from "../../components/UniqueInput";

type SourceEditFieldFormProps = {
  table: TableClientModel;
  fields: FieldsClientModel;
  field: FieldClientModel;
  tables: TablesClientModel;
  aggregateValueFieldOperators: AggregateValueFieldOperatorsClientModel;
  smartFunctions: SmartFunctionsClientModel;
  testFormulaExecutable: Executable<
    { formula: string },
    { records: RecordsClientModel; fields: FieldsClientModel }
  >;
  useFieldsLoadable: UseLoadable<{ tableSlug: string }, FieldsClientModel>;
  onFieldChange: (field: FieldClientModel) => void;
};

export const SourceEditFieldForm = (props: SourceEditFieldFormProps) => {
  const {
    table,
    field,
    fields,
    tables,
    aggregateValueFieldOperators,
    smartFunctions,
    testFormulaExecutable,
    onFieldChange,
    useFieldsLoadable,
  } = props;

  return (
    <FieldFormLayout>
      <DisplayNameInput
        field={field}
        onFieldChange={onFieldChange}
        linkedWithFieldName={false}
      />
      <FieldNameInput
        isReadOnly={true}
        field={field}
        onFieldChange={onFieldChange}
      />
      <FieldTypeInput
        table={table}
        field={field}
        fields={fields}
        tables={tables}
        smartFunctions={smartFunctions}
        aggregateValueFieldOperators={aggregateValueFieldOperators}
        testFormulaExecutable={testFormulaExecutable}
        onFieldChange={onFieldChange}
        useFieldsLoadable={useFieldsLoadable}
      />
      <DisplaySettingInput field={field} onFieldChange={onFieldChange} />
      <NullableInput field={field} onFieldChange={onFieldChange} />
      <UniqueInput field={field} />
      <DescriptionInput field={field} onFieldChange={onFieldChange} />
    </FieldFormLayout>
  );
};
