import { useCallback, useMemo } from "react";
import { NodeProps, NodeToolbar, Position, useStore } from "reactflow";
import { CanvasCellPromptClientModel } from "~/clientModel/canvas";
import { PlaygroundCellCard } from "../common/PlaygroundCellCard";
import { CanvasCellPromptUnits } from "../../../cellContent/prompt/CanvasCellPromptUnits";
import { Flex } from "~/components_next/Flex";
import { PlaygroundCellPromptExecuteButton } from "./PlaygroundCellPromptExecuteButton";
import { PlaygroundCellPromptCopyEmbedCode } from "./PlaygroundCellPromptCopyEmbedCode";
import { PlaygroundCellAddToPageButton } from "../common/PlaygroundCellAddToPageButton";
import { useUseExecuteCellLoadable } from "~/presenters/canvas/common/UseExecuteCellLoadableContextProvider";
import { PlaygroundCellPromptPublish } from "./PlaygroundCellPromptPublish";
import { useUseUpdateCellWithoutRunExecutable } from "../../providers/PlaygroundUpdateCellExecutableProvider";
import { useAncestorVariableValues } from "../../util/useAncestorVariableValues";

type PlaygroundCellPromptProps = {
  cellClientModel: CanvasCellPromptClientModel;
};

const PlaygroundCellPrompt = (props: NodeProps<PlaygroundCellPromptProps>) => {
  const { data, selected, dragging, xPos, yPos } = props;
  const { cellClientModel } = data;
  const { cellId } = cellClientModel;

  /**
   * Cell Selection
   */
  const addSelectedNodes = useStore((s) => s.addSelectedNodes);
  const handleOnSelectCell = useCallback(() => {
    addSelectedNodes([cellId]);
  }, []);

  /**
   * Cell Execution with variables
   * overwrite cell result with variables
   */

  const ancestorVariableValues = useAncestorVariableValues(cellClientModel);
  const nonNullVariableValues = useMemo(() => {
    return ancestorVariableValues.filter(
      (value) => value.value !== null && value.value !== undefined
    );
  }, [ancestorVariableValues]);
  // variables入りの再実行のためのuseQueryオプションを取得
  const useExecuteCellLoadable = useUseExecuteCellLoadable();
  const executeCellLoadable = useExecuteCellLoadable({
    cellId,
    variables: nonNullVariableValues,
  });

  const promptCellClientModel = useMemo(() => {
    if (
      executeCellLoadable.data &&
      executeCellLoadable.data instanceof CanvasCellPromptClientModel
    ) {
      // TODO: Factory メソッドに切り替える
      return executeCellLoadable.data;
    }
    return cellClientModel;
  }, [cellClientModel, executeCellLoadable]);

  const useUpdateCellExecutable = useUseUpdateCellWithoutRunExecutable();
  const updateCellExecutable = useUpdateCellExecutable();

  return (
    <PlaygroundCellCard
      cellClientModel={cellClientModel}
      isNodeDragging={dragging}
      isNodeSelected={selected}
      variant="fixed"
      position={{ x: xPos, y: yPos }}
    >
      <CanvasCellPromptUnits
        promptCellClientModel={promptCellClientModel}
        isSelected={selected}
        onSelect={handleOnSelectCell}
      />

      <NodeToolbar position={Position.Left} isVisible={selected}>
        <PlaygroundCellAddToPageButton cellClientModel={cellClientModel} />
      </NodeToolbar>

      <NodeToolbar position={Position.Right} isVisible={selected}>
        <Flex direction="column" align="start" justify="start" gap="2">
          <PlaygroundCellPromptExecuteButton
            cellClientModel={cellClientModel}
            onExecute={executeCellLoadable.refetch}
            isExecuting={executeCellLoadable.status === "loading"}
            onRetry={() => null}
            isRetrying={false}
          />
          <PlaygroundCellPromptCopyEmbedCode
            cellClientModel={cellClientModel}
          />
          <PlaygroundCellPromptPublish
            cellClientModel={cellClientModel}
            updateCellExecutable={updateCellExecutable}
          />
        </Flex>
      </NodeToolbar>

      {/* <NodeToolbar position={Position.Bottom} isVisible={selected}>
        <PlaygroundCellCreateCellButton
          canvasCellClientModel={promptCellClientModel}
        />
      </NodeToolbar> */}
    </PlaygroundCellCard>
  );
};

export { PlaygroundCellPrompt, type PlaygroundCellPromptProps };
