import { ReactNode } from "react";
import { Flex } from "~/components_next/Flex";
import { Spacer } from "~/components_next/Spacer";
import { Text } from "~/components_next/Text";
import { styled } from "~/stitches";

const DisplaySampleBox = styled(Flex, {
  backgroundColor: "$bronze3",
  borderRadius: "4px",
  padding: "8px 12px",
  width: "100%",
});

type DisplaySampleProps = {
  children: ReactNode;
};

export const DisplaySample = (props: DisplaySampleProps) => {
  const { children } = props;

  return (
    <DisplaySampleBox align="center">
      <Text variant="tinyDescription">Display Sample</Text>
      <Spacer />
      {typeof children === "string" ? (
        <Text variant="description">{children}</Text>
      ) : (
        children
      )}
      <Spacer />
    </DisplaySampleBox>
  );
};
