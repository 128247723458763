import { useMutation } from "react-query";
import { Executable, useComposeExecutable } from "~/clientModel/executable";

import { useErrorToast } from "~/components_next/Error";
import { useRunNormalizedTableMutation } from "~/serverStateStore";

type UseStartSyncComputedFieldsExecutableParams = {
  teamSlug: string;
  databaseId: string;
  tableSlug: string;
};

const useStartSyncComputedFieldsExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseStartSyncComputedFieldsExecutableParams): Executable<
  unknown,
  unknown
> => {
  const { errorToast } = useErrorToast({});

  return useComposeExecutable(
    useMutation({
      ...useRunNormalizedTableMutation({
        teamSlug,
        databaseId,
        tableSlug,
      }),
      onError: (e) => {
        errorToast(e);
      },
    })
  );
};

export const createUseStartSyncComputedFieldsExecutable = ({
  teamSlug,
  databaseId,
  tableSlug,
}: UseStartSyncComputedFieldsExecutableParams) => {
  return () =>
    useStartSyncComputedFieldsExecutable({
      teamSlug,
      databaseId,
      tableSlug,
    });
};
