import { CellSelectionStateClientModel } from "./CellSelectionStateClientModel";

export class CellSelectionStateClientModelFactory {
  public static createInitialCellSelectionState(): CellSelectionStateClientModel {
    return new CellSelectionStateClientModel({
      fieldNames: [],
      recordIdentifiers: [],
    });
  }
}
