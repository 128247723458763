import { DashboardNotificationListResponse } from "@usemorph/morph-dashboard-types";
import { useCallback } from "react";
import { useQuery } from "react-query";
import { useComposeLoadable } from "~/clientModel/loadable";
import { UseLoadable } from "~/clientModel/loadable/UseLoadable";
import { ListNotificationClientModel, ListNotificationClientModelFactory } from "~/clientModel/notification";
import { useListNotificationsQuery } from "~/serverStateStore/notification"

export type UseListNotificationQueryProps = {
  limit?: number;
  skip?: number;
  isArchived?: boolean;
  isRead?: boolean;
};

export const createUseListNotificationLoadable = (props: {
  teamSlug: string;
}): UseLoadable<UseListNotificationQueryProps, ListNotificationClientModel> => {
  const { teamSlug } = props

  return ({ limit, skip, isArchived, isRead }: { limit?: number; skip?: number; isArchived?: boolean; isRead?: boolean; }) =>
    useComposeLoadable(
      useQuery({
        ...useListNotificationsQuery({
          teamSlug,
          limit,
          skip,
          isArchived,
          isRead,
        }),
        select: useCallback(
          (data: DashboardNotificationListResponse) =>
            ListNotificationClientModelFactory.fromNotificationListResponse(data),
          [],
        ),
      })
    )
}
