import {
  RecordAggregationCondition,
  RecordAggregationFilter,
} from "@usemorph/morph-dashboard-types";

// todo: /featuresに移動する

export const EmptyAggregationFilterUnit = ({
  value,
}: {
  value?: string;
}): RecordAggregationFilter => {
  return {
    value: value ?? "",
    operator: "equal",
  };
};

export const EmptyAggregationUnit = ({
  key,
}: {
  key?: string;
}): RecordAggregationCondition => {
  return {
    key: key ?? "",
    operator: "count",
  };
};
