import { useEditorOrThrow } from "../utils";

const useParagraph = () => {
  const editor = useEditorOrThrow();

  const handleSet = () => {
    editor.chain().focus().setParagraph().run();
  };

  const isActive = editor.isActive("paragraph");

  const isDisabled = !editor.can().chain().focus().setParagraph().run();

  return {
    handleSet,
    isActive,
    isDisabled,
  };
};

export { useParagraph };
