import { FieldTypeClientModelFactory } from "~/clientModel/fields/field/fieldType";
import { FieldTypeSubSelect } from "./common/FieldTypeSubSelectBase";

const numberFieldTypes = [
  FieldTypeClientModelFactory.createEmpty("number"),
  FieldTypeClientModelFactory.createEmpty("bigNumber"),
  FieldTypeClientModelFactory.createEmpty("decimal"),
  FieldTypeClientModelFactory.createEmpty("autoNumber"),
  FieldTypeClientModelFactory.createEmpty("autoBigNumber"),
] as const;

type NumberFieldType = (typeof numberFieldTypes)[number];

type NumberFieldsTypeSubSelectProps = {
  onSelect: (fieldType: NumberFieldType) => void;
  options?:
    | {
        includes: NumberFieldType["type"][];
      }
    | {
        excludes: NumberFieldType["type"][];
      }; // 指定がなければ全部
};

export const NumberFieldsTypeSubSelect = (
  props: NumberFieldsTypeSubSelectProps
) => {
  const { onSelect, options } = props;

  return (
    <FieldTypeSubSelect
      triggerContent="Number"
      onSelect={onSelect}
      fieldTypeOptions={numberFieldTypes}
      options={options}
    />
  );
};
