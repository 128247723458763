import {
  AggregateValueFieldTypeClientModel,
  FieldTypeClientModelFactory,
} from "~/clientModel/fields/field/fieldType";
import { AggregateValueFieldOperatorsClientModel } from "~/clientModel/fields/field/fieldType/computed/aggregateValue/aggregateValueFieldOperators";
import { DropdownMenu } from "~/components_next/DropdownMenu";
import { FieldTypeLabelAndIcon } from "./common/FieldTypeLabelAndIcon";

const computedFieldTypes = [
  FieldTypeClientModelFactory.createEmpty("syncValue"),
  FieldTypeClientModelFactory.createEmpty("generateText"),
  FieldTypeClientModelFactory.createEmpty("calculation"),
  FieldTypeClientModelFactory.createEmpty("aggregateValue"),
  FieldTypeClientModelFactory.createEmpty("formula"),
] as const;

type DateFieldType = (typeof computedFieldTypes)[number];

type ComputedFieldsTypeSubSelectProps = {
  aggregateValueFieldOperators: AggregateValueFieldOperatorsClientModel;
  onSelect: (fieldType: DateFieldType) => void;
};

export const ComputedFieldsTypeSubSelect = (
  props: ComputedFieldsTypeSubSelectProps
) => {
  const { onSelect, aggregateValueFieldOperators } = props;

  const handleAggregateValueFieldSelect = ({
    operatorValue,
  }: {
    operatorValue: string;
  }) => {
    onSelect(
      (
        FieldTypeClientModelFactory.createEmpty(
          "aggregateValue"
        ) as AggregateValueFieldTypeClientModel
      ).updateOperatorValue(operatorValue)
    );
  };

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger>Computed</DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent>
        {computedFieldTypes.map((type) => {
          // Aggregate Valueの場合はOperatorを選択する
          if (type.type === "aggregateValue") {
            return (
              <DropdownMenu.Sub key={type.type}>
                <DropdownMenu.SubTrigger>
                  <FieldTypeLabelAndIcon type={type} />
                </DropdownMenu.SubTrigger>
                <DropdownMenu.SubContent>
                  {aggregateValueFieldOperators.operators.map(
                    ({ label, value }) => (
                      <DropdownMenu.Item
                        key={value}
                        onClick={() =>
                          handleAggregateValueFieldSelect({
                            operatorValue: value,
                          })
                        }
                      >
                        {label}
                      </DropdownMenu.Item>
                    )
                  )}
                </DropdownMenu.SubContent>
              </DropdownMenu.Sub>
            );
          }

          // それ以外
          return (
            <DropdownMenu.Item key={type.type} onClick={() => onSelect(type)}>
              <FieldTypeLabelAndIcon type={type} />
            </DropdownMenu.Item>
          );
        })}
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>
  );
};
