import {
  BoldButton,
  ItalicButton,
  UnderlineButton,
  StrikeButton,
  SuperscriptButton,
  SubscriptButton,
  HighlightButton,
  FontColorButton,
  CodeButton,
  ClearFormatButton,
  LinkButton,
} from "./BubbleMenuButtons";

import { BubbleMenu as BubbleMenuContainer } from "@tiptap/react";

import { Flex } from "@radix-ui/themes";
import { styled } from "~/stitches";

const MenuContainer = styled(Flex, {
  backgroundColor: "$bg0",
  padding: "$1",
  borderColor: "$border",
  borderRadius: "$3",
  boxShadow: "$4",
});

const BubbleMenu = () => {
  return (
    <BubbleMenuContainer
      tippyOptions={{
        maxWidth: 1000,
        duration: 100,
        placement: "top-start",
      }}
    >
      <MenuContainer gap="1" align="center" position="relative">
        <BoldButton />
        <ItalicButton />
        <UnderlineButton />
        <StrikeButton />
        <SuperscriptButton />
        <SubscriptButton />
        <HighlightButton />
        <FontColorButton />
        <CodeButton />
        <ClearFormatButton />
        <LinkButton />
      </MenuContainer>
    </BubbleMenuContainer>
  );
};

export { BubbleMenu };
