import { SmartFunctionFieldTypeClientModel } from "~/clientModel/fields/field/fieldType";
import { SmartFunctionsClientModel } from "~/clientModel/fields/field/fieldType/smartFunction/smartFunctions";
import { CreditConsumptionWrapper } from "./CreditConsumptionWrapper";

type SmartFunctionFieldCreditConsumptionProps = {
  smartFunctionType: SmartFunctionFieldTypeClientModel;
  smartFunctions: SmartFunctionsClientModel;
};

export const SmartFunctionFieldCreditConsumption = (
  props: SmartFunctionFieldCreditConsumptionProps
) => {
  const { smartFunctionType, smartFunctions } = props;

  const currentSmartFunction = smartFunctionType.smartFunctionId
    ? smartFunctions.getSmartFunction(smartFunctionType.smartFunctionId)
    : null;

  if (!currentSmartFunction) {
    return null;
  }

  const message =
    currentSmartFunction.consumedCredits > 1
      ? `${currentSmartFunction.consumedCredits} credits will be consumed per record update.`
      : `${currentSmartFunction.consumedCredits} credit will be consumed per record update.`;

  return <CreditConsumptionWrapper message={message} />;
};
