import { useMutation } from "react-query";
import { composeExecutable } from "~/clientModel/executable";
import { useSaveNewColorsMutation } from "~/serverStateStore/canvasStyle";

export const createUseSaveNewColorsExecutable = ({
  teamSlug,
}: {
  teamSlug: string;
}) => {
  return () => {
    return composeExecutable(
      useMutation(useSaveNewColorsMutation({ teamSlug }))
    );
  };
};
